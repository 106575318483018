/* global axios */
import ApiClient from '../ApiClient';

class CustomTicketFieldsAPI extends ApiClient {
  constructor() {
    super('custom_fields', { accountScoped: true });
  }

  toggle_status(id, status) {
    return axios.put(`${this.url}/${id}`, { enabled: status });
  }

  add_inboxes(id, inboxes) {
    return axios.post(`${this.url}/${id}/add_inbox`, { inbox_ids: inboxes });
  }

  remove_inboxes(id, inboxes) {
    return axios.post(`${this.url}/${id}/remove_inbox`, { inbox_ids: inboxes });
  }

  add_option(id, value) {
    return axios.post(`${this.url}/${id}/custom_field_options`, { value });
  }

  remove_option(fieldId, optionId) {
    return axios.delete(
      `${this.url}/${fieldId}/custom_field_options/${optionId}`
    );
  }
}

export default new CustomTicketFieldsAPI();
