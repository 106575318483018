<template>
  <div :style="`width: ${width}`" class="limechat-video-wrap">
    <video ref="video" controls="true" class="limechat-video">
      <source ref="vidsrc" :src="url" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: 'VideoView',
  props: {
    url: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '250px',
    },
  },
  mounted() {
    var refreshId = setInterval(() => {
      try {
        if (this.$refs.video.readyState === 4) {
          clearInterval(refreshId);
          return;
        }
        this.$refs.video.load();
      } catch (e) {
        clearInterval(refreshId);
      }
    }, 2000);
  },
};
</script>
<style lang="scss" scoped>
.limechat-video-wrap {
  width: 250px;
  padding-bottom: 8px;
  margin: 0 auto;
}

.limechat-video {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
