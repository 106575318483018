<template>
  <div class="selection-bar">
    <div class="checkbox-container">
      <label class="container-checkbox">
        <input
          type="checkbox"
          :checked="selectAllLoaded"
          value="select_all"
          @input="selectCard(selectAllLoaded)"
        />
        <span class="checkmark" />
      </label>
      <label
        v-if="!selectAllLoaded"
        class="checkbox-label mg-top--six"
        for="select_all"
      >
        {{ `${$t('CONVERSATION.SELECT_BUTTON')} (${selected.length})` }}
      </label>
      <div v-else class="checkbox-container">
        <label
          class="checkbox-label"
          for="select_all"
          style="margin-top: 0.8rem"
        >
          {{ `(${selected.length})` }}
        </label>
        <div class="checkbox-container mg-left">
          <label class="container-checkbox">
            <input
              type="checkbox"
              :checked="selectAll"
              value="select_all"
              @input="selectAllConversation"
            />
            <span class="checkmark" />
          </label>
          <label class="checkbox-label mg-top--six" for="select_all">
            {{ `${$t('CONVERSATION.SELECT_BUTTON')} (${selectCount})` }}
          </label>
        </div>
      </div>
    </div>
    <woot-primary-button
      type="button"
      size="small"
      :name="$t('CONVERSATION.MODIFY_BUTTON')"
      front-icon="refresh"
      icon-size="semimedium"
      @click="showModifyPopup"
    />
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    selectAllLoaded: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    selectCount: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    selectCard(selected) {
      this.$emit('select-card', selected);
    },
    selectAllConversation() {
      this.$emit('select-all-conversation');
    },
    showModifyPopup() {
      this.$emit('show-modify-popup');
    },
  },
};
</script>
