export const SHOWCASE_SLA_DATA = [
  {
    name: 'Service Level Agreements',
    key: 'SLA',
    color: '#4267B2',
    image:
      'https://ik.imagekit.io/vysbzd9hl/slaTracking.png?updatedAt=1724867483043',
    contentPath: 'SLA',
  },
];

export const TIME_DURATION_OPTIONS = [
  {
    name: 'Immediately',
    value: 0,
  },
  {
    name: '30 Minutes',
    value: 30,
  },
  {
    name: '1 Hour',
    value: 60,
  },
  {
    name: '4 Hours',
    value: 240,
  },
  {
    name: '8 Hours',
    value: 480,
  },
];

export const NOTIFICATION_TYPES = {
  SEND_NOTIFICATION: 'send_notification',
  SEND_EMAIL_NOTIFICATION: 'send_email_notification',
  SEND_IN_APP_AND_EMAIL_NOTIFICATION: 'send_in_app_and_email_notification',
};

export const TRIGGERS = {
  INBOX: 'inbox_id',
  LABEL: 'label_id',
  TEAM: 'team_id',
  ASSIGNEE: 'assignee_id',
};

export const TRIGGER_OPTIONS = [
  {
    name: 'Inbox',
    value: TRIGGERS.INBOX,
  },
  {
    name: 'Tag',
    value: TRIGGERS.LABEL,
  },
  {
    name: 'Team',
    value: TRIGGERS.TEAM,
  },
];

export const METRICS = {
  FIRST_RESPONSE_TIME: 'first_response_time',
  NEXT_RESPONSE_TIME: 'next_response_time',
  RESOLUTION_TIME: 'resolution_time',
};

export const METRICS_OPTIONS = [
  {
    name: 'First Response Time',
    value: METRICS.FIRST_RESPONSE_TIME,
    dueTimeKey: 'frt_due',
  },
  {
    name: 'Next Response Time',
    value: METRICS.NEXT_RESPONSE_TIME,
    dueTimeKey: 'nrt_due',
  },
  {
    name: 'Resolution Time',
    value: METRICS.RESOLUTION_TIME,
    dueTimeKey: 'resolution_due',
  },
];

export const ACTIONS = {
  SEND_NOTIFICATION: 'send_notification',
  SEND_EMAIL_NOTIFICATION: 'send_email_notification',
  SEND_IN_APP_AND_EMAIL_NOTIFICATION: 'send_in_app_and_email_notification',
};

export const ACTION_OPTIONS = [
  {
    name: 'Send Notification',
    value: ACTIONS.SEND_NOTIFICATION,
  },
  {
    name: 'Send Email Notification',
    value: ACTIONS.SEND_EMAIL_NOTIFICATION,
  },
  {
    name: 'Send In-App and Email Notification',
    value: ACTIONS.SEND_IN_APP_AND_EMAIL_NOTIFICATION,
  },
];
