/* global axios */
import ApiClient from './ApiClient';

class BillingPlanAPI extends ApiClient {
  constructor() {
    super('billing_plan', { accountScoped: true, apiVersion: 'v1' });
  }

  getSubscription() {
    const newUrl = this.url.substring(0, this.url.length - 13);
    return axios.get(`${newUrl}/subscriptions`);
  }
}

export default new BillingPlanAPI();
