var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('draggable',_vm._b({attrs:{"tag":"div","list":_vm.list,"sort":false,"value":_vm.value,"move":_vm.onLabelMove,"disabled":_vm.modalMode,"draggable":".client"},on:{"end":_vm.updateMovedLabel,"input":_vm.updateLabelState}},'draggable',_vm.dragOptions,false),_vm._l((_vm.labels),function(label){return _c('div',{key:label.id,class:label.label_type === 'client' ? 'client' : 'system',attrs:{"id":label.id,"data-level":label.label_level}},[(_vm.modalMode && label.label_type === 'system')?void 0:_c('div',{staticClass:"draggable-container flex-space-between",class:{
        'is-selected': _vm.selectedTag && _vm.selectedTag.id === label.id,
      },on:{"click":function($event){$event.preventDefault();return _vm.onClickLabel(label)}}},[_c('div',{staticClass:"flex-row-center"},[(_vm.selectedTagsToPerformAction.length)?_c('div',{staticClass:"tag-selection-checkbox"},[(!_vm.modalMode && label.label_type === 'client')?_c('label',{staticClass:"container-checkbox"},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectedTagsToPerformAction.includes(label.id),"value":label.id},on:{"input":function($event){return _vm.onTagSelect(label)}}}),_vm._v(" "),_c('span',{staticClass:"grey-checkmark checkmark"})]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"content-wrapper flex-column"},[_c('div',{staticClass:"flex-row flex-justify flex-align",style:({ color: _vm.labelColor(label) })},[(
                !_vm.hasNestedLabel(label) &&
                  !_vm.modalMode &&
                  label.label_type === 'client'
              )?_c('span',{staticClass:"mg-right--small",staticStyle:{"cursor":"pointer"}},[_c('icons',{attrs:{"name":"drag","color":"lightgrey-fill-only","size":"mediumlarge"}})],1):_vm._e(),_vm._v(" "),(_vm.modalMode && label.active)?[_c('label',{staticClass:"container-checkbox tag-selection-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedLabels),expression:"selectedLabels"}],attrs:{"type":"checkbox"},domProps:{"value":label.id,"checked":Array.isArray(_vm.selectedLabels)?_vm._i(_vm.selectedLabels,label.id)>-1:(_vm.selectedLabels)},on:{"input":function($event){return _vm.handleLabelSelect(label)},"change":function($event){var $$a=_vm.selectedLabels,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=label.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedLabels=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedLabels=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedLabels=$$c}}}}),_vm._v(" "),_c('span',{staticClass:"grey-checkmark checkmark"})])]:_vm._e(),_vm._v(" "),_c('icons',{attrs:{"name":"hashFilled","color":"primary","custom-style":{
                fill: _vm.labelColor(label),
                height: '2rem',
                marginTop: '1px',
                marginLeft: '8px',
              }}}),_vm._v(" "),_c('span',{staticClass:"content-container content--title body-b2",style:({ color: _vm.labelColor(label) }),domProps:{"innerHTML":_vm._s(_vm.prepareContent(label.title))}})],2)])]),_vm._v(" "),(!_vm.modalMode && label.label_type === 'client')?_c('div',{staticClass:"flex-row flex-align flex-justify--between gap--small"},[_c('woot-base-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(label.active ? 'Deactivate' : 'Activate'),expression:"label.active ? 'Deactivate' : 'Activate'"}],attrs:{"tag":"span","size":"small","variant":"tertiary"},on:{"click":function($event){return _vm.toggleLabelActivation(label.id)}}},[_c('woot-switch',{attrs:{"id":label.id,"value":label.active}})],1),_vm._v(" "),(_vm.canHaveNestedLabel(label))?_c('woot-base-button',{attrs:{"tag":"span","size":"small","variant":"tertiary"},on:{"click":function($event){return _vm.onOpenAddPopup(label.id)}}},[_c('icons',{attrs:{"name":"plus","color":"lightgrey-fill-only","size":"medium","view":"-1 -1 24 24","show-title":false}})],1):_vm._e(),_vm._v(" "),_c('woot-single-selector',{attrs:{"variant":"tertiary","custom-style":{
            borderRadius: '0.8rem',
            boxShadow: 'none',
            left: 'auto',
            right: '0.8rem',
            width: 'auto',
          },"icon-size-prop":"semimedium","icon":"threeDot","show-option-name":false,"show-selected-text":false,"option-list":_vm.actionsList(label)},on:{"click":function (action, e) { return _vm.onActionSelected(e, action, label); }}})],1):_vm._e()]),_vm._v(" "),(_vm.canHaveNestedLabel(label))?_c('nested-label',{staticClass:"label-open",style:(!_vm.hasNestedLabel(label) ? 'padding: 0' : ''),attrs:{"list":label.labels,"parent-label-id":_vm.parentLabelId,"selected-tags":_vm.selectedTags,"resource":_vm.resource,"selected-tag":_vm.selectedTag,"on-open-add-popup":_vm.onOpenAddPopup,"on-open-delete-popup":_vm.onOpenDeletePopup,"modal-mode":_vm.modalMode,"search":_vm.search,"on-label-select":_vm.onLabelSelect,"on-tag-select":_vm.onTagSelect,"on-view-tag":_vm.onViewTag,"selected-tags-to-perform-action":_vm.selectedTagsToPerformAction}}):_vm._e()],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }