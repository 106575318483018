<template>
  <div class="views-container">
    <current-view-tab @showViewsManager="showViewsManager = true" />
    <views-manager
      v-if="showViewsManager"
      @onChange="onChange"
      @close="showViewsManager = false"
    />
  </div>
</template>
<script>
import ViewsManager from './List';
import CurrentViewTab from './components/CurrentViewTab';

export default {
  components: { ViewsManager, CurrentViewTab },
  data() {
    return {
      showViewsManager: false,
    };
  },
  methods: {
    onChange() {
      this.$emit('onChange');
    },
    onClose() {
      this.showViewsManager = false;
    },
  },
};
</script>
