import * as types from '../../mutation-types';
import IntelligenceApi from 'dashboard/api/intelligence/intelligence';

// actions
const actions = {
  getImprovedText: async ({ commit }, payload) => {
    commit(types.default.IMPROVING_TEXT_UI_FLAG, { isImprovingText: true });

    try {
      const {
        data: { improved_content },
      } = await IntelligenceApi.improveTextWithGpt(payload);
      commit(types.default.IMPROVING_TEXT_UI_FLAG, { isImprovingText: false });

      return improved_content;
    } catch (error) {
      commit(types.default.IMPROVING_TEXT_UI_FLAG, { isImprovingText: false });

      throw error;
    }
  },
};

export default actions;
