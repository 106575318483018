/* global axios */
import ApiClient from './ApiClient';

class CSATReportsAPI extends ApiClient {
  constructor() {
    super('csat_survey_responses', { accountScoped: true });
  }

  get(
    {
      page,
      startDate,
      endDate,
      workingHours,
      ticketType,
      inboxes,
      agent_id,
    } = {},
    signal
  ) {
    const payload = {
      page,
      start_date: startDate,
      end_date: endDate,
      working_hours: workingHours,
      ticket_type: ticketType,
      inboxes,
      query_type: 'csat_responses',
      agent_id,
    };

    return axios.get(`${this.baseUrl()}/integrations/analytics/csat`, {
      params: payload,
      signal,
    });
  }

  getMetrics(
    { startDate, endDate, workingHours, ticketType, inboxes, agent_id, scope },
    signal
  ) {
    const payload = {
      start_date: startDate,
      end_date: endDate,
      working_hours: workingHours,
      ticket_type: ticketType,
      inboxes,
      query_type: 'csat_metrics',
      agent_id: agent_id,
      scope,
    };

    return axios.get(`${this.baseUrl()}/integrations/analytics/csat`, {
      params: payload,
      signal,
    });
  }

  download({
    startDate,
    endDate,
    workingHours,
    ticketType,
    inboxes,
    agent_id,
    email,
    scope,
  } = {}) {
    let payload = {
      start_date: startDate,
      end_date: endDate,
      working_hours: workingHours,
      ticket_type: ticketType,
      inboxes,
      email,
      agent_id,
      scope,
    };

    return axios.get(
      `${this.baseUrl()}/integrations/analytics/download_report`,
      {
        params: payload,
      }
    );
  }
}

export default new CSATReportsAPI();
