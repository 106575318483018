export const assigneeType = {
  ME: 'me',
  UNASSIGNED: 'unassigned',
  ALL: 'all',
};

export const statusType = {
  OPEN: 'open',
  RESOLVED: 'resolved',
  WAITING: 'waiting',
  FOLLOW_UP: 'followup',
  BOT: 'bot',
  CLOSED: 'closed',
  OUTBOUND: 'outbound',
};

export default {
  APP_BASE_URL: process.env.APP_BASE_URL || '/',
  get apiURL() {
    return `${this.APP_BASE_URL}/`;
  },
  UNAUTHORIZED_STATUSES: [401, 403],
  GRAVATAR_URL: 'https://www.gravatar.com/avatar/',
  ASSIGNEE_TYPE: { ...assigneeType },
  STATUS_TYPE: { ...statusType },
  CONDITIONS_TO_REMOVE_ASSIGNEE_TAB: {
    [statusType.OPEN]: [],
    [statusType.RESOLVED]: [assigneeType.UNASSIGNED],
    [statusType.WAITING]: [],
    [statusType.FOLLOW_UP]: [assigneeType.UNASSIGNED],
    [statusType.BOT]: [assigneeType.ME, assigneeType.UNASSIGNED],
    [statusType.CLOSED]: [assigneeType.ME, assigneeType.UNASSIGNED],
    [statusType.OUTBOUND]: [assigneeType.ME],
  },
  CONTACT_TAB_TYPE: {
    CONTACT: 'contact',
    ORDERS: 'orders',
    PRODUCTS: 'products',
    CART: 'cart',
  },
  DEFAULT_CONVERSATION_FILTERS: {
    assigneeType: assigneeType.ME,
    status: statusType.OPEN,
    page: 1,
    inboxId: undefined,
    starred: undefined,
    oldest: undefined,
    unread: undefined,
    alert: undefined,
    isNew: undefined,
    labels: undefined,
    query: undefined,
    teamId: undefined,
    identifier: undefined,
    sla_breached: undefined,
    selectedAgent: null,
    statusTimeRange: null,
    assigned_by: [],
    resolved_by: [],
    closed_by: [],
  },
  DEFAULT_CONVERSATION_FILTERS_DEPRECATED: {
    assigneeType: assigneeType.ME,
    status: statusType.OPEN,
    page: 1,
    inboxId: undefined,
    starred: undefined,
    oldest: undefined,
    unread: undefined,
    alert: undefined,
    isNew: undefined,
    labels: undefined,
    query: undefined,
    teamId: undefined,
    identifier: undefined,
    sla_breached: undefined,
    selectedAgent: null,
    statusTimeRange: { default: 2 },
    assigned_by: [],
    resolved_by: [],
    closed_by: [],
  },
  QUICK_ACTION_ALERT_FOR_NON_EMAIL: [
    {
      NAME: '10 Min',
      VALUE: { HH: '00', mm: '10' },
    },
    {
      NAME: '30 Min',
      VALUE: { HH: '00', mm: '30' },
    },
    {
      NAME: '01 Hour',
      VALUE: { HH: '01', mm: '00' },
    },
    {
      NAME: '03 Hour',
      VALUE: { HH: '03', mm: '00' },
    },
    {
      NAME: '01 Day',
      VALUE: { HH: '24', mm: '00' },
    },
    {
      NAME: 'Select Manually',
      VALUE: 'manual',
    },
  ],
  QUICK_ACTION_ALERT_FOR_EMAIL: [
    {
      NAME: '10 Min',
      VALUE: { HH: '00', mm: '10' },
    },
    {
      NAME: '30 Min',
      VALUE: { HH: '00', mm: '30' },
    },
    {
      NAME: '01 Hour',
      VALUE: { HH: '01', mm: '00' },
    },
    {
      NAME: '03 Hour',
      VALUE: { HH: '03', mm: '00' },
    },
    {
      NAME: '01 Day',
      VALUE: { HH: '24', mm: '00' },
    },
    {
      NAME: '02 Day',
      VALUE: { HH: '48', mm: '00' },
    },
    {
      NAME: '03 Day',
      VALUE: { HH: '72', mm: '00' },
    },
    {
      NAME: 'Select Manually',
      VALUE: 'manual',
    },
  ],
};

// onboarding modal
export const ONBOARDING_SCREENS = {
  WELCOME: { key: 'WELCOME', component: 'WelcomeScreen' },
  EDUCATE_USER: { key: 'EDUCATE_USER', component: 'EducateUser' },
  WA_FORM: { key: 'WA_FORM', component: 'Create360Inbox' },
  THREE_SIXTY_CONNECT: {
    key: 'THREE_SIXTY_CONNECT',
    component: 'ThreeSixtyConnectButton',
  },
  WA_SETUP_IN_PROGRESS: {
    key: 'WA_SETUP_IN_PROGRESS',
    component: 'SetupStatus',
  },
  WABA_INTEGRATION_COMPLETE: {
    key: 'WABA_INTEGRATION_COMPLETE',
    component: 'WabaIntegrationComplete',
  },
  CONFIGURATION_ERROR: {
    key: 'CONFIGURATION_ERROR',
    component: 'ConfigurationError',
  },
  FINISH_SETUP: {
    key: 'FINISH_SETUP',
    component: 'FinishSetup',
  },
};

// in app feedback capture
export const FEATURES_FOR_FEEDBACK = {
  AI_SUMMARY: 'ai_summary',
};

export const SOURCE_TYPES = {
  CONVERSATION: 'conversation',
  ACCOUNT: 'account',
  USER: 'user',
};

export const LC_APPS = [
  {
    id: 'crm',
    name: 'Helpdesk',
    url: '/',
  },
  {
    id: 'marketing',
    name: 'Marketing',
    url: process.env.CAMPAIGN_URL,
  },
  {
    id: 'automation',
    name: 'Automation',
    url: process.env.AUTOMATION_URL,
  },
];

export const CALL_DIRECTION = {
  INBOUND: 'INBOUND',
  OUTBOUND: 'OUTBOUND',
};

export const CALL_PROVIDERS = { EXOTEL: 'exotel', KNOWLARITY: 'knowlarity' };

// TODO: remove this after scalable solution is implemented
// This is only being done because of a custom client request made by AccountId:27732
export const FORBIDDEN_CUSTOM_SOLUTION_URL =
  'https://portal.maqsam.com/phone/dialer#autodial=';
