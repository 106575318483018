import { differenceInDays } from 'date-fns';
import billingMixin from 'dashboard/mixins/billing';

export default {
  mixins: [billingMixin],
  methods: {
    disableBilling(data) {
      if (!data) {
        return false;
      }
      if (!data.shopify_client) {
        return true;
      }
      return this.countryCodeCheck(data);
    },
    addBillingRestrictions(data) {
      return (
        this.isShopifyBilling &&
        (this.freeTrialHasEnded(data?.subscription?.trial_end) ||
          this.isBeyondGracePeriod(data?.grace_period_last_date) ||
          (data?.shopify_trial_taken && !data?.subscription))
      );
    },
    formatDateString(date) {
      return date.toLocaleDateString('en-us', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    sendPrevDate(date) {
      var prev_date = new Date(date);
      prev_date.setDate(prev_date.getDate() - 1);
      return prev_date.toDateString();
    },
    sendNextDate(date) {
      var next_date = new Date(date);
      next_date.setDate(next_date.getDate() + 1);
      return next_date.toLocaleDateString('en-us', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    countryCodeCheck(data) {
      const isCountryCodeNull = data?.country_code === null;
      const isCountryNameNull = data?.country_name === null;
      const isCountryCodeIndia = data?.country_code?.toLowerCase() === 'in';
      const isCountryNameIndia = data?.country_name?.toLowerCase() === 'india';

      return (
        (isCountryCodeNull && isCountryNameNull) ||
        (isCountryCodeIndia && isCountryNameIndia)
      );
    },
    showFreeTrialBanner(date, isOnGracePeriod) {
      return (
        differenceInDays(new Date(this.sendPrevDate(date)), new Date()) < 2 &&
        differenceInDays(new Date(this.sendPrevDate(date)), new Date()) >= 0 &&
        !isOnGracePeriod
      );
    },
    pendingTrialDays(date) {
      let daysLeft = differenceInDays(new Date(date), new Date()) + 1;
      if (daysLeft === 1) return daysLeft + ' DAY LEFT';
      return daysLeft + ' DAYS LEFT';
    },
    freeTrialHasEnded(date) {
      let daysLeft;
      if (date)
        daysLeft = differenceInDays(
          new Date(this.sendPrevDate(date)),
          new Date()
        );

      return daysLeft < 0;
    },
    isOnFreeTrial(date, isOnGracePeriod) {
      let daysLeft = this.differenceInDays(
        new Date(this.sendPrevDate(date)),
        new Date()
      );

      return daysLeft >= 0 && !isOnGracePeriod;
    },
    showStarterPlanCta(date, onGracePeriod) {
      let daysLeft = this.differenceInDays(new Date(date), new Date());

      return daysLeft === 0 && !onGracePeriod;
    },
    isBeyondGracePeriod(graceDate) {
      if (graceDate) {
        let isGracePeriodInPast = differenceInDays(
          new Date(),
          new Date(this.sendPrevDate(graceDate))
        );
        return isGracePeriodInPast > 0;
      }
      return false;
    },
    hasNoActivePlan(status, onGracePeriod, currentTermEnd) {
      let termEndOver = this.differenceInDays(
        new Date(),
        new Date(this.sendPrevDate(currentTermEnd))
      );
      return status === 'cancelled' && termEndOver > 0 && onGracePeriod;
    },
    isGracePeriodLastDay(date) {
      if (date) {
        let days = this.differenceInDays(
          new Date(this.sendPrevDate(date)),
          new Date(new Date().toDateString())
        );

        return days === 0;
      }
      return false;
    },
  },
};
