<template>
  <div class="filter flex-column gap--small">
    <div v-for="(group, groupIndex) in filterList" :key="groupIndex">
      <div
        v-if="group.GROUP_NAME"
        class="title-h6_m text-light mg-bottom--smaller"
        v-text="group.GROUP_NAME"
      />
      <div
        class="filters-container flex-row flex-wrap flex-align--start gap--small"
      >
        <template v-for="item in group.FILTERS">
          <woot-single-selector
            v-if="item.key === 'assignee_type'"
            :key="item.key"
            size="small"
            :button-style="{
              borderRadius: '4px',
              color: '#808975',
              padding: '0.4rem 0.6rem',
            }"
            :custom-style="{ left: 0 }"
            :show-selected-text="false"
            highlight-selected
            :default-option="defaultAssigneeTabOption.name"
            :option-list="assigneeTabsList"
            @click="onUpdateAssigneeTab"
          />
          <woot-single-selector
            v-else-if="item.key === 'inbox'"
            :key="item.key"
            :class="`all-${item.key}--filter`"
            :front-icon="inboxFilterIcon ? inboxFilterIcon : 'inbox'"
            size="small"
            :option-list="modifiedInboxList"
            :highlight-selected="highlightInboxName"
            :button-style="{
              borderRadius: '4px',
              color: '#808975',
              padding: '0.4rem',
            }"
            :custom-style="{
              left: 0,
              width: '20rem',
              'overflow-x': 'hidden',
            }"
            :show-selected-text="false"
            :show-option-tooltip="true"
            :static-title="item.static_title"
            :default-option="selectedInboxComputed"
            @click="applyInboxFilter"
          />
          <woot-single-selector
            v-else-if="item.key === 'sla_breached'"
            :key="item.key"
            class="sla-filter"
            size="small"
            :front-icon="item.icon"
            :option-list="slaOptions"
            :default-option="selectedSlaOption"
            :button-style="{
              borderRadius: '4px',
              color: '#808975',
              padding: '0.4rem 0.6rem',
            }"
            :custom-style="{
              left: 0,
              width: '20rem',
              'overflow-x': 'hidden',
            }"
            :show-selected-text="false"
            @click="onSlaChange"
          />
          <woot-multi-selector
            v-else-if="item.key === 'relevance'"
            :key="item.key"
            ref="multiSelector"
            size="small"
            :front-icon="item.icon"
            :option-list="filters"
            :show-selected-text="false"
            default-option="Relevance"
            prefix-text="<b style='color: #6bac1b'>Sorted By: </b>"
            :toggle-filter="handleSelections"
            :button-style="{
              borderRadius: '4px',
              color: '#808975',
              padding: '0.4rem',
            }"
            :custom-style="{
              left: 0,
              width: '20rem',
              'overflow-x': 'hidden',
            }"
            @dropdown-opened="handleDropdownOpened"
            @selected-option="handleSelectedOption"
          />
          <agents-filter
            v-else-if="item.key === 'agent'"
            :key="item.key"
            :item="item"
            :selected-agent-id="appliedFilters.selectedAgent"
            :toggle-filter="filterToggle"
          />
          <tags-filter
            v-else-if="item.key === 'label' && labels"
            :key="item.key"
            ref="tagsFilter"
            highlight-selected
            size="small"
            class="filter--button"
            :front-icon="item.icon"
            :static-title="item.static_title"
            :value="item.value"
            :button-style="{
              borderRadius: '4px',
              color: '#808975',
              padding: '0.4rem',
            }"
            :toggle-filter="(_, __, value) => filterToggle('label', __, value)"
            @labelSelected="handleLabelClick"
          />
          <teams-filter
            v-else-if="item.key === 'teams'"
            :key="item.key"
            :item="item"
            :selected-team-id="appliedFilters.teamId"
            :toggle-filter="filterToggle"
          />
          <woot-single-selector
            v-else-if="item.key === 'assigned_by' && showAssignedByFilter"
            :key="item.key"
            size="small"
            :front-icon="item.icon"
            :option-list="assignedByOptions"
            :default-option="assignedByOption"
            :highlight-selected="highlightAssignedBy"
            :button-style="{
              borderRadius: '4px',
              color: '#808975',
              padding: '0.4rem 0.6rem',
            }"
            :custom-style="{
              left: 0,
              width: '20rem',
              'overflow-x': 'hidden',
            }"
            :show-selected-text="false"
            @click="onAssignedByChange"
          />
          <woot-single-selector
            v-else-if="item.key === 'resolved_by' && showResolvedByFilter"
            :key="item.key"
            size="small"
            :front-icon="item.icon"
            :option-list="resolvedByOptions"
            :default-option="resolvedByOption"
            :highlight-selected="highlightResolvedBy"
            :button-style="{
              borderRadius: '4px',
              color: '#808975',
              padding: '0.4rem 0.6rem',
            }"
            :custom-style="{
              left: 0,
              width: '180px',
            }"
            :show-selected-text="false"
            @click="onResolvedByChange"
          />
          <woot-single-selector
            v-else-if="item.key === 'closed_by' && showClosedByFilter"
            :key="item.key"
            size="small"
            :front-icon="item.icon"
            :option-list="closedByOptions"
            :default-option="closedByOption"
            :highlight-selected="highlightClosedBy"
            :button-style="{
              borderRadius: '4px',
              color: '#808975',
              padding: '0.4rem 0.6rem',
            }"
            :custom-style="{
              left: 0,
              width: '180px',
            }"
            :show-selected-text="false"
            @click="onClosedByChange"
          />
          <status-time-filter
            v-else-if="item.key === 'status_time'"
            :key="item.key"
            :filter-title="item.static_title"
            :default-selection="defaultRangeSelection"
            @date-range-change="onDateRangeChange"
          />
          <facebook-filter
            v-else-if="item.key === 'facebook' && isFacebookInbox"
            :key="item.key"
            :item="item"
            :toggle-filter="filterToggle"
            :selected-option="identifier"
          />
          <conversation-status
            v-else-if="item.key === 'conversation_status'"
            :key="item.key"
            size="small"
            :active-status="activeStatus"
            :button-style="{ padding: '0.4rem 0.6rem' }"
            @statusFilterChange="updateStatusType"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TagsFilter from './TagsFilter';
import AgentsFilter from './AgentsFilter';
import StatusTimeFilter from './StatusTimeFilterDeprecated';
import FacebookFilter from './FacebookFilter';
import TeamsFilter from './TeamsFilter';
import ConversationStatus from './ConversationStatus';

import inboxMixin from 'dashboard/mixins/inbox';
import conversationMixin from 'dashboard/mixins/conversations';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import assigneeTypeMixin from 'dashboard/mixins/assigneeTypeMixin';

import wootConstants from 'dashboard/constants';

export default {
  components: {
    TagsFilter,
    AgentsFilter,
    FacebookFilter,
    StatusTimeFilter,
    TeamsFilter,
    ConversationStatus,
  },
  mixins: [
    inboxMixin,
    conversationMixin,
    googleAnalyticsMixin,
    mixPanelAnalyticsMixin,
    assigneeTypeMixin,
  ],
  props: {
    filterList: {
      type: Array,
      default: () => [],
    },
    appliedFilters: {
      type: Object,
      default: () => ({}),
    },
    parentComponentName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedInbox: null,
      assignedBy: null,
      resolvedBy: null,
      closedBy: null,
      identifier: null,
      labels: null,
      selectedFilters: [],
      slaOptions: [
        { id: 1, name: 'SLA Breached', value: true },
        { id: 2, name: 'SLA Not Breached', value: undefined },
      ],
      filters: [
        { id: 1, name: 'unread' },
        { id: 2, name: 'alert' },
        { id: 3, name: 'starred' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      inboxes: 'inboxes/getInboxes',
      activeInbox: 'getSelectedInbox',
    }),
    highlightAssignedBy() {
      return this.assignedBy && this.assignedBy !== 'All';
    },
    highlightResolvedBy() {
      return this.resolvedBy && this.resolvedBy !== 'All';
    },
    highlightClosedBy() {
      return this.closedBy && this.closedBy !== 'All';
    },
    assignedByOption() {
      return !this.assignedBy || this.assignedBy === 'All'
        ? 'Assigned By'
        : this.assignedBy;
    },
    resolvedByOption() {
      return !this.resolvedBy || this.resolvedBy === 'All'
        ? 'Resolved By'
        : this.resolvedBy;
    },
    closedByOption() {
      return !this.closedBy || this.closedBy === 'All'
        ? 'Closed By'
        : this.closedBy;
    },
    inboxList() {
      return this.inboxes.map(inbox => {
        let { name: icon, color } = this.computedInboxClass(inbox);

        return {
          id: inbox.id,
          name: inbox.name,
          status: inbox.status,
          type: inbox.channel_type,
          channel_id: inbox.channel_id,
          phoneNumber: inbox.phone_number,
          instagram_add_on: inbox.instagram_add_on,
          selected_agent: null,
          icon: icon,
          color: color,
        };
      });
    },
    inboxFilterIcon() {
      const inboxIcon = this.inboxes.filter(
        inbox => this.selectedInboxComputed?.id === inbox.id
      );
      return this.computedInboxClass(inboxIcon[0]).name;
    },
    modifiedInboxList() {
      return [
        {
          name: 'All',
          id: 0,
        },
        ...this.inboxList,
      ];
    },
    inbox() {
      return (
        this.selectedInbox ||
        this.$store.getters['inboxes/getInbox'](this.activeInbox)
      );
    },
    selectedInboxComputed() {
      if (this.inbox.id) {
        return {
          id: this.inbox.id,
          name: this.inbox.name,
          icon: this.computedInboxClass(this.inbox).name,
        };
      }

      return { id: 0, name: 'All Inboxes' };
    },
    highlightInboxName() {
      const inboxName = this.selectedInboxComputed.name;
      return !!inboxName && inboxName !== 'All Inboxes';
    },
    isFacebookInbox() {
      const inboxIndex = this.inboxes.findIndex(
        item => item.id === Number(this.selectedInboxComputed.id)
      );
      return (
        inboxIndex > -1 &&
        this.inboxes[inboxIndex].channel_type === 'Channel::FacebookPage'
      );
    },
    showAssignedByFilter() {
      if (this.activeAssignee === 'unassigned') {
        return false;
      }
      if (this.activeStatus === 'bot') {
        return false;
      }
      return true;
    },
    showResolvedByFilter() {
      return this.activeAssignee === 'all' && this.activeStatus === 'resolved';
    },
    showClosedByFilter() {
      return this.activeAssignee === 'all' && this.activeStatus === 'closed';
    },
    assignedByOptions() {
      return this.$t('FILTERS.ASSIGNED_BY_LIST');
    },
    resolvedByOptions() {
      return this.$t('FILTERS.RESOLVED_BY_LIST');
    },
    closedByOptions() {
      return this.$t('FILTERS.CLOSED_BY_LIST');
    },
    activeStatus() {
      return this.appliedFilters.status || wootConstants.STATUS_TYPE.OPEN;
    },
    activeAssignee() {
      return this.appliedFilters.assigneeType || wootConstants.ASSIGNEE_TYPE.ME;
    },
    defaultAssigneeTabOption() {
      return this.assigneeTabsList.find(tab => tab.key === this.activeAssignee);
    },
    defaultRangeSelection() {
      return this.appliedFilters?.statusTimeRange?.default;
    },
    isSlaBreached() {
      return this.appliedFilters.sla_breached;
    },
    selectedSlaOption() {
      const selectedOptionName = this.isSlaBreached
        ? this.slaOptions[0].name
        : this.slaOptions[1].name;

      return selectedOptionName;
    },
  },
  mounted() {
    this.setFilterState(this.appliedFilters);
  },
  methods: {
    setFilterState(filters) {
      const {
        identifier,
        assigned_by,
        resolved_by,
        closed_by,
        labels,
        inboxId,
      } = filters;

      this.selectedInbox = this.inboxes.find(inbox => inbox.id === inboxId);
      this.identifier = identifier;
      this.assignedBy = assigned_by?.length
        ? 'Assigned by ' + assigned_by
        : 'All';
      this.resolvedBy = resolved_by?.length
        ? 'Resolved by ' + resolved_by
        : 'All';
      this.closedBy = closed_by?.length ? 'Closed by ' + closed_by : 'All';
      this.labels = labels || [];
      this.$nextTick(() => {
        this.$refs.tagsFilter[0].$refs.multiSelector.selectedOptionId = this.labels;
      });
    },
    computedInboxClass(child) {
      if (child && child?.channel_type) {
        const { channel_type, phone_number, instagram_add_on } = child;
        const classByType = this.getInboxClassByType(
          channel_type,
          phone_number,
          '',
          instagram_add_on
        );

        return classByType;
      }

      return '';
    },
    updateStatusType(val) {
      this.$emit('filterChange', 'conversation_status', val);
    },
    filterToggle(key, _, val) {
      if (this.selectedInboxComputed.name !== 'All Inboxes') {
        this.$router.push(
          `/app/accounts/${this.accountId}/inbox/${this.selectedInboxComputed.id}`
        );
      } else {
        this.$router.push(`/app/accounts/${this.accountId}/dashboard`);
      }

      this.$emit('filterChange', key, val);
    },
    applyInboxFilter(val) {
      this.selectedInbox = val;
      this.$emit('filterChange', 'change_inbox', val);
    },
    onAssignedByChange(key) {
      if (this.selectedInboxComputed.name !== 'All Inboxes') {
        this.$router.push(
          `/app/accounts/${this.accountId}/inbox/${this.selectedInboxComputed.id}`
        );
      } else {
        this.$router.push(`/app/accounts/${this.accountId}/dashboard`);
      }
      this.assignedBy = key.name;

      if (key.id === 'none') {
        this.$emit('filterChange', 'assigned_by', null);
      } else {
        this.$emit('filterChange', 'assigned_by', key.id);
      }
    },
    onResolvedByChange(key) {
      if (this.selectedInboxComputed.name !== 'All Inboxes') {
        this.$router.push(
          `/app/accounts/${this.accountId}/inbox/${this.selectedInboxComputed.id}`
        );
      } else {
        this.$router.push(`/app/accounts/${this.accountId}/dashboard`);
      }
      this.resolvedBy = key.name;
      if (key.id === 'none') {
        this.$emit('filterChange', 'resolved_by', null);
      } else {
        this.$emit('filterChange', 'resolved_by', key.id);
      }
    },
    onClosedByChange(key) {
      if (this.selectedInboxComputed.name !== 'All Inboxes') {
        this.$router.push(
          `/app/accounts/${this.accountId}/inbox/${this.selectedInboxComputed.id}`
        );
      } else {
        this.$router.push(`/app/accounts/${this.accountId}/dashboard`);
      }
      this.closedBy = key.name;
      if (key.id === 'none') {
        this.$emit('filterChange', 'closed_by', null);
      } else {
        this.$emit('filterChange', 'closed_by', key.id);
      }
    },
    onDateRangeChange(value) {
      this.$emit('filterChange', 'status_time', value);
    },
    handleLabelClick(selectedLabel) {
      this.mix_panel_clicked_tag_filter_menu_item(
        this.parentComponentName,
        selectedLabel.label_type
      );
    },
    handleDropdownOpened() {
      this.mix_panel_clicked_ticket_sort();
    },
    handleSelectedOption(selectedOptionValue) {
      this.mix_panel_sorted_tickets(selectedOptionValue);
    },
    handleSelections(_, newSelection) {
      this.selectedFilters = newSelection;
      const filterName = 'relevance';
      this.$emit('filterChange', filterName, newSelection);
      this.logFilterChange(newSelection);
    },
    onUpdateAssigneeTab(value) {
      this.$emit('filterChange', 'assignee_type', value);
    },
    onSlaChange(option) {
      this.$emit('filterChange', 'sla_breached', option.value);
    },
  },
};
</script>
