<template>
  <div class="inbox-selection-section">
    <section>
      <label class="formlabel">
        <span class="title-h5 text-light">
          {{ $t('CUSTOM_TICKET_FIELDS.SUMMARY.SUB_HEADINGS.INBOXES') }}
        </span>
      </label>
      <woot-single-selector
        size="small"
        :option-list="inactiveInboxes"
        default-option="Select inbox for field"
        :custom-style="{
          left: 'auto',
          width: '100%',
          maxHeight: '20rem',
        }"
        :dropdown-container-style="{ width: '36rem' }"
        @click="addInbox"
      />
      <div class="flex-row flex-wrap mg-top--normal">
        <woot-chips
          v-for="(inbox, idx) in customFieldInboxes"
          :key="idx"
          show-cancel-icon
          :show-tooltip="false"
          variant="primary-small"
          :title="inbox.name"
          :front-icon="getInboxIcon(inbox).name"
          style="width: fit-content; max-width: 23.2rem;"
          @click="removeInbox(inbox)"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import inboxMixin from 'dashboard/mixins/inbox';

export default {
  mixins: [inboxMixin],
  props: {
    customFieldInboxes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
    }),
    inactiveInboxes() {
      let selectedIds = this.customFieldInboxes.map(item => {
        return item.id;
      });

      return this.inboxes
        .filter(item => !selectedIds.includes(item.id))
        .map(inbox => {
          const inboxIcon = this.getInboxIcon(inbox);
          return {
            ...inbox,
            icon: inboxIcon.name,
            color: inboxIcon.color,
          };
        });
    },
  },
  methods: {
    getInboxIcon(inbox) {
      return this.getInboxClassByType(
        inbox?.channel_type,
        inbox?.phone_number,
        '',
        inbox?.instagram_add_on
      );
    },
    addInbox(inbox) {
      this.$emit('addInbox', inbox);
    },
    removeInbox(inbox) {
      this.$emit('removeInbox', inbox);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.inbox-selection-section {
  padding: $space-normal;
  width: 50rem;
  background-color: $neutral-grey-100;
  border-radius: $border-radius;
  border: 1px solid $neutral-grey-300;
}
</style>
