<template>
  <div class="products-dropdown--container custom-scroll">
    <woot-loading-state
      v-if="uiFlags.fetchingProducts"
      :message="$t('PRODUCTS_MGMT.LIST.LOADING')"
    />
    <div v-else-if="!filteredProductList.length" class="no-order">
      <img
        :src="require('dashboard/assets/images/noOrder.svg')"
        alt="No Product"
      />
      <p class="no-items-error-message">
        {{ $t('PRODUCTS_MGMT.NO_PRODUCTS') }}
      </p>
    </div>
    <template v-else>
      <search-box
        :placeholder="$t('PRODUCTS_MGMT.SEARCH_TXT')"
        :value="search"
        :variant="true"
        :on-search="searchProducts"
        custom-class="no-border"
        @setSearch="setSearch"
      />
      <div
        v-for="product in filteredProductList"
        :key="product.id"
        class="product-container"
      >
        <product-option
          :product="product"
          @closeDropdown="$emit('closeDropdown')"
        />
      </div>
      <woot-loading-state
        v-if="uiFlags.fetchingProducts"
        :message="$t('PRODUCTS_MGMT.LIST.LOADING')"
      />
      <div class="text-center">
        <span
          v-if="
            !uiFlags.fetchingProducts &&
              filteredProductList.length &&
              hasNextPage
          "
          class="clear button load-more-conversations"
          @click="onPageChange"
        >
          {{ $t('PRODUCTS_MGMT.LIST.LOAD_MORE') }}
        </span>
        <span
          v-if="
            !uiFlags.fetchingProducts &&
              filteredProductList.length &&
              !hasNextPage
          "
          class="text-muted end-of-list-text"
        >
          {{ $t('PRODUCTS_MGMT.LIST.LOADED') }}
        </span>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import ProductOption from './ProductOption';
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';

import * as types from 'shared/constants/googleEventType';

import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';

export default {
  components: { ProductOption, SearchBox },
  mixins: [googleAnalyticsMixin],
  data() {
    return { search: '', page: 1 };
  },
  computed: {
    ...mapGetters({
      productList: 'getProducts',
      uiFlags: 'getProductsUIFlags',
      meta: 'getProductsMeta',
    }),
    filteredProductList() {
      return this.productList;
    },
    hasNextPage() {
      const isDisabled = this.page === Math.ceil(this.meta.count / 8);
      return !isDisabled;
    },
  },
  created() {
    this.$store.dispatch('getProducts', {
      page: 1,
      is_shopify: true,
    });
  },
  methods: {
    searchProducts() {
      if (this.search) {
        this.googleAnalyticsEvent(
          types.default.PRODUCTS_SEARCH,
          types.default.PRODUCTS_PANEL,
          this.search
        );
        this.$store.dispatch('getProducts', {
          query: this.search,
          is_shopify: true,
          page: 1,
        });
      }
    },
    setSearch(value) {
      const refetchAllproducts = !!this.search && value === '';
      if (refetchAllproducts) {
        this.$store.dispatch('getProducts', {
          page: 1,
          is_shopify: true,
        });
      }
      this.search = value;
    },

    onPageChange() {
      this.page += 1;
      if (this.search) {
        this.$store.dispatch('getProducts', {
          query: this.search,
          page: this.page,
          append: true,
          is_shopify: true,
        });
      } else {
        this.$store.dispatch('getProducts', {
          page: this.page,
          append: true,
          is_shopify: true,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.products-dropdown {
  &--container {
    @include shadow-dark;
    background: $color-white;
    border: 1px solid $neutral-grey-600;
    border-radius: 0 0 $space-small $space-small;
    max-height: 52.8rem;
    min-height: 14.5rem;
    overflow: overlay;
    position: absolute;
    width: 100%;
    z-index: 1;

    .no-border {
      border: none;
      margin-bottom: $zero;
    }

    .no-order {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: $space-slab;
      width: 100%;

      img {
        max-width: 12rem;
        margin-bottom: $space-slab;
      }

      p {
        color: $secondary-blue;
        font-size: $font-size-default;
        line-height: $space-medium;
      }
    }
  }
}
</style>
