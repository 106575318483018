<template>
  <div>
    <div
      v-if="isMenuStyled && uiFlags.isFetching"
      class="loading skeleton-animation"
    />
    <woot-single-selector
      v-else
      size="small"
      :custom-button="!isMenuStyled"
      :default-option="selectedInboxName"
      :top-position="{ top: '4rem' }"
      :custom-style="{
        left: '0.01rem',
        maxHeight: maxHeight,
        width: '20rem',
      }"
      :dropdown-container-style="{ backgroundColor: 'white' }"
      :option-list="waAndEmailInboxes"
      @click="onClick"
    >
      <woot-base-button
        variant="secondary"
        size="medium"
        front-icon="plus"
        icon-view-box="0 0 20 20"
      >
        Ticket
      </woot-base-button>
    </woot-single-selector>
    <woot-delete-modal-new
      :show.sync="showWarningModal"
      :on-close="hidePopup"
      :on-confirm="() => onSelectOption(selectedInbox)"
      show-close
      :title="$t('CREATE_TICKET.WARNING_MODAL.TITLE')"
      :message="$t('CREATE_TICKET.WARNING_MODAL.MESSAGE')"
      confirm-text="Confirm"
      reject-text="Back"
      :custom-style="{
        'max-width': '44rem',
        height: 'auto',
        textAlign: 'center',
      }"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import mixpanelEvent from 'dashboard/helper/mixpanel';

import { isAWhatsAppChannel } from 'shared/mixins/inboxMixin';

const INBOX_OPTIONS_ZERO_STATE = 'Select an Inbox';

export default {
  props: {
    isMenuStyled: {
      type: Boolean,
      default: false,
    },
    inbox: {
      type: Object,
      default: null,
    },
    maxHeight: {
      type: String,
      default: '15rem',
    },
  },
  data() {
    return {
      showWarningModal: false,
      selectedInbox: null,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      waInboxes: 'inboxes/getWhatsAppInboxes',
      emailInboxes: 'inboxes/getEmailInboxes',
      uiFlags: 'inboxes/getUIFlags',
    }),
    selectedInboxName() {
      return this.inbox?.name || INBOX_OPTIONS_ZERO_STATE;
    },
    waAndEmailInboxes() {
      return [
        ...this.waInboxes.map(({ id, name, channel_type: type }) => {
          return {
            id,
            name,
            type,
            icon: 'whatsApp',
            color: 'whatsapp',
            isWhatsAppInbox: true,
          };
        }),
        ...this.emailInboxes.map(({ id, name, channel_type: type }) => {
          return {
            id,
            name,
            type,
            icon: 'email',
            color: 'warning',
            isEmailInbox: true,
          };
        }),
      ];
    },
  },
  methods: {
    captureMixPanelEvents() {
      if (this.isMenuStyled)
        mixpanelEvent('clicked_inbox_via_create_ticket_filter');
      else mixpanelEvent('clicked_inbox_via_create_ticket_button');
    },
    isSameTypeInbox(currentInbox, nextInbox) {
      return (
        currentInbox.type === nextInbox.type ||
        (isAWhatsAppChannel(currentInbox) && isAWhatsAppChannel(nextInbox))
      );
    },
    onClick(inbox) {
      this.selectedInbox = inbox;
      if (this.inbox && !this.isSameTypeInbox(this.inbox, inbox)) {
        this.showWarningModal = true;
      } else this.onSelectOption(inbox);
    },
    hidePopup() {
      this.showWarningModal = false;
    },
    onSelectOption({ id: inbox_id }) {
      this.captureMixPanelEvents();

      this.hidePopup();

      const baseRoute = `/app/accounts/${this.accountId}/create-ticket`;
      const targetRoute = inbox_id
        ? `${baseRoute}/inbox/${inbox_id}`
        : baseRoute;

      this.$router.push(targetRoute);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.loading {
  border-radius: $border-radius-smaller;
  width: $space-medium * 5;
  height: $height-h1;
  padding: $space-one $space-slab;
}
.warning {
  color: red;
  text-align: center;
}
</style>
