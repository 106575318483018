import * as types from 'dashboard/store/mutation-types';
import AnalyticsApi from 'dashboard/api/limekit/analytics';

const fetchData = async (
  commit,
  params,
  mutationType,
  uiFlag,
  metrics = undefined
) => {
  commit(types.default.SET_DEEP_SHOPIFY_ANALYTICS_UI_FLAGS, { [uiFlag]: true });
  try {
    const { data } = await AnalyticsApi.getDeepShopifyData({
      ...params,
      metrics,
    });
    commit(types.default.SET_DEEP_SHOPIFY_ANALYTICS_UI_FLAGS, {
      [uiFlag]: false,
    });
    commit(mutationType, data);
    return data;
  } catch (error) {
    commit(types.default.SET_DEEP_SHOPIFY_ANALYTICS_UI_FLAGS, {
      [uiFlag]: false,
    });
    throw new Error(error);
  }
};

const actions = {
  fetchMetricsData: async ({ commit }, params) => {
    const metrics =
      'orders_count,orders_cancelled_count,orders_refunded_count,orders_edited_events_count';
    return fetchData(
      commit,
      { ...params, status: undefined },
      types.default.SET_DEEP_SHOPIFY_ANALYTICS_METRICS,
      'fetchingMetrics',
      metrics
    );
  },
  fetchTopOrderedProducts: async ({ commit }, params) => {
    const metrics = 'top_ordered_products';
    return fetchData(
      commit,
      { page: 1, items_per_page: 5, ...params },
      types.default.SET_DEEP_SHOPIFY_ANALYTICS_TOP_ORDERED_PRODUCTS,
      'fetchingTopOrderedProducts',
      metrics
    );
  },
  fetchTopCancelledProducts: async ({ commit }, params) => {
    const metrics = 'top_cancelled_products';
    return fetchData(
      commit,
      { page: 1, items_per_page: 5, ...params },
      types.default.SET_DEEP_SHOPIFY_ANALYTICS_TOP_CANCELLED_PRODUCTS,
      'fetchingTopCancelledProducts',
      metrics
    );
  },
  fetchPeriodicOrdersRevenue: async ({ commit }, params) => {
    const metrics = 'orders_periodic_count';
    return fetchData(
      commit,
      { ...params, status: undefined },
      types.default.SET_DEEP_SHOPIFY_ANALYTICS_PERIODIC_ORDER_COUNT,
      'fetchingPeriodicOrdersCount',
      metrics
    );
  },
  fetchCodPrepaidSplit: async ({ commit }, params) => {
    const metrics = 'orders_cod_split';
    return fetchData(
      commit,
      { ...params, status: undefined },
      types.default.SET_DEEP_SHOPIFY_ANALYTICS_COD_PREPAID_SPLIT,
      'fetchingCodPrepaidSplit',
      metrics
    );
  },
  fetchOrders: async ({ commit }, params) => {
    const metrics = 'all_orders';
    return fetchData(
      commit,
      { page: 1, items_per_page: 5, ...params },
      types.default.SET_DEEP_SHOPIFY_ORDERS,
      'fetchingOrders',
      metrics
    );
  },
  fetchStatuses: async ({ commit }) => {
    try {
      const {
        data: { results },
      } = await AnalyticsApi.getDeepShopifyOrderStatusesList();
      commit(types.default.SET_DEEP_SHOPIFY_ANALYTICS_ORDER_STATUSES, results);
      return results;
    } catch (error) {
      throw new Error(error);
    }
  },
  downloadReport: async (_, params) => {
    try {
      await AnalyticsApi.downloadDeepShopifyData(params);
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default actions;
