<template>
  <section class="notification--table-wrap custom-scroll">
    <woot-base-button
      v-if="notificationMetadata.unreadCount"
      class="mg-bottom--one"
      :loading="isUpdating"
      front-icon="tickRound"
      icon-size="medium"
      preserve-case
      @click="onMarkAllAsRead"
    >
      {{ $t('NOTIFICATIONS_PAGE.MARK_ALL_AS_READ') }}
    </woot-base-button>

    <table class="woot-table notifications-table">
      <tbody v-show="!isLoading">
        <tr
          v-for="notificationItem in notifications"
          :key="notificationItem.id"
          @click="() => onClickNotification(notificationItem)"
        >
          <td>
            <div
              v-if="!notificationItem.read_at"
              class="notification--unread-indicator"
            />
          </td>
          <td>
            <h5
              class="notification--title title-h5 text-dark"
              v-text="
                `#${
                  notificationItem.primary_actor
                    ? notificationItem.primary_actor.id
                    : 'deleted'
                }`
              "
            />
            <span
              class="notification--message-title body-b3 text-light line-clamp-2"
              v-text="notificationItem.push_message_title"
            />
          </td>
          <td class="text-align">
            <span
              class="notification--type text-dark title-h5_m"
              v-text="
                $t(
                  `NOTIFICATIONS_PAGE.TYPE_LABEL.${notificationItem.notification_type}`
                )
              "
            />
          </td>
          <td class="thumbnail--column">
            <thumbnail
              v-if="notificationItem.primary_actor.meta.assignee"
              :src="notificationItem.primary_actor.meta.assignee.thumbnail"
              size="36px"
              :username="notificationItem.primary_actor.meta.assignee.name"
            />
          </td>
          <td>
            <div class="text-align timestamp--column">
              <span
                class="title-h5_m notification--created-at text-light"
                v-text="dynamicTime(notificationItem.created_at)"
              />
            </div>
          </td>
          <td>
            <mark-as-read-button
              v-if="!notificationItem.read_at"
              :notification="notificationItem"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <empty-state
      v-if="showEmptyResult"
      :title="$t('NOTIFICATIONS_PAGE.LIST.404')"
    />
    <div v-if="isLoading" class="notifications--loader title-h4">
      <spinner />
      <span v-text="$t('NOTIFICATIONS_PAGE.LIST.LOADING_MESSAGE')" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import Thumbnail from 'dashboard/components/widgets/Thumbnail';
import Spinner from 'shared/components/Spinner';
import EmptyState from 'dashboard/components/widgets/EmptyState';
import MarkAsReadButton from './MarkAsReadButton';

import timeMixin from 'dashboard/mixins/time';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: {
    Thumbnail,
    Spinner,
    EmptyState,
    MarkAsReadButton,
  },
  mixins: [timeMixin, mixPanelAnalyticsMixin],
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    onClickNotification: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isUpdating: false,
    };
  },
  computed: {
    ...mapGetters({
      notificationMetadata: 'notifications/getMeta',
    }),
    showEmptyResult() {
      return !this.isLoading && this.notifications.length === 0;
    },
  },
  methods: {
    onMarkAllAsRead() {
      this.isUpdating = true;
      this.mix_panel_clicked_mark_all_notifications_as_read();
      this.$store.dispatch('notifications/readAll').then(() => {
        this.isUpdating = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.notification--title {
  font-size: var(--font-size-small);
  margin: 0;
}

.notification--table-wrap {
  flex: 1 1;
  height: 100%;
  padding: var(--space-medium);
}

.notifications-table {
  > tbody {
    > tr {
      cursor: pointer;

      &:hover {
        background: var(--b-50);
      }

      &.is-active {
        background: var(--b-100);
      }

      > td {
        &.conversation-count-item {
          padding-left: var(--space-medium);
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.notifications--loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-big);
}

.notification--unread-indicator {
  width: var(--space-one);
  height: var(--space-one);
  border-radius: 50%;
  background: var(--warn-red-500);
}

.thumbnail--column {
  width: 5.2rem;
}

.timestamp--column {
  min-width: 13rem;
}
</style>
