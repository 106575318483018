<template>
  <woot-modal-new :show="true" :on-close="() => $emit('onClose')">
    <woot-modal-header-new header-title="Import Template Responses" />
    <div class="modal-content-wrapper flex-column flex-justify--between">
      <div class="flex-column gap--normal">
        <div
          :style="{
            backgroundImage: `url(${require('assets/images/import_template.svg')})`,
          }"
          class="illustration-container"
        />
        <span
          class="body-b2 text-light"
          v-text="
            'Please upload the Template responses in the given format. If you don’t have a template ready, feel free to download here and fill & upload accordingly'
          "
        />
      </div>
      <div class="flex-row flex-justify--between">
        <woot-base-button
          to="/example_template.csv"
          variant="secondary"
          @click.native="InlineButtonClickHandler"
        >
          Download Template
        </woot-base-button>
        <woot-base-button class="custom-import-btn" :loading="isUploading">
          <file-upload
            :size="4096 * 4096"
            accept=".csv"
            @input-file="onFileUpload"
          >
            Upload Csv
          </file-upload>
        </woot-base-button>
      </div>
    </div>
  </woot-modal-new>
</template>
<script>
import FileUpload from 'vue-upload-component';
import ImportDataApi from 'dashboard/api/importData';
import * as types from 'shared/constants/googleEventType';

import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: { FileUpload },
  mixins: [googleAnalyticsMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      isUploading: false,
    };
  },
  methods: {
    async onFileUpload(file) {
      this.googleAnalyticsEvent(
        types.default.TEMPLATE_IMPORT,
        types.default.TEMPLATE_SETTINGS,
        types.default.TEMPLATE_SETTINGS
      );
      this.mix_panel_clicked_settings_import_upload_csv_template();
      if (!file) return;

      this.isUploading = true;
      try {
        await ImportDataApi.create({ file: file.file, type: 'template' });
        this.$store.dispatch('getTemplate');
        this.isUploading = false;
        this.$emit('onClose');
      } catch {
        // handle error
      } finally {
        this.isUploading = false;
      }
    },
    InlineButtonClickHandler() {
      this.mix_panel_clicked_settings_import_download_example_template();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.modal-content-wrapper {
  height: 80%;

  .illustration-container {
    height: 21.6rem;
    max-width: 62.4rem;
    background: $neutral-grey-300;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: $border-radius-smaller;
  }

  .custom-import-btn {
    position: relative;
    width: $space-normal * 10;

    ::v-deep .file-uploads {
      position: absolute;
      width: 100%;

      label {
        cursor: pointer;
      }
    }
  }
}
</style>
