import { CONTENT_TYPES } from '../constants/contentType';

export default {
  computed: {
    validContentType() {
      return (
        this.isCards ||
        this.isInputSelect ||
        this.isInputCsat ||
        this.isSticker ||
        this.isProductMessage ||
        this.isProductList
      );
    },
    isEmailContentType() {
      return this.contentType === CONTENT_TYPES.INCOMING_EMAIL;
    },
    isCards() {
      return this.contentType === CONTENT_TYPES.OUTGOING_CARDS;
    },
    isInputSelect() {
      return this.contentType === CONTENT_TYPES.OUTGOING_BUTTONS;
    },
    isInputCsat() {
      return this.contentType === CONTENT_TYPES.OUTGOING_CSAT;
    },
    isSticker() {
      return this.contentType === CONTENT_TYPES.OUTGOING_STICKER;
    },
    isProductMessage() {
      return this.contentType === CONTENT_TYPES.OUTGOING_PRODUCT_MESSAGE;
    },
    isProductList() {
      return this.contentType === CONTENT_TYPES.OUTGOING_PRODUCT_LIST;
    },
  },
};
