<template>
  <woot-modal :show.sync="show" :on-close="onClose" small-modal>
    <div class="column content-box">
      <div class="column page-top-bar modal-header">
        <span class="page-sub-title title-h2" v-text="modalHeader" />
      </div>
      <div class="row settings-form">
        <div class="time-selection-container">
          <div
            class="text-light body-b2 mg-bottom--small"
            v-text="$t('CHAT_LIST.SELECT_TIME')"
          />
          <div class="medium-12 flex-row flex-wrap gap--slab mg-bottom--slab">
            <label
              v-for="item in quickActions"
              :key="item.NAME"
              :for="item.NAME"
              class="radio-container"
              style="cursor:pointer"
            >
              <span class="body-b2 text-light" v-text="item.NAME" />
              <input
                :id="item.NAME"
                type="radio"
                name="quick-action"
                :value="alertDelay"
                :checked="isChecked(item)"
                @change="onChangeQuickAction(item.VALUE)"
              />
              <span class="radio-circle" />
            </label>
          </div>
          <div v-if="showTimePicker" class="timepicker">
            <vue-timepicker
              v-if="!isEmailChannel"
              v-model="alertDelay"
              hide-clear-button
              :minute-interval="10"
              format="HH:mm"
            />
            <date-picker
              v-else
              :value="selectedDateTime"
              class="medium-6"
              disable-previous-date
              :disabled-future-dates="disableAfter3DaysFromCurrent"
              type="datetime"
              variant="small"
              format="YYYY-MM-DD HH:mm"
              :show-range="false"
              :show-second="false"
              @change="onSelectDateTime"
            />
          </div>
        </div>
        <div v-if="showNextStatus" class="mg-top">
          <div
            class="body-b3 text-light mg-bottom--small"
            v-text="$t('CHAT_LIST.STATUS_DROPDOWN')"
          />
          <woot-single-selector
            :option-list="statusActionList"
            :default-option="selectedNextStatus"
            :custom-style="{
              left: 0,
              top: 0,
              width: '100%',
            }"
            @click="changeNextStatus"
          />
        </div>
        <div class="flex-row mg-top--larger mg-bottom flex-align--start">
          <img src="~/assets/images/light-bulb.svg" class="mg-right--small" />
          <div class="body-b3 text-light" v-text="modalContent" />
        </div>
        <div class="modal-footer">
          <woot-base-button
            size="small"
            :disabled="isSubmitting"
            @click="onSubmit"
          >
            {{ $t('CHAT_LIST.SAVE') }}
          </woot-base-button>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { differenceInHours, differenceInMinutes } from 'date-fns';
import { mapGetters } from 'vuex';
import VueTimepicker from 'vue2-timepicker';
import DatePicker from 'dashboard/components/ui/DatePicker';

import { INBOX_TYPES } from 'shared/mixins/inboxMixin';
import wootConstants, { statusType } from 'dashboard/constants';

export default {
  components: { VueTimepicker, DatePicker },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
    onClose: { type: Function, default: () => {} },
    onClick: { type: Function, default: () => {} },
  },
  data() {
    return {
      alertDelay: { HH: '00', mm: '10' },
      selectedDateTime: new Date(),
      isSubmitting: false,
      statusActionList: [
        {
          name: 'Open',
          value: statusType.OPEN,
        },
        {
          name: 'Resolve',
          value: statusType.RESOLVED,
        },
      ],
      selectedNextStatus: statusType.OPEN,
      showTimePicker: false,
    };
  },
  computed: {
    ...mapGetters({ currentChat: 'getSelectedChat' }),
    disableAfter3DaysFromCurrent() {
      const date = new Date();
      return new Date(date.setDate(date.getDate() + 3));
    },
    modalHeader() {
      if (this.status === statusType.WAITING)
        return this.$t('CHAT_LIST.WAITING_HEADER');

      return this.$t('CHAT_LIST.FOLLOW_UP_HEADER');
    },
    modalContent() {
      if (this.status === statusType.WAITING)
        return this.$t('CHAT_LIST.WAITING_TEXT');

      return this.$t('CHAT_LIST.FOLLOW_UP_TEXT');
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentChat.inbox_id);
    },
    isEmailChannel() {
      return this.inbox.channel_type === INBOX_TYPES.EMAIL;
    },
    quickActions() {
      if (this.isEmailChannel)
        return wootConstants.QUICK_ACTION_ALERT_FOR_EMAIL;

      return wootConstants.QUICK_ACTION_ALERT_FOR_NON_EMAIL;
    },
    showNextStatus() {
      return this.status === statusType.WAITING;
    },
  },
  methods: {
    isChecked(item) {
      return JSON.stringify(item.VALUE) === JSON.stringify(this.alertDelay);
    },
    calculateTimeDifference(receivedDate) {
      const currentDate = new Date();

      const hoursDiff = Math.abs(differenceInHours(receivedDate, currentDate));
      const totalMinutesDiff = Math.abs(
        differenceInMinutes(receivedDate, currentDate)
      );
      const minutesDiff = totalMinutesDiff % 60;

      return {
        HH: hoursDiff.toString().padStart(2, '0'),
        mm: minutesDiff.toString().padStart(2, '0'),
      };
    },

    onChangeQuickAction(val = { HH: '00', mm: '00' }) {
      if (val === 'manual') {
        this.showTimePicker = true;
        return;
      }
      this.showTimePicker = false;
      this.alertDelay = val;
    },
    changeNextStatus({ value }) {
      this.selectedNextStatus = value;
    },
    onSelectDateTime(value) {
      this.selectedDateTime = new Date(value);
      this.alertDelay = this.calculateTimeDifference(this.selectedDateTime);
    },
    async onSubmit() {
      this.isSubmitting = true;
      await this.onClick(this.alertDelay, this.selectedNextStatus).finally(
        () => {
          this.isSubmitting = false;
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.modal-header {
  @include elevation-1dp;
}

.settings-form {
  padding: $space-normal;
}

.time-selection-container {
  background-color: $neutral-grey-200;
  border-radius: $border-radius;
  padding: $space-slab;
}

.timepicker {
  .vue__time-picker {
    font-size: $font-size-small;
    width: auto;
    font-family: inherit;
  }

  &::v-deep input {
    cursor: default;
    margin-bottom: $zero;
    background: $neutral-white;
  }

  &::v-deep ul {
    &::-webkit-scrollbar {
      height: 0.1em;
      transition: all 0.5s;
      width: 0.4em;
      z-index: 10;
    }

    & ::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $neutral-grey-500;
      border-radius: 0.1em;
    }
  }
}

.modal-footer {
  padding: $zero;
}
</style>
