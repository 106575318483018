<template>
  <div class="contact-conversation--panel">
    <div v-if="!uiFlags.isFetching">
      <span v-if="!previousConversations.length" class="body-b2 text-dark">
        {{ $t('CONTACT_PANEL.CONVERSATIONS.NO_RECORDS_FOUND') }}
      </span>
      <div v-else class="contact-conversation--list">
        <conversation-card
          :key="previousConversations[0].id"
          :show-select-option="false"
          active-tab="me"
          active-status="open"
          :chat="previousConversations[0]"
          :hide-inbox-name="false"
          :hide-thumbnail="true"
          class="compact"
          :custom-style="{
            border: '0.5px solid #D9D9D9',
            'border-radius': '8px',
            'margin-bottom': '8px',
          }"
          :custom-card-style="{
            padding: '8px',
          }"
          @cardClick="handleCardClickEvent"
        />
        <span
          v-if="!showAllConversations && previousConversations.length > 1"
          class="see-more-conv body-b3"
          @click="showMoreConversations"
        >
          See More ({{ previousConversations.length - 1 }})
        </span>
        <div v-else>
          <conversation-card
            v-for="conversation in previousConversations.slice(1)"
            :key="conversation.id"
            :show-select-option="false"
            active-tab="me"
            active-status="open"
            :chat="conversation"
            :hide-inbox-name="false"
            :hide-thumbnail="true"
            class="compact"
            :custom-style="{
              border: '0.5px solid #D9D9D9',
              'border-radius': '8px',
              'margin-bottom': '8px',
            }"
            :custom-card-style="{
              padding: '8px',
            }"
            @cardClick="handleCardClickEvent"
          />
          <span
            class="see-more-conv body-b3"
            @click="showAllConversations = false"
          >
            See Less
          </span>
        </div>
      </div>
    </div>
    <div v-else class="mg-top--small">
      <spinner />
    </div>
  </div>
</template>

<script>
import ConversationCard from 'dashboard/components/widgets/conversation/ConversationCard.vue';
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner.vue';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import * as types from 'shared/constants/googleEventType';

export default {
  components: {
    ConversationCard,
    Spinner,
  },
  mixins: [googleAnalyticsMixin, mixPanelAnalyticsMixin],
  props: {
    contactId: {
      type: Number,
      default: null,
    },
    conversationId: {
      type: [String, Number],
      required: true,
    },
    parentComponentName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showAllConversations: false,
    };
  },
  computed: {
    conversations() {
      let filteredConversation = this.$store.getters[
        'contactConversations/getContactConversation'
      ](this.contactId);

      return filteredConversation
        .sort((a, b) => a.timestamp - b.timestamp)
        .reverse();
    },
    previousConversations() {
      return this.conversations.filter(
        conversation => conversation.id !== Number(this.conversationId)
      );
    },
    ...mapGetters({
      uiFlags: 'contactConversations/getUIFlags',
    }),
  },
  watch: {
    contactId(newContactId, prevContactId) {
      if (newContactId && newContactId !== prevContactId) {
        this.$store.dispatch('contactConversations/get', newContactId);
      }
    },
  },
  mounted() {
    this.googleAnalyticsEvent(
      types.default.CONTACT_CONVERSATION_OPENED,
      types.default.CONTACT_INFO_OR_PREVIOUS_CONVO,
      this.contactId
    );
    if (this.contactId) {
      this.$store.dispatch('contactConversations/get', this.contactId);
    }
  },
  methods: {
    showMoreConversations() {
      this.showAllConversations = true;
    },
    handleCardClickEvent() {
      if (this.parentComponentName === 'TicketOverview') {
        this.mix_panel_clicked_previous_ticket();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.contact-conversation--list {
  margin-top: $space-slab;
}

.see-more-conv {
  display: flex;
  justify-content: flex-end;
  color: $secondary-blue;
  cursor: pointer;

  &:hover {
    color: $facebook-blue;
  }
}
</style>
