<template>
  <div class="preview--container">
    <div class="image--container">
      <div class="preview--image">
        <img v-if="isTypeImage(files[0].type)" :src="files[0].url" />
        <img
          v-else-if="isTypePdf(files[0].type)"
          :width="size"
          :height="size"
          src="../../../assets/images/pdf.svg"
        />
        <img
          v-else
          :width="size"
          :height="size"
          src="../../../assets/images/file.svg"
        />
      </div>
      <span class="cancel--preview">
        <icons
          name="cancel"
          color="stroke-grey"
          size="semimedium"
          :custom-style="{
            strokeWidth: '2',
            cursor: 'pointer',
          }"
          @click.prevent="$emit('clear', files[0].id)"
        />
      </span>
    </div>
    <span>{{ `${files[0].name} (${formatFileSize(files[0].size)})` }}</span>
  </div>
</template>

<script>
import { formatBytes } from 'dashboard/helper/files';

export default {
  props: {
    files: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      default: '120px',
    },
  },
  methods: {
    formatFileSize(size) {
      return formatBytes(size, 0);
    },
    isTypeImage(type) {
      return type.includes('image');
    },
    isTypePdf(type) {
      return type.includes('pdf');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.preview--container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: $space-normal;
  width: 100%;
}

.image--container {
  @include card-shadow;

  border-radius: $border-radius;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: $space-small;
}

.preview--image {
  border-radius: $border-radius;
  max-height: 20rem;
  max-width: 20rem;
  overflow: hidden;
}

.cancel--preview {
  background: $neutral-white;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  right: -$space-small;
  top: -$space-small;
  z-index: 100;
}

.cancel--preview ::v-deep svg {
  margin-right: 0;
}
</style>
