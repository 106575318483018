<template>
  <section>
    <section-header translation-key="REMINDERS" />
    <aside class="sla-reminders">
      <div
        v-for="metricKey in reminders"
        :key="metricKey"
        class="filter filter--with-border"
        :class="{
          'mg-bottom--medium':
            reminders.indexOf(metricKey) !== reminders.length - 1,
        }"
      >
        <span>When SLA of </span>
        <metric-selector
          :class="'sla-reminders__metric_selector__' + metricKey"
          :value="metricKey"
          :excluded-options="reminders"
          @click="onSelectMetric($event, metricKey)"
        />
        <template v-if="metricKey !== 'select'">
          <span>approaches in</span>
          <time-duration-selector
            :value="data.reminders[metricKey].before"
            :include-immediate-option="false"
            @click="onSelectTimeDuration($event, metricKey)"
          />
        </template>
        <div class="flex-row flex-align gap--slab">
          <template v-if="metricKey !== 'select'">
            <span style="white-space: nowrap">Send Reminder to</span>
            <multi-select-trigger-values
              class="multi-select-filter"
              :class="'multi-select-filter__' + metricKey"
              :value="getValues(metricKey)"
              :dropdown-values="getDropdownValues(assigneeTrigger)"
              @input="handleValues($event, metricKey)"
            />
          </template>
          <woot-base-button
            tag="span"
            variant="tertiary-danger"
            @click="deleteCondition(metricKey)"
          >
            <icons
              name="bin"
              color="red"
              size="semimedium"
              :show-title="false"
            />
          </woot-base-button>
        </div>
      </div>
      <!-- add filter button -->
      <div v-if="reminders.length !== 3" class="mg-top">
        <add-filter-button
          :label="$t('SLA.CREATE.SECTIONS.METRICS.ADD_FILTER')"
          @click="onAddFilter"
        />
      </div>
    </aside>
  </section>
</template>
<script>
import MetricSelector from './components/selectors/MetricSelector.vue';
import SectionHeader from './components/shared/Header';
import AddFilterButton from './components/shared/AddFilterButton';
import MultiSelectTriggerValues from './components/selectors/MultiSelector';
import TimeDurationSelector from './components/selectors/TimeDurationSelector.vue';

import { onAddKey, onSelectKey } from '../../../utils/form';

import formMixin from '../../../mixins/formMixin';
import { TRIGGERS } from '../../../utils/constants';

export default {
  components: {
    SectionHeader,
    MetricSelector,
    AddFilterButton,
    TimeDurationSelector,
    MultiSelectTriggerValues,
  },
  mixins: [formMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    reminders() {
      return this.data?.reminders ? Object.keys(this.data?.reminders) : {};
    },
    assigneeTrigger() {
      return TRIGGERS.ASSIGNEE;
    },
  },
  methods: {
    getValues(metricKey) {
      return this.getDropdownValues(this.assigneeTrigger).filter(item =>
        this.data.reminders[metricKey].data.includes(item.id)
      );
    },
    onAddFilter() {
      const newReminder = onAddKey(this.data, 'reminders');
      if (newReminder) this.$emit('update', newReminder);
    },
    onSelectMetric({ value: selectedMetric }, metricKey) {
      const newPayload = onSelectKey(
        this.data,
        selectedMetric,
        metricKey,
        'reminders'
      );

      // Emit the updated SLA data
      if (newPayload) this.$emit('update', newPayload);
    },
    onSelectTimeDuration({ value: selectedTimeDuration }, metricKey) {
      const newPayload = {
        ...this.data,
        reminders: {
          ...this.data.reminders,
          [metricKey]: {
            ...this.data.reminders[metricKey],
            before: selectedTimeDuration,
          },
        },
      };

      // Emit the updated SLA data
      if (newPayload) this.$emit('update', newPayload);
    },
    handleValues(values, metricKey) {
      this.$emit('update', {
        ...this.data,
        reminders: {
          ...this.data.reminders,
          [metricKey]: {
            ...this.data.reminders[metricKey],
            data: values.map(value => value.id),
          },
        },
      });
    },
    deleteCondition(metricKey) {
      const updatedConditions = { ...this.data.reminders };
      delete updatedConditions[metricKey];

      this.$emit('update', {
        ...this.data,
        reminders: updatedConditions,
      });
    },
  },
};
</script>
