<template>
  <div class="small-12">
    <div class="flex-space-between" style="align-items : flex-start">
      <div class="message-display-container flex-column" style="width:50rem">
        <div class="selected-inbox">
          <span class="subtitle-s1 text-light">Inbox Channel</span>
          <span class="mg-top--small flex-row-center">
            <span class="mg-right--small">
              <icons name="whatsApp" size="medium" color="green" />
            </span>
            <span class="body-b2 text-dark">
              {{ selectedInbox.name }} - {{ selectedInbox.phone }}
            </span>
          </span>
        </div>
        <hr />
        <div v-if="selectedTemplate">
          <span class="subtitle-s1 text-light">Selected Template</span>
          <div
            v-if="selectedTemplate.template_type === 'image'"
            class="mg-top--medium body-b2 text-dark custom-scroll flex-space-between"
            style="max-width:42rem; max-height: 10rem; align-items: flex-start"
          >
            <div v-html="highlightedBrackets(selectedTemplate.body, true)" />
            <img
              v-if="firstRow.MediaURL"
              :src="firstRow.MediaURL"
              height="72px"
              width="72px"
              class="mg-left"
            />
          </div>
          <div
            v-else
            class="mg-top--medium body-b2 text-dark custom-scroll"
            style="max-width:42rem; max-height: 10rem"
          >
            <span v-html="highlightedBrackets(selectedTemplate.body, true)" />
          </div>
          <div
            class="subtitle-s1 text-light mg-bottom--slab"
            style="margin-top:2rem"
          >
            Variables
          </div>
          <div class="flex-row variable-container">
            <div v-for="(param, index) in parameters" :key="index">
              <div v-if="param" class="variable-chip text-truncate ">
                {{ param }}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div v-if="selectedTemplate">
          <span class="subtitle-s1 text-light">Test your Message</span>
          <div
            class="mg-top flex-row-center"
            style="width:40rem; align-items: flex-start"
          >
            <div style="min-width: 38rem">
              <woot-input
                v-model="testPhoneNumber"
                type="text"
                :class="{
                  error:
                    testPhoneNumber !== 'fake' && $v.testPhoneNumber.$error,
                }"
                :has-error="
                  testPhoneNumber !== 'fake' && $v.testPhoneNumber.$error
                "
                :error="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.PHONE.ERROR')"
                :placeholder="
                  $t('BROADCAST.BROADCAST_TAB.PHONE_INPUT_PLACEHOLDER')
                "
                @blur="$v.testPhoneNumber.$touch"
              />
            </div>
            <div class="mg-left--large" style="margin-top:0.6rem">
              <woot-secondary-button
                size="small"
                name="Test"
                :disabled="
                  testPhoneNumber === '' ||
                    ($v.testPhoneNumber.$error && testPhoneNumber !== 'fake')
                "
                @click="sendTestMessage"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mg-right whatsapp-position">
        <whatsapp-component :messages="messages" />
      </div>
    </div>
    <div class="flex-row flex-justify--end mg-top--medium mg-right">
      <woot-primary-button
        name="save and schedule"
        custom-class="mg-right"
        front-icon="history"
        size="medium"
        @click="scheduleBroadcast"
      />
      <woot-primary-button
        name="send now"
        front-icon="send"
        :custom-style="{ width: '12rem' }"
        size="medium"
        @click="sendBroadcast"
      />
    </div>
  </div>
</template>
<script>
import broadcastMixin from 'dashboard/mixins/broadcast';
import downloadMixin from 'shared/mixins/downloadMixin.js';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import WhatsappComponent from 'dashboard/components/WhatsappUIWidget';
import validatePhoneNumber from '../../../dashboard/settings/inbox/validations';
export default {
  components: {
    WhatsappComponent,
  },
  mixins: [broadcastMixin, downloadMixin, googleAnalyticsMixin],
  props: {
    selectedTemplate: {
      type: Object,
      default: () => {},
    },
    selectedInbox: {
      type: Object,
      default: () => {},
    },
    firstRow: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      testPhoneNumber: '',
    };
  },
  computed: {
    messages() {
      return [
        {
          type: 'received',
          value: this.highlightedBrackets(this.selectedTemplate.body, true),
          doc: this.selectedTemplate?.template_type === 'document',
          img: this.selectedTemplate?.template_type === 'image',
          buttons: this.selectedTemplate?.buttons,
        },
      ];
    },
    parameters() {
      return Object.keys(this.firstRow).map(val => {
        if (val.startsWith('Input')) return this.firstRow[val];
        return '';
      });
    },
  },
  validations: {
    testPhoneNumber: { ...validatePhoneNumber.phone },
  },

  methods: {
    sendTestMessage() {
      this.$emit('testMessage', this.testPhoneNumber);
      this.testPhoneNumber = null;
    },
    sendBroadcast() {
      this.$emit('sendBrd');
    },
    scheduleBroadcast() {
      this.$emit('scheduleBrd');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';
hr {
  margin: $space-two $zero;
}

.whatsapp-position {
  margin-top: -7rem;
}
.next-button {
  position: absolute;
  bottom: $space-slab;
  right: 7rem;
}
.variable-chip {
  padding: $space-smaller $space-small;
  background: $pg-light-1;
  color: $pg-1-700;
  text-transform: uppercase;
  font-size: $font-size-mini;
  font-weight: $font-weight-black;
  line-height: $height-h6;
  border-radius: $border-radius-smaller;
  max-width: 20rem;
}
.variable-container {
  flex-wrap: wrap;
  gap: $space-small;
  max-width: 90%;
}
</style>
