<template>
  <div class="small-12 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.VALUE_FIRST.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.VALUE_FIRST.DESC')"
    />
    <form @submit.prevent="createChannel">
      <div class="settings-sub-menu-content--larger mg-bottom--jumbo">
        <woot-input
          v-model.trim="channelName"
          required
          auto-focus
          :class="{ error: $v.channelName.$error }"
          :has-error="$v.channelName.$error"
          :error="$t('INBOX_MGMT.ADD.VALUE_FIRST.CHANNEL_NAME.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.VALUE_FIRST.CHANNEL_NAME.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.VALUE_FIRST.CHANNEL_NAME.PLACEHOLDER')
          "
          @blur="$v.channelName.$touch"
        />

        <woot-input
          v-model.trim="phoneNumber"
          required
          :class="{ error: $v.phoneNumber.$error }"
          :has-error="$v.phoneNumber.$error"
          :error="
            phoneNumber === ''
              ? $t('INBOX_MGMT.COMMON_ERROR_MESSAGE')
              : $t('INBOX_MGMT.ADD.VALUE_FIRST.PHONE_NUMBER.ERROR')
          "
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.VALUE_FIRST.PHONE_NUMBER.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.VALUE_FIRST.PHONE_NUMBER.PLACEHOLDER')
          "
          @blur="$v.phoneNumber.$touch"
        />

        <woot-input
          v-model.trim="user"
          required
          :class="{ error: $v.user.$error }"
          :has-error="$v.user.$error"
          :error="$t('INBOX_MGMT.ADD.VALUE_FIRST.USER.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.VALUE_FIRST.USER.LABEL')"
          :placeholder="$t('INBOX_MGMT.ADD.VALUE_FIRST.USER.PLACEHOLDER')"
          @blur="$v.user.$touch"
        />

        <woot-input
          v-model.trim="pass"
          required
          :class="{ error: $v.pass.$error }"
          :has-error="$v.pass.$error"
          :error="$t('INBOX_MGMT.ADD.VALUE_FIRST.PASS.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.VALUE_FIRST.PASS.LABEL')"
          :placeholder="$t('INBOX_MGMT.ADD.VALUE_FIRST.PASS.PLACEHOLDER')"
          @blur="$v.pass.$touch"
        />
      </div>
      <div class="medium-12 columns settings-sub-menu-bottom-nav">
        <woot-base-button
          variant="secondary"
          @click.prevent="() => $router.go(-1)"
        >
          Back
        </woot-base-button>
        <woot-base-button :loading="uiFlags.isCreating" type="submit">
          Next
        </woot-base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import { required } from 'vuelidate/lib/validators';
import validatePhoneNumber from '../validations';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      user: '',
      pass: '',
      channelName: '',
      phoneNumber: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    channelName: { required },
    phoneNumber: { ...validatePhoneNumber.phone, required },
    pass: { required },
    user: { required },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('AGENT_MGMT.FORM_ERROR'), 'error');
        return;
      }

      try {
        const valueFirst = await this.$store.dispatch(
          'inboxes/createValueFirstChannel',
          {
            value_first: {
              name: this.channelName,
              user: this.user,
              pass: this.pass,
              phone_number: this.phoneNumber,
            },
          }
        );

        const { id: inbox_id, channel_type, name: channel_name } = valueFirst;

        this.mix_panel_clicked_create_inbox_successful(
          'WhatsApp',
          channel_type
        );
        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id,
          },
          query: { channel_type, channel_name },
        });
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.VALUE_FIRST.API.ERROR_MESSAGE'),
          'error'
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.form-label {
  padding: $zero $space-small $space-one;
  color: $neutral-grey-800;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: 22px;
}

.content-margin {
  margin-bottom: 2.4rem;
}

.medium-12 {
  .button {
    float: right;
  }
}
</style>
