import { render, staticRenderFns } from "./ConfirmAndTest.vue?vue&type=template&id=612141f5&scoped=true&"
import script from "./ConfirmAndTest.vue?vue&type=script&lang=js&"
export * from "./ConfirmAndTest.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmAndTest.vue?vue&type=style&index=0&id=612141f5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "612141f5",
  null
  
)

export default component.exports