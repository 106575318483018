import { isDropdown } from 'dashboard/routes/dashboard/settings/customTicketFields/utils/helper';
import { CUSTOM_FIELD_TYPES } from 'dashboard/routes/dashboard/settings/customTicketFields/utils/constants';

function isUrl(fieldType) {
  return fieldType === CUSTOM_FIELD_TYPES.URL;
}

export function isDateOrTimeField(fieldType) {
  return (
    fieldType === CUSTOM_FIELD_TYPES.DATE ||
    fieldType === CUSTOM_FIELD_TYPES.TIME ||
    fieldType === CUSTOM_FIELD_TYPES.DATE_TIME
  );
}

export function getValuesFromData(
  { id, mandatory, field_type: fieldType },
  dataArray
) {
  let value;

  if (!dataArray?.length) value = '';
  else {
    const filteredEntries = dataArray.filter(
      field => field.custom_field_id === id
    );

    if (isDateOrTimeField(fieldType))
      value = filteredEntries.length ? new Date(filteredEntries[0].value) : '';
    else if (isDropdown(fieldType)) {
      value = filteredEntries.map(
        ({ custom_field_option_id: optionId, value: fieldOptionValue }) => ({
          id: optionId?.toString(),
          value: fieldOptionValue,
        })
      );
    } else value = filteredEntries.length ? filteredEntries[0].value : '';
  }

  return {
    custom_field_id: id,
    fieldType: fieldType,
    mandatory,
    url: isUrl(fieldType),
    value,
  };
}

export function filterUnchangedFields(fieldsArray) {
  return fieldsArray.filter(field => field.dirty);
}
