<template>
  <label :for="id" class="radio-container" :style="[customStyle, cursorStyle]">
    <span class="body-b2" :style="labelStyle"> {{ label }}</span>
    <input
      :id="id"
      type="radio"
      name="radio"
      :value="id"
      :disabled="disabled"
      @change="$emit('input', id)"
    />
    <span class="radio-circle" />
  </label>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
    labelStyle: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    cursorStyle() {
      return this.disabled ? { cursor: 'default' } : { cursor: 'pointer' };
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables';
.radio-container {
  display: block;
  margin-right: $space-normal;
  padding-left: $space-large;
  position: relative;
  user-select: none;
  input {
    cursor: pointer;
    opacity: 0;
    position: absolute;

    &:checked {
      ~ {
        .radio-circle {
          &::before {
            border-color: $pg-1-500;
          }

          &::after {
            background: $pg-1-500;
          }
        }
      }
    }
    &:disabled {
      ~ {
        .radio-circle {
          &::before {
            border-color: $neutral-grey-500;
          }

          &::after {
            background: $neutral-grey-500;
          }
        }
      }
    }
  }
}

.radio-circle {
  background: none;
  border-radius: 50%;
  height: $space-medium;
  left: 0;
  position: absolute;
  top: 0;
  width: $space-medium;

  &::before {
    border: 2px solid $neutral-grey-800;
    border-radius: 50%;
    content: '';
    height: $space-normal;
    left: $space-smaller;
    position: absolute;
    top: $space-smaller;
    width: $space-normal;
  }

  &::after {
    background: none;
    border-radius: 50%;
    content: '';
    height: $space-small;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: $space-small;
  }
}
</style>
