<template>
  <button
    class="secondary-button flex-space-between"
    :class="[size, customClass, `button-${size}`]"
    :disabled="disabled"
    :type="type"
    :name="name"
    :style="[customStyle, fullWidthClass]"
    @mouseover="hoverIcon"
    @mouseleave="leaveIcon"
    @click="$emit('click')"
  >
    <span v-if="frontIcon" :style="name && { 'margin-right': '0.8rem' }">
      <icons
        :name="frontIcon"
        :color="iconStyle"
        :size="icon"
        :layer-fill="layerFill"
      />
    </span>
    <span class="btn-name">{{ name }}</span>
    <span v-if="backIcon" :style="name && { 'margin-left': '0.8rem' }">
      <icons
        :name="backIcon"
        :color="iconStyle"
        :size="icon"
        :layer-fill="layerFill"
      />
    </span>
    <spinner
      v-if="loading"
      class="spinner-margin"
      :color="spinnerColor"
      :size="spinnerSize"
    />
  </button>
</template>

<script>
import Spinner from 'shared/components/Spinner';
export default {
  components: {
    Spinner,
  },
  props: {
    name: {
      type: String,
      default: 'submit',
    },
    size: {
      type: String,
      default: 'medium',
    },
    frontIcon: {
      type: String,
      default: '',
    },
    backIcon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: 'medium',
    },
    iconColor: {
      type: String,
      default: 'green',
    },
    iconHoverColor: {
      type: String,
      default: 'semidarkgreen',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'submit',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
    customClass: {
      type: [String, Array],
      default: undefined,
    },
    spinnerColor: {
      type: String,
      default: 'lime-green',
    },
    spinnerSize: {
      type: String,
      default: 'medium',
    },
    layerFill: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconHover: false,
    };
  },
  computed: {
    iconStyle() {
      if (this.disabled) return 'grey';
      if (this.iconHover) return this.iconHoverColor;
      return this.iconColor;
    },
    fullWidthClass() {
      if (this.fullWidth)
        return {
          width: '100%',
          'text-align': 'center',
          'justify-content': 'center',
        };
      return '';
    },
    icon() {
      if (this.iconSize) return this.iconSize;
      if (this.size === 'large') return 'medium';
      return 'semimedium';
    },
  },
  methods: {
    hoverIcon() {
      this.iconHover = true;
    },
    leaveIcon() {
      this.iconHover = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.secondary-button {
  border-radius: $border-radius-smaller;
  border: 1px solid $pg-1-500;
  cursor: pointer;
  white-space: nowrap;
  color: $pg-1-500;
  font-style: normal;
  justify-content: center;
  min-width: 8rem;

  &:hover {
    color: $pg-1-600;
    border: 1px solid $pg-1-600;
  }
  &:disabled {
    color: $neutral-grey-600;
    border: 1px solid $neutral-grey-600;
    pointer-events: none;
  }
}

.small {
  padding: $space-smaller $space-small;
}

.medium {
  padding: $space-small;
}

.large {
  padding: $space-small $space-slab;
}

.spinner-margin {
  margin: $zero $zero $space-micro $space-smaller;
}
</style>
