<template>
  <loading-state
    v-if="uiFlags.fetchingSchema"
    style="height: 20rem"
    :message="
      $t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.LEADSQUARED.FORM.LOADING')
    "
  />
  <loading-state
    v-else-if="uiFlags.fetchingList"
    style="height: 20rem"
    :message="
      $t(
        'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.LEADSQUARED.FORM.LOADING_DETAILS'
      )
    "
  />
  <form v-else @submit.prevent="onSubmit">
    <div
      v-for="fieldName in formFieldNames"
      :key="fieldName"
      class="content-margin"
    >
      <data-type-input
        :label="fieldName"
        :data-type="schema[fieldName].data_type"
        :value="formData[fieldName]"
        :validator="$v.formData[fieldName]"
        :update-value="newValue => updateFormData(fieldName, newValue)"
      />
    </div>
    <!-- Submission Button -->
    <div class="full-width flex-row flex-justify">
      <woot-base-button
        type="submit"
        :loading="uiFlags.creatingTicket"
        :disabled="$v.formData.$invalid"
      >
        Create Opportunity
      </woot-base-button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTypeInput from 'dashboard/components/forms/DataTypeInput';
import LoadingState from 'dashboard/components/ui/LoadingState';
import {
  initializeFormValues,
  initializeValidations,
} from 'dashboard/components/forms/utils/helper';
import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import { AVAILABLE_CRMS } from 'dashboard/routes/dashboard/conversation/overview/constants';

export default {
  components: { DataTypeInput, LoadingState },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return { formData: null };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'crm/getUIFlags',
      currentChat: 'getSelectedChat',
    }),
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    schema() {
      return this.$store.getters['crm/getFormSchema'](
        AVAILABLE_CRMS.LEADSQUARED
      );
    },
    formFieldNames() {
      return Object.keys(this.schema);
    },
  },
  created() {
    this.initData();
  },
  validations() {
    return { formData: initializeValidations(this.schema) };
  },
  inject: ['closeRaiseTicketSection'],
  methods: {
    initData() {
      this.fetchSchema();
    },
    updateFormData(fieldName, newValue) {
      this.$v.formData[fieldName].$model = newValue;
    },
    async fetchSchema() {
      try {
        await this.$store.dispatch(
          'crm/fetchFormSchemaForLeadSquared',
          AVAILABLE_CRMS.LEADSQUARED
        );
        this.formData = initializeFormValues(this.schema);
        this.populateFormValues();
      } catch {
        setTimeout(() => {
          this.closeRaiseTicketSection();
        }, 3000);
        this.showAlert(
          this.$t(
            'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.LEADSQUARED.SCHEMA.ERROR'
          ),
          'error'
        );
      }
    },
    populatePhoneNumber() {
      if (this.contact?.phone_number) {
        this.formData.contact_number = this.contact.phone_number;
      }
    },
    async populateFormValues() {
      try {
        const details = await this.fetchLeadDetails();
        this.formData = this.mergeFormData(details);
        this.populatePhoneNumber();
      } catch {
        this.showAlert(
          this.$t(
            'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.LEADSQUARED.LEAD_DETAILS.ERROR'
          ),
          'error'
        );
      }
    },
    async fetchLeadDetails() {
      const data = await this.$store.dispatch('crm/get', {
        selectedCrm: AVAILABLE_CRMS.LEADSQUARED,
        conversationId: this.currentChat.id,
      });

      return data.result;
    },
    mergeFormData(details) {
      return Object.keys(details).reduce(
        (acc, key) => {
          acc[key] =
            this.schema[key] && this.schema[key].data_type === 'date'
              ? new Date(details[key])
              : details[key];
          return acc;
        },
        { ...this.formData }
      );
    },
    constructPayload(payload) {
      return Object.keys(payload).reduce(
        (acc, key) => {
          acc[key] =
            this.schema[key] && this.schema[key].data_type === 'date'
              ? new Date(payload[key]).toISOString().split('T')[0]
              : payload[key];
          return acc;
        },
        { ...payload, selectedCrm: AVAILABLE_CRMS.LEADSQUARED }
      );
    },
    async onSubmit() {
      const payload = this.constructPayload(this.formData);
      try {
        await this.createOpportunity(payload);
      } catch {
        this.showAlert(
          this.$t(
            'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.LEADSQUARED.CREATE.ERROR'
          ),
          'error'
        );
      }
    },
    async createOpportunity(payload) {
      await this.$store.dispatch('crm/raiseTicket', payload);
      this.mix_panel_added_crm_ticket();
      this.closeRaiseTicketSection();
      this.showAlert(
        this.$t(
          'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.LEADSQUARED.CREATE.SUCCESS'
        ),
        'info'
      );
    },
  },
};
</script>
