<template>
  <div
    v-tooltip.bottom="title.replaceAll('_', ' ')"
    :class="labelClass"
    :style="{
      background: bgColor,
      color: textColor,
      borderRadius: '1.6rem',
    }"
    :title="description"
  >
    <span v-if="!href" class="label--box">
      {{ title }}
    </span>
    <a v-else :href="href" :style="{ color: textColor }">{{ title }}</a>
    <icons
      v-if="showIcon"
      :name="icon"
      size="medium"
      :custom-style="{
        fill: 'none',
        stroke: textColor,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        marginRight: '0.6rem',
        cursor: 'pointer',
      }"
      @click="onClick"
    />
  </div>
</template>
<script>
import { getContrastingTextColor } from 'shared/helpers/ColorHelper';
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: '#1f93ff',
    },
    small: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'cancel',
    },
  },
  computed: {
    textColor() {
      return getContrastingTextColor(this.bgColor);
    },
    labelClass() {
      return `label ${this.small ? 'small' : ''}`;
    },
  },
  methods: {
    onClick() {
      this.$emit('click', this.title);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.label {
  display: inline-flex;
  align-items: center;
  font-size: $font-size-mini;
  border-radius: $border-radius;
  line-height: $space-two;
  margin: $space-micro;
  padding: $zero;

  &--box {
    padding: 0.6rem $space-slab;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.small {
    font-size: $font-size-mini;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.label--icon {
  cursor: pointer;
  line-height: 1.5;
  margin-left: $space-smaller;
}
</style>
