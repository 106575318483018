<template>
  <loading-state style="height: 80vh" :message="message" />
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingState from 'dashboard/components/ui/LoadingState';
import { addToLocalStorage } from '../../helper/localStorage';
import { frontendURL } from '../../helper/URLHelper';

export default {
  components: { LoadingState },
  data() {
    return {
      count: 4,
      countdownInterval: null,
      message: 'Configuring shopify app...',
    };
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
  },
  mounted() {
    this.updateShopifyDetailsInLocalStorage();
    this.initCountdown();
    setTimeout(() => {
      this.redirectUser();
    }, 4000);
  },
  beforeDestroy() {
    if (this.countdownInterval) clearInterval(this.countdownInterval);
  },
  methods: {
    getUrlParams() {
      const url = new URL(window.location.href);
      const storeUrl = url.searchParams.get('shop');
      const accessToken = url.searchParams.get('accessToken');
      const countryCode = url.searchParams.get('countryCode');
      const countryName = url.searchParams.get('countryName');
      return { storeUrl, accessToken, countryCode, countryName };
    },
    updateShopifyDetailsInLocalStorage() {
      const {
        storeUrl,
        accessToken,
        countryCode,
        countryName,
      } = this.getUrlParams();

      addToLocalStorage({
        shopify_store_url: storeUrl,
        shopify_access_token: accessToken,
        shopify_country_code: countryCode,
        shopify_country_name: countryName,
      });
    },
    redirectUser() {
      window.location = frontendURL('login');
    },
    initCountdown() {
      this.countdownInterval = setInterval(() => {
        this.count -= 1;
        this.message = this.$auth.isAuthenticated
          ? `Redirecting to helpdesk in ${this.count}`
          : `You are not logged in, Redirecting to login page in ${this.count}`;

        if (this.count < 1) clearInterval(this.countdownInterval);
      }, 1000);
    },
  },
};
</script>
