<template>
  <accordian-card :custom-desc="true" custom-class="notes-container">
    <template v-slot:desc>
      <div class="address-btn flex-row flex-align flex-justify--between">
        <span style="width: 100%; cursor: pointer" class="subtitle-s2">
          Notes
        </span>
      </div>
    </template>
    <woot-input
      v-model="content"
      class="notes"
      rows="6"
      type="text"
      :placeholder="'Jot down something here...'"
      @change="changeNotesData"
    />
  </accordian-card>
</template>

<script>
import AccordianCard from 'dashboard/components/AccordianCard';

export default {
  components: { AccordianCard },
  data() {
    return {
      content: '',
    };
  },
  methods: {
    changeNotesData() {
      this.$emit('change-notes-data', this.content);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.notes-container {
  border: none;
  margin-bottom: $space-slab;
  padding: 0;

  .notes {
    margin-top: $space-slab;
  }
}
</style>
