<template>
  <div>
    <thumbnail
      :src="avatarSource"
      :username="name"
      bg-color="#6bac1b"
      color="#fff"
      size="20px"
      @mouseover.native="() => (isHovering = true)"
      @mouseleave.native="() => (isHovering = false)"
    />
    <slot v-if="isHovering" name="hover-state" />
  </div>
</template>
<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail';

export default {
  name: 'UserAvatar',
  components: { Thumbnail },
  props: {
    name: {
      type: String,
      default: '',
    },
    avatarSource: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isHovering: false,
    };
  },
};
</script>
