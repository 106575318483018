var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('woot-modal',{attrs:{"show":_vm.show,"on-close":_vm.onCancel},on:{"update:show":function($event){_vm.show=$event}}},[_c('div',{staticClass:"column content-box"},[_c('woot-modal-header',{attrs:{"header-title":("" + (_vm.$t('EDIT_CONTACT.TITLE'))),"header-content":_vm.$t('EDIT_CONTACT.DESC')}}),_vm._v(" "),_c('form',{staticClass:"row settings-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"medium-12 columns"},[_c('account-pii-mask-wrapper',{attrs:{"pii-data":_vm.name,"mask-type":_vm.maskType.NAME_INPUT,"unmask-payload":_vm.unmaskRequestPayload,"on-unmask":function (data) { return _vm.handleUnmask('name', data); },"unmask-action":"piiMask/logUnmaskEvent"},on:{"update":function($event){_vm.name = $event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var isMasked = ref.isMasked;
var updateData = ref.updateData;
return [_c('woot-input',{staticClass:"medium-11 columns content-margin",class:{ error: _vm.$v.name.$error },attrs:{"value":data,"label":_vm.$t('EDIT_CONTACT.FORM.NAME.LABEL'),"placeholder":_vm.$t('EDIT_CONTACT.FORM.NAME.PLACEHOLDER'),"disabled":isMasked},on:{"input":function (value) { return _vm.handleInputChange(value, updateData, _vm.$v.name.touch); }}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"medium-12 columns"},[_c('account-pii-mask-wrapper',{attrs:{"pii-data":_vm.email,"mask-type":_vm.maskType.EMAIL_INPUT,"unmask-payload":_vm.unmaskRequestPayload,"on-unmask":function (data) { return _vm.handleUnmask('email', data); },"unmask-action":"piiMask/logUnmaskEvent"},on:{"update":function($event){_vm.email = $event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var isMasked = ref.isMasked;
var updateData = ref.updateData;
return [_c('woot-input',{staticClass:"medium-11 columns content-margin",class:{ error: _vm.$v.email.$error },attrs:{"value":data,"has-error":_vm.$v.email.$error,"error":_vm.$t('EDIT_CONTACT.FORM.EMAIL_ADDRESS.ERROR_MESSAGE'),"label":_vm.$t('EDIT_CONTACT.FORM.EMAIL_ADDRESS.LABEL'),"placeholder":_vm.$t('EDIT_CONTACT.FORM.EMAIL_ADDRESS.PLACEHOLDER'),"disabled":isMasked},on:{"blur":_vm.$v.email.$touch,"input":function (value) { return _vm.handleInputChange(value, updateData); }}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"columns medium-12 content-margin"},[_c('woot-input',{attrs:{"rows":"3","type":"text","label":_vm.$t('EDIT_CONTACT.FORM.BIO.LABEL'),"placeholder":_vm.$t('EDIT_CONTACT.FORM.BIO.PLACEHOLDER')},on:{"input":_vm.$v.description.$touch},model:{value:(_vm.description),callback:function ($$v) {_vm.description=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"description"}})],1),_vm._v(" "),_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"row medium-12 flex-space-between"},[_c('div',{staticClass:"medium-5"},[_c('account-pii-mask-wrapper',{attrs:{"pii-data":_vm.phoneNumber,"mask-type":_vm.maskType.PHONE_INPUT,"unmask-payload":_vm.unmaskRequestPayload,"on-unmask":function (data) { return _vm.handleUnmask('phoneNumber', data); },"unmask-action":"piiMask/logUnmaskEvent"},on:{"update":function($event){_vm.phoneNumber = $event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var isMasked = ref.isMasked;
var updateData = ref.updateData;
return [_c('woot-input',{staticClass:"medium-12 columns content-margin",class:{ error: _vm.$v.name.$error },attrs:{"value":data,"error":null,"label":_vm.$t('EDIT_CONTACT.FORM.PHONE_NUMBER.LABEL'),"placeholder":_vm.$t('EDIT_CONTACT.FORM.PHONE_NUMBER.PLACEHOLDER'),"disabled":isMasked},on:{"blur":_vm.$v.phoneNumber.$touch,"input":function (value) { return _vm.handleInputChange(value, updateData); }}})]}}])})],1),_vm._v(" "),_c('woot-input',{staticClass:"medium-5 columns content-margin",attrs:{"label":_vm.$t('EDIT_CONTACT.FORM.COMPANY_NAME.LABEL'),"placeholder":_vm.$t('EDIT_CONTACT.FORM.COMPANY_NAME.PLACEHOLDER')},on:{"input":_vm.$v.companyName.$touch},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"companyName"}})],1),_vm._v(" "),(_vm.$v.phoneNumber.$error)?_c('span',{staticClass:"capital_c3 desc-error"},[_vm._v("\n          "+_vm._s(this.$t('EDIT_CONTACT.FORM.PHONE_NUMBER.ERROR_MESSAGE'))+"\n        ")]):_vm._e()]),_vm._v(" "),(_vm.contact.identifier)?_c('woot-input',{staticClass:"medium-12 columns content-margin disable-social-input",attrs:{"label":_vm.$t('EDIT_CONTACT.FORM.IDENTIFIER.LABEL'),"placeholder":_vm.$t('EDIT_CONTACT.FORM.IDENTIFIER.PLACEHOLDER')},model:{value:(_vm.identifier),callback:function ($$v) {_vm.identifier=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"identifier"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"medium-12 columns"},[_c('label',{staticClass:"form-label"},[_vm._v("\n          Social Information\n        ")]),_vm._v(" "),_vm._l((_vm.socialProfileKeys),function(socialProfile){return _c('div',{key:socialProfile.key},[_c('div',{staticClass:"input-group",class:{
              'disable-social-input':
                socialProfile.key === 'instagram_username' &&
                _vm.contact.instagram_username !== null,
            }},[_c('span',{staticClass:"input-group-label font-size-mini"},[_vm._v(_vm._s(socialProfile.prefixURL))]),_vm._v(" "),_c('account-pii-mask-wrapper',{staticClass:"input-group-field",attrs:{"pii-data":_vm.socialProfileUserNames[socialProfile.key],"mask-type":_vm.getSocialMediaMaskType(socialProfile.key),"unmask-payload":_vm.unmaskRequestPayload,"on-unmask":function (data) { return _vm.handleUnmask(socialProfile.key, data); },"unmask-action":"piiMask/logUnmaskEvent"},on:{"update":function($event){_vm.socialProfileUserNames[socialProfile.key] = $event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var data = ref.data;
            var isMasked = ref.isMasked;
            var updateData = ref.updateData;
return [_c('input',{staticClass:"input-group-field font-size-mini",class:{ 'disable-social-input': isMasked },attrs:{"type":"text","disabled":isMasked},domProps:{"value":data},on:{"input":function (event) { return updateData(event.target.value); }}})]}}],null,true)})],1)])})],2),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"button clear alert",on:{"click":function($event){$event.preventDefault();return _vm.onCancel($event)}}},[_vm._v("\n          "+_vm._s(_vm.$t('EDIT_CONTACT.FORM.CANCEL'))+"\n        ")]),_vm._v(" "),_c('woot-primary-button',{attrs:{"loading":_vm.uiFlags.isUpdating,"name":_vm.$t('EDIT_CONTACT.FORM.SUBMIT'),"front-icon":"peopleFilled"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }