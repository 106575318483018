/* global axios */

import ApiClient from './ApiClient';

class TemplateApi extends ApiClient {
  constructor() {
    super('templates', { accountScoped: true });
  }

  fetchTemplates() {
    return axios.get(`${this.url}/fetch_inbox_templates`);
  }
}

export default new TemplateApi();
