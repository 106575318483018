<template>
  <div v-if="imgSrc" class="avatar-with-camera-container">
    <div class="img-container">
      <img :src="imgSrc" />
    </div>
    <div v-if="showUploadButton" class="center-aligned">
      <file-upload
        :size="4096 * 4096"
        accept="image/*"
        @input-filter="handleImageUpload"
      >
        <div class="upload-photo-container">
          <icons
            name="camera"
            color="stroke-green"
            view="0 0 16 16"
            size="normal"
          />
        </div>
      </file-upload>
    </div>
  </div>
  <div
    v-else
    class="avatar-with-camera-container"
    :class="cssClassName"
    :style="[style, customStyle]"
    aria-hidden="true"
  >
    <span :class="avatarTextClass">{{ userInitial }}</span>
    <div v-if="showUploadButton" class="center-aligned">
      <file-upload
        :size="4096 * 4096"
        accept="image/*"
        @input-filter="handleImageUpload"
      >
        <div class="upload-photo-container">
          <icons
            name="camera"
            color="stroke-green"
            view="0 0 16 16"
            size="normal"
          />
        </div>
      </file-upload>
    </div>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  name: 'Avatar',
  components: {
    FileUpload,
  },
  mixins: [alertMixin],
  props: {
    username: {
      type: String,
      default: '',
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
    backgroundColor: {
      type: String,
      default: 'white',
    },
    color: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 40,
    },
    src: {
      type: String,
      default: '',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    colored: {
      type: Boolean,
      default: false,
    },
    showUploadButton: {
      type: Boolean,
      default: false,
    },
    imgSrc: {
      type: String,
      default: '',
    },
  },
  computed: {
    style() {
      return {
        width: `${this.size}px`,
        minWidth: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: this.rounded ? '50%' : '4px',
        border: this.colored
          ? 'unset'
          : `${Math.floor(this.size / 20) - 0.5}px solid ${this.color}`,
        lineHeight: `${12 * Math.floor(this.size / 20)}px`,
        backgroundColor: this.colored ? '#6bac1b' : this.backgroundColor,
        fontSize: `${Math.floor(this.size / 2.5)}px`,
        fontWeight: `bold`,
        color: this.color,
      };
    },
    cssClassName() {
      return this.colored ? 'avatar-container-colored' : 'avatar-container';
    },
    avatarTextClass() {
      return this.colored ? 'avatar-text-colored' : '';
    },

    userInitial() {
      return this.initials || this.initial(this.username);
    },
  },
  methods: {
    handleImageUpload(newFile, oldFile) {
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.showAlert('Image type not accepted!', 'warning');
        } else {
          newFile.url = '';
          let URL = window.URL || window.webkitURL;
          if (URL && URL.createObjectURL) {
            newFile.url = URL.createObjectURL(newFile.file);
            this.$emit('change', {
              file: newFile.file,
              url: newFile.url,
            });
          }
        }
      }
    },
    initial(username) {
      const parts = username ? username.split(/[ -]/) : [];
      let initials = '';
      for (let i = 0; i < parts.length; i += 1) {
        initials += parts[i].charAt(0);
      }
      if (initials.length > 2 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '');
      }
      initials = initials.substr(0, 2).toUpperCase();
      return initials;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: $neutral-white;
}

.avatar-container-colored {
  @include elevation-1dp;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.upload-photo-container {
  border-radius: 50%;
  background: $color-white;
  padding: $space-smaller;
  opacity: $zero;
}
.avatar-with-camera-container {
  position: relative;
  .img-container {
    height: 7.2rem;
    width: 7.2rem;
    border-radius: $border-radius;
    border: 1px solid $neutral-grey-500;

    img {
      height: 100%;
      width: 100%;
      border-radius: $border-radius;
      object-fit: cover;
    }
  }

  .file-uploads ::v-deep label {
    cursor: pointer;
  }
  &:hover {
    .upload-photo-container {
      opacity: 1;
    }
  }
}

.avatar-text-colored {
  color: $color-white;
}
</style>
