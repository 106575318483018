/* global axios */
import ApiClient from './ApiClient';

class AccountAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  createAccount(data) {
    return axios.post(`${this.apiVersion}/accounts`, data);
  }

  fetchPaymentLink() {
    return axios.get(`${this.baseUrl()}/collect_payment`);
  }

  update(data) {
    return axios.patch(`${this.url}`, data);
  }

  getBotConfigs() {
    return axios.get(`${this.url}bot_csat_configs`);
  }
}

export default new AccountAPI();
