<template>
  <woot-modal
    class="ticket-creation-success-modal"
    show-close
    :show.sync="show"
    :on-close="() => $emit('closeModal')"
  >
    <header class="modal-header">
      <img
        src="https://ik.imagekit.io/vysbzd9hl/ticket_creation_header_illustration.svg"
        class="image"
        alt="welcome-illustration"
      />
    </header>
    <main class="modal-content">
      <h1
        class="title-h1 content-margin"
        v-text="$t('CREATE_TICKET.FORM.SUBMIT.SUCCESS')"
      />
      <div class="show-created-tickets mg-bottom--large">
        <div
          v-for="({ name, email_address, phone_number }, idx) in contacts"
          :key="idx"
          class="ticket-details"
        >
          <icons name="ticket" size="semimedium" color="darkgrey" />
          <span
            v-if="inboxType === 'whatsapp'"
            class="body-b2 text-dark"
            v-text="'+' + phone_number"
          />
          <span
            v-if="inboxType === 'email'"
            class="body-b2 text-dark"
            v-text="email_address"
          />
          <span
            class="title-h5_m text-light ticket-contact-name line-clamp-1"
            v-text="name"
          />
        </div>
      </div>
    </main>
  </woot-modal>
</template>
<script>
import Icons from '../../../../../components/ui/Icons.vue';
export default {
  components: { Icons },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    inboxType: {
      type: String,
      default: '',
    },
  },
};
</script>
