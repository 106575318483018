<template>
  <span class="text-dark flex-row title-h1">
    <span v-if="hours !== '00'" class="hours">{{ hours }}:</span>
    <span class="minutes">{{ minutes }}:</span>
    <span class="seconds">{{ seconds }}</span>
  </span>
</template>
<script>
export default {
  props: {
    counter: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      timer: null,
      counterVal: this.counter,
    };
  },
  computed: {
    seconds() {
      return this.pad(Math.abs(this.counterVal % 60));
    },
    minutes() {
      const absValue = Math.abs(Math.trunc(this.counterVal / 60) % 60);
      const prefix =
        this.counterVal < 0 && Math.abs(+this.hours) === 0 ? '-' : '';
      return prefix + this.pad(absValue);
    },
    hours() {
      const absValue = Math.abs(Math.trunc(this.counterVal / 60 / 60) % 24);
      const prefix = this.counterVal < 0 && absValue !== 0 ? '-' : '';
      return prefix + this.pad(absValue);
    },
  },
  watch: {
    counterVal(n) {
      this.$emit('update:counter', n);
    },
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.counterVal += 1;
      }, 1000);
    },
    startCountDown() {
      this.timer = setInterval(() => {
        this.counterVal -= 1;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    clearTimer() {
      this.counterVal = 0;
      this.stopTimer();
    },
    pad(value, shouldReturnNull = false) {
      if (!value && shouldReturnNull) return null;
      if (value < 10) return '0' + value;
      return value;
    },
  },
};
</script>
