import Index from './Index';
import ChoosePlan from './ChoosePlan.vue';
import SettingsContent from '../Wrapper';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/billing_plan'),
      component: SettingsContent,
      props: {
        headerTitle: 'BILLING.HEADER',
        icon: 'card',
      },
      children: [
        {
          path: '',
          name: 'settings_billing_plan',
          component: Index,
          roles: ['administrator'],
          props: route => ({ state: route.query.state }),
        },
        {
          path: 'choose_billing_plan',
          component: ChoosePlan,
          name: 'settings_choose_billing_plan',
          roles: ['administrator'],
        },
      ],
    },
  ],
};
