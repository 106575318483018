var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-table-container"},[_c('table',{staticClass:"woot-table"},[(_vm.showTableHeader)?_c('thead',[(_vm.value.length)?_c('th'):_vm._e(),_vm._v(" "),_vm._l((_vm.columns),function(column,index){return _c('base-table-column',_vm._b({key:column.prop || index,on:{"sort":_vm.onSort}},'base-table-column',column,false))}),_vm._v(" "),(_vm.hasActionsSlot)?_c('th',{staticStyle:{"width":"0%"}}):_vm._e(),_vm._v(" "),(_vm.hasExtraActionsSlot)?_c('th',{staticStyle:{"width":"0%"}}):_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.tableData.length && !_vm.loading)?_c('transition-group',{style:({ '--total': _vm.tableData.length }),attrs:{"tag":"tbody","name":_vm.computedTransition}},_vm._l((_vm.tableData),function(row,index){return _c('tr',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.deselectRow),expression:"deselectRow"}],key:row.id || index,class:{
          'selected-row':
            _vm.selectedRow.index === index || _vm.value.includes(index),
          loaded: !_vm.loading,
        },style:([{ cursor: 'pointer' }, { '--i': index }]),on:{"click":function($event){return _vm.selectRow({ row: row, index: index, allowMultiSelect: false })}}},[(_vm.value.length && _vm.canShowCheckbox(row))?_c('td',{style:({
            '--i': 1,
            '--total': 1,
          })},[_c('label',{staticClass:"container-checkbox",staticStyle:{"cursor":"pointer"}},[_c('input',{attrs:{"type":"checkbox"},domProps:{"value":_vm.value,"checked":_vm.value.includes(index)},on:{"input":function($event){return _vm.selectRow({ row: row, index: index, allowMultiSelect: true })}}}),_vm._v(" "),_c('span',{staticClass:"grey-checkmark checkmark"})])]):(_vm.value.length)?_c('td',[_c('label',{staticClass:"container-checkbox disabled"},[_c('input',{attrs:{"type":"checkbox"}}),_vm._v(" "),_c('span',{staticClass:"grey-checkmark checkmark"})])]):_vm._e(),_vm._v(" "),_vm._l((_vm.columns),function(column,idx){return _c('td',{key:column.prop || idx,class:[
            {
              'text-align--center': column.align === 'center',
              'text-align--left': column.align === 'left',
              'text-align--right': column.align === 'right',
            } ],style:(_vm.getStyles(column))},[(column.component)?[_c('component-loader',{attrs:{"component-name":column.component,"data":row}})]:(column.prop)?_c('div',{staticClass:"line-clamp-2 title-h5 text-dark capitalize",class:column.customClass,attrs:{"title":row[column.prop]},domProps:{"textContent":_vm._s(row[column.prop])}}):_vm._e()],2)}),_vm._v(" "),(_vm.hasExtraActionsSlot)?_c('td',[_c('div',{staticClass:"full-width flex-row flex-justify--end"},[_vm._t("extra-actions",null,{"row":Object.assign({}, row, {index: index})})],2)]):_vm._e(),_vm._v(" "),(_vm.hasActionsSlot)?_c('td',[_vm._t("actions",null,{"row":{ row: row, index: index }})],2):_vm._e()],2)}),0):_vm._e(),_vm._v(" "),(_vm.hasLoadingSlot && _vm.loading)?_vm._t("loading-state"):(_vm.loading)?_c('new-table-skeleton',{attrs:{"columns":_vm.numberOfCols}}):_vm._e()],2),_vm._v(" "),(_vm.hasZeroStateSlot && !_vm.tableData.length && !_vm.loading)?_vm._t("zero-state"):(!_vm.tableData.length && !_vm.loading)?_c('div',{staticClass:"flex-row flex-justify flex-align zero-state"},[_c('span',{staticClass:"title-h5 text-light",domProps:{"textContent":_vm._s('No Data')}})]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }