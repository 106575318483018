<template>
  <div>
    <div class="ui-snackbar" :class="variant">
      <span class="icon" :class="iconProps.color">
        <icons :name="iconProps.name" color="white" size="medium" />
      </span>
      <div class="title-h4_m text-dark ui-snackbar-text">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'info',
    },
    message: {
      type: String,
      default: '',
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: [String, Number],
      default: 3000,
    },
  },
  data() {
    return {
      toggleAfterTimeout: false,
    };
  },
  computed: {
    iconProps() {
      switch (this.variant) {
        case 'success':
          return { name: 'tickRound', color: 'success-color' };
        case 'info':
          return { name: 'info', color: 'info-color' };
        case 'warning':
          return { name: 'danger', color: 'warning-color' };
        case 'error':
          return { name: 'danger', color: 'error-color' };
        default:
          return { name: 'info', color: 'info-color' };
      }
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
.icon {
  padding: $space-smaller;
  border-radius: $border-radius;
}
.success {
  border: 1px solid $color-woot;
}

.error {
  border: 1px solid $warn-red-500;
}

.warning {
  border: 1px solid $warn-yellow-500;
}

.info {
  border: 1px solid $secondary-blue;
}

.info-color {
  background: $secondary-blue;
}

.warning-color {
  background: $warn-yellow-500;
}

.error-color {
  background: $warn-red-500;
}

.success-color {
  background: $color-woot;
}
</style>
