import * as types from '../../mutation-types';
import { CALL_PROVIDERS } from 'dashboard/constants';
import alertMixin from 'shared/mixins/alertMixin';

const actions = {
  initiateOutboundCall: async ({ dispatch }, data) => {
    const { provider } = data;

    const callProviders = {
      [CALL_PROVIDERS.EXOTEL]: () => {
        const { inboxId, agentNumber, customerNumber, virtualNumber } = data;

        if (!virtualNumber) {
          alertMixin.methods.showAlert(
            'Cannot place call, select virtual number to place call',
            'error'
          );
          return null;
        }

        return dispatch('initiateExotelOutboundCall', {
          inboxId,
          agentNumber,
          customerNumber,
          virtualNumber,
        });
      },
      [CALL_PROVIDERS.KNOWLARITY]: () => {
        const {
          channelId,
          agentNumber,
          customerNumber,
          knowlarityNumber,
          callerId,
        } = data;

        return dispatch('initiateKnowlarityOutboundCall', {
          channelId,
          agentNumber,
          customerNumber,
          knowlarityNumber,
          callerId,
        });
      },
    };

    const initiateCall = callProviders[provider];

    return initiateCall ? initiateCall() : null;
  },
  setCallMeta: ({ commit }, data) => {
    commit(types.default.SET_CALL_META, data);
  },
  showCallPopup: ({ commit }, data) => {
    commit(types.default.SET_CALL_POPUP_FLAG, data);
  },
  showCallNotification: ({ commit }, data) => {
    commit(types.default.SET_CALL_NOTIFICATION_FLAG, data);
  },
  updateCallState: ({ commit }, data) => {
    commit(types.default.UPDATE_CALL_STATE, data);
  },
  resetCallState: ({ commit }) => {
    commit(types.default.RESET_CALL_STATE);
  },
};

export default actions;
