/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';

class DownloadApi extends ApiClient {
  constructor() {
    super('integrations', { accountScoped: true });
  }

  download(data) {
    return axios.post(`${this.baseUrl()}/integrations/download`, data, {
      responseType: 'arraybuffer',
    });
  }
}

export default new DownloadApi();
