import NotificationsView from './index';
import { frontendURL } from '../../../helper/URLHelper';
import SettingsWrapper from '../settings/Wrapper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/notifications'),
    component: SettingsWrapper,
    props: {
      headerTitle: 'NOTIFICATIONS_PAGE.HEADER',
      icon: 'bell',
      showNewButton: false,
    },
    children: [
      {
        path: '',
        name: 'notifications_index',
        component: NotificationsView,
        roles: ['administrator', 'agent', 'supervisor'],
      },
    ],
  },
];
