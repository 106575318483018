<template>
  <form @submit.prevent="handleSubmit">
    <div
      v-if="!selectedTeam"
      class="title-h3 text-dark mg-bottom--two"
      v-text="'Details'"
    />
    <woot-input
      v-model.trim="name"
      required
      auto-focus
      class="medium-12 columns content-margin"
      :class="{ error: $v.name.$error }"
      :has-error="$v.name.$error"
      :error="$t('TEAMS_SETTINGS.FORM.NAME.ERROR')"
      :label="$t('TEAMS_SETTINGS.FORM.NAME.LABEL')"
      :placeholder="$t('TEAMS_SETTINGS.FORM.NAME.PLACEHOLDER')"
      @input="$v.name.$touch"
    />
    <woot-input
      v-model.trim="description"
      rows="2"
      class="medium-12 columns"
      :label="$t('TEAMS_SETTINGS.FORM.DESCRIPTION.LABEL')"
      :placeholder="$t('TEAMS_SETTINGS.FORM.DESCRIPTION.PLACEHOLDER')"
    />
    <page-header
      size="small"
      :header-title="$t('TEAMS_SETTINGS.FORM.AUTO_ASSIGN.LABEL')"
      :show-border="false"
    >
      <woot-switch
        :id="Math.random()"
        :value="allowAutoAssign"
        @click="() => (allowAutoAssign = !allowAutoAssign)"
      />
    </page-header>
    <div class="mg-bottom--jumbo">
      <label style="margin-bottom: 0.4rem" class="formlabel flex-row">
        <span
          class="title-h5 text-dark"
          v-text="$t('TEAMS_SETTINGS.CREATE_FLOW.AGENTS.TITLE')"
        />
        <span
          v-tooltip="$t('TEAMS_SETTINGS.CREATE_FLOW.AGENTS.DESC')"
          class="desc-text capital_c3"
        >
          <icons
            name="warning"
            color="grey evenodd"
            size="semimedium"
            :show-title="false"
          />
        </span>
      </label>
      <div
        v-if="selectedAgents.length"
        class="medium-12 agent-chips custom-scroll mg-bottom--slab"
      >
        <woot-chips
          v-for="(agent, index) in selectedAgents"
          :key="agent.id"
          :title="agent.available_name"
          variant="primary-small"
          :show-cancel-icon="true"
          :show-tooltip="false"
          @click="onRemoveAgent(index)"
        />
      </div>
      <woot-single-selector
        class="medium-12"
        :default-option="$t('TEAMS_SETTINGS.CREATE_FLOW.AGENTS.BUTTON_TEXT')"
        :hide-on-select="false"
        :option-list="notSelectedAgents"
        :custom-style="{ left: 'auto', width: '100%' }"
        @click="onAgentSelect"
      />
    </div>
    <div
      class="pagination-controls"
      :class="{ 'snap-to-bottom': !selectedTeam }"
    >
      <woot-base-button
        v-if="!selectedTeam"
        class="modal-button"
        variant="tertiary-danger"
        @click.prevent="$emit('onClose')"
      >
        {{ $t('TEAMS_SETTINGS.ADD.CANCEL_BUTTON_TEXT') }}
      </woot-base-button>
      <woot-base-button
        class="modal-button"
        variant="primary"
        :disabled="$v.name.$invalid"
        :loading="uiFlags.isCreating || uiFlags.isUpdating"
        type="submit"
      >
        {{ selectedTeam ? 'Save' : 'Create' }}
      </woot-base-button>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';

import alertMixin from 'shared/mixins/alertMixin';

import validations from './helpers/validations';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: { PageHeader },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  props: {
    selectedTeam: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const selectedTeam = this.selectedTeam || {};
    const {
      description = '',
      name: name = '',
      allow_auto_assign: allowAutoAssign = true,
    } = selectedTeam;

    return {
      description,
      name,
      allowAutoAssign,
      selectedAgents: [],
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      uiFlags: 'teams/getUIFlags',
    }),
    notSelectedAgents() {
      let selectedIds = this.selectedAgents.map(item => {
        return item.id;
      });
      this.agentList.forEach(item => {
        if (selectedIds.includes(item.id)) item.selected = true;
        else item.selected = false;
      });

      return this.agentList;
    },
    teamId() {
      return this.selectedTeam?.id || 0;
    },
    teamMembers() {
      return this.$store.getters['teamMembers/getTeamMembers'](
        this.selectedTeam.id
      );
    },
  },
  validations,
  created() {
    if (this.teamId)
      this.$store
        .dispatch('teamMembers/get', {
          teamId: this.teamId,
        })
        .then(() => {
          this.selectedAgents = [...this.teamMembers];
        });
  },
  methods: {
    onAgentSelect(val) {
      if (this.selectedAgents.find(agent => val.id === agent.id)) {
        this.onRemoveAgent(val.index);
        return;
      }
      this.selectedAgents.push(val);
    },
    onRemoveAgent(index) {
      this.selectedAgents.splice(index, 1);
    },
    handleSubmit() {
      const { name, description, selectedAgents } = this;
      const payload = {
        name,
        description,
        allow_auto_assign: this.allowAutoAssign,
        selectedAgents: selectedAgents.map(agent => agent.id),
      };

      if (this.selectedTeam?.id) this.updateTeam(payload);
      else this.createTeam(payload);
    },
    async createTeam(data) {
      const { selectedAgents, ...payload } = data;

      this.$store
        .dispatch('teams/create', {
          ...payload,
        })
        .then(response => {
          this.$store
            .dispatch('teamMembers/create', {
              teamId: response.id,
              agentsList: selectedAgents,
            })
            .then(() => {
              this.showAlert(
                this.$t('TEAMS_SETTINGS.TEAM_FORM.SUCCESS_MESSAGE')
              );
              this.mix_panel_clicked_settings_create_team();
            });
        })
        .catch(() => {
          this.showAlert(this.$t('TEAMS_SETTINGS.TEAM_FORM.ERROR_MESSAGE'));
        })
        .finally(() => {
          this.$emit('onClose');
        });
    },
    updateTeam(data) {
      const { selectedAgents, ...payload } = data;

      axios
        .all(
          [
            this.$store.dispatch('teams/update', {
              id: this.teamId,
              ...payload,
            }),
          ],
          this.$store.dispatch('teamMembers/update', {
            teamId: this.teamId,
            agentsList: selectedAgents,
          })
        )
        .then(() => {
          this.showAlert(this.$t('TEAMS_SETTINGS.TEAM_FORM.SUCCESS_MESSAGE'));
        })
        .catch(() => {
          this.showAlert(this.$t('TEAMS_SETTINGS.TEAM_FORM.ERROR_MESSAGE'));
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.agent-chips {
  max-height: 12.8rem;
  overflow: overlay;
}

.content-margin {
  margin-bottom: $space-medium;
}

.snap-to-bottom {
  bottom: $zero;
  padding: 3.6rem $space-four * 2;
  position: absolute;
  right: $zero;
}

::v-deep [type='checkbox'] + label {
  margin: $zero;
}
</style>
