<template>
  <div class="csat--table-container">
    <table-skeleton v-if="uiFlags.isFetching" />
    <div v-else class="medium-12 bottom-container">
      <woot-table
        class="lime-card-1dp"
        title="BOT CSAT Summary"
        :header-list="$t('ANALYTICS.BOT_CSAT_HEADER')"
        :table-data="tableData"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TableSkeleton from '../../components/TableSkeleton';
import { formatToPercent } from 'dashboard/routes/dashboard/analytics/shared/helpers';

export default {
  components: {
    TableSkeleton,
  },
  computed: {
    ...mapGetters({
      uiFlags: 'csat/getUIFlags',
      botData: 'csat/getBotData',
    }),
    tableData() {
      return this.botData.flowWiseSplit.map(
        ({
          event_name,
          avg_csat,
          total_csat_sent,
          query_resolved_rate,
          query_not_resolved_rate,
          csat_collection_rate,
        }) => ({
          eventName: event_name,
          avgCsat: avg_csat,
          totalCsatSent: total_csat_sent,
          queryResolvedRate: formatToPercent(query_resolved_rate),
          queryNotResolvedRate: formatToPercent(query_not_resolved_rate),
          csatCollectionRate: formatToPercent(csat_collection_rate),
        })
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.csat--table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }

  .table-pagination {
    margin-top: $space-small;
    text-align: right;
  }
}
</style>
