<template>
  <section>
    <section-header translation-key="METRICS" />
    <aside>
      <div class="sla-metrics-container">
        <time-selector-with-office-check
          :label-key="METRICS.FIRST_RESPONSE_TIME"
          :data="data.metrics[METRICS.FIRST_RESPONSE_TIME]"
          @update="handleUpdate"
        />
        <time-selector-with-office-check
          :label-key="METRICS.NEXT_RESPONSE_TIME"
          :data="data.metrics[METRICS.NEXT_RESPONSE_TIME]"
          @update="handleUpdate"
        />
        <time-selector-with-office-check
          :label-key="METRICS.RESOLUTION_TIME"
          :data="data.metrics[METRICS.RESOLUTION_TIME]"
          @update="handleUpdate"
        />
      </div>
    </aside>
  </section>
</template>
<script>
import SectionHeader from './components/shared/Header';
import TimeSelectorWithOfficeCheck from './components/TimeSelectorWithOfficeCheck';

import { METRICS } from '../../../utils/constants';

export default {
  components: {
    SectionHeader,
    TimeSelectorWithOfficeCheck,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      value: new Date(),
      METRICS,
    };
  },
  methods: {
    handleUpdate(metricData) {
      this.$emit('update', {
        ...this.data,
        metrics: {
          ...this.data.metrics,
          ...metricData,
        },
      });
    },
  },
};
</script>
