const currencyData = require('./data/currencyDataDetailed.json');

export const getCurrencySymbol = code => {
  let symbol = '';

  const currency = currencyData[code] || null;

  if (currency) {
    symbol = currency.symbol_native;
  }
  return symbol;
};
