/* eslint no-undef: "error" */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */

function getDate(timestamp) {
  var date = new Date(timestamp);
  date.setHours(0, 0, 0, 0);
  return date;
}

function getformattedDate(date) {
  var options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

function addDateMessage(date) {
  return {
    date: getformattedDate(date),
    id: date.valueOf(),
    private: false,
  };
}

function uniqById(arr) {
  let idList = [];
  let objList = [];
  arr.forEach(element => {
    if (!idList.includes(element.id) && element) {
      idList.push(element.id);
      objList.push(element);
    }
  });
  return objList;
}

export default {
  methods: {
    setRelevanceFilter(value) {
      const options = ['starred', 'unread', 'alert'];

      options.forEach(el => {
        this.updateConversationFilter(
          el,
          value.includes(el) ? true : undefined
        );
      });
    },
    setConversationSortFilter(value) {
      const options = ['oldest', 'isNew'];

      options.forEach(el => {
        this.updateConversationFilter(
          el,
          value.includes(el) ? true : undefined
        );
      });
    },
    lastMessage(m) {
      return m.messages.last();
    },
    readMessages(m) {
      try {
        let x = this.addDateSeperators(
          m.messages.filter(
            chat => chat.created_at * 1000 <= m.agent_last_seen_at * 1000
          )
        );
        return uniqById(x);
      } catch (e) {
        // statements
        return [];
      }
    },
    unReadMessages(m) {
      try {
        let x = m.messages.filter(
          chat => chat.created_at * 1000 > m.agent_last_seen_at * 1000
        );
        return uniqById(x);
      } catch (e) {
        // statements
        return [];
      }
    },
    addDateSeperators(m) {
      var message_list = [];
      var last_date = null;
      var date;
      m.forEach(message => {
        date = getDate(message.created_at * 1000);
        if (last_date == null || date > last_date) {
          message_list.push(addDateMessage(date));
          last_date = date;
        }
        message_list.push(message);
      });
      return message_list;
    },
    getFilteredConversations(c, filters) {
      let conversation_list = c;

      if (filters.inboxId) {
        conversation_list = conversation_list.filter(conv => {
          return conv.inbox_id.toString() === filters.inboxId.toString();
        });
      }

      if (filters.teamId) {
        conversation_list = conversation_list.filter(conv => {
          return conv.meta.team?.id === filters.teamId;
        });
      }

      if (filters.selectedAgent) {
        conversation_list = conversation_list.filter(conv => {
          return conv.meta.assignee?.id === filters.selectedAgent;
        });
      }

      if (filters.isNew) {
        conversation_list = conversation_list.filter(conv => {
          return conv.is_new === filters.isNew;
        });
      }

      if (filters.alert) {
        conversation_list = conversation_list.filter(conv => {
          return conv.alert === filters.alert;
        });
      }

      if (filters.starred) {
        conversation_list = conversation_list.filter(conv => {
          return conv.starred === true;
        });
      }

      if (filters.unread) {
        conversation_list = conversation_list.filter(conv => {
          return conv.unread === true || conv.unread_count > 0;
        });
      }

      if (filters.oldest) {
        conversation_list = conversation_list.sort((conv_a, conv_b) => {
          return conv_a.timestamp - conv_b.timestamp;
        });
      }

      if (filters.identifier === 'comment') {
        conversation_list = conversation_list.filter(conv => {
          return ['comment', 'instagram_comment'].includes(conv.identifier);
        });
      }

      return conversation_list;
    },
  },
};
