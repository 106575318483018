<template>
  <div :class="['date-picker', `date-picker--${variant}`]">
    <date-picker
      :type="type"
      :range="showRange"
      :show-hour="showHour"
      :show-minute="showMinute"
      :show-second="showSecond"
      :clearable="false"
      :format="format"
      :editable="false"
      :input-class="['date-picker-input', `date-picker-input--${variant}`]"
      :confirm-text="confirmText"
      :placeholder="placeholder"
      :value="value"
      :disabled-date="(date, currentValue) => disabledDates(date, currentValue)"
      @change="handleChange"
    />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  components: { DatePicker },
  props: {
    type: { type: String, default: 'date' },
    confirmText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: '',
    },
    value: {
      type: [Array, Date],
      default: null,
    },
    showRange: {
      type: Boolean,
      default: true,
    },

    showHour: {
      type: Boolean,
      default: true,
    },
    showMinute: {
      type: Boolean,
      default: true,
    },
    showSecond: {
      type: Boolean,
      default: true,
    },
    disabledFutureDates: {
      type: Date,
      default: null,
    },
    // disable after next number of days from the start date
    disableAfterNextNumberOfDays: {
      type: Number,
      default: 0,
    },
    disablePreviousDate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    disabledDates(date, currentValue) {
      let dateDisabled = false;

      let current = new Date();

      if (this.disablePreviousDate)
        dateDisabled = date < new Date(current.setDate(current.getDate() - 1));

      if (this.disableAfterNextNumberOfDays !== 0) {
        let currDate = new Date(currentValue[0]);
        dateDisabled =
          dateDisabled ||
          date >
            new Date(
              currDate.setDate(
                currDate.getDate() + this.disableAfterNextNumberOfDays
              )
            );
      } else if (this.disabledFutureDates)
        dateDisabled = dateDisabled || date > this.disabledFutureDates;

      return dateDisabled;
    },
    handleChange(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables';

.date-picker {
  &--full-width {
    .mx-datepicker-range {
      width: 100% !important;
    }
  }
  &--small {
    .mx-datepicker-range {
      width: 21rem;
    }
  }
}

.date-picker-input {
  margin: auto $space-one !important;
  margin-left: auto !important;
  margin-bottom: $zero !important;
  height: 3.5rem !important;
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  font-size: $font-size-mini !important;
  font-weight: $font-weight-medium !important;
  line-height: $height-h6 !important;
  padding: $space-one $space-slab !important;

  &--small {
    height: 3rem;
    border: 1px solid $neutral-grey-500 !important;
    border-radius: $border-radius-smaller !important;
    color: $text-dark !important;
    font-size: $font-size-small !important;
    font-weight: $font-weight-black !important;
    margin-right: $space-smaller;
  }
}
</style>
