<template>
  <page-header
    :header-title="title"
    size="small"
    :header-content="errorMessage"
    custom-class="grid-1-2-ratio"
    :info-text="description"
  >
    <div class="flex-row flex-align flex-justify--between gap--larger">
      <woot-input
        v-for="unit in Object.keys(timeDelay)"
        :key="unit"
        v-model.number="timeDelay[unit]"
        class="small-3"
        :class="{ error: $v.timeDelay[unit].$error }"
        :has-error="$v.timeDelay[unit].$error"
        error="Please add a positive number"
        :label="$t(`INBOX_MGMT.COMMON_PROPERTIES.${unit}`)"
        type="number"
        show-spin-buttons
        @blur="$v.timeDelay[unit].$touch()"
        @input="updateConfig"
      />
    </div>
  </page-header>
</template>
<script>
import { required, integer } from 'vuelidate/lib/validators';

import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';

const isPostiveNumber = value => {
  if (!value) return true;
  return value >= 0;
};

export default {
  components: { PageHeader },
  props: {
    title: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    time: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      timeDelay: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },
  computed: {
    errorMessage() {
      if (!this.error) {
        return '';
      }
      return `<span class='text-red error'>${this.error}</span>`;
    },
  },
  validations: {
    timeDelay: {
      hours: { required, integer, isPostiveNumber },
      minutes: { required, integer, isPostiveNumber },
      seconds: { required, integer, isPostiveNumber },
    },
  },
  created() {
    this.timeDelay = this.separateValuesFromSeconds(this.time);
  },
  methods: {
    separateValuesFromSeconds(value) {
      if (!value) return { hours: 0, minutes: 0, seconds: 0 };
      return {
        // eslint-disable-next-line no-bitwise
        hours: ~~((+value / (60 * 60)) % 24),
        // eslint-disable-next-line no-bitwise
        minutes: ~~((+value / 60) % 60),
        // eslint-disable-next-line no-bitwise
        seconds: ~~(+value % 60),
      };
    },
    convertToSeconds({ hours, minutes, seconds }) {
      return hours * 60 * 60 + minutes * 60 + seconds;
    },
    updateConfig() {
      this.$emit('updateConfig', +this.convertToSeconds(this.timeDelay));
    },
  },
};
</script>
