<template>
  <label class="image-label">
    <span v-if="label">{{ label }}</span>
    <woot-thumbnail
      v-if="src"
      size="120px"
      color="#1b83ac"
      :src="src"
      :username="username"
      :has-border="true"
    />
    <img
      v-else
      class="alt-thumbnail"
      src="~dashboard/assets/images/flag.svg"
      alt="No Page Image"
    />
    <div class="image-upload">
      <file-upload
        :size="4096 * 4096"
        accept="image/*"
        @input-filter="handleImageUpload"
      >
        <icons name="edit" size="semimedium" color="stroke-green" />
      </file-upload>
    </div>
    <slot></slot>
  </label>
</template>

<script>
import FileUpload from 'vue-upload-component';
import alertMixin from 'shared/mixins/alertMixin';
export default {
  components: {
    FileUpload,
  },
  mixins: [alertMixin],
  props: {
    label: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    username: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleImageUpload(newFile, oldFile) {
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.showAlert('Not an image!', 'warning');
        } else {
          newFile.url = '';
          let URL = window.URL || window.webkitURL;
          if (URL && URL.createObjectURL) {
            newFile.url = URL.createObjectURL(newFile.file);
            this.$emit('change', {
              file: newFile.file,
              url: newFile.url,
            });
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.image-label {
  position: relative;
  width: max-content;
  margin-bottom: $space-normal;

  .image-upload {
    @include elevation-2dp;

    position: absolute;
    display: flex;
    justify-content: center;
    right: 0;
    bottom: 0;
    height: 3.6rem;
    width: 3.6rem;
    border-radius: 50%;
    background: $neutral-white;
  }

  .alt-thumbnail {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 5px solid $secondary-blue;
  }
}
</style>
