<template>
  <transition v-if="hasTransition" name="modal-fade">
    <div
      v-if="show"
      class="modal-mask"
      transition="modal"
      :style="modalPosition"
      @click="onBackDropClick"
    >
      <div :class="modalContainerClassName" :style="customStyle" @click.stop>
        <span v-if="showClose" class="modal--close">
          <woot-base-button
            class="close-icon"
            tag="span"
            variant="tertiary-danger"
            @click="close"
          >
            <icons
              name="closeRound"
              color="red"
              size="normal"
              :show-title="false"
            />
          </woot-base-button>
        </span>
        <slot />
      </div>
    </div>
  </transition>
  <div v-else>
    <div
      v-if="show"
      class="modal-mask"
      transition="modal"
      :style="modalPosition"
      @click="onBackDropClick"
    >
      <div :class="modalContainerClassName" :style="customStyle" @click.stop>
        <span class="modal--close">
          <icons
            v-if="showClose"
            name="closeRound"
            color="red"
            size="medium"
            @click="close"
          />
        </span>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasTransition: {
      type: Boolean,
      default: true,
    },
    customStyle: {
      type: Object,
      default: () => {},
    },
    modalPosition: {
      type: Object,
      default: () => {},
    },
    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },
    show: Boolean,
    onClose: {
      type: Function,
      default: () => {},
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    mediumWidth: {
      type: Boolean,
      default: false,
    },
    smallModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalContainerClassName() {
      let className = 'modal-container';
      if (this.fullWidth) {
        return `${className} modal-container--full-width`;
      }
      if (this.smallModal) {
        return `${className} modal-container--small-width`;
      }
      if (this.mediumWidth) {
        return `${className} modal-container--medium-width`;
      }
      return className;
    },
  },
  mounted() {
    document.addEventListener('keydown', e => {
      if (this.show && e.code === 'Escape') {
        this.onClose();
      }
    });
  },
  methods: {
    close() {
      this.onClose();
    },
    onBackDropClick() {
      if (this.closeOnBackdropClick) {
        this.onClose();
      }
    },
  },
};
</script>

<style scoped>
.modal-container--full-width {
  align-items: center;
  border-radius: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 97%;
}
.modal-container--small-width {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 50rem;
}
.modal-container--medium-width {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 120rem;
}
</style>
