import { DuplicateContactException } from 'shared/helpers/CustomErrors';
import types from '../../mutation-types';
import ContactAPI from '../../../api/contacts';

export const actions = {
  getSearchedContacts: async (_, { search, searchBy, labelIds = [], page }) => {
    try {
      const {
        data: { payload: results, meta },
      } = await ContactAPI.search(search, searchBy, labelIds, page);
      return { results, meta };
    } catch (error) {
      // Handle the error appropriately
      throw new Error(error);
    }
  },

  search: async (
    { dispatch, commit },
    { search, searchBy, labelIds = [], page }
  ) => {
    commit(types.SET_CONTACT_UI_FLAG, {
      isFetching: true,
      isSearching: true,
    });
    try {
      const { results, meta } = await dispatch('getSearchedContacts', {
        search,
        searchBy,
        labelIds,
        page,
      });

      commit(types.CLEAR_SEARCHED_CONTACTS);
      commit(types.SET_SEARCHED_CONTACTS, results);
      commit(types.SET_CONTACT_META, meta);
      commit(types.SET_CONTACT_UI_FLAG, {
        isFetching: false,
        isSearching: false,
      });
      return results;
    } catch (error) {
      // Adjust UI flags to reflect the error state
      commit(types.SET_CONTACT_UI_FLAG, {
        isFetching: false,
        isSearching: false,
      });
      return [];
    }
  },

  clearContacts: ({ commit }) => {
    commit(types.CLEAR_CONTACTS);
  },

  // eslint-disable-next-line consistent-return
  get: async ({ commit }, { page = 1, opt_outs, only_phones } = {}) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetching: true });
    try {
      const {
        data: { payload, meta },
      } = await ContactAPI.get(page, opt_outs, only_phones);
      commit(types.CLEAR_CONTACTS);
      commit(types.SET_CONTACTS, payload);
      commit(types.SET_CONTACT_META, meta);
      commit(types.SET_CONTACT_UI_FLAG, {
        isFetching: false,
        isSearching: false,
      });
      return payload;
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, {
        isFetching: false,
        isSearching: false,
      });
    }
  },

  getLastConversationId: async (_, contact_id) => {
    const { data } = await ContactAPI.getLastConversationId(contact_id);
    return data;
  },

  getList: async ({ commit }, { labelIds = [], page = 1 } = {}) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetching: true });
    try {
      const {
        data: { payload },
      } = await ContactAPI.getList(labelIds, page);
      commit(types.CLEAR_CONTACTS);
      commit(types.SET_CONTACTS, payload);
      commit(types.SET_CONTACT_META, {});
      commit(types.SET_CONTACT_UI_FLAG, {
        isFetching: false,
        isSearching: false,
      });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, {
        isFetching: false,
        isSearching: false,
      });
    }
  },

  getOptOut: async ({ commit }, { page, opt_outs, only_phones } = {}) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetching: true });
    try {
      const {
        data: { payload },
      } = await ContactAPI.getOptedOutContacts(page, opt_outs, only_phones);
      commit(types.SET_OPT_OUT_CONTACTS, payload);
      commit(types.SET_CONTACT_UI_FLAG, {
        isFetching: false,
        isSearching: false,
      });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, {
        isFetching: false,
        isSearching: false,
      });
    }
  },

  show: async ({ commit }, { id }) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await ContactAPI.show(id);
      commit(types.SET_CONTACT_ITEM, response.data.payload);
      commit(types.SET_CONTACT_UI_FLAG, {
        isFetchingItem: false,
      });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },

  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_CONTACT_UI_FLAG, { isUpdating: true });
    try {
      const response = await ContactAPI.update(id, updateObj);
      commit(types.EDIT_CONTACT, response.data.payload);
      commit(types.SET_CONTACT_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isUpdating: false });
      if (error.response?.data?.contact) {
        throw new DuplicateContactException(error.response.data.contact);
      } else {
        throw new Error(error);
      }
    }
  },

  updatePresence: ({ commit }, data) => {
    commit(types.UPDATE_CONTACTS_PRESENCE, data);
  },

  setContact({ commit }, data) {
    commit(types.SET_CONTACT_ITEM, data);
  },

  sendContactsOverEmail: async (_, filterData) => {
    try {
      const response = await ContactAPI.send(filterData);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
};
