<template>
  <div class="flex-column" style="height : 100vh">
    <transition name="modal-fade">
      <three-sixty-onboarding-modal
        v-if="isAdmin && showIntegratedOnboardingModal"
        :inbox="latestInProgressThreeSixtyInbox"
        :screen="currentOnboardingScreen"
        @updateCurrentScreen="value => (currentOnboardingScreen = value)"
        @closeModal="onCloseOnboardingModal"
      />
    </transition>
    <onboard-modal
      v-show="!isAdmin && showOnboardingModal"
      @closeModal="onOnboardModalClose"
    />
    <onboarding-tour
      v-if="userRole && showSectionIntroTour"
      :tour-name="'sectionIntroduction'"
      :tour-version="sectionIntroTourSteps.TOUR_VERSION"
      :tour-steps="sectionIntroSteps"
      @unmountTour="onCloseSectionIntroTour"
    />
    <onboarding-tour
      v-if="showChatSectionTour"
      :tour-name="'chatSection'"
      :tour-version="chatSectionSteps.TOUR_VERSION"
      :tour-steps="chatSectionSteps.STEPS"
      @unmountTour="showChatSectionTour = false"
    />
    <feature-intro-modal
      v-show="showFeatureIntroModal"
      @closeModal="onFeatureIntroModalClose"
    />
    <shopify-restriction-modal
      :content="shopifyBannerMsg"
      :type="shopifyModalType"
    />
    <render-in-carousel v-if="!showBanners">
      <template v-if="isShopifyBilling">
        <top-banner v-if="showBanner" variant="danger">
          <div class="flex-row flex-align flex-justify full-width">
            <span
              class="subtitle-s2"
              style="max-width: 80%"
              v-text="shopifyBannerMsg.content"
            />
            <div
              v-if="shopifyBannerMsg && shopifyBannerMsg.show_cta && isAdmin"
              class="alert-text"
            >
              <woot-base-button
                size="small"
                @click="
                  $router.push(
                    `/app/accounts/${$route.params.accountId}/settings/billing_plan/choose_billing_plan`
                  )
                "
              >
                browse plans
              </woot-base-button>
            </div>
          </div>
        </top-banner>
        <top-banner
          v-if="currentAccount && currentAccount.payment_pending"
          variant="danger"
        >
          <span
            class="subtitle-s2"
            v-text="$t('CONVERSATION.DISABLE_HELPDESK')"
          />
        </top-banner>
        <top-banner v-if="showTrialBanner">
          <div class="flex-row flex-align flex-justify full-width">
            <div class="subtitle-s2">
              {{ $t('BILLING.FREE_TRIAL_BANNER') }}
              <span class="title-h5" v-text="shopifyFreeTrialEndDate + '.'" />
            </div>
            <div
              class="title-h3 mg-left--medium alert-text"
              v-text="shopifyTrialDays"
            />
          </div>
        </top-banner>
      </template>
      <non-shopify-billing-banner v-if="showBillingBannerForNonShopify" />
      <inbox-authorization-status v-if="showInboxAuthStatusBanner" />
      <top-banner
        v-if="showOnboardingBannerComputed"
        :variant="onboardingProgressStatus.variant"
      >
        <div class="text-align flex-row flex-justify flex-align full-width">
          <div
            :key="onboardingProgressStatus.content"
            class="body-b2 text-align shake line-clamp-1"
            v-html="onboardingProgressStatus.content"
          />
          <woot-base-button
            size="small"
            class="mg-left"
            @click="showIntegratedOnboardingModal = true"
          >
            {{ $t('ONBOARDING.WHATSAPP.WABA_INTEGRATION_COMPLETE.CONTINUE') }}
          </woot-base-button>
        </div>
      </top-banner>
    </render-in-carousel>
    <div class="row app-wrapper">
      <navbar :route="currentRoute" />
      <section class="app-content columns">
        <router-view></router-view>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TopBanner from 'dashboard/components/ui/TopBanner';
import NonShopifyBillingBanner from 'dashboard/components/ui/banners/BillingNonShopify';
import InboxAuthorizationStatus from 'dashboard/components/ui/banners/InboxAuthorizationStatus';
import OnboardModal from '../../components/ui/OnboardModal';
import ThreeSixtyOnboardingModal from '../../components/ui/threeSixtyOnboardingModal/Index.vue';
import OnboardingTour from '../../components/ui/tours/OnboardingTour';
import FeatureIntroModal from '../../components/ui/FeatureIntroModal';
import Navbar from '../../components/layout/Navbar';
import ShopifyRestrictionModal from '../../components/ui/ShopifyRestrictionModal';
import RenderInCarousel from 'dashboard/components/ui/RenderInCarousel';

import isAdmin from 'dashboard/mixins/isAdmin';
import onboardingTourMixin from 'dashboard/mixins/onboardingTour';
import shopifyMixin from 'dashboard/mixins/shopify';
import billingMixin from 'dashboard/mixins/billing';
import timeMixin from 'dashboard/mixins/time';
import alertMixin from 'shared/mixins/alertMixin';
import threeSixtyDialogMixin from 'dashboard/mixins/threeSixtyDialog';
import onboardingMixin from 'dashboard/mixins/onboarding';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import { humanReadableDate } from 'dashboard/helper/time';

import { ONBOARDING_SCREENS } from 'dashboard/constants';
import {
  clearShopifyAppDetailsFromLocalStorage,
  getItemFromLocalStorage,
} from '../../helper/localStorage';
import {
  loadFreshdeskWidget,
  initializeSupportWidget,
} from '../../loadWidgets';

export default {
  components: {
    Navbar,
    OnboardModal,
    ThreeSixtyOnboardingModal,
    OnboardingTour,
    FeatureIntroModal,
    ShopifyRestrictionModal,
    TopBanner,
    NonShopifyBillingBanner,
    InboxAuthorizationStatus,
    RenderInCarousel,
  },
  mixins: [
    onboardingTourMixin,
    alertMixin,
    billingMixin,
    timeMixin,
    shopifyMixin,
    isAdmin,
    threeSixtyDialogMixin,
    onboardingMixin,
    mixPanelAnalyticsMixin,
  ],
  data() {
    return {
      currentOnboardingScreen: ONBOARDING_SCREENS.WELCOME.key,
      showSectionIntroTour: false,
      showBanners: true,
      showChatSectionTour: false,
      showOnboardingModal: false,
      isOnDesktop: true,
      showFeatureIntroModal: false,
      newFeatureLaunched: false,
      restrictUser: false,
      accountDetails: null,
      shopifyTrialEndDate: null,
      count: 0,
      shopifyModalType: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      inboxes: 'inboxes/getInboxes',
      getAccount: 'accounts/getAccount',
      currentRole: 'getCurrentRole',
      accountId: 'getCurrentAccountId',
    }),
    showInboxAuthStatusBanner() {
      return this.inboxes.some(inbox => inbox.status === 'inactive');
    },
    showBillingBannerForNonShopify() {
      return (
        this.inPendingPaymentForNonShopify || this.inGracePeriodForNonShopify
      );
    },
    showTrialBanner() {
      if (this.showOnboardingBannerComputed) return false;

      return (
        this.currentAccount?.shopify_client &&
        !this.countryCodeCheck(this.currentAccount) &&
        this.showFreeTrialBanner(
          this.subscriptionForShopify.trial_end,
          this.onGracePeriod
        )
      );
    },
    showBanner() {
      const noCountryCodeCheck =
        this.currentAccount &&
        this.currentAccount?.shopify_client &&
        !this.countryCodeCheck(this.currentAccount);

      if (noCountryCodeCheck) {
        return (
          (this.currentAccount?.on_grace_period &&
            !this.currentAccount?.shopify_app_reinstalled) ||
          this.freeTrialHasEnded(this.subscriptionForShopify?.trial_end) ||
          (this.currentAccount?.shopify_app_reinstalled &&
            this.currentAccount?.on_grace_period &&
            this.isGracePeriodLastDay(
              this.currentAccount?.grace_period_last_date
            )) ||
          this.isBeyondGracePeriod(
            this.currentAccount?.grace_period_last_date
          ) ||
          (this.currentAccount?.shopify_trial_taken &&
            !this.currentAccount.subscription)
        );
      }
      return false;
    },
    shopifyTrialDays() {
      return this.pendingTrialDays(this.subscriptionForShopify?.trial_end);
    },
    shopifyFreeTrialEndDate() {
      return humanReadableDate(
        new Date(this.subscriptionForShopify?.trial_end),
        {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          weekday: 'short',
        }
      );
    },
    onGracePeriod() {
      return this.currentAccount?.on_grace_period;
    },
    shopifyBannerMsg() {
      if (
        this.currentAccount?.shopify_trial_taken &&
        !this.currentAccount.subscription
      ) {
        return {
          content: this.$t('BILLING.FREE_TRIAL_TAKEN_ONCE'),
          show_cta: this.currentRole === 'administrator',
          type: 1,
        };
      }
      if (
        this.currentAccount?.shopify_app_reinstalled &&
        this.currentAccount?.on_grace_period &&
        this.isGracePeriodLastDay(this.currentAccount?.grace_period_last_date)
      ) {
        return {
          content: this.$t('BILLING.REINSTALL_BILLING_BANNER', {
            plan: this.subscriptionForShopify?.plan?.name.split(' ')[0],
          }),
          modal_content: this.$t('BILLING.REINSTALL_BILLING_BANNER', {
            plan: this.subscriptionForShopify?.plan?.name.split(' ')[0],
          }),
          heading: 'Renew Plan',
          show_cta: this.currentRole === 'administrator',
          type: 2,
        };
      }
      if (
        this.isBeyondGracePeriod(this.currentAccount?.grace_period_last_date)
      ) {
        return {
          content: this.$t('BILLING.BEYOND_GRACE_PERIOD_BANNER'),
          modal_content: `Your plan has expired. To continue using LimeChat Helpdesk, please select a plan & activate billing with Shopify now.`,
          heading: 'Select a Plan',
          show_cta: this.currentRole === 'administrator',
          type: 3,
        };
      }
      if (
        this.isShopifyBilling &&
        this.currentAccount?.on_grace_period &&
        !this.currentAccount?.shopify_app_reinstalled
      ) {
        return {
          content: this.$t('BILLING.UNINSTALL_BANNER', {
            graceEnd: new Date(
              this.currentAccount?.grace_period_last_date
            ).toUTCString(),
          }),
          modal_content: `Your store has uninstalled the LimeChat Shopify App. Contact your Shopify store admin to reinstall the app to continue using LimeChat Helpdesk.`,
          heading: 'Reinstall LimeChat Shopify App',
          show_cta: false,
          type: 4,
        };
      }
      if (this.freeTrialHasEnded(this.subscriptionForShopify?.trial_end)) {
        return {
          content: this.$t('BILLING.FREE_TRIAL_OVER_BANNER', {
            freeTrialEnd: this.shopifyFreeTrialEndDate,
          }),
          modal_content:
            'Your 14 days free trial has ended. To continue using LimeChat Helpdesk, please select a plan & activate billing with Shopify now.',
          heading: 'Free Trial Expired',
          show_cta: this.currentRole === 'administrator',
          type: 5,
        };
      }
      return {};
    },
    userRole() {
      const currAccountId = this.currentUser.account_id;
      let currentAccount = null;
      let userRole = null;

      if (this.currentUser.accounts && this.currentUser.accounts.length !== 0) {
        currentAccount = this.currentUser.accounts.filter(
          acc => acc.id === currAccountId
        );
        userRole = currentAccount[0].role;
      }

      return userRole;
    },
    sectionIntroSteps() {
      if (!this.userRole) return [];
      if (this.userRole !== 'administrator')
        return this.sectionIntroTourSteps.AGENT;
      return this.sectionIntroTourSteps.ADMIN;
    },
    currentRoute() {
      return ' ';
    },
    hasMargin() {
      if (
        this.$route.name === 'home' ||
        this.$route.name === 'inbox_dashboard' ||
        this.$route.name === 'inbox_conversation' ||
        this.$route.name === 'conversation_through_inbox' ||
        this.$route.name === 'label_conversations' ||
        this.$route.name === 'conversations_through_label'
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    shopifyBannerMsg() {
      if (
        this.currentAccount?.shopify_trial_taken &&
        !this.currentAccount?.subscription
      ) {
        this.shopifyModalType = 1;
      }
      if (
        this.currentAccount?.shopify_app_reinstalled &&
        this.currentAccount?.on_grace_period &&
        this.isGracePeriodLastDay(this.currentAccount?.grace_period_last_date)
      ) {
        this.shopifyModalType = 2;
      }
      if (
        this.isBeyondGracePeriod(this.currentAccount?.grace_period_last_date)
      ) {
        this.shopifyModalType = 3;
      }
      if (
        this.currentAccount?.on_grace_period &&
        !this.currentAccount?.shopify_app_reinstalled
      ) {
        this.shopifyModalType = 4;
      }
      if (this.freeTrialHasEnded(this.subscriptionForShopify?.trial_end)) {
        this.shopifyModalType = 5;
      }
      this.shopifyModalType = 0;
    },
    sectionIntroSteps() {
      if (
        this.showSectionIntroTour &&
        (!this.showOnboardingModal || !this.showIntegratedOnboardingModal) &&
        !this.newFeatureLaunched &&
        this.sectionIntroSteps.length !== 0 &&
        this.$tours.sectionIntroduction &&
        this.$route.path.includes(
          `/app/accounts/${this.$route.params.accountId}/dashboard`
        )
      )
        this.$tours.sectionIntroduction.start();
    },
  },
  mounted() {
    setTimeout(() => {
      this.showBanners = false;
    }, 3000);
    this.updateShopifyAccessTokenIfLoggedIn();
    // 360 starts here
    let firstTimeUser = localStorage.getItem('firstLaunch');
    this.showOnboardingModal = firstTimeUser;

    this.newFeatureLaunched = this.isNewFeatureLaunched();
    this.showFeatureIntroModal = !firstTimeUser && this.newFeatureLaunched;

    if (firstTimeUser) this.showIntegratedOnboardingModal = true;

    let params = this.getParamsFromUrl();
    let phoneNumber = this.getState(params);
    let client = '';
    let channels = '';

    this.sendParamsToParentWindow(params);

    if (this.isAdmin) {
      // check to validate if we are setting up this inbox

      this.$watch('latestInProgressThreeSixtyInbox', value => {
        if (
          phoneNumber === this.latestInProgressThreeSixtyInbox?.phone_number
        ) {
          client = this.getClientId(params);
          channels = this.getChannelIds(params);
        }

        if (!this.isEditInboxRoute) {
          this.canShowOnboardingBanner();

          this.currentOnboardingScreen = this.setOnboardingScreen(
            value,
            client,
            channels
          );

          if (
            this.currentOnboardingScreen ===
              ONBOARDING_SCREENS.WABA_INTEGRATION_COMPLETE.key &&
            client &&
            channels
          )
            this.showIntegratedOnboardingModal = true;

          params = '';
          phoneNumber = '';
          client = '';
          channels = '';
        }
      });
    }

    // 360 ends here

    this.showSectionIntroTour = this.shouldShowSectionIntroTour();
    this.showChatSectionTour = this.shouldShowChatSectionTour();

    loadFreshdeskWidget();
    initializeSupportWidget();

    // Fetch all custom fields into vuex store
    this.$store.dispatch('customTicketFields/get');
  },
  methods: {
    isNewFeatureLaunched() {
      if (
        localStorage.getItem('appVersion') !== this.$t('RELEASE.APP_VERSION')
      ) {
        // update the app version in local storage
        localStorage.setItem('appVersion', this.$t('RELEASE.APP_VERSION'));
        return true;
      }
      return false;
    },
    getShopifyAppDetails() {
      const storeUrl = getItemFromLocalStorage('shopify_store_url');
      const accessToken = getItemFromLocalStorage('shopify_access_token');
      return { storeUrl, accessToken };
    },
    updateShopifyAccessTokenIfLoggedIn() {
      const currentUserId = this.currentUser.id;
      const { storeUrl, accessToken } = this.getShopifyAppDetails();

      if (storeUrl && accessToken) {
        this.$store
          .dispatch('updateShopifyAccessToken', {
            user_id: currentUserId,
            shopify_access_token: accessToken,
            shopify_store_url: storeUrl,
          })
          .then(clearShopifyAppDetailsFromLocalStorage);
      }
    },
    onCloseSectionIntroTour() {
      this.showSectionIntroTour = false;
      // start progress tracker tour that gives introduction to progress tracker feature
      if (this.$tours?.progressTrackerTour)
        this.$tours.progressTrackerTour.start();
    },
    shouldShowSectionIntroTour() {
      if (
        localStorage.getItem('sectionIntroduction') !==
          this.sectionIntroTourSteps.TOUR_VERSION &&
        (!this.showOnboardingModal || !this.showIntegratedOnboardingModal)
      ) {
        if (
          !this.$route.path.includes(
            `/app/accounts/${this.$route.params.accountId}/dashboard`
          )
        )
          // this.$router.push(
          //   `/app/accounts/${this.$route.params.accountId}/dashboard`
          // );

          return true;
      }
      return false;
    },
    shouldShowChatSectionTour() {
      return (
        localStorage.getItem('chatSection') !==
          this.chatSectionSteps.TOUR_VERSION && !this.newFeatureLaunched
      );
    },
    onCloseOnboardingModal() {
      this.showIntegratedOnboardingModal = false;
      this.canShowOnboardingBanner();
      this.showSectionIntroTour = this.shouldShowSectionIntroTour();
      if (this.showSectionIntroTour)
        this.$nextTick(() => {
          this.$tours.sectionIntroduction.start();
        });
    },
    onOnboardModalClose() {
      this.showOnboardingModal = false;
      this.showSectionIntroTour = this.shouldShowSectionIntroTour();
      if (this.showSectionIntroTour)
        this.$nextTick(() => {
          this.$tours.sectionIntroduction.start();
        });
      // else this.$intercom.show();
    },
    onFeatureIntroModalClose() {
      this.newFeatureLaunched = false;
      this.showFeatureIntroModal = false;
      this.showChatSectionTour = this.shouldShowChatSectionTour();
      if (this.showChatSectionTour)
        this.$nextTick(() => {
          this.$tours.chatSection.start();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.alert-text {
  color: $warn-red-400;
  position: absolute;
  right: $space-normal;
}
</style>
