<template>
  <woot-modal
    :custom-style="{ width: '60%' }"
    :show.sync="show"
    :on-close="() => $emit('onClose')"
  >
    <woot-modal-header header-title="Understand how states work" />
    <safe-markdown class="page-top-bar" :content="renderedMarkdown" />
  </woot-modal>
</template>
<script>
import SafeMarkdown from 'dashboard/components/SafeMarkdown';

import markdownContent from './howConversationStatesWork.md';

export default {
  components: { SafeMarkdown },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    renderedMarkdown() {
      return markdownContent;
    },
  },
};
</script>
