<template>
  <div
    ref="sideCard"
    class="column settings-side-card flex-column flex-align flex-justify--between"
  >
    <div ref="sideContent" class="side-card-content flex-column custom-scroll">
      <slot />
    </div>
    <div
      v-if="image && showImage"
      class="side-card-image flex-row flex-justify"
    >
      <img
        alt="side card image"
        :src="require(`dashboard/assets/images/settings/${image}.svg`)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showImage: true,
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      let remainHeight =
        this.$refs.sideCard.clientHeight - this.$refs.sideContent.clientHeight;
      if (remainHeight < 200) {
        this.showImage = false;
      } else {
        this.showImage = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-side-card {
  position: relative;

  .side-card-content {
    border-radius: $border-radius-smaller;
    height: 100%;
    overflow: overlay;
    width: 100%;
  }

  .side-card-image {
    max-height: 150px;
    max-width: 100%;

    img {
      display: inline-block;
      vertical-align: middle;
      object-fit: fill;
      height: auto;
      width: auto;
    }
  }
}
</style>
