import { Line } from 'vue-chartjs';

const fontFamily =
  'Lato,Roboto,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif';

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#1B83AC',
    bodyFontColor: '#8c8c8c',
    footerFontColor: '#8c8c8c',
    titleFontSize: 24,
    titleAlign: 'left',
    bodyFontSize: 16,
    footerFontSize: 12,
    caretSize: 10,
    xPadding: 12,
    yPadding: 12,
    displayColors: false,
  },
  scales: {
    xAxes: [
      {
        barPercentage: 1.26,
        ticks: {
          fontFamily,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true,
          fontColor: '#8c8c8c',
          fontSize: 12,
          lineHeight: 2,
          labelString: 'Conversations',
        },
        ticks: {
          fontFamily,
          beginAtZero: true,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

export default {
  extends: Line,
  props: ['collection'],
  mounted() {
    let { labels, labelArray, dataArray } = this.collection;
    let colors = this.$t('LABEL_MGMT.FORM.COLOR_LIST');
    let datasets = [];
    dataArray.forEach((data, index) => {
      datasets.push({
        data,
        label: labelArray[index],
        fill: false,
        borderColor: colors[index],
        borderJoinStyle: 'bevel',
        borderWidth: 3,
        lineTension: 0,
        pointRadius: 6,
        pointHoverRadius: 8,
        pointBorderWidth: 0.5,
        pointHoverBackgroundColor: colors[index],
        pointHoverBorderColor: colors[index],
        pointBackgroundColor: '#fff',
        pointBorderColor: '#1B83AC',
      });
    });
    let lineData = {
      labels,
      datasets,
    };
    this.renderChart(lineData, chartOptions);
  },
};
