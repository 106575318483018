<template>
  <div>
    <div class="flex-row flex-align flex-justify--between top--container">
      <div class="flex-row flex-align info">
        <span style="cursor: pointer" title="Go back">
          <icons
            name="chevronLeft"
            color="green"
            size="medium"
            :show-title="false"
            @click="$emit('closeCreateAddress')"
          />
        </span>
        <span class="button-small">
          {{ isShipping ? 'Shipping' : 'Billing' }} Address
        </span>
      </div>
    </div>
    <div class="form-container">
      <div
        style="gap: 1.6rem"
        class="flex-row flex-justify--between content-margin"
      >
        <woot-input
          v-model.trim="firstName"
          style="width: 45%"
          :class="{ error: $v.firstName.$error }"
          :has-error="$v.firstName.$error"
          :error="$t('ORDER.ADDRESS.NAME.ERROR')"
          :label="$t('ORDER.ADDRESS.NAME.FIRST_NAME.LABEL')"
          :placeholder="$t('ORDER.ADDRESS.NAME.FIRST_NAME.PLACEHOLDER')"
          @blur="$v.firstName.$touch"
        />
        <woot-input
          v-model.trim="lastName"
          style="width: 45%"
          :class="{ error: $v.lastName.$error }"
          :has-error="$v.lastName.$error"
          :error="$t('ORDER.ADDRESS.NAME.ERROR')"
          :label="$t('ORDER.ADDRESS.NAME.LAST_NAME.LABEL')"
          :placeholder="$t('ORDER.ADDRESS.NAME.LAST_NAME.PLACEHOLDER')"
          @blur="$v.lastName.$touch"
        />
      </div>
      <div class="content-margin">
        <label
          style="color: #3c492c"
          class="form-label flex-row flex-justify--between"
        >
          {{ $t('ORDER.ADDRESS.ADDRESS_LINE_1.LABEL') }}
        </label>
        <woot-input
          v-model="addressLine1"
          :class="{ error: $v.addressLine1.$error }"
          rows="2"
          type="text"
          :placeholder="$t('ORDER.ADDRESS.ADDRESS_LINE_1.PLACEHOLDER')"
          @blur="$v.addressLine1.$touch"
        />
        <span
          v-if="$v.addressLine1.$error"
          class="capital_c3 text-padding"
          :class="{ 'desc-error': true }"
        >
          {{ $t('ORDER.ADDRESS.ADDRESS_LINE_1.ERROR') }}
        </span>
      </div>
      <label
        style="color: #3c492c"
        class="form-label flex-row flex-justify--between"
      >
        {{ $t('ORDER.ADDRESS.ADDRESS_LINE_2.LABEL') }}
      </label>
      <woot-input
        v-model="addressLine2"
        class="content-margin"
        rows="2"
        type="text"
        :placeholder="$t('ORDER.ADDRESS.ADDRESS_LINE_2.PLACEHOLDER')"
      />
      <woot-input
        v-model.trim="city"
        class="content-margin"
        :class="{ error: $v.city.$error }"
        :has-error="$v.city.$error"
        :error="$t('ORDER.ADDRESS.CITY.ERROR')"
        :label="$t('ORDER.ADDRESS.CITY.LABEL')"
        :placeholder="$t('ORDER.ADDRESS.CITY.PLACEHOLDER')"
        @blur="$v.city.$touch"
      />
      <woot-input
        v-model.trim="state"
        class="content-margin"
        :class="{ error: $v.state.$error }"
        :has-error="$v.state.$error"
        :error="$t('ORDER.ADDRESS.STATE.ERROR')"
        :label="$t('ORDER.ADDRESS.STATE.LABEL')"
        :placeholder="$t('ORDER.ADDRESS.STATE.PLACEHOLDER')"
        @blur="$v.state.$touch"
      />
      <woot-input
        v-model.trim="country"
        class="content-margin"
        :class="{ error: $v.country.$error }"
        :has-error="$v.country.$error"
        :error="$t('ORDER.ADDRESS.COUNTRY.ERROR')"
        :label="$t('ORDER.ADDRESS.COUNTRY.LABEL')"
        :placeholder="$t('ORDER.ADDRESS.COUNTRY.PLACEHOLDER')"
        @blur="$v.country.$touch"
      />
      <woot-input
        v-model.trim="phoneNumber"
        class="content-margin"
        :class="{ error: $v.phoneNumber.$error }"
        :has-error="$v.phoneNumber.$error"
        :error="$t('ORDER.ADDRESS.PHONE_NUMBER.ERROR')"
        :label="
          `${$t('ORDER.ADDRESS.PHONE_NUMBER.LABEL')}${
            permissions.phoneMandatory ? '' : ' (optional)'
          }`
        "
        :placeholder="$t('ORDER.ADDRESS.PHONE_NUMBER.PLACEHOLDER')"
        @blur="$v.phoneNumber.$touch"
      />
      <woot-input
        v-model.trim="email"
        :class="{ error: $v.email.$error }"
        :has-error="$v.email.$error"
        size="large"
        :error="$t('ORDER.ADDRESS.EMAIL.ERROR')"
        class="medium-12 columns content-margin"
        :label="
          `${$t('ORDER.ADDRESS.EMAIL.LABEL')}${
            permissions.emailMandatory ? '' : ' (optional)'
          }`
        "
        :placeholder="$t('ORDER.ADDRESS.EMAIL.PLACEHOLDER')"
        @blur="$v.email.$touch"
      />
      <woot-input
        v-model.trim="pinCode"
        class="content-margin"
        :class="{ error: $v.pinCode.$error }"
        :has-error="$v.pinCode.$error"
        :error="$t('ORDER.ADDRESS.PINCODE.ERROR')"
        :label="$t('ORDER.ADDRESS.PINCODE.LABEL')"
        :placeholder="$t('ORDER.ADDRESS.PINCODE.PLACEHOLDER')"
        @blur="$v.pinCode.$touch"
      />
      <div v-if="addressId === -1" class="address--container">
        <woot-secondary-button
          size="medium"
          class="address--btn"
          :name="$t('ORDER.ADDRESS.SAVE')"
          :loading="uiFlags.creatingAddress"
          @click="onSaveAddress"
        />
      </div>
      <div v-else class="address--container flex-row">
        <woot-secondary-button
          size="medium"
          class="address--btn"
          :name="$t('ORDER.ADDRESS.UPDATE')"
          :disabled="$v.$invalid"
          @click="onUpdateAddress"
        />
        <woot-primary-button
          size="medium"
          class="address--btn"
          :name="$t('ORDER.ADDRESS.DELETE')"
          custom-class="address--delete-btn"
          @click="onDeleteAddress"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import validations from '../shared/validations';
import { requiredIf } from 'vuelidate/lib/validators';

import alertMixin from 'shared/mixins/alertMixin';
import deepShopifyPermissionsMixin from 'shared/mixins/deepShopifyPermissionsMixin';

export default {
  mixins: [alertMixin, deepShopifyPermissionsMixin],
  props: {
    isShipping: {
      type: Boolean,
      default: true,
    },
    addressId: {
      type: [String, Number],
      default: -1,
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      state: '',
      city: '',
      country: '',
      phoneNumber: '',
      email: '',
      pinCode: '',
    };
  },
  validations: () => {
    const {
      shippingCharge,
      discount,
      phoneNumber,
      email,
      ...rest
    } = validations;

    return {
      ...rest,
      phoneNumber: {
        ...phoneNumber,
        // eslint-disable-next-line func-names
        required: requiredIf(function() {
          return this.permissions.phoneMandatory;
        }),
      },
      email: {
        ...email,
        // eslint-disable-next-line func-names
        required: requiredIf(function() {
          return this.permissions.emailMandatory;
        }),
      },
    };
  },
  computed: {
    ...mapGetters({
      customerDetails: 'getCustomerDetails',
      customerAddresses: 'getCustomerAddresses',
      uiFlags: 'getCustomerAddressUiFlags',
      shopifyBetaEnabledFlag: 'getDeepShopifyBetaEnabledFlag',
    }),
    customerId() {
      return this.customerDetails.id;
    },
  },
  watch: {
    addressId(newValue) {
      if (newValue === -1) {
        this.$v.$reset();
        this.clearFields();
        this.setCountryToIndia();
        return;
      }
      this.$v.$touch();
      const selectedAddress = this.customerAddresses.find(
        address => address.id === newValue
      );
      this.firstName = selectedAddress.first_name;
      this.lastName = selectedAddress.last_name;
      this.phoneNumber = selectedAddress.phone_number;
      this.email = selectedAddress.email;
      this.city = selectedAddress.city;
      this.company = selectedAddress.company;
      this.state = selectedAddress.province;
      this.country = selectedAddress.country;
      this.pinCode = selectedAddress.zip;
      this.addressLine1 = selectedAddress.address_1;
      this.addressLine2 = selectedAddress.address_2;
    },
  },
  mounted() {
    this.setCountryToIndia();
  },
  methods: {
    setCountryToIndia() {
      if (!this.shopifyBetaEnabledFlag) return;
      this.country = 'India';
    },
    clearFields() {
      this.firstName = '';
      this.lastName = '';
      this.phoneNumber = '';
      this.email = '';
      this.city = '';
      this.company = '';
      this.state = '';
      this.country = '';
      this.pinCode = '';
      this.addressLine1 = '';
      this.addressLine2 = '';
    },
    preparePayload() {
      return {
        customer_id: this.customerId,
        address_name: 'home',
        first_name: this.firstName,
        last_name: this.lastName,
        phone_number: this.phoneNumber,
        email: this.email,
        city: this.city,
        company: this.company,
        province: this.state,
        country: this.country,
        zip: this.pinCode,
        address_1: this.addressLine1,
        address_2: this.addressLine2,
        is_default: false,
      };
    },
    parseErrorMessage(response) {
      if (response.ok === false && response.error) {
        const baseMessage = response.error.invalid_params.message;
        const invalidFields = response.error.invalid_params.error;

        if (
          typeof invalidFields === 'object' &&
          invalidFields !== null &&
          Object.keys(invalidFields).length > 0
        ) {
          const fieldMessages = Object.keys(invalidFields).map(field => {
            const fieldErrors = invalidFields[field];
            return `${field} ${fieldErrors.join(', ')}`;
          });
          return `${baseMessage}, ${fieldMessages.join(', ')}`;
        }
        return baseMessage;
      }
      return this.$t('ORDER.ADDRESS.ERROR');
    },
    onSaveAddress() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const payload = this.preparePayload();
      this.$store
        .dispatch('addCustomerAddress', payload)
        .then(response => {
          if (response.status === 200) {
            this.$store.dispatch('setSelectedAddress', {
              addressType: 'shipping',
              addressId: response.data.id,
            });
            this.$emit('closeCreateAddress');
            this.showAlert(this.$t('ORDER.ADDRESS.SUCCESS'), 'success');
          } else this.showAlert(this.$t('ORDER.ADDRESS.ERROR'), 'error');
        })
        .catch(err => {
          let errResponse = JSON.parse(err.message);
          this.showAlert(this.parseErrorMessage(errResponse), 'error', 8000);
        });
    },
    onUpdateAddress() {
      this.$v.$touch();
      let payload = this.preparePayload();
      payload.address_id = this.addressId;

      try {
        this.$store
          .dispatch('updateCustomerAddress', payload)
          .then(response => {
            if (response.status === 200) {
              this.$store.dispatch('setSelectedAddress', {
                addressType: this.addressType,
                addressId: this.addressId,
              });
              this.$emit('closeCreateAddress');
              this.showAlert(this.$t('ORDER.ADDRESS.UPDATED'), 'info');
            } else {
              this.showAlert(this.$t('ORDER.ADDRESS.ERROR'), 'error');
            }
          });
      } catch (error) {
        this.showAlert(this.$t('ORDER.ADDRESS.UPDATE_ERROR'), 'error');
      }
    },
    onDeleteAddress() {
      // dispatch delete address api here
      try {
        this.$store
          .dispatch('deleteCustomerAddress', { addressId: this.addressId })
          .then(response => {
            if (response.status === 200) {
              this.$store.dispatch('setSelectedAddress', {
                addressType: this.addressType,
                addressId: -1,
              });
              this.$emit('closeCreateAddress');
              this.showAlert(this.$t('ORDER.ADDRESS.DELETED'), 'info');
            } else {
              this.showAlert(this.$t('ORDER.ADDRESS.ERROR'), 'error');
            }
          });
      } catch (error) {
        this.showAlert(this.$t('ORDER.ADDRESS.DELETED_ERROR'), 'error');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.content-margin {
  margin-bottom: $space-normal;
}

.form-container {
  .address {
    &--container {
      gap: $space-small;
    }

    &--btn {
      margin: $space-medium auto 0;
    }

    &--delete-btn {
      background: $warn-red-400;

      &:hover {
        background: $warn-red-500;
      }
    }
  }
}
</style>
