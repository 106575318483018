import cannedImg from 'assets/images/canned_intro.svg';

export const SHOWCASE_CANNED_DATA = [
  {
    name: 'canned',
    key: 'CANNED',
    color: '#4267B2',
    image: cannedImg,
    contentPath: 'CANNED_MGMT',
  },
];
