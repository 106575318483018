<template>
  <div class="user-thumbnail-box" :style="{ height: size, width: size }">
    <img
      v-if="!imgError && Boolean(src) && !isSrcBrokenGravatar"
      id="image"
      :src="src"
      :class="thumbnailClass"
      :style="imgStyle"
      @error="onImgError()"
    />
    <img
      v-else-if="dummy !== 0"
      id="dummy-avatar"
      :src="require(`dashboard/assets/images/thumbnail/${dummy}.svg`)"
      :class="thumbnailClass"
      :style="imgStyle"
    />
    <Avatar
      v-else
      :username="username"
      :class="thumbnailClass"
      :custom-style="customStyle"
      :color="color"
      :background-color="bgColor"
      :size="avatarSize"
    />
    <div
      v-if="showStatusIndicator"
      :class="`source-badge user-online-status user-online-status--${status}`"
      :style="statusStyle"
    />
  </div>
</template>
<script>
/**
 * Thumbnail Component
 * Src - source for round image
 * Size - Size of the thumbnail
 * Badge - Chat source indication { fb / telegram }
 * Username - User name for avatar
 */
import Avatar from './Avatar';

export default {
  components: {
    Avatar,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '40px',
    },
    username: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default: '#6bac1b',
    },
    bgColor: {
      type: String,
      default: '#fff',
    },
    dummy: {
      type: Number,
      default: 0,
    },
    coloredAvatar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgError: false,
    };
  },
  computed: {
    showStatusIndicator() {
      return this.status === 'online' || this.status === 'busy';
    },
    avatarSize() {
      return Number(this.size.replace(/\D+/g, ''));
    },
    statusStyle() {
      const statusSize = `${this.avatarSize / 4}px`;
      return { width: statusSize, height: statusSize };
    },
    imgStyle() {
      const border = `${Math.floor(this.avatarSize / 20)}px solid ${
        this.color
      }`;
      return this.hasBorder ? { border: border } : {};
    },
    thumbnailClass() {
      return 'user-thumbnail';
    },
    // TODO: Remove this after enabling gravatar
    isSrcBrokenGravatar() {
      const { src } = this;
      return src.includes('gravatar.com') && src.includes('d=404');
    },
  },
  watch: {
    src: {
      handler(value, oldValue) {
        if (value !== oldValue && this.imgError) {
          this.imgError = false;
        }
      },
    },
  },
  methods: {
    onImgError() {
      this.imgError = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/foundation-settings';
@import '~dashboard/assets/scss/mixins';

.user-thumbnail-box {
  @include flex-shrink;
  position: relative;
  display: flex;
  align-items: center;

  img {
    object-fit: cover;
  }

  .user-thumbnail {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .user-online-status {
    border-radius: 50%;
    right: 0;
    position: absolute;
    bottom: $space-micro;

    &:after {
      content: ' ';
    }
  }

  .user-online-status--online {
    background: $success-color;
  }

  .user-online-status--busy {
    background: $warning-color;
  }
}
</style>
