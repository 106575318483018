import { render, staticRenderFns } from "./FreshdeskTicketForm.vue?vue&type=template&id=001ec1b6&"
import script from "./FreshdeskTicketForm.vue?vue&type=script&lang=js&"
export * from "./FreshdeskTicketForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports