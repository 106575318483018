<template>
  <PiiMaskWrapper
    v-bind="$props"
    :user-has-permission="userHasPermission && accountOptedContactMasking"
    :mask-state="effectiveMaskState"
    @update="updateData"
  >
    <template v-slot:default="slotProps">
      <slot v-bind="slotProps"></slot>
    </template>
  </PiiMaskWrapper>
</template>

<script>
import PiiMaskWrapper from './PiiMaskWrapper.vue';
import { mapGetters } from 'vuex';
import { MASK_STATE } from './constants';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  name: 'AccountPiiMaskWrapper',
  components: { PiiMaskWrapper },
  mixins: [alertMixin],
  props: {
    ...PiiMaskWrapper.props,
  },
  data() {
    return {
      accountOptedContactMasking: true,
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      accountId: 'getCurrentAccountId',
    }),
    effectiveMaskState() {
      return this.accountOptedContactMasking
        ? this.maskState
        : MASK_STATE.noMask;
    },
  },
  mounted() {
    this.getAccountPermissions();
  },
  methods: {
    updateData(newValue) {
      this.unmaskedData = newValue;
      this.$emit('update', newValue);
    },
    async getAccountPermissions() {
      try {
        this.isLoading = true;

        let { id, contact_masking } = this.getAccount(this.accountId);
        if (!id) {
          await this.$store.dispatch('accounts/get');
          contact_masking = this.getAccount(this.accountId).contact_masking;
        }
        this.accountOptedContactMasking = contact_masking;
      } catch (error) {
        this.showAlert(
          'Something went wrong while fetching account details',
          'error'
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
