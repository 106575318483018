<template>
  <div class="input-group-wrap">
    <div>
      <div class="input-group flex-align--start">
        <label class="input-group-label subtitle-s2 text-light">
          {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.TO.LABEL') }} :
        </label>
        <div class="input-group-field">
          <woot-input
            v-model.trim="$v.toEmailsVal.$model"
            type="email"
            :placeholder="$t('CONVERSATION.REPLYBOX.EMAIL_HEAD.TO.PLACEHOLDER')"
            :class="{ error: $v.toEmailsVal.$error }"
            :error="$t('CONVERSATION.REPLYBOX.EMAIL_HEAD.TO.ERROR')"
            :has-error="$v.toEmailsVal.$error"
            @blur="onBlur"
          />
        </div>
        <woot-tertiary-button
          v-if="!showCc"
          :name="$t('CONVERSATION.REPLYBOX.EMAIL_HEAD.ADD_CC')"
          :custom-style="{ 'min-width': 'auto', 'padding-right': 0 }"
          @click="handleAddCc"
        />
        <woot-tertiary-button
          v-if="!showBcc"
          :name="$t('CONVERSATION.REPLYBOX.EMAIL_HEAD.ADD_BCC')"
          :custom-style="{ 'min-width': 'auto' }"
          @click="handleAddBcc"
        />
      </div>
    </div>
    <div>
      <div
        v-if="showCc"
        class="input-group small"
        :class="{ error: $v.ccEmailsVal.$error }"
      >
        <label class="input-group-label subtitle-s2 text-light">
          {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.CC.LABEL') }} :
        </label>
        <div class="input-group-field">
          <woot-input
            v-model.trim="$v.ccEmailsVal.$model"
            :class="{ error: $v.ccEmailsVal.$error }"
            :has-error="$v.ccEmailsVal.$error"
            :error="$t('CONVERSATION.REPLYBOX.EMAIL_HEAD.BCC.ERROR')"
            :placeholder="$t('CONVERSATION.REPLYBOX.EMAIL_HEAD.CC.PLACEHOLDER')"
            @blur="onBlur"
          />
        </div>
      </div>
    </div>
    <div v-if="showBcc">
      <div class="input-group small" :class="{ error: $v.bccEmailsVal.$error }">
        <label class="input-group-label subtitle-s2 text-light">
          {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.BCC.LABEL') }} :
        </label>
        <div class="input-group-field">
          <woot-input
            v-model.trim="$v.bccEmailsVal.$model"
            :class="{ error: $v.bccEmailsVal.$error }"
            :has-error="$v.bccEmailsVal.$error"
            :error="$t('CONVERSATION.REPLYBOX.EMAIL_HEAD.BCC.ERROR')"
            :placeholder="
              $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.BCC.PLACEHOLDER')
            "
            @blur="onBlur"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validEmailsByComma } from './helpers/emailHeadHelper';

export default {
  props: {
    ccEmails: {
      type: String,
      default: '',
    },
    bccEmails: {
      type: String,
      default: '',
    },
    receiverEmails: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showCc: false,
      showBcc: false,
      toEmailsVal: '',
      ccEmailsVal: '',
      bccEmailsVal: '',
    };
  },
  watch: {
    '$v.$invalid'(newVal) {
      this.onValidityChange(newVal);
    },
    receiverEmails(newVal) {
      if (newVal !== this.toEmailsVal) {
        this.toEmailsVal = newVal;
      }
    },
    bccEmails(newVal) {
      if (newVal !== this.bccEmailsVal) {
        this.showBcc = true;
        this.bccEmailsVal = newVal;
      }
    },
    ccEmails(newVal) {
      if (newVal !== this.ccEmailsVal) {
        this.showCc = true;
        this.ccEmailsVal = newVal;
      }
    },
  },
  validations: {
    toEmailsVal: {
      hasValidEmails(value) {
        if (!value) return false;
        return validEmailsByComma(value);
      },
    },
    ccEmailsVal: {
      hasValidEmails(value) {
        return validEmailsByComma(value);
      },
    },
    bccEmailsVal: {
      hasValidEmails(value) {
        return validEmailsByComma(value);
      },
    },
  },
  methods: {
    handleAddCc() {
      this.showCc = true;
    },
    handleAddBcc() {
      this.showBcc = true;
    },
    onValidityChange(error) {
      this.$emit('validEmails', !error);
    },
    onBlur() {
      this.$v.$touch();
      this.$emit('update:receiverEmails', this.toEmailsVal);
      this.$emit('update:bccEmails', this.bccEmailsVal);
      this.$emit('update:ccEmails', this.ccEmailsVal);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.input-group-wrap {
  border-bottom: 1px solid $neutral-grey-400;
}
.input-group {
  padding-bottom: $space-small;
  margin-bottom: $zero;

  .input-group-label {
    min-width: $space-medium + 3;
    height: $space-four;
    border-color: transparent;
    background: transparent;
  }
  .input-group-field {
    padding-right: $space-one;
  }

  .input-group-field::v-deep input {
    margin-bottom: $zero;
  }
}

.input-group.error {
  border-bottom-color: var(--r-500);
  .input-group-label {
    color: var(--r-500);
  }
}
</style>
