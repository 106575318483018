<template>
  <woot-modal-new :show="true" :on-close="() => $emit('onClose')">
    <woot-modal-header-new header-title="Download Canned Responses" />
    <div class="modal-content-wrapper flex-column flex-justify--between">
      <div class="flex-column gap--normal">
        <div
          :style="{
            backgroundImage: `url(${require('assets/images/export_canned.svg')})`,
          }"
          class="illustration-container"
        />
        <span
          class="body-b2 text-light"
          v-text="
            'This will export the whole content of the canned responses in the Given format'
          "
        />
      </div>
      <div class="full-width flex-row flex-justify--end">
        <woot-base-button :loading="isDownloading" @click="download('canned')">
          Export as csv
        </woot-base-button>
      </div>
    </div>
  </woot-modal-new>
</template>
<script>
import * as types from 'shared/constants/googleEventType';

import downloadMixin from 'shared/mixins/downloadMixin.js';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  mixins: [downloadMixin, googleAnalyticsMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      isDownloading: false,
    };
  },
  methods: {
    async download(type = '') {
      this.googleAnalyticsEvent(
        types.default.DOWNLOAD_CANNED_RESPONSE,
        types.default.CANNED_RESPONSE_SETTINGS,
        types.default.CANNED_RESPONSE_SETTINGS
      );
      this.mix_panel_clicked_settings_export_download_csv_canned_response();
      this.isDownloading = true;
      await this.$store.dispatch('download', { type: type }).then(data => {
        this.processCsv(data, type + '.csv');
        this.isDownloading = false;
        this.$emit('onClose');
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.modal-content-wrapper {
  height: 80%;

  .illustration-container {
    height: 21.6rem;
    max-width: 62.4rem;
    background: $neutral-grey-300;
    background-size: contain;
    border-radius: $border-radius-smaller;
  }

  .custom-import-btn {
    position: relative;
    width: $space-normal * 10;

    ::v-deep .file-uploads {
      position: absolute;
      width: 100%;

      label {
        cursor: pointer;
      }
    }
  }
}
</style>
