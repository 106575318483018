<template>
  <!-- Loading State -->
  <LoadingState v-if="isLoading" />
  <!-- Old Component if Feature Flag is Disabled and Not Loading -->
  <Index v-else-if="!isEnabled" />
  <!-- New Component if Feature Flag is Enabled -->
  <div v-else class="column content-box settings-box no-padding">
    <div class="settings-container">
      <iframe
        :src="marketingUrl"
        frameborder="0"
        style="width: calc(100% + 16px); height: 100%; margin-left: -16px"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import Index from './old/Index.vue';
import LoadingState from 'dashboard/components/ui/LoadingState.vue';

export default {
  components: {
    Index,
    LoadingState,
  },
  data() {
    return {
      isLoading: false,
      isEnabled: false,
      openedWindow: null,
      intervalCheck: null,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId', // Assuming this getter exists and works as expected
    }),
    flowBuilderUrl() {
      return process.env.FLOW_BUILDER_URL || null;
    },
    marketingUrl() {
      return `${process.env.CAMPAIGN_URL}wa/templates?hd_account_id=${this.accountId}&fullscreen=true`;
    },
  },
  mounted() {
    this.checkFeatureFlag();
  },
  methods: {
    async checkFeatureFlag() {
      if (!this.flowBuilderUrl) return; // Ignore if FLOW_BUILDER_URL is not set

      this.isLoading = true;

      try {
        const token = await this.$auth.getTokenSilently();
        const url = `${this.flowBuilderUrl}/auth/features?hd_account_id=${this.accountId}`;
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(url, { headers });

        const feature = response.data.results.find(
          f => f.name === 'central_templates'
        );
        this.isEnabled = feature ? feature.enabled : false;
      } catch (error) {
        // Handle error as needed
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-box {
  padding-top: $space-largest;
  padding-left: $zero;
  padding-right: $zero;
  overflow: hidden;

  .settings-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
