<template>
  <ErrorPage v-if="$auth.error" />
  <div v-else class="full-height full-width">
    <LoadingState message="Taking you to your dashboard..." />
  </div>
</template>

<script>
import ErrorPage from './ErrorPage';
import LoadingState from 'dashboard/components/ui/LoadingState';

export default {
  components: {
    ErrorPage,
    LoadingState,
  },
};
</script>
