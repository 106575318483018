<template>
  <div>
    <div
      v-for="index in rows"
      :key="index"
      class="flex-row-justify-center margin-container"
    >
      <div
        v-for="idx in index + rows"
        :key="idx"
        class="header-skeleton-margin"
      >
        <div class="skeleton-animation header-skeleton" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: [Number, String],
      default: 2,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.margin-container {
  margin: $space-large $space-micro;
  justify-content: left;

  .header-skeleton-margin {
    margin-right: $space-small;
    width: 50px;

    .header-skeleton {
      height: 18px;
    }
  }
}
</style>
