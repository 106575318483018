<template>
  <li
    :class="[
      'tab-element flex-row flex-align flex-justify',
      { 'is-active': active },
    ]"
    @click.prevent="onTabClick"
  >
    <span
      :class="{
        'title-h4 text-dark': active,
        'title-h5_m text-light': !active,
      }"
      v-text="name"
    />
    <span class="tab-element--bar" />
  </li>
</template>
<script>
import TWEEN from 'tween.js';

export default {
  name: 'WootTabsItem',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      animatedNumber: 0,
    };
  },

  computed: {
    active() {
      return this.index === this.$parent.index;
    },
  },

  watch: {
    count(newValue, oldValue) {
      let animationFrame;
      const animate = time => {
        TWEEN.update(time);
        animationFrame = window.requestAnimationFrame(animate);
      };
      const tweeningNumber = { value: oldValue };
      new TWEEN.Tween(tweeningNumber)
        .easing(TWEEN.Easing.Quadratic.Out)
        .to({ value: newValue }, 0)
        .onUpdate(() => {
          this.animatedNumber = tweeningNumber.value.toFixed(0);
        })
        .onComplete(() => {
          window.cancelAnimationFrame(animationFrame);
        })
        .start();
      animationFrame = window.requestAnimationFrame(animate);
    },
  },
  methods: {
    onTabClick() {
      if (!this.disabled) this.$parent.$emit('change', this.index, this.data);
    },
  },
};
</script>
