<template>
  <div class="filter flex-column gap--small">
    <div v-for="(group, groupIndex) in filterList" :key="groupIndex">
      <div
        v-if="group.GROUP_NAME"
        class="title-h6_m text-light mg-bottom--smaller"
        v-text="group.GROUP_NAME"
      />
      <div
        class="filters-container flex-row flex-wrap flex-align--start gap--small"
      >
        <template v-for="item in group.FILTERS">
          <component
            :is="getComponent(item)"
            v-if="isFilterVisible(item)"
            :key="item.key"
            :ref="getFilterRef(item)"
            :item="item"
            :size="'small'"
            :class="getCustomClass(item)"
            :button-style="getButtonStyle(item)"
            :custom-style="getCustomStyle(item)"
            :option-list="getOptionList(item)"
            :default-option="getDefaultOption(item)"
            :front-icon="getFrontIcon(item)"
            :selected-agent-id="appliedFilters.selectedAgent"
            :selected-team-id="appliedFilters.teamId"
            :filter-title="item.static_title"
            :default-selection="defaultRangeSelection"
            :active-status="activeStatus"
            :selected-option="identifier"
            :highlight-selected="isHighlightSelected(item)"
            :static-title="item.static_title"
            :show-selected-text="false"
            :custom-button="item.customButton"
            :toggle-filter="(...args) => getFilterToggler(item, args)"
            @click="value => handleClick(item, value)"
            @date-range-change="onDateRangeChange"
            @labelSelected="handleLabelClick"
            @selected-option="handleSelectedOption"
            @dropdown-opened="handleDropdownOpened"
            @statusFilterChange="updateStatusType"
          >
            <template v-slot:default="{ data }">
              <component :is="getCollapsedComponent(item)" :data="data" />
            </template>
          </component>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TagsFilter from './TagsFilter';
import TagsFilterButton from './TagsFilterButton';
import AgentsFilter from './AgentsFilter';
import StatusTimeFilter from './StatusTimeFilter';
import FacebookFilter from './FacebookFilter';
import TeamsFilter from './TeamsFilter';
import ConversationStatus from './ConversationStatus';

import inboxMixin from 'dashboard/mixins/inbox';
import conversationMixin from 'dashboard/mixins/conversations';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import assigneeTypeMixin from 'dashboard/mixins/assigneeTypeMixin';

import wootConstants from 'dashboard/constants';

export default {
  components: {
    TagsFilter,
    TagsFilterButton,
    AgentsFilter,
    FacebookFilter,
    StatusTimeFilter,
    TeamsFilter,
    ConversationStatus,
  },
  mixins: [
    inboxMixin,
    conversationMixin,
    googleAnalyticsMixin,
    mixPanelAnalyticsMixin,
    assigneeTypeMixin,
  ],
  props: {
    filterList: {
      type: Array,
      default: () => [],
    },
    appliedFilters: {
      type: Object,
      default: () => ({}),
    },
    parentComponentName: {
      type: String,
      default: '',
    },
    maxDropdownHeight: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      selectedInbox: null,
      selectedAgentId: null,
      selectedTeamId: null,
      identifier: null,
      selectedFilters: [],
      slaOptions: [
        { id: 1, name: 'SLA Breached', value: true },
        { id: 2, name: 'SLA Not Breached', value: undefined },
      ],
      relevanceFilterOptions: [
        { id: 1, name: 'unread' },
        { id: 2, name: 'alert' },
        { id: 3, name: 'starred' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      inboxes: 'inboxes/getInboxes',
      activeInbox: 'getSelectedInbox',
    }),
    assignedBy() {
      const assignedBy = this.appliedFilters.assigned_by;
      return assignedBy?.length ? `Assigned by ${assignedBy}` : 'All';
    },
    resolvedBy() {
      const resolvedBy = this.appliedFilters.resolved_by;
      return resolvedBy?.length ? `Resolved by ${resolvedBy}` : 'All';
    },
    closedBy() {
      const closedBy = this.appliedFilters.closed_by;
      return closedBy?.length ? `Closed by ${closedBy}` : 'All';
    },
    defaultRangeSelection() {
      return this.appliedFilters?.statusTimeRange?.default;
    },
    highlightAssignedBy() {
      return !!(this.assignedBy && this.assignedBy !== 'All');
    },
    highlightResolvedBy() {
      return !!(this.resolvedBy && this.resolvedBy !== 'All');
    },
    highlightClosedBy() {
      return !!(this.closedBy && this.closedBy !== 'All');
    },
    assignedByOption() {
      return !this.assignedBy || this.assignedBy === 'All'
        ? 'Assigned By'
        : this.assignedBy;
    },
    resolvedByOption() {
      return !this.resolvedBy || this.resolvedBy === 'All'
        ? 'Resolved By'
        : this.resolvedBy;
    },
    closedByOption() {
      return !this.closedBy || this.closedBy === 'All'
        ? 'Closed By'
        : this.closedBy;
    },
    inboxList() {
      return this.inboxes.map(inbox => {
        let { name: icon, color } = this.computedInboxClass(inbox);

        return {
          id: inbox.id,
          name: inbox.name,
          status: inbox.status,
          type: inbox.channel_type,
          channel_id: inbox.channel_id,
          phoneNumber: inbox.phone_number,
          instagram_add_on: inbox.instagram_add_on,
          selected_agent: null,
          icon: icon,
          color: color,
        };
      });
    },
    inboxFilterIcon() {
      const inboxIcon = this.inboxes.find(
        inbox => this.selectedInboxComputed?.id === inbox.id
      );
      return this.computedInboxClass(inboxIcon).name;
    },
    modifiedInboxList() {
      return [{ name: 'All', id: 0 }, ...this.inboxList];
    },
    inbox() {
      return (
        this.selectedInbox ||
        this.$store.getters['inboxes/getInbox'](this.activeInbox)
      );
    },
    selectedInboxComputed() {
      return this.inbox.id
        ? {
            id: this.inbox.id,
            name: this.inbox.name,
            icon: this.computedInboxClass(this.inbox).name,
          }
        : { id: 0, name: 'All Inboxes' };
    },
    highlightInboxName() {
      return !!(
        this.selectedInboxComputed.name &&
        this.selectedInboxComputed.name !== 'All Inboxes'
      );
    },
    isFacebookInbox() {
      const inbox = this.inboxes.find(
        item => item.id === Number(this.selectedInboxComputed.id)
      );
      return !!inbox && inbox.channel_type === 'Channel::FacebookPage';
    },
    showAssignedByFilter() {
      return (
        this.activeAssignee !== 'unassigned' && this.activeStatus !== 'bot'
      );
    },
    showResolvedByFilter() {
      return this.activeAssignee === 'all' && this.activeStatus === 'resolved';
    },
    showClosedByFilter() {
      return this.activeAssignee === 'all' && this.activeStatus === 'closed';
    },
    assignedByOptions() {
      return this.$t('FILTERS.ASSIGNED_BY_LIST');
    },
    resolvedByOptions() {
      return this.$t('FILTERS.RESOLVED_BY_LIST');
    },
    closedByOptions() {
      return this.$t('FILTERS.CLOSED_BY_LIST');
    },
    activeStatus() {
      return this.appliedFilters.status || wootConstants.STATUS_TYPE.OPEN;
    },
    activeAssignee() {
      return this.appliedFilters.assigneeType || wootConstants.ASSIGNEE_TYPE.ME;
    },
    isSlaBreached() {
      return this.appliedFilters.sla_breached;
    },
    selectedSlaOption() {
      const selectedOptionName = this.isSlaBreached
        ? this.slaOptions[0].name
        : this.slaOptions[1].name;

      return selectedOptionName;
    },
    defaultAssigneeTabOption() {
      return this.assigneeTabsList.find(tab => tab.key === this.activeAssignee);
    },
  },
  watch: {
    appliedFilters: {
      handler: 'setFilterState',
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setFilterState(filters) {
      const { inboxId } = filters;

      this.selectedInbox = this.inboxes.find(inbox => inbox.id === inboxId);

      this.$nextTick(() => {
        this.setTagsFilter(filters.labels);
        this.setRelevanceFilter(filters);
      });
    },
    setTagsFilter(labels) {
      if (this.$refs.tagsFilter?.[0]?.$refs.multiSelector) {
        this.$refs.tagsFilter[0].$refs.multiSelector.selectedOptionId =
          labels || [];
      }
    },
    setRelevanceFilter(filters) {
      if (this.$refs.relevanceFilter?.[0]) {
        this.$refs.relevanceFilter[0].selectedOption = this.getRelevanceFilter(
          filters
        ).map(el => el.name);
        this.$refs.relevanceFilter[0].selectedOptionId = this.getRelevanceFilter(
          filters
        ).map(el => el.id);
      }
    },
    getRelevanceFilter(filters) {
      const activeFilters = [];

      this.relevanceFilterOptions.forEach(el => {
        if (filters[el.name]) {
          activeFilters.push(el);
        }
      });

      return activeFilters;
    },
    computedInboxClass(child) {
      if (child?.channel_type) {
        const { channel_type, phone_number, instagram_add_on } = child;
        return this.getInboxClassByType(
          channel_type,
          phone_number,
          '',
          instagram_add_on
        );
      }
      return '';
    },
    updateStatusType(val) {
      this.$emit('filterChange', 'conversation_status', val);
    },
    getFilterToggler(item, args) {
      if (item.key === 'relevance') {
        this.handleSelections(args[1]);
        return;
      }

      this.filterToggle(item.key, args[2]);
    },
    filterToggle(key, val) {
      this.$emit('filterChange', key, val);
    },
    applyInboxFilter(val) {
      this.selectedInbox = val;
      this.$emit('filterChange', 'change_inbox', val);
    },
    onAssignedByChange(key) {
      this.$emit(
        'filterChange',
        'assigned_by',
        key.id === 'none' ? null : key.id
      );
    },
    onResolvedByChange(key) {
      this.$emit(
        'filterChange',
        'resolved_by',
        key.id === 'none' ? null : key.id
      );
    },
    onClosedByChange(key) {
      this.$emit(
        'filterChange',
        'closed_by',
        key.id === 'none' ? null : key.id
      );
    },
    onSlaChange(option) {
      this.$emit('filterChange', 'sla_breached', option.value);
    },
    onDateRangeChange(value) {
      this.$emit('filterChange', 'status_time', value);
    },
    handleLabelClick(selectedLabel) {
      this.mix_panel_clicked_tag_filter_menu_item(
        this.parentComponentName,
        selectedLabel.label_type
      );
    },
    handleDropdownOpened() {
      this.mix_panel_clicked_ticket_sort();
    },
    handleSelectedOption(selectedOptionValue) {
      this.mix_panel_sorted_tickets(selectedOptionValue);
    },
    handleSelections(newSelection) {
      this.selectedFilters = newSelection;
      this.$emit('filterChange', 'relevance', newSelection);
      this.logFilterChange(newSelection);
    },
    onUpdateAssigneeTab(value) {
      this.$emit('filterChange', 'assignee_type', value);
    },
    getFilterRef(item) {
      const refMap = { label: 'tagsFilter', relevance: 'relevanceFilter' };
      return refMap[item.key];
    },
    getComponent(item) {
      const componentsMap = {
        assignee_type: 'woot-single-selector',
        sla_breached: 'woot-single-selector',
        inbox: 'woot-single-selector',
        relevance: 'woot-multi-selector',
        agent: 'AgentsFilter',
        label: 'TagsFilter',
        teams: 'TeamsFilter',
        assigned_by: 'woot-single-selector',
        resolved_by: 'woot-single-selector',
        closed_by: 'woot-single-selector',
        status_time: 'StatusTimeFilter',
        facebook: 'FacebookFilter',
        conversation_status: 'ConversationStatus',
      };
      return componentsMap[item.key] || 'div';
    },
    getCollapsedComponent(item) {
      const componentsMap = {
        label: 'TagsFilterButton',
      };
      return componentsMap[item.key] || 'div';
    },
    isFilterVisible(item) {
      const visibilityMap = {
        assigned_by: this.showAssignedByFilter,
        resolved_by: this.showResolvedByFilter,
        closed_by: this.showClosedByFilter,
        facebook: this.isFacebookInbox,
      };

      return visibilityMap[item.key] !== undefined
        ? visibilityMap[item.key]
        : true;
    },
    getCustomClass(item) {
      const classMap = {
        inbox: 'inbox-filter',
      };
      return classMap[item.key] || '';
    },
    getButtonStyle(item) {
      const defaultStyle = {
        borderRadius: '4px',
        color: '#808975',
        padding: '0.4rem 0.6rem',
      };
      return item.key === 'inbox'
        ? { ...defaultStyle, padding: '0.4rem' }
        : defaultStyle;
    },
    getCustomStyle(item) {
      const defaultStyle = { left: 0, maxHeight: this.maxDropdownHeight };
      const widthMap = {
        inbox: '20rem',
        resolved_by: '180px',
        closed_by: '180px',
        label: '24rem',
      };
      return {
        ...defaultStyle,
        width: widthMap[item.key] || 'auto',
        'overflow-x': 'hidden',
      };
    },
    getOptionList(item) {
      const listMap = {
        assignee_type: this.assigneeTabsList,
        inbox: this.modifiedInboxList,
        assigned_by: this.assignedByOptions,
        resolved_by: this.resolvedByOptions,
        closed_by: this.closedByOptions,
        relevance: this.relevanceFilterOptions,
        sla_breached: this.slaOptions,
      };
      return listMap[item.key] || [];
    },
    getDefaultOption(item) {
      const optionMap = {
        assignee_type: this.defaultAssigneeTabOption?.name,
        inbox: this.selectedInboxComputed,
        assigned_by: this.assignedByOption,
        resolved_by: this.resolvedByOption,
        closed_by: this.closedByOption,
        relevance: 'Relevance',
        sla_breached: this.selectedSlaOption,
      };
      return optionMap[item.key] || '';
    },
    getFrontIcon(item) {
      return item.key === 'inbox' ? this.inboxFilterIcon || 'inbox' : item.icon;
    },
    isHighlightSelected(item) {
      const highlightMap = {
        inbox: this.highlightInboxName,
        assigned_by: this.highlightAssignedBy,
        resolved_by: this.highlightResolvedBy,
        closed_by: this.highlightClosedBy,
      };
      return highlightMap[item.key] !== undefined
        ? highlightMap[item.key]
        : false;
    },
    handleClick(item, value) {
      const clickHandlers = {
        assignee_type: this.onUpdateAssigneeTab,
        inbox: this.applyInboxFilter,
        assigned_by: this.onAssignedByChange,
        resolved_by: this.onResolvedByChange,
        closed_by: this.onClosedByChange,
        sla_breached: this.onSlaChange,
      };

      return clickHandlers[item.key](value) || (() => {});
    },
  },
};
</script>
