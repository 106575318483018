/* eslint no-param-reassign: 0 */
import * as types from '../mutation-types';

import StoreNotificationsAPIV2 from 'dashboard/api/limekit/v2/storeNotifications';

const state = {
  notificationFlows: [],
  records: [],
  uiFlags: {
    fetchingList: false,
    creatingItem: false,
    updatingItem: false,
    deletingItem: false,
  },
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  sendPrivateReply: async ({ commit }, data) => {
    commit(types.default.SET_STORE_NOTIFICATIONS_UI_FLAG, {
      creatingItem: true,
    });
    try {
      const response = await StoreNotificationsAPIV2.privateReply(data);
      commit(types.default.SET_STORE_NOTIFICATIONS_UI_FLAG, {
        creatingItem: false,
      });
      return response.status;
    } catch (error) {
      commit(types.default.SET_STORE_NOTIFICATIONS_UI_FLAG, {
        creatingItem: false,
      });
      throw error;
    }
  },
};

export const mutations = {
  [types.default.SET_STORE_NOTIFICATIONS_UI_FLAG]($state, uiFlag) {
    $state.uiFlags = { ...$state.uiFlags, ...uiFlag };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
