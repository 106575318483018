<template>
  <div>
    <transition name="slide-up" appear>
      <div class="flex-row flex-align" :class="bannerClass">
        <slot />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    variant: { type: String, default: 'warn' },
  },
  computed: {
    bannerClass() {
      const { variant } = this;
      return `top-banner top-banner--${variant}`;
    },
  },
};
</script>
