<template>
  <span v-if="contacts.length === 0" class="text-light body-b3">
    No Contacts added, please search and add from above
  </span>
  <transition-group
    v-else
    name="menu-list"
    tag="div"
    class="selected-contact-results flex-row flex-wrap"
  >
    <div v-for="({ email, type }, idx) in contacts" :key="`${type}_${idx}`">
      <account-pii-mask-wrapper
        :pii-data="email"
        :mask-state="
          type !== 'existing' ? MASK_STATE.noMask : MASK_STATE.serverMask
        "
        :mask-type="MASK_TYPES.EMAIL_INPUT"
        :unmask-payload="unmaskRequestPayload(contacts[idx])"
        unmask-action="piiMask/logUnmaskEvent"
        :on-unmask="data => updateField('email', data, idx)"
        @update="updateField('email', $event, idx)"
      >
        <template v-slot="{ data, isMasked }">
          <woot-chips
            :class="`CREATE_TICKET_EMAIL_${contactType}_${idx}`"
            show-cancel-icon
            :show-tooltip="false"
            variant="primary-small"
            :title="isMasked ? email : data"
            style="width: fit-content; max-width: 23.2rem;"
            @click="removeContact"
          />
        </template>
      </account-pii-mask-wrapper>
    </div>
  </transition-group>
</template>

<script>
import {
  MASK_TYPES,
  MASK_STATE,
} from 'dashboard/components/widgets/piiMask/constants';
import AccountPiiMaskWrapper from 'dashboard/components/widgets/piiMask/AccountPiiMaskWrapper';
import { mapGetters } from 'vuex';

export default {
  components: { AccountPiiMaskWrapper },
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    contactType: {
      type: String,
      default: 'TO',
    },
  },
  data() {
    return {
      MASK_TYPES,
      MASK_STATE,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
  },
  methods: {
    unmaskRequestPayload(contact) {
      return {
        user_id: this.currentUser.id,
        resource_type: 'Contact',
        resource_id: contact.contact_id,
      };
    },
    updateField(fieldName, newValue, index) {
      const updatedContacts = [...this.contacts];
      updatedContacts[index][fieldName] = newValue;
      this.$emit('updateContacts', updatedContacts);
    },
    removeContact(emailToRemove) {
      const updatedContacts = this.contacts.filter(
        contact => contact.email !== emailToRemove
      );
      this.$emit('updateContacts', updatedContacts);
    },
  },
};
</script>
