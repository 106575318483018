<template>
  <th :style="colHeaderStyle" class="title-h4 text-dark">
    <div
      :style="sortable && 'cursor: pointer'"
      :class="['flex-row', 'flex-align', colHeaderClasses]"
      @click="toggleSort"
    >
      <span v-text="label" />
      <span
        v-if="sortable"
        :class="['sort-icon', { 'sort-icon--filled': !!sortDirection }]"
      >
        <icons
          :name="
            sortDirection === 'asc' || sortDirection === ''
              ? 'chevronTop'
              : 'chevronDown'
          "
          :color="!!sortDirection ? 'white' : 'darkestgrey'"
          :show-title="false"
          view="0 0 24 24"
          size="semimedium"
        />
      </span>
    </div>
  </th>
</template>
<script>
export default {
  props: {
    sortable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    prop: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: '',
    },
  },
  data() {
    return { sortDirection: '' };
  },
  computed: {
    colHeaderStyle() {
      let style = {};

      if (this.sortable) style = { ...style, 'user-select': 'none' };

      return style;
    },
    colHeaderClasses() {
      const { align } = this;

      return {
        'flex-justify': align === 'center',
        'flex-justify--start': align === 'left',
        'flex-justify--end': align === 'right',
      };
    },
  },
  methods: {
    toggleSort() {
      if (!this.sortable) return;
      if (this.sortDirection === 'asc') this.sortDirection = 'desc';
      else if (this.sortDirection === 'desc') this.sortDirection = '';
      else this.sortDirection = 'asc';
      this.$emit('sort', { prop: this.prop, direction: this.sortDirection });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.sort-icon {
  border: 1px solid $secondary-blue;
  border-radius: 0.3rem;
  height: $space-normal;
  margin: $zero $space-normal;
  width: $space-normal;

  &--filled {
    background-color: $dark-grey;
  }
}
</style>
