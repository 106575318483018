<template>
  <div v-if="!uiFlags.fetchingMetrics" class="metrics-card-container">
    <metrics-card
      v-for="(item, index) in metricsData"
      :key="index"
      :title="item.title"
      :sub-title="item.sub"
    />
  </div>
  <div v-else class="metrics-card-container ">
    <metrics-card-skeleton v-for="(item, index) in metricsData" :key="index" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import MetricsCard from '../components/MetricsCard';
import MetricsCardSkeleton from 'dashboard/routes/dashboard/analytics/components/MetricsCardSkeleton';

export default {
  components: { MetricsCard, MetricsCardSkeleton },
  computed: {
    ...mapGetters({
      uiFlags: 'deepShopifyAnalytics/getUiFlags',
      metrics: 'deepShopifyAnalytics/getMetrics',
    }),
    metricsData() {
      return [
        {
          title: this.metrics.ordersCount,
          sub: this.$t('ANALYTICS.SHOPIFY_ANALYTICS.METRICS.ORDERS_CREATED'),
        },
        {
          title: this.metrics.ordersEditted,
          sub: this.$t('ANALYTICS.SHOPIFY_ANALYTICS.METRICS.ORDERS_EDITED'),
        },
        {
          title: this.metrics.ordersCancelledCount,
          sub: this.$t('ANALYTICS.SHOPIFY_ANALYTICS.METRICS.CANCELLED_ORDERS'),
        },
        {
          title: this.metrics.ordersRefundedCount,
          sub: this.$t('ANALYTICS.SHOPIFY_ANALYTICS.METRICS.REFUND_ORDERS'),
        },
      ];
    },
  },
};
</script>
