<template>
  <div class="time-selector">
    <label v-if="label" class="formlabel">
      <span class="title-h5 text-dark" v-text="label" />
    </label>
    <div class="time-selection-options">
      <div v-for="(options, type) in timeOptions" :key="type">
        <span class="body-b3 text-light" v-text="options.title" />
        <woot-single-selector
          size="small"
          :option-list="options.optionList"
          :default-option="options.selectedOption.value.toString()"
          :button-style="buttonStyle"
          :custom-style="customStyle"
          @click="onSelect(type, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    buttonStyle: {
      type: Object,
      default: () => ({}),
    },
    customStyle: {
      type: Object,
      default: () => ({ maxHeight: '20rem' }),
    },
    timeInMinutes: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedDay: { name: '0', value: 0 },
      selectedHour: { name: '0', value: 0 },
      selectedMinute: { name: '0', value: 0 },
    };
  },
  computed: {
    timeOptions() {
      return {
        day: {
          title: 'Day',
          optionList: this.dayOptions,
          selectedOption: this.selectedDay,
        },
        hour: {
          title: 'Hour',
          optionList: this.hourOptions,
          selectedOption: this.selectedHour,
        },
        minute: {
          title: 'Minute',
          optionList: this.minuteOptions,
          selectedOption: this.selectedMinute,
        },
      };
    },
    dayOptions() {
      return Array.from({ length: 31 }, (v, k) => ({
        id: k,
        name: `${k} day${k !== 1 ? 's' : ''}`,
        value: k,
      }));
    },
    hourOptions() {
      return Array.from({ length: 24 }, (v, k) => ({
        id: k,
        name: `${k} hour${k !== 1 ? 's' : ''}`,
        value: k,
      }));
    },
    minuteOptions() {
      return Array.from({ length: 60 }, (v, k) => ({
        id: k,
        name: `${k} minute${k !== 1 ? 's' : ''}`,
        value: k,
      }));
    },
  },
  mounted() {
    this.setSelectedTimes(this.timeInMinutes);
  },
  methods: {
    setSelectedTimes(totalMinutes) {
      const days = Math.floor(totalMinutes / (24 * 60));
      const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
      const minutes = totalMinutes % 60;

      this.selectedDay = { name: `${days}`, value: days };
      this.selectedHour = { name: `${hours}`, value: hours };
      this.selectedMinute = { name: `${minutes}`, value: minutes };
    },
    onSelect(type, option) {
      if (type === 'day') {
        this.selectedDay = option;
      } else if (type === 'hour') {
        this.selectedHour = option;
      } else if (type === 'minute') {
        this.selectedMinute = option;
      }
      this.emitTotalMinutes();
    },
    emitTotalMinutes() {
      const totalMinutes =
        this.selectedDay.value * 24 * 60 +
        this.selectedHour.value * 60 +
        this.selectedMinute.value;
      this.$emit('select', totalMinutes);
    },
  },
};
</script>

<style lang="scss" scoped>
.time-selector {
  .time-selection-options {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .time-selector__group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
}
</style>
