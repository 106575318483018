<template>
  <div class="base-table-container">
    <table class="woot-table">
      <thead v-if="showTableHeader">
        <th v-if="value.length" />
        <base-table-column
          v-for="(column, index) in columns"
          :key="column.prop || index"
          v-bind="column"
          @sort="onSort"
        />
        <!-- extra empty headers for extra actions included in table data -->
        <th v-if="hasActionsSlot" style="width: 0%" />
        <th v-if="hasExtraActionsSlot" style="width: 0%" />
      </thead>
      <transition-group
        v-if="tableData.length && !loading"
        tag="tbody"
        :name="computedTransition"
        :style="{ '--total': tableData.length }"
      >
        <!-- table rows -->
        <tr
          v-for="(row, index) in tableData"
          :key="row.id || index"
          v-on-clickaway="deselectRow"
          :style="[{ cursor: 'pointer' }, { '--i': index }]"
          :class="{
            'selected-row':
              selectedRow.index === index || value.includes(index),
            loaded: !loading,
          }"
          @click="selectRow({ row, index, allowMultiSelect: false })"
        >
          <td
            v-if="value.length && canShowCheckbox(row)"
            :style="{
              '--i': 1,
              '--total': 1,
            }"
          >
            <label style="cursor: pointer" class="container-checkbox">
              <input
                type="checkbox"
                :value="value"
                :checked="value.includes(index)"
                @input="selectRow({ row, index, allowMultiSelect: true })"
              />
              <span class="grey-checkmark checkmark" />
            </label>
          </td>
          <td v-else-if="value.length">
            <label class="container-checkbox disabled">
              <input type="checkbox" />
              <span class="grey-checkmark checkmark" />
            </label>
          </td>
          <td
            v-for="(column, idx) in columns"
            :key="column.prop || idx"
            :style="getStyles(column)"
            :class="[
              {
                'text-align--center': column.align === 'center',
                'text-align--left': column.align === 'left',
                'text-align--right': column.align === 'right',
              },
            ]"
          >
            <template v-if="column.component">
              <component-loader
                :component-name="column.component"
                :data="row"
              />
            </template>
            <div
              v-else-if="column.prop"
              class="line-clamp-2 title-h5 text-dark capitalize"
              :class="column.customClass"
              :title="row[column.prop]"
              v-text="row[column.prop]"
            />
          </td>
          <!-- extra actions -->
          <td v-if="hasExtraActionsSlot">
            <div class="full-width flex-row flex-justify--end">
              <slot name="extra-actions" :row="{ ...row, index }" />
            </div>
          </td>
          <td v-if="hasActionsSlot">
            <slot name="actions" :row="{ row, index }" />
          </td>
        </tr>
      </transition-group>

      <!-- loading state -->
      <slot v-if="hasLoadingSlot && loading" name="loading-state" />
      <new-table-skeleton v-else-if="loading" :columns="numberOfCols" />
    </table>
    <!-- zero state -->
    <slot
      v-if="hasZeroStateSlot && !tableData.length && !loading"
      name="zero-state"
    />
    <div
      v-else-if="!tableData.length && !loading"
      class="flex-row flex-justify flex-align zero-state"
    >
      <span class="title-h5 text-light" v-text="'No Data'" />
    </div>
  </div>
</template>
<script>
import BaseTableColumn from './BaseTableColumn';
import ComponentLoader from 'dashboard/components/ComponentLoader';
import NewTableSkeleton from 'dashboard/components/NewTableSkeleton';

import { mixin as clickaway } from 'vue-clickaway';

export default {
  components: {
    BaseTableColumn,
    ComponentLoader,
    NewTableSkeleton,
  },
  mixins: [clickaway],
  props: {
    enableAnimations: { type: Boolean, default: false },
    value: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return { selectedRow: {}, tableData: [], asc: true };
  },
  computed: {
    hasActionsSlot() {
      return this.actions.length && !!this.$scopedSlots.actions;
    },
    hasLoadingSlot() {
      return !!this.$slots['loading-state'];
    },
    hasZeroStateSlot() {
      return !!this.$slots['zero-state'];
    },
    hasExtraActionsSlot() {
      return !!this.$scopedSlots['extra-actions'];
    },
    showTableHeader() {
      return !this.columns.map(column => column.label).every(label => !label);
    },
    numberOfCols() {
      let count = this.columns.length;
      if (this.hasActionsSlot) count += 1;
      if (this.hasExtraActionsSlot) count += 1;

      return count;
    },
    computedTransition() {
      return this.enableAnimations ? 'staggered' : '';
    },
  },
  watch: {
    data: {
      handler(value) {
        this.tableData = [...value];
        this.selectedRow = {};
      },
      immediate: true,
    },
  },
  inject: {
    canSelectRow: {
      default: () =>
        // eslint-disable-next-line func-names
        function() {
          return true;
        },
    },
  },
  methods: {
    getStyles(column) {
      let style = {};
      if (column.width) style = { ...style, width: `${column.width}` };

      if (column.maxWidth)
        style = { ...style, 'max-width': `${column.maxWidth}` };

      if (column.minWidth)
        style = { ...style, 'min-width': `${column.minWidth}` };

      return style;
    },
    toggledSort({ sortBy }) {
      if (this.selectedRow.index !== undefined) {
        this.selectedRow = {};
        this.$emit('selectRow', {
          row: {},
          index: -1,
          allowMultiSelect: false,
        });
      }
      return this.tableData.sort((a, b) => {
        let modifier = 1;
        if (!this.asc) modifier = -1;
        if (a[sortBy] < b[sortBy]) return -1 * modifier;
        if (a[sortBy] > b[sortBy]) return 1 * modifier;
        return 0;
      });
    },
    onSort({ prop, direction }) {
      if (direction === '') {
        this.tableData = this.data;
        return;
      }
      if (direction === 'asc') this.asc = true;
      if (direction === 'desc') this.asc = false;
      this.toggledSort({ sortBy: prop });
    },
    selectRow(data) {
      const { allowMultiSelect } = data;

      if (!allowMultiSelect) {
        if (data.index === this.selectedRow.index) return;
        this.selectedRow = { ...data.row, index: data.index };
      } else this.deselectRow();
      this.$emit('selectRow', data);
    },
    deselectRow() {
      if (this.value.length) this.selectedRow = {};
    },
    canShowCheckbox(row) {
      return this.canSelectRow(row);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.scrollable-table {
  height: calc(100% - 5.6rem);
  overflow: overlay;
}

.base-table-container {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;

  .woot-table {
    border-collapse: separate;
    margin-bottom: $zero;

    thead {
      background: $info-blue-20;
      position: sticky;
      top: $zero;
    }

    th {
      padding: $space-normal $space-slab;
      text-transform: none;
    }

    tr {
      border-bottom: none;

      td {
        padding: $space-slab $space-normal;

        .container-checkbox {
          margin: $zero $zero $space-normal $space-normal;
        }

        .disabled {
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }

    .loaded {
      &:hover {
        background-color: $neutral-grey-100;
      }
    }

    .selected-row {
      background-color: $pg-light-1;
      cursor: default;

      &:hover {
        background-color: $pg-light-1;
      }
    }
  }
}

td,
.zero-state {
  border-top: $space-small solid $neutral-grey-200;
}

tr,
.zero-state {
  background-color: $neutral-white;
  padding: $space-normal;
}
</style>
