<template>
  <div :class="{ 'email-body': isEmail }">
    <video-view v-if="isVideo" :url="videoUrl" />
    <youtube-view v-else-if="isYoutube" :url="isYoutube" />
    <span v-else class="message-text__wrap">
      <div
        class="message-span"
        :class="{
          'message-activity': [2, 7].includes(data.message_type),
          'message-email body-b2': isEmail,
          'message-deleted': isDeleted,
        }"
      >
        <!-- Display the temporary content here -->
        <span v-if="temporaryContent" v-html="temporaryContent" />
      </div>
    </span>
  </div>
</template>

<script>
import Video from 'shared/helpers/VideoHelper';
import VideoView from 'shared/components/VideoView';
import YoutubeView from 'shared/components/YoutubeView';

import {
  S3_BUCKET_URL,
  PLACEHOLDER_MESSAGE,
} from 'shared/constants/messages.js';

export default {
  components: {
    VideoView,
    YoutubeView,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
    readableTime: {
      type: String,
      default: '',
    },
    isEmail: {
      type: Boolean,
      default: true,
    },
    emailSignature: {
      type: [String, Boolean],
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
    isCaption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      temporaryContent: '',
      videoHelper: new Video(),
    };
  },
  computed: {
    isVideo() {
      return this.videoHelper.checkVideo(this.data.content);
    },
    isYoutube() {
      return this.videoHelper.checkYoutube(this.data.content);
    },
    videoUrl() {
      return this.data.content;
    },
  },
  watch: {
    message() {
      if (
        this.message.startsWith(S3_BUCKET_URL) ||
        this.message === PLACEHOLDER_MESSAGE
      ) {
        // If the message is temporary, save it in the temporaryContent data property
        this.temporaryContent = this.message;
      } else {
        // If the message is the actual content, clear the temporary content and append the message to the shadow DOM
        this.temporaryContent = '';
        this.appendMessageToShadowDom();
      }
    },
  },
  mounted() {
    if (
      this.message.startsWith(S3_BUCKET_URL) ||
      this.message === PLACEHOLDER_MESSAGE
    )
      this.temporaryContent = this.message;
    else this.appendMessageToShadowDom();
  },
  methods: {
    appendMessageToShadowDom() {
      const el = this.$el.querySelector('.message-span');
      let shadow = el.shadowRoot;

      // If not, create a new one
      if (!shadow) shadow = el.attachShadow({ mode: 'open' });

      const div = document.createElement('div');
      div.classList.add('shadow-domed');
      div.innerHTML = this.message;
      shadow.appendChild(div);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.message-span ::v-deep p::after {
  content: '';
  padding-right: $space-jumbo;
  display: inline-block;
}

.message-email {
  overflow: auto;
}

.message-email ::v-deep p {
  margin-bottom: 0;

  &::after {
    display: none;
  }
}

.message-activity ::v-deep p::after {
  content: none;
}

.message-deleted ::v-deep p {
  color: $neutral-grey-600;
}
</style>
