import abortControllerManager from '../../api/abortController';

export const wrapActionWithAbortController = (actionName, action) => {
  return async ({ commit }, data) => {
    const abortController = abortControllerManager.createController(actionName);

    try {
      const response = await action({ commit, abortController }, data);

      // Clear the controller once the response is received if it was created
      if (abortController) {
        abortControllerManager.clearController(actionName);
      }

      return response;
    } catch (error) {
      // Clear the controller on error as well if it was created
      if (abortController) {
        abortControllerManager.clearController(actionName);
      }
      throw error;
    }
  };
};
