<template>
  <div>
    <variable-list
      v-if="shouldShowVariables"
      :search-key="variableSearchTerm"
      @click="insertVariable"
      @onSelectVariable="insertVariable"
    >
    </variable-list>
    <div
      ref="editor"
      style="width: 100%"
      :class="{ 'editor--with-border': !noBorder, 'editor--email': forEmail }"
    />
  </div>
</template>

<script>
import { Schema } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import VariableList from './VariableList';

import {
  schema,
  MarkdownParser,
  defaultMarkdownSerializer,
  MarkdownSerializer,
} from 'prosemirror-markdown';

import { EditorState } from 'prosemirror-state';
import { defaultMarkdownParser } from 'prosemirror-markdown';

import { WriterSetup } from './editor/WriterSetup';
import { insertAtCursor, scrollCursorIntoView } from './editor/helper';
import { suggestionsPlugin, triggerCharacters } from './editor/plugins';
import 'prosemirror-view/style/prosemirror.css';

const TYPING_INDICATOR_IDLE_TIME = 4000;

const extendedSchema = new Schema({
  nodes: schema.spec.nodes,
  marks: schema.spec.marks,
});

const createState = (content, placeholder, plugins = [], menuBar) => {
  return EditorState.create({
    doc: new MarkdownParser(extendedSchema, defaultMarkdownParser.tokenizer, {
      ...defaultMarkdownParser.tokens,
    }).parse(content),
    plugins: WriterSetup({
      schema: extendedSchema,
      placeholder,
      plugins,
      menuBar,
    }),
  });
};

export default {
  components: { VariableList },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isMenuBarRequired: {
      type: Boolean,
      default: true,
    },
    minHeight: {
      type: [Number, Object],
      default: null,
    },
    height: {
      type: [Number, Object],
      default: null,
    },
    isFormatMode: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    forEmail: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    enableVariables: {
      type: Boolean,
      default: false,
    },
    enableSuggestions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lastValue: null,
      editorView: null,
      range: null,
      variableSearchTerm: '',
      showVariables: false,
    };
  },
  computed: {
    shouldShowVariables() {
      return this.enableVariables && this.showVariables;
    },
    plugins() {
      if (!this.enableSuggestions) {
        return [];
      }

      return [
        suggestionsPlugin({
          matcher: triggerCharacters('{'),
          suggestionClass: '',
          onEnter: args => {
            this.showVariables = true;
            this.range = args.range;
            this.editorView = args.view;
            return false;
          },
          onChange: args => {
            this.editorView = args.view;
            this.range = args.range;

            this.variableSearchTerm = args.text.replace('{', '');
            return false;
          },
          onExit: () => {
            this.variableSearchTerm = '';
            this.showVariables = false;
            return false;
          },
          onKeyDown: ({ event }) => {
            return event.keyCode === 13 && this.showVariables;
          },
        }),
      ];
    },
  },
  watch: {
    value(newValue = '') {
      if (newValue !== this.lastValue) {
        const { tr } = this.state;
        if (this.isFormatMode) {
          this.state = createState(
            newValue,
            this.placeholder,
            this.plugins,
            this.isMenuBarRequired
          );
        } else {
          tr.insertText(newValue, 0, tr.doc.content.size);
          this.state = this.editorView.state.apply(tr);
        }
        this.editorView.updateState(this.state);
      }
      this.lastValue = newValue;
    },
  },
  created() {
    this.state = createState(
      this.value,
      this.placeholder,
      this.plugins,
      this.isMenuBarRequired
    );
  },
  mounted() {
    this.$nextTick(() => {
      if (this.autoFocus) this.focusEditorInputField();

      document.querySelector(
        '.ProseMirror-woot-style'
      ).style = `min-height: ${this.minHeight}rem;height:${this.height}rem`;
    });

    this.createEditorView();
  },
  methods: {
    createEditorView() {
      this.editorView = new EditorView(this.$refs.editor, {
        state: this.state,
        dispatchTransaction: tx => {
          this.state = this.state.apply(tx);
          this.emitOnChange();
        },
        handleDOMEvents: {
          keyup: () => {
            this.onKeyup();
          },
          focus: () => {
            this.onFocus();
          },
          blur: () => {
            this.onBlur();
          },
        },
      });
    },
    focusEditorInputField() {
      this.$refs.editor.querySelector('div.ProseMirror-woot-style').focus();
    },
    emitOnChange() {
      this.editorView.updateState(this.state);
      this.lastValue = new MarkdownSerializer(
        {
          ...defaultMarkdownSerializer.nodes,
        },
        defaultMarkdownSerializer.marks
      ).serialize(this.state.doc);
      this.$emit('input', this.lastValue);
    },
    resetTyping() {
      this.$emit('typing-off');
      this.idleTimer = null;
    },
    turnOffIdleTimer() {
      if (this.idleTimer) {
        clearTimeout(this.idleTimer);
      }
    },
    insertNodeIntoEditor(node, from = 0, to = 0) {
      this.state = insertAtCursor(this.editorView, node, from, to);
      this.emitOnChange();
      this.$nextTick(() => {
        scrollCursorIntoView(this.editorView);
      });
    },
    insertVariable(variable) {
      if (!this.editorView) {
        return;
      }

      const content = `{{${variable}}}`;

      let node = this.editorView.state.schema.text(content);
      const { from, to } = this.range;
      this.insertNodeIntoEditor(node, from, to);
      this.showVariables = false;
    },
    onKeyup() {
      if (!this.idleTimer) {
        this.$emit('typing-on');
      }
      this.turnOffIdleTimer();
      this.idleTimer = setTimeout(
        () => this.resetTyping(),
        TYPING_INDICATOR_IDLE_TIME
      );
    },
    onBlur() {
      this.turnOffIdleTimer();
      this.resetTyping();
      this.$emit('blur');
    },
    onFocus() {
      this.$emit('focus');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.editor {
  &--with-border {
    border: 1px solid #bfbfbf;
    border-radius: $border-radius-smaller;
  }
}
</style>
