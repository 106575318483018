<template>
  <div class="column">
    <div class="download-card-container">
      <div v-if="isAdmin" class="download-card lime-card">
        <span class="download-header">
          <icons name="download" color="primary evenodd" size="semimedium" />
          <span style="margin-left: 0.8rem;">{{ 'Download Messages' }}</span>
        </span>
        <div class="row">
          <div class="row small-12">
            <div class="columns selector-padding">
              <selector
                :disabled="false"
                :custom-button="true"
                :option-list="typeList('DATE_RANGE')"
                :custom-style="{ top: '4.4rem', left: 'auto' }"
                @click="changeDateMessageRange"
              >
                <button type="button" class="lime-selector-button">
                  <span class="text-truncate">{{
                    selectedType('DATE_RANGE', tf_message)
                  }}</span>
                  <icons name="chevronDown" color="grey" size="semimedium" />
                </button>
              </selector>
            </div>
            <div class="columns selector-padding">
              <selector
                :disabled="false"
                :custom-button="true"
                :option-list="typeList('MESSAGE_TYPE')"
                :custom-style="{ top: '4.4rem', left: 'auto' }"
                @click="changeMessageType"
              >
                <button type="button" class="lime-selector-button">
                  <span class="text-truncate">{{
                    selectedType('MESSAGE_TYPE', message_type)
                  }}</span>
                  <icons name="chevronDown" color="grey" size="semimedium" />
                </button>
              </selector>
            </div>
          </div>
          <div v-if="tf_message === '0'" class="row small-12">
            <div class="columns">
              <input v-model="start_date" type="date" />
            </div>
            <p class="date-picker-separator">
              to
            </p>
            <div class="columns">
              <input v-model="end_date" type="date" />
            </div>
            <div class="cancel-button" @click="closeMessageDate">
              <icons name="cancel" color="stroke-red" size="semimedium" />
            </div>
          </div>
        </div>
        <button
          class="button hollow"
          @click="startDownload(message_type, tf_message)"
        >
          Download
        </button>
      </div>
      <div class="download-card lime-card">
        <span class="download-header">
          <icons name="download" color="primary evenodd" size="semimedium" />
          <span style="margin-left: 0.8rem;">{{ 'Download Reports' }}</span>
        </span>
        <div class="row">
          <div class="column selector-padding">
            <selector
              :disabled="false"
              :custom-button="true"
              :option-list="typeList('DATE_RANGE')"
              :custom-style="{ top: '4.4rem', width: 'auto', left: 'auto' }"
              @click="changeDateReportRange"
            >
              <button type="button" class="lime-selector-button">
                <span class="text-truncate">{{
                  selectedType('DATE_RANGE', tf_report)
                }}</span>
                <icons name="chevronDown" color="grey" size="semimedium" />
              </button>
            </selector>
          </div>
          <div class="columns selector-padding">
            <selector
              :disabled="false"
              :custom-button="true"
              :option-list="typeList('CONVERSATION_REPORT_TYPE')"
              :custom-style="{ top: '4.4rem', width: 'auto', left: 'auto' }"
              @click="changeReportType"
            >
              <button type="button" class="lime-selector-button">
                <span class="text-truncate">{{
                  selectedType('CONVERSATION_REPORT_TYPE', report_type)
                }}</span>
                <icons name="chevronDown" color="grey" size="semimedium" />
              </button>
            </selector>
          </div>
          <div class="row small-12 selector-padding">
            <selector
              :disabled="false"
              :custom-button="true"
              :option-list="typeList('REPORT_FORMAT')"
              :custom-style="{ top: '4.4rem', width: 'auto', left: 'auto' }"
              @click="changeReportFormat"
            >
              <button type="button" class="lime-selector-button">
                <span class="text-truncate">{{
                  selectedType('REPORT_FORMAT', report_format)
                }}</span>
                <icons name="chevronDown" color="grey" size="semimedium" />
              </button>
            </selector>
          </div>
          <div v-if="tf_report === '0'" class="row small-12">
            <div class="columns">
              <input v-model="start_date" type="date" />
            </div>
            <p class="date-picker-separator">
              to
            </p>
            <div class="columns">
              <input v-model="end_date" type="date" />
            </div>
            <div class="cancel-button" @click="closeReportDate">
              <icons name="cancel" color="stroke-red" size="semimedium" />
            </div>
          </div>
        </div>

        <button
          class="button hollow"
          @click="startDownload(report_type, tf_report)"
        >
          Download
        </button>
      </div>
      <div v-if="isAdmin" class="download-card lime-card">
        <span class="download-header">
          <icons name="download" color="primary evenodd" size="semimedium" />
          <span style="margin-left: 0.8rem;">{{ 'Download Contact' }}</span>
        </span>

        <button class="button hollow" @click="startDownload('contact')">
          Download
        </button>
      </div>
    </div>
    <woot-modal :show.sync="downloading" :on-close="hidePopup">
      <div class="download-note">
        <img
          src="https://ik.imagekit.io/limechatai/Ellipsis-1s-200px_ktbBz0-IT.gif"
        />
        <div>Your download is in progress.</div>
      </div>
    </woot-modal>
    <woot-modal :show.sync="downloadError" :on-close="hidePopup">
      <div class="download-note">
        <div>Sorry for inconvenience some error occured.</div>
      </div>
    </woot-modal>
  </div>
</template>

<script type="text/javascript">
import isAdmin from 'dashboard/mixins/isAdmin';
import timeMixin from 'dashboard/mixins/time';
import downloadMixin from 'dashboard/mixins/download';

export default {
  mixins: [isAdmin, timeMixin, downloadMixin],
  data() {
    return {
      downloading: false,
      downloadError: false,
      tf_message: '1',
      tf_report: '1',
      timeframe: '0',
      timeframe_end: '-1',
      ptype: '',
      report_type: 'download_reports',
      message_type: 'message',
      report_format: 'pdf',
      start_date: this.formatDate(
        new Date().getTime() - 7 * 24 * 60 * 60 * 1000
      ),
      end_date: this.formatDate(new Date().getTime() + 24 * 60 * 60 * 1000),
    };
  },
  methods: {
    typeList(type) {
      return this.$t(`DOWNLOAD.${type}`).map(item => {
        return {
          key: item.KEY,
          name: item.NAME,
        };
      });
    },
    selectedType(type, value) {
      let typeArray = this.typeList(type);
      let selectedIndex = typeArray.findIndex(i => i.key === value);

      return this.$t(`DOWNLOAD.${type}`)[selectedIndex].NAME;
    },
    changeDateMessageRange(val) {
      this.tf_message = val.key;
    },
    changeMessageType(val) {
      this.message_type = val.key;
    },
    changeDateReportRange(val) {
      this.tf_report = val.key;
    },
    changeReportType(val) {
      this.report_type = val.key;
    },
    changeReportFormat(val) {
      this.report_format = val.key;
    },
    closeMessageDate() {
      this.tf_message = '1';
    },
    closeReportDate() {
      this.tf_report = '1';
    },
    hidePopup() {
      this.downloading = false;
      this.downloadError = false;
    },
    startDownload(type = '', tf = '') {
      if (type !== '') {
        this.ptype = type;
      }
      if (this.ptype === 'download_tracker_data') {
        this.report_format = 'excel';
      }
      if (tf !== '') {
        this.timeframe = tf;
        if (tf === '1') {
          this.timeframe_end = '0';
        } else {
          this.timeframe_end = '-1';
        }
      } else {
        this.timeframe = '0';
        this.timeframe_end = '-1';
      }
      this.download();
    },
    async download() {
      let t = this.timeframe;
      let t_e = this.timeframe_end;
      let d = new Date();

      let start_date = new Date(d.getTime() - t * 24 * 60 * 60 * 1000);
      start_date =
        start_date.getFullYear() +
        '/' +
        (parseInt(start_date.getMonth(), 10) + parseInt(1, 10)) +
        '/' +
        start_date.getDate();

      let end_date = new Date(d.getTime() - t_e * 24 * 60 * 60 * 1000);
      end_date =
        end_date.getFullYear() +
        '/' +
        (parseInt(end_date.getMonth(), 10) + parseInt(1, 10)) +
        '/' +
        end_date.getDate();

      if (t_e === '-1') {
        end_date = '';
      }

      start_date = this.start_date.replaceAll('-', '/');
      end_date = this.end_date.replaceAll('-', '/');

      this.downloading = true;
      await this.$store
        .dispatch('download', {
          type: this.ptype,
          timeframe: start_date,
          timeframe_end: end_date,
          report_format: this.report_format,
        })
        .then(data => {
          let report_mime_type = '';
          let file_extension = '';

          if (this.report_format === 'pdf') {
            report_mime_type = 'application/pdf';
            file_extension = '.pdf';
          } else if (this.report_format === 'excel') {
            report_mime_type =
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            file_extension = '.xlsx';
          }
          if (
            [
              'download_reports',
              'download_agent_reports',
              'download_bot_reports',
              'download_tracker_data',
            ].includes(this.ptype)
          ) {
            this.processFile(
              data,
              this.ptype + file_extension,
              report_mime_type
            );
          } else if (['download_sessionflow_reports'].includes(this.ptype)) {
            this.processFile(data, this.ptype + '.txt', 'application/txt');
          } else {
            this.processFile(
              data,
              this.ptype + '.csv',
              'text/csv;charset=utf-8;'
            );
          }
          this.downloading = false;
        })
        .catch(() => {
          this.downloading = false;
          this.downloadError = true;
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.selector-padding {
  margin-right: $space-slab;
}
.download-note {
  width: 100%;
  min-height: 30vh;
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div {
    font-size: $font-size-big;
  }
}
.download-card-container {
  display: flex;
  width: 100%;
  flex-direction: column;

  .download-card {
    @include elevation-2dp;

    margin-bottom: $space-slab;
    width: 100%;

    .download-header {
      display: flex;
      align-items: center;
      margin-bottom: $space-normal;
      font-size: $font-size-default;
      line-height: $space-medium;
    }
  }
}
.hollow {
  float: right;
  width: auto;
  padding: $space-small $space-slab;
}
.cancel-button {
  padding: $space-small;
  margin-bottom: $space-normal;
  cursor: pointer;
}
</style>
