import Vue from 'vue';
import types from '../../mutation-types';

export const mutations = {
  [types.SET_CONTACT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.CLEAR_CONTACTS]: $state => {
    Vue.set($state, 'records', {});
  },

  [types.CLEAR_SEARCHED_CONTACTS]: $state => {
    Vue.set($state, 'searchedContacts', {});
  },

  [types.SET_CONTACT_META]: ($state, data) => {
    Vue.set($state.meta, 'count', data?.count || 0);
    Vue.set($state.meta, 'currentPage', data?.currentPage || 1);
  },

  [types.SET_CONTACTS]: ($state, data) => {
    if (data && data[0]?.id) {
      data.forEach(contact => {
        Vue.set($state.records, contact.id, {
          ...($state.records[contact.id] || {}),
          ...contact,
        });
      });
    } else Vue.set($state.records, 'data', data);
  },

  [types.SET_SEARCHED_CONTACTS]: ($state, data) => {
    Vue.set($state, 'searchedContacts', data);
  },

  [types.SET_OPT_OUT_CONTACTS]: ($state, data) => {
    Vue.set($state, 'opt_outs', data);
  },
  [types.SET_CONTACT_ITEM]: ($state, data) => {
    if (!$state.uiFlags.isSearching) {
      Vue.set($state.records, data.id, {
        ...($state.records[data.id] || {}),
        ...data,
      });
    }
  },

  [types.EDIT_CONTACT]: ($state, data) => {
    Vue.set($state.records, data.id, data);
  },

  [types.UPDATE_CONTACTS_PRESENCE]: ($state, data) => {
    Object.values($state.records).forEach(element => {
      const availabilityStatus = data[element.id];
      if (availabilityStatus) {
        Vue.set(
          $state.records[element.id],
          'availability_status',
          availabilityStatus
        );
      }
      // else {
      //   Vue.delete($state.records[element.id], 'availability_status');
      // }
    });
  },
};
