<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box">
      <woot-modal-header :header-title="`Edit ticket`" />

      <form class="row settings-form" @submit.prevent="onSubmit">
        <woot-input
          v-model.trim="subject"
          :class="{ error: $v.subject.$error }"
          class="medium-12 columns content-margin"
          label="Subject"
          placeholder="Subject"
          @input="$v.subject.$touch"
        />

        <woot-input
          v-model.trim="orderId"
          :class="{ error: $v.orderId.$error }"
          class="medium-12 columns content-margin"
          label="Order ID"
          placeholder="Order ID"
          @input="$v.orderId.$touch"
        />
        <div
          v-if="selectedCrm === AVAILABLE_CRMS.ZOHO"
          class="small-12 edit-contact--form"
        >
          <div class="form-label">
            <div class="mg-left--micro mg-bottom--small">
              Department
            </div>
            <woot-single-selector
              :default-option="selectedDepartment"
              :option-list="departmentOptionList"
              :custom-style="{
                width: '100%',
                'margin-bottom': 'var(--$space-slab)',
              }"
              @click="changeDepartment"
            />
          </div>
        </div>

        <woot-input
          v-model.trim="description"
          :class="{ error: $v.description.$error }"
          class="medium-12 columns content-margin"
          label="Description"
          placeholder="Description"
          @input="$v.description.$touch"
        />

        <label
          v-if="selectedCrm === AVAILABLE_CRMS.FRESHDESK"
          class="form-label"
        >
          Status
          <select v-model.trim="status_updated" class="form-input">
            <option v-for="num in status_list" :key="num" :value="num">{{
              statusMap[num]
            }}</option>
          </select>
        </label>

        <div v-if="selectedCrm === AVAILABLE_CRMS.ODOO" class="ticket-status">
          <b>Status: </b>{{ ticket.status }}
        </div>

        <div v-if="selectedCrm === AVAILABLE_CRMS.KAPTURE" class="ticket-link">
          <b>Ticket link: </b>
          <a
            :href="ticket.url"
            target="_blank"
            v-text="
              $t(
                'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.KAPTURE.VIEW_LINK_BUTTON_TEXT'
              )
            "
          />
        </div>

        <div class="modal-footer flex-row gap--small">
          <woot-base-button
            v-if="selectedCrm === AVAILABLE_CRMS.FRESHDESK"
            variant="secondary-danger"
            front-icon="delete"
            @click="onDelete"
          >
            Delete
          </woot-base-button>
          <woot-base-button type="submit">
            Update
          </woot-base-button>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import alertMixin from 'shared/mixins/alertMixin';

import { AVAILABLE_CRMS } from '../../../constants';

export default {
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    ticket: {
      type: Object,
      default: () => ({}),
    },
    selectedCrm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      subject: '',
      orderId: '',
      description: '',
      newTicket: {},
      statusMap: {},
      status_updated: 2,
      status_list: [],
      selectedDepartment: '',
      AVAILABLE_CRMS,
    };
  },
  validations: {
    subject: {
      required,
    },
    orderId: {},
    description: {
      required,
    },
    status_updated: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'crm/getUIFlags',
      departmentOptionList: 'crm/getDepartmentNames',
    }),
  },
  mounted() {
    this.setTicketObject();
    this.setStatusMap();
    this.getDepartmentNames();
  },
  methods: {
    getDepartmentNames() {
      if (
        this.selectedCrm ===
        this.$t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.ZOHO.ID')
      )
        this.$store.dispatch('crm/fetchDepartmentNames', {
          selectedCrm: this.selectedCrm,
        });
    },
    onCancel() {
      this.$emit('cancel');
    },
    async onDelete() {
      try {
        await this.$store.dispatch('crm/delete', {
          id: this.ticket.id,
          selectedCrm: this.selectedCrm,
        });
        this.showAlert('Successfully deleted ticket!', 'success');
      } catch (error) {
        this.showAlert('Could not delete ticket.', 'error');
      }
      this.onCancel();
    },
    setTicketObject() {
      this.subject = this.ticket.subject;
      this.orderId = this.ticket.orderId;
      this.selectedDepartment = this.ticket.selectedDepartment;
      this.selectedDepartmentId = this.ticket.selectedDepartmentId;
      this.description = this.ticket.description;
    },
    setStatusMap() {
      this.status_list = [2, 3, 4, 5, 6, 7];
      this.status_updated = this.ticket.status_crm
        ? this.ticket.status_crm
        : this.ticket.status;
      this.statusMap = {
        2: 'Open',
        3: 'Pending',
        4: 'Resolved',
        5: 'Closed',
        6: 'Waiting on Customer',
        7: 'Waiting on Third Party',
      };
    },
    getTicketObject() {
      return {
        ...this.ticket,
        id: this.ticket.id,
        subject: this.subject,
        orderId: this.orderId,
        status_updated: this.status_updated,
        selectedCrm: this.selectedCrm,
        description: this.description,
        department_id: this.selectedDepartmentId,
        department: this.selectedDepartment,
      };
    },
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        await this.$store.dispatch('crm/update', this.getTicketObject());
        this.showAlert('Successfully updated ticket!', 'success');
      } catch (error) {
        this.showAlert('Could not update ticket.', 'error');
      }
      this.onCancel();
    },
    changeDepartment(val) {
      this.selectedDepartment = val.name;
      this.selectedDepartmentId = val.id;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-form {
  padding: $space-slab $space-medium;
}
.content-margin {
  margin-bottom: 2.4rem;
}
.form-label {
  padding: $zero;
  color: $neutral-grey-800;
  font-weight: $font-weight-bold;
  font-size: $font-size-small;
  margin: $zero $zero $space-medium;
  line-height: 22px;
}

.ticket-status {
  margin: auto;
}
</style>
