<template>
  <div class="column content-box settings-box no-padding">
    <div class="settings-button flex-row flex-align gap--normal">
      <reference-box
        :to="documentation.canned"
        content="Know More"
        type="document"
      />
      <woot-base-button
        size="medium"
        front-icon="plus"
        icon-view-box="0 0 20 20"
        @click="openAddPopup"
      >
        {{ $t('CANNED_MGMT.HEADER_BTN_TXT') }}
      </woot-base-button>
    </div>

    <div class="settings-container">
      <div class="column settings-main-menu-new">
        <div class="table-container">
          <data-table
            :columns="tableColumns"
            :table-data="cannedList"
            :actions="actionsList"
            :loading="uiFlags.fetchingList"
            :enable-animations="cannedList.length <= 10"
            _class="scrollable-table custom-scroll"
            @select="onSelectCanned"
            @delete="openDeletePopup"
          >
            <template v-slot:table-features>
              <!-- search bar feature -->
              <search-box
                :placeholder="$t('CANNED_MGMT.SEARCH_TXT')"
                :value="search"
                custom-class="search-box"
                @setSearch="setSearch"
              />
            </template>

            <template v-slot:zero-state>
              <table-zero-state
                v-if="!cannedList.length"
                title="No Canned responses added till now"
                subtitle="Click on the Add Canned Response Button"
                asset-link="/brand-assets/agent_zero_state.svg"
              >
              </table-zero-state>
            </template>
          </data-table>
        </div>
      </div>

      <settings-side-card>
        <div class="flex-column full-height">
          <div
            v-if="selectedResponse.id"
            class="canned-rhs-panel custom-scroll"
          >
            <add-edit-canned
              :key="selectedResponse.id"
              :data="selectedResponse"
            />
          </div>
          <educational-onboarding v-else :show-case-data="SHOWCASE_CANNED_DATA">
            <div class="mg-top--slab mg-left mg-right">
              <span
                class="title-h5 text-dark"
                v-text="$t('CANNED_MGMT.EDUCATIONAL_ONBOARDING.HOW_TO.TITLE')"
              />
              <div
                class="body-b3 text-light"
                v-text="$t('CANNED_MGMT.EDUCATIONAL_ONBOARDING.HOW_TO.DESC')"
              />
            </div>
          </educational-onboarding>
          <div class="action-btns full-width flex-row flex-justify gap--one">
            <woot-base-button
              v-if="cannedList.length"
              variant="secondary"
              full-width
              @click="showExportCannedModal = true"
            >
              Export
            </woot-base-button>
            <woot-base-button
              variant="secondary"
              full-width
              @click="showImportCannedModal = true"
            >
              {{ $t('CANNED_MGMT.IMPORT_CSV') }}
            </woot-base-button>
          </div>
        </div>
      </settings-side-card>
    </div>

    <!-- import csv -->
    <import-canned
      v-if="showImportCannedModal"
      @onClose="showImportCannedModal = false"
    />

    <!-- export csv -->
    <export-canned
      v-if="showExportCannedModal"
      @onClose="showExportCannedModal = false"
    />

    <!-- Add canned -->
    <woot-modal-new :show.sync="showAddPopup" :on-close="hideAddPopup">
      <woot-modal-header-new :header-title="$t('CANNED_MGMT.ADD.TITLE')" />
      <add-edit-canned
        :on-close="hideAddPopup"
        class="settings-form"
        show-close
      />
    </woot-modal-new>

    <!-- delete canned response -->
    <woot-delete-modal-new
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      show-close
      :title="$t('CANNED_MGMT.DELETE.CONFIRM.TITLE')"
      :message="deleteMessage"
      confirm-text="Continue"
      reject-text="Back"
      :custom-style="{
        'max-width': '35.2rem',
        height: 'auto',
      }"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DataTable from 'dashboard/components/widgets/table/DataTable';
import AddEditCanned from './AddEditCanned';
import ImportCanned from './ImportCanned';
import ExportCanned from './ExportCanned';
import SearchBox from '../SearchBox';
import SettingsSideCard from '../SettingSideCard';
import TableZeroState from 'dashboard/components/ui/settings/TableZeroState';
import EducationalOnboarding from 'dashboard/components/ui/settings/EducationalOnboarding';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';

import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import * as types from 'shared/constants/googleEventType';
import { SHOWCASE_CANNED_DATA } from 'dashboard/routes/dashboard/settings/canned/constants';
import documentation from 'dashboard/helper/data/documentationData.json';

export default {
  name: 'CannedHome',
  components: {
    SearchBox,
    DataTable,
    TableZeroState,
    EducationalOnboarding,
    AddEditCanned,
    ImportCanned,
    ExportCanned,
    SettingsSideCard,
    ReferenceBox,
  },
  mixins: [googleAnalyticsMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      search: '',
      showAddPopup: false,
      showDeleteConfirmationPopup: false,
      showImportCannedModal: false,
      showExportCannedModal: false,
      isError: false,
      showActive: null,
      cannedResponseAPI: {
        message: '',
      },
      loading: {},
      selectedResponse: {},
      tableColumns: [
        {
          label: 'Response',
          prop: 'short_code',
          component: 'CannedResponseDetail',
          sortable: true,
        },
      ],
      actionsList: [{ id: 0, name: 'delete', icon: 'delete' }],
      SHOWCASE_CANNED_DATA,
      documentation,
    };
  },
  computed: {
    ...mapGetters({
      records: 'getCannedResponses',
      uiFlags: 'getUIFlags',
    }),
    cannedList() {
      let cannedList = [];

      cannedList = this.records.filter(item => {
        return (
          item.short_code.toLowerCase().search(this.search.toLowerCase()) >
            -1 ||
          item.content.toLowerCase().search(this.search.toLowerCase()) > -1
        );
      });

      return cannedList;
    },
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedResponse.short_code
      }`;
    },
    deleteRejectText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedResponse.short_code
      }`;
    },
    deleteMessage() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.selectedResponse.short_code
      } ?`;
    },
  },
  created() {
    this.$store.dispatch('getCannedResponse');
  },
  methods: {
    setSearch(value) {
      this.googleAnalyticsEvent(
        types.default.SEARCH_CANNED_RESPONSE,
        types.default.CANNED_RESPONSE_SETTINGS,
        value
      );
      this.search = value;
      this.mix_panel_searched_settings_canned_response();
    },
    showAlert(message, variant) {
      // Reset loading, current selected agent
      this.loading[this.selectedResponse.id] = false;
      this.selectedResponse = {};
      // Show message
      this.cannedResponseAPI.message = message;
      bus.$emit('newToastMessage', [message, variant]);
    },
    // Edit Function
    openAddPopup() {
      this.googleAnalyticsEvent(
        types.default.OPEN_ADD_POP_UP_CANNED_RESPONSE,
        types.default.CANNED_RESPONSE_SETTINGS,
        types.default.CANNED_RESPONSE_SETTINGS
      );
      this.showAddPopup = true;
      this.mix_panel_clicked_settings_add_canned_response();
    },
    hideAddPopup() {
      this.googleAnalyticsEvent(
        types.default.HIDE_ADD_POP_UP_CANNED_RESPONSE,
        types.default.CANNED_RESPONSE_SETTINGS,
        types.default.CANNED_RESPONSE_SETTINGS
      );
      this.showAddPopup = false;
    },
    onSelectCanned(response) {
      this.selectedResponse = response;
    },
    // Delete Modal Functions
    openDeletePopup() {
      this.googleAnalyticsEvent(
        types.default.OPEN_DELETE_POP_UP_CANNED_RESPONSE,
        types.default.CANNED_RESPONSE_SETTINGS,
        types.default.CANNED_RESPONSE_SETTINGS
      );
      this.showDeleteConfirmationPopup = true;
    },
    closeDeletePopup() {
      this.googleAnalyticsEvent(
        types.default.HIDE_DELETE_POP_UP_CANNED_RESPONSE,
        types.default.CANNED_RESPONSE_SETTINGS,
        types.default.CANNED_RESPONSE_SETTINGS
      );
      this.showDeleteConfirmationPopup = false;
    },
    // Set loading and call Delete API
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteCannedResponse(this.selectedResponse.id);
    },
    deleteCannedResponse(id) {
      this.googleAnalyticsEvent(
        types.default.DELETE_CANNED_RESPONSE,
        types.default.CANNED_RESPONSE_SETTINGS,
        types.default.CANNED_RESPONSE_SETTINGS
      );
      this.$store
        .dispatch('deleteCannedResponse', id)
        .then(() => {
          this.showAlert(
            this.$t('CANNED_MGMT.DELETE.API.SUCCESS_MESSAGE'),
            'success'
          );
          this.mix_panel_deleted_settings_canned_response();
        })
        .catch(() => {
          this.showAlert(
            this.$t('CANNED_MGMT.DELETE.API.ERROR_MESSAGE'),
            'error'
          );
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/widgets/buttons';

.canned-rhs-panel {
  height: calc(100% - 8rem);
  display: flex;
  padding: $space-two $space-two $zero;

  form {
    width: 100%;
  }
}

.action-btns {
  background: $neutral-white;
  bottom: 0;
  border-top: 2px solid $neutral-grey-200;
  padding: $space-two;
  position: absolute;
}

.no-padding {
  padding-left: 0.1rem;
}

.table-container {
  height: 100%;
}

.settings-side-card {
  padding: $zero;
}

.settings-box {
  padding-top: $space-largest;
  overflow: hidden;

  .settings-container {
    display: flex;
    height: 100%;
    flex-direction: row;

    .search-box {
      background: none;
      margin-bottom: $zero;
      width: 40rem;
    }
  }
}

.settings-form {
  padding: $space-two $space-jumbo + $space-two $zero;
}
</style>
