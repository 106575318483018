<template>
  <div class="filters">
    <div class="filter">
      <div class="filter-inputs">
        <woot-single-selector
          :default-option="attributeKey"
          :option-list="filterAttributes"
          :custom-style="selectorCustomStyle"
          :dropdown-container-style="attributeDropdownStyle"
          :class="`AUTOMATION_CONDITION_ATTRIBUTE_KEY_${index}`"
          @click="handleAttributeChange"
        />
        <!-- operator -->
        <woot-single-selector
          v-if="operators.length !== 0"
          :default-option="filterOperator"
          :option-list="operators"
          :custom-style="selectorCustomStyle"
          :dropdown-container-style="operatorDropdownStyle"
          @click="handleOperatorChange"
        />
        <!-- operand -->
        <div
          v-if="showUserInput"
          class="filter__answer--wrap"
          :class="`AUTOMATION_CONDITION_VALUES_${index}`"
        >
          <div
            v-if="inputType === 'multi_select'"
            class="multiselect-wrap--small"
          >
            <multiselect
              v-model="values"
              track-by="id"
              :label="labelKey"
              placeholder="Select"
              multiple
              selected-label
              deselect-label=""
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :max-height="160"
              :options="dropdownValues"
              allow-empty
            />
          </div>
          <div
            v-else-if="inputType === 'search_select'"
            class="multiselect-wrap--small"
          >
            <multiselect
              v-model="values"
              track-by="id"
              label="name"
              placeholder="Select"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              deselect-label=""
              :max-height="160"
              :options="dropdownValues"
              allow-empty
              :option-height="104"
            />
          </div>
          <div v-else-if="inputType === 'date'" class="multiselect-wrap--small">
            <input
              v-model="values"
              type="date"
              :editable="false"
              class="answer--text-input datepicker"
            />
          </div>
          <div v-else-if="inputType === 'array[text]'">
            <keyword-input
              :keywords="values"
              size="small"
              input-placeholder="Type a keyword and press enter"
              @updateKeywords="handleValueChange"
            />
          </div>
          <woot-input
            v-else
            v-model="values"
            class="answer--text-input"
            placeholder="Enter value"
          />
        </div>
        <woot-base-button
          v-if="!isFirstCondition"
          tag="span"
          variant="tertiary-danger"
          @click="removeFilter"
        >
          <icons name="bin" color="red" size="semimedium" :show-title="false" />
        </woot-base-button>
      </div>
    </div>
    <div v-if="showQueryOperator" class="filter__join-operator">
      <hr class="operator__line" />
      <woot-single-selector
        :default-option="queryOperator"
        :option-list="queryOperatorOptions"
        :custom-style="selectorCustomStyle"
        :dropdown-container-style="queryOperatorDropdownStyle"
        @click="handleQueryOperatorChange"
      />
    </div>
  </div>
</template>

<script>
import KeywordInput from 'dashboard/components/KeywordInput';

export default {
  components: { KeywordInput },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    value: {
      type: Object,
      default: () => null,
    },
    filterAttributes: {
      type: Array,
      default: () => [],
    },
    inputType: {
      type: String,
      default: 'plain_text',
    },
    labelKey: {
      type: String,
      default: 'name',
    },
    operators: {
      type: Array,
      default: () => [],
    },
    dropdownValues: {
      type: Array,
      default: () => [],
    },
    showQueryOperator: {
      type: Boolean,
      default: false,
    },
    showUserInput: {
      type: Boolean,
      default: true,
    },
    groupedFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      queryOperatorOptions: [
        {
          id: 1,
          name: this.$t('FILTER.QUERY_DROPDOWN_LABELS.AND'),
          value: 'and',
        },
        {
          id: 2,
          name: this.$t('FILTER.QUERY_DROPDOWN_LABELS.OR'),
          value: 'or',
        },
      ],
    };
  },
  computed: {
    isFirstCondition() {
      return this.index === 0;
    },
    attributeKey() {
      return this.value.attribute_key
        ? this.filterAttributes.find(
            attr => attr.key === this.value.attribute_key
          ).name
        : 'Select an attribute';
    },
    filterOperator() {
      return this.value.filter_operator
        ? this.operators.find(
            operator => operator.value === this.value.filter_operator
          ).label
        : 'equal to';
    },
    values: {
      get() {
        return this.inputType === 'array[text]'
          ? this.value.values || []
          : this.value.values;
      },
      set(value) {
        this.handleValueChange(value);
      },
    },
    queryOperator() {
      return this.value ? this.value.query_operator : 'and';
    },
    selectorCustomStyle() {
      return { left: 0, top: 0, width: '100%', maxHeight: '22rem' };
    },
    attributeDropdownStyle() {
      return { height: '3.6rem', width: '22rem' };
    },
    operatorDropdownStyle() {
      return { height: '3.6rem', width: '16rem' };
    },
    queryOperatorDropdownStyle() {
      return { height: '3.6rem', background: '#fff' };
    },
  },
  methods: {
    removeFilter() {
      this.$emit('removeFilter');
    },
    handleValueChange(value) {
      const payload = this.value || {};
      this.$emit('input', { ...payload, values: value });
    },
    handleAttributeChange(value) {
      this.$emit('input', { ...this.value, attribute_key: value.id });
      this.$nextTick(() => this.$emit('resetFilter'));
    },
    handleOperatorChange(value) {
      this.$emit('input', { ...this.value, filter_operator: value.value });
    },
    handleQueryOperatorChange({ value }) {
      this.$emit('input', { ...this.value, query_operator: value });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.filter.error {
  background: $chat-private-background;
  border: 1px solid $warn-yellow-500;
}

.filter-error {
  color: $alert-color;
  display: block;
  margin: $space-smaller $zero;
}

.filter__question,
.filter__operator {
  margin-bottom: $zero;
  margin-right: $space-smaller;
}

.filter__question {
  max-width: 30%;
}

.filter__operator {
  max-width: 20%;
}

.filter__answer {
  &.answer--text-input {
    margin-bottom: $zero;
  }
}

.filter__join-operator-wrap {
  position: relative;
  z-index: -20;
  margin: $zero;
}

.filter__join-operator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: $space-normal $zero;

  .operator__line {
    position: absolute;
    width: 100%;
    border-bottom: 1px solid $neutral-grey-300;
  }

  .operator__select {
    position: relative;
    width: auto;
    margin-bottom: $zero !important;
  }
}

.multiselect {
  margin-bottom: $zero;
  min-height: $zero;
}
</style>
