<template>
  <component :is="renderOrderComponent" />
</template>
<script>
import { mapGetters } from 'vuex';

import OrderPanelNonDeepShopify from './nonDeepShopify/OrderPanel';
import OrderPanelDeepShopify from './deepShopify/OrderPanel';

export default {
  components: { OrderPanelNonDeepShopify, OrderPanelDeepShopify },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      isDeepShopifyEnabled: 'accounts/getDeepShopifyEnabledFlag',
    }),
    renderOrderComponent() {
      if (!this.isDeepShopifyEnabled) return 'OrderPanelNonDeepShopify';
      return 'OrderPanelDeepShopify';
    },
  },
};
</script>
