<template>
  <div class="settings-header lime-card-1dp" :style="customStyle">
    <div class="page-title-container">
      <back-button v-if="showBackButton" :back-url="backUrl"></back-button>
      <icons
        v-if="icon"
        :name="icon"
        size="mediumlarge"
        color="grey evenodd"
        :show-title="false"
      />
      <span class="page-title">{{ headerTitle }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BackButton from '../../../components/widgets/BackButton';
import adminMixin from '../../../mixins/isAdmin';

export default {
  components: {
    BackButton,
  },
  mixins: [adminMixin],
  props: {
    headerTitle: {
      default: '',
      type: String,
    },
    icon: {
      default: '',
      type: String,
    },
    showBackButton: { type: Boolean, default: false },
    backUrl: {
      type: [String, Object],
      default: '',
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
  },
};
</script>
