import { required, email, decimal } from 'vuelidate/lib/validators';
import { isValidPhoneNumber } from 'libphonenumber-js';

const checkValidName = value => /^[a-zA-Z ]+$/.test(value);

const isPostiveNumber = value => {
  return value >= 0;
};

const checkValidPhoneNumber = value => {
  if (!value) {
    return true;
  }
  return isValidPhoneNumber('+' + value);
};

const validLastName = value => {
  if (value === '') return true;
  return checkValidName(value);
};

export default {
  firstName: { required, checkValidName },
  lastName: { validLastName },
  addressLine1: { required },
  city: { required, checkValidName },
  state: { required, checkValidName },
  country: { required, checkValidName },
  phoneNumber: { checkValidPhoneNumber },
  email: { email },
  pinCode: { required },
  shippingCharge: { decimal, isPostiveNumber },
  discount: { decimal, isPostiveNumber },
};
