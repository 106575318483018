<template>
  <div class="column view-box">
    <settings-header
      icon="graph"
      :header-title="$t('ANALYTICS.HEADER.SHOPIFY_ANALYTICS')"
      :show-back-button="false"
    />
    <div class="column content-box settings-box">
      <div class="settings-container columns custom-scroll">
        <!-- Top Filters -->
        <div class="flex-space-between">
          <div class="flex-row gap--normal">
            <date-range-selector
              :default-selection="0"
              @date-range-change="onDateRangeChange"
            />
            <inbox-filter
              :value="selectedInbox"
              :exclude-inbox-types="excludedInboxTypes"
              :default-option="defaultInbox"
              @click="changeInboxId"
            />
            <woot-single-selector
              variant="secondary"
              :option-list="agentList"
              :default-option="selectedAgent"
              :custom-style="{ width: 'auto' }"
              :show-selected-text="false"
              @click="changeAgentId"
            />
          </div>
          <!-- Download Report -->
          <woot-base-button front-icon="email" @click="downloadReport">
            Report
          </woot-base-button>
        </div>
        <metrics />
        <sales-data />
        <products-table :applied-filters="appliedFilters" />
        <orders-table
          :applied-filters="appliedFilters"
          @status-change="onStatusChange"
        />
      </div>
    </div>
    <woot-modal :show.sync="downloading" :on-close="hidePopup">
      <div class="download-note">
        <img
          src="https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
        />
        <div
          v-html="$t('ANALYTICS.DOWNLOAD_REPORT.EMAIL', { email: userEmail })"
        />
      </div>
    </woot-modal>
    <woot-modal :show.sync="downloadError" :on-close="hidePopup">
      <div class="download-note">
        <div v-text="$t('ANALYTICS.DOWNLOAD_REPORT.REPORT_FAIL')" />
      </div>
    </woot-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import ProductsTable from './ProductsTable';
import Metrics from './Metrics';
import SalesData from './SalesData';
import OrdersTable from './OrdersTable';
import SettingsHeader from 'dashboard/routes/dashboard/settings/SettingsHeader';
import DateRangeSelector from 'dashboard/routes/dashboard/analytics/components/DateRangeSelector';
import InboxFilter from 'dashboard/components/widgets/conversation/filter/InboxFilter';

import { getUserEmail } from 'dashboard/helper/cookieHelper';
import timeMixin from 'dashboard/mixins/time';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

export default {
  components: {
    ProductsTable,
    Metrics,
    SalesData,
    OrdersTable,
    SettingsHeader,
    DateRangeSelector,
    InboxFilter,
  },
  mixins: [timeMixin],
  data() {
    return {
      selectedInbox: {
        id: null,
        name: this.$t('ANALYTICS.FILTER_SELECTION.INBOX.PLACEHOLDER'),
      },
      selectedAgentId: undefined,
      selectedStatusId: undefined,
      to: 0,
      from: 0,
      downloading: false,
      downloadError: false,
      appliedFilters: {
        startDate: this.formatDate(this.from * 1000).replaceAll('-', '/'),
        endDate: this.formatDate(this.to * 1000).replaceAll('-', '/'),
        inboxId: null,
        agentId: this.selectedAgentId,
      },
    };
  },
  computed: {
    ...mapGetters({
      agents: 'agents/getAgents',
    }),
    defaultInbox() {
      return {
        name: this.$t('ANALYTICS.FILTER_SELECTION.INBOX.PLACEHOLDER'),
        id: null,
      };
    },
    excludedInboxTypes() {
      return [INBOX_TYPES.KNOWLARITY];
    },
    agentList() {
      let agentArray = [
        {
          name: this.$t('ANALYTICS.FILTER_SELECTION.AGENT.PLACEHOLDER'),
          id: null,
        },
        ...this.agents,
      ];
      return agentArray.map(agent => ({
        id: agent.id,
        name: agent.name,
      }));
    },
    selectedAgent() {
      let selectedIndex = this.agentList.findIndex(
        a => a.id === this.selectedAgentId
      );

      return selectedIndex > 0
        ? this.agentList[selectedIndex].name
        : this.agentList[0].name;
    },
    userEmail() {
      return getUserEmail();
    },
  },
  created() {
    this.$store.dispatch('agents/get');
  },
  methods: {
    onStatusChange(newStatusId) {
      this.selectedStatusId = newStatusId;
      this.appliedFilters = { ...this.appliedFilters, status: newStatusId };
    },
    onDateRangeChange({ from, to }) {
      this.from = from;
      this.to = to;
      this.getData();
    },
    changeInboxId({ id, name }) {
      this.selectedInbox.id = id;
      this.selectedInbox.name = name;
      this.getData();
    },
    changeAgentId({ id }) {
      this.selectedAgentId = id;
      this.getData();
    },
    getData() {
      const params = {
        startDate: this.formatDate(this.from * 1000).replaceAll('-', '/'),
        endDate: this.formatDate(this.to * 1000).replaceAll('-', '/'),
        inboxId: this.selectedInbox.id,
        agentId: this.selectedAgentId,
        status: this.selectedStatusId,
      };
      this.appliedFilters = params;

      Promise.all([
        this.$store.dispatch('deepShopifyAnalytics/fetchMetricsData', params),
        this.$store.dispatch('deepShopifyAnalytics/fetchStatuses'),
        this.$store.dispatch(
          'deepShopifyAnalytics/fetchPeriodicOrdersRevenue',
          params
        ),
        this.$store.dispatch(
          'deepShopifyAnalytics/fetchCodPrepaidSplit',
          params
        ),
        this.$store.dispatch(
          'deepShopifyAnalytics/fetchTopOrderedProducts',
          params
        ),
        this.$store.dispatch(
          'deepShopifyAnalytics/fetchTopCancelledProducts',
          params
        ),
        this.$store.dispatch('deepShopifyAnalytics/fetchOrders', params),
      ]);
    },
    async downloadReport() {
      this.downloading = true;
      await this.$store
        .dispatch('deepShopifyAnalytics/downloadReport', {
          startDate: this.formatDate(this.from * 1000).replaceAll('-', '/'),
          endDate: this.formatDate(this.to * 1000).replaceAll('-', '/'),
          inboxId: this.selectedInbox.id,
          agentId: this.selectedAgentId,
          email: this.userEmail,
        })
        .catch(() => {
          this.downloading = false;
          this.downloadError = true;
        });
    },
    hidePopup() {
      this.downloading = false;
      this.downloadError = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-box {
  margin-top: 7.8rem;
  padding-left: $zero;
}
</style>
