<template>
  <div class="create-ticket-zero-state">
    <table-zero-state
      :title="$t('CREATE_TICKET.ZERO_STATE.TITLE')"
      :subtitle="$t('CREATE_TICKET.ZERO_STATE.SUBTITLE')"
      asset-link="/brand-assets/inbox_zero_state.svg"
      style="height: 100%; background: transparent;"
    >
      <create-ticket-button is-menu-styled style="width: 20rem;" />
    </table-zero-state>
  </div>
</template>

<script>
import CreateTicketButton from 'dashboard/components/buttons/CreateTicketButton';
import TableZeroState from 'dashboard/components/ui/settings/TableZeroState';

export default {
  components: {
    CreateTicketButton,
    TableZeroState,
  },
};
</script>

<style lang="scss" scoped>
.create-ticket-zero-state {
  box-shadow: 0 0 2px rgba(250, 250, 250, 0.14),
    0 2px 2px rgba(255, 255, 255, 0.12), 0 1px 3px rgba(3, 3, 3, 0.2);
  height: 89%;
  width: 98%;
  border-radius: 0.8rem;
  background: #ffffff;
  margin: 2rem 1.6rem;
  position: relative;
}
</style>
