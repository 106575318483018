<template>
  <form @submit.prevent="onSubmit">
    <slot />
    <!-- form fields -->
    <woot-input
      v-for="(field, key) in formFields"
      :key="key"
      v-model.trim="formData[key]"
      class="content-margin"
      :rows="key === 'description' ? 5 : undefined"
      :required="field.required"
      :label="$t(field.label)"
      :placeholder="$t(field.placeholder)"
      :class="{ error: $v.formData[key].$error }"
      :has-error="$v.formData[key].$error"
      :error="$t(field.error)"
      @blur="$v.formData[key].$touch"
    />
    <!-- show tags -->
    <div class="content-margin">
      <label
        v-if="activeLabelList.length"
        style="margin-bottom: 0"
        class="formlabel"
      >
        <span class="title-h5 text-dark" v-text="'Tags'" />
      </label>
      <conversation-labels
        :conversation-id="conversationId"
        :selected-labels="selectedLabels"
        :labels="activeLabelList"
        read-only
        @updateLabels="UpdateLabels"
      />
    </div>
    <!-- media -->
    <template v-if="canUploadMedia">
      <span
        v-if="uploadingAttachment"
        class="body-b3 text-light"
        v-text="'Uploading...'"
      />
      <div
        class="attachment-container flex-row flex-wrap gap--small mg-bottom--small"
      >
        <email-file
          v-for="img in allAttachments"
          :id="img"
          :key="img"
          attachment-type="image"
          show-img-modal
          :attachment-url="img"
          can-delete
          @clear="deleteAttachment"
        />
      </div>
      <div class="flex-column content-margin">
        <div class="attachment-holder flex-space-between">
          <label class="formlabel">
            <span class="title-h5 text-dark" v-text="'Attach Images'" />
          </label>
          <label>
            <icons
              name="circleAdd"
              color="stroke-green"
              view="0 0 24 24"
              size="medium"
            />
            <input
              ref="file"
              class="form-input"
              type="file"
              @change="uploadFile"
            />
          </label>
        </div>
        <span
          class="mg-bottom--small body-b3 text-light mg-left--micro"
          v-text="
            $t(
              'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.WARNINGS.ATTACHMENT_WARNING'
            )
          "
        />
      </div>
    </template>
    <div class="full-width flex-row flex-justify">
      <woot-base-button
        type="submit"
        :loading="uiFlags.creatingTicket"
        :disabled="$v.$invalid"
      >
        Submit
      </woot-base-button>
    </div>
  </form>
</template>
<script>
import { mapGetters } from 'vuex';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { required, email } from 'vuelidate/lib/validators';

import ConversationLabels from 'dashboard/routes/dashboard/conversation/labels/LabelBox';
import EmailFile from 'dashboard/components/widgets/conversation/bubble/EmailFile';

import ticketCreation from '../mixins/ticketCreation';
import alertMixin from 'shared/mixins/alertMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';

import { uploadAttachment } from 'dashboard/helper/fileUploadHelper';

import * as types from 'shared/constants/googleEventType';
import { BASE_FORM_FIELDS } from 'dashboard/routes/dashboard/conversation/overview/constants';

export default {
  components: { ConversationLabels, EmailFile },
  mixins: [ticketCreation, alertMixin, googleAnalyticsMixin],
  props: {
    parentFormFields: {
      type: Object,
      default: () => {},
    },
    canUploadMedia: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        email: '',
        phone: '',
        order_id: '',
        subject: '',
        description: '',
      },
      allAttachments: [],
      uploadingAttachment: false,
    };
  },
  validations: {
    formData: {
      email: {
        required,
        email,
      },
      phone: {
        checkValidPhoneNumber(value) {
          if (!value) return true;
          return isValidPhoneNumber('+' + value);
        },
      },
      order_id: {
        required,
      },
      subject: {
        required,
      },
      description: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({ uiFlags: 'crm/getUIFlags' }),
    formFields() {
      return BASE_FORM_FIELDS;
    },
    selectedLabels() {
      return this.$store.getters['conversationLabels/getConversationLabels'](
        this.conversationId
      );
    },
  },
  inject: ['closeRaiseTicketSection'],
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.googleAnalyticsEvent(
        types.default.RAISE_TICKET_CRM_TYPE,
        types.default.RIGHT_SIDE_PANEL_EVENT,
        types.default.RAISE_TICKET
      );

      let payload = { ...this.formData, ...this.parentFormFields };
      await this.createTicket(payload);
      this.closeRaiseTicketSection();
    },
    async uploadFile() {
      try {
        const attachment = this.$refs.file.files[0];
        this.uploadingAttachment = true;
        const response = await uploadAttachment(attachment);
        this.uploadingAttachment = false;
        if (response.data.status) this.appendAttachmentUrl(response.data.url);
      } catch (error) {
        this.uploadingAttachment = false;
        this.showAlert('Error uploading file', 'error');
      }
    },
    appendAttachmentUrl(url) {
      const imageText = `Images: \n${url}`;

      if (this.formData.description.includes('Images: '))
        this.formData.description += `\n\n${url}`;
      else this.formData.description += `\n\n${imageText}`;

      this.allAttachments.push(url);
    },
    deleteAttachment(fileUrl) {
      this.allAttachments = this.allAttachments.filter(
        file => file !== fileUrl
      );

      // remove attachment urls from description
      this.formData.description = this.formData.description
        .replace(new RegExp(`\\b${fileUrl}\\b`, 'g'), '')
        .trim();
      if (!this.allAttachments.length) {
        this.formData.description = this.formData.description
          .replace('Images:', '')
          .trim();
      }
    },
    async UpdateLabels({ id }, action) {
      try {
        await this.$store.dispatch('conversationLabels/update', {
          conversationId: this.conversationId,
          labelId: id,
          action,
        });
      } catch (error) {
        // Ignore error
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.attachment-container {
  .attachment-holder {
    width: 100%;
  }

  .form-input {
    color: $neutral-grey-600;
    font-size: $font-size-mini;
    margin-bottom: $zero;
    margin-top: $space-smaller;
  }
}

input[type='file'] {
  display: none;
}
</style>
