<template>
  <woot-modal
    :show.sync="opened"
    :custom-style="{ width: '932px' }"
    :on-close="onClose"
  >
    <woot-modal-header :header-title="modalTitle" />
    <template v-if="!loading">
      <div class="modal-body">
        <div class="template-cards row gap--two custom-scroll">
          <div
            v-if="canCreateNew"
            class="create-card template-card"
            @click="onClickHandleCreate({})"
          >
            <span class="create-icon">
              <icons
                name="plus"
                color="lightestgrey"
                size="large"
                view="-2 -2 24 24"
                :show-title="false"
              />
            </span>
            <span class="title-h4 title text-dark">
              Create new
            </span>
          </div>
          <div
            v-for="template in templates"
            :key="template.id"
            class="template-card"
            @click="onClickHandleCreate(template)"
          >
            <div class="template-card--top">
              <img :src="template.file_url" alt="Template Image" />
            </div>
            <div class="template-card--body">
              <div
                class="title title-h4 text-dark line-clamp-1"
                v-text="template.name"
              />
              <div
                class="desc body-b3 text-light line-clamp-2"
                v-text="template.description"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <loading-state v-else style="height: 40vh" />
  </woot-modal>
</template>
<script>
import LoadingState from 'dashboard/components/ui/LoadingState';

export default {
  components: { LoadingState },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: 'Select a template from below',
    },
    canCreateNew: {
      type: Boolean,
      default: true,
    },
    templates: {
      type: Array,
      required: true,
      validator(value) {
        return value.every(template => {
          return 'id' in template && 'name' in template;
        });
      },
    },
    createButtonTitle: {
      type: String,
      default: '',
    },
    handleCreate: {
      type: Function,
      default: () => null,
    },
  },
  methods: {
    onClickHandleCreate(selectedTemplate = {}) {
      this.handleCreate(selectedTemplate);
      this.onClose();
    },
    onClose() {
      this.$emit('onClose');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.modal-body {
  padding: $space-medium;

  .template-cards {
    max-height: 36rem;

    .template-card {
      cursor: pointer;
      height: $space-normal * 10;
      width: $space-two * 10;
      border-radius: $border-radius;
      border: 1px solid $pg-1-100;
      transition: all 0.2s ease-in-out;

      &:hover {
        border: 1px solid $pg-1-500;
        background-color: $pg-light-20;
      }

      &:active {
        background-color: $pg-light-50;
      }

      &--top {
        height: 45%;
        margin-bottom: $space-small;

        img {
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
        }
      }

      &--body {
        padding: $space-small $space-normal;
      }
    }

    .create-card {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: $space-slab;
      background-color: $neutral-grey-100;
      border: 1px dashed $neutral-grey-500;
      padding: $space-normal;

      &:hover {
        background-color: $neutral-grey-200;
      }

      &:active {
        background-color: $neutral-grey-300;
      }

      .create-icon {
        ::v-deep .custom-icons {
          height: auto;
        }
      }
    }
  }
}
</style>
