<template>
  <div class="add-contact-wrapper">
    <div class="search-contact">
      <search-box
        placeholder="Search for contact numbers here"
        custom-class="search-box"
        size="medium"
        input-type="number"
        :value="searchQuery"
        :on-search="onSearchSubmit"
        :on-enter="onAddNewContact"
        @setSearch="onInputSearch"
      />
      <woot-base-button
        variant="secondary"
        size="small"
        :disabled="!searchQuery"
        @click="onAddNewContact"
      >
        ADD
      </woot-base-button>
    </div>
    <show-contact-results-panel
      v-if="showSearchResults"
      inbox-type="whatsapp"
      :is-loading="isFetchingContacts"
      :contacts="filteredContacts"
      :on-select-contact="onAddContact"
      :on-add-new-contact="onAddNewContact"
      :can-load-more-results="canLoadMoreResults"
      :close-panel="() => (showSearchResults = false)"
      @onPageChange="onPageChange"
    />
    <slot
      v-if="selectedContacts.length !== 0"
      name="selected-contacts-display-panel"
      :contacts="selectedContacts"
      :updateContacts="onUpdateContacts"
    />
  </div>
</template>
<script>
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import ShowContactResultsPanel from './showContactResults/ShowContactResultsPanel';

import alertMixin from 'shared/mixins/alertMixin';

const MAX_NUMBER_OF_CONTACTS_ALLOWED = 50;

export default {
  components: { ShowContactResultsPanel, SearchBox },
  mixins: [alertMixin],
  props: {
    selectedContacts: {
      type: Array,
      default: () => [],
    },
    contactLimit: {
      type: Number,
      default: MAX_NUMBER_OF_CONTACTS_ALLOWED,
    },
  },
  data() {
    return {
      searchQuery: '',
      fetchedContacts: [],
      isFetchingContacts: false,
      showSearchResults: false,
      canLoadMoreResults: true,
      currentPage: 1,
      debounceTimer: null,
    };
  },
  computed: {
    filteredContacts() {
      const selectedSet = new Set(
        this.selectedContacts.map(contact => `${contact.phone_number}`)
      );

      return this.fetchedContacts.filter(
        fetchedContact => !selectedSet.has(`${fetchedContact.phone_number}`)
      );
    },
  },
  methods: {
    clearSearchResults() {
      this.fetchedContacts = [];
    },
    onInputSearch(query) {
      this.searchQuery = query;
      if (!query) {
        this.showSearchResults = false;
        return;
      }

      this.debouncedOnSearchSubmit();
    },
    clearTimer() {
      clearTimeout(this.debounceTimer);
    },
    debouncedOnSearchSubmit() {
      this.clearTimer();

      this.debounceTimer = setTimeout(() => {
        this.onSearchSubmit();
      }, 1000);
    },
    onUpdateContacts(updatedContacts) {
      this.$emit('updateContacts', updatedContacts);
    },
    canAddContacts() {
      const hasReachedContactLimit =
        this.selectedContacts.length === this.contactLimit;

      if (hasReachedContactLimit) {
        this.showAlert(
          `Cannot add more than ${this.contactLimit} contacts`,
          'error'
        );
        return false;
      }
      return true;
    },
    onAddNewContact() {
      if (!this.searchQuery.trim()) return null;
      const newContact = {
        name: '',
        phone_number: this.searchQuery,
        type: 'new',
      };

      return this.onAddContact(newContact);
    },
    onAddContact(contact) {
      this.showSearchResults = false;
      this.clearTimer();
      if (!this.canAddContacts()) return;

      this.searchQuery = '';
      const updatedContacts = [...this.selectedContacts, contact];
      this.onUpdateContacts(updatedContacts);
    },
    onPageChange() {
      this.currentPage += 1;
      this.onSearchSubmit(this.currentPage, true);
    },
    onSearchSubmit(page = 1, append = false) {
      if (!this.searchQuery) {
        this.clearSearchResults();
      }
      this.clearTimer();
      this.currentPage = page;
      this.showSearchResults = true;
      this.isFetchingContacts = true;
      this.canLoadMoreResults = true;
      this.$store
        .dispatch('contacts/getSearchedContacts', {
          search: this.searchQuery,
          searchBy: 'phone_number',
          page: this.currentPage,
          isSearching: true,
        })
        .then(({ results }) => {
          this.isFetchingContacts = false;

          if (results.length === 0 || results.length < 10)
            this.canLoadMoreResults = false;

          this.fetchedContacts = append
            ? [...this.fetchedContacts, ...results]
            : results;
        })
        .catch(() => {
          this.isFetchingContacts = false;
        });
    },
  },
};
</script>
