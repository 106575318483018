<template>
  <div class="header-container">
    <Header
      class="sla-header mg-bottom--slab"
      :name="name"
      :description="description"
      :name-placeholder="namePlaceholder"
      :description-placeholder="descriptionPlaceholder"
      :on-click="onClick"
      :on-back="onBack"
    >
      <template v-slot:right-section>
        <div
          class="status-indicator flex-row flex-align gap--small"
          :class="{ active: active }"
        >
          <span class="title-h5_m text-dark mg-left--slab">
            {{ active ? 'Active' : 'Inactive' }}
          </span>
          <sla-status-toggle :data="{ name, active }" @toggle="onToggle" />
        </div>
      </template>
    </Header>
  </div>
</template>

<script>
import Header from 'dashboard/components/ui/Header';
import SlaStatusToggle from '../../components/shared/ToggleSLA';

export default {
  name: 'FormHeader',
  components: {
    Header,
    SlaStatusToggle,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    namePlaceholder: {
      type: String,
      default: '',
    },
    descriptionPlaceholder: {
      type: String,
      default: '',
    },
    onClick: {
      type: Function,
      required: true,
    },
    onBack: {
      type: Function,
      required: true,
    },
  },
  methods: {
    onToggle({ active }) {
      this.$emit('updateActive', active);
    },
  },
};
</script>
