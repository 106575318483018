import SettingsContent from '../Wrapper';
import CustomFieldSettings from './index';
import CreateFormView from './views/CreateCustomFieldView';
import EditFormView from './views/EditCustomFieldView';
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/custom-ticket-fields'),
      roles: ['administrator'],
      component: SettingsContent,
      props: {
        headerTitle: 'SIDEBAR.CUSTOM_TICKET_FIELDS',
        icon: 'ticket',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'settings_custom_ticket_fields',
          component: CustomFieldSettings,
          roles: ['administrator'],
        },
        {
          path: 'create',
          name: 'settings_create_custom_field',
          component: CreateFormView,
          roles: ['administrator'],
        },
        {
          path: 'edit/:custom_field_id',
          name: 'settings_edit_custom_field',
          component: EditFormView,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
