import * as types from 'dashboard/store/mutation-types';
import wootConstants from 'dashboard/constants';
import { transformFiltersToFrontend } from 'dashboard/routes/dashboard/conversation/chats/helpers';

const mutations = {
  [types.default.SET_CONV_VIEWS_UI_FLAGS](state, newFlags) {
    state.uiFlags = { ...state.uiFlags, ...newFlags };
  },
  [types.default.SET_CUSTOM_CONV_VIEWS](state, customViews) {
    state.customViews = customViews;
  },
  [types.default.SET_CURATED_CONV_VIEWS](state, curatedViews) {
    state.curatedViews = curatedViews;
  },
  [types.default.SET_DEFAULT_CONV_VIEW](state, defaultView) {
    state.defaultView = defaultView;
  },
  [types.default.SET_CURRENT_CONV_VIEW](state, currentView) {
    state.currentView = currentView;
  },
  [types.default.ADD_CUSTOM_CONV_VIEW](state, newView) {
    state.customViews.push(newView);
  },
  [types.default.UPDATE_CUSTOM_CONV_VIEW](state, updatedView) {
    const index = state.customViews.findIndex(
      view => view.id === updatedView.id
    );
    if (index !== -1) {
      state.customViews.splice(index, 1, updatedView);
    }
  },
  [types.default.DELETE_CONV_VIEW](state, id) {
    state.customViews = state.customViews.filter(view => view.id !== id);
  },
  [types.default.SET_CURRENT_VIEW_FILTERS](_state, filters) {
    _state.currentViewFilters = {
      ..._state.currentViewFilters,
      ...filters,
    };
  },
  [types.default.UPDATE_CURRENT_VIEW_FILTERS](_state, data) {
    const { key, value } = data;
    _state.currentViewFilters[key] = value;
  },
  [types.default.RESET_CURRENT_VIEW_FILTERS](_state) {
    _state.currentViewFilters = {
      ...wootConstants.DEFAULT_CONVERSATION_FILTERS,
      ...transformFiltersToFrontend(_state.currentView.filters),
    };
  },
};

export default mutations;
