<template>
  <div class="flex-column gap--small">
    <woot-single-selector
      class="time--filter"
      variant="secondary"
      size="small"
      front-icon="history"
      :default-option="filterTitle"
      :option-list="dateRange"
      :button-style="{
        color: '#808975',
        padding: '0.4rem',
      }"
      :custom-style="{ left: 0, ...customStyle }"
      :show-selected-text="false"
      :highlight-selected="highlightDateFilter"
      @click="onDateRangeChange"
    />
    <woot-date-range-picker
      v-if="isDateRangeSelected"
      show-range
      :value="customDateRange"
      variant="small"
      :disabled-future-dates="currentDate"
      @change="onChange"
    />
  </div>
</template>

<script>
import WootDateRangePicker from 'dashboard/components/ui/DatePicker';
import { endOfDay, subDays, startOfDay } from 'date-fns';

const CUSTOM_DATE_RANGE_ID = 4;

export default {
  components: {
    WootDateRangePicker,
  },
  props: {
    defaultSelection: {
      type: Number,
      default: null,
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dateRange: this.$t('FILTERS.DATE_RANGE'),
      currentDate: new Date(),
      customDateRange: [new Date(), new Date()],
    };
  },
  computed: {
    currentDateRangeSelection() {
      return this.$t('FILTERS.DATE_RANGE').find(
        selection => selection.id === this.defaultSelection
      );
    },
    filterTitle() {
      return this.currentDateRangeSelection.id === null
        ? 'All Time'
        : this.currentDateRangeSelection.name;
    },
    isDateRangeSelected() {
      return this.currentDateRangeSelection.id === CUSTOM_DATE_RANGE_ID;
    },
    highlightDateFilter() {
      return this.currentDateRangeSelection.id !== null;
    },
  },
  methods: {
    to(range) {
      if (this.isDateRangeSelected) {
        return endOfDay(this.customDateRange[1]);
      }
      if (range.id === 1) {
        const toDate = subDays(new Date(), 1);
        return endOfDay(toDate);
      }

      return endOfDay(new Date());
    },
    from(range) {
      if (this.isDateRangeSelected) {
        return startOfDay(this.customDateRange[0]);
      }
      const dateRange = {
        0: 0,
        1: 1,
        2: 6,
        3: 29,
      };
      const diff = dateRange[range.id || 0];
      const fromDate = subDays(new Date(), diff);
      return startOfDay(fromDate);
    },
    onDateRangeChange(range) {
      let filters = null;
      if (range.id !== null) {
        filters = {
          from: this.from(range),
          to: this.to(range),
          default: range.id ?? this.defaultSelection,
          date_range_name: range.name,
        };
      }

      this.$emit('date-range-change', filters);
    },
    onChange(value) {
      this.customDateRange = value;
      this.onDateRangeChange(this.currentDateRangeSelection);
    },
  },
};
</script>
