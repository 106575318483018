/* global axios */
import ApiClient from '../ApiClient';

class ChatApiChannel extends ApiClient {
  constructor() {
    super('channels/two_factor_channels', { accountScoped: true });
  }

  balanceTrans({ id }) {
    return axios({
      method: 'get',
      url: `${this.url}/balance_trans`,
      params: {
        id,
      },
    });
  }

  reportTrans({ id, sessionId }) {
    return axios({
      method: 'get',
      url: `${this.url}/report_trans`,
      params: {
        id,
        session_id: sessionId,
      },
    });
  }
}

export default new ChatApiChannel();
