<template>
  <div class="lime-card-1dp">
    <div class="table-title mg-bottom">
      Agent Wise Conversation States
      <a class="mg-left--slab" @click="showEducationalModal = true">
        How states work?
      </a>
    </div>
    <div class="conv-states-tables-container">
      <div class="custom-scroll">
        <span
          class="subtitle-s2 text-dark"
          v-text="showConvStatesTitleWithDateRange(startDate, endDate)"
        />
        <woot-table
          class="conv-states-table with-range"
          :header-list="$t('ANALYTICS.CONVERSATION_HEADER_WITH_RANGE')"
          :table-data="tableDataWithRange"
          column-width-one="20%"
        />
      </div>
      <div class="custom-scroll">
        <span
          class="subtitle-s2 text-dark"
          v-text="showConvStatesTitleWithEndDate(endDate)"
        />
        <woot-table
          :header-list="$t('ANALYTICS.CONVERSATION_HEADER_WITHOUT_RANGE')"
          class="conv-states-table"
          :table-data="tableDataWithoutRange"
        />
      </div>
    </div>
    <educational-onboarding-modal
      :show="showEducationalModal"
      @onClose="showEducationalModal = false"
    />
  </div>
</template>
<script>
import EducationalOnboardingModal from './EducationalOnboardingModal';

import {
  showConvStatesTitleWithDateRange,
  showConvStatesTitleWithEndDate,
} from '../../shared/helpers';

export default {
  components: { EducationalOnboardingModal },
  props: {
    selectedDateRange: {
      type: Object,
      default: () => ({ startDate: null, endDate: null }),
    },
    agentConvStates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showEducationalModal: false,
    };
  },
  computed: {
    startDate() {
      return this.selectedDateRange.startDate;
    },
    endDate() {
      return this.selectedDateRange.endDate;
    },
    tableDataWithRange() {
      return this.createFormattedData([
        'name',
        'handled',
        'unique',
        'reopened',
        'resolved',
      ]);
    },
    tableDataWithoutRange() {
      return this.createFormattedData(['open', 'followup', 'waiting']);
    },
  },
  methods: {
    showConvStatesTitleWithDateRange,
    showConvStatesTitleWithEndDate,
    createFormattedData(fields) {
      if (!this.agentConvStates) return [];
      return this.agentConvStates.map(row => {
        let newRow = {};
        fields.forEach(field => {
          newRow[field] = row[field];
        });

        return newRow;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.conv-states-tables-container {
  display: grid;
  grid-template-columns: minmax(50%, 1fr) minmax(300px, min-content);
  grid-gap: $space-slab;

  .conv-states-table {
    &.with-range {
      ::v-deep table {
        tbody {
          td:first-child {
            text-align: left;
          }
        }
      }
    }

    ::v-deep table {
      margin-top: $space-normal;

      th {
        white-space: nowrap;
      }

      tbody {
        td {
          text-align: center;

          div {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
