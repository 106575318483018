export default {
  methods: {
    documentClickListener(event) {
      const el = this.$el;
      let targetElement = event.target;

      if (el && el.contains(targetElement)) return;

      // This is a click outside the dropdown.
      this.hideDropdown();
    },
    addClickOutsideListener() {
      document.addEventListener('mousedown', this.documentClickListener);
    },
    removeClickOutsideListener() {
      document.removeEventListener('mousedown', this.documentClickListener);
    },
  },
  beforeDestroy() {
    this.removeClickOutsideListener();
  },
};
