<template>
  <div class="template-body-substitution">
    <span
      v-for="(part, index) in parsedText"
      :key="index"
      class="body-b2 text-light"
    >
      <template v-if="part.type === 'variable'">
        <span
          class="variable-button capital_c3"
          :class="[
            `CREATE_TICKET_TEMPLATE_ATTRIBUTE${part.index}`,
            { filled: substitutionsFilled[part.index] },
          ]"
          @click="variableClicked(part.index)"
        >
          {{ substitutionsFilled[part.index] ? 'ADDED' : 'ADD' }}
        </span>
      </template>
      <template v-else>
        {{ part.value }}
      </template>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    body: {
      type: String,
      required: true,
    },
    substitutions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    parsedText() {
      const parts = this.body.split(/(\{\})/);
      let selectIndex = -1;
      return parts.map(part => {
        if (this.isPlaceholder(part)) {
          selectIndex += 1;
          return { type: 'variable', index: selectIndex };
        }

        return { type: 'text', value: part };
      });
    },
    substitutionsFilled() {
      return this.substitutions.map(
        substitutionArray =>
          substitutionArray.length > 0 &&
          substitutionArray.every(
            element => typeof element === 'string' && element.trim().length > 0
          )
      );
    },
  },
  methods: {
    isPlaceholder(part) {
      return part === '{}';
    },
    variableClicked(index) {
      this.$emit('click', index);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.variable-button {
  cursor: pointer;
  margin: $zero 0.1rem;
  padding: $space-micro $space-smaller;
  background: $secondary-blue-200;
  border-radius: $border-radius-smaller;
  color: $secondary-blue;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: $font-weight-black;

  &.filled {
    background: $pg-1-500;
    color: $color-white;
  }

  &.invalid-field-error-highlighting {
    background: $warn-red-400;
  }
}
</style>
