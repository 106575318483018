<template>
  <div class="flex-row flex-align flex-justify zero-state">
    <div class="flex-column flex-align assets-container">
      <img :src="assetLink" />
      <span
        class="title-h2 text-light mg-top--large text-align--center"
        v-text="title"
      />
      <div
        class="subtitle-s2 text-light text-align--center mg-top--small mg-bottom--large"
        v-text="subtitle"
      />
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    assetLink: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.zero-state {
  background: $neutral-grey-200;
  height: calc(100% - 6rem);
}
.assets-container {
  max-width: 40%;

  img {
    min-width: 20rem;
  }
}
</style>
