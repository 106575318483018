<template>
  <div
    v-if="currentView"
    class="views-toggle-button flex-row flex-align flex-justify--between gap--small"
  >
    <div class="flex-row flex-align gap--small">
      <div class="selected-view" v-text="currentView.name" />
      <div class="metric-capsule" v-text="showCount" />
    </div>
    <woot-base-button
      tag="span"
      size="small"
      variant="tertiary"
      class="open-views-list-btn"
      @click="$emit('showViewsManager')"
      @mousedown.stop
    >
      <icons
        name="chevronDown"
        color="grey"
        view="0 0 24 24"
        size="semimedium"
      />
    </woot-base-button>
  </div>
  <div v-else class="skeleton-animation" style="height: 28px; width: 200px" />
</template>
<script>
import { mapGetters } from 'vuex';
import wootConstants from 'dashboard/constants';

export default {
  computed: {
    ...mapGetters({
      currentView: 'conversationViews/getCurrentView',
      defaultView: 'conversationViews/getDefaultView',
      uiFlags: 'conversationViews/getUiFlags',
      conversationStats: 'conversationStats/getStats',
      appliedConversationFilters: 'conversationViews/getCurrentViewFilters',
    }),
    showCount() {
      const { assigneeType } = this.appliedConversationFilters;
      const countMapping = {
        [wootConstants.ASSIGNEE_TYPE.ME]: 'mineCount',
        [wootConstants.ASSIGNEE_TYPE.UNASSIGNED]: 'unAssignedCount',
        [wootConstants.ASSIGNEE_TYPE.ALL]: 'allCount',
      };

      const countKey = countMapping[assigneeType];
      const count = this.conversationStats[countKey] || 0;

      return count > 9000 ? '9000+' : `${count}`;
    },
  },
};
</script>
