<template>
  <draggable
    tag="div"
    :list="list"
    :sort="false"
    :value="value"
    :move="onLabelMove"
    v-bind="dragOptions"
    :disabled="modalMode"
    draggable=".client"
    @end="updateMovedLabel"
    @input="updateLabelState"
  >
    <div
      v-for="label in labels"
      :id="label.id"
      :key="label.id"
      :class="label.label_type === 'client' ? 'client' : 'system'"
      :data-level="label.label_level"
    >
      <template v-if="modalMode && label.label_type === 'system'" />
      <div
        v-else
        class="draggable-container flex-space-between"
        :class="{
          'is-selected': selectedTag && selectedTag.id === label.id,
        }"
        @click.prevent="onClickLabel(label)"
      >
        <div class="flex-row-center">
          <div
            v-if="selectedTagsToPerformAction.length"
            class="tag-selection-checkbox"
          >
            <label
              v-if="!modalMode && label.label_type === 'client'"
              class="container-checkbox"
            >
              <input
                :checked="selectedTagsToPerformAction.includes(label.id)"
                type="checkbox"
                :value="label.id"
                @input="onTagSelect(label)"
              />
              <span class="grey-checkmark checkmark" />
            </label>
          </div>

          <div class="content-wrapper flex-column">
            <div
              :style="{ color: labelColor(label) }"
              class="flex-row flex-justify flex-align"
            >
              <span
                v-if="
                  !hasNestedLabel(label) &&
                    !modalMode &&
                    label.label_type === 'client'
                "
                style="cursor: pointer"
                class="mg-right--small"
              >
                <icons
                  name="drag"
                  color="lightgrey-fill-only"
                  size="mediumlarge"
                />
              </span>
              <!-- checkboxes/pages -->
              <template v-if="modalMode && label.active">
                <label class="container-checkbox tag-selection-checkbox">
                  <input
                    v-model="selectedLabels"
                    type="checkbox"
                    :value="label.id"
                    @input="handleLabelSelect(label)"
                  />
                  <span class="grey-checkmark checkmark" />
                </label>
              </template>
              <icons
                name="hashFilled"
                color="primary"
                :custom-style="{
                  fill: labelColor(label),
                  height: '2rem',
                  marginTop: '1px',
                  marginLeft: '8px',
                }"
              />
              <span
                class="content-container content--title body-b2"
                :style="{ color: labelColor(label) }"
                v-html="prepareContent(label.title)"
              />
            </div>
          </div>
        </div>
        <!-- Action Buttons -->
        <div
          v-if="!modalMode && label.label_type === 'client'"
          class="flex-row flex-align flex-justify--between gap--small"
        >
          <woot-base-button
            v-tooltip="label.active ? 'Deactivate' : 'Activate'"
            tag="span"
            size="small"
            variant="tertiary"
            @click="toggleLabelActivation(label.id)"
          >
            <woot-switch :id="label.id" :value="label.active" />
          </woot-base-button>
          <woot-base-button
            v-if="canHaveNestedLabel(label)"
            tag="span"
            size="small"
            variant="tertiary"
            @click="onOpenAddPopup(label.id)"
          >
            <icons
              name="plus"
              color="lightgrey-fill-only"
              size="medium"
              view="-1 -1 24 24"
              :show-title="false"
            />
          </woot-base-button>
          <woot-single-selector
            variant="tertiary"
            :custom-style="{
              borderRadius: '0.8rem',
              boxShadow: 'none',
              left: 'auto',
              right: '0.8rem',
              width: 'auto',
            }"
            icon-size-prop="semimedium"
            icon="threeDot"
            :show-option-name="false"
            :show-selected-text="false"
            :option-list="actionsList(label)"
            @click="(action, e) => onActionSelected(e, action, label)"
          />
        </div>
      </div>

      <nested-label
        v-if="canHaveNestedLabel(label)"
        class="label-open"
        :style="!hasNestedLabel(label) ? 'padding: 0' : ''"
        :list="label.labels"
        :parent-label-id="parentLabelId"
        :selected-tags="selectedTags"
        :resource="resource"
        :selected-tag="selectedTag"
        :on-open-add-popup="onOpenAddPopup"
        :on-open-delete-popup="onOpenDeletePopup"
        :modal-mode="modalMode"
        :search="search"
        :on-label-select="onLabelSelect"
        :on-tag-select="onTagSelect"
        :on-view-tag="onViewTag"
        :selected-tags-to-perform-action="selectedTagsToPerformAction"
      />
    </div>
  </draggable>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

import alertMixin from 'shared/mixins/alertMixin';
import labelsMixin from 'shared/mixins/labelsMixin';

import * as types from 'shared/constants/googleEventType';

export default {
  name: 'NestedLabel',
  components: {
    draggable,
  },
  mixins: [alertMixin, labelsMixin],
  props: {
    search: {
      type: String,
      default: '',
    },
    parentLabelId: {
      type: Number,
      default: null,
    },
    modalMode: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: null,
    },
    list: {
      type: Array,
      default: null,
    },
    selectedTagsToPerformAction: {
      type: Array,
      default: () => [],
    },
    selectedTags: {
      type: Array,
      default: () => [],
    },
    selectedTag: {
      type: Object,
      default: null,
    },
    resource: {
      type: String,
      default: 'Conversation',
    },
    onViewTag: {
      type: Function,
      default: () => null,
    },
    onOpenAddPopup: {
      type: Function,
      default: () => null,
    },
    onOpenDeletePopup: {
      type: Function,
      default: () => null,
    },
    onLabelSelect: {
      type: Function,
      default: () => null,
    },
    onTagSelect: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      activeMovingLabel: null,
      selectedLabels: this.selectedTags.map(label => label.id),
    };
  },
  computed: {
    ...mapGetters({
      allLabels: 'labels/getLabels',
    }),
    dragOptions() {
      return {
        animation: 200,
        group: 'labelList',
        disabled: false,
        ghostClass: 'moving-card',
      };
    },
    labels() {
      return this.value ? this.value : this.list;
    },
  },
  watch: {
    selectedTags(newTags, oldTags) {
      if (newTags !== oldTags)
        this.selectedLabels = newTags.map(label => label.id);
    },
  },
  methods: {
    findLabelById(labels, id) {
      let foundLabel = labels.find(label => label.id === id);

      // If not found, recursively search in child labels
      if (!foundLabel) {
        labels.some(label => {
          if (label.labels && label.labels.length) {
            const foundInChild = this.findLabelById(label.labels, id);
            if (foundInChild) {
              foundLabel = foundInChild;
              return true; // Stop searching further if the label is found
            }
          }
          return false;
        });
      }

      return foundLabel;
    },
    findAndToggleParentActivation(parentId) {
      if (!parentId) return;

      const parentLabel = this.findLabelById(this.allLabels, parentId);
      if (parentLabel && !parentLabel.active) {
        // If the parent is inactive, toggle its activation
        parentLabel.active = true;
        this.findAndToggleParentActivation(parentLabel.parent_id);
      }
    },
    toggleLabelActivation(labelId) {
      const toggleActiveStatus = label => {
        // Toggle the active status of the given label
        return {
          id: labelId,
          oldData: label,
          active: !label.active,
          parent_id: label.parent_id,
        };
      };

      const updateLabelInStore = updatedLabel => {
        this.$store
          .dispatch('labels/update', updatedLabel)
          .then(() => {
            this.showAlert(
              this.$t('LABEL_MGMT.EDIT.API.SUCCESS_MESSAGE'),
              'success'
            );
          })
          .catch(() => {
            this.showAlert(
              this.$t('LABEL_MGMT.EDIT.API.ERROR_MESSAGE'),
              'error'
            );
          });
      };

      const label = this.labels.find(lbl => lbl.id === labelId);
      if (label) {
        // Toggle the label's active status
        const updatedLabel = toggleActiveStatus(label);

        updateLabelInStore({
          id: updatedLabel.id,
          oldData: label,
          active: updatedLabel.active,
          parent_id: updatedLabel.parent_id,
          resource_type: this.resource,
        });

        // If activating a label, check and toggle the parent's activation if necessary
        if (updatedLabel.active) {
          this.findAndToggleParentActivation(updatedLabel.parent_id);
        }
      }
    },
    onClickLabel(label) {
      this.onViewTag(label);
      if (this.modalMode && label.label_type === 'client' && label.active) {
        this.handleLabelSelect(label);
        return;
      }
      if (this.selectedTagsToPerformAction.length) this.onTagSelect(label);
    },
    actionsList(label) {
      if (this.hasNestedLabel(label)) return [{ id: 0, name: 'select' }];

      return [
        { id: 0, name: 'select' },
        { id: 2, name: 'delete' },
      ];
    },
    onActionSelected(e, action, data) {
      e.stopPropagation();
      if (action.name === 'select') {
        this.onTagSelect(data);
        return;
      }
      if (action.name === 'delete') {
        this.onOpenDeletePopup(data);
      }
    },
    prepareContent(content = '') {
      if (this.search === '') {
        return content;
      }

      return content.replace(
        new RegExp(`(${this.search})`, 'ig'),
        '<span class="searchkey--highlight">$1</span>'
      );
    },
    hasNestedLabel(label) {
      return !!label.labels?.length;
    },
    canHaveNestedLabel(label) {
      return ['label_group', 'label_sub_group'].includes(label.label_level);
    },
    updateMovedLabel(event) {
      let labelId = Number(event.item.id);
      let updatedParentLabelId = event.to?.parentElement?.id;
      let parentLabelId = event.from?.parentElement?.id;

      if (
        labelId &&
        this.activeMovingLabel &&
        this.activeMovingLabel.id === labelId &&
        parentLabelId !== updatedParentLabelId
      ) {
        this.activeMovingLabel.parent_id = updatedParentLabelId
          ? Number(updatedParentLabelId)
          : null;
        this.activeMovingLabel.resource_type = this.resource;

        this.$store
          .dispatch('labels/update', this.activeMovingLabel)
          .then(response => {
            this.onViewTag(response.data);
            this.showAlert(
              this.$t('LABEL_MGMT.EDIT.API.SUCCESS_MESSAGE'),
              'success'
            );
          })
          .catch(() => {
            this.showAlert(
              this.$t('LABEL_MGMT.EDIT.API.ERROR_MESSAGE'),
              'error'
            );
          });
      }
    },
    updateLabelState(value) {
      this.$store.dispatch('labels/set', {
        data: value,
        resource_type: this.resource,
      });
    },
    onLabelMove(event) {
      const {
        draggedContext,
        to: { parentElement },
      } = event;

      if (parentElement.className === 'system') return false;
      if (draggedContext.element?.labels?.length) return false;
      if (draggedContext.element)
        this.activeMovingLabel = draggedContext.element;

      return true;
    },
    handleLabelSelect(label) {
      const action = this.selectedLabels.includes(label.id) ? 'remove' : 'add';
      this.onLabelSelect(label, action);
    },
    hideAddPopup() {
      this.googleAnalyticsEvent(
        types.default.LABEL_HIDE_ADD_POPUP,
        types.default.LABEL_SETTINGS,
        types.default.LABEL_SETTINGS
      );
      this.showAddPopup = false;
    },
    hideEditPopup() {
      this.googleAnalyticsEvent(
        types.default.LABEL_HIDE_EDIT_POPUP,
        types.default.LABEL_SETTINGS,
        types.default.LABEL_SETTINGS
      );
      this.showEditPopup = false;
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.moving-card {
  opacity: 0.5;
  background: $neutral-grey-200;
}

.label-open {
  padding-left: $space-larger;
}

.system {
  .draggable-container,
  .action-button,
  .content-container a {
    cursor: default;
  }
}

.tag-selection-checkbox {
  margin-left: $space-small;
  margin-top: -$space-small;
}

.container-checkbox {
  cursor: pointer;
}

.content-wrapper {
  .content-container {
    margin-left: $space-small;

    &--title {
      justify-content: flex-start;
      color: $secondary-blue;
    }

    &--text {
      color: $neutral-grey-600;
      overflow: hidden;
    }
  }
}

.action-button {
  cursor: pointer;
  margin-left: $space-small;
}

.draggable-container {
  background: $neutral-white;
  border-radius: $border-radius-smaller;
  cursor: pointer;
  padding: $space-normal $space-normal;
  margin: $zero $zero $space-small;

  &:hover {
    background-color: $neutral-grey-100;
  }
}

.is-selected {
  background-color: $pg-light-1;
  cursor: default;

  &:hover {
    background-color: $pg-light-1;
  }
}
</style>
