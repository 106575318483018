var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{staticClass:"selected-contact-results",attrs:{"name":"menu-list","tag":"div"}},_vm._l((_vm.contacts),function(ref,idx){
var name = ref.name;
var phone_number = ref.phone_number;
var type = ref.type;
return _c('div',{key:(type + "_" + idx),staticClass:"selected-contact gap--slab mg-bottom--slab",class:("CREATE_TICKET_PHONE_NUMBER" + idx)},[_c('account-pii-mask-wrapper',{attrs:{"pii-data":phone_number,"mask-state":type !== 'existing' ? _vm.MASK_STATE.noMask : _vm.MASK_STATE.serverMask,"mask-type":_vm.MASK_TYPES.PHONE_INPUT,"unmask-payload":_vm.unmaskRequestPayload(_vm.contacts[idx]),"unmask-action":"piiMask/logUnmaskEvent","on-unmask":function (data) { return _vm.updateField('phone_number', data, idx); }},on:{"update":function($event){return _vm.updateField('phone_number', $event, idx)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var isMasked = ref.isMasked;
var updateData = ref.updateData;
return [_c('woot-input',{staticClass:"full-width",class:{ error: _vm.$v.contacts.$each[idx].phone_number.$error },attrs:{"label":idx === 0 ? 'Contact Number' : '',"auto-focus":"","size":"small","value":isMasked ? phone_number : data,"type":"number","required":idx === 0,"disabled":isMasked || type === 'existing',"placeholder":isMasked ? phone_number : 'Phone number',"has-error":!isMasked && _vm.$v.contacts.$each[idx].phone_number.$error,"error":_vm.$t('CREATE_TICKET.FORM.ERRORS.NOT_VALID_PHONE_NUMBER_DETAILED')},on:{"input":updateData}})]}}],null,true)}),_vm._v(" "),_c('account-pii-mask-wrapper',{attrs:{"pii-data":name,"mask-state":type !== 'existing' ? _vm.MASK_STATE.noMask : _vm.MASK_STATE.serverMask,"mask-type":_vm.MASK_TYPES.NAME_INPUT,"unmask-payload":_vm.unmaskRequestPayload(_vm.contacts[idx]),"unmask-action":"piiMask/logUnmaskEvent","on-unmask":function (data) { return _vm.updateField('name', data, idx); }},on:{"update":function($event){return _vm.updateField('name', $event, idx)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var isMasked = ref.isMasked;
var updateData = ref.updateData;
return [_c('woot-input',{staticClass:"full-width",attrs:{"label":idx === 0 ? 'Contact Name' : '',"size":"small","value":isMasked ? name : data,"disabled":isMasked || type === 'existing',"placeholder":isMasked ? 'Unmask to view name' : 'Name'},on:{"input":updateData}})]}}],null,true)}),_vm._v(" "),_c('woot-base-button',{staticClass:"remove-button",attrs:{"tag":"span","variant":"tertiary-danger"},on:{"click":function($event){return _vm.removeContact(idx)}}},[_c('icons',{attrs:{"name":"delete","size":"semimedium","color":"red","title":"Remove contact"}})],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }