<template>
  <tippy
    v-if="showTooltip"
    :to="id"
    :visible="true"
    trigger="manual"
    :placement="placement"
    theme="animated"
    :arrow="true"
    animation="shift-toward"
  >
    {{ text }}
  </tippy>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showTooltip: null,
    };
  },
  mounted() {
    this.tooltipMount();
  },
  methods: {
    tooltipMount() {
      let initialTime = localStorage.getItem('initialTime');

      if (!initialTime) {
        setTimeout(() => {
          this.showTooltip = true;
        }, 1000);
        initialTime = new Date();
        initialTime = initialTime.getTime() / 1000;
        localStorage.setItem('initialTime', initialTime);
        return;
      }

      let currTime = new Date();
      currTime = currTime.getTime() / 1000;

      // check if 3 hours elapsed
      if (currTime >= +initialTime + 10800) {
        initialTime = currTime;
        localStorage.setItem('initialTime', initialTime);
        setTimeout(() => {
          this.showTooltip = true;
        }, 1000);
        return;
      }
      this.showTooltip = false;
    },
  },
};
</script>
