<template>
  <component
    :is="tagName"
    :href="to"
    :type="type"
    :class="[btnClass, btnSizeClass]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <icons
      v-if="frontIcon"
      :name="frontIcon"
      :color="computedIconColor"
      :view="iconViewBox"
      :size="computedIconSize"
      :show-title="false"
    />
    <slot />
    <icons
      v-if="backIcon"
      :name="backIcon"
      :color="computedIconColor"
      :view="iconViewBox"
      :size="computedIconSize"
      :show-title="false"
    />
  </component>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    type: {
      type: String,
      default: 'button',
    },
    to: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    frontIcon: {
      type: String,
      default: '',
    },
    backIcon: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
    },
    iconViewBox: {
      type: String,
      default: undefined,
    },
    iconSize: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    preserveCase: { type: Boolean, default: false },
  },
  computed: {
    tagName() {
      if (this.to) return 'a';
      return this.tag ?? 'button';
    },
    btnClass() {
      return {
        [`base-btn ${
          this.preserveCase ? 'preserve-case' : ''
        } ${this.variant
          .split('-')
          .join(' ')} flex-row flex-justify flex-align`]: true,
        'full-width': this.fullWidth,
        'base-spinner': this.loading,
        disabled: this.disabled,
      };
    },
    btnSizeClass() {
      let iconClasses = [];
      if (this.tag === 'span') iconClasses.push('-uniform-padding');
      if (this.frontIcon || this.backIcon) iconClasses.push('-with-icon');
      if (this.backIcon) iconClasses.push(`${this.size}-pd-right`);
      if (this.frontIcon) iconClasses.push(`${this.size}-pd-left`);

      switch (this.size) {
        case 'small':
          return `small${iconClasses.join(' ')} gap--small button-small`;
        case 'large':
          return `large${iconClasses.join(' ')} gap--one button-large`;
        default:
          return `medium${iconClasses.join(' ')} gap--small button-medium`;
      }
    },
    computedIconSize() {
      if (this.iconSize) return this.iconSize;
      if (this.size === 'large') return 'semimedium';
      return 'normal';
    },
    computedIconColor() {
      if (this.iconColor) return this.iconColor;
      if (this.variant.includes('-danger')) return 'dark-red';

      switch (this.variant) {
        case 'primary':
          return 'white';
        case 'secondary':
          return 'green';
        case 'tertiary':
          return 'textlightgrey';
        default:
          return 'green';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.base-btn {
  border-radius: $border-radius-smaller;
  cursor: pointer;
  min-height: 2.8rem;
  min-width: $space-small * 10;
  text-transform: uppercase;
  white-space: nowrap;
  transition: background-color 0.15s ease-out;

  &:focus-visible {
    outline-color: $pg-1-100;
    outline-style: solid;
    outline-width: medium;
  }

  &.preserve-case {
    text-transform: none;
  }
}

.primary {
  background: $pg-1-500;
  color: $neutral-white;

  &.danger {
    background-color: $neutral-white;
  }
  &.disabled {
    color: $neutral-white;
    background-color: $neutral-grey-500;
    border: none;
    pointer-events: none;

    ::v-deep .icon {
      fill: $neutral-white;
    }
  }

  &:hover {
    background-color: $pg-1-600;
  }

  &:active {
    background-color: $pg-1-700;
  }
}

.secondary,
.tertiary {
  border: 1px solid $pg-1;

  &.disabled {
    border-color: $neutral-grey-500;
    color: $neutral-grey-500;
    pointer-events: none;

    ::v-deep .icon {
      fill: $neutral-grey-500;
    }
  }

  &:hover {
    background-color: $pg-light-1;
    color: $pg-1-600;

    ::v-deep .icon {
      fill: $pg-1-600;
    }
  }

  &:active {
    background-color: $pg-1-100;
    color: $pg-1-700;

    ::v-deep .icon {
      fill: $pg-1-700;
    }
  }
}

.secondary {
  color: $pg-1;
}

.tertiary {
  border: none;
  color: $text-light;

  &.danger {
    border: none;
    color: $text-light;
  }
}

// delete state

.danger {
  border: 1px solid $warn-red-500;
  color: $warn-red-500;

  &:hover,
  &:active {
    background-color: $warn-red-100;
    color: $warn-red-500;

    ::v-deep .icon {
      fill: $warn-red-500;
    }
  }

  &:active {
    background-color: $warn-red-200;
  }
}

// button sizes

.small {
  padding: $space-smaller $space-medium;

  &-uniform-padding {
    min-width: 0;
    padding: $space-smaller;
  }

  &-with-icon {
    padding: $space-smaller $space-slab;
  }

  &-pd-right {
    padding-right: $space-small;
  }

  &-pd-left {
    padding-left: $space-small;
  }
}

.medium {
  padding: $space-small $space-large;

  &-uniform-padding {
    min-width: 0;
    padding: $space-small;
  }

  &-with-icon {
    padding: $space-small $space-slab;
  }

  &-pd-right {
    padding-right: $space-one;
  }

  &-pd-left {
    padding-left: $space-one;
  }
}

.large {
  padding: $space-small $space-slab * 3;

  &-uniform-padding {
    min-width: 0;
    padding: $space-slab;
  }

  &-with-icon {
    padding: $space-small $space-normal;
  }

  &-pd-right {
    padding-right: $space-slab;
  }

  &-pd-left {
    padding-left: $space-slab;
  }
}

// distance between label and icon

.gap {
  &--small {
    gap: $space-small;
  }

  &--one {
    gap: $space-one;
  }
}

// loading animation

.tertiary.base-spinner,
.secondary.base-spinner {
  background-color: $neutral-white;
}

.base-spinner {
  position: relative;
  pointer-events: none;
  opacity: 0.8;
  overflow: hidden;

  &:before {
    background-color: inherit;
    display: block;
    height: 100%;
    content: '';
    left: $zero;
    position: absolute;
    top: $zero;
    width: 100%;
    z-index: 1;
  }

  &:after {
    animation: spinner 0.8s linear infinite;
    content: '';
    border: 2px solid $gradient-white;
    border-radius: 50%;
    border-top-color: inherit;
    height: $space-two;
    margin-top: -$space-one;
    margin-left: -$space-one;
    position: absolute;
    left: 50%;
    top: 50%;
    width: $space-two;
    z-index: 2;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
