/* global axios */

import ApiClient from './ApiClient';

class Agents extends ApiClient {
  constructor() {
    super('agents', { accountScoped: true });
  }

  delete(ids) {
    return axios.patch(`${this.url}/bulk_delete`, { agent_ids: ids });
  }

  fetchTicketCount(id, params) {
    return axios.get(`${this.url}/${id}/ticket_count`, { params });
  }
}

export default new Agents();
