<template>
  <base-integration
    ref="baseIntegration"
    :integration-fields="integrationFields"
    :form-length="formLength"
    @catchInput="integrationConnect"
  />
</template>

<script>
import BaseIntegration from './BaseIntegration';
export default {
  components: {
    BaseIntegration,
  },
  computed: {
    integrationFields() {
      return this.$t('INTEGRATION_SETTINGS.LOGISTICS.INTEGRATIONS.PICKRR');
    },
    formLength() {
      return this.integrationFields.FORM.length;
    },
  },
  mounted() {
    this.$refs.baseIntegration.getConnectionDetails();
  },
  methods: {
    integrationConnect(model) {
      const data = {
        token: model[0],
        is_active: model[1],
      };
      this.$refs.baseIntegration.createConnection(data);
    },
  },
};
</script>
