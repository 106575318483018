export const DEFAULT_FILTERS = {
  assigneeType: 'all',
  status: 'open',
  inboxId: undefined,
  teamId: undefined,
  selectedAgent: null,
};

export const VIEW_TYPE = {
  curated: 'curated_view',
  custom: 'custom_view',
};

export const CONTROL_TYPE = {
  makeDefault: 'makeDefault',
  edit: 'edit',
  delete: 'delete',
  clone: 'clone',
};

export const CONTROLS = {
  default: [],
  current: [CONTROL_TYPE.makeDefault],
  curated: [CONTROL_TYPE.makeDefault, CONTROL_TYPE.clone],
  custom: [CONTROL_TYPE.makeDefault, CONTROL_TYPE.edit, CONTROL_TYPE.delete],
};
