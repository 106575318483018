<template>
  <label :class="['input-wrapper', uploadState]">
    <input
      v-if="uploadState !== 'processing'"
      type="file"
      name="attachment"
      @change="onChangeFile"
    />
    <woot-spinner v-if="uploadState === 'processing'" />
    <icons v-if="uploadState === 'idle'" icon="file-upload" />
    <icons
      v-if="uploadState === 'uploaded'"
      icon="checkmark-circle"
      type="outline"
      class="success-icon"
    />
    <icons
      v-if="uploadState === 'failed'"
      icon="dismiss-circle"
      type="outline"
      class="error-icon"
    />
    <p class="file-button">{{ label }}</p>
  </label>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    initialFileName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uploadState: 'idle',
      label: this.$t('AUTOMATION.ATTACHMENT.LABEL_IDLE'),
    };
  },
  mounted() {
    if (this.initialFileName) {
      this.label = this.initialFileName;
      this.uploadState = 'uploaded';
    }
  },
  methods: {
    async onChangeFile(event) {
      this.uploadState = 'processing';
      this.label = this.$t('AUTOMATION.ATTACHMENT.LABEL_UPLOADING');
      try {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('attachment', file, file.name);
        await this.$store.dispatch('automations/uploadAttachment', formData);
        this.uploadState = 'uploaded';
        this.label = this.$t('AUTOMATION.ATTACHMENT.LABEL_UPLOADED');
      } catch (error) {
        this.uploadState = 'failed';
        this.label = this.$t('AUTOMATION.ATTACHMENT.LABEL_UPLOAD_FAILED');
        this.showAlert(this.$t('AUTOMATION.ATTACHMENT.UPLOAD_ERROR'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

input[type='file'] {
  display: none;
}

.input-wrapper {
  display: flex;
  height: 39px;
  background-color: $color-white;
  border-radius: $border-radius;
  border: 1px dashed $color-border-light;
  padding: $space-smaller $space-small;
  align-items: center;
  font-size: $font-size-mini;
  cursor: pointer;

  &.processing {
    cursor: not-allowed;
    opacity: 0.9;
  }
}

.success-icon {
  margin-right: $space-small;
  color: $success-color;
}

.error-icon {
  margin-right: $space-small;
  color: $alert-color;
}

.file-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: $zero;
}
</style>
