<template>
  <woot-single-selector
    variant="secondary"
    size="small"
    :default-option="
      $t(`SLA.CREATE.SECTIONS.METRICS.${value.toUpperCase()}.TITLE`)
    "
    :option-list="options"
    :custom-style="{
      left: 0,
      width: '20rem',
    }"
    :show-selected-text="false"
    @click="onClick"
  />
</template>
<script>
import { METRICS_OPTIONS } from '../../../../../utils/constants';

export default {
  props: {
    value: {
      type: String,
      default: 'select',
    },
    excludedOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    options() {
      return METRICS_OPTIONS.filter(
        option => !this.excludedOptions.includes(option.value)
      );
    },
  },
  methods: {
    onClick(value) {
      this.$emit('click', value);
    },
  },
};
</script>
