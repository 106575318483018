export const SUBSCRIPTIONS = [
  'conversation_created',
  'conversation_resolved',
  'conversation_opened',
  'conversation_tags_updated',
  'message_created',
  'message_updated',
  'webwidget_triggered',
  'message_status_changed',
  'contact_opt_in_changed',
  'label_created',
  'agent_analytics_created',
  'agent_analytics_updated',
  'conversation_analytics_created',
  'conversation_analytics_updated',
];
