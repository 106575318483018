import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import TemplateApi from '../../api/template';

const state = {
  records: [],
  uiFlags: {
    fetchingTemplateList: false,
    fetchingTemplateItem: false,
    creatingTemplateItem: false,
    updatingTemplateItem: false,
    deletingTemplateItem: false,
  },
};

const getters = {
  getTemplates(_state) {
    return _state.records;
  },
  getTemplateUIFlags(_state) {
    return _state.uiFlags;
  },
};

const actions = {
  getTemplate: async function getTemplate(
    { commit },
    { inboxId, searchKey } = {}
  ) {
    commit(types.default.SET_TEMPLATE_UI_FLAG, { fetchingTemplateList: true });
    try {
      const response = await TemplateApi.fetch({
        inbox_id: inboxId,
        search: searchKey,
        status: 'approved',
      });
      commit(types.default.SET_TEMPLATE, response.data);
      commit(types.default.SET_TEMPLATE_UI_FLAG, {
        fetchingTemplateList: false,
      });
    } catch (error) {
      commit(types.default.SET_TEMPLATE_UI_FLAG, {
        fetchingTemplateList: false,
      });
    }
  },

  createTemplate: async function createTemplate({ commit }, templateObj) {
    commit(types.default.SET_TEMPLATE_UI_FLAG, { creatingTemplateItem: true });
    try {
      await TemplateApi.create(templateObj);
      commit(types.default.SET_TEMPLATE_UI_FLAG, {
        creatingTemplateItem: false,
      });
    } catch (error) {
      commit(types.default.SET_TEMPLATE_UI_FLAG, {
        creatingTemplateItem: false,
      });
      if (error.response) {
        if (error.response.data.message.includes('Short code')) {
          throw error.response.data.message;
        }
      }
    }
  },

  addTemplate: async function addTemplate({ commit }, templateObj) {
    commit(types.default.SET_TEMPLATE_UI_FLAG, { creatingTemplateItem: true });
    commit(types.default.ADD_TEMPLATE, templateObj);
    commit(types.default.SET_TEMPLATE_UI_FLAG, {
      creatingTemplateItem: false,
    });
  },

  updateTemplate: async function updateTemplate(
    { commit },
    { id, ...updateObj }
  ) {
    commit(types.default.SET_TEMPLATE_UI_FLAG, { updatingTemplateItem: true });
    try {
      const response = await TemplateApi.update(id, updateObj);
      commit(types.default.EDIT_TEMPLATE, response.data);
      commit(types.default.SET_TEMPLATE_UI_FLAG, {
        updatingTemplateItem: false,
      });
    } catch (error) {
      commit(types.default.SET_TEMPLATE_UI_FLAG, {
        updatingTemplateItem: false,
      });
      if (error.response) {
        if (error.response.data.message.includes('Short code')) {
          throw error.response.data.message;
        }
      }
    }
  },

  deleteTemplate: async function deleteTemplate({ commit }, id) {
    commit(types.default.SET_TEMPLATE_UI_FLAG, { deletingTemplateItem: true });
    try {
      await TemplateApi.delete(id);
      commit(types.default.DELETE_TEMPLATE, id);
      commit(types.default.SET_TEMPLATE_UI_FLAG, {
        deletingTemplateItem: true,
      });
    } catch (error) {
      commit(types.default.SET_TEMPLATE_UI_FLAG, {
        deletingTemplateItem: true,
      });
    }
  },

  fetchTemplates: function fetchTemplates() {
    return TemplateApi.fetchTemplates();
  },
};

const mutations = {
  [types.default.SET_TEMPLATE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_TEMPLATE]: MutationHelpers.set,
  [types.default.ADD_TEMPLATE]: MutationHelpers.create,
  [types.default.EDIT_TEMPLATE]: MutationHelpers.update,
  [types.default.DELETE_TEMPLATE]: MutationHelpers.destroy,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
