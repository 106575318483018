<template>
  <div>
    <whatsapp-component
      :messages="messages"
      only-chat-screen
      custom-height="height: calc(100vh - 320px)"
    />
    <div
      v-if="messages[0].buttons.buttons"
      class="full-width edit-button-container flex-row flex-justify--end"
    >
      <woot-base-button
        size="small"
        variant="secondary"
        @click="handleEditButtonPayload"
      >
        Edit Button Payload
      </woot-base-button>
    </div>
    <slot />
  </div>
</template>
<script>
import WhatsappComponent from 'dashboard/components/WhatsappUIWidget';

import broadcastMixin from 'dashboard/mixins/broadcast';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: { WhatsappComponent },
  mixins: [broadcastMixin, mixPanelAnalyticsMixin],
  props: {
    selectedTemplate: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    messages() {
      return [
        {
          type: 'received',
          value: this.highlightedBrackets(this.selectedTemplate.body, true),
          doc: this.selectedTemplate?.template_type === 'document',
          img: this.selectedTemplate?.template_type === 'image',
          buttons: this.selectedTemplate?.buttons,
        },
      ];
    },
  },
  methods: {
    handleEditButtonPayload() {
      this.$emit('openEditModal', { ...this.selectedTemplate, navigateTo: 1 });
      this.mix_panel_clicked_settings_edit_button_payload_template();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.edit-button-container {
  padding: $space-slab $space-two;
}
</style>
