<template>
  <base-ticket-form
    :parent-form-fields="{ department_id: selectedDepartmentId, selectedCrm }"
  >
    <div class="content-margin">
      <label class="formlabel">
        <span class="title-h5 text-dark" v-text="'Department'" />
      </label>
      <woot-single-selector
        v-if="departmentOptionList.length"
        :default-option="selectedDepartment"
        :option-list="departmentOptionList"
        :custom-style="{ width: '100%', 'margin-bottom': '12px' }"
        @click="changeDepartment"
      />
      <div
        v-else
        class="body-b3 text-light"
        v-text="
          $t(
            'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.ZOHO.OVERVIEW_PANEL.FORM.ERRORS.NO_DEPARTMENT'
          )
        "
      />
    </div>
  </base-ticket-form>
</template>
<script>
import { mapGetters } from 'vuex';

import BaseTicketForm from './shared/BaseTicketForm';

import alertMixin from 'shared/mixins/alertMixin';

import { AVAILABLE_CRMS } from 'dashboard/routes/dashboard/conversation/overview/constants';

export default {
  components: { BaseTicketForm },
  mixins: [alertMixin],
  data() {
    return {
      selectedDepartment: '',
      selectedDepartmentId: undefined,
    };
  },
  computed: {
    ...mapGetters({
      departmentOptionList: 'crm/getDepartmentNames',
    }),
    selectedCrm() {
      return AVAILABLE_CRMS.ZOHO;
    },
  },
  created() {
    this.$store
      .dispatch('crm/fetchDepartmentNames', { selectedCrm: this.selectedCrm })
      .then(response => {
        if (Object.keys(response).length !== 0) {
          let defaultDepartment = this.departmentOptionList.find(
            el => el.isDefault === true
          );

          if (!defaultDepartment)
            defaultDepartment = this.departmentOptionList[0];

          this.selectedDepartment = defaultDepartment.name;
          this.selectedDepartmentId = defaultDepartment.id;
        }
      })
      .catch(() => {
        this.showAlert(
          this.$t(
            'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.ZOHO.OVERVIEW_PANEL.FORM.ERRORS.ERROR'
          ),
          'error'
        );
      });
  },
  methods: {
    changeDepartment(val) {
      this.selectedDepartment = val.name;
      this.selectedDepartmentId = val.id;
    },
  },
};
</script>
