import AuthAPI from '../api/auth';
import { getInstance } from '../auth0';
import BaseActionCableConnector from '../../shared/helpers/BaseActionCableConnector';
import { BUS_EVENTS } from '../../shared/constants/busEvents';
import { CALL_DIRECTION } from 'dashboard/constants';

class ActionCableConnector extends BaseActionCableConnector {
  constructor(app, pubsubToken) {
    super(app, pubsubToken);
    this.CancelTyping = [];
    this.events = {
      'message.created': this.onMessageCreated,
      'message.updated': this.onMessageUpdated,
      'conversation.created': this.onConversationCreated,
      'conversation.status_changed': this.onStatusChange,
      'user:logout': this.onLogout,
      'page:reload': this.onReload,
      'assignee.changed': this.onAssigneeChanged,
      'conversation.typing_on': this.onTypingOn,
      'conversation.typing_off': this.onTypingOff,
      'conversation.contact_changed': this.onConversationContactChange,
      'billing_plan.changed': this.onBillingPlanChanged,
      'presence.update': this.onPresenceUpdate,
      'inboxstatus.changed': this.onInboxStatusChanged,
      'conversation.update_new': this.onUpdateNew,
      'conversation.sla_updated': this.onConversationSlaUpdated,
      'template.created': this.onTemplateCreated,
      'agent.call': this.handleVoiceEvents,
      'agent.answer': this.handleVoiceEvents,
      'customer.call': this.handleVoiceEvents,
      'customer.answer': this.handleVoiceEvents,
      bridge: this.handleVoiceEvents,
      hangup: this.handleVoiceEvents,
      originate: this.handleVoiceEvents,
      'broadcast.progress': this.broadcastProgress,
      'three.sixty.submitted': this.handleChannelSubmittedThreeSixtyEvent,
      'three.sixty.live': this.handleChannelLiveThreeSixtyEvent,
      user_assignment_count_updated: this.handleCurrentAgentTicketCount,
      user_notifications_count_updated: this.onUserNotificationsCountUpdated,
      notification_created: this.onNotificationCreated,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  onReconnect = () => {
    bus.$emit(BUS_EVENTS.WEBSOCKET_RECONNECT);
  };

  // eslint-disable-next-line class-methods-use-this
  onDisconnected = () => {
    bus.$emit(BUS_EVENTS.WEBSOCKET_DISCONNECT);
  };

  isAValidEvent = (data, pubsub_token) => {
    let currentAccount = this.app.$store.getters.getCurrentUser.accounts?.find(
      account => account.id === this.app.$store.getters.getCurrentAccountId
    );
    if (currentAccount.role === 'agent') {
      return (
        this.app.$store.getters.getCurrentUser.pubsub_token === pubsub_token
      );
    }
    return this.app.$store.getters.getCurrentAccountId === data.account_id;
  };

  sendPrivateNote = conversationId => {
    const { notes } = this.app.$store.getters.getCallMeta;

    if (notes.trim() !== '') {
      const messagePayload = {
        conversationId,
        message: notes,
        private: true,
      };
      this.app.$store.dispatch('sendMessage', messagePayload);
      this.app.$store.dispatch('setCallMeta', {
        notes: '',
        callDuration: '',
      });
    }
  };

  handleChannelSubmittedThreeSixtyEvent = data => {
    const { inbox_id: id, onboarding_started_at } = data;
    this.app.$store.dispatch('inboxes/updateInboxWithoutOverriding', {
      id,
      onboarding_started_at,
      channel_submitted: true,
    });
  };

  handleChannelLiveThreeSixtyEvent = data => {
    const { inbox_id: id } = data;
    this.app.$store.dispatch('inboxes/updateInboxWithoutOverriding', {
      id,
      channel_live: true,
    });
  };

  // capture knowlarity events
  handleVoiceEvents = data => {
    const {
      call_direction: callDirection,
      event_name: eventName,
      conversation_display_id: conversationId,
    } = data;

    if (
      callDirection.toUpperCase() === CALL_DIRECTION.INBOUND &&
      eventName === 'ORIGINATE'
    ) {
      this.app.$store.dispatch('resetCallState');
      bus.$emit('incomingCall', { ...data, show: true });
    }

    if (eventName === 'HANGUP' && conversationId)
      this.sendPrivateNote(conversationId);

    this.app.$store.dispatch('updateCallState', data);
  };

  broadcastProgress = data => {
    const { broadcast_id: broadCastId, progress } = data;
    this.app.$store.dispatch('updateBrdState', { broadCastId, progress });
  };

  onMessageUpdated = data => {
    this.app.$store.dispatch('updateMessage', data);
  };

  onPresenceUpdate = data => {
    this.app.$store.dispatch('contacts/updatePresence', data.contacts);
    this.app.$store.dispatch('setCurrentUserAvailabilityStatus', data.users);
  };

  onConversationContactChange = payload => {
    const { meta = {}, id: conversationId } = payload;
    const { sender } = meta || {};
    if (conversationId) {
      this.app.$store.dispatch('updateConversationContact', {
        conversationId,
        ...sender,
      });
    }
  };

  onAssigneeChanged = payload => {
    const {
      meta = {},
      additional_attributes = {},
      id,
      status,
      assigned_by,
      inbox_id,
      assigned_by_resource_id,
      forwarded_conversation = {},
    } = payload;

    const { assignee } = meta || {};
    const { previous_status = '' } = additional_attributes;
    if (id) {
      this.app.$store.dispatch('updateAssignee', {
        id,
        assignee,
        assigned_by,
        assigned_by_resource_id,
        forwarded_conversation,
      });
      this.fetchConversationStats(
        'assigneeChange',
        status,
        previous_status,
        assignee?.id,
        inbox_id,
        forwarded_conversation?.previous_assignee_id
      );
    }
  };

  onConversationCreated = data => {
    this.app.$store.dispatch('addConversation', data);
  };

  onConversationSlaUpdated = data => {
    const {
      conversation_id,
      sla_policy_id,
      frt_due,
      nrt_due,
      resolution_due,
    } = data;

    const payload = {
      conversation_id,
      sla_policy_id,
      frt_due,
      nrt_due,
      resolution_due,
    };

    this.app.$store.dispatch('updateConversationProperty', payload);
    this.app.$store.dispatch('updateAllTabConversationProperty', payload);
  };

  onNotificationCreated = data => {
    this.app.$store.dispatch('notifications/addNotification', data);
  };

  onLogout = () => {
    this.app.$store.dispatch('logout');
  };

  onMessageCreated = data => {
    this.app.$store.dispatch('addMessage', data);
  };

  onReload = () => window.location.reload();

  onStatusChange = data => {
    const { additional_attributes = {}, meta = {}, status, inbox_id } = data;
    const { previous_status = '' } = additional_attributes;
    const { assignee } = meta || {};
    const assigneeId = assignee?.id;

    if (data.id) {
      this.fetchConversationStats(
        'statusChange',
        status,
        previous_status,
        assigneeId,
        inbox_id
      );
      this.app.$store.dispatch('updateConversation', data);
    }
  };

  onTypingOn = ({ conversation, user }) => {
    const conversationId = conversation.id;

    this.clearTimer(conversationId);
    this.app.$store.dispatch('conversationTypingStatus/create', {
      conversationId,
      user,
    });
    this.initTimer({ conversation, user });
  };

  onTypingOff = ({ conversation, user }) => {
    const conversationId = conversation.id;

    this.clearTimer(conversationId);
    this.app.$store.dispatch('conversationTypingStatus/destroy', {
      conversationId,
      user,
    });
  };

  onBillingPlanChanged = data => {
    this.app.$store.dispatch('setBillingPlanData', data);
  };

  onInboxStatusChanged = data => {
    this.app.$store.dispatch('inboxes/inboxStatusChanged', data);
  };

  onUpdateNew = data => {
    this.app.$store.dispatch('updateConversationNewLabel', data);
  };

  onTemplateCreated = data => {
    this.app.$store.dispatch('addTemplate', data);
  };

  clearTimer = conversationId => {
    const timerEvent = this.CancelTyping[conversationId];

    if (timerEvent) {
      clearTimeout(timerEvent);
      this.CancelTyping[conversationId] = null;
    }
  };

  initTimer = ({ conversation, user }) => {
    const conversationId = conversation.id;
    // Turn off typing automatically after 30 seconds
    this.CancelTyping[conversationId] = setTimeout(() => {
      this.onTypingOff({ conversation, user });
    }, 30000);
  };

  fetchConversationStats = (
    method,
    status = '',
    prevStatus = '',
    assigneeId = null,
    inbox_id = null,
    previousAssigneeId = null
  ) => {
    bus.$emit('fetch_conversation_stats', {
      method,
      status,
      prevStatus,
      inbox_id,
      assigneeId,
      previousAssigneeId,
    });
  };

  handleCurrentAgentTicketCount = ({ overall_count: assignedCount }) => {
    this.app.$store.dispatch('agents/setCurrentAgentTicketMeta', {
      assignedCount,
    });
  };

  onUserNotificationsCountUpdated = data => {
    this.app.$store.dispatch('notifications/setUnreadCount', data);
  };
}

export default {
  init() {
    const auth0 = getInstance();

    if (auth0.isAuthenticated) {
      const actionCable = new ActionCableConnector(
        window.WOOT,
        AuthAPI.getPubSubToken()
      );
      return actionCable;
    }
    return null;
  },
};
