import Vue from 'vue';
import types from '../mutation-types';
import ConversationApi from '../../api/inbox/conversation';

const state = {
  mineCount: 0,
  unAssignedCount: 0,
  allCount: 0,
};

function getUpdationValue(activeStatus, status, prevStatus) {
  if (activeStatus === status) return 1;
  if (activeStatus === prevStatus) return -1;

  return 0;
}

function newCount(prevValue, updationValue) {
  const newValue = prevValue + updationValue;
  return newValue >= 0 ? newValue : prevValue;
}

export const getters = {
  getStats: $state => $state,
};

export const actions = {
  get: async ({ commit }, params) => {
    try {
      const response = await ConversationApi.meta(params);
      const {
        data: { meta },
      } = response;
      commit(types.SET_CONV_TAB_META, meta);
    } catch (error) {
      // Ignore error
    }
  },
  set({ commit }, meta) {
    commit(types.SET_CONV_TAB_META, meta);
  },
  update({ commit }, data) {
    if (data.method === 'statusChange') {
      commit(types.UPDATE_CONVERSATION_STATS_ON_STATUS_CHANGE, data);
    } else if (data.method === 'assigneeChange') {
      commit(types.UPDATE_CONVERSATION_STATS_ON_ASSIGNEE_CHANGE, data);
    }
  },
};

export const mutations = {
  [types.SET_CONV_TAB_META](
    $state,
    {
      mine_count: mineCount,
      unassigned_count: unAssignedCount,
      all_count: allCount,
    } = {}
  ) {
    const isNumber = value => typeof value === 'number';

    if (isNumber(mineCount)) {
      Vue.set($state, 'mineCount', mineCount);
    }
    if (isNumber(unAssignedCount)) {
      Vue.set($state, 'unAssignedCount', unAssignedCount);
    }
    if (isNumber(allCount)) {
      Vue.set($state, 'allCount', allCount);
    }
  },
  [types.UPDATE_CONVERSATION_STATS_ON_STATUS_CHANGE](
    $state,
    { activeStatus, status, prevStatus, assigneeId, userId } = {}
  ) {
    let { mineCount, unAssignedCount, allCount } = $state;
    const value = getUpdationValue(activeStatus, status, prevStatus); // will return 1, -1 or 0

    Vue.set($state, 'allCount', newCount(allCount, value));
    if (userId === assigneeId) {
      // conversation added into active state is assigned to current user, increase mineCount by 1
      Vue.set($state, 'mineCount', newCount(mineCount, value));
    } else if (assigneeId === null) {
      // conversation added into active state is unassigned, increase unassingedCount by 1
      Vue.set($state, 'unAssignedCount', newCount(unAssignedCount, value));
    }
  },
  [types.UPDATE_CONVERSATION_STATS_ON_ASSIGNEE_CHANGE](
    $state,
    { activeStatus, status, previousAssigneeId, assigneeId, userId } = {}
  ) {
    let { mineCount, unAssignedCount } = $state;

    if (activeStatus !== status) return;
    if (!previousAssigneeId)
      // conversation got assigned, decrease unAssignedCount by 1
      Vue.set($state, 'unAssignedCount', newCount(unAssignedCount, -1));
    if (!assigneeId)
      // conversation got unassigned, increase unAssignedCount by 1
      Vue.set($state, 'unAssignedCount', newCount(unAssignedCount, 1));
    if (assigneeId === userId)
      // conversation got assigned to current agent, increase mineCount by 1
      Vue.set($state, 'mineCount', newCount(mineCount, 1));
    if (previousAssigneeId === userId && mineCount > 0)
      // conversation got unassigned from current agent, decrease mineCount by 1
      Vue.set($state, 'mineCount', newCount(mineCount, -1));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
