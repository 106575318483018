<template>
  <div
    class="column padding-container settings-box custom-scroll"
    style="padding-top: 0px"
  >
    <div class="filter-pane">
      <div class="flex-space-between" style="margin-top: 0.5rem">
        <div class="flex-row analytics-filters">
          <date-range-selector
            :default-selection="2"
            @date-range-change="onDateRangeChange"
          />
          <inbox-filter
            :allow-multi-select="true"
            :value="$t('ANALYTICS.FILTER_SELECTION.INBOX.PLACEHOLDER')"
            :exclude-inbox-types="excludedInboxTypes"
            :toggle-filter="changeInboxId"
          />
          <woot-single-selector
            :disabled="false"
            variant="secondary"
            size="medium"
            :default-option="selectedWorkingHour"
            :option-list="optionList"
            :show-selected-text="false"
            :custom-style="{ width: 'auto' }"
            @click="changeWorkingHourType"
          />
          <!-- <div>
            <woot-multi-selector
              variant="secondary"
              size="medium"
              default-option="All labels"
              :option-list="labelsOptionList"
              :collapse-selected="true"
              :show-option-tooltip="true"
              default-key="title"
              :custom-style="{ width: '20rem' }"
              :show-selected-text="false"
              :toggle-filter="changeLabelId"
            />
          </div> -->
        </div>
        <div>
          <woot-primary-button
            :name="$t('ANALYTICS.DOWNLOAD_REPORT.REPORT')"
            front-icon="email"
            @click="download"
          />
        </div>
      </div>
    </div>
    <div v-if="inboxWebsiteDivisionUIFlag" class="flex-space-between">
      <div style="width: 33%">
        <pie-chart-skeleton />
      </div>
      <div style="width: 33%">
        <pie-chart-skeleton />
      </div>
      <div style="width: 33%">
        <pie-chart-skeleton />
      </div>
    </div>
    <div v-else class="flex-space-between">
      <div
        class="lime-card-1dp bottom-container"
        style="width: 49%; height: 400px"
      >
        <pie-chart
          title="Inbox Division"
          :series="channelDistributionSeries"
          :colors="channelDistributionColors"
          :labels="channelDistributionCategories"
          :height="350"
          width="100%"
          legend-position="bottom"
          :legend-offset-x="0"
          :legend-offset-y="0"
          :marker-offset-x="0"
          :market-offset-y="0"
          :chart-offset-x="-10"
          :chart-offset-y="10"
        />
      </div>

      <div
        class="lime-card-1dp bottom-container"
        style="width: 49%; height: 400px"
      >
        <pie-chart
          title="Website page breakdown"
          :series="websiteEntrySeries"
          :colors="websiteEntryColors"
          :labels="websiteEntryLabels"
          :height="350"
          width="100%"
          legend-position="bottom"
          :legend-offset-x="0"
          :legend-offset-y="0"
          :marker-offset-x="0"
          :market-offset-y="0"
          :chart-offset-x="-10"
          :chart-offset-y="10"
        />
      </div>
    </div>

    <div v-if="userConcernComplainUIFlag" class="flex-space-between">
      <table-skeleton />
    </div>
    <div v-else class="flex-space-between">
      <div class="bottom-container" style="width: 49%">
        <woot-table
          title="Users Concerns"
          class="lime-card-1dp"
          :header-list="$t('ANALYTICS.USER_CONCERNS_HEADER')"
          :table-data="userTableOne"
          :is-sortable="true"
          :max-height="maxTableHeight"
        />
      </div>

      <div class="bottom-container" style="width: 49%">
        <woot-table
          title="Users Complaints"
          class="lime-card-1dp"
          :header-list="$t('ANALYTICS.USER_COMPLAINT_HEADER')"
          :table-data="userTableTwo"
          :is-sortable="true"
          :max-height="maxTableHeight"
        />
      </div>
    </div>
    <div v-if="userHandoffUIFlag" class="flex-space-between">
      <div style="width: 33%">
        <pie-chart-skeleton />
      </div>
      <div style="width: 33%">
        <pie-chart-skeleton />
      </div>
      <div style="width: 33%">
        <pie-chart-skeleton />
      </div>
    </div>
    <div v-else class="flex-space-between">
      <div
        class="lime-card-1dp bottom-container"
        style="width: 49%; height: 390px"
      >
        <pie-chart
          title="Reason of agent handoff"
          :series="handoffReasonPieSeries"
          :colors="handoffReasonColors"
          :labels="handoffReasonLabels"
          :height="400"
          width="510"
          legend-position="right"
          :legend-offset-x="-10"
          :legend-offset-y="20"
          :marker-offset-x="0"
          :market-offset-y="0"
          :chart-offset-x="0"
          :chart-offset-y="15"
        />
      </div>
      <div
        class="lime-card-1dp bottom-container"
        style="width: 49%; height: 390px"
      >
        <pie-chart
          title="Flow Breakdown of Agent Handoff"
          :series="userHandoffPieSeries"
          :colors="userHandoffPieColors"
          :labels="userHandoffPieCategories"
          :height="340"
          width="490"
          legend-position="right"
          :legend-offset-x="-40"
          :legend-offset-y="70"
          :marker-offset-x="0"
          :market-offset-y="0"
          :chart-offset-x="0"
          :chart-offset-y="10"
        />
      </div>
    </div>
    <div v-if="userActivityUIFlag">
      <line-chart-skeleton />
    </div>
    <div v-else class="lime-card-1dp medium-12 bottom-container">
      <heat-map-chart
        title="Users Activity"
        :series="userActivityHeatMapData"
        :height="heatMapHeight"
      />
    </div>
    <div v-if="userBotFailingTableUIFlag">
      <table-skeleton />
    </div>
    <div v-else class="bottom-container">
      <woot-table
        title="Bot failing utterances"
        :header-list="$t('ANALYTICS.BOT_FAILING_UTTERANCES_HEADER')"
        :table-data="botFailingTable"
        class="lime-card-1dp"
        :is-sortable="true"
        :is-downloadable="true"
        download-text="Download Full List"
        :is-downloading="downloadUIFlag"
        column-width-one="40%"
        column-width-two="20%"
        text-width="40rem"
        @download-csv="downloadBotFailingUtterancesTable"
      />
    </div>
    <woot-modal :show.sync="downloading" :on-close="hidePopup">
      <div class="download-note">
        <img
          src="https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
        />
        <div>
          An email with attached report will be sent to
          <span>{{ userEmail }} </span> shortly.
        </div>
      </div>
    </woot-modal>
    <woot-modal :show.sync="downloadError" :on-close="hidePopup">
      <div class="download-note">
        <div>Sorry for inconvenience some error occured.</div>
      </div>
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PieChart from 'dashboard/components/widgets/chart/PieChart';
import HeatMapChart from 'dashboard/components/widgets/chart/HeatMapChart';
import DateRangeSelector from '../components/DateRangeSelector';
import PieChartSkeleton from '../components/PieChartSkeleton';
import LineChartSkeleton from 'dashboard/components/LineChartSkeleton';
import TableSkeleton from '../components/TableSkeleton';
import InboxFilter from 'dashboard/components/widgets/conversation/filter/InboxFilter';

import timeMixin from 'dashboard/mixins/time';
import alertMixin from 'shared/mixins/alertMixin';
import downloadMixin from 'dashboard/mixins/download';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import { getUserEmail } from 'dashboard/helper/cookieHelper';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

export default {
  components: {
    HeatMapChart,
    DateRangeSelector,
    PieChartSkeleton,
    LineChartSkeleton,
    TableSkeleton,
    PieChart,
    InboxFilter,
  },
  mixins: [alertMixin, timeMixin, downloadMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      dateRange: '1',
      startDate: this.formatDate(new Date().getTime()).replaceAll('-', '/'),
      endDate: this.formatDate(new Date().getTime()).replaceAll('-', '/'),
      channelDistributionPieChart: null,
      userHandoffPieChart: null,
      userConcernsTable: null,
      userComplaintsTable: null,
      userActivityHeatMap: 0,
      botFailingUtterancesTable: null,
      handoffReasonPieChart: null,
      websiteEntryPieChart: null,
      selectedInboxes: [],
      selectedLabels: [],
      selectedWorkingHourType: 'all',
      downloading: false,
      downloadError: false,
      working_hour_type: [
        {
          NAME: 'All Time',
          KEY: 'all',
        },
        {
          NAME: 'Outside Working Hours',
          KEY: 'outside',
        },
        {
          NAME: 'Inside Working Hours',
          KEY: 'inside',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      inboxWebsiteDivisionUIFlag: 'getUserInboxWebsiteUIFlag',
      userConcernComplainUIFlag: 'getUserConcernComplainUIFlag',
      userHandoffUIFlag: 'getUserHandoffUIFlag',
      userActivityUIFlag: 'getUserActivityUIFlag',
      userBotFailingTableUIFlag: 'getUserBotFailingTableUIFlag',
      downloadUIFlag: 'getUsersDownloadTableUIFlag',
      accountLabels: 'labels/getLabelsWithoutGroups',
    }),
    excludedInboxTypes() {
      return [INBOX_TYPES.KNOWLARITY];
    },
    userEmail() {
      return getUserEmail();
    },
    optionList() {
      return this.working_hour_type.map(item => {
        return {
          key: item.KEY,
          name: item.NAME,
        };
      });
    },
    labelsOptionList() {
      return this.accountLabels;
    },
    selectedWorkingHour() {
      let selectedIndex = this.optionList.findIndex(
        a => a.key === this.selectedWorkingHourType
      );
      return selectedIndex > 0
        ? this.optionList[selectedIndex].name
        : this.optionList[0].name;
    },

    channelDistributionSeries() {
      return this.channelDistributionPieChart?.values;
    },
    channelDistributionColors() {
      return [
        '#6BAC1B',
        '#1B83AC',
        ' #9270CA',
        '#F6BD16',
        '#5D7092',
        '#FF9D4D',
      ];
    },
    channelDistributionCategories() {
      return this.channelDistributionPieChart?.labels;
    },
    websiteEntrySeries() {
      return this.websiteEntryPieChart?.values;
    },
    websiteEntryColors() {
      return ['#6BAC1B', '#1B83AC', '#9270CA', '#5D7092', '#5B8FF9', '#FF9D4D'];
    },
    websiteEntryLabels() {
      return this.websiteEntryPieChart?.labels;
    },
    userHandoffPieSeries() {
      return this.userHandoffPieChart?.values;
    },
    userHandoffPieColors() {
      return ['#6BAC1B', '#F6BD16', '#1B83AC', '#5D7092', '#9270CA'];
    },
    userHandoffPieCategories() {
      return this.userHandoffPieChart?.labels
        ? this.userHandoffPieChart?.labels
        : ['Complaint Flow', 'Order Tracking Flow', 'Product Flow'];
    },
    handoffReasonPieSeries() {
      return this.handoffReasonPieChart?.values;
    },
    handoffReasonColors() {
      return ['#6BAC1B', '#5D7092', '#9270CA', '#1B83AC', '#F6BD16'];
    },
    handoffReasonLabels() {
      let formattedLabels = this.handoffReasonPieChart?.labels.map(label => {
        let newString = label.replaceAll('_', ' ');
        return newString.charAt(0).toUpperCase() + newString.slice(1);
      });

      return formattedLabels;
    },

    userTableOne() {
      return this.userConcernsTable ? this.userConcernsTable : [];
    },
    userTableTwo() {
      return this.userComplaintsTable ? this.userComplaintsTable : [];
    },

    maxTableHeight() {
      if (
        this.userConcernsTable?.length > 0 &&
        this.userComplaintsTable?.length > 0
      ) {
        return '300px';
      }
      return 'auto';
    },
    botFailingTable() {
      let formattedData = this.botFailingUtterancesTable?.map(row => {
        let newRow = {};
        newRow.Name = row.Name;
        newRow['Conversation ID'] = row['Conversation ID'];
        newRow['Bot Confidence%'] = row['Bot Confidence%'].toFixed(1) + '%';
        newRow.Reason = row.Reason;
        return newRow;
      });

      return formattedData || [];
    },

    userActivityHeatMapData() {
      return this.userActivityHeatMap ? this.userActivityHeatMap : [];
    },
    heatMapHeight() {
      if (this.userActivityHeatMap?.length <= 2) {
        return '300px';
      }
      if (
        this.userActivityHeatMap?.length > 2 &&
        this.userActivityHeatMap?.length <= 9
      ) {
        return '350px';
      }
      if (
        this.userActivityHeatMap?.length > 20 &&
        this.userActivityHeatMap?.length <= 40
      ) {
        return '700px';
      }
      if (
        this.userActivityHeatMap?.length > 55 &&
        this.userActivityHeatMap?.length < 75
      ) {
        return '1200px';
      }
      return 'auto';
    },
  },

  methods: {
    async download() {
      this.mix_panel_clicked_report_menu_item('user_behaviour', 'report');

      this.downloading = true;
      await this.$store
        .dispatch('getUserAnalyticsReport', {
          start_date: this.startDate,
          end_date: this.endDate,
          email: this.userEmail,
          labels: this.selectedLabels,
          inboxes: this.selectedInboxes,
          working_hours: this.selectedWorkingHourType,
        })
        .then(() => {})
        .catch(() => {
          this.downloading = false;
          this.downloadError = true;
        });
    },
    hidePopup() {
      this.downloading = false;
      this.downloadError = false;
    },
    onDateRangeChange({ from, to }) {
      this.startDate = this.formatDate(from * 1000).replaceAll('-', '/');
      this.endDate = this.formatDate(to * 1000).replaceAll('-', '/');
      this.getChannelDistribution();
      this.getUserHandoffByFlow();
      this.getUserConcerns();
      this.getUserComplaints();
      this.getUserActivity();
      this.getBotFailingData();
      this.getHandoffReasonBreakdown();
      this.getWebsiteEntryPoint();
    },
    changeInboxId(val, inboxes, id) {
      this.selectedInboxes = id;
      this.getChannelDistribution();
      this.getUserHandoffByFlow();
      this.getUserConcerns();
      this.getUserComplaints();
      this.getUserActivity();
      this.getBotFailingData();
      this.getHandoffReasonBreakdown();
      this.getWebsiteEntryPoint();
    },
    changeWorkingHourType(val) {
      this.selectedWorkingHourType = val.key;
      this.getChannelDistribution();
      this.getUserHandoffByFlow();
      this.getUserConcerns();
      this.getUserComplaints();
      this.getUserActivity();
      this.getBotFailingData();
      this.getHandoffReasonBreakdown();
      this.getWebsiteEntryPoint();
    },
    changeLabelId(val, inboxes, id) {
      this.selectedLabels = id;
      this.getChannelDistribution();
      this.getUserHandoffByFlow();
      this.getUserConcerns();
      this.getUserComplaints();
      this.getUserActivity();
      this.getBotFailingData();
      this.getHandoffReasonBreakdown();
      this.getWebsiteEntryPoint();
    },
    async getChannelDistribution() {
      await this.$store
        .dispatch('getUserChannelDistribution', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          workingHours: this.selectedWorkingHourType,
          labels: this.selectedLabels,
        })
        .then(data => {
          this.channelDistributionPieChart = data;
        });
    },

    async getUserHandoffByFlow() {
      await this.$store
        .dispatch('getUserHandoffByFlows', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          workingHours: this.selectedWorkingHourType,
          labels: this.selectedLabels,
        })
        .then(data => {
          this.userHandoffPieChart = data;
        });
    },

    async getUserConcerns() {
      await this.$store
        .dispatch('getUserConcerns', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          workingHours: this.selectedWorkingHourType,
          labels: this.selectedLabels,
        })
        .then(data => {
          this.userConcernsTable = data;
        });
    },

    async getUserComplaints() {
      await this.$store
        .dispatch('getUserComplaints', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          workingHours: this.selectedWorkingHourType,
          labels: this.selectedLabels,
        })
        .then(data => {
          this.userComplaintsTable = data;
        });
    },

    async getUserActivity() {
      await this.$store
        .dispatch('getUserActivity', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          workingHours: this.selectedWorkingHourType,
          labels: this.selectedLabels,
        })
        .then(data => {
          this.userActivityHeatMap = data;
        });
    },

    async downloadBotFailingUtterancesTable() {
      this.mix_panel_clicked_report_menu_item(
        'user_behaviour',
        'bot_failing_utterances'
      );
      this.downloading = true;
      await this.$store
        .dispatch('downloadBotFailingData', {
          topFive: false,
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          workingHours: this.selectedWorkingHourType,
          labels: this.selectedLabels,
          email: this.userEmail,
        })
        .then(() => {})
        .catch(() => {
          this.downloading = false;
          this.downloadError = true;
        });
    },
    async getBotFailingData() {
      await this.$store
        .dispatch('getBotFailingData', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          workingHours: this.selectedWorkingHourType,
          labels: this.selectedLabels,
        })
        .then(data => {
          this.botFailingUtterancesTable = data;
        });
    },
    async getHandoffReasonBreakdown() {
      await this.$store
        .dispatch('getHandoffReasonBreakdown', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          workingHours: this.selectedWorkingHourType,
          labels: this.selectedLabels,
        })
        .then(data => {
          this.handoffReasonPieChart = data;
        });
    },
    async getWebsiteEntryPoint() {
      await this.$store
        .dispatch('getWebsiteEntryPoint', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          workingHours: this.selectedWorkingHourType,
          labels: this.selectedLabels,
        })
        .then(data => {
          this.websiteEntryPieChart = data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
.settings-container {
  display: flex;
  flex-direction: column;
}
.filter-pane {
  position: -webkit-sticky;
  position: sticky;
  background-color: #faf9f5;
  top: $zero;
  z-index: 1000;
  padding-bottom: $space-slab;
}
.chart-card {
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius;
  padding: $space-one $space-medium;
}
.metrics-card-container {
  display: flex;
  flex-direction: row;
  margin: $space-medium $space-micro;
  margin-top: $space-one;
}
.bottom-container {
  display: flex;
  flex-direction: column;
  margin: $space-micro $space-micro $space-normal;
}
.cancel-button {
  padding: $space-small;
  margin-bottom: $space-normal;
  cursor: pointer;
}
.selector-button {
  background: $neutral-white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: $neutral-grey-800;
  font-size: $font-size-small;
  line-height: 22px;
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius;
  padding: $space-small;
  margin-bottom: $space-normal;
  cursor: pointer;
  &:focus {
    border: 1px solid $pg-1-500;
    outline: none;
  }
}
.section-title {
  font-size: $font-size-medium;
  line-height: $space-medium;
  color: $neutral-grey-600;
  margin-bottom: $space-slab;
}
.button {
  margin-bottom: auto;
}

.margin-right {
  margin-left: $space-medium;
}

.metric-card-skeleton {
  margin-right: $space-medium;
}

.table-title {
  font-size: $font-size-medium;
  line-height: 2.6rem;
  color: $neutral-grey-800;
  font-weight: $font-weight-medium;
  margin: $space-medium $zero;
}
</style>
