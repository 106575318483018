<template>
  <div>
    <div class="ticket-card mg-bottom--small">
      <div class="flex-space-between mg-bottom--smaller">
        <span class="title-h5 text-dark" v-text="truncateString(ticket.id)" />
        <woot-chips
          :title="truncateString(ticket.status)"
          variant="primary-small"
          :show-tooltip="false"
          :show-cancel-icon="false"
        />
      </div>
      <div>
        <span class="title-h5 text-dark">Sub: </span>
        <span
          class="body-b2 text-dark"
          v-text="truncateString(ticket.subject)"
        />
      </div>
      <div class="flex-space-between">
        <div>
          <span class="title-h5 text-dark">Order ID: </span>
          <span
            class="body-b2 text-dark"
            v-text="truncateString(ticket.orderId)"
          />
        </div>
        <woot-base-button
          v-tooltip="'Edit'"
          tag="span"
          size="small"
          variant="tertiary"
          @click="toggleEditModal"
        >
          <icons
            name="editRound"
            color="green"
            size="semimedium"
            :show-title="false"
          />
        </woot-base-button>
      </div>
    </div>

    <edit-ticket
      v-if="showEditModal"
      :show="showEditModal"
      :ticket="ticket"
      :selected-crm="selectedCrm"
      @cancel="toggleEditModal"
    />
  </div>
</template>

<script>
import EditTicket from '../edit/EditTicket';

export default {
  components: {
    EditTicket,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    selectedCrm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showEditModal: false,
    };
  },
  methods: {
    truncateString(inputString) {
      const limit = 25;
      if (!inputString || inputString.length === 0) return 'Unavailable';

      return inputString.length > limit
        ? `${inputString.slice(0, limit)}...`
        : inputString;
    },
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.ticket-card {
  padding: $space-small;
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius;
}
</style>
