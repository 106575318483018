<template>
  <section class="actions-section">
    <div class="columns filters-wrap">
      <label class="formlabel">
        <span
          class="title-h5 text-dark"
          v-text="$t('AUTOMATION.ADD.FORM.ACTIONS.LABEL')"
        />
      </label>
      <action-input
        v-for="(action, idx) in actions"
        :key="idx"
        v-model="actions[idx]"
        :index="idx"
        :is-first-action="idx === 0"
        :action-types="actionTypes"
        :dropdown-values="getActionDropdownValues(actions[idx].action_name)"
        :show-action-input="showActionInput(actions[idx].action_name)"
        :initial-file-name="getFileName(actions[idx], files)"
        @resetAction="resetAction(idx)"
        @removeAction="removeAction(idx)"
      />
      <div v-if="lastAction && !!lastAction.action_name" class="filter-actions">
        <woot-base-button
          tag="span"
          size="small"
          variant="secondary"
          @click="appendAction"
        >
          <icons
            name="plus"
            color="green"
            size="semimedium"
            :show-title="false"
            view="0 0 20 20"
          />
        </woot-base-button>
      </div>
    </div>
  </section>
</template>

<script>
import ActionInput from './Input';

export default {
  components: { ActionInput },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    actionTypes: {
      type: Array,
      default: () => [],
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    lastAction() {
      const actionsCpy = [...this.actions];
      const lastIndex = actionsCpy.length - 1;
      return actionsCpy[lastIndex];
    },
  },
  methods: {
    appendAction() {
      this.$emit('appendAction');
    },
    resetAction(index) {
      this.$nextTick(() => {
        this.$emit('resetAction', index);
      });
    },
    removeAction(index) {
      this.$emit('removeAction', index);
    },
    getActionDropdownValues(type) {
      return this.$parent.getActionDropdownValues(type);
    },
    showActionInput(action) {
      if (!action) return false;

      return this.$parent.showActionInput(action);
    },
    getFileName(action, files) {
      return this.$parent.getFileName(action, files);
    },
  },
};
</script>
