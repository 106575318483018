<template>
  <div class="filter" :class="actionInputStyles">
    <div
      class="flex-row flex-row gap--normal flex-align--start flex-justify--between"
    >
      <div class="filter-inputs full-width">
        <woot-single-selector
          :class="`AUTOMATION_ACTION_NAME_${index}`"
          :default-option="selectedAction"
          :option-list="actionTypes"
          :custom-style="selectorCustomStyle"
          :dropdown-container-style="attributeDropdownStyle"
          @click="handleActionTypeChange"
        />
        <div
          v-if="showActionInput"
          class="filter__answer--wrap"
          :class="`AUTOMATION_ACTION_PARAMS_${index}`"
        >
          <div v-if="inputType">
            <div
              v-if="inputType === 'search_select'"
              class="multiselect-wrap--small"
            >
              <multiselect
                v-model="actionParams"
                track-by="id"
                label="name"
                :placeholder="$t('FORMS.MULTISELECT.SELECT')"
                selected-label
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                deselect-label=""
                :max-height="160"
                :options="dropdownValues"
                allow-empty
                :option-height="104"
              />
            </div>
            <div
              v-else-if="inputType === 'multi_select'"
              class="multiselect-wrap--small"
            >
              <multiselect
                v-model="actionParams"
                track-by="id"
                label="name"
                :placeholder="$t('FORMS.MULTISELECT.SELECT')"
                :multiple="true"
                selected-label
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                deselect-label=""
                :max-height="160"
                :options="dropdownValues"
                allow-empty
                :option-height="104"
              />
            </div>
            <woot-input
              v-else-if="inputType === 'email'"
              v-model="actionParams"
              type="email"
              class="answer--text-input"
              placeholder="Enter email"
            />
            <woot-input
              v-else-if="inputType === 'url'"
              v-model="actionParams"
              type="url"
              class="answer--text-input"
              placeholder="Enter URL"
            />
            <file-input
              v-if="inputType === 'attachment'"
              v-model="actionParams"
              :initial-file-name="initialFileName"
            />
          </div>
        </div>
        <woot-base-button
          v-if="!isMacro && !isFirstAction"
          tag="span"
          variant="tertiary-danger"
          @click="removeAction"
        >
          <icons name="bin" color="red" size="semimedium" :show-title="false" />
        </woot-base-button>
      </div>
    </div>
    <team-message-input
      v-if="inputType === 'team_message'"
      v-model="actionParams"
      :teams="dropdownValues"
    />
    <woot-input
      v-if="inputType === 'textarea'"
      v-model="actionParams"
      rows="4"
      class="action-message"
      :placeholder="$t('AUTOMATION.ACTION.TEAM_MESSAGE_INPUT_PLACEHOLDER')"
    />
    <text-editor
      v-if="inputType === 'text_with_variables'"
      v-model="actionParams"
      class="action-message"
      enable-variables
      is-menu-bar-required
      is-format-mode
      :min-height="15"
      placeholder="Type '{' to select a variable to add in your message template"
    />
  </div>
</template>

<script>
import TeamMessageInput from './TeamMessageInput';
import FileInput from './FileInput';
import TextEditor from 'shared/components/TextEditor';

export default {
  components: {
    TeamMessageInput,
    FileInput,
    TextEditor,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    index: {
      type: Number,
      default: 0,
    },
    actionTypes: {
      type: Array,
      default: () => [],
    },
    dropdownValues: {
      type: Array,
      default: () => [],
    },
    showActionInput: {
      type: Boolean,
      default: true,
    },
    initialFileName: {
      type: String,
      default: '',
    },
    isMacro: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFirstAction() {
      return this.index === 0;
    },
    selectedAction() {
      return this.value.action_name
        ? this.actionTypes.find(type => type.id === this.value.action_name)
        : 'Select an action';
    },
    actionParams: {
      get() {
        return this.value ? this.value.action_params : null;
      },
      set(value) {
        this.$emit('input', { ...this.value, action_params: value });
      },
    },
    inputType() {
      return this.selectedAction ? this.selectedAction.inputType : null;
    },
    selectorCustomStyle() {
      return {
        left: 0,
        top: 0,
        width: '100%',
        maxHeight: '14rem',
        zIndex: 100,
      };
    },
    attributeDropdownStyle() {
      return { height: '3.6rem', width: '22rem' };
    },
    actionInputStyles() {
      return {
        'is-a-macro': this.isMacro,
      };
    },
  },
  methods: {
    handleActionTypeChange(value) {
      this.resetAction();
      this.$emit('input', { ...this.value, action_name: value.id });
    },
    removeAction() {
      this.$emit('removeAction');
    },
    resetAction() {
      this.$emit('resetAction');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.filter.has-error {
  background: $color-white;
}

.filter-error {
  color: $warn-red-400;
  display: block;
  margin: $space-smaller;
}

.action-message {
  margin: $space-small $zero $zero;
}
</style>
