<template>
  <tbody>
    <tr v-for="idx in columns" :key="idx">
      <td v-for="index in columns" :key="index">
        <box-skeleton />
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  components: {
    BoxSkeleton: {
      render(h) {
        return h('div', {
          attrs: { class: 'skeleton-animation skeleton-col' },
        });
      },
    },
  },
  props: {
    columns: { type: Number, default: 5 },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.skeleton-col {
  height: 5rem;
}

tr {
  background-color: $neutral-white;

  td {
    border-top: $space-small solid $neutral-grey-200;
  }
}
</style>
