<template functional>
  <span class="custom-icons">
    <svg
      :viewBox="props.view"
      class="icon"
      text-anchor="middle"
      :style="props.customStyle"
      :class="$options.methods.getStyleClasses(props)"
      v-bind="data.attrs"
      role="img"
      v-on="listeners"
    >
      <title v-if="props.showTitle">{{ props.title || props.name }}</title>
      <desc v-if="props.desc">{{ props.desc }}</desc>
      <path :d="$options.methods.getPath(props)" />
      <path
        v-if="props.layerFill || props.layerStroke"
        :d="$options.methods.getPathLayer(props)"
        :fill="props.layerFill"
        :stroke="props.layerStroke"
      />
    </svg>
  </span>
</template>

<script>
import icons from 'dashboard/assets/icons/icons.json';
export default {
  name: 'AppIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    view: {
      type: String,
      default: '0 0 24 24',
    },
    size: {
      type: String,
      default: 'normal',
    },
    color: {
      type: String,
      default: 'default',
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    layerFill: {
      type: String,
      default: '',
    },
    layerStroke: {
      type: String,
      default: '',
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getPath(props) {
      return icons[props.name];
    },
    getPathLayer(props) {
      return icons[props.name + 'Layer'];
    },
    getStyleClasses(props) {
      let styles = props.color.split(' ');
      styles.push(props.size);
      return styles.map(val => val && `is-${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.custom-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.is-evenodd {
  fill-rule: evenodd;
  clip-rule: evenodd;
}
/* sizes */
.is-largest {
  width: 8rem;
  height: auto;
}
.is-large {
  width: 4rem;
  height: auto;
}
.is-mediumlarge {
  width: 3.2rem;
  height: auto;
  max-height: 3.2rem;
}
.is-medium {
  width: 2.4rem;
  height: auto;
  max-height: 2.4rem;
}
.is-semimedium {
  width: 2rem;
  height: auto;
  max-height: 2rem;
}
.is-normal {
  width: 1.6rem;
  height: auto;
}
.is-small {
  width: 1.2rem;
  height: auto;
}
.is-smaller {
  width: 1rem;
  height: auto;
}
.is-tiny {
  width: 0.8rem;
  height: auto;
}

/* colors */
.is-default {
  fill: none;
  stroke: #000;
}
.is-inactive,
.is-green {
  fill: $pg-1-500;
}
.is-green-stroke {
  fill: none;
  stroke: $pg-1-500;
  stroke-width: 1.5;
}
.is-semidarkgreen-stroke {
  fill: none;
  stroke: $pg-1-600;
  stroke-width: 1.5;
}
.is-success,
.is-primary {
  fill: $secondary-blue;
  transition: fill 0.2s linear;
}
.is-danger {
  fill: $warn-red-400;
}
.is-warning,
.is-orange {
  fill: #ff9800;
}
.is-active,
.is-darkgreen {
  fill: $pg-1-700;
}
.is-semidarkgreen {
  fill: $pg-1-600;
}
.is-white {
  fill: $neutral-white;
  fill-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.is-lightestgrey {
  fill: $neutral-grey-400;
  transition: fill 0.2s linear;
}
.is-lightgrey {
  fill: $neutral-grey-500;
  stroke: $neutral-grey-500;
  stroke-width: 0.5;
  transition: fill 0.2s linear;
}
.is-lightgrey-fill-only {
  fill: $neutral-grey-500;
  transition: fill 0.2s linear;
}
.is-grey {
  fill: $neutral-grey-600;
}
.is-darkgrey {
  fill: $neutral-grey-700;
  fill-rule: evenodd;
  clip-rule: evenodd;
}
.is-darkestgrey {
  fill: $neutral-grey-800;
}
.is-textlightgrey-stroke {
  fill: none;
  stroke: $text-light;
}
.is-textlightgrey {
  fill: $text-light;
}
.is-stroke-green {
  fill: none;
  stroke: $pg-1-500;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.is-stroke-darkest-grey {
  fill: none;
  stroke: $neutral-grey-800;
}
.is-stroke-warn {
  fill: none;
  stroke: $warn-red-400;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.is-stroke-blue {
  fill: none;
  stroke: $secondary-blue;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.is-tick {
  fill: #4fc3f7;
}
.is-whatsapp {
  fill: #25d366;
}
.is-stroke-red {
  fill: none;
  stroke: $warn-red-500;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.is-stroke-white {
  fill: none;
  stroke: $color-white;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.is-red {
  fill: $r-500;
}
.is-dark-red {
  fill: $warn-red-500;
}
.is-stroke-grey {
  fill: none;
  stroke: $neutral-grey-700;
}
.is-stroke-lightgrey {
  fill: none;
  stroke: $neutral-grey-600;
}
.is-twitter {
  fill: #1da1f2;
  stroke: #1da1f2;
  stroke-width: 0.3;
}
.is-facebook {
  fill: #4267b2;
  stroke-width: 0;
}
.is-messenger {
  fill: #00a7ff;
  stroke-width: 0;
}
.is-instagram {
  fill: #e1306c;
  stroke-width: 0;
}
.is-secondaryblue {
  fill: #1b83ac;
}
.is-darkyellow {
  fill: #f08700;
}
.is-warningyellow {
  fill: #f6bd16;
}
.is-warningyellow200 {
  fill: #ffe898;
}
.is-error {
  fill: $warn-red-400;
}
.is-purple {
  fill: $purple;
}
.is-text-dark {
  fill: $text-dark;
}
</style>
