<template>
  <div class="channel">
    <router-link
      :to="
        frontendURL(
          `accounts/${accountId}/settings/integrations/` + integrationAction
        )
      "
    >
      <div
        class="channel-image-container"
        :class="{ active: integrationEnabled }"
      >
        <span class="linked-icon flex-row flex-align flex-justify">
          <icons
            name="link"
            color="darkgreen"
            size="medium"
            view="-3 -3 24 24"
            title="linked"
          />
        </span>
        <div class="channel-image flex-row flex-align flex-justify">
          <img :src="'/dashboard/images/integrations/' + integrationLogo" />
        </div>
        <span
          class="channel__title body-b3 text-light"
          v-text="integrationName"
        />
      </div>
    </router-link>

    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.TITLE')"
      :message="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.YES')"
      :reject-text="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.NO')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { frontendURL } from '../../../../helper/URLHelper';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    integrationId: {
      type: String,
      default: '',
    },
    integrationLogo: {
      type: String,
      default: '',
    },
    integrationName: {
      type: String,
      default: '',
    },
    integrationAction: {
      type: String,
      default: '',
    },
    integrationEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDeleteConfirmationPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    frontendURL,
    openDeletePopup() {
      this.showDeleteConfirmationPopup = true;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      this.deleteIntegration(this.deleteIntegration);
      this.$router.push({ name: 'settings_integrations' });
    },
    async deleteIntegration() {
      try {
        await this.$store.dispatch(
          'integrations/deleteIntegration',
          this.integrationId
        );
        this.showAlert(
          this.$t('INTEGRATION_SETTINGS.DELETE.API.SUCCESS_MESSAGE'),
          'success'
        );
      } catch (error) {
        this.showAlert(
          this.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.API.ERROR_MESSAGE'),
          'error'
        );
      }
    },
  },
};
</script>
