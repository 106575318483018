<template>
  <div
    :id="id"
    class="accordian-container lime-card-border"
    :style="customStyle"
    :class="customClass"
  >
    <div class="row">
      <div class="medium-12 columns">
        <div class="accordian-description" :class="id" @click="toggle">
          <span
            v-if="!customDesc"
            class="flex-row-justify-center"
            style="overflow: hidden"
          >
            <span v-if="icon" class="accordian-icon">
              <icons
                :name="icon"
                color="textlightgrey"
                :view="iconView"
                size="semimedium"
              />
            </span>
            <span
              class="text-light accordian-title text-truncate"
              :class="computedClass"
            >
              {{ name }}
            </span>
          </span>
          <slot v-else name="desc" />
          <span
            v-if="!alwaysOpen"
            class="accordian-toggle"
            :class="{ 'rotate-icon': isPanelOpen }"
          >
            <icons
              name="chevronDown"
              color="grey"
              view="0 0 24 24"
              size="semimedium"
              :title="!isPanelOpen ? 'open' : 'close'"
            />
          </span>
          <span
            v-if="hasAddButton"
            class="accordian-toggle"
            @click="$emit('addButtonMethod')"
          >
            <icons
              name="circleAdd"
              color="stroke-green"
              view="0 0 24 24"
              size="medium"
            />
          </span>
        </div>
      </div>
      <div class="medium-12 columns">
        <slot name="close" />
      </div>
    </div>
    <transition name="toast-fade">
      <div v-if="isPanelOpen">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    id: { type: String, default: '' },
    name: {
      type: String,
      default: 'Title',
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    iconView: {
      type: String,
      default: '0 0 20 20',
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    customDesc: {
      type: Boolean,
      default: false,
    },
    firstTimeOpen: {
      type: Boolean,
      default: false,
    },
    hasAddButton: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: Object,
      default: () => {},
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isPanelOpen: false,
    };
  },
  computed: {
    computedClass() {
      return `is-${this.color} || ' '`;
    },
  },
  mounted() {
    this.isPanelOpen = this.alwaysOpen || this.firstTimeOpen;
  },
  methods: {
    toggle() {
      const { isPanelOpen } = this;
      if (!this.alwaysOpen) this.isPanelOpen = !isPanelOpen;
      this.$emit('panelToggled', { isPanelOpen });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.accordian-container {
  margin-bottom: $space-slab;
  display: flex;
  flex-direction: column;
  padding: $space-slab;
  transition: all 0.3s;
  position: relative;
}

.accordian-description {
  font-size: $font-size-default;
  color: $neutral-grey-800;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s;
}

.accordian-toggle {
  @include button-animation;

  display: flex;
  align-items: flex-start;
  border-radius: 100px;
  padding: $space-micro;
  height: $space-medium;
}

.accordian-title {
  line-height: 160%;
}

.accordian-icon {
  margin-right: $space-small;
}

.icon-center {
  align-items: center;
  display: inline-block;
}

span > * {
  vertical-align: middle;
}

.is-blue {
  color: $secondary-blue;
}
</style>
