<template>
  <div class="type-section-wrapper">
    <section class="type-section mg-bottom--normal">
      <label class="formlabel">
        <span
          class="body-b2 text-light"
          v-text="$t('CUSTOM_TICKET_FIELDS.ADD.FORM.TYPE.LABEL')"
        />
      </label>
      <div class="flex-row gap--normal">
        <woot-single-selector
          size="small"
          :class="{ disabled: editView }"
          :default-option="selectedType"
          :option-list="CUSTOM_FIELD_TEMPLATES"
          :show-selected-text="false"
          :custom-style="{ left: 0, width: '100%' }"
          :dropdown-container-style="{ width: '36rem' }"
          @click="changeType"
        />
        <span
          v-if="editView"
          v-tooltip="'Cannot change field type in edit view'"
        >
          <icons
            name="warning"
            color="grey evenodd"
            size="semimedium"
            :show-title="false"
          />
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import { CUSTOM_FIELD_TEMPLATES } from '../../utils/constants';

export default {
  props: {
    selectedType: {
      type: Object,
      required: true,
    },
    editView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CUSTOM_FIELD_TEMPLATES,
    };
  },
  methods: {
    changeType(type) {
      this.$emit('updateType', type);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.type-section-wrapper {
  padding: $space-normal;
  width: 50rem;
  background-color: $neutral-grey-100;
  border-radius: $border-radius;
  border: 1px solid $neutral-grey-300;

  .disabled {
    cursor: default;
    pointer-events: none;
  }
}
</style>
