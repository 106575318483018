<template>
  <button
    :type="type"
    data-testid="submit_button"
    class="flex-row-justify-center"
    :disabled="disabled"
    :class="computedClass"
    @click="onClick"
  >
    <icons
      v-if="!!iconName"
      :color="iconClass"
      :size="iconSize"
      :name="iconName"
    />
    <span>{{ buttonText }}</span>
    <spinner v-if="loading" />
  </button>
</template>

<script>
import Spinner from 'shared/components/Spinner';

export default {
  components: {
    Spinner,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: 'white',
    },
    iconName: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: 'normal',
    },
    type: {
      type: String,
      default: 'submit',
    },
  },
  computed: {
    computedClass() {
      return `button nice ${this.buttonClass || ' '}`;
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
