<template>
  <div class="columns small-12">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.DESC')"
    >
    </page-header>
    <div
      v-if="!ibxUiFlags.isCreating"
      class="settings-sub-menu-content--larger"
    >
      <woot-input
        v-model.trim="inboxName"
        required
        auto-focus
        full-width
        class="mg-bottom--slab"
        :class="{ error: $v.inboxName.$error }"
        :has-error="$v.inboxName.$error"
        :error="
          $t(
            'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.INBOX_NAME.ERROR'
          )
        "
        :label="
          $t(
            'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.INBOX_NAME.LABEL'
          )
        "
        :placeholder="
          $t(
            'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.INBOX_NAME.PLACEHOLDER'
          )
        "
        @blur="$v.inboxName.$touch"
      />
      <woot-input
        v-model.trim="phoneNumber"
        required
        full-width
        class="content-margin"
        :class="{ error: $v.phoneNumber.$error }"
        :has-error="$v.phoneNumber.$error"
        :error="
          $t(
            'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.PHONE_NUMBER.ERROR'
          )
        "
        :label="
          $t(
            'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.PHONE_NUMBER.LABEL'
          )
        "
        :placeholder="
          $t(
            'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.PHONE_NUMBER.PLACEHOLDER'
          )
        "
        @blur="$v.phoneNumber.$touch"
      />
    </div>
    <loading-state
      v-else
      class="loader-cmpt"
      :message="
        $t('INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.LOADING')
      "
    />
    <div class="medium-12 columns settings-sub-menu-bottom-nav">
      <woot-base-button
        variant="secondary"
        @click.prevent="() => $router.go(-1)"
      >
        Back
      </woot-base-button>
      <woot-base-button :loading="ibxUiFlags.isCreating" @click="createChannel">
        Next
      </woot-base-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { isValidPhoneNumber } from 'libphonenumber-js';

import PageHeader from '../../SettingsSubPageHeader';
import LoadingState from 'dashboard/components/ui/LoadingState';
import router from 'dashboard/routes/index';

import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: { PageHeader, LoadingState },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return { inboxName: '', phoneNumber: '' };
  },
  computed: {
    ...mapGetters({
      ibxUiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    inboxName: { required },
    phoneNumber: {
      checkValidPhoneNumber(value) {
        return isValidPhoneNumber('+' + value);
      },
      required,
    },
  },
  methods: {
    createChannel() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.showAlert(
          this.$t('ONBOARDING.WHATSAPP.FORM.VALIDATION_ERROR'),
          'error'
        );
        return;
      }

      const payload = {
        name: this.inboxName,
        phone_number: this.phoneNumber,
      };

      this.$store
        .dispatch('inboxes/createThreeSixtyDialogChannel', payload)
        .then(response => {
          const {
            id: inbox_id,
            channel: { channel_type },
            name: channel_name,
          } = response;

          // should be removed when we start receiving all the data in the response of this api call

          this.$store.dispatch('inboxes/get');

          this.mix_panel_clicked_create_inbox_successful(
            'WhatsApp',
            channel_type
          );
          router.replace({
            name: 'settings_inboxes_add_agents',
            params: {
              page: 'new',
              inbox_id,
            },
            query: { channel_type, channel_name },
          });
        })
        .catch(error => {
          this.showAlert(
            error.response.data.error ||
              this.$t('INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.API.ERROR_MESSAGE'),
            'error',
            5000
          );
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.loader-cmpt {
  height: 72vh;
}
</style>
