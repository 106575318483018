export default {
  methods: {
    truncateFilename(str, limit) {
      if (str.length <= limit) {
        return str;
      }
      let start = str.substring(0, limit);
      let end = str.replace(/^.*(?=\.[^.]+$)/g, '..');
      return start + end;
    },
    fileName(url) {
      if (!url) return '';

      const filename = url.substring(url.lastIndexOf('/') + 1);
      return decodeURI(filename);
    },
    fileType(url) {
      if (!url) return '';

      return url.substring(url.lastIndexOf('.') + 1);
    },
    isMp4(url) {
      if (this.fileType(url) === 'mp4') return true;
      return false;
    },
    async onDownload(url) {
      const image = await fetch(url + '?not-from-cache-please');
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);
      let exportedFilename = 'LimeChat-' + new Date().toLocaleDateString();
      let link = document.createElement('a');
      if (link.download !== undefined) {
        link.setAttribute('href', imageURL);
        link.setAttribute('download', exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
};
