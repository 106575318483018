import * as types from '../../mutation-types';
import actions from './actions';

const state = {
  uiFlags: { isImprovingText: false },
};

const getters = {
  getUiFlags({ uiFlags }) {
    return uiFlags;
  },
};

// mutations
export const mutations = {
  [types.default.IMPROVING_TEXT_UI_FLAG](_state, flagObject) {
    _state.uiFlags = { ...flagObject };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
