<template>
  <div>
    <v-tour :name="tourName" :steps="steps" :options="tourOptions">
      <!-- eslint-disable-next-line vue/no-template-shadow -->
      <template slot-scope="tour">
        <div v-if="tour.currentStep !== -1" class="overlay app-wrapper" />
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :highlight="tour.highlight"
          >
            <div slot="header">
              <div
                v-if="steps[tour.currentStep].header"
                class="v-step__header flex-space-between"
              >
                <div
                  v-if="steps[tour.currentStep].header.title"
                  v-html="stepHeader"
                ></div>
                <span style="pointer-events:none" class="muted-button">
                  STEP {{ tour.steps[tour.currentStep].fakeStepNumber }}/{{
                    tour.steps[tour.currentStep].fakeTotalSteps
                  }}
                </span>
              </div>
            </div>
            <div
              slot="actions"
              :style="[
                tour.isLast
                  ? { 'justify-content': 'flex-end' }
                  : { 'justify-content': 'space-between' },
              ]"
              class="buttons-container"
            >
              <template
                v-if="
                  tour.steps[tour.currentStep].fakeStepNumber !==
                    tour.steps[tour.currentStep].fakeTotalSteps
                "
              >
                <span
                  v-if="!tour.isLast"
                  class="muted-button"
                  @click="tourSkip"
                >
                  SKIP STEP
                </span>
              </template>
              <template v-else>
                <span
                  v-if="!tour.isLast"
                  class="muted-button"
                  @click="tourStop"
                >
                  SKIP STEP
                </span>
              </template>

              <div class="tour-navigation flex-row-justify-center">
                <woot-secondary-button
                  v-if="!tour.isFirst"
                  custom-class="tour-button"
                  name="PREVIOUS"
                  @click="customPrevStep"
                />
                <woot-primary-button
                  v-if="!tour.isLast"
                  custom-class="tour-button"
                  name="NEXT"
                  @click="customNextStep"
                />
                <woot-primary-button
                  v-else
                  class="tour-button"
                  name="FINISH"
                  @click="tourStop"
                />
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OnboardingTour',
  props: {
    tourName: {
      type: String,
      default: '',
    },
    tourVersion: {
      type: String,
      default: '',
    },
    tourSteps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish',
        },
      },
      steps: this.assignStepNumbers(this.tourSteps),
      previousFakeStep: 1,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    stepHeader() {
      return this.tour.steps[this.tour.currentStep].header.title;
    },
    tour() {
      return this.$tours[this.tourName];
    },
  },
  methods: {
    tourSkip() {
      this.updateTourVersion();
      this.tour.steps.slice(this.tour.currentStep + 1).find(el => {
        if (this.previousFakeStep !== el.fakeStepNumber) {
          this.previousFakeStep = el.fakeStepNumber;
          this.tour.start(el.stepNumber);
          return true;
        }
        this.previousFakeStep = el.fakeStepNumber;
        return false;
      });
    },
    updatePreviousFakeStep() {
      this.previousFakeStep = this.tour.steps[
        this.tour.currentStep
      ].fakeStepNumber;
    },
    async customNextStep() {
      this.tour.direction = 'FORWARD';
      await this.tour.nextStep();
      await this.updatePreviousFakeStep();
    },
    async customPrevStep() {
      this.tour.direction = 'BACKWARDS';
      await this.tour.previousStep();
      await this.updatePreviousFakeStep();
    },
    updateTourVersion() {
      // update tour version in local storage so that it would run only once for the new version
      localStorage.setItem(this.tourName, this.tourVersion);
    },
    tourStop() {
      this.updateTourVersion();
      this.tour.stop();
      this.$emit('unmountTour');
    },
    assignStepNumbers(tourSteps) {
      let counter = 0;
      let updatedTourSteps = Object.keys(tourSteps).reduce(
        (accumulator, key) => {
          // eslint-disable-next-line no-plusplus
          tourSteps[key].stepNumber = counter++;
          accumulator.push(tourSteps[key]);
          return accumulator;
        },
        []
      );
      return updatedTourSteps;
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: $zero;
}

.overlay {
  position: absolute;
  top: $zero;
  left: $zero;
  background: rgba(0, 0, 0, 0.246) !important;
  z-index: 1001;
  animation: fadein 0.8s cubic-bezier(0, 0, 0.2, 1);
}

.v-tour__target--highlighted {
  pointer-events: none !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.14) !important;
  z-index: 9999 !important;
  background-color: $color-white;
  padding: $space-one !important;
  border-radius: $border-radius !important;
}

.v-step {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.14) !important;
  background: $color-white !important;
  color: $neutral-grey-800 !important;
  padding: $space-medium !important;
  border-radius: $border-radius !important;
  max-width: $space-jumbo * 6 !important;
  min-width: $space-jumbo * 6 !important;

  .v-step__header {
    background: $color-white !important;
    color: $secondary-blue !important;
    font-size: $font-size-medium !important;
    font-weight: $font-weight-black !important;
    padding: $zero !important;
    margin: $zero $zero $space-slab $zero !important;
    text-align: left !important;
  }
  .v-step__content {
    text-align: left;
    font-size: $font-size-small !important;
    margin-bottom: $space-two !important;
  }
  .v-step__arrow,
  .v-step__arrow:before {
    background: $color-white !important;
  }

  .muted-button {
    cursor: pointer;
    color: $neutral-grey-600;
    font-size: $font-size-small;
    font-weight: $font-weight-black;
    &:hover {
      color: $neutral-grey-700;
    }
  }

  .buttons-container {
    margin-top: $space-one;
    display: flex;
    align-items: center;

    .tour-navigation {
      .tour-button {
        width: $space-mega;
        margin-left: $space-smaller;
      }
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
