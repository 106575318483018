<template>
  <div class="bottom-container lime-card-new">
    <div class="skeleton-animation heading-skeleton"></div>
    <div class="flex-row-justify-center margin-container">
      <div v-for="index in 4" :key="index" class="header-skeleton-margin">
        <div class="skeleton-animation header-skeleton"></div>
      </div>
    </div>
    <div class="skeleton-animation" :style="{ height: chartHeight }"></div>
  </div>
</template>

<script>
export default {
  props: {
    chartHeight: {
      type: String,
      default: '25rem',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.margin-container {
  margin: $space-large $space-micro;
  justify-content: left;
}

.header-skeleton-margin {
  margin-right: $space-medium;
  width: 15%;
}

.header-skeleton {
  height: 1.5rem;
}

.heading-skeleton {
  height: 3.5rem;
  width: 50%;
}
</style>
