<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box">
      <woot-modal-header
        :header-title="`Profile - ${contact.name || contact.email}`"
      />
      <div class="edit-contact--form">
        <div class="row">
          <div class="medium-9 columns">
            <label v-if="name">
              {{ $t('EDIT_CONTACT.FORM.NAME.LABEL') }}
              <span>{{ name }}</span>
            </label>

            <label v-if="email">
              {{ $t('EDIT_CONTACT.FORM.EMAIL_ADDRESS.LABEL') }}
              <span>{{ email }}</span>
            </label>
          </div>
        </div>
        <div v-if="description" class="medium-12 columns">
          <label>
            {{ $t('EDIT_CONTACT.FORM.BIO.LABEL') }}
            <span>{{ description }}</span>
          </label>
        </div>
        <div v-if="phoneNumber" class="row">
          <span class="medium-6 columns">{{ phoneNumber }}</span>
        </div>
        <span v-if="companyName" class="medium-6 columns">{{
          companyName
        }}</span>
        <div class="medium-12 columns">
          <label>
            Social Profiles
          </label>
          <div
            v-for="socialProfile in socialProfileKeys"
            :key="socialProfile.key"
            class="input-group"
          >
            <span class="input-group-label">{{ socialProfile.prefixURL }}</span>
            <span class="input-group-field">{{
              socialProfileUserNames[socialProfile.key]
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hasADuplicateContact: false,
      duplicateContact: {},
      companyName: '',
      description: '',
      email: '',
      name: '',
      phoneNumber: '',
      socialProfileUserNames: {
        facebook: '',
        twitter: '',
        linkedin: '',
      },
      socialProfileKeys: [
        { key: 'facebook', prefixURL: 'https://facebook.com/' },
        { key: 'twitter', prefixURL: 'https://twitter.com/' },
        { key: 'linkedin', prefixURL: 'https://linkedin.com/' },
      ],
    };
  },
  validations: {
    name: {
      required,
    },
    description: {},
    email: {},
    companyName: {},
    phoneNumber: {},
    bio: {},
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
  },
  watch: {
    contact() {
      this.setContactObject();
    },
  },
  mounted() {
    this.setContactObject();
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    setContactObject() {
      const { email: email, phone_number: phoneNumber, name } = this.contact;
      const additionalAttributes = this.contact.additional_attributes || {};

      this.name = name || '';
      this.email = email || '';
      this.phoneNumber = phoneNumber || '';
      this.companyName = additionalAttributes.company_name || '';
      this.description = additionalAttributes.description || '';
      const {
        social_profiles: socialProfiles = {},
        screen_name: twitterScreenName,
      } = additionalAttributes;
      this.socialProfileUserNames = {
        twitter: socialProfiles.twitter || twitterScreenName || '',
        facebook: socialProfiles.facebook || '',
        linkedin: socialProfiles.linkedin || '',
      };
    },
    getContactObject() {
      return {
        id: this.contact.id,
        name: this.name,
        email: this.email,
        phone_number: this.phoneNumber,
        additional_attributes: {
          ...this.contact.additional_attributes,
          description: this.description,
          company_name: this.companyName,
          social_profiles: this.socialProfileUserNames,
        },
      };
    },
    resetDuplicate() {
      this.hasADuplicateContact = false;
      this.duplicateContact = {};
    },
  },
};
</script>

<style scoped lang="scss">
.edit-contact--form {
  padding: var(--space-normal) var(--space-large) var(--space-large);

  .columns {
    padding: 0 var(--space-smaller);
  }
}
</style>
