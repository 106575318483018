<template>
  <div class="custom-scroll">
    <woot-modal :show.sync="downloading" :on-close="hidePopup">
      <div class="download-note">
        <img
          src="https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
        />
        <div
          v-html="$t('ANALYTICS.DOWNLOAD_REPORT.EMAIL', { email: userEmail })"
        />
      </div>
    </woot-modal>
    <woot-modal :show.sync="downloadError" :on-close="hidePopup">
      <div class="download-note">
        <div>{{ $t('ANALYTICS.DOWNLOAD_REPORT.REPORT_FAIL') }}</div>
      </div>
    </woot-modal>
    <div class="column content-box settings-box">
      <div class="settings-container columns">
        <div class="flex-space-between">
          <div class="flex-container medium-flex-dir-row">
            <div class="mr-right">
              <report-date-range-selector
                :default-selection="0"
                @date-range-change="onDateRangeChange"
              />
            </div>
            <div class="mr-right">
              <woot-single-selector
                variant="secondary"
                size="medium"
                :option-list="timingList"
                :default-option="selectedTiming"
                :show-selected-text="false"
                :custom-style="{ width: 'auto' }"
                @click="onTimingChange"
              />
            </div>
            <div class="mr-right">
              <inbox-filter
                value="All Inboxes"
                :allow-multi-select="true"
                :exclude-inbox-types="excludedInboxTypes"
                :toggle-filter="changeInboxId"
              />
            </div>
          </div>
          <woot-base-button front-icon="email" @click="download">
            CSAT Report
          </woot-base-button>
        </div>
        <csat-metrics />
        <csat-table />
      </div>
    </div>
  </div>
</template>

<script>
import CsatMetrics from './CsatMetrics';
import CsatTable from './CsatTable';
import ReportDateRangeSelector from '../../components/DateRangeSelector';
import InboxFilter from 'dashboard/components/widgets/conversation/filter/InboxFilter';

import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import downloadMixin from 'shared/mixins/downloadMixin';
import timeMixin from 'dashboard/mixins/time';

import * as types from 'shared/constants/googleEventType';
import { getUserEmail } from 'dashboard/helper/cookieHelper';
import { convertParamValueToString } from 'dashboard/routes/dashboard/analytics/shared/helpers';

import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

export default {
  name: 'BotCsatResponses',
  components: {
    CsatMetrics,
    CsatTable,
    ReportDateRangeSelector,
    InboxFilter,
  },
  mixins: [timeMixin, googleAnalyticsMixin, downloadMixin],
  data() {
    return {
      from: 0,
      to: 0,
      timing: 0,
      selectedInboxes: [],
      downloading: false,
      downloadError: false,
    };
  },
  computed: {
    excludedInboxTypes() {
      return [INBOX_TYPES.KNOWLARITY];
    },
    timingList() {
      return this.$t('ANALYTICS.FILTER_SELECTION.WORKING_HOURS').map(
        (item, index) => {
          return {
            id: index,
            name: item,
          };
        }
      );
    },
    selectedTiming() {
      return this.timingList[this.timing].name;
    },
    userEmail() {
      return getUserEmail();
    },
  },
  methods: {
    changeInboxId(_, __, id) {
      this.selectedInboxes = id;
      this.getData();
    },
    onTimingChange(timing) {
      this.googleAnalyticsEvent(
        types.default.CSAT_ON_TIMING_CHANGE,
        types.default.CSAT_ANALYTICS,
        timing.id
      );
      this.timing = timing.id;
      this.getData();
    },
    getData() {
      this.googleAnalyticsEvent(
        types.default.CSAT_ANALYTICS_APPLY_OR_MOUNT,
        types.default.CSAT_ANALYTICS,
        types.default.CSAT_ANALYTICS
      );
      this.$store.dispatch('csat/getBotCsatMetrics', {
        startDate: this.formatDate(this.from * 1000).replaceAll('-', '/'),
        endDate: this.formatDate(this.to * 1000).replaceAll('-', '/'),
        inboxes: this.selectedInboxes,
        workingHours: convertParamValueToString('workingHours', this.timing),
      });
    },
    async download() {
      this.downloading = true;
      this.googleAnalyticsEvent(
        types.default.CSAT_DOWNLOAD,
        types.default.CSAT_ANALYTICS,
        types.default.CSAT_ANALYTICS
      );
      await this.$store
        .dispatch('csat/download', {
          startDate: this.formatDate(this.from * 1000).replaceAll('-', '/'),
          endDate: this.formatDate(this.to * 1000).replaceAll('-', '/'),
          inboxes: this.selectedInboxes,
          email: this.userEmail,
        })
        .catch(() => {
          this.downloading = false;
          this.downloadError = true;
        });
    },
    onDateRangeChange({ from, to }) {
      this.googleAnalyticsEvent(
        types.default.CSAT_ANALYTICS_ONDATERANGECHANGE,
        from,
        to
      );
      this.from = from;
      this.to = to;
      this.getData();
    },
    hidePopup() {
      this.downloading = false;
      this.downloadError = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.selector-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  align-items: center;
  color: $neutral-grey-800;
  font-size: $font-size-small;
  line-height: 22px;
  background: $neutral-white;
  border: 1px solid $pg-1-500;
  cursor: pointer;
  border-radius: $border-radius;
  padding: $space-slab;
  margin-left: $space-normal;
}

.filter-container {
  margin-top: $space-slab;
  margin-left: $space-smaller;
  align-items: center;
  justify-content: flex-start;
}
.mr-right {
  margin-right: $space-normal;
}
</style>
