<template>
  <woot-modal :show="show" :on-close="() => $emit('onClose')">
    <woot-modal-header
      :header-title="`Edit Variable {{${currentSelectedVariableIndex + 1}}}`"
    />
    <form class="substitute-variables" @submit.prevent="$emit('onClose')">
      <woot-input
        v-for="(value, index) in substitutions[currentSelectedVariableIndex]"
        :key="index"
        required
        size="small"
        :auto-focus="index === 0"
        :value="value"
        :label="`Customer ${index + 1}`"
        placeholder="Input substitution value for the customer"
        class="mg-bottom--slab"
        @input="$emit('input', $event, index)"
      />
      <div class="modal-footer">
        <woot-base-button type="submit">
          Save
        </woot-base-button>
      </div>
    </form>
  </woot-modal>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    substitutions: {
      type: Array,
      default: () => [],
    },
    currentSelectedVariableIndex: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.substitute-variables {
  padding: 0 $space-medium $space-medium;
}
</style>
