<template>
  <div class="scroll--panel custom-scroll">
    <search-box
      :placeholder="$t('PRODUCTS_MGMT.SEARCH_TXT')"
      :value="search"
      :variant="true"
      :on-search="searchProducts"
      @setSearch="setSearch"
    />
    <div
      v-if="!uiFlags.fetchingProducts && !productList.length"
      class="no-order"
    >
      <img
        :src="require('dashboard/assets/images/noOrder.svg')"
        alt="No Product"
      />
      <p class="no-items-error-message">
        No products found.
      </p>
    </div>
    <div>
      <accordian-card
        v-for="product in filteredProductList"
        :key="product.id"
        :custom-desc="true"
      >
        <template v-slot:desc>
          <div class="item-wrap">
            <div class="card__media lime-card-1dp">
              <img :src="product.Image" alt="Card image" />
            </div>
            <div class="card__content">
              <header
                v-tooltip="product.name"
                class="card__header text-truncate"
              >
                {{ product.name }}
              </header>
              <footer class="card__meta flex-space-between" role="contentinfo">
                <div class="card__price">
                  <span
                    v-if="product.sale_price < product.price"
                    v-html="`${accountCurrency} ${product.sale_price}`"
                  >
                  </span>
                  <span
                    :class="{
                      'sale-price': product.sale_price < product.price,
                    }"
                    v-html="`${accountCurrency} ${product.price}`"
                  >
                  </span>
                </div>
              </footer>
              <span
                v-if="
                  product.inventory < 1 &&
                    !['not_managed', 'instock'].includes(
                      product.inventory_management
                    )
                "
                class="out-of-stock title-h6"
                v-text="$t('PRODUCTS_MGMT.OUT_OF_STOCK')"
              />
            </div>
          </div>
        </template>
        <template v-slot:close></template>
        <p
          v-if="product.description"
          class="card__excerpt custom-scroll"
          v-html="product.description"
        />
        <div class="form-button">
          <woot-secondary-button
            size="small"
            :custom-style="{
              'margin-left': '0.4rem',
              width: '60%',
              'justify-content': 'center',
            }"
            :name="$t('PRODUCTS_MGMT.SHARE')"
            @click="openSharePopup(product)"
          />
        </div>
      </accordian-card>
      <woot-loading-state
        v-if="uiFlags.fetchingProducts"
        :message="'Fetching products...'"
      />
      <p
        v-if="!uiFlags.fetchingProducts && productList.length && hasNextPage"
        class="clear button load-more-conversations"
        @click="onPageChange()"
      >
        {{ 'Load More Products' }}
      </p>
      <p
        v-if="!uiFlags.fetchingProducts && productList.length && !hasNextPage"
        class="text-center text-muted end-of-list-text"
      >
        {{ 'All Products Loaded' }}
      </p>
    </div>

    <woot-delete-modal
      :show.sync="showShareModal"
      :on-close="closeSharePopup"
      :on-confirm="shareProduct"
      :title="$t('PRODUCTS_MGMT.SHARE')"
      :message="shareMessage"
      :confirm-text="'Share'"
      :reject-text="'Cancel'"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccordianCard from 'dashboard/components/AccordianCard';
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';
import accountMixin from 'dashboard/mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
import * as types from 'shared/constants/googleEventType';

export default {
  components: {
    AccordianCard,
    SearchBox,
  },
  mixins: [
    googleAnalyticsMixin,
    accountMixin,
    alertMixin,
    mixPanelAnalyticsMixin,
  ],
  data() {
    return {
      search: '',
      page: 1,
      showShareModal: false,
      selectedProduct: {},
    };
  },
  computed: {
    ...mapGetters({
      productList: 'getProducts',
      meta: 'getProductsMeta',
      uiFlags: 'getProductsUIFlags',
      currentChat: 'getSelectedChat',
    }),
    shareMessage() {
      return `Share Product: "${this.selectedProduct?.name}" with ${this.currentChat.meta?.sender?.name}`;
    },
    hasNextPage() {
      const isDisabled = this.page === Math.ceil(this.meta.count / 8);
      return !isDisabled;
    },
    filteredProductList() {
      return this.productList.filter(item => item.price > 0.0);
    },
    accountCurrency() {
      return this.$store.getters['accounts/getAccountCurrency'](this.accountId);
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentChat.inbox_id);
    },
  },
  mounted() {
    this.$store.dispatch('getProducts', {
      page: 1,
    });
  },
  methods: {
    setSearch(value) {
      const refetchAllproducts = !!this.search && value === '';
      if (refetchAllproducts) {
        this.$store.dispatch('getProducts', {
          page: 1,
        });
      }
      this.search = value;
    },
    searchProducts() {
      if (this.search) {
        this.googleAnalyticsEvent(
          types.default.PRODUCTS_SEARCH,
          types.default.PRODUCTS_PANEL,
          this.search
        );
        this.$store.dispatch('getProducts', {
          query: this.search,
          page: 1,
        });
      }
    },
    async shareProduct() {
      this.mix_panel_shared_ticket_product();
      if (!this.currentChat.can_reply) {
        this.showAlert(
          this.$t('CONVERSATION.REPLY_WINDOW', { duration: '24 hours' }),
          'error'
        );
        this.closeSharePopup();
        return;
      }

      try {
        let desc = '';
        let actions = [];
        if (this.selectedProduct.description) {
          desc = this.selectedProduct.description.replace(/(<([^>]+)>)/gi, '');
          desc = desc.substr(0, 200) + '...';
        }
        if (this.selectedProduct.product_link) {
          if (this.inbox.channel_type === INBOX_TYPES.FB) {
            actions.push({
              text: 'Show Product',
              url: this.selectedProduct.product_link,
            });
          } else {
            desc += `\n\nFollow this link to the product:\n${this.selectedProduct.product_link}`;
          }
        }

        const messagePayload = {
          conversationId: this.currentChat.id,
          contentType: 'cards',
          contentAttributes: {
            items: [
              {
                title: `${this.selectedProduct.name} ${this.accountCurrency} ${this.selectedProduct.sale_price}`,
                description: desc,
                media_url: this.selectedProduct.Image,
                actions,
              },
            ],
          },
        };

        await this.$store.dispatch('sendMessage', messagePayload);
        bus.$emit('scrollToMessage');
      } catch (error) {
        // handle error
      }
      this.showShareModal = false;
    },
    closeSharePopup() {
      this.showShareModal = false;
    },
    openSharePopup(product) {
      this.selectedProduct = product;
      this.showShareModal = true;
    },
    onPageChange() {
      this.page += 1;
      if (this.search) {
        this.$store.dispatch('getProducts', {
          query: this.search,
          page: this.page,
          append: true,
        });
      } else {
        this.$store.dispatch('getProducts', {
          page: this.page,
          append: true,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.item-wrap {
  display: flex;
  flex-direction: row;
  width: 90%;

  .card__media {
    position: relative;
    width: 6rem;
    height: 6rem;
    min-width: 6rem;
    padding: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      padding: 0;
      border-radius: $border-radius;
    }
  }

  .card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 0 $space-small;
    text-overflow: ellipsis;
    overflow: hidden;

    .card__header {
      font-size: $font-size-default;
      line-height: $space-medium;
      color: $neutral-grey-800;
    }

    .out-of-stock {
      color: $warn-red-400;
      text-transform: uppercase;
    }

    .card__meta {
      border-bottom: 0;

      .card__price {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $neutral-grey-800;
        font-size: $font-size-default;
        line-height: $space-medium;

        .sale-price {
          font-size: $font-size-small;
          margin-left: $space-small;
          color: $neutral-grey-600;
          text-decoration: line-through;
          text-decoration-color: $warn-red-500;
        }
      }
    }
  }
}

.flex-space-between {
  .view-button {
    cursor: pointer;
    padding: $space-slab;
    margin-bottom: $space-slab;
  }
}

.form-button {
  margin-top: $space-medium;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__excerpt {
  padding-top: $space-slab;
  border-top: 1px solid $neutral-grey-400;
  position: relative;
  margin-top: $space-slab;
  font-size: $font-size-small;
  line-height: 22px;
  color: $neutral-grey-600;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 40vh;
  text-align: start;
  word-break: break-word;
  margin-bottom: $space-normal;

  &::after {
    content: '-';
    opacity: 0;
  }
}

.card__content::v-deep a {
  color: $pg-1-500;
}

.card__content::v-deep strong {
  color: $neutral-grey-800;
  font-size: $font-size-medium;
}

.card__content::v-deep img {
  width: 100%;
  margin-bottom: $space-small;
}
.no-order {
  margin-top: 10rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 12rem;
    margin-bottom: $space-large;
  }

  p {
    color: $secondary-blue;
    font-size: $font-size-default;
    line-height: $space-medium;
  }
}
.load-more-conversations {
  border: 0;
  color: $pg-1-500;
  font-size: $font-size-small;
  margin: 0;
  padding: $space-normal;
  width: 100%;
}
.end-of-list-text {
  padding: $space-normal;
}
.loading-state {
  padding: $zero;
}
</style>
