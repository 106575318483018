/* global axios */
import ApiClient from './ApiClient';

class ContactAPI extends ApiClient {
  constructor() {
    super('contacts', { accountScoped: true });
  }

  get(page, opt_outs, only_phones) {
    return axios.get(
      `${this.url}?page=${page}&opt_outs=${opt_outs}&only_phones=${only_phones}`
    );
  }

  getLastConversationId(contact_id) {
    return axios.get(`${this.url}/contact_last_conversation`, {
      params: { contact_id },
    });
  }

  getList(label_ids, page) {
    return axios.get(`${this.url}/listing?page=${page}&label_ids=${label_ids}`);
  }

  getOptedOutContacts(page) {
    return axios.get(
      `${this.url}?page=${page}&opt_outs=${true}&only_phones=${true}`
    );
  }

  getOrders({ contactId, cursor }) {
    return axios.get(`${this.baseUrl()}/contacts/${contactId}/orders`, {
      params: { cursor },
    });
  }

  markOptOut(contact) {
    return axios.post(`${this.baseUrl()}/contacts/opt_out`, contact);
  }

  getConversations(contactId) {
    return axios.get(`${this.url}/${contactId}/conversations`);
  }

  search(search = '', searchBy, label_ids, page = 1) {
    return axios.get(
      `${this.url}/search?q=${search}&search_by=${searchBy}&label_ids=${label_ids}&page=${page}`
    );
  }

  // send contacts to email
  send({ start_time, end_time, inbox_ids }) {
    return axios.get(`${this.url}/download`, {
      params: { start_time, end_time, inbox_ids },
    });
  }

  getLabels(contactId) {
    return axios.get(`${this.url}/${contactId}/labels`);
  }

  updateLabel(contactId, labelId, action) {
    return axios.post(
      `${this.url}/${contactId}/labels?label_id=${labelId}&action_to_perform=${action}`
    );
  }
}

export default new ContactAPI();
