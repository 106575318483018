<template>
  <div
    class="list-item"
    :class="{ draggable: isDraggable, active }"
    @click="onSelect"
  >
    <div class="flex-row gap--slab">
      <span v-if="isDraggable" class="drag-icon">
        <icons name="drag" color="lightgrey-fill-only" size="mediumlarge" />
      </span>
      <div class="flex-column">
        <div class="title-h5 text-dark" v-text="data.name" />
        <div
          class="body-b3 text-light line-clamp-1"
          v-text="data.description"
        />
      </div>
    </div>
    <div class="actions">
      <toggle-sla :data="data" @toggle="onToggle" />
      <woot-base-button
        tag="span"
        size="small"
        variant="tertiary"
        @click="onEdit"
      >
        <icons name="editRound" size="medium" view="0 0 24 24" color="grey" />
      </woot-base-button>
      <woot-single-selector
        variant="tertiary"
        :custom-style="{
          borderRadius: '0.8rem',
          boxShadow: 'none',
          left: 'auto',
          right: '0.8rem',
          width: 'auto',
        }"
        icon-size-prop="semimedium"
        icon="threeDot"
        :show-selected-text="false"
        :show-option-name="false"
        :option-list="getActionsList"
        @click="onActionSelected"
      />
    </div>
  </div>
</template>
<script>
import ToggleSla from '../shared/ToggleSLA';

export default {
  components: { ToggleSla },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getActionsList() {
      return [
        {
          name: 'Clone',
          value: 'clone',
        },
        {
          name: 'Delete',
          value: 'delete',
        },
      ];
    },
  },
  methods: {
    onToggle(data) {
      this.$emit('toggle', data);
    },
    onEdit() {
      this.onActionSelected({ value: 'edit' });
    },
    onSelect() {
      this.onActionSelected({ value: 'view' });
    },
    onActionSelected(action) {
      this.$emit(action.value, this.data);
    },
  },
};
</script>
