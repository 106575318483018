<template>
  <div class="view-box-new columns">
    <settings-header
      icon="graph"
      :header-title="$t('ANALYTICS.HEADER.ANALYTICS')"
      :show-back-button="false"
    />

    <last-updated-time
      scope="tickets"
      class="flex-row flex-justify--end pd-right"
    />

    <div class="filter-pane">
      <div class="flex-space-between" style="margin-top: 0.5rem">
        <div class="flex-row-justify-center analytics-filters">
          <date-range-selector
            :default-selection="2"
            @date-range-change="onDateRangeChange"
          />
          <inbox-filter
            :allow-multi-select="true"
            :value="$t('ANALYTICS.FILTER_SELECTION.INBOX.PLACEHOLDER')"
            :toggle-filter="changeInboxId"
            @selected-option="logSelectedInbox"
          />
          <tags-filter
            value="All Tags"
            :toggle-filter="changeLabelId"
            @selected-option="logSelectedTag"
          />
          <woot-single-selector
            :disabled="false"
            variant="secondary"
            size="medium"
            :default-option="selectedWorkingHour"
            :option-list="optionList"
            :show-selected-text="false"
            :custom-style="{ width: 'auto' }"
            @click="changeWorkingHourType"
          />
        </div>
        <woot-single-selector
          custom-button
          :option-list="reportTypeList"
          :show-selected-text="false"
          :custom-style="{ left: 0, width: '100%' }"
          @click="downloadReport"
        >
          <woot-base-button
            front-icon="email"
            back-icon="chevronDown"
            :loading="isDownloadingReport"
          >
            {{ 'GENERATE REPORT' }}
          </woot-base-button>
        </woot-single-selector>
      </div>
    </div>
    <div
      class="column padding-container settings-box custom-scroll"
      style="padding-top: 0px"
    >
      <div class="settings-container columns">
        <div v-if="metricCardUIFlag">
          <div class="metrics-card-container container-width">
            <metrics-card-skeleton
              v-for="(item, index) in metricsCount.slice(0, 5)"
              :key="index"
            />
          </div>
          <div class="metrics-card-container">
            <metrics-card-skeleton
              v-for="(item, index) in metricsCount.slice(-4)"
              :key="index"
            />
          </div>
        </div>
        <div v-else>
          <div class="metrics-card-container container-width">
            <metrics-card
              v-for="(item, index) in metricsCount.slice(0, 5)"
              :key="index"
              :title="item.title"
              :sub-title="item.sub"
              :unit="item.unit"
              :color="item.color"
              :actual="item.actual"
            />
          </div>
          <div class="metrics-card-container">
            <metrics-card
              v-for="(item, index) in metricsCount.slice(-4)"
              :key="index"
              :title="item.title"
              :sub-title="item.sub"
              :unit="item.unit"
              :color="item.color"
              :actual="item.actual"
            >
              <div
                v-if="item.extra"
                class="title-h5_m text-light extra-info"
                v-html="item.extra"
              />
            </metrics-card>
          </div>
        </div>

        <div v-if="lineChartUIFlag">
          <line-chart-skeleton chart-height="17rem" />
        </div>
        <div v-else>
          <div class="lime-card-1dp bottom-container">
            <div style="position: relative">
              <span class="table-title">
                {{ $t('ANALYTICS.TICKET_OVERVIEW.AGENT_STATES') }}
              </span>
              <span class="tooltip-position">
                <icons name="info" size="medium" color="grey" />
              </span>
              <div class="line-chart-legend title-h5">
                <div class="green-icon"></div>
                {{ $t('ANALYTICS.TICKET_OVERVIEW.TOTAL') }}
              </div>
              <line-chart
                chart-height="230"
                :series="chartSeries"
                :categories="chartCategories"
                :colors="chartColors"
                :show-marker="false"
                :toggle-data="false"
                :highlight-data="false"
                :show-legend="false"
                :toolbar-enable="false"
              />
            </div>
          </div>
        </div>
        <div v-if="handoffTrendUIFlag">
          <line-chart-skeleton />
        </div>
        <div v-else class="medium-12 bottom-container">
          <div class="lime-card-1dp medium-12">
            <div style="position: relative">
              <span class="table-title">
                {{ $t('ANALYTICS.TICKET_OVERVIEW.AGENT_HANDOFF') }}
              </span>
              <span class="tooltip-position">
                <icons name="info" size="medium" color="grey" />
              </span>
            </div>
            <line-chart
              class="agentHandoffChart"
              style="margin-left: 1rem;"
              :series="handOffChartSeries"
              :categories="handOffChartCategories"
              :colors="handOffChartColors"
              :toolbar-enable="false"
            />
          </div>
        </div>
        <woot-modal :show.sync="isDownloadingReport" :on-close="hidePopup">
          <div class="download-note">
            <img
              src="https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
            />
            <div
              v-html="
                $t('ANALYTICS.DOWNLOAD_REPORT.EMAIL', { email: userEmail })
              "
            />
          </div>
        </woot-modal>
        <woot-modal :show.sync="downloadError" :on-close="hidePopup">
          <div class="download-note">
            <div>{{ $t('ANALYTICS.DOWNLOAD_REPORT.REPORT_FAIL') }}</div>
          </div>
        </woot-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MetricsCard from './components/MetricsCard';
import LineChart from 'dashboard/components/widgets/chart/LineChart';
import SettingsHeader from '../settings/SettingsHeader';
import DateRangeSelector from './components/DateRangeSelector';
import LineChartSkeleton from 'dashboard/components/LineChartSkeleton';
import MetricsCardSkeleton from './components/MetricsCardSkeleton';
import LastUpdatedTime from './components/LastUpdatedTime.vue';
import InboxFilter from 'dashboard/components/widgets/conversation/filter/InboxFilter';
import TagsFilter from 'dashboard/components/widgets/conversation/filter/TagsFilter';

import inboxMixin from 'dashboard/mixins/inbox';
import timeMixin from 'dashboard/mixins/time';
import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import { getUserEmail } from 'dashboard/helper/cookieHelper';

export default {
  components: {
    SettingsHeader,
    MetricsCard,
    LineChart,
    DateRangeSelector,
    LineChartSkeleton,
    MetricsCardSkeleton,
    LastUpdatedTime,
    InboxFilter,
    TagsFilter,
  },
  mixins: [inboxMixin, alertMixin, timeMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      dateRange: '1',
      startDate: this.formatDate(new Date().getTime()).replaceAll('-', '/'),
      endDate: this.formatDate(new Date().getTime()).replaceAll('-', '/'),
      conversationMetrics: null,
      slaMetrics: { violation_percentage: 0 },
      isFetchingSlaMetrics: false,
      convPieChartData: null,
      convLineGraphData: null,
      convTableData: null,
      agentBotHandoffLineChart: null,
      report_type: 'download_overall_report',
      working_hour_type: [
        {
          NAME: 'All Time',
          KEY: 'all',
        },
        {
          NAME: 'Outside Working Hours',
          KEY: 'outside',
        },
        {
          NAME: 'Inside Working Hours',
          KEY: 'inside',
        },
      ],
      isActive: [false],
      selectedInboxes: [],
      selectedLabels: [],
      selectedWorkingHourType: 'all',
      isDownloadingReport: false,
      downloadError: false,
    };
  },
  computed: {
    ...mapGetters({
      metricCardUIFlag: 'getConversationMetricUIFlag',
      lineChartUIFlag: 'getConversationStateUIFlag',
      handoffTrendUIFlag: 'getConversationHandoffTrendUIFlag',
      inboxes: 'inboxes/getInboxes',
      accountLabels: 'labels/getLabelsWithoutGroups',
    }),
    inboxList() {
      return this.inboxes.map(inbox => ({
        id: inbox.id,
        name: inbox.name,
        status: inbox.status,
        type: inbox.channel_type,
        channel_id: inbox.channel_id,
        phoneNumber: inbox.phone_number,
        instagram_add_on: inbox.instagram_add_on,
        selected_agent: null,
        icon: this.computedInboxClass(inbox).name,
        color: this.computedInboxClass(inbox).color,
      }));
    },
    headerList() {
      return this.$t('ANALYTICS.ATTENDANCE_HEADER');
    },
    reportTypeList() {
      return this.$t(`DOWNLOAD.AGENT_REPORT_TYPE`).map(item => {
        return {
          key: item.KEY,
          name: item.NAME,
        };
      });
    },
    selectedReportType() {
      let selectedIndex = this.reportTypeList.findIndex(
        i => i.key === this.report_type
      );

      return this.$t(`DOWNLOAD.AGENT_REPORT_TYPE`)[selectedIndex].NAME;
    },
    optionList() {
      return this.working_hour_type.map(item => {
        return {
          key: item.KEY,
          name: item.NAME,
        };
      });
    },

    selectedWorkingHour() {
      let selectedIndex = this.optionList.findIndex(
        a => a.key === this.selectedWorkingHourType
      );
      return selectedIndex > 0
        ? this.optionList[selectedIndex].name
        : this.optionList[0].name;
    },
    metricsCount() {
      const violationPercentageText = this.isFetchingSlaMetrics
        ? '<span><em class="text-yellow">loading...</em></span> SLA Breached'
        : `<span><b class="text-yellow">${this.slaMetrics.violation_percentage}%</b></span> SLA Breached`;

      return [
        {
          title: this.conversationMetrics?.total_conv
            ? this.conversationMetrics?.total_conv
            : 0,
          sub: this.$t('ANALYTICS.TICKET_OVERVIEW.TOTAL_TICKETS'),
          unit: '',
          actual: this.$t('ANALYTICS.TOOLTIP.TICKET_OVERVIEW.TOTAL'),
        },
        {
          title: this.conversationMetrics?.marketing_tickets
            ? this.conversationMetrics?.marketing_tickets
            : 0,
          sub: this.$t('ANALYTICS.TICKET_OVERVIEW.MARKETING'),
          unit: '',
          actual: this.$t('ANALYTICS.TOOLTIP.TICKET_OVERVIEW.MARKETING'),
        },
        {
          title: this.conversationMetrics?.inbound
            ? this.conversationMetrics?.inbound
            : 0,
          sub: this.$t('ANALYTICS.TICKET_OVERVIEW.INBOUND'),
          unit: '',
          actual: this.$t('ANALYTICS.TOOLTIP.TICKET_OVERVIEW.INBOUND'),
        },
        {
          title: this.conversationMetrics?.bot
            ? this.conversationMetrics?.bot
            : 0,
          sub: this.$t('ANALYTICS.TICKET_OVERVIEW.BOT'),
          unit: '',
          actual: this.$t('ANALYTICS.TOOLTIP.TICKET_OVERVIEW.BOT'),
        },
        {
          title: this.conversationMetrics?.agent_tickets
            ? this.conversationMetrics?.agent_tickets
            : 0,
          sub: this.$t('ANALYTICS.TICKET_OVERVIEW.AGENT'),
          unit: '',
          actual: this.$t('ANALYTICS.TOOLTIP.TICKET_OVERVIEW.OPEN'),
        },
        {
          title: this.conversationMetrics?.resolved
            ? this.conversationMetrics?.resolved
            : 0,
          sub: this.$t('ANALYTICS.TICKET_OVERVIEW.RESOLVED'),
          unit: '',
          actual: this.$t('ANALYTICS.TOOLTIP.TICKET_OVERVIEW.RESOLVED'),
        },
        {
          title: this.conversationMetrics?.closed
            ? this.conversationMetrics?.closed
            : 0,
          sub: this.$t('ANALYTICS.TICKET_OVERVIEW.CLOSED'),
          unit: '',
          actual: this.$t('ANALYTICS.TOOLTIP.TICKET_OVERVIEW.CLOSED'),
          extra: violationPercentageText,
        },
        {
          title: this.conversationMetrics?.followup
            ? this.conversationMetrics?.followup
            : 0,
          sub: this.$t('ANALYTICS.TICKET_OVERVIEW.FOLLOWUP'),
          unit: '',
          actual: this.$t('ANALYTICS.TOOLTIP.TICKET_OVERVIEW.FOLLOWUP'),
        },
        {
          title: this.conversationMetrics?.waiting
            ? this.conversationMetrics?.waiting
            : 0,
          sub: this.$t('ANALYTICS.TICKET_OVERVIEW.WAITING'),
          unit: '',
          actual: this.$t('ANALYTICS.TOOLTIP.TICKET_OVERVIEW.WAITING'),
        },
      ];
    },
    chartSeries() {
      let formattedSeries = [
        {
          name: this.$t('ANALYTICS.TICKET_OVERVIEW.TOTAL_TICKETS'),
          data: this.convLineGraphData?.series.filter(
            item => item.name === 'total_conv'
          )[0].data,
        },
        {
          name: this.$t('ANALYTICS.TICKET_OVERVIEW.MARKETING'),
          data: this.convLineGraphData?.series.filter(
            item => item.name === 'marketing_tickets'
          )[0].data,
        },
        {
          name: this.$t('ANALYTICS.TICKET_OVERVIEW.INBOUND'),
          data: this.convLineGraphData?.series.filter(
            item => item.name === 'inbound'
          )[0].data,
        },
        {
          name: this.$t('ANALYTICS.TICKET_OVERVIEW.RESOLVED'),
          data: this.convLineGraphData?.series.filter(
            item => item.name === 'resolved'
          )[0].data,
        },

        {
          name: this.$t('ANALYTICS.TICKET_OVERVIEW.BOT'),
          data: this.convLineGraphData?.series.filter(
            item => item.name === 'bot'
          )[0].data,
        },
        // outbound if included in the same graph will cause the y axis to grow very large as compared to other metrics, we should make a separate graph for outbound tickets only
        // {
        //   name: this.$t('ANALYTICS.TICKET_OVERVIEW.OUTBOUND'),
        //   data: this.convLineGraphData?.series.filter(
        //     item => item.name === 'outbound'
        //   )[0].data,
        // },
        {
          name: this.$t('ANALYTICS.TICKET_OVERVIEW.AGENT'),
          data: this.convLineGraphData?.series.filter(
            item => item.name === 'open'
          )[0].data,
        },
        {
          name: this.$t('ANALYTICS.TICKET_OVERVIEW.CLOSED'),
          data: this.convLineGraphData?.series.filter(
            item => item.name === 'closed'
          )[0].data,
        },
        {
          name: this.$t('ANALYTICS.TICKET_OVERVIEW.FOLLOWUP'),
          data: this.convLineGraphData?.series.filter(
            item => item.name === 'followup'
          )[0].data,
        },
        {
          name: this.$t('ANALYTICS.TICKET_OVERVIEW.WAITING'),
          data: this.convLineGraphData?.series.filter(
            item => item.name === 'waiting'
          )[0].data,
        },
      ];
      return formattedSeries || [];
    },

    chartCategories() {
      if (this.startDate === this.endDate) {
        if (this.convLineGraphData?.categories)
          return this.convLineGraphData?.categories.map(x =>
            this.timeToAMPM(x.substring(x.length - 8))
          );
      }
      return this.convLineGraphData?.categories?.map(date =>
        this.dateToWords(date)
      );
    },
    chartColors() {
      // give color to all tickets metric and to hide rest of the metrics lines make them transparent
      return [
        '#6BAC1B',
        'rgba(0,0,0,0)',
        'rgba(0,0,0,0)',
        'rgba(0,0,0,0)',
        'rgba(0,0,0,0)',
        'rgba(0,0,0,0)',
        'rgba(0,0,0,0)',
        'rgba(0,0,0,0)',
        'rgba(0,0,0,0)',
      ];
    },

    handOffChartSeries() {
      return [
        {
          name: 'Bot Tickets',
          data: this.agentBotHandoffLineChart?.bot_counts,
        },
        {
          name: 'Agent Handoffs',
          data: this.agentBotHandoffLineChart?.handoff_counts,
        },
      ];
    },
    handOffChartCategories() {
      if (this.startDate === this.endDate) {
        return this.agentBotHandoffLineChart?.dates.map(date =>
          new Date(date).toLocaleString([], {
            day: 'numeric',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          })
        );
      }
      return this.agentBotHandoffLineChart?.dates.map(date =>
        this.dateToWords(date) ? this.dateToWords(date) : date
      );
    },
    handOffChartColors() {
      return ['#1B83AC', '#6BAC1B'];
    },
    userEmail() {
      return getUserEmail();
    },
  },

  methods: {
    computedInboxClass(child) {
      if (child && child?.channel_type) {
        const { channel_type, phone_number, instagram_add_on } = child;
        const classByType = this.getInboxClassByType(
          channel_type,
          phone_number,
          '',
          instagram_add_on
        );

        return classByType;
      }

      return '';
    },
    onDateRangeChange({ from, to, date_range_name, isFiredOnMount }) {
      this.startDate = this.formatDate(from * 1000).replaceAll('-', '/');
      this.endDate = this.formatDate(to * 1000).replaceAll('-', '/');
      this.getConvMetrics();
      this.getSlaMetrics();
      this.getConvGraphData();
      this.getAgentHandoffTrend();
      if (!isFiredOnMount) {
        this.mix_panel_clicked_date_filter_menu_item(
          'ticket_overview',
          date_range_name
        );
      }
    },
    changeLabelId(val, inboxes, id) {
      this.selectedLabels = id;
      this.getConvMetrics();
      this.getSlaMetrics();
      this.getConvGraphData();
      this.getAgentHandoffTrend();
    },
    downloadReport(val) {
      this.report_type = val.key;
      this.download();
    },
    async download() {
      this.mix_panel_clicked_ticket_overview_report(
        'ticket_overview',
        this.report_type
      );
      this.isDownloadingReport = true;
      await this.$store
        .dispatch('getAnalyticsReport', {
          start_date: this.startDate,
          end_date: this.endDate,
          query_type: 'download_overall_report',
          email: this.userEmail,
          labels: this.selectedLabels,
          inboxes: this.selectedInboxes,
          scope: 'tickets',
          state:
            this.report_type === 'download_open_conv_report' ? 'open' : null,
          working_hours: this.selectedWorkingHourType,
        })
        .then(() => {})
        .catch(() => {
          this.isDownloadingReport = false;
          this.downloadError = true;
        });
    },
    hidePopup() {
      this.isDownloadingReport = false;
      this.downloadError = false;
    },
    changeWorkingHourType(val) {
      this.selectedWorkingHourType = val.key;
      this.getConvMetrics();
      this.getSlaMetrics();
      this.getConvGraphData();
      this.getAgentHandoffTrend();
      this.mix_panel_clicked_working_hours_menu_item(
        'ticket_overview',
        val.key
      );
    },

    changeInboxId(_, __, id) {
      this.selectedInboxes = id;
      this.getConvMetrics();
      this.getSlaMetrics();
      this.getConvGraphData();
      this.getAgentHandoffTrend();
    },

    logSelectedInbox(selectedOptionValue) {
      const filteredInbox = this.inboxes.find(
        inbox => inbox.name === selectedOptionValue
      );

      this.mix_panel_clicked_inbox_filter_menu_item(
        'ticket_overview',
        filteredInbox.channel_type
      );
    },

    logSelectedTag(selectedOptionValue) {
      const filteredInbox = this.accountLabels.find(
        tag => tag.title === selectedOptionValue
      );

      this.mix_panel_clicked_tag_filter_menu_item(
        'ticket_overview',
        filteredInbox.label_type
      );
    },
    async getSlaMetrics() {
      this.isFetchingSlaMetrics = true;
      await this.$store
        .dispatch('getSlaStats', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          workingHours: this.selectedWorkingHourType,
        })
        .then(({ total_violations = 0 }) => {
          this.slaMetrics.violation_percentage = total_violations;
          this.isFetchingSlaMetrics = false;
        });
    },
    async getConvMetrics() {
      await this.$store
        .dispatch('getConversationStats', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          this.conversationMetrics = data;
        });
    },
    async getConvGraphData() {
      this.$store
        .dispatch('getConversationPeriodicStates', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          this.convLineGraphData = data;
        });
    },
    async getAgentHandoffTrend() {
      this.$store
        .dispatch('getConversationAgentHandoffTrend', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          this.agentBotHandoffLineChart = data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-container {
  display: flex;
  flex-direction: column;
}

.analytics-filters {
  justify-content: flex-start !important;
  max-width: 90%;
  flex-wrap: wrap;
  gap: $space-slab;
}

.metrics-card-container {
  display: flex;
  flex-direction: row;
  margin: $space-micro $space-micro $space-normal;

  .extra-info {
    position: absolute;
    top: 5.2rem;
  }
}

.filter-pane {
  position: -webkit-sticky;
  position: sticky;
  background-color: $chat-background;
  top: $zero;
  z-index: 1000;
  padding: $space-normal $space-large;
  padding-left: $zero;
  margin-top: $space-larger;
}

.mr-right {
  margin-right: $space-normal;
}
.bottom-container {
  display: flex;
  flex-direction: column;
  margin: $zero $space-micro $space-normal;
}

.cancel-button {
  padding: $space-small;
  margin-bottom: $space-normal;
  cursor: pointer;
}
.selector-button {
  background: $neutral-white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: $neutral-grey-800;
  font-size: $font-size-small;
  line-height: 22px;
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius;
  padding: $space-small;
  margin-bottom: $space-normal;
  cursor: pointer;

  &:focus {
    border: 1px solid $pg-1-500;
    outline: none;
  }
}

.section-title {
  font-size: $font-size-medium;
  line-height: $space-medium;
  color: $neutral-grey-600;
  margin-bottom: $space-slab;
}

.button {
  margin-bottom: auto;
}

.metric-card-skeleton {
  margin-right: $space-medium;
}

.table-title {
  font-size: $font-size-medium;
  line-height: 2.6rem;
  color: $neutral-grey-800;
  font-weight: $font-weight-medium;
  margin: $space-slab $zero;
}

.card-padding {
  padding: $space-two $space-slab;
}

.tooltip-position {
  position: absolute;
  top: $zero;
  right: $zero;
}

.pie-card {
  height: 36.6rem;
}

.line-chart-legend {
  color: $pg-1-500;
  display: flex;
  margin: $space-small;
  margin-top: $space-normal;
}

.green-icon {
  height: $space-normal;
  width: $space-normal;
  background: $pg-1-500;
  border-radius: $space-smaller;
  margin: auto $space-small;
}

.container-width {
  width: 100%;
}

.download-note {
  width: 100%;
  min-height: 30vh;
  height: auto;
  overflow: hidden;
  display: flex;
  padding: 1rem 0.3rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div {
    font-size: $font-size-medium;
    margin: 1rem 2rem;
  }

  span {
    color: $pg-1;
  }
  img {
    width: 40%;
    height: 40%;
    margin: 3rem 2rem;
  }
}

.agentHandoffChart::v-deep .apexcharts-svg {
  overflow: visible;
}
</style>
