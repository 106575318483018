import store from 'dashboard/store';

import ConversationAnalytics from './ConversationAnalytics';
import ConversationOverview from './ConversationOverview';
import AgentOverview from './AgentOverview';
import BotPerformace from './BotPerformance';
import SalesAnalytics from './SalesAnalytics';
import IndividualAgentAnalytics from './individualAnalytics/AgentAnalytics';
import Csat from './csat/Index.vue';
import ShopifyAnalytics from './Shopify/Index.vue';

import { frontendURL } from 'dashboard/helper/URLHelper';

const {
  getters: { 'accounts/getDeepShopifyEnabledFlag': isDeepShopifyEnabled },
} = store;

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/analytics'),
      name: 'account_analytics',
      roles: ['administrator', 'supervisor'],
      redirect: () => {
        const isAdminSupervisorRole = ['administrator', 'supervisor'].includes(
          store.getters.getCurrentRole
        );
        return frontendURL(
          `accounts/:accountId/analytics/${
            isAdminSupervisorRole ? 'conversation_overview' : 'agent'
          }`
        );
      },
    },
    {
      path: frontendURL('accounts/:accountId/analytics/conversation'),
      name: 'analytics_conversation',
      roles: ['administrator', 'supervisor'],
      component: ConversationAnalytics,
    },
    {
      path: frontendURL('accounts/:accountId/analytics/csat'),
      name: 'csat_analytics',
      roles: ['administrator', 'supervisor'],
      component: Csat,
    },
    {
      path: frontendURL('accounts/:accountId/analytics/agent_overview'),
      name: 'agent_overview',
      roles: ['administrator', 'supervisor'],
      component: AgentOverview,
    },
    {
      path: frontendURL('accounts/:accountId/analytics/conversation_overview'),
      name: 'conversation_overview',
      roles: ['administrator', 'supervisor'],
      component: ConversationOverview,
    },
    {
      path: frontendURL('accounts/:accountId/analytics/me'),
      name: 'individual_agent_analytics',
      roles: ['agent'],
      component: IndividualAgentAnalytics,
    },
    {
      path: frontendURL('accounts/:accountId/analytics/bot_performance'),
      name: 'bot_performance',
      roles: ['administrator', 'supervisor'],
      component: BotPerformace,
    },
    {
      path: frontendURL('accounts/:accountId/analytics/sales_analytics'),
      name: 'sales_analytics',
      roles: ['administrator', 'supervisor'],
      component: SalesAnalytics,
    },
    {
      path: frontendURL('accounts/:accountId/analytics/shopify_analytics'),
      name: 'shopify_analytics',
      roles: isDeepShopifyEnabled ? ['administrator', 'supervisor'] : [],
      component: ShopifyAnalytics,
    },
  ],
};
