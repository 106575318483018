<template>
  <div class="flex-row-center">
    <button style="cursor: pointer" @click="muteToggle">
      <icons
        v-if="soundOn"
        name="sound"
        layer-fill="white"
        color="darkestgrey"
        size="semimedium"
      />
      <icons
        v-else
        name="soundoff"
        layer-fill="white"
        color="darkestgrey"
        size="semimedium"
      />
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import * as types from 'shared/constants/googleEventType';

export default {
  mixins: [alertMixin, clickaway, googleAnalyticsMixin],
  data() {
    return {
      soundOn: true,
      showConversationActions: false,
      showEmailActionsModal: false,
      actionList: [
        'CONTACT_PANEL.MUTE_CONTACT',
        'CONTACT_PANEL.UNMUTE_CONTACT',
        'CONTACT_PANEL.SEND_TRANSCRIPT',
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    currentStatus() {
      return this.currentChat.muted
        ? this.$t('CONTACT_PANEL.MUTE_CONTACT')
        : this.$t('CONTACT_PANEL.UNMUTE_CONTACT');
    },
    getActionList() {
      return this.actionList.map((item, index) => {
        return {
          id: index,
          name: this.$t(item),
        };
      });
    },
  },
  methods: {
    muteToggle() {
      this.soundOn = !this.soundOn;
      if (this.soundOn) {
        this.unmute();
      } else {
        this.mute();
      }
    },
    mute() {
      this.googleAnalyticsEvent(
        types.default.MORE_ACTIONS_MUTE,
        types.default.CONVERSATION_HEADER,
        types.default.CONVERSATION_HEADER
      );
      this.$store.dispatch('muteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.MUTED_SUCCESS'), 'success');
    },
    unmute() {
      this.googleAnalyticsEvent(
        types.default.MORE_ACTIONS_UNMUTE,
        types.default.CONVERSATION_HEADER,
        types.default.CONVERSATION_HEADER
      );
      this.$store.dispatch('unmuteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.UNMUTED_SUCCESS'), 'success');
    },
    toggleEmailActionsModal() {
      this.googleAnalyticsEvent(
        types.default.MORE_ACTIONS_SEND_TRANSCRIPT,
        types.default.CONVERSATION_HEADER,
        types.default.CONVERSATION_HEADER
      );
      this.showEmailActionsModal = !this.showEmailActionsModal;
    },
  },
};
</script>
