<template>
  <ul
    id="canned-list"
    class="vertical dropdown menu canned"
    :style="{ top: getTopPadding() + 'rem' }"
  >
    <li v-if="!templates.length">
      <a class="text-truncate">
        {{ `No such template found for this inbox.` }}
      </a>
    </li>
    <li
      v-for="(item, index) in templates"
      :id="`canned-${index}`"
      :key="index"
      :class="{ active: index === selectedIndex }"
      @click="onListItemSelection(index)"
    >
      <strong style="width: 5%; min-width: 5%">{{ item.display_id }}</strong>
      <strong>{{ item.short_code }}</strong>
      <span style="width: 5%; min-width: 5%">{{ item.template_type }}</span>
      <a class="text-truncate">
        {{ item.body }}
      </a>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      selectedIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      templates: 'getTemplates',
    }),
  },
  mounted() {
    document.addEventListener('keydown', this.keyListener);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyListener);
  },
  methods: {
    getTopPadding() {
      if (this.templates.length === 0) {
        return -3.9;
      }
      if (this.templates.length < 5) {
        return -this.templates.length * 3.9;
      }
      return -14;
    },
    isUp(e) {
      return e.keyCode === 38 || (e.ctrlKey && e.keyCode === 80); // UP, Ctrl-P
    },
    isDown(e) {
      return e.keyCode === 40 || (e.ctrlKey && e.keyCode === 78); // DOWN, Ctrl-N
    },
    isEnter(e) {
      return e.keyCode === 13;
    },
    keyListener(e) {
      if (this.isUp(e)) {
        if (!this.selectedIndex) {
          this.selectedIndex = this.templates.length - 1;
        } else {
          this.selectedIndex -= 1;
        }
      }
      if (this.isDown(e)) {
        if (this.selectedIndex === this.templates.length - 1) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex += 1;
        }
      }
      if (this.isEnter(e)) {
        let t = this.templates[this.selectedIndex];
        this.$emit('click', t.id, t.short_code, t.body);
      }
      this.$el.scrollTop = 34 * this.selectedIndex;
    },
    onHover(index) {
      this.selectedIndex = index;
    },
    onListItemSelection(index) {
      this.selectedIndex = index;
      let t = this.templates[this.selectedIndex];

      this.$emit('click', t);
    },
  },
};
</script>
