import {
  wrapIn,
  setBlockType,
  chainCommands,
  toggleMark,
  exitCode,
  joinUp,
  joinDown,
  lift,
  selectParentNode,
} from 'prosemirror-commands';
import {
  wrapInList,
  splitListItem,
  liftListItem,
  sinkListItem,
} from 'prosemirror-schema-list';
import { undo, redo } from 'prosemirror-history';
import { undoInputRule } from 'prosemirror-inputrules';

const mac =
  typeof navigator !== 'undefined' ? /Mac/.test(navigator.platform) : false;

export function buildKeymap(schema, mapKeys) {
  let keys = {};
  function bind(key, cmd) {
    if (mapKeys) {
      let mapped = mapKeys[key];
      if (mapped === false) return;
      if (mapped) key = mapped;
    }
    keys[key] = cmd;
  }

  bind('Mod-z', undo);
  bind('Shift-Mod-z', redo);
  bind('Backspace', undoInputRule);
  if (!mac) bind('Mod-y', redo);

  bind('Alt-ArrowUp', joinUp);
  bind('Alt-ArrowDown', joinDown);
  bind('Mod-BracketLeft', lift);
  bind('Escape', selectParentNode);

  if (schema.marks.strong) {
    bind('Mod-b', toggleMark(schema.marks.strong));
    bind('Mod-B', toggleMark(schema.marks.strong));
  }

  if (schema.marks.em) {
    bind('Mod-i', toggleMark(schema.marks.em));
    bind('Mod-I', toggleMark(schema.marks.em));
  }
  if (schema.marks.code) bind('Mod-`', toggleMark(schema.marks.code));

  if (schema.nodes.bullet_list)
    bind('Shift-Ctrl-8', wrapInList(schema.nodes.bullet_list));
  if (schema.nodes.bullet_list)
    bind('Shift-Ctrl-9', wrapInList(schema.nodes.bullet_list));
  if (schema.nodes.blockquote) bind('Ctrl->', wrapIn(schema.nodes.blockquote));

  if (schema.nodes.hard_break && schema.nodes.list_item) {
    let br = schema.nodes.hard_break;
    let cmd = chainCommands(exitCode, (state, dispatch) => {
      dispatch(state.tr.replaceSelectionWith(br.create()).scrollIntoView());
      return true;
    });

    bind('Mod-Enter', cmd);
    if (mac) bind('Ctrl-Enter', cmd);

    let splitOrBreak = chainCommands((state, dispatch) => {
      let { $from } = state.selection;
      // eslint-disable-next-line no-plusplus
      for (let depth = $from.depth; depth > 0; depth -= 1) {
        if ($from.node(depth).type === schema.nodes.list_item) {
          return splitListItem(schema.nodes.list_item)(state, dispatch);
        }
      }
      return false;
    }, cmd);
    bind('Enter', splitOrBreak);
  } else if (schema.nodes.hard_break) {
    let br = schema.nodes.hard_break;
    let cmd = chainCommands(exitCode, (state, dispatch) => {
      dispatch(state.tr.replaceSelectionWith(br.create()).scrollIntoView());
      return true;
    });
    bind('Mod-Enter', cmd);
    bind('Enter', cmd);
    if (mac) bind('Ctrl-Enter', cmd);
  } else if (schema.nodes.list_item) {
    bind('Enter', splitListItem(schema.nodes.list_item));
  }

  if (schema.nodes.list_item) {
    bind('Mod-[', liftListItem(schema.nodes.list_item));
    bind('Mod-]', sinkListItem(schema.nodes.list_item));
  }
  if (schema.nodes.paragraph)
    bind('Shift-Ctrl-0', setBlockType(schema.nodes.paragraph));
  if (schema.nodes.code_block)
    bind('Shift-Ctrl-\\', setBlockType(schema.nodes.code_block));
  if (schema.nodes.heading)
    for (let i = 1; i <= 6; i += 1)
      bind('Shift-Ctrl-' + i, setBlockType(schema.nodes.heading, { level: i }));
  if (schema.nodes.horizontal_rule) {
    let hr = schema.nodes.horizontal_rule;
    bind('Mod-_', (state, dispatch) => {
      dispatch(state.tr.replaceSelectionWith(hr.create()).scrollIntoView());
      return true;
    });
  }

  return keys;
}
