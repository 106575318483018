<template>
  <form @submit.prevent="onSubmitCannedResponse">
    <div
      v-if="!data"
      class="title-h3 text-dark mg-bottom--two"
      v-text="'Details'"
    />
    <woot-input
      v-model.trim="shortCode"
      required
      auto-focus
      class="medium-12 columns content-margin"
      :has-error="$v.shortCode.$error"
      :class="{ error: $v.shortCode.$error }"
      :error="$t('CANNED_MGMT.ADD.FORM.SHORT_CODE.ERROR')"
      :label="$t('CANNED_MGMT.ADD.FORM.SHORT_CODE.LABEL')"
      :placeholder="$t('CANNED_MGMT.ADD.FORM.SHORT_CODE.PLACEHOLDER')"
      @blur="$v.shortCode.$touch"
    />

    <woot-input
      v-model.trim="content"
      :rows="data ? '15' : '6'"
      type="text"
      required
      class="medium-12 columns content-margin"
      :class="{ error: $v.content.$error }"
      :has-error="$v.content.$error"
      :error="$t('CANNED_MGMT.ADD.FORM.CONTENT.ERROR')"
      :label="$t('CANNED_MGMT.ADD.FORM.CONTENT.LABEL')"
      :placeholder="$t('CANNED_MGMT.ADD.FORM.CONTENT.PLACEHOLDER')"
      @blur="$v.content.$touch"
    />
    <!--disabled support for media currently -->
    <div v-if="false" class="attachment-container">
      <file-upload
        ref="uploadCannedMedia"
        v-model="media"
        :size="4096 * 4096"
        accept="image/*, application/pdf, audio/mpeg, video/mp4, audio/ogg"
        @input-filter="addImagePreviewUrl"
        @input-file="onFileUpload"
      >
        <span class="form-label form-attachment">
          <icons
            v-if="!isUploading"
            name="clip"
            color="darkestgrey"
            size="semimedium"
          />
          <woot-spinner v-else />
          <span style="padding-left: 1.2rem">{{
            $t('CANNED_MGMT.ADD.FORM.MEDIA.LABEL')
          }}</span>
        </span>
      </file-upload>
      <attachment-preview
        v-if="media.length && attachmentPreview"
        :files="media"
        @clear="clearAttachments"
      />
    </div>

    <div v-if="!data" class="pagination-controls">
      <woot-base-button
        class="modal-button"
        variant="tertiary-danger"
        @click.prevent="onClose"
      >
        {{ $t('CANNED_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
      </woot-base-button>
      <woot-base-button
        class="modal-button"
        variant="primary"
        :disabled="
          $v.content.$invalid || $v.shortCode.$invalid || canned.showLoading
        "
        :loading="canned.showLoading"
        type="submit"
      >
        {{ $t('CANNED_MGMT.ADD.FORM.SUBMIT') }}
      </woot-base-button>
    </div>
    <div v-else class="flex-row flex-justify--end">
      <woot-base-button
        class="modal-button mg-bottom"
        variant="primary"
        :disabled="
          $v.content.$invalid || $v.shortCode.$invalid || canned.showLoading
        "
        :loading="canned.showLoading"
        type="submit"
        @click.native="InlineButtonClickHandler"
      >
        {{ $t('CANNED_MGMT.EDIT.FORM.SAVE') }}
      </woot-base-button>
    </div>
  </form>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import FileUpload from 'vue-upload-component';
import AttachmentPreview from 'dashboard/components/widgets/conversation/AttachmentPreview';
import alertMixin from 'shared/mixins/alertMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import * as types from 'shared/constants/googleEventType';

export default {
  components: {
    FileUpload,
    AttachmentPreview,
  },
  mixins: [alertMixin, googleAnalyticsMixin, mixPanelAnalyticsMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      shortCode: '',
      content: '',
      canned: {
        showLoading: false,
        message: '',
      },
      isUploading: false,
      media: [],
      previewImage: false,
      attachmentPreview: false,
    };
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    content: {
      required,
    },
  },
  created() {
    if (this.data?.id) {
      const {
        data: { short_code: shortCode, content },
      } = this;

      this.shortCode = shortCode;
      this.content = content;
    }
  },
  methods: {
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    createCanned(payload) {
      this.$store
        .dispatch('createCannedResponse', payload)
        .then(() => {
          this.canned.showLoading = false;
          this.showAlert(
            this.$t('CANNED_MGMT.ADD.API.SUCCESS_MESSAGE'),
            'success'
          );
          this.resetForm();
          this.onClose();
        })
        .catch(() => {
          this.canned.showLoading = false;
          this.showAlert(this.$t('CANNED_MGMT.ADD.API.ERROR_MESSAGE'), 'error');
        });
    },
    editCanned(payload) {
      this.$store
        .dispatch('updateCannedResponse', payload)
        .then(() => {
          this.canned.showLoading = false;
          this.showAlert(
            this.$t('CANNED_MGMT.EDIT.API.SUCCESS_MESSAGE'),
            'success'
          );
        })
        .catch(() => {
          this.canned.showLoading = false;
          this.showAlert(
            this.$t('CANNED_MGMT.EDIT.API.ERROR_MESSAGE'),
            'error'
          );
        });
    },
    onSubmitCannedResponse() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert('Fix errors before continuing', 'error');

        return;
      }

      this.googleAnalyticsEvent(
        types.default.ADD_CANNED_RESPONSE,
        types.default.CANNED_RESPONSE_SETTINGS,
        types.default.CANNED_RESPONSE_SETTINGS
      );
      this.canned.showLoading = true;

      let payload = {
        short_code: this.shortCode,
        content: this.content,
        media: this.media[0] ? this.media[0] : undefined,
      };

      if (this.data) {
        payload = { ...payload, id: this.data.id };
        this.editCanned(payload);
        return;
      }

      this.createCanned(payload);
    },
    addImagePreviewUrl(newFile, oldFile) {
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.previewImage = false;
        } else {
          newFile.url = '';
          let URL = window.URL || window.webkitURL;
          if (URL && URL.createObjectURL) {
            newFile.url = URL.createObjectURL(newFile.file);
            this.previewImage = true;
          }
        }
      }
    },
    onFileUpload(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.attachmentPreview = true;
        this.isUploading = true;
        this.$store
          .dispatch('uploadImage', { file: newFile.file })
          .then(() => {
            this.isUploading = false;
          })
          .catch(() => {
            this.isUploading = false;
          });
      }
      if (!newFile && oldFile) {
        this.attachmentPreview = false;
      }
    },
    clearAttachments() {
      this.$refs.uploadCannedMedia.clear();
    },
    InlineButtonClickHandler() {
      this.mix_panel_clicked_settings_save_edit_canned_response();
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '~dashboard/assets/scss/variables';

.media {
  width: 100%;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.fileupload {
  font-size: 18px;
}

.form-label {
  padding: $zero $space-small $space-one;
  color: $neutral-grey-800;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: 22px;
}

.form-info {
  color: $neutral-grey-600;
  font-size: $font-size-mini;
  line-height: 18px;
  padding: $space-smaller $space-small;
}

.content-margin {
  margin-bottom: 2.4rem;
}

.attachment-container {
  .file-uploads ::v-deep label {
    cursor: pointer;
  }

  .form-attachment {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 0;
  }
}
</style>
