import { required } from 'vuelidate/lib/validators';
import { DATA_TYPES } from './constants';

export const getDefaultValue = (dataType, enumObject = null) => {
  switch (dataType) {
    case DATA_TYPES.NUM:
      return 0;
    case DATA_TYPES.ENUM:
      if (enumObject) return enumObject?.value || '';
      return '';
    case DATA_TYPES.TEXT:
      return '';
    case DATA_TYPES.DATE:
      return new Date();
    case DATA_TYPES.DATETIME:
      return new Date();
    case DATA_TYPES.BOOL:
      return false;
    case DATA_TYPES.TIMEFRAME:
      return { start: new Date(), end: new Date() };
    case DATA_TYPES.TIME:
      return new Date();
    case DATA_TYPES.OBJECT:
      return {};
    case DATA_TYPES.ARRAY:
    case DATA_TYPES.ARRAY_NUM:
    case DATA_TYPES.ARRAY_ENUM:
    case DATA_TYPES.ARRAY_TEXT:
    case DATA_TYPES.ARRAY_DATE:
    case DATA_TYPES.ARRAY_DATETIME:
    case DATA_TYPES.ARRAY_TIME:
      return [];
    default:
      return null;
  }
};

export const getInnerDataType = arrayDataType => {
  const openingBracketIndex = arrayDataType.indexOf('[');
  const closingBracketIndex = arrayDataType.indexOf(']');
  return arrayDataType.substring(openingBracketIndex + 1, closingBracketIndex);
};

export const initializeFormValues = schema => {
  return Object.keys(schema).reduce((values, fieldName) => {
    const field = schema[fieldName];

    values[fieldName] = getDefaultValue(
      field.data_type,
      (field?.allowed_values && field?.allowed_values[0]) || null
    );
    return values;
  }, {});
};

export const initializeValidations = schema => {
  return Object.keys(schema).reduce((validations, fieldKey) => {
    const { required: isRequired } = schema[fieldKey];

    validations[fieldKey] = isRequired ? { required } : {};

    return validations;
  }, {});
};

export const formatLabel = str => {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
