<template>
  <div
    class="automation-header flex-row flex-align--end flex-justify--between gap--small"
  >
    <div class="flex-row flex-align--start">
      <span class="mg-right--small" style="cursor: pointer">
        <icons
          name="chevronLeft"
          color="grey"
          size="medium"
          @click.prevent="onBack"
        />
      </span>
      <div
        :class="{ disabled }"
        class="automation-header--content"
        v-on="disabled ? {} : { click: onClick }"
      >
        <div class="flex-row flex-align gap--small">
          <h2
            class="title-h2 text-light AUTOMATION_NAME"
            v-text="name || namePlaceholder"
          />
          <span v-if="!disabled" style="cursor: pointer">
            <icons name="editRound" color="grey" size="semimedium" />
          </span>
        </div>
        <span
          class="body-b2 text-light line-clamp-1"
          v-text="description || descriptionPlaceholder"
        />
      </div>
    </div>
    <slot name="right-section" />
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    namePlaceholder: {
      type: String,
      default: '',
    },
    descriptionPlaceholder: {
      type: String,
      default: '',
    },
    onClick: {
      type: Function,
      default: () => null,
    },
    onBack: {
      type: Function,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.automation-header {
  &--content {
    cursor: text;

    &.disabled {
      cursor: default;
    }

    &:hover {
      ::v-deep {
        .icon {
          fill: $neutral-grey-700;
        }
      }
    }
  }
}
</style>
