export default {
  methods: {
    processFile(data, exportedFilename, contentType) {
      var blob = new Blob([data], { type: contentType });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilename);
      } else {
        let link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          let url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', exportedFilename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    openLink(link) {
      window.open(
        link,
        '_blank' // <- This is what makes it open in a new window.
      );
    },
  },
};
