import { INBOX_NAMES } from 'shared/mixins/inboxMixin';
import inboxMethods from 'dashboard/mixins/inbox.js';

export default {
  mixins: [inboxMethods],
  computed: {
    shadeColor() {
      const inboxMetaData = this.getInboxMeta();

      if (inboxMetaData.brand_color) {
        return `linear-gradient(131deg, ${inboxMetaData.hex} -200%, rgba(255,255,255,1) 50%, ${inboxMetaData.brand_color} 250%)`;
      }

      return inboxMetaData.hex + '25';
    },
    channelImageIdentifier() {
      return INBOX_NAMES[this.inbox.channel_type]?.key || '';
    },
  },
  methods: {
    imageSrc(identifier) {
      identifier =
        identifier === 'whatsapp_cloud_api' ? 'whatsapp' : identifier;

      if (identifier === 'facebook' && this.inbox?.instagram_add_on)
        identifier = 'instagram';

      try {
        // eslint-disable-next-line global-require
        const src = require(`dashboard/assets/images/channels/${identifier}.svg`);
        return src;
      } catch {
        this.imgError = true;
      }
      return '';
    },
    getInboxMeta() {
      return this.getInboxClassByType(
        this.inbox?.channel_type,
        this.inbox?.phone_number,
        '',
        this.inbox?.instagram_add_on
      );
    },
  },
};
