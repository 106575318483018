<template>
  <div
    v-if="isStatus"
    class="bagde-v3-component"
    :class="status.toLowerCase().replaceAll(' ', '')"
  >
    <icons
      v-if="statusIcon"
      :name="statusIcon"
      size="semimedium"
      :color="statusIconColor"
    />
    <span
      style="padding: 0.2rem 0.8rem 0.2rem 0.6rem; text-transform : uppercase"
    >
      {{ statusText }}
    </span>
  </div>
  <div v-else class="bagde-v3-component" :class="type">
    <icons v-if="!noIcon" :name="icon" size="semimedium" color="white" />
    <span style="padding: 0.2rem 0.8rem 0.2rem 0.6rem">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'badge-green-variant',
    },
    text: {
      type: String,
      default: '',
      required: false,
    },
    icon: {
      type: String,
      required: false,
      default: 'badge-check',
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    isStatus: {
      type: Boolean,
      default: false,
      required: false,
    },
    status: {
      type: String,
      default: '',
      required: false,
    },
  },
  computed: {
    statusIconColor() {
      if (this.status.toLowerCase().replaceAll(' ', '') === 'resolved')
        return 'darkgreen';
      if (this.status.toLowerCase().replaceAll(' ', '') === 'waiting')
        return 'darkyellow';
      if (this.status.toLowerCase().replaceAll(' ', '') === 'followup')
        return 'secondaryblue';
      if (this.status.toLowerCase().replaceAll(' ', '') === 'bot')
        return 'green';
      return 'darkestgrey';
    },
    statusIcon() {
      if (this.status.toLowerCase().replaceAll(' ', '') === 'resolved')
        return 'tickRound';
      if (this.status.toLowerCase().replaceAll(' ', '') === 'waiting')
        return 'history';
      if (this.status.toLowerCase().replaceAll(' ', '') === 'followup')
        return 'uTurn';
      if (this.status.toLowerCase().replaceAll(' ', '') === 'bot') return '';
      if (this.status.toLowerCase().replaceAll(' ', '') === 'closed') return '';
      return '';
    },
    statusText() {
      if (this.status.toLowerCase().replaceAll(' ', '') === 'resolve')
        return 'Resolved';
      return this.status;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
.bagde-v3-component {
  display: inline-flex;
  align-items: center;
  font-size: $font-size-mini;
  border-radius: 0.4rem;
  font-weight: $font-weight-black;
  color: $color-white;
  line-height: 2rem;
  font-style: normal;
  margin-left: $space-smaller;
  padding: $space-micro $space-small;
}

.badge-blue-variant {
  background: $secondary-blue;
}

.badge-red-variant {
  background: $r-500;
}
.badge-yellow-variant {
  background: $warn-yellow-500;
}
.badge-green-variant {
  background: $pg-1;
}

.resolve {
  color: $pg-1-700;
  background: $pg-light-1;
}

.waiting {
  color: #8b650e;
  background: $warn-yellow-200;
}

.followup {
  color: $secondary-blue;
  background: rgba(162, 218, 240, 0.2);
}

.bot {
  color: $color-woot;
  background: $neutral-grey-300;
}

.closed {
  color: $text-dark;
  background: $neutral-grey-300;
}

.outbound {
  color: $text-dark;
  background: #ffc7ac;
}
</style>
