import { required, url } from 'vuelidate/lib/validators';

export const FIELD_DATA_TYPE = {
  short_text: 'text',
  paragraph: 'large_text',
  url: 'text',
  multi_select: 'multi_enum',
  single_select: 'enum',
  timestamp: 'datetime',
  date: 'date',
  time: 'time',
};

export const customUrlValidator = (value, field) => {
  if (field.url) return url(value);
  return true;
};

export const customRequiredValidator = (value, field) => {
  if (field.mandatory) return required(value);
  return true;
};
