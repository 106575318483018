// import Vue from 'vue';
import * as types from '../../mutation-types';
import LogisticsApi from '../../../api/limekit/logistics';
import UploadApi from '../../../api/limekit/upload';
import DownloadApi from '../../../api/limekit/download';
import BroadcastApi from '../../../api/limekit/broadcast';
import AnalyticsApi from '../../../api/limekit/analytics';
import OrdersAPI from '../../../api/limekit/orders';
import ContactAPI from '../../../api/contacts';
import ProductsAPI from '../../../api/limekit/products';
import CustomersAPI from '../../../api/limekit/customers';
import ShopifyAPI from '../../../api/limekit/shopify';
import AttendanceAPI from '../../../api/limekit/attendance';
import { wrapActionWithAbortController } from '../../utils/actionWrapper';
import { ignoreCancelledError } from '../../utils/errorHandling';

// actions
const actions = {
  fetchLimekitLogisticsConnection: async ({ commit }, data = null) => {
    try {
      const response = await LogisticsApi.fetchLimekitLogisticsConnection(data);
      return response;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      throw new Error(error);
    }
  },
  createLimekitLogisticsConnection: async ({ commit }, data) => {
    try {
      const response = await LogisticsApi.createLimekitLogisticsConnection(
        data
      );
      return response;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      throw new Error(error);
    }
  },
  deleteLimekitLogisticsConnection: async ({ commit }, data) => {
    try {
      const response = await LogisticsApi.deleteLimekitLogisticsConnection(
        data
      );
      return response;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      throw new Error(error);
    }
  },
  getEnabledLogistics: async ({ commit }) => {
    try {
      const response = await LogisticsApi.getEnabledLogistics();
      return response.data;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
      throw new Error(error);
    }
  },
  uploadImage: async ({ commit }, data) => {
    try {
      const response = await UploadApi.uploadImage(data);
      return response.data;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
    }
    return {};
  },
  download: async ({ commit }, data) => {
    try {
      const response = await DownloadApi.download(data);
      return response.data;
    } catch (error) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
    }
    return {};
  },
  broadcast: async (_, data) => {
    try {
      const response = await BroadcastApi.send(data);
      return response.status;
    } catch (error) {
      throw error.response;
    }
  },
  broadcastStatus: async ({ commit }, data) => {
    try {
      const response = await BroadcastApi.status(data);
      response.data.results.forEach(item => {
        item.sentCount = null;
      });
      commit(types.default.SET_BROADCAST_LIST, response.data);
      return response.data;
    } catch (e) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
    }
    return {};
  },
  broadcastSentCount: async ({ commit }, id) => {
    try {
      const response = await BroadcastApi.sentCount(id);
      return response.data;
    } catch (e) {
      commit(types.default.CRM_ERROR, 'Some Error occurred');
    }
    return {};
  },
  broadcastDownload: async ({ commit }, data) => {
    try {
      const response = await BroadcastApi.download(data);
      return response.data;
    } catch (e) {
      // statements
      commit(types.default.CRM_ERROR, 'Some Error occurred');
    }
    return {};
  },
  cancelScheduledBroadcast: async ({ commit }, broadcastId) => {
    try {
      const response = await BroadcastApi.cancelScheduledBroadcast(broadcastId);
      commit(types.default.CANCEL_BROADCAST, response.data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  updateBrdState: ({ commit }, data) => {
    commit(types.default.SET_BROADCAST_PROGRESS, data);
  },
  upgradeShopifyAppPlan: async (_, data) => {
    try {
      const response = await ShopifyAPI.upgradeShopifyPlan(data);
      return response.data;
    } catch (error) {
      // Handle error
    }
    return {};
  },
  updateShopifyAccessToken: async (_, data) => {
    try {
      await ShopifyAPI.upgradeShopifyToken(data);
    } catch (error) {
      // Handle error
    }
    return {};
  },
  getBillingPlans: async () => {
    try {
      const response = await ShopifyAPI.getShopifyBillingPlans();
      return response.data;
    } catch (error) {
      // Handle error
    }
    return {};
  },
  getBillingConversationCount: async (_, data) => {
    try {
      const response = await ShopifyAPI.getShopifyConversationCount(data);
      return response.data;
    } catch (error) {
      // Handle error
    }
    return {};
  },
  getAnalyticsReport: wrapActionWithAbortController(
    'getAnalyticsReport',
    async ({ commit, abortController }, data) => {
      try {
        const response = await AnalyticsApi.getReport(
          data,
          abortController.signal
        );
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.CRM_ERROR, 'Some Error occurred');
        });
      }
      return {};
    }
  ),
  getBotAnalyticsReport: wrapActionWithAbortController(
    'getBotAnalyticsReport',
    async ({ commit, abortController }, data) => {
      try {
        const response = await AnalyticsApi.getBotReport(
          data,
          abortController.signal
        );
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.CRM_ERROR, 'Some Error occurred');
        });
      }
      return {};
    }
  ),
  getUserAnalyticsReport: wrapActionWithAbortController(
    'getUserAnalyticsReport',
    async ({ commit, abortController }, data) => {
      try {
        const response = await AnalyticsApi.getUserReport(
          data,
          abortController.signal
        );
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.CRM_ERROR, 'Some Error occurred');
        });
      }
      return {};
    }
  ),
  getConversationStats: wrapActionWithAbortController(
    'getConversationStats',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_CONVERSATION_METRICS_UI_FLAG, {
        fetchingConversationMetricsCards: true,
      });
      try {
        let response = await AnalyticsApi.getCoversationStats(
          data,
          abortController.signal
        );
        commit(types.default.SET_CONVERSATION_METRICS_UI_FLAG, {
          fetchingConversationMetricsCards: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_CONVERSATION_METRICS_UI_FLAG, {
            fetchingConversationMetricsCards: false,
          });
        });
      }
      return {};
    }
  ),
  getSlaStats: wrapActionWithAbortController(
    'getSlaStats',
    async ({ abortController }, data) => {
      try {
        let response = await AnalyticsApi.getSlaStats(
          data,
          abortController.signal
        );
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => null);
      }
      return {};
    }
  ),
  getConversationPeriodicStates: wrapActionWithAbortController(
    'getConversationPeriodicStates',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_CONVERSATION_STATE_UI_FLAG, {
        fetchingConversationStates: true,
      });
      try {
        const response = await AnalyticsApi.getCoversationPeriodicStates(
          data,
          abortController.signal
        );
        commit(types.default.SET_CONVERSATION_STATE_UI_FLAG, {
          fetchingConversationStates: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_CONVERSATION_STATE_UI_FLAG, {
            fetchingConversationStates: false,
          });
        });
      }
      return {};
    }
  ),
  getConversationAgentHandoffTrend: wrapActionWithAbortController(
    'getConversationAgentHandoffTrend',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_CONVERSATION_HANDOFF_TREND_UI_FLAG, {
        fetchingConversationHandoffTrend: true,
      });
      try {
        const response = await AnalyticsApi.getCoversationAgentHandoffTrend(
          data,
          abortController.signal
        );
        commit(types.default.SET_CONVERSATION_HANDOFF_TREND_UI_FLAG, {
          fetchingConversationHandoffTrend: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_CONVERSATION_HANDOFF_TREND_UI_FLAG, {
            fetchingConversationHandoffTrend: false,
          });
        });
      }
      return {};
    }
  ),
  getAgentPerformanceStats: wrapActionWithAbortController(
    'getAgentPerformanceStats',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_AGENT_METRICS_UI_FLAG, {
        fetchingPerformanceMetricCards: true,
      });
      try {
        const response = await AnalyticsApi.getAgentPerformanceStats(
          data,
          abortController.signal
        );
        commit(types.default.SET_AGENT_METRICS_UI_FLAG, {
          fetchingPerformanceMetricCards: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_AGENT_METRICS_UI_FLAG, {
            fetchingPerformanceMetricCards: false,
          });
        });
      }
      return {};
    }
  ),
  getPeriodicAgentsPerformance: wrapActionWithAbortController(
    'getPeriodicAgentsPerformance',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_AGENT_PERIODIC_UI_FLAG, {
        fetchingPeriodicPerformance: true,
      });
      try {
        const response = await AnalyticsApi.getPeriodicAgentsPerformance(
          data,
          abortController.signal
        );
        commit(types.default.SET_AGENT_PERIODIC_UI_FLAG, {
          fetchingPeriodicPerformance: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_AGENT_PERIODIC_UI_FLAG, {
            fetchingPeriodicPerformance: false,
          });
        });
      }
      return {};
    }
  ),
  getAgentSlaTableData: wrapActionWithAbortController(
    'getAgentSlaTableData',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_AGENT_PERIODIC_UI_FLAG, {
        fetchingSlaTableData: true,
      });
      try {
        const response = await AnalyticsApi.getAgentSlaTableData(
          data,
          abortController.signal
        );
        commit(types.default.SET_AGENT_PERIODIC_UI_FLAG, {
          fetchingSlaTableData: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_AGENT_PERIODIC_UI_FLAG, {
            fetchingSlaTableData: false,
          });
        });
      }
      return {};
    }
  ),
  getAgentHourlyPerformance: wrapActionWithAbortController(
    'getAgentHourlyPerformance',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_AGENT_HOURLY_UI_FLAG, {
        fetchingHourlyPerformance: true,
      });
      try {
        const response = await AnalyticsApi.getAgentHourlyPerformance(
          data,
          abortController.signal
        );
        commit(types.default.SET_AGENT_HOURLY_UI_FLAG, {
          fetchingHourlyPerformance: false,
        });
        return response.data;
      } catch (error) {
        commit(types.default.SET_AGENT_HOURLY_UI_FLAG, {
          fetchingHourlyPerformance: false,
        });
      }
      return {};
    }
  ),
  getAgentWiseConvStats: wrapActionWithAbortController(
    'getAgentWiseConvStats',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_AGENT_CONV_STAT_UI_FLAG, {
        fetchingConversationState: true,
      });

      try {
        const response = await AnalyticsApi.getAgentWiseCoversationStates(
          data,
          abortController.signal
        );
        commit(types.default.SET_AGENT_CONV_STAT_UI_FLAG, {
          fetchingConversationState: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_AGENT_CONV_STAT_UI_FLAG, {
            fetchingConversationState: false,
          });
        });
      }
      return null;
    }
  ),
  getAgentPerformanceLogs: wrapActionWithAbortController(
    'agentconvstates',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_AGENT_MEAN_UI_FLAG, {
        fetchingMeanPerformance: true,
      });
      try {
        const response = await AnalyticsApi.getAgentPerformanceLogs(
          data,
          abortController.signal
        );
        commit(types.default.SET_AGENT_MEAN_UI_FLAG, {
          fetchingMeanPerformance: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_AGENT_MEAN_UI_FLAG, {
            fetchingMeanPerformance: false,
          });
        });
      }
      return {};
    }
  ),
  getAgentPerformanceMessageBlocks: wrapActionWithAbortController(
    'getAgentPerformanceMessageBlocks',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_AGENT_MSG_BLOCK_UI_FLAG, {
        fetchingMessageBlocks: true,
      });
      try {
        const response = await AnalyticsApi.getAgentMessageBlocks(
          data,
          abortController.signal
        );
        commit(types.default.SET_AGENT_MSG_BLOCK_UI_FLAG, {
          fetchingMessageBlocks: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_AGENT_MSG_BLOCK_UI_FLAG, {
            fetchingMessageBlocks: false,
          });
        });
      }
      return {};
    }
  ),
  getBotPerformanceStats: wrapActionWithAbortController(
    'getBotPerformanceStats',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_BOT_CARDS_UI_FLAG, {
        fetchingBotMetricsCards: true,
      });
      try {
        const response = await AnalyticsApi.getBotPerformanceStats(
          data,
          abortController.signal
        );
        commit(types.default.SET_BOT_CARDS_UI_FLAG, {
          fetchingBotMetricsCards: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_BOT_CARDS_UI_FLAG, {
            fetchingBotMetricsCards: false,
          });
        });
      }
      return {};
    }
  ),
  getBotAutomationPercent: wrapActionWithAbortController(
    'getBotAutomationPercent',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_BOT_LINE_CHART_UI_FLAG, {
        fetchingBotLineChartMetrics: true,
      });
      try {
        const response = await AnalyticsApi.getBotAutomationPercent(
          data,
          abortController.signal
        );
        commit(types.default.SET_BOT_LINE_CHART_UI_FLAG, {
          fetchingBotLineChartMetrics: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_BOT_LINE_CHART_UI_FLAG, {
            fetchingBotLineChartMetrics: false,
          });
        });
      }
      return {};
    }
  ),
  getBotConversionRate: wrapActionWithAbortController(
    'getBotConversionRate',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_BOT_LINE_CHART_UI_FLAG, {
        fetchingBotLineChartMetrics: true,
      });
      try {
        const response = await AnalyticsApi.getBotConversionRate(
          data,
          abortController.signal
        );
        commit(types.default.SET_BOT_LINE_CHART_UI_FLAG, {
          fetchingBotLineChartMetrics: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_BOT_LINE_CHART_UI_FLAG, {
            fetchingBotLineChartMetrics: false,
          });
        });
      }
      return {};
    }
  ),
  getBotUserIntentBreakdown: wrapActionWithAbortController(
    'getBotUserIntentBreakdown',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_BOT_ANALYTICS_UI_FLAG, {
        fetchingBotColumnChartMetrics: true,
      });
      try {
        const response = await AnalyticsApi.getBotUserIntentBreakdown(
          data,
          abortController.signal
        );
        commit(types.default.SET_BOT_ANALYTICS_UI_FLAG, {
          fetchingBotColumnChartMetrics: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_BOT_ANALYTICS_UI_FLAG, {
            fetchingBotColumnChartMetrics: false,
          });
        });
      }
      return {};
    }
  ),
  getSalesOverview: wrapActionWithAbortController(
    'getSalesOverview',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
        fetchingSalesMetrics: true,
      });
      try {
        const response = await AnalyticsApi.getSalesOverview(
          data,
          abortController.signal
        );
        commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
          fetchingSalesMetrics: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
            fetchingSalesMetrics: false,
          });
        });
      }
      return {};
    }
  ),
  getSalesLineChart: wrapActionWithAbortController(
    'getSalesLineChart',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
        fetchingSalesMetrics: true,
      });
      try {
        const response = await AnalyticsApi.getSalesLineChart(
          data,
          abortController.signal
        );
        commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
          fetchingSalesMetrics: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
            fetchingSalesMetrics: false,
          });
        });
      }
      return {};
    }
  ),
  getSalesTable: wrapActionWithAbortController(
    'getSalesTable',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_SALES_TABLE_UI_FLAG, {
        fetchingSalesTable: true,
      });
      try {
        const response = await AnalyticsApi.getSalesTable(
          data,
          abortController.signal
        );
        commit(types.default.SET_SALES_TABLE_UI_FLAG, {
          fetchingSalesTable: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_SALES_TABLE_UI_FLAG, {
            fetchingSalesTable: false,
          });
        });
      }
      return {};
    }
  ),
  downloadSalesTable: async ({ commit }, data) => {
    commit(types.default.SET_SALES_TABLE_UI_FLAG, {
      fetchingSalesTable: false,
    });
    try {
      const response = await AnalyticsApi.downloadSalesDataTable(data);
      commit(types.default.SET_SALES_TABLE_UI_FLAG, {
        fetchingSalesTable: false,
      });
      return response.data;
    } catch (error) {
      commit(types.default.SET_SALES_TABLE_UI_FLAG, {
        fetchingSalesTable: false,
      });
    }
    return {};
  },
  getSalesByScope: async ({ commit }, data) => {
    commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
      fetchingSalesMetrics: true,
    });
    try {
      const response = await AnalyticsApi.getSalesByScope(data);
      commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
        fetchingSalesMetrics: false,
      });
      return response.data;
    } catch (error) {
      commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
        fetchingSalesMetrics: false,
      });
    }
    return {};
  },
  getSalesPieChart: wrapActionWithAbortController(
    'getSalesPieChart',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
        fetchingSalesMetrics: true,
      });
      try {
        const response = await AnalyticsApi.getSalesPieChart(
          data,
          abortController.signal
        );
        commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
          fetchingSalesMetrics: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
            fetchingSalesMetrics: false,
          });
        });
      }
      return {};
    }
  ),
  getSalesFunnelOne: wrapActionWithAbortController(
    'getSalesFunnelOne',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
        fetchingSalesMetrics: true,
      });
      try {
        const response = await AnalyticsApi.getSalesFunnelOne(
          data,
          abortController.signal
        );
        commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
          fetchingSalesMetrics: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
            fetchingSalesMetrics: false,
          });
        });
      }
      return {};
    }
  ),
  getSalesFunnelTwo: wrapActionWithAbortController(
    'getSalesFunnelTwo',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
        fetchingSalesMetrics: true,
      });
      try {
        const response = await AnalyticsApi.getSalesFunnelTwo(
          data,
          abortController.signal
        );
        commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
          fetchingSalesMetrics: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_SALES_ANALYTICS_UI_FLAG, {
            fetchingSalesMetrics: false,
          });
        });
      }
      return {};
    }
  ),
  getUserChannelDistribution: wrapActionWithAbortController(
    'getUserChannelDistribution',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_USERS_INBOX_WEBSITE_UI_FLAG, {
        fetchingUserInboxWebsiteDivision: true,
      });
      try {
        const response = await AnalyticsApi.getUserChannelDistribution(
          data,
          abortController.signal
        );
        commit(types.default.SET_USERS_INBOX_WEBSITE_UI_FLAG, {
          fetchingUserInboxWebsiteDivision: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_USERS_INBOX_WEBSITE_UI_FLAG, {
            fetchingUserInboxWebsiteDivision: false,
          });
        });
      }
      return {};
    }
  ),
  getWebsiteEntryPoint: wrapActionWithAbortController(
    'getWebsiteEntryPoint',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_USERS_INBOX_WEBSITE_UI_FLAG, {
        fetchingUserInboxWebsiteDivision: true,
      });
      try {
        const response = await AnalyticsApi.getWebsiteEntryPoint(
          data,
          abortController.signal
        );
        commit(types.default.SET_USERS_INBOX_WEBSITE_UI_FLAG, {
          fetchingUserInboxWebsiteDivision: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_USERS_INBOX_WEBSITE_UI_FLAG, {
            fetchingUserInboxWebsiteDivision: false,
          });
        });
      }
      return {};
    }
  ),
  getUserConcerns: wrapActionWithAbortController(
    'getUserConcerns',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_USERS_CONCERNS_COMPLAIN_UI_FLAG, {
        fetchingUserConcernsComplains: true,
      });
      try {
        const response = await AnalyticsApi.getUserConcerns(
          data,
          abortController.signal
        );
        commit(types.default.SET_USERS_CONCERNS_COMPLAIN_UI_FLAG, {
          fetchingUserConcernsComplains: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_USERS_CONCERNS_COMPLAIN_UI_FLAG, {
            fetchingUserConcernsComplains: false,
          });
        });
      }
      return {};
    }
  ),
  getUserComplaints: wrapActionWithAbortController(
    'getUserComplaints',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_USERS_CONCERNS_COMPLAIN_UI_FLAG, {
        fetchingUserConcernsComplains: true,
      });
      try {
        const response = await AnalyticsApi.getUserComplaints(
          data,
          abortController.signal
        );
        commit(types.default.SET_USERS_CONCERNS_COMPLAIN_UI_FLAG, {
          fetchingUserConcernsComplains: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_USERS_CONCERNS_COMPLAIN_UI_FLAG, {
            fetchingUserConcernsComplains: false,
          });
        });
      }
      return {};
    }
  ),
  getHandoffReasonBreakdown: wrapActionWithAbortController(
    'getHandoffReasonBreakdown',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_USERS_HANDOFF_UI_FLAG, {
        fetchingUserHandoff: true,
      });
      try {
        const response = await AnalyticsApi.getHandoffReasonBreakdown(
          data,
          abortController.signal
        );
        commit(types.default.SET_USERS_HANDOFF_UI_FLAG, {
          fetchingUserHandoff: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_USERS_HANDOFF_UI_FLAG, {
            fetchingUserHandoff: false,
          });
        });
      }
      return {};
    }
  ),
  getUserHandoffByFlows: wrapActionWithAbortController(
    'getUserHandoffByFlows',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_USERS_HANDOFF_UI_FLAG, {
        fetchingUserHandoff: true,
      });
      try {
        const response = await AnalyticsApi.getUserHandoffByFlows(
          data,
          abortController.signal
        );
        commit(types.default.SET_USERS_HANDOFF_UI_FLAG, {
          fetchingUserHandoff: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_USERS_HANDOFF_UI_FLAG, {
            fetchingUserHandoff: false,
          });
        });
      }
      return {};
    }
  ),
  getUserActivity: wrapActionWithAbortController(
    'getUserActivity',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_USERS_ACTIVITY_UI_FLAG, {
        fetchingUserActivity: true,
      });
      try {
        const response = await AnalyticsApi.getUserActivity(
          data,
          abortController.signal
        );
        commit(types.default.SET_USERS_ACTIVITY_UI_FLAG, {
          fetchingUserActivity: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_USERS_ACTIVITY_UI_FLAG, {
            fetchingUserActivity: false,
          });
        });
      }
      return {};
    }
  ),
  getBotFailingData: wrapActionWithAbortController(
    'getBotFailingData',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_BOT_FAILING_UI_FLAG, {
        fetchingUserBotFailingTable: true,
      });
      try {
        const response = await AnalyticsApi.getBotFailingData(
          data,
          abortController.signal
        );
        commit(types.default.SET_BOT_FAILING_UI_FLAG, {
          fetchingUserBotFailingTable: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_BOT_FAILING_UI_FLAG, {
            fetchingUserBotFailingTable: false,
          });
        });
      }
      return {};
    }
  ),
  downloadBotFailingData: wrapActionWithAbortController(
    'downloadBotFailingData',
    async ({ commit, abortController }, data) => {
      commit(types.default.SET_USERS_DOWNLOAD_TABLE_UI_FLAG, {
        fetchingUserDownloadTable: true,
      });
      try {
        const response = await AnalyticsApi.downloadBotFailingData(
          data,
          abortController.signal
        );
        commit(types.default.SET_USERS_DOWNLOAD_TABLE_UI_FLAG, {
          fetchingUserDownloadTable: false,
        });
        return response.data;
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.default.SET_USERS_DOWNLOAD_TABLE_UI_FLAG, {
            fetchingUserDownloadTable: false,
          });
        });
      }
      return {};
    }
  ),
  getOrders: async ({ commit }, data) => {
    commit(types.default.SET_ORDERS_UI_FLAG, { fetchingList: true });
    try {
      let response = null;

      if (!data.search) response = await ContactAPI.getOrders(data);
      else response = await OrdersAPI.searchOrders(data);

      response.data.contactId = data.contactId;
      response.data.append = data.append;

      commit(types.default.SET_ORDERS, response.data);
      commit(types.default.SET_ORDERS_UI_FLAG, { fetchingList: false });
    } catch (error) {
      commit(types.default.SET_ORDERS_UI_FLAG, { fetchingList: false });
    }
  },
  resetOrders: ({ commit }) => {
    commit(types.default.RESET_ORDERS, { fetchingList: true });
  },
  markContactOptOut: async (_, data) => {
    try {
      const response = await ContactAPI.markOptOut(data);
      return response.data;
    } catch (error) {
      // handle error
      throw new Error(error);
    }
  },
  fetchShopifyOrders: async ({ commit }, data) => {
    commit(types.default.SET_ORDERS_UI_FLAG, { fetchingList: true });
    try {
      const { shouldLoadMore, ...rest } = data;
      let response = await OrdersAPI.fetchShopifyOrders(rest);
      response.data.contactId = data.contactId;
      response.data.shouldLoadMore = shouldLoadMore;

      commit(types.default.SET_SHOPIFY_ORDERS, response.data);
      commit(types.default.SET_ORDERS_UI_FLAG, { fetchingList: false });
      return response;
    } catch (error) {
      // handle error
      commit(types.default.SET_ORDERS_UI_FLAG, { fetchingList: false });
      throw new Error(error);
    }
  },
  updateShopifyOrderAddress: async ({ commit }, data) => {
    commit(types.default.SET_DRAFT_ORDER_UI_FLAG, { creatingDraftOrder: true });
    try {
      let response = await OrdersAPI.updateShopifyOrderAddress(data);
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      commit(types.default.SET_ACTIVE_SHOPIFY_ORDER, response.data);
      return response;
    } catch (error) {
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      // handle error
      throw new Error(error);
    }
  },
  fetchShopifyShippingRates: async ({ commit }, data) => {
    commit(types.default.SET_DRAFT_ORDER_UI_FLAG, { creatingDraftOrder: true });
    try {
      let response = await OrdersAPI.fetchShopifyShippingRates(data);
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      commit(types.default.SET_ACCOUNT_SHIPPING_RATES, response.data);
      return response;
    } catch (error) {
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      // handle error
      throw new Error(error);
    }
  },
  fetchShopifyOrderDetail: async ({ commit }, data) => {
    commit(types.default.SET_DRAFT_ORDER_UI_FLAG, { creatingDraftOrder: true });
    try {
      let response = await OrdersAPI.fetchShopifyOrderDetail(data);
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      commit(types.default.SET_ACTIVE_SHOPIFY_ORDER, response.data);
      return response;
    } catch (error) {
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      // handle error
      throw new Error(error);
    }
  },
  fetchDirectShopifyOrderDetail: async ({ commit }, data) => {
    commit(types.default.SET_DRAFT_ORDER_UI_FLAG, { creatingDraftOrder: true });
    try {
      let response = await OrdersAPI.fetchDirectShopifyOrderDetail(data);
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      commit(types.default.SET_ACTIVE_SHOPIFY_ORDER, response.data);
      return response;
    } catch (error) {
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      // handle error
      throw new Error(error);
    }
  },
  createCODOrder: async ({ commit }, data) => {
    commit(types.default.SET_COD_ORDER_UI_FLAG, { creatingCodOrder: true });

    try {
      let response = await OrdersAPI.createCODOrder(data);
      commit(types.default.SET_COD_ORDER_UI_FLAG, { creatingCodOrder: false });
      // Reseting draft order
      commit(types.default.SET_DRAFT_ORDER, {});
      // reseting cart
      commit(types.default.EMPTY_CART);
      return response;
    } catch (error) {
      // handle error
      commit(types.default.SET_COD_ORDER_UI_FLAG, { creatingCodOrder: false });
      throw new Error(error);
    }
  },
  cancelOrder: async (_, data) => {
    try {
      let response = await OrdersAPI.cancelOrder(data);
      return response;
    } catch (error) {
      // handle error
      throw new Error(error);
    }
  },
  calculateOrderRefund: async (_, data) => {
    try {
      let response = await OrdersAPI.calculateOrderRefund(data);
      return response;
    } catch (error) {
      // handle error
      throw new Error(error);
    }
  },
  initiateOrderRefund: async (_, data) => {
    try {
      let response = await OrdersAPI.initiateOrderRefund(data);
      return response;
    } catch (error) {
      // handle error
      throw new Error(error);
    }
  },
  createDraftOrder: async ({ commit }, data) => {
    commit(types.default.SET_DRAFT_ORDER_UI_FLAG, { creatingDraftOrder: true });
    try {
      let response = await OrdersAPI.createDraftOrder(data);
      commit(types.default.SET_DRAFT_ORDER, response.data);
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      return response;
    } catch (error) {
      // handle error
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      throw new Error(error);
    }
  },
  resetDraftOrder: ({ commit }) => {
    commit(types.default.RESET_DRAFT_ORDER);
  },
  updateDraftOrder: async ({ commit }, data) => {
    commit(types.default.SET_DRAFT_ORDER_UI_FLAG, { creatingDraftOrder: true });
    try {
      let response = await OrdersAPI.updateDraftOrder(data);
      commit(types.default.SET_DRAFT_ORDER, response.data);
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      return response;
    } catch (error) {
      // handle error
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      throw new Error(error);
    }
  },
  initiatePrepaidOrder: async ({ commit }, data) => {
    try {
      let response = await OrdersAPI.initiatePrepaidOrder(data);
      commit(types.default.SET_DRAFT_ORDER, response.data);
      return response;
    } catch (error) {
      // handle error
      throw new Error(error);
    }
  },
  getProducts: async ({ commit }, data) => {
    commit(types.default.SET_PRODUCTS_UI_FLAG, { fetchingProducts: true });
    try {
      let response = await ProductsAPI.get(data);
      response.data.append = data.append;
      commit(types.default.SET_PRODUCTS, response.data);
      commit(types.default.SET_PRODUCTS_UI_FLAG, { fetchingProducts: false });
    } catch (error) {
      commit(types.default.SET_PRODUCTS_UI_FLAG, { fetchingProducts: false });
    }
  },
  // eslint-disable-next-line no-unused-vars
  getProductDetails: async ({ commit }, data) => {
    try {
      let response = await ProductsAPI.getProductDetails(data);
      return response;
    } catch (error) {
      // handle error
      return error;
    }
  },
  setCartInEditMode: ({ commit }, data) => {
    commit(types.default.SET_EDIT_MODE_CART, data);
  },
  getCustomerCart: async ({ commit }, data) => {
    try {
      let response = await ProductsAPI.getCustomerCart(data);
      commit(types.default.SET_CART, response.data);
      return response;
    } catch (error) {
      // handle error
      throw new Error(error);
    }
  },
  emptyCustomerCart: ({ commit }) => {
    commit(types.default.EMPTY_CART);
  },
  emptyOrders: ({ commit }) => {
    commit(types.default.EMPTY_ORDERS);
  },
  addProductToCart: async ({ commit }, data) => {
    try {
      let response = await ProductsAPI.addProductToCart(data);
      commit(types.default.ADD_PRODUCT_IN_CART, response.data);
      return response;
    } catch (error) {
      // handle error
      throw new Error(error);
    }
  },
  removeProductFromCart: async ({ commit }, data) => {
    commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
      creatingDraftOrder: true,
    });
    try {
      let response = await ProductsAPI.removeProductFromCart(data);
      commit(types.default.REMOVE_PRODUCT_FROM_CART, data.itemId);
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      return response;
    } catch (error) {
      // handle error
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      throw new Error(error);
    }
  },
  clearCart: async ({ commit }, data) => {
    commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
      creatingDraftOrder: true,
    });
    try {
      let response = await ProductsAPI.clearCart(data);
      commit(types.default.RESET_DRAFT_ORDER);
      commit(types.default.SET_CART, response.data.cart);
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      return response;
    } catch (error) {
      // handle error
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      throw new Error(error);
    }
  },
  getCustomer: async ({ commit }, data) => {
    try {
      let response = await CustomersAPI.getCustomer(data);
      commit(types.default.SET_CUSTOMER_DETAILS, response.data);
      return response;
    } catch (error) {
      // handle error
      throw new Error(error);
    }
  },
  clearCustomer: ({ commit }) => {
    commit(types.default.EMPTY_CUSTOMER_DETAILS);
  },
  addCustomerAddress: async ({ commit }, data) => {
    commit(types.default.ADD_ADDRESS_UI_FLAG, { creatingAddress: true });
    try {
      let response = await CustomersAPI.addCustomerAddress(data);
      commit(types.default.ADD_ADDRESS_UI_FLAG, { creatingAddress: false });
      commit(types.default.ADD_NEW_CUSTOMER_ADDRESS, response.data);
      commit(types.default.SET_SELETED_ADDRESS, response);
      return response;
    } catch (error) {
      // handle error
      commit(types.default.ADD_ADDRESS_UI_FLAG, { creatingAddress: false });
      throw new Error(JSON.stringify(error.response.data));
    }
  },
  updateCustomerAddress: async ({ commit }, data) => {
    try {
      let response = await CustomersAPI.updateCustomerAddress(data);
      commit(types.default.UPDATE_CUSTOMER_ADDRESS, response.data);
      return response;
    } catch (error) {
      // handle error
      throw new Error(error);
    }
  },
  deleteCustomerAddress: async ({ commit }, data) => {
    try {
      let response = await CustomersAPI.deleteCustomerAddress(data);
      commit(types.default.DELETE_CUSTOMER_ADDRESS, data.addressId);
      return response;
    } catch (error) {
      // handle error
      throw new Error(error);
    }
  },
  setCustomerAddress: async ({ commit }, data) => {
    try {
      let response = await CustomersAPI.updateCustomerAddress(data);
      commit(types.default.UPDATE_CUSTOMER_ADDRESS, response.data);
      return response;
    } catch (error) {
      // handle error
      throw new Error(error);
    }
  },
  setSelectedAddress: async ({ commit }, data) => {
    commit(types.default.SET_SELETED_ADDRESS, data);
  },
  setOrderMode: async ({ commit }, mode) => {
    commit(types.default.SET_ORDER_MODE, mode);
  },
  updateProductQuantity: async ({ commit }, data) => {
    commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
      creatingDraftOrder: true,
    });
    try {
      let response = await ProductsAPI.updateProductQuantity(data);
      commit(types.default.UPDATE_PRODUCT_IN_CART, response.data);
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      return response;
    } catch (error) {
      // handle error
      commit(types.default.SET_DRAFT_ORDER_UI_FLAG, {
        creatingDraftOrder: false,
      });
      throw new Error(JSON.stringify(error.response.data));
    }
  },
  // shopify end
  updateProduct: async ({ commit }, data) => {
    commit(types.default.SET_PRODUCTS_UI_FLAG, { updatingProduct: true });
    try {
      const response = await ProductsAPI.update(data);

      if (response.data === 'Product updated successfully!') {
        commit(types.default.UPDATE_PRODUCTS, data);
        commit(types.default.SET_PRODUCTS_UI_FLAG, { updatingProduct: false });
        return true;
      }

      return false;
    } catch (error) {
      commit(types.default.SET_PRODUCTS_UI_FLAG, { updatingProduct: false });
    }
    return false;
  },
  updateShopifyDomain: async (_, data) => {
    try {
      const response = await ShopifyAPI.update(data);
      if (
        response.data === 'Store created for this account' ||
        response.data === 'Store updated for this account'
      ) {
        return true;
      }
      return false;
    } catch (error) {
      // Add exception here.
    }
    return false;
  },
  deleteShopifyIntegration: async (_, data) => {
    try {
      const response = await ShopifyAPI.delete(data);
      if (response) return true;

      return false;
    } catch (error) {
      // Add exception here.
    }

    return false;
  },
  goToShopifyStore: async () => {
    try {
      const response = await ShopifyAPI.get();
      return response.data;
    } catch (error) {
      // Add exception here.
    }

    return {};
  },
  getAttendanceMetrics: async ({ commit }, data) => {
    commit(types.default.SET_ANALYTICS_UI_FLAG, {
      fetchingAgentMetrics: true,
    });
    try {
      let response = await AttendanceAPI.get(data);
      commit(types.default.SET_ATTENDANCE_METRICS, response.data);
      commit(types.default.SET_ANALYTICS_UI_FLAG, {
        fetchingAgentMetrics: false,
      });
    } catch (error) {
      commit(types.default.SET_ANALYTICS_UI_FLAG, {
        fetchingAgentMetrics: false,
      });
    }
  },
  getAttendanceLogs: async ({ commit }, data) => {
    commit(types.default.SET_ANALYTICS_UI_FLAG, {
      fetchingAgentMetrics: true,
    });
    try {
      let response = await AttendanceAPI.getLogs(data);
      commit(types.default.SET_ATTENDANCE_LOGS, response.data);
      commit(types.default.SET_ANALYTICS_UI_FLAG, {
        fetchingAgentMetrics: false,
      });
    } catch (error) {
      commit(types.default.SET_ANALYTICS_UI_FLAG, {
        fetchingAgentMetrics: false,
      });
    }
  },
  // eslint-disable-next-line no-unused-vars
  getAttendanceDownload: async ({ commit }, data) => {
    try {
      let res = {};
      if (data.userId > 0) {
        res = await AttendanceAPI.download_agent_attendance(data);
      } else {
        res = await AttendanceAPI.download_overall_attendance(data);
      }
      return res;
    } catch (error) {
      // handle error
    }
    return {};
  },

  // Shopify
  fetchIsShopifyClientStatus: async ({ commit }, accountId) => {
    const response = await ShopifyAPI.checkShopifyStatus(accountId);
    const isShopifyClient = response.data.status;
    if (isShopifyClient) {
      commit(types.default.SET_ACCOUNT_SHOPIFY_STATUS);
    }
  },

  // check payment enabled or not
  checkPaymentEnabled: async () => {
    try {
      const response = await OrdersAPI.checkPaymentEnabled();
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default actions;
