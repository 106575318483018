<template>
  <div class="column custom-scroll overflow-overlay">
    <woot-base-button
      v-if="!isNewPage"
      layer-fill="white"
      class="settings-button"
      :loading="isUpdating"
      @click="updateAgents"
    >
      Update
    </woot-base-button>
    <page-header
      v-if="isNewPage && inbox && inbox.name"
      :header-title="inbox.name"
    />
    <div class="settings-sub-menu-content--larger">
      <div>
        <span
          class="title-h5 text-dark mg-right--slab"
          v-text="
            isAFacebookInbox
              ? $t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS') + ' For'
              : $t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS')
          "
        />
        <span
          v-if="!isAFacebookInbox"
          class="body-b3 text-light"
          v-text="
            '(' + $t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS_SUB_TEXT') + ')'
          "
        />
        <div v-if="isAFacebookInbox" class="flex-row gap--micro mg-top--small">
          <woot-base-button
            :variant="showCommentCollaborators ? 'primary' : 'secondary'"
            size="small"
            @click="showCommentCollaborators = true"
          >
            Comments
          </woot-base-button>
          <woot-base-button
            :variant="showCommentCollaborators ? 'secondary' : 'primary'"
            size="small"
            @click="showCommentCollaborators = false"
          >
            Direct Messages
          </woot-base-button>
        </div>
      </div>
      <div
        class="flex-row flex-justify--between flex-align gap--slab mg-top--normal search-container"
      >
        <search-box
          placeholder="Search for Agent name/role"
          custom-class="search-box"
          :value="search"
          @setSearch="setSearch"
        />
        <woot-base-button
          style="height: 4.4rem"
          variant="secondary"
          @click="openAddPopup"
        >
          Add agent
        </woot-base-button>
      </div>
      <div class="pd-top--two bg-green mg-bottom--slab">
        <data-table
          :columns="tableColumns"
          :table-data="addedCollaborators"
          :loading="uiFlags.isFetching || isFetchingMembers"
          :enable-animations="false"
          title="Collaborators Added"
        >
          <template v-slot:actions="{ row: data }">
            <woot-base-button
              variant="secondary-danger"
              size="small"
              class="custom-button"
              @click="onRemoveMember(data)"
            >
              Remove
            </woot-base-button>
          </template>
          <template v-slot:zero-state>
            <span class="title-h5 text-light" v-text="'None'" />
          </template>
        </data-table>
      </div>
      <div class="pd-top bg-white mg-bottom--larger">
        <data-table
          :columns="tableColumns"
          :table-data="notAddedCollaborators"
          :loading="uiFlags.isFetching || isFetchingMembers"
          :enable-animations="false"
          title="Available Collaborators"
        >
          <template v-slot:actions="{ row: data }">
            <woot-base-button
              variant="secondary"
              size="small"
              class="custom-button"
              @click="onAddCollaborator(data)"
            >
              Add to inbox
            </woot-base-button>
          </template>
          <template v-slot:zero-state>
            <span class="title-h5 text-light" v-text="'None'" />
          </template>
        </data-table>
      </div>
    </div>
    <div
      v-if="isNewPage"
      class="medium-12 columns settings-sub-menu-bottom-nav"
    >
      <woot-base-button
        variant="secondary"
        @click.prevent="() => $router.go(-1)"
      >
        Back
      </woot-base-button>
      <woot-base-button
        :disabled="isAKnowlarityInbox && !addedCollaborators.length"
        @click="updateAgents"
      >
        Finish
      </woot-base-button>
    </div>

    <add-edit-agent
      v-if="showAgentPopup"
      :show="showAgentPopup"
      :agent-data="{}"
      :inbox-list="populateInboxData"
      @onClose="() => (showAgentPopup = false)"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';
import DataTable from 'dashboard/components/widgets/table/DataTable';
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import AddEditAgent from 'dashboard/routes/dashboard/settings/agents/AddEditAgent';

import inboxMixin from 'shared/mixins/inboxMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import alertMixin from 'shared/mixins/alertMixin';

import * as types from 'shared/constants/googleEventType';
import router from 'dashboard/routes/index';

export default {
  components: {
    PageHeader,
    SearchBox,
    DataTable,
    AddEditAgent,
  },
  mixins: [
    inboxMixin,
    googleAnalyticsMixin,
    alertMixin,
    mixPanelAnalyticsMixin,
  ],
  props: {
    inbox: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: '',
      inboxMembers: [],
      inboxMembersComment: [],
      isFetchingMembers: false,
      isUpdating: false,
      showCommentCollaborators: false,
      showAgentPopup: false,
      tableColumns: [
        {
          prop: 'avatar',
          component: 'AgentAvatar',
          width: '10%',
        },
        {
          prop: 'name',
          component: 'AgentNameWithStatus',
          width: '35%',
        },
        {
          component: 'RoleAndEmail',
          width: '30%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      records: 'agents/getAgents',
      uiFlags: 'agents/getUIFlags',
    }),
    isNewPage() {
      return this.$route.params.page === 'new';
    },
    addedCollaborators() {
      const inboxCollaborators = !this.showCommentCollaborators
        ? this.inboxMembers
        : this.inboxMembersComment;

      return inboxCollaborators.filter(
        item =>
          item.name.toLowerCase().search(this.search.toLowerCase()) > -1 ||
          item.role.toLowerCase().search(this.search.toLowerCase()) > -1
      );
    },
    notAddedCollaborators() {
      let collaborators = [];
      const members = !this.showCommentCollaborators
        ? this.inboxMembers
        : this.inboxMembersComment;

      collaborators = this.records.filter(agent => {
        return members.every(member => {
          return member.id !== agent.id;
        });
      });

      collaborators = collaborators.filter(
        item =>
          item.name.toLowerCase().search(this.search.toLowerCase()) > -1 ||
          item.role.toLowerCase().search(this.search.toLowerCase()) > -1
      );

      return collaborators;
    },
    populateInboxData() {
      const { inbox } = this;

      if (!inbox.id) return [];

      return [
        {
          id: inbox.id,
          name: inbox.name || this.channelName,
          channel_type: inbox.channel_type,
        },
      ];
    },
  },
  created() {
    if (!this.records.length) this.$store.dispatch('agents/get');
    this.fetchInboxMembers();
  },
  methods: {
    setSearch(value) {
      this.search = value;
    },
    onAddCollaborator(val) {
      if (this.showCommentCollaborators) {
        this.googleAnalyticsEvent(
          types.default.INBOX_ON_COMMENT_SELECT,
          types.default.SETTINGS_INBOX,
          val
        );
        this.inboxMembersComment.push(val);
        return;
      }

      this.googleAnalyticsEvent(
        types.default.INBOX_ON_AGENT_SELECT,
        types.default.SETTINGS_INBOX,
        val
      );
      this.inboxMembers.push(val);
    },
    onRemoveMember({ index }) {
      if (this.showCommentCollaborators) {
        this.googleAnalyticsEvent(
          types.default.INBOX_ON_REMOVE_COMMENT,
          types.default.SETTINGS_INBOX,
          types.default.SETTINGS_INBOX
        );
        this.inboxMembersComment.splice(index, 1);
        return;
      }

      this.googleAnalyticsEvent(
        types.default.INBOX_ON_REMOVE_AGENT,
        types.default.SETTINGS_INBOX,
        types.default.SETTINGS_INBOX
      );
      this.inboxMembers.splice(index, 1);
    },
    async updateAgents() {
      this.googleAnalyticsEvent(
        types.default.INBOX_UPDATE_AGENTS,
        types.default.SETTINGS_INBOX,
        types.default.SETTINGS_INBOX
      );
      this.isUpdating = true;
      try {
        await this.$store.dispatch('inboxMembers/create', {
          inboxId: this.inbox.id,
          agentList: this.inboxMembers.map(el => el.id),
          inboxType: 'message',
        });
        if (this.isAFacebookInbox) {
          await this.$store.dispatch('inboxMembers/create', {
            inboxId: this.inbox.id,
            agentList: this.inboxMembersComment.map(el => el.id),
            inboxType: 'comment',
          });
          this.isUpdating = false;
        }
        this.isUpdating = false;

        if (this.isNewPage)
          router.replace({
            name: 'settings_inbox_finish',
            params: {
              page: 'new',
              inbox_id: this.$route.params.inbox_id,
            },
          });

        this.showAlert(
          this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'),
          'success'
        );
      } catch (error) {
        this.isUpdating = false;
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'), 'error');
      }
    },
    async fetchInboxMembers() {
      this.isFetchingMembers = true;
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.inbox.id,
        });

        this.isFetchingMembers = false;

        const {
          data: { payload: inboxMembers },
        } = response;

        this.inboxMembers = inboxMembers.filter(
          item => item.inbox_type === 'message'
        );

        this.inboxMembersComment = inboxMembers.filter(
          item => item.inbox_type === 'comment'
        );
      } catch (error) {
        this.isFetchingMembers = false;
        //  Handle error
        this.showAlert('Error fetching inbox collaborators', 'error');
      }
    },
    openAddPopup() {
      this.showAgentPopup = true;
      this.mixPanelAddCollaboratorFromInboxSettingsInitiated();
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.custom-button {
  width: $space-slab * 10;
}

.search-container {
  background-color: $neutral-white;
  height: $space-four * 2;
  position: sticky;
  top: $zero;
  z-index: 1;
}

.search-box {
  flex-direction: row-reverse;
  margin-bottom: $zero;
  width: 82.6%;
}

.bg-green {
  ::v-deep {
    tr {
      background-color: $bg-green;

      td {
        border-top: $space-small solid $neutral-white;
      }
    }
  }
}

.bg-white {
  ::v-deep {
    tr {
      background-color: $neutral-white;

      td {
        border-top: $space-small solid $neutral-white;
      }
    }
  }
}
</style>
