<template>
  <div class="draggable-list">
    <table-zero-state
      :title="$t('SLA.LIST.EMPTY.TITLE')"
      :subtitle="$t('SLA.LIST.EMPTY.DESC')"
      asset-link="/brand-assets/inbox_zero_state.svg"
    >
      <woot-base-button
        size="medium"
        front-icon="plus"
        icon-view-box="0 0 20 20"
        @click="$emit('onCreate')"
      >
        {{ $t('SLA.CREATE.TITLE') }}
      </woot-base-button>
    </table-zero-state>
  </div>
</template>
<script>
import TableZeroState from 'dashboard/components/ui/settings/TableZeroState';

export default {
  components: { TableZeroState },
};
</script>
