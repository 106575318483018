<template>
  <woot-modal
    :show.sync="show"
    :on-close="onCancel"
    :show-close="false"
    small-modal
  >
    <div class="column content-box">
      <div class="column page-top-bar modal-header">
        <span class="page-sub-title title-h2">
          {{ $t('BROADCAST.BROADCAST_TAB.SAVE_AND_SCHEDULE.TITLE') }}
        </span>
      </div>
      <div class="row settings-form">
        <span class="text-dark body-b2 mg-bottom--medium">
          {{ $t('BROADCAST.BROADCAST_TAB.SAVE_AND_SCHEDULE.DESC') }}
        </span>
        <span class="medium-12 body-b2 text-light mg-bottom--small">
          {{ $t('BROADCAST.BROADCAST_TAB.SAVE_AND_SCHEDULE.SUBTITLE') }}
        </span>
        <div class="flex-row">
          <div class="date-picker mg-right">
            <date-picker
              :show-range="false"
              :value="selectedDate"
              variant="small"
              :disabled-future-dates="allowedDates"
              :disable-previous-date="true"
              @change="onChange"
            />
          </div>
          <div class="limechat-timepicker row">
            <div class="column flex-row-justify-center picker-container">
              <vue-timepicker
                v-model="selectedTime"
                hide-clear-button
                :minute-interval="15"
                format="HH:mm"
                :hour-range="enabledHours"
                :minute-range="enabledMinutes"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer mg-top">
          <woot-tertiary-button
            :name="$t('BROADCAST.BROADCAST_TAB.SAVE_AND_SCHEDULE.CANCEL')"
            :custom-style="{ color: '#FF422E' }"
            @click="onCancel"
          />

          <woot-primary-button
            :name="$t('BROADCAST.BROADCAST_TAB.SAVE_AND_SCHEDULE.BUTTON')"
            @click="onSaveAndSchedule(selectedDate, selectedTime)"
          />
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import VueTimepicker from 'vue2-timepicker';
import DatePicker from 'dashboard/components/ui/DatePicker';

export default {
  components: {
    VueTimepicker,
    DatePicker,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    onCancel: { type: Function, default: () => {} },
    onSaveAndSchedule: { type: Function, default: () => {} },
  },
  data() {
    return {
      selectedTime: { HH: '00', mm: '00' },
      selectedDate: new Date(),
    };
  },
  computed: {
    allowedDates() {
      let date = new Date();
      return new Date(date.setDate(date.getDate() + 15));
    },
    enabledHours() {
      const currentHour = new Date().getHours();
      if (
        new Date(this.selectedDate.toDateString()).getTime() ===
        new Date(new Date().toDateString()).getTime()
      )
        return [[currentHour, 23]];
      return null;
    },
    enabledMinutes() {
      const currentMinute = new Date().getMinutes();
      if (
        new Date(this.selectedDate.toDateString()).getTime() ===
        new Date(new Date().toDateString()).getTime()
      ) {
        if (parseInt(this.selectedTime.HH, 10) > new Date().getHours())
          return null;
        return [[currentMinute, 59]];
      }

      return null;
    },
  },
  methods: {
    onChange(value) {
      this.selectedDate = value;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';
.modal-header {
  @include elevation-1dp;
}
.modal-footer {
  padding: $zero;
}
.settings-form {
  padding: $space-normal;
}
</style>
