<template>
  <div class="summary-head flex-row flex-align flex-justify--between">
    <div class="flex-row gap--small">
      <thumbnail :src="avatarSource" size="2rem" />
      <div class="summary-bubble-heading">
        <b class="text-red new-badge">New</b>
        <span
          class="title-h5 text-blue"
          v-text="$t('CONVERSATION.GPT.HEADING')"
        />
      </div>
    </div>
    <span
      class="title-h6_m text-light"
      v-text="$t('CONVERSATION.GPT.SUB_HEADING')"
    />
  </div>
</template>
<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail';
import { BOT_AVATAR_IMAGE_URL } from 'shared/constants/messages';

export default {
  components: { Thumbnail },
  computed: {
    avatarSource() {
      return BOT_AVATAR_IMAGE_URL;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.summary {
  &-head {
    border-bottom: 0.5px solid $neutral-grey-400;
    background-color: $alice-blue;
    border-radius: $space-slab $space-slab $zero $zero;
    padding: $space-slab;

    .summary-bubble-heading {
      position: relative;

      .new-badge {
        position: absolute;
        top: -$space-small;
        right: -$space-medium;
      }
    }
  }
}
</style>
