<template>
  <div
    class="create-ticket-form-with-preview flex-row flex-justify--between gap--normal"
  >
    <form class="create-ticket-form custom-scroll" @submit.prevent>
      <add-contact-section
        class="CREATE_TICKET_CONTACTS"
        :selected-contacts="selectedContacts"
        @selectedContacts="selectedContacts = $event"
      />
      <add-template-section
        :inbox-id="inboxId"
        :number-of-substitutions="selectedContacts.length"
        @templateInputs="templateInputs = $event"
        @selectedTemplate="selectedTemplate = $event"
      />
      <assign-agent-or-team-section @select="onAgentOrTeamSelection" />
      <assign-tags-section
        tag-type="Conversation"
        @selectedLabels="selectedConvLabels = $event"
      />
      <assign-tags-section
        tag-type="Contact"
        @selectedLabels="selectedContactLabels = $event"
      />
      <div class="submit-button">
        <woot-base-button
          front-icon="rocket"
          :loading="uiFlags.isCreatingConversation"
          @click="onSubmit"
        >
          {{ $t('CREATE_TICKET.FORM.SUBMIT.TITLE') }}
        </woot-base-button>
      </div>
    </form>
    <whatsapp-ui
      class="whatsapp-ui-wrapper"
      :messages="messages"
      only-chat-screen
      custom-height="height: calc(100vh - 20rem)"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import AddContactSection from './AddContactSection';
import AddTemplateSection from './AddTemplateSection';
import AssignAgentOrTeamSection from '../shared/AssignAgentOrTeamSection';
import AssignTagsSection from '../shared/AssignTagsSection';
import WhatsappUi from 'dashboard/components/WhatsappUIWidget';

import broadcastMixin from 'dashboard/mixins/broadcast';

export default {
  components: {
    AddContactSection,
    AddTemplateSection,
    AssignAgentOrTeamSection,
    AssignTagsSection,
    WhatsappUi,
  },
  mixins: [broadcastMixin],
  props: {
    inboxId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedContacts: [],
      selectedTemplate: {},
      selectedContactLabels: [],
      selectedConvLabels: [],
      templateInputs: {
        media_url: '',
        dynamic_url: '',
        template_attributes: [],
      },
      selectedAgent: null,
      selectedTeam: null,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'getConversationUiFlags' }),
    messages() {
      return [
        {
          type: 'received',
          value: this.highlightedBrackets(this.selectedTemplate.body, true),
          doc: this.selectedTemplate?.template_type === 'document',
          img: this.selectedTemplate?.template_type === 'image',
          buttons: this.selectedTemplate?.buttons,
        },
      ];
    },
  },
  methods: {
    onAgentOrTeamSelection({ id, type }) {
      if (type === 'agent') this.selectedAgent = id;
      if (type === 'team') {
        // remove 'team' from the id before assigning to state
        const teamId = id.replace(/^team:/, '');
        this.selectedTeam = +teamId;
      }
    },
    preparePayload() {
      const {
        media_url,
        dynamic_url,
        template_attributes,
      } = this.templateInputs;

      const contacts = this.selectedContacts.map((contact, index) => ({
        ...contact,
        template_attributes: template_attributes[index],
      }));

      return {
        template_id: this.selectedTemplate.id,
        agent_id: this.selectedAgent,
        team_id: this.selectedTeam,
        has_dynamic_button: this.selectedTemplate.has_dynamic_button,
        dynamic_url,
        has_media: this.selectedTemplate.has_media,
        media_url,
        contacts,
        labels: this.selectedConvLabels.map(({ title }) => title),
        contact_labels: this.selectedContactLabels.map(({ title }) => title),
      };
    },
    onSubmit() {
      const payload = this.preparePayload();
      this.$emit('submit', payload);
    },
  },
};
</script>
