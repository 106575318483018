import teamIntroImage from 'assets/images/teams_intro.svg';

export const SHOWCASE_TEAMS_DATA = [
  {
    name: 'teams',
    key: 'TEAMS',
    color: '#4267B2',
    image: teamIntroImage,
    contentPath: 'TEAMS_SETTINGS',
  },
];
