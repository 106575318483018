<template>
  <div class="small-12">
    <div class="flex-space-between">
      <search-box
        :placeholder="$t('BROADCAST.BROADCAST_TAB.SEARCH_TXT')"
        :value="search"
        :variant="true"
        custom-class="custom-search"
        icon-size="mediumlarge"
        @setSearch="setSearch"
      />
      <div
        class="filter-button"
        :class="{ 'filter-button-active': isImgFilterActive }"
        @click="toggleImgFilter"
      >
        {{ $t('BROADCAST.BROADCAST_TAB.IMG_FILTER') }}
        <span>
          <icons
            name="filterBtn"
            :color="isImgFilterActive ? 'white' : 'green'"
            size="semimedium"
            view="0 0 20 20"
          />
        </span>
      </div>
    </div>
    <div
      :style="
        selectedTemplateId !== null && toggleTemplate
          ? { 'max-height': '24rem' }
          : { 'max-height': '35rem' }
      "
      class="custom-scroll"
    >
      <table v-if="templateList.length > 0" class="woot-table">
        <thead>
          <th
            v-for="thHeader in $t(
              'BROADCAST.BROADCAST_TAB.SELECT_TEMPLATE.TABLE_HEADER'
            )"
            :key="thHeader"
          >
            {{ thHeader }}
          </th>
        </thead>
        <tbody v-if="!uiFlags.fetchingTemplateList && templateList.length">
          <tr
            v-for="(item, index) in templateList"
            :key="index"
            :class="{
              'selected-template':
                selectedTemplateId === item.id && toggleTemplate,
            }"
            @click="selectTemplate(item.id)"
          >
            <td class="flex-row-center" style="height: 40px">
              <span class="mg-left--small mg-right--small check-icon">
                <icons
                  name="checked"
                  :color="
                    selectedTemplateId === item.id && toggleTemplate
                      ? 'green'
                      : 'lightestgrey'
                  "
                  size="medium"
                  view="0 0 24 24"
                />
              </span>
              <span>
                <span class="title-h5_m text-dark mg-right--small">
                  {{ item.display_id }}
                </span>
                <span
                  class="body-b2 text-light"
                  v-html="prepareContent(item.short_code)"
                ></span>
              </span>
            </td>
            <td style="width: 50%">
              <div class="flex-row-center">
                <div
                  v-if="
                    item.template_type == 'image' && item.media_url !== '{}'
                  "
                  class="mg-right--small template-img"
                >
                  <img :src="item.media_url" height="24px" width="24px" />
                </div>
                <div
                  class="text-truncate body-b2 text-light"
                  style="max-width:90%"
                  v-html="highlightedBrackets(prepareContent(item.body), false)"
                ></div>
              </div>
            </td>
            <td>
              <span class="body-b2 text-dark">
                Inbox - {{ item.inbox_id }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="!uiFlags.fetchingTemplateList && !templateList.length"
        class="body-b2 text-light flex-row-center flex-justify"
      >
        No Templates Found
      </div>
      <woot-loading-state
        v-if="uiFlags.fetchingTemplateList"
        :message="$t('TEMPLATE.LOADING')"
      />
    </div>
    <hr />
    <div
      v-if="selectedTemplateId !== null && toggleTemplate"
      class="selected-template-box custom-scroll"
    >
      <div class="mg-top mg-bottom  button-small text-light">
        Selected Template
      </div>
      <div
        class="body-b2 text-dark mg-left--micro"
        v-html="highlightedBrackets(selectedTemplate.body, true)"
      />
    </div>
    <div class="flex-row flex-justify--end mg-top--large">
      <woot-primary-button
        name="next"
        size="medium"
        :custom-style="{ width: '12rem' }"
        :disabled="selectedTemplateId === null || !toggleTemplate"
        @click="goToNext"
      />
    </div>
  </div>
</template>

<script>
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import alertMixin from 'shared/mixins/alertMixin';
import broadcastMixin from 'dashboard/mixins/broadcast';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import * as types from 'shared/constants/googleEventType';
import { mapGetters } from 'vuex';

export default {
  components: {
    SearchBox,
  },
  mixins: [alertMixin, broadcastMixin, googleAnalyticsMixin],
  data() {
    return {
      search: '',
      selectedTemplateId: null,
      templateParameterCount: 0,
      isImgFilterActive: false,
      toggleTemplate: false,
    };
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
      templates: 'getTemplates',
      uiFlags: 'getTemplateUIFlags',
    }),
    validTemplates() {
      let broadcastEnabledInboxes = this.broadcastEnabledChannel(this.inboxes);
      let validTemplates = this.templates.reduce((templateList, template) => {
        if (broadcastEnabledInboxes.includes(template.inbox_id)) {
          templateList.push(template);
        }

        return templateList;
      }, []);

      return validTemplates;
    },
    templateList() {
      let templateList = this.validTemplates.filter(item => {
        return (
          item.short_code.toLowerCase().search(this.search.toLowerCase()) >
            -1 ||
          item?.body?.toLowerCase().search(this.search.toLowerCase()) > -1
        );
      });
      if (this.isImgFilterActive) {
        return templateList.filter(
          template => template.template_type !== 'text'
        );
      }
      return templateList;
    },
    selectedTemplate() {
      return this.templates.filter(
        temp => temp.id === this.selectedTemplateId
      )[0];
    },
    selectedInbox() {
      let inbox = this.inboxes.find(
        item =>
          item.id ===
          this.templates.find(it => it.id === this.selectedTemplateId)?.inbox_id
      );
      return inbox
        ? {
            id: inbox.id,
            name: inbox.name,
            type: inbox.channel_type,
            phone: inbox.phone_number,
          }
        : {};
    },
  },
  created() {
    this.$store.dispatch('getTemplate');
  },
  methods: {
    setSearch(value) {
      this.search = value;
    },
    prepareContent(content = '') {
      if (this.search === '') {
        return content;
      }
      if (content)
        return content.replace(
          new RegExp(`(${this.search})`, 'ig'),
          '<span class="searchkey--highlight">$1</span>'
        );

      return '';
    },
    selectTemplate(id) {
      this.googleAnalyticsEvent(
        types.default.BROADCAST_SELECT_TEMPLATE,
        types.default.BROADCAST,
        id
      );

      this.selectedTemplateId = id;
      this.toggleTemplate = !this.toggleTemplate;
      let count = (this.selectedTemplate.body.match(/\{\}/g) || []).length;
      this.needsMediaHeader =
        this.selectedTemplate.has_media &&
        this.selectedTemplate.media_url === '{}';

      if (this.selectedTemplate.has_dynamic_button) count += 1;

      this.templateParameterCount = count;
    },

    toggleImgFilter() {
      this.isImgFilterActive = !this.isImgFilterActive;
    },
    goToNext() {
      this.$emit(
        'next',
        this.selectedTemplate,
        this.templateParameterCount,
        this.selectedInbox,
        this.selectedTemplateId,
        this.needsMediaHeader
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.custom-search {
  border: $zero;
  border-bottom: 1px solid $neutral-grey-500;
  border-radius: $zero !important;
  width: 55rem;
}

hr {
  margin: $space-normal $zero;
}
.selected-template-box {
  max-height: 12rem;
}
.filter-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $space-small;
  padding: $space-smaller $space-slab;
  border: 1px solid $pg-1-500;
  border-radius: $border-radius-smaller;
  color: $neutral-grey-800;
  font-weight: $font-weight-black;
  line-height: $height-h6;
  font-size: $font-size-mini;
  cursor: pointer;

  &:hover {
    border-width: 1.5px;
  }
}
.filter-button-active {
  background: $pg-1-500;
  color: $color-white;
}

.woot-table {
  table-layout: fixed;
  > thead {
    th {
      padding: $space-slab $zero;
    }
    > th:first-child {
      padding-left: 4rem;
      width: 30%;
    }
    > th:nth-child(2) {
      width: 60%;
      padding-right: $space-large;
    }
  }
  tbody tr {
    border: none;
    padding: 0;
    height: 4rem;
    cursor: pointer;
    &:hover {
      background: $neutral-grey-200;
      td {
        .check-icon {
          opacity: 1;
        }
      }
    }

    td {
      padding: $zero $space-micro;
      .check-icon {
        opacity: 0;
      }
    }
  }

  .template-img {
    min-width: $space-medium;
    &:hover {
      transform: scale(1.5);
      transition: all 0.5s;
    }
  }
  .selected-template {
    background: $pg-light-1;
    .check-icon {
      opacity: 1;
    }
  }
}

.next-button {
  position: absolute;
  bottom: $space-slab;
  right: 7rem;
}
</style>
