import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({ chatList: 'getAllConversations' }),
  },
  methods: {
    findConversation(id = null) {
      const conversationId = parseInt(id, 10);
      const [chat] = this.chatList.filter(c => c.id === conversationId);
      return chat;
    },
    getConversationById(id) {
      const chat = this.findConversation(id);

      if (!chat) return this.$store.dispatch('getConversation', id);

      return Promise.resolve(chat);
    },
  },
};
