import axios from 'axios';
import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import labelMixin from 'shared/mixins/labelsMixin';

import { DuplicateContactException } from 'shared/helpers/CustomErrors';

export default {
  mixins: [alertMixin, mixPanelAnalyticsMixin, labelMixin],
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      accountId: 'getCurrentAccountId',
    }),
    account() {
      return this.$store.getters['accounts/getAccount'](this.accountId);
    },
    companyName() {
      return this.account.name || '';
    },
    conversationId() {
      return this.currentChat.id;
    },
    activeLabelList() {
      return this.filterClientLabels(
        this.$store.getters['conversationLabels/getConversationLabels'](
          this.conversationId
        )
      );
    },
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    chatAssignee() {
      return this.currentChat?.meta?.assignee;
    },
    agentInfo() {
      return this.$store.getters['agents/getAgent'](this.chatAssignee?.id);
    },
  },
  methods: {
    getTicketObject(payload) {
      payload = {
        ...payload,
        displayId: this.conversationId,
        company_name: this.companyName,
        name: this.contact.name,
        selected_labels: this.activeLabelList,
        agentname: this.agentInfo?.name,
        agentId: this.agentInfo?.id,
        agentEmail: this.agentInfo?.email,
      };
      return payload;
    },
    async createTicket(payload) {
      this.appendLinkToDescription(payload);

      try {
        await this.$store.dispatch(
          'crm/raiseTicket',
          this.getTicketObject(payload)
        );
        this.mix_panel_added_crm_ticket();
        this.showAlert('Ticket created!', 'info');
      } catch (error) {
        this.handleTicketCreationError(error);
      }
    },
    appendLinkToDescription(payload) {
      if (!payload.description.includes('Link : ')) {
        payload.description += ` \n\n\nLink : ${window.location.href}`;
      }
    },
    handleTicketCreationError(error) {
      if (axios.isAxiosError(error)) {
        this.showAlert(error?.response?.data?.message, 'error');
      } else if (error instanceof DuplicateContactException) {
        this.showAlert(this.$t('EDIT_CONTACT.CONTACT_ALREADY_EXIST'), 'info');
      } else {
        this.showAlert(this.$t('Unable to create ticket!'), 'error');
      }
    },
  },
};
