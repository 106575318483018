<template>
  <div class="device">
    <div :class="{ screen: !onlyChatScreen }">
      <div class="screen-container">
        <div class="chat">
          <div class="chat-container">
            <div v-if="!onlyChatScreen" class="user-bar">
              <div class="back">
                <icons
                  name="chevronLeft"
                  color="lightgrey"
                  view-box="0 0 30 30"
                  size="medium"
                />
              </div>
              <div v-if="userAvatar" class="avatar">
                <img :src="userAvatar" />
              </div>
              <div class="name">
                <span>{{ name }}</span>
              </div>
              <div class="actions more">
                <icons name="threeDot" color="white" size="semimedium" />
              </div>
              <div class="actions attachment">
                <icons name="phone" color="white" size="semimedium" />
              </div>
              <div class="actions">
                <icons name="videoCall" color="white" size="semimedium" />
              </div>
            </div>
            <div
              :style="chatBoxHeight"
              class="whatsapp-conversation"
              :class="{ expanded: onlyChatScreen }"
            >
              <div v-if="!onlyChatScreen" class="date">
                <span class="today body-b3">Today</span>
              </div>
              <div class="conversation-container custom-scroll">
                <div
                  v-for="(item, index) in messages"
                  :key="index"
                  style="max-width: 85%;"
                  class="flex-column"
                >
                  <div class="message" :class="item.type">
                    <div v-if="item.img" class="img mg-bottom--smaller">
                      <img v-if="item.src" :src="item.src" />
                      <div class="img-placeholder"></div>
                    </div>
                    <div v-if="item.doc" class="flex-column">
                      <div class="doc"></div>
                      <div class="doc-name flex-row-center">
                        <span>document.pdf</span>
                      </div>
                    </div>
                    <span v-html="item.value" />
                    <span class="metadata">
                      <span
                        class="time"
                        v-text="
                          new Date().toLocaleString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true,
                          })
                        "
                      />
                      <span v-if="item.type == 'sent'" class="tick">
                        <icons name="tick" color="tick" view="0 0 16 16" />
                      </span>
                    </span>
                  </div>
                  <div
                    v-if="item.buttons && item.buttons.buttons"
                    style="flex-wrap: wrap"
                    class="whatsapp-ctas-container flex-row gap--smaller"
                  >
                    <woot-base-button
                      v-for="(button, inx) in item.buttons.buttons"
                      :key="inx"
                      v-tooltip.bottom="button.url"
                      icon-size="semimedium"
                      icon-color="grey"
                      class="whatsapp-ctas"
                      size="small"
                      tag="span"
                      variant="tertiary"
                      :front-icon="
                        item.buttons.type === 'call_to_action' ? 'reply' : ''
                      "
                    >
                      {{ button.title }}
                    </woot-base-button>
                  </div>
                </div>
              </div>

              <div v-if="!onlyChatScreen" class="conversation-compose">
                <div class="emoji">
                  <icons name="emoji" color="grey" size="medium" />
                </div>
                <input
                  class="input-msg"
                  style="border-radius: 0px !important; height:38px"
                  name="input"
                  placeholder="Type a message"
                  autocomplete="off"
                  autofocus
                />
                <div class="photo">
                  <div class="photo-icons">
                    <icons name="clip" color="grey" size="medium" />
                  </div>
                  <div class="photo-icons">
                    <icons
                      name="camera"
                      color="stroke-lightgrey"
                      size="medium"
                      view="0 0 16 16"
                    />
                  </div>
                </div>
                <button class="send">
                  <div class="circle">
                    <icons name="mic" color="white" size="medium" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: 'John Doe',
    },
    customHeight: {
      type: String,
      default: '',
    },
    userAvatar: {
      type: String,
      default:
        'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200',
    },
    onlyChatScreen: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    chatBoxHeight() {
      if (this.customHeight) return this.customHeight;
      if (!this.onlyChatScreen) return '';
      return '100%';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.screen {
  text-align: left;
  width: $space-large * 10;
}

.screen-container {
  height: 100%;
}

.status-bar {
  height: 2.5rem;
  background: $whatsapp-status;
  color: $color-white;
  font-size: $font-size-small;
  padding: $zero $space-small;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  width: $space-larger * 10;
  justify-content: right;
}

.status-bar:after {
  content: '';
  display: table;
  clear: both;
}

.status-bar div {
  float: right;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: $space-one $zero $zero $space-six;
  font-weight: $font-weight-bold;
}

.chat {
  border-radius: $border-radius * 2;
  height: calc(100% - 6.9rem);
}

.chat-container {
  height: 100%;
}

.user-bar {
  height: 4rem;
  background: $whatsapp-user-bar;
  color: $color-white;
  padding: $zero $space-small;
  font-size: $font-size-big;
  position: relative;
  z-index: 1;
  border-top-left-radius: 1.6rem;
  border-top-right-radius: 1.6rem;
}

.user-bar:after {
  content: '';
  display: table;
  clear: both;
}

.user-bar div {
  float: left;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}

.user-bar .actions {
  float: right;
}

.user-bar .actions img {
  height: $height-h2;
}
.user-bar .actions.more {
  margin: $zero $space-slab $zero $space-two;
}

.user-bar .actions.attachment {
  margin: $zero $zero $zero $space-two;
}

.user-bar .actions.attachment i {
  display: block;
  /*   transform: rotate(-45deg); */
}

.user-bar .avatar {
  margin: $zero $zero $zero 0.5rem;
  width: 2.5rem;
  height: 2.4rem;
}

.user-bar .avatar img {
  border-radius: 50%;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  display: block;
  width: 100%;
}

.user-bar .name {
  font-size: $font-size-default;
  font-weight: 400;
  color: #d3dae0;
  text-overflow: ellipsis;
  letter-spacing: 0.3px;
  margin: $zero $zero $zero $space-small;
  overflow: hidden;
  white-space: nowrap;
  width: 11rem;
}

.user-bar .status {
  display: block;
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
  letter-spacing: $zero;
}

.whatsapp-conversation {
  height: calc(100% - 12px);
  width: 100%;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  position: relative;
  background: url('~widget/assets/images/bg_chat.png') repeat;
  background-color: $whatsapp-chat-background;
  z-index: 0;
  display: flex;
  flex-direction: column;

  .conversation-container {
    height: calc(100% - 68px);
    width: 100%;
    padding: $zero $space-normal;
    max-height: 40rem;
    min-height: 35rem;
    margin-bottom: 1.6rem;

    .whatsapp-ctas-container {
      background-color: rgb(235, 230, 225);
      border-top: $space-six solid rgb(235, 230, 225);
      bottom: $zero;
      margin-top: $space-micro;
      position: sticky;

      .whatsapp-ctas {
        background-color: $pg-light-1;
        color: $pg-1-600;
        flex-grow: 1;

        ::v-deep .icon {
          fill: $pg-1-600;
        }

        &:hover {
          background-color: $pg-1-100;
          color: $pg-1-700;

          ::v-deep .icon {
            fill: $pg-1-700;
          }
        }
      }
    }

    &:after {
      content: '';
      clear: both;
    }
  }
}

.expanded {
  border-radius: $zero;

  .conversation-container {
    height: 100%;
    max-height: none;
  }
}

.date {
  margin-top: 0.8rem;
  display: flex;
  justify-content: center;
}
.today {
  background: #dddde9;
  padding: 0.3rem 0.6rem;
  border-radius: 0.4rem;
  color: #3c3c43;
  text-transform: uppercase;
}

.message {
  color: $neutral-black;
  clear: both;
  line-height: $height-h7;
  font-size: $font-size-small;
  padding: $space-small;
  position: relative;
  margin: $space-small $zero;
  word-wrap: break-word;
  z-index: -1;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
}

.message:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
}

.metadata {
  display: inline-block;
  float: right;
  padding: $zero $zero $zero 0.7rem;
  position: relative;
  bottom: -$space-smaller;
}

.metadata .time {
  color: rgba(0, 0, 0, 0.45);
  font-size: $font-size-mini;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-left: $space-micro;
  position: relative;
  top: $space-smaller;
  height: $space-normal;
  width: $space-normal;
}

.metadata .tick svg {
  position: absolute;
  transition: 0.5s ease-in-out;
}

.metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
  transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
  transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
  transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
  transform: perspective(800px) rotateY(-179.9deg);
}

.message:first-child {
  margin: $space-normal $zero $zero;
}

.message.received {
  background: #f9f9f9;
  border-radius: $space-small;
  float: left;
}

.message.received .metadata {
  padding: $zero $zero $zero $space-normal;
}

.message.received:after {
  bottom: -0.7rem;
  left: -1.6rem;
  content: '';
  position: absolute;
  border: 0 solid transparent;
  border-top: 9px solid #f9f9f9;
  border-radius: 0 20px 0;
  width: 15px;
  height: 30px;
  transform: rotate(111deg);
}

.message.sent {
  background: #dcf8c7;
  border-radius: 0.5rem $zero 0.5rem 0.5rem;
  float: right;
}

.img {
  height: auto;
  width: 100%;
  max-width: 25rem;
}
.img-placeholder {
  width: 100%;
  min-height: 11.8rem;
  background: #c4c4c4;
  border-radius: $space-smaller;
}

.doc {
  width: 100%;
  max-width: 100%;
  min-height: 10rem;
  background: #c4c4c4;
  border-top-left-radius: $space-smaller;
  border-top-right-radius: $space-smaller;
}
.doc-name {
  font-size: 1.7rem;
  color: #46484b;
  font-weight: 400;
  line-height: 2.2rem;
  background: #eaeaea;
  padding: $space-small;
  display: flex;
  border-bottom-left-radius: $space-smaller;
  border-bottom-right-radius: $space-smaller;
  margin-bottom: $space-smaller;
}
.message.sent:after {
  border-width: $zero $zero $space-one $space-one;
  border-color: transparent transparent transparent #dcf8c7;
  top: $zero;
  right: -$space-one;
}

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 4rem;
  width: 100%;
  z-index: 2;
  margin-bottom: 1.5rem;
}

.conversation-compose div,
.conversation-compose input {
  background: $color-white;
  height: 100%;
}

.conversation-compose .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-white;
  border-radius: 50% 0 0 50%;
  flex: 0 0 auto;
  margin-left: $space-small;
  width: 3.8rem;
  height: 3.8rem;
}

.conversation-compose .input-msg {
  border: 0;
  flex: 1 1 auto;
  font-size: 14px;
  margin: $zero;
  outline: none;
  color: #707980;
  border-radius: $zero;
  min-width: 5rem;
  height: 3.6rem;
}

.conversation-compose .photo {
  flex: 0 0 auto;
  border-radius: 0 30px 30px 0;
  text-align: center;
  width: auto;
  display: flex;
  padding-right: $space-two;
  height: 3.8rem;
}

.photo-icons {
  margin-left: $space-one;
}
.conversation-compose .send {
  background: transparent;
  border: $zero;
  cursor: pointer;
  flex: 0 0 auto;
  margin-right: 8px;
  padding: $zero;
  position: relative;
  outline: none;
  margin-left: 0.5rem;
}

.conversation-compose .send .circle {
  background: #008a7c;
  border-radius: 50%;
  color: #fff;
  position: relative;
  width: 3.8rem;
  height: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conversation-compose .send .circle i {
  font-size: $font-size-big;
  margin-left: 0.1rem;
}
</style>
