<template>
  <div class="sidebar-labels-wrap">
    <div
      v-if="contactLabels.length || systemLabels.length"
      class="body-b3 text-light mg-top--slab"
    >
      {{ $t('CONTACT_PANEL.ASSIGNED_TAGS.LABEL') }}
    </div>
    <span v-if="showAddButton" class="add-btn" @click="showLabelsModal = true">
      <icons
        name="circleAdd"
        color="stroke-green"
        view="0 0 24 24"
        size="medium"
      />
    </span>
    <woot-modal
      :show.sync="showLabelsModal"
      :on-close="hideModal"
      :small-width="true"
    >
      <div class="column content-box">
        <woot-modal-header header-title="Add Tags for Customer" />
        <div class="row settings-form">
          <div style="margin-bottom: 1.2rem" class="label-container columns">
            <woot-chips
              v-for="label in contactLabels"
              :key="label.id"
              :title="label.title"
              :custom-style="{
                'border-color': labelColor(label),
                color: labelColor(label),
              }"
              :description="label.description"
              variant="secondary-small"
              :show-cancel-icon="true"
              :show-tooltip="false"
              @click="onRemove"
            />
            <div v-if="!contactLabels.length" class="no--label">
              {{ $t('CONTACT_PANEL.LABELS.NO_AVAILABLE_CONTACT_LABELS') }}
            </div>
          </div>
          <labels
            :modal-mode="showLabelsModal"
            :selected-tags="contactLabels"
            :on-label-select="onSelect"
            resource="Contact"
          />
        </div>
      </div>
    </woot-modal>
    <div v-if="!labelUiFlags.isFetching" class="contact-conversation--list row">
      <div class="label-container columns">
        <woot-chips
          v-for="label in labelsToShow"
          :key="label.id"
          :title="label.title"
          :custom-style="{
            'border-color': labelColor(label),
            color: '#3C492C',
          }"
          :description="label.description"
          variant="secondary-small"
          :show-cancel-icon="!readOnly"
          :show-tooltip="false"
          @click="onRemove"
        />

        <div
          v-if="!contactLabels.length"
          class="body-b2 text-dark mg-bottom--small"
          v-text="
            placeholder ||
              $t('CONTACT_PANEL.LABELS.NO_AVAILABLE_CONTACT_LABELS')
          "
        />
      </div>
    </div>
    <spinner v-else />
    <div v-if="!showAllLabelChips">
      <div
        v-if="contactLabels.length > 3 || systemLabels.length"
        class="see-more-conv body-b3"
        @click="showAllLabelChips = true"
      >
        See More
        {{ contactLabels.length > 3 ? `(${contactLabels.length - 3})` : '' }}
      </div>
    </div>
    <div v-else>
      <woot-chips
        v-for="label in contactLabels.slice(3)"
        :key="label.id"
        :title="label.title"
        :custom-style="{
          'border-color': labelColor(label),
          color: '#3C492C',
        }"
        :description="label.description"
        variant="secondary-small"
        :show-cancel-icon="!readOnly"
        :show-tooltip="false"
        @click="onRemove"
      />
      <div v-if="systemLabels && systemLabels.length" class="mg-top--small">
        <span class="body-b3 text-light">{{
          $t('CONTACT_PANEL.SYSTEM_TAGS.LABEL')
        }}</span>
        <div class="contact-conversation--list row">
          <woot-chips
            v-for="label in systemLabels"
            :key="label.id"
            :title="label.title"
            :custom-style="{
              'border-color': labelColor(label),
              color: '#3C492C',
            }"
            :description="label.description"
            variant="secondary-small"
            :show-tooltip="false"
          />
          <div
            v-if="!systemLabels.length"
            class="body-b2 text-dark mg-bottom--small"
          >
            {{ $t('CONTACT_PANEL.LABELS.NO_AVAILABLE_CONTACT_LABELS') }}
          </div>
        </div>
      </div>
      <div class="see-more-conv body-b3" @click="showAllLabelChips = false">
        See Less
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Labels from 'dashboard/routes/dashboard/settings/labels/Index';
import Spinner from 'shared/components/Spinner';

import labelsMixin from 'shared/mixins/labelsMixin';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    Spinner,
    Labels,
  },
  mixins: [labelsMixin, alertMixin],
  props: {
    contactId: {
      type: [String, Number],
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: false,
    },
    contactLabels: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showLabelsModal: false,
      showAllLabelChips: false,
    };
  },
  computed: {
    ...mapGetters({
      labelUiFlags: 'contactLabels/getUIFlags',
    }),
    systemLabels() {
      return this.contactLabels.filter(
        ({ label_type }) => label_type === 'system'
      );
    },
    labelsToShow() {
      if (this.contactLabels.length > 3) return this.contactLabels.slice(0, 3);
      return this.contactLabels;
    },
  },
  watch: {
    contactId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.showAllLabelChips = false;
      }
    },
  },
  methods: {
    onUpdateLabels(label, action) {
      this.$emit('updateLabels', label, action);
    },
    hideModal() {
      this.showLabelsModal = false;
    },
    onSelect(option, action) {
      this.onUpdateLabels(option, action);
    },
    onRemove(label) {
      const selectedLabel = this.contactLabels.filter(
        activeLabel => activeLabel.title === label
      );

      this.onUpdateLabels(selectedLabel.pop(), 'remove');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.settings-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $space-medium;
  padding-top: $zero;

  &::v-deep .search {
    width: 100%;
    margin-left: $zero;
    margin-right: $zero;
  }
}

.add-btn {
  cursor: pointer;
  position: absolute;
  top: $space-slab;
  right: $space-slab;
}

.contact-conversation--list {
  padding-top: $space-small;

  .label-container {
    flex: 0 1 auto;
    flex-wrap: wrap;
    display: flex;
    max-width: 100%;

    .label {
      max-width: 100%;
    }
  }
}

.no--label {
  font-size: $font-size-small;
  color: $neutral-grey-600;
}

.error {
  color: $alert-color;
  font-size: $font-size-mini;
  font-weight: $font-weight-medium;
}

.see-more-conv {
  display: flex;
  justify-content: flex-end;
  color: $secondary-blue;
  cursor: pointer;
  padding-top: $space-small;

  &:hover {
    color: $facebook-blue;
  }
}
</style>
