<template>
  <section>
    <form-section-header
      :heading="$t('CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.HEADING')"
      :sub-heading="
        $t('CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.SUB_HEADING')
      "
      icon="template"
    />
    <woot-input
      :label="
        $t('CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.SUBJECT.LABEL')
      "
      class="CREATE_TICKET_SUBJECT"
      style="max-width: 50rem; margin-left: 4.8rem; margin-bottom: 1.6rem"
      size="small"
      type="text"
      required
      :placeholder="
        $t(
          'CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.SUBJECT.PLACEHOLDER'
        )
      "
      @input="$emit('subjectInput', $event)"
    />
    <div
      class="flex-row flex-align mg-bottom--small"
      style="margin-left: 4.8rem"
    >
      <span title="required" class="dot-circle" />
      <span
        class="title-h5 text-dark"
        v-text="
          $t('CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.BODY.LABEL')
        "
      />
    </div>
    <div class="email-body-editor form-input">
      <div class="reply-box-icons">
        <file-upload
          v-model="files"
          :multiple="true"
          :size="4096 * 4096"
          accept="image/*, application/pdf, audio/mpeg, video/mp4, audio/ogg, .zip, .csv"
          @input-file="onFileUpload"
        >
          <icons
            name="clip"
            size="semimedium"
            color="darkestgrey"
            title="Upload file(s)"
          />
        </file-upload>
      </div>
      <text-editor
        class="CREATE_TICKET_BODY"
        auto-focus
        is-menu-bar-required
        is-format-mode
        no-border
        for-email
        :height="13"
        :placeholder="
          $t(
            'CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.BODY.PLACEHOLDER'
          )
        "
        @input="$emit('messageInput', $event)"
      />
      <div
        v-if="files.length && attachmentPreview"
        class="flex-row flex-justify--start uploaded-files-container"
      >
        <div v-for="file in files" :key="file.id">
          <email-file
            :id="`${file.id}`"
            :attachment-type="file.type"
            :attachment-name="file.name"
            :attachment-size="file.size"
            :attachment-url="file.url"
            can-delete
            @clear="clearAttachments"
          />
        </div>
      </div>
    </div>
    <div class="signature-switch flex-row">
      <span
        class="title-h5 text-dark"
        v-text="
          $t(
            'CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.SIGNATURE.HEADING'
          )
        "
      />
      <woot-switch
        :id="Math.random()"
        :value="hasEmailSignature"
        @click="toggleEmailSignature"
      />
    </div>
    <div class="signature-switch mg-top--smaller">
      <span
        class="body-b2 text-light"
        v-text="
          $t(
            'CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.SIGNATURE.SUB_HEADING'
          )
        "
      />
    </div>
  </section>
</template>
<script>
import FileUpload from 'vue-upload-component';

import FormSectionHeader from '../shared/FormSectionHeader';
import TextEditor from 'shared/components/TextEditor';
import EmailFile from 'dashboard/components/widgets/conversation/bubble/EmailFile.vue';

import downloadMixin from 'shared/mixins/downloadMixin';

export default {
  components: { FormSectionHeader, TextEditor, FileUpload, EmailFile },
  mixins: [downloadMixin],
  data() {
    return {
      files: [],
      attachmentPreview: false,
      hasEmailSignature: false,
    };
  },
  methods: {
    onFileUpload(newFile, oldFile) {
      this.addImagePreviewUrl(newFile, oldFile);
      if (newFile && !oldFile) {
        this.$emit('fileInput', this.files);
        this.attachmentPreview = true;
        setTimeout(() => {
          if (this.attachmentPreview) this.scrollToBottom();
        }, 200);
      }
    },
    clearAttachments(fileId) {
      if (!fileId) this.files = [];
      this.files = this.files.filter(file => file.id !== fileId);
      if (this.files.length === 0) {
        this.attachmentPreview = false;
      }
      this.$emit('fileInput', this.files);
    },
    scrollToBottom() {
      const emailReplyBox = document.querySelector('.reply-box-icons');
      emailReplyBox.scrollTo({
        top: emailReplyBox.scrollHeight,
        behavior: 'smooth',
      });
    },
    toggleEmailSignature() {
      this.hasEmailSignature = !this.hasEmailSignature;
      this.$emit('toggleEmailSignature', this.hasEmailSignature);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.dot-circle {
  background-color: $warn-red-400;
  border-radius: 50%;
  display: inline-block;
  margin-right: $space-smaller;
  height: $space-smaller;
  width: $space-smaller;
}
.email-body-editor {
  margin-left: $space-larger;
  position: relative;
  max-width: $space-mega * 5;
  border-radius: $space-smaller;

  ::v-deep .ProseMirror-menubar-wrapper {
    height: $space-normal * 10;

    .ProseMirror-menubar {
      top: auto;
      bottom: -$space-slab;
      left: 3.5rem;
      z-index: 0;
    }
  }
}
.reply-box-icons {
  padding: $zero $space-one $zero $space-normal;
  border-right: 1px solid #d9d9d9;
  width: 3.6rem;
  height: 1.5rem;
  position: absolute;
  bottom: 0.5rem;

  .file-uploads {
    width: 1.5rem;

    ::v-deep label {
      cursor: pointer;
    }
  }
}
.uploaded-files-container {
  gap: $space-one $space-medium;
  flex-wrap: wrap;
  margin: $zero $space-two $space-large;
  width: 100%;

  ::v-deep .cancel--preview {
    z-index: 0;
  }
}
.signature-switch {
  margin-left: $space-larger;
  margin-top: $space-one;

  &.mg-top--smaller {
    margin-top: $space-smaller;
  }
}
</style>
