<template>
  <div class="view-box-new columns">
    <settings-header
      icon="graph"
      :header-title="$t('ANALYTICS.HEADER.ANALYTICS')"
      :show-back-button="false"
    />

    <last-updated-time
      :key="computedScope"
      :scope="computedScope"
      class="flex-row flex-justify--end pd-right"
    />

    <div class="tabs-pane">
      <woot-tabs :index="selectedTabIndex" @change="onTabChange">
        <woot-tabs-item v-for="tab in tabs" :key="tab.key" :name="tab.name" />
      </woot-tabs>
    </div>
    <agent-performance v-if="selectedTabIndex == 0" />
    <agent-attendance v-if="selectedTabIndex == 1" />
  </div>
</template>

<script>
import SettingsHeader from '../settings/SettingsHeader';
import AgentPerformance from './tabs/AgentPerformance.vue';
import AgentAttendance from './tabs/AgentAttendance.vue';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import LastUpdatedTime from './components/LastUpdatedTime';

export default {
  components: {
    SettingsHeader,
    AgentPerformance,
    AgentAttendance,
    LastUpdatedTime,
  },
  mixins: [mixPanelAnalyticsMixin],
  data() {
    return {
      selectedTabIndex: 0,
    };
  },
  computed: {
    tabParameter() {
      const selectedTab = Number(this.$route.query?.tab);
      return !Number.isNaN(selectedTab) && selectedTab >= 0 ? selectedTab : 0;
    },
    computedScope() {
      const scopeMap = {
        0: 'agent',
        1: 'agent_attendance',
      };
      return scopeMap[this.selectedTabIndex];
    },
    tabs() {
      return [
        {
          key: 'agent_performance',
          name: this.$t('ANALYTICS.HEADER.PERFORMANCE'),
        },
        {
          key: 'agent_attendance',
          name: this.$t('ANALYTICS.HEADER.ATTENDANCE'),
        },
      ];
    },
  },
  mounted() {
    this.onTabChange(this.tabParameter, 'mounted');
  },
  methods: {
    onTabChange(selectedTabIndex, source) {
      if (source !== 'mounted') {
        this.mix_panel_clicked_agent_overview_tab(
          selectedTabIndex === 0
            ? this.$t('ANALYTICS.HEADER.PERFORMANCE')
            : this.$t('ANALYTICS.HEADER.ATTENDANCE')
        );
      }
      window.history.pushState(
        {},
        null,
        `${this.$route.path}?tab=${selectedTabIndex}`
      );
      this.selectedTabIndex = selectedTabIndex;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tabs-pane {
  position: -webkit-sticky;
  position: sticky;
  background-color: $chat-background;
  top: $zero;
  z-index: 1000;
  margin-top: $space-larger;
  padding-bottom: $space-six;
}
</style>
