export const ASSIGNMENT_MODES = {
  AUTO: 'auto',
  MANUAL: 'manual',
};

export const ASSIGNMENT_LOGIC = {
  LOAD_BALANCED: 'load_balanced',
  ROUND_ROBIN: 'round_robin',
};

export const ADD_INBOX_DEMO_VIDEO_URL =
  'https://ik.imagekit.io/vysbzd9hl/Add%20Inbox.mp4?updatedAt=1719939436997';
export const PLAYBOOK_URL =
  'https://www.notion.so/limechat/Understanding-Ticket-Assignment-Algorithms-on-LimeChat-CRM-f3a94022199c42c49313ba79f6972ebf';
export const FEATURE_DEMO_URL =
  'https://ik.imagekit.io/vysbzd9hl/TicketAssignment.mp4?updatedAt=1719936829177';
