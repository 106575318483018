import TurndownService from 'turndown';
import { removeStyles } from './rules';

/**
 * Creates and configures an instance of TurndownService.
 *
 * This service is tailored for converting HTML email content to Markdown
 * by removing unwanted CSS, inline styles, and attributes that are
 * often present in emails.
 *
 * @param {Array<Object>} customRules - An array of custom Turndown rules.
 * @returns {TurndownService} A configured instance of TurndownService.
 */
const createTurndownService = (customRules = []) => {
  const turndownService = new TurndownService({
    headingStyle: 'atx',
    codeBlockStyle: 'fenced',
    bulletListMarker: '-',
    emDelimiter: '_',
  });

  // core rules here
  turndownService.addRule('removeStyles', removeStyles);

  // custom rules here
  customRules.forEach(rule => {
    if (rule.name) {
      turndownService.addRule(rule.name, rule);
    }
  });

  return turndownService;
};

export default createTurndownService;
