import Cookies from 'js-cookie';
import router from 'dashboard/routes';
import mixpanelEvent, { setupMixpanelUser } from 'dashboard/helper/mixpanel';
import { accountIdFromPathname } from 'dashboard/helper/URLHelper';
import { getItemFromLocalStorage } from 'dashboard/helper/localStorage';
import { getRedirectPath } from '../../routes';

Cookies.defaults = { sameSite: 'Lax' };

export const setUser = user => {
  const { accounts, ...userData } = user;

  const inSevenDays = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
  Cookies.set('user', userData, { expires: inSevenDays });
  localStorage.setItem('userAccounts', JSON.stringify(accounts));

  try {
    setupMixpanelUser(user);
  } catch (e) {
    // handle error
  }
};

export const clearDataOnLogout = () => {
  Cookies.remove('user');
  localStorage.removeItem('userAccounts');
};

export const getShopifyStoreInfo = () => {
  const accessToken = getItemFromLocalStorage('shopify_access_token');
  if (!accessToken) {
    mixpanelEvent('NORMAL_SIGN_UP_INITIATED');
    return null;
  }

  const storeUrl = getItemFromLocalStorage('shopify_store_url');
  const countryCode = getItemFromLocalStorage('shopify_country_code');
  const countryName = getItemFromLocalStorage('shopify_country_name');
  const storeName = storeUrl.split('.')[0];

  mixpanelEvent('SHOPIFY_SIGN_UP_INITIATED');
  return {
    name: storeName,
    is_signup: true,
    source: 'shopify',
    store_url: storeUrl,
    shopify_access_token: accessToken,
    shopify_country_code: countryCode,
    shopify_country_name: countryName,
  };
};

export const redirectPostLogin = (currentAccountId, accounts) => {
  let redirectPath =
    localStorage.getItem('redirectPath') || window.location.pathname;
  const accountId = accountIdFromPathname(redirectPath);
  const hasAccessToAccount = accounts.some(account => account.id === accountId);

  localStorage.removeItem('redirectPath');

  if (hasAccessToAccount) {
    return router.replace(redirectPath);
  }

  return router.replace(getRedirectPath(currentAccountId));
};

export const handleLoginError = () => {
  router.replace({
    name: 'error',
    params: {
      errorSubtitle: 'Seems like you are not part of any account.',
      buttonTitle: 'Go back to login',
    },
  });
};
