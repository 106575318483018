<template>
  <div class="table-container">
    <data-table
      :actions="actionsList"
      collapse-actions
      :columns="tableColumns"
      :table-data="automationsList"
      :loading="loading"
      :enable-animations="automationsList.length <= 10"
      _class="scrollable-table custom-scroll"
      @select="viewAutomation"
      @clone="cloneAutomation"
      @delete="openDeletePopup"
    >
      <template v-slot:actions="{ row }">
        <div class="flex-row flex-align gap--large">
          <toggle-automation-rule
            v-if="canToggleRules"
            :data="row"
            @onToggle="toggleAutomation"
          />
          <woot-base-button
            tag="span"
            size="small"
            variant="tertiary"
            @click="openEditPopup(row)"
          >
            <icons
              name="editRound"
              size="medium"
              view="0 0 24 24"
              color="green"
            />
          </woot-base-button>
        </div>
      </template>
      <template v-slot:zero-state>
        <table-zero-state
          v-if="!automationsList.length"
          :title="$t('AUTOMATION.LIST.ZERO_STATE.TITLE')"
          :subtitle="$t('AUTOMATION.LIST.ZERO_STATE.SUBTITLE')"
          asset-link="/brand-assets/inbox_zero_state.svg"
        >
          <div class="flex-row flex-align gap--small">
            <woot-base-button
              v-if="selectedTabIndex === 0"
              size="medium"
              front-icon="plus"
              icon-view-box="0 0 20 20"
              @click="openAddPopup"
            >
              {{ $t('AUTOMATION.HEADER_BTN_TXT') }}
            </woot-base-button>
            <woot-base-button
              size="medium"
              variant="secondary"
              @click="changeSelectedTab"
            >
              {{ buttonText }}
            </woot-base-button>
          </div>
        </table-zero-state>
      </template>
    </data-table>
  </div>
</template>

<script>
import ToggleAutomationRule from './shared/ToggleAutomationRule';
import DataTable from 'dashboard/components/widgets/table/DataTable';
import TableZeroState from 'dashboard/components/ui/settings/TableZeroState';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    DataTable,
    TableZeroState,
    ToggleAutomationRule,
  },
  mixins: [alertMixin],
  props: {
    search: { type: String, default: '' },
    selectedTabIndex: { type: Number, default: 0 },
    actionsList: { type: Array, default: () => [] },
    tableColumns: { type: Array, default: () => [] },
    automationsList: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  computed: {
    canToggleRules() {
      return this.selectedTabIndex === 0;
    },
    buttonText() {
      return this.selectedTabIndex === 0
        ? this.$t('AUTOMATION.GO_TO_LIBRARY')
        : this.$t('AUTOMATION.GO_TO_AUTOMATION_RULES');
    },
  },
  methods: {
    toggleAutomation({ id: automationId, active: isActive }) {
      try {
        this.$store.dispatch('automations/update', {
          id: automationId,
          active: isActive,
        });
        const message = isActive
          ? this.$t('AUTOMATION.TOGGLE.ACTIVATION_SUCCESFUL')
          : this.$t('AUTOMATION.TOGGLE.DEACTIVATION_SUCCESFUL');
        this.showAlert(message, 'success');
      } catch (error) {
        this.showAlert(this.$t('AUTOMATION.EDIT.API.ERROR_MESSAGE'), 'error');
      }
    },
    viewAutomation(automation) {
      this.$emit('viewAutomation', automation);
    },
    openEditPopup(automation) {
      this.$emit('openEditPopup', automation);
    },
    cloneAutomation({ id }) {
      this.$emit('cloneAutomation', id);
    },
    openAddPopup() {
      this.$emit('openAddPopup');
    },
    changeSelectedTab() {
      this.$emit('changeSelectedTab', (this.selectedTabIndex + 1) % 2);
    },
    openDeletePopup() {
      this.$emit('openDeletePopup');
    },
  },
};
</script>
