<template>
  <div class="add-template-wrapper">
    <woot-base-button
      v-if="!selectedTemplate.body"
      variant="secondary"
      size="small"
      class="CREATE_TICKET_TEMPLATE_ID"
      @click="openTemplateSelectionModal"
    >
      {{ $t('CREATE_TICKET.FORM.SECTIONS.ADD_TEMPLATE.HEADING') }}
    </woot-base-button>
    <template-substitutions
      v-else
      :template="selectedTemplate"
      :media-url="templateInputs.media_url"
      :dynamic-url="templateInputs.dynamic_url"
      :number-of-substitutions="numberOfSubstitutions"
      @updateField="onUpdateField"
      @removeTemplate="onClearTemplate"
    />
    <!-- view template -->
    <woot-modal-new
      :show="showTemplateSelection"
      :on-close="() => (showTemplateSelection = false)"
      medium-width
    >
      <woot-modal-header-new
        :header-title="
          $t('CREATE_TICKET.FORM.SECTIONS.ADD_TEMPLATE.MODAL.HEADING')
        "
      />
      <div class="template-view-wrapper">
        <view-template
          :inbox-id="inboxId"
          :show-inbox="false"
          @select="template => (templateToPreview = template)"
        />
        <whatsapp-ui
          class="whatsapp-ui-wrapper"
          :messages="messages"
          only-chat-screen
          custom-height="height: calc(100vh - 42rem)"
        />
        <div class="modal-footer">
          <woot-base-button @click="onSubmit">
            Submit
          </woot-base-button>
        </div>
      </div>
    </woot-modal-new>
  </div>
</template>
<script>
import ViewTemplate from 'dashboard/routes/dashboard/settings/template/old/ViewTemplate';
import WhatsappUi from 'dashboard/components/WhatsappUIWidget';
import TemplateSubstitutions from './TemplateSubstitutions';

import broadcastMixin from 'dashboard/mixins/broadcast';
import showError from 'dashboard/helper/showError';

export default {
  components: { ViewTemplate, WhatsappUi, TemplateSubstitutions },
  mixins: [broadcastMixin],
  props: {
    numberOfSubstitutions: {
      type: Number,
      default: 0,
    },
    inboxId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showTemplateSelection: false,
      selectedTemplate: {},
      templateToPreview: {},
      templateInputs: {
        media_url: '',
        dynamic_url: '',
        template_attributes: [],
      },
    };
  },
  computed: {
    messages() {
      return [
        {
          type: 'received',
          value: this.highlightedBrackets(this.templateToPreview.body, true),
          doc: this.templateToPreview?.template_type === 'document',
          img: this.templateToPreview?.template_type === 'image',
          buttons: this.templateToPreview?.buttons,
        },
      ];
    },
  },
  methods: {
    openTemplateSelectionModal() {
      showError({
        message: `${this.$t(
          'CREATE_TICKET.FORM.ERRORS.NO_INBOX'
        )} to proceed further`,
        isValid: !!this.inboxId,
        errorneousFieldClassName: 'CREATE_TICKET_INBOX_ID',
      });

      if (this.inboxId) this.showTemplateSelection = true;
    },
    transposeTemplateAttributes(inputArray) {
      const transposedArray = inputArray[0].map((_, columnIndex) =>
        inputArray.map(row => row[columnIndex])
      );

      return transposedArray;
    },
    onUpdateField(fieldName, newValue) {
      this.templateInputs[fieldName] = newValue;
      this.$emit('templateInputs', this.templateInputs);
    },
    onSubmit() {
      this.showTemplateSelection = false;
      this.selectedTemplate = this.templateToPreview;
      this.$emit('selectedTemplate', this.selectedTemplate);
    },
    onClearTemplate() {
      this.selectedTemplate = {};
      this.templateToPreview = {};
      this.templateInputs = {
        media_url: '',
        dynamic_url: '',
        template_attributes: [],
      };
      this.$emit('templateInputs', this.templateInputs);
      this.$emit('selectedTemplate', {});
    },
  },
};
</script>
