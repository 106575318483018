<template>
  <div class="signup">
    <div class="signup__hero" style="padding: 0rem 6rem">
      <img
        :src="globalConfig.logo"
        :alt="globalConfig.installationName"
        class="hero__logo"
      />
      <h2 class="hero__title" v-text="$t('REGISTER.TRY_WOOT')" />
    </div>
    <form
      class="signup__box"
      style="padding: 0rem 6rem"
      @submit.prevent="submit"
    >
      <woot-input
        v-model="accountName"
        class="columns content-margin"
        :class="{ error: $v.accountName.$error }"
        :label="$t('REGISTER.COMPANY_NAME.LABEL')"
        :placeholder="$t('REGISTER.COMPANY_NAME.PLACEHOLDER')"
        :error="$v.accountName.$error ? $t('REGISTER.COMPANY_NAME.ERROR') : ''"
        @blur="$v.accountName.$touch"
      />

      <div class="signin__footer">
        <p class="desc-text accept__terms" v-html="termsLink" />
        <p
          class="desc-text"
          v-text="'Copyright © LimeChat.ai All Rights Reserved'"
        />
      </div>
      <woot-primary-button
        :disabled="isSignupInProgress || $v.accountName.$error"
        :name="$t('REGISTER.SUBMIT')"
        :loading="isSignupInProgress"
      />
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import mixpanelEvent from 'dashboard/helper/mixpanel';
import { required, minLength } from 'vuelidate/lib/validators';

import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import alertMixin from 'shared/mixins/alertMixin';
import { getShopifyStoreInfo } from '../../store/utils/api';

export default {
  mixins: [globalConfigMixin, alertMixin],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      accountName: '',
      isSignupInProgress: false,
      error: '',
    };
  },
  validations: {
    accountName: {
      required,
      minLength: minLength(2),
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
    termsLink() {
      return this.$t('REGISTER.TERMS_ACCEPT')
        .replace('https://www.chatwoot.com/terms', this.globalConfig.termsURL)
        .replace(
          'https://www.chatwoot.com/privacy-policy',
          this.globalConfig.privacyURL
        );
    },
  },
  mounted() {
    this.prefillAccountDetails();
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.isSignupInProgress = true;

      try {
        const payload = getShopifyStoreInfo();
        await this.$store.dispatch('register', {
          user: this.user,
          params: {
            ...payload,
            name: this.accountName,
            features: {
              signup_onboarding: false,
            },
          },
        });
        mixpanelEvent('clicked_signup_successful');
        localStorage.setItem('firstLaunch', true);
        window.location = '/';
      } catch (error) {
        let errorMessage = this.$t('REGISTER.API.ERROR_MESSAGE');
        if (error.response && error.response.data.message)
          errorMessage = error.response.data.message;
        this.showAlert(errorMessage, 'error');
      } finally {
        this.isSignupInProgress = false;
      }
    },
    prefillAccountDetails() {
      const payload = getShopifyStoreInfo();
      if (payload) {
        this.accountName = payload.name;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.signup {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  .signup__hero {
    width: 70%;
    margin-bottom: var(--space-normal);

    .hero__logo {
      width: 180px;
    }

    .hero__title {
      font-size: var(--font-size-large);
      margin-top: var(--space-small);
    }
  }

  .signup__box {
    width: 70%;
    padding: var(--space-large);

    button {
      margin-top: var(--space-normal);
    }
  }

  .signin__footer {
    .accept__terms {
      font-size: var(--font-size-small);
      text-align: center;
      margin: var(--space-normal) 0 0 0;
    }
  }
}
</style>
