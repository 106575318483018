export const MASK_STATE = {
  serverMask: 'server-mask',
  clientMask: 'client-mask',
  noMask: 'no-mask',
};

export const MASK_TYPES = {
  NAME: 'name',
  PHONE: 'phone',
  EMAIL: 'email',
  ADDRESS: 'address',
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',

  // inputs
  NAME_INPUT: 'name-input',
  PHONE_INPUT: 'phone-input',
  EMAIL_INPUT: 'email-input',
  FACEBOOK_INPUT: 'facebook-input',
  INSTAGRAM_INPUT: 'instagram-input',
  TWITTER_INPUT: 'twitter-input',
  LINKEDIN_INPUT: 'linkedin-input',
  OTHER: 'other',
};

export const MASK_SETTINGS = {
  PHONE: {
    SHOW_LAST_X: 'SHOW_LAST_X_DIGITS',
    SHOW_FIRST_X: 'SHOT_FIRST_X_DIGITS',
    RANDOMLY_MASK_X: 'RANDOMLY_MASK_X_DIGITS',
  },
  EMAIL: {
    UNIQUE_ID_ONLY: 'SHOW_UNIQUE_ID_ONLY',
    DOMAIN_ONLY: 'SHOW_DOMAIN_ONLY',
    SHOW_X_CHARACTERS_UNIQUE_ID: 'SHOW_X_CHAR_UNIQUE_ID',
  },
  ADDRESS: {
    DEFAULT: 'SHOW_CITY_ONLY',
  },
};

export const ACCESSED_DETAILS = {
  phone: 'phone_number',
  email: 'email',
  instagram: 'instagram_username',
  facebook: 'facebook',
  address: 'address',
  name: 'contact_name',
  pageAccessToken: 'page_access_token',
  userAccessToken: 'user_access_token',
};
