<template>
  <div class="customer-ringing flex-row">
    <div style="width: 50%" class="avatar-container flex-column flex-align">
      <avatar
        :username="agentName"
        rounded
        :size="60"
        :colored="true"
        :custom-style="{
          'font-size': '2.4rem',
          'box-shadow': 'none',
          border: 'none',
        }"
      />
      <span v-if="!eventName.HANGUP" class="body-b3 text-connected">
        {{
          $t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.STATES.CONNECTED')
        }}
      </span>
      <span
        v-if="eventName.CUSTOMER_ANSWER || eventName.BRIDGE || eventName.HANGUP"
        class="flex-column flex-align"
      >
        <span class="text-align--center title-h5 text-dark mt-8">
          {{ agentName }}
        </span>
        <span class="body-b3 text-light">
          {{ agentRole }}
        </span>
      </span>
    </div>
    <div style="width: 50%" class="avatar-container flex-column flex-align">
      <div
        v-if="eventName.AGENT_ANSWER || eventName.CUSTOMER_CALL"
        style="width: 145%"
        class="outbound-ringer"
      >
        <lottie :options="defaultOptions" :height="114" :width="114" />
      </div>
      <avatar
        v-if="customerName && customerName !== 'New Customer'"
        :username="customerName"
        rounded
        :size="60"
        :colored="true"
        :custom-style="{
          'font-size': '2.4rem',
          'box-shadow': 'none',
          border: 'none',
        }"
      />
      <span v-else class="user-icon">
        <icons
          name="user"
          color="primary"
          :custom-style="{ width: '5.2rem' }"
        />
      </span>
      <span v-if="eventName.AGENT_ANSWER" class="body-b3 text-light mt-8">
        {{
          $t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.STATES.CONNECTED')
        }}
      </span>
      <span v-if="eventName.CUSTOMER_CALL" class="body-b3 text-waiting mt-8">
        {{
          $t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.STATES.WAITING')
        }}
      </span>
      <span
        v-if="eventName.CUSTOMER_ANSWER || eventName.BRIDGE"
        class="body-b3 text-connected mt-8"
      >
        {{
          $t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.STATES.CONNECTED')
        }}
      </span>
      <span
        v-if="eventName.CUSTOMER_ANSWER || eventName.BRIDGE || eventName.HANGUP"
        class="flex-column flex-align"
      >
        <span class="text-align--center title-h5 text-dark mt-8">
          {{ customerName || 'Customer' }}
        </span>
        <span v-if="customerName" class="body-b3 text-light">
          {{
            $t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.ROLES.CUSTOMER')
          }}
        </span>
      </span>
    </div>
  </div>
</template>
<script>
import Lottie from 'vue-lottie';

import animationData from 'dashboard/assets/lottie/outboundRinger.json';
import Avatar from 'dashboard/components/widgets/Avatar.vue';

export default {
  components: { Avatar, Lottie },
  props: {
    eventName: {
      type: Object,
      default: () => {},
    },
    agentName: {
      type: String,
      default: '',
    },
    agentRole: {
      type: String,
      default: '',
    },
    customerName: {
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      defaultOptions: { animationData },
    };
  },
};
</script>
