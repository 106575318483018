<template>
  <div
    v-tooltip="tooltipOptions"
    class="lime-card-new metrics-card flex-column flex-justify--between"
    :class="{ 'flex-align--start gap--four': actual }"
    :style="customStyle"
  >
    <span class="title-h2 text-dark" v-html="title" />
    <span class="title-h5_m text-light" v-html="subTitle" />
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: [String, Number],
      required: true,
    },
    actual: {
      type: [String, Number],
      default: '',
    },
    subTitle: {
      type: String,
      required: true,
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    tooltipOptions() {
      return this.actual
        ? {
            arrow: true,
            content: this.actual,
          }
        : {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.metrics-card {
  @include elevation-1dp;

  position: relative;
  flex: 1 1 $zero;
  padding: $space-medium $space-medium $space-normal;
  margin-right: $space-normal;
  border-radius: $border-radius-smaller;
  height: 13rem;

  &:last-child {
    margin-right: $zero;
  }
}
</style>
