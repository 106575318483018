import store from 'dashboard/store';

import Index from './Index';
import SettingsContent from '../Wrapper';
import Webhook from './webhook/Index';
import Shopify from './shopify/Index';
import ShopifyPermissions from './deepShopify/Permissions';
import ShowIntegration from './ShowIntegration';
import FreshdeskIntegration from './FreshdeskIntegration';
import KaptureIntegration from './KaptureIntegration';
import ZohoIntegration from './ZohoIntegration';
import OnedirectIntegration from './OnedirectIntegration';
import ZendeskIntegration from './ZendeskIntegration';
import OdooIntegration from './OdooIntegration';
import ShiprocketIntegration from './ShiprocketIntegration';
import EzyslipsIntegration from './EzyslipsIntegration';
import DelhiveryIntegration from './DelhiveryIntegration';
import ClickpostIntegration from './ClickpostIntegration';
import EasyecomIntegration from './EasyecomIntegration';
import PickrrIntegration from './PickrrIntegration';
import UnicommerceIntegration from './UnicommerceIntegration';
import ShipdelightIntegration from './ShipdelightIntegration';
import BluedartIntegration from './BluedartIntegration';
import { frontendURL } from '../../../../helper/URLHelper';

const {
  getters: { 'accounts/getDeepShopifyEnabledFlag': isDeepShopifyEnabled },
} = store;

let shopifySettingsAccessibleTo = [];

if (isDeepShopifyEnabled) shopifySettingsAccessibleTo = ['administrator'];

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/integrations'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_integrations';
        const backUrl =
          params.name === 'settings_integrations_integration'
            ? { name: 'settings_integrations' }
            : '';
        return {
          headerTitle: 'INTEGRATION_SETTINGS.HEADER',
          icon: 'integration',
          showBackButton,
          backUrl,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_integrations',
          component: Index,
          roles: ['administrator'],
        },
        {
          path: 'webhook',
          component: Webhook,
          name: 'settings_integrations_webhook',
          roles: ['administrator'],
        },
        {
          path: 'shopify',
          component: Shopify,
          name: 'settings_integrations_shopify',
          roles: ['administrator'],
        },
        {
          path: 'shopify-permissions',
          name: 'settings_shopify_integration',
          component: ShopifyPermissions,
          roles: shopifySettingsAccessibleTo,
        },
        {
          path: 'crm/freshdesk',
          name: 'settings_integrations_freshdesk',
          component: FreshdeskIntegration,
          roles: ['administrator'],
        },
        {
          path: 'crm/kapture',
          name: 'settings_integrations_kapture',
          component: KaptureIntegration,
          roles: ['administrator'],
        },
        {
          path: 'crm/zohodesk',
          name: 'settings_integrations_zohodesk',
          component: ZohoIntegration,
          roles: ['administrator'],
        },

        {
          path: 'crm/onedirect',
          name: 'settings_integrations_onedirect',
          component: OnedirectIntegration,
          roles: ['administrator'],
        },
        {
          path: 'crm/zendesk',
          name: 'settings_integrations_zendesk',
          component: ZendeskIntegration,
          roles: ['administrator'],
        },
        {
          path: 'crm/odoo',
          name: 'settings_integrations_odoo',
          component: OdooIntegration,
          roles: ['administrator'],
        },
        {
          path: 'logistics/shiprocket',
          name: 'settings_integrations_shiprocket',
          component: ShiprocketIntegration,
          roles: ['administrator'],
        },
        {
          path: 'logistics/ezyslips',
          name: 'settings_integrations_ezyslips',
          component: EzyslipsIntegration,
          roles: ['administrator'],
        },
        {
          path: 'logistics/pickrr',
          name: 'settings_integrations_pickrr',
          component: PickrrIntegration,
          roles: ['administrator'],
        },
        {
          path: 'logistics/clickpost',
          name: 'settings_integrations_clickpost',
          component: ClickpostIntegration,
          roles: ['administrator'],
        },
        {
          path: 'logistics/easyecom',
          name: 'settings_integrations_easyecom',
          component: EasyecomIntegration,
          roles: ['administrator'],
        },
        {
          path: 'logistics/delhivery',
          name: 'settings_integrations_delhivery',
          component: DelhiveryIntegration,
          roles: ['administrator'],
        },
        {
          path: 'logistics/unicommerce',
          name: 'settings_integrations_unicommerce',
          component: UnicommerceIntegration,
          roles: ['administrator'],
        },
        {
          path: 'logistics/bluedart',
          name: 'settings_integrations_bluedart',
          component: BluedartIntegration,
          roles: ['administrator'],
        },
        {
          path: 'logistics/shipdelight',
          name: 'settings_integrations_shipdelight',
          component: ShipdelightIntegration,
          roles: ['administrator'],
        },
        {
          path: ':integration_id',
          name: 'settings_integrations_integration',
          component: ShowIntegration,
          roles: ['administrator'],
          props: route => {
            return {
              integrationId: route.params.integration_id,
              code: route.query.code,
            };
          },
        },
      ],
    },
  ],
};
