/* global mixpanel */
import * as types from 'shared/constants/mixPanelEventType';

export default {
  methods: {
    /* MixPanel custom event tracker */
    mixPanelAnalyticsEvent(event) {
      try {
        if (window.mixpanel) {
          mixpanel.track(event);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    /* MixPanel custom event tracker */

    /* MixPanel custom event with properties tracker */
    mixPanelAnalyticsEventWithProperties(event_name, event_properties) {
      try {
        if (window.mixpanel) {
          mixpanel.track(event_name, event_properties);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    /* MixPanel custom event properties tracker */

    handleZoomButtonClick(screen_name, chartName) {
      this.mix_panel_clicked_graph_zoom_in_out(screen_name, chartName);
    },

    /* SignUp Events */
    mixPanelShopifySignupInitiated() {
      this.mixPanelAnalyticsEvent(types.default.SHOPIFY_SIGN_UP_INITIATED);
    },
    mixPanelNormalSignupInitiated() {
      this.mixPanelAnalyticsEvent(types.default.NORMAL_SIGN_UP_INITIATED);
    },
    mixPanelShopifySignupAttempted() {
      this.mixPanelAnalyticsEvent(types.default.SHOPIFY_SIGN_UP_ATTEMPTED);
    },
    mixPanelNormalSignupAttempted() {
      this.mixPanelAnalyticsEvent(types.default.NORMAL_SIGN_UP_ATTEMPTED);
    },
    /* SignUp Events */

    /* OnBoarding Events */
    mixPanelNormalOnBoardingModalInitiated() {
      this.mixPanelAnalyticsEvent(
        types.default.NORMAL_ONBOARDING_MODAL_INITIATED
      );
    },

    mixPanelNormalOnBoardingModalFinished() {
      this.mixPanelAnalyticsEvent(
        types.default.NORMAL_ONBOARDING_MODAL_FINISHED
      );
    },

    mixPanelShopifyOnBoardingModalInitiated() {
      this.mixPanelAnalyticsEvent(
        types.default.SHOPIFY_ONBOARDING_MODAL_INITIATED
      );
    },

    mixPanelShopifyOnBoardingModalFinished() {
      this.mixPanelAnalyticsEvent(
        types.default.SHOPIFY_ONBOARDING_MODAL_FINISHED
      );
    },
    /* OnBoarding Events */

    /* Add collaborator/agent Events */
    mixPanelAddCollaboratorFromAgentSettingsInitiated() {
      this.mixPanelAnalyticsEvent(
        types.default.ADD_COLLABORATOR_INITIATED_FROM_AGENT_SETTINGS
      );
    },

    mixPanelAddCollaboratorFinished() {
      this.mixPanelAnalyticsEvent(types.default.ADD_COLLABORATOR_FINISHED);
    },

    mixPanelAddCollaboratorFromInboxSettingsInitiated() {
      this.mixPanelAnalyticsEvent(
        types.default.ADD_COLLABORATOR_INITIATED_FROM_INBOX_SETTINGS
      );
    },
    /* Add collaborator/agent Events */

    /* FB inbox creation Events */
    mixPanelFBInboxCreationInitiated() {
      this.mixPanelAnalyticsEvent(types.default.FB_INBOX_CREATION_INITIATED);
    },

    mixPanelFBInboxCreationErrorNotAuthorized() {
      this.mixPanelAnalyticsEvent(
        types.default.FB_INBOX_CREATION_ERROR_UNAUTHORIZED
      );
    },

    mixPanelFBInboxCreationError() {
      this.mixPanelAnalyticsEvent(
        types.default.FB_INBOX_CREATION_GENERIC_ERROR
      );
    },

    mixPanelFBInboxCreationSuccessful() {
      this.mixPanelAnalyticsEvent(types.default.FB_INBOX_CREATION_SUCCESSFUL);
    },
    /* FB inbox creation Events */

    /* IG inbox creation Events */
    mixPanelIGInboxCreationInitiated() {
      this.mixPanelAnalyticsEvent(types.default.IG_INBOX_CREATION_INITIATED);
    },

    mixPanelIGInboxCreationErrorNotAuthorized() {
      this.mixPanelAnalyticsEvent(
        types.default.IG_INBOX_CREATION_ERROR_UNAUTHORIZED
      );
    },

    mixPanelIGInboxCreationError() {
      this.mixPanelAnalyticsEvent(
        types.default.IG_INBOX_CREATION_GENERIC_ERROR
      );
    },

    mixPanelIGInboxCreationSuccessful() {
      this.mixPanelAnalyticsEvent(types.default.IG_INBOX_CREATION_SUCCESSFUL);
    },
    /* IG inbox creation Events */

    /* Widget script copy Events */
    mixPanelWAWidgetScriptCopied() {
      this.mixPanelAnalyticsEvent(types.default.WA_WIDGET_SCRIPT_COPIED);
    },

    mixPanelWebWidgetScriptCopied() {
      this.mixPanelAnalyticsEvent(types.default.WEB_WIDGET_SCRIPT_COPIED);
    },
    /* Widget script copy Events */

    /* Send message event to track active status of a user */
    mixPanelSendMessage() {
      this.mixPanelAnalyticsEvent(types.default.SEND_MESSAGE);
    },
    /* Send message event to track active status of a user */

    /* Automation Events */
    mixPanelAddAutomationInitiated() {
      this.mixPanelAnalyticsEvent(types.default.ADD_AUTOMATION_RULE_INITIATED);
    },

    mixPanelAddAutomationSuccessful() {
      this.mixPanelAnalyticsEvent(types.default.ADD_AUTOMATION_RULE_SUCCESSFUL);
    },
    /* Automation Events */

    /* Teams Events */
    mixPanelAddTeamInitiated() {
      this.mixPanelAnalyticsEvent(types.default.ADD_TEAM_INITIATED);
    },

    mixPanelAddTeamSuccessful() {
      this.mixPanelAnalyticsEvent(types.default.ADD_TEAM_SUCCESSFUL);
    },
    /* Teams Events */

    /* Deep Shopify Events */
    mixPanelOrderCreateSuccess() {
      this.mixPanelAnalyticsEvent(types.default.ORDER_CREATE_SUCCESS);
    },

    mixPanelOrderCancelled() {
      this.mixPanelAnalyticsEvent(types.default.ORDER_CANCELLED);
    },

    mixPanelOrderEdited() {
      this.mixPanelAnalyticsEvent(types.default.ORDER_EDITED);
    },
    /* Deep Shopify Events */

    mix_panel_clicked_analytics_section(section_name) {
      switch (section_name) {
        case 'conversation_overview':
          this.mixPanelAnalyticsEventWithProperties(
            types.default.clicked_analytics_section,
            {
              section_name: types.default.ticket_overview,
            }
          );
          break;
        case 'bot_performance':
          this.mixPanelAnalyticsEventWithProperties(
            types.default.clicked_analytics_section,
            {
              section_name: types.default.bot_overview,
            }
          );

          break;
        case 'agent_overview':
          this.mixPanelAnalyticsEventWithProperties(
            types.default.clicked_analytics_section,
            {
              section_name: types.default.bot_overview,
            }
          );
          break;
        default:
          break;
      }
    },

    mix_panel_clicked_date_filter_menu_item(screen_name, selection) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_date_filter_menu_item,
        {
          screen_name: screen_name,
          selection: selection,
        }
      );
    },

    mix_panel_clicked_inbox_filter_menu_item(screen_name, selection) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_inbox_filter_menu_item,
        {
          screen_name: screen_name,
          selection: selection,
        }
      );
    },

    mix_panel_clicked_tag_filter_menu_item(screen_name, selection) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_tag_filter_menu_item,
        {
          screen_name: screen_name,
          selection: selection,
        }
      );
    },

    mix_panel_clicked_working_hours_menu_item(screen_name, selection) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_working_hours_menu_item,
        {
          screen_name: screen_name,
          selection: selection,
        }
      );
    },

    mix_panel_clicked_ticket_overview_report(screen_name, selection) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_ticket_overview_report,
        {
          screen_name: screen_name,
          selection: selection,
        }
      );
    },

    mix_panel_clicked_ticket_overview_overall_report_dropdown(screen_name) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_ticket_overview_overall_report_dropdown,
        {
          screen_name: screen_name,
        }
      );
    },

    mix_panel_clicked_ticket_overview_overall_report_menu_item(
      screen_name,
      selection
    ) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_ticket_overview_overall_report_dropdown_menu_item,
        {
          screen_name: screen_name,
          selection: selection,
        }
      );
    },

    mix_panel_clicked_graph_zoom_in_out(screen_name, graph_name) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_graph_zoom_in_out,
        {
          screen_name: screen_name,
          graph_name: graph_name,
        }
      );
    },

    mix_panel_clicked_agent_overview_tab(tab_name) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_agent_overview_tab,
        {
          screen_name: 'agent_overview',
          tab_name: tab_name,
        }
      );
    },

    mix_panel_clicked_agent_filter_menu_item(screen_name, agent_type) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_agent_filter_menu_item,
        {
          screen_name: screen_name,
          agent_type: agent_type,
        }
      );
    },

    mix_panel_clicked_report_menu_item(screen_name, report_name) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_report_menu_item,
        {
          screen_name: screen_name,
          report_name: report_name,
        }
      );
    },

    mix_panel_clicked_bot_overview_tab(tab_name) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_bot_overview_tab,
        {
          tab_name: tab_name,
        }
      );
    },

    mix_panel_toggled_bot_performance_graph(selection) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.toggled_bot_performance_graph,
        {
          selection: selection,
        }
      );
    },

    mix_panel_clicked_ticket_search() {
      this.mixPanelAnalyticsEvent(types.default.clicked_ticket_search);
    },

    mix_panel_clicked_ticket_search_category(screen_name, category) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_ticket_search_category,
        {
          screen_name: screen_name,
          category: category,
        }
      );
    },

    mix_panel_clicked_ticket_save_search(screen_name) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_ticket_save_search,
        {
          screen_name: screen_name,
        }
      );
    },

    mix_panel_clicked_ticket_search_reset(screen_name) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_ticket_search_reset,
        {
          screen_name: screen_name,
        }
      );
    },

    mix_panel_clicked_ticket_team_filter() {
      this.mixPanelAnalyticsEvent(types.default.clicked_ticket_team_filter);
    },

    mix_panel_clicked_ticket_assigned_by(assigner) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_ticket_assigned_by,
        {
          assigner: assigner,
        }
      );
    },

    mix_panel_clicked_ticket_save_filter() {
      this.mixPanelAnalyticsEvent(types.default.clicked_ticket_save_filter);
    },

    mix_panel_clicked_ticket_reset_filter() {
      this.mixPanelAnalyticsEvent(types.default.clicked_ticket_reset_filter);
    },

    mix_panel_clicked_ticket_action_tab(tab_name) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_ticket_action_tab,
        {
          tab_name: tab_name === 'contact' ? 'overview' : tab_name, // key of tab_name is contact, which is misleading
        }
      );
    },

    mix_panel_assigned_ticket_to_team() {
      this.mixPanelAnalyticsEvent(types.default.assigned_ticket_to_team);
    },

    mix_panel_clicked_previous_ticket() {
      this.mixPanelAnalyticsEvent(types.default.clicked_previous_ticket);
    },

    mix_panel_clicked_add_crm_ticket() {
      this.mixPanelAnalyticsEvent(types.default.clicked_add_crm_ticket);
    },

    mix_panel_added_crm_ticket() {
      this.mixPanelAnalyticsEvent(types.default.added_crm_ticket);
    },

    mix_panel_selected_ticket_order_filter(filter_type) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.selected_ticket_order_filter,
        {
          filter_type: filter_type,
        }
      );
    },

    mix_panel_searched_ticket_order() {
      this.mixPanelAnalyticsEvent(types.default.searched_ticket_order);
    },

    mix_panel_shared_ticket_product() {
      this.mixPanelAnalyticsEvent(types.default.shared_ticket_product);
    },

    mix_panel_sent_ticket_emoji() {
      this.mixPanelAnalyticsEvent(types.default.sent_ticket_emoji);
    },

    mix_panel_saved_ticket_private_note() {
      this.mixPanelAnalyticsEvent(types.default.saved_ticket_private_note);
    },

    mix_panel_clicked_side_nav_menu_item(item_name) {
      if (
        item_name === 'COMPANY' ||
        item_name === 'CONVERSATIONS' ||
        item_name === 'ANALYTICS' ||
        item_name === 'BROADCAST' ||
        item_name === 'CONTACTS' ||
        item_name === 'SETTINGS' ||
        item_name === 'CALLS' ||
        item_name === 'NOTIFICATIONS' ||
        item_name === 'PROFILE'
      ) {
        this.mixPanelAnalyticsEventWithProperties(
          types.default.clicked_side_nav_menu_item,
          {
            item_name: item_name,
          }
        );
      }
    },

    mix_panel_clicked_change_profile_status() {
      this.mixPanelAnalyticsEvent(types.default.clicked_change_profile_status);
    },

    mix_panel_changed_profile_status(status) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.changed_profile_status,
        {
          status: status,
        }
      );
    },

    mix_panel_clicked_profile_settings() {
      this.mixPanelAnalyticsEvent(types.default.clicked_profile_settings);
    },

    mix_panel_clicked_contacts_previous() {
      this.mixPanelAnalyticsEvent(types.default.clicked_contacts_previous);
    },

    mix_panel_clicked_contacts_next() {
      this.mixPanelAnalyticsEvent(types.default.clicked_contacts_next);
    },

    mix_panel_clicked_contact_card() {
      this.mixPanelAnalyticsEvent(types.default.clicked_contact_card);
    },

    mix_panel_clicked_contacts_download_data() {
      this.mixPanelAnalyticsEvent(types.default.clicked_contacts_download_data);
    },

    mix_panel_clicked_contacts_download_select_date_range() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_contacts_download_select_date_range
      );
    },

    mix_panel_clicked_contacts_download_send_to_email() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_contacts_download_send_to_email
      );
    },

    mix_panel_clicked_contact_edit() {
      this.mixPanelAnalyticsEvent(types.default.clicked_contact_edit);
    },

    mix_panel_clicked_contact_edit_cancel() {
      this.mixPanelAnalyticsEvent(types.default.clicked_contact_edit_cancel);
    },
    mix_panel_clicked_contact_edit_submit() {
      this.mixPanelAnalyticsEvent(types.default.clicked_contact_edit_submit);
    },

    mix_panel_clicked_ticket_select() {
      this.mixPanelAnalyticsEvent(types.default.clicked_ticket_select);
    },

    mix_panel_clicked_ticket_select_all() {
      this.mixPanelAnalyticsEvent(types.default.clicked_ticket_select_all);
    },

    mix_panel_clicked_ticket_bulk_modify() {
      this.mixPanelAnalyticsEvent(types.default.clicked_ticket_bulk_modify);
    },

    mix_panel_clicked_settings_section(section_name) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_settings_section,
        {
          section_name: section_name,
        }
      );
    },

    mix_panel_clicked_settings_save_edit_tag() {
      this.mixPanelAnalyticsEvent(types.default.clicked_settings_save_edit_tag);
    },

    mix_panel_clicked_settings_edit_button_payload_template() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_settings_edit_button_payload_template
      );
    },

    mix_panel_clicked_selected_ticket_menu_option(selection) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_selected_ticket_menu_option,
        {
          selection: selection,
        }
      );
    },
    mix_panel_clicked_ticket_menu_options() {
      this.mixPanelAnalyticsEvent(types.default.clicked_ticket_menu_options);
    },

    mix_panel_clicked_ticket_sort() {
      this.mixPanelAnalyticsEvent(types.default.clicked_ticket_sort);
    },

    mix_panel_sorted_tickets(sort_by) {
      this.mixPanelAnalyticsEventWithProperties(types.default.sorted_tickets, {
        sort_by: sort_by,
      });
    },

    mix_panel_clicked_sales_analytics_tab(tab_name) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_sales_analytics_tab,
        {
          tab_name: tab_name,
        }
      );
    },
    mix_panel_clicked_sales_insight_chart_type_sales_analytics(chart_type) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_sales_insight_chart_type_sales_analytics,
        {
          chart_type: chart_type,
        }
      );
    },

    mix_panel_clicked_mark_all_notifications_as_read() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_mark_all_notifications_as_read
      );
    },

    mix_panel_clicked_settings_save_account_settings() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_settings_save_account_settings
      );
    },

    mix_panel_clicked_settings_agent_submit(screen_name) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_settings_agent_submit,
        {
          screen_name: screen_name,
        }
      );
    },

    mix_panel_clicked_settings_edit_agent() {
      this.mixPanelAnalyticsEvent(types.default.clicked_settings_edit_agent);
    },

    mix_panel_clicked_role_filter_menu_item(role) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_role_filter_menu_item,
        {
          role: role,
        }
      );
    },

    mix_panel_toggled_agent_ticket_concurrency(screen_name, toggle) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.toggled_agent_ticket_concurrency,
        {
          screen_name: screen_name,
          toggle: toggle,
        }
      );
    },

    mix_panel_searched_settings_automation() {
      this.mixPanelAnalyticsEvent(types.default.searched_settings_automation);
    },

    mix_panel_clicked_settings_create_submit_automation(event_type) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_settings_create_submit_automation,
        {
          event_type: event_type,
        }
      );
    },

    mix_panel_clicked_settings_save_edit_canned_response() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_settings_save_edit_canned_response
      );
    },

    mix_panel_clicked_settings_export_download_csv_canned_response() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_settings_export_download_csv_canned_response
      );
    },

    mix_panel_clicked_settings_import_upload_csv_canned_response() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_settings_import_upload_csv_canned_response
      );
    },

    mix_panel_searched_settings_canned_response() {
      this.mixPanelAnalyticsEvent(
        types.default.searched_settings_canned_response
      );
    },

    mix_panel_clicked_settings_add_canned_response() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_settings_add_canned_response
      );
    },

    mix_panel_deleted_settings_canned_response() {
      this.mixPanelAnalyticsEvent(
        types.default.deleted_settings_canned_response
      );
    },

    mix_panel_searched_settings_inbox() {
      this.mixPanelAnalyticsEvent(types.default.searched_settings_inbox);
    },

    mix_panel_deleted_settings_inbox() {
      this.mixPanelAnalyticsEvent(types.default.deleted_settings_inbox);
    },

    mix_panel_clicked_edit_inbox() {
      this.mixPanelAnalyticsEvent(types.default.clicked_edit_inbox);
    },

    mix_panel_clicked_settings_add_inbox() {
      this.mixPanelAnalyticsEvent(types.default.clicked_settings_add_inbox);
    },

    mix_panel_connection_successful_settings_integrations(integration_type) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.connection_successful_settings_integrations,
        {
          integration_type: integration_type,
        }
      );
    },

    mix_panel_clicked_settings_export_download_csv_tag() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_settings_export_download_csv_tag
      );
    },

    mix_panel_clicked_settings_import_upload_csv_tag() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_settings_import_upload_csv_tag
      );
    },

    mix_panel_clicked_settings_import_download_example_tag() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_settings_import_download_example_tag
      );
    },

    mix_panel_clicked_settings_add_sub_tag() {
      this.mixPanelAnalyticsEvent(types.default.clicked_settings_add_sub_tag);
    },

    mix_panel_clicked_settings_add_tag() {
      this.mixPanelAnalyticsEvent(types.default.clicked_settings_add_tag);
    },

    mix_panel_deleted_settings_tag() {
      this.mixPanelAnalyticsEvent(types.default.deleted_settings_tag);
    },

    mix_panel_clicked_update_profile_settings() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_update_profile_settings
      );
    },

    mix_panel_clicked_enable_push_notification() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_enable_push_notification
      );
    },

    mix_panel_searched_settings_team() {
      this.mixPanelAnalyticsEvent(types.default.searched_settings_team);
    },

    mix_panel_deleted_settings_team() {
      this.mixPanelAnalyticsEvent(types.default.deleted_settings_team);
    },

    mix_panel_clicked_settings_create_team() {
      this.mixPanelAnalyticsEvent(types.default.clicked_settings_create_team);
    },

    mix_panel_clicked_settings_export_download_csv_template() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_settings_export_download_csv_template
      );
    },

    mix_panel_clicked_settings_import_upload_csv_template() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_settings_import_upload_csv_template
      );
    },

    mix_panel_clicked_settings_import_download_example_template() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_settings_import_download_example_template
      );
    },

    mix_panel_searched_settings_template() {
      this.mixPanelAnalyticsEvent(types.default.searched_settings_template);
    },
    mix_panel_clicked_settings_add_template() {
      this.mixPanelAnalyticsEvent(types.default.clicked_settings_add_template);
    },

    mix_panel_clicked_settings_edit_template() {
      this.mixPanelAnalyticsEvent(types.default.clicked_settings_edit_template);
    },

    mix_panel_deleted_settings_template() {
      this.mixPanelAnalyticsEvent(types.default.deleted_settings_template);
    },

    mix_panel_clicked_settings_sync_template() {
      this.mixPanelAnalyticsEvent(types.default.clicked_settings_sync_template);
    },

    mix_panel_clicked_reset_password_mail_sent() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_reset_password_mail_sent
      );
    },

    mix_panel_clicked_order_create_successful(payment_type) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_order_create_successful,
        {
          payment_type: payment_type,
        }
      );
    },

    mix_panel_clicked_edit_order_successful() {
      this.mixPanelAnalyticsEvent(types.default.clicked_edit_order_successful);
    },

    mix_panel_clicked_refund_order_successful() {
      this.mixPanelAnalyticsEvent(
        types.default.clicked_refund_order_successful
      );
    },

    mix_panel_clicked_create_inbox_successful(inbox_category, channel_type) {
      this.mixPanelAnalyticsEventWithProperties(
        types.default.clicked_order_create_successful,
        {
          inbox_category: inbox_category,
          channel_type: channel_type,
        }
      );
    },

    mix_panel_clicked_improve_draft() {
      this.mixPanelAnalyticsEvent(types.default.clicked_improve_draft);
    },

    mix_panel_clicked_select_draft() {
      this.mixPanelAnalyticsEvent(types.default.clicked_select_draft);
    },

    mix_panel_clicked_reject_draft() {
      this.mixPanelAnalyticsEvent(types.default.clicked_reject_draft);
    },

    mix_panel_clicked_reply_draft() {
      this.mixPanelAnalyticsEvent(types.default.clicked_reply_draft);
    },
  },
};
