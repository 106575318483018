<template>
  <div :class="['offline-assignment-selector', { disabled: !inboxes.length }]">
    <div class="flex-row flex-align gap--slab">
      <label class="container-checkbox">
        <div class="body-b2 label-text">
          <span v-text="$t('TICKET_ASSIGNMENT.OFFLINE_ASSIGNMENT.TITLE')" />
        </div>
        <input v-model="isSelected" type="checkbox" @input="onInputSelected" />
        <span class="checkmark" />
      </label>
      <woot-single-selector
        variant="secondary"
        default-option="Select Inboxes"
        :option-list="inboxList"
        :show-selected-text="false"
        class="mg-bottom--small"
        :custom-style="{
          left: 0,
          maxHeight: '20rem',
          width: '20rem',
          borderRadius: '0.4rem',
          bottom: '4.4rem',
        }"
        :top-position="{ top: undefined }"
        :class="{ disabled: !isSelected }"
        @click="onSelectInbox"
      />
    </div>
    <woot-chips
      v-for="inbox in selectedInboxes"
      :key="inbox.id"
      :title="inbox.name"
      :show-tooltip="false"
      :front-icon="getInboxMeta(inbox).name"
      variant="primary-small"
      show-cancel-icon
      @click="onRemoveInbox(inbox)"
    />
  </div>
</template>
<script>
import inboxMixin from 'dashboard/mixins/inbox';

export default {
  mixins: [inboxMixin],
  props: {
    inboxes: { type: Array, default: () => [] },
    selectedInboxes: { type: Array, default: () => [] },
  },
  data() {
    return { isSelected: false };
  },
  computed: {
    inboxList() {
      return this.inboxes.filter(
        inbox =>
          !this.selectedInboxes.some(
            selectedInbox => selectedInbox.id === inbox.id
          )
      );
    },
  },
  watch: {
    selectedInboxes: {
      immediate: true,
      handler(newInboxes) {
        this.isSelected = newInboxes.length !== 0;
      },
    },
  },
  methods: {
    getInboxMeta(inbox) {
      return this.getInboxClassByType(
        inbox?.channel_type,
        inbox?.phone_number,
        '',
        inbox?.instagram_add_on
      );
    },
    onSelectInbox(option) {
      this.$emit('update', {
        id: option.id,
        allow_offline_assignment: true,
      });
    },
    onRemoveInbox(option) {
      this.$emit('update', {
        id: option.id,
        allow_offline_assignment: false,
      });
    },
    onInputSelected() {
      this.isSelected = !this.isSelected;
      if (!this.isSelected) {
        this.selectedInboxes.forEach(inbox => this.onRemoveInbox(inbox));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';
.offline-assignment-selector {
  &.disabled,
  .disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .container-checkbox {
    .label-text {
      &.disabled {
        pointer-events: none;
      }
    }
  }
}
</style>
