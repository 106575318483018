import { Bar } from 'vue-chartjs';

const fontFamily =
  'Lato,Roboto,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif';

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#1B83AC',
    bodyFontColor: '#8c8c8c',
    footerFontColor: '#8c8c8c',
    titleFontSize: 24,
    bodyFontSize: 16,
    footerFontSize: 12,
    caretSize: 10,
    displayColors: false,
    xPadding: 12,
    yPadding: 12,
  },
  legend: {
    display: false,
  },
  hover: {
    animationDuration: 1,
  },
  animation: {
    duration: 1,
    onComplete: function mapValues() {
      let chartInstance = this.chart;
      let ctx = chartInstance.ctx;
      ctx.textAlign = 'center';
      ctx.fillStyle = 'rgba(0, 0, 0, 1)';
      ctx.textBaseline = 'bottom';
      this.data.datasets.forEach((dataset, i) => {
        var meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          var data = dataset.data[index];
          // eslint-disable-next-line no-underscore-dangle
          ctx.fillText(data, bar._model.x, bar._model.y - 5);
        });
      });
    },
  },
  scales: {
    xAxes: [
      {
        barPercentage: 0.3,
        ticks: {
          fontFamily,
          callback: label => {
            return label.length > 20 ? label.substr(0, 20) + '...' : label;
          },
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true,
          fontColor: '#8c8c8c',
          fontSize: 12,
          lineHeight: 2,
          labelString: 'Conversations',
        },
        ticks: {
          fontFamily,
          beginAtZero: true,
        },
        gridLines: {
          display: true,
        },
      },
    ],
  },
};

export default {
  extends: Bar,
  props: ['collection'],
  mounted() {
    let { labels, label, data } = this.collection;
    let datasets = [
      {
        label,
        data,
        backgroundColor: '#76C9EA',
        hoverBackgroundColor: '#1B83AC',
      },
    ];
    let barData = {
      labels,
      datasets,
    };
    this.renderChart(barData, chartOptions);
  },
};
