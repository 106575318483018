<template>
  <div class="sla-status-container">
    <item
      v-for="metric in metrics"
      :key="metric.key"
      :chat-id="chat.id"
      :name="metric.key"
      :time="metric.time"
    />
  </div>
</template>
<script>
import Item from './Item';

export default {
  components: { Item },
  props: {
    chat: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    metrics() {
      return ['frt_due', 'nrt_due', 'resolution_due'].map(key => {
        return {
          key: this.$t(`SLA.METRICS_KEYS.${key}`),
          time: this.chat[key] ? new Date(this.chat[key]) : null,
        };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.sla-status-container {
  .sla-status-item:not(:last-child) {
    margin-bottom: $space-small;
  }
}
</style>
