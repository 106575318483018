<template>
  <div v-if="loadingState" class="small-12 custom-scroll center-aligned">
    <woot-loading-state message="Fetching Plan details" />
  </div>
  <div v-else class="column settings-box  billing-box">
    <div>
      <div class="button-large text-dark flex-row mg-top">
        <span class="mg-right--smaller go-back" @click="goToCurrentBillPage">
          <icons name="chevronLeft" color="green" size="medium" />
        </span>
        {{ $t('BILLING.CHOOSE_PLAN') }}
      </div>
      <div class="mg-top mg-left--large">
        <span class="subtitle-s2 text-dark">
          {{ $t('BILLING.DESC_TEXT') }}<br />
          {{ $t('BILLING.DESC_TEXT_TWO') }}
        </span>
      </div>
    </div>

    <div class="column plans-box settings-box custom-scroll">
      <div class="plans-container">
        <div v-for="plan in plans" :key="plan.id" class="plan-box">
          <div class="title-h4 text-dark" style="text-transform: uppercase">
            {{ planTitle(plan) }}
          </div>
          <div class="title-h1 blue-text mg-top" style="text-align:left">
            ${{ plan.price }}/mo
          </div>
          <div
            v-if="
              plan.name === $t('BILLING.ENTERPRISE_PLAN') ||
                plan.custom_plan_identifier
            "
            class="title-h1 blue-text mg-top custom-text"
            style="text-align:left"
          >
            {{ $t('BILLING.CUSTOM') }}
          </div>

          <div v-else>
            <div class="title-h6_m text-light">
              ${{ plan.extra_usage_rate }}
              {{ $t('BILLING.EXTRA_TICKETS_TEXT') }}
            </div>
            <div class="title-h6_m text-light">
              {{ $t('BILLING.AGENT_CHARGE') }}
            </div>
          </div>
          <div class="mg-top--medium">
            <div class="capital_c1 text-light mg-bottom--small">
              {{ getFeatures(plan.name) && getFeatures(plan.name).SUBTITLE }}
            </div>
            <div
              v-for="feature in getFeatures(plan.name) &&
                getFeatures(plan.name).FEATURES"
              :key="feature"
              class="flex-row-center mg-bottom--small"
            >
              <icons
                name="tickSingle"
                color="green"
                size="normal"
                view="0 0 16 16"
              />
              <span class="subtitle-s2 text-dark mg-left--smaller">
                {{ feature }}
              </span>
            </div>
          </div>

          <div v-if="plan.name !== $t('BILLING.ENTERPRISE_PLAN')">
            <div v-if="!isCurrentPlan(plan.name)" class="button-position">
              <woot-primary-button
                name="Choose Plan"
                full-width
                @click="showModal(plan.name)"
              />
            </div>
            <div v-else class="button-position">
              <woot-primary-button
                v-if="
                  showStarterPlanCta(
                    subscriptionForShopify.trial_end,
                    onGracePeriod
                  ) || isGracePeriodLastDay(gracePeriodLastDate)
                "
                name="continue"
                full-width
                @click="continueWithCurrentPlan(plan.name)"
              />
              <woot-secondary-button
                v-else
                name="current plan"
                full-width
                :custom-style="{ 'pointer-events': 'none' }"
              />
            </div>
          </div>
          <div v-else class="button-position">
            <woot-primary-button
              name="contact sales"
              full-width
              @click="openSalesPage"
            />
          </div>
        </div>
      </div>
    </div>

    <woot-delete-modal
      :show.sync="showConfirmationModal"
      :on-close="closeModal"
      :on-confirm="choosePlan"
      :title="
        $t('BILLING.FREE_TRIAL_LAST_DAY_HEADER', {
          plan: modalPlanTitle,
        })
      "
      :message="confirmationModalContent"
      :confirm-text="$t('BILLING.CONTINUE')"
      :reject-text="$t('BILLING.BACK')"
    />
    <woot-delete-modal
      :show.sync="showContinuePlanModal"
      :on-close="closeContinuePlanModal"
      :on-confirm="choosePlan"
      :title="
        $t('BILLING.FREE_TRIAL_LAST_DAY_HEADER', {
          plan: modalPlanTitle,
        })
      "
      :message="continueModalContent"
      :confirm-text="$t('BILLING.CONTINUE')"
      :reject-text="$t('BILLING.BACK')"
    />
    <woot-delete-modal
      :show.sync="showGenericConfirmationModal"
      :on-close="closeGenericConfirmationModal"
      :on-confirm="choosePlan"
      :title="
        $t('BILLING.FREE_TRIAL_LAST_DAY_HEADER', {
          plan: modalPlanTitle,
        })
      "
      :message="
        `You have selected  ${modalPlanTitle} plan. Do you wish to continue to activate billing with Shopify?`
      "
      :confirm-text="$t('BILLING.CONTINUE')"
      :reject-text="$t('BILLING.BACK')"
    />
    <woot-delete-modal
      :show.sync="showUnistallFlowModal"
      :on-close="closeUnistallFlowModal"
      title="Download LimeChat Shopify App"
      message="Your store has uninstalled the LimeChat Shopify App. Contact your Shopify store admin to reinstall the app and then select a plan.
      "
      :show-buttons="false"
      :reject-text="$t('BILLING.BACK')"
    />
    <woot-delete-modal
      :show.sync="gracePeriodConfirmationModal"
      :on-close="closegracePeriodConfirmationModal"
      :on-confirm="choosePlan"
      :title="
        $t('BILLING.FREE_TRIAL_LAST_DAY_HEADER', {
          plan: modalPlanTitle,
        })
      "
      :message="gracePeriodConfirmationModalContent"
      :confirm-text="$t('BILLING.CONTINUE')"
      :reject-text="$t('BILLING.BACK')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import timeMixin from 'dashboard/mixins/time';
import shopifyMixin from 'dashboard/mixins/shopify';
import billingMixin from 'dashboard/mixins/billing';

export default {
  mixins: [alertMixin, timeMixin, shopifyMixin, billingMixin],
  data() {
    return {
      shopifyAppUrl: '',
      plans: null,
      currentPlan: null,
      showConfirmationModal: false,
      showGenericConfirmationModal: false,
      showUnistallFlowModal: false,
      chosenPlan: '',
      showContinuePlanModal: null,
      gracePeriodConfirmationModal: null,
      loadingState: true,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    isAppReinstallFlow() {
      return (
        this.currentAccount?.on_grace_period &&
        this.currentAccount?.shopify_app_reinstalled
      );
    },
    gracePeriodConfirmationModalContent() {
      return !this.isGracePeriodLastDay(this.gracePeriodLastDate)
        ? this.$t('BILLING.GRACE_PERIOD_MODAL', {
            plan: this.chosenPlan.split(' ')[0],
            endDate: new Date(
              this.sendPrevDate(this.gracePeriodLastDate)
            ).toLocaleDateString('en-us', {
              weekday: 'long',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }),
          })
        : this.$t('BILLING.GRACE_PERIOD_LAST_DAY', {
            plan: this.chosenPlan.split(' ')[0],
          });
    },
    confirmationModalContent() {
      return !this.showStarterPlanCta(
        this.subscriptionForShopify?.trial_end,
        this.onGracePeriod
      )
        ? this.$t('BILLING.FREE_TRIAL_MODAL', {
            plan: this.chosenPlan.split(' ')[0],
          })
        : this.$t('BILLING.FREE_TRIAL_LAST_DAY', {
            plan: this.chosenPlan.split(' ')[0],
          });
    },
    onGracePeriod() {
      return this.currentAccount?.on_grace_period;
    },
    continueModalContent() {
      if (
        this.showStarterPlanCta(
          this.subscriptionForShopify?.trial_end,
          this.onGracePeriod
        )
      )
        return this.$t('BILLING.CONTINUE_WITH_STARTER', {
          plan: this.chosenPlan.split(' ')[0],
        });
      if (this.isGracePeriodLastDay(this.gracePeriodLastDate))
        return this.$t('BILLING.CONTINUE_WITH_STARTER', {
          plan: this.chosenPlan.split(' ')[0],
        });
      return '';
    },
    isBeyondGrace() {
      return this.isBeyondGracePeriod(
        this.currentAccount?.grace_period_last_date
      );
    },
    modalPlanTitle() {
      return this.chosenPlan.includes('custom')
        ? this.$t('BILLING.CUSTOM')
        : this.chosenPlan.split(' ')[0];
    },
  },
  mounted() {
    if (this.currentAccount?.id) {
      this.fetchBillingPlans();
      return;
    }

    this.$watch('currentAccount', () => {
      this.fetchBillingPlans();
    });
  },
  methods: {
    fetchBillingPlans() {
      this.$store.dispatch('getBillingPlans').then(data => {
        this.loadingState = false;
        this.plans = data?.plans
          .filter(plan => !plan.trial && plan.platform === 'shopify')
          .sort((a, b) => {
            return a.id - b.id;
          });

        const { on_shopify_custom_plan: onCustomPlan } = this.currentAccount;

        this.plans = this.plans.filter(plan => {
          // condition to show custom plans
          if (plan.custom_plan_identifier && onCustomPlan) {
            // extract account id from identifier string
            const customPlanID = plan.custom_plan_identifier.split('_')[3];
            return +customPlanID === +this.accountId;
          }

          // condition for standard plan
          return !plan.custom_plan_identifier;
        });
      });
    },
    goToCurrentBillPage() {
      this.$router.push(
        `/app/accounts/${this.$route.params.accountId}/settings/billing_plan`
      );
    },
    planTitle(plan) {
      return plan.custom_plan_identifier
        ? this.$t('BILLING.CUSTOM')
        : plan.name.split(' ')[0];
    },
    isCurrentPlan(name) {
      if (
        this.isBeyondGrace ||
        this.freeTrialHasEnded(this.subscriptionForShopify?.trial_end)
      ) {
        return false;
      }

      if (
        this.subscriptionForShopify?.plan?.name === 'Starter Trial Plan' ||
        this.subscriptionForShopify?.plan?.name === 'Starter Plan USD'
      ) {
        if (this.isAppReinstallFlow && this.isBeyondGrace) return false;
        return name.includes('Starter');
      }
      return this.subscriptionForShopify?.plan?.name === name;
    },
    getFeatures(name) {
      let planName = this.$t('BILLING.BILLING_PLANS').filter(
        plan => plan.NAME === name
      )[0];
      return planName;
    },

    showModal(name) {
      this.chosenPlan = name;

      if (
        this.isOnFreeTrial(
          this.subscriptionForShopify?.trial_end,
          this.currentAccount?.on_grace_period
        ) &&
        !name.includes('Starter')
      ) {
        this.showConfirmationModal = true;
      } else if (
        this.currentAccount?.on_grace_period &&
        !this.currentAccount?.shopify_app_reinstalled
      ) {
        this.showUnistallFlowModal = true;
      } else if (this.currentAccount?.on_grace_period && !this.isBeyondGrace) {
        this.gracePeriodConfirmationModal = true;
      } else this.showGenericConfirmationModal = true;
    },
    continueWithCurrentPlan(name) {
      this.showContinuePlanModal = true;
      this.chosenPlan = name;
    },
    choosePlan() {
      this.$store
        .dispatch('upgradeShopifyAppPlan', {
          account_id: this.accountId,
          plan_name: this.chosenPlan,
        })
        .then(data => {
          this.shopifyAppUrl = data.shopify_confirmation_url;
          if (data.data.shopify_confirmation_url)
            window.open(
              data.data.shopify_confirmation_url,
              '_blank',
              'noopener'
            );
          else this.showAlert(this.$t('BILLING.ERROR_MSG'), 'error');
        })
        .catch(() => {
          this.showAlert(this.$t('BILLING.ERROR_MSG'), 'error');
        });
      this.showConfirmationModal = false;
      this.showGenericConfirmationModal = false;
      this.showUnistallFlowModal = false;
      this.showContinuePlanModal = false;
      this.gracePeriodConfirmationModal = false;
    },
    openSalesPage() {
      window.open('https://www.limechat.ai/book-a-demo', '_blank', 'noopener');
    },
    closeModal() {
      this.showConfirmationModal = false;
    },
    closeContinuePlanModal() {
      this.showContinuePlanModal = false;
    },
    closegracePeriodConfirmationModal() {
      this.gracePeriodConfirmationModal = false;
    },
    closeGenericConfirmationModal() {
      this.showGenericConfirmationModal = false;
    },
    closeUnistallFlowModal() {
      this.showUnistallFlowModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';
.billing-box {
  padding-top: $space-largest;
  padding-left: $space-micro;
  position: relative;
}
.blue-text {
  color: $secondary-blue;
}
.plans-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: $zero auto;
  grid-gap: $space-slab;
  margin-top: $space-medium;
  width: 95%;
}
.plans-box {
  height: 80vh;
}
.plan-box {
  @include elevation-1dp;
  background: $color-white;
  border-radius: $border-radius;
  padding: $space-normal;
  position: relative;
  width: 25rem;
  min-height: 52rem;
}

.button-position {
  position: absolute;
  width: 88%;
  bottom: $space-normal;
}
.custom-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  text-transform: uppercase;
  font-size: $font-size-bigger;
}
.go-back {
  cursor: pointer;
}
</style>
