<template>
  <form
    v-if="!isLoading && allEnabledFields.length"
    @submit.prevent="updateValues"
  >
    <woot-base-button
      class="update-button"
      size="small"
      :disabled="$v.fieldValues.$invalid"
      type="submit"
    >
      UPDATE
    </woot-base-button>
    <div class="flex-column gap--normal">
      <section class="flex-column gap--normal mg-top--normal">
        <data-type-input
          class="FIELD_INPUT_0"
          :label="allEnabledFields[0].name"
          :data-type="FIELD_DATA_TYPE[allEnabledFields[0].field_type]"
          :options="allEnabledFields[0].dropdown_options || []"
          :value="fieldValue(allEnabledFields[0])"
          :validator="$v.fieldValues.$each[0].value"
          :error="getErrorMessage($v.fieldValues.$each[0].value)"
          :update-value="newValue => updateField(allEnabledFields[0], newValue)"
        />
        <div
          v-if="allEnabledFields.length > 1 && !showAllFields"
          class="see-more body-b3"
          @click="showAllFields = true"
        >
          See More
          {{
            allEnabledFields.length > 1
              ? `(${allEnabledFields.length - 1})`
              : ''
          }}
        </div>
      </section>
      <section v-if="showAllFields" class="flex-column gap--normal">
        <data-type-input
          v-for="(field, index) in allEnabledFields.slice(1)"
          :key="index"
          :class="`FIELD_INPUT_${index + 1}`"
          :label="field.name"
          :data-type="FIELD_DATA_TYPE[field.field_type]"
          :options="field.dropdown_options || []"
          :value="fieldValue(field)"
          :validator="$v.fieldValues.$each[index + 1].value"
          :error="getErrorMessage($v.fieldValues.$each[index + 1].value)"
          :update-value="newValue => updateField(field, newValue)"
        />
        <div class="see-more body-b3" @click="showAllFields = false">
          See Less
        </div>
      </section>
    </div>
  </form>
  <spinner v-else-if="isLoading" class="mg-top--small" />
  <div
    v-else
    class="body-b2 text-dark mg-top--small"
    v-text="$t('CUSTOM_TICKET_FIELDS.NO_FIELDS_MESSAGE')"
  />
</template>

<script>
import DataTypeInput from 'dashboard/components/forms/DataTypeInput';
import Spinner from 'shared/components/Spinner';

import alertMixin from 'shared/mixins/alertMixin';

import {
  FIELD_DATA_TYPE,
  customUrlValidator,
  customRequiredValidator,
} from './utils/constants';
import { CUSTOM_FIELD_TYPES } from 'dashboard/routes/dashboard/settings/customTicketFields/utils/constants';
import {
  getValuesFromData,
  filterUnchangedFields,
  isDateOrTimeField,
} from './utils/helper';

export default {
  components: { DataTypeInput, Spinner },
  mixins: [alertMixin],
  props: {
    inboxId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      FIELD_DATA_TYPE,
      allFields: [],
      fieldValues: [],
      showAllFields: false,
      isLoading: false,
    };
  },
  computed: {
    allEnabledFields() {
      return this.allFields.filter(field => field.enabled);
    },
  },
  watch: {
    conversationId: 'getAllFields',
  },
  mounted() {
    if (this.conversationId) this.getAllFields(this.conversationId);
  },
  validations: {
    fieldValues: {
      $each: {
        value: {
          required: customRequiredValidator,
          url: customUrlValidator,
        },
      },
    },
  },
  methods: {
    async getAllFields(newConvId, oldConvId = '') {
      if (newConvId !== oldConvId) {
        try {
          this.isLoading = true;
          this.showAllFields = false;

          await this.$store.dispatch('customTicketFields/get');

          this.allFields = this.$store.getters[
            'customTicketFields/getFieldsOfInbox'
          ](this.inboxId);

          await this.fetchCustomFieldValues(newConvId);
          this.isLoading = false;
        } catch {
          this.isLoading = false;
        }
      }
    },
    async fetchCustomFieldValues(conversationId) {
      await this.$store
        .dispatch('customTicketFields/getCustomFieldValues', {
          conversationId,
        })
        .then(data => {
          this.fieldValues = this.allEnabledFields.map(field =>
            getValuesFromData(field, data)
          );
        });
    },
    getErrorMessage(validator) {
      if (!validator.required && validator.$params.required)
        return this.$t('CUSTOM_TICKET_FIELDS.ERRORS.REQUIRED');
      if (!validator.url && validator.$params.url)
        return this.$t('CUSTOM_TICKET_FIELDS.ERRORS.URL');
      return '';
    },
    updateField(field, newValue) {
      const fieldIndex = this.fieldValues.findIndex(
        fieldValue => fieldValue.custom_field_id === field.id
      );

      if (field.field_type === CUSTOM_FIELD_TYPES.SINGLE_SELECT)
        this.$v.fieldValues.$model[fieldIndex].value = [newValue];
      else if (field.field_type === CUSTOM_FIELD_TYPES.MULTI_SELECT) {
        this.$v.fieldValues.$model[fieldIndex].value = newValue.value.map(
          (value, index) => ({
            id: newValue.id[index],
            value,
          })
        );
      } else {
        this.$v.fieldValues.$model[fieldIndex].value = newValue;
      }

      this.$v.fieldValues.$model[fieldIndex].dirty = true;
    },
    fieldValue(field) {
      const fieldValue = this.fieldValues.find(
        ({ custom_field_id }) => custom_field_id === field.id
      );
      if (isDateOrTimeField(field.field_type)) return fieldValue?.value || [];
      return field.field_type === CUSTOM_FIELD_TYPES.SINGLE_SELECT
        ? fieldValue?.value[0]?.value || 'Select a value'
        : fieldValue?.value || '';
    },
    updateValues() {
      const payload = filterUnchangedFields(this.fieldValues).map(
        ({ custom_field_id, fieldType, value }) => {
          if (fieldType === CUSTOM_FIELD_TYPES.MULTI_SELECT) {
            return {
              custom_field_id,
              option_ids: value.map(({ id }) => id),
            };
          }
          if (fieldType === CUSTOM_FIELD_TYPES.SINGLE_SELECT) {
            return {
              custom_field_id,
              option_id: value[0].id,
            };
          }
          return {
            custom_field_id,
            value: value,
          };
        }
      );

      if (payload.length) this.updateCustomFieldValues(payload);
      else
        this.showAlert(
          this.$t('CUSTOM_TICKET_FIELDS.NO_CHANGES_MESSAGE'),
          'info'
        );
    },
    async updateCustomFieldValues(customFieldValues) {
      try {
        await this.$store.dispatch(
          'customTicketFields/updateCustomFieldValues',
          {
            conversationId: this.conversationId,
            customFieldValues,
          }
        );

        this.showAlert(
          this.$t('CUSTOM_TICKET_FIELDS.SUCCESS_MESSAGE'),
          'success'
        );
      } catch (error) {
        if (error)
          this.showAlert("Value can't be blank for mandatory field", 'error');
        else
          this.showAlert(
            this.$t('CUSTOM_TICKET_FIELDS.ERRORS.ERROR_MESSAGE'),
            'error'
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.update-button {
  position: absolute;
  top: $space-slab;
  right: $space-slab;
}

.see-more {
  display: flex;
  justify-content: flex-end;
  color: $secondary-blue;
  cursor: pointer;
  padding-top: $space-small;

  &:hover {
    color: $facebook-blue;
  }
}
</style>
