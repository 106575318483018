<template>
  <section>
    <form-section-header
      :heading="$t('CREATE_TICKET.FORM.SECTIONS.ADD_CONTACT.HEADING')"
      :sub-heading="$t('CREATE_TICKET.FORM.SECTIONS.ADD_CONTACT.SUB_HEADING')"
      icon="people"
    />
    <add-contact
      :selected-contacts="selectedContacts"
      :contact-limit="5"
      @updateContacts="updateSelectedContacts"
    >
      <template
        v-slot:selected-contacts-display-panel="{ contacts, updateContacts }"
      >
        <show-selected-contacts
          :contacts="contacts"
          @updateContacts="updateContacts"
        />
      </template>
    </add-contact>
  </section>
</template>
<script>
import AddContact from 'dashboard/components/widgets/addContact';
import ShowSelectedContacts from './addContacts/ShowSelectedContacts';
import FormSectionHeader from '../shared/FormSectionHeader';

export default {
  components: { FormSectionHeader, ShowSelectedContacts, AddContact },
  props: {
    selectedContacts: {
      type: Array,
      required: true,
    },
  },
  methods: {
    updateSelectedContacts(updatedContacts) {
      this.$emit('selectedContacts', updatedContacts);
    },
  },
};
</script>
