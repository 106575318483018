var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showHead)?_c('div',{staticClass:"message__mail-head",style:([0, 1, 9, 10, 11].includes(_vm.messageType) && 'margin-right: 3.2rem')},[_c('div',{staticClass:"flex-row flex-align--start flex-justify--between"},[_c('div',{staticClass:"profile flex-row"},[(_vm.showSystemAvatar)?_c('thumbnail',{attrs:{"src":'https://ik.imagekit.io/limechatai/Ellipse_2-3_j9UWmYtQ6.png',"bg-color":"#6bac1b","color":"#fff","size":"24px","username":_vm.senderAddress}}):_c('thumbnail',{attrs:{"src":_vm.sender.avatar_url,"bg-color":_vm.thumbnailBgColor,"color":"#fff","size":"24px","username":_vm.senderAddress}}),_vm._v(" "),_c('span',{staticClass:"title-h5_m flex-center"},[_c('account-pii-mask-wrapper',{attrs:{"pii-data":_vm.senderAddress,"mask-type":_vm.maskType,"mask-state":_vm.maskState,"toggle-icon-size":"normal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._v("\n            "+_vm._s(data)+"\n          ")]}}],null,false,4095903697)}),_vm._v(" "),(_vm.isForwarded)?_c('span',{staticClass:"body-b3 text-light forwarded-text"},[_vm._v("\n          "+_vm._s(_vm.$t('EMAIL_HEADER.FORWARDED'))+"\n        ")]):_vm._e(),_vm._v(" "),(!_vm.isFirstEmail && !_vm.isForwarded && [0, 1].includes(_vm.messageType))?_c('span',{staticClass:"body-b3 text-light forwarded-text"},[_vm._v("\n          "+_vm._s(_vm.$t('EMAIL_HEADER.REPLIED'))+"\n        ")]):_vm._e()],1)],1),_vm._v(" "),(_vm.emailAttributes.date || _vm.replyTimeStamp)?_c('span',{staticClass:"text-light body-b3 line-clamp-1",domProps:{"textContent":_vm._s(_vm.timeStamp)}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"meta-wrap"},[(_vm.toMails && !_vm.isForwarded)?_c('span',{staticClass:"text-light body-b3 flex-row"},[_vm._v("\n      "+_vm._s(_vm.$t('EMAIL_HEADER.TO') + ':\u00A0')+"\n      "),_c('account-pii-mask-wrapper',{attrs:{"pii-data":_vm.toMails,"mask-state":_vm.maskState,"toggle-icon-size":"normal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._v("\n          "+_vm._s(data)+"\n        ")]}}],null,false,124624849)})],1):_vm._e(),_vm._v(" "),(_vm.toMails && _vm.isForwarded)?_c('span',{staticClass:"text-light body-b3 flex-row"},[_vm._v("\n      "+_vm._s(_vm.$t('EMAIL_HEADER.FORWARDED_TO') + ':\u00A0')+"\n      "),_c('account-pii-mask-wrapper',{attrs:{"pii-data":_vm.toMails,"mask-state":_vm.maskState,"toggle-icon-size":"normal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._v("\n          "+_vm._s(data)+"\n        ")]}}],null,false,124624849)})],1):_vm._e(),_vm._v(" "),(_vm.ccMails)?_c('span',{staticClass:"text-light body-b3 flex-row"},[_vm._v("\n      | "+_vm._s(_vm.$t('EMAIL_HEADER.CC') + ':\u00A0')+"\n      "),_c('account-pii-mask-wrapper',{attrs:{"pii-data":_vm.ccMails,"mask-state":_vm.maskState,"toggle-icon-size":"normal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._v("\n          "+_vm._s(data)+"\n        ")]}}],null,false,124624849)})],1):_vm._e(),_vm._v(" "),(_vm.bccMails)?_c('span',{staticClass:"text-light body-b3 flex-row"},[_vm._v("\n      | "+_vm._s(_vm.$t('EMAIL_HEADER.BCC') + ':\u00A0')+"\n      "),_c('account-pii-mask-wrapper',{attrs:{"pii-data":_vm.bccMails,"mask-state":_vm.maskState},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._v("\n          "+_vm._s(data)+"\n        ")]}}],null,false,124624849)})],1):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }