import { render, staticRenderFns } from "./AssignmentPanel.vue?vue&type=template&id=604093b2&scoped=true&"
import script from "./AssignmentPanel.vue?vue&type=script&lang=js&"
export * from "./AssignmentPanel.vue?vue&type=script&lang=js&"
import style0 from "./AssignmentPanel.vue?vue&type=style&index=0&id=604093b2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "604093b2",
  null
  
)

export default component.exports