import { isValidPhoneNumber } from 'libphonenumber-js';

export const checkValidPhoneNumber = value => {
  return isValidPhoneNumber('+' + value);
};

export function validateAutomationRule(rule) {
  if (!rule.name) {
    return ['NO_NAME', false, 'NAME'];
  }

  if (!rule.event_name) {
    return ['NO_EVENT_NAME', false, 'EVENT_NAME'];
  }

  if (!rule.conditions || rule.conditions.length === 0) {
    return ['NO_CONDITIONS', false, 'CONDITIONS'];
  }

  const missingConditionFields = rule.conditions.reduce(
    (acc, condition, index) => {
      if (!condition.attribute_key) {
        acc.push({
          error: 'NO_ATTRIBUTE_KEY',
          field: `CONDITION_ATTRIBUTE_KEY_${index}`,
        });
      }
      if (!condition.values || condition.values.length === 0) {
        acc.push({
          error: 'NO_VALUES',
          field: `CONDITION_VALUES_${index}`,
          fieldSelector: '.multiselect__tags, .keyword-selector input',
        });
      }
      return acc;
    },
    []
  );

  if (missingConditionFields.length > 0) {
    return [
      missingConditionFields[0].error,
      false,
      missingConditionFields[0].field,
      missingConditionFields[0].fieldSelector,
    ];
  }

  if (!rule.actions || rule.actions.length === 0) {
    return ['NO_ACTIONS', false, 'ACTIONS'];
  }

  const missingActionFields = rule.actions.reduce((acc, action, index) => {
    if (!action.action_name) {
      acc.push({
        error: 'NO_ACTION_NAME',
        field: `ACTION_NAME_${index}`,
      });
    }
    // if action params is an array and has no values then show error
    if (
      Array.isArray(action.action_params) &&
      action.action_params.length === 0
    ) {
      acc.push({
        error: 'NO_ACTION_PARAMS',
        field: `ACTION_PARAMS_${index}`,
        fieldSelector: '.multiselect__tags',
      });
    }
    return acc;
  }, []);

  if (missingActionFields.length > 0) {
    return [
      missingActionFields[0].error,
      false,
      missingActionFields[0].field,
      missingActionFields[0].fieldSelector,
    ];
  }

  const invalidPhoneNumber = rule.test_numbers.find(number => {
    return !checkValidPhoneNumber(number);
  });

  if (invalidPhoneNumber) {
    return ['NOT_VALID_PHONE_NUMBER', false, 'TEST_MODE'];
  }

  // All validations passed
  return ['', true, ''];
}
