<template>
  <div class="status">
    <div class="status-view">
      <div
        :class="`status-badge status-badge__${currentUserAvailabilityStatus}`"
      />

      <div class="status-view--title title-h5">
        {{ availabilityDisplayLabel }}
      </div>
    </div>

    <div class="status-change title-h5_m">
      <transition name="menu-slide">
        <div
          v-if="isStatusMenuOpened"
          v-on-clickaway="closeStatusMenu"
          class="dropdown-pane top availabilty-modal"
          style="width : 25rem"
        >
          <ul class="vertical dropdown menu">
            <li
              v-for="status in availabilityStatuses"
              :key="status.value"
              class="status-items"
            >
              <div :class="`status-badge status-badge__${status.value}`" />

              <button
                class="button clear status-change--dropdown-button"
                :class="{ disabled: status.disabled }"
                @click="changeAvailabilityStatus(status.value)"
              >
                {{ status.label }}
              </button>
            </li>
          </ul>
        </div>
      </transition>

      <button class="status-change--change-button" @click="openStatusMenu">
        {{ $t('SIDEBAR_ITEMS.CHANGE_AVAILABILITY_STATUS') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

const AVAILABILITY_STATUS_KEYS = [
  'online',
  'offline',
  'meals',
  'utility',
  'oncall',
];

export default {
  mixins: [clickaway, googleAnalyticsMixin, mixPanelAnalyticsMixin],

  data() {
    return {
      isStatusMenuOpened: false,
      isUpdating: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
    }),
    availabilityDisplayLabel() {
      const availabilityIndex = AVAILABILITY_STATUS_KEYS.findIndex(
        key => key === this.currentUserAvailabilityStatus
      );
      return this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST')[
        availabilityIndex
      ];
    },
    currentUserAvailabilityStatus() {
      return this.currentUser.availability_status;
    },
    availabilityStatuses() {
      return this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST').map(
        (statusLabel, index) => ({
          label: statusLabel,
          value: AVAILABILITY_STATUS_KEYS[index],
          disabled:
            this.currentUserAvailabilityStatus ===
            AVAILABILITY_STATUS_KEYS[index],
        })
      );
    },
  },

  methods: {
    openStatusMenu() {
      this.mix_panel_clicked_change_profile_status();
      this.isStatusMenuOpened = true;
    },
    closeStatusMenu() {
      this.isStatusMenuOpened = false;
    },
    changeAvailabilityStatus(availability) {
      this.mix_panel_changed_profile_status(availability);
      this.logAvailibilityChange(availability);
      if (this.isUpdating) {
        return;
      }

      this.isUpdating = true;
      let account_id = this.accountId;
      this.$store
        .dispatch('updateAvailability', {
          availability,
          account_id,
        })
        .finally(() => {
          this.isUpdating = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.availabilty-modal {
  top: -20rem;
  left: 25rem;
  width: 25rem;
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $space-micro $space-smaller;
}

.status-view {
  display: flex;
  align-items: baseline;

  & &--title {
    color: $color-woot;
    margin-left: $space-small;

    &:first-letter {
      text-transform: capitalize;
    }
  }
}

.status-change {
  .dropdown-pane {
    top: -180px;
  }

  .status-items {
    display: flex;
    align-items: baseline;

    .disabled {
      color: $neutral-grey-700;
    }
  }

  & &--change-button {
    color: $pg-1-500;
    font-size: $font-size-small;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  & &--dropdown-button {
    font-weight: $font-weight-normal;
    font-size: $font-size-small;
    padding: $space-small $space-one;
    text-align: left;
    width: 100%;
  }
}

.status-badge {
  width: $space-one;
  height: $space-one;
  border-radius: 50%;

  &__online {
    background: $success-color;
  }

  &__offline {
    background: $color-gray;
  }

  &__utility,
  &__meals,
  &__oncall {
    background: $warning-color;
  }
}
</style>
