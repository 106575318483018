import adminIntro from 'assets/images/admin_intro.svg';
import supervisorIntro from 'assets/images/supervisor_intro.svg';
import agentIntro from 'assets/images/agent_intro.svg';

export const USER_ROLES = [
  { id: 1, name: 'All' },
  { id: 2, name: 'administrator' },
  { id: 3, name: 'agent' },
  { id: 4, name: 'supervisor' },
];

export const ROLE_COLORS = {
  administrator: '#4267B2',
  supervisor: '#FF99C3',
  agent: '#5AD8A6',
};

export const SHOWCASE_ROLE_DATA = [
  {
    name: 'administrator',
    key: 'ADMINISTRATOR',
    color: ROLE_COLORS.administrator,
    image: adminIntro,
    contentPath: 'AGENT_MGMT',
  },
  {
    name: 'supervisor',
    key: 'SUPERVISOR',
    color: ROLE_COLORS.supervisor,
    image: supervisorIntro,
    contentPath: 'AGENT_MGMT',
  },
  {
    name: 'agent',
    key: 'AGENT',
    color: ROLE_COLORS.agent,
    image: agentIntro,
    contentPath: 'AGENT_MGMT',
  },
];
