<template>
  <div class="flex-row flex-justify">
    <woot-base-button @click="openWidget">
      {{
        $t(
          'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.OVERVIEW_PANEL.FORM.CREATE_TICKET'
        )
      }}
    </woot-base-button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import * as types from 'shared/constants/googleEventType';

export default {
  mixins: [googleAnalyticsMixin],
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
  },
  methods: {
    openWidget() {
      this.googleAnalyticsEvent(
        types.default.RAISE_TICKET,
        types.default.RIGHT_SIDE_PANEL_EVENT,
        types.default.RAISE_TICKET_FRESHWORKS
      );
      // eslint-disable-next-line no-undef
      FreshworksWidget('open', 'ticketForm');
      // eslint-disable-next-line no-undef
      FreshworksWidget('prefill', 'ticketForm', {
        name: this.contact.name,
        email: this.contact.email,
        phone: this.contact.phone_number,
        description: ' \n\n\n Link : ' + window.location.href,
      });
    },
  },
};
</script>
