<template>
  <ul class="top-nav-bar">
    <li
      v-for="(tab, index) in tabList"
      :key="tab.key"
      :class="{
        'is-active': active === index,
        'is-first': index === 0,
        'is-last': index === tabList.length - 1,
        'is-inactive': index !== active && index > active,
      }"
      @click="switchActive(index)"
    >
      <div
        class="folder-tab flex-row-center"
        :style="[customStyle]"
        :class="{
          'is-first': index === 0,
          'title-h4': active === index,
          'subtitle-s1': active !== index,
        }"
      >
        <div
          class="index-number title-h5 mg-right--slab flex-column-center flex-justify"
          :class="{
            'active-index': active === index,
            'inactive-index': active !== index,
          }"
        >
          {{ index + 1 }}
        </div>
        <span>{{ tab.name }}</span>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    active: {
      type: Number,
      default: 0,
    },
    tabList: {
      type: Array,
      default: () => [],
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    switchActive(index) {
      this.$emit('click', index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.top-nav-bar {
  display: flex;
  flex-direction: row;

  li {
    display: inline-block;
    margin-left: -$space-slab;
    z-index: 1;

    .folder-tab {
      border: 1px solid $neutral-grey-400;
      color: $text-dark;
      position: relative;
      box-sizing: content-box;
      background: $color-white;
      padding: $space-slab $space-large $space-slab 2rem;
      border-radius: $zero $border-radius * 2 $border-radius * 2 $zero;

      .index-number {
        height: $space-medium;
        width: $space-medium;
        border-radius: 55%;
        padding: $space-smaller;
      }

      .active-index {
        background: $color-white;
        color: $pg-1-500;
      }

      .inactive-index {
        background: $text-dark;
        color: $color-white;
      }
    }
  }

  .is-first {
    border-radius: $border-radius $border-radius * 2 $border-radius * 2
      $border-radius !important;
    padding-left: $space-slab !important;
    z-index: 2;
  }

  .is-last {
    z-index: 0;
  }

  .is-active {
    .folder-tab {
      background: $pg-1-500;
      color: $neutral-white;
    }
  }
  .is-inactive {
    .folder-tab {
      background: $neutral-grey-300;
      pointer-events: none;
    }
  }
}
</style>
