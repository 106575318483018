<template>
  <div class="relative-position">
    <span
      v-if="isSelected"
      class="table-title flex-row-justify-center"
      style="justify-content: left"
    >
      <icons
        name="chevronLeft"
        color="green"
        size="mediumlarge"
        @click="$emit('select-summary')"
      />
      <span class="subtable-title">{{ title }} </span>
    </span>
    <span v-else class="table-title">{{ title }} </span>
    <slot name="filters" />
    <span class="tooltip-position flex-row-justify-center">
      <div
        v-if="isDownloadable"
        class="flex-row-justify-center"
        style="margin-right: 0.3rem"
        @click="$emit('download-csv')"
      >
        <woot-base-button front-icon="download">
          {{ downloadText }}
        </woot-base-button>
        <span v-if="isDownloading" class="spinner"> </span>
      </div>
      <div v-if="tooltipContent" v-tooltip="tooltipContent">
        <icons name="info" size="medium" color="grey" />
      </div>
    </span>
    <div>
      <table
        v-if="maxHeight"
        class="metrics-table custom-scroll scroll-table relative-position"
        :style="{ height: maxHeight }"
      >
        <tr>
          <th v-for="item in headerList" :key="item.id" class="sticky-header">
            <div
              v-if="isSortable"
              class="table-header"
              @click="sortBy(item.id)"
            >
              <icons name="sort" color="green" size="semimedium" />
              <span style="margin-left: 0.8rem">{{ item.name }}</span>
            </div>
            <div v-else class="table-header">
              <span>{{ item.name }}</span>
            </div>
          </th>
        </tr>
        <tbody v-if="tableData.length && !hasSubtable">
          <tr v-for="(row, $index) in formattedTableData" :key="$index">
            <td :style="{ width: columnWidthOne }">
              {{ row[0] }}
            </td>
            <td
              v-for="(cell, $index2) in row.slice(1)"
              :key="$index2"
              :style="{ width: columnWidthTwo }"
            >
              <span> {{ cell }}</span>
            </td>
          </tr>
        </tbody>
        <tbody v-if="tableData.length && hasSubtable">
          <tr
            v-for="(row, $index) in formattedTableData"
            :key="$index"
            @click="$emit('select-agent', row[0])"
          >
            <td v-for="(cell, i) in row" :key="i">
              {{ cell }}
            </td>
          </tr>
        </tbody>
      </table>

      <table v-else class="metrics-table">
        <tr>
          <th v-for="item in headerList" :key="item.id">
            <div
              v-if="isSortable"
              class="table-header"
              @click="sortBy(item.id)"
            >
              <icons name="sort" color="green" size="semimedium" />
              <span style="margin-left: 0.8rem">{{ item.name }}</span>
            </div>
            <div v-else class="table-header">
              <span>{{ item.name }}</span>
            </div>
          </th>
        </tr>
        <tbody v-if="tableData.length && !hasSubtable">
          <tr v-for="(row, $index) in formattedTableData" :key="$index">
            <td :style="{ width: columnWidthOne }">
              <div :style="{ width: textWidth }" v-html="row[0]" />
            </td>
            <td
              v-for="(cell, i) in row.slice(1)"
              :key="i"
              :style="{ width: columnWidthTwo }"
            >
              <span v-if="cell == 'AGENT HANDOFF' || cell == 'BOT CONFUSED'">
                <span v-if="cell == 'AGENT HANDOFF'">
                  <woot-badge
                    :text="cell"
                    type="badge-yellow-variant"
                    :no-icon="true"
                  />
                </span>
                <span v-if="cell == 'BOT CONFUSED'">
                  <woot-badge
                    :text="cell"
                    type="badge-green-variant"
                    :no-icon="true"
                  />
                </span>
              </span>
              <span v-else-if="typeof cell === 'object'">
                <router-link :to="cell.link" class="row-link">
                  <span class="mg-right--smaller">{{ cell.message }}</span>
                  <u> {{ cell.conv_id }}</u>
                </router-link>
              </span>
              <span v-else v-html="cell" />
            </td>
          </tr>

          <tr v-if="totalArray.length > 0">
            <td
              v-for="(row, $index) in totalArray"
              :key="$index"
              class="row-padding"
            >
              {{ row }}
            </td>
          </tr>
        </tbody>
        <tbody v-if="tableData.length && hasSubtable">
          <tr
            v-for="(row, $index) in formattedTableData"
            :key="$index"
            @click="$emit('select-agent', row[0])"
          >
            <td v-for="(cell, i) in row" :key="i">
              {{ cell }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!tableData.length" class="no-data flex-column-center">
      <div>
        <img
          :src="require('dashboard/assets/images/noData.svg')"
          alt="No Data"
          height="100px"
          width="100px"
        />
      </div>
      <p class="no-items-error-message">
        {{ 'No data found' }}
      </p>
    </div>

    <div
      v-if="isPaginated && isPaginationVisible"
      class="flex-row-justify-center"
      style="justify-content: flex-end"
    >
      <p style="margin-top: 1rem; margin-right: 1rem">
        {{ pageSize }} of {{ totalCount }}
      </p>
      <span class="icon-button rotate-180">
        <icons
          name="chevronRightDouble"
          size="medium"
          color="green"
          @click="onFirstPage"
        />
      </span>
      <span class="icon-button">
        <icons
          name="chevronLeft"
          size="medium"
          color="green"
          @click="onPrevPage"
        />
      </span>
      <p v-if="currentPage < 10" style="margin-top: 1rem">
        {{ '0' + currentPage }}
      </p>
      <p v-else style="margin-top: 1rem">
        {{ currentPage }}
      </p>
      <span class="icon-button">
        <icons
          name="chevronRight"
          size="medium"
          color="green"
          @click="onNextPage"
        />
      </span>
      <span class="icon-button">
        <icons
          name="chevronRightDouble"
          size="medium"
          color="green"
          @click="onLastPage"
        />
      </span>
    </div>
    <slot name="bottom" />
  </div>
</template>

<script>
import timeMixin from 'dashboard/mixins/time';
export default {
  mixins: [timeMixin],
  props: {
    headerList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    isSortable: {
      type: Boolean,
      default: false,
    },
    hasSubtable: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    columnWidthOne: {
      type: String,
      default: 'auto',
    },
    columnWidthTwo: {
      type: String,
      default: 'auto',
    },
    textWidth: {
      type: String,
      default: 'auto',
    },
    isPaginated: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    onPageChange: {
      type: Function,
      default: () => {},
    },
    isDownloadable: {
      type: Boolean,
      default: false,
    },
    isDownloading: {
      type: Boolean,
      default: true,
    },
    downloadText: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: String,
      default: '',
    },
    tooltipContent: {
      type: String,
      default: '',
    },
    totalArray: {
      type: Array,
      default: () => [],
    },
    isLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortParam: 'name',
      sortDir: true, // 'asc'
    };
  },
  computed: {
    isPaginationVisible() {
      return this.totalCount >= this.pageSize && this.tableData.length;
    },
    firstIndex() {
      const firstIndex = this.pageSize * (this.currentPage - 1) + 1;
      return firstIndex;
    },
    lastIndex() {
      const index = Math.min(this.totalCount, this.pageSize * this.currentPage);
      return index;
    },
    hasLastPage() {
      const isDisabled =
        this.currentPage === Math.ceil(this.totalCount / this.pageSize);
      return isDisabled;
    },
    hasFirstPage() {
      const isDisabled = this.currentPage === 1;
      return isDisabled;
    },
    hasNextPage() {
      const isDisabled =
        this.currentPage === Math.ceil(this.totalCount / this.pageSize);
      return isDisabled;
    },
    hasPrevPage() {
      const isDisabled = this.currentPage === 1;
      return isDisabled;
    },
    formattedTableData() {
      let requiredData = this.isSortable
        ? this.sortedTableMetrics
        : this.tableData;
      return requiredData.map(row => {
        return [...Object.values(row)];
      });
    },
    sortedTableMetrics() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.tableData.sort((a, b) => {
        let modifier = 1;
        if (!this.sortDir) modifier = -1;
        if (a[this.sortParam] < b[this.sortParam]) return -1 * modifier;
        if (a[this.sortParam] > b[this.sortParam]) return 1 * modifier;
        return 0;
      });
    },
  },
  methods: {
    sortBy(item) {
      this.sortParam = item;
      this.sortDir = !this.sortDir;
    },
    onNextPage() {
      if (this.hasNextPage) return;
      const newPage = this.currentPage + 1;
      this.onPageChange(newPage);
    },
    onPrevPage() {
      if (this.hasPrevPage) return;

      const newPage = this.currentPage - 1;
      this.onPageChange(newPage);
    },
    onFirstPage() {
      if (this.hasFirstPage) return;

      const newPage = 1;
      this.onPageChange(newPage);
    },
    onLastPage() {
      if (this.hasLastPage) return;

      const newPage = Math.ceil(this.totalCount / this.pageSize);
      this.onPageChange(newPage);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.table-title {
  font-size: $font-size-medium;
  line-height: 2.4rem;
  color: $neutral-grey-800;
  font-weight: $font-weight-medium;
  cursor: pointer;
}

.sticky-header {
  position: sticky;
  top: 0;
  background: $color-white;
  width: 100%;
}

.scroll-table {
  display: block;
  overflow: auto;
}

.relative-position {
  position: relative;
}

.badge-yellow-one {
  color: #8f680c;
  background: $warn-yellow-200;
  padding: $space-micro $space-small;
  border-radius: $space-smaller;
  font-weight: $font-weight-medium;
}
.badge-green {
  color: $pg-1-700;
  background: $pg-light-1;
  padding: $space-micro $space-small;
  border-radius: $space-smaller;
  font-weight: $font-weight-medium;
}
.download-link {
  color: $pg-1;
  font-size: $font-size-small;
  text-transform: uppercase;
  font-weight: $font-weight-medium;
  margin: $space-smaller;
  &:hover {
    background: $neutral-grey-100;
    cursor: pointer;
  }
}

.icon-button {
  padding: $space-small;
  border-radius: 50%;

  &:hover {
    background: $neutral-grey-100;
    cursor: pointer;
  }
}

.status-color {
  text-transform: capitalize;

  &__online {
    color: $success-color;
  }

  &__offline {
    color: $color-gray;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}
.tooltip-position {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.subtable-title {
  margin-left: 1.2rem;
  align-items: center;
  margin-top: 3px;
}
.no-data {
  margin-top: $space-slab;
  min-height: 18rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.row-link {
  color: $secondary-blue;
}

.metrics-table {
  margin-top: $space-medium;
  border-radius: $border-radius;

  .table-header {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
  }
}

.no-items-error-message {
  text-align: center;
}

.status-color {
  text-transform: capitalize;

  &__online {
    color: $success-color;
  }

  &__offline {
    color: $color-gray;
  }

  &__utility,
  &__meals,
  &__oncall {
    color: $warning-color;
  }
}
.row-padding {
  padding: $space-slab;
  padding-left: $space-medium;
}
table {
  border-collapse: separate;
  background: $neutral-white;
  border-spacing: 0;
  min-width: 350px;

  tr {
    td {
      border-right: 1px solid $neutral-grey-500;
      border-bottom: 1px solid $neutral-grey-400;
      padding-left: $space-medium;
    }

    th {
      border-right: 1px solid $neutral-grey-500;
      border-bottom: 1px solid $neutral-grey-600;
    }

    th:first-child,
    td:first-child {
      border-left: 1px solid $neutral-grey-400;
    }

    th {
      border-top: 1px solid $neutral-grey-400;
      text-align: left;
      padding: $space-normal;
      font-size: $font-size-default;
      font-weight: $font-weight-normal;
      line-height: $space-medium;
    }

    &:hover {
      background: $neutral-grey-200;
      cursor: pointer;
    }

    &:first-child th {
      &:first-child {
        border-top-left-radius: $border-radius;
      }

      &:last-child {
        border-top-right-radius: $border-radius;
      }
    }

    &:last-child td {
      &:first-child {
        border-bottom-left-radius: $border-radius;
      }

      &:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}
</style>
