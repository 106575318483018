<template>
  <div class="column content-box settings-wrapper">
    <form-header
      :edit-view="editView"
      :custom-field-name="customField.name"
      :custom-field-desc="
        customField.description ||
          $t('CUSTOM_TICKET_FIELDS.ADD.FORM.HEADER.DESC')
      "
      :show-details-modal="() => (showDetailsModal = true)"
      :enable-status="customField.enabled"
      @toggleFieldStatus="customField.enabled = $event"
    />
    <custom-field-details-modal
      v-if="showDetailsModal"
      opened
      :name="customField.name"
      :description="customField.description"
      @onSubmit="onSubmitDetailsModal"
      @onClose="showDetailsModal = false"
    />
    <div
      class="custom-field-form-wrapper lime-card-1dp flex-column flex-justify--between"
    >
      <div
        class="flex-column gap--normal custom-scroll mg-bottom--small full-height"
      >
        <type-section
          :edit-view="editView"
          :selected-type="{
            field_type: customFieldType,
            name: getCustomFieldNameByType(customFieldType),
          }"
          @updateType="changeFieldType"
        />
        <draggable-values-section
          v-if="isDropdownField"
          :disabled="isMultiSelectField && editView"
          class="CUSTOM_FIELD_VALUES"
          :list="customField.options"
          @updateValues="customField.options = $event"
          @updateLabelName="
            (name, index) => (customField.options[index].value = name)
          "
          @addField="
            index =>
              customField.options.splice(index + 1, 0, { id: null, value: '' })
          "
          @removeField="deleteField"
        />
        <inbox-selection-section
          class="CUSTOM_FIELD_INBOXES"
          :custom-field-inboxes="customFieldInboxes"
          @addInbox="customFieldInboxes.push($event)"
          @removeInbox="removeInboxFromList"
        />
        <div class="checkbox-container">
          <label class="container-checkbox">
            <input
              v-model="customField.mandatory"
              class="notification--checkbox"
              type="checkbox"
            />
            <span class="checkmark"></span>
          </label>
          <label class="checkbox-label" for="customField.mandatory">
            {{ $t('CUSTOM_TICKET_FIELDS.ADD.FORM.MANDATORY') }}
          </label>
        </div>
      </div>
      <Footer :edit-view="editView" @submitFieldForm="createOrEditField" />
    </div>
  </div>
</template>

<script>
import FormHeader from '../../headers/FormHeader';
import CustomFieldDetailsModal from '../CustomFieldDetailsModal';

import TypeSection from './TypeSection';
import InboxSelectionSection from './InboxSelectionSection';
import DraggableValuesSection from './DraggableValuesSection';
import Footer from './Footer';

import alertMixin from 'shared/mixins/alertMixin';
import showError from 'dashboard/helper/showError';

import { ERROR_PREFIX, CUSTOM_FIELD_TYPES } from '../../utils/constants';
import {
  isDropdown,
  cleanEmptyStrings,
  getCustomFieldNameByType,
  getPayload,
} from '../../utils/helper';
import { validateCustomField } from '../../utils/validations';

export default {
  components: {
    FormHeader,
    CustomFieldDetailsModal,
    TypeSection,
    InboxSelectionSection,
    DraggableValuesSection,
    Footer,
  },
  mixins: [alertMixin],
  props: {
    editView: {
      type: Boolean,
      default: false,
    },
    customField: {
      type: Object,
      default: () => {},
    },
    initialCustomFieldInboxes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDetailsModal: false,
      customFieldInboxes: [...this.initialCustomFieldInboxes],
    };
  },
  computed: {
    isDropdownField() {
      return isDropdown(this.customFieldType);
    },
    isMultiSelectField() {
      return this.customFieldType === CUSTOM_FIELD_TYPES.MULTI_SELECT;
    },
    customFieldType() {
      return this.customField.field_type;
    },
  },
  methods: {
    getCustomFieldNameByType,
    onSubmitDetailsModal({ name, description }) {
      this.customField.name = name ?? this.customField.name;
      this.customField.description =
        description ?? this.customField.description;
      this.showDetailsModal = false;
    },
    changeFieldType({ id: fieldType }) {
      this.customField = getPayload({
        ...this.customField,
        field_type: fieldType,
      });
    },
    removeInboxFromList(inbox) {
      this.customFieldInboxes = this.customFieldInboxes.filter(
        item => item.id !== inbox.id
      );
    },
    deleteField(index) {
      this.customField.options.splice(index, 1);
      if (!this.customField.options.length)
        this.customField.options.push({ id: null, value: '' });
    },
    async createOrEditField() {
      let payload = { ...this.customField };
      payload.field_type = this.customFieldType;

      payload.inbox_ids = this.customFieldInboxes.map(inbox => inbox.id);

      if (this.isDropdownField)
        payload.options = cleanEmptyStrings(payload.options);

      const [
        errorMessage,
        isValid,
        errorneousFieldClassName,
      ] = validateCustomField(payload, this.isDropdownField);

      if (!isValid) {
        showError({
          message: this.$t(`${ERROR_PREFIX}.FORM.ERRORS.${errorMessage}`),
          isValid,
          errorneousFieldClassName: errorneousFieldClassName,
        });
        return;
      }

      this.$emit('submitFieldForm', payload);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.custom-field-form-wrapper {
  height: calc(100% - 6.7rem);
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: $space-normal;
  margin-left: $space-normal;

  input {
    margin: $zero;
  }

  .checkbox-label {
    font-size: $font-size-small;
    line-height: 2.2rem;
    color: $neutral-grey-800;
    margin-top: 3 * $space-three;
  }

  .checkmark {
    cursor: pointer;
  }
}
</style>
