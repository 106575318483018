<template>
  <div class="lime-card-1dp medium-12">
    <span class="table-title">Agent Attendance Data</span>
    <table class="metrics-table">
      <tr>
        <th v-for="item in headerList" :key="item.id">
          <div class="table-header" @click="sortBy(item.id)">
            <icons name="sort" color="green" size="semimedium" />
            <span style="margin-left: 0.8rem">{{ item.name }}</span>
          </div>
        </th>
      </tr>
      <tbody v-if="!uiFlag && attendanceMetrics.length">
        <tr
          v-for="(item, index) in sortedAttendanceMetrics"
          :key="index"
          @click="$emit('select-agent', item.id)"
        >
          <td style="width: 30%">
            {{ item.name }}
          </td>
          <td
            :class="`status-color status-color__${item.current_availability}`"
          >
            {{ item.current_availability || '-' }}
          </td>
          <td>
            {{ humanReadableTime(item.last_login) }}
          </td>
          <td>
            {{ humanReadableTime(item.last_logout) }}
          </td>
          <td
            v-html="
              formattedDuration(
                secondsToDuration(parseInt(item.active_time * 60, 10))
              )
            "
          />
          <td
            v-html="
              formattedDuration(
                secondsToDuration(parseInt(item.break_time * 60, 10))
              )
            "
          />
        </tr>
      </tbody>
    </table>
    <p
      v-if="!attendanceMetrics.length && !uiFlag"
      class="no-items-error-message"
    >
      {{ 'No data found' }}
    </p>
    <woot-loading-state v-if="uiFlag" message="Fetching agent attendance" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import timeMixin from 'dashboard/mixins/time';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import * as types from 'shared/constants/googleEventType';

export default {
  mixins: [timeMixin, googleAnalyticsMixin],
  props: {
    headerList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortParam: 'name',
      sortDir: true, // 'asc'
    };
  },
  computed: {
    ...mapGetters({
      attendanceMetrics: 'getAttendanceMetrics',
      uiFlag: 'getAttendanceUIFlag',
    }),
    sortedAttendanceMetrics() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.attendanceMetrics.sort((a, b) => {
        let modifier = 1;
        if (!this.sortDir) modifier = -1;
        if (a[this.sortParam] < b[this.sortParam]) return -1 * modifier;
        if (a[this.sortParam] > b[this.sortParam]) return 1 * modifier;
        return 0;
      });
    },
  },
  methods: {
    sortBy(item) {
      this.sortParam = item;
      this.sortDir = !this.sortDir;
      this.googleAnalyticsEvent(
        types.default.AGENT_ATTENDANCE_SORT,
        this.sortParam,
        this.sortDir
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.table-title {
  font-size: $font-size-medium;
  line-height: 2.6rem;
  color: $neutral-grey-800;
  font-weight: $font-weight-medium;
}

.metrics-table {
  margin-top: $space-medium;
  border-radius: $border-radius;

  .table-header {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
  }
}

.no-items-error-message {
  height: auto;
}

.status-color {
  text-transform: capitalize;

  &__online {
    color: $success-color;
  }

  &__offline {
    color: $color-gray;
  }

  &__utility,
  &__meals,
  &__oncall {
    color: $warning-color;
  }
}
table {
  border-collapse: separate;
  background: $neutral-white;
  border-spacing: 0;
  min-width: 350px;

  tr {
    td {
      border-right: 1px solid $neutral-grey-500;
      border-bottom: 1px solid $neutral-grey-400;
      padding: $space-slab $space-normal;
    }

    th {
      border-right: 1px solid $neutral-grey-500;
      border-bottom: 1px solid $neutral-grey-600;
    }

    th:first-child,
    td:first-child {
      border-left: 1px solid $neutral-grey-400;
    }

    th {
      border-top: 1px solid $neutral-grey-400;
      text-align: left;
      padding: $space-normal;
      font-size: $font-size-default;
      font-weight: $font-weight-normal;
      line-height: $space-medium;
    }

    &:hover {
      background: $neutral-grey-200;
      cursor: pointer;
    }

    &:first-child th {
      &:first-child {
        border-top-left-radius: $border-radius;
      }

      &:last-child {
        border-top-right-radius: $border-radius;
      }
    }

    &:last-child td {
      &:first-child {
        border-bottom-left-radius: $border-radius;
      }

      &:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}
</style>
