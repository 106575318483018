<template>
  <custom-field-form
    :custom-field="customField"
    :initial-custom-field-inboxes="customFieldInboxes"
    @submitFieldForm="createField"
  />
</template>

<script>
import CustomFieldForm from '../components/form';

import alertMixin from 'shared/mixins/alertMixin';

import { CUSTOM_FIELD_TYPES } from '../utils/constants';
import { getPayload, isDropdown } from '../utils/helper';
import mixpanelEvent from 'dashboard/helper/mixpanel';

export default {
  components: {
    CustomFieldForm,
  },
  mixins: [alertMixin],
  data() {
    return {
      customField: getPayload({
        name: this.$route.params.customFieldName || null,
        description: this.$route.params.customFieldDesc || null,
        field_type:
          this.$route.params.customFieldType || CUSTOM_FIELD_TYPES.SHORT_TEXT,
      }),
      customFieldInboxes: [],
    };
  },
  methods: {
    async createField(payload) {
      mixpanelEvent('clicked_create_field');
      try {
        if (isDropdown(payload.field_type))
          payload.options = payload.options.map(option => option.value);
        await this.$store.dispatch('customTicketFields/create', payload);

        this.showAlert(
          this.$t('CUSTOM_TICKET_FIELDS.SUCCESS_MESSAGE'),
          'success'
        );

        this.$router.push({ name: 'settings_custom_ticket_fields' });
      } catch (errorMessage) {
        this.showAlert(
          this.$t('CUSTOM_TICKET_FIELDS.ERRORS.ERROR_MESSAGE'),
          'error'
        );
      }
    },
  },
};
</script>
