import SettingsContent from '../Wrapper';
import Automation from './Index';
import CreateAutomation from './views/CreateAutomation';
import EditAutomation from './views/EditAutomation';

import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/automation'),
      component: SettingsContent,
      props: {
        headerTitle: 'AUTOMATION.HEADER',
        icon: 'automation',
      },
      children: [
        {
          path: '',
          name: 'automations_wrapper',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'automation_list',
          component: Automation,
          roles: ['administrator'],
        },
        {
          path: 'create',
          name: 'create_automation',
          component: CreateAutomation,
          roles: ['administrator'],
        },
        {
          path: 'create/:template_id',
          name: 'create_automation',
          component: CreateAutomation,
          roles: ['administrator'],
        },
        {
          path: 'edit/:rule_id',
          name: 'edit_automation',
          component: EditAutomation,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
