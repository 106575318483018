import SettingsContent from '../Wrapper';
import List from './index';
import Create from './views/Create';
import Edit from './views/Edit';
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL(
        'accounts/:accountId/settings/service-level-agreements'
      ),
      roles: ['administrator'],
      component: SettingsContent,
      props: {
        headerTitle: 'SLA.HEADER',
        icon: 'filterBtn',
        showNewButton: true,
      },
      children: [
        {
          path: '',
          name: 'settings_sla',
          component: List,
          roles: ['administrator'],
        },
        {
          path: 'create',
          name: 'settings_create_sla',
          component: Create,
          roles: ['administrator'],
        },
        {
          path: 'edit/:sla_id',
          name: 'settings_edit_sla',
          component: Edit,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
