import { render, staticRenderFns } from "./EmojiInput.vue?vue&type=template&id=336b4777&scoped=true&"
import script from "./EmojiInput.vue?vue&type=script&lang=js&"
export * from "./EmojiInput.vue?vue&type=script&lang=js&"
import style0 from "./EmojiInput.vue?vue&type=style&index=0&id=336b4777&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "336b4777",
  null
  
)

export default component.exports