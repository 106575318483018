<template>
  <div class="conversation-header">
    <div class="header-title">
      <skeleton-loader width="200px" height="28px" />
    </div>
    <div class="search-filter">
      <skeleton-loader width="100%" height="32px" />
    </div>
    <div class="filter-options">
      <skeleton-loader width="100px" height="30px" />
      <skeleton-loader width="100px" height="30px" />
      <skeleton-loader width="100px" height="30px" />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    SkeletonLoader: {
      props: {
        height: { type: String, default: '100%' },
        width: { type: String, default: '100%' },
      },
      render(h) {
        return h('div', {
          attrs: {
            class: 'skeleton-animation',
            style: `height: ${this.height}; width: ${this.width}`,
          },
        });
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.conversation-header {
  display: flex;
  flex-direction: column;
  gap: $space-slab;
  padding: $space-slab;
  margin: $space-slab $space-slab $zero $space-slab;
  background-color: $bg-green;
  border-radius: $border-radius;
  border: 1px solid $pg-light-1;

  .header-title {
    display: flex;
    align-items: center;
  }

  .search-filter {
    display: flex;
    align-items: center;
    gap: $space-small;
  }

  .filter-options {
    display: flex;
    gap: $space-small;
  }
}
</style>
