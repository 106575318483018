<template>
  <div
    class="profile-container"
    @mouseenter="() => (hovering = true)"
    @mouseleave="() => (hovering = false)"
  >
    <img
      v-if="!imgError"
      :src="imgSrc"
      :class="{ 'grey-filterr': hovering }"
      @error="onImgError"
    />
    <div class="actions-container">
      <span
        v-if="action"
        class="action-btn title-h6 text-dark"
        @click="$emit('action')"
        v-text="action"
      />
      <span v-if="isDelete" class="action-btn" @click="$emit('delete')">
        <icons name="delete" size="semimedium" color="red" />
      </span>
    </div>
    <transition v-if="!disableFileUpload" name="modal-fade">
      <div v-show="hovering" class="center-aligned flex-column flex-align">
        <file-upload
          :size="4096 * 4096"
          accept="image/*"
          @input-filter="handleImageUpload"
        >
          <div class="upload-image">
            <icons name="camera" size="large" class="camera-icon" />
          </div>
        </file-upload>
        <span
          style="color: #fff"
          class="title-h5 text-align--center"
          v-text="$t('AGENT_MGMT.RHS_PANEL.SECTIONS.PROFILE_VIEWER.TITLE')"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import FileUpload from 'vue-upload-component';

export default {
  components: {
    FileUpload,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    action: {
      type: [String, Boolean],
      default: '',
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    disableFileUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { imgSrc: '', imgError: false, hovering: false };
  },
  watch: {
    src: {
      handler(newValue, oldValue) {
        this.imgSrc = newValue;
        if (newValue !== oldValue && this.imgError) {
          this.imgError = false;
        }
      },
    },
  },
  mounted() {
    this.imgSrc = this.src;
  },
  methods: {
    handleImageUpload(newFile, oldFile) {
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.showAlert('Not an image!', 'warning');
        } else {
          newFile.url = '';
          let URL = window.URL || window.webkitURL;
          if (URL && URL.createObjectURL) {
            newFile.url = URL.createObjectURL(newFile.file);
            this.imgError = false;
            this.imgSrc = newFile.url;
            this.$emit('profileChange', {
              file: newFile.file,
              url: newFile.url,
            });
          }
        }
      }
    },
    onImgError() {
      this.imgError = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.profile-container {
  height: 100%;
  position: relative;
  width: 100%;

  .upload-image {
    background: $neutral-white;
    border-radius: 50%;
    padding: $space-smaller;
    opacity: 0.75;

    ::v-deep .custom-icons {
      position: relative;
      left: $space-six;
      top: 0.7rem;
    }
  }

  img {
    border-radius: $border-radius-smaller;
    height: 100%;
    object-fit: cover;
    text-indent: -10000px;
    transition: all 0.3s ease-out;
    width: 100%;
  }

  .grey-filter {
    filter: grayscale(100%);
  }

  .action-btn {
    background-color: $neutral-white;
    border-radius: $border-radius-smaller;
    cursor: pointer;
    height: $height-h2;
    padding: $space-smaller $space-small;
    position: absolute;
    right: $space-small;
    text-transform: uppercase;

    &:first-child {
      top: $space-small;
    }

    &:last-child {
      bottom: $space-small;
    }

    &:active {
      transform: translateY($space-micro);
    }

    &:hover {
      background-color: $neutral-grey-200;
    }
  }
}

.file-uploads ::v-deep label {
  cursor: pointer;
}
</style>
