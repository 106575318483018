<template>
  <div class="flex-container actions--container">
    <div class="action--button" @click="toggleDropdown">
      <button v-if="!customButton" class="clear more--button">
        <span v-if="name" class="button-text">{{ name }}</span>
        <icons :name="icon" :size="iconSize" :color="iconColor" />
      </button>
      <slot v-else />
    </div>
    <ul
      v-if="showDropdown"
      v-on-clickaway="hideDropdown"
      :style="[customStyle]"
      class="dropdown--pane lime-card custom-scroll"
      :class="{ 'dropdown--pane--open': showDropdown }"
    >
      <li v-for="option in optionList" :key="option.id">
        <button
          v-if="!isSelected(option) || showAll"
          class="button dropdown-button"
          type="button"
          :class="{
            'dropdown-button--selected':
              (isSelected(option) && disabled) || option.selected,
            'dropdown-button--disabled': option.disabled,
          }"
          @click="onClick(option)"
        >
          <span v-if="option.icon" style="margin-right: 8px">
            <icons :name="option.icon" size="semimedium" color="grey" />
          </span>
          <span class="text-truncate">{{ option.name }}</span>
        </button>
      </li>
      <li v-if="!optionList.length">
        <button class="button action--button">
          <span>{{ 'No options available' }}</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin, clickaway],
  props: {
    customButton: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    selected: {
      type: String,
      default: '',
    },
    optionList: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: 'chevronDown',
    },
    iconSize: {
      type: String,
      default: 'medium',
    },
    iconColor: {
      type: String,
      default: 'green',
    },
    label: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
    hideOnSelect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  mounted() {
    bus.$on('dropdown_pane', this.hideDropdown);
  },
  methods: {
    isSelected(option) {
      return this.selected === option.name;
    },
    onClick(val) {
      this.$emit('click', val);
      if (this.hideOnSelect) {
        this.toggleDropdown();
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    hideDropdown() {
      this.showDropdown = false;
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/mixins';
@import '~dashboard/assets/scss/variables';

.actions--container {
  position: relative;

  .action--button {
    display: flex;
    width: 100%;

    .more--button {
      align-items: center;
      display: flex;
      overflow: hidden;
      cursor: pointer;

      &:focus {
        box-shadow: none;
        outline: none;
      }

      .button-text {
        color: $neutral-grey-600;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: $space-small;
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        text-transform: capitalize;
        line-height: 3.8rem;
      }
    }
  }

  .dropdown--pane {
    padding: 0;
    position: absolute;
    z-index: 10;
    max-height: 30rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 0;
    left: -$space-slab;
    top: $space-large;
    width: calc(100% + 2.4rem);

    &:last-child {
      .dropdown-button--selected {
        margin-bottom: $zero;
      }
    }

    .dropdown-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      white-space: nowrap;
      padding: $space-small $space-slab;
      color: $neutral-grey-800;
      font-size: $font-size-small;
      font-weight: $font-weight-normal;
      line-height: 2.2rem;
      margin-right: $space-normal;
      background-color: transparent;
      border-color: transparent;
      border: 0;
      text-transform: capitalize;

      &::first-letter {
        text-transform: uppercase;
      }

      &:hover {
        background: $neutral-grey-300;
      }

      &--selected {
        background: $pg-1-500;
        color: $neutral-white;
        pointer-events: none;
        margin-bottom: 0.1rem;
      }

      &--disabled {
        background: $neutral-white;
        opacity: 0.4;
        color: $neutral-grey-800;
        pointer-events: none;
      }
    }
  }

  .dropdown--pane--open {
    display: block;
    visibility: visible;
  }
}

button:disabled,
button[disabled] {
  background: $neutral-grey-300;
  cursor: default;
  color: $neutral-grey-600;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li:last-child {
  .button {
    margin-bottom: $zero !important;
  }
}
</style>
