<template>
  <div class="content-container">
    <div class="flex-row flex-align--start gap--small">
      <div v-if="computedIconName">
        <icons
          :name="computedIconName"
          :color="computedIconColor"
          :size="iconSize"
          :view="viewBox"
          :show-title="false"
        />
      </div>
      <div
        class="box-content flex-row flex-justify--between flex-align--start full-width"
      >
        <template v-if="to">
          <a
            :style="{ color: computedTextColor }"
            class="title-h6 hyper-linked-text"
            :href="to"
            rel="noopener noreferrer nofollow"
            target="_blank"
            v-text="content"
          />
          <a
            v-if="type === 'video'"
            class="open-link-icon"
            :href="to"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <icons
              name="openLink"
              color="grey"
              size="medium"
              :show-title="false"
            />
          </a>
          <a
            v-else
            class="open-link-icon"
            :href="to"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <icons
              name="openLink"
              color="grey"
              size="medium"
              :show-title="false"
            />
          </a>
        </template>
        <div v-else>
          <div
            v-if="heading"
            class="title-h5 text-dark mg-bottom--smaller"
            v-html="heading"
          />
          <div
            class="body-b2 text-light text-content"
            :style="{ color: computedTextColor }"
            v-html="content"
          />
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    heading: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '#3c492c',
    },
    iconName: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: 'darkest-grey',
    },
    iconSize: {
      type: String,
      default: 'medium',
    },
    viewBox: {
      type: String,
      default: '-2 -2 24 24',
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedTextColor() {
      if (this.type === 'document') return '#1B83AC';
      if (this.type === 'video') return '#6BAC1B';
      return this.textColor;
    },
    computedIconColor() {
      if (this.type === 'document') return 'primary';
      if (this.type === 'video') return 'green';
      return this.iconColor;
    },
    computedIconName() {
      if (this.type === 'document') return 'document';
      if (this.type === 'video') return 'videoSquircle';
      return this.iconName;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.content-container {
  background: $chat-background;
  border: 1px solid $neutral-grey-200;
  border-radius: $border-radius-smaller;
  padding: $space-six;
  max-width: 25.6rem;

  .box-content {
    gap: $space-small;

    .hyper-linked-text {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .open-link-icon {
    &:hover {
      ::v-deep .icon {
        fill: $neutral-grey-800;
        transition: 0.3s all;
      }
    }
  }
}
</style>
