<template>
  <header class="conversation-view__header">
    <div class="header--title">
      HelpDesk
    </div>
    <div class="header--menu">
      <create-ticket-button class="mg-right" :max-height="null" />
      <template v-if="currentRole === 'administrator'">
        <AppSwitcher />
        <span class="header--menu__divider" />
      </template>

      <AccountSwitcher />
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

import AppSwitcher from './AppSwitcher';
import AccountSwitcher from './AccountSwitcher';
import CreateTicketButton from '../buttons/CreateTicketButton';

export default {
  components: {
    AppSwitcher,
    AccountSwitcher,
    CreateTicketButton,
  },
  computed: {
    ...mapGetters({
      currentRole: 'getCurrentRole',
    }),
  },
};
</script>
