<template>
  <div class="marketing-banner flex-column flex-align gap--larger">
    <slot />
    <div class="content flex-column flex-justify flex-align">
      <img
        src="https://limechat-fb-uploads.s3.amazonaws.com/uploaded/7/be8328e4-e52e-40ba-9ecf-da9be4dcce1b.svg"
        width="320"
        class="mg-bottom--large"
      />
      <div class="header mg-bottom--small">
        <div
          class="title-h1 text-dark text-align"
          v-text="$t('BROADCAST.DEPRECATION.HEADER')"
        />
      </div>
      <div class="details mg-bottom--medium">
        <div
          class="subtitle-s2 information text-light text-align"
          v-html="$t('BROADCAST.DEPRECATION.DETAILS')"
        />
      </div>
      <div class="actions flex-column flex-justify gap--small mg-top">
        <woot-base-button @click="switchToMarketingBroadcast">
          {{ $t('BROADCAST.DEPRECATION.ACTIONS.SWITCH') }}
        </woot-base-button>
        <woot-base-button variant="tertiary" @click="stayInCurrentBroadcast">
          {{ $t('BROADCAST.DEPRECATION.ACTIONS.STAY') }}
        </woot-base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { NEW_BROADCAST_URL } from '../constants';

export default {
  computed: {
    ...mapGetters({ accountId: 'getCurrentAccountId' }),
  },
  methods: {
    switchToMarketingBroadcast() {
      window.location.href = `${NEW_BROADCAST_URL}?hd_account_id=${this.accountId}`;
    },
    stayInCurrentBroadcast() {
      this.$emit('on-close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.marketing-banner {
  position: relative;
  margin-top: $space-small * 10;
  height: calc(100vh - 12rem);

  .details {
    .information {
      max-width: 60rem;
    }
  }
}
</style>
