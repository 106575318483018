export const convertToTitleCase = str => {
  return str.split('_').join(' ');
};

export const removeInvisibleCharacters = str => {
  // Unicode ranges for invisible characters and control characters
  // Includes common invisible characters like zero-width space (U+200B), zero-width non-joiner (U+200C),
  // zero-width joiner (U+200D), left-to-right mark (U+200E), right-to-left mark (U+200F),
  // and many others in the control characters range.
  // eslint-disable-next-line no-control-regex
  const invisibleCharRegex = /[\u0000-\u001F\u007F-\u00A0\u00AD\u0600-\u0605\u061C\u06DD\u070F\u08E2\u1680\u180E\u2000-\u200F\u2028-\u202F\u205F-\u206F\uFEFF\uFFF9-\uFFFB]/g;

  // Remove invisible characters
  return str.replace(invisibleCharRegex, '');
};

export const isString = value => typeof value === 'string';

export const isNonEmptyString = value =>
  isString(value) && value.trim().length > 0;

export const removeTrailingSlashesAndSpaces = str => {
  // Use a regular expression to remove trailing \\\n and spaces
  return str.replace(/(\\\n\s*)+$/, '');
};
