<template>
  <div class="conversation" @click="navigateToConversation">
    <div class="conversation--details columns">
      <div class="conversation--top">
        <span class="title-h5 text-dark conversation--user flex-row">
          <span>
            <icons
              :name="computedInboxClass.name"
              :color="`${computedInboxClass.color} evenodd`"
              :show-title="false"
              size="semimedium"
            />
          </span>
          <account-pii-mask-wrapper
            :pii-data="contactName"
            :mask-type="maskType"
            unmask-action="piiMask/logUnmaskEvent"
            :unmask-payload="unmaskRequestPayload"
          >
            <template v-slot="{ data: displayData }">
              <span
                v-if="displayData"
                class="text-truncate"
                style="max-width: 15rem"
                v-text="displayData"
              />
            </template>
          </account-pii-mask-wrapper>
        </span>
        <div class="conversation--meta">
          <span class="timestamp body-b3">
            {{ dynamicTime(data.timestamp || data.created_at) }}
          </span>
        </div>
      </div>
      <div class="conversation--middle">
        <span
          class="conversation--message body-b2 text-light"
          v-html="prepareContent"
        />
        <span class="assignee--label" v-text="getChatAssignee" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import AccountPiiMaskWrapper from 'dashboard/components/widgets/piiMask/AccountPiiMaskWrapper.vue';
import { MASK_TYPES } from 'dashboard/components/widgets/piiMask/constants';

import { frontendURL, conversationUrl } from 'dashboard/helper/URLHelper.js';
import router from 'dashboard/routes/index.js';

import inboxMixin from 'dashboard/mixins/inbox';
import timeMixin from 'dashboard/mixins/time';

export default {
  components: { AccountPiiMaskWrapper },
  mixins: [inboxMixin, timeMixin],
  props: {
    searchTerm: {
      type: String,
      default: '',
    },
    identifier: {
      type: String,
      default: '',
    },
    assigneeName: {
      type: String,
      default: '',
    },
    contactName: {
      type: String,
      default: '',
    },
    contactId: {
      type: Number,
      default: null,
    },
    content: {
      type: String,
      default: '',
    },
    displayId: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
    }),
    contact() {
      return this.$store.getters['contacts/getContact'](
        this.currentChat.meta?.sender?.id
      );
    },
    maskType() {
      return MASK_TYPES.NAME;
    },
    unmaskRequestPayload() {
      const payload = {
        user_id: this.currentUser.id,
        resource_id: this.contactId,
        resource_type: 'Contact',
      };

      return payload;
    },
    inboxInfo() {
      const stateInbox = this.$store.getters['inboxes/getInbox'](
        this.data.inbox_id
      );
      return stateInbox;
    },
    computedInboxClass() {
      if (this.inboxInfo?.instagram_add_on) {
        if (this.identifier === 'instagram_comment')
          return { name: 'instagram', color: 'instagram' };
        return { name: 'messenger', color: 'instagram' };
      }
      return this.getInboxClassByType(
        this.inboxInfo?.channel_type,
        this.inboxInfo?.phone_number,
        this.identifier,
        this.inboxInfo?.instagram_add_on
      );
    },
    getChatAssignee() {
      return this.assigneeFirstName(this.assigneeName);
    },
    prepareContent() {
      return this.searchTerm.trim()
        ? this.content.replace(
            new RegExp(`(${this.searchTerm})`, 'ig'),
            '<span class="searchkey--highlight">$1</span>'
          )
        : this.content;
    },
  },
  methods: {
    assigneeFirstName(username) {
      const parts = username ? username.split(/[ -]/) : [];
      let fName = parts.length ? parts[0] : '';
      if (fName.length > 0) {
        fName = fName.replace(/\b\w/g, l => l.toUpperCase());
      }
      return fName;
    },
    navigateToConversation() {
      const path = conversationUrl({
        accountId: this.accountId,
        id: this.displayId,
      });
      this.$emit('routeNavigated');
      router.push({ path: frontendURL(path) });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.conversation {
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius-smaller;
  margin-bottom: $space-small;
  transition: background-color 0.2s ease-in;
  width: 100%;

  &:active {
    background-color: $neutral-grey-300;
  }
}
</style>
