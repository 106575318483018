<template>
  <woot-base-button
    variant="primary"
    :loading="uiFlags.fetchingPartnerId"
    @click="openPopup"
  >
    {{ label }}
  </woot-base-button>
</template>
<script>
import { open360DialogPopup } from '../../helpers/360Dialog/openPopup';

import threeSixtyDialogMixin from 'dashboard/mixins/threeSixtyDialog';
import { mapGetters } from 'vuex';

const THREE_SIXTY_APP_NAME = 'connect-360dialog';

export default {
  mixins: [threeSixtyDialogMixin],
  props: {
    label: {
      type: String,
      default: 'Connect 360dialog',
    },
    env: {
      type: String,
      default: 'prod',
    },
    callback: {
      type: Function,
      default: () => {},
    },
    queryParameters: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      permissionUrl: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'threeSixtyDialog/getUIFlags',
    }),
  },
  mounted() {
    // use this to set global redirect url in 360 dialog partner hub
    // this.setGlobalRedirectUrl('https://app.limechat.ai')

    const params = this.getParamsFromUrl();
    const client = this.getClientId(params);
    const channels = this.getChannelIds(params);

    this.sendParamsToParentWindow(params);

    if (window.name !== THREE_SIXTY_APP_NAME && client && channels) {
      const channelsArray = channels
        .substring(1, channels.length - 1)
        .split(',');

      let callbackObj = {
        client: client,
        channels: channelsArray,
      };

      this.callback(callbackObj);

      // remove search parameters from URL after fetching them
      window.history.replaceState(null, '', window.location.pathname);
    }
  },
  methods: {
    buildPermissionUrl(partnerId) {
      const { env } = this;

      const baseUrl =
        env === 'prod'
          ? 'https://hub.360dialog.com'
          : 'https://admin.hub-staging.360dialog.io';

      this.permissionUrl = `${baseUrl}/dashboard/app/${partnerId}/permissions`;

      if (this.queryParameters) {
        let values = Object.values(this.queryParameters);
        Object.keys(this.queryParameters).forEach((k, idx) => {
          const symbol = idx === 0 ? '?' : '&';
          this.permissionUrl += `${symbol}${k}=${values[idx]}`;
        });
      }
    },
    openPopup() {
      this.$store
        .dispatch('threeSixtyDialog/fetchPartnerId')
        .then(partnerId => {
          this.buildPermissionUrl(partnerId);

          open360DialogPopup({
            url: this.permissionUrl,
            name: THREE_SIXTY_APP_NAME,
            baseUrl: window.location.origin,
          });
        });
    },
  },
};
</script>
