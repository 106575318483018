<template>
  <woot-modal
    :show.sync="opened"
    :custom-style="{ width: '60rem' }"
    :on-close="() => $emit('onClose')"
  >
    <woot-modal-header
      :header-title="$t('CUSTOM_TICKET_FIELDS.CREATE.DETAILS_MODAL.TITLE')"
    />
    <form
      class="modal-body"
      @submit.prevent="
        $emit('onSubmit', { name: newName, description: newDesc })
      "
    >
      <woot-input
        size="small"
        :value="newName"
        :label="$t('CUSTOM_TICKET_FIELDS.ADD.FORM.NAME.LABEL')"
        type="text"
        auto-focus
        required
        :has-error="$v.newName.$error"
        class="mg-bottom"
        :class="{ error: $v.newName.$error }"
        :error="nameError"
        :placeholder="$t('CUSTOM_TICKET_FIELDS.ADD.FORM.NAME.PLACEHOLDER')"
        @input="newName = $event"
        @blur="$v.newName.$touch"
      />
      <woot-input
        size="small"
        :value="newDesc"
        :label="$t('CUSTOM_TICKET_FIELDS.ADD.FORM.DESC.LABEL')"
        tag="textarea"
        class="content-margin"
        rows="3"
        full-width
        :placeholder="$t('CUSTOM_TICKET_FIELDS.ADD.FORM.DESC.PLACEHOLDER')"
        @input="newDesc = $event"
      />
      <div class="modal-body--footer flex-row flex-justify--end">
        <woot-base-button size="small" type="submit" :disabled="$v.$invalid">
          Continue
        </woot-base-button>
      </div>
    </form>
  </woot-modal>
</template>
<script>
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  validations: {
    newName: {
      required,
    },
  },
  data() {
    return {
      newName: this.name,
      newDesc: this.description,
    };
  },
  computed: {
    nameError() {
      return this.$v.newName.$error
        ? this.$t('CUSTOM_TICKET_FIELDS.ADD.FORM.NAME.ERROR')
        : '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.modal-body {
  padding: $space-normal $space-medium;
}
</style>
