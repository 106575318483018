<template>
  <transition name="toast-fade" appear>
    <div class="views-manager-wrapper">
      <div v-if="!uiFlags.isFetching" class="views-manager">
        <!-- Current View Section -->
        <Section
          :title="$t('CONVERSATION.CONVERSATION_VIEWS.SECTIONS.CURRENT')"
          :views="[currentView]"
          :is-active="() => true"
          :is-default="isDefaultView"
          :controls="CONTROLS.current"
          @onMakeDefault="onMakeDefault"
        />

        <!-- Default View Section -->
        <Section
          :title="$t('CONVERSATION.CONVERSATION_VIEWS.SECTIONS.DEFAULT')"
          :views="[defaultView]"
          :is-default="() => true"
          @onMakeCurrent="onMakeCurrent"
        />

        <!-- Curated Views Section -->
        <Section
          :title="$t('CONVERSATION.CONVERSATION_VIEWS.CUSTOM_VIEW_INFO')"
          :accordion-title="
            $t('CONVERSATION.CONVERSATION_VIEWS.SECTIONS.CURATED')
          "
          :views="curatedViews"
          :is-default="isDefaultView"
          :is-active="isCurrentView"
          :controls="CONTROLS.curated"
          has-accordion
          is-accordion-open
          @onEdit="onClone"
          @onMakeCurrent="onMakeCurrent"
          @onMakeDefault="onMakeDefault"
        />

        <!-- Custom Views Section -->
        <Section
          :accordion-title="
            $t('CONVERSATION.CONVERSATION_VIEWS.SECTIONS.CUSTOM')
          "
          :views="customViews"
          :is-default="isDefaultView"
          :is-active="isCurrentView"
          has-accordion
          :is-accordion-open="isCustomViewsAccordionOpen"
          :controls="CONTROLS.custom"
          @onEdit="onEditView"
          @onDelete="onDeleteView"
          @onMakeCurrent="onMakeCurrent"
          @onMakeDefault="onMakeDefault"
        />

        <!-- Create Custom View Button -->
        <div class="create-custom-view-container">
          <woot-base-button
            variant="secondary"
            class="create-custom-view-button"
            full-width
            preserve-case
            @click="createCustomView"
          >
            <div class="flex-row flex-align full-width gap--slab">
              <icons name="circleAdd" color="stroke-green" size="medium" />
              {{ $t('CONVERSATION.CONVERSATION_VIEWS.ADD.TITLE') }}
            </div>
          </woot-base-button>
        </div>
      </div>

      <loading-state
        v-else
        message="Please wait..."
        :style="'maxHeight: 20rem'"
      />

      <update-view-modal
        v-if="showUpdateModal"
        :opened="showUpdateModal"
        :data="selectedView"
        :is-default-view="isDefaultView(selectedView)"
        @onClose="onCloseUpdateModal"
        @onSubmit="onSubmit"
      />

      <woot-delete-modal-new
        :show.sync="showDeleteModal"
        :on-close="onCloseDeleteModal"
        :on-confirm="onConfirmDelete"
        show-close
        :title="$t('CONVERSATION.CONVERSATION_VIEWS.DELETE.TITLE')"
        :message="$t('CONVERSATION.CONVERSATION_VIEWS.DELETE.DESC')"
        :confirm-text="$t('CONVERSATION.CONVERSATION_VIEWS.DELETE.DELETE')"
        :reject-text="$t('CONVERSATION.CONVERSATION_VIEWS.DELETE.CANCEL')"
        :custom-style="{ 'max-width': '35.2rem', height: 'auto' }"
      />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

import Section from './components/section';
import UpdateViewModal from '../components/UpdateViewModal';
import LoadingState from 'dashboard/components/ui/LoadingState';

import clickOutsideMixin from 'dashboard/mixins/clickOutsideMixin';
import alertMixin from 'shared/mixins/alertMixin';

import { VIEW_TYPE, CONTROLS } from '../constants';

export default {
  components: {
    Section,
    UpdateViewModal,
    LoadingState,
  },
  mixins: [clickOutsideMixin, alertMixin],
  data() {
    return {
      showUpdateModal: false,
      showDeleteModal: false,
      selectedView: undefined,
      isCustomViewsAccordionOpen: false,
      VIEW_TYPE,
      CONTROLS,
    };
  },
  computed: {
    ...mapGetters({
      currentView: 'conversationViews/getCurrentView',
      defaultView: 'conversationViews/getDefaultView',
      curatedViews: 'conversationViews/getCuratedViews',
      customViews: 'conversationViews/getCustomViews',
      uiFlags: 'conversationViews/getUiFlags',
    }),
  },
  mounted() {
    this.addClickOutsideListener();
  },
  methods: {
    isDefaultView(view) {
      if (!view) return false;
      return (
        view.id === this.defaultView?.id &&
        view.view_type === this.defaultView?.view_type
      );
    },
    isCurrentView(view) {
      return (
        view.id === this.currentView?.id &&
        view.view_type === this.currentView?.view_type
      );
    },
    createCustomView() {
      this.showUpdateModal = true;
    },
    onEditView(view) {
      this.selectedView = view;
      this.showUpdateModal = true;
    },
    onClone({ id, name, ...rest }) {
      this.selectedView = { name: name + ' clone', ...rest };
      this.showUpdateModal = true;
    },
    onDeleteView(view) {
      this.selectedView = view;
      this.showDeleteModal = true;
    },
    onMakeCurrent(view) {
      this.$store.dispatch('conversationViews/setCurrentView', view);
      this.$emit('onChange');
      this.hideDropdown();
    },
    onMakeDefault(view) {
      if (!this.currentView) this.onMakeCurrent(view);

      this.$store
        .dispatch('conversationViews/markConversationViewDefault', view)
        .catch(() => {
          this.showAlert(
            this.$t('CONVERSATION.CONVERSATION_VIEW.API.MARK_DEFAULT.ERROR'),
            'error'
          );
        });
    },
    onSubmit(payload) {
      const actionName = payload.id ? 'editCustomView' : 'createCustomView';
      const action = payload.id ? 'EDIT' : 'CREATE';
      const { isDefault, ...rest } = payload;

      this.$store
        .dispatch(`conversationViews/${actionName}`, rest)
        .then(response => {
          this.handleEditActions(rest, action);
          this.handleDefaultView(isDefault, response);
          this.finalizeSubmission(action);
        })
        .catch(() => {
          this.handleSubmissionError(action);
        });
    },
    handleEditActions(rest, action) {
      if (action === 'EDIT') {
        if (this.isDefaultView(rest)) {
          this.$store.dispatch('conversationViews/setDefaultView', rest);
        }
        if (this.isCurrentView(rest)) {
          this.$store.dispatch('conversationViews/setCurrentView', rest);
        }
      }
    },
    handleDefaultView(isDefault, response) {
      if (isDefault) {
        this.$store.dispatch(
          'conversationViews/markConversationViewDefault',
          response
        );
      }
    },
    finalizeSubmission(action) {
      this.onCloseUpdateModal();
      this.openCustomAccordion();
      this.showAlert(
        this.$t(`CONVERSATION.CONVERSATION_VIEWS.API.${action}.SUCCESS`),
        'success'
      );
    },
    handleSubmissionError(action) {
      this.showAlert(
        this.$t(`CONVERSATION.CONVERSATION_VIEWS.API.${action}.ERROR`),
        'error'
      );
    },
    onCloseUpdateModal() {
      this.resetSelectedView();
      this.showUpdateModal = false;
    },
    onCloseDeleteModal() {
      this.resetSelectedView();
      this.showDeleteModal = false;
    },
    onConfirmDelete() {
      this.$store
        .dispatch('conversationViews/deleteView', this.selectedView.id)
        .then(() => {
          this.showAlert(
            this.$t('CONVERSATION.CONVERSATION_VIEWS.API.DELETE.SUCCESS'),
            'success'
          );
          this.showDeleteModal = false;
          this.resetSelectedView();
        })
        .catch(() => {
          this.showAlert(
            this.$t('CONVERSATION.CONVERSATION_VIEWS.API.DELETE.ERROR'),
            'error'
          );
        });
    },
    openCustomAccordion() {
      this.isCustomViewsAccordionOpen = true;
    },
    resetSelectedView() {
      this.selectedView = undefined;
    },
    hideDropdown() {
      this.$emit('close');
    },
  },
};
</script>
