<template>
  <woot-multi-selector
    v-if="allowMultiSelect"
    variant="secondary"
    :size="size"
    :default-option="value"
    :option-list="inboxesList"
    :collapse-selected="collapseSelected"
    :custom-style="customStyle"
    :show-selected-text="showSelectedText"
    :toggle-filter="toggleFilter"
  />
  <woot-single-selector
    v-else
    variant="secondary"
    :size="size"
    :front-icon="inboxFilterIcon ? inboxFilterIcon : 'inbox'"
    :default-option="value"
    :option-list="inboxesList"
    :custom-style="customStyle"
    :show-selected-text="showSelectedText"
    @click="propagateClick"
  />
</template>

<script>
import { mapGetters } from 'vuex';

import inboxMixin from 'dashboard/mixins/inbox';

export default {
  mixins: [inboxMixin],
  props: {
    allowMultiSelect: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    value: {
      type: [String, Object],
      default: '',
    },
    collapseSelected: {
      type: Boolean,
      default: true,
    },
    customStyle: {
      type: Object,
      default: () => ({
        left: 0,
        width: 'auto',
      }),
    },
    showSelectedText: {
      type: Boolean,
      default: false,
    },
    optionList: {
      type: Array,
      default: () => [],
    },
    toggleFilter: {
      type: Function,
      default: () => {},
    },
    excludeInboxTypes: {
      type: Array,
      default: () => [],
    },
    defaultOption: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
    }),
    inboxesList() {
      let filteredInboxes = this.optionList.length
        ? this.filterInboxes(this.optionList)
        : this.filterInboxes(this.inboxes);
      return [
        ...(this.defaultOption ? [this.defaultOption] : []),
        ...filteredInboxes.map(inbox => {
          let { name: icon, color } = this.computedInboxClass(inbox);

          return {
            id: inbox.id,
            name: inbox.name,
            status: inbox.status,
            type: inbox.channel_type,
            channel_id: inbox.channel_id,
            phoneNumber: inbox.phone_number,
            instagram_add_on: inbox.instagram_add_on,
            selected_agent: null,
            icon: icon,
            color: color,
          };
        }),
      ];
    },
    inboxFilterIcon() {
      let inboxIcon = this.inboxes.filter(inbox => this.value.id === inbox.id);
      return this.computedInboxClass(inboxIcon[0]).name;
    },
  },
  methods: {
    computedInboxClass(inbox) {
      if (inbox && inbox?.channel_type) {
        const { channel_type, phone_number, instagram_add_on } = inbox;
        const classByType = this.getInboxClassByType(
          channel_type,
          phone_number,
          '',
          instagram_add_on
        );

        return classByType;
      }

      return '';
    },
    filterInboxes(unfilteredInboxList) {
      return unfilteredInboxList.filter(
        inbox => !this.excludeInboxTypes.includes(inbox.channel_type)
      );
    },
    propagateClick(inbox, event) {
      this.$emit('click', inbox, event);
    },
  },
};
</script>
