<template>
  <div>
    <div class="settings full-height custom-scroll">
      <div
        class="settings-title title-h3 text-dark"
        v-text="'Define Heirarchy'"
      />
      <label class="formlabel">
        <span
          class="title-h5 text-dark"
          v-text="$t('LABEL_MGMT.FORM.GROUP.LABEL')"
        />
      </label>
      <woot-single-selector
        size="large"
        default-key="title"
        :option-list="parentLabels"
        :custom-style="{
          width: '100%',
          left: 0,
          boxShadow: 'none',
          maxHeight: '18rem',
        }"
        :default-option="selectedGroup"
        @click="onChangeGroup"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import { TYPES } from 'dashboard/routes/dashboard/settings/labels/constants';

export default {
  props: {
    labelId: {
      type: Number,
      default: null,
    },
    parentId: {
      type: Number,
      default: null,
    },
    resource: {
      type: String,
      default: TYPES.conversation,
    },
  },
  data() {
    return {
      parentIdVal: null,
      selectedGroup: 'None',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'labels/getUIFlags',
    }),
    flatLabels() {
      return this.$store.getters['labels/getLabelsWithoutGroupsByResource'](
        this.resource
      );
    },
    parentLabels() {
      return [
        { id: 0, title: 'None' },
        ...this.flatLabels.filter(
          label =>
            label.label_level !== 'label_child' &&
            label.label_type === 'client' &&
            label.id !== this.labelId
        ),
      ];
    },
  },
  created() {
    this.parentIdVal = this.parentId;
    this.$emit('labelHeirarchy', { parent_id: this.parentId || null });
    this.setSelectedGroup();
  },
  methods: {
    setSelectedGroup() {
      this.parentLabels.forEach(label => {
        if (label.id === this.parentIdVal) {
          this.selectedGroup = label.title;
        }
      });
    },
    onChangeGroup(val) {
      this.parentIdVal = val.id;
      this.selectedGroup = val.title;
      this.$emit('labelHeirarchy', { parent_id: val.id || null });
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '~dashboard/assets/scss/variables';

.heirarchy-settings-container {
  padding: $space-two $space-jumbo;
  height: calc(100vh - 46rem);

  .settings {
    .settings-title {
      display: block;
      margin-bottom: $space-two;
    }
  }
}

.settings {
  padding: $zero $space-two;
  overflow: overlay;

  .settings-title {
    display: none;
  }
}
</style>
