<template>
  <section>
    <section-header translation-key="ESCALATIONS" />
    <aside class="sla-escalations">
      <div
        v-for="metricKey in escalations"
        :key="metricKey"
        class="filter filter--with-border"
        :class="{ 'mg-bottom--medium': isNotLastMetric(metricKey) }"
      >
        <div class="flex-row flex-align gap--slab full-width">
          <span>When SLA of </span>
          <metric-selector
            :class="'sla-escalations__metric_selector__' + metricKey"
            :value="metricKey"
            :excluded-options="escalations"
            @click="onSelectMetric($event, metricKey)"
          />
          <span v-if="metricKey !== 'select'">is breached</span>
          <woot-base-button
            tag="span"
            variant="tertiary-danger"
            @click="deleteCondition(metricKey)"
          >
            <icons
              name="bin"
              color="red"
              size="semimedium"
              :show-title="false"
            />
          </woot-base-button>
        </div>
        <div
          v-if="metricKey !== 'select'"
          class="flex-column flex-align--start gap--slab"
        >
          <escalation-action
            v-for="(action, actionIndex) in data.escalations[metricKey].action"
            :key="actionIndex"
            :class="'escalation-action__' + metricKey + '__' + actionIndex"
            :action="action"
            :metric-key="metricKey"
            :action-index="actionIndex"
            :dropdown-values="getDropdownValues(assigneeTriggerKey)"
            :escalation-level="escalationLevel(actionIndex)"
            :selected-values="getValues(metricKey, actionIndex)"
            @update="handleActionUpdate"
            @delete="deleteAction"
          />
          <add-filter-button
            v-if="canAddAction(metricKey)"
            class="mg-top--smaller"
            :label="$t('SLA.CREATE.SECTIONS.ESCALATIONS.ACTIONS.ADD_FILTER')"
            @click="onAddAction(metricKey)"
          />
        </div>
      </div>
      <!-- add filter button -->
      <div v-if="canAddMetricFilter" class="mg-top">
        <add-filter-button
          :label="$t('SLA.CREATE.SECTIONS.ESCALATIONS.ADD_FILTER')"
          @click="onAddFilter"
        />
      </div>
    </aside>
  </section>
</template>

<script>
import MetricSelector from './components/selectors/MetricSelector.vue';
import SectionHeader from './components/shared/Header';
import AddFilterButton from './components/shared/AddFilterButton';
import EscalationAction from './components/EscalationAction.vue';

import { NOTIFICATION_TYPES, TRIGGERS } from '../../../utils/constants';
import { onAddKey, onSelectKey } from '../../../utils/form';

import formMixin from '../../../mixins/formMixin';

export default {
  components: {
    SectionHeader,
    MetricSelector,
    AddFilterButton,
    EscalationAction,
  },
  mixins: [formMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    escalations() {
      return this.data?.escalations ? Object.keys(this.data?.escalations) : [];
    },
    assigneeTriggerKey() {
      return TRIGGERS.ASSIGNEE;
    },
    canAddMetricFilter() {
      return this.escalations.length < 3;
    },
  },
  methods: {
    isNotLastMetric(metricKey) {
      return (
        this.escalations.indexOf(metricKey) !== this.escalations.length - 1
      );
    },
    escalationLevel(actionIndex) {
      const levels = ['1st', '2nd', '3rd'];
      return levels[actionIndex] || `${actionIndex + 1}th`;
    },
    getValues(metricKey, actionIndex) {
      return this.getDropdownValues(this.assigneeTriggerKey).filter(item =>
        this.data.escalations[metricKey].action[actionIndex].data.includes(
          item.id
        )
      );
    },
    onAddFilter() {
      const newEscalation = onAddKey(this.data, 'escalations');
      if (newEscalation) this.$emit('update', newEscalation);
    },
    onSelectMetric({ value: selectedMetric }, metricKey) {
      const newPayload = onSelectKey(
        this.data,
        selectedMetric,
        metricKey,
        'escalations'
      );
      if (newPayload) this.$emit('update', newPayload);
    },
    handleActionUpdate({ metricKey, actionIndex, update }) {
      const newPayload = this.updateAction(metricKey, actionIndex, update);
      this.$emit('update', newPayload);
    },
    updateAction(metricKey, actionIndex, update) {
      const actions = [...this.data.escalations[metricKey].action];

      if (actionIndex < actions.length) {
        // Update existing action
        actions[actionIndex] = { ...actions[actionIndex], ...update };
      } else {
        // Add new action
        actions.push({ ...update });
      }

      return {
        ...this.data,
        escalations: {
          ...this.data.escalations,
          [metricKey]: {
            ...this.data.escalations[metricKey],
            action: actions,
          },
        },
      };
    },
    canAddAction(metricKey) {
      return this.data.escalations[metricKey].action.length < 3;
    },
    onAddAction(metricKey) {
      const newAction = {
        type: NOTIFICATION_TYPES.SEND_IN_APP_AND_EMAIL_NOTIFICATION,
        after: 0,
        data: [],
      };
      const newPayload = this.updateAction(
        metricKey,
        this.data.escalations[metricKey].action.length,
        newAction
      );

      this.$emit('update', newPayload);
    },
    deleteAction({ metricKey, actionIndex }) {
      const updatedActions = this.data.escalations[metricKey].action.filter(
        (_, index) => index !== actionIndex
      );
      const newPayload = {
        ...this.data,
        escalations: {
          ...this.data.escalations,
          [metricKey]: {
            ...this.data.escalations[metricKey],
            action: updatedActions,
          },
        },
      };
      this.$emit('update', newPayload);
    },
    deleteCondition(metricKey) {
      const updatedConditions = { ...this.data.escalations };
      delete updatedConditions[metricKey];
      this.$emit('update', {
        ...this.data,
        escalations: updatedConditions,
      });
    },
  },
};
</script>
