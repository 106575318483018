<template>
  <loading-state
    v-if="uiFlags.isFetching || !customField"
    style="height: 100%"
  />
  <custom-field-form
    v-else
    edit-view
    :custom-field="customField"
    :initial-custom-field-inboxes="customFieldInboxes"
    @submitFieldForm="updateCustomField"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

import CustomFieldForm from '../components/form';
import LoadingState from 'dashboard/components/ui/LoadingState';

import alertMixin from 'shared/mixins/alertMixin';
import { reOrder, isDropdown } from '../utils/helper';
import mixpanelEvent from 'dashboard/helper/mixpanel';

export default {
  components: {
    CustomFieldForm,
    LoadingState,
  },
  mixins: [alertMixin],
  data() {
    return {
      customField: null,
      customFieldCopy: null,
      changed: false,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'customTicketFields/getUIFlags' }),
    customFieldInboxes() {
      return this.customField.inboxes;
    },
    customFieldType() {
      return this.customField.field_type;
    },
    isDropdownField() {
      return isDropdown(this.customFieldType);
    },
  },
  created() {
    this.getField();
  },
  methods: {
    initializeCustomField(data) {
      if (data.dropdown_options) {
        this.customField = {
          options: data.dropdown_options,
          ...data,
        };
      } else {
        this.customField = { ...data };
      }

      this.customFieldCopy = JSON.parse(JSON.stringify(this.customField));
    },
    async getField() {
      await this.$store
        .dispatch('customTicketFields/getFieldWithId', {
          id: this.$route.params.custom_field_id,
        })
        .then(data => {
          this.initializeCustomField(data);
        });
    },
    async updateOptions(fieldId, newDropdownOptions) {
      const newDropdownOptionIds = newDropdownOptions.map(({ id }) => id);

      const optionsToAdd = newDropdownOptions.filter(
        option => option.id === null
      );

      const optionsToDelete = this.customFieldCopy.dropdown_options.filter(
        option => !newDropdownOptionIds.includes(option.id)
      );

      if (optionsToAdd.length || optionsToDelete.length) {
        this.changed = true;

        await axios.all([
          ...optionsToAdd.map(option =>
            this.$store.dispatch('customTicketFields/addOption', {
              id: fieldId,
              option: option.value,
            })
          ),
          ...optionsToDelete.map(option =>
            this.$store.dispatch('customTicketFields/removeOption', {
              id: fieldId,
              optionId: option.id,
            })
          ),
        ]);
      }
    },
    async updateInboxes(fieldId, newInboxes) {
      const inboxIds = this.customFieldInboxes.map(({ id }) => id);

      const inboxesToDelete = inboxIds.filter(
        inbox => !newInboxes.includes(inbox)
      );

      const inboxesToAdd = newInboxes.filter(
        inboxId => !inboxIds.includes(inboxId)
      );

      if (inboxesToDelete.length) {
        this.changed = true;
        await this.$store.dispatch('customTicketFields/removeInboxes', {
          id: fieldId,
          inboxIds: inboxesToDelete,
        });
      }

      if (inboxesToAdd.length) {
        this.changed = true;
        await this.$store.dispatch('customTicketFields/addInboxes', {
          id: fieldId,
          inboxIds: inboxesToAdd,
        });
      }
    },
    async updateCustomField(initialPayload) {
      mixpanelEvent('clicked_save_field');
      try {
        let { inbox_ids: newInboxes, ...payload } = initialPayload;

        const fieldId = this.$route.params.custom_field_id;

        if (this.isDropdownField) {
          payload.options = reOrder(payload.options);

          await this.updateOptions(fieldId, payload.options);
        }

        await this.updateInboxes(fieldId, newInboxes);

        if (
          JSON.stringify(payload) === JSON.stringify(this.customFieldCopy) &&
          !this.changed
        ) {
          this.showAlert(
            this.$t('CUSTOM_TICKET_FIELDS.NO_CHANGES_MESSAGE'),
            'info'
          );

          return;
        }

        const newFieldData = await this.$store.dispatch(
          'customTicketFields/edit',
          {
            id: fieldId,
            ...payload,
          }
        );

        this.initializeCustomField(newFieldData);

        this.showAlert(
          this.$t('CUSTOM_TICKET_FIELDS.SUCCESS_MESSAGE'),
          'success'
        );
        this.changed = false;
      } catch (error) {
        this.showAlert(
          this.$t('CUSTOM_TICKET_FIELDS.ERRORS.ERROR_MESSAGE'),
          'error'
        );
      }
    },
  },
};
</script>
