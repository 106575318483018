<template>
  <button
    class="filter-button"
    type="button"
    :class="{ 'filter-button--active': active }"
    :name="name"
    :icon="icon"
    @click="$emit('click')"
  >
    <span style="margin-bottom: 0.2rem">
      <icons
        v-if="icon"
        :name="icon"
        :color="active ? 'green' : 'stroke-grey'"
      />
    </span>
    <span class="filter-text" :class="{ 'filter-text--active': active }">{{
      name
    }}</span>
  </button>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    infoOnly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.filter-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: $space-smaller 0.6rem;
  border: 1px solid $neutral-grey-500;
  border-radius: $border-radius;
  margin-right: $space-slab;
  cursor: pointer;
  white-space: nowrap;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &--active {
    border: 1px solid $pg-1-500;

    .filter--text {
      color: $pg-1-500;
    }
  }

  .filter-text {
    color: $neutral-grey-700;
    font-size: $font-size-small;
    line-height: 22px;
    padding: $zero $space-micro;

    &--active {
      color: $pg-1-500;
    }
  }
}
</style>
