<template>
  <div class="personal-details-container">
    <form class="settings-form custom-scroll">
      <div
        class="flex-row medium-12 flex-justify--between content-margin gap--small"
      >
        <!-- name -->
        <woot-input
          v-model.trim="agentNameVal"
          class="half-width"
          size="small"
          :class="{ error: $v.agentNameVal.$error }"
          :has-error="$v.agentNameVal.$error"
          :error="
            agentNameVal === ''
              ? $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_NAME.ERROR')
              : $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_NAME.ERROR')
          "
          :label="$t('AGENT_MGMT.ADD.FORM.AGENT_NAME.LABEL')"
          :placeholder="$t('AGENT_MGMT.ADD.FORM.AGENT_NAME.PLACEHOLDER')"
          auto-focus
          @blur="$v.agentNameVal.$touch"
          @input="$emit('update:agentName', agentNameVal)"
        />
        <!-- role -->
        <div class="half-width" :class="{ disabled: shouldDisableFeature }">
          <label class="formlabel flex-row flex-justify--between gap-4">
            <span
              class="title-h5 text-dark"
              v-text="$t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.LABEL')"
            />
          </label>
          <woot-single-selector
            size="large"
            :option-list="roles"
            :default-option="getAgentTypeLabel"
            :custom-style="{ top: '4.8rem', left: 'auto', width: '100%' }"
            :dropdown-container-style="{ height: '3.6rem' }"
            @click="changeAgentType"
          />
        </div>
      </div>
      <div
        class="flex-row medium-12 flex-justify--between content-margin gap--small"
      >
        <!-- email -->
        <woot-input
          v-model.trim="agentEmailVal"
          size="small"
          :class="{ error: $v.agentEmailVal.$error }"
          :has-error="$v.agentEmailVal.$error"
          :error="
            agentEmailVal === ''
              ? $t('INBOX_MGMT.COMMON_ERROR_MESSAGE')
              : $t('AGENT_MGMT.ADD.FORM.EMAIL.ERROR_MESSAGE')
          "
          :label="$t('AGENT_MGMT.ADD.FORM.EMAIL.LABEL')"
          :placeholder="$t('AGENT_MGMT.ADD.FORM.EMAIL.PLACEHOLDER')"
          :disabled="shouldDisableFeature"
          full-width
          @blur="$v.agentEmailVal.$touch"
          @input="$emit('update:agentEmail', agentEmailVal)"
        />

        <!-- phone -->
        <woot-input
          v-model.trim="phoneNumberVal"
          size="small"
          type="Number"
          :class="{ error: $v.phoneNumberVal.$error }"
          :has-error="$v.phoneNumberVal.$error"
          :error="
            phoneNumberVal === ''
              ? $t('INBOX_MGMT.COMMON_ERROR_MESSAGE')
              : $t('AGENT_MGMT.ADD.FORM.PHONE_NUMBER.ERROR')
          "
          :label="$t('AGENT_MGMT.ADD.FORM.PHONE_NUMBER.LABEL')"
          :placeholder="$t('AGENT_MGMT.ADD.FORM.PHONE_NUMBER.PLACEHOLDER')"
          full-width
          @blur="$v.phoneNumberVal.$touch"
          @input="$emit('update:phoneNumber', phoneNumberVal)"
        />
      </div>
      <!-- add agent to inbox -->
      <div class="medium-12 content-margin">
        <div class="flex-row flex-align">
          <label class="formlabel" :class="{ error: $v.inboxIdsVal.$error }">
            <span
              class="title-h5 text-dark"
              v-text="$t('AGENT_MGMT.EDIT.FORM.INBOX.LABEL')"
            />
          </label>
          <span
            v-tooltip="'Select Inbox for Agent'"
            class="mg-bottom--smaller mg-left--small"
          >
            <icons
              name="warning"
              color="grey evenodd"
              size="semimedium"
              :show-title="false"
            />
          </span>
        </div>
        <woot-single-selector
          size="large"
          :option-list="inactiveInbox"
          default-option="Select Inbox for Agent"
          :custom-style="{
            top: '4.8rem',
            left: 'auto',
            width: '100%',
            maxHeight: '16rem',
          }"
          :dropdown-container-style="{ height: '3.6rem' }"
          @click="addInbox"
        />
        <div
          v-if="inboxIdsVal.length"
          class="medium-12 agent-container custom-scroll"
        >
          <woot-chips
            v-for="(inbox, index) in inboxIdsVal"
            :key="inbox.id"
            :title="inbox.name"
            variant="secondary-small"
            show-cancel-icon
            :show-tooltip="false"
            :custom-style="{
              'border-color': getInboxMeta(inbox).hex,
              color: getInboxMeta(inbox).hex,
            }"
            :front-icon="getInboxMeta(inbox).name"
            :front-icon-color="getInboxMeta(inbox).color"
            @click="onRemoveInbox(index)"
          />
        </div>
      </div>
      <div v-if="!!agentId" class="medium-12 content-margin">
        <label class="formlabel">
          <span
            class="title-h5_m text-light"
            v-text="$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_RESET_TEXT')"
          />
        </label>
        <woot-base-button
          size="small"
          variant="secondary"
          @click.prevent="resetPassword"
        >
          <i class="ion-locked" />
          {{ $t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_RESET_BUTTON') }}
        </woot-base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { required, minLength, email } from 'vuelidate/lib/validators';
import { validName, checkValidPhoneNumber } from '../validations';

import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from 'dashboard/mixins/inbox';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

import * as types from 'shared/constants/googleEventType';

export default {
  mixins: [alertMixin, inboxMixin, googleAnalyticsMixin],
  props: {
    agentId: { type: [Number, String], default: '' },
    agentName: { type: String, default: '' },
    phoneNumber: { type: String, default: '' },
    agentEmail: { type: String, default: '' },
    agentType: { type: String, default: 'agent' },
    inboxIds: { type: Array, default: () => [] },
  },
  data() {
    return {
      agentNameVal: this.agentName,
      phoneNumberVal: this.phoneNumber,
      agentEmailVal: this.agentEmail,
      agentTypeVal: this.agentType,
      inboxIdsVal: [...this.inboxIds],
      agentCredentials: {
        email: this.agentEmail,
      },
      roles: [
        {
          id: 'administrator',
          name: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
        },
        {
          id: 'agent',
          name: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
        },
        {
          id: 'supervisor',
          name: this.$t('AGENT_MGMT.AGENT_TYPES.SUPERVISOR'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
      currentUserId: 'getCurrentUserID',
    }),
    shouldDisableFeature() {
      if (!this.agentId) return false;
      return this.currentUserId === this.agentId;
    },
    getAgentTypeLabel() {
      if (this.agentTypeVal === 'administrator') {
        return this.roles[0].name;
      }
      if (this.agentTypeVal === 'agent') {
        return this.roles[1].name;
      }
      if (this.agentTypeVal === 'supervisor') {
        return this.roles[2].name;
      }

      return '';
    },
    phoneValidationRequired() {
      return !!this.inboxIdsVal.find(
        inbox => inbox.channel_type === INBOX_TYPES.KNOWLARITY
      );
    },
    inactiveInbox() {
      let selectedIds = this.inboxIdsVal.map(item => {
        return item.id;
      });

      return this.inboxes.filter(item => !selectedIds.includes(item.id));
    },
    shouldDisableBtn() {
      const validity =
        this.$v.phoneNumberVal.$invalid ||
        this.$v.agentEmailVal.$invalid ||
        this.$v.agentNameVal.$invalid;

      this.$emit('formValidity', validity);
      return validity;
    },
  },
  watch: {
    shouldDisableBtn(val) {
      this.$emit('formValidity', val);
    },
  },
  // eslint-disable-next-line func-names
  validations: function() {
    let commonValidations = {
      agentNameVal: {
        required,
        validName,
        minLength: minLength(4),
      },
      agentEmailVal: {
        required,
        email,
      },
      inboxIdsVal: {
        required,
      },
      agentTypeVal: {
        required,
      },
      phoneNumberVal: { checkValidPhoneNumber },
    };

    if (this.phoneValidationRequired) {
      commonValidations.phoneNumberVal = {
        ...commonValidations.phoneNumberVal,
        required,
      };
    }

    return commonValidations;
  },
  mounted() {
    this.$emit('formValidity', this.shouldDisableBtn);
  },
  methods: {
    getInboxMeta(inbox) {
      return this.getInboxClassByType(
        inbox?.channel_type,
        inbox?.phone_number,
        '',
        inbox?.instagram_add_on
      );
    },
    changeAgentType(val) {
      this.agentTypeVal = val.id;
      this.$emit('update:agentType', val.id);
    },
    addInbox(val) {
      this.$nextTick(() => {
        if (this.phoneValidationRequired) {
          this.$v.phoneNumberVal.$touch();
        }
      });

      this.inboxIdsVal.push(val);
      this.$emit('update:inboxIds', this.inboxIdsVal);
    },
    onRemoveInbox(index) {
      this.inboxIdsVal.splice(index, 1);
      this.$emit('update:inboxIds', this.inboxIdsVal);
    },
    async resetPassword() {
      try {
        this.googleAnalyticsEvent(
          types.default.AGENT_RESET_PASSWORD,
          types.default.AGENT_SETTINGS,
          types.default.AGENT_SETTINGS
        );

        await this.$store.dispatch('requestPasswordChange', {
          email: this.agentEmailVal,
        });

        this.showAlert(
          this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_SUCCESS_MESSAGE'),
          'success'
        );
      } catch (error) {
        this.showAlert(
          this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ERROR_MESSAGE'),
          'error'
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.personal-details-container {
  padding: $space-two $space-two;

  .disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.settings-form {
  padding: $zero $space-two;

  .settings-title {
    margin-bottom: $space-large;
  }
}

.content-margin {
  margin-bottom: $space-two;
}

.form-label {
  color: $neutral-grey-800;
  padding-bottom: $space-one;
}

.agent-container {
  height: $space-four;
  margin-top: $space-small;
}

.gap-normal {
  gap: $space-normal;
}

.reset-button {
  align-items: self-end;
  display: inline-flex;
  gap: $space-six;
  padding-left: $zero;
}

.half-width {
  width: 50%;
}
</style>
