<template>
  <div class="sla-rhs-panel custom-scroll">
    <div class="sla-details">
      <div
        class="sla-name flex-row flex-align flex-wrap gap--small mg-bottom--medium"
      >
        <div class="body-b2 text-light">
          Policy:
        </div>
        <div class="title-h4 text-dark line-clamp-1" v-text="slaName" />
      </div>
      <template v-if="slaDescription">
        <div class="body-b2 text-light mg-bottom--smaller">
          Description:
        </div>
        <div
          class="sla-description body-b2 text-dark line-clamp-3 mg-bottom--medium"
          v-text="slaDescription"
        />
      </template>
    </div>
    <div class="sla-summary">
      <div class="body-b2 text-light mg-bottom--small">
        Summary of the policy:
      </div>
      <div class="body-b2 text-light" v-html="slaSummary" />
    </div>
  </div>
</template>
<script>
import generateSlaSummary from '../../utils/generateSlaSummary';

export default {
  props: {
    sla: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    slaName() {
      return this.sla.name;
    },
    slaDescription() {
      return this.sla.description;
    },
    slaSummary() {
      return generateSlaSummary(this.sla);
    },
  },
};
</script>
