<template>
  <woot-single-selector
    size="medium"
    :front-icon="iconName"
    :custom-style="{
      left: 0,
      top: 0,
      width: '18rem',
    }"
    :button-style="{
      color: '#808975',
      padding: '0.4rem 0.6rem',
    }"
    :show-selected-text="false"
    :default-option="selectedFilterComputed"
    :option-list="filterOptions"
    highlight-selected
    @click="onSelectType"
  />
</template>

<script>
export default {
  props: {
    selectedOption: {
      type: String,
      default: 'messenger',
    },
    item: {
      type: Object,
      default: () => {},
    },
    toggleFilter: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selectedFilter: null,
      filterOptions: [
        { id: 0, name: 'messenger' },
        { id: 1, name: 'comment' },
      ],
    };
  },
  computed: {
    selectedFilterComputed() {
      return this.selectedFilter?.name || this.selectedOption || 'messenger';
    },
    iconName() {
      return this.selectedFilterComputed === 'messenger'
        ? 'messenger'
        : 'instagram';
    },
  },
  methods: {
    onSelectType(val) {
      this.selectedFilter = val;
      this.toggleFilter(this.item.key, true, this.selectedFilter.name);
    },
  },
};
</script>
