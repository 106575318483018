<template>
  <div class="flex-column-center flex-justify full-width full-height">
    <img
      :src="globalConfig.logo"
      :alt="globalConfig.installationName"
      class="hero__logo"
    />
    <div class="align-center mg-top--large" style="padding: 0rem 6rem">
      <div class="small-12 column align-center text-align">
        <p class="title-h1 dark">
          {{ errorTitle }}
        </p>
        <p class="body-b1 light">
          {{ errorSubtitle }}
        </p>
        <woot-submit-button
          class="go-back"
          :button-text="buttonTitle"
          button-class="medium"
          @click="onButtonClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import auth from '../api/auth';

export default {
  mixins: [globalConfigMixin],
  props: {
    errorTitle: {
      type: String,
      default: function defaultTitle() {
        return this.$route.params.errorTitle || this.$t('ERROR_PAGE.TITLE');
      },
    },
    errorSubtitle: {
      type: String,
      default: function defaultSubTitle() {
        return (
          this.$route.params.errorSubtitle || this.$t('ERROR_PAGE.SUB_TITLE')
        );
      },
    },
    buttonTitle: {
      type: String,
      default: function defaultButtonTitle() {
        return this.$route.params.buttonTitle || this.$t('ERROR_PAGE.BUTTON');
      },
    },
    onButtonClick: {
      type: Function,
      default: () => auth.logout({ withRedirect: false }),
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.go-back {
  margin-top: var(--space-medium);
}

.dark {
  color: $text-dark;
}

.light {
  color: $text-light;
}
</style>
