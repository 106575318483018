export default {
  /* SignUp Events */
  SHOPIFY_SIGN_UP_INITIATED: 'SHOPIFY_SIGN_UP_INITIATED',
  NORMAL_SIGN_UP_INITIATED: 'NORMAL_SIGN_UP_INITIATED',
  SHOPIFY_SIGN_UP_ATTEMPTED: 'SHOPIFY_SIGN_UP_ATTEMPTED',
  NORMAL_SIGN_UP_ATTEMPTED: 'NORMAL_SIGN_UP_ATTEMPTED',
  /* SignUp Events */

  /* OnBoarding Events */
  NORMAL_ONBOARDING_MODAL_INITIATED: 'NORMAL_ONBOARDING_MODAL_INITIATED',
  NORMAL_ONBOARDING_MODAL_FINISHED: 'NORMAL_ONBOARDING_MODAL_FINISHED',
  SHOPIFY_ONBOARDING_MODAL_INITIATED: 'SHOPIFY_ONBOARDING_MODAL_INITIATED',
  SHOPIFY_ONBOARDING_MODAL_FINISHED: 'SHOPIFY_ONBOARDING_MODAL_FINISHED',
  /* OnBoarding Events */

  /* Add collaborator/agent Events */
  ADD_COLLABORATOR_INITIATED_FROM_AGENT_SETTINGS:
    'ADD_COLLABORATOR_INITIATED_FROM_AGENT_SETTINGS',
  ADD_COLLABORATOR_INITIATED_FROM_INBOX_SETTINGS:
    'ADD_COLLABORATOR_INITIATED_FROM_INBOX_SETTINGS',
  ADD_COLLABORATOR_FINISHED: 'ADD_COLLABORATOR_FINISHED',
  /* Add collaborator/agent Events */

  /* FB inbox creation Events */
  FB_INBOX_CREATION_INITIATED: 'FB_INBOX_CREATION_INITIATED',
  FB_INBOX_CREATION_ERROR_UNAUTHORIZED: 'FB_INBOX_CREATION_ERROR_UNAUTHORIZED',
  FB_INBOX_CREATION_GENERIC_ERROR: 'FB_INBOX_CREATION_GENERIC_ERROR',
  FB_INBOX_CREATION_SUCCESSFUL: 'FB_INBOX_CREATION_SUCCESSFUL',
  /* FB inbox creation Events */

  /* IG inbox creation Events */
  IG_INBOX_CREATION_INITIATED: 'IG_INBOX_CREATION_INITIATED',
  IG_INBOX_CREATION_ERROR_UNAUTHORIZED: 'IG_INBOX_CREATION_ERROR_UNAUTHORIZED',
  IG_INBOX_CREATION_GENERIC_ERROR: 'IG_INBOX_CREATION_GENERIC_ERROR',
  IG_INBOX_CREATION_SUCCESSFUL: 'IG_INBOX_CREATION_SUCCESSFUL',
  /* IG inbox creation Events */

  /* Widget script copy Events */
  WA_WIDGET_SCRIPT_COPIED: 'WA_WIDGET_SCRIPT_COPIED',
  WEB_WIDGET_SCRIPT_COPIED: 'WEB_WIDGET_SCRIPT_COPIED',
  /* Widget script copy Events */

  /* Send message event to track active status of a user */
  SEND_MESSAGE: 'SEND_MESSAGE',
  /* Send message event to track active status of a user */

  /* Automation Events */
  ADD_AUTOMATION_RULE_INITIATED: 'ADD_AUTOMATION_RULE_INITIATED',
  ADD_AUTOMATION_RULE_SUCCESSFUL: 'ADD_AUTOMATION_RULE_SUCCESSFUL',
  /* Automation Events */

  /* Teams Events */
  ADD_TEAM_INITIATED: 'ADD_TEAM_INITIATED',
  ADD_TEAM_SUCCESSFUL: 'ADD_TEAM_SUCCESSFUL',
  /* Teams Events */

  /* Deep Shopify Events */
  ORDER_CREATE_SUCCESS: 'ORDER_CREATE_SUCCESS',
  ORDER_CANCELLED: 'ORDER_CANCELLED',
  ORDER_EDITED: 'ORDER_EDITED',
  /* Deep Shopify Events */

  ticket_overview: 'Ticket Overview',
  bot_overview: 'Bot Overview',
  agent_overview: 'Agent Overview',
  clicked_analytics_section: 'clicked_analytics_section',

  clicked_date_filter_menu_item: 'clicked_date_filter_menu_item',
  clicked_inbox_filter_menu_item: 'clicked_inbox_filter_menu_item',
  clicked_tag_filter_menu_item: 'clicked_tag_filter_menu_item',
  clicked_working_hours_menu_item: 'clicked_working_hours_menu_item',

  clicked_ticket_overview_report: 'clicked_ticket_overview_report',
  clicked_ticket_overview_overall_report_dropdown:
    'clicked_ticket_overview_overall_report_dropdown',
  clicked_ticket_overview_overall_report_dropdown_menu_item:
    'clicked_ticket_overview_overall_report_dropdown_menu_item',
  clicked_graph_zoom_in_out: 'clicked_graph_zoom_in_out',
  clicked_agent_overview_tab: 'clicked_agent_overview_tab',
  clicked_agent_filter_menu_item: 'clicked_agent_filter_menu_item',
  clicked_report_menu_item: 'clicked_report_menu_item',
  clicked_bot_overview_tab: 'clicked_bot_overview_tab',
  toggled_bot_performance_graph: 'toggled_bot_performance_graph',
  clicked_ticket_search: 'clicked_ticket_search',
  clicked_ticket_search_category: 'clicked_ticket_search_category',
  clicked_ticket_save_search: 'clicked_ticket_save_search',
  clicked_ticket_search_reset: 'clicked_ticket_search_reset',
  clicked_ticket_team_filter: 'clicked_ticket_team_filter',
  clicked_ticket_assigned_by: 'clicked_ticket_assigned_by',
  clicked_ticket_save_filter: 'clicked_ticket_save_filter',
  clicked_ticket_reset_filter: 'clicked_ticket_reset_filter',
  clicked_ticket_action_tab: 'clicked_ticket_action_tab',
  assigned_ticket_to_team: 'assigned_ticket_to_team',
  mix_panel_clicked_previous_ticket: 'mix_panel_clicked_previous_ticket',
  clicked_add_crm_ticket: 'clicked_add_crm_ticket',
  added_crm_ticket: 'added_crm_ticket',
  selected_ticket_order_filter: 'selected_ticket_order_filter',
  searched_ticket_order: 'searched_ticket_order',
  shared_ticket_product: 'shared_ticket_product',
  sent_ticket_emoji: 'sent_ticket_emoji',
  saved_ticket_private_note: 'saved_ticket_private_note',
  clicked_side_nav_menu_item: 'clicked_side_nav_menu_item',

  clicked_change_profile_status: 'clicked_change_profile_status',
  changed_profile_status: 'changed_profile_status',
  clicked_profile_settings: 'clicked_profile_settings',
  clicked_contacts_next: 'clicked_contacts_next',
  clicked_contacts_previous: 'clicked_contacts_previous',
  clicked_contact_card: 'clicked_contact_card',
  clicked_contacts_download_send_to_email:
    'clicked_contacts_download_send_to_email',
  clicked_contacts_download_data: 'clicked_contacts_download_data',
  clicked_contacts_download_select_date_range:
    'clicked_contacts_download_select_date_range',
  clicked_contact_edit: 'clicked_contact_edit',
  clicked_contact_edit_cancel: 'clicked_contact_edit_cancel',
  clicked_contact_edit_submit: 'clicked_contact_edit_submit',
  clicked_ticket_select: 'clicked_ticket_select',
  clicked_ticket_select_all: 'clicked_ticket_select_all',
  clicked_ticket_bulk_modify: 'clicked_ticket_bulk_modify',
  clicked_settings_section: 'clicked_settings_section',
  clicked_settings_save_edit_tag: 'clicked_settings_save_edit_tag',
  clicked_settings_edit_button_payload_template:
    'clicked_settings_edit_button_payload_template',
  clicked_ticket_bulk_starred: 'clicked_ticket_bulk_starred',
  clicked_ticket_bulk_apply_changes: 'clicked_ticket_bulk_apply_changes',
  clicked_selected_ticket_menu_option: 'clicked_selected_ticket_menu_option',
  clicked_ticket_menu_options: 'clicked_ticket_menu_options',
  clicked_ticket_sort: 'clicked_ticket_sort',
  sorted_tickets: 'sorted_tickets',
  clicked_sales_analytics_tab: 'clicked_sales_analytics_tab',
  clicked_sales_insight_chart_type_sales_analytics:
    'clicked_sales_insight_chart_type_sales_analytics',
  clicked_mark_all_notifications_as_read:
    'clicked_mark_all_notifications_as_read',
  clicked_settings_save_account_settings:
    'clicked_settings_save_account_settings',
  clicked_settings_agent_submit: 'clicked_settings_agent_submit',
  clicked_settings_edit_agent: 'clicked_settings_edit_agent',
  clicked_role_filter_menu_item: 'clicked_role_filter_menu_item',
  toggled_agent_ticket_concurrency: 'toggled_agent_ticket_concurrency',
  searched_settings_automation: 'searched_settings_automation',
  clicked_settings_create_submit_automation:
    'clicked_settings_create_submit_automation',
  clicked_settings_save_edit_canned_response:
    'clicked_settings_save_edit_canned_response',
  clicked_settings_export_download_csv_canned_response:
    'clicked_settings_export_download_csv_canned_response',
  clicked_settings_import_upload_csv_canned_response:
    'clicked_settings_import_upload_csv_canned_response',
  clicked_settings_import_download_example_canned_response:
    'clicked_settings_import_download_example_canned_response',
  searched_settings_canned_response: 'searched_settings_canned_response',
  clicked_settings_add_canned_response: 'clicked_settings_add_canned_response',
  deleted_settings_canned_response: 'deleted_settings_canned_response',
  searched_settings_inbox: 'searched_settings_inbox',
  deleted_settings_inbox: 'deleted_settings_inbox',
  clicked_edit_inbox: 'clicked_edit_inbox',
  clicked_settings_add_inbox: 'clicked_settings_add_inbox',
  clicked_update_inbox_settings: 'clicked_update_inbox_settings',
  clicked_ooo_toggle: 'clicked_ooo_toggle',
  clicked_prechatform_toggle: 'clicked_prechatform_toggle',

  /* Inbox assignment settings events begin */
  clicked_disable_assignment: 'clicked_disable_assignment',
  clicked_enable_assignment: 'clicked_enable_assignment',
  clicked_enable_online_assignment: 'clicked_enable_online_assignment',
  /* Inbox assignment settings events end */

  connection_successful_settings_integrations:
    'connection_successful_settings_integrations',
  clicked_settings_export_download_csv_tag:
    'clicked_settings_export_download_csv_tag',
  clicked_settings_import_upload_csv_tag:
    'clicked_settings_import_upload_csv_tag',
  clicked_settings_import_download_example_tag:
    'clicked_settings_import_download_example_tag',
  searched_settings_tag: 'searched_settings_tag',
  clicked_settings_add_sub_tag: 'clicked_settings_add_sub_tag',
  clicked_settings_add_tag: 'clicked_settings_add_tag',
  deleted_settings_tag: 'deleted_settings_tag',
  clicked_update_profile_settings: 'clicked_update_profile_settings',
  clicked_enable_push_notification: 'clicked_enable_push_notification',
  searched_settings_team: 'searched_settings_team',
  deleted_settings_team: 'deleted_settings_team',
  clicked_settings_create_team: 'clicked_settings_create_team',
  clicked_settings_export_download_csv_template:
    'clicked_settings_export_download_csv_template',
  clicked_settings_import_upload_csv_template:
    'clicked_settings_import_upload_csv_template',
  clicked_settings_import_download_example_template:
    'clicked_settings_import_download_example_template',
  searched_settings_template: 'searched_settings_template',
  clicked_settings_add_template: 'clicked_settings_add_template',
  clicked_settings_edit_template: 'clicked_settings_edit_template',
  deleted_settings_template: 'deleted_settings_template',
  clicked_settings_sync_template: 'clicked_settings_sync_template',
  clicked_login_successful: 'clicked_login_successful',
  clicked_reset_password_mail_sent: 'clicked_reset_password_mail_sent',
  clicked_signup_successful: 'clicked_signup_successful',
  clicked_order_create_successful: 'clicked_order_create_successful',
  clicked_edit_order_successful: 'clicked_edit_order_successful',
  clicked_refund_order_successful: 'clicked_refund_order_successful',
  clicked_create_inbox_successful: 'clicked_create_inbox_successful',
  clicked_improve_draft: 'clicked_improve_draft',
  clicked_select_draft: 'clicked_select_draft',
  clicked_reject_draft: 'clicked_reject_draft',
  clicked_reply_draft: 'clicked_reply_draft',
  // Ticket creation
  clicked_inbox_via_create_ticket_button:
    'clicked_inbox_via_create_ticket_button',
  clicked_inbox_via_create_ticket_filter:
    'clicked_inbox_via_create_ticket_filter',
  clicked_create_ticket_send_message: 'clicked_create_ticket_send_message',
  // Ticket assignment
  clicked_move_to_automatic_assignment: 'clicked_move_to_automatic_assignment',
  clicked_move_to_manual_assignment: 'clicked_move_to_manual_assignment',

  /* Custom Ticket Fields begins */
  clicked_create_field: 'clicked_create_field',
  clicked_save_field: 'clicked_save_field',
  /* Custom Ticket Fields ends */
};
