<template>
  <div
    class="search gap--one"
    :class="[
      { 'search--white': variant },
      { 'no-spin-buttons': inputType === 'number' },
      customClass,
    ]"
    :style="searchHeight"
  >
    <slot />
    <input
      ref="searchInput"
      :value="value"
      :type="inputType"
      class="search-input text-dark"
      :class="size === 'medium' ? 'body-b3' : 'body-b2'"
      :placeholder="placeholder"
      @keydown="disableArrowKey"
      @keyup.enter="onEnterHandler"
      @input="handleInput"
    />
    <div class="button-right" @click="triggerSearch">
      <icons
        name="search"
        :color="value.length ? 'green' : 'lightgrey'"
        :size="iconSize"
      />
    </div>
  </div>
</template>

<script>
import { removeInvisibleCharacters } from 'dashboard/helper/stringUtils';

export default {
  props: {
    size: {
      type: String,
      default: 'large',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: 'Search Here',
    },
    filter: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
    variant: {
      type: Boolean,
      default: false,
    },
    onSearch: {
      type: Function,
      default: () => {},
    },
    onEnter: {
      type: Function,
      default: () => null,
    },
    customClass: {
      type: String,
      default: undefined,
    },
    iconSize: {
      type: String,
      default: 'semimedium',
    },
  },
  computed: {
    searchHeight() {
      return this.size === 'medium'
        ? { height: '3.6rem' }
        : { height: '4.4rem' };
    },
  },
  mounted() {
    this.focusInputField();
  },
  methods: {
    // Prevent the default action (incrementing/decrementing the value)
    disableArrowKey(event) {
      if (this.inputType !== 'number') return;
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
      }
    },
    focusInputField() {
      this.$refs.searchInput.focus();
    },
    triggerSearch() {
      this.onSearch();
      this.focusInputField();
    },
    handleInput({ target: { value } }) {
      const sanitizedValue = removeInvisibleCharacters(value);
      this.$emit('setSearch', sanitizedValue);
    },
    onEnterHandler() {
      if (this.onEnter() !== null) {
        return this.onEnter;
      }
      return this.triggerSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.search {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $space-slab;
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius-smaller;
  padding: $zero $space-slab;
  background: $color-white;

  &--white {
    border: 1px solid $neutral-grey-400;
    flex-direction: row-reverse;
    background: $neutral-white;
  }

  .search-input {
    background: none;
    display: flex;
    padding: 0;
    outline: none;
  }

  .button-right {
    float: right;

    .icon {
      margin-right: 8px;
    }
  }
}

.button-right ::v-deep svg {
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}
::placeholder {
  color: $text-light;
  opacity: 1;
}

.no-spin-buttons {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}
</style>
