<template>
  <div
    class="column padding-container settings-box custom-scroll"
    style="padding-top: 0px"
  >
    <div class="filter-pane">
      <div class="flex-space-between" style="margin-top: 0.5rem">
        <div class="flex-row analytics-filters">
          <date-range-selector
            :default-selection="2"
            @date-range-change="onDateRangeChange"
          />
          <inbox-filter
            :allow-multi-select="true"
            :value="$t('ANALYTICS.FILTER_SELECTION.INBOX.PLACEHOLDER')"
            :exclude-inbox-types="excludedInboxTypes"
            :toggle-filter="changeInboxId"
          />
          <woot-single-selector
            :disabled="false"
            variant="secondary"
            size="medium"
            :default-option="selectedWorkingHour"
            :option-list="optionList"
            :show-selected-text="false"
            :custom-style="{ width: 'auto' }"
            @click="changeWorkingHourType"
          />
        </div>
        <div>
          <woot-primary-button
            :name="$t('ANALYTICS.DOWNLOAD_REPORT.PERFORMANCE_REPORT')"
            front-icon="email"
            @click="download"
          />
        </div>
      </div>
    </div>
    <div v-if="cardUIFlag" class="metrics-card-container">
      <metrics-card-skeleton
        v-for="(item, index) in metricsCount"
        :key="index"
      />
    </div>
    <div v-else class="metrics-card-container">
      <metrics-card
        v-for="(item, index) in metricsCount"
        :key="index"
        :title="item.title"
        :sub-title="item.sub"
        :unit="item.unit"
        :actual="item.actual"
        :color="item.color"
      />
    </div>

    <div v-if="lineChartUIFlag" class="bottom-container">
      <line-chart-skeleton />
    </div>
    <div v-else class="medium-12 bottom-container">
      <div class="lime-card-1dp medium-12">
        <div
          class="flex-space-between"
          style="position: relative; height: 3.5rem"
        >
          <div class="table-title">
            {{ selectedLineChart }}
          </div>
          <div class="flex-row-justify-center" style="margin-top: 2rem">
            <woot-single-selector
              :option-list="lineChartType"
              :default-option="selectedLineChart"
              :custom-style="{ 'margin-top': '-0.1rem', 'z-index': '10000' }"
              :show-selected-text="false"
              front-icon="filterHollow"
              @click="changeLineChartType"
            />
          </div>
        </div>
        <line-chart
          :series="chartSeries"
          :categories="chartCategories"
          :colors="chartColors"
          :show-marker="false"
          :toggle-data="false"
          :highlight-data="false"
          :toolbar-enable="false"
          @zoom-button-clicked="
            handleZoomButtonClick('bot_performance', 'automation_percentage')
          "
        />
      </div>
    </div>
    <div v-if="uiFlag" class="bottom-container">
      <line-chart-skeleton />
    </div>
    <div v-else class="medium-12 bottom-container">
      <div class="lime-card-1dp medium-12">
        <column-chart
          title="User Intent Breakdown"
          :series="columnChartSeries"
          :categories="columnChartCategories"
          :colors="columnChartColors"
          :has-title="true"
          :border-radius="10"
          column-width="30%"
          :chart-offset-y="30"
        />
      </div>
    </div>
    <woot-modal :show.sync="downloading" :on-close="hidePopup">
      <div class="download-note">
        <img
          src="https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
        />
        <div>
          An email with attached report will be sent to
          <span>{{ userEmail }} </span> shortly.
        </div>
      </div>
    </woot-modal>
    <woot-modal :show.sync="downloadError" :on-close="hidePopup">
      <div class="download-note">
        <div>Sorry for inconvenience some error occured.</div>
      </div>
    </woot-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MetricsCard from '../components/MetricsCard';
import LineChart from 'dashboard/components/widgets/chart/LineChart';
import ColumnChart from 'dashboard/components/widgets/chart/ColumnChart';
import DateRangeSelector from '../components/DateRangeSelector';
import LineChartSkeleton from 'dashboard/components/LineChartSkeleton';
import MetricsCardSkeleton from '../components/MetricsCardSkeleton';
import InboxFilter from 'dashboard/components/widgets/conversation/filter/InboxFilter';

import timeMixin from 'dashboard/mixins/time';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import alertMixin from 'shared/mixins/alertMixin';

import { getUserEmail } from 'dashboard/helper/cookieHelper';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

export default {
  components: {
    MetricsCard,
    LineChart,
    ColumnChart,
    DateRangeSelector,
    LineChartSkeleton,
    MetricsCardSkeleton,
    InboxFilter,
  },
  mixins: [alertMixin, timeMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      dateRange: '1',
      startDate: this.formatDate(new Date().getTime()).replaceAll('-', '/'),
      endDate: this.formatDate(new Date().getTime()).replaceAll('-', '/'),
      botMetrics: null,
      botAutomationPercent: null,
      botConversionRate: null,
      botUserIntentBreakdown: null,
      line_chart: 'automation_percent',
      downloading: false,
      downloadError: false,
      selectedInboxes: [],
      selectedLabels: [],
      line_chart_type: [
        {
          NAME: 'Automation Percent',
          KEY: 'automation_percent',
        },
        {
          NAME: 'Conversion Rate',
          KEY: 'conversion_rate',
        },
      ],
      working_hour_type: [
        {
          NAME: 'All Time',
          KEY: 'all',
        },
        {
          NAME: 'Outside Working Hours',
          KEY: 'outside',
        },
        {
          NAME: 'Inside Working Hours',
          KEY: 'inside',
        },
      ],
      selectedWorkingHourType: 'all',
      lineChartColor: 'green',
    };
  },
  computed: {
    ...mapGetters({
      uiFlag: 'getBotUIFlag',
      cardUIFlag: 'getBotCardUIFlag',
      lineChartUIFlag: 'getBotLineChartUIFlag',
      accountLabels: 'labels/getLabelsWithoutGroups',
    }),
    excludedInboxTypes() {
      return [INBOX_TYPES.KNOWLARITY];
    },
    userEmail() {
      return getUserEmail();
    },
    optionList() {
      return this.working_hour_type.map(item => {
        return {
          key: item.KEY,
          name: item.NAME,
        };
      });
    },
    labelsOptionList() {
      return this.accountLabels;
    },
    selectedWorkingHour() {
      let selectedIndex = this.optionList.findIndex(
        a => a.key === this.selectedWorkingHourType
      );
      return selectedIndex > 0
        ? this.optionList[selectedIndex].name
        : this.optionList[0].name;
    },

    lineChartType() {
      return this.line_chart_type.map(item => {
        return {
          key: item.KEY,
          name: item.NAME,
        };
      });
    },
    selectedLineChart() {
      let selectedIndex = this.lineChartType.findIndex(
        i => i.key === this.line_chart
      );

      return this.line_chart_type[selectedIndex].NAME;
    },

    metricsCount() {
      return [
        {
          title: this.botMetrics?.bot_conversations
            ? this.nFormatter(this.botMetrics?.bot_conversations)
            : 0,
          sub: 'Bot Tickets',
          actual: this.$t('ANALYTICS.TOOLTIP.BOT_OVERVIEW.BOT_TICKETS'),
        },
        {
          title: this.botMetrics?.automation
            ? this.nFormatter(this.botMetrics?.automation) + '%'
            : 0,
          sub: 'Automation',
          actual: this.$t('ANALYTICS.TOOLTIP.BOT_OVERVIEW.AUTOMATION'),
        },
        {
          title: this.botMetrics?.resolution_time
            ? this.formattedDuration(
                this.secondsToDuration(
                  parseInt(this.botMetrics?.resolution_time * 60, 10)
                )
              )
            : 0,
          sub: 'Resolution Time',
          unit: 'Minute',
          actual: this.$t('ANALYTICS.TOOLTIP.BOT_OVERVIEW.RESOLUTION_TIME'),
        },
      ];
    },
    chartSeries() {
      if (this.line_chart === 'automation_percent') {
        return [this.botAutomationPercent?.yaxis[0]];
      }
      return this.botConversionRate?.yaxis;
    },
    chartCategories() {
      return this.line_chart === 'automation_percent'
        ? this.botAutomationPercent?.xaxis?.map(date => this.dateToWords(date))
        : this.botConversionRate?.xaxis?.map(date => this.dateToWords(date));
    },
    chartColors() {
      return this.line_chart === 'automation_percent'
        ? ['#6BAC1B', 'rgba(0,0,0,0)', 'rgba(0,0,0,0)']
        : ['#5D7092'];
    },

    columnChartSeries() {
      return [
        {
          name: 'User Intent Breakdown',
          data: this.botUserIntentBreakdown
            ? Object.values(this.botUserIntentBreakdown)
            : null,
        },
      ];
    },
    columnChartColors() {
      return ['#6BAC1B'];
    },
    columnChartCategories() {
      return this.botUserIntentBreakdown
        ? Object.keys(this.botUserIntentBreakdown)
        : null;
    },
  },

  methods: {
    async download() {
      this.mix_panel_clicked_report_menu_item(
        'bot_performance',
        'performance_report'
      );
      this.downloading = true;
      await this.$store
        .dispatch('getBotAnalyticsReport', {
          start_date: this.startDate,
          end_date: this.endDate,
          email: this.userEmail,
          labels: this.selectedLabels,
          inboxes: this.selectedInboxes,
          working_hours: this.selectedWorkingHourType,
        })
        .then(() => {})
        .catch(() => {
          this.downloading = false;
          this.downloadError = true;
        });
    },
    hidePopup() {
      this.downloading = false;
      this.downloadError = false;
    },
    nFormatter(num) {
      return Math.round(num * 100) / 100;
    },
    changeLineChartType(val) {
      this.mix_panel_toggled_bot_performance_graph(val.key);
      this.line_chart = val.key;
    },
    changeInboxId(val, inboxes, id) {
      this.selectedInboxes = id;
      this.getBotMetrics();
      this.getBotAutomationPercent();
      this.getBotConversionRate();
      this.getUserBreakdown();
    },
    changeWorkingHourType(val) {
      this.selectedWorkingHourType = val.key;
      this.getBotMetrics();
      this.getBotAutomationPercent();
      this.getBotConversionRate();
      this.getUserBreakdown();
    },
    changeLabelId(val, inboxes, id) {
      this.selectedLabels = id;
      this.getBotMetrics();
      this.getBotAutomationPercent();
      this.getBotConversionRate();
      this.getUserBreakdown();
    },
    onDateRangeChange({ from, to }) {
      this.startDate = this.formatDate(from * 1000).replaceAll('-', '/');
      this.endDate = this.formatDate(to * 1000).replaceAll('-', '/');
      this.getBotMetrics();
      this.getBotAutomationPercent();
      this.getBotConversionRate();
      this.getUserBreakdown();
    },
    async getBotMetrics() {
      await this.$store
        .dispatch('getBotPerformanceStats', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          workingHours: this.selectedWorkingHourType,
          labels: this.selectedLabels,
        })
        .then(data => {
          this.botMetrics = data;
        });
    },
    async getBotAutomationPercent() {
      this.$store
        .dispatch('getBotAutomationPercent', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          workingHours: this.selectedWorkingHourType,
          labels: this.selectedLabels,
        })
        .then(data => {
          this.botAutomationPercent = data;
        });
    },
    async getBotConversionRate() {
      this.$store
        .dispatch('getBotConversionRate', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          workingHours: this.selectedWorkingHourType,
          labels: this.selectedLabels,
        })
        .then(data => {
          this.botConversionRate = data;
        });
    },
    async getUserBreakdown() {
      this.$store
        .dispatch('getBotUserIntentBreakdown', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          workingHours: this.selectedWorkingHourType,
          labels: this.selectedLabels,
        })
        .then(data => {
          this.botUserIntentBreakdown = data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
.settings-container {
  display: flex;
  flex-direction: column;
}
.chart-card {
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius;
  padding: $space-one $space-medium;
}
.metrics-card-container {
  display: flex;
  flex-direction: row;
  margin: $space-normal $space-micro;
  margin-top: $space-one;
}
.bottom-container {
  display: flex;
  flex-direction: column;
  margin: $zero $space-micro $space-normal;
}
.cancel-button {
  padding: $space-small;
  margin-bottom: $space-normal;
  cursor: pointer;
}
.selector-button {
  background: $neutral-white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: $neutral-grey-800;
  font-size: $font-size-small;
  line-height: 22px;
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius;
  padding: $space-small;
  margin-bottom: $space-normal;
  cursor: pointer;
  &:focus {
    border: 1px solid $pg-1-500;
    outline: none;
  }
}
.filter-pane {
  position: -webkit-sticky;
  position: sticky;
  background-color: #faf9f5;
  top: $zero;
  z-index: 1000;
  padding-bottom: $space-slab;
}
.section-title {
  font-size: $font-size-medium;
  line-height: $space-medium;
  color: $neutral-grey-600;
  margin-bottom: $space-slab;
}
.button {
  margin-bottom: auto;
}

.table-title {
  font-size: $font-size-medium;
  line-height: 2.6rem;
  color: $neutral-grey-800;
  font-weight: $font-weight-medium;
  margin: $space-slab $zero;
  margin-left: 1rem;
}

.tooltip-position {
  position: absolute;
  top: $zero;
  right: $zero;
}
</style>
