import { render, staticRenderFns } from "./EditContactModal.vue?vue&type=template&id=01cd0d15&scoped=true&"
import script from "./EditContactModal.vue?vue&type=script&lang=js&"
export * from "./EditContactModal.vue?vue&type=script&lang=js&"
import style0 from "./EditContactModal.vue?vue&type=style&index=0&id=01cd0d15&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01cd0d15",
  null
  
)

export default component.exports