<template>
  <div
    :class="{ 'full-width': fullWidth, 'no-spin-buttons': !showSpinButtons }"
  >
    <label
      ref="formLabel"
      :style="!label && !hasError && !required && 'margin-bottom: 0'"
      class="formlabel flex-row flex-justify--between gap-4"
    >
      <div ref="labelContainer" class="flex-row flex-align gap-4">
        <span v-if="required" title="required" class="dot-circle" />
        <span
          v-if="label"
          :class="['title-h5 text-dark', { 'text-light': disabled }]"
          v-text="label"
        />
      </div>
      <transition name="slide-down">
        <div
          v-if="hasError"
          ref="errorContainer"
          v-tooltip.top="collapseErrorMessage ? error : ''"
          class="error-container flex-row flex-align gap-4"
        >
          <icons
            name="danger"
            size="semimedium"
            color="danger"
            :show-title="false"
          />
          <span
            v-if="!collapseErrorMessage"
            class="body-b3 text-warn"
            :show-title="false"
            v-text="error"
          />
          <span v-else class="body-b3 text-warn" v-text="'Incorrect Details'" />
        </div>
      </transition>
    </label>
    <div style="position: relative" :class="{ 'read-only-active': readonly }">
      <component
        :is="tag"
        v-focus="autoFocus"
        class="body-b3 form-input custom-scroll"
        :value.prop="value"
        :type="inputType"
        :style="[inputSize]"
        :rows="rows"
        :placeholder="placeholder"
        :readonly="readonly"
        :disabled="disabled"
        @input="onChange"
        @blur="onBlur"
        @keydown="inputType === 'Number' ? validatePureNumber : null"
      />
      <div v-if="helpText" class="body-b3 helper-text" v-text="helpText" />
      <button
        v-if="showPasswordButton && value.length > 0"
        type="button"
        class="password-eye-button"
        :class="{ 'password-hide': !hidePassword }"
        @click="showPasswordToggle"
      >
        <icons
          name="eye"
          size="semimedium"
          color="stroke-grey"
          :custom-style="{ strokeWidth: 2 }"
          :title="hidePassword ? 'Hide' : 'Show'"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  directives: {
    focus: {
      inserted: (el, binding) => {
        if (binding.value) el.focus();
      },
    },
  },
  props: {
    rows: {
      type: [Number, String],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: 'Incorrect Input',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    fullWidth: { type: Boolean, default: false },
    required: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showPasswordButton: {
      type: Boolean,
      default: false,
    },
    showSpinButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hidePassword: true,
      collapseErrorMessage: false,
      modifiedType: 'password',
    };
  },
  computed: {
    tag() {
      if (this.rows) return 'textarea';
      return 'input';
    },
    inputType() {
      return this.showPasswordButton ? this.modifiedType : this.type;
    },
    inputSize() {
      switch (this.size) {
        case 'small':
          if (this.rows) return { padding: '0.6rem 0.8rem' };
          return { height: '3.6rem', padding: '0.6rem 0.8rem' };

        case 'large':
          if (this.rows) return { padding: '2.2rem 1.2rem' };
          return { padding: '2.2rem 1.2rem' };

        default:
          // this is the medium size
          if (this.rows) return { padding: '1rem 1.2rem' };
          return { height: '4.4rem', padding: '1rem 1.2rem' };
      }
    },
  },
  watch: {
    hasError() {
      this.handleResize();
    },
  },
  methods: {
    handleResize() {
      this.$nextTick(() => {
        if (this.$refs.errorContainer) {
          let remainingWidth =
            this.$refs.formLabel.clientWidth -
            this.$refs.errorContainer.clientWidth -
            this.$refs.labelContainer.clientWidth;

          const gapFromAdjacentElement = 20;
          if (remainingWidth < gapFromAdjacentElement) {
            this.collapseErrorMessage = true;
            return null;
          }
        }
        return null;
      });
      this.collapseErrorMessage = false;
    },
    onChange(e) {
      this.$emit('input', e.target.value);
    },
    showPasswordToggle() {
      this.hidePassword = !this.hidePassword;
      if (this.hidePassword) {
        this.modifiedType = 'password';
      } else {
        this.modifiedType = 'text';
      }
    },
    validatePureNumber(e) {
      return e.keyCode === 69;
    },
    onBlur(e) {
      this.$emit('blur', e.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.formlabel {
  .dot-circle {
    background-color: $warn-red-400;
    border-radius: 50%;
    display: inline-block;
    height: $space-smaller;
    width: $space-smaller;
  }

  .error-container {
    min-width: 0;

    .text-warn {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.password {
  &-eye-button {
    position: absolute;
    top: 1.3rem;
    right: $space-slab;
    cursor: pointer;
  }

  &-hide {
    &::after {
      position: absolute;
      content: '/';
      font-weight: $font-weight-light;
      font-size: 2.3rem;
      color: $neutral-grey-800;
      right: 0.5rem;
      bottom: -1px;
    }
  }
}

.gap-4 {
  gap: $space-smaller;
}

::placeholder {
  color: $text-light;
  opacity: 1;
}

input:disabled,
textarea:disabled {
  color: $neutral-grey-600;
  background-color: $neutral-grey-200;
  pointer-events: none;
}

.read-only-active {
  input:read-only,
  textarea:read-only {
    background-color: $chat-background;
    border: none;
    box-shadow: none;
    color: $text-dark;
    cursor: text;
  }
}

.no-spin-buttons {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}
</style>
