<template>
  <div class="column content-box settings-box">
    <div class="settings-container gap--normal">
      <div
        class="column custom-scroll lime-card-1dp"
        :class="{ 'settings-sub-menu': shouldShowKnowlarityAgents }"
      >
        <router-view />
      </div>
      <settings-side-card
        v-if="shouldShowKnowlarityAgents"
        :image="!shouldShowKnowlarityAgents ? 'inbox' : ''"
      >
        <knowlarity-agents :channel-id="currentChannel.channel_id" />
      </settings-side-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SettingsSideCard from '../SettingSideCard';
import KnowlarityAgents from './components/knowlarity/knowlarityAgents';

import { INBOX_TYPES } from 'shared/mixins/inboxMixin';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';

export default {
  components: {
    SettingsSideCard,
    KnowlarityAgents,
  },
  mixins: [globalConfigMixin],
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
    currentChannel() {
      return this.$store.getters['inboxes/getInbox'](
        this.$route.params.inbox_id
      );
    },
    activeIndex() {
      return this.items.findIndex(i => i.route === this.$route.name);
    },
    shouldShowKnowlarityAgents() {
      return (
        this.isKnowlarityInbox &&
        this.$route.name === 'settings_inboxes_add_agents'
      );
    },
    isKnowlarityInbox() {
      return this.currentChannel.channel_type === INBOX_TYPES.KNOWLARITY;
    },
    items() {
      return this.$t('INBOX_MGMT.CREATE_FLOW').map(item => ({
        ...item,
        body: this.useInstallationName(item.body),
      }));
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.settings-box {
  padding-top: $space-largest;
  padding-left: 0.1rem;
  overflow: hidden;

  .settings-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    .settings-sub-menu {
      position: relative;
    }

    .lime-card-1dp {
      padding: $zero;
    }

    .settings-side-card {
      margin-left: $zero;
      border-radius: $border-radius-smaller;

      .router-container {
        margin: $zero $space-large $space-large;

        .router-button {
          .button {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
