<template>
  <base-ticket-form can-upload-media :parent-form-fields="{ selectedCrm }" />
</template>
<script>
import BaseTicketForm from './shared/BaseTicketForm';
import { AVAILABLE_CRMS } from 'dashboard/routes/dashboard/conversation/overview/constants';

export default {
  components: { BaseTicketForm },
  computed: {
    selectedCrm() {
      return AVAILABLE_CRMS.KAPTURE;
    },
  },
};
</script>
