import { formatTime } from 'dashboard/helper/time';
import { convertToTitleCase } from 'dashboard/helper/stringUtils';
import { mapIdsToNames, getStyledKey } from 'dashboard/helper/summaryHelpers';

const generateSLASummary = sla => {
  const conditions = sla.conditions
    ? Object.entries(sla.conditions)
        .map(([key, condition]) => {
          const values = mapIdsToNames(condition.values, key).join(', ');
          return `<li>When <b class="text-dark capitalize">${getStyledKey(
            key
          )}</b> is <b class="text-lime-green">${values}</b></li>`;
        })
        .join('')
    : 'None';

  const metrics = Object.entries(sla.metrics)
    .map(([key, metric]) => {
      return `<li><b class="text-dark capitalize">${convertToTitleCase(
        key
      )}</b> ${formatTime(metric.time)}</li>`;
    })
    .join('');

  const reminders =
    sla.reminders && Object.keys(sla.reminders).length > 0
      ? Object.entries(sla.reminders)
          .map(([key, reminder]) => {
            const recipients = mapIdsToNames(reminder.data, 'agent_id').join(
              ', '
            );
            return `<li><b class="text-dark capitalize">${convertToTitleCase(
              key
            )}</b> reminder sent to <b class="text-dark">${recipients}</b> ${
              reminder.before
            } minutes before SLA breach</li>`;
          })
          .join('')
      : 'None';

  const escalationLevels = ['1st', '2nd', '3rd'];

  const escalations =
    sla.escalations && Object.keys(sla.escalations).length > 0
      ? Object.entries(sla.escalations)
          .map(([key, escalation]) => {
            const actions = escalation.action
              .map((action, index) => {
                const level = escalationLevels[index] || `${index + 1}th`;
                return `${level} level escalation to <b class="text-dark capitalize">${convertToTitleCase(
                  mapIdsToNames(action.data, 'agent_id').join(', ')
                )}</b> after ${action.after} minutes`;
              })
              .join('<br>');
            return `<li>When <b class="text-dark capitalize">${convertToTitleCase(
              key
            )}</b> SLA is <b class="text-dark">Breached</b><br>${actions}</li>`;
          })
          .join('')
      : 'None';

  const summary = `
    <div class="section"><strong>Conditions</strong><ul>${conditions}</ul></div>
    <div class="section"><strong>SLA Rule</strong><ul>${metrics}</ul></div>
    <div class="section"><strong>Reminders</strong><ul>${reminders}</ul></div>
    <div class="section"><strong>Escalation</strong><ul>${escalations}</ul></div>
  `;

  return summary;
};

export default generateSLASummary;
