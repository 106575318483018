<template>
  <top-banner :variant="bannerVariant[0]">
    <div class="text-align flex-row flex-justify flex-align full-width">
      <div
        class="body-b2 text-align shake line-clamp-1"
        v-text="bannerVariant[1]"
      />
      <woot-base-button
        v-if="isAdmin"
        size="small"
        class="mg-left"
        :loading="uiFlags.isFetchingPaymentLink"
        @click="getPaymentLink"
      >
        {{ $t('BILLING.NON_SHOPIFY.PAY_NOW') }}
      </woot-base-button>
    </div>
  </top-banner>
</template>

<script>
import { mapGetters } from 'vuex';

import TopBanner from 'dashboard/components/ui/TopBanner';

import billingMixin from 'dashboard/mixins/billing';
import onboardingMixin from 'dashboard/mixins/onboarding';
import shopifyMixin from 'dashboard/mixins/shopify';
import isAdmin from 'dashboard/mixins/isAdmin';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: { TopBanner },
  mixins: [billingMixin, shopifyMixin, onboardingMixin, alertMixin, isAdmin],
  computed: {
    ...mapGetters({
      uiFlags: 'accounts/getUIFlags',
    }),
    fallbackYellowBanner() {
      return this.gracePeriodDaysLeft <= 0 || !this.gracePeriodDaysLeft;
    },
    bannerVariant() {
      if (this.isEnterprise) {
        if (
          this.inGracePeriodForNonShopify ||
          this.inPendingPaymentForNonShopify
        )
          return [
            'warn',
            this.isAdmin
              ? this.$t('BILLING.NON_SHOPIFY.IN_GRACE_PERIOD')
              : this.$t('BILLING.NON_SHOPIFY.IN_GRACE_PERIOD_NON_ADMINS'),
          ];
      } else {
        if (this.inGracePeriodForNonShopify) {
          if (this.fallbackYellowBanner || this.gracePeriodDaysLeft > 7) {
            return [
              'warn',
              this.isAdmin
                ? this.$t('BILLING.NON_SHOPIFY.IN_GRACE_PERIOD')
                : this.$t('BILLING.NON_SHOPIFY.IN_GRACE_PERIOD_NON_ADMINS'),
            ];
          }
          if (this.gracePeriodDaysLeft <= 7)
            return [
              'warn-hard',
              this.$t('BILLING.NON_SHOPIFY.IN_GRACE_PERIOD_HARD', {
                daysLeft:
                  this.gracePeriodDaysLeft === 1
                    ? 'by today'
                    : 'within ' + this.gracePeriodDaysLeft + ' days',
              }),
            ];
        }
        if (this.inPendingPaymentForNonShopify)
          return [
            'danger',
            this.isAdmin
              ? this.$t('BILLING.NON_SHOPIFY.OVERDUE')
              : this.$t('BILLING.NON_SHOPIFY.OVERDUE_NON_ADMINS'),
          ];
      }

      return ['success', 'SUCCESS'];
    },
  },
  methods: {
    getPaymentLink() {
      this.$store
        .dispatch('accounts/fetchPaymentLink')
        .then(response => {
          window.open(
            response.payment_link,
            '_blank',
            'noreferrer',
            'noopener'
          );
        })
        .catch(() => {
          this.showAlert(this.$t('BILLING.NON_SHOPIFY.ERROR_MESSAGE'), 'error');
        });
    },
  },
};
</script>
