import { ACCESSED_DETAILS } from '../../piiMask/constants';

export async function unmaskFields(
  formData,
  fieldsToUnmask,
  endPoint,
  unmaskRequestPayload,
  vueInstance
) {
  const promises = fieldsToUnmask.map(async field => {
    if (!formData[field]) {
      const response = await vueInstance.$store.dispatch(endPoint, {
        ...unmaskRequestPayload,
        accessed_detail: ACCESSED_DETAILS[field],
      });
      formData[field] = response.accessed_data;
    }
  });

  await Promise.all(promises);
  return formData;
}
