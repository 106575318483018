<template>
  <div v-if="time" class="sla-status-item">
    <div class="text-light body-b2" v-text="label" />
    <div
      class="title-h5"
      :class="!isBreached ? 'text-sunrise-yellow' : 'text-light-red'"
      v-text="formattedTime"
    />
  </div>
</template>

<script>
import { formatDateWithToday } from 'dashboard/helper/time';
import { calculateTimeRemaining } from 'dashboard/components/widgets/conversation/helpers/slaBreachCalculator';

import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  props: {
    chatId: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: () => ({}),
    },
    time: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      counter: 0, // Time remaining in seconds
      isBreached: false,
    };
  },
  computed: {
    label() {
      return `${this.name} due by`;
    },
    formattedTime() {
      return formatDateWithToday(this.time);
    },
  },
  mounted() {
    if (this.time) {
      this.startCountdown();
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    startCountdown() {
      this.counter = calculateTimeRemaining(this.time);

      if (this.counter > 0) {
        this.timer = setInterval(() => {
          this.counter -= 1;

          if (this.counter <= 0) {
            this.handleBreach();
          }
        }, 1000);
      } else {
        this.handleBreach();
      }
    },
    handleBreach() {
      this.isBreached = true;
      // Emit the breach event
      bus.$emit(BUS_EVENTS.SLA_BREACHED, { chatId: this.chatId });
    },
  },
};
</script>
