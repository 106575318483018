<template>
  <div id="chart" style="width : 90px">
    <apexchart
      type="radialBar"
      height="120"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    chartOptions() {
      return {
        colors: ['#6BAC1B'],
        chart: {
          height: 20,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            inverseOrder: false,
            startAngle: 0,
            endAngle: 360,
            offsetX: 0,
            offsetY: 0,
            hollow: {
              margin: 5,
              size: '60%',
              background: 'transparent',
              image: undefined,
              imageWidth: 150,
              imageHeight: 150,
              imageOffsetX: 0,
              imageOffsetY: 0,
              imageClipped: true,
              position: 'front',
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
              },
            },
            track: {
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: 'transperant',
              strokeWidth: '97%',
              opacity: 1,
              margin: 5,
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
              },
            },
            dataLabels: {
              show: true,
              name: {
                show: false,
                fontSize: '12px',
                fontFamily: undefined,
                fontWeight: 600,
                color: undefined,
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: '12px',
                fontFamily: 'Lato',
                fontWeight: 700,
                color: '#3C492C',
                offsetY: 5,
                offsetX: 5,
                formatter: val => {
                  return val + '%';
                },
              },
              total: {
                show: true,
                label: 'Total',
                color: '#373d3f',
                fontSize: '12px',
                fontFamily: undefined,
                fontWeight: 600,
                formatter: w => {
                  return (
                    w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0) /
                      w.globals.series.length +
                    '%'
                  );
                },
              },
            },
          },
        },
      };
    },
  },
};
</script>
