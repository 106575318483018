<template>
  <woot-modal-new :show="true" :on-close="() => $emit('onClose')">
    <woot-modal-header-new header-title="Import Tags" />

    <div
      class="modal-content-wrapper flex-column flex-justify--between custom-scroll"
    >
      <div class="flex-column gap--normal">
        <div
          :style="{
            backgroundImage: `url(${require('assets/images/import_tags.svg')})`,
          }"
          class="illustration-container"
        />
        <span
          class="body-b2 text-light"
          v-text="
            'Please upload the Tags in the given format. If you don’t have a template ready, feel free to download here and fill & upload accordingly'
          "
        />
      </div>

      <table
        v-if="metadata"
        style="width: 100%; border-collapse: collapse; margin-top: 10px;"
      >
        <tr style="background-color: #f2f2f2;">
          <td style="padding: 10px; border: 1px solid #ddd;">
            Total Rows
          </td>
          <td style="padding: 10px; border: 1px solid #ddd;">
            {{ metadata.total_rows }}
          </td>
        </tr>
        <tr>
          <td style="padding: 10px; border: 1px solid #ddd;">
            Successful Imports
          </td>
          <td style="padding: 10px; border: 1px solid #ddd;">
            {{ metadata.success }}
          </td>
        </tr>
        <tr style="background-color: #f2f2f2;">
          <td style="padding: 10px; border: 1px solid #ddd;">
            Existing Tags
          </td>
          <td style="padding: 10px; border: 1px solid #ddd;">
            {{ metadata.exists }}
          </td>
        </tr>
        <tr>
          <td style="padding: 10px; border: 1px solid #ddd;">
            Errors
          </td>
          <td style="padding: 10px; border: 1px solid #ddd;">
            {{ metadata.errors }}
          </td>
        </tr>
      </table>

      <span
        v-if="errorMessage"
        class="text-align--right body-b3 text-dark"
        v-text="errorMessage"
      />
      <woot-base-button
        v-if="csvData"
        variant="secondary"
        class="download-report-btn"
        @click="downloadCSV"
      >
        Download Import Tags Report
      </woot-base-button>

      <div v-if="showUpload" class="flex-row flex-justify--between">
        <woot-base-button
          to="/example_tag.csv"
          variant="secondary"
          @click.native="InlineButtonClickHandler"
        >
          Download Example
        </woot-base-button>
        <woot-base-button class="custom-import-btn" :loading="isUploading">
          <file-upload
            :size="4096 * 4096"
            accept=".csv"
            @input-file="onFileUpload"
          >
            {{ errorMessage ? 'Retry' : 'Upload CSV' }}
          </file-upload>
        </woot-base-button>
      </div>
    </div>
  </woot-modal-new>
</template>

<script>
import FileUpload from 'vue-upload-component';
import LabelsAPI from 'dashboard/api/labels';
import alertMixin from 'shared/mixins/alertMixin';
import downloadMixin from 'shared/mixins/downloadMixin.js';

import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: { FileUpload },
  mixins: [mixPanelAnalyticsMixin, alertMixin, downloadMixin],
  data() {
    return {
      isUploading: false,
      errorMessage: '',
      metadata: null,
      csvData: null,
    };
  },
  computed: {
    showUpload() {
      return !this.metadata || (this.metadata && this.metadata.errors > 0);
    },
  },
  methods: {
    async onFileUpload(file) {
      this.isUploading = true;
      try {
        const response = await LabelsAPI.importLabels({ file: file.file });
        const {
          data: { csv_data, metadata },
        } = response;
        this.csvData = csv_data;
        this.metadata = metadata;
        this.$store.dispatch('labels/get');
        this.isUploading = false;
      } catch (error) {
        this.errorMessage =
          error?.response?.data?.error ||
          'Some error occurred. Please check the CSV file.';
        this.metadata = error?.response?.data?.metadata;
        this.showAlert(this.errorMessage);
      } finally {
        this.isUploading = false;
      }
    },
    downloadCSV() {
      this.processCsv(atob(this.csvData), 'import_tags_report.csv');
    },
    InlineButtonClickHandler() {
      this.mix_panel_clicked_settings_import_download_example_tag();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.modal-content-wrapper {
  overflow: auto;
  height: calc(100% - 8.6rem);

  .illustration-container {
    height: 21.6rem;
    max-width: 62.4rem;
    background: $neutral-grey-300;
    background-size: contain;
    border-radius: $border-radius-smaller;
  }

  .custom-import-btn {
    position: relative;
    width: $space-normal * 10;

    ::v-deep .file-uploads {
      position: absolute;
      width: 100%;

      label {
        cursor: pointer;
      }
    }
  }

  .download-report-btn {
    margin-top: $space-small;
    margin-bottom: $space-normal;
  }
}
</style>
