import { render, staticRenderFns } from "./Sinch.vue?vue&type=template&id=f9af0308&scoped=true&"
import script from "./Sinch.vue?vue&type=script&lang=js&"
export * from "./Sinch.vue?vue&type=script&lang=js&"
import style0 from "./Sinch.vue?vue&type=style&index=0&id=f9af0308&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9af0308",
  null
  
)

export default component.exports