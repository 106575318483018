import alertMixin from 'shared/mixins/alertMixin';

export const manifestSearchResults = async (
  dispatch,
  append,
  query,
  callBack
) => {
  if (!append) dispatch('conversationSearch/setSearchConversations', []);
  else dispatch('conversationSearch/setSearchConversationsAppend', []);

  if (!query) return [];

  dispatch('conversationSearch/setSearchConversationsUIFlag', {
    isFetching: true,
  });
  try {
    const payload = await callBack();
    if (!append) dispatch('conversationSearch/setSearchConversations', payload);
    else dispatch('conversationSearch/setSearchConversationsAppend', payload);
    return payload;
  } catch (error) {
    alertMixin.methods.showAlert('Error fetching search results!', 'error');
    return [];
  } finally {
    dispatch('conversationSearch/setSearchConversationsUIFlag', {
      isFetching: false,
    });
  }
};
