export default {
  computed: {
    hostURL() {
      return window.chatwootConfig.hostURL;
    },
    twilioCallbackURL() {
      return `${this.hostURL}/twilio/callback`;
    },
    umsEnabledChannels() {
      return [
        'Channel::GupshupEnterprise',
        'Channel::WhatsappCloudApi',
        'Channel::ThreeSixtyDialog',
        'Channel::Ics',
        'Channel::Infobip',
        'Channel::ExotelVoice',
      ].includes(this.channelType);
    },
    whatsappCallbackURL() {
      let channelName = this.channelType.split('::')[1];
      channelName = channelName
        .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
        .toLowerCase();

      let queryParam = '';
      if (this.hasQueryParamInCallback) {
        queryParam += `?app_id=${this.selectedInboxPhoneNumber}`;
      }

      return `${this.hostURL}/whatsapp/${channelName}/callback${queryParam}`;
    },
    vapidPublicKey() {
      return window.chatwootConfig.vapidPublicKey;
    },
    enabledLanguages() {
      return window.chatwootConfig.enabledLanguages;
    },
  },
};
