<template>
  <div class="custom-attributes--panel">
    <div v-for="attribute in listOfAttributes" :key="attribute">
      <woot-chips
        :title="`${attribute}: ${customAttributes[attribute]}`"
        variant="primary-small"
        :show-cancel-icon="false"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    customAttributes: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    listOfAttributes() {
      return Object.keys(this.customAttributes).filter(key => {
        const value = this.customAttributes[key];
        return value !== null && value !== undefined && value !== '';
      });
    },
  },
};
</script>
<style scoped>
.custom-attributes--panel {
  margin-top: var(--space-slab);
}
</style>
