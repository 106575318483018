/* eslint-disable consistent-return */
import VueRouter from 'vue-router';
import auth from '../api/auth';
import dashboard from './dashboard/dashboard.routes';
import authRoute from './auth/auth.routes';
import CallbackPage from './CallbackPage';
import Login from './Login';
import ErrorPage from './ErrorPage';
import { getInstance } from '../auth0';
import { frontendURL } from '../helper/URLHelper';

const DEFAULT_REDIRECT_PATH = 'accounts/:account_id/dashboard';
const UNPROTECTED_ROUTES = ['login', 'callback', 'error'];
const IGNORED_ROUTES = ['error', 'pushBack', 'install_shopify', 'callback'];

window.roleWiseRoutes = {
  agent: [],
  administrator: [],
  supervisor: [],
};

// Function to generate role-wise routes and update global object
export const generateRoleWiseRoute = route => {
  route.forEach(element => {
    if (element.children) {
      generateRoleWiseRoute(element.children);
    }
    if (element.roles) {
      element.roles.forEach(role =>
        window.roleWiseRoutes[role].push(element.name)
      );
    }
  });
};

export function getRedirectPath(accountId) {
  return frontendURL(DEFAULT_REDIRECT_PATH.replace(':account_id', accountId));
}

const routes = [
  { path: frontendURL('callback'), name: 'callback', component: CallbackPage },
  { path: frontendURL('login'), name: 'login', component: Login },
  { path: frontendURL('error'), name: 'error', component: ErrorPage },
  ...dashboard.routes,
  ...authRoute.routes,
  {
    path: '/',
    redirect: () => {
      const { authenticated, user, hasAccounts } = auth.getAuthenticatedUser();

      if (!authenticated) return frontendURL('login');
      if (authenticated && (!user || !hasAccounts)) return frontendURL('error');

      return getRedirectPath(user.account_id);
    },
  },
];

generateRoleWiseRoute(routes);

function routeIsAccessibleFor(route, role) {
  return window.roleWiseRoutes[role].includes(route);
}

export const withAuthGuard = (to, _, next) => {
  const authService = getInstance();
  const isIgnoredRoute = IGNORED_ROUTES.includes(to.name);
  const isUnprotectedRoute = UNPROTECTED_ROUTES.includes(to.name);

  const fn = () => {
    const { authenticated, user, hasAccounts } = auth.getAuthenticatedUser(
      to.path
    );

    if (isIgnoredRoute || (!authenticated && isUnprotectedRoute)) return next();
    if (!authenticated) return next(frontendURL('login'));
    if (authenticated && (!user || !hasAccounts))
      return next({
        name: 'error',
        params: {
          errorSubtitle: 'It seems like you are not part of any account.',
        },
      });
    if (authenticated && !to.name)
      return next(getRedirectPath(user.account_id));

    const userHasRoleAccess = routeIsAccessibleFor(to.name, user?.role);
    if (authenticated && hasAccounts && !userHasRoleAccess)
      return next(getRedirectPath(user.account_id));

    next();
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn();
    }
  });
};

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => withAuthGuard(to, from, next));

export default router;
