<template>
  <div
    class="conversation"
    :class="{
      active: isActiveChat,
      'unread-chat': hasUnread,
    }"
    :style="customStyle"
  >
    <div v-if="showSelectOption" class="more-action">
      <woot-single-selector
        variant="tertiary"
        :custom-style="{
          top: '2.4rem',
          width: 'auto',
          left: 'auto',
          right: '0.8rem',
          borderRadius: '0.8rem',
        }"
        :option-list="getActionList"
        icon="threeDot"
        :show-selected-text="false"
        :show-option-name="false"
        @click="toggleAction"
        @dropdown-opened="handleDropdownOpened"
      />
    </div>
    <div v-if="selection" class="selected--container">
      <label class="container-checkbox">
        <input
          :checked="selected"
          class="selected--checkbox"
          type="checkbox"
          @input="toggleAction({ id: 'select' })"
        />
        <span class="checkmark"></span>
      </label>
    </div>
    <div
      class="conversation--details columns"
      :class="{ 'selection--cards': selection }"
      :style="customCardStyle"
      @click="cardClick(chat)"
    >
      <div class="conversation--top">
        <span class="title-h5 text-dark conversation--user flex-row">
          <span v-tooltip="inboxTooltip">
            <icons
              :name="computedInboxClass.name"
              :color="`${computedInboxClass.color} evenodd`"
              :show-title="false"
              size="semimedium"
            />
          </span>

          <account-pii-mask-wrapper
            :mask-type="maskType"
            :pii-data="currentContact.name"
            unmask-action="piiMask/logUnmaskEvent"
            :unmask-payload="unmaskRequestPayload"
          >
            <template v-slot="{ data }">
              <span
                :title="data"
                class="text-truncate"
                style="max-width: 15rem"
              >
                {{ data }}
              </span>
            </template>
          </account-pii-mask-wrapper>

          <span
            v-if="showSlaBreachStatus"
            :key="refreshSlaStatus"
            v-tooltip="getSlaBreachStatus()"
            class="jiggle"
          >
            <icons
              name="bell"
              color="red"
              :show-title="false"
              size="semimedium"
            />
          </span>

          <span
            v-if="conversationForwardedBy"
            v-tooltip="
              $t('CONVERSATION.FORWARDED_BY') + conversationForwardedBy
            "
          >
            <icons
              name="forward"
              color="stroke-blue"
              size="semimedium"
              :show-title="false"
            />
          </span>
        </span>
        <div class="conversation--meta">
          <span class="timestamp body-b3 line-clamp-1">
            {{ dynamicTime(chat.timestamp) }}
          </span>
        </div>
      </div>

      <div v-if="lastMessageInChat" class="conversation--middle">
        <span v-if="lastMessageInChat.content" class="flex-row">
          <icons
            v-if="messageByAgent && inboxInfo.channel_type !== 'Channel::Email'"
            name="replyFilled"
            color="grey"
          />
          <span
            class="conversation--message body-b2 text-light"
            v-html="parsedLastMessage"
          />
        </span>
        <span v-else-if="lastMessageInChat.attachments" class="flex-row">
          <icons v-if="getIcon !== 'file'" :name="getIcon" color="grey" />
          <i v-else class="ion-document-text"></i>
          <span style="margin-left: 4px" class="conversation--message body-b2">
            {{ this.$t(`${attachmentIconKey}.CONTENT`) }}
          </span>
        </span>
        <span
          v-else-if="lastMessageInChat.content_type"
          class="flex-row"
          style="max-width: 85%"
        >
          <icons name="bot" color="grey" />
          <span class="conversation--message body-b2">
            {{ lastMessageInChat.content_type.replace('_', ' ') }}
          </span>
        </span>
        <span v-else>
          {{ $t('CHAT_LIST.NO_CONTENT') }}
        </span>
        <span class="flex-space-between">
          <span v-if="showAssigneeName" class="assignee--label">
            {{ getChatAssignee }}
          </span>
          <span class="flex-row">
            <span v-if="isConvStarred" class="mg-left--smaller mg-top--micro">
              <icons name="star" color="warningyellow" size="medium" />
            </span>
            <span>
              <span v-if="hasUnread" class="unread" :style="unreadColor">
                {{ unreadCount > 99 ? '99+' : unreadCount }}
              </span>
              <span
                v-if="!hasUnread && (isConvAlert || isConvNew)"
                class="unread"
                :style="unreadColor"
              >
                {{ '' }}
              </span>
            </span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { MESSAGE_TYPE } from 'widget/helpers/constants';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';

import router from '../../../routes';
import conversationMixin from '../../../mixins/conversations';
import timeMixin from '../../../mixins/time';
import inboxMixin from 'dashboard/mixins/inbox';
import alertMixin from 'shared/mixins/alertMixin';
import { frontendURL, conversationUrl } from '../../../helper/URLHelper';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import { getSlaBreachStatus } from './helpers/slaBreachCalculator';
import { MASK_TYPES } from 'dashboard/components/widgets/piiMask/constants';
import AccountPiiMaskWrapper from '../piiMask/AccountPiiMaskWrapper.vue';

export default {
  components: {
    AccountPiiMaskWrapper,
  },
  mixins: [
    timeMixin,
    conversationMixin,
    messageFormatterMixin,
    inboxMixin,
    googleAnalyticsMixin,
    alertMixin,
    mixPanelAnalyticsMixin,
  ],
  props: {
    customStyle: {
      type: Object,
      default: () => {},
    },
    customCardStyle: {
      type: Object,
      default: () => {},
    },
    chatAccountName: {
      type: String,
      default: '',
    },
    showSelectOption: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: true,
    },
    selection: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    activeStatus: {
      type: String,
      required: true,
    },
    chat: {
      type: Object,
      default: () => {},
    },
    hideInboxName: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: [String, Number],
      default: 0,
    },
    refreshSlaStatus: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      inboxesList: 'inboxes/getInboxes',
      activeInbox: 'getSelectedInbox',
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
    }),
    chatMetadata() {
      return this.chat.meta;
    },
    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.chatMetadata.sender.id
      );
    },
    attachmentIconKey() {
      const lastMessage = this.lastMessageInChat;
      const [{ file_type: fileType } = {}] = lastMessage.attachments;
      return `CHAT_LIST.ATTACHMENTS.${fileType || 'others'}`;
    },
    isActiveChat() {
      return this.currentChat.id === this.chat.id;
    },
    unreadCount() {
      return this.chat.unread_count ?? 0;
    },
    hasUnread() {
      return this.unreadCount > 0;
    },
    isInboxNameVisible() {
      return !this.activeInbox;
    },
    lastMessageInChat() {
      return this.lastMessage(this.chat);
    },
    getIcon() {
      if ('type' in this.lastMessageInChat.attachments[0]) return '';
      return this.lastMessageInChat.attachments[0].file_type;
    },
    showAssigneeName() {
      return (
        this.activeTab === 'all' &&
        ['open', 'waiting', 'followup', 'resolved'].includes(
          this.activeStatus
        ) &&
        this.chat.meta.assignee
      );
    },
    messageByAgent() {
      const lastMessage = this.lastMessageInChat;
      const { message_type: messageType } = lastMessage;
      return messageType === MESSAGE_TYPE.OUTGOING;
    },
    parsedLastMessage() {
      const {
        additional_attributes: { mail_subject },
      } = this.chat;

      return this.stripStyleCharacters(
        this.getPlainText(mail_subject || this.lastMessageInChat.content)
      );
    },
    unreadColor() {
      if (this.isConvAlert) return { background: '#FF7E61' };
      if (this.isConvNew) return { background: '#6BAC1B' };
      return { background: '#6BAC1B' };
    },
    isConvStarred() {
      return this.chat.starred;
    },
    isConvAlert() {
      return this.chat.alert;
    },
    isConvNew() {
      return this.chat.is_new;
    },
    getChatAssignee() {
      return this.assigneeFirstName(this.chat.meta.assignee?.name);
    },
    getActionList() {
      return [
        {
          id: 'select',
          name: this.selected
            ? this.$t('CONVERSATION.CARD_ACTIONS.DESELECT')
            : this.$t('CONVERSATION.CARD_ACTIONS.SELECT'),
        },
        {
          id: 'select_all',
          name: this.selectAll
            ? this.$t('CONVERSATION.CARD_ACTIONS.DESELECT_ALL')
            : this.$t('CONVERSATION.CARD_ACTIONS.SELECT_ALL'),
        },
        {
          id: 'starred',
          name: this.isConvStarred
            ? this.$t('CONVERSATION.CARD_ACTIONS.UNMARK_STAR')
            : this.$t('CONVERSATION.CARD_ACTIONS.MARK_STAR'),
        },
      ];
    },
    inboxInfo() {
      const stateInbox = this.$store.getters['inboxes/getInbox'](
        this.chat.inbox_id
      );
      return stateInbox;
    },
    computedInboxClass() {
      if (this.inboxInfo?.instagram_add_on) {
        if (this.chat.identifier === 'instagram_comment')
          return { name: 'instagram', color: 'instagram' };
        return { name: 'messenger', color: 'instagram' };
      }
      return this.getInboxClassByType(
        this.inboxInfo?.channel_type,
        this.inboxInfo?.phone_number,
        this.chat.identifier,
        this.inboxInfo?.instagram_add_on
      );
    },
    conversationForwardedBy() {
      const chatAssignee = this.chat.meta.assignee;

      if (
        !chatAssignee ||
        this.chat.assigned_by === 'system' ||
        chatAssignee.id === this.chat.assigned_by_resource_id
      )
        return null;

      const forwardedConversation = this.chat?.forwarded_conversation;
      let forwardedBy = null;

      if (forwardedConversation)
        forwardedBy = forwardedConversation.previous_assignee_name;

      return forwardedBy;
    },
    inboxTooltip() {
      if (this.accountId !== this.chat.account_id) return this.chatAccountName;
      if (!this.hideInboxName && this.isInboxNameVisible)
        return this.inboxInfo.name;
      return '';
    },
    showSlaBreachStatus() {
      const { sla_policy_id, frt_due, nrt_due, resolution_due } = this.chat;

      // Check if there is an SLA policy and at least one due time is present
      const hasDueTime = frt_due || nrt_due || resolution_due;

      return sla_policy_id && hasDueTime;
    },
    maskType() {
      return MASK_TYPES.NAME;
    },
    unmaskRequestPayload() {
      return {
        user_id: this.currentUser.id,
        resource_id: this.currentContact.id,
        resource_type: 'Contact',
      };
    },
  },
  methods: {
    getSlaBreachStatus() {
      return getSlaBreachStatus(this.chat);
    },
    cardClick(chat) {
      const { activeInbox, teamId, accountId } = this;

      if (chat.inbox_id !== activeInbox) {
        this.$store.dispatch('setActiveInbox', null);
      }

      const path = conversationUrl({
        accountId: chat.account_id,
        activeInbox: chat.inbox_id === activeInbox ? activeInbox : undefined,
        id: chat.id,
        teamId,
      });

      this.logConversationCardClick(path);
      this.$emit('cardClick', 'Card clicked');
      router.push({ path: frontendURL(path) });

      if (accountId !== chat.account_id) window.location.reload();
    },
    toggleAction(val) {
      this.mix_panel_clicked_selected_ticket_menu_option(val.name);
      if (val.id === 'select') {
        this.$emit('select', this.selected, this.chat.id);
      } else if (val.id === 'select_all') {
        this.$emit('select', this.selectAll);
      } else {
        this.$store.dispatch('starConversation', this.chat.id).then(() => {
          if (this.isConvStarred)
            this.showAlert(this.$t('CONVERSATION.STAR'), 'success');
          else this.showAlert(this.$t('CONVERSATION.UNSTAR'), 'info');
        });
      }
    },
    assigneeFirstName(username) {
      const parts = username ? username.split(/[ -]/) : [];
      let fName = parts.length ? parts[0] : '';
      if (fName.length > 0) {
        fName = fName.replace(/\b\w/g, l => l.toUpperCase());
      }
      return fName;
    },
    handleDropdownOpened() {
      this.mix_panel_clicked_ticket_menu_options();
    },
  },
};
</script>
