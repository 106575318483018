<template>
  <div class="mg-left--smaller mg-right--smaller">
    <div class="flex-row flex-justify flex-align gap--small">
      <div class="small-10">
        <dynamic-input
          :value="userInputValue"
          :label="label"
          :data-type="innerDataType"
          :validator="validator"
          help-text="Type the keyword and hit + button"
          @update-value="newValue => (userInputValue = newValue)"
        />
      </div>
      <woot-base-button
        tag="span"
        class="small-2 mg-top--smaller"
        variant="tertiary"
        @click="onAdd"
      >
        <icons
          name="plus"
          color="green"
          size="mediumlarge"
          view="-2 -2 24 24"
          :show-title="false"
        />
      </woot-base-button>
    </div>
    <div class="mg-top--small">
      <woot-chips
        v-for="keyword in inputValues"
        :key="keyword"
        :title="keyword.toString()"
        :show-tooltip="false"
        show-cancel-icon
        variant="primary-small"
        @click="onRemove"
      />
    </div>
  </div>
</template>
<script>
import DynamicInput from './DynamicInput';

import { DATA_TYPES } from './utils/constants';
import { getDefaultValue, getInnerDataType } from './utils/helper';

export default {
  components: { DynamicInput },
  props: {
    dataType: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    validator: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [String, Number, Array, Object, Boolean, Date],
      required: true,
    },
  },
  data() {
    return {
      userInputValue: '',
      inputValues: [],
    };
  },
  computed: {
    innerDataType() {
      return getInnerDataType(this.dataType) || 'text';
    },
  },
  mounted() {
    this.userInputValue = getDefaultValue(this.innerDataType);
  },
  methods: {
    onAdd() {
      const keyword = this.userInputValue;
      this.userInputValue = getDefaultValue(this.innerDataType);

      if (this.innerDataType === DATA_TYPES.TEXT && !keyword.length) return;

      if (!this.inputValues.includes(keyword)) {
        this.inputValues.push(keyword);
        this.$emit('update-value', this.inputValues);
      }
    },
    onRemove(keyword) {
      if (this.innerDataType === DATA_TYPES.NUM) keyword = +keyword;
      this.inputValues = this.inputValues.filter(el => el !== keyword);
    },
  },
};
</script>
