<template>
  <div class="banner-class" :class="variant" :style="customStyle">
    <icons name="info" size="medium" :color="iconColor" />

    <div class="icon-margin">
      <h5 class="title-h5 title-color">
        {{ title }}
        <!-- eslint-disable  -->
        <a :href="href" rel="noopener noreferrer nofollow" target="_blank"
          ><span class="body-b3 mg-left--slab link-color">{{ linkMsg }}</span>
        </a>
      </h5>

      <p class="body-b3 subtitle-color">
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'success',
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
    href: {
      type: String,
      default: '',
    },
    linkMsg: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconColor() {
      if (this.variant === 'info') return 'secondaryblue';
      if (this.variant === 'error') return 'red';
      if (this.variant === 'warning') return 'warningyellow';
      return 'green';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
.banner-class {
  background: transparent;
  padding: $space-slab;
  display: flex;
  position: relative;
  border-radius: $border-radius;
  margin: $space-one $space-larger;
  .subtitle-color {
    color: $text-light;
    margin-bottom: $zero;
  }

  .title-color {
    color: $text-dark;
    font-weight: $font-weight-black;
    margin-bottom: $space-smaller;
  }

  .icon-margin {
    margin-left: $space-one;
  }
}
.warning {
  border: 1px solid $sunrise-yellow;
}

.error {
  border: 1px solid $warn-red-400;
}

.info {
  border: 1px solid $secondary-blue;
}

.success {
  border: 1px solid $pg-1-500;
}

.link-color {
  color: $secondary-blue;
}
</style>
