<template>
  <section
    class="assign-agent-section title-h5_m text-dark flex-row flex-align gap--small"
  >
    <span v-text="$t('CREATE_TICKET.FORM.SECTIONS.ASSIGN_AGENT.SUB_HEADING')" />
    <span class="agent-selector">
      <woot-single-selector
        size="small"
        :custom-style="{
          left: 0,
          maxHeight: '20rem',
          width: '20rem',
        }"
        :button-style="{
          color: '#808975',
          padding: '0.4rem 0.6rem',
        }"
        :show-selected-text="false"
        :default-option="selectedAgent.name"
        :option-list="modifiedAgentList"
        @click="onSelectAgent"
      />
    </span>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      selectedAgent: { id: null, name: 'Any Collaborator' },
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      teamList: 'teams/getTeams',
    }),
    modifiedAgentList() {
      return [
        { id: null, name: 'Any Collaborator' },
        ...this.agentList.map(({ id, name }) => ({ id, name, type: 'agent' })),
        ...this.teamList.map(({ id, name }) => ({
          id: 'team:' + id,
          name: 'Team: ' + name,
          type: 'team',
        })),
      ];
    },
  },
  methods: {
    onSelectAgent(option) {
      this.selectedAgent = option;
      const { id, type } = option;
      this.$emit('select', { id, type });
    },
  },
};
</script>
