<template>
  <div class="button-container">
    <span
      v-if="stateText"
      class="custom-switch-button title-h6 flex-row-justify-center"
      :class="{
        'no-pointer-events': !stateText || !complimentStateText || activeState,
      }"
      :style="[
        activeState
          ? !complimentStateText
            ? switchStyleInactive
            : switchStyleActive
          : switchStyleInactive,
      ]"
      @click="$emit('click')"
      v-text="stateText"
    />
    <span
      v-if="complimentStateText"
      class="custom-switch-button title-h6 flex-row-justify-center"
      :class="{
        'no-pointer-events': !stateText || !complimentStateText || !activeState,
      }"
      :style="[
        !activeState
          ? !stateText
            ? switchStyleInactive
            : switchStyleActive
          : switchStyleInactive,
      ]"
      @click="$emit('click')"
      v-text="complimentStateText"
    />
  </div>
</template>

<script>
export default {
  props: {
    stateText: {
      type: [String, Boolean],
      default: '',
    },
    complimentStateText: {
      type: [String, Boolean],
      default: '',
    },
    activeState: {
      type: Boolean,
      default: true,
    },
    colorFlipped: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    switchStyleActive() {
      let background = this.colorFlipped ? '#6bac1b' : null;
      let color = this.colorFlipped ? '#fff' : '#808975';

      return {
        background: background,
        color: color,
      };
    },
    switchStyleInactive() {
      let color = this.colorFlipped ? '#808975' : '#fff';

      return {
        color: color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.button-container {
  background: $neutral-white;
  display: inline-flex;
  justify-content: space-around;
  padding: $space-smaller;
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius-smaller;

  .custom-switch-button {
    align-items: center;
    border-radius: $border-radius-smaller;
    cursor: pointer;
    height: $space-two;
    padding: $space-slab $space-six;
  }
  .no-pointer-events {
    pointer-events: none;
  }
}
</style>
