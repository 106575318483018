/* global axios */
import ApiClient from '../ApiClient';

class ChatApiChannel extends ApiClient {
  constructor() {
    super('channels/chat_api_channels', { accountScoped: true });
  }

  reboot({ chatapi_id }) {
    let data = {
      chatapi_id: chatapi_id,
    };

    return axios({
      method: 'post',
      url: `${this.url}/reboot`,
      data: data,
    });
  }

  rebootedAt({ chatapi_id }) {
    let data = {
      chatapi_id: chatapi_id,
    };

    return axios({
      method: 'post',
      url: `${this.url}/rebooted_at`,
      data,
    });
  }
}

export default new ChatApiChannel();
