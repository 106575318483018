import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import CSATReports from '../../api/csatReports';
import { wrapActionWithAbortController } from 'dashboard/store/utils/actionWrapper';
import { ignoreCancelledError } from 'dashboard/store/utils/errorHandling';

export const state = {
  records: [],
  botData: {
    metrics: {
      totalCsatRatings: 0,
      averageCsatRating: 0,
      responseRate: 0,
      collectionRate: 0,
      satisfactionScore: 0,
    },
    ratingCount: {},
    percentageSplit: {},
    flowWiseSplit: [],
  },
  metrics: {
    totalResponseCount: 0,
    totalSentMessagesCount: 0,
    satifactionScore: 0,
    totalCsatRatings: 0,
    responseRate: 0,
    ratingPercentageSplit: {},
  },
  uiFlags: {
    isFetching: false,
    isFetchingMetrics: false,
  },
};

export const getters = {
  // common
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  // bot csat
  getBotData(_state) {
    return _state.botData;
  },
  getRatingSystemPoint(_state) {
    return Object.keys(_state.botData.ratingCount).length;
  },
  getBotMetrics(_state) {
    return _state.botData.metrics;
  },
  // agent csat
  getCSATResponses(_state) {
    return _state.records;
  },
  getMetrics(_state) {
    return _state.metrics;
  },
  getCSATRating(_state) {
    return _state.metrics.totalCsatRatings;
  },
  getSatisfactionScore(_state) {
    return _state.metrics.satifactionScore;
  },
  getResponseRate(_state) {
    return _state.metrics.responseRate;
  },
  getRatingPercentage(_state) {
    return _state.metrics.ratingPercentageSplit;
  },
};

export const actions = {
  get: wrapActionWithAbortController(
    'getResponses',
    async function getResponses(
      { commit, abortController },
      {
        page = 1,
        startDate,
        endDate,
        workingHours = 'all',
        ticketType = 'all',
        inboxes,
        agent_id,
      } = {}
    ) {
      commit(types.SET_CSAT_RESPONSE_UI_FLAG, { isFetching: true });
      try {
        const response = await CSATReports.get(
          {
            page,
            startDate,
            endDate,
            workingHours,
            ticketType,
            inboxes,
            agent_id,
          },
          abortController.signal
        );
        commit(types.SET_CSAT_RESPONSE, response.data.results);
        commit(types.SET_CSAT_RESPONSE_UI_FLAG, { isFetching: false });
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.SET_CSAT_RESPONSE_UI_FLAG, { isFetching: false });
        });
      }
    }
  ),
  getBotCsatMetrics: wrapActionWithAbortController(
    'getBotCsatMetrics',
    async function getMetrics(
      { commit, abortController },
      { startDate, endDate, inboxes, workingHours = 'all', scope = 'bot_csat' }
    ) {
      commit(types.SET_CSAT_RESPONSE_UI_FLAG, { isFetching: true });
      commit(types.SET_CSAT_RESPONSE_UI_FLAG, { isFetchingMetrics: true });
      try {
        const response = await CSATReports.getMetrics(
          {
            startDate,
            endDate,
            inboxes,
            workingHours,
            scope,
          },
          abortController.signal
        );
        commit(types.SET_BOT_CSAT_RESPONSE_METRICS, response.data);
        commit(types.SET_CSAT_RESPONSE_UI_FLAG, { isFetching: false });
        commit(types.SET_CSAT_RESPONSE_UI_FLAG, { isFetchingMetrics: false });
      } catch (error) {
        ignoreCancelledError(error, () => {
          commit(types.SET_CSAT_RESPONSE_UI_FLAG, { isFetching: false });
          commit(types.SET_CSAT_RESPONSE_UI_FLAG, { isFetchingMetrics: false });
        });
      }
    }
  ),
  getMetrics: wrapActionWithAbortController(
    'getCsatMetrics',
    async function getMetrics(
      { commit, abortController },
      {
        startDate,
        endDate,
        workingHours = 'all',
        ticketType = 'all',
        inboxes,
        agent_id,
        scope = 'overall_csat',
      }
    ) {
      commit(types.SET_CSAT_RESPONSE_UI_FLAG, { isFetchingMetrics: true });
      try {
        const response = await CSATReports.getMetrics(
          {
            startDate,
            endDate,
            workingHours,
            ticketType,
            inboxes,
            agent_id,
            scope,
          },
          abortController.signal
        );
        commit(types.SET_CSAT_RESPONSE_METRICS, response.data);
        commit(types.SET_CSAT_RESPONSE_UI_FLAG, { isFetchingMetrics: false });
      } catch (error) {
        ignoreCancelledError(error, () =>
          commit(types.SET_CSAT_RESPONSE_UI_FLAG, { isFetchingMetrics: false })
        );
      }
    }
  ),
  download: async (
    _,
    {
      startDate,
      endDate,
      workingHours = 'all',
      ticketType = 'all',
      inboxes,
      agent_id,
      email,
      scope = 'csat',
    } = {}
  ) => {
    const response = await CSATReports.download({
      startDate,
      endDate,
      workingHours,
      ticketType,
      inboxes,
      agent_id,
      email,
      scope,
    });
    return response.data;
  },
};

export const mutations = {
  [types.SET_CSAT_RESPONSE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_CSAT_RESPONSE]: MutationHelpers.set,
  [types.SET_CSAT_RESPONSE_METRICS](_state, payload) {
    const {
      total_count: totalResponseCount = 0,
      total_sent_messages_count: totalSentMessagesCount = 0,
      satisfaction_score: satifactionScore,
      csat_rating: totalCsatRatings,
      rating_percentage_split: ratingPercentageSplit,
      response_rate: responseRate = 0,
    } = payload;

    Object.assign(_state.metrics, {
      totalResponseCount,
      satifactionScore,
      totalCsatRatings,
      ratingPercentageSplit,
      totalSentMessagesCount,
      responseRate,
    });
  },
  // bot csat
  [types.SET_BOT_CSAT_RESPONSE_METRICS](_state, payload) {
    const {
      total_csat_rating,
      csat_rating,
      response_rate,
      collection_rate,
      rating_count,
      percentage_split,
      flow_wise_split,
      satisfaction_score,
    } = payload;

    Object.assign(_state.botData.metrics, {
      totalCsatRatings: total_csat_rating,
      averageCsatRating: csat_rating,
      responseRate: response_rate,
      satisfactionScore: satisfaction_score,
      collectionRate: collection_rate,
    });

    Object.assign(_state.botData, {
      ratingCount: rating_count,
      percentageSplit: percentage_split,
      flowWiseSplit: flow_wise_split,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
