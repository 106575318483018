<template>
  <div>
    <div class="mg-top">
      <woot-single-selector
        :default-option="selectedCrm"
        :option-list="crmOptionList"
        :custom-style="{ width: '100%' }"
        @click="changeSelectedCrm"
      />
    </div>
    <div v-if="!uiFlags.fetchingList">
      <div v-if="noTicketsFound" class="body-b2 text-light mg-top text-align">
        No tickets found
      </div>
      <div v-else-if="isJiraCrmSelected" class="pd-top--slab">
        <jira-ticket-card
          v-for="ticket in ticketsList"
          :key="ticket.id"
          :ticket="ticket"
        />
      </div>
      <div v-else class="pd-top--slab">
        <ticket-card
          v-for="ticket in ticketsList"
          :key="ticket.id"
          :ticket="ticket"
          :selected-crm="selectedCrm"
        />
      </div>
    </div>
    <div v-else class="body-b2 text-light mg-top text-align">
      Loading tickets...
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TicketCard from './TicketCard';
import JiraTicketCard from './JiraTicketCard';

import alertMixin from 'shared/mixins/alertMixin';
import getCurrentChatContact from 'shared/mixins/getCurrentChatContact';

import { AVAILABLE_CRMS } from '../../../constants';

export default {
  components: { TicketCard, JiraTicketCard },
  mixins: [alertMixin, getCurrentChatContact],
  computed: {
    ...mapGetters({
      ticketsList: 'crm/getTickets',
      uiFlags: 'crm/getUIFlags',
      enabledCrms: 'crm/getEnabledCrms',
      selectedCrm: 'crm/getSelectedCrm',
      currentChat: 'getSelectedChat',
    }),
    isJiraCrmSelected() {
      return this.selectedCrm === AVAILABLE_CRMS.JIRA;
    },
    hasEnabledCrms() {
      return this.enabledCrms.length !== 0;
    },
    noTicketsFound() {
      return !this.ticketsList.length;
    },
    crmOptionList() {
      return this.enabledCrms?.map((crm, index) => ({ id: index, name: crm }));
    },
    email() {
      return this.contact?.email;
    },
    phone() {
      return this.contact?.phone_number;
    },
  },
  mounted() {
    if (this.enabledCrms.length > 0 && !this.selectedCrm)
      this.setSelectedCrm(this.enabledCrms[0]);
    this.fetchTickets();
  },
  methods: {
    changeSelectedCrm({ name }) {
      this.setSelectedCrm(name);
      this.fetchTickets();
    },
    setSelectedCrm(name) {
      this.$store.dispatch('crm/setSelectedCrm', name);
    },
    fetchTickets() {
      if (this.selectedCrm === AVAILABLE_CRMS.JIRA) this.fetchProjects();

      this.$store.dispatch('crm/get', {
        selectedCrm: this.selectedCrm,
        email: this.email,
        phoneNumber: this.phone,
        conversationId: this.currentChat.id,
      });
    },
    async fetchProjects() {
      try {
        await this.$store.dispatch('crm/fetchProjectsAndIssueTypes');
      } catch (error) {
        this.showAlert(
          this.$t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.COMMON.ERROR'),
          'error'
        );
      }
    },
  },
};
</script>
