import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import wootConstants from 'dashboard/constants';

const state = {
  customViews: [],
  curatedViews: [],
  defaultView: null,
  currentView: null,
  uiFlags: {
    isFetching: false,
    isSaving: false,
    isDeleting: false,
    isFilterActive: false,
  },
  currentViewFilters: { ...wootConstants.DEFAULT_CONVERSATION_FILTERS },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
