<template>
  <div class="medium-3 bg-white contact--panel custom-scroll lime-card-1dp">
    <contact-info :contact="contact" parent-component-name="ContactInfo" />
    <contact-custom-attributes
      v-if="hasContactAttributes"
      :custom-attributes="contact.custom_attributes"
    />
    <accordian-card
      v-if="contact.id"
      name="Previous Tickets"
      icon="history"
      icon-view="0 0 24 24"
      :first-time-open="true"
    >
      <contact-conversations
        v-if="contact.id"
        :contact-id="contact.id || -1"
        conversation-id=""
        parent-component-name="ContactInfo"
      />
    </accordian-card>
    <accordian-card
      id="add-tags-accordion"
      :name="$t('CONTACT_PANEL.CONTACT_TAGS.LABEL')"
      icon="hashFilled"
      icon-view="0 0 24 24"
      always-open
    >
      <contact-labels
        :key="contact.id"
        :contact-id="contact.id"
        :contact-labels="contactLabels"
        show-add-button
        @updateLabels="UpdateLabels"
      />
    </accordian-card>
  </div>
</template>

<script>
import AccordianCard from 'dashboard/components/AccordianCard';
import ContactConversations from 'dashboard/routes/dashboard/conversation/ContactConversations';
import ContactInfo from 'dashboard/routes/dashboard/conversation/contact/ContactInfo';
import ContactCustomAttributes from 'dashboard/routes/dashboard/conversation/ContactCustomAttributes';
import ContactLabels from 'dashboard/routes/dashboard/contacts/labels/LabelBox';

import { mapGetters } from 'vuex';

export default {
  components: {
    AccordianCard,
    ContactCustomAttributes,
    ContactConversations,
    ContactInfo,
    ContactLabels,
  },
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      contactLabelTitles: [],
    };
  },
  computed: {
    ...mapGetters({
      allContactLabels: 'labels/getContactLabelsWithoutGroups',
    }),
    hasContactAttributes() {
      const { custom_attributes: customAttributes } = this.contact;
      return customAttributes && Object.keys(customAttributes).length;
    },
    contactLabels() {
      return this.allContactLabels.filter(({ title }) => {
        return this.contactLabelTitles.includes(title);
      });
    },
  },
  mounted() {
    this.fetchContactLabels(this.contact);
  },
  methods: {
    fetchContactLabels(contact) {
      this.$store.dispatch('contactLabels/get', contact.id).then(data => {
        this.contactLabelTitles = data;
      });
    },
    async UpdateLabels({ id }, action) {
      try {
        await this.$store
          .dispatch('contactLabels/update', {
            contactId: this.contact.id,
            labelId: id,
            action,
          })
          .then(data => {
            this.contactLabelTitles = data;
          });
      } catch (error) {
        // Ignore error
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.contact--panel {
  @include border-normal-left;

  background: white;
  height: 100%;
  font-size: $font-size-small;
  position: relative;
  padding: $space-one;
}

.conversation--details {
  padding: 0 $space-normal;
}

.contact-conversation--panel {
  height: 100%;
}

.contact--mute {
  color: $warn-red-400;
  display: block;
  text-align: left;
}

.contact--actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
