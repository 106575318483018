export const isAFormMessage = message => message.content_type === 'form';
export const isASubmittedFormMessage = (message = {}) =>
  isAFormMessage(message) && !!message.content_attributes?.submitted_values;

export const messageTypes = [
  'incoming',
  'outgoing',
  'info_collect',
  'input_csat',
  'template',
  'activity',
  'sync_out',
  'start_conversation',
];

export const MESSAGE_MAX_LENGTH = {
  GENERAL: 10000,
  FACEBOOK: 1000,
  TWILIO_SMS: 160,
  TWEET: 280,
  EMAIL: 100000,
};
