<template>
  <div>
    <div class="content-margin">
      <h1
        class="title-h1 text-dark"
        v-html="$t('ONBOARDING.WHATSAPP.WELCOME.HEADING')"
      />
      <span
        class="body-b2 text-light"
        v-html="$t('ONBOARDING.WHATSAPP.WELCOME.SUB_HEADING')"
      />
    </div>
    <div class="content-margin">
      <span
        class="text-light body-b2 mg-bottom--small"
        v-text="$t('ONBOARDING.WHATSAPP.LIST.HEADING')"
      />
      <ol>
        <li
          v-for="(item, idx) in $t('ONBOARDING.WHATSAPP.LIST.ARRAY')"
          :key="idx"
          class="text-dark title-h4"
          v-text="item"
        />
      </ol>
    </div>
    <reference-box
      class="reference-box content-margin"
      :content="
        $t('ONBOARDING.WHATSAPP.INFO_TEXTS.IMPORTANT_INFORMATION.TITLE')
      "
    >
      <a
        class="body-b2 mg-top--small"
        href="https://www.limechat.ai"
        rel="noopener noreferrer nofollow"
        target="_blank"
        v-text="$t('ONBOARDING.WHATSAPP.INFO_TEXTS.IMPORTANT_INFORMATION.LINK')"
      />
    </reference-box>
    <div class="flex-row flex-justify--between">
      <woot-base-button
        variant="secondary"
        class="navigation-button"
        @click="navigateTo(ONBOARDING_SCREENS.WELCOME.key)"
      >
        {{ $t('ONBOARDING.NAVIGATION.PREVIOUS') }}
      </woot-base-button>
      <woot-base-button
        class="navigation-button"
        @click="navigateTo(ONBOARDING_SCREENS.WA_FORM.key)"
      >
        {{ $t('ONBOARDING.NAVIGATION.NEXT') }}
      </woot-base-button>
    </div>
  </div>
</template>
<script>
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';

import { ONBOARDING_SCREENS } from 'dashboard/constants';

export default {
  components: {
    ReferenceBox,
  },
  data() {
    return { ONBOARDING_SCREENS };
  },
  inject: ['navigateTo'],
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.content-margin {
  margin-bottom: $space-medium;
}

ol {
  > li {
    list-style-type: disc;
    margin-top: $space-smaller;
  }
}

.reference-box {
  max-width: 100%;
}

.navigation-button {
  width: $space-slab * 10;
}
</style>
