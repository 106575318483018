<template>
  <div class="flex-row flex-align flex-justify--between">
    <span class="flex-row flex-align">
      <span style="cursor: pointer" class="mg-right" @click="navigateBack">
        <icons
          name="chevronLeft"
          color="grey"
          size="mediumlarge"
          view="0 -1 24 24"
          title="Go back"
        />
      </span>
      <span
        class="title-h3 text-dark"
        v-text="$t('CREATE_TICKET.HEADER.TITLE')"
      />
      <create-ticket-button
        class="mg-left--slab CREATE_TICKET_INBOX_ID"
        :inbox="inbox"
        is-menu-styled
      />
    </span>
    <div class="flex-row flex-align gap--normal">
      <reference-box
        :to="documentation.ticketCreation.email"
        content="Email Ticket Guide"
        type="document"
      />
      <reference-box
        :to="documentation.ticketCreation.whatsapp"
        content="WhatsApp Ticket Guide"
        type="document"
      />
    </div>
  </div>
</template>
<script>
import CreateTicketButton from 'dashboard/components/buttons/CreateTicketButton';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';

import documentation from 'dashboard/helper/data/documentationData.json';

export default {
  components: { CreateTicketButton, ReferenceBox },
  props: {
    inbox: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      documentation,
    };
  },
  methods: {
    navigateBack() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>
