export default {
  methods: {
    sortByLabelType(labels) {
      return labels.sort((a, b) => (a.label_type > b.label_type ? 1 : -1));
    },
    labelColor(label) {
      return label.label_type === 'client' ? label.color : '#f6bd16';
    },
    filterLabelsByType(labels, type) {
      return labels.filter(label => label.label_type === type);
    },
    filterClientLabels(labels) {
      return this.filterLabelsByType(labels, 'client');
    },
    filterSystemLabels(labels) {
      return this.filterLabelsByType(labels, 'system');
    },
  },
};
