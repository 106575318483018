/* global axios */
import ApiClient from './ApiClient';

class Inboxes extends ApiClient {
  constructor() {
    super('inboxes', { accountScoped: true });
  }

  bulkUpdateAutoAssignment(items) {
    return axios.post(`${this.url}/bulk_update_auto_assignment`, { items });
  }
}

export default new Inboxes();
