// use this fn if required to detect the feasibility of the upcoming step
function detectStep(tour, target, callbackFn) {
  return new Promise((resolve, reject) => {
    if (!document.querySelector(target)) {
      tour.direction =
        tour.direction === undefined ? 'FORWARD' : tour.direction;
      let value = tour.direction === 'FORWARD' ? 2 : -2;
      let skipToStep = tour.currentStep + value;
      tour.start(skipToStep);
      reject();
    }
    callbackFn();
    resolve();
  });
}

export default {
  data() {
    return {
      progressTrackerTourSteps: {
        TOUR_VERSION: '1.0.0',
        STEPS: [
          {
            fakeStepNumber: 1,
            fakeTotalSteps: 1,
            target: '.progress-tracker-button',
            header: {
              title: 'Set up Account',
            },
            content:
              'You can start setting up and track your account from here',
            params: {
              placement: 'right',
            },
          },
        ],
      },
      chatSectionSteps: {
        TOUR_VERSION: '1.0.0',
        STEPS: [
          {
            fakeStepNumber: 1,
            fakeTotalSteps: 4,
            target: '#conversation-status-selector',
            header: {
              title: 'Conversation Status',
            },
            content:
              'Click on Resolve button to resolve the ticket in just one click. Select from dropdown to change the ticket status to Follow-up & Waiting.',
            params: {
              placement: 'left',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.chatSection,
                '#conversation-status-selector',
                () => {
                  document.querySelector('.contact').click();
                }
              ),
          },
          {
            fakeStepNumber: 1,
            fakeTotalSteps: 4,
            target: '.dropdown--pane-new--open',
            header: {
              title: 'Ticket Status',
            },
            content:
              'You can see the ticket status here. Select from dropdown to change the ticket status to Open, Resolved, Follow-up, Waiting, etc.',
            params: {
              placement: 'left',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.chatSection,
                '.conversation-status--dropdown',
                () => {
                  document
                    .querySelector('.conversation-status--dropdown')
                    .click();
                }
              ),
          },
          {
            fakeStepNumber: 1,
            fakeTotalSteps: 4,
            target: '.agent-dropdown-button',
            header: {
              title: 'Assign Agent',
            },
            content:
              'You can assign an agent to a ticket from here. Transfer the ticket to another agent in case of your unavailability or when you are not able to handle a query.',
            params: {
              placement: 'right',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.chatSection,
                '.agent-dropdown-button',
                () => {}
              ),
          },
          {
            fakeStepNumber: 2,
            fakeTotalSteps: 4,
            target: '.contact',
            header: {
              title: 'Ticket Overview',
            },
            content:
              'Get basic information about customer and the ticket. You can raise and view tickets of your integrated CRM.',
            params: {
              placement: 'left',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.chatSection,
                '.contact',
                () => {}
              ),
          },
          {
            fakeStepNumber: 2,
            fakeTotalSteps: 4,
            target: '#previous-tickets-accordion',
            header: {
              title: 'Previous tickets',
            },
            content: 'View all the previous tickets of the customer, here.',
            params: {
              placement: 'left',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.chatSection,
                '.previous-tickets-accordion',
                () =>
                  document.querySelector('.previous-tickets-accordion').click()
              ),
          },
          {
            fakeStepNumber: 2,
            fakeTotalSteps: 4,
            target: '#add-tags-accordion',
            header: {
              title: 'Assign Tags',
            },
            content:
              'Assign pre-defined tags to the tickets to categorise them. You can also see the tags that are automatically assigned by the bot.',
            params: {
              placement: 'left',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.chatSection,
                '.contact',
                // eslint-disable-next-line func-names
                () => document.querySelector('.contact').click()
              ),
          },
          {
            fakeStepNumber: 3,
            fakeTotalSteps: 4,
            target: '.orders',
            header: {
              title: 'Orders',
            },
            content:
              'View & share the details of all customer purchases like the order summary and discounts offered to the customer, here.',
            params: {
              placement: 'left',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(this, this.$tours.chatSection, '.orders', () =>
                document.querySelector('.orders').click()
              ),
          },
          {
            fakeStepNumber: 4,
            fakeTotalSteps: 4,
            target: '.products',
            header: {
              title: 'Products',
            },
            content:
              'Recommend or share the details of any product based on the customer’s requirements, directly from here. This section contains the entire product portfolio of the company.',
            params: {
              placement: 'left',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(this, this.$tours.chatSection, '.products', () =>
                document.querySelector('.products').click()
              ),
          },
        ],
      },
      sectionIntroTourSteps: {
        TOUR_VERSION: '1.0.0',
        ADMIN: [
          {
            fakeStepNumber: 1,
            fakeTotalSteps: 2,
            target: '.home',
            header: {
              title: 'Tickets',
            },
            content:
              'A ticket is created in LimeChat Helpdesk when a customer sends a new inbound  message or responds to an outbound message sent by the company. This is where, you can track & manage all the tickets from various communication channels at one place.',
            params: {
              placement: 'right',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.home',
                () => document.querySelector('.home').click()
              ),
          },
          {
            fakeStepNumber: 1,
            fakeTotalSteps: 2,
            target: '.conversation_overview',
            header: {
              title: 'Analytics',
            },
            content:
              'You can monitor your Helpdesk using real-time data and reports. Get in-depth insights into the ticket volume, agent and bot performance, helping you to optimize efficiency and drive customer experience.',
            params: {
              placement: 'right',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.conversation_overview',
                () => document.querySelector('.conversation_overview').click()
              ),
          },
          {
            fakeStepNumber: 1,
            fakeTotalSteps: 2,
            target: '.broadcast',
            header: {
              title: 'Broadcast',
            },
            content:
              'You can send promotional messages, alerts or important reminders in bulk using Broadcast.',
            params: {
              placement: 'right',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.broadcast',
                () => document.querySelector('.broadcast').click()
              ),
          },
          {
            fakeStepNumber: 1,
            fakeTotalSteps: 2,
            target: '.contacts_dashboard',
            header: {
              title: 'Contacts',
            },
            content:
              'You can view and edit customer details, and find tickets history for all your customers, here.',
            params: {
              placement: 'right',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.contacts_dashboard',
                () => document.querySelector('.contacts_dashboard').click()
              ),
          },
          {
            fakeStepNumber: 1,
            fakeTotalSteps: 2,
            target: '.settings_inbox_list',
            header: {
              title: 'Settings',
            },
            content:
              'This is where you can add inboxes and agents to manage customer tickets. You can also create tags, canned responses and templates, enable store notifications, integrate your Shopify store, and access general account settings.',
            params: {
              placement: 'right',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.settings_inbox_list',
                () => document.querySelector('.settings_inbox_list').click()
              ),
          },
          {
            fakeStepNumber: 2,
            fakeTotalSteps: 2,
            target: '.assignee-tabs',
            header: {
              title: 'Mine, Unassigned, All',
            },
            content:
              'Find tickets assigned to you under the mine section and tickets with no assigned agents under the unassigned section. You can also find all the tickets under the All section.',
            params: {
              placement: 'bottom',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.home',
                () => document.querySelector('.home').click()
              ),
          },
          {
            fakeStepNumber: 2,
            fakeTotalSteps: 2,
            target: '.filter-button',
            header: {
              title: 'Filtering and Sorting',
            },
            content:
              'Use ‘Tags’ and ‘Agents’ filters to view tickets based on the tags and agents assigned to them. Apply other filters to view tickets that are unread, starred or in alert state. Also, sort tickets by newest first, oldest first.',
            params: {
              placement: 'bottom',
              highlight: true,
            },
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.filter-button',
                () => {}
              ),
          },
          {
            fakeStepNumber: 2,
            fakeTotalSteps: 2,
            target: '.dropdown--pane-new--open',
            header: {
              title: 'Ticket Status Filter',
            },
            content:
              'You can filter & view tickets based on their current status like Open, Resolved, Followup, Waiting, Bot, Closed & Outbound.',
            params: {
              placement: 'right',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.status-filter',
                () => document.querySelector('.status-filter').click()
              ),
          },
        ],
        AGENT: [
          {
            fakeStepNumber: 1,
            fakeTotalSteps: 2,
            target: '.home',
            header: {
              title: 'Tickets',
            },
            content:
              'A ticket is created in LimeChat Helpdesk when a customer sends a new inbound  message or responds to an outbound message sent by the company. This is where, you can track & manage all the tickets from various communication channels at one place.',
            params: {
              placement: 'right',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.home',
                () => document.querySelector('.home').click()
              ),
          },
          {
            fakeStepNumber: 1,
            fakeTotalSteps: 2,
            target: '.broadcast',
            header: {
              title: 'Broadcast',
            },
            content:
              'You can send promotional messages, alerts or important reminders in bulk using Broadcast.',
            params: {
              placement: 'right',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.broadcast',
                () => document.querySelector('.broadcast').click()
              ),
          },
          {
            fakeStepNumber: 1,
            fakeTotalSteps: 2,
            target: '.contacts_dashboard',
            header: {
              title: 'Contacts',
            },
            content:
              'You can view and edit customer details, and find tickets history for all your customers, here.',
            params: {
              placement: 'right',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.contacts_dashboard',
                () => document.querySelector('.contacts_dashboard').click()
              ),
          },
          {
            fakeStepNumber: 1,
            fakeTotalSteps: 2,
            target: '.settings_inbox_list',
            header: {
              title: 'Settings',
            },
            content:
              'This is where you can add inboxes and agents to manage customer tickets. You can also create tags, canned responses and templates, enable store notifications, integrate your Shopify store, and access general account settings.',
            params: {
              placement: 'right',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.settings_inbox_list',
                () => document.querySelector('.settings_inbox_list').click()
              ),
          },
          {
            fakeStepNumber: 2,
            fakeTotalSteps: 2,
            target: '.assignee-tabs',
            header: {
              title: 'Mine, Unassigned, All',
            },
            content:
              'Find tickets assigned to you under the mine section and tickets with no assigned agents under the unassigned section. You can also find all the tickets under the All section.',
            params: {
              placement: 'bottom',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.home',
                () => document.querySelector('.home').click()
              ),
          },
          {
            fakeStepNumber: 2,
            fakeTotalSteps: 2,
            target: '.filter-button',
            header: {
              title: 'Filtering and Sorting',
            },
            content:
              'Use ‘Tags’ and ‘Agents’ filters to view tickets based on the tags and agents assigned to them. Apply other filters to view tickets that are unread, starred or in alert state. Also, sort tickets by newest first, oldest first.',
            params: {
              placement: 'bottom',
              highlight: true,
            },
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.filter-button',
                () => {}
              ),
          },
          {
            fakeStepNumber: 2,
            fakeTotalSteps: 2,
            target: '.dropdown--pane-new--open',
            header: {
              title: 'Ticket Status Filter',
            },
            content:
              'You can filter & view tickets based on their current status like Open, Resolved, Followup, Waiting, Bot, Closed & Outbound.',
            params: {
              placement: 'right',
              highlight: true,
            },
            // eslint-disable-next-line func-names
            before: () =>
              detectStep.call(
                this,
                this.$tours.sectionIntroduction,
                '.status-filter',
                () => document.querySelector('.status-filter').click()
              ),
          },
        ],
      },
    };
  },
};
