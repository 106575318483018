<template>
  <section class="conversation-view">
    <Header />

    <div class="app-content columns">
      <search-view
        v-if="savedSearchMeta.results.length"
        class="conversations-sidebar columns"
        :on-show-search-modal="onShowSearchModal"
        :on-clear-search="clearSearchResult"
        @conversation-load="onConversationLoad"
      />
      <chat-list
        v-else-if="isAdvancedTicketViewsEnabled"
        :label="label"
        :conversation-inbox="inboxId"
        @conversation-load="onConversationLoad"
      />
      <chat-list-deprecated
        v-else
        :label="label"
        :conversation-inbox="inboxId"
        @conversation-load="onConversationLoad"
      />
      <conversation-box />
      <router-view />
      <transition name="modal-fade">
        <search-modal
          v-if="showSearchModal"
          :saved-search-data="
            savedSearchMeta.results.length ? savedSearchMeta : null
          "
          :on-close="closeSearch"
        />
      </transition>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import ChatList from '../components/ChatList';
import ChatListDeprecated from '../components/deprecated/ChatListDeprecated';
import ConversationBox from 'dashboard/components/widgets/conversation/ConversationBox';
import SearchView from './SearchView';
import SearchModal from 'dashboard/components/widgets/conversation/filter/SearchFilter/search';

import Header from 'dashboard/components/layout/Header';
import AdminMixin from 'dashboard/mixins/isAdmin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  name: 'ConversationView',
  components: {
    Header,
    ChatList,
    ChatListDeprecated,
    ConversationBox,
    SearchModal,
    SearchView,
  },
  mixins: [AdminMixin, mixPanelAnalyticsMixin],
  props: {
    inboxId: {
      type: [String, Number],
      default: 0,
    },
    conversationId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showSearchModal: false,
      showFilterModal: false,
    };
  },
  computed: {
    ...mapGetters({
      chatList: 'getAllConversations',
      currentChat: 'getSelectedChat',
      uiFlags: 'getConversationUiFlags',
      savedSearchMeta: 'conversationSearch/getSavedSearchMeta',
      isAdvancedTicketViewsEnabled:
        'accounts/getCustomConversationViewsEnabledFlag',
    }),
    searchButtonLabel() {
      return this.$t('CONVERSATION.SEARCH_MESSAGES');
    },
  },
  mounted() {
    // if not admin then fetch agents otherwise agents are already being fetched in Navbar.vue cmpt for admin
    if (!this.isAdmin) this.$store.dispatch('agents/get');

    this.initialize();
    this.$watch('$store.state.route', () => this.initialize());
    this.$watch('chatList.length', (newLength, oldLength) => {
      if (newLength !== oldLength) {
        this.setActiveChat();
      }
    });
  },
  methods: {
    onConversationLoad(id = null) {
      this.fetchConversationIfUnavailable(id);
    },
    initialize() {
      this.$store.dispatch('setActiveInbox', this.inboxId);
      this.setActiveChat();
    },
    findConversation(id = null) {
      const conversationId = parseInt(id || this.conversationId, 10);
      const [chat] = this.chatList.filter(c => c.id === conversationId);
      return chat;
    },
    fetchConversationIfUnavailable(id = null) {
      const conversationId = id || this.conversationId;

      if (!conversationId) return null;

      const chat = this.findConversation(id);

      if (!chat) this.$store.dispatch('getConversation', conversationId);
      return chat;
    },
    setActiveChat() {
      if (this.conversationId) {
        const selectedConversation = this.fetchConversationIfUnavailable();
        if (
          !selectedConversation ||
          selectedConversation.id === this.currentChat.id
        ) {
          return;
        }
        this.$store.dispatch('setActiveChat', selectedConversation).then(() => {
          bus.$emit(BUS_EVENTS.SCROLL_TO_MESSAGE);
        });
      } else {
        this.$store.dispatch('clearSelectedState');
      }
    },
    clearSearchResult() {
      this.$store.dispatch('conversationSearch/clearSavedResult');
      this.$store.dispatch('conversationSearch/clearSearchRequestPayload');
    },
    onShowSearchModal() {
      this.showSearchModal = true;
      this.mix_panel_clicked_ticket_search();
    },
    closeSearch(id = null) {
      this.showSearchModal = false;
      this.onConversationLoad(id);
    },
    onShowFilterModal() {
      this.showFilterModal = true;
    },
    closeFilter() {
      this.showFilterModal = false;
    },
  },
};
</script>
