import { mapGetters } from 'vuex';

import { ONBOARDING_SCREENS } from 'dashboard/constants';

export default {
  data() {
    return {
      showOnboardingBanner: false,
      showIntegratedOnboardingModal: false,
    };
  },
  computed: {
    ...mapGetters({
      inProgressThreeSixtyInboxes: 'inboxes/getInProgressThreeSixtyInboxes',
      inboxes: 'inboxes/getInboxes',
    }),
    latestInProgressThreeSixtyInbox() {
      return this.inProgressThreeSixtyInboxes[0];
    },
    isEditInboxRoute() {
      return this.$route.name === 'settings_inbox_show';
    },
    showOnboardingBannerComputed() {
      // return false if any billing banner is shown after due date
      if (
        this.showBanner ||
        this.currentAccount?.payment_pending ||
        this.inPendingPaymentForNonShopify
      )
        return false;

      if (this.currentOnboardingScreen === ONBOARDING_SCREENS.FINISH_SETUP.key)
        return false;

      return (
        this.isAdmin &&
        !this.showIntegratedOnboardingModal &&
        this.showOnboardingBanner
      );
    },
    onboardingProgressStatus() {
      let variant = 'warn';
      const wabaIntegrationComplete =
        this.currentOnboardingScreen ===
        ONBOARDING_SCREENS.WABA_INTEGRATION_COMPLETE.key;
      const beforeFillingWaForm = [
        ONBOARDING_SCREENS.WELCOME.key,
        ONBOARDING_SCREENS.EDUCATE_USER.key,
        ONBOARDING_SCREENS.WA_FORM.key,
      ].includes(this.currentOnboardingScreen);
      const configurationErrorOccurred =
        this.currentOnboardingScreen ===
        ONBOARDING_SCREENS.CONFIGURATION_ERROR.key;

      if (wabaIntegrationComplete) variant = 'success';
      if (configurationErrorOccurred) variant = 'danger';
      if (beforeFillingWaForm)
        return {
          variant,
          content: this.$t(
            `ONBOARDING.STATUS.${ONBOARDING_SCREENS.WELCOME.key}`,
            {
              phoneNumber:
                this.latestInProgressThreeSixtyInbox?.phone_number || '',
            }
          ),
        };

      return {
        variant,
        content: this.$t(`ONBOARDING.STATUS.${this.currentOnboardingScreen}`, {
          phoneNumber: this.latestInProgressThreeSixtyInbox?.phone_number || '',
        }),
      };
    },
  },
  methods: {
    canShowOnboardingBanner() {
      this.showOnboardingBanner =
        !this.inboxes.length || !!this.latestInProgressThreeSixtyInbox?.id;
    },
    setOnboardingScreen(inbox, client, channels) {
      // run if there is a 360 dialog inbox which is not live
      if (inbox?.id) {
        if (window.name !== 'connect-360dialog' && client && channels)
          return ONBOARDING_SCREENS.WABA_INTEGRATION_COMPLETE.key;

        if (inbox.channel_live)
          return ONBOARDING_SCREENS.WABA_INTEGRATION_COMPLETE.key;

        if (inbox.channel_submitted)
          return ONBOARDING_SCREENS.WA_SETUP_IN_PROGRESS.key;

        // fallback
        return ONBOARDING_SCREENS.THREE_SIXTY_CONNECT.key;
      }

      return ONBOARDING_SCREENS.WELCOME.key;
    },
  },
};
