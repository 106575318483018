export const SALES_SCOPES = {
  INFLUENCED: 'influenced',
  BOT: 'bot',
  OUTBOUND: 'outbound',
};

export const INFLUENCED_SALES_PIE_CHART_COLORS = [
  '#6BAC1B',
  '#1B83AC',
  '#9270CA',
];

export const SALES_LINE_CHART_COLOR = ['#5D7092'];
