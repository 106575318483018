export const processValues = values => {
  if (Array.isArray(values)) {
    return values.map(val =>
      typeof val === 'object' && val?.id ? val.id : val
    );
  }
  if (typeof values === 'object' && values !== null) {
    return values.id ? [values.id] : [];
  }
  return values ? [values] : [];
};

const generatePayload = data => {
  // Deep clone the input to avoid mutation of the original data
  const filters = JSON.parse(JSON.stringify(data));

  const payload = filters.map((item, index, arr) => ({
    ...item,
    values: processValues(item.values),
    // Remove query_operator for all items except the last one
    query_operator: index === arr.length - 1 ? undefined : item.query_operator,
  }));

  return { payload };
};

export default generatePayload;
