<template>
  <div :id="message.id" class="post-container">
    <div :class="`flex-row-justify-center post-info`">
      <icons :name="postType" size="medium" :color="`${postType} evenodd`" />
      <span style="margin-left: 0.4rem" class="title-h5">
        {{ mediaData.name }}
      </span>
    </div>
    <span
      v-if="postType === 'facebook'"
      class="body-b2 text-dark post-info--caption post-info--limiter"
      style="margin-top: 0"
    >
      {{ mediaData.caption }}
    </span>
    <div v-if="mediaData.attachment" class="post-media">
      <a
        v-if="!isAnMp4"
        :href="mediaData.post_url"
        target="_blank"
        rel="noopener noreferrer"
        class="flex-row-justify-center"
      >
        <span v-if="isAVideo">
          <i class="ion ion-play mg-right" />
        </span>
        <img :src="mediaData.attachment" width="382" height="280" />
      </a>
      <video v-else :key="currentChat.id" width="328" controls height="280">
        <source :src="mediaData.attachment" type="video/mp4" />
      </video>
    </div>
    <div
      v-if="postType === 'facebook'"
      class="flex-space-between likes-container"
    >
      <div class="flex-row-center">
        <span class="mg-left--smaller mg-bottom--micro">
          <icons name="like" color="facebook" size="semimedium" />
        </span>
        <span class="mg-left--smaller">
          <icons name="smiley" color="facebook" size="semimedium" />
        </span>
        <span class="mg-left--smaller">
          <icons name="commentIg" color="facebook" size="semimedium" />
        </span>
      </div>
      <div class="flex-row-center">
        <a
          :href="mediaData.post_url"
          target="_blank"
          rel="noopener noreferrer"
          class="flex-row-justify-center"
        >
          <icons name="transfer" color="facebook" size="semimedium" />
          <span
            class="button-small facebook-blue"
            v-text="$t('CONVERSATION.SOCIAL_POST.OPEN_POST')"
          />
        </a>
      </div>
    </div>
    <div
      v-if="postType === 'instagram'"
      class="flex-space-between likes-container"
    >
      <div class="flex-row-center" style="margin-top: 0.4rem">
        <span class="mg-left--smaller">
          <icons name="heartLike" color="darkestgrey" size="medium" />
        </span>
        <span class="mg-left--smaller">
          <icons name="commentIg" color="darkestgrey" size="medium" />
        </span>
        <span class="mg-left--smaller">
          <icons name="sendIg" color="darkestgrey" size="medium" />
        </span>
      </div>

      <div class="flex-row-center">
        <a
          :href="mediaData.post_url"
          target="_blank"
          rel="noopener noreferrer"
          class="flex-row-justify-center"
        >
          <img
            src="~/assets/images/instagram_transfer.svg"
            class="mg-right--small"
          />
          <span
            class="button-small instagram-gradient"
            v-text="$t('CONVERSATION.SOCIAL_POST.OPEN_POST')"
          />
        </a>
      </div>
    </div>
    <div v-if="postType === 'facebook'" class="post-footer">
      <div class="flex-row-justify-center" style="justify-content: flex-start">
        <div class="mg-right--small">
          <woot-avatar
            :size="20"
            :username="username"
            color="#4267b2"
            :colored="false"
          />
        </div>
        <div
          :class="{ 'text-truncate': isCommentLengthReachingThreshold }"
          style="width: 100%"
        >
          <div class="flex-space-between">
            <b class="title-h5 text-dark">{{ username }}</b>

            <span class="flex-row">
              <woot-base-button
                v-tooltip="'Reply'"
                size="small"
                variant="tertiary"
                tag="span"
                @click="replyComment"
              >
                <icons
                  name="reply"
                  color="green"
                  size="semimedium"
                  :show-title="false"
                />
              </woot-base-button>
              <woot-base-button
                v-tooltip="'Reply privately'"
                size="small"
                variant="tertiary"
                tag="span"
                @click="onPrivateReply"
              >
                <icons
                  name="messenger"
                  color="green"
                  size="semimedium"
                  :show-title="false"
                />
              </woot-base-button>
            </span>
          </div>

          <div
            class="comment-container body-b2 text-dark"
            :class="{ 'text-truncate': isCommentLengthReachingThreshold }"
          >
            <span> {{ content }}</span>
            <div class="timestamp">
              <span class="capital_c3">
                {{ messageStamp(message.created_at) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="postType === 'instagram'" class="flex-row-center">
      <span class="body-b2 text-dark post-info--caption post-info--limiter">
        <b> {{ mediaData.name }}</b>
        {{ mediaData.caption }}
      </span>
    </div>
    <div v-if="postType === 'instagram'">
      <div
        class="flex-row-justify-center"
        style="padding: 0.8rem; padding-top: 0"
      >
        <div class="mg-top--one">
          <woot-avatar
            :size="20"
            :username="username"
            color="#dd2a7b"
            :colored="false"
          />
        </div>

        <div class="full-width flex-column">
          <div class="flex-row flex-justify--between">
            <span class="body-b2 text-dark post-info--caption">
              <b>{{ contact.instagram_username || username }}</b>
            </span>

            <span class="flex-row">
              <woot-base-button
                v-tooltip="'Reply'"
                size="small"
                variant="tertiary"
                tag="span"
                @click="replyComment"
              >
                <icons
                  name="reply"
                  color="green"
                  size="semimedium"
                  :show-title="false"
                />
              </woot-base-button>
              <woot-base-button
                v-tooltip="'Reply privately'"
                size="small"
                variant="tertiary"
                tag="span"
                @click="onPrivateReply"
              >
                <icons
                  name="messenger"
                  color="green"
                  size="semimedium"
                  :show-title="false"
                />
              </woot-base-button>
              <woot-base-button
                v-if="!isDeleted"
                v-tooltip="'Delete Comment'"
                size="small"
                variant="tertiary-danger"
                tag="span"
                @click="toggleDeleteModal"
              >
                <icons
                  name="bin"
                  color="darkestgrey"
                  size="semimedium"
                  :show-title="false"
                />
              </woot-base-button>
            </span>
          </div>

          <div
            class="full-width flex-column pd-top--smaller pd-bottom--smaller"
            :class="{ 'deleted-message-outline': isDeleted }"
          >
            <deleted-message-tag
              v-if="isDeleted"
              :message-attributes="messageContentAttributes"
            />

            <div class="pd-left--small pd-right--small">
              <span class="body-b2 text-dark">
                {{ content }}
              </span>
              <span class="timestamp">
                <span class="capital_c3">
                  {{ messageStamp(message.created_at) }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <delete-modal
      :show="showDeleteModal"
      message="Are you sure you want to delete this message?"
      confirm-text="Yes, Delete"
      reject-text="Cancel"
      :on-close="toggleDeleteModal"
      :on-confirm="deleteChat"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';
import timeMixin from 'dashboard/mixins/time';
import DeleteModal from '../../modal/DeleteModal';
import DeletedMessageTag from './DeletedMessageTag.vue';

export default {
  components: {
    DeleteModal,
    DeletedMessageTag,
  },
  mixins: [timeMixin, alertMixin],
  props: {
    mediaData: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    getReplyData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    isAnMp4() {
      if (this.mediaData.attachment.includes('mp4')) return true;
      return false;
    },
    postType() {
      let postType = this.type.split('_')[0];
      return postType === 'comment' ? 'facebook' : postType;
    },
    isAVideo() {
      if (this.mediaData.post_url.includes('videos')) return true;
      return false;
    },
    message() {
      return this.data?.messages[0];
    },
    isDeleted() {
      return this.message?.content_attributes?.deleted;
    },
    username() {
      return this.message?.sender?.name || '';
    },
    content() {
      return this.message?.content;
    },
    isCommentLengthReachingThreshold() {
      return this.content?.length > 1000 - 40;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](
        this.currentChat.meta?.sender?.id
      );
    },
    messageContentAttributes() {
      return (this.message && this.message.content_attributes) || {};
    },
  },
  methods: {
    replyComment() {
      const reply = this.getReplyData(this.message);
      reply({ showOnReplyBox: true });
    },
    onPrivateReply() {
      if (this.message.content_attributes?.private_reply === true) {
        this.showAlert(
          this.$t('CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.ALREADY_REPLIED'),
          'error'
        );
        return;
      }

      const reply = this.getReplyData(this.message);
      reply({ showPrivateReply: true });
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    async deleteChat() {
      try {
        let messagePayload = {
          conversationId: this.message.conversation_id,
          messageId: this.message.id,
        };

        await this.$store.dispatch('deleteMessage', messagePayload);
        this.showAlert(this.$t('CONVERSATION.MESSAGE.DELETE_SUCCESS'), 'info');
      } catch (error) {
        this.showAlert(this.$t('CONVERSATION.MESSAGE.DELETE_ERROR'), 'error');
      }
      this.toggleDeleteModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.post-container {
  @include elevation-1dp;
  display: block;
  border-radius: $border-radius;
  margin: $space-normal;
  margin-bottom: 0.8rem;
  background: $neutral-white;
  align-items: center;
  height: auto;
  max-width: 32.8rem;

  .facebook-blue {
    color: $facebook-blue;
  }

  .instagram-gradient {
    background: $instagram-gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .likes-container {
    padding: $space-small;
  }

  .post-footer {
    padding: $space-small;
  }

  .comment-container {
    display: inline-block;
    padding: $space-small;
    background: rgba(66, 103, 178, 0.1);
    margin-top: $space-smaller;
    max-width: 99%;
    border-radius: $border-radius;
    position: relative;
  }
  .reply-comment {
    cursor: pointer;
  }

  .private-reply {
    cursor: pointer;
    align-self: start;
    margin-left: $space-small;
  }

  .timestamp {
    display: flex;
    justify-content: flex-end;
  }
  .post-media {
    display: block;
    position: relative;
    font-size: 0;

    img {
      display: block !important;
      object-fit: fit;
      padding: $zero;
      width: 100%;
    }
  }

  .post-info {
    position: relative;
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding: $space-small;
    text-overflow: ellipsis;

    &--title {
      align-items: center;
      font-size: $font-size-default;
      font-weight: $font-weight-medium;
      line-height: $space-medium;
      margin-bottom: $space-smaller;
      text-transform: capitalize;
    }

    &--time {
      color: $neutral-grey-600;
      font-size: $font-size-small;
      line-height: 2.2rem;
      white-space: none;
      margin-bottom: $space-normal;
      margin-left: $space-micro;
    }

    &--caption {
      word-break: break-word;
      max-width: 100%;
      overflow: hidden;
      max-height: 60%;
      text-align: start;
      margin: $space-small;
      margin-bottom: $space-micro;
    }

    &--limiter {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &--button {
      position: absolute;
      right: $space-normal;
      bottom: $space-slab;
      font-size: $font-size-small;
      font-weight: $font-weight-medium;
      line-height: 2.2rem;
      align-items: center;
      float: right;
      text-transform: capitalize;
      color: $pg-1-500;
    }
  }
}

.instagram-color {
  background-clip: text;
  background-color: $insta-grad-3;
  background-image: $instagram-gradient;
  background-size: 100%;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.comment-color {
  color: $secondary-blue;
}
.ion-play {
  font-size: $font-size-giga;
  color: $color-white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.comment-timestamp {
  margin-left: $space-small;
}
.deleted-message-outline {
  border-color: $warn-red-500;
  border-radius: 0.8rem;
}
</style>
