<template>
  <div class="summary-content">
    <div>
      <!-- Personal Info -->
      <div v-if="personalInfo" class="title-h5_m text-dark mg-bottom">
        <template v-for="(value, key) in personalInfo">
          <div v-if="value" :key="key" class="summary-content-info">
            <template v-if="getIcon(key)">
              <icons :name="getIcon(key)" color="darkgrey" size="normal" />
              <span class="info-value body-b3" v-html="formatMessage(value)" />
            </template>
            <template v-else-if="value">
              <span
                class="info-key title-h5 capitalize"
                v-text="convertToTitleCase(key) + ': '"
              />
              <span class="info-value body-b3" v-html="formatMessage(value)" />
            </template>
          </div>
        </template>
      </div>
      <!-- Current Summary -->
      <div
        v-if="currentSummary.intent || currentSummary.text"
        class="text-light body-b2 mg-bottom--small"
      >
        Ticket Summary
      </div>
      <div
        v-if="currentSummary.intent"
        class="text-blue body-b2"
        v-html="`Intent: ${currentSummary.intent}`"
      />
      <div
        v-if="currentSummary.text"
        class="text-dark body-b2 mg-bottom"
        v-html="currentSummary.text"
      />
      <!-- Previous Summary -->
      <div
        v-if="previousSummary"
        class="text-light body-b2 mg-bottom--small"
        v-text="lastTicketSummaryHeader"
      />
      <div
        v-if="previousSummary"
        class="text-dark body-b2"
        v-html="previousSummary"
      />
    </div>
    <span class="meta flex-row flex-align gap--smaller">
      <icons
        name="eye"
        size="small"
        color="stroke-lightgrey"
        :custom-style="{ strokeWidth: 2 }"
      />
      <span class="text-light" v-text="$t('CONVERSATION.GPT.VISIBILITY')" />
    </span>
  </div>
</template>
<script>
import getConversation from 'dashboard/mixins/getConversation';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import { humanReadableDate } from 'dashboard/helper/time';
import { convertToTitleCase } from 'dashboard/helper/stringUtils';

const OPTIONS = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

export default {
  mixins: [getConversation, messageFormatterMixin],
  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      previousConversationDate: '',
    };
  },
  computed: {
    lastTicketSummaryHeader() {
      return this.previousConversationDate
        ? 'Last Ticket Summary | ' + this.previousConversationDate
        : 'Last Ticket Summary';
    },
    personalInfo() {
      return (
        this.message.content_attributes?.current_summary?.personal_info || null
      );
    },
    currentSummary() {
      const intent =
        this.message.content_attributes?.current_summary?.intent || '';
      const text = this.message.content || '';
      return {
        intent,
        text: this.formatMessage(text),
      };
    },
    previousSummary() {
      return (
        this.formatMessage(
          this.message.content_attributes?.previous_conversation?.summary
        ) || ''
      );
    },
  },
  created() {
    const prevConvId = this.message.content_attributes?.previous_conversation
      ?.id;

    if (prevConvId)
      this.getConversationById(prevConvId).then(chat => {
        let { created_at: createdAt } = chat;
        createdAt = new Date(createdAt);
        this.previousConversationDate = humanReadableDate(createdAt, OPTIONS);
      });
  },
  methods: {
    getIcon(key) {
      const mapKeyToIconName = {
        phone_number: 'phone',
        address: 'location',
        email: 'email',
      };
      return mapKeyToIconName[key];
    },
    convertToTitleCase,
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.summary {
  &-content {
    border-radius: $zero $zero $space-slab $space-slab;
    padding: $space-slab $space-slab $space-normal;
    position: relative;

    &-info {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: $space-micro;
      word-break: break-word;

      .info-key {
        white-space: nowrap;
      }

      .info-value {
        margin-top: 1px;
      }
    }

    .meta {
      position: absolute;
      bottom: $zero;
      right: $space-one;
    }
  }
}
</style>
