<template>
  <section>
    <form-section-header
      :heading="$t('CREATE_TICKET.FORM.SECTIONS.ADD_TEMPLATE.HEADING')"
      :sub-heading="$t('CREATE_TICKET.FORM.SECTIONS.ADD_TEMPLATE.SUB_HEADING')"
      icon="template"
    />
    <add-template
      :inbox-id="inboxId"
      :number-of-substitutions="numberOfSubstitutions"
      @selectedTemplate="$emit('selectedTemplate', $event)"
      @templateInputs="$emit('templateInputs', $event)"
    />
  </section>
</template>
<script>
import FormSectionHeader from '../shared/FormSectionHeader';
import AddTemplate from './addTemplate';

export default {
  components: { FormSectionHeader, AddTemplate },
  props: {
    numberOfSubstitutions: {
      type: Number,
      default: 0,
    },
    inboxId: {
      type: Number,
      default: 0,
    },
  },
};
</script>
