<template>
  <div>
    <div class="text-align mg-bottom--larger">
      <div class="flex-row flex-justify flex-align gap--small mg-bottom">
        <icons
          name="tickFilled"
          size="medium"
          color="green"
          :show-title="false"
        />
        <span
          class="title-h1 text-dark"
          v-html="$t('ONBOARDING.WHATSAPP.FINISH_SETUP.HEADING')"
        />
      </div>
      <span
        class="body-b2 text-light"
        v-html="$t('ONBOARDING.WHATSAPP.FINISH_SETUP.SUB_HEADING')"
      />
    </div>
    <div class="flex-row flex-justify--end">
      <woot-base-button @click="onCloseModal">
        {{ $t('ONBOARDING.WHATSAPP.FINISH_SETUP.EXPLORE_HELPDESK') }}
      </woot-base-button>
    </div>
  </div>
</template>
<script>
export default {
  inject: ['navigateTo', 'onCloseModal'],
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.content-margin {
  margin-bottom: $space-medium;
}

ol {
  > li {
    list-style-type: disc;
    margin-top: $space-smaller;
  }
}

.reference-box {
  max-width: 100%;
}

.navigation-button {
  width: $space-slab * 10;
}
</style>
