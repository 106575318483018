<template>
  <div class="message-text__wrap">
    <div class="message-image-container" @click="onClick">
      <img ref="image" class="message-image" :src="url" />
    </div>
    <span
      v-if="hasAttachedContent"
      class="message-span"
      :class="{
        'message-deleted': isDeleted,
      }"
      v-html="message"
    />
    <woot-modal :full-width="true" :show.sync="show" :on-close="onClose">
      <span class="download-button" @click="onDownload(url)">
        <icons name="download" color="green" size="medium" />
      </span>
      <img :src="url" class="modal-image" />
    </woot-modal>
  </div>
</template>

<script>
import fileMixin from 'shared/mixins/fileMixin';

export default {
  mixins: [fileMixin],
  props: {
    url: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: '',
    },
    hasAttachedContent: {
      type: Boolean,
      default: false,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    var refreshId = setInterval(() => {
      if (this.$refs.image) {
        let src = this.$refs.image.src;
        this.$refs.image.src = src;
        if (this.$refs.image.complete) {
          clearInterval(refreshId);
        }
      }
    }, 2000);
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.message-span ::v-deep p {
  margin: 0;
}

.modal-image {
  max-height: 100%;
}

.download-button {
  position: absolute;
  top: $space-normal + $space-micro;
  right: $space-jumbo;
  cursor: pointer;
}
.message-image-container {
  background: $chat-background;
  border-radius: $border-radius;
  cursor: pointer;
  max-height: 25rem;
  overflow: hidden;
  position: relative;
  margin-bottom: $space-slab;

  .message-image {
    display: block;
    margin: 0 auto;
    min-width: 10rem;
    min-height: 10rem;
    max-width: 25rem;
    width: 100%;
    object-fit: contain;
  }

  &::before {
    background-image: linear-gradient(
      -180deg,
      transparent 3%,
      $neutral-grey-800 130%
    );
    bottom: 0;
    content: '';
    height: 20%;
    left: 0;
    opacity: 0.4;
    position: absolute;
    width: 100%;
  }
}

.message-span {
  display: block;
  margin: $space-smaller;

  :after {
    content: '';
    padding-right: $space-jumbo;
    display: inline-block;
  }
}

.message-deleted ::v-deep p {
  color: $neutral-grey-600;
}
</style>
