<template>
  <div>
    <div class="account-switcher" @click="handleCompanyModal">
      <span class="text-truncate title-h4 mg-right--small text-green">
        {{ currentAccount ? currentAccount.name : 'N/A' }}
      </span>
      <div v-if="avatarUrl" class="company-logo-img">
        <img :src="avatarUrl" alt="logo" />
      </div>
      <div v-else>
        <avatar
          :username="currentAccount && currentAccount.name"
          :rounded="false"
          :colored="true"
          :size="30"
          :custom-style="{ cursor: 'pointer', fontSize: '1.8rem' }"
        />
      </div>
    </div>
    <client-logo-modal
      v-if="showCompanyModal"
      v-on-clickaway="hideCompanyModal"
      :current-account="currentAccount"
      :current-user="currentUser"
      :avatar-url="avatarUrl"
      @getLogoImg="getLogo"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import { mixpanelEventWithProps } from 'dashboard/helper/mixpanel';
import Avatar from '../widgets/Avatar.vue';
import ClientLogoModal from './ClientLogoModal';

export default {
  components: {
    Avatar,
    ClientLogoModal,
  },
  mixins: [clickaway],
  data() {
    return {
      showCompanyModal: false,
      avatarUrl: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      getAccount: 'accounts/getAccount',
    }),
    currentAccount() {
      return this.currentUser.accounts?.find(
        account => account.id === this.accountId
      );
    },
  },
  created() {
    this.getLogo();
  },
  methods: {
    async getLogo() {
      const { avatar_url } = this.getAccount(this.currentAccount.id);
      this.avatarUrl = avatar_url;
    },
    handleCompanyModal() {
      mixpanelEventWithProps('clicked_side_nav_menu_item', {
        item_name: 'COMPANY',
      });
      this.showCompanyModal = true;
    },
    hideCompanyModal() {
      this.showCompanyModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'dashboard/assets/scss/_variables.scss';
@import 'dashboard/assets/scss/_mixins.scss';

.account-switcher {
  max-width: 14.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: 1px solid $neutral-grey-400;
  border-radius: $space-smaller;
  padding: $space-smaller $space-slab;
}
.company-logo-img {
  @include elevation-1dp;
  height: 3rem;
  width: 3rem;
  border-radius: $border-radius;
  border: 1px solid $neutral-grey-500;
  cursor: pointer;
  background: $color-white;

  img {
    height: 100%;
    width: 100%;
    border-radius: $border-radius;
    object-fit: cover;
  }
}
</style>
