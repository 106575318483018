<template>
  <div class="column content-box settings-box no-padding">
    <woot-base-button
      size="medium"
      front-icon="plus"
      class="settings-button"
      icon-view-box="0 0 20 20"
      @click="openAddPopup"
    >
      {{ $t('TEMPLATE.HEADER_BTN_TXT') }}
    </woot-base-button>
    <div class="settings-container">
      <div class="column settings-main-menu-new">
        <view-template
          :actions-list="actionsList"
          @edit="openEditPopup"
          @delete="openDeletePopup"
          @select="template => (selectedTemplate = template)"
        />
      </div>

      <settings-side-card>
        <educational-onboarding
          v-if="!selectedTemplate.id"
          :show-case-data="SHOWCASE_TEMPLATES_DATA"
        >
          <reference-box
            class="mg-top--large"
            :to="documentation.templates"
            content="Know more about templates here"
            type="document"
          />
        </educational-onboarding>
        <rhs-panel
          v-else
          :key="selectedTemplate.id"
          :selected-template="selectedTemplate"
          @openEditModal="openEditPopup"
        >
        </rhs-panel>

        <div class="flex-column full-height flex-justify--end">
          <div class="action-btns flex-column gap--one">
            <woot-base-button
              full-width
              :loading="isFetching"
              @click="debouncedFetchTemplates"
            >
              {{ $t('TEMPLATE.IMPORT') }}
            </woot-base-button>
            <div class="flex-row gap--one">
              <woot-base-button
                v-if="templateList.length"
                variant="secondary"
                full-width
                @click="showExportTemplateModal = true"
              >
                {{ $t('TEMPLATE.EXPORT') }}
              </woot-base-button>
              <woot-base-button
                variant="secondary"
                full-width
                @click="showImportTemplateModal = true"
              >
                {{ $t('CANNED_MGMT.IMPORT_CSV') }}
              </woot-base-button>
            </div>
          </div>
        </div>
      </settings-side-card>
    </div>

    <!-- import csv -->
    <import-template
      v-if="showImportTemplateModal"
      @onClose="showImportTemplateModal = false"
    />

    <!-- export csv -->
    <export-template
      v-if="showExportTemplateModal"
      @onClose="showExportTemplateModal = false"
    />

    <!-- Add Agent -->
    <transition name="modal-fade">
      <add-template v-if="showAddPopup" :on-close="hideAddPopup" />
    </transition>

    <!-- Edit Template Message -->
    <transition name="modal-fade">
      <edit-template
        v-if="showEditPopup"
        :selected-template="selectedTemplate"
        :on-close="hideEditPopup"
      />
    </transition>

    <!-- Delete Template Message -->
    <woot-delete-modal-new
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('TEMPLATE.DELETE.CONFIRM.TITLE')"
      :message="deleteMessage"
      confirm-text="Continue"
      reject-text="Back"
      :custom-style="{
        'max-width': '35.2rem',
        height: 'auto',
      }"
    />
  </div>
</template>
<script>
import AddTemplate from './AddTemplate';
import EditTemplate from './EditTemplate';
import SettingsSideCard from '../../SettingSideCard';
import ViewTemplate from './ViewTemplate';
import ImportTemplate from './ImportTemplate';
import ExportTemplate from './ExportTemplate';
import EducationalOnboarding from 'dashboard/components/ui/settings/EducationalOnboarding';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';
import RhsPanel from 'dashboard/components/ui/settings/templates/RhsPanel';

import broadcastMixin from 'dashboard/mixins/broadcast';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import downloadMixin from 'shared/mixins/downloadMixin';
import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import * as types from 'shared/constants/googleEventType';
import { SHOWCASE_TEMPLATES_DATA } from './constants';
import documentation from 'dashboard/helper/data/documentationData.json';
import { mapGetters } from 'vuex';

export default {
  name: 'TemplateHome',
  components: {
    AddTemplate,
    EditTemplate,
    SettingsSideCard,
    ViewTemplate,
    ImportTemplate,
    ExportTemplate,
    EducationalOnboarding,
    RhsPanel,
    ReferenceBox,
  },
  mixins: [
    googleAnalyticsMixin,
    broadcastMixin,
    downloadMixin,
    alertMixin,
    mixPanelAnalyticsMixin,
  ],
  data() {
    return {
      showAddPopup: false,
      showEditPopup: false,
      isFetching: false,
      showImportTemplateModal: false,
      showExportTemplateModal: false,
      showDeleteConfirmationPopup: false,
      selectedTemplate: {},
      actionsList: [
        { id: 0, name: 'edit', icon: 'editRound' },
        { id: 1, name: 'delete', icon: 'delete' },
      ],
      SHOWCASE_TEMPLATES_DATA,
      documentation,
    };
  },
  computed: {
    ...mapGetters({
      templateList: 'getTemplates',
    }),
    deleteMessage() {
      return `${this.$t('TEMPLATE.DELETE.CONFIRM.MESSAGE')} ${
        this.selectedTemplate.short_code
      } ?`;
    },
  },
  methods: {
    openAddPopup() {
      this.googleAnalyticsEvent(
        types.default.TEMPLATE_OPEN_ADD_POPUP,
        types.default.TEMPLATE_SETTINGS,
        types.default.TEMPLATE_SETTINGS
      );
      this.showAddPopup = true;
      this.mix_panel_clicked_settings_add_template();
    },
    hideAddPopup() {
      this.googleAnalyticsEvent(
        types.default.TEMPLATE_HIDE_ADD_POPUP,
        types.default.TEMPLATE_SETTINGS,
        types.default.TEMPLATE_SETTINGS
      );
      this.showAddPopup = false;
    },
    // Edit Modal Functions
    openEditPopup(response) {
      this.googleAnalyticsEvent(
        types.default.TEMPLATE_OPEN_EDIT_POPUP,
        types.default.TEMPLATE_SETTINGS,
        types.default.TEMPLATE_SETTINGS
      );
      this.showEditPopup = true;
      this.selectedTemplate = response;
      this.mix_panel_clicked_settings_edit_template();
    },
    hideEditPopup() {
      this.googleAnalyticsEvent(
        types.default.TEMPLATE_HIDE_EDIT_POPUP,
        types.default.TEMPLATE_SETTINGS,
        types.default.TEMPLATE_SETTINGS
      );
      this.showEditPopup = false;
    },
    openDeletePopup() {
      this.googleAnalyticsEvent(
        types.default.TEMPLATE_OPEN_DELETE_POPUP,
        types.default.TEMPLATE_SETTINGS,
        types.default.TEMPLATE_SETTINGS
      );
      this.showDeleteConfirmationPopup = true;
    },
    closeDeletePopup() {
      this.googleAnalyticsEvent(
        types.default.TEMPLATE_CLOSE_DELETE_POPUP,
        types.default.TEMPLATE_SETTINGS,
        types.default.TEMPLATE_SETTINGS
      );
      this.showDeleteConfirmationPopup = false;
    },
    // Set loading and call Delete API
    confirmDeletion() {
      this.closeDeletePopup();
      this.deleteTemplate(this.selectedTemplate.id);
    },
    deleteTemplate(id) {
      this.googleAnalyticsEvent(
        types.default.TEMPLATE_DELETE,
        types.default.TEMPLATE_SETTINGS,
        types.default.TEMPLATE_SETTINGS
      );
      this.$store
        .dispatch('deleteTemplate', id)
        .then(() => {
          this.showAlert(
            this.$t('TEMPLATE.DELETE.API.SUCCESS_MESSAGE'),
            'success'
          );
          this.mix_panel_deleted_settings_template();
        })
        .catch(() => {
          this.showAlert(this.$t('TEMPLATE.DELETE.API.ERROR_MESSAGE'), 'error');
        });
    },
    fetchTemplates() {
      this.$store
        .dispatch('fetchTemplates')
        .then(response => {
          if (response.status === 202) {
            this.showAlert(
              this.$t('TEMPLATE.SYNC_API.IN_PROGRESS_MESSAGE'),
              'info'
            );
          } else {
            this.showAlert(
              this.$t('TEMPLATE.SYNC_API.SUCCESS_MESSAGE'),
              'success'
            );
          }
          this.mix_panel_clicked_settings_sync_template();
        })
        .catch(() => {
          this.showAlert(this.$t('TEMPLATE.SYNC_API.ERROR_MESSAGE'), 'error');
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    debouncedFetchTemplates() {
      this.isFetching = true;

      setTimeout(() => {
        this.fetchTemplates();
      }, 3000);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.no-padding {
  padding-left: 0.1rem;
}

.settings-box {
  padding-top: $space-largest;
  overflow: hidden;

  .settings-container {
    display: flex;
    height: 100%;
    flex-direction: row;

    .settings-side-card {
      padding: $zero;
    }
  }
}

.action-btns {
  border-top: 2px solid $neutral-grey-200;
  padding: $space-two;
}
</style>
