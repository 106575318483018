import * as types from 'dashboard/store/mutation-types';
import SlaApi from 'dashboard/api/sla';

export default {
  async createSLA({ commit }, data) {
    commit(types.default.SET_SLA_UI_FLAGS, { isCreating: true });
    try {
      const response = await SlaApi.create(data);
      commit(types.default.SET_SLA_UI_FLAGS, { isCreating: false });
      commit(types.default.ADD_SLA, response.data);
    } catch (error) {
      commit(types.default.SET_SLA_UI_FLAGS, { isCreating: false });
      throw error;
    }
  },
  async setSLAsOrder({ dispatch }, data) {
    try {
      await SlaApi.updateOrder(data.map(sla => sla.id));
      dispatch('setSLAs', data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async getSLAs({ commit, dispatch }) {
    commit(types.default.SET_SLA_UI_FLAGS, { isFetching: true });
    try {
      const response = await SlaApi.get();
      dispatch('setSLAs', response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_SLA_UI_FLAGS, { isFetching: false });
    }
  },
  async editSLA({ commit }, { id, ...data }) {
    commit(types.default.SET_SLA_UI_FLAGS, { isSaving: true });
    try {
      const response = await SlaApi.update(id, data);
      commit(types.default.SET_SLA_UI_FLAGS, { isSaving: false });
      commit(types.default.UPDATE_SLA, response.data);
    } catch (error) {
      commit(types.default.SET_SLA_UI_FLAGS, { isSaving: false });
      throw error;
    }
  },
  async deleteSLA({ commit }, id) {
    commit(types.default.SET_SLA_UI_FLAGS, { isDeleting: true });
    try {
      await SlaApi.delete(id);
      commit(types.default.REMOVE_SLA, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_SLA_UI_FLAGS, { isDeleting: false });
    }
  },
  async showSLA({ commit }, id) {
    commit(types.default.SET_SLA_UI_FLAGS, { isFetching: true });
    try {
      const response = await SlaApi.show(id);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_SLA_UI_FLAGS, { isFetching: false });
    }
  },
  setSLAs({ commit }, data) {
    commit(types.default.SET_SLAS, data);
  },
};
