/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';

class CrmApi extends ApiClient {
  constructor() {
    super('integrations', { accountScoped: true });
  }

  createTicket({ selectedCrm, ...rest }) {
    return axios.post(`${this.url}/crm/${selectedCrm}/crm_tickets`, {
      integration_name: selectedCrm,
      ...rest,
    });
  }

  fetchTickets({ selectedCrm, email, phoneNumber, conversationId }) {
    return axios.get(`${this.url}/crm/${selectedCrm}/crm_tickets`, {
      params: {
        integration_name: selectedCrm,
        email: email,
        phone: phoneNumber,
        conversation_id: conversationId,
      },
    });
  }

  deleteTicket({ selectedCrm, id }) {
    return axios.delete(`${this.url}/crm/${selectedCrm}/crm_tickets/${id}`);
  }

  updateTicket(data) {
    data.integration_name = data.selectedCrm;
    return axios.put(
      `${this.url}/crm/${data.selectedCrm}/crm_tickets/${data.id}`,
      data
    );
  }

  fetchTicket({ selectedCrm, id }) {
    return axios.get(`${this.url}/crm/${selectedCrm}/crm_tickets/${id}`);
  }

  crmConnection(data) {
    if (data.update) {
      return axios.get(`${this.url}/crm/connect_crm`, {
        params: { widget_id: data.crmWidgetId },
      });
    }
    return axios.get(`${this.url}/crm/connect_crm`, {
      params: { get: true },
    });
  }

  createLimekitCrmConnection(data) {
    return axios.post(`${this.url}/crm`, {
      integration_name: data.integration_name,
      integration_data: data.integration_data,
    });
  }

  fetchLimekitCrmConnection(data) {
    return axios.get(`${this.url}/crm`, {
      params: { integration_name: data.integration_name },
    });
  }

  deleteLimekitCrmConnection({ integration_name }) {
    return axios.delete(`${this.url}/crm/` + integration_name, {
      params: { integration_name: integration_name },
    });
  }

  getEnabledCrms() {
    return axios.get(`${this.url}/crm`);
  }

  getAgentNames({ selectedCrm }) {
    return axios.get(`${this.url}/crm/get_agent_names`, {
      params: { selected_crm: selectedCrm },
    });
  }

  getDepartmentNames({ selectedCrm }) {
    return axios.get(`${this.url}/crm/${selectedCrm}/departments`);
  }

  getTicketFields({ selectedCrm }) {
    return axios.get(`${this.url}/crm/get_ticket_fields`, {
      params: { selected_crm: selectedCrm },
    });
  }

  getFreshdeskWidgetId() {
    return axios.get(`${this.url}/crm/freshdesk/get_freshdesk_widget_id`);
  }

  getProjectsAndIssueTypes() {
    return axios.get(`${this.url}/crm/jira/fetch_projects_and_issue_types`);
  }

  getFormSchema(selectedCrm, params = undefined) {
    return axios.get(`${this.url}/crm/${selectedCrm}/crm_tickets/schema`, {
      params,
    });
  }
}

export default new CrmApi();
