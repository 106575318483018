<template>
  <loading-state
    v-if="fetchingProjects"
    style="height: 20rem"
    :message="$t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.PROJECTS.LOADING')"
  />
  <form v-else @submit.prevent="onSubmit">
    <!-- Project Selector Section -->
    <label class="formlabel">
      <span
        class="title-h5 text-dark"
        v-text="$t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.PROJECTS.TITLE')"
      />
    </label>
    <woot-single-selector
      v-if="selectedProject"
      class="content-margin"
      :default-option="selectedProject.name"
      :option-list="projectOptions"
      :custom-style="{ left: 0, top: 0, width: '100%' }"
      @click="onProjectSelect"
    />
    <div
      v-else
      class="body-b3 text-light content-margin"
      v-text="
        $t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.PROJECTS.ZERO_STATE')
      "
    />

    <!-- Issue Type Selector Section -->
    <label class="formlabel">
      <span
        class="title-h5 text-dark"
        v-text="
          $t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.ISSUE_TYPES.TITLE')
        "
      />
    </label>
    <woot-single-selector
      v-if="selectedIssueType"
      class="content-margin"
      :default-option="selectedIssueType.name"
      :option-list="issueTypeOptions"
      :custom-style="{ left: 0, top: 0, width: '100%' }"
      @click="onIssueSelect"
    />
    <div
      v-else
      class="body-b3 text-light content-margin"
      v-text="
        $t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.ISSUE_TYPES.ZERO_STATE')
      "
    />
    <!-- Dynamic Form Fields -->
    <template v-if="!fetchingSchema">
      <div
        v-for="fieldName in formFieldNames"
        :key="fieldName"
        class="content-margin"
      >
        <data-type-input
          :label="schema[fieldName].name"
          :data-type="schema[fieldName].data_type"
          :options="schema[fieldName].allowed_values"
          :value="formData[fieldName]"
          :validator="$v.formData[fieldName]"
          :update-value="newValue => updateFormData(fieldName, newValue)"
        />
      </div>

      <!-- Submission Button -->
      <div class="full-width flex-row flex-justify">
        <woot-base-button
          type="submit"
          :loading="uiFlags.creatingTicket"
          :disabled="$v.formData.$invalid"
        >
          Submit
        </woot-base-button>
      </div>
    </template>
    <loading-state v-else style="height: 8rem" width="80" message="" />
  </form>
</template>
<script>
import { mapGetters } from 'vuex';

import DataTypeInput from 'dashboard/components/forms/DataTypeInput';
import LoadingState from 'dashboard/components/ui/LoadingState';

import {
  initializeFormValues,
  initializeValidations,
} from 'dashboard/components/forms/utils/helper';
import { AVAILABLE_CRMS } from 'dashboard/routes/dashboard/conversation/overview/constants';

import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: { DataTypeInput, LoadingState },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      formData: null,
      selectedProject: null,
      selectedIssueType: null,
      fetchingProjects: false,
      fetchingSchema: false,
    };
  },
  computed: {
    ...mapGetters({
      projects: 'crm/getProjectsFromJira',
      uiFlags: 'crm/getUIFlags',
      currentChat: 'getSelectedChat',
    }),
    schema() {
      return this.$store.getters['crm/getFormSchema'](AVAILABLE_CRMS.JIRA);
    },
    conversationId() {
      return this.currentChat.id;
    },
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    formFieldNames() {
      return Object.keys(this.schema);
    },
    projectOptions() {
      return this.projects.map(({ id, name, key }) => ({ id, name, key }));
    },
    issueTypeOptions() {
      return this.selectedProject
        ? this.projects
            .find(proj => proj.id === this.selectedProject.id)
            ?.issuetypes.map(issue => ({ id: issue.id, name: issue.name }))
        : [];
    },
  },
  created() {
    this.loadInitialData();
  },
  validations() {
    return { formData: initializeValidations(this.schema) };
  },
  inject: ['closeRaiseTicketSection'],
  methods: {
    onProjectSelect(selectedProject) {
      this.selectedProject = selectedProject;
      this.fetchSchema();
    },
    onIssueSelect(selectedIssue) {
      this.selectedIssueType = selectedIssue;
      this.fetchSchema();
    },
    updateFormData(fieldName, newValue) {
      this.$v.formData[fieldName].$model = newValue;
    },
    async loadInitialData() {
      try {
        this.fetchingProjects = true;
        await this.$store.dispatch('crm/fetchProjectsAndIssueTypes');
        [this.selectedProject] = this.projectOptions;
        [this.selectedIssueType] = this.issueTypeOptions;
        await this.fetchSchema();
      } catch (error) {
        this.showAlert(
          this.$t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.COMMON.ERROR'),
          'error'
        );
      } finally {
        this.fetchingProjects = false;
      }
    },
    async fetchSchema() {
      try {
        this.fetchingSchema = true;
        await this.$store.dispatch('crm/fetchFormSchemaForJira', {
          selectedCrm: AVAILABLE_CRMS.JIRA,
          projectKey: this.selectedProject.key,
          issueType: this.selectedIssueType.name,
        });
        this.formData = initializeFormValues(this.schema);
      } catch (error) {
        this.showAlert(
          this.$t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.COMMON.ERROR'),
          'error'
        );
      } finally {
        this.fetchingSchema = false;
      }
    },
    isFormValid() {
      this.$v.formData.$touch();
      if (this.$v.formData.$invalid) {
        this.showAlert(
          this.$t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.FORM.ERROR'),
          'error'
        );
        return false;
      }
      return true;
    },
    constructPayload() {
      const { summary, description, ...customFields } = this.formData;
      const {
        id: contactId,
        name: contactName,
        email: contactEmail,
        phone_number: contactPhone,
      } = this.contact;

      const contactInboxId = this.currentChat.inbox_id;

      return {
        summary,
        description,
        project_key: this.selectedProject.key,
        issue_type: this.selectedIssueType.name,
        selectedCrm: AVAILABLE_CRMS.JIRA,
        conversation: {
          id: this.conversationId,
          public_url: 'https://app.limechat.ai/',
        },
        contact: {
          id: contactId,
          inbox_id: contactInboxId,
          name: contactName,
          email: contactEmail,
          phone_number: contactPhone,
        },
        custom_fields: customFields,
      };
    },
    onSubmit() {
      if (!this.isFormValid()) return;

      const payload = this.constructPayload();
      this.createTicket(payload);
    },
    async createTicket(payload) {
      try {
        await this.$store.dispatch('crm/raiseTicket', payload);
        this.mix_panel_added_crm_ticket();
        this.closeRaiseTicketSection();
        this.showAlert(
          this.$t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.CREATE.SUCCESS'),
          'info'
        );
      } catch (error) {
        this.showAlert(
          this.$t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.CREATE.ERROR'),
          'error'
        );
      }
    },
  },
};
</script>
