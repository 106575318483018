<template>
  <div :id="id" class="flex-container actions--container">
    <div class="action--button" :style="customButtonStyle">
      <span
        class="primary-button"
        :class="[size, `button-${size}`]"
        @click="buttonClick"
      >
        <span v-if="frontIcon" style="margin-right: 0.8rem">
          <icons :name="frontIcon" color="white" :size="iconSize" />
        </span>
        {{ name }}
      </span>
      <button
        :class="`toggle-${size}`"
        class="clear more--button"
        @click="toggleDropdown"
      >
        <icons :name="toggleIcon" :size="iconSize" color="white" />
      </button>
    </div>
    <ul
      v-if="showDropdown"
      v-on-clickaway="hideDropdown"
      class="dropdown--pane custom-scroll"
      :style="[customStyle, modalPosition]"
      :class="[{ 'dropdown--pane--open': showDropdown }, customClass]"
    >
      <li v-for="option in optionList" :key="option.id">
        <button
          v-if="!isSelected(option) || showAll"
          class="button dropdown-button"
          type="button"
          :class="{
            'dropdown-button--selected':
              (isSelected(option) && disabled) || option.selected,
            'dropdown-button--disabled': option.disabled,
          }"
          @click="onClick(option)"
        >
          <span v-if="option.icon" style="margin-right: 8px">
            <icons :name="option.icon" size="semimedium" color="grey" />
          </span>
          <span class="text-truncate">{{ option.name }}</span>
        </button>
      </li>
      <li v-if="!optionList.length">
        <button class="button action--button">
          <span>{{ 'No options available' }}</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin, clickaway],
  props: {
    id: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
    },
    frontIcon: {
      type: String,
      default: '',
    },
    isInverted: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    selected: {
      type: String,
      default: '',
    },
    optionList: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: 'chevronDown',
    },
    iconSize: {
      type: String,
      default: 'semimedium',
    },
    iconColor: {
      type: String,
      default: 'green',
    },
    label: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    customClass: { type: String, default: '' },
    customStyle: {
      type: Object,
      default: undefined,
    },
    customButtonStyle: {
      type: Object,
      default: undefined,
    },
    hideOnSelect: {
      type: Boolean,
      default: true,
    },
    buttonClick: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    toggleIcon() {
      if (this.isInverted) return 'chevronTop';
      return 'chevronDown';
    },
    modalPosition() {
      if (this.isInverted)
        return {
          position: 'absolute',
          top: '-12rem',
          width: 'auto',
          overflow: 'auto',
          'z-index': 100,
          'border-radius': '16px',
          'border-bottom-left-radius': '0px',
          'border-bottom-right-radius': '0px',
        };
      return '';
    },
  },

  mounted() {
    bus.$on('dropdown_pane', this.hideDropdown);
  },

  methods: {
    isSelected(option) {
      return this.selected === option.name;
    },
    onClick(val) {
      this.$emit('click', val);
      if (this.hideOnSelect) {
        this.toggleDropdown();
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      this.$emit('dropdown-toggled', this.showDropdown);
    },
    hideDropdown() {
      this.showDropdown = false;
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/mixins';
@import '~dashboard/assets/scss/variables';
.primary-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: $border-radius-smaller;
  border-bottom-left-radius: $border-radius-smaller;
  background: $pg-1-500;
  cursor: pointer;
  white-space: nowrap;
  color: $color-white;
  &:focus {
    background: $pg-1-700;
  }

  &:hover {
    background: $pg-1-600;
  }
  &:disabled {
    background: $neutral-grey-600;
    cursor: not-allowed;
  }
}

.small {
  padding: $space-smaller $space-small;
}
.medium {
  padding: $space-small $space-slab;
}

.large {
  padding: $space-small $space-slab;
}

.actions--container {
  position: relative;

  .action--button {
    display: flex;
    width: 100%;

    .more--button {
      align-items: center;
      display: flex;
      overflow: hidden;
      cursor: pointer;
      background: $pg-1-600;
      border-top-right-radius: $border-radius-smaller;
      border-bottom-right-radius: $border-radius-smaller;
      &:focus {
        box-shadow: none;
        outline: none;
      }

      .button-text {
        color: $neutral-grey-600;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: $space-small;
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        text-transform: capitalize;
        line-height: 3.8rem;
      }
    }
  }

  .toggle-small {
    padding: $space-smaller;
  }
  .toggle-medium {
    padding: $space-small;
  }
  .dropdown--pane {
    @include shadow-medium;
    padding: 0;
    position: absolute;
    z-index: 10;
    max-height: 30rem;
    border-radius: $border-radius * 2;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 0;
    top: $space-four;
    width: 100%;
    background: $neutral-white;
    border: 1px solid $neutral-grey-400;

    &:last-child {
      .dropdown-button--selected {
        margin-bottom: $zero;
      }
    }

    .dropdown-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      white-space: nowrap;
      padding: $space-small $space-slab;
      color: $neutral-grey-800;
      font-size: $font-size-small;
      font-weight: $font-weight-normal;
      line-height: 2.2rem;
      background-color: transparent;
      border-color: transparent;
      border: 0;
      text-transform: capitalize;

      &::first-letter {
        text-transform: uppercase;
      }

      &:hover {
        background: $neutral-grey-300;
      }

      &--selected {
        background: $pg-1-500;
        color: $neutral-white;
        pointer-events: none;
        margin-bottom: 0.1rem;
      }

      &--disabled {
        background: $neutral-white;
        opacity: 0.4;
        color: $neutral-grey-800;
        pointer-events: none;
      }
    }
  }

  .dropdown--pane--open {
    display: block;
    visibility: visible;
  }
}

button:disabled,
button[disabled] {
  background: $neutral-grey-300;
  cursor: default;
  color: $neutral-grey-600;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li:last-child {
  .button {
    margin-bottom: $zero !important;
  }
}
</style>
