<template>
  <div class="actions--container-new" :style="dropdownContainerStyle">
    <div
      v-if="!customButton && !showSearch"
      class="action--button-new flex-space-between"
      :class="[cssClassName, id, customClass, { pressed: showDropdown }]"
      :style="[buttonStyle, highlightButtonStyle]"
      @click="toggleDropdown"
    >
      <span v-if="frontIcon" style="margin-right: 0.2rem">
        <icons
          :name="frontIcon"
          :color="highlightSelected ? 'green' : frontIconColor"
          :size="iconSize"
        />
      </span>
      <span
        v-if="showOptionName"
        :style="[optionStyle, highlightStyle]"
        :title="defaultOption.name || defaultOption"
        class="selected-option title-h6_m text-truncate"
        v-html="staticTitle || defaultOption.name || defaultOption"
      />
      <span v-if="icon" :class="{ 'mg-left--small': showOptionName }">
        <icons
          :name="icon"
          :size="iconSize"
          :color="iconColor"
          :show-title="false"
        />
      </span>
    </div>
    <div
      v-else
      class="action--button-new flex-space-between"
      :class="id"
      @click="toggleDropdown"
    >
      <slot />
    </div>
    <div v-if="showSearch" @click="toggleDropdown">
      <woot-input
        v-model="search"
        :size="size"
        type="text"
        :placeholder="searchPlaceholder"
      />
    </div>
    <transition name="dropdown">
      <ul
        v-if="showDropdown"
        :id="id"
        :style="[customStyle, dropDownPanePosition]"
        class="dropdown-list dropdown--pane-new custom-scroll"
        :class="{ 'dropdown--pane-new--open': showDropdown }"
      >
        <li
          v-for="option in formattedOptions"
          :key="option.id"
          v-tooltip="showOptionTooltip && option[defaultKey]"
        >
          <button
            v-if="!isSelected(option) || showAll"
            class="dropdown-button-new"
            type="button"
            :class="{
              'dropdown-button-new--selected':
                (isSelected(option) && !disabled) || option.selected,
              'dropdown-button-new--disabled': option.disabled,
            }"
            @click="e => onClick(option, e)"
          >
            <span v-if="optionIcon" style="margin-right: 0.4rem">
              <icons
                :name="optionIcon"
                :size="iconSize"
                :color="isSelected(option) ? 'white' : 'darkestgrey'"
              />
            </span>
            <span v-if="option.icon" style="margin-right: 0.4rem">
              <icons
                :name="option.icon"
                size="normal"
                :color="
                  isSelected(option) ? 'white' : `${option.color} evenodd`
                "
              />
            </span>
            <span
              v-if="option.availability"
              :class="
                `availability-status availability-status--${option.availability}`
              "
            >
            </span>
            <span class="option-name title-h6_m text-truncate">
              {{ option[defaultKey] }}
            </span>
            <span v-if="showSelectedText" class="title-h6_m">
              <span v-if="isSelected(option)">Selected</span>
              <span v-else class="show-selected">Select</span>
            </span>
          </button>
        </li>
        <li v-if="!formattedOptions.length">
          <button class="button action--button-new no-border-radius">
            <span>{{ zeroState }}</span>
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import clickOutsideMixin from 'dashboard/mixins/clickOutsideMixin';

export default {
  mixins: [clickOutsideMixin],
  props: {
    customButton: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'secondary',
      validator: val => ['primary', 'secondary', 'tertiary'].includes(val),
    },
    size: {
      type: String,
      default: 'medium',
      validator: val => ['small', 'medium', 'large'].includes(val),
    },
    staticTitle: {
      type: String,
      default: '',
    },
    defaultOption: {
      type: [String, Object],
      default: '',
    },
    optionList: {
      type: Array,
      default: () => [],
    },
    frontIcon: {
      type: String,
      default: '',
    },
    optionIcon: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'chevronDown',
    },
    showAll: {
      type: Boolean,
      default: true,
    },
    showOptionTooltip: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideOnSelect: {
      type: Boolean,
      default: true,
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
    buttonStyle: {
      type: Object,
      default: undefined,
    },
    dropdownContainerStyle: {
      type: Object,
      default: undefined,
    },
    topPosition: {
      type: Object,
      default: undefined,
    },
    showSelectedText: {
      type: Boolean,
      default: true,
    },
    showOptionName: {
      type: Boolean,
      default: true,
    },
    defaultKey: {
      type: String,
      default: 'name',
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search Here',
    },
    id: {
      type: String,
      default: '',
      required: false,
    },
    highlightSelected: {
      type: Boolean,
      default: false,
    },
    isDropdownShown: {
      type: Boolean,
      default: false,
    },
    iconSizeProp: {
      type: String,
      default: '',
    },
    zeroState: {
      type: String,
      default: 'No options available',
    },
    customClass: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      showDropdown: this.isDropdownShown,
      showSearch: false,
      search: '',
    };
  },
  computed: {
    frontIconColor() {
      if (this.variant === 'primary') return 'white';
      if (this.variant === 'secondary') return 'textlightgrey';
      return 'darkestgrey';
    },
    iconColor() {
      if (this.variant === 'primary') return 'white';
      return 'grey';
    },
    iconSize() {
      if (this.iconSizeProp) return this.iconSizeProp;
      if (this.variant === 'primary' || this.variant === 'tertiary') {
        if (this.size === 'large') return 'medium';
      }
      return 'semimedium';
    },
    cssClassName() {
      if (this.variant === 'primary') {
        return `${this.variant}-dropdown ${this.size}-dropdown`;
      }
      return `${this.variant}-dropdown ${this.variant}-${this.size}`;
    },
    dropDownPanePosition() {
      if (this.topPosition) return this.topPosition;
      if (this.size === 'small') return { top: '3.4rem' };
      if (this.size === 'medium') return { top: '4.4rem' };
      if (this.size === 'large') return { top: '4.4rem' };
      return { top: '4.4rem' };
    },
    formattedOptions() {
      let options = [];

      options = this.optionList.filter(item => {
        return (
          item?.[this.defaultKey]
            ?.toLowerCase()
            .search(this.search?.toLowerCase()) > -1
        );
      });
      if (this.enableSearch) {
        return options;
      }
      return this.optionList;
    },
    optionStyle() {
      if (this.size === 'large' && this.variant === 'tertiary') {
        return {
          'text-transform': 'uppercase',
          'margin-left': '0.4rem',

          'max-width': '15rem',
          overflow: 'hidden',
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap',
        };
      }
      if (this.size === 'secondary-large') {
        return { 'margin-right': '5rem' };
      }
      return { 'margin-right': 'auto' };
    },
    highlightStyle() {
      if (this.highlightSelected) {
        return { color: '#6bac1b' };
      }
      return '';
    },
    highlightButtonStyle() {
      if (this.highlightSelected) {
        return { 'border-color': '#6bac1b' };
      }
      return '';
    },
  },
  methods: {
    isSelected(option) {
      switch (typeof this.defaultOption) {
        case 'string':
          return (
            this.defaultOption?.toLowerCase() ===
            option[this.defaultKey].toLowerCase()
          );

        case 'object':
          return this.defaultOption.id === option.id;

        default:
          return false;
      }
    },
    onClick(val, e) {
      this.$emit('click', val, e);
      if (this.hideOnSelect) {
        this.toggleDropdown();
      }
      this.showSearch = false;
    },
    toggleDropdown() {
      this.$emit('dropdown-opened');
      if (this.enableSearch) {
        this.showSearch = true;

        this.search = '';
      }
      this.showDropdown = !this.showDropdown;

      if (this.showDropdown) this.addClickOutsideListener();
      else this.removeClickOutsideListener();
    },
    hideDropdown() {
      this.showDropdown = false;
      this.showSearch = false;
    },
  },
};
</script>
