<template>
  <div class="text-align">
    <div class="mg-bottom--large">
      <h1
        class="title-h1 text-dark"
        v-html="$t('ONBOARDING.WELCOME.HEADING')"
      />
      <span
        class="body-b2 text-light"
        v-html="$t('ONBOARDING.WELCOME.SUB_HEADING')"
      />
    </div>
    <div>
      <woot-base-button
        class="navigation-button mg-auto"
        @click="onStartOnboarding"
      >
        {{ $t('ONBOARDING.WELCOME.START') }}
      </woot-base-button>
    </div>
  </div>
</template>
<script>
import { ONBOARDING_SCREENS } from 'dashboard/constants';

export default {
  inject: ['navigateTo'],
  methods: {
    onStartOnboarding() {
      this.navigateTo(ONBOARDING_SCREENS.EDUCATE_USER.key);
      localStorage.removeItem('firstLaunch');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.navigation-button {
  width: $space-slab * 10;
}
</style>
