import * as types from '../mutation-types';

const state = {
  enabledLogistics: [],
};

export const getters = {
  getEnabledLogistics($state) {
    return $state.enabledLogistics;
  },
};

export const actions = {
  setEnabledLogistics: ({ commit }, data) => {
    commit(types.default.SET_ENABLED_LOGISTICS, data);
  },
};

export const mutations = {
  [types.default.SET_ENABLED_LOGISTICS]($state, enabledLogistics) {
    $state.enabledLogistics = [...enabledLogistics];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
