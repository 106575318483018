<template>
  <div class="flex-row flex-align gap--small">
    <label :for="label" class="radio-container">
      <span class="title-h4 text-dark" v-text="label" />
      <input
        :id="label"
        :value="isActive"
        type="radio"
        :checked="isActive"
        @input="$emit('toggle')"
      />
      <span class="radio-circle" />
    </label>
    <span v-if="infoText" v-tooltip="infoText">
      <icons name="info" size="small" color="darkgrey" />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    label: { type: String, default: '' },
    infoText: { type: String, default: '' },
    isActive: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss" scoped>
.radio-container {
  margin-right: 0;
  cursor: pointer;
}
</style>
