<template>
  <div class="active-notification">
    <span class="notifications" @click.stop="showNotification">
      <icons
        name="notification"
        color="white"
        size="mediumlarge"
        :show-title="false"
      />
      <span
        v-if="unreadCount"
        :key="unreadCount"
        class="unread-badge zoom-in-out"
      >
        {{ unreadCount }}
      </span>
    </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  mixins: [mixPanelAnalyticsMixin],
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      notificationMetadata: 'notifications/getMeta',
    }),
    unreadCount() {
      if (!this.notificationMetadata.unreadCount) {
        return 0;
      }

      return this.notificationMetadata.unreadCount < 100
        ? this.notificationMetadata.unreadCount
        : '99+';
    },
  },
  created() {
    this.$store.dispatch('notifications/unreadCount');
  },
  methods: {
    showNotification() {
      this.mix_panel_clicked_side_nav_menu_item('NOTIFICATIONS');
      this.$router.push(`/app/accounts/${this.accountId}/notifications`);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.notifications {
  font-size: $font-size-big;
  margin: $zero auto;
  position: relative;
  cursor: pointer;

  .unread-badge {
    background: $warn-red-500;
    border-radius: $space-small;
    color: $color-white;
    font-size: $font-size-micro;
    font-weight: $font-weight-black;
    left: $space-normal;
    padding: $zero $space-smaller;
    position: absolute;
    top: $zero;
  }
}

.active-notification {
  border-radius: $border-radius;
  transition: all 0.3s;
}
</style>
