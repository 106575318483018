<template>
  <div class="column content-box settings-wrapper">
    <automation-form
      v-if="!templateId"
      :selected-response="selectedTemplate"
      :mode="selectedMode"
      @saveAutomation="saveAutomation"
    />
    <automation-form
      v-else-if="!uiFlags.isFetchingTemplates && areTemplatesFetched"
      :selected-response="selectedTemplate"
      :mode="selectedMode"
      @saveAutomation="saveAutomation"
    />
    <loading-state v-else style="height: 100%" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import LoadingState from 'dashboard/components/ui/LoadingState';
import AutomationForm from '../components/form/AutomationForm';

import alertMixin from 'shared/mixins/alertMixin';
import mixPanelMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import accountMixin from 'dashboard/mixins/account';

import { MODES } from '../utils/constants';

export default {
  components: { LoadingState, AutomationForm },
  mixins: [alertMixin, mixPanelMixin, accountMixin],
  data() {
    return { selectedTemplate: null, areTemplatesFetched: false };
  },
  computed: {
    ...mapGetters({
      automationTemplates: 'automations/getAutomationTemplates',
      uiFlags: 'automations/getUIFlags',
    }),
    templateId() {
      return +this.$router.history.current?.params?.template_id;
    },
    selectedMode() {
      return this.templateId ? MODES.CREATE_WITH_TEMPLATE : MODES.CREATE;
    },
  },
  created() {
    if (this.selectedMode === MODES.CREATE_WITH_TEMPLATE) {
      this.$store.dispatch('automations/getTemplates').then(() => {
        this.areTemplatesFetched = true;
        this.getSelectedTemplate();
      });
    }
  },
  methods: {
    getSelectedTemplate() {
      this.selectedTemplate =
        this.automationTemplates.find(
          template => template.id === this.templateId
        ) ?? null;
    },
    navigateToEditPage(automationId) {
      let route = this.addAccountScoping(
        `settings/automation/edit/${automationId}`
      );
      this.$router.push(route);
    },
    async saveAutomation(payload) {
      try {
        this.mixPanelAddAutomationSuccessful();

        const response = await this.$store.dispatch(
          'automations/create',
          payload
        );
        this.navigateToEditPage(response.id);
        this.showAlert(
          this.$t('AUTOMATION.ADD.API.SUCCESS_MESSAGE'),
          'success'
        );
        this.mix_panel_clicked_settings_create_submit_automation(
          payload.event_name
        );
      } catch (error) {
        this.showAlert(this.$t('AUTOMATION.ADD.API.ERROR_MESSAGE'), 'error');
      }
    },
  },
};
</script>
