<template>
  <div class="column content-box settings-box no-padding">
    <div class="settings-button flex-row flex-align gap--normal">
      <reference-box
        :to="documentation.agent.role"
        content="Know More"
        type="document"
      />
      <woot-base-button
        size="medium"
        front-icon="plus"
        icon-view-box="0 0 20 20"
        :disabled="inPendingPaymentForNonShopify && !isEnterprise"
        @click="openAddPopup"
      >
        {{ $t('AGENT_MGMT.HEADER_BTN_TXT') }}
      </woot-base-button>
    </div>
    <div class="settings-container">
      <div class="column settings-main-menu-new">
        <div class="table-container">
          <data-table
            ref="dataTable"
            :columns="tableColumns"
            :table-data="agentList"
            :loading="uiFlags.isFetching"
            :actions="actionsList"
            :enable-animations="agentList.length <= 10"
            _class="scrollable-table custom-scroll"
            collapse-actions
            @edit="editAgent"
            @select="viewAgent"
            @delete="openDeletePopup"
          >
            <template v-slot:table-features>
              <!-- search bar feature -->
              <search-box
                :placeholder="$t('AGENT_MGMT.SEARCH_TXT')"
                :value="search"
                custom-class="search-box"
                @setSearch="setSearch"
              />
              <!-- sort by inbox feature -->
              <woot-single-selector
                v-if="inboxList.length"
                :disabled="false"
                variant="secondary"
                size="medium"
                :front-icon="inboxFilterIcon ? inboxFilterIcon : 'inbox'"
                :default-option="selectedInboxObject"
                :option-list="inboxList"
                :show-selected-text="false"
                :dropdown-container-style="filterStyles"
                :button-style="{ 'border-radius': '0.4rem' }"
                :custom-style="{ left: 0 }"
                @click="applyInboxFilter"
              />
              <!-- sort by role feature -->
              <woot-single-selector
                :disabled="false"
                variant="secondary"
                size="medium"
                :default-option="selectedRole"
                :option-list="roles"
                :show-selected-text="false"
                :dropdown-container-style="filterStyles"
                :button-style="{ 'border-radius': '0.4rem' }"
                :custom-style="{ left: 0 }"
                @click="applyRoleFilter"
              />
            </template>

            <template v-slot:zero-state>
              <table-zero-state
                v-if="!agentList.length"
                title="No Agents added till now"
                subtitle="Click on the Add Agent Button to add a new agent"
                asset-link="/brand-assets/agent_zero_state.svg"
              >
                <woot-base-button
                  size="medium"
                  front-icon="plus"
                  style="margin-bottom: 5vh"
                  icon-view-box="0 0 20 20"
                  @click="openAddPopup"
                >
                  {{ $t('AGENT_MGMT.HEADER_BTN_TXT') }}
                </woot-base-button>
              </table-zero-state>
            </template>
          </data-table>
        </div>
      </div>

      <settings-side-card>
        <agent-rhs-panel
          ref="rhsPanel"
          :data="currentAgent"
          @openDeleteModal="openDeletePopup"
          @openEditModal="editAgent"
        />
      </settings-side-card>
    </div>

    <!-- Add Agent -->
    <add-edit-agent
      v-if="showAgentPopup"
      :show="showAgentPopup"
      :agent-data="currentAgent"
      @onClose="hideAddPopup"
      @updateCurrAgent="viewAgent"
    />

    <!-- Delete Agent -->
    <woot-delete-modal-new
      :show.sync="showDeletePopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      show-close
      :title="$t('AGENT_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('AGENT_MGMT.DELETE.CONFIRM.MESSAGE')"
      confirm-text="Continue"
      reject-text="Back"
      :custom-style="{
        'max-width': '35.2rem',
        height: 'auto',
      }"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import SearchBox from '../SearchBox';
import SettingsSideCard from '../SettingSideCard';
import DataTable from 'dashboard/components/widgets/table/DataTable';
import TableZeroState from 'dashboard/components/ui/settings/TableZeroState';
import AgentRhsPanel from 'dashboard/components/ui/settings/agents/RhsPanel';
import AddEditAgent from './AddEditAgent';

import * as types from 'shared/constants/googleEventType';
import { USER_ROLES } from './constants';
import { isSuperAdmin } from 'dashboard/helper/privileges';

import inboxMixin from 'dashboard/mixins/inbox';
import alertMixin from 'shared/mixins/alertMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import billingMixin from 'dashboard/mixins/billing';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';

import documentation from 'dashboard/helper/data/documentationData.json';

export default {
  name: 'AgentHome',
  components: {
    ReferenceBox,
    SearchBox,
    AddEditAgent,
    AgentRhsPanel,
    SettingsSideCard,
    DataTable,
    TableZeroState,
  },
  mixins: [
    globalConfigMixin,
    inboxMixin,
    alertMixin,
    googleAnalyticsMixin,
    mixPanelAnalyticsMixin,
    billingMixin,
  ],
  data() {
    return {
      documentation,
      showAgentPopup: false,
      showDeletePopup: false,
      search: '',
      selectedRole: 'Role',
      currentAgent: {},
      selectedInbox: { id: 0, name: 'All' },
      agentsToDelete: [],
      roles: USER_ROLES,
      actionsList: [
        { id: 0, name: 'edit' },
        { id: 1, name: 'select' },
        { id: 2, name: 'delete' },
      ],
      tableColumns: [
        {
          label: '',
          prop: 'avatar',
          component: 'AgentAvatar',
        },
        {
          label: 'Name',
          prop: 'name',
          width: '30%',
          component: 'AgentNameWithStatus',
          sortable: true,
        },
        {
          label: 'Designation',
          prop: 'role',
          width: '30%',
          sortable: true,
          component: 'RoleAndEmail',
        },
        {
          label: 'Inboxes',
          prop: 'inboxes',
          width: '18%',
          component: 'ViewInboxes',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      records: 'agents/getAgents',
      uiFlags: 'agents/getUIFlags',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
      inboxes: 'inboxes/getInboxes',
    }),
    selectedInboxObject() {
      return this.selectedInbox.name === 'All'
        ? { name: 'All Inboxes', id: null }
        : this.selectedInbox;
    },
    agentList() {
      let agentsData = [...this.records];

      if (this.selectedRole !== 'Role')
        agentsData = agentsData.filter(
          record => record.role === this.selectedRole
        );

      if (this.selectedInbox.name !== 'All') {
        agentsData = agentsData.filter(agent =>
          agent.inboxes.find(inbox => inbox.id === this.selectedInbox.id)
        );
      }

      agentsData = agentsData.filter(
        item =>
          item.name.toLowerCase().search(this.search.toLowerCase()) > -1 ||
          item.role.toLowerCase().search(this.search.toLowerCase()) > -1
      );
      return agentsData;
    },
    filterStyles() {
      return {
        height: '4.4rem',
        'max-width': '15rem',
      };
    },
    inboxList() {
      let inboxesData = [];

      inboxesData = this.inboxes.reduce((ibList, ib) => {
        let { name: icon, color } = this.computedInboxClass(ib);
        ibList.push({
          id: ib.id,
          name: ib.name,
          type: ib.channel_type,
          icon: icon,
          color: color,
        });

        return ibList;
      }, []);

      inboxesData = [{ id: 0, name: 'All' }, ...inboxesData];

      return inboxesData;
    },
    inboxFilterIcon() {
      let inboxIcon = this.inboxes.filter(
        inbox => this.selectedInbox.id === inbox.id
      );
      return this.computedInboxClass(inboxIcon[0]).name;
    },
  },
  provide() {
    return {
      getConditionalActionsList: this.getConditionalActionsList,
      canSelectRow: this.canSelectRow,
    };
  },
  created() {
    this.$store.dispatch('agents/get');
  },
  methods: {
    applyInboxFilter(val) {
      this.mix_panel_clicked_inbox_filter_menu_item('Agent Settings', val.type);
      this.selectedInbox = val;
    },
    applyRoleFilter(val) {
      if (val.name === 'All') {
        this.selectedRole = 'Role';
        return;
      }
      this.mix_panel_clicked_role_filter_menu_item(val.name);
      this.selectedRole = val.name;
    },
    setSearch(value) {
      this.googleAnalyticsEvent(
        types.default.AGENTS_SETTINGS_SEARCH,
        types.default.AGENT_SETTINGS,
        value
      );
      this.search = value;
    },
    canSelectRow(row) {
      const canDeleteRow = this.getConditionalActionsList(
        [{ name: 'delete' }],
        row
      ).find(action => action.name === 'delete');
      return !!canDeleteRow;
    },
    getConditionalActionsList(actionsList, agent) {
      if (!this.showDeleteAction(agent))
        actionsList = actionsList.filter(
          action => !['delete', 'select'].includes(action.name)
        );

      return actionsList;
    },
    showDeleteAction(agent) {
      if (isSuperAdmin(agent)) return false;

      if (this.currentUserId === agent.id) return false;

      if (!agent.confirmed) return true;

      if (agent.role === 'administrator')
        return this.verifiedAdministrators().length !== 1;

      return true;
    },
    verifiedAdministrators() {
      return this.records.filter(
        agent => agent.role === 'administrator' && agent.confirmed
      );
    },
    // Add Function
    openAddPopup() {
      this.googleAnalyticsEvent(
        types.default.AGENTS_OPEN_ADD_POP_UP,
        types.default.AGENT_SETTINGS,
        types.default.AGENT_SETTINGS
      );
      this.mixPanelAddCollaboratorFromAgentSettingsInitiated();
      this.currentAgent = {};
      this.showAgentPopup = true;
    },
    hideAddPopup() {
      this.googleAnalyticsEvent(
        types.default.AGENTS_HIDE_ADD_POP_UP,
        types.default.AGENT_SETTINGS,
        types.default.AGENT_SETTINGS
      );
      this.showAgentPopup = false;
    },
    editAgent(agent) {
      this.googleAnalyticsEvent(
        types.default.AGENTS_OPEN_EDIT_POP_UP,
        types.default.AGENT_SETTINGS,
        types.default.AGENT_SETTINGS
      );
      this.mix_panel_clicked_settings_edit_agent();
      this.currentAgent = agent;
      this.showAgentPopup = true;
    },
    viewAgent(agent, index) {
      agent.canDelete = false;

      if (this.showDeleteAction(agent)) agent.canDelete = true;

      this.currentAgent = { ...agent, index };
    },
    // Delete Function
    openDeletePopup(indices) {
      this.showDeletePopup = true;
      this.agentsToDelete = indices;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      this.currentAgent = {};
      this.$refs.dataTable.clearSelections();
      this.findAgentsToDelete(this.agentsToDelete);
    },
    findAgentsToDelete(indices) {
      const agentIds = this.agentList.reduce((accumulator, agent, index) => {
        if (indices.includes(index)) accumulator.push(agent.id);
        return accumulator;
      }, []);

      this.deleteAgents(agentIds);
    },
    async deleteAgents(ids) {
      try {
        this.googleAnalyticsEvent(
          types.default.DELETE_AGENT,
          types.default.AGENT_SETTINGS,
          types.default.AGENT_SETTINGS
        );
        await this.$store.dispatch('agents/delete', ids);
        this.agentsToDelete = [];
        this.showAlert(
          this.$t('AGENT_MGMT.DELETE.API.SUCCESS_MESSAGE'),
          'success'
        );
      } catch (error) {
        this.agentsToDelete = [];
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.ERROR_MESSAGE'), 'error');
      }
    },
    computedInboxClass(inbox) {
      if (inbox && inbox?.channel_type) {
        const { channel_type, phone_number, instagram_add_on } = inbox;
        const classByType = this.getInboxClassByType(
          channel_type,
          phone_number,
          '',
          instagram_add_on
        );

        return classByType;
      }

      return '';
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/widgets/buttons';

.table-container {
  height: 100%;
}
.no-padding {
  padding-left: 0.1rem;
}
.settings-box {
  padding-top: $space-largest;
  overflow: hidden;

  .settings-container {
    display: flex;
    height: 100%;
    flex-direction: row;

    .search-box {
      background: none;
      margin-bottom: $zero;
      width: 30.4rem;
    }

    .settings-side-card {
      padding: $zero;
    }
  }
}
</style>
