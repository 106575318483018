/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';

class KnowlarityApi extends ApiClient {
  constructor() {
    super('integrations', { accountScoped: true });
  }

  initiateKnowlarityOutboundCall({
    channelId,
    knowlarityNumber,
    agentNumber,
    customerNumber,
    callerId,
  }) {
    return axios.post(
      `${this.baseUrl()}/knowlarity/${channelId}/outbound_call`,
      {
        k_number: knowlarityNumber,
        agent_number: agentNumber,
        customer_number: customerNumber,
        caller_id: callerId,
      }
    );
  }

  getKnowlarityAgents({ channelId }) {
    return axios.get(`${this.baseUrl()}/knowlarity/${channelId}/fetch_agents`);
  }
}

export default new KnowlarityApi();
