<template>
  <div class="sla-list-container">
    <div class="header">
      <span
        class="title-h4 text-dark"
        v-text="$t('SLA.LIST.TABLE_HEADER.NAME')"
      />
      <span
        class="title-h4 text-dark"
        v-text="$t('SLA.LIST.TABLE_HEADER.ACTION')"
      />
    </div>
    <template v-if="!uiFlags.isFetching && slasList.length !== 0">
      <component :is="componentName" v-model="slasList" class="draggable-list">
        <list-item
          v-for="sla in slasList"
          :key="sla.id"
          :active="selectedSla.id === sla.id"
          :data="sla"
          :is-draggable="isDraggable"
          @toggle="onToggle"
          @clone="onClone"
          @delete="onDelete"
          @edit="onEdit"
          @view="onView"
        />
      </component>
    </template>
    <loading-state v-else-if="uiFlags.isFetching" class="draggable-list" />
    <zero-state v-else @onCreate="$emit('onCreate')" />
    <list-bottom
      v-if="slasList.length !== 0"
      :is-draggable="isDraggable"
      @onReorder="onClickReorder"
      @onSaveOrder="onSaveOrder"
      @onCancel="onCancel"
    />
    <delete-modal
      :show="showDeleteModal"
      :selected-sla="selectedSla"
      @close="showDeleteModal = false"
      @delete="onConfirmDeletion"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

import ListItem from './Item';
import ListBottom from './Bottom';
import ZeroState from './ZeroState';
import DeleteModal from '../modals/DeleteModal';
import LoadingState from 'dashboard/components/ui/LoadingState';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    draggable,
    ListItem,
    ListBottom,
    ZeroState,
    DeleteModal,
    LoadingState,
  },
  mixins: [alertMixin],
  props: {
    filters: {
      type: Object,
      default: () => ({ searchTerm: '', inboxId: null }),
    },
    selectedSla: {
      type: Object,
      default: () => ({ id: null }),
    },
  },
  data() {
    return {
      isDraggable: false,
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'sla/getUIFlags' }),
    slasList: {
      get() {
        return this.$store.getters['sla/allSLAs'](this.filters);
      },
      set(value) {
        this.$store.dispatch('sla/setSLAs', value);
      },
    },
    componentName() {
      return this.isDraggable ? 'draggable' : 'div';
    },
  },
  methods: {
    onToggle(data) {
      this.$store.dispatch('sla/editSLA', data);
    },
    onClone(data) {
      const { id, ...payload } = data;
      payload.name = `${data.name} - Copy`;
      this.$store
        .dispatch('sla/createSLA', payload)
        .then(() => {
          this.showAlert(this.$t('SLA.CLONE.API.SUCCESS_MESSAGE'), 'success');
        })
        .catch(() => {
          this.showAlert(this.$t('SLA.CLONE.API.ERROR_MESSAGE'), 'error');
        });
    },
    onDelete() {
      this.showDeleteModal = true;
    },
    onConfirmDeletion() {
      this.$store
        .dispatch('sla/deleteSLA', this.selectedSla.id)
        .then(() => {
          this.showDeleteModal = false;
          this.showAlert(this.$t('SLA.DELETE.API.SUCCESS_MESSAGE'), 'success');
        })
        .catch(() => {
          this.showAlert(this.$t('SLA.DELETE.API.ERROR_MESSAGE'), 'error');
        });
    },
    onEdit({ id: sla_id }) {
      this.$router.push({ name: 'settings_edit_sla', params: { sla_id } });
    },
    onView(data) {
      this.$emit('view', data);
    },
    onClickReorder() {
      this.isDraggable = true;
    },
    onSaveOrder() {
      this.isDraggable = false;
      this.$store.dispatch('sla/setSLAsOrder', this.slasList);
    },
    onCancel() {
      this.isDraggable = false;
      this.$store.dispatch('sla/getSLAs');
    },
  },
};
</script>
