export default {
  methods: {
    companyName(currentAccountId, currentUser) {
      let currentAccount = null;
      let companyName = null;

      if (currentUser.accounts && currentUser.accounts.length !== 0) {
        currentAccount = currentUser.accounts.filter(
          acc => acc.id === currentAccountId
        );
        companyName = currentAccount[0].name;
      }

      return companyName;
    },
  },
};
