<template>
  <button class="fav--button" @click="onClick">
    <icons
      v-if="isFav"
      :name="icon"
      :color="active"
      :view="view"
      :size="size"
    />
    <icons v-else name="starStroke" :size="size" />
  </button>
</template>

<script>
export default {
  props: {
    isConvFav: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'star',
    },
    iconActiveColor: {
      type: String,
      default: 'warningyellow',
    },
    iconInactiveColor: {
      type: String,
      default: 'darkestgrey',
    },
    iconViewSize: {
      type: String,
      default: '0 0 20 20',
    },
    iconSize: {
      type: String,
      default: 'medium',
    },
  },
  data() {
    return {
      name: this.icon,
      size: this.iconSize,
      view: this.iconViewSize,
      active: this.iconActiveColor,
      inactive: this.iconInactiveColor,
    };
  },
  computed: {
    isFav() {
      return this.isConvFav;
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.fav--button {
  cursor: pointer;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}
</style>
