import { Bar } from 'vue-chartjs';

const fontFamily =
  'Lato,Roboto,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif';

let weekDays = [
  'Sunday',
  'Saturday',
  'Friday',
  'Thursday',
  'Wednesday',
  'Tuesday',
  'Monday',
  '',
];

export default {
  extends: Bar,
  props: ['collection'],
  mounted() {
    let { labels, dataset } = this.collection;

    let dataArray = []; // a 2d array of complete dataset (7 x 20)
    let max = 0;
    let scale = 0; // darkest section in heatmap
    dataset.forEach(week => {
      max = week.data.reduce((a, b) => {
        return Math.max(a, b);
      });

      if (max > scale) {
        scale = max;
      }

      dataArray.push(week.data);
    });

    // creating dataset
    let datasets = [];
    let data = new Array(20).fill(1); // to generate 20 bars of equalt thickness (for a single day)
    dataset.forEach(week => {
      // creating 7 datasets for each day of a week
      datasets.push({
        data,
        label: week.name,
        fill: false,
        backgroundColor: this.generateDatasetColors(week.data, scale),
      });
    });

    let barData = {
      labels,
      datasets,
    };

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#1B83AC',
        bodyFontColor: '#8c8c8c',
        footerFontColor: '#8c8c8c',
        titleFontSize: 24,
        bodyFontSize: 16,
        footerFontSize: 12,
        caretSize: 10,
        displayColors: false,
        xPadding: 12,
        yPadding: 12,
        callbacks: {
          title: (tooltipItems, value) => {
            return (
              value.datasets[tooltipItems[0].datasetIndex].label +
              ', ' +
              tooltipItems[0].xLabel
            );
          },
          label: tooltipItem => {
            return dataArray[tooltipItem.datasetIndex][tooltipItem.index];
          },
        },
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              fontFamily,
              callback: label => {
                return label.length > 20 ? label.substr(0, 20) + '...' : label;
              },
            },
            gridLines: {
              display: true,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            scaleLabel: {
              display: true,
              fontColor: '#8c8c8c',
              fontSize: 12,
              lineHeight: 2,
              labelString: 'Week Days',
            },
            ticks: {
              fontFamily,
              callback: (value, index) => {
                return weekDays[index];
              },
            },
            gridLines: {
              display: true,
            },
          },
        ],
      },
    };

    this.renderChart(barData, chartOptions);
  },
  methods: {
    generateDatasetColors(valuesArray, scale) {
      let colors = [];

      valuesArray.forEach(value => {
        let opacity = value / scale;
        if (opacity > 1) {
          opacity = 1;
        }

        colors.push(`rgba(107, 172, 27, ${opacity})`);
      });

      return colors;
    },
  },
};
