import alertMixin from 'shared/mixins/alertMixin';

const MAX_NUMBER_OF_CONTACTS_ALLOWED = 5;

export default {
  mixins: [alertMixin],
  data() {
    return {
      searchQuery: '',
      fetchedContacts: [],
      selectedContacts: [],
      isFetchingContacts: false,
      showSearchResults: false,
      canLoadMoreResults: true,
      currentPage: 1,
      debounceTimer: null,
    };
  },
  computed: {
    filteredContacts() {
      const selectedSet = new Set(
        this.selectedContacts.map(contact => contact.contact_id)
      );

      return this.fetchedContacts.filter(
        fetchedContact => !selectedSet.has(fetchedContact.id)
      );
    },
  },
  methods: {
    clearSearchResults() {
      this.fetchedContacts = [];
    },
    onInputSearch(query) {
      this.searchQuery = query;
      if (!query) {
        this.showSearchResults = false;
        return;
      }

      this.debouncedOnSearchSubmit();
    },
    clearTimer() {
      clearTimeout(this.debounceTimer);
    },
    debouncedOnSearchSubmit() {
      this.clearTimer();

      this.debounceTimer = setTimeout(() => {
        this.onSearchSubmit();
      }, 1000);
    },
    onUpdateContacts(updatedContacts) {
      this.selectedContacts = updatedContacts;
      this.$emit('selectedContacts', this.selectedContacts);
    },
    canAddContacts() {
      const hasReachedContactLimit =
        this.selectedContacts.length === MAX_NUMBER_OF_CONTACTS_ALLOWED;

      if (hasReachedContactLimit) {
        this.showAlert('Cannot add more than 5 contacts', 'error');
        return false;
      }
      return true;
    },
    onAddContact(contact) {
      this.showSearchResults = false;
      this.clearTimer();
      if (!this.canAddContacts()) return;

      this.searchQuery = '';
      this.selectedContacts.push(contact);
      this.$emit('selectedContacts', this.selectedContacts);
    },
    onPageChange() {
      this.currentPage += 1;
      this.onSearchSubmit(this.currentPage, true);
    },
  },
};
