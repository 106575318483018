<template>
  <div class="scroll--panel custom-scroll">
    <!-- assign agent and team -->
    <section
      v-for="selector in selectors"
      :id="selector.id"
      :key="selector.id"
      :name="selector.name"
    >
      <span
        class="text-light subtitle-s2 mg-right mg-left--smaller"
        v-text="selector.title"
      />
      <div class="multiselect-box">
        <woot-single-selector
          variant="tertiary"
          size="large"
          :default-option="selector.defaultOption"
          :custom-button="true"
          :custom-style="{ width: '100%', left: '0.01rem' }"
          :option-list="selector.list"
          @click="selector.action"
        >
          <div class="agent-dropdown-button">
            <img :src="selector.iconSrc" :style="selector.iconStyle" />
            <span
              :style="selector.labelStyle"
              class="dropdown-option"
              v-text="selector.defaultOption"
            />
            <icons name="chevronDown" size="medium" color="green" />
          </div>
        </woot-single-selector>
      </div>
    </section>

    <sla-status
      :key="'sla_' + currentChat.id"
      class="mg-bottom mg-left--slab"
      :chat="currentChat"
    />

    <div v-show="!isSectionHidden">
      <accordian-card
        v-show="contact.id"
        id="previous-tickets-accordion"
        :name="$t('CONTACT_PANEL.CONVERSATIONS.TITLE')"
        icon="history"
        :always-open="true"
        icon-view="0 0 24 24"
      >
        <contact-conversations
          :contact-id="contact.id"
          :conversation-id="conversationId"
          parent-component-name="TicketOverview"
        />
      </accordian-card>
      <accordian-card
        id="custom-fields-accordion"
        name="Custom Properties"
        icon="editRound"
        icon-view="0 0 24 24"
        :has-add-button="false"
        always-open
      >
        <custom-fields
          :inbox-id="currentInboxId"
          :conversation-id="conversationId"
        />
      </accordian-card>
      <accordian-card
        id="add-tags-accordion"
        :name="$t('CONTACT_PANEL.TAGS.LABEL')"
        icon="hashFilled"
        icon-view="0 0 24 24"
        always-open
      >
        <conversation-labels
          :conversation-id="conversationId"
          :selected-labels="selectedConvLabels"
          show-add-button
          @updateLabels="UpdateConvLabels"
        />
      </accordian-card>
      <accordian-card
        id="add-tags-accordion"
        :name="$t('CONTACT_PANEL.CONTACT_TAGS.LABEL')"
        icon="hashFilled"
        icon-view="0 0 24 24"
        always-open
      >
        <contact-labels
          :key="contactId"
          :contact-id="contactId"
          :contact-labels="contactLabels"
          show-add-button
          @updateLabels="UpdateContactLabels"
        />
      </accordian-card>
      <accordian-card
        v-if="hasContactAttributes"
        name="Custom Attributes"
        icon="hashFilled"
        icon-view="0 0 24 24"
        always-open
      >
        <contact-custom-attributes
          :custom-attributes="contact.custom_attributes"
        />
      </accordian-card>
    </div>

    <crm-tickets
      :key="conversationId"
      @raiseTicketSectionOpened="isSectionHidden = true"
      @raiseTicketSectionClosed="isSectionHidden = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AccordianCard from 'dashboard/components/AccordianCard';
import ContactConversations from '../ContactConversations';
import ContactCustomAttributes from '../ContactCustomAttributes';
import CrmTickets from '../overview/views/crms/Index.vue';
import ConversationLabels from '../labels/LabelBox';
import ContactLabels from '../../contacts/labels/LabelBox';
import CustomFields from '../customFields';

import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import alertMixin from 'shared/mixins/alertMixin';

import agentIcon from 'dashboard/assets/images/agent';
import teamIcon from 'dashboard/assets/images/team';
import SlaStatus from './components/slaStatus';

export default {
  components: {
    AccordianCard,
    ContactConversations,
    ContactCustomAttributes,
    ConversationLabels,
    ContactLabels,
    CrmTickets,
    CustomFields,
    SlaStatus,
  },
  mixins: [alertMixin, mixPanelAnalyticsMixin, googleAnalyticsMixin],
  props: {
    conversationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isSectionHidden: false,
      currentChatAssignee: '',
      currentChatTeam: '',
      contactLabelTitles: [],
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      agents: 'agents/getAgents',
      allContactLabels: 'labels/getContactLabelsWithoutGroups',
      teams: 'teams/getTeams',
    }),
    selectors() {
      const baseSelectors = [
        {
          id: 'assign-agent-selector',
          name: 'assignAgent',
          title: this.$t('CONTACT_PANEL.ASSIGN_AGENT'),
          defaultOption: this.selectAgentDropdownOption,
          list: this.agentList,
          action: this.assignAgent,
          iconSrc: agentIcon,
          labelStyle: null,
          iconStyle: 'height: 20px; width: 20px;',
        },
      ];

      if (this.teams.length !== 0) {
        const teamSelector = {
          id: 'assign-team-selector',
          name: 'assignTeam',
          title: this.$t('CONTACT_PANEL.ASSIGN_TEAM'),
          defaultOption: this.selectTeamDropdownOption,
          list: this.teamList,
          action: this.assignTeam,
          iconSrc: teamIcon,
          labelStyle: 'margin-left: 2.8rem',
          iconStyle: 'height: 32px; width: 32px; position: absolute; left: 0;',
        };

        baseSelectors.push(teamSelector);
      }

      return baseSelectors;
    },
    selectAgentDropdownOption() {
      return this.getDropdownOption(
        this.currentChat?.meta?.assignee,
        'Assign Agent'
      );
    },
    selectTeamDropdownOption() {
      return this.getDropdownOption(
        this.currentChat?.meta?.team,
        'Assign Team',
        'name'
      );
    },
    teamList() {
      return this.formatDropdownList(this.teams, 'None');
    },
    agentList() {
      return this.formatDropdownList(this.agents, 'None', true);
    },
    hasContactAttributes() {
      const { custom_attributes: customAttributes } = this.contact;
      return customAttributes && Object.keys(customAttributes).length;
    },
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    currentInboxId() {
      return this.currentChat.inbox_id || 0;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    selectedConvLabels() {
      return this.$store.getters['conversationLabels/getConversationLabels'](
        this.conversationId
      );
    },
    contactLabels() {
      return this.allContactLabels.filter(({ title }) => {
        return this.contactLabelTitles.includes(title);
      });
    },
  },
  watch: {
    conversationId: 'fetchDataOnIdChange',
    contactId: 'getContactDetails',
  },
  created() {
    this.fetchDataOnIdChange(this.conversationId);
    bus.$on('contactLabelsChange', () => {
      this.fetchContactLabels(this.contactId);
    });
  },
  mounted() {
    this.getContactDetails();
  },
  methods: {
    fetchDataOnIdChange(newId, oldId = '') {
      if (newId !== oldId) {
        this.getContactDetails();
        this.fetchLabels(newId);
      }
    },
    fetchContactLabels(contactId) {
      this.$store.dispatch('contactLabels/get', contactId).then(data => {
        this.contactLabelTitles = data;
      });
    },
    getDropdownOption(item, defaultText, key = 'available_name') {
      return item && item[key] ? item[key] : defaultText;
    },
    formatDropdownList(items, noneLabel, isAgent = false) {
      const formattedItems = items.map(item => ({
        id: item.id,
        name: item.name,
        ...(isAgent
          ? {
              role: item.role,
              availability: item.availability_status,
              confirmed: item.confirmed,
            }
          : {}),
      }));
      return [
        { id: 0, name: noneLabel, confirmed: true, email: 'None', icon: '' },
        ...formattedItems,
      ];
    },
    assignAgent(agent) {
      this.currentChatAssignee = agent?.name;
      if (!agent?.confirmed) {
        this.showAlert(
          this.$t('CONVERSATION.AGENT_VERIFICATION_PENDING'),
          'error'
        );
        return;
      }
      this.logAssigneeChange(agent.id);
      this.$store
        .dispatch('assignAgent', {
          conversationId: this.currentChat.id,
          agentId: agent.id,
        })
        .then(() => {
          this.showAlert(this.$t('CONVERSATION.CHANGE_AGENT'), 'success');
        });
    },
    assignTeam(team) {
      this.currentChatTeam = team?.name;
      this.logTeamChange(team.id);
      this.mix_panel_assigned_ticket_to_team();
      this.$store
        .dispatch('assignTeam', {
          conversationId: this.currentChat.id,
          teamId: team.id,
        })
        .then(() => {
          this.showAlert(this.$t('CONVERSATION.CHANGE_TEAM'), 'success');
        });
    },
    getContactDetails() {
      if (this.contactId) {
        this.$store.dispatch('contacts/show', { id: this.contactId });
        this.fetchContactLabels(this.contactId);
      }
    },
    fetchLabels(conversationId) {
      if (!conversationId) return;
      this.$store.dispatch('conversationLabels/get', conversationId);
    },
    async UpdateConvLabels({ id }, action) {
      try {
        await this.$store.dispatch('conversationLabels/update', {
          conversationId: this.conversationId,
          labelId: id,
          action,
        });
      } catch (error) {
        // Ignore error
      }
    },
    async UpdateContactLabels({ id }, action) {
      try {
        await this.$store
          .dispatch('contactLabels/update', {
            contactId: this.contactId,
            labelId: id,
            action,
          })
          .then(data => {
            this.contactLabelTitles = data;
          });
      } catch (error) {
        // Ignore error
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.custom-scroll {
  overflow-x: hidden;
}

// a dynamically applied class
.highlight-labelbox {
  border: 3px solid $warn-red-500;
}

.multiselect-box {
  cursor: pointer;
  margin: $space-small $space-smaller $space-normal;
}

.agent-dropdown-button {
  position: relative;
  align-items: center;
  border: 1px solid $neutral-grey-600;
  border-radius: $border-radius-smaller;
  display: flex;
  padding: $space-small;
  width: 100%;

  .dropdown-option {
    width: 100%;
    font-size: $font-size-small;
    font-weight: $font-weight-black;
    line-height: $height-h5;
    color: $text-dark;
    text-transform: uppercase;
    margin-left: $space-small;
  }
}
</style>
