var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column content-box settings-box"},[_c('div',{staticClass:"settings-button flex-row-justify-center",staticStyle:{"top":"2.4rem"}},[_c('woot-primary-button',{attrs:{"name":_vm.$t('PRODUCTS_MGMT.HEADER_BUTTON.PREV'),"front-icon":"chevronLeft","disabled":_vm.itemIndex === 0,"custom-style":{ 'margin-right': '1.2rem' }},on:{"click":_vm.prevCard}}),_vm._v(" "),_c('woot-primary-button',{attrs:{"name":_vm.$t('PRODUCTS_MGMT.HEADER_BUTTON.NEXT'),"back-icon":"chevronRight","disabled":_vm.itemIndex === _vm.records.length - 1},on:{"click":_vm.nextCard}})],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(!_vm.uiFlags.fetchingProducts && _vm.item !== {})?_c('div',{key:_vm.itemIndex,staticClass:"settings-container custom-scroll"},[_c('div',{staticClass:"small-12 item-wrap",class:{ 'item-height': _vm.item.description !== null }},[_c('div',{staticClass:"card__media",class:{
            'image-unavailable': _vm.productImage.isUnavailable,
          }},[_c('img',{attrs:{"src":_vm.productImage.source,"alt":'Product Image'}})]),_vm._v(" "),_c('div',{staticClass:"card__content flex-column custom-scroll"},[_c('div',{staticClass:"card__header text-dark title-h1"},[_vm._v("\n            "+_vm._s(_vm.item.name)+"\n          ")]),_vm._v(" "),_c('footer',{staticClass:"card__meta flex-space-between",attrs:{"role":"contentinfo"}},[_c('div',{staticClass:"card__price text-dark title-h2"},[(_vm.productPrice.onSale)?_c('span',{domProps:{"innerHTML":_vm._s((_vm.accountCurrency + " " + (_vm.productPrice.price)))}}):_vm._e(),_vm._v(" "),_c('span',{class:{
                  'sale-price': _vm.productPrice.onSale,
                },domProps:{"innerHTML":_vm._s((_vm.accountCurrency + " " + (_vm.productPrice.comparedAtPrice)))}}),_vm._v(" "),(_vm.productPrice.onSale)?_c('span',{staticClass:"price__discount flex-row-justify-center"},[_c('icons',{attrs:{"name":"discount","color":"primary","size":"semimedium"}}),_vm._v(" "),_c('span',[_vm._v("\n                  "+_vm._s(("Buy this at " + _vm.discountPercentage + "\n                % OFF discount"))+"\n                ")])],1):_vm._e()])]),_vm._v(" "),(_vm.hasNoVariants && _vm.defaultVariant.inventory_quantity < 1)?_c('span',{staticClass:"out-of-stock title-h5"},[_vm._v("\n            OUT OF STOCK\n          ")]):_vm._e(),_vm._v(" "),(!_vm.hasNoVariants)?_c('product-variants',{key:+_vm.productId,attrs:{"id":+_vm.productId},on:{"variantData":_vm.handleVariantData}}):_vm._e(),_vm._v(" "),(_vm.item.description !== null)?_c('p',{staticClass:"card__excerpt subtitle-s2",domProps:{"innerHTML":_vm._s(_vm.item.description)}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"attributes-container"},[_c('div',{staticClass:"attribute-header"},[_vm._v("\n          "+_vm._s(_vm.$t('PRODUCTS_MGMT.ATTRIBUTES.TITLE'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"flex-space-between"},[_c('woot-input',{staticClass:"columns content-margin",attrs:{"label":_vm.$t('PRODUCTS_MGMT.ATTRIBUTES.LABEL'),"placeholder":_vm.$t('PRODUCTS_MGMT.ATTRIBUTES.PLACEHOLDER'),"help-text":_vm.$t('PRODUCTS_MGMT.ATTRIBUTES.DESC')},model:{value:(_vm.newAttributes),callback:function ($$v) {_vm.newAttributes=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"newAttributes"}}),_vm._v(" "),_c('woot-primary-button',{attrs:{"name":_vm.$t('PRODUCTS_MGMT.ATTRIBUTES.BUTTON'),"disabled":_vm.newAttributes.length === 0,"custom-style":{
              'margin-top': '3.5rem',
              width: '10rem',
              display: 'flex',
              'justify-content': 'center',
            }},on:{"click":function($event){return _vm.addAttributes(_vm.newAttributes)}}})],1),_vm._v(" "),_c('span',{staticClass:"attribute-box columns"},[_vm._l((_vm.attributes),function(attribute,index){return _c('woot-chips',{key:index,attrs:{"title":attribute,"variant":"primary-small","show-icon":true},on:{"click":function($event){return _vm.onRemoveAttribute(index)}}})}),_vm._v(" "),(_vm.attributes.length === 0)?_c('p',{staticClass:"no-items-error-message"},[_vm._v("\n            "+_vm._s(_vm.$t('PRODUCTS_MGMT.ATTRIBUTES.404'))+"\n          ")]):_vm._e()],2)]),_vm._v(" "),(!_vm.uiFlags.fetchingProducts && _vm.item === {})?_c('p',{staticClass:"no-items-error-message"},[_vm._v("\n        "+_vm._s(_vm.$t('PRODUCTS_MGMT.LIST.404'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.uiFlags.fetchingProducts)?_c('woot-loading-state',{attrs:{"message":_vm.$t('PRODUCTS_MGMT.LIST.LOADING')}}):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }