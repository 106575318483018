<template>
  <div class="automation-rhs-panel custom-scroll">
    <div class="automation-details">
      <div
        class="automation-name flex-row flex-align flex-wrap gap--small mg-bottom--medium"
      >
        <div class="body-b2 text-light">
          Rule:
        </div>
        <test-mode-badge v-if="automation.is_test" />
        <div class="title-h4 text-dark line-clamp-1" v-text="automationName" />
      </div>
      <template v-if="automationDescription">
        <div class="body-b2 text-light mg-bottom--smaller">
          Description:
        </div>
        <div
          class="automation-description body-b2 text-dark line-clamp-3 mg-bottom--medium"
          v-text="automationDescription"
        />
      </template>
    </div>
    <div class="automation-summary">
      <div class="body-b2 text-light mg-bottom--small">
        Summary of the rule:
      </div>
      <div class="body-b2 text-light" v-html="automationSummary" />
    </div>
    <div class="rhs-panel-footer">
      <woot-base-button
        variant="secondary"
        size="small"
        @click="$emit('cloneAutomation', automation.id)"
      >
        Clone
      </woot-base-button>
    </div>
  </div>
</template>
<script>
import TestModeBadge from './shared/TestModeBadge';

import generateAutomationRuleSummary from '../utils/generateRuleSummary';

export default {
  components: { TestModeBadge },
  props: {
    automation: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    automationName() {
      return this.automation.name;
    },
    automationDescription() {
      return this.automation.description;
    },
    automationSummary() {
      return generateAutomationRuleSummary(this.automation);
    },
  },
};
</script>
