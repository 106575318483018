var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroll--panel custom-scroll"},[_c('woot-modal',{attrs:{"full-width":true,"show":_vm.showImagePreview,"on-close":function () { return (_vm.showImagePreview = false); }},on:{"update:show":function($event){_vm.showImagePreview=$event}}},[_c('span',{staticClass:"download-button",on:{"click":function($event){return _vm.onDownload(_vm.productImageSrc)}}},[_c('icons',{attrs:{"name":"download","color":"green","size":"medium"}})],1),_vm._v(" "),_c('img',{staticClass:"modal-image",attrs:{"src":_vm.productImageSrc}})]),_vm._v(" "),_c('div',{staticClass:"product-search flex-row"},[_c('search-box',{attrs:{"placeholder":_vm.$t('PRODUCTS_MGMT.SEARCH_TXT'),"value":_vm.search,"variant":true,"on-search":_vm.searchProducts},on:{"setSearch":_vm.setSearch}}),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
        !_vm.permissions.canCreate &&
          _vm.$t('ORDER.CTA_PERMISSION_MESSAGES.NO_PERMISSION')
      ),expression:"\n        !permissions.canCreate &&\n          $t('ORDER.CTA_PERMISSION_MESSAGES.NO_PERMISSION')\n      "}],staticClass:"flex-row"},[_c('span',{staticClass:"cart-icon",class:{
          disabled: _vm.currentCartId === undefined || !_vm.permissions.canCreate,
        },on:{"click":_vm.navigateToCart}},[_c('icons',{attrs:{"name":"cart","color":_vm.currentCartId !== undefined ? 'green' : 'grey',"size":"medium"}}),_vm._v(" "),(_vm.totalProductsInCart)?_c('span',{key:_vm.totalProductsInCart,staticClass:"cart-count zoom-in-out"},[_vm._v("\n          "+_vm._s(_vm.totalProductsInCart)+"\n        ")]):_vm._e()],1)])],1),_vm._v(" "),(!_vm.uiFlags.fetchingProducts && !_vm.productsList.length)?_c('div',{staticClass:"no-order"},[_c('img',{attrs:{"src":require('dashboard/assets/images/noOrder.svg'),"alt":"No Product"}}),_vm._v(" "),_c('p',{staticClass:"no-items-error-message"},[_vm._v("\n      "+_vm._s(_vm.$t('PRODUCTS_MGMT.NO_PRODUCTS'))+"\n    ")])]):_vm._e(),_vm._v(" "),_c('div',[_vm._l((_vm.productsList),function(product){return _c('accordian-card',{key:product.id,attrs:{"custom-desc":""},on:{"panelToggled":_vm.onClearVariantData},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('div',{staticClass:"flex-row flex-align--start",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"item-wrap flex-row"},[_c('div',{staticClass:"card__media",class:{
                'image-unavailable': _vm.setImageSource(product).isUnavailable,
              },on:{"click":function (e) { return _vm.onCardMediaClicked(e, product); }}},[_c('img',{staticStyle:{"font-size":"1rem"},attrs:{"src":_vm.setImageSource(product).source,"alt":'Product Image'}})]),_vm._v(" "),_c('div',{staticClass:"card__content flex-column"},[_c('header',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(product.name),expression:"product.name"}],staticClass:"title-h5 text-truncate"},[_vm._v("\n                "+_vm._s(product.name)+"\n              ")]),_vm._v(" "),_c('footer',{staticClass:"card__meta flex-space-between",attrs:{"role":"contentinfo"}},[_c('div',{staticClass:"body-b2 flex-row flex-align"},[(_vm.setProductPrice(product).onSale)?_c('span',{domProps:{"innerHTML":_vm._s(
                      (_vm.accountCurrency + " " + (_vm.setProductPrice(product).price))
                    )}}):_vm._e(),_vm._v(" "),_c('span',{class:{
                      'sale-price': _vm.setProductPrice(product).onSale,
                    },domProps:{"innerHTML":_vm._s(
                      (_vm.accountCurrency + " " + (_vm.setProductPrice(product).comparedAtPrice))
                    )}})])])])]),_vm._v(" "),_c('span',{staticClass:"share-icon",on:{"click":function (e) { return _vm.onShareProduct(e, product); }}},[_c('icons',{attrs:{"name":"share","color":"green","size":"semimedium"}})],1)])]},proxy:true}],null,true)},[_vm._v(" "),(product.shopify_variant_options[0].value !== 'Default Title')?_c('product-variants',{attrs:{"id":product.id},on:{"variantData":_vm.handleVariantData,"clearVariantData":_vm.onClearVariantData,"disableAddToCart":function($event){_vm.disableAddToCart = Object.assign({}, _vm.disableAddToCart, $event)},"availableOptions":function($event){_vm.availableOptions = $event},"resetOptions":function($event){_vm.onResetOptions = $event}}}):_vm._e(),_vm._v(" "),(
          product.shopify_variant_options[0].value === 'Default Title' &&
            product.default_variant.inventory_quantity < 1
        )?_c('span',{staticClass:"text-red title-h6"},[_vm._v("\n        "+_vm._s(_vm.$t('PRODUCTS_MGMT.OUT_OF_STOCK'))+"\n      ")]):_vm._e(),_vm._v(" "),(product.description)?_c('hr'):_vm._e(),_vm._v(" "),(product.description)?_c('p',{staticClass:"product-card--excerpt body-b3 text-dark flex-column custom-scroll",domProps:{"innerHTML":_vm._s(product.description)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-buttons flex-row flex-justify--end flex-align"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            !_vm.permissions.canCreate &&
              _vm.$t('ORDER.CTA_PERMISSION_MESSAGES.NO_PERMISSION')
          ),expression:"\n            !permissions.canCreate &&\n              $t('ORDER.CTA_PERMISSION_MESSAGES.NO_PERMISSION')\n          "}]},[_c('woot-secondary-button',{attrs:{"size":"small","name":_vm.$t('PRODUCTS_MGMT.ADD_TO_CART'),"disabled":(_vm.disableAddToCart[product.id] && !!product) ||
                !_vm.permissions.canCreate},on:{"click":function($event){return _vm.addProductToCart(product)}}})],1)])],1)}),_vm._v(" "),(_vm.uiFlags.fetchingProducts)?_c('woot-loading-state',{attrs:{"message":_vm.$t('PRODUCTS_MGMT.LIST.LOADING')}}):_vm._e(),_vm._v(" "),(!_vm.uiFlags.fetchingProducts && _vm.productsList.length && _vm.hasNextPage)?_c('p',{staticClass:"clear button load-more-conversations",on:{"click":_vm.onPageChange}},[_vm._v("\n      "+_vm._s(_vm.$t('PRODUCTS_MGMT.LIST.LOAD_MORE'))+"\n    ")]):_vm._e(),_vm._v(" "),(!_vm.uiFlags.fetchingProducts && _vm.productsList.length && !_vm.hasNextPage)?_c('p',{staticClass:"text-center text-muted end-of-list-text"},[_vm._v("\n      "+_vm._s(_vm.$t('PRODUCTS_MGMT.LIST.LOADED'))+"\n    ")]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }