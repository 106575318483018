export const addToLocalStorage = items => {
  if (typeof items === 'object' && items !== null)
    Object.entries(items).forEach(([key, value]) => {
      localStorage.setItem(key, value);
    });
  else throw new Error('Passed parameter is not an object.');
};

export const getItemFromLocalStorage = key => {
  return localStorage.getItem(key);
};

export const removeFromLocalStorage = keys => {
  if (!Array.isArray(keys)) {
    keys = [keys];
  }

  keys.forEach(key => {
    if (getItemFromLocalStorage(key)) localStorage.removeItem(key);
  });
};

export const clearShopifyAppDetailsFromLocalStorage = () => {
  const keys = [
    'shopify_access_token',
    'shopify_store_url',
    'shopify_country_name',
    'shopify_country_code',
  ];

  removeFromLocalStorage(keys);
};
