import BroadcastTab from './Broadcast';
import DownloadTab from './BroadcastReports';
import OptOutSection from './OptOut.vue';
import { frontendURL } from '../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/broadcast'),
      name: 'broadcast',
      roles: ['administrator', 'agent', 'supervisor'],
      component: DownloadTab,
      redirect: frontendURL('accounts/:accountId/broadcast/reports'),
    },
    {
      path: frontendURL('accounts/:accountId/broadcast/reports'),
      name: 'broadcast_reports',
      roles: ['administrator', 'agent', 'supervisor'],
      component: DownloadTab,
    },
    {
      path: frontendURL('accounts/:accountId/broadcast/create'),
      name: 'create_broadcast',
      roles: ['administrator', 'agent', 'supervisor'],
      component: BroadcastTab,
    },
    {
      path: frontendURL('accounts/:accountId/broadcast/opt_out'),
      name: 'broadcast_opt_out',
      roles: ['administrator', 'agent', 'supervisor'],
      component: OptOutSection,
    },
  ],
};
