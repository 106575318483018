<template>
  <div>
    <div class="backdrop" @click="$emit('closeImageSlider')" />
    <div class="slide flex-column" @click="closeImageSlider">
      <div
        class="slide--wrapper flex-row flex-justify flex-align"
        :class="{ 'ml-9': numberOfAttachments > 1 }"
        @click="closeImageSlider"
      >
        <div
          v-for="(attachment, idx) in attachments"
          :key="attachment.id"
          class="slide--image-container flex-row flex-justify flex-align"
          @click="traverseAttachments(idx)"
          @dblclick="closeImageSlider"
        >
          <template v-if="!attachmentsWithError.includes(idx)">
            <img
              v-if="!isMp4(attachment.url)"
              :src="attachment.url"
              @error="imgPlaceholder(idx)"
            />
            <video-view v-else :url="attachment.url" width="100%" />
          </template>
          <span v-else class="flex-column flex-justify flex-align">
            <icons name="image" size="mediumlarge" color="white" />
            <span class="title-h4_m placeholder">
              {{ $t('IMAGE_PREVIEW.ERROR') }}
            </span>
          </span>
        </div>
      </div>
      <div class="file-wrapper flex-column flex-align flex-justify">
        <div
          style="cursor: pointer"
          :title="fileName(attachments[index].url)"
          class="file flex-row flex-align flex-justify"
          @click="onDownload(attachments[index].url)"
        >
          <span class="text-block-title title-h6_m">
            {{ truncateFilename(fileName(attachments[index].url), 20) }}
          </span>
          <icons name="download" size="medium" color="white" />
        </div>
        <div v-if="numberOfAttachments > 1">
          <span
            class="file navigation-buttons flex-row flex-align flex-justify"
          >
            <span class="icon-wrap prev" @click="prevBtn">
              <icons
                :name="'chevronLeft'"
                size="medium"
                show-title
                :title="'Previous'"
                :color="index === 0 ? 'grey' : 'white'"
              />
            </span>
            <span style="user-select:none" class="text-block-title title-h6_m">
              {{ index + 1 }}
            </span>
            <span class="icon-wrap next" @click="nextBtn">
              <icons
                :name="'chevronRight'"
                size="medium"
                show-title
                :title="'Next'"
                :color="index === numberOfAttachments - 1 ? 'grey' : 'white'"
              />
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VideoView from 'shared/components/VideoView';
import fileMixin from 'shared/mixins/fileMixin';
import {
  isEscape,
  hasPressedRightArrow,
  hasPressedLeftArrow,
} from 'shared/helpers/KeyboardHelpers';

export default {
  components: {
    VideoView,
  },
  mixins: [fileMixin],
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    activeAttachment: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      index: this.activeAttachment,
      attachmentsWithError: [],
    };
  },
  computed: {
    numberOfAttachments() {
      return this.attachments.length;
    },
  },
  mounted() {
    setTimeout(() => {
      this.traverseAttachments(this.index);
    }, 100);

    document.addEventListener('keydown', this.handleKeyEvents);
  },
  methods: {
    closeImageSlider(e) {
      if (e.type !== 'dblclick' && e.target !== e.currentTarget) return;
      this.$emit('closeImageSlider');
      document.removeEventListener('keydown');
    },
    nextBtn() {
      if (this.numberOfAttachments === 1) return;
      if (this.index > this.numberOfAttachments - 2) return;
      this.index += 1;
      this.traverseAttachments(this.index);
    },
    prevBtn() {
      if (this.numberOfAttachments === 1) return;
      if (this.index < 1) return;
      this.index -= 1;
      this.traverseAttachments(this.index);
    },
    traverseAttachments(idx) {
      this.index = idx;
      document.querySelector(
        '.slide--wrapper'
      ).style.transform = `translateX(-${132 * idx}rem)`;
    },
    imgPlaceholder(idx) {
      this.attachmentsWithError.push(idx);
    },
    handleKeyEvents(e) {
      if (isEscape(e)) this.$emit('closeImageSlider');
      if (hasPressedRightArrow(e)) this.nextBtn();
      if (hasPressedLeftArrow(e)) this.prevBtn();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.backdrop {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: $zero;
  position: fixed;
  top: $zero;
  width: 100%;
  z-index: 9999;
}

.slide {
  left: 50%;
  overflow: hidden;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1500px;
  height: auto;
  z-index: 10000;

  .ml-9 {
    margin-left: 9rem;
  }

  .slide--wrapper {
    gap: $space-normal;
    min-width: fit-content;
    transition: transform 0.5s ease-in-out;

    .slide--image-container {
      background-color: rgba(0, 0, 0, 0.82);
      border-radius: $border-radius;
      width: 1312px;
      height: 774.42px;
      cursor: pointer;

      img {
        background: url('https://placehold.jp/14/000000/ffffff/250x16.png?text=Please wait for the media file to load...')
          no-repeat center;
        height: 100%;
        object-fit: contain;
        width: 100%;
      }

      .placeholder {
        color: $neutral-white;
      }
    }
  }

  .file-wrapper {
    gap: $space-normal;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);

    .file {
      background-color: $neutral-grey-1000;
      border-radius: $border-radius;
      color: $neutral-white;
      display: inline-flex;
      height: $space-larger;
      gap: $space-small;
      padding: $space-slab $space-normal;
      transition: all 0.1s ease-in-out;

      &:hover {
        transform: scale(1.02);
      }

      &:active {
        transform: scale(0.98);
      }

      .icon-wrap {
        cursor: pointer;
      }

      .prev {
        padding: $space-slab $zero $space-slab $space-normal;
      }

      .next {
        padding: $space-slab $space-normal $space-slab $zero;
      }

      .text-block-title {
        margin: $zero;
        word-break: break-word;
      }
    }

    .navigation-buttons {
      padding: $zero;
    }
  }
}
</style>
