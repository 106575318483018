<template>
  <transition-group name="menu-list" tag="div" class="selected-contact-results">
    <div
      v-for="({ name, phone_number, type }, idx) in contacts"
      :key="`${type}_${idx}`"
      class="selected-contact gap--slab mg-bottom--slab"
      :class="`CREATE_TICKET_PHONE_NUMBER${idx}`"
    >
      <account-pii-mask-wrapper
        :pii-data="phone_number"
        :mask-state="
          type !== 'existing' ? MASK_STATE.noMask : MASK_STATE.serverMask
        "
        :mask-type="MASK_TYPES.PHONE_INPUT"
        :unmask-payload="unmaskRequestPayload(contacts[idx])"
        unmask-action="piiMask/logUnmaskEvent"
        :on-unmask="data => updateField('phone_number', data, idx)"
        @update="updateField('phone_number', $event, idx)"
      >
        <template v-slot="{ data, isMasked, updateData }">
          <woot-input
            :label="idx === 0 ? 'Contact Number' : ''"
            auto-focus
            class="full-width"
            size="small"
            :value="isMasked ? phone_number : data"
            type="number"
            :required="idx === 0"
            :disabled="isMasked || type === 'existing'"
            :placeholder="isMasked ? phone_number : 'Phone number'"
            :class="{ error: $v.contacts.$each[idx].phone_number.$error }"
            :has-error="!isMasked && $v.contacts.$each[idx].phone_number.$error"
            :error="
              $t('CREATE_TICKET.FORM.ERRORS.NOT_VALID_PHONE_NUMBER_DETAILED')
            "
            @input="updateData"
          />
        </template>
      </account-pii-mask-wrapper>
      <account-pii-mask-wrapper
        :pii-data="name"
        :mask-state="
          type !== 'existing' ? MASK_STATE.noMask : MASK_STATE.serverMask
        "
        :mask-type="MASK_TYPES.NAME_INPUT"
        :unmask-payload="unmaskRequestPayload(contacts[idx])"
        unmask-action="piiMask/logUnmaskEvent"
        :on-unmask="data => updateField('name', data, idx)"
        @update="updateField('name', $event, idx)"
      >
        <template v-slot="{ data, isMasked, updateData }">
          <woot-input
            :label="idx === 0 ? 'Contact Name' : ''"
            size="small"
            class="full-width"
            :value="isMasked ? name : data"
            :disabled="isMasked || type === 'existing'"
            :placeholder="isMasked ? 'Unmask to view name' : 'Name'"
            @input="updateData"
          />
        </template>
      </account-pii-mask-wrapper>
      <woot-base-button
        tag="span"
        variant="tertiary-danger"
        class="remove-button"
        @click="removeContact(idx)"
      >
        <icons
          name="delete"
          size="semimedium"
          color="red"
          title="Remove contact"
        />
      </woot-base-button>
    </div>
  </transition-group>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { checkValidPhoneNumber } from '../../utils/validations';
import {
  MASK_TYPES,
  MASK_STATE,
} from 'dashboard/components/widgets/piiMask/constants';
import AccountPiiMaskWrapper from 'dashboard/components/widgets/piiMask/AccountPiiMaskWrapper';
import { mapGetters } from 'vuex';

export default {
  components: { AccountPiiMaskWrapper },
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      MASK_TYPES,
      MASK_STATE,
    };
  },
  validations: {
    contacts: {
      $each: {
        phone_number: { required, checkValidPhoneNumber },
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
  },
  watch: { 'contacts.length': 'validateNewlyAddedContacts' },
  mounted() {
    this.validateContactAt(0);
  },
  methods: {
    unmaskRequestPayload(contact) {
      return {
        user_id: this.currentUser.id,
        resource_type: 'Contact',
        resource_id: contact.contact_id,
      };
    },
    validateNewlyAddedContacts(newLength, oldLength) {
      // Check if a contact was added (newLength > oldLength)
      if (newLength > oldLength)
        this.$nextTick(() => this.validateContactAt(newLength - 1));
    },
    validateContactAt(index) {
      this.$v.contacts.$each[index].$touch();
    },
    updateField(fieldName, newValue, index) {
      const updatedContacts = [...this.contacts];
      updatedContacts[index][fieldName] = newValue;
      this.$emit('updateContacts', updatedContacts);
    },
    removeContact(indexToRemove) {
      const updatedContacts = this.contacts.filter(
        (_, idx) => idx !== indexToRemove
      );
      this.$emit('updateContacts', updatedContacts);
    },
  },
};
</script>
