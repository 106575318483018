export const DATA_TYPES = {
  NUM: 'num',
  ENUM: 'enum',
  MULTI_ENUM: 'multi_enum',
  TEXT: 'text',
  LARGE_TEXT: 'large_text',
  DATE: 'date',
  DATETIME: 'datetime',
  BOOL: 'bool',
  TIMEFRAME: 'timeframe',
  TIME: 'time',
  OBJECT: 'object',
  ARRAY: 'array',
  ARRAY_NUM: 'array[num]',
  ARRAY_ENUM: 'array[enum]',
  ARRAY_TEXT: 'array[text]',
  ARRAY_DATE: 'array[date]',
  ARRAY_DATETIME: 'array[datetime]',
  ARRAY_TIME: 'array[time]',
};
