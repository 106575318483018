<template>
  <div>
    <div @click="loadVariants">
      <div class="product flex-row flex-justify--between flex-align">
        <div
          class="flex-row flex-align"
          :class="{ disable: defaultVariant.inventory_quantity < 1 }"
        >
          <label
            v-if="!hasVariants && defaultVariant.inventory_quantity > 0"
            style="cursor: pointer"
            class="container-checkbox"
          >
            <input
              v-model="selectedProducts"
              type="checkbox"
              :value="defaultVariant.id"
              @input="
                handleSelectedProducts({
                  productId: product.product_id,
                  variantId: defaultVariant.id,
                })
              "
            />
            <span class="checkmark" />
          </label>
          <div class="card__media">
            <img
              style="font-size: 0; cursor: pointer"
              :src="product.shopify_images[0].src"
              :alt="'Product Image'"
            />
          </div>
          <span
            v-tooltip.top="
              !hasVariants && defaultVariant.inventory_quantity + ' in stock'
            "
            class="subtitle-s2"
          >
            {{ product.name }}
          </span>
        </div>
        <woot-spinner
          v-if="!hasVariants && isProductLoading"
          color="lime-green"
        />
        <div class="flex-row flex-justify--end flex-align">
          <span
            v-if="!hasVariants && defaultVariant.inventory_quantity < 1"
            class="out-of-stock"
          >
            {{ $t('PRODUCTS_MGMT.OUT_OF_STOCK') }}
          </span>
          <span v-if="hasVariants" style="z-index: -1">
            <woot-spinner
              v-if="areVariantLoading || isProductLoading"
              color="lime-green"
            />
            <icons
              v-else
              :name="showVariants ? 'chevronTop' : 'chevronDown'"
              color="green"
              size="semimedium"
              :show-title="false"
            />
          </span>
          <span v-else-if="!hasVariants" class="text-light product-price">
            {{ accountCurrency + product.sale_price }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="showVariants" class="variants-container">
      <div
        v-for="variant in variantsArr"
        :key="variant.id"
        class="variant flex-row flex-align flex-justify--between"
        :class="{ disable: variant.inventory_quantity < 1 }"
      >
        <div class="flex-row flex-align">
          <label
            v-if="variant.inventory_quantity > 0"
            style="cursor:pointer"
            class="container-checkbox"
          >
            <input
              v-model="selectedProducts"
              type="checkbox"
              :value="variant.id"
              @input="
                handleSelectedProducts({
                  productId: product.product_id,
                  variantId: variant.id,
                })
              "
            />
            <span class="checkmark" />
          </label>
          <span
            v-tooltip.top="variant.inventory_quantity + ' in stock'"
            class="subtitle-s2"
          >
            {{ variant.title }}
          </span>
        </div>
        <div class="flex-row flex-justify--end flex-align">
          <span v-if="variant.inventory_quantity < 1" class="out-of-stock">
            {{ $t('PRODUCTS_MGMT.OUT_OF_STOCK') }}
          </span>
          <span class="text-light product-price">
            {{ accountCurrency + variant.price }}
          </span>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';
import calculateDraftMixin from 'shared/mixins/calculateDraftMixin';

export default {
  mixins: [alertMixin, calculateDraftMixin],
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      variantsArr: [],
      selectedProducts: [],
      showVariants: false,
      areVariantLoading: false,
      isProductLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      productsInCartInCreateMode: 'getProductsInCart',
      productsInCartInEditMode: 'getProductsInCartInEditMode',
      draftOrderData: 'getDraftOrderMeta',
      orderMode: 'getCurrentOrderMode',
      currentChat: 'getSelectedChat',
      selectedAddress: 'getSelectedAddress',
      cartDetailsInCreateMode: 'getCartMeta',
      cartMetaInEditMode: 'getCartMetaInEditMode',
      customerAddresses: 'getCustomerAddresses',
    }),
    cartDetails() {
      if (this.orderMode === 'edit') return this.cartMetaInEditMode;
      return this.cartDetailsInCreateMode;
    },
    currentCartId() {
      return this.cartDetails.id;
    },
    accountCurrency() {
      return this.$store.getters['accounts/getAccountCurrency'](this.accountId);
    },
    hasVariants() {
      return this.defaultVariant.option1 !== 'Default Title';
    },
    defaultVariant() {
      return this.product.default_variant;
    },
    productsInCart() {
      if (this.orderMode === 'edit') return this.productsInCartInEditMode;
      return this.productsInCartInCreateMode;
    },
  },
  mounted() {
    this.selectedProducts = this.productsInCart?.map(
      product => +product.variant_item.shopify_variant_id
    );
  },
  methods: {
    async removeProductFromCart(item) {
      // remove product from cart if already in cart
      try {
        if (item) {
          this.isProductLoading = true;

          let assigneeId = null;
          try {
            assigneeId = this.currentChat?.meta?.assignee?.id;
            if (!assigneeId) {
              assigneeId = null;
            }
          } catch {
            assigneeId = null;
          }

          let payload = {
            cart: this.currentCartId,
            itemId: item.id,
            agentId: assigneeId,
            draftOrderId: this.draftOrderData?.id,
            conversationId: this.currentChat?.id,
          };

          if (this.orderMode === 'edit') {
            payload.isActualOrder = true;
            payload.orderId = this.cartDetails.orderId;
          }
          // remove item from cart
          await this.$store
            .dispatch('removeProductFromCart', payload)
            .then(() => {
              if (this.productsInCart.length && this.customerAddresses.length)
                this.calculateOrder();
              if (this.orderMode === 'edit') {
                this.$store.dispatch('fetchShopifyOrderDetail', {
                  order_id: this.cartDetails.orderId,
                });
              }
              this.isProductLoading = false;
              this.$emit('closeDropdown');
              this.showAlert('Item removed from cart', 'warning');
            })
            .catch(() => {
              this.isLoading = false;
              // call the clear cart api if last product is removed.
              if (this.orderMode !== 'edit') {
                this.$store
                  .dispatch('clearCart', {
                    draftOrderId: this.draftOrderData?.id,
                    cart: this.currentCartId,
                  })
                  .then(() => {
                    this.showAlert('Cart Refreshed successfully.', 'success');
                  });
              }
            });
        }
      } catch (error) {
        this.isProductLoading = false;
        this.showAlert('Error removing item from cart', 'error');
      }
    },
    addProductToCart(productInfo) {
      if (!this.currentCartId) {
        this.showAlert(
          'Please add phone number/e-mail for the customer from the contacts section',
          'error'
        );
        this.$emit('closeDropdown');
        return;
      }

      const item = this.productsInCart.find(
        product =>
          +product.variant_item?.shopify_variant_id === productInfo.variantId
      );

      if (item) {
        this.removeProductFromCart(item);
        return;
      }

      let assigneeId = null;
      try {
        assigneeId = this.currentChat?.meta?.assignee?.id;
        if (!assigneeId) {
          assigneeId = null;
        }
      } catch {
        assigneeId = null;
      }

      const payload = {
        cart: this.currentCartId,
        variant_item: productInfo.variantId,
        product_item_id: productInfo.productId,
        quantity: 1,
        agentId: assigneeId,
        conversationId: this.currentChat?.id,
      };

      if (this.orderMode === 'edit') {
        payload.isActualOrder = true;
        payload.orderId = this.cartDetails.orderId;
      }

      this.isProductLoading = true;
      try {
        this.$store.dispatch('addProductToCart', payload).then(() => {
          if (this.customerAddresses.length) this.calculateOrder();
          if (this.orderMode === 'edit') {
            this.$store.dispatch('fetchShopifyOrderDetail', {
              order_id: this.cartDetails.orderId,
            });
          }
          this.showAlert('Item added to cart', 'info');
          this.$emit('closeDropdown');
          this.isProductLoading = false;
        });
      } catch (error) {
        this.isProductLoading = false;
        this.showAlert('Error adding item to cart', 'error');
      }
    },
    handleSelectedProducts({ productId, variantId }) {
      this.addProductToCart({ variantId, productId });
      this.selectedProducts.push(variantId);
    },
    sortVariants(variants) {
      return variants.sort(variant => (variant.inventory_quantity ? -1 : 1));
    },
    loadVariants() {
      if (!this.hasVariants) return;

      if (this.showVariants) {
        this.showVariants = false;
        return;
      }

      this.areVariantLoading = true;

      this.$store
        .dispatch('getProductDetails', { productId: this.product.id })
        .then(response => {
          this.showVariants = true;
          this.areVariantLoading = false;
          this.variantsArr = this.sortVariants(response.data.shopify_variants);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.out-of-stock {
  color: $warn-red-400;
  font-weight: $font-weight-normal;
  font-size: $font-size-micro;
  line-height: $space-normal;
  overflow: hidden;
  padding: 0 $space-small 0 $space-micro;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.product {
  padding: $space-slab;

  .card__media {
    border: 1px solid $neutral-grey-400;
    border-radius: $border-radius-smaller;
    flex-shrink: 0;
    height: 2.6rem;
    margin-right: $space-small;
    overflow: hidden;
    width: 2.6rem;

    img {
      object-fit: cover;
    }
  }
}

.variant {
  margin-bottom: $space-normal;
  padding: 0 $space-slab 0 $space-normal;
}

.product-price {
  font-weight: $font-weight-normal;
  font-size: $font-size-micro;
  line-height: $space-normal;
}

.disable {
  pointer-events: none;
}

.container-checkbox {
  padding-left: 0;
  margin-bottom: 0;
  margin-right: $space-small;

  .checkmark {
    border-radius: $space-micro;
    position: relative;
  }
}

hr {
  background-color: $neutral-grey-200;
  margin: $zero $space-slab;
}
</style>
