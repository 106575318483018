<template>
  <div class="wizard-body small-12 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.TWO_FACTOR.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.TWO_FACTOR.DESC')"
    />

    <hr />

    <form class="row" @submit.prevent="createChannel()">
      <woot-input
        v-model.trim="channelName"
        :class="{ error: $v.channelName.$error }"
        :has-error="$v.channelName.$error"
        :error="$t('INBOX_MGMT.ADD.TWO_FACTOR.CHANNEL_NAME.ERROR')"
        class="medium-12 columns content-margin"
        :label="$t('INBOX_MGMT.ADD.TWO_FACTOR.CHANNEL_NAME.LABEL')"
        :placeholder="$t('INBOX_MGMT.ADD.TWO_FACTOR.CHANNEL_NAME.PLACEHOLDER')"
        @blur="$v.channelName.$touch"
      />

      <woot-input
        v-model.trim="phoneNumber"
        :class="{ error: $v.phoneNumber.$error }"
        :has-error="$v.phoneNumber.$error"
        :error="
          phoneNumber === ''
            ? $t('INBOX_MGMT.COMMON_ERROR_MESSAGE')
            : $t('INBOX_MGMT.ADD.TWO_FACTOR.PHONE_NUMBER.ERROR')
        "
        class="medium-12 columns content-margin"
        :label="$t('INBOX_MGMT.ADD.TWO_FACTOR.PHONE_NUMBER.LABEL')"
        :placeholder="$t('INBOX_MGMT.ADD.TWO_FACTOR.PHONE_NUMBER.PLACEHOLDER')"
        @blur="$v.phoneNumber.$touch"
      />

      <woot-input
        v-model.trim="senderId"
        :class="{ error: $v.senderId.$error }"
        :has-error="$v.senderId.$error"
        :error="$t('INBOX_MGMT.ADD.TWO_FACTOR.SENDER_ID.ERROR')"
        class="medium-12 columns content-margin"
        :label="$t('INBOX_MGMT.ADD.TWO_FACTOR.SENDER_ID.LABEL')"
        :placeholder="$t('INBOX_MGMT.ADD.TWO_FACTOR.SENDER_ID.PLACEHOLDER')"
        @blur="$v.senderId.$touch"
      />

      <woot-input
        v-model.trim="authToken"
        :class="{ error: $v.authToken.$error }"
        :has-error="$v.authToken.$error"
        :error="$t('INBOX_MGMT.ADD.TWO_FACTOR.AUTH_TOKEN.ERROR')"
        class="medium-12 columns content-margin"
        :label="$t('INBOX_MGMT.ADD.TWO_FACTOR.AUTH_TOKEN.LABEL')"
        :placeholder="$t('INBOX_MGMT.ADD.TWO_FACTOR.AUTH_TOKEN.PLACEHOLDER')"
        @blur="$v.authToken.$touch"
      />

      <div class="medium-12 columns">
        <woot-primary-button
          :loading="uiFlags.isCreating"
          :name="$t('INBOX_MGMT.ADD.TWO_FACTOR.SUBMIT_BUTTON')"
          :custom-style="{ float: 'right' }"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import validatePhoneNumber from '../validations';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      senderId: '',
      authToken: '',
      channelName: '',
      phoneNumber: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    channelName: { required },
    phoneNumber: { ...validatePhoneNumber.phone },
    authToken: { required },
    senderId: { required },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('AGENT_MGMT.FORM_ERROR'), 'error');
        return;
      }

      try {
        const twoFactorChannel = await this.$store.dispatch(
          'inboxes/createTwoFactorChannel',
          {
            two_factor_channel: {
              name: this.channelName,
              sender_id: this.senderId,
              auth_token: this.authToken,
              phone_number: this.phoneNumber,
            },
          }
        );

        const {
          id: inbox_id,
          channel_type,
          name: channel_name,
        } = twoFactorChannel;

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id,
          },
          query: { channel_type, channel_name },
        });
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.TWO_FACTOR.API.ERROR_MESSAGE'),
          'error'
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.form-label {
  padding: $zero $space-small $space-one;
  color: $neutral-grey-800;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: 22px;
}

.content-margin {
  margin-bottom: 2.4rem;
}

.medium-12 {
  .button {
    float: right;
  }
}
</style>
