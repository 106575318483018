<template>
  <transition name="menu-list">
    <div class="order">
      <div class="order--top flex-row flex-align">
        <div
          v-tooltip="!readOnly && item.inventory_quantity + ' in stock'"
          class="card__media"
        >
          <img :src="itemImage" />
        </div>
        <span style="width: 100%" class="title-h5 text-dark">
          {{ item.product.name }}
        </span>
      </div>
      <div
        v-if="item.title && item.title !== 'Default Title'"
        class="order--desc body-b2 text-dark"
      >
        {{ item.title }}
      </div>
      <div class="order--bottom flex-row flex-align flex-justify--between">
        <span v-if="readOnly" class="body-b3 text-light">
          {{ 'Quantity ' + itemQty }}
        </span>
        <span class="body-b2 text-dark">
          {{ accountCurrency + item.price }}
        </span>
        <div
          v-if="!readOnly"
          class="order--controls flex-row flex-align"
          :class="{
            'order--controls--disable': isLoading || creatingDraftOrder,
          }"
        >
          <span
            :class="itemQty === 1 && 'control--delete'"
            @click="decreaseCount"
          >
            <icons
              name="circleMinus"
              :color="itemQty === 1 ? 'stroke-warn' : 'stroke-green'"
              size="normal"
              :title="itemQty === 1 ? 'Remove' : 'Add less'"
            />
          </span>
          <woot-spinner v-if="isLoading" color="lime-green" />
          <span v-else class="count-badge--container">
            <span class="count-badge flex-row flex-align flex-justify">
              <span class="title-h6">
                {{ productQuantity }}
              </span>
            </span>
          </span>
          <span @click="increaseCount">
            <icons
              name="circleAdd"
              color="stroke-green"
              size="normal"
              title="Add more"
            />
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';
import calculateDraftMixin from 'shared/mixins/calculateDraftMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  mixins: [alertMixin, calculateDraftMixin, mixPanelAnalyticsMixin],
  props: {
    orderData: {
      type: Object,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productQuantity: this.orderData.quantity,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      cartDetailsInCreateMode: 'getCartMeta',
      currentChat: 'getSelectedChat',
      cartDetailsInEditMode: 'getCartMetaInEditMode',
      selectedAddress: 'getSelectedAddress',
      draftOrderData: 'getDraftOrderMeta',
      orderMode: 'getCurrentOrderMode',
      creatingDraftOrder: 'getDraftOrderUIFlag',
      productsInCartInCreateMode: 'getProductsInCart',
      productsInCartInEditMode: 'getProductsInCartInEditMode',
      customerAddresses: 'getCustomerAddresses',
    }),
    productsInCart() {
      if (this.orderMode === 'edit') return this.productsInCartInEditMode;
      return this.productsInCartInCreateMode;
    },
    cartDetails() {
      if (this.orderMode === 'edit') return this.cartDetailsInEditMode;
      return this.cartDetailsInCreateMode;
    },
    currentCartId() {
      return this.cartDetails.id;
    },
    accountCurrency() {
      return this.$store.getters['accounts/getAccountCurrency'](this.accountId);
    },
    item() {
      return this.orderData.variant_item;
    },
    itemQty() {
      return this.orderData.quantity;
    },
    itemImage() {
      return (
        this.orderData.variant_item.image_link ||
        this.orderData.variant_item?.product?.default_image?.src
      );
    },
  },
  methods: {
    updateQuantity(qty) {
      this.isLoading = true;
      let assigneeId = null;
      try {
        assigneeId = this.currentChat?.meta?.assignee?.id;
        if (!assigneeId) {
          assigneeId = null;
        }
      } catch {
        assigneeId = null;
      }

      const payload = {
        cart: this.currentCartId,
        variantId: this.orderData.id,
        quantity: qty,
        agentId: assigneeId,
        conversationId: this.currentChat?.id,
      };

      if (this.orderMode === 'edit') {
        payload.isActualOrder = true;
        payload.orderId = this.cartDetails.orderId;
      }

      this.$store
        .dispatch('updateProductQuantity', payload)
        .then(response => {
          this.isLoading = false;
          this.productQuantity = response.data.quantity;

          if (this.customerAddresses.length) this.calculateOrder();

          if (this.orderMode === 'edit') {
            this.mixPanelOrderEdited();
            this.showAlert('Order updated successfully', 'success');
          }
        })
        .then(() => {
          if (this.orderMode === 'edit')
            this.$store
              .dispatch('fetchShopifyOrderDetail', {
                order_id: this.cartDetails.orderId,
              })
              .catch(() => {
                this.isLoading = false;
              });
        })
        .catch(err => {
          this.isLoading = false;
          let errResponse = JSON.parse(err.message);

          const errorMsg = errResponse.fields.length
            ? errResponse.fields[0]
            : errResponse.message;

          if (this.orderMode === 'edit') {
            this.showAlert('Order not updated', 'error');
          }

          this.showAlert(errorMsg, 'error', 10000);
        });
    },
    increaseCount() {
      const updatedQuantity = this.itemQty + 1;
      if (updatedQuantity > this.item.inventory_quantity) {
        this.showAlert('Cannot add more of this item', 'warning');
        return;
      }
      this.updateQuantity(updatedQuantity);
    },
    decreaseCount() {
      const updatedQuantity = this.itemQty - 1;
      if (updatedQuantity === 0) {
        this.removeItemFromCart();
        return;
      }

      this.updateQuantity(updatedQuantity);
    },
    removeItemFromCart() {
      this.isLoading = true;
      let assigneeId = null;
      try {
        assigneeId = this.currentChat?.meta?.assignee?.id;
        if (!assigneeId) {
          assigneeId = null;
        }
      } catch {
        assigneeId = null;
      }

      let payload = {
        cart: this.currentCartId,
        itemId: this.orderData.id,
        draftOrderId: this.draftOrderData?.id,
        agentId: assigneeId,
        conversationId: this.currentChat?.id,
      };

      if (this.orderMode === 'edit') {
        payload.isActualOrder = true;
        payload.orderId = this.cartDetails.orderId;
      }

      try {
        this.$store
          .dispatch('removeProductFromCart', payload)
          .then(response => {
            if (response.status === 200) {
              this.isLoading = false;
              if (this.productsInCart.length && this.customerAddresses.length) {
                this.calculateOrder();
              }
              this.showAlert('Item removed from cart', 'warning');
            } else {
              this.isLoading = false;
              this.showAlert('Error removing item from cart', 'error');
            }
          })
          .catch(() => {
            this.isLoading = false;
            // call the clear cart api if last product is removed.
            if (this.orderMode !== 'edit') {
              this.$store
                .dispatch('clearCart', {
                  draftOrderId: this.draftOrderData?.id,
                  cart: this.currentCartId,
                })
                .then(() => {
                  this.showAlert('Cart Refreshed successfully.', 'success');
                });
            }
          });
      } catch (error) {
        // handle error
        this.isLoading = false;
        this.showAlert('Error removing item from cart', 'error');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.order {
  margin-bottom: $space-slab;

  &--desc {
    padding-left: 3.4rem;
  }

  &--bottom {
    padding-left: 3.4rem;
  }
}

.card__media {
  @include elevation-1dp;
  border-radius: $border-radius-smaller;
  width: 2.6rem;
  height: 2.6rem;
  margin-right: $space-small;
  overflow: hidden;

  img {
    object-fit: cover;
  }
}

.order--controls {
  &--disable {
    pointer-events: none;
    opacity: 0.8;
  }

  .control--delete {
    ::v-deep .icon {
      stroke-width: 2.5;

      &:hover {
        stroke: $warn-red-500;
      }

      &:active {
        stroke: red;
      }
    }
  }

  > span {
    &:first-child {
      cursor: pointer;
      padding: 0 $space-one;
    }

    &:last-child {
      cursor: pointer;
      padding-left: $space-one;
    }

    ::v-deep .icon {
      stroke-width: 2.5;

      &:hover {
        stroke: $pg-1-600;
      }

      &:active {
        stroke: $pg-1-400;
      }
    }
  }

  .count-badge--container {
    padding: 0 0.5rem;

    .count-badge {
      background: $pg-1;
      border-radius: 50%;
      color: $neutral-white;
      height: $space-two;
      padding: $space-micro $space-smaller;
      user-select: none;
      width: $space-two;
    }
  }
}
</style>
