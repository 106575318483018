<template>
  <woot-modal
    :show.sync="opened"
    :custom-style="{ width: '600px' }"
    :on-close="() => $emit('onClose')"
  >
    <woot-modal-header :header-title="$t('AUTOMATION.ADD.FORM.LABEL')" />
    <form class="modal-body" @submit.prevent="$emit('onSubmit')">
      <woot-input
        size="small"
        :value="name"
        :label="$t('AUTOMATION.ADD.FORM.NAME.LABEL')"
        type="text"
        auto-focus
        required
        :has-error="$v.name.$error"
        class="mg-bottom"
        :class="{ error: $v.name.$error }"
        :error="nameError"
        :placeholder="$t('AUTOMATION.ADD.FORM.NAME.PLACEHOLDER')"
        @input="$emit('updateDetail', { name: $event })"
        @blur="$v.name.$touch"
      />
      <woot-input
        size="small"
        :value="description"
        :label="$t('AUTOMATION.ADD.FORM.DESC.LABEL')"
        tag="textarea"
        class="content-margin"
        rows="3"
        full-width
        :placeholder="$t('AUTOMATION.ADD.FORM.DESC.PLACEHOLDER')"
        @input="$emit('updateDetail', { description: $event })"
      />
      <div class="modal-body--footer flex-row flex-justify--end">
        <woot-base-button size="small" type="submit" :disabled="$v.$invalid">
          Continue
        </woot-base-button>
      </div>
    </form>
  </woot-modal>
</template>
<script>
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  validations: {
    name: {
      required,
    },
  },
  computed: {
    nameError() {
      return this.$v.name.$error
        ? this.$t('AUTOMATION.ADD.FORM.NAME.ERROR')
        : '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.modal-body {
  padding: $space-normal $space-medium;
}
</style>
