<template>
  <div
    class="lime-card-1dp flex-row flex-align--start flex-justify--start email-subject-container"
  >
    <span>
      <icons name="email" color="darkestgrey" size="medium" />
    </span>
    <span
      class="mail-subject title-h4_m text-dark custom-scroll"
      v-html="mailSubject"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    mailSubject() {
      const {
        additional_attributes: { mail_subject },
      } = this.currentChat;
      return mail_subject || '------no subject------';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.email-subject-container {
  z-index: 1;
  margin: $space-small $space-normal $space-smaller;
  padding: $space-small;
  gap: $space-one;
  box-shadow: none;
  border: 1px solid $neutral-grey-300;

  .mail-subject {
    max-height: $space-small * 10;
  }
}
</style>
