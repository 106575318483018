<template>
  <woot-modal
    small-modal
    has-transition
    close-on-backdrop-click
    show
    :on-close="closePrivateReplyModal"
  >
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.TITLE')"
        :header-content="$t('CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.DESC')"
      />
      <form class="row settings-form" @submit.prevent="sendPrivateReply">
        <woot-input
          v-model.trim="privateReplyContent"
          auto-focus
          class="medium-12 columns content-margin"
          :class="{ error: $v.privateReplyContent.$error }"
          :error="
            $t('CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.CONTENT.VALIDATION')
          "
          :has-error="$v.privateReplyContent.$error"
          :label="$t('CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.CONTENT.LABEL')"
          :help-text="
            $t('CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.CONTENT.HELP_TEXT')
          "
          :placeholder="
            $t('CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.CONTENT.PLACEHOLDER')
          "
          @input="$v.privateReplyContent.$touch"
          @blur="$v.privateReplyContent.$touch"
        />

        <div class="modal-footer gap--small">
          <woot-base-button
            variant="tertiary-danger"
            @click.prevent="closePrivateReplyModal"
          >
            {{ $t('CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.CANCEL') }}
          </woot-base-button>
          <woot-base-button
            front-icon="peopleFilled"
            :disabled="$v.privateReplyContent.$error"
            :loading="uiFlags.creatingItem"
            type="submit"
          >
            {{ $t('CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.SEND') }}
          </woot-base-button>
        </div>
      </form>
    </div>
  </woot-modal>
</template>
<script>
import { mapGetters } from 'vuex';

import { required } from 'vuelidate/lib/validators';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      privateReplyContent: '',
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      uiFlags: 'storeNotifications/getUIFlags',
    }),
    postType() {
      let postType = this.type.split('_')[0];
      return postType === 'comment' ? 'facebook' : postType;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](
        this.currentChat.meta?.sender?.id
      );
    },
  },
  validations: {
    privateReplyContent: {
      required,
    },
  },
  methods: {
    closePrivateReplyModal() {
      this.privateReplyContent = '';
      this.$emit('togglePrivateReply', false);
    },
    async sendPrivateReply() {
      try {
        await this.$store.dispatch('storeNotifications/sendPrivateReply', {
          medium: this.postType === 'facebook' ? 'pages' : this.postType,
          source_id: this.message.source_id,
          contact_id: this.contact.id,
          conversation_id: this.currentChat.id,
          inbox_id: this.currentChat.inbox_id,
          content: this.privateReplyContent,
        });
        this.closePrivateReplyModal();
        this.showAlert(
          this.$t('CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.SUCCESS'),
          'success'
        );
      } catch (error) {
        this.showAlert(
          this.$t('CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.ERROR'),
          'error'
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-form {
  padding: $zero $space-medium;
}
</style>
