/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';

class OrdersApi extends ApiClient {
  constructor() {
    super('integrations', { accountScoped: true });
  }

  get({ startDate, endDate, inCSV }) {
    return axios.post(`${this.url}/attendance/attendance_metrics`, {
      start_date: startDate,
      end_date: endDate,
      in_csv: inCSV,
    });
  }

  getLogs({ startDate, endDate, inCSV, userId }) {
    return axios.post(`${this.url}/attendance/attendance_metrics`, {
      start_date: startDate,
      end_date: endDate,
      in_csv: inCSV,
      user_id: userId,
    });
  }

  download_overall_attendance({ startDate, endDate, email }) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/download_report`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          email: email,
          scope: 'attendance',
        },
      }
    );
  }

  download_agent_attendance({ startDate, endDate, userId, email }) {
    return axios.get(
      `${this.baseUrl()}/integrations/analytics/download_report`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          agent_id: userId,
          email: email,
          scope: 'summary',
        },
      }
    );
  }
}

export default new OrdersApi();
