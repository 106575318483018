var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('woot-single-selector',{attrs:{"variant":"tertiary","size":"large","custom-button":true,"top-position":{ top: undefined },"custom-style":{
    width: '100%',
    left: '0.01rem',
    bottom: '0.4rem',
    maxHeight: '10rem',
    borderRadius: '0.4rem',
    top: null,
  },"hide-on-select":false,"option-list":_vm.standardVariables,"is-dropdown-shown":""},on:{"click":_vm.onSelectOption}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }