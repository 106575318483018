<template>
  <div
    v-if="showCaseDatum"
    class="educational-onboarding-wrapper flex-column flex-align mg-bottom--medium"
    @mouseenter="stopSlider"
    @mouseleave="startSlider"
  >
    <div
      class="profile-viewer full-width"
      :style="{
        backgroundImage: 'url(' + showCaseDatum.image + ')',
        backgroundColor: showCaseDatum.color || '#4267B2',
      }"
    />
    <div class="information-container">
      <div class="information-slider flex-row flex-justify--between flex-align">
        <span
          class="title-h1 text-dark capitalize"
          v-text="showCaseDatum.name"
        />
        <div v-if="showCaseData.length > 1" class="slide flex-row">
          <span
            v-for="(el, idx) in showCaseData.length"
            :key="idx"
            style="cursor: pointer"
            :class="{ capsule: idx === currIndex }"
            @click="currIndex = idx"
          >
            <span class="capsule-green" />
          </span>
        </div>
      </div>
      <div
        :style="contentStyles"
        class="body-b3 text-light custom-scroll"
        v-html="
          $t(
            `${showCaseDatum.contentPath}.EDUCATIONAL_ONBOARDING.${showCaseDatum.key}.DESC`
          )
        "
      />
      <div
        class="title-h4 text-dark mg-bottom--small mg-top--slab"
        v-text="
          $t(
            `${showCaseDatum.contentPath}.EDUCATIONAL_ONBOARDING.${showCaseDatum.key}`
          ).LIST_TITLE || ''
        "
      />
      <!-- access list -->
      <transition name="modal-fade" mode="out-in" appear>
        <ul v-if="isListAvailable" :key="forceRerender()" class="slide-in">
          <li
            v-for="listItem in $t(
              `${showCaseDatum.contentPath}.EDUCATIONAL_ONBOARDING.${showCaseDatum.key}.LIST`
            )"
            :key="listItem"
            style="gap: 0.8rem"
            class="access-list flex-row"
          >
            <icons name="tickSingle" color="green" view="0 0 16 16" />
            <span class="body-b3 text-light" v-text="listItem" />
          </li>
        </ul>
        <img
          v-if="showCaseDatum.asset"
          :src="showCaseDatum.asset"
          class="full-width"
          alt="illustration"
        />
      </transition>
    </div>
    <slot />
  </div>
</template>
<script>
import { preloadImage } from '../../../helper/preloadImages';

export default {
  props: {
    showCaseData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currIndex: 0,
      slidingInterval: null,
    };
  },
  computed: {
    isListAvailable() {
      return this.$t(
        `${this.showCaseDatum.contentPath}.EDUCATIONAL_ONBOARDING.${this.showCaseDatum.key}`
      ).LIST;
    },
    hasSlot() {
      return this.$slots?.default?.length || false;
    },
    contentStyles() {
      let computedHt = 'auto';
      if (this.hasSlot && this.isListAvailable) computedHt = '10vh';
      return `height: ${computedHt}`;
    },
    showCaseDatum() {
      return (
        (this.showCaseData?.length && this.showCaseData[this.currIndex]) || null
      );
    },
  },
  created() {
    const imagesArrayToPreload = this.showCaseData.map(data => data.image);
    preloadImage(imagesArrayToPreload);
  },
  mounted() {
    this.startSlider();
  },
  destroyed() {
    this.stopSlider();
  },
  methods: {
    forceRerender() {
      return Math.random();
    },
    initSlider() {
      this.slidingInterval = setInterval(() => {
        this.currIndex = (this.currIndex + 1) % this.showCaseData.length;
      }, 2400);
    },
    startSlider() {
      if (this.showCaseData.length) this.initSlider();
    },
    stopSlider() {
      clearInterval(this.slidingInterval);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.educational-onboarding-wrapper {
  padding: $space-small;
}

.profile-viewer {
  background: $facebook-blue;
  background-size: cover;
  background-position: center;
  border-radius: $border-radius-smaller;
  height: $space-two * 10;
  transition: background 0.3s ease-in-out;
}

.information-slider {
  flex-wrap: wrap;
  margin-bottom: $space-two;

  .slide {
    gap: $space-smaller;

    .capsule {
      background: $neutral-grey-400;
      position: relative;

      .capsule-green {
        background: $pg-1;
        position: absolute;
      }
    }

    span {
      background: $neutral-grey-300;
      border-radius: $border-radius;
      height: $space-small;
      position: relative;
      width: $space-normal;
    }
  }
}

.information-container {
  padding: $space-normal;

  .access-list {
    margin: $space-slab $space-smaller;
  }
}
</style>
