<template>
  <div class="columns content-box settings-box">
    <div class="settings-container column custom-scroll lime-card-1dp mr-right">
      <woot-base-button
        class="settings-button"
        :loading="isUpdating"
        @click="updateAccount"
      >
        {{ $t('GENERAL_SETTINGS.SUBMIT') }}
      </woot-base-button>
      <div v-if="!uiFlags.isFetchingItem" class="small-12 columns">
        <page-header header-title="Company Account Details" />
        <form class="settings-sub-menu-content--larger medium-8">
          <div class="flex-row full-width gap--normal">
            <woot-input
              v-model.trim="name"
              required
              class="full-width content-margin"
              :class="{ error: $v.name.$error }"
              :has-error="$v.name.$error"
              :error="$t('GENERAL_SETTINGS.FORM.NAME.ERROR')"
              :label="$t('GENERAL_SETTINGS.FORM.NAME.LABEL')"
              :placeholder="$t('GENERAL_SETTINGS.FORM.NAME.PLACEHOLDER')"
              @input="$v.name.$touch"
            />
            <woot-input
              v-model.trim="websiteUrl"
              class="full-width content-margin"
              :class="{ error: $v.websiteUrl.$error }"
              :has-error="$v.websiteUrl.$error"
              :error="$t('GENERAL_SETTINGS.FORM.WEBSITE_URL.ERROR')"
              :label="$t('GENERAL_SETTINGS.FORM.WEBSITE_URL.LABEL')"
              :placeholder="$t('GENERAL_SETTINGS.FORM.WEBSITE_URL.PLACEHOLDER')"
              @input="$v.websiteUrl.$touch"
            />
          </div>

          <woot-input
            v-model.trim="currency"
            required
            class="medium-12 columns content-margin"
            :class="{ error: $v.currency.$error }"
            :has-error="$v.currency.$error"
            :error="$t('GENERAL_SETTINGS.FORM.CURRENCY.ERROR')"
            :label="$t('GENERAL_SETTINGS.FORM.CURRENCY.LABEL')"
            :placeholder="$t('GENERAL_SETTINGS.FORM.CURRENCY.PLACEHOLDER')"
            :help-text="$t('GENERAL_SETTINGS.FORM.CURRENCY.INFO_TEXT')"
            @input="$v.currency.$touch"
          />

          <div class="medium-12 columns">
            <label class="formlabel" :class="{ error: $v.locale.$error }">
              <span
                class="title-h5 text-dark"
                v-text="$t('GENERAL_SETTINGS.FORM.LANGUAGE.LABEL')"
              />
            </label>
            <woot-single-selector
              size="large"
              :option-list="languagesSortedByCode"
              :custom-style="{ width: '100%' }"
              :default-option="getLangName"
              @click="changeLanguage"
            />

            <span class="desc-text">
              {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.ERROR') }}
            </span>
          </div>

          <label v-if="featureInboundEmailEnabled" class="formlabel">
            <span
              class="title-h5 text-dark"
              v-text="
                $t('GENERAL_SETTINGS.FORM.FEATURES.INBOUND_EMAIL_ENABLED')
              "
            />
          </label>

          <label v-if="featureCustomDomainEmailEnabled" class="formlabel">
            <span
              class="title-h5 text-dark"
              v-text="
                $t('GENERAL_SETTINGS.FORM.FEATURES.CUSTOM_EMAIL_DOMAIN_ENABLED')
              "
            />
          </label>

          <woot-input
            v-if="featureCustomDomainEmailEnabled"
            v-model.trim="domain"
            class="medium-12 columns content-margin"
            :label="$t('GENERAL_SETTINGS.FORM.DOMAIN.LABEL')"
            :placeholder="$t('GENERAL_SETTINGS.FORM.DOMAIN.PLACEHOLDER')"
          />

          <woot-input
            v-if="featureCustomDomainEmailEnabled"
            v-model.trim="supportEmail"
            class="medium-12 columns content-margin"
            :label="$t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.LABEL')"
            :placeholder="$t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.PLACEHOLDER')"
          />

          <page-header
            :header-title="$t('GENERAL_SETTINGS.FORM.HIDE_ALL_TAB.TITLE')"
            size="small"
          >
            <woot-switch
              :id="Math.random()"
              :value="hideAllTab"
              @click="() => (hideAllTab = !hideAllTab)"
            />
          </page-header>

          <page-header
            :header-title="$t('GENERAL_SETTINGS.FORM.HIDE_QUEUED_TAB.TITLE')"
            size="small"
          >
            <woot-switch
              :id="Math.random()"
              :value="hideQueuedTab"
              @click="() => (hideQueuedTab = !hideQueuedTab)"
            />
          </page-header>

          <page-header
            :header-title="$t('GENERAL_SETTINGS.FORM.HIDE_BOT_TICKETS.TITLE')"
            size="small"
          >
            <woot-switch
              id="hide-bot-switch"
              :value="hideBotConversations"
              @click="() => (hideBotConversations = !hideBotConversations)"
            />
          </page-header>
          <page-header
            :header-title="$t('GENERAL_SETTINGS.FORM.ENFORCE_TAGGING.TITLE')"
            size="small"
          >
            <woot-switch
              id="enforce-tagging"
              :value="enforceTagging"
              @click="() => (enforceTagging = !enforceTagging)"
            />
          </page-header>
          <page-header
            :header-title="$t('GENERAL_SETTINGS.FORM.HIDE_OUT_OF_STOCK.TITLE')"
            size="small"
          >
            <woot-switch
              id="hide-out-of-stock"
              :value="hideOutOfStock"
              @click="() => (hideOutOfStock = !hideOutOfStock)"
            />
          </page-header>
          <page-header
            :header-title="$t('GENERAL_SETTINGS.FORM.APPLY_PII_MASK.TITLE')"
            size="small"
          >
            <woot-switch
              id="apply-pii-mask"
              :value="applyPiiMask"
              @click="() => (applyPiiMask = !applyPiiMask)"
            />
          </page-header>
        </form>
        <div class="current-version body-b3 text-light">
          <div>{{ `v${globalConfig.appVersion}` }}</div>
          <div v-if="hasAnUpdateAvailable && globalConfig.displayManifest">
            {{
              useInstallationName(
                $t('GENERAL_SETTINGS.UPDATE_CHATWOOT', {
                  latestChatwootVersion: latestChatwootVersion,
                })
              )
            }}
          </div>
        </div>
      </div>
      <loading-state v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minValue, url } from 'vuelidate/lib/validators';

import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';
import LoadingState from 'dashboard/components/ui/LoadingState';

import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import accountMixin from '../../../../mixins/account';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import * as types from 'shared/constants/googleEventType';

const semver = require('semver');

export default {
  name: 'AccountName',
  components: { PageHeader, LoadingState },
  mixins: [
    accountMixin,
    alertMixin,
    configMixin,
    globalConfigMixin,
    googleAnalyticsMixin,
    mixPanelAnalyticsMixin,
  ],
  data() {
    return {
      id: '',
      name: '',
      websiteUrl: '',
      currency: '',
      locale: 'en',
      domain: '',
      supportEmail: '',
      features: {},
      autoResolveDuration: null,
      latestChatwootVersion: null,
      hideAllTab: false,
      hideQueuedTab: false,
      hideBotConversations: false,
      enforceTagging: false,
      hideOutOfStock: false,
      applyPiiMask: false,
    };
  },
  validations: {
    name: {
      required,
    },
    websiteUrl: {
      url,
    },
    currency: {
      required,
    },
    locale: {
      required,
    },
    autoResolveDuration: {
      minValue: minValue(1),
    },
    hideAllTab: {
      required: true,
    },
    hideQueuedTab: {
      required: true,
    },
    hideBotConversations: {
      required: true,
    },
    hideOutOfStock: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      getAccount: 'accounts/getAccount',
      uiFlags: 'accounts/getUIFlags',
    }),
    hasAnUpdateAvailable() {
      if (!semver.valid(this.latestChatwootVersion)) {
        return false;
      }

      return semver.lt(
        this.globalConfig.appVersion,
        this.latestChatwootVersion
      );
    },
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
    isUpdating() {
      return this.uiFlags.isUpdating;
    },
    featureInboundEmailEnabled() {
      return !!this.features.inbound_emails;
    },
    featureCustomDomainEmailEnabled() {
      return this.featureInboundEmailEnabled && !!this.customEmailDomainEnabled;
    },
    getLangName() {
      let selectedIndex = this.languagesSortedByCode.findIndex(
        i => i.iso_639_1_code === this.locale
      );

      return this.languagesSortedByCode[selectedIndex].name;
    },
  },
  mounted() {
    if (!this.id) this.initializeAccount();
  },
  methods: {
    changeLanguage(val) {
      this.googleAnalyticsEvent(
        types.default.CHANGE_LANGUAGE,
        types.default.ACCOUNT_SETTINGS,
        types.default.ACCOUNT_SETTINGS
      );
      this.locale = val.iso_639_1_code;
    },
    async initializeAccount() {
      try {
        await this.$store.dispatch('accounts/get');
        const {
          name,
          website_url,
          locale,
          id,
          domain,
          currency,
          support_email,
          custom_email_domain_enabled,
          features,
          auto_resolve_duration,
          latest_chatwoot_version: latestChatwootVersion,
          hide_all_tab,
          hide_queued_tab,
          hide_bot_conversations,
          enforce_tagging,
          hide_out_of_stock,
          contact_masking,
        } = this.getAccount(this.accountId);

        this.$root.$i18n.locale = locale;
        this.name = name;
        this.websiteUrl = website_url;
        this.currency = currency;
        this.locale = locale;
        this.id = id;
        this.domain = domain;
        this.supportEmail = support_email;
        this.customEmailDomainEnabled = custom_email_domain_enabled;
        this.features = features;
        this.autoResolveDuration = auto_resolve_duration;
        this.latestChatwootVersion = latestChatwootVersion;
        this.hideAllTab = hide_all_tab;
        this.hideQueuedTab = hide_queued_tab;
        this.hideBotConversations = hide_bot_conversations;
        this.enforceTagging = enforce_tagging;
        this.hideOutOfStock = hide_out_of_stock;
        this.applyPiiMask = contact_masking;
      } catch (error) {
        // Ignore error
      }
    },
    async updateAccount() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('GENERAL_SETTINGS.FORM.ERROR'), 'error');
        return;
      }
      try {
        this.googleAnalyticsEvent(
          types.default.UPDATE_ACCOUNT,
          types.default.ACCOUNT_SETTINGS,
          types.default.ACCOUNT_SETTINGS
        );
        const response = await this.$store.dispatch('accounts/update', {
          locale: this.locale,
          name: this.name,
          website_url: this.websiteUrl,
          currency: this.currency,
          domain: this.domain,
          support_email: this.supportEmail,
          auto_resolve_duration: this.autoResolveDuration,
          hide_all_tab: this.hideAllTab,
          hide_queued_tab: this.hideQueuedTab,
          hide_bot_conversations: this.hideBotConversations,
          enforce_tagging: this.enforceTagging,
          hide_out_of_stock: this.hideOutOfStock,
          contact_masking: this.applyPiiMask,
        });

        this.$store.dispatch('accounts/setAccount', response);
        this.$store.dispatch('accounts/setUiVisibility', response);
        this.$root.$i18n.locale = this.locale;
        this.mix_panel_clicked_settings_save_account_settings();
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.SUCCESS'), 'success');
      } catch (error) {
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.ERROR'), 'error');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

.settings-box {
  padding-top: $space-largest;
  padding-left: 0.1rem;
  overflow: hidden;

  .lime-card-1dp {
    padding: $zero;

    .small-12 {
      position: relative;
    }
  }

  .settings-container {
    height: 100%;
    margin: $zero;
  }
}

.content-margin {
  margin-bottom: $space-medium;
}

.current-version {
  bottom: $zero;
  padding: $space-slab;
  position: absolute;
  text-align: center;
  width: 100%;
}
</style>
