import {
  METRICS,
  METRICS_OPTIONS,
  NOTIFICATION_TYPES,
  TRIGGERS,
} from './constants';

const getDefaultSla = () => {
  return {
    name: '',
    description: '',
    active: true,
    conditions: {},
    metrics: {
      [METRICS.FIRST_RESPONSE_TIME]: {
        time: 0,
        exclude_out_of_office_hours: true,
      },
      [METRICS.NEXT_RESPONSE_TIME]: {
        time: 0,
        exclude_out_of_office_hours: true,
      },
      [METRICS.RESOLUTION_TIME]: {
        time: 0,
        exclude_out_of_office_hours: true,
      },
    },
    reminders: {},
    escalations: {},
  };
};

export const manifestSlaObject = sla => {
  const defaultSla = getDefaultSla();

  return {
    name: sla.name || defaultSla.name,
    description: sla.description || defaultSla.description,
    active: sla.active !== undefined ? sla.active : defaultSla.active,
    conditions: sla.conditions || defaultSla.conditions,
    metrics: {
      [METRICS.FIRST_RESPONSE_TIME]: {
        ...defaultSla.metrics[METRICS.FIRST_RESPONSE_TIME],
        ...(sla.metrics && sla.metrics[METRICS.FIRST_RESPONSE_TIME]),
      },
      [METRICS.NEXT_RESPONSE_TIME]: {
        ...defaultSla.metrics[METRICS.NEXT_RESPONSE_TIME],
        ...(sla.metrics && sla.metrics[METRICS.NEXT_RESPONSE_TIME]),
      },
      [METRICS.RESOLUTION_TIME]: {
        ...defaultSla.metrics[METRICS.RESOLUTION_TIME],
        ...(sla.metrics && sla.metrics[METRICS.RESOLUTION_TIME]),
      },
    },
    reminders: sla.reminders || defaultSla.reminders,
    escalations: sla.escalations || defaultSla.escalations,
  };
};

// Helper function to build metrics if they exist and have a valid time
const buildMetrics = (sla, metricKey) => {
  const defaultSla = getDefaultSla();

  return sla.metrics?.[metricKey]?.time !== 0
    ? {
        ...defaultSla.metrics[metricKey],
        ...sla.metrics[metricKey],
      }
    : null;
};

export const manifestPayload = sla => {
  const defaultSla = getDefaultSla();

  const metrics = METRICS_OPTIONS.map(metric =>
    metric.value.toUpperCase()
  ).reduce((acc, key) => {
    const metric = buildMetrics(sla, METRICS[key]);
    if (metric) acc[METRICS[key]] = metric;
    return acc;
  }, {});

  const payload = {
    name: sla.name || defaultSla.name,
    description: sla.description || defaultSla.description,
    active: sla.active ?? defaultSla.active,
    conditions: sla.conditions || defaultSla.conditions,
    reminders: sla.reminders || defaultSla.reminders,
    escalations: sla.escalations || defaultSla.escalations,
    ...(Object.keys(metrics).length > 0 && { metrics }),
  };

  return payload;
};

const getTriggerObject = trigger => {
  return {
    [trigger]: {
      values: [],
    },
  };
};

const getMetricObject = metric => {
  return {
    [metric]: {
      time: 0,
      exclude_out_of_office_hours: false,
    },
  };
};

const getReminderObject = metric => {
  return {
    [metric]: {
      before: 30,
      action: [NOTIFICATION_TYPES.SEND_IN_APP_AND_EMAIL_NOTIFICATION],
      data: [],
    },
  };
};

const getEscalationObject = metric => {
  return {
    [metric]: {
      action: [
        {
          after: 0,
          type: NOTIFICATION_TYPES.SEND_IN_APP_AND_EMAIL_NOTIFICATION,
          data: [],
        },
      ],
    },
  };
};

function getObjectByKeyType(key, keyType) {
  switch (keyType) {
    case 'conditions':
      return getTriggerObject(key);
    case 'metrics':
      return getMetricObject(key);
    case 'reminders':
      return getReminderObject(key);
    case 'escalations':
      return getEscalationObject(key);
    default:
      return {};
  }
}

export function onSelectKey(data, selectedKey, currentKey, keyType) {
  if (selectedKey === 'select') return null; // Do nothing if 'select' is chosen

  const newObject = getObjectByKeyType(selectedKey, keyType);

  const updatedData = {
    ...newObject[selectedKey],
  };

  const newKeyObject = {
    ...data[keyType],
    [selectedKey]: updatedData,
  };

  delete newKeyObject[currentKey];

  return {
    ...data,
    [keyType]: newKeyObject,
  };
}

export function onAddKey(data, keyType) {
  const defaultKey = 'select';

  if (!data[keyType][defaultKey]) {
    const newObject = getObjectByKeyType(defaultKey, keyType);

    return {
      ...data,
      [keyType]: {
        ...data[keyType],
        ...newObject,
      },
    };
  }
  return null;
}

export const getOptions = ({ agents, inboxes, teams, type, labels }) => {
  const conditionFilterMaps = {
    [TRIGGERS.ASSIGNEE]: agents,
    [TRIGGERS.INBOX]: inboxes,
    [TRIGGERS.TEAM]: teams,
    [TRIGGERS.LABEL]: labels,
  };

  return conditionFilterMaps[type];
};
