/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';

class CustomersApi extends ApiClient {
  constructor() {
    super('integrations', { accountScoped: true });
  }

  getCustomer({ customerId }) {
    return axios.get(`${this.url}/customers/customer_detail`, {
      params: { customer_id: customerId },
    });
  }

  addCustomerAddress(data) {
    return axios.post(`${this.url}/customers/create_customer_address`, data);
  }

  updateCustomerAddress(data) {
    return axios.post(`${this.url}/customers/update_customer_address`, data);
  }

  deleteCustomerAddress({ addressId }) {
    return axios.post(`${this.url}/customers/delete_customer_address`, {
      address_id: addressId,
    });
  }
}

export default new CustomersApi();
