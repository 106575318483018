/* eslint-disable no-unused-vars */
import * as types from '../mutation-types';
import ThreeSixtyDialog from 'dashboard/api/channel/threeSixtyDialog';

export const state = {
  uiFlags: {
    isConfiguring: false,
    isConfiguringError: false,
    isSettingUrl: false,
    fetchingPartnerId: false,
  },
  partnerId: null,
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  configureChannel: async ({ commit }, data) => {
    commit(types.default.SET_DIALOG_UI_FLAG, { isConfiguring: true });
    commit(types.default.SET_DIALOG_UI_FLAG, { isConfiguringError: false });
    try {
      const response = await ThreeSixtyDialog.configure(data);
      commit(types.default.SET_DIALOG_UI_FLAG, { isConfiguring: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_DIALOG_UI_FLAG, { isConfiguring: false });
      commit(types.default.SET_DIALOG_UI_FLAG, { isConfiguringError: true });
      throw error;
    }
  },
  setRedirectUrl: async ({ commit }, redirectUrl) => {
    commit(types.default.SET_DIALOG_UI_FLAG, { isSettingUrl: true });
    try {
      const response = await ThreeSixtyDialog.setRedirectUrl(redirectUrl);
      commit(types.default.SET_DIALOG_UI_FLAG, { isSettingUrl: false });
      return response;
    } catch (error) {
      commit(types.default.SET_DIALOG_UI_FLAG, { isSettingUrl: false });
    }
    return null;
  },
  fetchPartnerId: async ({ commit, state: $state }) => {
    if ($state.partnerId) return $state.partnerId;
    commit(types.default.SET_DIALOG_UI_FLAG, { fetchingPartnerId: true });

    try {
      const {
        data: { partner_id },
      } = await ThreeSixtyDialog.fetchPartnerId();
      commit(types.default.SET_DIALOG_UI_FLAG, { fetchingPartnerId: false });
      commit(types.default.SET_PARTNER_ID, partner_id);

      return partner_id;
    } catch (error) {
      commit(types.default.SET_DIALOG_UI_FLAG, { fetchingPartnerId: false });
    }
    return null;
  },
};

export const mutations = {
  [types.default.SET_DIALOG_UI_FLAG]($state, uiFlag) {
    $state.uiFlags = { ...$state.uiFlags, ...uiFlag };
  },
  [types.default.SET_PARTNER_ID]($state, partnerId) {
    $state.partnerId = partnerId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
