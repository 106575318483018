<template>
  <div class="view-box columns" :class="{ 'view-box-new': newBackground }">
    <settings-header
      button-route="new"
      :icon="icon"
      :header-title="$t(headerTitle)"
      :show-back-button="showBackButton"
      :back-url="backUrl"
      :custom-style="customStyle"
    />
    <transition name="modal-fade" duration="100" mode="out-in">
      <keep-alive :include="cmptsList.join(',')" :max="7">
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
/* eslint no-console: 0 */
import SettingsHeader from './SettingsHeader';

export default {
  components: {
    SettingsHeader,
  },
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    newBackground: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    backUrl: {
      type: [String, Object],
      default: '',
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      cmptsList: [
        'InboxHome',
        'AgentHome',
        'TeamsHome',
        'AutomationHome',
        'TicketAssignmentHome',
        'CannedHome',
        'LabelHome',
        'ProductHome',
        'TemplateHome',
        'BotManagementHome',
        'StoreNotificationHome',
        'BillingHome',
        'CustomTicketFieldsHome',
        'SLAHome',
      ],
    };
  },
  computed: {
    currentPage() {
      return this.$store.state.route.name;
    },
    showNewButton() {
      return this.newButtonRoutes.length !== 0 && !this.showBackButton;
    },
  },
};
</script>
