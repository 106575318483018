import types from '../../mutation-types';
import NotificationsAPI from '../../../api/notifications';

export const actions = {
  get: async ({ commit }, { page = 1 } = {}) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: true });
    try {
      const {
        data: {
          data: { payload, meta },
        },
      } = await NotificationsAPI.get(page);
      commit(types.CLEAR_NOTIFICATIONS);
      commit(types.SET_NOTIFICATIONS, payload);
      commit(types.SET_NOTIFICATIONS_META, meta);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: false });
    }
  },
  unreadCount: async ({ commit, dispatch } = {}) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: true });
    try {
      const { data } = await NotificationsAPI.getUnreadCount();
      dispatch('setUnreadCount', { count: data });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: false });
    }
  },
  setUnreadCount: async ({ commit }, { count }) => {
    try {
      commit(types.SET_NOTIFICATIONS_UNREAD_COUNT, count);
    } catch (error) {
      throw new Error(error);
    }
  },
  readAll: async ({ commit }) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: true });
    try {
      await NotificationsAPI.readAll();
      commit(types.UPDATE_ALL_NOTIFICATIONS);
      return true;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: false });
    }
  },
  markAsRead: async ({ commit }, { notificationId, primaryActorId }) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: true });
    try {
      await NotificationsAPI.update(notificationId);
      commit(types.UPDATE_NOTIFICATION, primaryActorId);
      return true;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: false });
    }
  },
  addNotification: async ({ commit }, notification) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: true });
    try {
      commit(types.ADD_NOTIFICATION, notification);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: false });
    }
  },
};
