<template>
  <div class="ticket-card mg-bottom--small">
    <div class="ticket-card-header flex-row flex-justify--between flex-align">
      <a
        :href="ticket.ticket_url"
        target="_blank"
        rel="noopener noreferrer"
        class="ticket-id title-h5"
      >
        {{ ticket.project_key }}-{{ ticket.id }}
      </a>
      <woot-chips
        v-if="ticket.status"
        variant="primary-small"
        :title="ticket.status"
        :show-tooltip="false"
        :show-cancel-icon="false"
      />
    </div>
    <div
      class="ticket-summary line-clamp-2 body-b2 text-dark"
      v-text="ticket.summary"
    />
    <div class="ticket-footer flex-row flex-justify--between">
      <div class="flex-row flex-align">
        <img :src="projectAvatar" class="project-type-icon" />
        <span class="ticket-project body-b3" v-text="ticket.project_key" />
      </div>
      <div class="flex-row flex-align">
        <img :src="issueTypeIcon" class="issue-type-icon" />
        <span class="ticket-category body-b3" v-text="ticket.issue_type" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({ projects: 'crm/getProjectsFromJira' }),
    project() {
      return this.projects.find(p => p.key === this.ticket.project_key) || {};
    },
    projectAvatar() {
      return this.project.avatarUrls ? this.project.avatarUrls['16x16'] : '';
    },
    issueTypeIcon() {
      const issueType = this.project.issuetypes
        ? this.project.issuetypes.find(it => it.name === this.ticket.issue_type)
        : null;
      return issueType ? issueType.iconUrl : '';
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.ticket-card {
  padding: $space-small;
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius;

  .ticket-card-header {
    border-bottom: 1px dashed $neutral-grey-400;
    margin-bottom: $space-small;
    padding-bottom: $space-slab;

    .ticket-id {
      color: $secondary-blue;
    }
  }

  .ticket-summary {
    margin-bottom: $space-normal;
  }

  .ticket-footer {
    .ticket-category {
      color: $warning-color;
    }

    .ticket-project {
      color: $secondary-blue;
    }
  }

  .issue-type-icon,
  .project-type-icon {
    width: $space-normal;
    height: $space-normal;
    margin-right: $space-smaller;
    border-radius: $border-radius-smaller;
  }
}
</style>
