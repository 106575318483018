export default {
  methods: {
    calculateOrder() {
      let assigneeId = null;
      try {
        assigneeId = this.currentChat?.meta?.assignee?.id;
        if (!assigneeId) {
          assigneeId = null;
        }
      } catch {
        assigneeId = null;
      }
      if (this.orderMode !== 'edit') {
        if (!this.draftOrderData?.id || this.draftOrderData.id === -1) {
          this.$store
            .dispatch('createDraftOrder', {
              cartId: this.currentCartId,
              conversationId: this.currentChat.id,
              inboxId: this.currentChat.inbox_id,
              agentId: assigneeId,
              shippingAddress: this.selectedAddress.shipping,
              isCashOnDelivery: true,
            })
            .then(res => {
              this.$store.dispatch('updateDraftOrder', {
                draftOrderId: res.data.id,
                editRequest: 'edit_line_items',
                conversationId: this.currentChat.id,
                agentId: assigneeId,
                shippingAddress: this.selectedAddress.shipping,
              });
            });
        } else {
          this.$store.dispatch('updateDraftOrder', {
            draftOrderId: this.draftOrderData.id,
            conversationId: this.currentChat.id,
            editRequest: 'edit_line_items',
            agentId: assigneeId,
            shippingAddress: this.selectedAddress.shipping,
          });
        }
      } else {
        this.$store.dispatch('fetchShopifyOrderDetail', {
          order_id: this.cartDetails.orderId,
        });
      }
    },
  },
};
