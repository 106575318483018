let windowRef = null;
let previousUrl = null;

export const open360DialogPopup = ({ url, name, baseUrl }) => {
  // remove any existing event listeners
  window.removeEventListener('message', receiveMessage);

  const windowFeatures =
    'toolbar=no, menubar=no, width=600, height=900, top=100, left=100';

  if (windowRef === null || windowRef.closed)
    windowRef = window.open(url, name, windowFeatures);
  else if (previousUrl !== url) {
    windowRef = window.open(url, name, windowFeatures);
    windowRef.focus();
  } else windowRef.focus();

  // add the listener for receiving a message from the popup
  window.addEventListener(
    'message',
    event => receiveMessage(event, baseUrl),
    false
  );

  previousUrl = url;
};

const receiveMessage = (event, baseUrl) => {
  if (event.origin !== baseUrl) return;

  const { data } = event;
  const redirectUrl = `${data}`;
  window.location.search = redirectUrl;
};
