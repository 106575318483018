export default {
  methods: {
    setGlobalRedirectUrl(url) {
      this.$store.dispatch('threeSixtyDialog/setRedirectUrl', url).catch(() => {
        this.showAlert(
          this.$t(
            'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.API.RIDIRECT_URL_ERROR_MESSAGE'
          ),
          'error'
        );
      });
    },
    getUrlParameter(params, name) {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      let results = regex.exec(params);
      return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },
    getParamsFromUrl() {
      const params = window.location.search;
      return params;
    },
    sendParamsToParentWindow(params) {
      if (window.opener) {
        window.opener.postMessage(params);
        window.close();
      }
    },
    getClientId(params) {
      return this.getUrlParameter(params, 'client');
    },
    getChannelIds(params) {
      return this.getUrlParameter(params, 'channels');
    },
    getState(params) {
      return this.getUrlParameter(params, 'state');
    },
  },
};
