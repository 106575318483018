<template>
  <woot-delete-modal-new
    :show.sync="show"
    :on-close="onClose"
    :on-confirm="onConfirm"
    :title="$t('SLA.DELETE.TITLE')"
    :message="deleteMessage"
    show-close
    :confirm-text="$t('SLA.DELETE.CONFIRM')"
    :reject-text="$t('SLA.DELETE.CANCEL')"
    :custom-style="{
      'max-width': '35.2rem',
      height: 'auto',
    }"
  />
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedSla: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    deleteMessage() {
      return `${this.$t('SLA.DELETE.DESC')} ${this.selectedSla?.name || ''}?`;
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onConfirm() {
      this.$emit('delete');
    },
  },
};
</script>
