var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.title.replaceAll('_', ' ')),expression:"title.replaceAll('_', ' ')",modifiers:{"bottom":true}}],class:_vm.labelClass,style:({
    background: _vm.bgColor,
    color: _vm.textColor,
    borderRadius: '1.6rem',
  }),attrs:{"title":_vm.description}},[(!_vm.href)?_c('span',{staticClass:"label--box"},[_vm._v("\n    "+_vm._s(_vm.title)+"\n  ")]):_c('a',{style:({ color: _vm.textColor }),attrs:{"href":_vm.href}},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),(_vm.showIcon)?_c('icons',{attrs:{"name":_vm.icon,"size":"medium","custom-style":{
      fill: 'none',
      stroke: _vm.textColor,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      marginRight: '0.6rem',
      cursor: 'pointer',
    }},on:{"click":_vm.onClick}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }