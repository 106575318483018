<template>
  <div class="flex-row">
    <table :style="[tableStyle]" class="custom-scroll">
      <tr class="small-12">
        <th v-if="headersOnly" class="body-b2 text-dark">
          CountryCode
        </th>
        <th class="body-b2 text-dark">
          Phone
        </th>
        <th v-if="needsMediaHeader" class="body-b2 text-dark">
          MediaURL
        </th>

        <th
          v-for="(temp, inx) in Array(visibleParams)"
          :key="inx"
          class="body-b2 text-dark"
        >
          {{ `Input${inx + 1}` }}
        </th>
      </tr>
      <tr>
        <td>91</td>
        <td>Something</td>
        <td v-if="needsMediaHeader" class="body-b2 text-dark">
          url1
        </td>

        <td v-for="(temp, inx) in Array(visibleParams)" :key="inx">
          -
        </td>
      </tr>
      <tr>
        <td>91</td>
        <td>Something</td>
        <td v-if="needsMediaHeader" class="body-b2 text-dark">
          url2
        </td>

        <td v-for="(temp, inx) in Array(visibleParams)" :key="inx">
          -
        </td>
      </tr>
    </table>

    <div
      v-if="extraParams"
      class="extra-params title-h4 text-light flex-row-center"
    >
      {{ '+' + extraParams }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paramsCount: {
      type: Number,
      default: 0,
    },
    headersOnly: {
      type: Boolean,
      default: false,
    },

    phList: {
      type: Array,
      default: () => [],
    },
    needsMediaHeader: {
      type: Boolean,
      default: false,
    },
    templateParameters: {
      type: Array,
      default: () => [],
    },
    rowHeight: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    fixedHeaders() {
      if (this.needsMediaHeader) return 3;
      return 2;
    },
    visibleParams() {
      if (this.paramsCount > 4 - this.fixedHeaders)
        return 4 - this.fixedHeaders;
      return this.paramsCount;
    },
    extraParams() {
      if (this.paramsCount > 4 - this.fixedHeaders)
        return this.paramsCount + this.fixedHeaders - 4;
      return null;
    },
    phoneList() {
      return this.headersOnly ? [] : this.phList;
    },
    tableStyle() {
      if (this.phoneList.length === 0) {
        return { height: 'auto' };
      }

      let minHeight =
        this.phoneList.length > this.rowHeight
          ? this.rowHeight
          : this.phoneList.length;
      return { height: `${(minHeight + 1) * 38 - 9}px` };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

table {
  font-family: arial, sans-serif;

  border-collapse: collapse;
  width: 100%;
  display: block;
}

td,
th {
  width: 10%;
  text-align: left;
  padding: $space-small;
  border: 1px solid $neutral-grey-400;
}

td {
  font-size: $font-size-small;
  color: $neutral-grey-600;
  line-height: $height-h5;
}

.extra-params {
  border: 1px solid $neutral-grey-400;
  border-radius: $zero $space-small $space-small $zero;
  height: 100%;
  padding: $space-small;
  height: 11.8rem;
}
</style>
