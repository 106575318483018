import { render, staticRenderFns } from "./TableSkeleton.vue?vue&type=template&id=76fc73fc&scoped=true&"
var script = {}
import style0 from "./TableSkeleton.vue?vue&type=style&index=0&id=76fc73fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76fc73fc",
  null
  
)

export default component.exports