/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
import * as types from '../../mutation-types';
import actions from './actions';

const state = {
  exotelAgentsList: [],
};

export const getters = {
  getExotelAgentsList(_state) {
    return _state.exotelAgentsList;
  },
};

export const mutations = {
  [types.default.SET_EXOTEL_AGENTS](_state, data) {
    _state.exotelAgentsList = [...data];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
