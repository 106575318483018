import * as types from '../mutation-types';
import PiiMaskApi from '../../api/piiMask';
import { ACCESSED_DETAILS } from '../../components/widgets/piiMask/constants';

const state = {
  uiFlags: {
    isLogging: false,
  },
};

export const getters = {
  getUiFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  logUnmaskEvent: async ({ commit }, payload) => {
    commit(types.default.SET_PII_UI_FLAG, { isLogging: true });

    if (!Object.values(ACCESSED_DETAILS).includes(payload.accessed_detail)) {
      commit(types.default.SET_PII_UI_FLAG, { isLogging: false });
      throw new Error('Invalid accessed detail');
    }

    try {
      const response = await PiiMaskApi.logUnmaskEvent(payload);
      commit(types.default.SET_PII_UI_FLAG, { isLogging: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_PII_UI_FLAG, { isLogging: false });
      throw error;
    }
  },
};

export const mutations = {
  [types.default.SET_PII_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
