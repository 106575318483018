<template>
  <div
    v-if="!hasReplaced && !errorMessage"
    class="text-container flex-row flex-align--start flex-justify--between gap--small"
  >
    <div
      class="flex-row gap--small"
      :class="`flex-align${isImprovingText ? '' : '--start'}`"
    >
      <icons name="wand" size="semimedium" color="green" :show-title="false" />
      <template v-if="isImprovingText">
        <span
          class="title-h6 text-green"
          v-text="$t('CONVERSATION.GPT.LOADING')"
        />
        <three-dot-loader color="lime-green" />
      </template>
      <span
        v-else
        class="body-b2 text-light improved-text custom-scroll"
        v-text="improvedText"
      />
    </div>
    <div v-if="!isImprovingText" class="actions flex-row flex-align gap--small">
      <woot-base-button
        tag="span"
        size="small"
        variant="secondary"
        preserve-case
        style="width: 8rem"
        @click="onReplace"
      >
        <icons
          name="tickSingle"
          color="green"
          size="small"
          view="1 1.5 14 14"
          :show-title="false"
        />
        Replace
      </woot-base-button>
      <woot-base-button
        tag="span"
        variant="tertiary-danger"
        @click="
          () => {
            mix_panel_clicked_reject_draft();
            onClose();
          }
        "
      >
        <icons name="closeRound" color="lightgrey" :show-title="false" />
      </woot-base-button>
    </div>
  </div>
  <div v-else-if="hasReplaced && !errorMessage" class="success-container">
    <span class="title-h5 text-lime-green">Writing Improved with AI</span>
  </div>
  <!-- error state -->
  <div v-else class="error-container">
    <span class="title-h5 text-light" v-text="errorMessage" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ThreeDotLoader from 'shared/components/loaders/ThreeDotLoader';

import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: { ThreeDotLoader },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  props: {
    text: { type: String, default: '' },
  },
  data() {
    return {
      errorMessage: '',
      improvedText: '',
      hasReplaced: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'intelligence/getUiFlags',
      accountId: 'getCurrentAccountId',
      currentChat: 'getSelectedChat',
    }),
    isImprovingText() {
      return this.uiFlags.isImprovingText;
    },
  },
  created() {
    this.$store
      .dispatch('intelligence/getImprovedText', {
        draft_text: this.text,
        account_id: this.accountId,
        conversation_id: this.currentChat.id,
      })
      .then(response => {
        this.improvedText = response;
        this.$emit('onTextImproved');
      })
      .catch(error => {
        const {
          response: {
            data: { error: errorMessage },
          },
        } = error;

        if (errorMessage === 'Rate Limit Error') {
          this.errorMessage = 'Request limit reached. Try again shortly';
        } else this.errorMessage = this.$t('CONVERSATION.GPT.ERROR');

        this.showAlert(this.$t('CONVERSATION.GPT.ERROR'), 'error');

        this.closeWithDelay(5000);
      });
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    closeWithDelay(delay) {
      setTimeout(() => {
        this.onClose();
      }, delay);
    },
    onReplace() {
      this.hasReplaced = true;
      this.$emit('improvedText', this.improvedText);
      this.closeWithDelay(2000);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.text-container {
  @include shadow-medium;

  background-color: $bg-green;
  border-radius: $border-radius-smaller;
  margin: $space-smaller $space-normal;
  padding: $space-small;

  .improved-text {
    color: $neutral-grey-700;
    max-height: $space-medium * 10;
    overflow: overlay;
  }

  .actions {
    height: 2.2rem;
  }
}

%container-shared-styles {
  @include elevation-1dp;

  border-radius: $border-radius;
  margin: $space-small auto;
  padding: $space-smaller $space-slab;
  width: fit-content;
}

.success-container {
  @extend %container-shared-styles;
  background-color: $bg-green;
}

.error-container {
  @extend %container-shared-styles;
  background-color: $warn-red-100;
}
</style>
