/* global axios */
import ApiClient from './ApiClient';

class LabelsAPI extends ApiClient {
  constructor() {
    super('labels', { accountScoped: true });
  }

  getLabelsWithoutGroups({ resource_type }) {
    return axios.get(`${this.url}/flat_labels?resource_type=${resource_type}`);
  }

  bulkDeleteLabels(payload) {
    return axios.delete(`${this.url}/bulk_destroy`, {
      data: { id: payload },
    });
  }

  bulkUpdateStatus(labelIds, isActive) {
    return axios.put(`${this.url}/bulk_status_update`, {
      ids: labelIds,
      active: isActive,
    });
  }

  importLabels({ file }) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return axios({ method: 'post', url: `${this.url}/import`, data: formData });
  }

  exportLabels() {
    return axios.get(`${this.url}/export`);
  }
}

export default new LabelsAPI();
