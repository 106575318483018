<template>
  <div class="lime-card-1dp medium-12">
    <span class="table-title flex-row-justify-center">
      <icons
        name="chevronLeft"
        color="green"
        size="mediumlarge"
        @click="$emit('select-summary')"
      />
      <span style="margin-left: 1.2rem; align-items: center">
        {{ agentName }}
      </span>
    </span>
    <table class="metrics-table">
      <tr>
        <th v-for="item in headerList" :key="item.id">
          {{ item.name }}
        </th>
      </tr>
      <tbody v-if="!uiFlag && attendanceLogs && attendanceLogs['logs'].length">
        <tr v-for="(item, index) in attendanceLogs['logs']" :key="index">
          <td :class="`status-color status-color__${item['Status']}`">
            {{ item['Status'] }}
          </td>
          <td>
            {{ humanReadableTime(item['Update Time']) }}
          </td>
          <td
            v-html="
              formattedDuration(
                secondsToDuration(parseInt(item['Time in State'] * 60, 10))
              )
            "
          />
        </tr>
      </tbody>
    </table>
    <p
      v-if="!(attendanceLogs && attendanceLogs['logs'].length) && !uiFlag"
      class="no-items-error-message"
    >
      {{ 'No data found' }}
    </p>
    <woot-loading-state v-if="uiFlag" message="Fetching agent logs" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import timeMixin from 'dashboard/mixins/time';

export default {
  mixins: [timeMixin],
  props: {
    headerList: {
      type: Array,
      default: () => [],
    },
    agentName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      attendanceLogs: 'getAttendanceLogs',
      uiFlag: 'getAttendanceUIFlag',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.table-title {
  font-size: $font-size-medium;
  line-height: 2.6rem;
  color: $neutral-grey-800;
  font-weight: $font-weight-medium;
  justify-content: flex-start;
  cursor: pointer;
}

.no-items-error-message {
  height: auto;
}

.metrics-table {
  margin-top: $space-medium;
  border-radius: $border-radius;

  .table-header {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
  }
}
.status-color {
  text-transform: capitalize;

  &__Online {
    color: $success-color;
  }

  &__Offline {
    color: $color-gray;
  }

  &__Utility,
  &__Meals,
  &__Oncall {
    color: $warning-color;
  }
}
table {
  border-collapse: separate;
  background: $neutral-white;
  border-spacing: 0;
  min-width: 350px;

  tr {
    td {
      border-right: 1px solid $neutral-grey-500;
      border-bottom: 1px solid $neutral-grey-400;
    }

    th {
      border-right: 1px solid $neutral-grey-500;
      border-bottom: 1px solid $neutral-grey-600;
    }

    &:hover {
      background: $neutral-grey-200;
    }

    th:first-child,
    td:first-child {
      border-left: 1px solid $neutral-grey-400;
    }

    th {
      border-top: 1px solid $neutral-grey-400;
      text-align: left;
      padding: $space-normal;
      font-size: $font-size-default;
      font-weight: $font-weight-normal;
      line-height: $space-medium;
    }

    &:first-child th {
      &:first-child {
        border-top-left-radius: $border-radius;
      }

      &:last-child {
        border-top-right-radius: $border-radius;
      }
    }

    &:last-child td {
      &:first-child {
        border-bottom-left-radius: $border-radius;
      }

      &:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}
</style>
