<template>
  <div class="row loading-state" :style="customStyle">
    <h6 class="message">
      {{ message }}
      <span class="spinner"></span>
    </h6>
  </div>
</template>
<script>
export default {
  props: {
    message: { type: String, default: '' },
    customStyle: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
