var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.vapidPublicKey && !_vm.isBrowserSafari)?_c('div',{staticClass:"profile--settings--row row push-row"},[_c('woot-modal-header',{attrs:{"header-title":_vm.$t('PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.TITLE'),"header-content":_vm.$t('PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.NOTE')}}),_vm._v(" "),_c('div',{staticClass:"columns small-9"},[(_vm.hasEnabledPushPermissions)?_c('p',[_vm._v("\n        "+_vm._s(_vm.$t(
            'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.HAS_ENABLED_PUSH'
          ))+"\n      ")]):_c('div',[_c('woot-primary-button',{attrs:{"name":_vm.$t(
              'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.REQUEST_PUSH'
            )},on:{"click":_vm.onRequestPermissions}}),_vm._v(" "),_c('p',{staticClass:"notification_blocked"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.NOTIFICATIONS_BLOCKED_RESOLUTION_HEADING'
            ))+"\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t(
              'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.NOTIFICATIONS_BLOCKED_RESOLUTION_TITLE'
            ))+"\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t(
              'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.NOTIFICATIONS_BLOCKED_RESOLUTION_SUBTITLE'
            ))+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"columns medium-12 form-box"},_vm._l((_vm.allPushFlags),function(pushFlag){return _c('div',{key:pushFlag.value,staticClass:"checkbox-container"},[_c('label',{staticClass:"container-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedPushFlags),expression:"selectedPushFlags"}],staticClass:"notification--checkbox",attrs:{"type":"checkbox"},domProps:{"value":pushFlag.value,"checked":Array.isArray(_vm.selectedPushFlags)?_vm._i(_vm.selectedPushFlags,pushFlag.value)>-1:(_vm.selectedPushFlags)},on:{"input":_vm.handlePushInput,"change":function($event){var $$a=_vm.selectedPushFlags,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=pushFlag.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedPushFlags=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedPushFlags=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedPushFlags=$$c}}}}),_vm._v(" "),_c('span',{staticClass:"checkmark"})]),_vm._v(" "),_c('label',{staticClass:"checkbox-label",attrs:{"for":pushFlag.value}},[_vm._v("\n            "+_vm._s(pushFlag.label)+"\n          ")])])}),0)])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }