/* global axios */
import ApiClient from './ApiClient';

class PiiMask extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  logUnmaskEvent(data) {
    return axios.post(`${this.url}data_access_logs`, data);
  }
}

export default new PiiMask();
