<template>
  <div class="status-container flex-column flex-align">
    <hr class="top--bar" />
    <span
      v-if="currentStatus.message"
      :style="`color: ${currentStatus.color}`"
      class="capital_c2"
    >
      {{ currentStatus.message }}
    </span>
    <span
      v-if="currentStatus.status"
      :style="`color: ${currentStatus.statusColor}`"
      class="title-h5"
    >
      {{ currentStatus.status }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    currentStatus: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
