<template>
  <woot-modal-new :show="true" :on-close="() => $emit('onClose')">
    <woot-modal-header-new header-title="Download Tags" />
    <div class="modal-content-wrapper flex-column flex-justify--between">
      <div class="flex-column gap--normal">
        <div
          :style="{
            backgroundImage: `url(${require('assets/images/export_tags.svg')})`,
          }"
          class="illustration-container"
        />
        <span
          class="body-b2 text-light"
          v-text="'This will export the tags in the given format'"
        />
      </div>
      <div class="full-width flex-row flex-justify--end">
        <woot-base-button :loading="isDownloading" @click="download">
          Export as csv
        </woot-base-button>
      </div>
    </div>
  </woot-modal-new>
</template>
<script>
import * as types from 'shared/constants/googleEventType';
import LabelsAPI from 'dashboard/api/labels';
import alertMixin from 'shared/mixins/alertMixin';
import downloadMixin from 'shared/mixins/downloadMixin.js';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  mixins: [
    downloadMixin,
    googleAnalyticsMixin,
    mixPanelAnalyticsMixin,
    alertMixin,
  ],
  data() {
    return {
      isDownloading: false,
    };
  },
  methods: {
    async download() {
      this.googleAnalyticsEvent(
        types.default.LABEL_DOWNLOAD,
        types.default.LABEL_SETTINGS,
        types.default.LABEL_SETTINGS
      );
      this.isDownloading = true;

      await LabelsAPI.exportLabels()
        .then(response => {
          this.mix_panel_clicked_settings_export_download_csv_tag();
          this.processCsv(response.data, 'labels.csv');
          this.isDownloading = false;
          this.$emit('onClose');
        })
        .catch(() => {
          this.showAlert('Failed to download tags', 'error');
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.modal-content-wrapper {
  height: 80%;

  .illustration-container {
    height: 21.6rem;
    max-width: 62.4rem;
    background: $neutral-grey-300;
    background-size: contain;
    border-radius: $border-radius-smaller;
  }

  .custom-import-btn {
    position: relative;
    width: $space-normal * 10;

    ::v-deep .file-uploads {
      position: absolute;
      width: 100%;

      label {
        cursor: pointer;
      }
    }
  }
}
</style>
