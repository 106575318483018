<template>
  <div>
    <div class="status">
      <icons
        name="danger"
        color="warningyellow200"
        size="mediumlarge"
        @click="showEmailPopup"
      />
    </div>
    <email-verify-modal
      v-if="showEmailVerifyModal"
      :show.sync="showEmailVerifyModal"
      :title="$t('REGISTER.CONFIRMATION.TITLE')"
      :description="
        $t('REGISTER.CONFIRMATION.DESCRIPTION', {
          email: currentUser.email,
        })
      "
      :on-close="hideEmailPopup"
      :email="currentUser.email"
      @resend="resendEmailConfirmation"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Auth from 'dashboard/api/auth';
import alertMixin from 'shared/mixins/alertMixin';
import EmailVerifyModal from '../ui/EmailVerificationModal';

export default {
  components: {
    EmailVerifyModal,
  },
  mixins: [alertMixin],
  data() {
    return {
      showEmailVerifyModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    unconfirmedUserText() {
      return (
        this.$t('REGISTER.RESEND.RESEND_TEXT_SIDEBAR') +
        ' ' +
        this.currentUser.email
      );
    },
    resendMail() {
      return this.$t('REGISTER.RESEND.RESEND_CTA_SIDEBAR');
    },
  },
  methods: {
    async resendEmailConfirmation() {
      await Auth.resendConfirmation()
        .then(() => {
          this.showAlert(this.$t('REGISTER.RESEND.SUCCESS_MESSAGE'), 'success');
        })
        .catch(() => {
          this.showAlert(this.$t('REGISTER.RESEND.ERROR_MESSAGE'), 'error');
        });
    },
    hideEmailPopup() {
      this.showEmailVerifyModal = false;
    },
    showEmailPopup() {
      this.showEmailVerifyModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
