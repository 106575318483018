<template>
  <div
    class="products-data flex-row flex-align--start flex-justify--between gap--normal"
  >
    <div class="full-width products-table">
      <table-skeleton
        v-if="uiFlags.fetchingTopOrderedProducts"
        class="products-table-skeleton"
      />
      <woot-table
        v-else
        :title="
          $t('ANALYTICS.SHOPIFY_ANALYTICS.TABLES.TOP_ORDERED_PRODUCTS.TITLE')
        "
        class="lime-card-1dp"
        :header-list="tableHeader"
        :table-data="topOrderedProductsTableData"
      >
        <template v-slot:bottom>
          <div class="flex-row flex-justify--end">
            <ve-pagination
              :total="topOrderedProductsMeta.total_entries"
              :page-index="topOrderedProductsMeta.current_page"
              :page-size="5"
              :paging-count="2"
              :layout="['total', 'prev', 'pager', 'next']"
              @on-page-number-change="idx => onPageNumberChange(idx, 'ordered')"
            />
          </div>
        </template>
      </woot-table>
    </div>
    <div class="full-width products-table">
      <table-skeleton
        v-if="uiFlags.fetchingTopCancelledProducts"
        class="products-table-skeleton"
      />
      <woot-table
        v-else
        :title="
          $t('ANALYTICS.SHOPIFY_ANALYTICS.TABLES.TOP_CANCELLED_PRODUCTS.TITLE')
        "
        class="lime-card-1dp"
        :header-list="tableHeader"
        :table-data="topCancelledProductsTableData"
      >
        <template v-slot:bottom>
          <div class="flex-row flex-justify--end">
            <ve-pagination
              :total="topCancelledProductsMeta.total_entries"
              :page-index="topCancelledProductsMeta.current_page"
              :page-size="5"
              :paging-count="2"
              :layout="['total', 'prev', 'pager', 'next']"
              @on-page-number-change="
                idx => onPageNumberChange(idx, 'cancelled')
              "
            />
          </div>
        </template>
      </woot-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { VePagination } from 'vue-easytable';

import TableSkeleton from '../components/TableSkeleton';

export default {
  components: { VePagination, TableSkeleton },
  props: {
    appliedFilters: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'deepShopifyAnalytics/getUiFlags',
      topOrderedProductsMeta: 'deepShopifyAnalytics/getTopOrderedProductsMeta',
      topOrderedProducts: 'deepShopifyAnalytics/getTopOrderedProductsList',
      topCancelledProductsMeta:
        'deepShopifyAnalytics/getTopCancelledProductsMeta',
      topCancelledProducts: 'deepShopifyAnalytics/getTopCancelledProductsList',
    }),
    tableHeader() {
      return [
        {
          id: 'name',
          name: this.$t(
            'ANALYTICS.SHOPIFY_ANALYTICS.TABLES.TOP_ORDERED_PRODUCTS.HEADER.PRODUCT_NAME'
          ),
        },
        {
          id: 'order_count',
          name: this.$t(
            'ANALYTICS.SHOPIFY_ANALYTICS.TABLES.TOP_ORDERED_PRODUCTS.HEADER.COUNT'
          ),
        },
      ];
    },
    topOrderedProductsTableData() {
      return this.topOrderedProducts.map(({ name, order_count }) => ({
        name,
        order_count,
      }));
    },
    topCancelledProductsTableData() {
      return this.topCancelledProducts.map(({ name, order_count }) => ({
        name,
        order_count,
      }));
    },
  },
  methods: {
    onPageNumberChange(pageIndex, type) {
      const params = {
        ...this.appliedFilters,
        page: pageIndex,
        items_per_page: 5,
      };

      if (type === 'ordered')
        this.$store.dispatch(
          'deepShopifyAnalytics/fetchTopOrderedProducts',
          params
        );

      if (type === 'cancelled')
        this.$store.dispatch(
          'deepShopifyAnalytics/fetchTopCancelledProducts',
          params
        );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.products-data {
  position: relative;
  margin: $space-normal $zero $space-normal;

  .products-table {
    .products-table-skeleton {
      height: $space-four * 10;
    }
  }
}
</style>
