<template>
  <span class="pii-wrapper">
    <template v-if="isLoading">
      <!-- When loading, show either the input field or a loading animation -->
      <slot
        v-if="isInputMask"
        :data="displayData"
        :isMasked="isMasked"
        :updateData="updateData"
      />
      <lottie
        v-else
        :options="defaultAnimOptions"
        :height="30"
        :width="80"
        @animCreated="handleAnimation"
      />
    </template>
    <template v-else>
      <!-- When not loading, display the data using a scoped slot -->
      <slot :data="displayData" :isMasked="isMasked" :updateData="updateData" />
    </template>

    <!-- Toggle button to switch between masked and unmasked states -->
    <span
      v-if="showToggleIcon && isMasked"
      v-tooltip.top="tooltipMsg"
      class="toggle-icon"
      :class="{ disabled: isLoading }"
      @click="toggleMask"
    >
      <icons
        v-tooltip.top="tooltipMsg"
        name="lock"
        :show-tooltip="false"
        color="stroke-darkest-grey"
        :size="toggleIconSize"
      />
    </span>
  </span>
</template>

<script>
import Lottie from 'vue-lottie';
import { getMaskedData } from './helpers';
import { ACCESSED_DETAILS, MASK_STATE, MASK_TYPES } from './constants';
import animationData from 'dashboard/assets/lottie/loader.json';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  name: 'PiiMaskWrapper',
  components: { Lottie },
  mixins: [alertMixin],
  props: {
    // The actual data to be masked/unmasked
    piiData: {
      type: String,
      default: null,
    },
    // Determines the initial masking state (server-side, client-side, or no masking)
    maskState: {
      type: String,
      default: MASK_STATE.serverMask,
      validator: value => Object.values(MASK_STATE).includes(value),
    },
    // Whether the data should be initially masked
    defaultMasked: {
      type: Boolean,
      default: true,
    },
    // The type of mask to apply (e.g., name, email, etc.)
    maskType: {
      type: String,
      default: MASK_TYPES.OTHER,
      validator: value => Object.values(MASK_TYPES).includes(value),
    },
    // Custom settings for masking (if any)
    maskSetting: {
      type: Object,
      default: () => ({}),
    },
    // Whether the user has permission to unmask the data
    userHasPermission: {
      type: Boolean,
      default: true,
    },
    // The Vuex action to dispatch for unmasking server-masked data
    unmaskAction: {
      type: String,
      default: null,
    },
    // Additional payload for the unmask action
    unmaskPayload: {
      type: Object,
      default: () => ({}),
    },
    // Callback function to be called after unmasking
    onUnmask: {
      type: Function,
      default: () => {},
    },
    hideToggleIcon: {
      type: Boolean,
      default: false,
    },
    toggleIconSize: {
      type: String,
      default: 'semimedium',
      validator: value =>
        ['semimedium', 'medium', 'small', 'normal'].includes(value),
    },
  },

  data() {
    return {
      isLoading: false,
      isMasked: this.maskState !== MASK_STATE.noMask && this.defaultMasked,
      maskedData: '',
      unmaskedData: '',
      defaultAnimOptions: { animationData, loop: true },
    };
  },

  computed: {
    tooltipMsg() {
      return 'Unmask';
    },
    isInputMask() {
      return this.maskType.toLowerCase().includes('input');
    },
    // Computes the data to be displayed based on current state
    displayData() {
      if (this.maskState === MASK_STATE.noMask) {
        return this.piiData;
      }

      if (this.isInputMask && this.isMasked) {
        return this.$t('MASKED_INPUT.PLACEHOLDER');
      }
      return this.isMasked
        ? this.maskedData
        : this.unmaskedData || this.piiData;
    },
    hasNoMask() {
      return this.maskState === MASK_STATE.noMask;
    },
    hasClientMask() {
      return this.maskState === MASK_STATE.clientMask;
    },
    hasServerMask() {
      return this.maskState === MASK_STATE.serverMask;
    },
    showToggleIcon() {
      return (
        !this.isLoading &&
        !this.hideToggleIcon &&
        this.userHasPermission &&
        !this.hasNoMask &&
        (Boolean(this.piiData) || this.isInputMask)
      );
    },
  },
  watch: {
    maskState() {
      this.updateMaskedState();
    },
  },
  mounted() {
    this.updateMaskedState();
  },
  methods: {
    // Updates the masked/unmasked data based on the current mask state
    updateMaskedState() {
      if (this.hasClientMask) {
        this.unmaskedData = this.piiData;
        this.maskedData = getMaskedData(
          this.unmaskedData,
          this.maskType.split('-')[0],
          this.maskSetting
        );
      } else if (this.hasServerMask) {
        this.maskedData = this.piiData;
        this.unmaskedData = '';
      } else {
        this.isMasked = false;
        this.maskedData = '';
        this.unmaskedData = this.piiData;
      }
    },
    // Updates the unmasked data and emits an update event
    updateData(newValue) {
      this.unmaskedData = newValue;
      this.$emit('update', newValue);
    },
    handleAnimation(anim) {
      this.anim = anim;
      this.anim.setSpeed(1);
    },
    async unmask() {
      if (this.isMasked && this.hasServerMask && !this.unmaskedData) {
        try {
          this.isLoading = true;
          const accessed_detail = ACCESSED_DETAILS[this.maskType.split('-')[0]];
          const response = await this.$store.dispatch(this.unmaskAction, {
            ...this.unmaskPayload,
            accessed_detail,
          });
          this.unmaskedData = response.accessed_data;
          this.onUnmask(this.unmaskedData);
          this.isMasked = false; // toggle mask in case of successful un-masking
        } catch (error) {
          // handle inputField, when it doesn't have value
          if (this.isInputMask) {
            this.isMasked = false;
            this.unmaskedData = '';
            this.onUnmask(this.unmaskedData);
          } else {
            this.showAlert(
              this.$t('UNMASK_ERROR.MESSAGE', {
                fieldName: this.maskType.split('0')[0],
              }),
              'error'
            );
          }
        } finally {
          this.isLoading = false;
        }
      } else {
        this.isMasked = false;
      }
    },
    mask() {
      this.isMasked = true;
    },
    async toggleMask(event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.isLoading || !this.userHasPermission) {
        return;
      }

      if (this.isMasked) {
        await this.unmask();
      } else {
        this.mask();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.pii-wrapper {
  display: flex;
  align-items: center;
  gap: $space-six;
}

.toggle-icon {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
