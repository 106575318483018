var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('woot-modal',{attrs:{"show":_vm.show,"on-close":_vm.onCancel},on:{"update:show":function($event){_vm.show=$event}}},[_c('div',{staticClass:"column content-box"},[_c('woot-modal-header',{attrs:{"header-title":"Edit ticket"}}),_vm._v(" "),_c('form',{staticClass:"row settings-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('woot-input',{staticClass:"medium-12 columns content-margin",class:{ error: _vm.$v.subject.$error },attrs:{"label":"Subject","placeholder":"Subject"},on:{"input":_vm.$v.subject.$touch},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"subject"}}),_vm._v(" "),_c('woot-input',{staticClass:"medium-12 columns content-margin",class:{ error: _vm.$v.orderId.$error },attrs:{"label":"Order ID","placeholder":"Order ID"},on:{"input":_vm.$v.orderId.$touch},model:{value:(_vm.orderId),callback:function ($$v) {_vm.orderId=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"orderId"}}),_vm._v(" "),(_vm.selectedCrm === _vm.AVAILABLE_CRMS.ZOHO)?_c('div',{staticClass:"small-12 edit-contact--form"},[_c('div',{staticClass:"form-label"},[_c('div',{staticClass:"mg-left--micro mg-bottom--small"},[_vm._v("\n            Department\n          ")]),_vm._v(" "),_c('woot-single-selector',{attrs:{"default-option":_vm.selectedDepartment,"option-list":_vm.departmentOptionList,"custom-style":{
              width: '100%',
              'margin-bottom': 'var(--$space-slab)',
            }},on:{"click":_vm.changeDepartment}})],1)]):_vm._e(),_vm._v(" "),_c('woot-input',{staticClass:"medium-12 columns content-margin",class:{ error: _vm.$v.description.$error },attrs:{"label":"Description","placeholder":"Description"},on:{"input":_vm.$v.description.$touch},model:{value:(_vm.description),callback:function ($$v) {_vm.description=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"description"}}),_vm._v(" "),(_vm.selectedCrm === _vm.AVAILABLE_CRMS.FRESHDESK)?_c('label',{staticClass:"form-label"},[_vm._v("\n        Status\n        "),_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.status_updated),expression:"status_updated",modifiers:{"trim":true}}],staticClass:"form-input",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.status_updated=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.status_list),function(num){return _c('option',{key:num,domProps:{"value":num}},[_vm._v(_vm._s(_vm.statusMap[num]))])}),0)]):_vm._e(),_vm._v(" "),(_vm.selectedCrm === _vm.AVAILABLE_CRMS.ODOO)?_c('div',{staticClass:"ticket-status"},[_c('b',[_vm._v("Status: ")]),_vm._v(_vm._s(_vm.ticket.status)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.selectedCrm === _vm.AVAILABLE_CRMS.KAPTURE)?_c('div',{staticClass:"ticket-link"},[_c('b',[_vm._v("Ticket link: ")]),_vm._v(" "),_c('a',{attrs:{"href":_vm.ticket.url,"target":"_blank"},domProps:{"textContent":_vm._s(
            _vm.$t(
              'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.KAPTURE.VIEW_LINK_BUTTON_TEXT'
            )
          )}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal-footer flex-row gap--small"},[(_vm.selectedCrm === _vm.AVAILABLE_CRMS.FRESHDESK)?_c('woot-base-button',{attrs:{"variant":"secondary-danger","front-icon":"delete"},on:{"click":_vm.onDelete}},[_vm._v("\n          Delete\n        ")]):_vm._e(),_vm._v(" "),_c('woot-base-button',{attrs:{"type":"submit"}},[_vm._v("\n          Update\n        ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }