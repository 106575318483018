import store from 'dashboard/store';

import SettingsContent from '../Wrapper';
import ProductList from './Index';
import ProductPageDeepShopify from './deepShopify/Product';
import ProductPageNonDeepShopify from './nonDeepShopify/Product';
import { frontendURL } from 'dashboard/helper/URLHelper';

const {
  getters: { 'accounts/getDeepShopifyEnabledFlag': isDeepShopifyEnabled },
} = store;

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/products'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_product_list';
        const query = params.query.page ? `?page=${params.query.page}` : '';
        const accountId = params.params.accountId;
        return {
          headerTitle: 'PRODUCTS_MGMT.HEADER',
          icon: 'cart',
          backUrl:
            params.name === 'settings_product_show'
              ? frontendURL(
                  `accounts/${accountId}/settings/products/list${query}`
                )
              : '',
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_product',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_product_list',
          component: ProductList,
          roles: ['administrator', 'agent', 'supervisor'],
        },
        {
          path: frontendURL(
            'accounts/:accountId/settings/products/:product_id'
          ),
          name: 'settings_product_show',
          component: isDeepShopifyEnabled
            ? ProductPageDeepShopify
            : ProductPageNonDeepShopify,
          roles: ['administrator'],
          props: route => {
            return { productId: route.params.product_id };
          },
        },
      ],
    },
  ],
};
