<template>
  <div
    class="flex-container flex-dir-column medium-flex-dir-row csat-date-range"
  >
    <div class="pull-right date-picker">
      <woot-single-selector
        v-if="!isDateRangeSelected"
        variant="secondary"
        size="medium"
        :default-option="currentDateRangeSelection.name"
        :option-list="dateRange"
        :show-selected-text="false"
        @click="changeDateSelection"
      />
    </div>
    <template v-if="isDateRangeSelected">
      <div
        class="flex-row"
        style="border :1px solid #bfbfbf; border-radius: 0.8rem"
      >
        <woot-date-range-picker
          show-range
          :value="customDateRange"
          :confirm-text="$t('ANALYTICS.CUSTOM_DATE_RANGE.CONFIRM')"
          :placeholder="$t('ANALYTICS.CUSTOM_DATE_RANGE.PLACEHOLDER')"
          :disabled-future-dates="currentDate"
          @change="onChange"
        />
        <woot-single-selector
          variant="tertiary"
          :custom-style="{
            top: '2.4rem',
            width: 'auto',
            left: 'auto',
            right: '0.8rem',
          }"
          size="medium"
          icon="chevronDown"
          :option-list="dateRange"
          :show-option-name="false"
          :show-selected-text="false"
          @click="changeDateSelection"
        />
      </div>
    </template>
  </div>
</template>
<script>
import WootDateRangePicker from 'dashboard/components/ui/DatePicker';
import { endOfDay, subDays, startOfDay, getUnixTime } from 'date-fns';
const CUSTOM_DATE_RANGE_ID = 7;

export default {
  components: {
    WootDateRangePicker,
  },
  props: {
    defaultSelection: {
      type: Number,
      default: () => [0],
    },
  },
  data() {
    return {
      currentDateRangeSelection: this.$t('ANALYTICS.DATE_RANGE')[
        this.defaultSelection
      ],
      dateRange: this.$t('ANALYTICS.DATE_RANGE'),
      customDateRange: [new Date(), new Date()],
    };
  },
  computed: {
    currentDate() {
      return new Date();
    },
    isDateRangeSelected() {
      return this.currentDateRangeSelection.id === CUSTOM_DATE_RANGE_ID;
    },
    to() {
      if (this.isDateRangeSelected) {
        return this.toCustomDate(this.customDateRange[1]);
      }
      if (this.currentDateRangeSelection.id === 1) {
        const toDate = subDays(new Date(), 1);
        return this.toCustomDate(toDate);
      }

      return this.toCustomDate(new Date());
    },
    from() {
      if (this.isDateRangeSelected) {
        return this.fromCustomDate(this.customDateRange[0]);
      }
      const dateRange = {
        0: 0,
        1: 1,
        2: 6,
        3: 29,
      };
      const diff = dateRange[this.currentDateRangeSelection.id];
      const fromDate = subDays(new Date(), diff);
      return this.fromCustomDate(fromDate);
    },
  },
  mounted() {
    this.onDateRangeChange(true);
  },
  methods: {
    onDateRangeChange(isFiredOnMount = false) {
      this.$emit('date-range-change', {
        from: this.from,
        to: this.to,
        date_range_name: this.currentDateRangeSelection.name,
        isFiredOnMount: isFiredOnMount,
      });
    },
    fromCustomDate(date) {
      return getUnixTime(startOfDay(date));
    },
    toCustomDate(date) {
      return getUnixTime(endOfDay(date));
    },
    changeDateSelection(selectedRange) {
      this.currentDateRangeSelection = selectedRange;
      this.onDateRangeChange();
    },
    onChange(value) {
      this.customDateRange = value;
      this.onDateRangeChange();
    },
  },
};
</script>
