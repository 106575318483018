<template>
  <div class="time-selector-with-office-check">
    <time-selector
      :label="$t(`SLA.CREATE.SECTIONS.METRICS.${labelKey.toUpperCase()}.TITLE`)"
      :time-in-minutes="data.time"
      @select="handleUpdate({ time: $event })"
    />
    <label class="container-checkbox">
      <div class="body-b2 label-text">
        <span v-text="$t('SLA.CREATE.SECTIONS.METRICS.OOO')" />
      </div>
      <input v-model="isOutOfOfficeIncluded" type="checkbox" />
      <span class="checkmark" />
    </label>
  </div>
</template>
<script>
import TimeSelector from './selectors/TimeSelector.vue';

export default {
  components: {
    TimeSelector,
  },
  props: {
    labelKey: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => ({ exclude_out_of_office_hours: false, time: 0 }),
    },
  },
  computed: {
    isOutOfOfficeIncluded: {
      get() {
        return !this.data.exclude_out_of_office_hours;
      },
      set(value) {
        this.handleUpdate({ exclude_out_of_office_hours: !value });
      },
    },
  },
  methods: {
    handleUpdate(newData) {
      this.$emit('update', {
        [this.labelKey]: { ...this.data, ...newData },
      });
    },
  },
};
</script>
