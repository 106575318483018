<template>
  <div class="custom-fields-rhs-panel">
    <div
      class="flex-column gap--medium custom-scroll"
      style="height: calc(100% - 6rem)"
    >
      <div class="custom-fields-name flex-row flex-align flex-wrap gap--small">
        <div class="body-b2 text-light">
          Name :
        </div>
        <mandatory-badge v-if="customField.mandatory" />
        <div
          class="title-h4 text-dark line-clamp-1"
          v-text="customFieldName"
        ></div>
      </div>
      <template v-if="customFieldDescription">
        <div class="flex-column gap--smaller">
          <div class="body-b2 text-light">
            Description:
          </div>
          <div
            class="custom-fields-description body-b2 text-dark line-clamp-3"
            v-text="customFieldDescription"
          ></div>
        </div>
      </template>
      <custom-field-summary :custom-field="customField" />
      <div class="rhs-panel-footer">
        <woot-base-button
          size="small"
          variant="secondary"
          @click="editCustomField"
        >
          EDIT
        </woot-base-button>
      </div>
    </div>
  </div>
</template>

<script>
import MandatoryBadge from './MandatoryBadge';
import CustomFieldSummary from './FieldSummary.vue';

export default {
  components: {
    MandatoryBadge,
    CustomFieldSummary,
  },
  props: {
    customField: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    customFieldName() {
      return this.customField?.name;
    },
    customFieldDescription() {
      return this.customField?.description;
    },
  },
  methods: {
    editCustomField() {
      this.$router.push({
        name: 'settings_edit_custom_field',
        params: { custom_field_id: this.customField?.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.custom-fields-rhs-panel {
  padding: $space-two;
  height: 100%;

  .rhs-panel-footer {
    position: absolute;
    background-color: $color-white;
    padding: $space-two $zero;
    bottom: $zero;
  }
}
</style>
