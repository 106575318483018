<template>
  <div class="view-box columns">
    <settings-header
      icon="megaphone"
      :header-title="$t('BROADCAST.HEADER')"
      :show-back-button="false"
    />
    <div v-if="isLoading" class="center-aligned">
      <woot-loading-state
        :message="$t('BROADCAST.BROADCAST_TAB.LOADING_TEXT')"
      />
    </div>
    <div v-else class="settings-box">
      <div class="campaign-name flex-row-center">
        <span class="back-button" @click="navigateBack">
          <icons name="chevronLeft" color="green" size="large" />
        </span>
        <input
          v-model="campaignName"
          type="text"
          class="broadcast-input title-h1 text-light"
          placeholder="Enter Your Campaign Name"
        />
      </div>

      <div class="lime-card-1dp broadcast-container custom-scroll">
        <div class="mg-bottom--medium">
          <woot-folder-tabs :active="step" :tab-list="tabList" />
        </div>
        <keep-alive>
          <select-template v-if="step === 0" @next="nextStep" />
          <select-users
            v-if="step === 1"
            :selected-template="selectedTemplate"
            :params-count="templateParameterCount"
            :needs-media-header="needsMediaHeader"
            :opt-out-list-prop="optOutListProp"
            @next="nextStepAfterCSVUpload"
            @prev="prevStepBeforeCSVUpload"
          />
          <confirm-test
            v-if="step === 2"
            :selected-template="selectedTemplate"
            :selected-inbox="selectedInbox"
            :first-row="firstRow"
            :params-count="templateParameterCount"
            @testMessage="sendTestMessage"
            @sendBrd="sendMsgConfirmation"
            @prev="prevStepBeforeBroadcast"
            @scheduleBrd="scheduleBroadcast"
          />
        </keep-alive>
        <woot-delete-modal
          :show.sync="showConfirmationModal"
          :on-close="closeModal"
          :on-confirm="send"
          :message="
            `You are about to send this Broadcast message to ${phoneNumberList.length} people. Would you like to confirm?`
          "
          :confirm-text="$t('BROADCAST.BROADCAST_TAB.UPLOAD_CSV.CONTINUE')"
          :reject-text="$t('BROADCAST.BROADCAST_TAB.UPLOAD_CSV.BACK')"
        />
        <woot-modal :show="showSuccessModal" :show-close="false" small-modal>
          <div class="flex-colum-center" style="padding:3.6rem">
            <span>
              <lottie
                :options="defaultOptions"
                :height="90"
                :width="90"
                @animCreated="handleAnimation"
              />
            </span>
            <div class="title-h1 text-dark mg-top">
              {{ successModalContent }}
            </div>
          </div>
        </woot-modal>
        <schedule-broadcast
          :show="showScheduleModal"
          :on-cancel="closeScheduleModal"
          :on-save-and-schedule="convertToTimeStamp"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SelectTemplate from './components/SelectTemplate.vue';
import SelectUsers from './components/SelectUsers.vue';
import ConfirmTest from './components/ConfirmAndTest.vue';
import ScheduleBroadcast from './components/ScheduleBroadcast.vue';
import SettingsHeader from '../settings/SettingsHeader';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import alertMixin from 'shared/mixins/alertMixin';
import broadcastMixin from 'dashboard/mixins/broadcast';
import downloadMixin from 'shared/mixins/downloadMixin.js';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import shopifyMixin from 'dashboard/mixins/shopify';
import Lottie from 'vue-lottie';
import animationData from 'dashboard/assets/lottie/successfulBroadcast.json';

export default {
  components: {
    SelectTemplate,
    SelectUsers,
    ConfirmTest,
    ScheduleBroadcast,
    SettingsHeader,
    Lottie,
  },
  mixins: [
    alertMixin,
    broadcastMixin,
    googleAnalyticsMixin,
    downloadMixin,
    messageFormatterMixin,
    shopifyMixin,
  ],
  data() {
    return {
      step: 0,
      campaignName: '',
      selectedTemplate: null,
      selectedInbox: null,
      phoneNumbersCount: 0,
      selectedTemplateId: null,
      templateParameters: [],
      templateParameterCount: 0,
      files: [],
      firstRow: null,
      phoneNumberList: [],
      errorMessage: this.$t('BROADCAST.BROADCAST_TAB.FORM.ERROR.UNKOWN'),
      isUploading: false,
      isLoading: false,
      conversationStatus: 'outbound',
      showConfirmationModal: false,
      defaultOptions: { animationData, loop: false },
      showSuccessModal: false,
      showScheduleModal: false,
      scheduledAt: null,
    };
  },
  computed: {
    tabList() {
      return [
        {
          key: 'broadcast',
          name: this.$t('BROADCAST.BROADCAST_TAB.STAGE_1'),
        },
        {
          key: 'download',
          name: this.$t('BROADCAST.BROADCAST_TAB.STAGE_2'),
        },
        {
          key: 'userCohort',
          name: this.$t('BROADCAST.BROADCAST_TAB.STAGE_3'),
        },
      ];
    },

    broadcastSizeWarningText() {
      if (this.phoneNumbersCount > 10000 && this.phoneNumbersCount < 100000)
        return this.$t('BROADCAST.BROADCAST_TAB.WARNINGS.BRD_SIZE.SMALL');
      if (this.phoneNumbersCount > 100000)
        return this.$t('BROADCAST.BROADCAST_TAB.WARNINGS.BRD_SIZE.LARGE');
      return '';
    },
    successModalContent() {
      if (this.scheduledAt) {
        return 'Message scheduled succesfully!';
      }
      return 'Message sent succesfully!';
    },
  },
  mounted() {
    this.$store
      .dispatch('contacts/get', {
        page: 1,
        opt_outs: true,
        only_phones: true,
      })
      .then(response => {
        this.optOutListProp = response;
      });
    this.$store.dispatch('getTemplate');
  },
  methods: {
    navigateBack() {
      this.$router.push(
        `/app/accounts/${this.$route.params.accountId}/broadcast/reports?&page=1`
      );
    },
    handleAnimation(anim) {
      this.anim = anim;
      this.anim.setSpeed(1.2);
    },
    closeModal() {
      this.showConfirmationModal = false;
    },
    nextStep(selectedTemplate, count, inbox, templateId, needsMediaHeader) {
      this.selectedTemplate = selectedTemplate;
      this.templateParameterCount = count;
      this.selectedInbox = inbox;
      this.selectedTemplateId = templateId;
      this.needsMediaHeader = needsMediaHeader;
      this.step += 1;
    },
    nextStepAfterCSVUpload(params, phNoList, selectedFiles, count, row) {
      this.step += 1;
      this.templateParameters = params;
      this.phoneNumberList = phNoList;
      this.files = selectedFiles;
      this.phoneNumbersCount = count;
      this.firstRow = row;
    },
    convertToTimeStamp(date, time) {
      let timestamp = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        time.HH,
        time.mm
      ).getTime();
      this.scheduledAt = timestamp / 1000;
      this.send();
    },
    closeScheduleModal() {
      this.showScheduleModal = false;
    },
    prevStepBeforeCSVUpload() {
      this.step = 0;
    },
    prevStepBeforeBroadcast() {
      this.step = 1;
    },
    sendTestMessage(testPhoneNumber) {
      let error = false;
      this.inboxId = this.selectedInbox.id;

      if (!error) {
        this.$store
          .dispatch('broadcast', {
            template_parameters: this.templateParameters,
            template_id: this.selectedTemplateId,
            message_type: this.messageType ? 'custom' : 'template',
            phone_numbers:
              testPhoneNumber === 'fake'
                ? this.phoneNumberList
                : [testPhoneNumber],
            content: this.selectedTemplate.body,
            inbox_id: this.inboxId,
            status: this.conversationStatus,
            is_fake: testPhoneNumber === 'fake',
            customAttributes: {
              is_test: true,
            },
            file: this.files.length ? this.files[0].file : undefined,
            name: this.campaignName,
          })
          .then(() => {
            this.showAlert(`Successfully sent a test message.`);
            error = false;
          })
          .catch(err => {
            if (err.data && err.data.template_found) {
              this.errormessage = this.$t(
                'BROADCAST.BROADCAST_TAB.FORM.ERROR.SERVER'
              );
            } else {
              this.errormessage = err.data.error;
            }
            error = true;
            this.showAlert(this.errormessage);
          });
      }
    },
    scheduleBroadcast() {
      this.showScheduleModal = true;
    },
    sendMsgConfirmation() {
      this.showConfirmationModal = true;
    },
    async send() {
      this.showConfirmationModal = false;
      this.showScheduleModal = false;
      this.$store
        .dispatch('broadcast', {
          template_parameters: this.templateParameters,
          template_id: this.selectedTemplateId,
          phone_numbers: this.phoneNumberList,
          content: this.selectedTemplate.body,
          status: this.conversationStatus,
          name: this.campaignName,
          scheduled_at: this.scheduledAt,
        })
        .then(status => {
          if (status === 200) {
            this.showSuccessModal = true;
            setTimeout(() => {
              this.navigateBack();
            }, 3500);
          }
        })
        .catch(error => {
          let timeLeft = error?.data?.error.substring(
            error?.data?.error.indexOf(',')
          );

          this.errorMessage = this.$t(
            'BROADCAST.BROADCAST_TAB.FORM.ERROR.SERVER'
          );
          if (error?.status === 429) {
            this.showAlert(
              this.$t('BROADCAST.BROADCAST_TAB.FORM.ERROR.ERROR_429') +
                timeLeft,
              'warning',
              2000
            );
          } else if (error?.status === 499) {
            this.showAlert(
              this.$t('BROADCAST.BROADCAST_TAB.FORM.ERROR.ERROR_499'),
              'info',
              2000
            );
          } else {
            this.showAlert(this.errorMessage, 'error', 2000);
          }
          setTimeout(() => {
            this.navigateBack();
          }, 2000);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';
.settings-box {
  position: relative;
  padding-top: 8.6rem;
  height: 90vh;

  .broadcast-container {
    margin: $zero $space-normal $zero $zero;
    padding: $space-medium;
    height: 98%;
  }
}
</style>
