/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
import * as types from '../../mutation-types';
import actions from './actions';

const state = {
  callInformation: {},
  callMeta: { notes: '', callDuration: '' },
  currentCallState: {
    callType: '',
    callDirection: '',
    eventName: '',
    hangupCause: '',
    customerName: '',
    customerNumber: '',
    conversationId: null,
  },
  uiFlags: {
    showCallPopup: false,
    showCallNotification: false,
    initiatingCall: false,
    callFailure: false,
  },
};

export const getters = {
  getCallStatesUiFlags(_state) {
    return _state.uiFlags;
  },
  getCurrentCallState(_state) {
    return _state.currentCallState;
  },
  getCallMeta(_state) {
    return _state.callMeta;
  },
};

export const mutations = {
  [types.default.SET_CALL_INITIATION_UI_FLAG](_state, data) {
    _state.uiFlags.initiatingCall = data.initiatingCall;
  },
  [types.default.CALL_FAILURE_UI_FLAG](_state, data) {
    _state.uiFlags.callFailure = data.callFailure;
  },
  [types.default.SET_CALL_POPUP_FLAG](_state, data) {
    _state.uiFlags.showCallPopup = data;
  },
  [types.default.SET_CALL_NOTIFICATION_FLAG](_state, data) {
    _state.uiFlags.showCallNotification = data;
  },
  [types.default.SET_CALL_INFORMATION](_state, data) {
    _state.callInformation = data;
  },
  [types.default.SET_CALL_META](_state, data) {
    _state.callMeta = data;
  },
  [types.default.UPDATE_CALL_STATE](_state, data) {
    const {
      call_type: callType,
      call_direction: callDirection,
      event_name: eventName,
      hangup_cause: hangupCause,
      contact,
      customer_number: customerNumber,
      conversation_display_id,
    } = data;

    const customerName =
      contact === undefined ? _state.currentCallState.customerName : contact;

    const conversationId =
      conversation_display_id === undefined
        ? _state.currentCallState.conversationId
        : conversation_display_id;

    _state.currentCallState = {
      callType,
      callDirection,
      eventName,
      hangupCause,
      customerName,
      customerNumber,
      conversationId,
    };
  },
  [types.default.RESET_CALL_STATE](_state) {
    _state.currentCallState = {
      callType: '',
      callDirection: '',
      eventName: '',
      hangupCause: '',
      customerName: '',
      customerNumber: '',
      conversationId: null,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
