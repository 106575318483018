<template>
  <woot-modal
    :show.sync="opened"
    :custom-style="{ width: '600px' }"
    :on-close="onClose"
  >
    <woot-modal-header :header-title="headerTitle" />
    <form class="modal-body" @submit.prevent="onSubmit">
      <woot-input
        v-model="name"
        size="small"
        :label="$t('CONVERSATION.CONVERSATION_VIEWS.ADD.FORM.NAME')"
        type="text"
        auto-focus
        required
        :has-error="$v.name.$error"
        class="mg-bottom medium-6"
        :class="{ error: $v.name.$error }"
        :error="$t('CONVERSATION.CONVERSATION_VIEWS.ADD.FORM.ERROR')"
        :placeholder="
          $t('CONVERSATION.CONVERSATION_VIEWS.ADD.FORM.PLACEHOLDER')
        "
        @blur="$v.name.$touch"
      />

      <div class="conversation-view-filters">
        <conversation-filter-tab
          class="mg-bottom"
          :applied-filters="filters"
          :filter-list="conditionalFilterList"
          max-dropdown-height="16rem"
          @filterChange="onUpdateFilter"
        />

        <label class="container-checkbox" :class="{ disabled: isDefaultView }">
          <div class="body-b2 label-text">
            <span v-text="makeDefaultText" />
          </div>
          <input v-model="isDefault" type="checkbox" @input="onInputSelected" />
          <span class="checkmark" />
        </label>
      </div>

      <div class="modal-body--footer flex-row flex-justify--end">
        <woot-base-button
          size="small"
          type="submit"
          :loading="uiFlags.isSaving"
          :disabled="$v.$invalid"
        >
          {{ $t('CONVERSATION.CONVERSATION_VIEWS.ADD.FORM.SAVE') }}
        </woot-base-button>
      </div>
    </form>
  </woot-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import ConversationFilterTab from 'dashboard/components/widgets/conversation/filter/ConversationFilters';

import alertMixin from 'shared/mixins/alertMixin';

import wootConstants from 'dashboard/constants';
import { DEFAULT_FILTERS, VIEW_TYPE } from '../constants';

export default {
  components: { ConversationFilterTab },
  mixins: [alertMixin],
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    isDefaultView: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      name: '',
      filters: { ...DEFAULT_FILTERS },
      isDefault: false,
    };
  },
  validations: {
    name: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'conversationViews/getUiFlags',
    }),
    isEditMode() {
      return !!this.data;
    },
    headerTitle() {
      return this.isEditMode
        ? this.$t('CONVERSATION.CONVERSATION_VIEWS.UPDATE.TITLE')
        : this.$t('CONVERSATION.CONVERSATION_VIEWS.ADD.TITLE');
    },
    makeDefaultText() {
      return !this.isDefaultView
        ? this.$t('CONVERSATION.CONVERSATION_VIEWS.ADD.FORM.MAKE_DEFAULT')
        : this.$t(
            'CONVERSATION.CONVERSATION_VIEWS.ADD.FORM.DISABLED_MAKE_DEFAULT'
          );
    },
    conditionalFilterList() {
      let filtersList = JSON.parse(
        JSON.stringify(this.$t('CONVERSATION.CONVERSATION_VIEWS.FILTER_LIST'))
      );
      if (this.filters.assigneeType === wootConstants.ASSIGNEE_TYPE.ME) {
        filtersList = filtersList.map(group => {
          group.FILTERS = group.FILTERS.filter(
            filter => filter.key !== 'agent'
          );
          return group;
        });
      }
      return filtersList;
    },
  },
  mounted() {
    if (this.isEditMode) this.populateData();
  },
  methods: {
    populateData() {
      this.name = this.data.name;
      this.filters.assigneeType = this.data.filters.assignee_type;
      this.filters.status = this.data.filters.status;
      this.filters.teamId = this.data.filters.team_id;
      this.filters.selectedAgent = this.data.filters.selected_agent_id;
      this.filters.inboxId = this.data.filters.inbox_id;
      this.isDefault = this.isDefaultView;
    },
    onInputSelected() {
      this.isDefault = !this.isDefault;
    },
    onUpdateFilter(key, value) {
      switch (key) {
        case 'assignee_type':
          this.filters.assigneeType = value.key;
          break;

        case 'conversation_status':
          this.filters.status = value;
          break;

        case 'teams':
          this.filters.teamId = value;
          break;

        case 'agent':
          this.filters.selectedAgent = value;
          break;

        case 'change_inbox':
          this.filters.inboxId = value.id || undefined;
          break;

        default:
          this.showAlert(
            this.$t('CONVERSATION.FILTER.APPLICATION.ERROR'),
            'error'
          );
          break;
      }
    },
    generatePayload() {
      const {
        assigneeType: assignee_type,
        inboxId: inbox_id,
        selectedAgent: selected_agent_id,
        status,
        teamId: team_id,
      } = this.filters;

      const filtersPayload = {
        assignee_type,
        inbox_id,
        selected_agent_id,
        status,
        team_id,
      };

      if (assignee_type === wootConstants.ASSIGNEE_TYPE.ME) {
        filtersPayload.selected_agent_id = null;
      }

      return {
        name: this.name,
        filters: filtersPayload,
        view_type: VIEW_TYPE.custom,
        isDefault: this.isDefault,
      };
    },
    onSubmit() {
      const payload = this.generatePayload();
      if (this.data?.id) payload.id = this.data.id;
      this.$emit('onSubmit', payload);
    },
    onClose() {
      this.$emit('onClose');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.modal-body {
  padding: $space-normal $space-medium;

  .conversation-view-filters {
    margin-bottom: $space-slab * 10;
  }

  .container-checkbox {
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
</style>
