import store from 'dashboard/store';
import { convertToTitleCase } from './stringUtils';

const getInboxNameById = id => {
  const inbox = store.getters['inboxes/getInboxes'].find(ibx => ibx.id === id);
  return inbox ? inbox.name : id;
};

const getConversationLabelNameById = id => {
  const label = store.getters['labels/getLabelsWithoutGroups'].find(
    lbl => lbl.id === id
  );
  return label ? label.title : id;
};

const getContactLabelNameById = id => {
  const label = store.getters['labels/getContactLabelsWithoutGroups'].find(
    lbl => lbl.id === id
  );
  return label ? label.title : id;
};

const getTeamNameById = id => {
  const team = store.getters['teams/getTeams'].find(tm => tm.id === id);
  return team ? team.name : id;
};

const getAgentNameById = id => {
  const agent = store.getters['agents/getAgents'].find(ag => ag.id === id);
  return agent ? agent.name : id;
};

export const mapIdsToNames = (values, key) => {
  if (!values || values.length === 0) return ['_____'];
  if (key === 'inbox_id') return values.map(getInboxNameById);

  if (['add_label', 'label_id'].includes(key))
    return values.map(getConversationLabelNameById);

  if (['add_contact_label', 'contact_label_id'].includes(key))
    return values.map(getContactLabelNameById);

  if (['assign_team', 'team_id'].includes(key))
    return values.map(getTeamNameById);

  if (['assign_agent', 'agent_id'].includes(key))
    return values.map(getAgentNameById);
  return values;
};

export const getStyledKey = key => {
  if (key === 'inbox_id') return 'inbox';

  if (['add_label', 'label_id'].includes(key)) return 'tag';

  if (['add_contact_label', 'contact_label_id'].includes(key))
    return 'contact tag';

  return convertToTitleCase(key);
};
