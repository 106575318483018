/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';

class OrdersApi extends ApiClient {
  constructor() {
    super('integrations', { accountScoped: true });
  }

  get(data) {
    return axios.get(`${this.url}/orders`, {
      params: data,
    });
  }

  searchOrders({ type, cursor, query }) {
    return axios.get(`${this.url}/orders/search`, {
      params: { type, cursor, [type]: query },
    });
  }

  fetchShopifyShippingRates({ orderValue }) {
    return axios.get(`${this.url}/orders/fetch_shipping_rates`, {
      params: {
        order_value: orderValue,
      },
    });
  }

  fetchShopifyOrders(data) {
    return axios.get(`${this.url}/orders/shopify_orders`, {
      params: data,
    });
  }

  fetchShopifyOrderDetail(data) {
    return axios.get(`${this.url}/orders/fetch_order_detail`, {
      params: data,
    });
  }

  fetchDirectShopifyOrderDetail(data) {
    return axios.get(`${this.url}/orders/fetch_direct_order_detail`, {
      params: data,
    });
  }

  updateShopifyOrderAddress({
    orderId,
    shippingAddress,
    agentId,
    conversationId,
  }) {
    return axios.post(`${this.url}/orders/update_order_address`, {
      order_id: orderId,
      shopify_shipping_address: shippingAddress,
      agent_id: agentId,
      conversation_id: conversationId,
    });
  }

  createCODOrder({ draftOrderId, note, tags, shippingCharge }) {
    return axios.get(`${this.url}/orders/create_cod_order`, {
      params: {
        draft_order_id: draftOrderId,
        note: note,
        tags: tags,
        shipping_charge: shippingCharge,
      },
    });
  }

  calculateOrderRefund({ orderId }) {
    return axios.get(`${this.url}/orders/calculate_order_refund`, {
      params: {
        order_id: orderId,
      },
    });
  }

  initiateOrderRefund({
    orderId,
    note,
    userRefundAmount,
    transactions,
    isEditedOrder,
    agentId,
    conversationId,
  }) {
    return axios.post(`${this.url}/orders/initiate_order_refund`, {
      order_id: orderId,
      note: note,
      user_refund_amount: userRefundAmount,
      transactions: transactions,
      is_edited_order: isEditedOrder,
      agent_id: agentId,
      conversation_id: conversationId,
    });
  }

  cancelOrder(data) {
    return axios.post(`${this.url}/orders/cancel_order`, data);
  }

  initiatePrepaidOrder({ draftOrderId }) {
    return axios.get(`${this.url}/orders/initiate_prepaid_order`, {
      params: {
        draft_order_id: draftOrderId,
      },
    });
  }

  createDraftOrder({
    cartId,
    shippingAddress,
    isCashOnDelivery,
    conversationId,
    inboxId,
    agentId,
  }) {
    let payload = {
      cart: cartId,
      used_default_customer_address: false,
      is_cod: isCashOnDelivery,
      final: false,
      conversation_id: conversationId,
      inbox_id: inboxId,
      agent_id: agentId,
    };
    if (shippingAddress !== -1) {
      payload.shipping_address = shippingAddress;
      payload.billing_address = shippingAddress;
    }
    return axios.post(`${this.url}/orders/create_draft_order`, payload);
  }

  updateDraftOrder({
    draftOrderId,
    editRequest,
    shippingAddress,
    conversationId,
    agentId,
    discountType,
    appliedDiscount = 0,
    appliedShippingCharge = 0,
    note = '',
    tags = 'LC_HD_ORDER,',
    isCashOnDelivery = true,
  }) {
    return axios.post(`${this.url}/orders/update_draft_order`, {
      draft_order_id: draftOrderId,
      edit_request: editRequest,
      billing_address: shippingAddress,
      shipping_address: shippingAddress,
      used_default_customer_address: false,
      conversation_id: conversationId,
      agent_id: agentId,
      note: note,
      tags: tags,
      is_cod: isCashOnDelivery,
      discount_type: discountType,
      applied_discount: appliedDiscount,
      shipping_charges: appliedShippingCharge,
    });
  }

  checkPaymentEnabled() {
    return axios.get(`${this.url}/orders/check_payment_enabled`);
  }
}

export default new OrdersApi();
