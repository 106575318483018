/* global axios */
import ApiClient from './ApiClient';

class SlaApi extends ApiClient {
  constructor() {
    super('sla_policies', { accountScoped: true });
  }

  updateOrder(sla_policy_ids) {
    return axios.patch(`${this.url}/update_order`, { sla_policy_ids });
  }
}

export default new SlaApi();
