import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({ currentChat: 'getSelectedChat' }),
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
  },
};
