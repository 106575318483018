<template>
  <div>
    <div class="content-margin">
      <h1
        class="title-h1 text-dark"
        v-html="$t('ONBOARDING.WHATSAPP.SETUP.HEADING')"
      />
      <span
        class="body-b2 text-light"
        v-html="$t('ONBOARDING.WHATSAPP.SETUP.SUB_HEADING')"
      />
    </div>
    <div class="flex-row flex-justify--between gap--large flex-align--start">
      <div>
        <span class="body-b2 text-light">
          Live in
        </span>
        <call-timer
          v-if="timerTime !== null"
          ref="countDown"
          class="countdown"
          :counter.sync="timerTime"
        />
      </div>
      <reference-box
        class="reference-box content-margin"
        :class="currentStatus.class"
        :icon-size="currentStatus.iconSize"
        :view-box="currentStatus.viewBox"
        :icon-name="currentStatus.iconName"
        :icon-color="currentStatus.iconColor"
        :content="currentStatus.content"
      />
    </div>
    <div class="flex-row flex-justify--end gap--small">
      <woot-base-button
        :variant="timeLimitExceeded ? 'tertiary' : 'primary'"
        @click="onCloseModal"
      >
        {{ $t('ONBOARDING.WHATSAPP.SETUP.EXPLORE_HELPDESK') }}
      </woot-base-button>
      <connect-button
        v-if="timeLimitExceeded"
        class="navigation-button"
        :label="$t('ONBOARDING.WHATSAPP.SETUP.RETRY')"
        :query-parameters="{
          redirect_url: setRedirectUrl(),
          state: inbox.phone_number,
        }"
      />
    </div>
  </div>
</template>
<script>
import CallTimer from 'dashboard/components/ui/calling/CallTimer';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';
import ConnectButton from 'dashboard/routes/dashboard/settings/inbox/components/360Dialog/ConnectButton';

import alertMixin from 'shared/mixins/alertMixin';

import { TIMER_VALUES } from './constants';
import { ONBOARDING_SCREENS } from 'dashboard/constants';

export default {
  components: {
    CallTimer,
    ReferenceBox,
    ConnectButton,
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      timerTime: null,
    };
  },
  computed: {
    timeLimitExceeded() {
      return this.timerTime < TIMER_VALUES.TIME_EXCEEDED;
    },
    currentStatus() {
      if (this.timeLimitExceeded)
        return {
          iconName: 'error',
          iconColor: 'red',
          iconSize: 'semimedium',
          class: 'ref-box--red',
          content: this.$t('ONBOARDING.WHATSAPP.SETUP.STATUSES.ERROR'),
        };

      if (this.timerTime < 0)
        return {
          iconName: 'warning',
          iconColor: 'warning evenodd',
          iconSize: 'medium',
          content: this.$t('ONBOARDING.WHATSAPP.SETUP.STATUSES.DELAY'),
        };

      return {
        iconName: 'info',
        iconColor: 'green',
        iconSize: 'semimedium',
        viewBox: '0 0 24 24',
        class: 'ref-box--green',
        content: this.$t('ONBOARDING.WHATSAPP.SETUP.STATUSES.CREATING'),
      };
    },
  },
  mounted() {
    this.initTimer();
  },
  inject: ['navigateTo', 'onCloseModal'],
  methods: {
    initTimer() {
      const onboardingTime = this.inbox.onboarding_started_at;
      if (!onboardingTime) {
        this.showAlert(
          this.$t('ONBOARDING.WHATSAPP.SETUP.ERROR_MESSAGE'),
          'error',
          5000
        );
        this.navigateTo(ONBOARDING_SCREENS.THREE_SIXTY_CONNECT.key);
        return;
      }
      const date = new Date(onboardingTime);
      const currentDate = new Date();
      const timePassedFromOnboardedTimeInSeconds =
        Math.trunc(currentDate.getTime() / 1000) -
        Math.trunc(date.getTime() / 1000);
      this.timerTime =
        TIMER_VALUES.ESTIMATED_TIME - timePassedFromOnboardedTimeInSeconds;

      this.$nextTick(() => {
        this.$refs.countDown.startCountDown();
      });
    },
    setRedirectUrl() {
      const redirectUrl = window.location.origin + this.$route.path;

      return redirectUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.content-margin {
  margin-bottom: $space-normal;
}

.countdown {
  transform: scale(1.1);

  ::v-deep .seconds {
    color: $warn-red-500;
  }
}

ol {
  > li {
    list-style-type: disc;
    margin-top: $space-smaller;
  }
}

.reference-box {
  max-width: 100%;
}

.ref-box {
  &--green {
    background-color: $pg-light-1;
  }
  &--red {
    background-color: $warn-red-100;

    ::v-deep .text-content {
      color: $text-dark;
    }
  }
}

.navigation-button {
  width: $space-slab * 10;
}
</style>
