<template>
  <div class="small-12 columns">
    <page-header :header-title="$t('INBOX_MGMT.ADD.EXOTEL_CHANNEL.TITLE')">
    </page-header>
    <woot-loading-state
      v-if="uiFlags.isCreating"
      :message="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.LOADING_MESSAGE')"
    />
    <form
      v-if="!uiFlags.isCreating"
      @keydown.enter.prevent
      @submit.prevent="createChannel"
    >
      <div class="settings-sub-menu-content--larger mg-bottom--jumbo">
        <woot-input
          v-model.trim="channelName"
          required
          auto-focus
          class="medium-12 columns content-margin"
          :class="{ error: $v.channelName.$error }"
          :has-error="$v.channelName.$error"
          :error="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.CHANNEL_NAME.ERROR')"
          :label="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.CHANNEL_NAME.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.VOICE_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
          "
          @blur="$v.channelName.$touch"
        />
        <woot-input
          v-model.trim="apiKey"
          required
          class="medium-12 columns content-margin"
          :class="{ error: $v.apiKey.$error }"
          :has-error="$v.apiKey.$error"
          :error="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.API_KEY.ERROR')"
          :label="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.API_KEY.LABEL')"
          :placeholder="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.API_KEY.PLACEHOLDER')"
          @blur="$v.apiKey.$touch"
        />
        <woot-input
          v-model.trim="apiToken"
          required
          class="medium-12 columns content-margin"
          :class="{ error: $v.apiToken.$error }"
          :has-error="$v.apiToken.$error"
          :error="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.API_TOKEN.ERROR')"
          :label="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.API_TOKEN.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.VOICE_CHANNEL.API_TOKEN.PLACEHOLDER')
          "
          @blur="$v.apiToken.$touch"
        />
        <woot-input
          v-model.trim="subdomain"
          required
          class="medium-12 columns content-margin"
          :class="{ error: $v.subdomain.$error }"
          :has-error="$v.subdomain.$error"
          :error="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.SUBDOMAIN.ERROR')"
          :label="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.SUBDOMAIN.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.VOICE_CHANNEL.SUBDOMAIN.PLACEHOLDER')
          "
          @blur="$v.subdomain.$touch"
        />
        <woot-input
          v-model.trim="accountSid"
          required
          class="medium-12 columns content-margin"
          :class="{ error: $v.accountSid.$error }"
          :has-error="$v.accountSid.$error"
          :error="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.ACCOUNT_SID.ERROR')"
          :label="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.ACCOUNT_SID.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.VOICE_CHANNEL.ACCOUNT_SID.PLACEHOLDER')
          "
          @blur="$v.accountSid.$touch"
        />
        <!-- takes parser which allows only numbers to be entered -->
        <keyword-input
          :keywords="virtualNumbers"
          type="Number"
          validation-pattern="^[0-9]*$"
          size="small"
          input-label="Virtual Numbers"
          input-placeholder="Type a virtual number and press enter"
          required
          help-text="Phone numbers should start with the country code without `+` sign"
          @updateKeywords="virtualNumbers = $event"
        />
      </div>
      <div class="medium-12 columns settings-sub-menu-bottom-nav">
        <woot-base-button
          variant="secondary"
          @click.prevent="() => $router.go(-1)"
        >
          Back
        </woot-base-button>
        <woot-base-button
          :loading="uiFlags.isCreating"
          :disabled="$v.$invalid"
          type="submit"
        >
          Next
        </woot-base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';

import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import KeywordInput from 'dashboard/components/KeywordInput.vue';

export default {
  components: {
    PageHeader,
    KeywordInput,
  },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      apiKey: '',
      apiToken: '',
      subdomain: '',
      channelName: '',
      virtualNumbers: [],
      accountSid: '',
    };
  },
  validations: {
    apiKey: { required },
    apiToken: { required },
    subdomain: { required },
    channelName: { required },
    accountSid: { required },
    virtualNumbers: { required },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'getUIFlags',
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('AGENT_MGMT.FORM_ERROR'), 'error');
        return;
      }
      const channel = await this.$store
        .dispatch('inboxes/createExotelChannel', {
          account_id: this.accountId,
          account_SID: this.accountSid,
          api_key: this.apiKey,
          api_token: this.apiToken,
          name: this.channelName,
          subdomain: this.subdomain,
          virtual_numbers: this.virtualNumbers,
        })
        .catch(() => {
          this.showAlert(
            this.$t('INBOX_MGMT.ADD.VOICE_CHANNEL.ERROR.FAIL_INBOX_CREATION'),
            'error'
          );
        });

      const { id: channel_id, channel_type, name: channel_name } = channel;
      this.mix_panel_clicked_create_inbox_successful('Voice Channel', 'Exotel');

      router.replace({
        name: 'settings_inboxes_add_agents',
        params: {
          page: 'new',
          inbox_id: channel_id,
        },
        query: { channel_type, channel_name },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.content-margin {
  margin-bottom: 2.4rem;
}

.medium-12 {
  .button {
    float: right;
  }
}
</style>
