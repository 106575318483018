<template>
  <div style="position: relative" class="full-height">
    <div class="tags-rhs-panel">
      <div>
        <label-details
          :tag-title="selectedTagVal.title"
          :tag-description="selectedTagVal.description"
          :resource="resource"
          @labelDetails="onChangeLabelDetails"
          @formValidity="disableSavebtn = $event"
        />
      </div>
    </div>
    <label-heirarchy
      :key="selectedType"
      :label-id="selectedTagVal.id"
      style="height: calc(100vh - 60rem)"
      :parent-id="selectedTagVal.parent_id"
      :resource="selectedType"
      @labelHeirarchy="labelHeirarchySetting = $event"
    />
    <div class="save-btn flex-row flex-justify--end">
      <woot-base-button
        variant="primary"
        :disabled="disableSavebtn"
        @click="emitEditLabelEvent"
      >
        Save
      </woot-base-button>
    </div>
  </div>
</template>

<script>
import LabelDetails from 'dashboard/routes/dashboard/settings/labels/settings/LabelDetails';
import LabelHeirarchy from 'dashboard/routes/dashboard/settings/labels/settings/LabelHeirarchy';

import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import { TYPES } from 'dashboard/routes/dashboard/settings/labels/constants';

export default {
  components: { LabelDetails, LabelHeirarchy },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  props: {
    selectedTag: {
      type: Object,
      default: () => {},
    },
    resource: {
      type: String,
      default: 'Conversation',
    },
  },
  data() {
    return {
      disableSavebtn: false,
      labelDetailsSetting: {},
      labelHeirarchySetting: {},
      selectedType: TYPES.conversation,
      selectedTagVal: this.selectedTag,
    };
  },
  mounted() {
    this.selectedType = this.resource;
  },
  methods: {
    onChangeLabelDetails(newDetails) {
      this.labelDetailsSetting = newDetails;
      const { resource_type } = newDetails;
      this.selectedType = resource_type;
    },
    emitEditLabelEvent() {
      const payload = {
        ...this.labelDetailsSetting,
        ...this.labelHeirarchySetting,
      };

      if (!Object.keys(payload).length) {
        this.showAlert('Change some value to update the tag', 'info');
        return;
      }

      this.$emit('updateLabel', {
        id: this.selectedTagVal.id,
        ...payload,
        oldData: this.selectedTagVal,
        resource_type: this.selectedType,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.tags-rhs-panel {
  padding: $space-two;
}

.save-btn {
  bottom: $space-medium;
  position: absolute;
  right: $space-two;
}
</style>
