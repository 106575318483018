export default class Video {
  checkYoutube(url) {
    try {
      // eslint-disable-next-line
  		var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      // eslint-disable-next-line
      var match = url.match(regExp);
      this.url = url;
      if (match && match[2].length === 11) {
        return 'https://www.youtube.com/embed/' + match[2];
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  checkVideo(url) {
    this.url = url;
    try {
      const theFileType = url.substring(url.lastIndexOf('.') + 1);
      if (theFileType === 'mp4') {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
}
