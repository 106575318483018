/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';

class OrdersApi extends ApiClient {
  constructor() {
    super('integrations', { accountScoped: true });
  }

  get({ query, page, is_shopify }) {
    return axios.get(`${this.url}/products`, {
      params: { query, page, is_shopify },
    });
  }

  getCustomerCart({ phone_number, email, firstName }) {
    return axios.post(`${this.url}/cart`, {
      phone_number,
      email,
      first_name: firstName, // we are taking cutomer name (if present) as first_name
    });
  }

  getProductDetails({ productId }) {
    return axios.get(`${this.url}/products/product_detail`, {
      params: { product_id: productId },
    });
  }

  addProductToCart({
    cart,
    variant_item,
    product_item_id,
    quantity,
    agentId,
    conversationId,
    isActualOrder = false,
    orderId = null,
  }) {
    return axios.post(`${this.url}/cart/${cart}/create_cart_item`, {
      cart,
      variant_item,
      product_item_id,
      quantity,
      is_actual_order: isActualOrder,
      conversation_id: conversationId,
      agent_id: agentId,
      order_id: orderId,
    });
  }

  updateProductQuantity({
    cart,
    variantId,
    quantity,
    agentId,
    conversationId,
    isActualOrder = false,
    orderId = null,
  }) {
    return axios.post(`${this.url}/cart/${cart}/update_cart_item`, {
      item_id: variantId,
      quantity,
      cart,
      conversation_id: conversationId,
      agent_id: agentId,
      is_actual_order: isActualOrder,
      order_id: orderId,
    });
  }

  removeProductFromCart({
    cart,
    itemId,
    agentId,
    conversationId,
    isActualOrder = false,
    orderId = null,
    draftOrderId = null,
  }) {
    return axios.post(`${this.url}/cart/${cart}/delete_cart_item`, {
      item_id: itemId,
      is_actual_order: isActualOrder,
      order_id: orderId,
      conversation_id: conversationId,
      agent_id: agentId,
      draft_order_id: draftOrderId,
    });
  }

  clearCart({ cart, draftOrderId }) {
    return axios.get(`${this.url}/cart/${cart}/clear_cart`, {
      params: {
        draft_order_id: draftOrderId,
      },
    });
  }

  update({ id, attributes }) {
    return axios.put(`${this.url}/products/${id}`, { attributes });
  }
}

export default new OrdersApi();
