<template>
  <woot-base-button
    class="tags-filter-button"
    :class="{ active: selectedOptionsLength }"
    tag="span"
    size="small"
    variant="secondary"
  >
    <icons name="tag" color="grey" size="semimedium" />
    <span
      v-if="selectedOptionsLength"
      :key="selectedOptionsLength"
      class="number-badge zoom-in-out"
      v-text="selectedOptionsLength"
    />
  </woot-base-button>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    selectedOptionsLength() {
      return this.data?.selectedOptionsLength;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.tags-filter-button {
  border: 1px solid $neutral-grey-400;
  position: relative;

  &:hover {
    border: 1px solid $pg-1;
  }

  &.active {
    border: 1px solid $pg-1;

    ::v-deep .icon {
      fill: $pg-1;
    }
  }

  .number-badge {
    background: $pg-1;
    border-radius: 50%;
    color: $color-white;
    font-size: var(--font-size-micro);
    font-weight: var(--font-weight-black);
    position: absolute;
    top: -$space-small;
    left: $space-medium;
    display: flex;
    width: $space-normal;
    height: $space-normal;
    padding: $space-micro;
    justify-content: center;
    align-items: center;
    gap: $space-normal;
  }
}
</style>
