export const humanReadableDate = (
  date,
  options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
) => {
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate;
};

export const formatTime = time => {
  const minutes = Math.floor(time);
  let seconds = Math.round((time - minutes) * 60);

  if (seconds === 60) {
    return `${minutes + 1} Min 0 Sec`;
  }

  return `${minutes} Min ${seconds} Sec`;
};

export const getDaySuffix = day => {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

// example Output: "05:37 pm, 24th Sept 2024"
export const formatDate = (
  dateString,
  options = { hour: '2-digit', minute: '2-digit', hour12: true }
) => {
  const date = new Date(dateString);

  const time = date.toLocaleString('en-US', options).toLowerCase();

  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();

  const dayWithSuffix = day + getDaySuffix(day);

  return `${time}, ${dayWithSuffix} ${month} ${year}`;
};

// example Output: "05:37 pm, Today"
export const formatDateWithToday = (
  dateString,
  options = { hour: '2-digit', minute: '2-digit', hour12: true }
) => {
  const date = new Date(dateString);
  const today = new Date();

  const isToday = date.toDateString() === today.toDateString();

  if (isToday) {
    const time = date.toLocaleString('en-US', options).toLowerCase();
    return `${time}, Today`;
  }
  return formatDate(dateString, options);
};
