export const AVAILABLE_CRMS = {
  FRESHDESK: 'freshdesk',
  KAPTURE: 'kapture',
  ZOHO: 'zohodesk',
  ONEDIRECT: 'onedirect',
  ZENDESK: 'zendesk',
  ODOO: 'odoo',
  JIRA: 'jira',
  LEADSQUARED: 'leadsquared',
};

export const BASE_FORM_FIELDS = {
  email: {
    required: true,
    label: 'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.EMAIL.LABEL',
    placeholder:
      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.EMAIL.PLACEHOLDER',
    error: 'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.EMAIL.ERROR',
  },
  phone: {
    label: 'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.PHONE.LABEL',
    placeholder:
      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.PHONE.PLACEHOLDER',
    error: 'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.PHONE.ERROR',
  },
  order_id: {
    required: true,
    label: 'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.ORDER_ID.LABEL',
    placeholder:
      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.ORDER_ID.PLACEHOLDER',
    error: 'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.ORDER_ID.ERROR',
  },
  subject: {
    required: true,
    label: 'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.SUBJECT.LABEL',
    placeholder:
      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.SUBJECT.PLACEHOLDER',
    error: 'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.SUBJECT.ERROR',
  },
  description: {
    required: true,
    label:
      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.DESCRIPTION.LABEL',
    placeholder:
      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.DESCRIPTION.PLACEHOLDER',
    error:
      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.FORM.DESCRIPTION.ERROR',
  },
};
