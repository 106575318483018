import * as types from 'dashboard/store/mutation-types';
import authAPI from 'dashboard/api/auth';
import customConversationViewsApi from 'dashboard/api/customConversationViews';

const actions = {
  fetchViews: async ({ commit, dispatch }, data) => {
    commit(types.default.SET_CONV_VIEWS_UI_FLAGS, { isFetching: true });
    try {
      const response = await customConversationViewsApi.get(data);
      commit(
        types.default.SET_CUSTOM_CONV_VIEWS,
        response.data.custom_views.map(view => ({
          ...view,
          view_type: 'custom_view',
        }))
      );
      commit(
        types.default.SET_CURATED_CONV_VIEWS,
        response.data.curated_views.map(view => ({
          ...view,
          view_type: 'curated_view',
        }))
      );

      if (response.data.default) {
        dispatch('setDefaultView', response.data.default);
        dispatch('setCurrentView', response.data.default);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_CONV_VIEWS_UI_FLAGS, { isFetching: false });
    }
  },
  setCurrentView({ commit }, view) {
    commit(types.default.SET_CURRENT_CONV_VIEW, view);
  },
  setDefaultView({ commit }, view) {
    commit(types.default.SET_DEFAULT_CONV_VIEW, view);
  },
  createCustomView: async ({ commit }, viewData) => {
    commit(types.default.SET_CONV_VIEWS_UI_FLAGS, { isSaving: true });
    try {
      const response = await customConversationViewsApi.create(viewData);
      const data = {
        ...response.data,
        view_type: 'custom_view',
      };
      commit(types.default.ADD_CUSTOM_CONV_VIEW, data);
      return data;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_CONV_VIEWS_UI_FLAGS, { isSaving: false });
    }
  },
  editCustomView: async ({ commit }, { id, ...rest }) => {
    commit(types.default.SET_CONV_VIEWS_UI_FLAGS, { isSaving: true });
    try {
      const response = await customConversationViewsApi.update(id, rest);
      const data = {
        ...response.data,
        view_type: 'custom_view',
      };
      commit(types.default.UPDATE_CUSTOM_CONV_VIEW, data);
      return data;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_CONV_VIEWS_UI_FLAGS, { isSaving: false });
    }
  },
  deleteView: async ({ commit }, id) => {
    commit(types.default.SET_CONV_VIEWS_UI_FLAGS, { isDeleting: true });
    try {
      await customConversationViewsApi.delete(id);
      commit(types.default.DELETE_CONV_VIEW, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_CONV_VIEWS_UI_FLAGS, { isDeleting: false });
    }
  },
  markConversationViewDefault: async ({ dispatch }, data) => {
    try {
      const payload = {
        default_view_id: data.id,
        default_view_type: data.view_type,
      };
      await authAPI.markConversationViewAsDefault(payload);
      dispatch('setDefaultView', data);
    } catch (error) {
      //  handle error
      throw new Error(error);
    }
  },
  setCurrentViewFilters: ({ commit, dispatch }, filters) => {
    commit(types.default.SET_CONV_VIEWS_UI_FLAGS, { isFilterActive: true });
    dispatch('bulkUpdateCurrentViewFilters', filters);
  },
  updateCurrentViewFilters: ({ commit }, { key, value }) => {
    commit(types.default.UPDATE_CURRENT_VIEW_FILTERS, { key, value });
  },
  bulkUpdateCurrentViewFilters: ({ commit }, filters) => {
    commit(types.default.SET_CURRENT_VIEW_FILTERS, filters);
  },
  resetCurrentViewFilters: ({ commit }) => {
    commit(types.default.SET_CONV_VIEWS_UI_FLAGS, { isFilterActive: false });
    commit(types.default.RESET_CURRENT_VIEW_FILTERS);
  },
};

export default actions;
