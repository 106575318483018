<template>
  <page-header
    :header-title="$t('BOT_CSAT.CONTROLS.RATING_SCALE.TITLE')"
    size="small"
    custom-class="grid-1-2-ratio"
    :info-text="$t('BOT_CSAT.CONTROLS.RATING_SCALE.DESCRIPTION')"
  >
    <div class="rating-controls-grid">
      <label
        for="three-stars"
        class="radio-container rating-control"
        style="cursor:pointer"
      >
        <span class="body-b2" v-text="'3 star'" />
        <input
          id="three-stars"
          type="radio"
          name="csat-rating"
          :value="3"
          :checked="rating === 3"
          @change="$emit('updateConfig', 3)"
        />
        <span class="radio-circle" />
      </label>
      <label
        for="five-stars"
        class="radio-container rating-control"
        style="cursor:pointer"
      >
        <span class="body-b2" v-text="'5 star'" />
        <input
          id="five-stars"
          type="radio"
          name="csat-rating"
          :value="5"
          :checked="rating === 5"
          @change="$emit('updateConfig', 5)"
        />
        <span class="radio-circle" />
      </label>
    </div>
  </page-header>
</template>
<script>
import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';

export default {
  components: { PageHeader },
  props: {
    rating: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.rating-controls-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: $space-normal;
}
</style>
