/* global axios */
import ApiClient from '../ApiClient';

class MaytapiChannel extends ApiClient {
  constructor() {
    super('channels/maytapi_channels', { accountScoped: true });
  }

  reboot({ maytapi_id }) {
    let data = {
      maytapi_id: maytapi_id,
    };
    return axios({
      method: 'post',
      url: `${this.url}/reboot`,
      data: data,
    });
  }

  rebootedAt({ maytapi_id }) {
    let data = {
      maytapi_id: maytapi_id,
    };
    return axios({
      method: 'post',
      url: `${this.url}/rebooted_at`,
      data,
    });
  }
}

export default new MaytapiChannel();
