<template>
  <section class="flex-column gap--normal">
    <div class="section-header">
      <span class="text-light body-b2">
        {{ $t('CUSTOM_TICKET_FIELDS.SUMMARY.HEADING') }}
      </span>
    </div>
    <div class="section-type flex-column gap--smaller">
      <span class="title-h5 text-light">
        {{ $t('CUSTOM_TICKET_FIELDS.SUMMARY.SUB_HEADINGS.TYPE') }}
      </span>
      <span class="title-h5 text-dark">
        {{ customFieldType }} - {{ customFieldSubType }}
      </span>
    </div>
    <div class="section-name flex-column gap--smaller">
      <span class="title-h5 text-light">
        {{ $t('CUSTOM_TICKET_FIELDS.SUMMARY.SUB_HEADINGS.FIELD_NAME') }}
      </span>
      <span class="title-h5 text-dark">
        {{ customField.name }}
      </span>
    </div>
    <div
      v-if="customField.dropdown_options"
      class="section-values flex-column gap--smaller"
    >
      <span class="title-h5 text-light">
        {{ $t('CUSTOM_TICKET_FIELDS.SUMMARY.SUB_HEADINGS.VALUES') }}
      </span>
      <div class="flex-row flex-wrap">
        <woot-chips
          v-for="({ value }, idx) in customField.dropdown_options"
          :key="idx"
          :show-tooltip="false"
          variant="primary-small"
          :title="value"
          style="width: fit-content; max-width: 23.2rem;"
        />
      </div>
    </div>
    <div class="section-inboxes flex-column gap--smaller">
      <span class="title-h5 text-light">
        {{ $t('CUSTOM_TICKET_FIELDS.SUMMARY.SUB_HEADINGS.INBOXES') }}
      </span>
      <div class="flex-row flex-wrap">
        <woot-chips
          v-for="(inbox, idx) in customField.inboxes"
          :key="idx"
          :show-tooltip="false"
          variant="primary-small"
          :title="inbox.name"
          :front-icon="getInboxIconName(inbox)"
          style="width: fit-content; max-width: 23.2rem;"
        />
      </div>
    </div>
  </section>
</template>

<script>
import inboxMixin from 'dashboard/mixins/inbox';

import { CUSTOM_FIELD_TYPE_INFO_PATH } from '../utils/constants';

export default {
  mixins: [inboxMixin],
  props: {
    customField: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      CUSTOM_FIELD_TYPE_INFO_PATH,
    };
  },
  computed: {
    customFieldType() {
      return this.$t(
        `${CUSTOM_FIELD_TYPE_INFO_PATH[this.customField.field_type]}.TYPE`
      );
    },
    customFieldSubType() {
      return this.$t(
        `${CUSTOM_FIELD_TYPE_INFO_PATH[this.customField.field_type]}.SUB_TYPE`
      );
    },
  },
  methods: {
    getInboxIconName(inbox) {
      return this.getInboxClassByType(
        inbox?.channel_type,
        inbox?.phone_number,
        '',
        inbox?.instagram_add_on
      ).name;
    },
  },
};
</script>
