<template>
  <div :class="{ 'email-body': isEmail }">
    <span class="message-text__wrap">
      <div
        class="message-span"
        :class="{
          'message-email body-b2': isEmail,
          'show--quoted': showQuotedContent,
          'hide--quoted': !showQuotedContent,
        }"
        v-html="sanitizeContent"
      />
      <button class="quoted-text--button" @click="toggleQuotedContent">
        <span v-if="showQuotedContent">
          {{ $t('CHAT_LIST.HIDE_QUOTED_TEXT') }}
        </span>
        <span v-else>
          {{ $t('CHAT_LIST.SHOW_QUOTED_TEXT') }}
        </span>
      </button>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    isEmail: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showQuotedContent: false,
    };
  },
  computed: {
    sanitizeContent() {
      return this.message
        .replace(/<style[^>]*>.*?<\/style>/gs, '')
        .replace(/<script[^>]*>.*?<\/script>/gs, '');
    },
  },
  methods: {
    toggleQuotedContent() {
      this.showQuotedContent = !this.showQuotedContent;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.message-span ::v-deep p::after {
  content: '';
  padding-right: $space-jumbo;
  display: inline-block;
}

.message-email {
  overflow: hidden;
}

.message-email ::v-deep p {
  margin-bottom: 0;

  &::after {
    display: none;
  }
}

.show--quoted {
  display: block;
}

.hide--quoted {
  display: none;
}

.quoted-text--button {
  color: $secondary-blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: $font-size-mini;
  padding-bottom: $space-small;
  padding-top: $space-small;

  &:hover {
    color: $pg-1;
  }
}
</style>
