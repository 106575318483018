<template>
  <div class="flex-column gap--small">
    <div class="summary-bubble">
      <summary-head />
      <summary-content :message="message" />
    </div>
    <capture-feedback :conversation-id="message.conversation_id" />
  </div>
</template>
<script>
import SummaryHead from './SummaryHead';
import SummaryContent from './SummaryContent';
import CaptureFeedback from './CaptureFeedback';

export default {
  components: { SummaryHead, SummaryContent, CaptureFeedback },
  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.summary-bubble {
  @include chat-user-elevation;

  border-radius: $space-slab;
  max-width: 100%;
  text-align: left;
  word-wrap: break-word;
}
</style>
