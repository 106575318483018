<template>
  <div
    class="textarea-container flex-space-between"
    :class="isAnEmail && 'textarea-container--email'"
  >
    <textarea
      ref="textarea"
      :style="`min-height: ${minHeight}rem`"
      :placeholder="placeholder"
      :value="value"
      @input="onInput"
      @focus="onFocus"
      @keyup="onKeyup"
      @mouseup="captureSelectedText"
      @blur="onBlur"
      @paste="onMediaPaste"
    >
    </textarea>
    <file-upload
      ref="upload"
      v-model="files"
      :size="4096 * 4096"
      accept="image/*, application/pdf, audio/mpeg, video/mp4, audio/ogg, .zip"
      @input-file="uploadFile"
    />
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component';
const TYPING_INDICATOR_IDLE_TIME = 4000;

export default {
  components: {
    FileUpload,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    minHeight: {
      type: Number,
      default: 2,
    },
    isAnEmail: {
      type: Boolean,
      default: false,
    },
    retainSelectionOnFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectionStart: 0,
      selectionEnd: 0,
      files: [],
      fileData: {
        addImageData: {},
        uploadFileData: {},
        files: this.files,
      },
      idleTimer: null,
    };
  },
  watch: {
    value() {
      this.resizeTextarea();
    },
  },
  mounted() {
    this.$refs.textarea.focus();
  },
  methods: {
    uploadFile(newFile, oldFile) {
      this.$emit('fileData', this.files, newFile, oldFile);
    },
    onMediaPaste(e) {
      if (e.clipboardData.files.length > 0) {
        this.$refs.upload.add(e.clipboardData.files[0]);
      }
    },
    resizeTextarea() {
      if (!this.value) {
        this.$refs.textarea.style.height = `${this.minHeight}rem`;
      } else {
        this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`;
      }
    },
    onInput(event) {
      this.$emit('input', event.target.value);
      this.resizeTextarea();
    },
    resetTyping() {
      this.$emit('typing-off');
      this.idleTimer = null;
    },
    turnOffIdleTimer() {
      if (this.idleTimer) {
        clearTimeout(this.idleTimer);
      }
    },
    captureSelectedText() {
      const textarea = this.$refs.textarea;
      this.selectionStart = textarea.selectionStart;
      this.selectionEnd = textarea.selectionEnd;

      if (this.selectionStart !== this.selectionEnd) {
        this.selectedText = this.value.substring(
          this.selectionStart,
          this.selectionEnd
        );
      } else this.selectedText = '';

      this.$emit('onSelectText', this.selectedText);
    },
    onKeyup() {
      this.captureSelectedText();

      if (!this.idleTimer) {
        this.$emit('typing-on');
      }
      this.turnOffIdleTimer();
      this.idleTimer = setTimeout(
        () => this.resetTyping(),
        TYPING_INDICATOR_IDLE_TIME
      );
    },
    onBlur() {
      this.turnOffIdleTimer();
      this.resetTyping();
      this.captureSelectedText();
      this.$emit('blur');
    },
    onFocus() {
      if (this.retainSelectionOnFocus) {
        const textarea = this.$refs.textarea;
        textarea.setSelectionRange(this.selectionStart, this.selectionEnd);
      }

      this.$emit('focus');
    },
    focus() {
      this.$refs.textarea.focus();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.textarea-container {
  margin-right: $space-micro;
  width: 100%;

  > textarea {
    @include ghost-input();
    margin: 0;
    background: transparent;
    margin-right: $space-small;
    max-height: $space-mega * 2.4;
    min-height: 4rem;
    padding: $space-small 0;
    resize: none;
  }

  &--email {
    > textarea {
      max-height: none;
      overflow: hidden;
    }
  }
}

textarea::-webkit-scrollbar {
  width: 0.2em;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 0.1em;
}
textarea::placeholder {
  color: $text-light !important;
  opacity: 0.5;
}
</style>
