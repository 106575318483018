import { CSAT_RATINGS } from 'shared/constants/messages';
import { humanReadableDate } from 'dashboard/helper/time';

export const generateCsatRatings = ratingSystemPoint => {
  // If the system is a 3 point system, filter out the middle ratings (2 and 4)
  if (ratingSystemPoint === 3) {
    return CSAT_RATINGS.filter(
      rating => rating.value === 1 || rating.value === 3 || rating.value === 5
    );
  }

  // Otherwise, return the base ratings for a 5 point system
  return CSAT_RATINGS;
};

export const convertParamValueToString = (param, value) => {
  const enums = {
    workingHours: ['all', 'inside', 'outside'],
    ticketType: ['all', 'non_bot', 'bot', 'auto_resolved'],
    // the payload for Agent tickets(in HD UI) is non_bot for backend compatibility, backend only accepts the above ticketTypes
  };
  return enums[param][value];
};

export const formatToPercent = value => {
  return value ? `${value}%` : '--';
};

export const showConvStatesTitleWithDateRange = (start, end) => {
  if (!start || !end) return '';
  if (start === end) return showConvStatesTitleWithEndDate(end);

  start = humanReadableDate(new Date(start), {
    month: 'long',
    day: 'numeric',
  });
  end = humanReadableDate(new Date(end), {
    month: 'long',
    day: 'numeric',
  });

  return `Calculated for the entire time period (${start} - ${end})`;
};

export const showConvStatesTitleWithEndDate = end => {
  if (!end) return '';
  end = new Date(end);
  return (
    'Calculated on ' +
    humanReadableDate(new Date(end), {
      month: 'long',
      day: 'numeric',
    })
  );
};
