<template>
  <label class="container-checkbox" :style="[customStyle, cursorStyle]">
    <span v-if="label" class="body-b2 label-text" :style="labelStyle">{{
      label
    }}</span>
    <input
      v-model="model"
      type="checkbox"
      :value="value"
      :disabled="disabled"
      :checked="checked"
    />
    <span class="checkmark"></span>
  </label>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Boolean, Array],
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
    labelStyle: {
      type: Object,
      default: undefined,
    },
    inputMethod: { type: Function, default: () => {} },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    cursorStyle() {
      return this.disabled ? { cursor: 'default' } : { cursor: 'pointer' };
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.container-checkbox {
  display: flex;
  position: relative;
  padding-left: 3rem;
  user-select: none;

  .label-text {
    line-height: $height-h6;
    color: $neutral-grey-800;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked {
      ~ {
        .checkmark {
          background-color: $pg-1-500;
          border: none;
          &::after {
            display: block;
          }
        }
      }
    }
    &:disabled {
      ~ {
        .checkmark {
          border: 2px solid $neutral-grey-500;
        }
      }
    }
  }
}

.checkmark {
  position: absolute;
  top: $zero;
  left: $zero;
  height: $height-h7;
  width: $height-h7;
  background-color: none;
  border: 2px solid $neutral-grey-800;
  border-radius: $border-radius-smaller;

  &::after {
    content: '';
    position: absolute;
    display: none;
    left: $space-six;
    top: $space-micro;
    width: $space-six;
    height: $space-slab;
    border: solid $color-white;
    border-width: $zero $space-micro $space-micro $zero;
    transform: rotate(45deg);
  }
}
</style>
