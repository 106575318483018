<template>
  <accordian-card :custom-desc="true" custom-class="address-container">
    <template v-slot:desc>
      <div class="address-btn flex-row flex-align flex-justify--between">
        <span
          style="width: 100%; cursor: pointer; text-transform: capitalize;"
          class="subtitle-s2"
        >
          {{ addressType }} Address
        </span>
        <span
          v-if="addressType === 'shipping'"
          title="Add New"
          class="add-new-address"
          @click="onShowCreateOrder"
        >
          <icons
            name="circleAdd"
            color="stroke-green"
            size="medium"
            :custom-style="{ 'stroke-width': 2 }"
            :show-title="false"
          />
        </span>
      </div>
    </template>
    <div v-if="addresses.length === 0" class="body-b3 text-light">
      {{ $t('ORDER.ADDRESS.NOT_FOUND') }}
    </div>
    <span v-else-if="addressType === 'billing'" class="body-b3 text-light">
      {{ $t('ORDER.BILLING.SAME_AS_SHIPPING') }}
    </span>
    <span v-if="addressType === 'shipping'">
      <address-field
        v-for="(address, idx) in sortedAddresses"
        :key="address.id"
        :index="idx"
        :selected="selectedAddress"
        :data="address"
        @updateSelectedAddress="updateSelectedAddress"
      />
    </span>
  </accordian-card>
</template>

<script>
import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';
import AccordianCard from 'dashboard/components/AccordianCard';
import AddressField from './AddressField';

export default {
  components: { AccordianCard, AddressField },
  mixins: [alertMixin],
  props: {
    addressType: {
      type: String,
      default: '',
    },
    addresses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showAddress: false,
      isSameAsShipping: false,
      selectedAddressId: -1,
      isWatcherInitialised: false,
    };
  },
  computed: {
    ...mapGetters({
      draftOrderData: 'getDraftOrderMeta',
      orderMode: 'getCurrentOrderMode',
      currentChat: 'getSelectedChat',
      selectedAddressValue: 'getSelectedAddress',
      cartDetails: 'getCartMeta',
      activeShopifyOrder: 'getActiveShopifyOrder',
      productsInCart: 'getProductsInCart',
    }),
    sortedAddresses() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.addresses.sort(
        (a, b) => b.is_default_address - a.is_default_address
      );
    },
    currentCartId() {
      return this.cartDetails.id;
    },
    selectedAddress() {
      return (
        (this.selectedAddressValue.shipping !== -1 &&
          this.selectedAddressValue.shipping) ||
        (this.selectedAddressId !== -1 && this.selectedAddressId) ||
        this.addresses.find(address => address.is_default_address)?.id ||
        this.addresses[0]?.id
      );
    },
    canExpand() {
      return this.addresses.length !== 0;
    },
    shouldUpdateDraftOrder() {
      const {
        cart: { line_items: productsInDraft },
      } = this.draftOrderData;

      return (
        JSON.stringify(this.createArrayofProductIds(this.productsInCart)) !==
        JSON.stringify(this.createArrayofProductIds(productsInDraft))
      );
    },
  },
  created() {
    if (this.addressType === 'billing') return;

    if (this.orderMode === 'edit') {
      this.updtDraftWithSelectedAdd(this.selectedAddress);
    }

    if (
      !this.selectedAddress ||
      this.selectedAddress === -1 ||
      this.orderMode === 'edit' ||
      this.selectedAddressValue === this.selectedAddress
    ) {
      this.$watch('selectedAddress', value => {
        this.isWatcherInitialised = true;
        this.updtDraftWithSelectedAdd(value);
        this.defaultAddress(value);
      });
      return;
    }

    this.updtDraftWithSelectedAdd(this.selectedAddress);
  },
  methods: {
    updtDraftWithSelectedAdd(newValue) {
      if (!newValue) return;

      if (this.orderMode !== 'edit') {
        if (!this.draftOrderData.id || this.draftOrderData?.id === -1) {
          if (this.productsInCart.length !== 0)
            this.$store
              .dispatch('createDraftOrder', {
                cartId: this.currentCartId,
                shippingAddress: newValue,
                conversationId: this.currentChat.id,
                inboxId: this.currentChat.inbox_id,
                isCashOnDelivery: true,
              })
              .then(res => {
                if (res.data?.shipping_address?.id !== newValue || false)
                  this.updateDraftOrderAddress(newValue);
                else this.defaultAddress(newValue);
                this.updateDraftOrderCart(newValue);
              });
        } else if (this.shouldUpdateDraftOrder) {
          this.updateDraftOrderCart(newValue);
        } else {
          this.updateDraftOrderAddress(newValue);
        }
      } else {
        this.updateDraftOrderAddressInEditMode(newValue);
      }
    },
    defaultAddress(addressId) {
      this.selectedAddressId = addressId;

      this.$store.dispatch('setSelectedAddress', {
        addressType: this.addressType,
        addressId: addressId,
      });
    },
    updateDraftOrderAddressInEditMode(addressId) {
      try {
        let assigneeId = null;
        try {
          assigneeId = this.currentChat?.meta?.assignee?.id;
          if (!assigneeId) {
            assigneeId = null;
          }
        } catch {
          assigneeId = null;
        }
        const data = {
          orderId: this.activeShopifyOrder.id,
          shippingAddress: addressId,
          agentId: assigneeId,
          conversationId: this.currentChat?.id,
        };

        this.$store.dispatch('updateShopifyOrderAddress', data).then(() => {
          this.showAlert('Order updated successfully', 'success');
        });
      } catch (error) {
        this.showAlert('Failed to update order', 'error');
      }
    },
    updateDraftOrderAddress(addressId) {
      this.defaultAddress(addressId);
      let assigneeId = null;
      try {
        assigneeId = this.currentChat?.meta?.assignee?.id;
        if (!assigneeId) {
          assigneeId = null;
        }
      } catch {
        assigneeId = null;
      }

      return this.$store.dispatch('updateDraftOrder', {
        draftOrderId: this.draftOrderData.id,
        shippingAddress: addressId,
        editRequest: 'edit_shipping_address',
        agentId: assigneeId,
        conversationId: this.currentChat?.id,
      });
    },
    updateDraftOrderCart(addressId) {
      let assigneeId = null;
      try {
        assigneeId = this.currentChat?.meta?.assignee?.id;
        if (!assigneeId) {
          assigneeId = null;
        }
      } catch {
        assigneeId = null;
      }
      this.$store.dispatch('updateDraftOrder', {
        draftOrderId: this.draftOrderData.id,
        shippingAddress: addressId,
        editRequest: 'edit_line_items',
        agentId: assigneeId,
        conversationId: this.currentChat?.id,
      });
    },
    createArrayofProductIds(arr) {
      return arr.reduce((accumulator, product) => {
        accumulator.push(product.id);
        return accumulator;
      }, []);
    },
    scrollToTop() {
      document.querySelector('.customer--container').scrollTo({
        top: 0,
      });
    },
    onShowCreateOrder() {
      this.scrollToTop();

      this.$emit('showCreateAddress', {
        addressType: this.addressType,
        addressId: -1,
      });
    },
    updateSelectedAddress(addressId) {
      if (this.selectedAddress === addressId) {
        this.scrollToTop();
        this.$emit('showCreateAddress', {
          addressType: this.addressType,
          addressId,
        });
        return;
      }

      this.selectedAddressId = addressId;
      this.$store.dispatch('setSelectedAddress', {
        addressType: this.addressType,
        addressId: addressId,
      });

      // check if watcher initialised
      if (!this.isWatcherInitialised) {
        this.updtDraftWithSelectedAdd(this.selectedAddress);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.address-container {
  border: none;
  margin-bottom: $space-slab;
  padding: 0;
}

.add-new-address {
  cursor: pointer;
  margin-right: $space-normal;

  &:hover {
    ::v-deep .icon {
      stroke: $pg-1-600;
    }
  }
}
</style>
