var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-form custom-scroll"},[_c('div',{staticClass:"template-container"},[(_vm.selectedTemplate.media_url)?_c('woot-input',{staticClass:"medium-12 columns",attrs:{"value":_vm.templateMediaUrl.replace('{}', ''),"disabled":_vm.selectedTemplate.media_url !== '{}',"label":_vm.$t('TEMPLATE.ADD.FORM.MEDIA.LABEL'),"placeholder":_vm.$t('TEMPLATE.ADD.FORM.MEDIA.PLACEHOLDER')},on:{"input":function (value) { return _vm.$emit('update:templateMediaUrl', value); }}}):_vm._e(),_vm._v(" "),_vm._l((_vm.selectedTemplate.body.split('{}')),function(item,index){return _c('span',{key:index,staticClass:"template-text-container"},[_vm._l((item.split(' ')),function(word,ind){return _c('span',{key:ind,staticClass:"template-word"},[_vm._v("\n        "+_vm._s(word)+"\n      ")])}),_vm._v(" "),(_vm.substitutionCount > index)?[(_vm.inputType === 'text')?_c('input',{attrs:{"placeholder":"Enter Text","type":"text"},domProps:{"value":_vm.parameters[index]},on:{"input":function($event){return _vm.$emit('setParams', $event.target.value, index)}}}):_c('select',{domProps:{"value":_vm.parameters[index].startsWith('Hard-coded value:')
              ? 'Hard-coded value'
              : _vm.parameters[index]},on:{"input":function($event){return _vm.updateParam($event.target.value, index)}}},_vm._l((_vm.predefinedParams),function(params,indx){return _c('option',{key:indx,domProps:{"value":params}},[_vm._v("\n            "+_vm._s(params)+"\n          ")])}),0),_vm._v(" "),(_vm.parameters[index].startsWith('Hard-coded value:'))?_c('input',{staticStyle:{"margin-left":"0.8rem"},attrs:{"type":"text","placeholder":"Enter string to hard-code"},domProps:{"value":_vm.parameters[index].replace('Hard-coded value:', '')},on:{"input":function($event){return _vm.updateHardcodedParam($event.target.value, index)}}}):_vm._e()]:_vm._e()],2)}),_vm._v(" "),(_vm.selectedTemplate.buttons)?_c('div',{staticClass:"medium-12 columns flex-column"},_vm._l((_vm.selectedTemplate.buttons.buttons),function(button,inx){return _c('div',{key:inx,staticClass:"flex-row mg-top--small"},[_c('woot-secondary-button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(button.url),expression:"button.url",modifiers:{"bottom":true}}],attrs:{"icon-size":"semimedium","icon-color":"grey","custom-class":"read-only-button mg-right--smaller","size":"medium","name":button.title}}),_vm._v(" "),(button.type && button.type === 'dynamic_url')?[(_vm.inputType === 'text')?_c('input',{staticClass:"columns",attrs:{"placeholder":_vm.$t('TEMPLATE.EDIT.FORM.BUTTON.URL'),"type":"text"},domProps:{"value":_vm.templateButtonUrl},on:{"input":function($event){return _vm.updateTemplateButtonUrl($event.target.value)}}}):_c('select',{domProps:{"value":_vm.templateButtonUrl.startsWith('Hard-coded value:')
                ? 'Hard-coded value'
                : _vm.templateButtonUrl},on:{"input":function($event){return _vm.updateTemplateButtonUrl($event.target.value)}}},_vm._l((_vm.predefinedParams),function(params,indx){return _c('option',{key:indx,domProps:{"value":params}},[_vm._v("\n              "+_vm._s(params)+"\n            ")])}),0),_vm._v(" "),(_vm.templateButtonUrl.startsWith('Hard-coded value:'))?_c('input',{staticStyle:{"margin-left":"0.8rem"},attrs:{"type":"text","placeholder":"Enter string to hard-code"},domProps:{"value":_vm.templateButtonUrl.replace('Hard-coded value:', '')},on:{"input":function($event){return _vm.$emit(
                'update:templateButtonUrl',
                ("Hard-coded value:" + ($event.target.value))
              )}}}):_vm._e()]:_vm._e()],2)}),0):_vm._e()],2),_vm._v(" "),(_vm.showCloseButton)?_c('button',{staticClass:"cancel-message--button",attrs:{"type":"button"},on:{"click":_vm.onClose}},[_c('icons',{attrs:{"name":"cancel","color":"stroke-red","size":"semimedium"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }