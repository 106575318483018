import Cookies from 'js-cookie';

export const getUserEmail = () => {
  const userData = Cookies.getJSON('user');

  if (!userData) {
    return null;
  }

  return userData.email;
};
