class AbortControllerManager {
  constructor() {
    this.controllers = new Map();
  }

  createController(key) {
    this.abortController(key);
    const controller = new AbortController();
    this.controllers.set(key, controller);
    return controller;
  }

  getController(key) {
    return this.controllers.get(key);
  }

  abortController(key) {
    const controller = this.controllers.get(key);
    if (controller) {
      controller.abort();
      this.controllers.delete(key);
    }
  }

  clearController(key) {
    this.controllers.delete(key);
  }
}

export default new AbortControllerManager();
