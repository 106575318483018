<template>
  <div v-if="product">
    <div class="categories">
      <span v-if="isOutOfStock" class="out-of-stock title-h6">
        {{ $t('PRODUCTS_MGMT.OUT_OF_STOCK') }}
      </span>
      <div
        v-for="(category, index) in Object.keys(categorisedOptions)"
        :key="index"
        class="category"
      >
        <span class="text-light body-b3">{{ category }}</span>
        <div class="options--container flex-row">
          <span v-for="option in categorisedOptions[category]" :key="option.id">
            <woot-chips
              :custom-style="setCustomStyle(option)"
              :show-tooltip="false"
              :title="option.value.toUpperCase()"
              :bg-color="setBgColor(option)"
              variant="secondary-small"
              @click.native="onSelectOption(option)"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
  <product-variants-skeleton v-else />
</template>
<script>
import ProductVariantsSkeleton from 'dashboard/components/ProductVariantsSkeleton';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    ProductVariantsSkeleton,
  },
  mixins: [alertMixin],
  props: {
    id: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      product: null,
      selectedOptions: {},
      availableOptions: [],
      clearOutOfStockVariants: false,
    };
  },
  computed: {
    variants() {
      return this.sortVariants(
        this.product.shopify_variants.filter(
          variant => variant.inventory_quantity > 0
        )
      );
    },
    isOutOfStock() {
      return this.variants.length === 0;
    },
    allCategories() {
      return this.product.shopify_variant_options;
    },
    categorisedOptions() {
      let optionsObj = this.allCategories.reduce((options, option) => {
        options[option.category] = options[option.category] || [];
        options[option.category].push(option);
        return options;
      }, Object.create(null));

      return optionsObj;
    },
  },
  created() {
    this.$store
      .dispatch('getProductDetails', { productId: this.id })
      .then(response => {
        this.product = response.data;
        if (this.isOutOfStock) return;
        // select first in stock variant and make it selected by default
        if (this.variants.length === 1) this.setDefaultOption();
        else {
          this.filterVariants();
          this.$emit('availableOptions', this.availableOptions);
          this.$emit('disableAddToCart', {
            [this.variants[0].product.id]: true,
          });
        }
      });

    this.$emit('resetOptions', this.resetOptions);
  },
  methods: {
    setDefaultOption() {
      const defaultSelectedVariant = this.variants.find(
        variant => variant.inventory_quantity > 0
      );
      for (let i = 1; i <= Object.keys(this.categorisedOptions).length; i += 1)
        this.availableOptions.push(defaultSelectedVariant['option' + i]);
    },
    setCustomStyle(option) {
      const commonStyles = { 'max-width': '18vw' };

      if (this.selectedOptions[['option' + option.position]] === option.value) {
        if (this.clearOutOfStockVariants)
          return {
            'border-color': '#ff7e61',
            'pointer-events': 'none',
            ...commonStyles,
          };
        return {
          'border-color': '#6BAC1B',
          cursor: 'pointer',
          ...commonStyles,
        };
      }

      if (this.availableOptions.includes(option.value))
        return {
          'border-color': '#595959',
          cursor: 'pointer',
          ...commonStyles,
        };

      return {
        color: '#bfbfbf',
        'border-color': '#bfbfbf',
        cursor: 'pointer',
        ...commonStyles,
      };
    },
    setBgColor(option) {
      if (this.selectedOptions[['option' + option.position]] === option.value) {
        if (this.clearOutOfStockVariants) return '#ff7e61';
        return '#6BAC1B';
      }
      return '';
    },
    sortVariants(variants) {
      return variants.sort((a, b) => {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      });
    },
    resetOptions() {
      this.selectedOptions = {};
      this.availableOptions = [];
      this.filterVariants();
    },
    removeOption(position) {
      return Object.keys(this.selectedOptions).reduce((accumulator, key) => {
        if (key !== 'option' + position) {
          accumulator[key] = this.selectedOptions[key];
        }
        return accumulator;
      }, {});
    },
    filterVariants() {
      return this.variants.filter(variant => {
        // eslint-disable-next-line no-restricted-syntax
        for (let key in this.selectedOptions)
          if (
            variant[key] === undefined ||
            variant[key] !== this.selectedOptions[key]
          )
            return false;

        for (
          let i = 1;
          i <= Object.keys(this.categorisedOptions).length;
          i += 1
        )
          if (this.availableOptions.indexOf(variant['option' + i]) === -1)
            this.availableOptions.push(variant['option' + i]);

        return true;
      });
    },
    onSelectOption(option) {
      this.availableOptions = [];

      if (this.selectedOptions['option' + option.position] === option.value)
        this.selectedOptions = this.removeOption(option.position);
      else
        this.selectedOptions = {
          ...this.selectedOptions,
          ['option' + option.position]: option.value,
        };

      let filteredVariants = this.filterVariants();

      if (Object.keys(this.selectedOptions).length === 0) {
        this.resetOptions();
        this.$emit('clearVariantData');
      }

      if (filteredVariants.length === 0) {
        this.clearOutOfStockVariants = true;
        this.showAlert('Item Out Of Stock', 'error');
        setTimeout(() => {
          this.selectedOptions = this.removeOption(option.position);
          this.clearOutOfStockVariants = false;
          this.filterVariants();
        }, 800);
      } else if (filteredVariants.length === 1) {
        this.clearOutOfStockVariants = false;
        this.$emit('disableAddToCart', {
          [filteredVariants[0].product.id]: false,
        });
        this.$emit('variantData', {
          [filteredVariants[0].product.id]: { ...filteredVariants[0] },
        });
      } else {
        this.$emit('disableAddToCart', {
          [filteredVariants[0].product.id]: true,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.categories {
  position: relative;
  width: fit-content;
  margin-top: $space-slab;
  min-width: $space-two * 10;
}

.out-of-stock {
  color: $warn-red-400;
  position: absolute;
  right: $zero;
  top: $zero;
  text-transform: uppercase;
}

.options {
  &--container {
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    gap: $space-smaller $space-six;
    margin: $space-smaller auto $space-slab;
    max-width: 100%;
  }
}
</style>
