<template>
  <transition-group
    name="menu-list"
    mode="out-in"
    tag="div"
    class="customer--container lime-card-border custom-scroll"
  >
    <create-address
      v-show="showCreateAddress"
      :key="1"
      :is-shipping="isShippingAddress"
      :address-id="selecteAddressId"
      @closeCreateAddress="showCreateAddress = false"
    />
    <div v-show="!showCreateAddress" :key="2" class="">
      <div class="top--container">
        <div class="flex-row flex-align flex-justify--between ">
          <div class="flex-row flex-align info">
            <span style="cursor: pointer" title="Go back">
              <icons
                name="chevronLeft"
                color="green"
                size="medium"
                :show-title="false"
                @click="onCloseCreateOrder"
              />
            </span>
            <span class="button-small">
              <span v-if="orderMode === 'edit'" class="text-light">
                {{
                  $t('ORDER.CREATE.EDIT_MODE.TITLE', {
                    orderStatus: activeShopifyOrder.is_cod ? 'COD' : 'PREPAID',
                  })
                }}
              </span>
              <span v-else class="text-light">
                {{ $t('ORDER.CREATE.CREATE_MODE.TITLE') }}
              </span>
              <transition name="toast-fade" mode="out-in">
                <span v-if="!shouldEditName" @dblclick="onEditCustomerName">
                  {{ contact.name }}
                </span>
                <woot-input
                  v-else
                  v-model.trim="customerName"
                  class="mt-4"
                  :placeholder="$t('ORDER.CREATE.CUSTOMER_NAME.PLACEHOLDER')"
                  @keyup.enter.native="onUpdateCustomerName"
                />
              </transition>
            </span>
          </div>
          <span
            v-if="!shouldEditName"
            class="edit-name-icon"
            :title="$t('ORDER.CREATE.CUSTOMER_NAME.TOOLTIP_TEXT_EDIT')"
            @click="onEditCustomerName"
          >
            <icons
              name="editRound"
              color="green"
              size="semimedium"
              :show-title="false"
            />
          </span>
          <span v-else class="update-name-icon">
            <icons
              name="refresh"
              :size="'medium'"
              color="green"
              :title="$t('ORDER.CREATE.CUSTOMER_NAME.TOOLTIP_TEXT_UPDATE')"
              @click="onUpdateCustomerName"
            />
          </span>
        </div>
        <span v-if="orderMode === 'edit'" class="text-red body-b2 alert">
          {{ $t('ORDER.CREATE.EDIT_MODE.EDIT_WARNING_TEXT') }}
        </span>
      </div>
      <woot-secondary-button
        size="medium"
        :name="$t('ORDER.CREATE.ADD_PRODUCTS')"
        :back-icon="showProductList ? 'chevronTop' : 'chevronDown'"
        icon-color="green"
        :custom-class="[
          'add-new-product-btn',
          showProductList && 'active-btn-state',
        ]"
        @click="showProductList = !showProductList"
      />
      <div class="flex-column flex-justify--between customer--tools-container">
        <div class="customer--tools">
          <transition name="slide-up">
            <products-dropdown
              v-if="showProductList"
              v-on-clickaway="() => (showProductList = false)"
              @closeDropdown="showProductList = false"
            />
          </transition>
          <div v-if="hasProducts">
            <order-details
              :orders="productsInCart"
              :cart-data="cartDetails"
              @error="areCartFieldsValid = !$event"
            />
          </div>
          <div v-else class="info-excerpt body-b3 text-dark">
            {{ $t('ORDER.CREATE.NO_PRODUCTS_ADDED') }}
          </div>
          <div
            v-if="hasProducts && !customerAddresses.length"
            class="info-excerpt body-b3 text-dark"
          >
            {{ $t('ORDER.CREATE.NO_ADDRESS_ADDED') }}
          </div>
          <customer-address
            address-type="shipping"
            :addresses="customerAddresses"
            @showCreateAddress="handleShowCreateAddress"
          />
          <customer-address
            address-type="billing"
            :addresses="customerAddresses"
          />
          <notes @change-notes-data="setNotesData($event)" />
        </div>
        <transition v-if="orderMode !== 'edit'" name="modal-fade">
          <span
            v-if="!showProductList"
            class="place-order-button flex-row flex-justify"
            :class="{ disabled: isBtnDisabled }"
            @click="btnDisabledHandler"
          >
            <woot-dropdown-button
              v-if="permissions.canPrepay && permissions.canCod"
              :is-inverted="true"
              :name="placeOrderButtonLabel"
              :option-list="orderOptions"
              custom-class="place-order--dropdown actions--container"
              :custom-button-style="isBtnDisabled ? { opacity: '60%' } : {}"
              :button-click="placePrepaidOrder"
              @click="placeCodOrder"
            />
            <woot-primary-button
              v-else-if="permissions.canPrepay"
              :name="placeOrderButtonLabel"
              custom-class="place-order--dropdown actions--container"
              :custom-style="isBtnDisabled ? { opacity: '60%' } : {}"
              @click="placePrepaidOrder"
            />
            <woot-primary-button
              v-else-if="permissions.canCod"
              :name="'Place COD order'"
              custom-class="place-order--dropdown actions--container"
              :custom-style="isBtnDisabled ? { opacity: '60%' } : {}"
              @click="placeCodOrder"
            />
          </span>
        </transition>
      </div>

      <!-- Share payment link confirmation Modal -->
      <woot-modal
        :show.sync="showConfirmationModal"
        :on-close="() => (showConfirmationModal = false)"
      >
        <woot-modal-header
          :header-title="
            $t('ORDER.CREATE.PREPAID_PAYMENT.CONFIRMATION.HEADER_TITLE')
          "
          :header-content="
            $t('ORDER.CREATE.PREPAID_PAYMENT.CONFIRMATION.HEADER_CONTENT')
          "
        />
        <div class="content-box">
          <div class="flex-row flex-justify--end">
            <woot-primary-button
              :loading="prepaidLoading"
              :disabled="prepaidLoading"
              name="Share Link"
              @click="preparePrepaidOrderData"
            />
          </div>
        </div>
      </woot-modal>

      <!-- COD Confirmation Modal -->
      <woot-modal
        :show.sync="showCodConfirmationModal"
        :on-close="() => (showCodConfirmationModal = false)"
      >
        <woot-modal-header
          :header-title="
            $t('ORDER.CREATE.CASH_ON_DELIVERY.CONFIRMATION.HEADER_TITLE')
          "
          :header-content="
            `
            <br />
            ${$t(
              'ORDER.CREATE.CASH_ON_DELIVERY.CONFIRMATION.HEADER_CONTENT'
            )} ${contact.name} <br /> Amount: ${accountCurrency}${
              draftOrderData.total_price
            }`
          "
        />

        <div class="flex-row flex-justify--end content-box">
          <woot-primary-button
            :name="$t('ORDER.CREATE.CASH_ON_DELIVERY.CONFIRMATION.BUTTON_TEXT')"
            :loading="creatingCodOrder"
            :disabled="creatingCodOrder"
            @click="createCodOrder"
          />
        </div>
      </woot-modal>
    </div>
  </transition-group>
</template>
<script>
import { mapGetters } from 'vuex';
import { frontendURL, conversationUrl } from 'dashboard/helper/URLHelper';

import validations from '../shared/validations';

import ProductsDropdown from '../../products/deepShopify/ProductsDropdown';
import CreateAddress from './CreateAddress';
import CustomerAddress from './CustomerAddress';
import Notes from '../shared/Notes';
import OrderDetails from '../shared/OrderDetails';

import inboxMixin from 'shared/mixins/inboxMixin';
import alertMixin from 'shared/mixins/alertMixin';
import { mixin as clickaway } from 'vue-clickaway';
import deepShopifyPermissionsMixin from 'shared/mixins/deepShopifyPermissionsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: {
    ProductsDropdown,
    CreateAddress,
    CustomerAddress,
    OrderDetails,
    Notes,
  },
  mixins: [
    inboxMixin,
    alertMixin,
    clickaway,
    deepShopifyPermissionsMixin,
    mixPanelAnalyticsMixin,
  ],
  data() {
    return {
      shouldEditName: false,
      showProductList: false,
      showCreateAddress: false,
      areCartFieldsValid: true,
      isShippingAddress: true,
      showConfirmationModal: false,
      showCodConfirmationModal: false,
      selecteAddressId: -1,
      customerName: '',
      paymentInformation: null,
      prepaidLoading: false,
      initialOrderData: null,
      noteData: '',
      appliedDiscount: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      accountId: 'getCurrentAccountId',
      activeInbox: 'getSelectedInbox',
      productsInCartInCreateMode: 'getProductsInCart',
      cartDetailsInCreateMode: 'getCartMeta',
      productsInCartInEditMode: 'getProductsInCartInEditMode',
      cartDetailsInEditMode: 'getCartMetaInEditMode',
      customerAddresses: 'getCustomerAddresses',
      draftOrderData: 'getDraftOrderMeta',
      creatingDraftOrder: 'getDraftOrderUIFlag',
      creatingCodOrder: 'getCodOrderUIFlag',
      orderMode: 'getCurrentOrderMode',
      selectedAddress: 'getSelectedAddress',
      activeShopifyOrder: 'getActiveShopifyOrder',
    }),
    hasProducts() {
      return this.productsInCart && this.productsInCart.length !== 0;
    },
    accountCurrency() {
      return this.$store.getters['accounts/getAccountCurrency'](this.accountId);
    },
    productsInCart() {
      if (this.orderMode === 'edit') return this.productsInCartInEditMode;

      return this.productsInCartInCreateMode;
    },
    cartDetails() {
      if (this.orderMode === 'edit') return this.cartDetailsInEditMode;
      return this.cartDetailsInCreateMode;
    },
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    orderOptions() {
      let isEditMode = this.orderMode === 'edit';
      let name = isEditMode ? 'UPDATE ORDER' : 'PLACE COD ORDER';

      if (isEditMode && !this.activeShopifyOrder.is_cod) return [];

      return [{ id: 'cod', name }];
    },
    placeOrderButtonLabel() {
      return this.$t('ORDER.CREATE.PREPAID_PAYMENT.BUTTON_TEXT');
    },
    isBtnDisabled() {
      if (
        this.orderMode === 'edit' &&
        this.activeShopifyOrder.fixed_price ===
          this.activeShopifyOrder.total_price
      )
        return true;
      if (
        this.creatingDraftOrder ||
        !this.productsInCart?.length ||
        !this.customerAddresses.length ||
        !this.areCartFieldsValid
      )
        return true;
      return false;
    },
    formatAddress() {
      if (this.initialOrderData.shipping_address) {
        return `${this.initialOrderData.shipping_address?.address_1}, ${this.initialOrderData.shipping_address?.address_2}, ${this.initialOrderData.shipping_address?.city}, ${this.initialOrderData.shipping_address?.province}, ${this.initialOrderData.shipping_address?.country} - ${this.initialOrderData.shipping_address?.zip}, `;
      }
      return `Not Available`;
    },
  },
  created() {
    if (!this.cartDetails.customer || this.cartDetails.customer === -1) {
      let unwatch = () => null;
      unwatch = this.$watch('cartDetails.customer', () => {
        if (Object.keys(this.cartDetails).length !== 0)
          this.$store.dispatch('getCustomer', {
            customerId: this.cartDetails.customer,
          });
      });

      // clear watcher after sometime
      setTimeout(() => {
        unwatch();
      }, 60000);
    } else {
      this.$store.dispatch('getCustomer', {
        customerId: this.cartDetails.customer,
      });
    }
  },
  mounted() {
    document.removeEventListener('keydown', this.handleKeyEvents);
  },
  validations: { ...validations },
  methods: {
    orderItemsMessagePayload(items) {
      let message = ``;
      items.forEach((item, index) => {
        message += `\n${index + 1}. ${item.variant_item.product.name} (Price: ${
          item.variant_item.price
        }, Quantity: ${item.quantity})`;
      });
      return message;
    },
    handleShowCreateAddress({ addressType, addressId }) {
      if (addressType === 'shipping') this.isShippingAddress = true;
      else this.isShippingAddress = false;
      this.selecteAddressId = addressId;
      this.showCreateAddress = true;
    },
    onCloseCreateOrder() {
      if (this.shouldEditName) {
        this.shouldEditName = false;
        return;
      }

      const { activeInbox } = this;

      const path = conversationUrl({
        accountId: this.currentChat.account_id,
        activeInbox,
        id: this.currentChat.id,
      });

      // redirecting to overview panel
      this.$router.push(frontendURL(path) + '/orders');
    },
    sharePaymentInformation() {
      try {
        const message = `*Thank you for choosing us! Here are your order details.*
          \n \n*Invoice name:* ${this.initialOrderData.customer.first_name ||
            ''}
          \n*Delivery Address:* ${this.formatAddress}
          \n*PIN Code:* ${this.initialOrderData.shipping_address?.zip}
          \n*Amount to pay:* ${this.paymentInformation.link_amount}
          \n*Please pay using this link:*
          \n${this.paymentInformation.link_url}
          \n\n*Items:* ${this.orderItemsMessagePayload(
            this.initialOrderData.cart.line_items
          )}`;

        if (this.isAnEmailChannel) {
          bus.$emit('openReplyBox', { message });
          return;
        }

        const messagePayload = {
          conversationId: this.currentChat.id,
          message,
        };

        this.$store.dispatch('sendMessage', messagePayload);
        bus.$emit('scrollToMessage');
      } catch (error) {
        // handle error
        this.showAlert(this.$t('ORDER.ERROR'), 'error');
        this.prepaidLoading = false;
      }
    },
    setNotesData(note) {
      this.noteData = note;
    },
    btnDisabledHandler() {
      if (this.isBtnDisabled) {
        if (!this.productsInCart.length)
          this.showAlert(this.$t('ORDER.CREATE.NO_PRODUCTS_IN_CART'), 'error');
        else if (!this.customerAddresses.length)
          this.showAlert(this.$t('ORDER.CREATE.NO_ADDRESS'), 'error');
        else if (!this.areCartFieldsValid)
          this.showAlert(this.$t('ORDER.CREATE.ERROR'), 'error');
        else if (
          this.orderMode === 'edit' &&
          this.activeShopifyOrder.fixed_price ===
            this.activeShopifyOrder.total_price
        )
          this.showAlert(this.$t('ORDER.UPDATE.NO_CHANGE.ERROR'), 'warning');
      }
    },
    onEditCustomerName() {
      this.customerName = this.contact.name;
      this.shouldEditName = true;
    },
    onUpdateCustomerName() {
      if (!this.customerName) {
        this.showAlert(this.$t('ORDER.CREATE.CUSTOMER_NAME.ERROR'), 'warning');
        return;
      }
      try {
        this.$store
          .dispatch('contacts/update', {
            id: this.contact.id,
            name: this.customerName,
          })
          .then(() => {
            this.showAlert(
              this.$t('ORDER.CREATE.CUSTOMER_NAME.SUCCESS'),
              'success'
            );
            this.shouldEditName = false;
          });
      } catch (error) {
        this.showAlert(this.$t('EDIT_CONTACT.ERROR_MESSAGE'), 'error');
        this.shouldEditName = false;
      }
    },
    initiateCodOrder(payload) {
      try {
        this.$store
          .dispatch('createCODOrder', payload)
          .then(orderResponse => {
            this.shareOrder(orderResponse.data);
            this.onCloseCreateOrder();
            this.showAlert(
              this.$t('ORDER.CREATE.CASH_ON_DELIVERY.SUCCESS'),
              'success'
            );
            this.getCart();
          })
          .catch(() => {
            this.showAlert(
              this.$t('ORDER.CREATE.CASH_ON_DELIVERY.ERROR'),
              'error'
            );
          });
      } catch (error) {
        this.showAlert(this.$t('ORDER.ERROR'), 'error');
      }
    },
    createCodOrder() {
      let tags = '';
      let assigneeId = null;
      try {
        assigneeId = this.currentChat?.meta?.assignee?.id;
        if (!assigneeId) {
          assigneeId = null;
        }
      } catch {
        tags += `LC_HD_ORDER,`;
        assigneeId = null;
      }

      const data = {
        draftOrderId: this.draftOrderData?.id,
        note: this.noteData,
        shippingCharge: this.shippingCharge,
        tags: tags,
        agentId: assigneeId,
        conversationId: this.currentChat?.id,
      };

      if (
        !this.selectedAddress.shipping &&
        this.selectedAddress.shipping === null
      )
        this.updateDraftOrderAddress().then(() => {
          this.initiateCodOrder(data);
        });
      else this.initiateCodOrder(data);
    },
    getCart() {
      const { phone_number: phoneNumber, email, name } = this.contact;
      if (!phoneNumber && !email) {
        this.$store.dispatch('emptyCustomerCart');
        return;
      }
      const payload = {
        phone_number: phoneNumber || '',
        email: email || '',
        firstName: name || '',
      };
      this.$store.dispatch('getCustomerCart', payload);
    },
    invoiceName(firstName, lastName) {
      return firstName || lastName
        ? `\n*Invoice Name:* ${firstName || ''} ${lastName || ''}`
        : '';
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    getShippingAddress(order) {
      if (order.shopify_shipping_address) {
        return `${order.shopify_shipping_address?.address_1} ${
          order.shopify_shipping_address.address_2
            ? order.shopify_shipping_address.address_2
            : ''
        }, ${order.shopify_shipping_address?.city}, ${
          order.shopify_shipping_address.province
            ? order.shopify_shipping_address.province
            : ''
        }`;
      }
      return '';
    },
    shareOrder(order) {
      try {
        let desc = '';
        if (
          order.product_link !== null &&
          order.fulfillment.length &&
          order.fulfillment[0].tracking_url
        ) {
          desc += `Tracking URL:\n${order.fulfillment[0].tracking_url}`;
        }

        const message = `*Thank you for choosing us! Your order has been placed successfully.*
          \n\n*Order ID:* ${order.name}

          \n${this.invoiceName(
            order.shopify_customer.first_name,
            order.shopify_customer.last_name
          )}
          \n*Placed On:* ${this.formatDate(order.created_at)}
          \n*Delivery Address:* ${this.getShippingAddress(order)}
          \n*Pin Code:* ${
            order.shopify_shipping_address
              ? order.shopify_shipping_address.zip
              : ''
          }
          \n*Payment Mode:* COD
          \n \n *Items:* ${this.orderItemsMessagePayload(order.cart.line_items)}

          \n\n
          \n\n*Order summary*:
          \n*Subtotal:*       \t${order.cart.cart_total}
          \n*Total Tax:*      \t${order.total_tax}
          \n*Discount:*       \t${order.applied_discount}
          \n*Total Amount:*   \t${order.amount}
          \n\n${desc}`;

        if (this.isAnEmailChannel) {
          bus.$emit('openReplyBox', { message });
          return;
        }

        const messagePayload = {
          conversationId: this.currentChat.id,
          message: message,
        };

        this.$store.dispatch('sendMessage', messagePayload);
        bus.$emit('scrollToMessage');
      } catch (error) {
        // handle error
      }
    },
    updateDraftOrderAddress() {
      const addressId = this.customerAddresses.find(
        address => address.is_default_address
      )?.id;
      let assigneeId = null;
      try {
        assigneeId = this.currentChat?.meta?.assignee?.id;
        if (!assigneeId) {
          assigneeId = null;
        }
      } catch {
        assigneeId = null;
      }
      return this.$store.dispatch('updateDraftOrder', {
        draftOrderId: this.draftOrderData.id,
        shippingAddress: addressId,
        editRequest: 'edit_shipping_address',
        agentId: assigneeId,
        conversationId: this.currentChat?.id,
      });
    },
    preparePrepaidOrderData() {
      this.prepaidLoading = true;
      const data = {
        draftOrderId: this.draftOrderData.id,
      };
      if (
        !this.selectedAddress.shipping &&
        this.selectedAddress.shipping === null
      )
        this.updateDraftOrderAddress().then(() => {
          this.initiatePrepaidOrder(data);
        });
      else this.initiatePrepaidOrder(data);
    },
    initiatePrepaidOrder(payload) {
      try {
        this.$store.dispatch('initiatePrepaidOrder', payload).then(response => {
          this.initialOrderData = response.data;
          this.paymentInformation = response.data.payment_link;
          this.showAlert(
            'Order saved, will be placed post payment confirmation.',
            'success'
          );
          const { activeInbox } = this;

          const path = conversationUrl({
            accountId: this.currentChat.account_id,
            activeInbox,
            id: this.currentChat.id,
          });

          this.$router.push(frontendURL(path) + '/');
          this.sharePaymentInformation();

          this.paymentInformation = null;
          this.initialOrderData = null;
          this.showConfirmationModal = false;
          this.prepaidLoading = false;
        });
      } catch (error) {
        this.showAlert(this.$t('ORDER.ERROR'), 'error');
        this.prepaidLoading = false;
      }
    },
    placeCodOrder() {
      if (this.isBtnDisabled) return;

      if (!this.permissions.canCod) {
        this.showAlert(this.$t('ORDER.COD.ERROR'), 'warning');
        return;
      }

      this.placeOrder('cod');
    },
    placePrepaidOrder() {
      if (this.isBtnDisabled) return;

      if (!this.permissions.canPrepay) {
        this.showAlert(this.$t('ORDER.PREPAID.ERROR'), 'warning');
        return;
      }

      this.placeOrder('prepaid');
    },
    placeOrder(val) {
      let assigneeId = null;
      try {
        assigneeId = this.currentChat?.meta?.assignee?.id;
        if (!assigneeId) {
          assigneeId = null;
        }
      } catch {
        assigneeId = null;
      }
      this.$store
        .dispatch('updateDraftOrder', {
          draftOrderId: this.draftOrderData.id,
          shippingAddress: this.selectedAddress.shipping,
          editRequest: 'edit_shipping_address',
          agentId: assigneeId,
          conversationId: this.currentChat?.id,
        })
        .then(() => {
          if (val === 'cod') {
            this.showCodConfirmationModal = true;
            this.mix_panel_clicked_order_create_successful('COD');
          } else {
            this.showConfirmationModal = true;
            this.mix_panel_clicked_order_create_successful('PREPAID');
          }
        })
        .catch(() => {
          this.showAlert(this.$t('ORDER.ERROR'), 'error');
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.content-box {
  padding: $space-slab $space-medium !important;
}

.disabled {
  background-color: #fff;
}

.edit-name-icon {
  cursor: pointer;

  &:hover {
    ::v-deep .icon {
      fill: $pg-1-600;
    }
  }
}
.update-name-icon {
  cursor: pointer;

  &:hover {
    ::v-deep .icon {
      fill: $pg-1-600;
    }
  }
}

.mt-4 {
  margin-top: $space-smaller;
}
</style>
