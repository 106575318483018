<template>
  <div class="three-dot-loader" :class="`${size} is-${color}`">
    <div class="dot" />
    <div class="dot" />
    <div class="dot" />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'tiny',
    },
    color: {
      type: String,
      default: 'grey',
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/mixins';

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

@mixin color-dot() {
  .dot {
    background-color: currentColor;
    animation: jump 0.6s ease-in-out infinite;
  }
}

.three-dot-loader {
  @include color-dot();
  display: inline-flex;
  vertical-align: middle;

  .dot {
    width: $space-one;
    height: $space-one;
    border-radius: 50%;
    margin: $zero $space-micro;
  }

  &.small .dot {
    width: $space-small;
    height: $space-small;
  }

  &.tiny .dot {
    width: $space-six;
    height: $space-six;
    animation-name: jump-tiny;
  }

  &.is-grey {
    color: $neutral-grey-800;
  }

  &.is-lime-green {
    color: $pg-1;
  }

  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes jump-tiny {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}
</style>
