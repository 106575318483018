<template>
  <div
    class="conversation flex-row flex-justify--between gap--normal"
    @click="navigateToConversation"
  >
    <div class="contact-avatar">
      <thumbnail
        :src="data.thumbnail"
        :username="data.name"
        :custom-style="{ border: 'none', fontSize: '1.4rem' }"
        :status="data.availability_status"
        :bg-color="'#F5F5F5'"
        color="#5B8FF9"
        size="36px"
      />
    </div>
    <div class="contact-name info-container full-width">
      <span
        class="title-h5 text-dark line-clamp-1 text-align--left"
        v-text="data.name"
      />
    </div>
    <div class="info-container">
      <div
        v-if="data.phone_number"
        class="body-b2 text-dark text-align--right"
        v-text="data.phone_number"
      />
      <div
        class="body-b3 line-clamp-1 text-light text-align--right"
        v-text="data.email || 'N/A'"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import Thumbnail from 'dashboard/components/widgets/Thumbnail';

import router from 'dashboard/routes/index.js';
import { frontendURL, conversationUrl } from 'dashboard/helper/URLHelper.js';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: { Thumbnail },
  mixins: [alertMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    navigateToConversation() {
      this.$store
        .dispatch('contacts/getLastConversationId', this.data.id)
        .then(response => {
          const path = conversationUrl({
            accountId: this.accountId,
            id: response.display_id,
          });
          this.$emit('routeNavigated', response.display_id);
          router.push({ path: frontendURL(path) });
        })
        .catch(error => {
          const {
            response: {
              data: { error: errorMessage },
            },
          } = error;
          this.showAlert(
            errorMessage ||
              this.$t('CONVERSATION.SEARCH.REDIRECTION_ERROR_MESSAGE'),
            'error'
          );
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.conversation {
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius-smaller;
  margin-bottom: $space-small;
  padding: $space-slab $space-normal;
  transition: background-color 0.2s ease-in;
  width: 100%;

  &:active {
    background-color: $neutral-grey-300;
  }

  .contact-avatar {
    width: fit-content;
  }
}
</style>
