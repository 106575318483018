<template>
  <div class="view-box columns">
    <settings-header
      icon="graph"
      :header-title="$t('ANALYTICS.HEADER.CONVERSATION')"
      :show-back-button="false"
    />
    <div class="column content-box settings-box">
      <div class="settings-container">
        <div class="column analytics-box analytics-panel">
          <woot-folder-tabs
            :active="currentSelection"
            :tab-list="tabList"
            :custom-style="{ padding: '0.8rem 1.6rem 0.8rem' }"
            @click="changeSelection"
          />
          <div class="analytics-container custom-scroll">
            <div class="graph-filter">
              <div class="flex-row-justify-center">
                <label class="lime-radio-container">
                  {{ $t('ANALYTICS.DATE_RANGE[2].name') }}
                  <input
                    type="radio"
                    name="radio"
                    :checked="currentDateRangeSelection"
                    @click="changeDateSelection"
                  />
                  <span class="lime-checkmark" />
                </label>
                <label class="lime-radio-container">
                  {{ $t('ANALYTICS.DATE_RANGE[3].name') }}
                  <input
                    type="radio"
                    name="radio"
                    :checked="!currentDateRangeSelection"
                    @click="changeDateSelection"
                  />
                  <span class="lime-checkmark" />
                </label>
              </div>
              <div class="flex-row-justify-center dropdown-filter">
                <selector
                  v-if="filterSelection"
                  show-all
                  :custom-button="true"
                  :custom-style="{ width: 'auto' }"
                  :option-list="agentList"
                  :selected="selectedAgent"
                  @click="changeFilterId"
                >
                  <button class="selector-button">
                    <span class="button-text">{{ selectedAgent }}</span>
                    <icons
                      name="chevronDown"
                      size="semimedium"
                      color="darkestgrey"
                    />
                  </button>
                </selector>
                <selector
                  v-else
                  show-all
                  :custom-button="true"
                  :custom-style="{ width: 'auto' }"
                  :option-list="inboxList"
                  :selected="selectedInbox"
                  @click="changeFilterId"
                >
                  <button class="selector-button">
                    <span class="button-text">{{ selectedInbox }}</span>
                    <icons
                      name="chevronDown"
                      size="semimedium"
                      color="darkestgrey"
                    />
                  </button>
                </selector>
                <woot-tab-switch
                  :active-state="!filterSelection"
                  :state-text="$t('ANALYTICS.FILTER_SELECTION.INBOX.NAME')"
                  :compliment-state-text="
                    $t('ANALYTICS.FILTER_SELECTION.AGENT.NAME')
                  "
                  :color-flipped="false"
                  @click="filterSelectionChange()"
                />
              </div>
            </div>
            <div class="report-bar">
              <woot-loading-state
                v-if="accountReport.isFetching"
                :message="$t('ANALYTICS.LOADING_CHART')"
              />
              <div v-else class="chart-container">
                <woot-bar
                  v-if="accountReport.data.length && currentDateRangeSelection"
                  :collection="collection"
                />
                <woot-line
                  v-else-if="
                    accountReport.data.length && !currentDateRangeSelection
                  "
                  :collection="collection"
                />
                <span v-else class="empty-state">
                  {{ $t('ANALYTICS.NO_ENOUGH_DATA') }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <settings-side-card image="analytics">
          <download />
        </settings-side-card>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsHeader from '../settings/SettingsHeader';
import { mapGetters } from 'vuex';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import getUnixTime from 'date-fns/getUnixTime';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import Download from './Download';
import SettingsSideCard from '../settings/SettingSideCard';

const REPORTS_KEYS = {
  CONVERSATIONS: 'conversations_count',
  INCOMING_MESSAGES: 'incoming_messages_count',
  OUTGOING_MESSAGES: 'outgoing_messages_count',
  FIRST_RESPONSE_TIME: 'avg_first_response_time',
  RESOLUTION_TIME: 'avg_resolution_time',
  RESOLUTION_COUNT: 'resolutions_count',
};

export default {
  components: {
    SettingsHeader,
    Download,
    SettingsSideCard,
  },
  data() {
    return {
      currentSelection: 0,
      currentDateRangeSelection: true, // week
      filterSelection: true, // agent
      filterId: -1,
    };
  },
  computed: {
    ...mapGetters({
      accountSummary: 'getAccountSummary',
      accountReport: 'getAccountReports',
      inboxes: 'inboxes/getInboxes',
      agents: 'agents/getAgents',
    }),
    tabList() {
      const reportKeys = [
        'CONVERSATIONS',
        'INCOMING_MESSAGES',
        'OUTGOING_MESSAGES',
      ];
      return reportKeys.map(key => ({
        key: REPORTS_KEYS[key],
        name: this.$t(`ANALYTICS.METRICS.${key}.NAME`),
        desc: `(${this.accountSummary[REPORTS_KEYS[key]]})`,
      }));
    },
    to() {
      return getUnixTime(startOfDay(new Date()));
    },
    from() {
      const diff = this.currentDateRangeSelection ? 6 : 29;
      const fromDate = subDays(new Date(), diff);
      return getUnixTime(fromDate);
    },
    collection() {
      if (this.accountReport.isFetching) {
        return {};
      }
      if (!this.accountReport.data.length) return {};
      const labels = this.accountReport.data.map(element =>
        format(fromUnixTime(element.timestamp), 'dd/MMM')
      );
      const data = this.accountReport.data.map(element => element.value);
      return {
        labels,
        label: this.tabList[this.currentSelection].name,
        data,
      };
    },
    inboxList() {
      let inboxArray = [
        {
          name: this.$t('ANALYTICS.FILTER_SELECTION.INBOX.PLACEHOLDER'),
          id: 0,
          channel_id: 0,
        },
        ...this.inboxes,
      ];

      return inboxArray.map(inbox => ({
        id: inbox.id,
        name: inbox.name,
        channel_id: inbox.channel_id,
      }));
    },
    selectedInbox() {
      let selectedIndex = this.inboxList.findIndex(
        i => i.channel_id === this.filterId
      );

      return selectedIndex > -1
        ? this.inboxList[selectedIndex].name
        : this.inboxList[0].name;
    },
    agentList() {
      let agentArray = [
        {
          name: this.$t('ANALYTICS.FILTER_SELECTION.AGENT.PLACEHOLDER'),
          id: 0,
        },
        ...this.agents,
      ];

      return agentArray.map(agent => ({
        id: agent.id,
        name: agent.name,
      }));
    },
    selectedAgent() {
      let selectedIndex = this.agentList.findIndex(a => a.id === this.filterId);

      return selectedIndex > -1
        ? this.agentList[selectedIndex].name
        : this.agentList[0].name;
    },
  },
  mounted() {
    this.fetchAllData();
    this.$store.dispatch('agents/get');
  },
  methods: {
    changeFilterId(val) {
      this.filterId = val.id;
      this.fetchAllData();
    },
    fetchAllData() {
      const { from, to } = this;
      const type = this.filterSelection ? 'agent' : 'inbox';
      this.$store.dispatch('fetchAccountSummary', {
        from,
        to,
        type: this.filterId === -1 || this.filterId === '-1' ? 'account' : type,
        sid: this.filterId,
      });
      this.$store.dispatch('fetchAccountReport', {
        metric: this.tabList[this.currentSelection].key,
        from,
        to,
        type: this.filterId === -1 || this.filterId === '-1' ? 'account' : type,
        sid: this.filterId,
      });
    },
    changeDateSelection() {
      this.currentDateRangeSelection = !this.currentDateRangeSelection;
      this.fetchAllData();
    },
    changeSelection(index) {
      this.currentSelection = index;
      this.fetchChartData();
    },
    fetchChartData() {
      const { from, to } = this;
      this.$store.dispatch('fetchAccountReport', {
        metric: this.tabList[this.currentSelection].key,
        from,
        to,
        type:
          // eslint-disable-next-line no-nested-ternary
          this.filterId === -1 || this.filterId === '-1'
            ? 'account'
            : this.filterSelection
            ? 'agent'
            : 'inbox',
        sid: this.filterId,
      });
    },
    filterSelectionChange() {
      this.filterId = -1;
      this.filterSelection = !this.filterSelection;
      this.fetchAllData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.view-box {
  background: $neutral-grey-200;
}
.settings-box {
  padding-top: $space-smaller;
  overflow: hidden;

  .settings-container {
    display: flex;
    height: 100%;
    flex-direction: row;

    .analytics-box {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
      border-radius: $border-radius;
      border-top-left-radius: $zero;
      box-shadow: -1px 2px 2px $elevation-2;

      .top-nav-bar {
        margin: 0;
      }

      .analytics-container {
        background: $neutral-white;
        height: 100%;
        border-radius: $border-radius;
        border-top-left-radius: $zero;
        padding: $space-normal;
      }
    }
  }
}
.graph-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .dropdown-filter {
    padding: $space-small;
    border-radius: $border-radius;

    .button-container {
      box-shadow: none;
      background: $pg-1-500;
    }

    .actions--container {
      margin: $zero $space-normal;
      width: 12rem;
    }
  }
}
.report-stats {
  padding: $space-medium;
}
.selector-button {
  align-items: center;
  display: flex;
  overflow: hidden;
  cursor: pointer;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  .button-text {
    color: $neutral-grey-800;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: $space-small;
    font-size: $font-size-small;
    text-transform: capitalize;
    line-height: 2.2rem;
  }
}
</style>
