export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const mustBeCDNUrlorVariable = value => {
  // eslint-disable-next-line dot-notation
  if (value === null) return false;
  if (value === '{}') return true;
  if (value.match(/\.(jpeg|jpg|gif|png|pdf|mp4|avi|mkv|docx)$/) !== null) {
    return true;
  }

  return false;
};
