<template>
  <div class="channel">
    <div
      class="channel-image-container"
      :class="{ inactive: !isActive }"
      @click="onItemClick"
    >
      <div class="channel-image flex-row flex-align flex-justify">
        <img
          v-if="channel.key === 'gupshup_enterprise'"
          src="~dashboard/assets/images/channels/gupshup.svg"
        />
        <img
          v-else-if="
            ['whatsapp_channels', 'whatsapp_cloud_API'].includes(channel.key)
          "
          src="~dashboard/assets/images/channels/whatsapp.svg"
        />
        <img
          v-else
          :src="
            require(`dashboard/assets/images/channels/${channel.key.toLowerCase()}.svg`)
          "
        />
      </div>
      <span
        class="channel__title body-b3 text-light"
        v-text="stringPrettify(channel.name)"
      />
    </div>
  </div>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    channel: {
      type: Object,
      required: true,
    },
    enabledFeatures: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isActive() {
      const { key } = this.channel;
      if (Object.keys(this.enabledFeatures).length === 0) return false;

      if (key === 'facebook' || key === 'instagram')
        return this.enabledFeatures.channel_facebook;

      if (key === 'email') return this.enabledFeatures.channel_email;

      if (
        Object.keys(this.enabledFeatures).includes('website') ||
        Object.keys(this.enabledFeatures).includes('api')
      ) {
        if (key === 'website') return this.enabledFeatures.website;

        if (key === 'api') return this.enabledFeatures.api;
      }

      return [
        'whatsapp_channels',
        'voice_channels',
        'website',
        'three_sixty_dialog',
        'twilio',
        'api',
        'chat_API',
        'zoko',
        'gupshup',
        'acl',
        'maytapi',
        'freshchat',
        'freshchat',
        'wati',
        'value_first',
        'gupshup_enterprise',
        'whatsapp_cloud_API',
        'knowlarity',
        'ics',
        'infobip',
        'exotel',
        'sinch',
      ].includes(key);
    },
  },
  methods: {
    onItemClick() {
      if (this.isActive) this.$emit('channel-item-click', this.channel.key);
      else
        this.showAlert('This feature is not enabled for this account', 'info');
    },
    // Function to remove underscores and replace them with spaces and capitalizing the first letters
    stringPrettify(str) {
      var i;
      var frags = str.split('_');
      for (i = 0; i < frags.length; i += 1) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    },
  },
};
</script>
