<template>
  <div class="column content-box settings-box no-padding">
    <router-link
      :to="addAccountScoping('settings/inboxes/new')"
      @click.native="InlineButtonClickHandler"
    >
      <woot-base-button
        v-if="isAdmin"
        size="medium"
        front-icon="plus"
        class="settings-button"
        :disabled="inPendingPaymentForNonShopify && !isEnterprise"
        icon-view-box="0 0 20 20"
      >
        {{ $t('SETTINGS.INBOXES.NEW_INBOX') }}
      </woot-base-button>
    </router-link>
    <div class="settings-container">
      <div class="column settings-main-menu-new">
        <div class="table-container">
          <data-table
            :actions="actionsList"
            :columns="tableColumns"
            :table-data="inboxList"
            :loading="uiFlags.isFetching"
            :enable-animations="inboxList.length <= 10"
            _class="scrollable-table custom-scroll"
            @edit="openInboxSettings"
            @select="viewInbox"
            @delete="openDelete"
          >
            <template v-slot:table-features>
              <search-box
                :placeholder="$t('INBOX_MGMT.SEARCH_TXT')"
                :value="search"
                custom-class="search-box"
                @setSearch="setSearch"
              />
              <woot-single-selector
                v-if="inboxes.length"
                :disabled="false"
                variant="secondary"
                size="medium"
                :front-icon="inboxFilterIcon ? inboxFilterIcon : 'inbox'"
                :default-option="selectedInboxName"
                :option-list="inboxTypeList"
                :show-selected-text="false"
                :dropdown-container-style="filterStyles"
                :button-style="{ 'border-radius': '0.4rem' }"
                :custom-style="{ left: 0 }"
                @click="applyInboxFilter"
              />
            </template>
            <template v-slot:zero-state>
              <table-zero-state
                v-if="!inboxList.length"
                title="No Inboxes added till now"
                asset-link="/brand-assets/inbox_zero_state.svg"
              >
                <router-link
                  :to="addAccountScoping('settings/inboxes/new')"
                  @click.native="InlineButtonClickHandler"
                >
                  <woot-base-button
                    v-if="isAdmin"
                    size="medium"
                    front-icon="plus"
                    style="margin-bottom: 5vh"
                    icon-view-box="0 0 20 20"
                  >
                    {{ $t('SETTINGS.INBOXES.NEW_INBOX') }}
                  </woot-base-button>
                </router-link>
              </table-zero-state>
            </template>
          </data-table>
        </div>
      </div>

      <settings-side-card>
        <inboxes-rhs-panel :inbox="selectedInbox" />
      </settings-side-card>
    </div>

    <woot-delete-modal-new
      :show.sync="showDeletePopup"
      :on-close="closeDelete"
      :on-confirm="confirmDeletion"
      show-close
      :title="$t('INBOX_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('INBOX_MGMT.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('INBOX_MGMT.DELETE.CONFIRM.YES')"
      :reject-text="$t('INBOX_MGMT.DELETE.CONFIRM.NO')"
      :custom-style="{
        'max-width': '35.2rem',
        height: 'auto',
      }"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import SearchBox from '../SearchBox';
import SettingsSideCard from '../SettingSideCard';
import DataTable from 'dashboard/components/widgets/table/DataTable';
import TableZeroState from 'dashboard/components/ui/settings/TableZeroState';
import InboxesRhsPanel from 'dashboard/components/ui/settings/inboxes/RhsPanel';

import inboxMixin from 'dashboard/mixins/inbox';
import adminMixin from 'dashboard/mixins/isAdmin';
import accountMixin from 'dashboard/mixins/account';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import alertMixin from 'shared/mixins/alertMixin';
import billingMixin from 'dashboard/mixins/billing';
import { INBOX_NAMES } from 'shared/mixins/inboxMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import { deleteWAWidget } from 'dashboard/api/whatsappWidget/whatsappWidget';

import * as types from 'shared/constants/googleEventType';

export default {
  name: 'InboxHome',
  components: {
    InboxesRhsPanel,
    DataTable,
    TableZeroState,
    SearchBox,
    SettingsSideCard,
  },
  mixins: [
    inboxMixin,
    adminMixin,
    accountMixin,
    globalConfigMixin,
    googleAnalyticsMixin,
    alertMixin,
    billingMixin,
    mixPanelAnalyticsMixin,
  ],
  data() {
    return {
      showSettings: false,
      showDeletePopup: false,
      selectedInbox: {},
      tableColumns: [
        {
          label: '',
          prop: 'avatar',
          width: '10%',
          component: 'InboxAvatar',
        },
        {
          label: 'Inbox',
          prop: 'name',
          width: '34%',
          component: 'InboxNameWithStatus',
          sortable: true,
        },
        {
          label: 'Type',
          prop: 'channel_type',
          width: '28%',
          component: 'InboxChannelType',
        },
      ],
      selectedInboxInFilter: { id: 0, name: 'All' },
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
      globalConfig: 'globalConfig/get',
      uiFlags: 'inboxes/getUIFlags',
    }),
    selectedInboxName() {
      return this.selectedInboxInFilter.name === 'All'
        ? 'Inbox Types'
        : this.selectedInboxInFilter.name;
    },
    actionsList() {
      if (!this.isAdmin) return [];
      return [
        { id: 0, name: 'edit', icon: 'editRound' },
        { id: 1, name: 'delete', icon: 'delete' },
      ];
    },
    inboxList() {
      let inboxList = [...this.inboxes];

      if (this.selectedInboxInFilter.name !== 'All') {
        inboxList = inboxList.filter(
          inbox => inbox.channel_type === this.selectedInboxInFilter.type
        );
      }

      inboxList = inboxList.filter(item => {
        return (
          item.name.toLowerCase().search(this.search.toLowerCase()) > -1 ||
          INBOX_NAMES[item.channel_type].name
            .toLowerCase()
            .search(this.search.toLowerCase()) > -1
        );
      });

      return inboxList;
    },
    inboxTypeList() {
      const typesList = this.inboxes.map((inbox, idx) => {
        let { name: icon, color } = this.computedInboxClass(inbox);
        return {
          id: idx + 1,
          name: INBOX_NAMES[inbox.channel_type].name,
          type: inbox.channel_type,
          icon: icon,
          color: color,
        };
      });

      return [{ id: 0, name: 'All' }, ...this.cleanDuplicates(typesList)];
    },
    inboxFilterIcon() {
      return this.selectedInboxInFilter.icon;
    },
    filterStyles() {
      return {
        height: '4.4rem',
        'max-width': '15rem',
      };
    },
  },
  methods: {
    cleanDuplicates(payload) {
      let uniqueVals = new Set();

      payload = payload.filter(item => {
        let k = item.type;
        return uniqueVals.has(k) ? false : uniqueVals.add(k);
      });

      return payload;
    },
    applyInboxFilter(val) {
      this.mix_panel_clicked_inbox_filter_menu_item('Inbox Settings', val.type);
      this.selectedInboxInFilter = val;
    },
    computedInboxClass(inbox) {
      if (inbox && inbox?.channel_type) {
        const { channel_type, phone_number, instagram_add_on } = inbox;
        const classByType = this.getInboxClassByType(
          channel_type,
          phone_number,
          '',
          instagram_add_on
        );

        return classByType;
      }

      return '';
    },
    setSearch(value) {
      this.googleAnalyticsEvent(
        types.default.INBOX_SEARCH,
        types.default.INBOX_SETTINGS,
        value
      );
      this.mix_panel_searched_settings_inbox();
      this.search = value;
    },
    async deleteInbox({ id }) {
      try {
        this.googleAnalyticsEvent(
          types.default.INBOX_DELETE,
          types.default.INBOX_SETTINGS,
          types.default.INBOX_SETTINGS
        );
        await this.$store.dispatch('inboxes/delete', id);
        if (this.inboxWithWAAccessToken()) {
          this.deleteWhatsappWidget();
        }
        this.selectedInbox = {};
        this.closeDelete();
        this.showAlert(
          this.$t('INBOX_MGMT.DELETE.API.SUCCESS_MESSAGE'),
          'success'
        );
        this.mix_panel_deleted_settings_inbox();
      } catch (error) {
        this.closeDelete();
        this.showAlert(this.$t('INBOX_MGMT.DELETE.API.ERROR_MESSAGE'), 'error');
      }
    },
    async deleteWhatsappWidget() {
      const {
        phone_number: phone,
        wa_widget_access_token: accessToken,
      } = this.selectedInbox;
      await deleteWAWidget(phone, accessToken);
    },
    inboxWithWAAccessToken() {
      return !!this.selectedInbox.wa_widget_access_token;
    },
    confirmDeletion() {
      this.deleteInbox(this.selectedInbox);
    },
    openInboxSettings(item) {
      this.mix_panel_clicked_edit_inbox();
      let route = this.addAccountScoping(`settings/inboxes/${item.id}`);
      this.$router.push(route);
    },
    viewInbox(inbox) {
      this.selectedInbox = inbox;
    },
    openDelete() {
      this.googleAnalyticsEvent(
        types.default.OPEN_DELETE_INBOX,
        types.default.INBOX_SETTINGS,
        types.default.INBOX_SETTINGS
      );
      this.showDeletePopup = true;
    },
    closeDelete() {
      this.googleAnalyticsEvent(
        types.default.CLOSE_DELETE_INBOX,
        types.default.INBOX_SETTINGS,
        types.default.INBOX_SETTINGS
      );
      this.showDeletePopup = false;
    },
    InlineButtonClickHandler() {
      this.mix_panel_clicked_settings_add_inbox();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/widgets/buttons';

.table-container {
  height: 100%;
}

.no-padding {
  padding-left: 0.1rem;
}

.settings-box {
  padding-top: $space-largest;
  overflow: hidden;

  .settings-container {
    display: flex;
    height: 100%;
    flex-direction: row;

    .search-box {
      background: none;
      margin-bottom: $zero;
      width: 30.4rem;
    }

    .settings-side-card {
      padding: $zero;
    }
  }
}
</style>
