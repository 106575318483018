<template>
  <Form v-if="!initialisingData" :data="sla" @submit="onSubmit" />
</template>
<script>
import Form from '../components/create/Form';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    Form,
  },
  mixins: [alertMixin],
  data() {
    return {
      sla: { name: '', description: '' },
      initialisingData: true,
    };
  },
  mounted() {
    this.initDataFromRoute();
  },
  methods: {
    initDataFromRoute() {
      const { name, description } = this.$route.params;
      this.sla = { name, description };
      this.initialisingData = false;
    },
    onSubmit(payload) {
      this.$store
        .dispatch('sla/createSLA', payload)
        .then(() => {
          this.$router.push({ name: 'settings_sla' });
          this.showAlert(this.$t('SLA.CREATE.API.SUCCESS_MESSAGE'), 'success');
        })
        .catch(error => {
          const errorMessage = error.response?.data?.[0]
            ? error.response.data[0]
            : this.$t('SLA.CREATE.API.ERROR_MESSAGE');

          this.showAlert(
            errorMessage,
            'error',
            errorMessage.length >= 50 ? 5000 : 2500
          );
        });
    },
  },
};
</script>
