<template>
  <div class="small-12 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.AUTH.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.AUTH.DESC')"
    />
    <div class="row channels">
      <channel-item
        v-for="channel in channelList"
        :key="channel.key"
        :channel="channel"
        :enabled-features="enabledFeatures"
        @channel-item-click="initChannelAuth(channel.key)"
      />
    </div>
  </div>
</template>

<script>
import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';
import ChannelItem from 'dashboard/components/widgets/ChannelItem';

import ChannelListMixin from 'dashboard/mixins/channelList';

export default {
  components: {
    ChannelItem,
    PageHeader,
  },
  mixins: [ChannelListMixin],
  data() {
    return {
      channelList: [
        { key: 'whatsapp_channels', name: 'WhatsApp' },
        { key: 'website', name: 'Website' },
        { key: 'facebook', name: 'Facebook' },
        { key: 'instagram', name: 'Instagram' },
        { key: 'email', name: 'Email' },
        { key: 'voice_channels', name: 'Voice' },
        { key: 'api', name: 'API' },
      ],
    };
  },
};
</script>
