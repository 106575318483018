<template>
  <div class="view-box-new columns">
    <settings-header
      icon="graph"
      :header-title="headerTitle"
      :show-back-button="false"
    />

    <last-updated-time
      scope="sales_insights"
      class="flex-row flex-justify--end pd-right"
    />

    <div class="tabs-pane">
      <woot-tabs :index="selectedTabIndex" @change="onTabChange">
        <woot-tabs-item v-for="tab in tabs" :key="tab.key" :name="tab.name" />
      </woot-tabs>
    </div>
    <sales-overview v-if="selectedTabIndex === 0" />
    <sales-insights v-if="selectedTabIndex === 1" />
  </div>
</template>

<script>
import SettingsHeader from '../settings/SettingsHeader';
import SalesOverview from './tabs/SalesOverview';
import SalesInsights from './tabs/SalesInsights';
import LastUpdatedTime from './components/LastUpdatedTime';

import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: {
    SettingsHeader,
    SalesOverview,
    SalesInsights,
    LastUpdatedTime,
  },
  mixins: [mixPanelAnalyticsMixin],
  data() {
    return {
      selectedTabIndex: 0,
    };
  },
  computed: {
    headerTitle() {
      return this.$t('ANALYTICS.HEADER.ANALYTICS');
    },
    tabs() {
      return [
        {
          key: 'sales_overview',
          name: this.$t('ANALYTICS.HEADER.SALES_OVERVIEW'),
        },
        {
          key: 'sales_insights',
          name: this.$t('ANALYTICS.HEADER.SALES_INSIGHTS'),
        },
      ];
    },
    tabParameter() {
      const selectedTab = Number(this.$route.query?.tab);
      return !Number.isNaN(selectedTab) && selectedTab >= 0 ? selectedTab : 0;
    },
  },
  mounted() {
    this.onTabChange(this.tabParameter, 'mounted');
  },
  methods: {
    onTabChange(selectedTabIndex, source) {
      if (source !== 'mounted') {
        this.mix_panel_clicked_sales_analytics_tab(
          selectedTabIndex === 0
            ? this.$t('ANALYTICS.HEADER.SALES_OVERVIEW')
            : this.$t('ANALYTICS.HEADER.SALES_INSIGHTS')
        );
      }
      this.$router.replace({ query: { tab: selectedTabIndex } });
      this.selectedTabIndex = selectedTabIndex;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tabs-pane {
  position: -webkit-sticky;
  position: sticky;
  background-color: $chat-background;
  top: $zero;
  z-index: 1000;
  margin-top: 7rem;
  padding-bottom: $space-six;
}
</style>
