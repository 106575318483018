export default {
  allSLAs: state => filters => {
    return state.slas.filter(sla => {
      // Filter by inboxId
      const inboxCondition = sla.conditions?.inbox_id ?? null;
      const inboxMatch = filters.inboxId
        ? inboxCondition.values.includes(filters.inboxId)
        : true;

      // Filter by searchTerm (compared with name, id, description)
      const searchTermLower = filters.searchTerm.toLowerCase();
      const searchMatch =
        sla.name.toLowerCase().includes(searchTermLower) ||
        String(sla.id).includes(searchTermLower) ||
        sla.description.toLowerCase().includes(searchTermLower);

      return inboxMatch && searchMatch;
    });
  },
  getUIFlags(state) {
    return state.uiFlags;
  },
};
