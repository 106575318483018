<template>
  <div class="content-box settings-box">
    <List />
  </div>
</template>
<script>
import List from './views/List';

export default {
  name: 'SLAHome',
  components: {
    List,
  },
  created() {
    this.$store.dispatch('sla/getSLAs');
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

.settings-box {
  position: relative;
  padding-top: $space-largest;
  padding-left: 0.1rem;
  overflow: hidden;
  height: 100%;

  .lime-card-1dp {
    padding: $zero;
  }

  .settings-container {
    height: 100%;
    margin: $zero;
  }
}
</style>
