<template>
  <span
    class="flex-row flex-align gap--smaller pd-left--smaller pd-right--smaller"
  >
    <span
      class="uppercase text-light-red"
      v-text="$t('CONVERSATION.DELETED')"
    />
    <span v-if="deletedByText" class="text-dark" v-text="deletedByText" />
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import { humanReadableDate } from 'dashboard/helper/time';

export default {
  props: {
    deletedBy: {
      type: [String, Number],
      required: true,
    },
    deletedAt: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getAgent: 'agents/getAgent',
    }),
    deletedByText() {
      if (this.deletedAt && this.deletedBy) {
        const user = this.getAgentName(this.deletedBy);
        const formattedTime = humanReadableDate(
          new Date(this.deletedAt * 1000)
        );

        return this.$t('CONVERSATION.DELETED_BY_USER_AT_TIME', {
          user,
          time: formattedTime,
        });
      }

      return null;
    },
  },
  methods: {
    getAgentName(agentId) {
      let agent = this.getAgent(agentId);

      if (!agent.name) {
        agent = { name: 'NA' };
      }

      return agent.name;
    },
  },
};
</script>
