<template>
  <component :is="dynamicCmpt" v-if="!loading" :data="data" />
  <box-skeleton v-else />
</template>
<script>
export default {
  components: {
    BoxSkeleton: {
      render(h) {
        return h('div', {
          attrs: { class: 'skeleton-animation skeleton-col' },
        });
      },
    },
  },
  props: {
    componentName: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dynamicCmpt: '',
      loading: true,
    };
  },
  computed: {
    componentLoader() {
      return () =>
        import(`dashboard/components/ui/cells/${this.componentName}`);
    },
  },
  created() {
    this.componentLoader()
      .then(() => {
        this.loading = false;
        this.dynamicCmpt = () => this.componentLoader();
      })
      .catch(() => {
        this.loading = false;
      });
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.skeleton-col {
  height: 5rem;
  min-width: 3.8rem;
}
</style>
