import Vue from 'vue';
import axios from 'axios';
import * as types from '../mutation-types';
import authAPI from 'dashboard/api/auth';
import actionCable from 'dashboard/helper/actionCable';
import { getInstance, auth0Configs } from 'dashboard/auth0';
import endPoints from 'dashboard/api/endPoints';
import createApiInstance from 'dashboard/helper/APIHelper';
import { setUser, redirectPostLogin } from '../utils/api';

const state = {
  currentUser: {
    id: null,
    account_id: null,
    channel: null,
    email: null,
    name: null,
    provider: null,
    uid: null,
    subscription: {
      state: null,
      expiry: null,
    },
  },
  currentAccountId: null,
};

// getters
export const getters = {
  getCurrentUserID(_state) {
    return _state.currentUser.id;
  },
  getUISettings(_state) {
    return _state.currentUser.ui_settings || {};
  },
  getCurrentUserAvailabilityStatus(_state) {
    return _state.currentUser.availability_status;
  },
  getCurrentAccountId(_state) {
    return _state.currentAccountId;
  },
  getCurrentRole(_state) {
    const accounts = JSON.parse(localStorage.getItem('userAccounts') || '[]');
    const [currentAccount = {}] = accounts.filter(
      account => account.id === _state.currentAccountId
    );
    return currentAccount.role;
  },
  getCurrentUser(_state) {
    return _state.currentUser;
  },
};

// actions
export const actions = {
  fetchCurrentUser: async ({ dispatch }) => {
    const apiInstance = createApiInstance();

    try {
      const response = await apiInstance.get(endPoints('profile').url);
      window.axios = apiInstance;

      return response.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('HD log error fetchCurrentUser', error);
      dispatch('logout');
    }

    return null;
  },
  login: async ({ commit, dispatch }, user) => {
    try {
      setUser(user);
      actionCable.init(Vue);
      commit(types.default.SET_CURRENT_USER);
      redirectPostLogin(user.account_id, user.accounts);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('HD log error login', error);
      dispatch('logout');
    }
  },

  register: async ({ commit, dispatch }, { user, params }) => {
    try {
      await window.axios.patch(`api/v1/accounts/${user.account_id}`, params);
      const updatedUser = await dispatch('fetchCurrentUser');

      setUser(updatedUser);
      actionCable.init(Vue);
      commit(types.default.SET_CURRENT_USER);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('HD log error register', error);
      dispatch('logout');
      const errorData = error.response?.data || {};
      throw errorData;
    }
  },

  setUser({ commit }) {
    const auth0 = getInstance();

    if (auth0.isAuthenticated) {
      commit(types.default.SET_CURRENT_USER);
    } else {
      commit(types.default.CLEAR_USER);
    }
  },

  logout: async ({ commit, state: $state }) => {
    try {
      await authAPI.updateAvailability({
        availability: 'offline',
        account_id: $state.currentAccountId,
      });
    } finally {
      commit(types.default.CLEAR_USER);
      authAPI.logout();
    }
  },

  updateProfile: async ({ commit }, params) => {
    try {
      const response = await authAPI.profileUpdate(params);
      setUser(response.data);
      commit(types.default.SET_CURRENT_USER);
    } catch (error) {
      const errorData = error.response?.data || {};
      throw errorData;
    }
  },

  requestPasswordChange: async (_, { email }) => {
    try {
      await axios.post(
        `https://${auth0Configs.config.domain}/dbconnections/change_password`,
        {
          client_id: auth0Configs.config.clientId,
          connection: 'Username-Password-Authentication',
          email,
        }
      );
    } catch (error) {
      // Ignore error

      throw new Error(error);
    }
  },

  updateUISettings: async ({ commit }, params) => {
    try {
      commit(types.default.SET_CURRENT_USER_UI_SETTINGS, params);
      const response = await authAPI.updateUISettings(params);
      setUser(response.data);
      commit(types.default.SET_CURRENT_USER);
    } catch (error) {
      // Ignore error
    }
  },

  updateAvailability: ({ commit, dispatch }, { availability, account_id }) => {
    authAPI.updateAvailability({ availability, account_id }).then(response => {
      const userData = response.data;
      const { id, availability_status: availabilityStatus } = userData;
      setUser(userData);
      commit(types.default.SET_CURRENT_USER);
      dispatch('agents/updatePresence', { [id]: availabilityStatus });
    });
  },

  setCurrentAccountId({ commit }, accountId) {
    commit(types.default.SET_CURRENT_ACCOUNT_ID, accountId);
  },

  setCurrentUserAvailabilityStatus({ commit, state: $state }, data) {
    if (data[$state.currentUser.id]) {
      commit(
        types.default.SET_CURRENT_USER_AVAILABILITY,
        data[$state.currentUser.id]
      );
    }
  },
};

// mutations
export const mutations = {
  [types.default.SET_CURRENT_USER_AVAILABILITY](_state, status) {
    Vue.set(_state.currentUser, 'availability_status', status);
  },
  [types.default.CLEAR_USER](_state) {
    _state.currentUser.id = null;
  },
  [types.default.SET_CURRENT_USER](_state) {
    const currentUser = {
      ...authAPI.getCurrentUser(),
    };

    Vue.set(_state, 'currentUser', currentUser);
  },
  [types.default.SET_CURRENT_USER_UI_SETTINGS](_state, { uiSettings }) {
    Vue.set(_state, 'currentUser', {
      ..._state.currentUser,
      ui_settings: {
        ..._state.currentUser.ui_settings,
        ...uiSettings,
      },
    });
  },
  [types.default.SET_CURRENT_ACCOUNT_ID](_state, accountId) {
    Vue.set(_state, 'currentAccountId', Number(accountId));
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
