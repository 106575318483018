<template>
  <apexchart
    ref="chart"
    width="100%"
    :height="chartHeight"
    type="line"
    :options="chartOptions"
    :series="series"
    @zoomed="onZoom"
  ></apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    chartHeight: {
      type: String,
      default: '280',
    },
    colors: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    toolbarEnable: {
      type: Boolean,
      default: true,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 1100,
    },
    labelAppend: {
      type: String,
      default: '',
    },
    showMarker: {
      type: Boolean,
      default: true,
    },
    toggleData: {
      type: Boolean,
      default: true,
    },
    highlightData: {
      type: Boolean,
      default: true,
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
    toolbarOffsetY: {
      type: Number,
      default: 15,
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          id: 'chart1',
          offsetX: 0,
          type: 'line',
          toolbar: {
            show: this.toolbarEnable,
            offsetX: 10,
            offsetY: this.toolbarOffsetY,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        tooltip: {
          fillSeriesColor: false,
          style: {
            fontSize: '13px',
            fontFamily: 'Lato',
          },
          x: {
            show: true,
            format: 'dd MMM',
            style: {
              background: '#6BAC1B',
            },
          },
          y: {
            formatter(value) {
              return Math.round(value * 100) / 100;
            },
          },
          marker: {
            show: this.showMarker,
          },
        },
        stroke: {
          width: 4,
          curve: 'smooth',
        },
        legend: {
          show: this.showLegend,
          position: 'top',
          horizontalAlign: 'left',
          showForSingleSeries: true,
          fontSize: '14px',
          fontFamily: 'Lato',
          fontWeight: 400,
          offsetX: -34,
          offsetY: 0,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            width: 16,
            height: 16,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 4,
            customHTML: undefined,
            onClick: undefined,
            offsetX: -5,
            offsetY: 3,
          },
          itemMargin: {
            horizontal: 20,
            vertical: 13,
          },
          onItemClick: {
            toggleDataSeries: this.toggleData,
          },
          onItemHover: {
            highlightDataSeries: this.highlightData,
          },
        },
        colors: this.colors,
        yaxis: {
          forceNiceScale: true,
          title: {
            text: this.labelAppend,
            style: {
              fontSize: '12px',
              fontWeight: 400,
              fontFamily: 'Lato',
              color: '#8c8c8c',
            },
          },
          labels: {
            formatter(value) {
              return value + '';
            },
            style: {
              fontSize: '13px',
              colors: ['#8c8c8c'],
              fontFamily: 'Lato',
            },
          },
        },
        xaxis: {
          type: 'dateime',
          // tickPlacement: 'between',
          // overwriteCategories: this.categories,
          dateTimeUTC: true,
          categories: this.categories,
          axisTicks: {
            show: false,
          },
          labels: {
            hideOverlappingLabels: true,
            trim: false,
            minHeight: 40,
            style: {
              fontSize: '13px',
              colors: '#8c8c8c',
              fontFamily: 'Lato',
            },
          },
        },
      };
    },
  },
  methods: {
    onZoom(_, { xaxis }) {
      if (xaxis) {
        this.$emit('zoom-button-clicked');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.table-title {
  font-size: $font-size-medium;
  line-height: 2.6rem;
  color: $neutral-grey-800;
  font-weight: $font-weight-medium;
  margin: $space-slab $zero;
}

.card-padding {
  padding: $space-two $space-slab;
}

.tooltip-position {
  position: absolute;
  top: $zero;
  right: $zero;
}
</style>
