/* global mixpanel */

import * as types from 'shared/constants/mixPanelEventType';
import { accountIdFromPathname } from './URLHelper';

export default function mixpanelEvent(event) {
  const validEvents = types.default[event];

  if (!validEvents) {
    throw new Error('Invalid mixpanel event type');
  }

  if (!window.mixpanel) {
    throw new Error('Mixpanel is not defined');
  }

  mixpanel.track(types.default[event]);
}

export const mixpanelEventWithProps = (event, props) => {
  const validEvents = types.default[event];

  if (!validEvents) {
    throw new Error('Invalid mixpanel event type');
  }

  if (!window.mixpanel) {
    throw new Error('Mixpanel is not defined');
  }

  mixpanel.track(types.default[event], props);
};

export const setupMixpanelUser = user => {
  const { name, email, role, accounts } = user;

  const { pathname } = window.location;
  const accountId = accountIdFromPathname(pathname || '');
  const currentAccount = accounts.find(item => item.id === accountId);

  if (!currentAccount) {
    throw new Error('Current account not found for mixpanel user');
  }

  const { name: accountName } = currentAccount;

  if (!name || !email || !role || !accountName) {
    throw new Error('Missing required user data for mixpanel user');
  }

  mixpanel.identify(email);
  mixpanel.people.set({
    $name: name,
    $email: email,
    role: role,
    account_id: accountId,
    account_name: accountName,
  });
};
