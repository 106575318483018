<template>
  <div v-if="!isDraggable" class="list-bottom">
    <span class="title-h6_m text-dark" v-text="$t('SLA.LIST.BOTTOM.TITLE')" />
    <woot-base-button variant="secondary" size="small" @click="onClickReorder">
      {{ $t('SLA.LIST.BOTTOM.CTA.REORDER') }}
    </woot-base-button>
  </div>
  <div
    v-else
    class="mg-top--small flex-row flex-align flex-justify--end gap--small"
  >
    <woot-base-button variant="tertiary-danger" size="small" @click="onCancel">
      {{ $t('SLA.LIST.BOTTOM.CTA.CANCEL') }}
    </woot-base-button>
    <woot-base-button size="small" @click="onSaveOrder">
      {{ $t('SLA.LIST.BOTTOM.CTA.SAVE') }}
    </woot-base-button>
  </div>
</template>
<script>
export default {
  props: {
    isDraggable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickReorder() {
      this.$emit('onReorder');
    },
    onSaveOrder() {
      this.$emit('onSaveOrder');
    },
    onCancel() {
      this.$emit('onCancel');
    },
  },
};
</script>
