<template>
  <div class="feedback flex-column gap--small">
    <div class="feedback-bubble feedback-head flex-row flex-align gap--slab">
      <icons
        name="file"
        size="medium"
        color="stroke-blue"
        :custom-style="{ strokeWidth: 2 }"
      />
      <span
        class="body-b2 text-dark"
        v-html="$t('CONVERSATION.GPT.FEEDBACK.TITLE')"
      />
    </div>
    <div class="flex-row flex-align flex-justify--between gap--smaller">
      <div
        class="feedback-btn flex-row flex-justify flex-align gap--one"
        :class="{ active: isNo }"
        @click="captureResponse($t('CONVERSATION.GPT.FEEDBACK.NO'))"
      >
        <icons
          name="dislike"
          size="semimedium"
          color="primary"
          :custom-style="{ strokeWidth: 2 }"
        />
        <span
          class="button-medium"
          v-text="$t('CONVERSATION.GPT.FEEDBACK.NO')"
        />
      </div>
      <div
        class="feedback-btn flex-row flex-justify flex-align gap--one"
        :class="{ active: isYes }"
        @click="captureResponse($t('CONVERSATION.GPT.FEEDBACK.YES'))"
      >
        <icons
          name="like"
          size="medium"
          color="primary"
          :custom-style="{ strokeWidth: 2 }"
        />
        <span
          class="button-medium"
          v-text="$t('CONVERSATION.GPT.FEEDBACK.YES')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';
import { FEATURES_FOR_FEEDBACK } from 'dashboard/constants';

export default {
  mixins: [alertMixin],
  props: {
    conversationId: { type: [Number, String], default: '' },
  },
  data() {
    return {
      activeBtn: '',
    };
  },
  computed: {
    ...mapGetters({
      accountDetails: 'accounts/getAccountDetails',
      currentChat: 'getSelectedChat',
    }),
    isYes() {
      return this.activeBtn === this.$t('CONVERSATION.GPT.FEEDBACK.YES');
    },
    isNo() {
      return this.activeBtn === this.$t('CONVERSATION.GPT.FEEDBACK.NO');
    },
  },
  created() {
    this.setSummaryFeedback();
  },
  methods: {
    setSummaryFeedback() {
      this.$store
        .dispatch('captureFeedback/getConversationFeedbacks', {
          account_id: this.accountDetails.id,
          source_id: this.conversationId,
        })
        .then(response => {
          this.activeBtn = this.isSummaryFeedbackCaptured(response);
        });
    },
    isSummaryFeedbackCaptured(feedbacks) {
      if (!feedbacks || !feedbacks?.length) return '';

      const featureLikability =
        feedbacks.filter(
          f => f.feature_name === FEATURES_FOR_FEEDBACK.AI_SUMMARY
        )[feedbacks.length - 1]?.feature_likability || false;

      return featureLikability
        ? this.$t('CONVERSATION.GPT.FEEDBACK.YES')
        : this.$t('CONVERSATION.GPT.FEEDBACK.NO');
    },
    captureResponse(response) {
      this.activeBtn = response;
      const featureLikability =
        response === this.$t('CONVERSATION.GPT.FEEDBACK.YES');

      this.$store
        .dispatch('captureFeedback/submitFeedbackForAISummary', {
          account_id: this.accountDetails.id,
          source_id: this.conversationId,
          feature_likability: featureLikability,
        })
        .then(() => {
          this.showAlert(
            this.$t('CONVERSATION.GPT.FEEDBACK.SUCCESS'),
            'success'
          );
        })
        .catch(() => {
          this.showAlert(this.$t('CONVERSATION.GPT.FEEDBACK.ERROR'), 'error');
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.feedback {
  &-head {
    border-bottom: 0.5px solid $neutral-grey-400;
    background-color: $alice-blue;
    border-radius: $space-slab $space-slab $zero $zero;
    padding: $space-slab;
  }

  &-bubble {
    @include chat-user-elevation;

    border-radius: $space-slab;
    max-width: 100%;
    text-align: left;
    word-wrap: break-word;
  }

  &-btn {
    @include chat-user-elevation;

    background-color: $color-white;
    border-radius: $border-radius-smaller;
    cursor: pointer;
    height: $space-four;
    width: 50%;
    padding: $space-small $space-slab;

    &:hover {
      background-color: $neutral-grey-200;
    }

    &.active {
      background-color: $neutral-grey-300;
      border: 2px solid $neutral-grey-400;
    }
  }
}
</style>
