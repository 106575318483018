<template>
  <div class="automation-tester">
    <test-mode-button
      class="AUTOMATION_TEST_MODE"
      :is-active="automationTestData.is_test"
      @onClick="showTestModal = true"
    />
    <test-modal
      :opened="showTestModal"
      :is-test-mode-active="automationTestData.is_test"
      :phone-numbers="automationTestData.test_numbers"
      @onUpdate="$emit('updateAutomationTestData', $event)"
      @onClose="showTestModal = false"
    />
  </div>
</template>
<script>
import TestModeButton from './TestModeButton';
import TestModal from './TestModal';

export default {
  components: { TestModeButton, TestModal },
  props: {
    automationTestData: {
      type: Object,
      default: () => ({ is_test: false, test_numbers: [] }),
    },
  },
  data() {
    return {
      showTestModal: false,
    };
  },
};
</script>
