<template>
  <transition name="network-notification-fade" tag="div">
    <div v-show="showNotification && bannerText">
      <div>
        <top-banner :variant="bannerVariant">
          <div class="flex-row flex-align flex-justify full-width">
            <span class="subtitle-s2">
              {{ bannerText }}
            </span>
            <woot-button
              v-if="canRefresh"
              :title="$t('NETWORK.BUTTON.REFRESH') || 'refresh'"
              variant="clear"
              size="small"
              color-scheme="warning"
              icon="arrow-clockwise"
              @click="refreshPage"
            />
            <woot-button
              variant="clear"
              size="small"
              color-scheme="warning"
              icon="dismiss"
              @click="closeNotification"
            />
          </div>
        </top-banner>
      </div>
    </div>
  </transition>
</template>

<script>
import { BUS_EVENTS } from 'shared/constants/busEvents';
import TopBanner from 'dashboard/components/ui/TopBanner';

export default {
  components: { TopBanner },
  data() {
    return {
      showNotification: !navigator.onLine,
      isDisconnected: false,
      isReconnecting: false,
      isReconnected: false,
      reconnectTimeout: null,
      RECONNECTED_BANNER_TIMEOUT: 2000,
    };
  },
  computed: {
    bannerText() {
      if (this.isReconnecting)
        return this.$t('NETWORK.NOTIFICATION.RECONNECTING');
      if (this.isReconnected)
        return this.$t('NETWORK.NOTIFICATION.RECONNECT_SUCCESS');
      if (this.isDisconnected) {
        return this.$t('NETWORK.NOTIFICATION.OFFLINE');
      }
      return null;
    },
    bannerVariant() {
      if (this.isReconnecting) {
        return 'warn';
      }
      if (this.isReconnected) {
        return 'success';
      }
      if (this.isDisconnected) {
        return 'danger';
      }
      return null;
    },
    iconName() {
      return this.isReconnected ? 'wifi' : 'wifi-off';
    },
    canRefresh() {
      return !this.isReconnecting && !this.isReconnected;
    },
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    bus.$on(BUS_EVENTS.WEBSOCKET_DISCONNECT, this.updateWebsocketStatus);
    bus.$on(
      BUS_EVENTS.WEBSOCKET_RECONNECT_COMPLETED,
      this.handleReconnectionCompleted
    );
    bus.$on(BUS_EVENTS.WEBSOCKET_RECONNECT, this.handleReconnecting);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    bus.$off(BUS_EVENTS.WEBSOCKET_DISCONNECT, this.updateWebsocketStatus);
    bus.$off(
      BUS_EVENTS.WEBSOCKET_RECONNECT_COMPLETED,
      this.handleReconnectionCompleted
    );
    bus.$off(BUS_EVENTS.WEBSOCKET_RECONNECT, this.handleReconnecting);

    clearTimeout(this.reconnectTimeout);
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
    closeNotification() {
      this.showNotification = false;
      this.isReconnected = false;
      clearTimeout(this.reconnectTimeout);
    },
    // isInAnyOfTheRoutes(routeName) {
    //   return (
    //     this.isAConversationRoute(routeName, true) ||
    //     this.isAInboxViewRoute(routeName, true) ||
    //     this.isNotificationRoute(routeName, true)
    //   );
    // },
    updateWebsocketStatus() {
      this.isDisconnected = true;
      this.showNotification = true;
    },
    handleReconnectionCompleted() {
      this.isDisconnected = false;
      this.isReconnecting = false;
      this.isReconnected = true;
      this.showNotification = true;
      this.reconnectTimeout = setTimeout(
        this.closeNotification,
        this.RECONNECTED_BANNER_TIMEOUT
      );
    },
    handleReconnecting() {
      // custom reconnection handling
      // if (this.isInAnyOfTheRoutes(this.$route.name)) {
      //   this.isReconnecting = true;
      //   this.isReconnected = false;
      //   this.showNotification = true;
      // }
      this.handleReconnectionCompleted();
    },
    updateOnlineStatus(event) {
      if (event.type === 'offline') {
        this.showNotification = true;
      } else if (event.type === 'online' && !this.isDisconnected) {
        this.handleReconnectionCompleted();
      }
    },
  },
};
</script>

<style>
/*  */
</style>
