<template>
  <div class="flex-row flex-justify--between gap--normal">
    <div class="lime-card-1dp full-width">
      <div style="position: relative">
        <span
          class="table-title"
          v-text="$t('ANALYTICS.SHOPIFY_ANALYTICS.REVENUE.TOTAL_ORDERS.LEGEND')"
        />
        <div class="line-chart-legend title-h5">
          <div class="green-icon" />
          {{ $t('ANALYTICS.SHOPIFY_ANALYTICS.REVENUE.TOTAL_ORDERS.LABEL') }}
        </div>
        <line-chart
          v-if="!uiFlags.fetchingPeriodicOrdersCount"
          chart-height="230"
          :series="chartSeries"
          :categories="chartCategories"
          :colors="['#6BAC1B']"
          :show-marker="false"
          :toggle-data="false"
          :highlight-data="false"
          :show-legend="false"
          :toolbar-offset-y="-35"
        />
        <line-chart-skeleton v-else chart-height="8rem" />
      </div>
    </div>
    <div
      v-if="!uiFlags.fetchingCodPrepaidSplit"
      class="lime-card-1dp flex-row flex-justify flex-align pie-chart-mr"
    >
      <pie-chart
        title="Response Split"
        :series="pieSeries"
        :colors="['#6BAC1B', '#1b83ac']"
        :labels="pieLabels"
        :height="180"
        legend-position="right"
        :legend-offset-x="0"
        :legend-offset-y="-30"
        :marker-offset-x="0"
        :marker-offset-y="1"
        :chart-offset-x="0"
        :chart-offset-y="0"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import LineChart from 'dashboard/components/widgets/chart/LineChart';
import LineChartSkeleton from 'dashboard/components/LineChartSkeleton';
import PieChart from 'dashboard/components/widgets/chart/PieChart';

import timeMixin from 'dashboard/mixins/time';
import { ORDER_TYPES } from './constants';

export default {
  components: { LineChart, LineChartSkeleton, PieChart },
  mixins: [timeMixin],
  computed: {
    ...mapGetters({
      ordersPeriodicCount: 'deepShopifyAnalytics/getPeriodicOrdersCount',
      codPrepaidSplit: 'deepShopifyAnalytics/getCodPrepaidSplit',
      uiFlags: 'deepShopifyAnalytics/getUiFlags',
    }),
    chartSeries() {
      return [
        {
          name: this.$t(
            'ANALYTICS.SHOPIFY_ANALYTICS.REVENUE.TOTAL_ORDERS.LEGEND'
          ),
          data: this.ordersPeriodicCount.map(item => item.count),
        },
      ];
    },
    chartCategories() {
      return this.ordersPeriodicCount.map(item => this.dateToWords(item.date));
    },
    pieSeries() {
      return this.codPrepaidSplit.map(item => item.order_count);
    },
    pieLabels() {
      return [
        this.$t(`ANALYTICS.SHOPIFY_ANALYTICS.REVENUE.SPLIT.${ORDER_TYPES.COD}`),
        this.$t(
          `ANALYTICS.SHOPIFY_ANALYTICS.REVENUE.SPLIT.${ORDER_TYPES.PREPAID}`
        ),
      ];
    },
  },
};
</script>
