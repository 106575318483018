<template>
  <div v-if="!uiFlags.isConfiguring">
    <div class="text-align mg-bottom--larger">
      <div class="flex-row flex-justify flex-align gap--small mg-bottom">
        <icons
          name="tickFilled"
          size="medium"
          color="green"
          :show-title="false"
        />
        <span
          class="title-h1 text-dark"
          v-html="$t('ONBOARDING.WHATSAPP.WABA_INTEGRATION_COMPLETE.HEADING')"
        />
      </div>
      <span
        class="body-b2 text-light"
        v-html="$t('ONBOARDING.WHATSAPP.WABA_INTEGRATION_COMPLETE.SUB_HEADING')"
      />
    </div>
    <div class="flex-row flex-justify--end">
      <connect-button
        class="navigation-button"
        :label="$t('ONBOARDING.WHATSAPP.WABA_INTEGRATION_COMPLETE.CONTINUE')"
        :callback="configureChannel"
        :query-parameters="{
          redirect_url: setRedirectUrl(),
          state: inbox.phone_number,
        }"
      />
    </div>
  </div>
  <loading-state
    v-else
    class="loader-cmpt"
    :message="$t('INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.LOADING')"
  />
</template>
<script>
import { mapGetters } from 'vuex';

import ConnectButton from 'dashboard/routes/dashboard/settings/inbox/components/360Dialog/ConnectButton';
import LoadingState from 'dashboard/components/ui/LoadingState';

import { ONBOARDING_SCREENS } from 'dashboard/constants';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: { ConnectButton, LoadingState },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'threeSixtyDialog/getUIFlags',
    }),
  },
  inject: ['navigateTo'],
  methods: {
    configureChannel({ client, channels }) {
      const payload = {
        three_sixty_channel_id: channels[0],
        three_sixty_client_id: client,
        phone_number: this.inbox.phone_number,
      };

      this.$store
        .dispatch('threeSixtyDialog/configureChannel', payload)
        .then(response => {
          this.$store.dispatch('inboxes/updateInboxWithoutOverriding', {
            id: this.inbox.id,
            ...response,
          });

          this.navigateTo(ONBOARDING_SCREENS.FINISH_SETUP.key);
        })
        .catch(error => {
          this.navigateTo(ONBOARDING_SCREENS.CONFIGURATION_ERROR.key);

          this.showAlert(
            error.response.data.message ||
              error.response.data.error ||
              this.$t('INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.API.ERROR_MESSAGE'),
            'error',
            5000
          );
        });
    },
    setRedirectUrl() {
      const redirectUrl = window.location.origin + this.$route.path;

      return redirectUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.content-margin {
  margin-bottom: $space-medium;
}

ol {
  > li {
    list-style-type: disc;
    margin-top: $space-smaller;
  }
}

.reference-box {
  max-width: 100%;
}

.navigation-button {
  width: $space-slab * 10;
}

.loader-cmpt {
  height: 20vh;
}
</style>
