<template>
  <div class="small-12 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.MAYTAPI.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.MAYTAPI.DESC')"
    />
    <form @submit.prevent="createChannel">
      <div class="settings-sub-menu-content--larger mg-bottom--jumbo">
        <woot-input
          v-model.trim="channelName"
          required
          auto-focus
          :class="{ error: $v.channelName.$error }"
          :has-error="$v.channelName.$error"
          :error="$t('INBOX_MGMT.ADD.MAYTAPI.CHANNEL_NAME.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.MAYTAPI.CHANNEL_NAME.LABEL')"
          :placeholder="$t('INBOX_MGMT.ADD.MAYTAPI.CHANNEL_NAME.PLACEHOLDER')"
          @blur="$v.channelName.$touch"
        />

        <woot-input
          v-model.trim="phoneNumber"
          required
          :class="{ error: $v.phoneNumber.$error }"
          :has-error="$v.phoneNumber.$error"
          :error="$t('INBOX_MGMT.ADD.MAYTAPI.PHONE_NUMBER.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.MAYTAPI.PHONE_NUMBER.LABEL')"
          :placeholder="$t('INBOX_MGMT.ADD.MAYTAPI.PHONE_NUMBER.PLACEHOLDER')"
          @blur="$v.phoneNumber.$touch"
        />

        <woot-input
          v-model.trim="phoneId"
          required
          :class="{ error: $v.phoneId.$error }"
          :has-error="$v.phoneId.$error"
          :error="$t('INBOX_MGMT.ADD.MAYTAPI.PHONE_ID.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.MAYTAPI.PHONE_ID.LABEL')"
          :placeholder="$t('INBOX_MGMT.ADD.MAYTAPI.PHONE_ID.PLACEHOLDER')"
          @blur="$v.phoneId.$touch"
        />

        <woot-input
          v-model.trim="productId"
          required
          :class="{ error: $v.productId.$error }"
          :has-error="$v.productId.$error"
          :error="$t('INBOX_MGMT.ADD.MAYTAPI.PRODUCT_ID.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.MAYTAPI.PRODUCT_ID.LABEL')"
          :placeholder="$t('INBOX_MGMT.ADD.MAYTAPI.PRODUCT_ID.PLACEHOLDER')"
          @blur="$v.productId.$touch"
        />

        <woot-input
          v-model.trim="token"
          required
          :class="{ error: $v.token.$error }"
          :has-error="$v.token.$error"
          :error="$t('INBOX_MGMT.ADD.MAYTAPI.TOKEN.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.MAYTAPI.TOKEN.LABEL')"
          :placeholder="$t('INBOX_MGMT.ADD.MAYTAPI.TOKEN.PLACEHOLDER')"
          @blur="$v.token.$touch"
        />
      </div>
      <div class="medium-12 columns settings-sub-menu-bottom-nav">
        <woot-base-button
          variant="secondary"
          @click.prevent="() => $router.go(-1)"
        >
          Back
        </woot-base-button>
        <woot-base-button :loading="uiFlags.isCreating" type="submit">
          Next
        </woot-base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import { required } from 'vuelidate/lib/validators';
import validatePhoneNumber from '../validations';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';

const isNumber = (value = '') => /^([1-9]\d*)$/.test(value);

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      phoneId: '',
      productId: '',
      channelName: '',
      phoneNumber: '',
      token: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    channelName: { required },
    phoneNumber: { required, ...validatePhoneNumber.phone },
    productId: { required },
    token: { required },
    phoneId: { required, isNumber },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('AGENT_MGMT.FORM_ERROR'), 'error');
        return;
      }

      try {
        const maytapiChannel = await this.$store.dispatch(
          'inboxes/createMaytapiChannel',
          {
            maytapi_channel: {
              name: this.channelName,
              phone_id: this.phoneId,
              product_id: this.productId,
              token: this.token,
              phone_number: this.phoneNumber,
            },
          }
        );

        const {
          id: inbox_id,
          channel_type,
          name: channel_name,
        } = maytapiChannel;
        this.mix_panel_clicked_create_inbox_successful(
          'WhatsApp',
          channel_type
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id,
          },
          query: { channel_type, channel_name },
        });
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.MAYTAPI.API.ERROR_MESSAGE'),
          'error'
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.form-label {
  padding: $zero $space-small $space-one;
  color: $neutral-grey-800;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: 22px;
}

.content-margin {
  margin-bottom: 2.4rem;
}

.medium-12 {
  .button {
    float: right;
  }
}
</style>
