<template>
  <div class="content-box settings-box">
    <div class="settings-container gap--normal">
      <div class="custom-scroll lime-card-1dp">
        <page-header
          :header-title="
            $t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.TITLE')
          "
          class="page-header"
        >
          <template v-slot:icon>
            <div class="crm-img-container">
              <div class="centered-image">
                <img
                  :src="
                    '/dashboard/images/integrations/' +
                      $t(
                        'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.THUMBNAIL'
                      )
                  "
                />
              </div>
            </div>
          </template>
          <reference-box
            :to="$t('INTEGRATION_SETTINGS.GITBOOK_URL') + 'crm/freshdesk'"
            :content="
              'Know more about ' +
                $t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.TITLE') +
                ' Integration'
            "
            type="document"
          />
        </page-header>

        <div class="crm-integration flex-column">
          <woot-loading-state v-if="isLoading" message="Fetching crm details" />
          <div :class="isLoading ? 'disable-container' : ''">
            <div class="crm-container">
              <div class="crm-body-container">
                <form class="flex-column-center" @submit.prevent="crmConnect()">
                  <woot-input
                    v-model.trim="domain"
                    :label="
                      $t(
                        'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.DOMAIN_LABEL'
                      )
                    "
                    :class="{ error: $v.domain.$error }"
                    class="crm-input"
                    :placeholder="
                      $t(
                        'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.DOMAIN_PLACEHOLDER'
                      )
                    "
                    @input="$v.domain.$touch"
                  />
                  <woot-input
                    v-model.trim="widgetId"
                    :label="
                      $t(
                        'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.WIDGET_ID_LABEL'
                      )
                    "
                    :class="{ error: $v.widgetId.$error }"
                    class="crm-input"
                    :placeholder="
                      $t(
                        'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.WIDGET_ID_PLACEHOLDER'
                      )
                    "
                    @input="$v.widgetId.$touch"
                  />
                  <woot-input
                    v-model.trim="apiKey"
                    :label="
                      $t(
                        'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.API_KEY_LABEL'
                      )
                    "
                    class="crm-input"
                    :placeholder="
                      $t(
                        'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.API_KEY_PLACEHOLDER'
                      )
                    "
                  />
                  <woot-input
                    v-model.trim="password"
                    :label="
                      $t(
                        'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.PASSWORD_LABEL'
                      )
                    "
                    class="crm-input"
                    :placeholder="
                      $t(
                        'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.PASSWORD_PLACEHOLDER'
                      )
                    "
                  />
                  <woot-button
                    type="submit"
                    class="button nice crm-button"
                    :disabled="
                      $v.domain.$invalid ||
                        $v.widgetId.$invalid ||
                        $v.apiKey.$invalid ||
                        $v.password.$invalid
                    "
                  >
                    {{
                      isLimekitCrmConnected
                        ? 'Update'
                        : $t('INTEGRATION_SETTINGS.CRM.CONNECT.BUTTON_TEXT')
                    }}
                  </woot-button>

                  <a
                    href="https://developers.freshdesk.com/widget-api/#introduction"
                    target="_blank"
                    class="steps limechat-store"
                  >
                    <icons name="rocketFilled" size="medium" color="green" />
                    <span class="text-margin">
                      How to find out Freshdesk Widget ID?
                    </span>
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import { required } from 'vuelidate/lib/validators';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';
import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';

export default {
  components: {
    ReferenceBox,
    PageHeader,
  },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      isLoading: false,
      domain: '',
      integrationName: 'freshdesk',
      apiKey: '',
      password: '',
      widgetId: '',
      isLimekitCrmConnected: false,
    };
  },
  validations: {
    domain: {
      required,
    },
    widgetId: {
      required,
    },
    apiKey: {
      required,
    },
    password: {
      required,
    },
  },
  mounted() {
    this.getCrmConnectionInfo();
  },
  methods: {
    crmConnect() {
      const data = {
        domain: this.domain,
        api_key: this.apiKey,
        password: this.password,
        widget_id: this.widgetId,
      };
      this.$store
        .dispatch('crm/createLimekitCrmConnection', {
          integration_name: this.integrationName,
          integration_data: data,
        })
        .then(response => {
          let integration_data = JSON.parse(response.data.data);
          if (response.data && integration_data.integration_connection) {
            this.isLimekitCrmConnected = true;
            let freshdesk = integration_data.integration_details;
            this.widgetId = response.data.freshdesk_widget_id;
            if (!this.widgetId) {
              this.showAlert(
                'Widget ID not found. Please update widget ID.',
                'warning'
              );
            } else {
              this.mix_panel_connection_successful_settings_integrations(
                'freshdesk'
              );
              this.showAlert('CRM Fully connected.', 'success');
            }
            this.setData(freshdesk);
          } else {
            this.showAlert('You are not connected', 'error');
          }
        })
        .catch(() => {
          this.showAlert('Some unknown error', 'error');
        });
    },
    getCrmConnectionInfo() {
      this.$store
        .dispatch('crm/fetchLimekitCrmConnection', {
          integration_name: this.integrationName,
        })
        .then(response => {
          let integration_data = JSON.parse(response.data.data);
          if (response.data && integration_data.integration_connection) {
            this.isLimekitCrmConnected = true;
            let freshdesk = integration_data.integration_details;
            this.widgetId = response.data.freshdesk_widget_id;
            if (!this.widgetId) {
              this.showAlert(
                'Widget ID not found. Please update widget ID.',
                'warning'
              );
            } else {
              this.showAlert('Integration fully connected', 'success');
            }
            this.setData(freshdesk);
          } else {
            this.showAlert('You are not connected', 'error');
          }
        })
        .catch(() => {
          this.showAlert('Some unknown error', 'error');
        });
    },
    setData(data) {
      this.domain = data.domain;
      this.apiKey = data.api_key;
      this.password = data.password;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
.crm-integration {
  justify-content: center;
}

.disable-container {
  pointer-events: none;
  opacity: 0.3;
}

.settings-box {
  padding-top: $space-largest;
  padding-left: 0.1rem;
  overflow: hidden;

  .settings-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    .lime-card-1dp {
      flex-grow: 1;
      padding: $zero;
      padding-bottom: $space-two;
    }
  }
}

.crm-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.crm-body-container {
  width: 80%;
}

.crm-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.centered-image {
  height: 32px;
  width: 32px;
}

.heading {
  text-align: center;
  font-size: $font-size-medium;
  margin: $space-normal $zero;
  font-weight: $font-weight-medium;
}

.steps {
  text-align: center;
  font-size: $font-size-small;
  margin: $space-micro $space-larger;
}

.question {
  color: $neutral-grey-600;
  margin: $space-two $space-larger;
}

.limechat-store {
  color: $pg-1-500;
  margin: $space-two $space-larger;
  display: flex;
  justify-content: center;
}

.text-margin {
  margin: $space-micro;
}

.crm-button {
  margin-top: $space-normal;
  margin-bottom: $space-one;

  padding: $space-slab $space-larger;
}

.error {
  border: 1px solid $warn-red-400;
}

.crm-input {
  margin-top: $space-one;
  margin-bottom: $space-one;
  width: 300px;
}

.page-header {
  margin-bottom: $space-two;
}
</style>
