<template>
  <div class="item-container">
    <div v-for="item in order" :key="item.id" class="item-body">
      <span v-if="item.name" class="item-body--title">
        {{ `${item.name}` }}
      </span>
      <div class="item-body--sub flex-space-between">
        <span>{{ `Quantity ${item.quantity}` }}</span>
        <span
          style="color: black"
          v-html="`${accountCurrency} ${item.price}`"
        ></span>
      </div>
    </div>
    <div class="item-pricing">
      <div class="flex-space-between item-body--sub">
        <span>Shipping charges</span>
        <span v-html="`${accountCurrency} ${shippingCharges}`"></span>
      </div>
      <div class="flex-space-between item-body--sub">
        <span>{{ `Tax @ ${totalTax.rate}% ${totalTax.title}` }}</span>
        <span v-html="`${accountCurrency} ${totalTax.tax}`"></span>
      </div>
      <div class="flex-space-between item-body--sub">
        <span>Discount</span>
        <span v-html="`-${accountCurrency} ${totalDiscount}`"></span>
      </div>
    </div>
    <div
      class="item-footer"
      v-html="`Total Price ${accountCurrency} ${amount}`"
    ></div>
  </div>
</template>

<script>
/* eslint-disable dot-notation */
import accountMixin from '../../../../../mixins/account';
import { mapGetters } from 'vuex';

export default {
  mixins: [accountMixin],
  props: {
    order: {
      type: Array,
      default: () => [],
    },
    shippingCharges: {
      type: String,
      default: '0.00',
    },
    amount: {
      type: String,
      default: '0',
    },
    subTotal: {
      type: String,
      default: '0',
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    totalDiscount() {
      let discount = 0;
      this.order.forEach(item => {
        if (item['discount_allocations']?.length)
          discount += +item.discount_allocations[0].amount;
      });

      return discount;
    },
    totalTax() {
      let tax = 0;
      let rate = 0;
      let title = '';
      this.order.forEach(item => {
        if (item.taxable && item['tax_lines'] && item['tax_lines'].length) {
          rate = Number(item.tax_lines[0].rate) * 100;
          title = item.tax_lines[0].title;
          tax = Number(item.tax_lines[0].price);
        }
      });

      return { tax, rate, title };
    },
    accountCurrency() {
      return this.$store.getters['accounts/getAccountCurrency'](this.accountId);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.item-container {
  background: $pg-light-20;
  border-radius: $border-radius;
  padding: $space-slab;
  margin-bottom: $space-normal;

  .item-body {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: $space-slab;

    &:last-child {
      margin-bottom: $space-small;
    }

    .item-body--title {
      font-size: $font-size-small;
      line-height: 2.2rem;
      color: $neutral-grey-800;
      word-break: break-word;
    }
  }

  .item-pricing {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-top: 1px dashed $neutral-grey-600;
    padding: $space-small $zero $space-smaller;
  }

  .item-footer {
    display: flex;
    width: 100%;
    font-size: $font-size-default;
    line-height: $space-medium;
    color: $neutral-grey-800;
    justify-content: flex-end;
  }
}

.item-body--sub {
  font-size: $font-size-mini;
  line-height: $space-two;
  color: $neutral-grey-600;
}
</style>
