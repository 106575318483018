import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentUserRole: 'getCurrentRole',
    }),
    isAgent() {
      return this.currentUserRole === 'agent';
    },
  },
};
