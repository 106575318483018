<template>
  <div
    v-show="$refs.upload"
    :style="[dropZoneHeight]"
    class="dropzone flex-column-center"
    :class="$refs.upload && $refs.upload.dropActive ? 'dropzone--active' : null"
  >
    <span style="margin-bottom: 0.8rem" class="dropzone--title">
      <img
        v-if="imgIcon"
        src="~/assets/images/excel.svg"
        class="mg-right--small"
      />
      <icons v-else :name="iconName" size="mediumlarge" color="warning" />
    </span>
    <span
      v-if="$refs.upload && !$refs.upload.dropActive"
      class="dropzone--title"
    >
      {{ title || $t('CONVERSATION.REPLYBOX.DROP_FILES.TITLE') }}
    </span>
    <span v-else class="dropzone--title">
      {{ $t('CONVERSATION.REPLYBOX.DROP_FILES.DROP_ACTIVE') }}
    </span>
    <span class="flex-column-center">
      <span
        v-if="showAttachmentButton && $refs.upload && !$refs.upload.dropActive"
        class="dropzone--title separator"
      >
        {{ $t('CONVERSATION.REPLYBOX.DROP_FILES.SEPERATOR_TEXT') }}
      </span>
      <file-upload
        ref="upload"
        v-model="files"
        :multiple="multiFileSupport"
        :drop="true"
        :drop-directory="dropDirectory"
        :size="sizeLimit"
        :accept="acceptFormat"
        @input-file="uploadFile"
      >
        <woot-primary-button
          v-if="
            showAttachmentButton && $refs.upload && !$refs.upload.dropActive
          "
          :name="buttonTitle || $t('CONVERSATION.REPLYBOX.DROP_FILES.BUTTON')"
          size="small"
        />
      </file-upload>
    </span>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component';
export default {
  components: {
    FileUpload,
  },
  props: {
    imgIcon: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    buttonTitle: {
      type: String,
      default: null,
    },
    iconName: {
      type: String,
      default: 'clip',
    },
    sizeLimit: {
      type: Number,
      default: 4096 * 4096,
    },
    acceptFormat: {
      type: String,
      default:
        'image/*, application/pdf, audio/mpeg, video/mp4, audio/ogg, .zip',
    },
    dropDirectory: {
      type: Boolean,
      default: false,
    },
    showAttachmentButton: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
    multiFileSupport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: [],
      fileData: {
        addImageData: {},
        uploadFileData: {},
        files: this.files,
      },
    };
  },
  computed: {
    dropZoneHeight() {
      if (this.height === 'auto') return {};

      return { height: `${this.height}px` };
    },
  },
  beforeUnmount() {
    this.$refs.upload.clear();
  },
  methods: {
    uploadFile(newFile, oldFile) {
      this.$emit('fileData', this.files, newFile, oldFile);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.dropzone {
  justify-content: center;
  width: 100%;
  padding: $space-normal;
  background: $neutral-white;
  border: 2px dashed $neutral-grey-600;
  border-radius: $border-radius;
  margin-bottom: $space-micro;

  &--title {
    pointer-events: none;
    font-size: $font-size-small;
    color: $neutral-grey-600;
    line-height: 2.2rem;
  }

  &--active {
    background-color: $neutral-grey-200;
  }

  .file-uploads ::v-deep label {
    cursor: pointer;
  }
}

.separator {
  margin: $space-normal;
}
</style>
