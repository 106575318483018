<template>
  <div class="columns content-box settings-box">
    <div class="settings-container column custom-scroll lime-card-1dp mr-right">
      <div class="small-12 columns">
        <page-header
          :header-title="$t('INTEGRATION_SETTINGS.HEADER')"
          :header-content="$t('INTEGRATION_SETTINGS.SUB_HEADER')"
        />
        <!-- crms -->
        <div class="channels">
          <div
            class="title-h6 text-dark mg-bottom--small"
            v-text="$t('INTEGRATION_SETTINGS.CRM.TITLE')"
          />
          <div class="row">
            <div v-for="(_, crm) in AVAILABLE_CRMS" :key="crm.ID">
              <integration
                :integration-id="
                  $t(`INTEGRATION_SETTINGS.CRM.INTEGRATIONS.${crm}.ID`)
                "
                :integration-logo="
                  $t(`INTEGRATION_SETTINGS.CRM.INTEGRATIONS.${crm}.THUMBNAIL`)
                "
                :integration-name="
                  $t(`INTEGRATION_SETTINGS.CRM.INTEGRATIONS.${crm}.TITLE`)
                "
                :integration-action="
                  $t(`INTEGRATION_SETTINGS.CRM.INTEGRATIONS.${crm}.ACTION`)
                "
                :integration-enabled="isCrmEnabled(crm)"
              />
            </div>
          </div>
        </div>
        <!-- logistics -->
        <div class="channels">
          <div
            class="title-h6 text-dark mg-bottom--small"
            v-text="$t('INTEGRATION_SETTINGS.LOGISTICS.TITLE')"
          />
          <div class="row">
            <div
              v-for="crm in $t('INTEGRATION_SETTINGS.LOGISTICS.INTEGRATIONS')"
              :key="crm.ID"
            >
              <integration
                :integration-id="crm.ID"
                :integration-logo="crm.THUMBNAIL"
                :integration-name="crm.TITLE"
                :integration-action="crm.ACTION"
                :integration-enabled="isLogisticsEnabled(crm)"
              />
            </div>
          </div>
        </div>
        <!-- store front -->
        <div v-if="isDeepShopifyEnabled" class="channels">
          <div
            class="title-h6 text-dark mg-bottom--small"
            v-text="$t('INTEGRATION_SETTINGS.STOREFRONT')"
          />
          <div class="row">
            <integration
              integration-id="shopify-permissions"
              integration-logo="shopify.svg"
              integration-name="Shopify"
              integration-action="shopify-permissions"
              :integration-enabled="true"
            />
          </div>
        </div>
        <!-- others -->
        <div class="channels">
          <div
            class="title-h6 text-dark mg-bottom--small"
            v-text="$t('INTEGRATION_SETTINGS.OTHERS')"
          />
          <div v-if="integrationsList.length" class="row">
            <div v-for="item in integrationsList" :key="item.id">
              <integration
                :integration-id="item.id"
                :integration-logo="item.logo"
                :integration-name="item.name"
                :integration-description="item.description"
                :integration-action="item.id"
              />
            </div>
          </div>
          <div
            v-else
            class="row integration-card-skeleton skeleton-animation"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';
import Integration from './Integration';

import { AVAILABLE_CRMS } from './constants';

export default {
  components: {
    PageHeader,
    Integration,
  },
  data() {
    return {
      enabledLogistics: [],
      AVAILABLE_CRMS,
    };
  },
  computed: {
    ...mapGetters({
      records: 'integrations/getIntegrations',
      accountId: 'getCurrentAccountId',
      isDeepShopifyEnabled: 'accounts/getDeepShopifyEnabledFlag',
      enabledCrms: 'crm/getEnabledCrms',
    }),
    integrationsList() {
      return this.records;
    },
  },
  created() {
    axios.all([
      this.$store.dispatch('integrations/get'),
      this.$store.dispatch('crm/fetchEnabledCrms'),
    ]);
    this.$store.dispatch('getEnabledLogistics').then(data => {
      this.enabledLogistics = data.enabled_logistics;
      this.$store.dispatch(
        'logistics/setEnabledLogistics',
        this.enabledLogistics
      );
    });
  },
  methods: {
    isCrmEnabled(crm) {
      return !!this.enabledCrms.find(item => item === crm.ID);
    },
    isLogisticsEnabled(logistic) {
      return !!this.enabledLogistics.find(item => item === logistic.ID);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-box {
  padding-top: $space-largest;
  padding-left: 0.1rem;
  overflow: hidden;

  .lime-card-1dp {
    padding: $zero;
  }

  .settings-container {
    height: 100%;
    margin: $zero;
  }
}

.channels {
  padding: $space-slab $space-medium $zero;
}
</style>
