<template>
  <div class="column content-box settings-box no-padding">
    <woot-tertiary-button
      front-icon="addFilled"
      layer-fill="white"
      :name="$t('INTEGRATION_SETTINGS.WEBHOOK.HEADER_BTN_TXT')"
      :custom-class="'settings-button'"
      @click="openAddPopup()"
    />

    <div class="settings-container">
      <div class="column settings-main-menu">
        <p
          v-if="!uiFlags.fetchingList && !records.length"
          class="no-items-error-message"
        >
          {{ $t('INTEGRATION_SETTINGS.WEBHOOK.LIST.404') }}
        </p>
        <woot-loading-state
          v-if="uiFlags.fetchingList"
          :message="$t('INTEGRATION_SETTINGS.WEBHOOK.LOADING')"
        />

        <table
          v-if="!uiFlags.fetchingList && records.length"
          class="woot-table"
        >
          <thead>
            <th
              v-for="thHeader in $t(
                'INTEGRATION_SETTINGS.WEBHOOK.LIST.TABLE_HEADER'
              )"
              :key="thHeader"
              :style="getComputedStyle(thHeader)"
            >
              {{ thHeader }}
            </th>
          </thead>
          <tbody>
            <tr v-for="webHookItem in records" :key="webHookItem.id">
              <td>
                <div>
                  {{ webHookItem.url }}
                </div>
              </td>
              <td
                class="flex-row flex-justify--start"
                style="margin: 1.2rem 2rem;"
              >
                <div
                  v-if="webHookItem.status === 'inactive'"
                  v-tooltip="webHookItem.unsubscription_reason"
                >
                  <icons
                    name="error"
                    color="danger"
                    size="semimedium"
                    :show-title="false"
                  />
                </div>
                <div v-else>
                  <icons
                    name="checked"
                    color="green"
                    size="medium"
                    :show-title="false"
                  />
                </div>
              </td>
              <td>
                <div class="button-wrapper">
                  <woot-base-button
                    tag="span"
                    variant="tertiary"
                    @click="openEditPopup(webHookItem)"
                  >
                    <icons
                      title="edit"
                      name="editRound"
                      size="medium"
                      color="textlightgrey"
                    />
                  </woot-base-button>
                  <woot-base-button
                    tag="span"
                    variant="tertiary"
                    @click="openDeletePopup(webHookItem)"
                  >
                    <icons name="delete" size="medium" color="textlightgrey" />
                  </woot-base-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <settings-side-card image="inbox">
        <span class="info-card-header">
          {{ $t('INTEGRATION_SETTINGS.WEBHOOK.SIDEBAR_QUES') }}
        </span>
        <span
          class="info-card-content"
          v-html="
            useInstallationName($t('INTEGRATION_SETTINGS.WEBHOOK.SIDEBAR_TXT'))
          "
        />
      </settings-side-card>
    </div>

    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <new-webhook :on-close="hideAddPopup" />
    </woot-modal>

    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-webhook
        :id="selectedWebHook.id"
        :inboxes="selectedWebHook.inboxes"
        :show.sync="showEditPopup"
        :editurl="selectedWebHook.url"
        :editsubscriptions="selectedWebHook.subscriptions"
        :editmessagetypes="selectedWebHook.message_types"
        :on-close="hideEditPopup"
      />
    </woot-modal>

    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.TITLE')"
      :message="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.YES')"
      :reject-text="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.NO')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import NewWebhook from './New';
import EditWebhook from './Edit';
import SettingsSideCard from '../../SettingSideCard';
import alertMixin from 'shared/mixins/alertMixin';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';

export default {
  components: {
    NewWebhook,
    EditWebhook,
    SettingsSideCard,
  },
  mixins: [alertMixin, globalConfigMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedWebHook: {},
    };
  },
  computed: {
    ...mapGetters({
      records: 'webhooks/getWebhooks',
      uiFlags: 'webhooks/getUIFlags',
      globalConfig: 'globalConfig/get',
    }),
  },
  mounted() {
    this.$store.dispatch('webhooks/get');
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedWebHook = response;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedWebHook = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.loading[this.selectedWebHook.id] = true;
      this.closeDeletePopup();
      this.deleteWebhook(this.selectedWebHook.id);
    },
    getComputedStyle(header) {
      if (header === 'Webhook endpoint') return { width: '60%' };
      if (header === 'Status') return { width: '40%' };
      return {};
    },
    async deleteWebhook(id) {
      try {
        await this.$store.dispatch('webhooks/delete', id);
        this.showAlert(
          this.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.API.SUCCESS_MESSAGE'),
          'success'
        );
      } catch (error) {
        this.showAlert(
          this.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.API.ERROR_MESSAGE'),
          'error'
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/widgets/buttons';

.table-container {
  height: 100%;

  table.woot-table tr td {
    word-break: break-word;
  }
}

.no-padding {
  padding-left: 0.1rem;
}
.settings-box {
  padding-top: $space-largest;
  overflow: hidden;

  .settings-container {
    display: flex;
    height: 100%;
    flex-direction: row;
  }
}

.info-card-header {
  font-size: $font-size-default;
  color: $secondary-blue;
  font-weight: $font-weight-black;
  line-height: 26px;
  margin-bottom: $space-medium;
}

.info-card-content {
  font-size: $font-size-small;
  color: $neutral-grey-600;
  line-height: 22px;
  margin-bottom: $space-larger;
}

.button-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.no-items-error-message {
  margin-top: $space-medium;
  text-align: center;
}
</style>
