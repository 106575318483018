import adminIntro from 'assets/images/admin_intro.svg';

const CUSTOM_FIELD_TYPE_PATH_PREFIX = 'CUSTOM_TICKET_FIELDS.TYPE';

export const CUSTOM_FIELD_TYPES = {
  SHORT_TEXT: 'short_text',
  PARAGRAPH: 'paragraph',
  URL: 'url',
  MULTI_SELECT: 'multi_select',
  SINGLE_SELECT: 'single_select',
  DATE_TIME: 'timestamp',
  DATE: 'date',
  TIME: 'time',
};

export const SHOWCASE_CUSTOM_FIELD_DATA = [
  {
    name: 'Custom Ticket Fields',
    key: 'CUSTOM_TICKET_FIELDS',
    color: '#4267B2',
    image: adminIntro,
    contentPath: 'CUSTOM_TICKET_FIELDS',
  },
];

export const CUSTOM_FIELD_TABLE_COLUMNS = [
  {
    label: 'Name',
    prop: 'name',
    width: '40%',
    component: 'customFields/CustomFieldNameAndDesc',
  },
  {
    label: 'Type',
    prop: 'field_type',
    width: '20%',
    component: 'customFields/CustomFieldType',
  },
  {
    label: 'Inboxes',
    prop: 'inboxes',
    width: '20%',
    component: 'ViewInboxes',
  },
  {
    label: 'Status',
    prop: 'enabled',
    width: '10%',
    component: 'customFields/CustomFieldStatus',
  },
  {
    label: 'Actions',
    width: '10%',
    component: 'customFields/CustomFieldActions',
  },
];

export const CUSTOM_FIELD_TYPE_INFO_PATH = {
  short_text: `${CUSTOM_FIELD_TYPE_PATH_PREFIX}.SHORT_TEXT`,
  paragraph: `${CUSTOM_FIELD_TYPE_PATH_PREFIX}.PARAGRAPH`,
  url: `${CUSTOM_FIELD_TYPE_PATH_PREFIX}.URL`,
  multi_select: `${CUSTOM_FIELD_TYPE_PATH_PREFIX}.MULTI_SELECT`,
  single_select: `${CUSTOM_FIELD_TYPE_PATH_PREFIX}.SINGLE_SELECT`,
  timestamp: `${CUSTOM_FIELD_TYPE_PATH_PREFIX}.DATE_TIME`,
  date: `${CUSTOM_FIELD_TYPE_PATH_PREFIX}.DATE`,
  time: `${CUSTOM_FIELD_TYPE_PATH_PREFIX}.TIME`,
};

export const CUSTOM_FIELD_TEMPLATES = [
  {
    id: CUSTOM_FIELD_TYPES.SHORT_TEXT,
    name: 'Short Text',
    description: 'Brief input, concise responses',
    file_url:
      'https://amar-work-stuff.s3.ap-south-1.amazonaws.com/Short_text.jpeg',
  },
  {
    id: CUSTOM_FIELD_TYPES.PARAGRAPH,
    name: 'Paragraph Text',
    description: 'Detailed input, extended responses',
    file_url:
      'https://amar-work-stuff.s3.ap-south-1.amazonaws.com/Paragraph.jpeg',
  },
  {
    id: CUSTOM_FIELD_TYPES.URL,
    name: 'URL Text',
    description: 'Web address input field',
    file_url: 'https://amar-work-stuff.s3.ap-south-1.amazonaws.com/URL.jpeg',
  },
  {
    id: CUSTOM_FIELD_TYPES.MULTI_SELECT,
    name: 'Multi-Select Dropdown',
    description: 'Choose multiple options easily',
    file_url:
      'https://amar-work-stuff.s3.ap-south-1.amazonaws.com/MultiSelect.jpeg',
  },
  {
    id: CUSTOM_FIELD_TYPES.SINGLE_SELECT,
    name: 'Single-Select Dropdown',
    description: 'Choose one option only',
    file_url:
      'https://amar-work-stuff.s3.ap-south-1.amazonaws.com/SingleSelect.jpeg',
  },
  {
    id: CUSTOM_FIELD_TYPES.DATE_TIME,
    name: 'Date & Time Picker',
    description: 'Select a specific date and time easily',
    file_url:
      'https://amar-work-stuff.s3.ap-south-1.amazonaws.com/DateTimePicker.jpeg',
  },
  {
    id: CUSTOM_FIELD_TYPES.DATE,
    name: 'Date Picker',
    description: 'Select a specific date easily',
    file_url:
      'https://amar-work-stuff.s3.ap-south-1.amazonaws.com/DateTimePicker.jpeg',
  },
  {
    id: CUSTOM_FIELD_TYPES.TIME,
    name: 'Time Picker',
    description: 'Select a specific time easily',
    file_url:
      'https://amar-work-stuff.s3.ap-south-1.amazonaws.com/Timepicker.jpeg',
  },
];

export const ERROR_PREFIX = 'CUSTOM_TICKET_FIELDS.ADD';
