<template>
  <div>
    <div class="settings-form custom-scroll">
      <div class="settings-title title-h3 text-dark" v-text="'Details'" />
      <div class="flex-row flex-align gap--small content-margin">
        <woot-input
          v-model.trim="title"
          required
          auto-focus
          :label="$t('LABEL_MGMT.FORM.NAME.LABEL')"
          :class="{ error: $v.title.$error }"
          :has-error="$v.title.$error"
          :error="$t('LABEL_MGMT.FORM.NAME.ERROR')"
          :placeholder="$t('LABEL_MGMT.FORM.NAME.PLACEHOLDER')"
          @input="onChange"
        />

        <div>
          <div
            class="title-h5 text-dark mg-bottom--small"
            v-text="$t('LABEL_MGMT.FORM.TYPE.LABEL')"
          />
          <woot-single-selector
            :default-option="selectedType"
            :option-list="labelTypeOptions"
            :custom-style="{ left: 0 }"
            @click="onSelectType"
          />
        </div>
      </div>

      <woot-input
        v-model.trim="description"
        rows="4"
        type="text"
        class="medium-12 columns"
        :label="$t('LABEL_MGMT.FORM.DESCRIPTION.LABEL')"
        :placeholder="$t('LABEL_MGMT.FORM.DESCRIPTION.PLACEHOLDER')"
        @input="onChange"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { TYPES } from 'dashboard/routes/dashboard/settings/labels/constants';

import validations from '../validations';

export default {
  props: {
    tagTitle: { type: String, default: '' },
    resource: { type: String, default: 'Conversation' },
    tagDescription: { type: String, default: '' },
  },
  data() {
    return {
      description: '',
      title: '',
      selectedType: this.resource,
      labelTypeOptions: [
        { id: 1, name: TYPES.conversation },
        { id: 2, name: TYPES.contact },
      ],
    };
  },
  validations,
  computed: {
    ...mapGetters({
      uiFlags: 'labels/getUIFlags',
    }),
    labelDetails() {
      const { title, description, selectedType } = this;
      return { title, description, resource_type: selectedType };
    },
  },
  created() {
    this.title = this.tagTitle || '';
    this.description = this.tagDescription || '';
  },
  mounted() {
    this.$emit('formValidity', this.$v.title.$invalid);
  },
  methods: {
    onChange() {
      this.$v.$touch();
      this.$emit('labelDetails', this.labelDetails);
      this.$emit('formValidity', this.$v.title.$invalid);
    },
    onSelectType({ name }) {
      this.selectedType = name;
      this.$emit('labelDetails', this.labelDetails);
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '~dashboard/assets/scss/variables';

.label-settings-container {
  padding: $space-two $space-jumbo;

  .settings-form {
    height: $space-large * 10;
    padding: $zero $space-two;
    overflow: overlay;

    .settings-title {
      display: block;
      margin-bottom: $space-two;
    }
  }
}

.settings-title {
  display: none;
}

.content-margin {
  margin-bottom: $space-medium;
  display: grid;
  grid-gap: $space-medium;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
</style>
