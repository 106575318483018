<template>
  <div v-if="uiFlags.isFetchingMetrics" class="flex-space-between">
    <div class="metrics-card-container">
      <metrics-card-skeleton
        v-for="(item, index) in metricsCount"
        :key="index"
      />
    </div>
    <pie-chart-skeleton
      chart-height="18rem"
      :custom-style="{ margin: '0rem' }"
    />
  </div>
  <div v-else class="flex-space-between">
    <div class="metrics-card-container">
      <metrics-card
        v-for="(item, index) in metricsCount"
        :key="index"
        :title="item.title"
        :sub-title="item.sub"
        :actual="item.actual"
        :style="{ 'margin-right': '0rem' }"
      />
    </div>

    <div
      v-if="metrics.totalResponseCount"
      class="lime-card-1dp flex-row flex-justify flex-align pie-chart-mr"
    >
      <pie-chart
        title="Response Split"
        :series="pieSeries"
        :colors="pieColors"
        :labels="pieLabels"
        :height="180"
        legend-position="right"
        :legend-offset-x="0"
        :legend-offset-y="-30"
        :marker-offset-x="0"
        :marker-offset-y="1"
        :chart-offset-x="0"
        :chart-offset-y="0"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import MetricsCard from './MetricsCard';
import PieChart from 'dashboard/components/widgets/chart/PieChart';
import MetricsCardSkeleton from './MetricsCardSkeleton';
import PieChartSkeleton from './PieChartSkeleton';

import { CSAT_RATINGS } from 'shared/constants/messages';
import { formatToPercent } from 'dashboard/routes/dashboard/analytics/shared/helpers';

export default {
  components: {
    MetricsCard,
    PieChart,
    MetricsCardSkeleton,
    PieChartSkeleton,
  },
  computed: {
    ...mapGetters({
      metrics: 'csat/getMetrics',
      uiFlags: 'csat/getUIFlags',
      ratingPercentage: 'csat/getRatingPercentage',
      satisfactionScore: 'csat/getSatisfactionScore',
      csatRating: 'csat/getCSATRating',
      responseRate: 'csat/getResponseRate',
    }),
    avgCsatRating() {
      return this.csatRating ? `${this.csatRating} / 5` : '--';
    },
    pieSeries() {
      return CSAT_RATINGS.map(rating =>
        Number(this.ratingPercentage[rating.value])
      );
    },
    pieLabels() {
      return CSAT_RATINGS.map(rating => ` ${rating.emoji} ${rating.label}`);
    },
    pieColors() {
      return CSAT_RATINGS.map(rating => rating.color);
    },
    responseCount() {
      return this.metrics.totalResponseCount
        ? this.metrics.totalResponseCount.toLocaleString()
        : '--';
    },
    metricsCount() {
      return [
        {
          title: this.responseCount,
          sub: this.$t('ANALYTICS.CSAT_REPORTS.METRIC.TOTAL_RESPONSES.LABEL'),
          actual: this.$t(
            'ANALYTICS.CSAT_REPORTS.METRIC.TOTAL_RESPONSES.TOOLTIP'
          ),
        },
        {
          title: this.avgCsatRating,
          sub: this.$t('ANALYTICS.CSAT_REPORTS.METRIC.CSAT_RATING.LABEL'),
          actual: this.$t('ANALYTICS.CSAT_REPORTS.METRIC.CSAT_RATING.TOOLTIP'),
        },
        {
          title: formatToPercent(this.responseRate),
          sub: this.$t('ANALYTICS.CSAT_REPORTS.METRIC.RESPONSE_RATE.LABEL'),
          actual: this.$t(
            'ANALYTICS.CSAT_REPORTS.METRIC.RESPONSE_RATE.TOOLTIP'
          ),
        },
        {
          title: formatToPercent(this.satisfactionScore),
          sub: this.$t(
            'ANALYTICS.CSAT_REPORTS.METRIC.SATISFACTION_SCORE.LABEL'
          ),
          actual: this.$t(
            'ANALYTICS.CSAT_REPORTS.METRIC.SATISFACTION_SCORE.TOOLTIP'
          ),
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
.metrics-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $space-medium;
  width: 100%;
  margin: $space-medium $space-medium $space-medium $space-micro;
}

.pie-chart-mr {
  margin: $space-micro;
  min-height: 29rem;
}
</style>
