<template>
  <div class="form-footer flex-row flex-justify--between full-width">
    <woot-base-button
      size="small"
      :loading="isLoading"
      @click="$emit('submit')"
    >
      {{ editView ? 'SAVE' : 'CREATE' }}
    </woot-base-button>
    <woot-base-button
      size="small"
      variant="secondary-danger"
      @click="$emit('onBack')"
    >
      DISCARD
    </woot-base-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    editView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({ uiFlags: 'sla/getUIFlags' }),
    isLoading() {
      return !this.editView ? this.uiFlags.isCreating : this.uiFlags.isSaving;
    },
  },
};
</script>
