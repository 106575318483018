<template>
  <div class="template-substitutions-wrapper">
    <woot-input
      v-if="hasMedia"
      required
      size="small"
      label="Media Url Link"
      :value="mediaUrl"
      placeholder="Enter media url"
      class="template-subsitution-input-field mg-bottom--one CREATE_TICKET_MEDIA_URL"
      :class="{ error: $v.mediaUrl.$error }"
      :has-error="$v.mediaUrl.$error"
      :error="$t('CREATE_TICKET.FORM.ERRORS.NOT_VALID_MEDIA_URL')"
      @blur="$v.mediaUrl.$touch"
      @input="$emit('updateField', 'media_url', $event)"
    />
    <template-body-substitutions
      :style="{ paddingRight: '25%' }"
      :body="template.body"
      :substitutions="substitutions"
      @click="openSubstitutionModal"
    />
    <woot-input
      v-if="hasDynamicButtons"
      required
      size="small"
      label="Dynamic Button Url"
      :value="dynamicUrl"
      placeholder="Enter dynamic button url"
      class="template-subsitution-input-field mg-top--one CREATE_TICKET_DYNAMIC_URL"
      :class="{ error: $v.dynamicUrl.$error }"
      :has-error="$v.dynamicUrl.$error"
      :error="$t('CREATE_TICKET.FORM.ERRORS.NOT_VALID_DYNAMIC_URL')"
      @blur="$v.dynamicUrl.$touch"
      @input="$emit('updateField', 'dynamic_url', $event)"
    />
    <woot-base-button
      tag="span"
      size="small"
      variant="secondary-danger"
      class="remove-template-btn"
      @click="$emit('removeTemplate')"
    >
      <icons name="delete" size="normal" color="red" />
    </woot-base-button>
    <template-body-substitutions-editor
      :show="showSubstitutionModal"
      :substitutions="substitutions"
      :current-selected-variable-index="currentSelectedVariableIndex"
      @onClose="() => (showSubstitutionModal = false)"
      @input="onInputTemplateBodySubtitutions"
    />
  </div>
</template>
<script>
import { required, url } from 'vuelidate/lib/validators';

import TemplateBodySubstitutions from './TemplateBodySubstitutions';
import TemplateBodySubstitutionsEditor from './TemplateBodySubstitutionsEditor';

import broadcastMixin from 'dashboard/mixins/broadcast';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: { TemplateBodySubstitutions, TemplateBodySubstitutionsEditor },
  mixins: [broadcastMixin, alertMixin],
  props: {
    template: {
      type: Object,
      default: () => {},
    },
    mediaUrl: {
      type: String,
      default: '',
    },
    dynamicUrl: {
      type: String,
      default: '',
    },
    numberOfSubstitutions: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      substitutions: [],
      showSubstitutionModal: false,
      currentSelectedVariableIndex: null,
    };
  },
  validations: {
    mediaUrl: { required, url },
    dynamicUrl: { required },
  },
  computed: {
    numberOfVariables() {
      return this.template?.body?.match(/\{\}/g)?.length || 0;
    },
    hasMedia() {
      return this.template.has_media;
    },
    hasDynamicButtons() {
      return this.template.has_dynamic_button;
    },
    templateBody() {
      const { body } = this.template;
      if (!body) return body;
      let formattedData = body.replaceAll(
        '{}',
        '<span class="brackets--highlight">SELECT</span>'
      );
      return formattedData;
    },
  },
  watch: { numberOfSubstitutions: 'initSubstitutions' },
  mounted() {
    this.initSubstitutions();
  },
  methods: {
    initSubstitutions() {
      this.substitutions = new Array(this.numberOfVariables).fill(
        new Array(this.numberOfSubstitutions).fill('')
      );

      this.$emit(
        'updateField',
        'template_attributes',
        this.transposeTemplateAttributes(this.substitutions)
      );
    },
    openSubstitutionModal(variableIndex) {
      if (this.numberOfSubstitutions < 1) {
        this.showAlert('Please add contacts for substitution', 'info');
        return;
      }
      this.showSubstitutionModal = true;
      this.currentSelectedVariableIndex = variableIndex;
    },
    transposeTemplateAttributes(inputArray) {
      if (!inputArray[0]?.length) return inputArray;
      const transposedArray = inputArray[0].map((_, columnIndex) =>
        inputArray.map(row => row[columnIndex])
      );

      return transposedArray;
    },
    onInputTemplateBodySubtitutions(value, index) {
      const newSubstitutions = JSON.parse(JSON.stringify(this.substitutions));
      newSubstitutions[this.currentSelectedVariableIndex][index] = value;
      this.substitutions = newSubstitutions;

      this.$emit(
        'updateField',
        'template_attributes',
        this.transposeTemplateAttributes(newSubstitutions)
      );
    },
  },
};
</script>
