<template>
  <div class="small-12 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.API_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.API_CHANNEL.DESC')"
    />
    <form @submit.prevent="createChannel">
      <div class="settings-sub-menu-content--larger mg-bottom--jumbo">
        <woot-input
          v-model.trim="channelName"
          auto-focus
          required
          :class="{ error: $v.channelName.$error }"
          :has-error="$v.channelName.$error"
          :error="$t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
          "
          @blur="$v.channelName.$touch"
        />

        <woot-input
          v-model.trim="webhookUrl"
          required
          :class="{ error: $v.webhookUrl.$error }"
          :has-error="$v.webhookUrl.$error"
          :error="$t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.PLACEHOLDER')
          "
          @blur="$v.webhookUrl.$touch"
        />
      </div>
      <div class="medium-12 columns settings-sub-menu-bottom-nav">
        <woot-base-button
          variant="secondary"
          @click.prevent="() => $router.go(-1)"
        >
          Back
        </woot-base-button>
        <woot-base-button :loading="uiFlags.isCreating" type="submit">
          Next
        </woot-base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';

const shouldBeWebhookUrl = (value = '') => value.startsWith('http');

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      channelName: '',
      webhookUrl: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    channelName: { required },
    webhookUrl: { required, shouldBeWebhookUrl },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('AGENT_MGMT.FORM_ERROR'), 'error');
        return;
      }

      try {
        const apiChannel = await this.$store.dispatch('inboxes/createChannel', {
          name: this.channelName,
          channel: {
            type: 'api',
            webhook_url: this.webhookUrl,
          },
        });

        const { id: inbox_id, channel_type, name: channel_name } = apiChannel;
        this.mix_panel_clicked_create_inbox_successful(
          'API Channel',
          channel_type
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id,
          },
          query: { channel_type, channel_name },
        });
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.API_CHANNEL.API.ERROR_MESSAGE'),
          'error'
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.form-label {
  padding: $zero $space-small $space-one;
  color: $neutral-grey-800;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: 22px;
}

.content-margin {
  margin-bottom: 2.4rem;
}

.medium-12 {
  .button {
    float: right;
  }
}
</style>
