<template>
  <woot-base-button
    front-icon="plus"
    size="small"
    variant="secondary"
    @click="$emit('click')"
  >
    {{ label }}
  </woot-base-button>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Add',
    },
  },
};
</script>
