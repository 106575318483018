import ContactsAPI from '../../api/contacts';
import * as types from '../mutation-types';

const state = {
  uiFlags: {
    isFetching: false,
    isUpdating: false,
  },
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }, contactId) => {
    commit(types.default.SET_CONTACT_LABELS_UI_FLAG, {
      isFetching: true,
    });
    try {
      const response = await ContactsAPI.getLabels(contactId);
      commit(types.default.SET_CONTACT_LABELS_UI_FLAG, {
        isFetching: false,
      });
      return response.data.payload;
    } catch (error) {
      commit(types.default.SET_CONTACT_LABELS_UI_FLAG, {
        isFetching: false,
      });
      throw new Error(error);
    }
  },
  update: async ({ commit }, { contactId, labelId, action }) => {
    commit(types.default.SET_CONTACT_LABELS_UI_FLAG, {
      isUpdating: true,
    });
    try {
      const response = await ContactsAPI.updateLabel(
        contactId,
        labelId,
        action
      );
      commit(types.default.SET_CONTACT_LABELS_UI_FLAG, {
        isUpdating: false,
      });
      return response.data.payload;
    } catch (error) {
      commit(types.default.SET_CONTACT_LABELS_UI_FLAG, {
        isUpdating: false,
      });
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_CONTACT_LABELS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
