<template>
  <div class="column content-box settings-box">
    <div class="settings-button flex-row-justify-center" style="top: 2.4rem">
      <woot-primary-button
        :name="$t('PRODUCTS_MGMT.HEADER_BUTTON.PREV')"
        front-icon="chevronLeft"
        :disabled="itemIndex === 0"
        :custom-style="{ 'margin-right': '1.2rem' }"
        @click="prevCard"
      />

      <woot-primary-button
        :name="$t('PRODUCTS_MGMT.HEADER_BUTTON.NEXT')"
        back-icon="chevronRight"
        :disabled="itemIndex === records.length - 1"
        @click="nextCard"
      />
    </div>
    <transition name="fade">
      <div
        v-if="!uiFlags.fetchingProducts && item !== {}"
        :key="itemIndex"
        class="settings-container custom-scroll"
      >
        <div
          class="small-12 item-wrap"
          :class="{ 'item-height': item.description !== null }"
        >
          <div
            class="card__media"
            :class="{
              'image-unavailable': productImage.isUnavailable,
            }"
          >
            <img :src="productImage.source" :alt="'Product Image'" />
          </div>
          <div class="card__content flex-column custom-scroll">
            <div class="card__header text-dark title-h1">
              {{ item.name }}
            </div>
            <footer class="card__meta flex-space-between" role="contentinfo">
              <div class="card__price text-dark title-h2">
                <span
                  v-if="productPrice.onSale"
                  v-html="`${accountCurrency} ${productPrice.price}`"
                >
                </span>
                <span
                  :class="{
                    'sale-price': productPrice.onSale,
                  }"
                  v-html="`${accountCurrency} ${productPrice.comparedAtPrice}`"
                />
                <span
                  v-if="productPrice.onSale"
                  class="price__discount flex-row-justify-center"
                >
                  <icons name="discount" color="primary" size="semimedium" />
                  <span>
                    {{
                      `Buy this at ${discountPercentage}
                  % OFF discount`
                    }}
                  </span>
                </span>
              </div>
            </footer>
            <span
              v-if="hasNoVariants && defaultVariant.inventory_quantity < 1"
              class="out-of-stock title-h5"
            >
              OUT OF STOCK
            </span>
            <product-variants
              v-if="!hasNoVariants"
              :id="+productId"
              :key="+productId"
              @variantData="handleVariantData"
            />
            <p
              v-if="item.description !== null"
              class="card__excerpt subtitle-s2"
              v-html="item.description"
            ></p>
          </div>
        </div>

        <div class="attributes-container">
          <div class="attribute-header">
            {{ $t('PRODUCTS_MGMT.ATTRIBUTES.TITLE') }}
          </div>
          <div class="flex-space-between">
            <woot-input
              v-model.trim="newAttributes"
              class="columns content-margin"
              :label="$t('PRODUCTS_MGMT.ATTRIBUTES.LABEL')"
              :placeholder="$t('PRODUCTS_MGMT.ATTRIBUTES.PLACEHOLDER')"
              :help-text="$t('PRODUCTS_MGMT.ATTRIBUTES.DESC')"
            />
            <woot-primary-button
              :name="$t('PRODUCTS_MGMT.ATTRIBUTES.BUTTON')"
              :disabled="newAttributes.length === 0"
              :custom-style="{
                'margin-top': '3.5rem',
                width: '10rem',
                display: 'flex',
                'justify-content': 'center',
              }"
              @click="addAttributes(newAttributes)"
            />
          </div>
          <span class="attribute-box columns">
            <woot-chips
              v-for="(attribute, index) in attributes"
              :key="index"
              :title="attribute"
              variant="primary-small"
              :show-icon="true"
              @click="onRemoveAttribute(index)"
            />
            <p v-if="attributes.length === 0" class="no-items-error-message">
              {{ $t('PRODUCTS_MGMT.ATTRIBUTES.404') }}
            </p>
          </span>
        </div>

        <p
          v-if="!uiFlags.fetchingProducts && item === {}"
          class="no-items-error-message"
        >
          {{ $t('PRODUCTS_MGMT.LIST.404') }}
        </p>
        <woot-loading-state
          v-if="uiFlags.fetchingProducts"
          :message="$t('PRODUCTS_MGMT.LIST.LOADING')"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import router from 'dashboard/routes';
import alertMixin from 'shared/mixins/alertMixin';
import accountMixin from '../../../../../mixins/account';
import { frontendURL } from 'dashboard/helper/URLHelper';

import ProductVariants from 'dashboard/routes/dashboard/conversation/products/deepShopify/ProductVariants';

export default {
  components: { ProductVariants },
  mixins: [alertMixin, accountMixin],
  props: {
    productId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      newAttributes: '',
      product: {},
      variantsData: {},
    };
  },
  computed: {
    ...mapGetters({
      records: 'getProducts',
      uiFlags: 'getProductsUIFlags',
      accountId: 'getCurrentAccountId',
    }),
    hasNoVariants() {
      return this.defaultVariant?.option1 === 'Default Title';
    },
    defaultVariant() {
      return this.product.default_variant;
    },
    currentVariant() {
      return this.variantsData[this.product.id];
    },
    variantPrice() {
      return {
        price: this.currentVariant.price,
        compareAtPrice: this.currentVariant.compare_at_price,
      };
    },
    discountPercentage() {
      return Math.floor(
        100 -
          (100 * this.productPrice.price) / this.productPrice.comparedAtPrice
      );
    },
    productImage() {
      const { shopify_images: defaultImages } = this.product;

      if (Object.keys(this.variantsData).length !== 0 && this.currentVariant) {
        if (this.currentVariant.image_link === null) {
          return {
            isUnavailable: true,
            source: defaultImages[0].src,
          };
        }
        return {
          isUnavailable: false,
          source: this.currentVariant.image_link,
        };
      }

      if (!defaultImages[0].src)
        return {
          isUnavailable: true,
          source: '',
        };

      return {
        isUnavailable: false,
        source: defaultImages[0].src,
      };
    },
    productPrice() {
      const { price: comparedAtPrice, sale_price: price } = this.product;

      if (Object.keys(this.variantsData).length !== 0 && this.currentVariant) {
        if (!this.variantPrice.compareAtPrice)
          return {
            onSale: false,
            comparedAtPrice: '',
            price: this.variantPrice.price,
          };
        if (!this.variantPrice.price)
          return {
            onSale: false,
            comparedAtPrice: '',
            price: '',
          };
        return {
          onSale: this.variantPrice.compareAtPrice > this.variantPrice.price,
          comparedAtPrice: this.variantPrice.compareAtPrice,
          price: this.variantPrice.price,
        };
      }
      return {
        onSale:
          this.defaultVariant?.compare_at_price > this.defaultVariant?.price,
        comparedAtPrice: this.getPrice(comparedAtPrice),
        price: this.getPrice(price),
      };
    },
    itemIndex() {
      return this.records.findIndex(
        item => item.id === Number(this.product.id)
      );
    },
    item() {
      return this.product;
    },
    attributes() {
      return this.product.attributes || [];
    },
    currentPage() {
      const page = this.$route.query?.page;
      return page || 1;
    },
    accountCurrency() {
      return this.$store.getters['accounts/getAccountCurrency'](this.accountId);
    },
  },
  watch: {
    productId(newProductId) {
      this.$store
        .dispatch('getProductDetails', { productId: newProductId })
        .then(response => {
          this.product = response.data;
        });
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch('getProducts', {
        page: this.currentPage,
        is_shopify: true,
      }),
      this.$store
        .dispatch('getProductDetails', { productId: this.productId })
        .then(response => {
          this.product = response.data;
        }),
    ]);
  },
  methods: {
    getPrice(value) {
      return value === null || value === undefined
        ? 'price unavailable'
        : value;
    },
    nextCard() {
      let id = this.records[this.itemIndex + 1].id;
      router.push({
        path: frontendURL(
          `accounts/${this.accountId}/settings/products/${id}?page=${this.currentPage}`
        ),
      });
    },
    prevCard() {
      let id = this.records[this.itemIndex - 1].id;
      router.push({
        path: frontendURL(
          `accounts/${this.accountId}/settings/products/${id}?page=${this.currentPage}`
        ),
      });
    },
    handleVariantData(data) {
      this.variantsData = { ...this.variantsData, ...data };
    },
    addAttributes(val) {
      let newAttributesArray = val.split(',');
      let newList = [...this.attributes, ...newAttributesArray];
      this.product.attributes = newList;
      this.newAttributes = '';
      this.updateAttributes();
    },
    onRemoveAttribute(index) {
      this.product.attributes.splice(index, 1);
      this.updateAttributes();
    },
    updateAttributes() {
      try {
        this.$store
          .dispatch('updateProduct', {
            attributes: this.attributes,
            id: this.item.id,
          })
          .then(data => {
            if (data) {
              this.showAlert(
                this.$t('PRODUCTS_MGMT.SUCCESS_MESSAGE'),
                'success'
              );
            } else {
              this.showAlert(this.$t('PRODUCTS_MGMT.FAILURE_MESSAGE'), 'error');
            }
          });
      } catch {
        this.showAlert(this.$t('PRODUCTS_MGMT.FAILURE_MESSAGE'), 'error');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.out-of-stock {
  color: $warn-red-400;
  text-transform: uppercase;
}

.settings-box {
  padding-top: $space-largest;
  padding-left: 0.1rem;
  overflow: hidden;

  .settings-container {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
}

.item-wrap {
  @include elevation-1dp;
  display: flex;
  flex-direction: row;
  background: $neutral-white;
  padding: $space-slab;
  margin: $space-micro;
  margin-bottom: $space-normal;
  max-height: 40%;

  .card__media {
    border: 1px solid $neutral-grey-400;
    border-radius: $border-radius;
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      border-radius: $border-radius;
      padding: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .image-unavailable {
    z-index: 0;

    &::before {
      background: $neutral-grey-600;
      bottom: $zero;
      content: 'Image Unavailable';
      color: #fff;
      font-size: $font-size-big;
      font-weight: $font-weight-normal;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 25%;
      left: $zero;
      opacity: 0.5;
      position: absolute;
      width: 100%;
      z-index: 1;
      animation: slideup 0.3s ease-out;
      background: #8c8c8c;
    }
  }

  .card__content {
    padding: $space-small $space-large;
    height: 100%;
    width: 100%;

    .card__excerpt {
      position: relative;
      display: flex;
      color: $neutral-grey-800;
      flex-direction: column;
      max-width: 100%;
      height: 100%;
      text-align: start;
      word-break: break-word;
      margin: $space-normal 0;

      &::after {
        content: '-';
        opacity: 0;
      }
    }

    .card__meta {
      border-bottom: 0;

      .card__price {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: $space-slab;

        .sale-price {
          margin-left: $space-small;
          color: $neutral-grey-600;
          font-size: $font-size-small;
          text-decoration: line-through;
          text-decoration-color: $warn-red-500;
        }
      }

      .price__discount {
        margin-left: $space-medium;
        align-items: center;
        color: $secondary-blue;
        font-size: $font-size-default;
        line-height: $space-medium;
      }
    }
  }
}

.attributes-container {
  @include elevation-1dp;
  background: $neutral-white;
  padding: $space-slab;
  margin: $space-micro;
  margin-bottom: $space-medium;
  display: flex;
  flex-direction: column;

  .attribute-header {
    font-size: $font-size-medium;
    line-height: 2.6rem;
    color: $neutral-grey-600;
    padding-bottom: $space-normal;
    margin-bottom: $space-normal;
    border-bottom: 1px solid $neutral-grey-400;
    display: flex;
  }

  button {
    margin-left: $space-slab;
  }
}

.card__header {
  margin-bottom: $space-normal;
}

.item-height {
  max-height: 60%;
}

.card__content::v-deep a {
  color: $pg-1-500;
}

.card__content::v-deep strong {
  color: $neutral-grey-800;
  font-size: $font-size-medium;
}

.card__content::v-deep img {
  width: 100%;
  margin-bottom: $space-small;
}

.no-items-error-message {
  text-align: center;
}

.label-container {
  flex: 0 1 auto;
  flex-wrap: wrap;
  display: flex;
  max-width: 100%;
}

.button {
  min-width: 100px;
  padding: $space-small;
}

.button ::v-deep svg {
  margin-right: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.18s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: $zero;
}

@keyframes slideup {
  0% {
    transform: translateY($space-one * 10);
  }
  100% {
    transform: translateY($zero);
  }
}
</style>
