import { render, staticRenderFns } from "./Acl.vue?vue&type=template&id=4cbb9d45&scoped=true&"
import script from "./Acl.vue?vue&type=script&lang=js&"
export * from "./Acl.vue?vue&type=script&lang=js&"
import style0 from "./Acl.vue?vue&type=style&index=0&id=4cbb9d45&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cbb9d45",
  null
  
)

export default component.exports