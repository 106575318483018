/* global axios */
import ApiClient from 'dashboard/api/ApiClient';

class StoreNotificationsAPI extends ApiClient {
  constructor() {
    super('store_notifications', { accountScoped: true, apiVersion: 'v2' });
  }

  privateReply({
    medium,
    source_id,
    contact_id,
    conversation_id,
    inbox_id,
    content,
  }) {
    return axios.post(`${this.url}/facebook/${medium}/private-reply`, {
      comment_id: source_id,
      recipient_id: contact_id,
      comment_conversation_id: conversation_id,
      content_type: 'text',
      inbox_id,
      content,
    });
  }
}

export default new StoreNotificationsAPI();
