<template>
  <div
    v-if="uiFlags.isFetchingItem"
    class="small-12 custom-scroll center-aligned"
  >
    <woot-loading-state message="Fetching Plan details" />
  </div>
  <div v-else>
    <div
      v-if="hideBillingSection || isOnCustomPlan"
      class="column content-box billing-box"
    >
      <div class="settings-form lime-card-1dp">
        <div
          v-if="hideBillingSection"
          class="title-h2"
          style="text-transform: uppercase"
        >
          {{ $t('BILLING.NO_ACTIVE_PLAN_TXT') }}
        </div>
        <div v-else class="flex-space-between">
          <div class="title-h2" style="text-transform: uppercase">
            {{ $t('BILLING.CUSTOM_PLAN') }}
          </div>
          <div class="title-h1 plan-rate">
            <span>
              {{
                subscriptionForShopify.plan &&
                subscriptionForShopify.plan.currency_code == 'USD'
                  ? '$'
                  : subscriptionForShopify.plan.currency_code
              }}{{
                subscriptionForShopify.plan &&
                  subscriptionForShopify.plan.price
              }}/mo
            </span>
          </div>
        </div>

        <div class="flex-space-between flex-align--start">
          <div class="body-b2 text-dark" style="min-width: 85%">
            <span v-if="hideBillingSection">
              {{ $t('BILLING.NO_ACTIVE_PLAN') }}
            </span>
            <span v-else>
              {{
                $t('BILLING.PAID_PLAN_MAIN_PAGE_TEXT', {
                  daysLeft: daysLeft + 1,
                  day: daysLeft > 1 ? 'days' : 'day',
                  resetDate: resetDate,
                })
              }}
              <br />
              {{
                $t('BILLING.PLAN_START_END', {
                  startDate: startDate,
                  endDate: endDate,
                })
              }}
            </span>
          </div>

          <woot-primary-button
            :name="$t('BILLING.BROWSE_PLANS')"
            custom-class="mg-top--large"
            full-width
            @click="showPlansPage"
          />
        </div>
      </div>
      <div class="flex-row  mg-top">
        <div class="mg-right--smaller">
          <img src="/brand-assets/bulb.svg" height="20" width="20" />
        </div>
        <div class="body-b2 text-dark">
          {{ $t('BILLING.LIMECHAT_TXT') }}
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="activePlanOver" class="column content-box billing-box">
        <div
          class="settings-form lime-card-1dp flex-space-between"
          style="padding: 2.4rem; align-items :flex-start"
        >
          <div style="min-width : 60%">
            <div class="title-h2" style="text-transform: uppercase">
              {{ $t('BILLING.NO_ACTIVE_PLAN_TXT') }}
            </div>
            <div class="mg-top--small body-b2 text-dark">
              {{ $t('BILLING.NO_ACTIVE_PLAN') }}
            </div>
          </div>
          <div>
            <woot-primary-button
              :name="$t('BILLING.BROWSE_PLANS')"
              custom-class="mg-top--large"
              full-width
              @click="showPlansPage"
            />
          </div>
        </div>
      </div>
      <div v-else class="column content-box billing-box">
        <div
          v-if="freeTrialOver"
          class="settings-form lime-card-1dp flex-space-between"
          style="padding: 2.4rem; align-items :flex-start"
        >
          <div style="min-width : 60%">
            <div class="title-h2" style="text-transform: uppercase">
              {{ $t('BILLING.FREE_TRIAL_OVER') }}
            </div>
            <div class="mg-top--small body-b2 text-dark">
              {{
                $t('BILLING.FREE_TRIAL_EXPIRED', {
                  freeTrial: freeTrialEndDate,
                })
              }}
            </div>
          </div>
          <div>
            <woot-primary-button
              :name="$t('BILLING.BROWSE_PLANS')"
              custom-class="mg-top--large"
              full-width
              @click="showPlansPage"
            />
          </div>
        </div>
        <div
          v-else
          class="settings-form lime-card-1dp flex-space-between"
          style="padding: 2.4rem; align-items :flex-start"
        >
          <div>
            <div class="title-h2" style="text-transform: uppercase">
              <span v-if="isOnFreeTrialCheck">
                {{ $t('BILLING.STARTER') }}
              </span>
              <span v-else>{{
                subscriptionForShopify.plan &&
                  subscriptionForShopify.plan.name.split(' ')[0]
              }}</span>
            </div>
            <div
              v-if="isOnFreeTrialCheck"
              class="mg-top--small body-b2"
              style="color : #E8684A"
            >
              {{
                $t('BILLING.FREE_TRIAL_START_END', {
                  trialStartDate: trialStartDate,
                  freeTrialEndDate: freeTrialEndDate,
                })
              }}
            </div>
            <div
              v-else-if="isOnGracePeriod"
              class="mg-top--small title-h5 text-dark"
            >
              {{
                $t('BILLING.PAID_PLAN_GRACE_PERIOD_TEXT', {
                  startDate: gracePeriodStartDate,
                  endDate: gracePeriodLastDate,
                })
              }}
            </div>
            <div v-else class="mg-top--small body-b2 text-dark">
              {{
                $t('BILLING.PAID_PLAN_MAIN_PAGE_TEXT', {
                  daysLeft: daysLeft + 1,
                  day: daysLeft > 1 ? 'days' : 'day',
                  resetDate: resetDate,
                })
              }}
              <br />
              {{
                $t('BILLING.PLAN_START_END', {
                  startDate: startDate,
                  endDate: endDate,
                })
              }}
            </div>
            <div class="mg-top flex-row flex-row-center">
              <div style="width : 90%">
                <div class="text-dark title-h5 mg-bottom--slab">
                  {{ $t('BILLING.CYCLE_USAGE') }}
                </div>

                <progress-bar
                  :val="conversationTrackerValue"
                  :text="conversationTrackerText"
                  :bar-border-radius="8"
                  bg-color="#F0F0F0"
                  bar-color="#1B83AC"
                  text-align="left"
                  :size="12"
                />
              </div>
              <div class="mg-left mg-top" style="width : 20%; margin-top: 4rem">
                <div class="subtitle-s2" style="color : #E8684A">
                  {{ extraConversations }}
                </div>
                <div>
                  <span v-if="extraConversations > 0">
                    ${{ extraCharges }} {{ $t('BILLING.EXTRA_CHARGES') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div style="text-align:right">
            <div class="title-h1 plan-rate">
              <span v-if="isOnFreeTrialCheck || isOnGracePeriod">
                {{ $t('BILLING.FREE_TRIAL_RATE') }}
              </span>
              <span v-else>
                {{
                  subscriptionForShopify.plan &&
                  subscriptionForShopify.plan.currency_code == 'USD'
                    ? '$'
                    : subscriptionForShopify.plan.currency_code
                }}{{
                  subscriptionForShopify.plan &&
                    subscriptionForShopify.plan.price
                }}/mo
              </span>
            </div>
            <div class="title-h5_m text-dark">
              <span v-if="isOnFreeTrialCheck || isOnGracePeriod">150</span>
              <span v-else>{{
                subscriptionForShopify.plan &&
                  subscriptionForShopify.plan.free_conversations
              }}</span>
              tickets<span class="text-light">/month</span>
            </div>
            <div class="title-h6_m text-light">
              <span v-if="isOnFreeTrialCheck || isOnGracePeriod">
                $0.15 {{ $t('BILLING.EXTRA_TICKETS_TEXT') }}
              </span>
              <span v-else>
                ${{
                  subscriptionForShopify.plan &&
                    subscriptionForShopify.plan.extra_usage_rate
                }}
                per additional ticket
              </span>
            </div>
            <div
              v-if="subscriptionForShopify.plan.allowed_logins"
              class="title-h6_m text-light"
            >
              <span class="text-dark">
                {{
                  subscriptionForShopify.plan &&
                    subscriptionForShopify.plan.allowed_logins
                }}
                agent logins
              </span>
              /month
            </div>
            <div class="title-h6_m text-light">
              {{ $t('BILLING.AGENT_CHARGE') }}
            </div>
            <woot-primary-button
              name="Browse Plans"
              custom-class="mg-top--large"
              full-width
              @click="showPlansPage"
            />
          </div>
        </div>
        <div
          v-if="
            showStarterPlanCta(subscriptionForShopify.trial_end) &&
              !freeTrialOver &&
              !isAppUninstallFlow &&
              !isAppReinstallFlow
          "
          class="settings-form lime-card-1dp flex-space-between mg-top"
          style="padding: 2.4rem"
        >
          <div>
            <div class="title-h2">
              {{ $t('BILLING.ACTIVATE_PLAN') }}
            </div>
            <div class="body-b1 text-light mg-top--small">
              {{ $t('BILLING.LAST_DAY_CTA') }}
            </div>
          </div>
          <div class="mg-top  starter-plan-cta">
            <woot-primary-button
              :name="$t('BILLING.LAST_DAY_CTA_BTN')"
              @click="activateStarterPlan"
            />
          </div>
        </div>
        <div class="flex-row  mg-top">
          <div class="mg-right--smaller">
            <img src="/brand-assets/bulb.svg" height="20" width="20" />
          </div>
          <div class="body-b2 text-dark">
            {{ $t('BILLING.LIMECHAT_TXT') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProgressBar from 'vue-simple-progress';

import alertMixin from 'shared/mixins/alertMixin';
import timeMixin from 'dashboard/mixins/time';
import shopifyMixin from 'dashboard/mixins/shopify';
import billingMixin from 'dashboard/mixins/billing';

export default {
  name: 'BillingHome',
  components: {
    ProgressBar,
  },
  mixins: [alertMixin, timeMixin, shopifyMixin, billingMixin],
  data() {
    return {
      conversationTracker: null,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      uiFlags: 'accounts/getUIFlags',
    }),
    daysLeft() {
      return this.differenceInDays(
        new Date(this.subscriptionForShopify?.current_term_end),
        new Date()
      );
    },
    isOnCustomPlan() {
      return this.currentAccount?.on_shopify_custom_plan;
    },
    isAppUninstallFlow() {
      return (
        this.currentAccount?.on_grace_period &&
        !this.currentAccount?.shopify_app_reinstalled
      );
    },
    isAppReinstallFlow() {
      return (
        this.currentAccount?.on_grace_period &&
        this.currentAccount?.shopify_app_reinstalled
      );
    },
    isOnGracePeriod() {
      return (
        this.isAppUninstallFlow ||
        (this.currentAccount?.shopify_app_reinstalled &&
          this.currentAccount?.on_grace_period &&
          !this.isBeyondGracePeriod(
            this.currentAccount?.grace_period_last_date
          ))
      );
    },
    gracePeriodLastDate() {
      return this.formatDateString(
        new Date(this.sendPrevDate(this.currentAccount?.grace_period_last_date))
      );
    },
    gracePeriodStartDate() {
      if (this.subscriptionForShopify?.current_term_start)
        return this.startDate;
      return this.trialStartDate;
    },
    hideBillingSection() {
      return (
        (this.currentAccount?.shopify_trial_taken &&
          !this.subscriptionForShopify) ||
        !this.subscriptionForShopify
      );
    },
    activePlanOver() {
      return this.isBeyondGracePeriod(
        this.currentAccount?.grace_period_last_date
      );
    },
    freeTrialOver() {
      return this.freeTrialHasEnded(this.subscriptionForShopify?.trial_end);
    },
    conversationTrackerValue() {
      if (this.isOnFreeTrialCheck) {
        return (this.conversationTracker?.consumed_conversations / 500) * 100;
      }
      if (this.conversationTracker?.free_conversations >= 0)
        return (
          (this.conversationTracker?.consumed_conversations /
            this.conversationTracker?.free_conversations) *
          100
        );
      return 0;
    },
    conversationTrackerText() {
      if (this.isOnFreeTrialCheck) {
        return `${this.conversationTracker?.consumed_conversations}/500 Tickets`;
      }
      return `${this.conversationTracker?.consumed_conversations}/${this.conversationTracker?.free_conversations} Tickets`;
    },
    extraConversations() {
      if (this.isOnFreeTrialCheck) {
        return this.conversationTracker?.consumed_conversations - 500 > 0
          ? `+${this.conversationTracker?.consumed_conversations - 500}`
          : null;
      }
      return this.conversationTracker?.consumed_conversations -
        this.conversationTracker?.free_conversations >
        0
        ? `+${this.conversationTracker?.consumed_conversations -
            this.conversationTracker?.free_conversations}`
        : null;
    },
    extraCharges() {
      return (
        this.extraConversations *
        this.subscriptionForShopify.plan?.extra_usage_rate
      ).toFixed(2);
    },
    isOnFreeTrialCheck() {
      return this.isOnFreeTrial(
        this.subscriptionForShopify?.trial_end,
        this.currentAccount?.on_grace_period
      );
    },
    freeTrialEndDate() {
      var prev_date = new Date(this.subscriptionForShopify?.trial_end);
      prev_date.setDate(prev_date.getDate() - 1);

      return this.formatDateString(prev_date);
    },
    startDate() {
      return this.formatDateString(
        new Date(this.subscriptionForShopify?.current_term_start)
      );
    },
    endDate() {
      return this.formatDateString(
        new Date(
          this.sendPrevDate(this.subscriptionForShopify?.current_term_end)
        )
      );
    },
    resetDate() {
      return this.sendNextDate(this.endDate);
    },
    trialStartDate() {
      return this.formatDateString(
        new Date(this.subscriptionForShopify?.trial_start)
      );
    },
  },
  mounted() {
    this.getConversationCount();
  },

  methods: {
    showPlansPage() {
      this.$router.push(
        `/app/accounts/${this.$route.params.accountId}/settings/billing_plan/choose_billing_plan`
      );
    },
    getConversationCount() {
      this.$store.dispatch('getBillingConversationCount').then(data => {
        this.conversationTracker = data;
      });
    },
    activateStarterPlan() {
      this.$store
        .dispatch('upgradeShopifyAppPlan', {
          account_id: this.accountId,
          plan_name: 'Starter Plan USD',
        })
        .then(data => {
          if (data?.data?.shopify_confirmation_url)
            window.open(
              data?.data?.shopify_confirmation_url,
              '_blank',
              'noopener'
            );
          else this.showAlert('Something went wrong!', 'error');
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';
.billing-box {
  padding-top: $space-largest;
  padding-left: $space-micro;
}
.plan-rate {
  color: $secondary-blue;
}

.settings-form {
  padding: $space-medium;
}
</style>
