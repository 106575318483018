<template>
  <div class="lime-card-border flex-column raise-ticket-container">
    <span
      class="flex-row mg-bottom--slab navigate-back"
      @click="$emit('closeRaiseTicket')"
    >
      <span class="mg-right--smaller">
        <icons
          name="chevronLeft"
          color="green"
          size="semimedium"
          :show-title="false"
        />
      </span>
      <span
        class="text-light title-h5_m"
        v-text="$t('INTEGRATION_SETTINGS.CRM.TITLE')"
      />
    </span>
    <main-ticket-form />
  </div>
</template>

<script>
import MainTicketForm from './crmTicketForms/MainTicketForm';

export default {
  components: { MainTicketForm },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.raise-ticket-container {
  padding: $space-slab;

  .navigate-back {
    cursor: pointer;
    &:hover {
      span {
        color: $text-dark;
      }
    }
  }
}
</style>
