<template>
  <div class="view-box columns">
    <settings-header
      icon="megaphone"
      :header-title="$t('BROADCAST.HEADER')"
      :show-back-button="false"
    />
    <div class="settings-box flex-row">
      <div class="small-12">
        <div class="settings-button flex-row flex-justify--end">
          <woot-base-button front-icon="add" @click="addContact">
            Add Contact
          </woot-base-button>
          <div class="mg-left">
            <woot-base-button front-icon="download" @click="downloadOptOuts">
              Download list
            </woot-base-button>
          </div>
        </div>

        <div
          class="table-container lime-card-1dp custom-scroll mg-top"
          style="padding: 0; margin: 0.1rem 0.2rem 0.1rem 0.1rem"
        >
          <table class="woot-table">
            <thead>
              <th
                v-for="thHeader in $t('BROADCAST.OPT_OUT.TABLE_HEADER')"
                :key="thHeader"
              >
                {{ thHeader }}
              </th>
            </thead>
            <tbody v-if="!isEmpty">
              <tr
                v-for="contact in records"
                :key="contact.id"
                style="border-bottom:none"
              >
                <td class="body-b2 text-dark">
                  {{ contact.name }}
                </td>

                <td class="body-b2 text-dark">
                  {{ contact.phone_number || '-' }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="isEmpty" class="no-data flex-column-center">
            <div>
              <img
                :src="require('dashboard/assets/images/noData.svg')"
                alt="No Data"
                height="100px"
                width="100px"
              />
            </div>
            <p class="no-items-error-message">
              No Contacts Found
            </p>
          </div>
          <table-footer
            v-if="!isEmpty"
            :on-page-change="onPageChange"
            :current-page="Number(meta.currentPage)"
            :total-count="meta.count"
            :page-size="8"
          />
        </div>
      </div>
      <woot-loading-state v-if="uiFlags.isFetching" message="Loading" />
    </div>

    <woot-modal :show.sync="showContactModal" :on-close="hideContactModal">
      <woot-modal-header header-title="Add contact to be opted out" />
      <div style="padding: 0rem 2.4rem 2.4rem 2.4rem">
        <woot-input
          v-model.trim="contactNo"
          :class="{ error: $v.contactNo.$error }"
          :has-error="$v.contactNo.$error"
          :error="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.PHONE.ERROR')"
          class="medium-12 columns content-margin"
          label="Phone Number"
          placeholder="Add phone number to be opted out
            "
          @blur="$v.contactNo.$touch"
        />
        <div class="flex-row-justify-center" style="justify-content: flex-end">
          <woot-primary-button
            name="Add Contact"
            front-icon="add"
            :disabled="contactNo === '' || $v.contactNo.$error || !contactNo"
            @click="addPhoneNumber"
          />
        </div>
      </div>
    </woot-modal>
  </div>
</template>

<script>
import SettingsHeader from '../settings/SettingsHeader';
import broadcastMixin from 'dashboard/mixins/broadcast';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import alertMixin from 'shared/mixins/alertMixin';
import downloadMixin from 'shared/mixins/downloadMixin.js';
import { mapGetters } from 'vuex';
import validatePhoneNumber from '../../dashboard/settings/inbox/validations';

export default {
  components: {
    TableFooter,
    SettingsHeader,
  },
  mixins: [alertMixin, broadcastMixin, downloadMixin],
  data() {
    return {
      showContactModal: false,
      contactNo: null,
      invalidFileFormat: false,
    };
  },
  computed: {
    ...mapGetters({
      records: 'contacts/getContacts',
      optOutNumbers: 'contacts/getOptOutContacts',
      meta: 'contacts/getMeta',
      uiFlags: 'contacts/getUIFlags',
    }),
    pageParameter() {
      const selectedPageNumber = Number(this.$route.query?.page);
      return !Number.isNaN(selectedPageNumber) && selectedPageNumber >= 1
        ? selectedPageNumber
        : 1;
    },
    isEmpty() {
      return (
        this.records?.length === 1 &&
        this.records[0] &&
        this.records[0].length === 0
      );
    },
  },
  validations: {
    contactNo: { ...validatePhoneNumber.phone },
  },
  mounted() {
    this.$store.dispatch('contacts/get', {
      page: this.pageParameter,
      opt_outs: true,
    });
    this.$store.dispatch('contacts/getOptOut', {
      page: this.pageParameter,
      opt_outs: true,
    });
  },
  methods: {
    async downloadCSVTemplate() {
      const csv = 'phone_number';
      this.processCsv(csv, 'opt-out-template.csv');
    },
    reset() {
      this.contactNo = null;
    },
    onPageChange(page) {
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
      this.$store.dispatch('contacts/get', {
        opt_outs: true,
        page,
      });
    },
    downloadOptOuts() {
      let header = 'Sr. no, Opted Out Contacts \n';
      let csv = '';

      // eslint-disable-next-line no-unused-expressions
      this.optOutNumbers?.opt_outs.forEach((number, index) => {
        csv += index + 1 + ',' + number + '\n';
      });

      this.processCsv(header + csv, 'opted-out-numbers.csv');
    },
    addContact() {
      this.showContactModal = true;
    },
    hideContactModal() {
      this.showContactModal = false;
      this.reset();
    },
    addPhoneNumber() {
      this.$store
        .dispatch('markContactOptOut', {
          phone: this.contactNo,
          opt_in: false,
        })
        .then(() => {
          this.showAlert('Phone number added', 'success');
          this.$store.dispatch('contacts/get', {
            page: this.pageParameter,
            opt_outs: true,
          });
        })
        .catch(() => {
          this.showAlert('Something went wrong', 'error');
        });
      this.hideContactModal();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.settings-box {
  position: relative;
  height: calc(100% - 1.6rem);
  padding-top: $space-largest;
  padding-right: $space-normal;
  overflow: hidden;
}
.small-12 {
  display: flex;
  flex-direction: column;
}

.table-container {
  height: 100%;
  width: 100%;

  table.woot-table tr td {
    word-break: break-word;
  }
}
.grey-row {
  background: $neutral-grey-200;
}
.status-actions {
  border-bottom: 1px solid rgba($neutral-grey-600, 0.5);
  color: $neutral-grey-600;
  font-size: $font-size-default;
  font-weight: $font-weight-medium;
  line-height: $space-medium;
  justify-content: space-between;
  width: 100%;
  margin-bottom: $space-normal;
  padding-bottom: $space-normal;
}
.woot-table {
  margin-top: -1px;

  > thead {
    position: sticky;
    top: $zero;

    > th:first-child {
      padding-left: var(--space-medium);
      width: 50%;
    }
  }

  > tbody {
    > tr {
      cursor: pointer;

      &:hover {
        background: $pg-light-1;
      }

      > td {
        padding: var(--space-slab);

        &:first-child {
          padding-left: var(--space-medium);
        }
      }
    }
  }
}
.no-items-error-message {
  margin-top: $space-normal;
  text-align: center;
}
.load-more-conversations {
  border: 0;
  color: $pg-1-500;
  font-size: $font-size-small;
  margin: 0;
  padding: $space-normal;
  width: 100%;
}
.end-of-list-text {
  padding: $space-normal;
}
.loading-state {
  padding: $zero;
}
.reports-search {
  margin-bottom: $zero;
  background: none;
  border: 1px solid #8c8c8c;
  border-radius: $border-radius-smaller;
  min-width: 30rem;
}
.no-data {
  margin-top: $space-large;
  min-height: 18rem;
  text-align: center;
  justify-content: center;
}
</style>
