import axios from 'axios';
import { currentEnv } from './commons';

export const uploadAttachment = async data => {
  const LIMEKIT_URL =
    currentEnv() === 'staging'
      ? 'https://limekit-stgapp.limechat.ai'
      : process.env.LIMEKIT_URL;

  const formData = new FormData();
  formData.append('file', data);
  formData.append('name', data.name);
  const headers = { 'Content-Type': 'multipart/form-data' };

  return axios({
    method: 'post',
    url: `${LIMEKIT_URL}/crm/upload_ticket_attachment/`,
    data: formData,
    headers: headers,
  });
};
