import { MASK_SETTINGS, MASK_TYPES } from './constants';

export const MASKING_CHARACTER = 'x';
export const MIN_MASK_LENGTH = 4;
export const MAX_MASK_LENGTH = 10;

function limitMaskingLength(maskedString) {
  const length = maskedString.length;
  if (length < MIN_MASK_LENGTH) {
    return MASKING_CHARACTER.repeat(MIN_MASK_LENGTH);
  }
  if (length > MAX_MASK_LENGTH) {
    return MASKING_CHARACTER.repeat(MAX_MASK_LENGTH);
  }
  return maskedString;
}

function maskPhone(phone, setting) {
  const phoneSetting = setting?.phone || MASK_SETTINGS.PHONE.SHOW_LAST_X;
  const lastXDigits = setting?.lastXDigits || 4; // Default to 4 if not provided

  if (phoneSetting === MASK_SETTINGS.PHONE.SHOW_LAST_X) {
    const maskedPhone = phone
      .slice(-lastXDigits)
      .padStart(phone.length, MASKING_CHARACTER);
    return limitMaskingLength(maskedPhone);
  }
  if (phoneSetting === MASK_SETTINGS.PHONE.SHOW_FIRST_X) {
    const maskedPhone = phone
      .slice(0, lastXDigits)
      .padEnd(phone.length, MASKING_CHARACTER);
    return limitMaskingLength(maskedPhone);
  }
  if (phoneSetting === MASK_SETTINGS.PHONE.RANDOMLY_MASK_X) {
    const maskedPhone = phone.replace(/\d(?=\d{4})/g, MASKING_CHARACTER);
    return limitMaskingLength(maskedPhone);
  }
  return phone;
}

function maskEmail(email, setting) {
  const [local, domain] = email.split('@');
  const emailSetting = setting?.email || MASK_SETTINGS.EMAIL.DOMAIN_ONLY;

  // Function to create masked email
  const createMaskedEmail = maskedLocal => {
    return `${limitMaskingLength(maskedLocal)}${domain ? '@' + domain : ''}`;
  };

  switch (emailSetting) {
    case MASK_SETTINGS.EMAIL.UNIQUE_ID_ONLY: {
      const maskedLocal = local.replace(/.(?=.{2})/g, MASKING_CHARACTER);
      return createMaskedEmail(maskedLocal);
    }

    case MASK_SETTINGS.EMAIL.DOMAIN_ONLY: {
      const maskedLocal = local.replace(/./g, MASKING_CHARACTER);
      return createMaskedEmail(maskedLocal);
    }

    case MASK_SETTINGS.EMAIL.SHOW_X_CHARACTERS_UNIQUE_ID: {
      const numCharsToShow = setting?.numChars || 3; // Default to 3 characters if not provided
      const visiblePart = local.slice(0, numCharsToShow);
      const maskedPart = MASKING_CHARACTER.repeat(
        local.length - numCharsToShow
      );
      return createMaskedEmail(
        `${visiblePart}${limitMaskingLength(maskedPart)}`
      );
    }

    default:
      return email;
  }
}

function maskAddress(address, setting) {
  const addressSetting = setting?.address || MASK_SETTINGS.ADDRESS.DEFAULT;

  if (addressSetting === MASK_SETTINGS.ADDRESS.DEFAULT) {
    const maskedAddress =
      limitMaskingLength(MASKING_CHARACTER.repeat(MAX_MASK_LENGTH)) +
      address.slice(-4);
    return maskedAddress;
  }
  return address;
}

function maskOther(data) {
  const maskedData = data.replace(/./g, MASKING_CHARACTER);
  return limitMaskingLength(maskedData);
}

function maskName(name) {
  const maskedName = name.replace(/[^ ]/g, MASKING_CHARACTER);
  return limitMaskingLength(maskedName);
}

export function getMaskedData(data, maskType, maskSetting) {
  if (!data) return '';
  switch (maskType) {
    case MASK_TYPES.PHONE:
      return maskPhone(data, maskSetting);
    case MASK_TYPES.EMAIL:
      return maskEmail(data, maskSetting);
    case MASK_TYPES.ADDRESS:
      return maskAddress(data, maskSetting);
    case MASK_TYPES.NAME:
      return maskName(data);
    default:
      return maskOther(data ?? '');
  }
}
