import { mapGetters } from 'vuex';

import isAdmin from 'dashboard/mixins/isAdmin';
import wootConstants from 'dashboard/constants';

export default {
  mixins: [isAdmin],
  computed: {
    ...mapGetters({
      accountUiFlags: 'accounts/getUIFlags',
    }),
    assigneeTabsList() {
      return this.$t('CHAT_LIST.ASSIGNEE_TYPE_TABS').reduce((acc, item) => {
        if (!this.isAdmin) {
          const {
            accountUiFlags: { isAllTabHidden, isQueuedTabHidden },
          } = this;

          const isAllTab = item.KEY === wootConstants.ASSIGNEE_TYPE.ALL;

          if (isAllTabHidden && isAllTab) return acc;

          const isQueuedTab =
            item.KEY === wootConstants.ASSIGNEE_TYPE.UNASSIGNED;

          if (isQueuedTabHidden && isQueuedTab) return acc;
        }

        acc.push({
          key: item.KEY,
          name: item.NAME,
        });

        return acc;
      }, []);
    },
  },
};
