<template>
  <div
    class="column padding-container settings-box custom-scroll"
    style="padding-top: 0px"
  >
    <div class="filter-pane">
      <div class="flex-space-between" style="margin-top: 0.5rem">
        <div class="flex-row-justify-center analytics-filters">
          <date-range-selector
            :default-selection="2"
            @date-range-change="onDateRangeChange"
          />
          <inbox-filter
            value="All Inboxes"
            :allow-multi-select="true"
            :exclude-inbox-types="excludedInboxTypes"
            :toggle-filter="changeInboxId"
            @selected-option="logSelectedInbox"
          />
          <tags-filter
            value="All Tags"
            :toggle-filter="changeLabelId"
            @selected-option="logSelectedTag"
          />
          <woot-single-selector
            variant="secondary"
            :option-list="agentList"
            :default-option="selectedAgent"
            :custom-style="{ width: 'auto' }"
            :show-selected-text="false"
            @click="changeAgentId"
          />
          <woot-single-selector
            variant="secondary"
            size="medium"
            :disabled="false"
            :option-list="optionList"
            :default-option="selectedWorkingHour"
            :show-selected-text="false"
            :custom-style="{ width: 'auto' }"
            @click="changeWorkingHourType"
          />
        </div>
        <div>
          <woot-primary-button
            name="Performance Report"
            front-icon="email"
            @click="download"
          />
        </div>
      </div>
    </div>
    <div class="column settings-box custom-scroll" style="padding-top: 0px">
      <div class="settings-container columns custom-scroll">
        <div v-if="metricCardUIFlag" class="metrics-card-container">
          <metrics-card-skeleton
            v-for="(item, index) in metricsCount"
            :key="index"
          />
        </div>
        <div v-else class="metrics-card-container">
          <metrics-card
            v-for="(item, index) in metricsCount"
            :key="index"
            :title="item.title"
            :sub-title="item.sub"
            :actual="item.actual"
          />
        </div>

        <div v-if="periodicChartUIFlag">
          <line-chart-skeleton />
        </div>
        <div v-else class="medium-12 bottom-container">
          <div class="lime-card-1dp medium-12">
            <div style="position: relative">
              <span class="table-title">Periodic Agent Performance</span>
              <line-chart
                :series="chartSeries"
                :categories="chartCategories"
                :colors="chartColors"
                :toolbar-enable="false"
                label-append="Min"
              />
            </div>
          </div>
        </div>
        <div v-if="meanTableUIFlag">
          <table-skeleton />
        </div>
        <div v-else class="medium-12 bottom-container">
          <woot-table
            title="Agents Performance (Mean)"
            class="lime-card-1dp"
            :header-list="$t('ANALYTICS.PERFORMANCE_SUMMARY_HEADER')"
            :table-data="formattedTableData"
            :is-sortable="true"
            column-width-one="20%"
            column-width-two="15%"
          />
        </div>
        <div v-if="slaTableUIFlag">
          <table-skeleton />
        </div>
        <div v-else class="medium-12 bottom-container">
          <woot-table
            title="SLA Violation (%)"
            class="lime-card-1dp"
            :header-list="$t('ANALYTICS.AGENT_SLA_TABLE_HEADER')"
            :table-data="formattedSlaTableData"
            :is-sortable="true"
            column-width-one="20%"
            column-width-two="15%"
          />
        </div>
        <div v-if="msgBlockUIFlag" class="bottom-container">
          <line-chart-skeleton />
        </div>
        <div v-else class="medium-12 bottom-container">
          <div class="lime-card-1dp medium-12">
            <column-chart
              title="Total Message Blocks sent by Agents"
              :series="messageBlockSeries"
              :categories="messageBlockCategories"
              :colors="messageBlockColor"
              :has-title="true"
              :border-radius="10"
              column-width="30%"
              :chart-offset-y="30"
            />
          </div>
        </div>

        <div v-if="hourlyUIFlag">
          <line-chart-skeleton />
          <div class="flex-space-between">
            <div style="width: 49%">
              <line-chart-skeleton />
            </div>
            <div style="width: 49%">
              <line-chart-skeleton />
            </div>
          </div>
        </div>
        <div v-else class="lime-card-1dp medium-12 bottom-container">
          <div class="flex-space-between" style="height: 40px">
            <span class="table-title">Agents Hourly Performance</span>
          </div>
          <column-chart
            :series="columnChartSeries"
            :categories="columnChartCategories"
            :colors="columnChartColors"
          />
          <div class="chart-card mg-bottom--medium">
            <div class="flex-space-between">
              <span class="table-title smaller-font">
                First Response Time
              </span>
              <div
                v-tooltip="
                  'First Response time indicates the time taken by the agent to respond to a chat'
                "
              >
                <icons name="info" size="medium" color="grey" />
              </div>
            </div>
            <line-chart
              class="chartOverflowVisible"
              :series="firstResponseTimeSeries"
              :categories="firstResponseTimeCategories"
              :colors="firstResponseChartColors"
              :toolbar-enable="false"
              :show-tooltip="true"
              :width="510"
              label-append="Min"
            />
          </div>
          <div class="chart-card">
            <div class="flex-space-between">
              <span class="table-title smaller-font">Unresolved Tickets</span>

              <div
                v-tooltip="
                  'Tickets which are unresolved in that time for the selected agent'
                "
              >
                <icons name="info" size="medium" color="grey" />
              </div>
            </div>
            <line-chart
              class="chartOverflowVisible"
              :series="pendingChatSeries"
              :categories="pendingChatCategories"
              :colors="pendingChatChartColors"
              :toolbar-enable="false"
              :show-tooltip="true"
              :width="510"
              label-append="Min"
            />
          </div>
        </div>

        <div v-if="convStateUIFlag">
          <table-skeleton />
        </div>
        <agent-conversation-states
          v-else
          :agent-conv-states="agentConvStates"
          :selected-date-range="{ startDate, endDate }"
        />
        <woot-modal :show.sync="downloading" :on-close="hidePopup">
          <div class="download-note">
            <img
              src="https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
            />
            <div
              v-html="
                $t('ANALYTICS.DOWNLOAD_REPORT.EMAIL', { email: userEmail })
              "
            />
          </div>
        </woot-modal>
        <woot-modal :show.sync="downloadError" :on-close="hidePopup">
          <div class="download-note">
            <div>{{ $t('ANALYTICS.DOWNLOAD_REPORT.REPORT_FAIL') }}</div>
          </div>
        </woot-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LineChartSkeleton from 'dashboard/components/LineChartSkeleton.vue';
import TableSkeleton from '../components/TableSkeleton.vue';
import MetricsCardSkeleton from '../components/MetricsCardSkeleton.vue';
import MetricsCard from '../components/MetricsCard';
import LineChart from 'dashboard/components/widgets/chart/LineChart';
import ColumnChart from 'dashboard/components/widgets/chart/ColumnChart';
import DateRangeSelector from '../components/DateRangeSelector';
import AgentConversationStates from '../components/AgentConversationState';
import InboxFilter from 'dashboard/components/widgets/conversation/filter/InboxFilter';
import TagsFilter from 'dashboard/components/widgets/conversation/filter/TagsFilter';

import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import timeMixin from 'dashboard/mixins/time';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

import { getUserEmail } from 'dashboard/helper/cookieHelper';

export default {
  components: {
    MetricsCard,
    LineChart,
    ColumnChart,
    DateRangeSelector,
    LineChartSkeleton,
    TableSkeleton,
    MetricsCardSkeleton,
    AgentConversationStates,
    InboxFilter,
    TagsFilter,
  },
  mixins: [alertMixin, timeMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      dateRange: '1',
      startDate: this.formatDate(new Date().getTime()).replaceAll('-', '/'),
      endDate: this.formatDate(new Date().getTime()).replaceAll('-', '/'),
      agentPerformanceMetrics: null,
      agentSlaData: null,
      agentLineGraphData: null,
      agentTableData: null,
      agentChartsData: null,
      agentConvStates: null,
      messageBlockChartData: null,
      selectedAgentId: null,
      downloading: false,
      downloadError: false,
      working_hour_type: [
        {
          NAME: 'All Time             ',
          KEY: 'all',
        },
        {
          NAME: 'Outside Working Hours',
          KEY: 'outside',
        },
        {
          NAME: 'Inside Working Hours',
          KEY: 'inside',
        },
      ],
      isActive: [false],
      selectedInboxes: [],
      selectedLabels: [],
      selectedWorkingHourType: 'all',
    };
  },
  computed: {
    ...mapGetters({
      agents: 'agents/getAgents',
      inboxes: 'inboxes/getInboxes',
      accountLabels: 'labels/getLabelsWithoutGroups',
      metricCardUIFlag: 'getPerformanceMetricUIFlag',
      periodicChartUIFlag: 'getPeriodicPerformanceUIFlag',
      meanTableUIFlag: 'getMeanPerformanceUIFlag',
      slaTableUIFlag: 'getSlaTableUIFlag',
      hourlyUIFlag: 'getHourlyPerformanceUIFlag',
      msgBlockUIFlag: 'getMsgBlockUIFlag',
      convStateUIFlag: 'getAgentConversationStateUIFlag',
    }),
    headerList() {
      return this.$t('ANALYTICS.ATTENDANCE_HEADER');
    },
    excludedInboxTypes() {
      return [INBOX_TYPES.KNOWLARITY];
    },
    optionList() {
      return this.working_hour_type.map(item => {
        return {
          key: item.KEY,
          name: item.NAME,
        };
      });
    },
    selectedAgent() {
      let selectedIndex = this.agentList.findIndex(
        a => a.id === this.selectedAgentId
      );

      return selectedIndex > 0
        ? this.agentList[selectedIndex].name
        : this.agentList[0].name;
    },
    selectedWorkingHour() {
      let selectedIndex = this.optionList.findIndex(
        a => a.key === this.selectedWorkingHourType
      );
      return selectedIndex > 0
        ? this.optionList[selectedIndex].name
        : this.optionList[0].name;
    },
    metricsCount() {
      return [
        {
          title: this.formatMetricWithUnits(this.agentPerformanceMetrics?.frt),
          sub: 'First Response Time',
          actual: this.$t('ANALYTICS.TOOLTIP.AGENT_PERFORMANCE.FRT'),
        },
        {
          title: this.formatMetricWithUnits(
            this.agentPerformanceMetrics?.resolution_time
          ),
          sub: 'Resolution Time',
          actual: this.$t(
            'ANALYTICS.TOOLTIP.AGENT_PERFORMANCE.RESOLUTION_TIME'
          ),
        },
        {
          title: this.nFormatter(this.agentPerformanceMetrics?.no_of_conv),
          sub: 'Tickets Assigned',
          actual: this.$t('ANALYTICS.TOOLTIP.AGENT_PERFORMANCE.NO_OF_CONV'),
        },
        {
          title: this.formatMetricWithUnits(
            this.agentPerformanceMetrics?.wait_time
          ),
          sub: 'Wait Time',
          actual: this.$t('ANALYTICS.TOOLTIP.AGENT_PERFORMANCE.WAIT_TIME'),
        },
      ];
    },
    chartSeries() {
      return this.agentLineGraphData?.yaxis;
    },
    chartCategories() {
      if (this.startDate === this.endDate) {
        if (this.agentLineGraphData?.xaxis) {
          return this.agentLineGraphData?.xaxis.map(x =>
            this.timeToAMPM(x.substring(x.length - 8))
          );
        }
        return this.agentLineGraphData?.xaxis?.map(date =>
          this.dateToWords(date)
        );
      }
      return this.agentLineGraphData?.xaxis?.map(date =>
        this.dateToWords(date)
      );
    },
    chartColors() {
      return ['#6BAC1B', '#1B83AC', '#9270CA', '#5D7092'];
    },
    columnChartSeries() {
      let formattedSeries = [
        {
          name: 'Assigned Ticket',
          data: this.agentChartsData?.assigned_resolved_conv_data?.yaxis.filter(
            item => item.name === 'Assigned Conversation'
          )[0].data,
        },
        {
          name: 'Resolved Ticket',
          data: this.agentChartsData?.assigned_resolved_conv_data?.yaxis.filter(
            item => item.name === 'Resolved Conversation'
          )[0].data,
        },
      ];

      return formattedSeries || [];
    },
    columnChartColors() {
      return ['#6BAC1B', '#1B83AC'];
    },
    columnChartCategories() {
      return this.agentChartsData?.assigned_resolved_conv_data?.xaxis.map(
        range => this.timeRangeToAMPMRange(range)
      );
    },
    firstResponseTimeSeries() {
      return [
        {
          name: 'First Response Time',
          data: this.agentChartsData?.frt?.yaxis.map(yaxis => yaxis.toFixed(2)),
        },
      ];
    },
    firstResponseTimeCategories() {
      if (this.agentChartsData?.frt?.xaxis)
        return this.agentChartsData?.frt?.xaxis.map(range =>
          this.timeRangeToAMPMRange(range)
        );
      return [];
    },
    firstResponseChartColors() {
      return ['#6BAC1B'];
    },
    pendingChatSeries() {
      return [
        {
          name: 'Unresolved Tickets',
          data: this.agentChartsData?.pending_chats?.yaxis,
        },
      ];
    },
    pendingChatCategories() {
      if (this.agentChartsData?.pending_chats?.xaxis)
        return this.agentChartsData?.pending_chats?.xaxis.map(range =>
          this.timeRangeToAMPMRange(range)
        );
      return [];
    },
    pendingChatChartColors() {
      return ['#1B83AC'];
    },
    messageBlockSeries() {
      return [
        {
          name: 'Message Block Sent by Agents',
          data: this.messageBlockChartData?.agent_turns,
        },
      ];
    },
    messageBlockCategories() {
      return this.messageBlockChartData?.agent_names;
    },
    messageBlockColor() {
      return ['#6BAC1B'];
    },
    formattedTableData() {
      let formattedData = this.agentTableData?.map(row => {
        let newRow = {};
        newRow.name = row.name;
        newRow.frt = this.formatMetricWithUnits(row.frt);
        newRow.resolution_time = this.formatMetricWithUnits(
          row.resolution_time
        );
        newRow.wait_time = this.formatMetricWithUnits(row.wait_time);

        newRow.no_of_conv = row.no_of_conv;
        return newRow;
      });
      return this.agentTableData ? formattedData : [];
    },
    formattedSlaTableData() {
      let formattedData = this.agentSlaData?.map(row => {
        let newRow = {};
        newRow.name = row.name || 'N/A';
        newRow.frt = row.frt + '%';
        newRow.nrt = row.nrt + '%';
        newRow.rt = row.rt + '%';

        return newRow;
      });
      return this.agentSlaData ? formattedData : [];
    },
    agentList() {
      let agentArray = [
        {
          name: this.$t('ANALYTICS.FILTER_SELECTION.AGENT.PLACEHOLDER'),
          id: null,
        },
        ...this.agents,
      ];
      return agentArray.map(agent => ({
        id: agent.id,
        name: agent.name,
      }));
    },
    userEmail() {
      return getUserEmail();
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
  },
  methods: {
    formatMetricWithUnits(metric) {
      if (!metric) return 0;
      return this.formattedDuration(
        this.secondsToDuration(parseInt(metric * 60, 10))
      );
    },
    async download() {
      this.mix_panel_clicked_report_menu_item(
        'agent_performance',
        'performance_report'
      );
      this.downloading = true;
      await this.$store
        .dispatch('getAnalyticsReport', {
          start_date: this.startDate,
          end_date: this.endDate,
          query_type: 'download_overall_report',
          email: this.userEmail,
          labels: this.selectedLabels,
          inboxes: this.selectedInboxes,
          working_hours: this.selectedWorkingHourType,
          scope: 'agents',
          agent_id: this.selectedAgentId,
        })
        .then(() => {})
        .catch(() => {
          this.downloading = false;
          this.downloadError = true;
        });
    },
    changeLabelId(val, inboxes, id) {
      this.selectedLabels = id;
      this.getMetrics();
      this.getAgentSlaTableData();
      this.getGraphData();
      this.getTableData();
      this.getHourlyData();
      this.getConvStat();
      this.getAgentMessageBlock();
    },

    hidePopup() {
      this.downloading = false;
      this.downloadError = false;
    },
    onDateRangeChange({ from, to, date_range_name, isFiredOnMount }) {
      this.startDate = this.formatDate(from * 1000).replaceAll('-', '/');
      this.endDate = this.formatDate(to * 1000).replaceAll('-', '/');
      this.getMetrics();
      this.getAgentSlaTableData();
      this.getGraphData();
      this.getTableData();
      this.getHourlyData();
      this.getConvStat();
      this.getAgentMessageBlock();
      if (!isFiredOnMount) {
        this.mix_panel_clicked_date_filter_menu_item(
          'agent_performance',
          date_range_name
        );
      }
    },
    changeAgentId(val) {
      this.selectedAgentId = val.id;
      const filteredAgent = this.agents.find(agent => agent.id === val.id);
      this.getMetrics();
      this.getAgentSlaTableData();
      this.getGraphData();
      this.getTableData();
      this.getHourlyData();
      this.getConvStat();
      this.getAgentMessageBlock();
      this.mix_panel_clicked_agent_filter_menu_item(
        'agent_performance',
        filteredAgent.role
      );
    },
    changeWorkingHourType(val) {
      this.selectedWorkingHourType = val.key;
      this.getMetrics();
      this.getAgentSlaTableData();
      this.getGraphData();
      this.getTableData();
      this.getHourlyData();
      this.getConvStat();
      this.getAgentMessageBlock();
      this.mix_panel_clicked_working_hours_menu_item(
        'agent_performance',
        val.key
      );
    },

    nFormatter(num) {
      if (!num) return 0;

      if (num > 9999 && num < 1_000_000) {
        return (num / 1000).toFixed(1) + 'K';
      }
      if (num > 1000000) {
        return (num / 1000000).toFixed(0) + 'M';
      }
      if (num < 9999) {
        return Math.round(num * 10) / 10;
      }
      return Math.round(num * 10) / 10;
    },

    changeInboxId(_, __, id) {
      this.selectedInboxes = id;
      this.getMetrics();
      this.getAgentSlaTableData();
      this.getGraphData();
      this.getTableData();
      this.getHourlyData();
      this.getConvStat();
      this.getAgentMessageBlock();
    },

    logSelectedInbox(selectedOptionValue) {
      const filteredInbox = this.inboxes.find(
        inbox => inbox.name === selectedOptionValue
      );

      this.mix_panel_clicked_inbox_filter_menu_item(
        'agent_performance',
        filteredInbox.channel_type
      );
    },

    logSelectedTag(selectedOptionValue) {
      const filteredInbox = this.accountLabels.find(
        tag => tag.title === selectedOptionValue
      );

      this.mix_panel_clicked_tag_filter_menu_item(
        'agent_performance',
        filteredInbox.label_type
      );
    },
    async getMetrics() {
      await this.$store
        .dispatch('getAgentPerformanceStats', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          agentId: this.selectedAgentId,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          this.agentPerformanceMetrics = data;
        });
    },
    async getAgentSlaTableData() {
      await this.$store
        .dispatch('getAgentSlaTableData', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          agentId: this.selectedAgentId,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          this.agentSlaData = data;
        });
    },
    async getGraphData() {
      this.$store
        .dispatch('getPeriodicAgentsPerformance', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          agentId: this.selectedAgentId,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          this.agentLineGraphData = data;
        });
    },
    async getTableData() {
      this.$store
        .dispatch('getAgentPerformanceLogs', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          agentId: this.selectedAgentId,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          this.agentTableData = data;
        });
    },
    async getHourlyData() {
      this.$store
        .dispatch('getAgentHourlyPerformance', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          agentId: this.selectedAgentId,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          this.agentChartsData = data;
        });
    },
    async getConvStat() {
      this.$store
        .dispatch('getAgentWiseConvStats', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          agentId: this.selectedAgentId,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          this.agentConvStates = data;
        });
    },
    async getAgentMessageBlock() {
      this.$store
        .dispatch('getAgentPerformanceMessageBlocks', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          agentId: this.selectedAgentId,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          this.messageBlockChartData = data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-container {
  display: flex;
  flex-direction: column;
}
.chart-card {
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius;
  padding: $space-one $space-medium;
}

.filter-pane {
  position: -webkit-sticky;
  position: sticky;
  background-color: #faf9f5;
  top: $zero;
  z-index: 1000;
  padding: $space-normal $zero;
}
.metrics-card-container {
  display: flex;
  flex-direction: row;
  margin: $space-slab $space-micro $space-normal;
}
.bottom-container {
  display: flex;
  flex-direction: column;
  margin: $zero $space-micro $space-normal;
}
.cancel-button {
  padding: $space-small;
  margin-bottom: $space-normal;
  cursor: pointer;
}
.selector-button {
  background: $neutral-white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: $neutral-grey-800;
  font-size: $font-size-small;
  line-height: 22px;
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius;
  padding: $space-small;
  margin-bottom: $space-normal;
  cursor: pointer;
  &:focus {
    border: 1px solid $pg-1-500;
    outline: none;
  }
}
.section-title {
  font-size: $font-size-medium;
  line-height: $space-medium;
  color: $neutral-grey-600;
  margin-bottom: $space-slab;
}
.button {
  margin-bottom: auto;
}

.margin-right {
  margin-left: $space-medium;
}

.analytics-filters {
  justify-content: flex-start !important;
  max-width: 90%;
  flex-wrap: wrap;
  gap: $space-slab;
}
.table-title {
  font-size: $font-size-medium;
  line-height: 2.6rem;
  color: $neutral-grey-800;
  font-weight: $font-weight-medium;
  margin: $space-slab $zero;
}

.card-padding {
  padding: $space-two $space-slab;
}

.tooltip-position {
  position: absolute;
  top: $zero;
  right: $zero;
}

.mr-right {
  margin-right: $space-normal;
}

.smaller-font {
  font-size: $font-size-small;
}
.download-note {
  width: 100%;
  min-height: 30vh;
  height: auto;
  overflow: hidden;
  display: flex;
  padding: 1rem 0.3rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div {
    font-size: $font-size-medium;
    margin: 1rem 2rem;
  }

  span {
    color: $pg-1;
  }
  img {
    width: 40%;
    height: 40%;
    margin: 3rem 2rem;
  }
}

.chartOverflowVisible::v-deep .apexcharts-svg {
  overflow: visible;
}
</style>
