<template>
  <div class="table-container">
    <data-table
      :actions="actionsList"
      collapse-actions
      :columns="tableColumns"
      :table-data="customFieldsList"
      :loading="loading"
      :enable-animations="customFieldsList.length <= 10"
      _class="scrollable-table custom-scroll"
      @select="viewCustomField"
      @deleteCustomField="openDeletePopup"
    >
      <template v-slot:zero-state>
        <table-zero-state
          v-if="!customFieldsList.length"
          :title="$t('CUSTOM_TICKET_FIELDS.ZERO_STATE.TITLE')"
          :subtitle="$t('CUSTOM_TICKET_FIELDS.ZERO_STATE.SUBTITLE')"
          asset-link="/brand-assets/inbox_zero_state.svg"
        >
          <div class="flex-row flex-align gap--small">
            <woot-base-button
              size="medium"
              front-icon="plus"
              icon-view-box="0 0 20 20"
              @click="openAddPopup"
            >
              {{ $t('CUSTOM_TICKET_FIELDS.HEADER_BTN_TXT') }}
            </woot-base-button>
          </div>
        </table-zero-state>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from 'dashboard/components/widgets/table/DataTable';
import TableZeroState from 'dashboard/components/ui/settings/TableZeroState';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    DataTable,
    TableZeroState,
  },
  mixins: [alertMixin],
  props: {
    search: {
      type: String,
      default: '',
    },
    actionsList: {
      type: Array,
      default: () => [],
    },
    tableColumns: {
      type: Array,
      default: () => [],
    },
    customFieldsList: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    viewCustomField(customField) {
      this.$emit('viewCustomField', customField);
    },
    openAddPopup() {
      this.$emit('openAddPopup');
    },
    openDeletePopup() {
      this.$emit('openDeletePopup');
    },
  },
};
</script>

<style>
.custom-fields .table-container {
  height: 100%;
}
</style>
