<template>
  <div class="contact-results-wrapper custom-scroll">
    <loading-state
      v-if="isLoading"
      style="height: 20rem"
      height="80"
      message="Searching..."
    />
    <div v-else-if="showZeroState" class="zero-state capital_c3 text-light">
      No contacts available
    </div>
    <woot-base-button
      v-if="showZeroState"
      variant="secondary"
      size="small"
      class="load-more-button"
      @click="onAddNewContact"
    >
      Add This {{ inboxType === 'email' ? 'Email' : 'Number' }}
    </woot-base-button>
    <template v-else>
      <span class="results-heading capital_c3 text-light">
        Available Contacts
      </span>
      <div class="contact-results">
        <contact-entry
          v-for="{ id, phone_number, email, name, opt_in } in contacts"
          :key="id"
          :contact-id="id"
          :contact-name="name"
          :contact-phone="phone_number"
          :email="email"
          :is-opt-in="opt_in"
          :inbox-type="inboxType"
          @onSelectContact="onSelectContact"
        />
      </div>
      <woot-base-button
        v-if="canLoadMoreResults"
        variant="tertiary"
        size="small"
        class="load-more-button"
        @click="$emit('onPageChange')"
      >
        Load More Results
      </woot-base-button>
    </template>
  </div>
</template>
<script>
import clickOutsideMixin from 'dashboard/mixins/clickOutsideMixin';

import LoadingState from 'dashboard/components/ui/LoadingState';
import ContactEntry from './ContactEntry';

export default {
  components: {
    LoadingState,
    ContactEntry,
  },
  mixins: [clickOutsideMixin],
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    onSelectContact: {
      type: Function,
      default: () => null,
    },
    onAddNewContact: {
      type: Function,
      default: () => null,
    },
    closePanel: {
      type: Function,
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    canLoadMoreResults: {
      type: Boolean,
      default: true,
    },
    inboxType: {
      type: String,
      required: true,
    },
  },
  computed: {
    showZeroState() {
      return this.contacts.length === 0;
    },
  },
  mounted() {
    this.addClickOutsideListener();
  },
  methods: {
    hideDropdown() {
      this.closePanel();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.contact-results {
  &-wrapper {
    @include shadow-dark;
    background: $color-white;
    border: 1px solid $neutral-grey-400;
    border-radius: $border-radius;
    max-height: 20rem;
    overflow: overlay;
    padding: $space-small;
    position: absolute;
    top: $space-four;
    width: 32.6rem;
    z-index: 1;

    .results-heading {
      padding: $zero $space-slab;
    }

    .zero-state {
      width: 100%;
      cursor: pointer;
      padding: $space-small;
      text-align: center;
      background-color: $neutral-grey-200;
      border-radius: $border-radius-smaller;
    }

    .load-more-button {
      width: 100%;
      margin-top: $space-small;
    }
  }
}
</style>
