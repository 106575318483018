<template>
  <div class="table-container">
    <data-table
      :actions="actionsList"
      :columns="tableColumns"
      :table-data="templateList"
      :loading="uiFlags.fetchingTemplateList"
      :enable-animations="templateList.length <= 10"
      _class="scrollable-table custom-scroll"
      @edit="onEdit"
      @delete="onDelete"
      @select="OnSelectTemplate"
    >
      <template v-slot:table-features>
        <!-- search -->
        <search-box
          :placeholder="$t('TEMPLATE.SEARCH_TXT')"
          :value="search"
          custom-class="search-box"
          @setSearch="setSearch"
        />
        <!-- sort by inbox -->
        <woot-single-selector
          v-if="inboxList.length && showInbox"
          variant="secondary"
          size="medium"
          :default-option="selectedInboxName"
          :front-icon="inboxFilterIcon ? inboxFilterIcon : 'inbox'"
          :option-list="inboxList"
          :show-selected-text="false"
          :dropdown-container-style="{
            height: '4.4rem',
            'max-width': '15rem',
          }"
          :button-style="{ 'border-radius': '0.4rem' }"
          :custom-style="{ left: 0 }"
          @click="applyInboxFilter"
        />
      </template>
      <template v-slot:zero-state>
        <table-zero-state
          v-if="!templateList.length"
          title="No template added till now"
          subtitle="Click on the Sync Button to sync templates"
          asset-link="/brand-assets/agent_zero_state.svg"
        >
        </table-zero-state>
      </template>
    </data-table>
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import inboxMixin from 'dashboard/mixins/inbox';
import DataTable from 'dashboard/components/widgets/table/DataTable';
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import TableZeroState from 'dashboard/components/ui/settings/TableZeroState';

import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: { DataTable, SearchBox, TableZeroState },
  mixins: [inboxMixin, mixPanelAnalyticsMixin],
  props: {
    actionsList: {
      type: Array,
      default: () => [],
    },
    inboxId: {
      type: Number,
      default: 0,
    },
    showInbox: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: '',
      selectedInbox: { id: 0, name: 'All' },
    };
  },
  computed: {
    ...mapGetters({
      records: 'getTemplates',
      uiFlags: 'getTemplateUIFlags',
      inboxes: 'inboxes/getInboxes',
    }),
    tableColumns() {
      const columns = [
        {
          label: 'Template',
          prop: 'short_code',
          sortable: true,
          component: 'ShowTemplateResponse',
          width: '35%',
        },
        {
          label: 'ID',
          prop: 'display_id',
          align: 'center',
          // custom class to override styles for table element
          customClass: 'text-light title-h5_m',
          width: '10%',
        },
      ];
      if (this.showInbox) {
        columns.splice(1, 0, {
          label: 'Inbox',
          prop: 'inbox_id',
          component: 'ShowInboxName',
          align: 'center',
        });
      }
      return columns;
    },
    templateList() {
      let templateList = [...this.records];

      if (this.selectedInbox.name !== 'All')
        templateList = templateList.filter(
          template => template.inbox_id === this.selectedInbox.id
        );

      templateList = templateList.filter(item => {
        return (
          item.id.toString().search(this.search) > -1 ||
          item.short_code?.toLowerCase().search(this.search?.toLowerCase()) >
            -1 ||
          item.body?.toLowerCase().search(this.search?.toLowerCase()) > -1 ||
          item.template_type?.toLowerCase().search(this.search?.toLowerCase()) >
            -1
        );
      });

      return templateList;
    },
    selectedInboxName() {
      return this.selectedInbox.name === 'All'
        ? 'All Inboxes'
        : this.selectedInbox.name;
    },
    inboxList() {
      let inboxesData = [];

      inboxesData = this.inboxes.reduce((ibList, ib) => {
        let { name: icon, color } = this.computedInboxClass(ib);
        ibList.push({
          id: ib.id,
          name: ib.name,
          type: ib.channel_type,
          icon: icon,
          color: color,
        });

        return ibList;
      }, []);

      inboxesData = [{ id: 0, name: 'All' }, ...inboxesData];

      return inboxesData;
    },
    inboxFilterIcon() {
      let inboxIcon = this.inboxes.filter(
        inbox => this.selectedInbox.name === inbox.name
      );
      return this.computedInboxClass(inboxIcon[0]).name;
    },
  },
  watch: {
    inboxId: 'onApplyInboxFilter',
  },
  created() {
    axios
      .all([
        this.$store.dispatch('getTemplate'),
        this.$store.dispatch('inboxes/get'),
      ])
      .then(this.onApplyInboxFilter);
  },
  methods: {
    onApplyInboxFilter() {
      const inbox = this.searchInboxById(this.inboxId);
      if (inbox) this.applyInboxFilter(inbox);
    },
    setSearch(value) {
      this.search = value;
      this.mix_panel_searched_settings_template();
    },
    searchInboxById(id) {
      return this.inboxList.find(inbox => inbox.id === id);
    },
    applyInboxFilter(val) {
      this.selectedInbox = val;
      this.mix_panel_clicked_inbox_filter_menu_item(
        'Template Settings',
        val.type
      );
    },
    onEdit(item) {
      this.$emit('edit', item);
    },
    onDelete(item) {
      this.$emit('delete', item);
    },
    OnSelectTemplate(template) {
      this.$emit('select', template);
    },
    computedInboxClass(inbox) {
      if (inbox && inbox?.channel_type) {
        const { channel_type, phone_number, instagram_add_on } = inbox;
        const classByType = this.getInboxClassByType(
          channel_type,
          phone_number,
          '',
          instagram_add_on
        );

        return classByType;
      }

      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.table-container {
  height: 100%;

  table.woot-table tr td {
    word-break: break-word;
  }

  .search-box {
    background: none;
    margin-bottom: $zero;
    width: $space-four * 10;
  }
}
</style>
