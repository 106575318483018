<template>
  <router-link
    :is="'router-link'"
    :to="menuItem.toState"
    tag="li"
    active-class="active"
    :style="{ position: 'relative' }"
    :class="computedClass"
    @click.native="InlineButtonClickHandler"
  >
    <span>
      <a
        v-tooltip.right="$t(`SIDEBAR.${menuItem.label}`)"
        :title="menuItem.toolTip"
        class="side-menu-collapsed"
        :class="menuItem.toStateName"
        data-tooltip
        aria-haspopup="true"
      >
        <div class="wrap" style="justify-content: center">
          <icons
            :name="isActiveIcon ? menuItem.activeIcon : menuItem.icon"
            color="white"
            size="mediumlarge"
            :show-title="false"
          />
        </div>
      </a>
    </span>
    <div
      v-if="menuItem.isNew"
      class="statbadge statbadge-dot statbadge--danger"
    />
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';

import adminMixin from '../../mixins/isAdmin';
import inboxMixin from 'dashboard/mixins/inbox';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  mixins: [adminMixin, inboxMixin, mixPanelAnalyticsMixin],
  props: {
    menuItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters({
      activeInbox: 'getSelectedInbox',
      inboxes: 'inboxes/getInboxes',
    }),
    computedClass() {
      // If active Inbox is present
      // donot highlight conversations
      if (this.activeInbox) return ' ';

      const routes = {
        inbox_conversation: true,
        overview_panel: true,
        orders_panel: true,
        create_order_cart: true,
        products_panel: true,
      };

      if (
        this.menuItem.toStateName === 'home' &&
        routes[this.$store.state.route.name]
      )
        return 'active';
      return ' ';
    },
    isActiveIcon() {
      return (
        this.$route.name === this.menuItem.toStateName ||
        this.$router.history.current.path.includes(
          this.menuItem.label.toLowerCase()
        )
      );
    },
  },
  methods: {
    InlineButtonClickHandler() {
      this.mix_panel_clicked_side_nav_menu_item(this.menuItem.label);
    },
  },
};
</script>
