<template>
  <div class="carousel-wrapper">
    <template v-for="(node, index) of filteredPaginatedComponents">
      <node-renderer
        v-show="index === currIndex"
        :key="node.key || index"
        :value="node"
        tag="div"
      />
    </template>
    <template v-if="canShowPagination">
      <div
        class="carousel-pagination carousel-pagination--left flex-row flex-align gap-normal"
      >
        <span
          class="title-h5_m text-light"
          v-text="`${currIndex + 1}/${numberOfComponents}`"
        />
      </div>
      <div
        class="carousel-pagination carousel-pagination--right flex-row flex-align gap--normal"
      >
        <woot-base-button
          tag="span"
          size="small"
          variant="tertiary"
          :disabled="currIndex === 0"
          @click="prev"
        >
          <icons name="chevronLeft" size="medium" color="grey" />
        </woot-base-button>
        <woot-base-button
          tag="span"
          size="small"
          variant="tertiary"
          :disabled="currIndex === numberOfComponents - 1"
          @click="next"
        >
          <icons name="chevronRight" size="medium" color="grey" />
        </woot-base-button>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  components: {
    NodeRenderer: {
      props: {
        value: { type: [Object, Array], required: true },
        tag: { type: String, default: 'div' },
      },
      render(h) {
        let nodes = this.value;
        if (!this.value.tag) return null;
        if (!Array.isArray(nodes)) nodes = [nodes];

        return h(this.tag, { attrs: { class: '' } }, nodes);
      },
    },
  },
  data() {
    return {
      currIndex: 0,
    };
  },
  computed: {
    filteredPaginatedComponents() {
      return this.$slots.default?.filter(cmpt => cmpt?.tag);
    },
    numberOfComponents() {
      if (!this.filteredPaginatedComponents) return 0;
      return this.filteredPaginatedComponents.length;
    },
    canShowPagination() {
      return this.numberOfComponents > 1;
    },
  },
  methods: {
    next() {
      if (this.currIndex + 1 < this.filteredPaginatedComponents.length)
        this.currIndex += 1;
    },
    prev() {
      if (this.currIndex !== 0) this.currIndex -= 1;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
.carousel {
  &-wrapper {
    position: relative;
  }

  &-pagination {
    position: absolute;
    z-index: 1000;

    &--left {
      top: $space-small;
      left: $space-normal;
    }

    &--right {
      top: 5px;
      right: $space-small;
    }
  }
}
</style>
