import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  records: {},
  uiFlags: {
    isFetching: false,
    isUpdating: false,
    isCreating: false,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
