<template>
  <div v-tooltip="disabled ? title : null">
    <input
      :id="id"
      :value="value"
      :checked="value"
      type="checkbox"
      :disabled="disabled"
      class="toggle--checkbox"
      :class="{ disabled: disabled }"
      @input="$emit('click', $event.target.value)"
    />
    <label
      :for="id"
      class="lime-switch"
      :class="{ 'disabled-switch': disabled }"
    >
    </label>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
    id: {
      type: [Number, String],
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
.toggle--checkbox {
  display: none;
  opacity: $zero;
  width: $zero;
  height: $zero;
}

.lime-switch {
  position: relative;
  width: $space-four;
  height: $space-medium;
  background-color: $neutral-grey-300;
  border-radius: $border-radius * 2;
  transition: 0.4s;
  display: flex;
  transition: all 350ms ease-in;
  &:before {
    animation-name: reverse;
    animation-duration: 350ms;
    animation-fill-mode: forwards;
    transition: all 350ms ease-in;
    content: '';
    width: $space-two;
    height: $space-two;
    top: $space-micro;
    left: $space-micro;
    position: absolute;
    border-radius: $border-radius * 2;
    background: $color-white;
    box-shadow: $zero 0.3rem 0.7rem $elevation-1;
  }
}

.toggle--checkbox {
  &:checked {
    + {
      .lime-switch {
        background-color: $color-woot;
        &:before {
          animation-name: switch;
          animation-duration: 350ms;
          animation-fill-mode: forwards;
        }
        &:after {
          transition-delay: 350ms;
          opacity: 1;
        }
      }
    }
  }
  &:disabled {
    + {
      .lime-switch {
        background-color: $pg-1-100;
        cursor: default;
      }
    }
  }
}
@keyframes reverse {
  0% {
    left: 1.8rem;
  }
  60% {
    left: $space-one;
    width: 2.6rem;
  }
  100% {
    left: $space-micro;
  }
}

@keyframes switch {
  0% {
    left: $space-micro;
  }
  60% {
    left: $space-one;
    width: 2.6rem;
  }
  100% {
    left: 1.8rem;
  }
}
.switch-slider.round {
  border-radius: $border-radius * 2;

  &:before {
    border-radius: $border-radius * 2;
    animation-name: switch;
    animation-duration: 350ms;
    animation-fill-mode: forwards;
  }
}
</style>
