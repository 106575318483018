<template>
  <div class="automation-toggler">
    <woot-switch :id="data.id" :value="data.active" @click="toggleAutomation" />
    <span
      v-if="showStatusText"
      class="title-h5_m text-dark"
      v-text="currentStatus"
    />
  </div>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    data: { type: Object, default: () => {} },
    showStatusText: { type: Boolean, default: false },
  },
  computed: {
    currentStatus() {
      return `Rule ${this.data.active ? 'Active' : 'Inactive'}`;
    },
  },
  methods: {
    toggleAutomation() {
      this.$emit('onToggle', { id: this.data.id, active: !this.data.active });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep [type='checkbox'] + label {
  margin: 0;
}
</style>
