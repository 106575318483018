<template>
  <div v-if="!viewsUiFlags.isFetching" class="chat-list-top">
    <views @onChange="fetchConversations" />

    <search-filter />

    <conversation-filter-tab
      class="conversation-filters-container"
      parent-component-name="Tickets"
      :filter-list="$t('FILTERS.TICKET_SECTION_FILTER_LIST')"
      :applied-filters="appliedConversationFilters"
      @filterChange="onUpdateFilter"
    />
  </div>
  <zero-state v-else />
</template>

<script>
import { mapGetters } from 'vuex';

import Views from './viewsManager';
import ZeroState from './ChatListTopZeroState';
import SearchFilter from 'dashboard/components/widgets/conversation/filter/SearchFilter';
import ConversationFilterTab from 'dashboard/components/widgets/conversation/filter/ConversationFilters';

import alertMixin from 'shared/mixins/alertMixin';

import wootContants from 'dashboard/constants';
import { transformFiltersToFrontend } from '../helpers';

export default {
  components: {
    ZeroState,
    ConversationFilterTab,
    SearchFilter,
    Views,
  },
  mixins: [alertMixin],
  computed: {
    ...mapGetters({
      appliedConversationFilters: 'conversationViews/getCurrentViewFilters',
      uiFlags: 'getConversationUiFlags',
      currentConversationView: 'conversationViews/getCurrentView',
      viewsUiFlags: 'conversationViews/getUiFlags',
    }),
  },
  mounted() {
    this.fetchViews();
  },
  methods: {
    generateNewFilters() {
      const currentViewFilters = transformFiltersToFrontend(
        this.currentConversationView.filters
      );

      return {
        ...wootContants.DEFAULT_CONVERSATION_FILTERS,
        ...currentViewFilters,
      };
    },
    fetchViews() {
      this.$store
        .dispatch('conversationViews/fetchViews')
        .then(() => {
          this.fetchConversations();
        })
        .catch(() => {
          this.showAlert(
            this.$t('CONVERSATION.CONVERSATION_VIEWS.API.FETCH.ERROR'),
            'error'
          );
        });
    },
    bulkUpdateCurrentViewFilters() {
      this.$store.dispatch(
        'conversationViews/bulkUpdateCurrentViewFilters',
        this.generateNewFilters()
      );
    },
    fetchConversations() {
      this.bulkUpdateCurrentViewFilters();
    },
    onUpdateFilter(key, value) {
      this.$emit('filterChange', key, value);
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.conversation-status-filter {
  ::v-deep .selected-option {
    text-transform: uppercase !important;
  }
}
</style>
