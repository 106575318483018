<template>
  <div class="lime-card-new">
    <div class="text-dark title-h1" v-text="allHandledTicketsCount" />
    <div class="flex-row flex-align gap--medium">
      <span class="text-light title-h5_m">Tickets Handled</span>
      <span class="text-light title-h5_m">
        Avg: <b v-text="averageHandledTickets" />
      </span>
    </div>
    <div class="charts-container">
      <apex-chart
        v-for="chart in charts"
        :key="chart.id"
        type="bar"
        :options="chart.options"
        :series="chart.series"
      />
    </div>
    <div class="educational-cta">
      <a @click="showEducationalModal = true">How is it calculated?</a>
    </div>
    <educational-onboarding-modal
      :show="showEducationalModal"
      @onClose="showEducationalModal = false"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';

import EducationalOnboardingModal from '../../components/AgentConversationState/EducationalOnboardingModal';

import {
  showConvStatesTitleWithDateRange,
  showConvStatesTitleWithEndDate,
} from '../../shared/helpers';

export default {
  components: {
    'apex-chart': VueApexCharts,
    EducationalOnboardingModal,
  },
  props: {
    selectedDateRange: {
      type: Object,
      default: () => ({ startDate: null, endDate: null }),
    },
    agentConvStates: {
      type: Object,
      default: () => ({
        name: '',
        open: 0,
        averageHandlingTime: 0,
        averageHandledTickets: 0,
        followup: 0,
        waiting: 0,
        handled: 0,
        unique: 0,
        resolved: 0,
        reopened: 0,
      }),
    },
  },
  data() {
    return {
      showEducationalModal: false,
      charts: [],
    };
  },
  computed: {
    allHandledTicketsCount() {
      return this.agentConvStates?.handled || 0;
    },
    averageHandledTickets() {
      return this.agentConvStates?.averageHandledTickets || 0;
    },
  },
  mounted() {
    this.charts = this.generateCharts();
  },
  methods: {
    generateCharts() {
      return [
        {
          id: 'conversation-states-chart-with-range',
          options: this.createChartOptions(
            ['Handled', 'Unique', 'Resolved', 'Reopened'],
            showConvStatesTitleWithDateRange(
              this.selectedDateRange.startDate,
              this.selectedDateRange.endDate
            )
          ),
          series: [
            {
              name: 'tickets',
              data: ['handled', 'unique', 'resolved', 'reopened'].map(
                key => this.agentConvStates?.[key] || 0
              ),
            },
          ],
        },
        {
          id: 'conversation-states-chart-without-range',
          options: this.createChartOptions(
            ['Open', 'Followup', 'Waiting'],
            showConvStatesTitleWithEndDate(this.selectedDateRange.endDate)
          ),
          series: [
            {
              name: 'tickets',
              data: ['open', 'followup', 'waiting'].map(
                key => this.agentConvStates?.[key] || 0
              ),
            },
          ],
        },
      ];
    },
    createChartOptions(categories, subtitle) {
      return {
        chart: {
          id: '',
          toolbar: { show: false },
          animations: { enabled: true },
        },
        colors: '#1B83AC',
        tooltip: { enabled: false },
        xaxis: {
          labels: { show: false },
          axisBorder: { show: false },
          axisTicks: { show: false },
          categories,
        },
        grid: { show: false },
        yaxis: {
          labels: {
            style: {
              colors: ['#3C492C'],
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '22px',
            },
          },
        },
        subtitle: {
          text: subtitle,
          align: 'center',
          style: {
            color: '#808975',
            fontSize: '14px',
            lineHeight: '22px',
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '50%',
            columnWidth: '50%',
            endingShape: 'rounded',
            dataLabels: {
              orientation: 'horizontal',
              position: 'top',
            },
          },
        },
        dataLabels: {
          offsetX: 20,
          textAnchor: 'top',
          distributed: false,
          style: {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '22px',
          },
          background: {
            enabled: true,
            foreColor: '#808975',
            borderRadius: 0,
            borderColor: '#fff',
            opacity: 1,
          },
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.lime-card-new {
  @include elevation-1dp;
  padding: $space-medium;
  position: relative;

  .educational-cta {
    position: absolute;
    top: $space-medium;
    right: $space-medium;
  }
}

.charts-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $space-normal;
}
</style>
