<template>
  <div class="chat-list-top-deprecated">
    <div class="chat-list__top">
      <h1 class="page-title text-truncate title-h1 text-dark">
        <span class="mg-right--smaller">
          <img src="~/assets/images/ticket.svg" />
        </span>
        {{ $t('CHAT_LIST.TAB_HEADING') }}
      </h1>
      <conversation-status
        class="conversation-status-filter"
        :active-status="activeStatus"
        variant="tertiary"
        @statusFilterChange="$emit('statusFilterChange', $event)"
      />
    </div>

    <search-filter :assignee-tab="activeAssigneeTab" />

    <conversation-filter-tab
      :key="rerenderConversationFilters"
      class="conversation-filters-container"
      :filter-list="$t('FILTERS.TICKET_SECTION_FILTER_LIST')"
      :active-tab="activeAssigneeTab"
      :applied-filters="appliedConversationFilters"
      parent-component-name="Tickets"
      @filterChange="onUpdateFilter"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ConversationStatus from 'dashboard/components/widgets/conversation/filter/ConversationStatus';
import ConversationFilterTab from 'dashboard/components/widgets/conversation/filter/ConversationFiltersDeprecated';
import SearchFilter from 'dashboard/components/widgets/conversation/filter/SearchFilterDeprecated';

export default {
  name: 'ChatListTopDeprecated',
  components: {
    ConversationStatus,
    ConversationFilterTab,
    SearchFilter,
  },
  computed: {
    ...mapGetters({
      appliedConversationFilters: 'getConversationFilters',
      uiFlags: 'getConversationUiFlags',
    }),
    rerenderConversationFilters() {
      return this.appliedConversationFilters ? Math.random() : '';
    },
    activeAssigneeTab() {
      return this.appliedConversationFilters.assigneeType;
    },
    activeStatus() {
      return this.appliedConversationFilters.status;
    },
  },
  methods: {
    onUpdateFilter(key, value) {
      this.$emit('filterChange', key, value);
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.conversation-status-filter {
  ::v-deep .selected-option {
    text-transform: uppercase !important;
  }
}
</style>
