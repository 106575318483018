<template>
  <woot-modal
    :show.sync="showModal"
    :on-close="hideModal"
    :show-close="false"
    :small-width="true"
  >
    <div class="feature-intro-modal">
      <p class="modal-heading">
        {{ content.heading }}
      </p>
      <div class="media-container">
        <img src="/brand-assets/feature1_illustration.svg" />
      </div>
      <div class="feature-container">
        <div class="body-b2 text-dark">
          {{ content.modal_content }}
        </div>
      </div>
      <div
        class="button-container flex-space-between"
        style="justify-content :flex-end"
      >
        <woot-primary-button
          v-if="currentRole === 'administrator'"
          size="small"
          :custom-class="'primary-button'"
          :name="content.show_cta ? 'Browse Plans' : 'GOT IT!'"
          @click="hideModal"
        />
        <woot-primary-button
          v-else
          size="small"
          :custom-class="'primary-button'"
          :name="'GOT IT!'"
          @click="closeModal"
        />
      </div>
    </div>
  </woot-modal>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentRole: 'getCurrentRole',
    }),
  },
  watch: {
    type(newVal) {
      if (this.content.modal_content) {
        if (localStorage.getItem('shopifyModalType') !== undefined) {
          if (newVal !== JSON.parse(localStorage.getItem('shopifyModalType'))) {
            this.showModal = true;
            localStorage.setItem('shopifyModalType', newVal);
          }
        } else if (newVal !== localStorage.getItem('shopifyModalType')) {
          this.showModal = true;
          localStorage.setItem('shopifyModalType', newVal);
        }
      }
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    hideModal() {
      if (this.content?.show_cta)
        this.$router.push(
          `/app/accounts/${this.$route.params.accountId}/settings/billing_plan/choose_billing_plan`
        );
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.feature-intro-modal {
  padding: $space-medium $space-normal $space-normal;

  .modal-heading {
    text-align: center;
    margin-bottom: $space-normal;
    color: $secondary-blue;
    font-weight: $font-weight-black;
    font-size: $font-size-big;
  }
  .media-container {
    img {
      display: block;
      margin: $zero auto;
    }
  }
  .feature-container {
    padding: $space-medium $space-large $space-two;
  }
}
</style>
