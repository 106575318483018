import Vue from 'vue';
import types from '../mutation-types';
import LabelsAPI from '../../api/labels';

function getIndexOfSubLabel(labels, id) {
  for (let i = 0; i < labels.length; i += 1) {
    let index = labels[i].labels.findIndex(item => item.id === id);
    if (index > -1) {
      return [i, index];
    }
  }

  return null;
}

export const state = {
  labelsData: {
    Conversation: [],
    Contact: [],
  },
  labelsWithoutGroups: {
    Conversation: [],
    Contact: [],
  },
  uiFlags: {
    isFetching: false,
    isFetchingLabelsWithoutGroups: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getLabels(_state) {
    return _state.labelsData.Conversation;
  },
  getContactLabels(_state) {
    return _state.labelsData.Contact;
  },
  getLabelsByResource: _state => (resource_type = 'Conversation') => {
    return _state.labelsData[resource_type];
  },
  getLabelsWithoutGroups(_state) {
    return _state.labelsWithoutGroups.Conversation;
  },
  getContactLabelsWithoutGroups(_state) {
    return _state.labelsWithoutGroups.Contact;
  },
  getLabelsWithoutGroupsByResource: _state => (resource = 'Conversation') => {
    return _state.labelsWithoutGroups[resource];
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  fetchLabels: async ({ commit }, resource_type = 'Conversation') => {
    commit(types.SET_LABEL_UI_FLAG, { isFetching: true });
    try {
      const response = await LabelsAPI.fetch({
        show_internal: true,
        label_type: 'client',
        resource_type,
      });
      commit(types.SET_LABEL_UI_FLAG, { isFetching: false });
      return response.data.payload;
    } catch (error) {
      commit(types.SET_LABEL_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  get: async function getLabels({ dispatch }, resource_type = 'Conversation') {
    try {
      const labelsPayload = await dispatch('fetchLabels', resource_type);
      dispatch('set', { data: labelsPayload, resource_type });
    } catch (error) {
      throw new Error(error);
    }
  },
  create: async function createLabels({ commit, dispatch }, labelObj) {
    commit(types.SET_LABEL_UI_FLAG, { isCreating: true });
    try {
      labelObj.label_type = 'client';
      const response = await LabelsAPI.create(labelObj);
      dispatch('add', {
        data: response.data,
        resource_type: labelObj.resource_type,
      });

      return response;
    } catch (error) {
      throw error.response || error;
    } finally {
      commit(types.SET_LABEL_UI_FLAG, { isCreating: false });
    }
  },
  set: async ({ commit }, { data, resource_type }) => {
    commit(types.SET_LABELS, { data, resource_type });
  },
  add: async ({ commit }, { data, resource_type }) => {
    commit(types.ADD_LABEL, {
      data,
      resource_type: resource_type || 'Conversation',
    });
  },
  remove: async ({ commit }, { data, resource_type }) => {
    commit(types.DELETE_LABEL, {
      data,
      resource_type: resource_type || 'Conversation',
    });
  },
  update: async function updateLabels(
    { commit },
    { id, oldData, ...updateObj }
  ) {
    commit(types.SET_LABEL_UI_FLAG, { isUpdating: true });
    try {
      const response = await LabelsAPI.update(id, updateObj);
      commit(types.EDIT_LABEL, {
        data: response.data,
        oldData,
        resource_type: updateObj.resource_type,
      });
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LABEL_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async function deleteLabels(
    { commit, dispatch },
    { data: labelObj, resource_type }
  ) {
    commit(types.SET_LABEL_UI_FLAG, { isDeleting: true });
    try {
      await LabelsAPI.delete(labelObj.id);

      dispatch('remove', { data: labelObj, resource_type: resource_type });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LABEL_UI_FLAG, { isDeleting: false });
    }
  },
  bulkDelete: async function bulkDeleteLabels({ commit }, labelObj) {
    commit(types.SET_LABEL_UI_FLAG, { isDeleting: true });
    try {
      await LabelsAPI.bulkDeleteLabels(labelObj);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LABEL_UI_FLAG, { isDeleting: false });
    }
  },
  bulkUpdateStatus: async function bulkUpdateLabelsStatus(
    { commit },
    { labelIds = [], isActive = false, resource_type }
  ) {
    commit(types.SET_LABEL_UI_FLAG, { isUpdating: true });
    try {
      await LabelsAPI.bulkUpdateStatus(labelIds, isActive);
      commit(types.UPDATE_STATUSES, {
        labelIds,
        isActive,
        resource_type: resource_type || 'Conversation',
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LABEL_UI_FLAG, { isUpdating: false });
    }
  },
  fetchLabelsWithoutGroups: async (
    { commit },
    resource_type = 'Conversation'
  ) => {
    commit(types.SET_LABEL_UI_FLAG, { isFetchingLabelsWithoutGroups: true });
    try {
      const response = await LabelsAPI.getLabelsWithoutGroups({
        resource_type,
      });
      commit(types.SET_LABEL_UI_FLAG, { isFetchingLabelsWithoutGroups: false });
      return response.data.payload;
    } catch (error) {
      commit(types.SET_LABEL_UI_FLAG, { isFetchingLabelsWithoutGroups: false });
      throw new Error(error);
    }
  },
  getLabelsWithoutGroups: async (
    { dispatch, commit },
    { resource_type } = { resource_type: 'Conversation' }
  ) => {
    try {
      const labelsPayload = await dispatch(
        'fetchLabelsWithoutGroups',
        resource_type
      );
      commit(types.SET_LABELS_WITHOUT_GROUPS, {
        data: labelsPayload,
        resource_type,
      });
    } catch (error) {
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.SET_LABEL_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_LABELS](_state, { data, resource_type }) {
    // Check if the resource_type is valid before updating
    if (Object.keys(_state.labelsData).includes(resource_type)) {
      _state.labelsData[resource_type] = data;
    }
  },
  [types.SET_LABELS_WITHOUT_GROUPS](_state, { data, resource_type }) {
    _state.labelsWithoutGroups[resource_type] = data;
  },
  [types.ADD_LABEL](_state, { data, resource_type }) {
    const targetState = _state.labelsData[resource_type];
    let { label_level, parent_id } = data;

    if (label_level === 'label_sub_group') {
      let labelGroupIndex = targetState.findIndex(el => el.id === parent_id);

      if (labelGroupIndex > -1) {
        targetState[labelGroupIndex].labels.push(data);
      }
    } else if (label_level === 'label_child') {
      let labelIndex = getIndexOfSubLabel(targetState, parent_id);

      if (labelIndex) {
        targetState[labelIndex[0]].labels[labelIndex[1]].labels.push(data);
      }
    } else {
      targetState.push(data);
    }
  },
  [types.UPDATE_STATUSES]: (_state, { labelIds, isActive, resource_type }) => {
    const targetState = _state.labelsData[resource_type];

    const updateActiveState = labels => {
      labels.forEach(label => {
        if (labelIds.includes(label.id)) {
          label.active = isActive;
        }
        if (label.labels && label.labels.length) {
          updateActiveState(label.labels);
        }
      });
    };

    updateActiveState(targetState);
  },
  [types.EDIT_LABEL]: (_state, { data, oldData, resource_type }) => {
    const targetState = _state.labelsData[resource_type];
    let { label_level, parent_id } = data;

    if (label_level === 'label_sub_group') {
      let labelGroupIndex = targetState.findIndex(el => el.id === parent_id);

      if (labelGroupIndex > -1) {
        let notFound = true;
        targetState[labelGroupIndex].labels.forEach((element, index) => {
          if (element.id === data.id) {
            notFound = false;
            Vue.set(targetState[labelGroupIndex].labels, index, data);
          }
        });
        if (notFound) {
          targetState[labelGroupIndex].labels.push(data);

          mutations[types.DELETE_LABEL](_state, {
            data: oldData,
            resource_type,
          });
        }
      }
    } else if (label_level === 'label_child') {
      let labelIndex = getIndexOfSubLabel(targetState, parent_id);

      if (labelIndex) {
        let notFound = true;
        targetState[labelIndex[0]].labels[labelIndex[1]].labels.forEach(
          (element, index) => {
            if (element.id === data.id) {
              notFound = false;
              Vue.set(
                targetState[labelIndex[0]].labels[labelIndex[1]].labels,
                index,
                data
              );
            }
          }
        );
        if (notFound) {
          targetState[labelIndex[0]].labels[labelIndex[1]].labels.push(data);
          mutations[types.DELETE_LABEL](_state, {
            data: oldData,
            resource_type,
          });
        }
      }
    } else {
      let notFound = true;
      targetState.forEach((element, index) => {
        if (element.id === data.id) {
          notFound = false;
          Vue.set(targetState, index, data);
        }
      });
      if (notFound) {
        targetState.push(data);
        mutations[types.DELETE_LABEL](_state, { data: oldData, resource_type });
      }
    }
  },
  [types.DELETE_LABEL](_state, { data, resource_type }) {
    let targetState = _state.labelsData[resource_type];
    let { id, label_level, parent_id } = data;

    if (label_level === 'label_sub_group') {
      const labelGroupIndex = targetState.findIndex(el => el.id === parent_id);
      if (labelGroupIndex > -1) {
        const updatedLabels = targetState[labelGroupIndex].labels.filter(
          record => record.id !== id
        );
        Vue.set(targetState[labelGroupIndex], 'labels', updatedLabels);
      }
    } else if (label_level === 'label_child') {
      const labelIndex = getIndexOfSubLabel(targetState, parent_id);
      if (labelIndex) {
        const updatedSubLabels = targetState[labelIndex[0]].labels[
          labelIndex[1]
        ].labels.filter(record => record.id !== id);
        Vue.set(
          targetState[labelIndex[0]].labels[labelIndex[1]],
          'labels',
          updatedSubLabels
        );
      }
    } else {
      const index = targetState.findIndex(record => record.id === id);
      if (index > -1) {
        Vue.delete(targetState, index);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
