/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';

class LogisticsApi extends ApiClient {
  constructor() {
    super('integrations', { accountScoped: true });
  }

  deleteLimekitLogisticsConnection({ integration_name }) {
    return axios.delete(`${this.url}/logistics/` + integration_name, {
      params: { integration_name: integration_name },
    });
  }

  createLimekitLogisticsConnection({ integration_name, integration_data }) {
    return axios.post(`${this.url}/logistics`, {
      integration_name: integration_name,
      integration_data: integration_data,
    });
  }

  fetchLimekitLogisticsConnection({ integration_name }) {
    return axios.get(`${this.url}/logistics`, {
      params: { integration_name: integration_name },
    });
  }

  getEnabledLogistics() {
    return axios.get(`${this.url}/logistics`);
  }
}

export default new LogisticsApi();
