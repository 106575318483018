<template>
  <div class="small-12 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.TWILIO.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.TWILIO.DESC')"
    />
    <form @submit.prevent="createChannel">
      <div class="settings-sub-menu-content--larger mg-bottom--jumbo">
        <woot-input
          v-model.trim="channelName"
          required
          auto-focus
          :class="{ error: $v.channelName.$error }"
          :has-error="$v.channelName.$error"
          :error="$t('INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.LABEL')"
          :placeholder="$t('INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.PLACEHOLDER')"
          @blur="$v.channelName.$touch"
        />

        <div class="medium-12 content-margin">
          <label class="formlabel flex-row gap--slab">
            <span
              class="title-h5 text-dark"
              v-text="$t('INBOX_MGMT.ADD.TWILIO.CHANNEL_TYPE.LABEL')"
            />
          </label>
          <woot-single-selector
            size="large"
            :option-list="mediumList"
            :default-option="medium.name"
            :custom-style="{ top: '4.8rem', left: 'auto', width: '100%' }"
            @click="changeMedium"
          />

          <span v-if="$v.medium.$error" class="desc-text capital_c3">{{
            $t('INBOX_MGMT.ADD.TWILIO.CHANNEL_TYPE.ERROR')
          }}</span>
        </div>

        <woot-input
          v-model.trim="phoneNumber"
          required
          :class="{ error: $v.phoneNumber.$error }"
          :has-error="$v.phoneNumber.$error"
          :error="$t('INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.LABEL')"
          :placeholder="$t('INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.PLACEHOLDER')"
          @blur="$v.phoneNumber.$touch"
        />

        <woot-input
          v-model.trim="accountSID"
          required
          :class="{ error: $v.accountSID.$error }"
          :has-error="$v.accountSID.$error"
          :error="$t('INBOX_MGMT.ADD.TWILIO.ACCOUNT_SID.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.TWILIO.ACCOUNT_SID.LABEL')"
          :placeholder="$t('INBOX_MGMT.ADD.TWILIO.ACCOUNT_SID.PLACEHOLDER')"
          @blur="$v.accountSID.$touch"
        />

        <woot-input
          v-model.trim="authToken"
          required
          :class="{ error: $v.authToken.$error }"
          :has-error="$v.authToken.$error"
          :error="$t('INBOX_MGMT.ADD.TWILIO.AUTH_TOKEN.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.TWILIO.AUTH_TOKEN.LABEL')"
          :placeholder="$t('INBOX_MGMT.ADD.TWILIO.AUTH_TOKEN.PLACEHOLDER')"
          @blur="$v.authToken.$touch"
        />
      </div>
      <div class="medium-12 columns settings-sub-menu-bottom-nav">
        <woot-base-button
          variant="secondary"
          @click.prevent="() => $router.go(-1)"
        >
          Back
        </woot-base-button>
        <woot-base-button :loading="uiFlags.isCreating" type="submit">
          Next
        </woot-base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';

const shouldStartWithPlusSign = (value = '') => value.startsWith('+');

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      accountSID: '',
      authToken: '',
      medium: {
        name: 'WhatsApp',
        id: 'whatsapp',
      },
      channelName: '',
      phoneNumber: '',
      mediumList: [
        {
          name: 'WhatsApp',
          id: 'whatsapp',
        },
        {
          name: 'SMS',
          id: 'sms',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    channelName: { required },
    phoneNumber: { required, shouldStartWithPlusSign },
    authToken: { required },
    accountSID: { required },
    medium: { required },
  },
  methods: {
    changeMedium(val) {
      this.medium = val;
    },
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('AGENT_MGMT.FORM_ERROR'), 'error');
        return;
      }

      try {
        const twilioChannel = await this.$store.dispatch(
          'inboxes/createTwilioChannel',
          {
            twilio_channel: {
              name: this.channelName,
              medium: this.medium,
              account_sid: this.accountSID,
              auth_token: this.authToken,
              phone_number: `+${this.phoneNumber.replace(/\D/g, '')}`,
            },
          }
        );

        const {
          id: inbox_id,
          channel_type,
          name: channel_name,
        } = twilioChannel;

        this.mix_panel_clicked_create_inbox_successful(
          'WhatsApp',
          channel_type
        );
        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id,
          },
          query: { channel_type, channel_name },
        });
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.TWILIO.API.ERROR_MESSAGE'),
          'error'
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.form-label {
  padding: $zero $space-small $space-one;
  color: $neutral-grey-800;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: 22px;
}

.content-margin {
  margin-bottom: 2.4rem;
}

.medium-12 {
  .button {
    float: right;
  }
}
.selector-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: $settings-searchbar-height;
  align-items: center;
  color: $neutral-grey-800;
  font-size: $font-size-small;
  line-height: 22px;
  background: $neutral-grey-200;
  border: 1px solid $neutral-grey-200;
  cursor: pointer;
  border-radius: $border-radius;
  padding: $space-slab;

  &:focus {
    border: 1px solid $pg-1-500;
    background: $neutral-white;
    outline: none;
  }

  &:hover {
    border: 1px solid $neutral-grey-600;
    background: $neutral-white;
  }
}
</style>
