<template>
  <section>
    <form-section-header
      :heading="$t('CREATE_TICKET.FORM.SECTIONS.ADD_EMAIL.HEADING')"
      :sub-heading="$t('CREATE_TICKET.FORM.SECTIONS.ADD_EMAIL.SUB_HEADING')"
      icon="ticket"
    />
    <add-email @selectedContacts="$emit('selectedContactsTo', $event)" />
    <div
      class="flex-row flex-justify--end"
      style="max-width: 49rem; margin: 0 4.8rem"
    >
      <woot-base-button
        v-if="!showCC"
        size="small"
        variant="secondary"
        tag="span"
        style="border: 0; margin-bottom: 0.8rem;"
        @click="showCC = !showCC"
      >
        <span class="button-small text-blue">CC</span>
      </woot-base-button>
      <woot-base-button
        v-if="!showBCC"
        size="small"
        variant="secondary"
        tag="span"
        style="border: 0; margin-bottom: 0.8rem;"
        @click="showBCC = !showBCC"
      >
        <span class="button-small text-blue">BCC</span>
      </woot-base-button>
    </div>
    <add-email
      v-if="showCC"
      type="CC"
      @selectedContacts="$emit('selectedContactsCC', $event)"
    />
    <add-email
      v-if="showBCC"
      type="BCC"
      @selectedContacts="$emit('selectedContactsBCC', $event)"
    />
  </section>
</template>
<script>
import AddEmail from './addEmails';
import FormSectionHeader from '../shared/FormSectionHeader';

export default {
  components: { FormSectionHeader, AddEmail },
  data() {
    return {
      showBCC: false,
      showCC: false,
    };
  },
};
</script>
