/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';

class ShopifyApi extends ApiClient {
  constructor() {
    super('integrations', { accountScoped: true });
  }

  update({ domain }) {
    return axios.post(`${this.url}/shopify`, {
      domain: domain,
    });
  }

  delete({ domain }) {
    return axios.put(`${this.url}/shopify/disconnect_shopify`, { domain });
  }

  get() {
    return axios.get(`${this.url}/shopify/get_shopify`);
  }

  checkShopifyStatus(accountId) {
    return axios.get(
      `${this.url}/shopify/check_shopify?account_id=${accountId}`
    );
  }

  upgradeShopifyPlan(params) {
    return axios.post(`${this.baseUrl()}/shopify/subscriptions`, params);
  }

  upgradeShopifyToken(params) {
    return axios.put(`${this.baseUrl()}/update_shopify_access_token`, params);
  }

  getShopifyConversationCount(data) {
    return axios.get(`${this.baseUrl()}//conversation_tracker`, {
      params: data,
    });
  }

  getShopifyBillingPlans() {
    return axios.get(`${this.apiVersion}/plans`);
  }
}

export default new ShopifyApi();
