<template>
  <div v-if="uiFlags.isFetchingMetrics" class="flex-space-between">
    <div class="metrics-card-container">
      <metrics-card-skeleton
        v-for="(item, index) in metricsCount"
        :key="index"
      />
    </div>
    <pie-chart-skeleton
      chart-height="18rem"
      :custom-style="{ margin: '0rem' }"
    />
  </div>
  <div v-else class="flex-space-between">
    <div class="metrics-card-container">
      <metrics-card
        v-for="(item, index) in metricsCount"
        :key="index"
        :title="item.title"
        :sub-title="item.sub"
        :actual="item.actual"
        :style="{ 'margin-right': '0rem' }"
      />
    </div>

    <div
      v-if="botMetrics.totalCsatRatings"
      class="lime-card-1dp flex-row flex-justify flex-align pie-chart-mr"
    >
      <pie-chart
        title="Response Split"
        :series="pieSeries"
        :colors="pieColors"
        :labels="pieLabels"
        :height="180"
        legend-position="right"
        :legend-offset-x="0"
        :legend-offset-y="-30"
        :marker-offset-x="0"
        :marker-offset-y="1"
        :chart-offset-x="0"
        :chart-offset-y="0"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MetricsCard from '../../components/MetricsCard';
import PieChart from 'dashboard/components/widgets/chart/PieChart';
import MetricsCardSkeleton from '../../components/MetricsCardSkeleton';
import PieChartSkeleton from '../../components/PieChartSkeleton';

import {
  generateCsatRatings,
  formatToPercent,
} from 'dashboard/routes/dashboard/analytics/shared/helpers';

export default {
  components: {
    MetricsCard,
    PieChart,
    MetricsCardSkeleton,
    PieChartSkeleton,
  },
  computed: {
    ...mapGetters({
      botData: 'csat/getBotData',
      botMetrics: 'csat/getBotMetrics',
      uiFlags: 'csat/getUIFlags',
      ratingSystemPoint: 'csat/getRatingSystemPoint',
    }),
    responseCount() {
      return this.botMetrics.totalCsatRatings
        ? this.botMetrics.totalCsatRatings.toLocaleString()
        : '--';
    },
    avgCsatRating() {
      return this.botMetrics.averageCsatRating
        ? `${this.botMetrics.averageCsatRating} / 5`
        : '--';
    },
    responseRate() {
      return this.botMetrics.responseRate;
    },
    satisfactionScore() {
      return this.botMetrics.satisfactionScore;
    },
    csatRatings() {
      return generateCsatRatings(this.ratingSystemPoint);
    },
    ratingPercentage() {
      return this.botData.percentageSplit;
    },
    pieSeries() {
      return this.csatRatings.map(rating =>
        Number(this.ratingPercentage[rating.value])
      );
    },
    pieLabels() {
      return this.csatRatings.map(rating => ` ${rating.emoji} ${rating.label}`);
    },
    pieColors() {
      return this.csatRatings.map(rating => rating.color);
    },
    metricsCount() {
      return [
        {
          title: this.responseCount,
          sub: this.$t('ANALYTICS.BOT_CSAT.METRIC.TOTAL_RESPONSES.LABEL'),
          actual: this.$t('ANALYTICS.BOT_CSAT.METRIC.TOTAL_RESPONSES.TOOLTIP'),
        },
        {
          title: this.avgCsatRating,
          sub: this.$t('ANALYTICS.BOT_CSAT.METRIC.CSAT_RATING.LABEL'),
          actual: this.$t('ANALYTICS.BOT_CSAT.METRIC.CSAT_RATING.TOOLTIP'),
        },
        {
          title: formatToPercent(this.responseRate),
          sub: this.$t('ANALYTICS.BOT_CSAT.METRIC.RESPONSE_RATE.LABEL'),
          actual: this.$t('ANALYTICS.BOT_CSAT.METRIC.RESPONSE_RATE.TOOLTIP'),
        },
        {
          title: formatToPercent(this.satisfactionScore),
          sub: this.$t('ANALYTICS.BOT_CSAT.METRIC.SATISFACTION_SCORE.LABEL'),
          actual: this.$t(
            'ANALYTICS.BOT_CSAT.METRIC.SATISFACTION_SCORE.TOOLTIP'
          ),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
.metrics-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $space-medium;
  width: 100%;
  margin: $space-medium $space-medium $space-medium $space-micro;
}

.pie-chart-mr {
  margin: $space-micro;
  min-height: 29rem;
}
</style>
