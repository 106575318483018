<template>
  <div class="column page-top-bar">
    <img v-if="headerImage" :src="headerImage" alt="No image" />
    <span v-if="headerTitle" class="page-sub-title title-h2">
      {{ headerTitle }}
    </span>
    <p
      v-if="headerContent"
      class="small-12 column header-content title-h4_m text-dark"
      v-html="headerContent"
    />
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    headerContent: {
      type: String,
      default: '',
    },
    headerImage: {
      type: String,
      default: '',
    },
  },
};
</script>
