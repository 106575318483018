<template>
  <div class="columns content-box settings-box">
    <woot-base-button
      class="settings-button"
      :loading="uiFlags.isUpdating"
      @click="updateSettings"
    >
      Update
    </woot-base-button>
    <div class="settings-container column custom-scroll lime-card-1dp mr-right">
      <bot-csat-form v-model="botCsatConfig" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BotCsatForm from './components/BotCsatForm';

import { accountIdFromPathname } from 'dashboard/helper/URLHelper';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: { BotCsatForm },
  mixins: [alertMixin],
  data() {
    return {
      botCsatConfig: {
        enable_bot_csat: false,
        csat_flow_trigger: {},
        csat_point_system: 5,
        bot_csat_timer: 1800,
        csat_reminder_timer: 3600,
      },
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      uiFlags: 'accounts/getUIFlags',
    }),
    accountId() {
      const { pathname } = window.location;
      const accountId = accountIdFromPathname(pathname);
      return accountId;
    },
  },
  created() {
    this.$store
      .dispatch('accounts/getBotConfigs', this.accountId)
      .then(this.populateBotCsatData);
  },
  methods: {
    populateBotCsatData() {
      const { bot_csat_configs: botConfigs } = this.getAccount(this.accountId);

      this.botCsatConfig.enable_bot_csat = botConfigs.enable_bot_csat;
      this.botCsatConfig.csat_flow_trigger = botConfigs.csat_flow_trigger || {};
      this.botCsatConfig.csat_point_system = botConfigs.csat_point_system || 0;
      this.botCsatConfig.bot_csat_timer = botConfigs.bot_csat_timer || 0;
      this.botCsatConfig.csat_reminder_timer =
        botConfigs.csat_reminder_timer || 0;
    },
    updateSettings() {
      if (document.querySelector('.error')) {
        this.showAlert(this.$t('AGENT_MGMT.FORM_ERROR'), 'error');
        document
          .querySelector('.error')
          .scrollIntoView({ block: 'center', behavior: 'smooth' });
        return;
      }

      const { csat_flow_trigger, ...rest } = this.botCsatConfig;
      const payload = {
        ...rest,
        ...csat_flow_trigger,
      };

      const formData = new FormData();
      Object.keys(payload).forEach(key => {
        formData.append(`bot_csat_configs[${key}]`, payload[key]);
      });

      this.$store
        .dispatch('accounts/update', formData)
        .then(() => {
          this.showAlert(this.$t('BOT_CSAT.API.SUCCESS_MESSAGE'), 'success');
        })
        .catch(() => {
          this.showAlert(this.$t('BOT_CSAT.API.ERROR_MESSAGE'), 'error');
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

.settings-box {
  padding-top: $space-largest;
  padding-left: 0.1rem;
  overflow: hidden;

  .lime-card-1dp {
    padding: $zero;
  }

  .settings-container {
    height: 100%;
    margin: $zero;
  }
}
</style>
