import tagsIntroImage from 'assets/images/tags_intro.svg';

export const SHOWCASE_TAGS_DATA = [
  {
    name: 'tags',
    key: 'TAGS',
    color: '#4267B2',
    image: tagsIntroImage,
    contentPath: 'LABEL_MGMT',
  },
];

export const TYPES = {
  conversation: 'Conversation',
  contact: 'Contact',
};
