<template>
  <div>
    <div
      :style="{ background: shadeColor }"
      class="inbox-viewer flex-row flex-align flex-justify mg-bottom--larger"
    >
      <div
        v-if="inbox.id"
        class="flex-row flex-justify mg-top--small mg-auto gap--slab"
      >
        <span v-if="isWhatsappChannel">
          <img
            width="80"
            :src="require('dashboard/assets/images/channels/whatsapp.svg')"
          />
        </span>
        <span v-if="isAKnowlarityInbox">
          <img
            width="80"
            :src="
              require('dashboard/assets/images/channels/voice_channels.svg')
            "
          />
        </span>
        <span v-if="isWhatsappChannel || isAKnowlarityInbox">
          <icons name="link" color="green" size="large" :show-title="false" />
        </span>
        <span>
          <img width="80" :src="imageSrc(channelImageIdentifier)" />
        </span>
      </div>
    </div>
    <div class="wizard-body columns content-box small-12 flex-column">
      <empty-state :title="title" :message="message">
        <div class="medium-12 columns text-center">
          <div class="medium-12">
            <woot-code
              v-if="isATwilioChannel"
              lang="html"
              :script="twilioCallbackURL"
            >
            </woot-code>
          </div>
          <div class="medium-12">
            <woot-code
              v-if="isAnEmailChannel"
              lang="html"
              :script="inbox.forward_to_email"
            >
            </woot-code>
          </div>
        </div>
        <router-link
          class="medium-12 router-button"
          :to="{
            name: 'settings_inbox_show',
            params: { inboxId: this.$route.params.inbox_id },
          }"
        >
          <woot-base-button size="large" class="cta-btn" front-icon="settings">
            {{ $t('INBOX_MGMT.FINISH.BUTTON_TEXT') }}
          </woot-base-button>
        </router-link>
      </empty-state>
    </div>
  </div>
</template>

<script>
import EmptyState from '../../../../components/widgets/EmptyState';

import inboxMixin from 'shared/mixins/inboxMixin';
import inboxBannerMixin from 'shared/mixins/inboxBannerMixin';
import configMixin from 'shared/mixins/configMixin';

export default {
  components: {
    EmptyState,
  },
  mixins: [configMixin, inboxBannerMixin, inboxMixin],
  computed: {
    inbox() {
      return this.$store.getters['inboxes/getInbox'](
        this.$route.params.inbox_id
      );
    },
    title() {
      if (this.isThreeSixtyDialogInbox)
        return this.$t(
          'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.FINISH.TITLE'
        );
      return this.$t('INBOX_MGMT.FINISH.TITLE');
    },
    message() {
      if (this.isATwilioChannel) {
        return `${this.$t('INBOX_MGMT.FINISH.MESSAGE')}. ${this.$t(
          'INBOX_MGMT.ADD.TWILIO.API_CALLBACK.SUBTITLE'
        )}`;
      }

      if (this.isThreeSixtyDialogInbox) {
        return this.$t(
          'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.FINISH.MESSAGE'
        );
      }

      if (this.isAChatApiInbox) {
        return `${this.$t('INBOX_MGMT.FINISH.MESSAGE')}. ${this.$t(
          'INBOX_MGMT.ADD.CHAT_API.FINISH.MESSAGE'
        )}`;
      }

      if (this.isAnEmailChannel) {
        return this.$t('INBOX_MGMT.ADD.EMAIL_CHANNEL.FINISH_MESSAGE');
      }

      if (!this.inbox.web_widget_script) {
        return this.$t('INBOX_MGMT.FINISH.MESSAGE');
      }

      return this.$t('INBOX_MGMT.FINISH.WEBSITE_SUCCESS');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
.wizard-body {
  justify-content: center;
  align-items: center;

  .website--code {
    margin: $space-normal auto $zero;
    max-width: 70%;
  }

  .cta-btn {
    margin: $space-medium auto;
  }
}

.inbox-viewer {
  height: 15.6rem;
  width: 100%;
}
</style>
