import SettingsContent from '../settings/Wrapper';
import Index from './Index.vue';
import { frontendURL } from '../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/profile'),
      name: 'profile_settings',
      roles: ['administrator', 'agent', 'supervisor'],
      component: SettingsContent,
      props: {
        headerTitle: 'PROFILE_SETTINGS.TITLE',
        icon: 'settings',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'profile_settings_index',
          component: Index,
          roles: ['administrator', 'agent', 'supervisor'],
        },
      ],
    },
  ],
};
