<template>
  <div class="flex-row flex-align flex-wrap gap--small">
    <search-box
      :placeholder="$t('SLA.SEARCH_TXT')"
      :value="searchTerm"
      custom-class="search-box"
      @setSearch="setSearch"
    />
  </div>
</template>
<script>
import SearchBox from '../../../SearchBox';

export default {
  components: { SearchBox },
  props: {
    searchTerm: {
      type: String,
      default: '',
    },
  },
  methods: {
    setSearch(searchTerm) {
      this.$emit('setSearch', searchTerm);
    },
  },
};
</script>
