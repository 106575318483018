<template>
  <div class="column content-box settings-wrapper">
    <form-header
      class="sla-header mg-bottom--slab"
      :name="slaPayload.name"
      :description="slaPayload.description"
      :name-placeholder="$t('SLA.CREATE.MODAL.NAME.PLACEHOLDER')"
      :description-placeholder="$t('SLA.CREATE.MODAL.DESC.LONG_PLACEHOLDER')"
      :active="slaPayload.active"
      :on-click="() => (showDetailsModal = true)"
      :on-back="onBack"
      @updateActive="slaPayload.active = $event"
    />
    <create-modal
      :show="showDetailsModal"
      :name="slaPayload.name"
      :description="slaPayload.description"
      @updateDetail="onUpdateDetail"
      @close="onCloseModal"
      @submit="onSubmitModal"
    />
    <unsaved-changes-modal
      :show="showUnsavedChangesModal"
      @close="showUnsavedChangesModal = false"
      @confirm="navigateToSlaHome"
    />
    <div class="sla-form-wrapper">
      <div class="sla-form-sections">
        <triggers-section :data="slaPayload" @update="onUpdatePayload" />
        <metrics-section :data="slaPayload" @update="onUpdatePayload" />
        <reminders-section :data="slaPayload" @update="onUpdatePayload" />
        <escalations-section :data="slaPayload" @update="onUpdatePayload" />
      </div>
      <form-footer
        :edit-view="isEditView"
        @submit="submitForm"
        @onBack="onBack"
      />
    </div>
  </div>
</template>

<script>
import FormHeader from './FormHeader';
import CreateModal from '../modals/CreateModal';
import UnsavedChangesModal from '../modals/UnsavedChangesModal';
import TriggersSection from './sections/Triggers';
import MetricsSection from './sections/Metrics';
import RemindersSection from './sections/Reminders';
import EscalationsSection from './sections/Escalations';
import FormFooter from './FormFooter';

import { manifestPayload, manifestSlaObject } from '../../utils/form';
import validateSLA from '../../utils/validations';
import showError from 'dashboard/helper/showError';

import alertMixin from 'shared/mixins/alertMixin';

const ERROR_PREFIX = 'SLA';

export default {
  components: {
    FormHeader,
    CreateModal,
    UnsavedChangesModal,
    TriggersSection,
    MetricsSection,
    RemindersSection,
    EscalationsSection,
    FormFooter,
  },
  mixins: [alertMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showDetailsModal: false,
      showUnsavedChangesModal: false,
      slaPayload: null,
      oldData: {},
    };
  },
  computed: {
    isEditView() {
      return !!this.data.id;
    },
  },
  created() {
    this.slaPayload = manifestSlaObject(this.data);
    this.updateOldData();
  },
  methods: {
    onBack() {
      const noChangeFound = this.oldData === JSON.stringify(this.slaPayload);
      if (!noChangeFound) {
        this.showUnsavedChangesModal = true;
      } else this.navigateToSlaHome();
    },
    updateOldData() {
      this.oldData = JSON.stringify(this.slaPayload);
    },
    navigateToSlaHome() {
      this.$router.push({ name: 'settings_sla' });
    },
    onCloseModal() {
      this.showDetailsModal = false;
    },
    onUpdateDetail(data) {
      this.slaPayload = { ...this.slaPayload, ...data };
    },
    onSubmitModal() {
      this.onCloseModal();
    },
    onUpdatePayload(data) {
      this.slaPayload = { ...this.slaPayload, ...data };
    },
    submitForm() {
      const [
        errorMessage,
        isValid,
        errorneousFieldClassName,
        specificFieldSelector,
      ] = validateSLA(this.slaPayload);

      if (!isValid) {
        showError(
          {
            message: this.$t(`${ERROR_PREFIX}.FORM.ERRORS.${errorMessage}`),
            isValid,
            errorneousFieldClassName,
            specificFieldSelector,
          },
          errorMessage.length > 50 ? 5000 : 2500
        );
        return;
      }

      this.updateOldData();

      this.$emit('submit', manifestPayload(this.slaPayload));
    },
  },
};
</script>
