<template>
  <div>
    <div v-if="hasTitle" class="pos-relative">
      <span class="table-title">{{ title }}</span>
      <span class="tooltip-position">
        <icons name="info" size="medium" color="grey" />
      </span>
      <apexchart
        width="100%"
        height="280"
        type="bar"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
    <div v-else class="chart-card">
      <apexchart
        width="100%"
        height="280"
        type="bar"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    colors: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 1050,
    },
    hasTitle: {
      type: Boolean,
      default: false,
    },
    columnWidth: {
      type: String,
      default: '50%',
    },
    borderRadius: {
      type: Number,
      default: 5,
    },
    chartOffsetX: {
      type: Number,
      default: 0,
    },
    chartOffsetY: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          id: 'chart2',
          type: 'bar',
          height: 350,
          offsetY: this.chartOffsetY,
          offsetX: this.chartOffsetX,
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            dropShadow: {
              enabled: true,
            },
          },
        },
        tooltip: {
          x: {
            // eslint-disable-next-line func-names
            formatter: function(_, { seriesIndex, w }) {
              return w.config.series[seriesIndex].name;
            },
          },
          y: {
            title: {
              // eslint-disable-next-line func-names
              formatter: function(_, { dataPointIndex, w }) {
                return w.config.xaxis.categories[dataPointIndex] + ':';
              },
            },
            // eslint-disable-next-line func-names
            formatter: function(value) {
              return value;
            },
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: 'Lato',
          fontWeight: 400,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            width: 16,
            height: 16,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 4,
            customHTML: undefined,
            onClick: undefined,
            offsetX: -5,
            offsetY: 3,
          },
          itemMargin: {
            horizontal: 8,
            vertical: 13,
          },
        },
        colors: this.colors,
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: this.columnWidth,
            borderRadius: this.borderRadius,
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 4,
          colors: ['transparent'],
        },
        xaxis: {
          categories: this.categories,
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              fontSize: '13px',
              colors: '#8c8c8c',
            },
          },
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            style: {
              fontSize: '13px',
              colors: ['#8c8c8c'],
            },
          },
        },
        fill: {
          opacity: 1,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.table-title {
  font-size: $font-size-medium;
  line-height: 2.6rem;
  color: $neutral-grey-800;
  font-weight: $font-weight-medium;
  margin: $space-medium $zero;
  margin-left: $space-one;
}

.chart-card {
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius;
  margin: 2.6rem $zero;
}

.tooltip-position {
  position: absolute;
  top: $zero;
  right: $zero;
}

.pos-relative {
  position: relative;
}
</style>
