<template>
  <div class="medium-3 bg-white contact--panel columns">
    <tab-nav
      :active="activeTab || 'contact'"
      :tab-items="contactTabItems"
      @click="handleTabClick"
    />
    <transition name="menu-list" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import wootConstants from 'dashboard/constants';
import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import { frontendURL, conversationUrl } from 'dashboard/helper/URLHelper';

export default {
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      activeTab: wootConstants.CONTACT_TAB_TYPE.CONTACT,
      showRaiseTicketSection: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      activeInbox: 'getSelectedInbox',
      isDeepShopifyEnabled: 'accounts/getDeepShopifyEnabledFlag',
    }),
    contactTabItems() {
      return this.$t('CONTACT_PANEL.TABS').map(item => {
        return {
          key: item.KEY,
          name: item.NAME,
          icon: item.ICON,
        };
      });
    },
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
  },
  watch: {
    $route(to) {
      const routeTo = to.path.substring(to.path.lastIndexOf('/') + 1);
      this.setActiveTab(routeTo, 'watch'); // Pass 'watch' as the source parameter
    },
    currentChat(newChat, oldChat) {
      if (newChat !== oldChat) {
        this.getCart();
        this.$store.dispatch('clearCustomer');
        this.$store.dispatch('resetDraftOrder');
      }
    },
  },
  created() {
    this.getCart();
    const routeTo = this.$route.path.substring(
      this.$route.path.lastIndexOf('/') + 1
    );
    this.setActiveTab(routeTo, 'created');
  },
  methods: {
    handleTabClick(selectedTab) {
      this.setActiveTab(selectedTab, 'user-click');
    },
    setActiveTab(selectedTab, source) {
      if (source === 'user-click') {
        this.mix_panel_clicked_ticket_action_tab(selectedTab);
      }
      const { activeInbox } = this;
      const {
        accountId,
        conversation_id: conversationId,
      } = this.$router.history.current.params;

      const path = conversationUrl({
        accountId: accountId,
        activeInbox,
        id: conversationId,
      });

      const params = window.location.search;

      if (this.activeTab !== selectedTab) {
        switch (selectedTab) {
          case wootConstants.CONTACT_TAB_TYPE.CONTACT:
            this.$router.push(frontendURL(path) + params);
            this.activeTab = wootConstants.CONTACT_TAB_TYPE.CONTACT;
            break;
          case wootConstants.CONTACT_TAB_TYPE.ORDERS:
            this.$router.push(frontendURL(path) + '/orders' + params);
            this.activeTab = wootConstants.CONTACT_TAB_TYPE.ORDERS;
            break;
          case wootConstants.CONTACT_TAB_TYPE.PRODUCTS:
            this.$router.push(frontendURL(path) + '/products' + params);
            this.activeTab = wootConstants.CONTACT_TAB_TYPE.PRODUCTS;
            break;
          case wootConstants.CONTACT_TAB_TYPE.CART:
            this.$router.push(frontendURL(path) + '/orders/cart' + params);
            this.activeTab = wootConstants.CONTACT_TAB_TYPE.ORDERS;
            break;
          default:
            this.$router.push(frontendURL(path) + params);
            this.activeTab = wootConstants.CONTACT_TAB_TYPE.CONTACT;
            break;
        }
      }
    },
    getCart() {
      if (!this.isDeepShopifyEnabled) return;

      const { phone_number: phoneNumber, email, name } = this.contact;
      if (!phoneNumber && !email) {
        this.$store.dispatch('emptyCustomerCart');
        this.$store.dispatch('resetDraftOrder');
        return;
      }
      const payload = {
        phone_number: phoneNumber || '',
        email: email || '',
        firstName: name || '',
      };
      this.$store.dispatch('getCustomerCart', payload);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.contact--panel {
  @include elevation-1dp;

  display: flex;
  flex-direction: column;
  background: white;
  font-size: $font-size-small;
  overflow-y: hidden;
  overflow: hidden;
  position: relative;
  padding: 0 0 $space-slab 0;

  i {
    margin-right: $space-smaller;
  }
}
</style>
