<template>
  <div
    class="address--container custom-scroll"
    :class="{
      'address--selected': isSelected,
      'address--disable': creatingDraftOrder,
    }"
    @mouseover="showEditOption = true"
    @mouseleave="showEditOption = false"
    @click="$emit('updateSelectedAddress', data.id)"
  >
    <div class="flex-row flex-justify--between">
      <span class="subtitle-s2 text-light">
        {{ $t('ORDER.ADDRESS.LABEL', { idx: index + 1 }) }}
      </span>
      <span style="cursor: pointer; gap: 0.8rem" class="flex-row">
        <icons
          v-if="showEditOption && isSelected"
          name="editRound"
          size="medium"
          :color="isSelected ? 'green' : 'grey'"
          title="Edit"
        />
        <icons
          :name="isSelected ? 'tickFilled' : 'tickRound'"
          size="medium"
          :color="isSelected ? 'green' : 'grey'"
          title="Select"
        />
      </span>
    </div>
    <span class="body-b2 text-dark" v-html="address" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    index: {
      type: Number,
      default: 1,
    },
    selected: {
      type: Number,
      default: -1,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showEditOption: false,
    };
  },
  computed: {
    ...mapGetters({
      creatingDraftOrder: 'getDraftOrderUIFlag',
    }),
    address() {
      const {
        first_name,
        last_name,
        address_1,
        address_2,
        city,
        province,
        zip,
        phone_number,
        email,
      } = this.data;
      return `
      ${first_name ? first_name + ' ' : ''}
      ${last_name ? last_name + '' : ''}<br />
      ${address_1 ? address_1 + '<br />' : ''}
      ${address_2 ? address_2 + '<br />' : ''}
      ${city ? city + ', ' : ''}${province ? province + '<br />' : ''}
      ${zip ? 'Pincode: ' + zip + '<br />' : ''}
      ${phone_number ? 'Phone: ' + phone_number + '<br />' : ''}
      ${email ? 'Email: ' + email : ''}
        `;
    },
    isSelected() {
      return this.selected === this.data.id;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.address {
  &--container {
    border: 1px solid $neutral-grey-400;
    border-radius: $border-radius;
    cursor: pointer;
    max-height: $space-two * 10;
    margin: $space-slab 0 $space-slab;
    padding: $space-slab;
    transition: all 0.2s ease;
  }

  &--selected {
    border: 1px solid $pg-1;
  }

  &--disable {
    pointer-events: none;
    opacity: 0.8;
  }
}
</style>
