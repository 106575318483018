import Vue from 'vue';
import types from '../../mutation-types';

const mutations = {
  [types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.SET_CUSTOM_TICKET_FIELDS_LIST]: ($state, data) => {
    $state.records = data;
  },

  [types.REMOVE_CUSTOM_TICKET_FIELD]: ($state, id) => {
    $state.records = $state.records.filter(record => record.id !== id);
  },

  [types.SET_CUSTOM_TICKET_FIELD_BY_ID]: ($state, data) => {
    const index = $state.records.findIndex(record => record.id === data.id);

    if (index !== -1) {
      Vue.set($state.records, index, data);
    }
  },

  [types.ADD_CUSTOM_TICKET_FIELD]: ($state, data) => {
    $state.records = [...$state.records, data];
  },

  [types.TOGGLE_CUSTOM_TICKET_FIELD_STATUS]: ($state, { id, status }) => {
    const index = $state.records.findIndex(record => record.id === id);

    if (index !== -1) {
      Vue.set($state.records[index], 'enabled', status);
    }
  },
};

export default mutations;
