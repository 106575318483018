<template>
  <div class="template-form custom-scroll">
    <div class="template-container">
      <woot-input
        v-if="selectedTemplate.media_url"
        :value="templateMediaUrl.replace('{}', '')"
        :disabled="selectedTemplate.media_url !== '{}'"
        class="medium-12 columns"
        :label="$t('TEMPLATE.ADD.FORM.MEDIA.LABEL')"
        :placeholder="$t('TEMPLATE.ADD.FORM.MEDIA.PLACEHOLDER')"
        @input="value => $emit('update:templateMediaUrl', value)"
      />

      <span
        v-for="(item, index) in selectedTemplate.body.split('{}')"
        :key="index"
        class="template-text-container"
      >
        <span
          v-for="(word, ind) in item.split(' ')"
          :key="ind"
          class="template-word"
        >
          {{ word }}
        </span>

        <template v-if="substitutionCount > index">
          <input
            v-if="inputType === 'text'"
            :value="parameters[index]"
            :placeholder="`Enter Text`"
            type="text"
            @input="$emit('setParams', $event.target.value, index)"
          />

          <select
            v-else
            :value="
              parameters[index].startsWith('Hard-coded value:')
                ? 'Hard-coded value'
                : parameters[index]
            "
            @input="updateParam($event.target.value, index)"
          >
            <option
              v-for="(params, indx) in predefinedParams"
              :key="indx"
              :value="params"
            >
              {{ params }}
            </option>
          </select>
          <input
            v-if="parameters[index].startsWith('Hard-coded value:')"
            :value="parameters[index].replace('Hard-coded value:', '')"
            type="text"
            style="margin-left: 0.8rem"
            :placeholder="`Enter string to hard-code`"
            @input="updateHardcodedParam($event.target.value, index)"
          />
        </template>
      </span>

      <div
        v-if="selectedTemplate.buttons"
        class="medium-12 columns flex-column"
      >
        <div
          v-for="(button, inx) in selectedTemplate.buttons.buttons"
          :key="inx"
          class="flex-row mg-top--small"
        >
          <woot-secondary-button
            v-tooltip.bottom="button.url"
            icon-size="semimedium"
            icon-color="grey"
            custom-class="read-only-button mg-right--smaller"
            size="medium"
            :name="button.title"
          />
          <template v-if="button.type && button.type === 'dynamic_url'">
            <input
              v-if="inputType === 'text'"
              :value="templateButtonUrl"
              class="columns"
              :placeholder="$t('TEMPLATE.EDIT.FORM.BUTTON.URL')"
              type="text"
              @input="updateTemplateButtonUrl($event.target.value)"
            />

            <select
              v-else
              :value="
                templateButtonUrl.startsWith('Hard-coded value:')
                  ? 'Hard-coded value'
                  : templateButtonUrl
              "
              @input="updateTemplateButtonUrl($event.target.value)"
            >
              <option
                v-for="(params, indx) in predefinedParams"
                :key="indx"
                :value="params"
              >
                {{ params }}
              </option>
            </select>
            <input
              v-if="templateButtonUrl.startsWith('Hard-coded value:')"
              :value="templateButtonUrl.replace('Hard-coded value:', '')"
              type="text"
              style="margin-left: 0.8rem"
              :placeholder="`Enter string to hard-code`"
              @input="
                $emit(
                  'update:templateButtonUrl',
                  `Hard-coded value:${$event.target.value}`
                )
              "
            />
          </template>
        </div>
      </div>
    </div>

    <button
      v-if="showCloseButton"
      type="button"
      class="cancel-message--button"
      @click="onClose"
    >
      <icons name="cancel" color="stroke-red" size="semimedium" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    predefinedParams: { type: Array, default: () => [] },
    parameters: { type: Array, default: () => [] },
    selectedTemplate: { type: Object, required: true },
    templateMediaUrl: { type: String, default: '' },
    templateButtonUrl: { type: String, default: '' },
    inputType: { type: String, default: 'text' },
    showCloseButton: { type: Boolean, default: true },
  },
  computed: {
    substitutionCount() {
      return this.parameters.length;
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    updateParam(value, index) {
      if (value === 'Hard-coded value') {
        this.$emit('setParams', 'Hard-coded value:', index);
      } else {
        this.$emit('setParams', value, index);
      }
    },
    updateTemplateButtonUrl(value) {
      if (value === 'Hard-coded value') {
        this.$emit('update:templateButtonUrl', 'Hard-coded value:');
      } else {
        this.$emit('update:templateButtonUrl', value);
      }
    },
    updateHardcodedParam(value, index) {
      this.$emit('setParams', `Hard-coded value:${value}`, index);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.template-form {
  max-width: 100%;
  display: flex;
  padding: $space-small $zero;
  max-height: 20rem;

  .cancel-message--button {
    position: absolute;
    top: $space-slab;
    right: $space-small;
    cursor: pointer;
    background: $neutral-white;

    &:focus {
      outline: 0;
    }
  }

  .template-container {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    input {
      padding: $space-small;

      &::placeholder {
        text-align: center;
      }
    }

    select,
    input {
      margin: $space-micro $space-small;
      width: $space-mega;
      border: 0.5px solid $pg-1-500;
      border-radius: $border-radius;
    }

    .template-text-container {
      font-size: $font-size-small;
      color: $neutral-grey-600;
      flex: 0 1 auto;
      flex-wrap: wrap;
      display: flex;
      max-width: 100%;
      align-items: center;
      word-break: break-word;

      .template-word {
        margin-left: $space-micro;
        margin-right: $space-micro;
        flex: 0 1 auto;
        white-space: nowrap;
      }
    }
  }
}
</style>
