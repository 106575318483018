import axiosInstance, { setAccessToken } from './axiosInstance';

export async function getWAWidgetConfig(phone, accessToken) {
  try {
    setAccessToken(accessToken);
    const response = await axiosInstance.get(`/getWidgetConfig/${phone}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function updateWAWidget(phone, updateData, accessToken) {
  try {
    setAccessToken(accessToken);
    const response = await axiosInstance.patch(
      `/updateWidgetConfig/${phone}`,
      updateData
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function createWAWidget(phone, isShopify, prefill_text) {
  try {
    const response = await axiosInstance.post('/createWidget', {
      phone,
      isShopify,
      prefill_text,
    });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function deleteWAWidget(phone, accessToken) {
  try {
    setAccessToken(accessToken);
    const response = await axiosInstance.delete(`/deleteByPhone/${phone}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}
