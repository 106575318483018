/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';

class MessageApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  create({
    conversationId,
    message,
    private: isPrivate,
    echo_id: echoId,
    contentAttributes,
    parentMessageId,
    isTemplate,
    templateId,
    parameters,
    contentType,
    ccEmails,
    bccEmails,
    toEmails,
    attachments,
    messageType,
    quotedText,
    hasEmailSignature,
  }) {
    let payload;

    if (attachments && attachments.length !== 0) {
      payload = new FormData();
      if (message) payload.append('content', message);
      if (messageType === 'forwarded_mail')
        payload.append('message_type', messageType);
      if (isPrivate) payload.append('private', isPrivate);
      if (quotedText) payload.append('quoted_text', quotedText);
      payload.append('has_email_signature', hasEmailSignature);
      if (contentType) payload.append('content_type', contentType);
      if (isTemplate) payload.append('isTemplate', isTemplate);
      if (parentMessageId) payload.append('parent_message_id', parentMessageId);
      if (templateId) payload.append('template_id', templateId);
      if (parameters) payload.append('template_attributes', parameters);
      if (echoId) payload.append('echo_id', echoId);
      if (ccEmails) payload.append('cc_emails', ccEmails);
      if (bccEmails) payload.append('bcc_emails', bccEmails);
      if (toEmails) payload.append('to_emails', toEmails);
      attachments.forEach(file => {
        payload.append('attachments[]', file);
      });
    } else {
      payload = {
        content: message,
        private: isPrivate,
        content_attributes: contentAttributes,
        content_type: contentType,
        isTemplate: isTemplate,
        template_id: templateId,
        parent_message_id: parentMessageId,
        template_attributes: parameters,
        echo_id: echoId,
        cc_emails: ccEmails,
        bcc_emails: bccEmails,
        to_emails: toEmails,
        quoted_text: quotedText,
        has_email_signature: hasEmailSignature,
      };
      if (messageType && messageType === 'forwarded_mail')
        payload = { ...payload, message_type: messageType };
      if (isTemplate) payload = { ...payload, message_type: 'template' };
    }
    return axios.post(`${this.url}/${conversationId}/messages`, payload);
  }

  deleteMessage({ conversationId, messageId }) {
    return axios.delete(`${this.url}/${conversationId}/messages/${messageId}`);
  }

  getPreviousMessages({
    conversationId,
    before,
    last_message_id,
    referenced_message_id,
  }) {
    return axios.get(`${this.url}/${conversationId}/messages`, {
      params: {
        before,
        last_message_id,
        referenced_message_id,
      },
    });
  }

  sendAttachment([conversationId, { file, isPrivate = false }]) {
    const formData = new FormData();
    formData.append('attachments[]', file, file.name);
    formData.append('private', isPrivate);

    return axios({
      method: 'post',
      url: `${this.url}/${conversationId}/messages`,
      data: formData,
    });
  }
}

export default new MessageApi();
