<template>
  <div class="company-modal-popup">
    <div class="company-avatar">
      <woot-loading-state
        v-if="loading"
        :custom-style="{
          padding: '0rem',
          height: '7.2rem',
          display: 'flex',
          'align-items': 'center',
        }"
      />
      <Avatar
        v-else
        :username="currentAccount && currentAccount.name"
        :rounded="false"
        :size="56"
        :colored="true"
        :show-upload-button="true"
        :custom-style="{
          'font-size': '2.2rem',
          'box-shadow': 'none',
        }"
        :img-src="avatarUrl ? avatarUrl : avatarImgSrc"
        @change="handleImageUpload"
      />

      <div
        :style="{ overflow: 'hidden' }"
        class="flex-column flex-justify mg-left--slab"
      >
        <div class="title-h4 text-dark text-truncate">
          {{ currentAccount.name }}
        </div>
        <div class="body-b3 text-light text-truncate">
          {{ 'Account ID: ' + currentAccount.id }}
        </div>
      </div>
    </div>

    <div
      v-if="
        currentUser && currentUser.accounts && currentUser.accounts.length > 1
      "
      class="account-list"
    >
      <span class="divider" />

      <search-box
        :placeholder="$t('SIDEBAR_ITEMS.ACCOUNT_SEARCH_TXT')"
        :value="search"
        size="medium"
        @setSearch="setSearch"
      />

      <div class="custom-scroll account-list--container">
        <label class="selected-account-container flex-space-between">
          <div
            class="title-h5 text-dark selected-account-container--name text-truncate"
          >
            {{ `#${currentAccount.id} ${currentAccount.name}` }}
          </div>
          <div class="subtitle-s2 text-light selected-account-container--role">
            {{ currentAccount.role }}
          </div>
        </label>

        <a
          v-for="account in filteredUserAccount"
          :key="account.id"
          :href="redirectOnSwitchAccount(account.id)"
          class="account-selector"
        >
          <label :for="account.name" class="account--details">
            <div class="title-h5 text-dark account--name text-truncate">
              {{ `#${account.id} ${account.name}` }}
            </div>
            <div class="subtitle-s2 text-light account--role">
              {{ account.role }}
            </div>
          </label>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '../widgets/Avatar.vue';
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    Avatar,
    SearchBox,
  },
  mixins: [alertMixin],
  props: {
    currentAccount: {
      type: Object,
      default: () => {},
    },
    currentUser: {
      type: Object,
      default: () => {},
    },
    avatarUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: '',
      avatarFile: null,
      avatarImgSrc: '',
      loading: false,
    };
  },
  computed: {
    filteredUserAccount() {
      let searchResults = this.currentUser?.accounts?.filter(account => {
        return (
          account.name.toLowerCase().search(this.search.toLowerCase()) > -1 &&
          account.id !== this.accountId
        );
      });
      return searchResults.filter(
        account => account.id !== this.currentAccount.id
      );
    },
  },

  methods: {
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarImgSrc = url;
      this.updateLogo();
    },
    async updateLogo() {
      this.loading = true;
      const formData = new FormData();
      formData.append('avatar_url', this.avatarFile);

      try {
        await this.$store.dispatch('accounts/update', formData);
        this.showAlert(
          this.$t('GENERAL_SETTINGS.LOGO_UPDATE.SUCCESS'),
          'success'
        );
        this.loading = false;
        this.$emit('getLogoImg');
      } catch (error) {
        this.loading = false;
        this.showAlert(this.$t('GENERAL_SETTINGS.LOGO_UPDATE.ERROR'), 'error');
      }
    },
    setSearch(value) {
      this.search = value;
    },
    redirectOnSwitchAccount(current_account_id) {
      let backlink = window.location.href;
      let result = '';

      if (
        backlink.includes('/accounts/') &&
        backlink.match(/\d+/g) &&
        backlink.includes('settings/inboxes') &&
        backlink.includes('tab=')
      ) {
        let splitted = backlink.split('accounts');
        let second_half = splitted[1];
        let str = second_half;
        let replStr = current_account_id;
        let re = /^(\/)([^/]+)/gm;
        result = str.replace(re, '$1' + replStr);
        result = '/app/accounts' + result;
        let splitted2 = result.split('inboxes');
        result = splitted2[0] + 'inboxes';
      } else if (backlink.includes('/accounts/') && backlink.match(/\d+/g)) {
        let splitted = backlink.split('accounts');
        let second_half = splitted[1];
        let str = second_half;
        let replStr = current_account_id;
        let re = /^(\/)([^/]+)/gm;
        result = str.replace(re, '$1' + replStr);
        result = '/app/accounts' + result;
      }

      return result;
    },
  },
};
</script>
<style lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.selected-account-container {
  background: $pg-1-500;
  padding: $space-small;
  border-radius: $space-smaller;

  .selected-account-container--name {
    text-transform: capitalize;
    color: $color-white;
  }
  .selected-account-container--role {
    text-transform: capitalize;
    color: $color-white;
  }
}
.divider {
  display: block;
  height: 1px;
  background: $neutral-grey-300;
  margin: $space-micro 0;
  width: 100%;
}

.search-box {
  padding: $space-normal;
}
.dropdown-arrow {
  @include button-animation;
  cursor: pointer;
  margin: $space-smaller;
}
.account-list {
  padding: $space-slab;
  display: flex;
  flex-direction: column;
  width: 100%;

  &--container {
    max-height: 30rem;
    width: 100%;
  }
}
.account-selector {
  align-items: center;
  display: flex;
  cursor: pointer;
  cursor: pointer;
  padding: $space-small;

  .account--details {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .account--role {
    text-transform: capitalize;
  }

  &:hover {
    background: $neutral-grey-200;
  }
}
</style>
