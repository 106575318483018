export const SEARCH_BY = {
  MESSAGE: 'message',
  NAME: 'name',
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
};

export const SEARCH_BY_OPTIONS = [
  {
    name: 'messages',
    icon: 'chatRound',
    value: SEARCH_BY.MESSAGE,
  },
  {
    name: 'people',
    icon: 'people',
    value: SEARCH_BY.NAME,
  },
  {
    name: 'email id',
    icon: 'email',
    value: SEARCH_BY.EMAIL,
  },
  {
    name: 'contacts',
    icon: 'phone',
    value: SEARCH_BY.PHONE_NUMBER,
  },
];
