<template>
  <CreateFromTemplate
    :templates="recommendedAutomationTemplates"
    :modal-title="$t('AUTOMATION.CREATE.MODAL.TITLE')"
    :loading="uiFlags.isFetchingTemplates"
    opened
    :handle-create="data => $emit('handleCreate', data)"
    @onClose="$emit('onClose')"
  />
</template>
<script>
import { mapGetters } from 'vuex';

import CreateFromTemplate from 'components/ui/modals/CreateFromTemplate';

export default {
  components: { CreateFromTemplate },
  computed: {
    ...mapGetters({
      automationTemplates: 'automations/getAutomationTemplates',
      uiFlags: 'automations/getUIFlags',
    }),
    recommendedAutomationTemplates() {
      return this.automationTemplates.filter(template => template.recommended);
    },
  },
  created() {
    this.$store.dispatch('automations/getTemplates');
  },
};
</script>
