<template>
  <div class="lime-card-new pie-chart-skeleton" :style="customStyle">
    <div class="skeleton-animation pie-header"></div>
    <div class="flex-space-between">
      <div
        class="skeleton-animation pie-circle"
        :style="{ height: chartHeight }"
      ></div>
      <div class="skeleton-animation pie-legend"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chartHeight: {
      type: String,
      default: '20rem',
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.pie-chart-skeleton {
  margin: $space-micro $space-micro $space-normal;
}

.pie-header {
  height: 2rem;
  width: 50%;
  margin-bottom: $space-medium;
}

.pie-circle {
  width: 20rem;
  border-radius: 50%;
}

.pie-legend {
  height: 6rem;
  width: 9rem;
  margin: auto $space-medium;
}
</style>
