<template>
  <div class="columns small-12">
    <page-header
      v-if="!hasLoginStarted"
      :header-title="$t('INBOX_MGMT.ADD.FB.TITLE_INSTA')"
    >
      <reference-box
        :to="documentation.channels.facebook"
        content="Know how to create an instagram inbox here"
        type="document"
      />
    </page-header>
    <div v-if="!hasLoginStarted" class="login-init full-height">
      <a href="#" @click="startLogin">
        <img
          src="~dashboard/assets/images/channels/facebook_login.png"
          alt="Facebook-logo"
        />
      </a>
      <p>
        {{ useInstallationName($t('INBOX_MGMT.ADD.FB.HELP')) }}
      </p>
    </div>
    <div v-else>
      <loading-state
        v-if="showLoader && !inboxCreated"
        :message="emptyStateMessage"
      />
      <div v-else-if="!showLoader && !inboxCreated">
        <page-header
          :header-title="$t('INBOX_MGMT.ADD.DETAILS.TITLE')"
          :header-content="
            useInstallationName($t('INBOX_MGMT.ADD.DETAILS.IGDESC'))
          "
        />
        <page-header
          size="medium"
          :header-title="$t('INBOX_MGMT.ADD.FB.CHOOSE_IG')"
        />
        <form class="row" @submit.prevent="createChannel">
          <div class="medium-12 columns">
            <div
              v-if="selectedIGs.length"
              class="settings-sub-menu-content--larger medium-12 agent-container"
            >
              <woot-chips
                v-for="(ig, index) in selectedIGs"
                :key="ig.id"
                :title="ig.name"
                :show-cancel-icon="true"
                :bg-color="'#CDF0A2'"
                @click="onRemoveIg(index)"
              />
            </div>
            <div
              class="settings-sub-menu-content--larger medium-12 columns content-margin"
            >
              <selector
                :custom-button="true"
                :hide-on-select="false"
                :option-list="getSelectableIGs"
                :custom-style="{ top: '4.8rem', left: 'auto', width: '100%' }"
                @click="selectIg"
              >
                <button type="button" class="selector-button">
                  <span>{{ $t('INBOX_MGMT.ADD.FB.PICK_A_VALUE') }}</span>
                  <icons name="chevronDown" color="grey" size="semimedium" />
                </button>
              </selector>
            </div>
            <div
              class="settings-sub-menu-content--larger flex-row flex-justify--end"
            >
              <woot-base-button :disabled="!selectedIGs.length" type="submit">
                Create Inbox
              </woot-base-button>
            </div>
          </div>
        </form>
      </div>
      <div v-else>
        <page-header
          header-title="Inboxes Created!"
          class="flex-column flex-align--start"
        >
          <div class="body-b2 text-light">
            {{
              ' You can add agents to the Inbox by going to Inbox settings or'
            }}
            <router-link :to="routeState">
              Click here
            </router-link>
            {{ 'to go to conversations.' }}
          </div>
        </page-header>
        <div class="table-container settings-sub-menu-content--larger">
          <data-table
            :actions="actionsList"
            :columns="tableColumns"
            :table-data="inboxList"
            :loading="!inboxList.length"
            _class="scrollable-table custom-scroll"
            @edit="openInboxSettings"
          />
        </div>
      </div>
    </div>
    <div class="medium-12 columns settings-sub-menu-bottom-nav">
      <woot-base-button
        variant="secondary"
        @click.prevent="() => $router.go(-1)"
      >
        Back
      </woot-base-button>
    </div>
  </div>
</template>

<script>
/* eslint-env browser */
/* global FB */
import { mapGetters } from 'vuex';

import DataTable from 'dashboard/components/widgets/table/DataTable';
import LoadingState from 'dashboard/components/widgets/LoadingState';
import PageHeader from '../../SettingsSubPageHeader';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';

import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import accountMixin from 'dashboard/mixins/account';
import adminMixin from 'dashboard/mixins/isAdmin';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

import ChannelApi from 'dashboard/api/channels';
import { frontendURL } from 'dashboard/helper/URLHelper';
import documentation from 'dashboard/helper/data/documentationData.json';

export default {
  components: {
    DataTable,
    LoadingState,
    PageHeader,
    ReferenceBox,
  },
  mixins: [adminMixin, globalConfigMixin, accountMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      isLoading: false,
      inboxCreated: false,
      hasLoginStarted: false,
      user_access_token: '',
      emptyStateMessage: this.$t('INBOX_MGMT.DETAILS.LOADING_FB'),
      channel: 'facebook',
      selectedIGs: [],
      pageList: [],
      tableColumns: [
        {
          label: '',
          prop: 'avatar',
          width: '10%',
          component: 'InboxAvatar',
        },
        {
          label: 'Inbox',
          prop: 'name',
          width: '34%',
          sortable: true,
        },
        {
          label: 'Type',
          prop: 'channel_type',
          width: '28%',
          component: 'InboxChannelType',
        },
      ],
      documentation,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      inboxes: 'inboxes/getInboxes',
      accountId: 'getCurrentAccountId',
    }),
    actionsList() {
      if (!this.isAdmin) return [];
      return [{ id: 0, name: 'edit', icon: 'editRound' }];
    },
    routeState() {
      return frontendURL(
        `accounts/${this.accountId}/dashboard?assignee_tab=all`
      );
    },
    showLoader() {
      return !this.user_access_token || this.isLoading;
    },
    getSelectableIGs() {
      let selectedIds = this.selectedIGs.map(item => {
        return item.id;
      });

      let iGs = this.pageList.filter(item => !item.exists);

      iGs.forEach(item => {
        if (selectedIds.includes(item.id)) item.selected = true;
        else item.selected = false;
      });

      return iGs;
    },
    inboxList() {
      return this.inboxes.filter(item => item.channel_type === INBOX_TYPES.FB);
    },
  },
  created() {
    this.initFB();
    this.loadFBsdk();
  },
  mounted() {
    this.initFB();
  },
  methods: {
    openInboxSettings(item) {
      let route = this.addAccountScoping(`settings/inboxes/${item.id}`);
      this.$router.push(route);
    },
    startLogin() {
      this.hasLoginStarted = true;
      this.tryFBlogin();
    },
    selectIg(ig) {
      this.selectedIGs.push(ig);
    },
    onRemoveIg(ig) {
      this.selectedIGs.splice(ig, 1);
    },
    initChannelAuth(channel) {
      if (channel === 'facebook') {
        this.loadFBsdk();
      }
    },
    initFB() {
      if (window.fbSDKLoaded === undefined) {
        window.fbAsyncInit = () => {
          FB.init({
            appId: window.chatwootConfig.fbAppId,
            xfbml: true,
            version: 'v7.0',
            status: true,
          });
          window.fbSDKLoaded = true;
          FB.AppEvents.logPageView();
        };
      }
    },
    loadFBsdk() {
      ((d, s, id) => {
        let js;
        // eslint-disable-next-line
        const fjs = (js = d.getElementsByTagName(s)[0]);
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    },
    tryFBlogin() {
      FB.login(
        response => {
          if (response.status === 'connected') {
            this.mix_panel_clicked_create_inbox_successful(
              'Instagram',
              'ig_inbox'
            );
            this.fetchPagesandIGs(response.authResponse.accessToken);
          } else if (response.status === 'not_authorized') {
            this.mixPanelIGInboxCreationErrorNotAuthorized();

            // The person is logged into Facebook, but not your app.
            this.emptyStateMessage = this.$t(
              'INBOX_MGMT.DETAILS.ERROR_FB_AUTH'
            );
          } else {
            this.mixPanelIGInboxCreationError();

            // The person is not logged into Facebook, so we're not sure if
            // they are logged into this app or not.
            this.emptyStateMessage = this.$t(
              'INBOX_MGMT.DETAILS.ERROR_FB_AUTH'
            );
          }
        },
        {
          scope:
            'pages_manage_metadata,pages_messaging,pages_read_user_content,pages_manage_engagement,pages_read_engagement,instagram_manage_messages,instagram_basic,instagram_manage_comments',
        }
      );
    },
    async fetchPagesandIGs(_token) {
      try {
        const response = await ChannelApi.fetchFacebookPages(
          _token,
          this.accountId,
          'instagram'
        );
        const {
          data: { data },
        } = response;
        this.pageList = data.page_details;
        this.user_access_token = data.user_access_token;
      } catch (error) {
        // Ignore error
      }
    },
    channelParams(inbox) {
      return {
        user_access_token: this.user_access_token,
        page_access_token: inbox.access_token,
        instagram_id: inbox.instagram_id,
        instagram_add_on: inbox.add_on,
        page_id: inbox.id,
        inbox_name: inbox.name,
      };
    },
    createChannel() {
      this.emptyStateMessage = this.$t('INBOX_MGMT.DETAILS.CREATING_CHANNEL');
      this.isLoading = true;
      let inboxes = [...this.selectedIGs];
      inboxes.forEach(inbox => {
        this.$store.dispatch(
          'inboxes/createFBChannel',
          this.channelParams(inbox)
        );
      });

      this.$store.dispatch('inboxes/get').then(() => {
        this.inboxCreated = true;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.form-label {
  justify-content: flex-start;
  align-items: center;
  padding: $zero $space-small $space-one;
  color: $neutral-grey-800;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: 22px;
}

.content-margin {
  margin-bottom: 2.4rem;
}

.medium-12 {
  .button {
    float: right;
  }
}
.selector-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: $settings-searchbar-height;
  align-items: center;
  color: $neutral-grey-800;
  font-size: $font-size-small;
  line-height: 22px;
  background: $neutral-grey-200;
  border: 1px solid $neutral-grey-200;
  cursor: pointer;
  border-radius: $border-radius-smaller;
  padding: $space-slab;

  &:focus {
    border: 1px solid $pg-1-500;
    background: $neutral-white;
    outline: none;
  }

  &:hover {
    border: 1px solid $neutral-grey-600;
    background: $neutral-white;
  }
}
.no-items-error-message {
  margin-top: $space-medium;
  text-align: center;
}
.table-container {
  height: 100%;
}
</style>
