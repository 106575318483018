<template>
  <header class="mg-bottom--medium">
    <div
      class="title-h4 text-dark"
      v-text="$t(`SLA.CREATE.SECTIONS.${translationKey}.TITLE`)"
    />
    <div
      class="body-b2 text-light"
      v-text="$t(`SLA.CREATE.SECTIONS.${translationKey}.DESC`)"
    />
  </header>
</template>
<script>
export default {
  name: 'Header',
  props: {
    translationKey: {
      type: String,
      default: '',
    },
  },
};
</script>
