import { mapGetters } from 'vuex';

import {
  BOT_AVATAR_IMAGE_URL,
  AGENT_AVATAR_IMAGE_URL,
  MESSAGE_TYPE,
} from '../constants/messages';

export default {
  data() {
    return { AGENT_AVATAR_IMAGE_URL };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
      getAccount: 'accounts/getAccount',
    }),
    showReply() {
      if (this.isFirstIncomingComment) return false;

      if (
        [
          MESSAGE_TYPE.INCOMING,
          MESSAGE_TYPE.OUTGOING,
          MESSAGE_TYPE.SYNC_OUT,
        ].includes(this.data.message_type)
      )
        return !!this.data.parent_message_id && this.hasReplyMessageSupport;

      return false;
    },
    currentAccount() {
      return this.currentUser.accounts?.find(
        account => account.id === this.accountId
      );
    },
    agentInfo() {
      return this.$store.getters['agents/getAgent'](this.sender.id);
    },
    senderType() {
      return this.sender?.type;
    },
    cardInfo() {
      const { name, role, email, availability_status: status } = this.agentInfo;

      const defaultInfo = {
        name: this.currentAccountInfo?.name,
        role: this.$t('CONVERSATION.SENDER_INFO.TYPES.SYSTEM'),
        avatarSource: this.avatarUrl,
      };

      if (this.senderType === 'user')
        return {
          name,
          role,
          avatarSource: this.avatarUrl,
          email,
          status,
        };

      const botRoleMap = {
        agent_bot: this.$t('CONVERSATION.SENDER_INFO.TYPES.LIMECHAT'),
        outbound_bot: this.$t('CONVERSATION.SENDER_INFO.TYPES.OUTBOUND'),
      };

      return botRoleMap[this.senderType]
        ? {
            name: name || this.currentAccountInfo.name,
            role: botRoleMap[this.senderType],
            avatarSource: this.avatarUrl,
          }
        : defaultInfo;
    },
    currentAccountInfo() {
      return this.getAccount(this.currentAccount.id);
    },
    avatarUrl() {
      if (this.isBotTypeMessage) return BOT_AVATAR_IMAGE_URL;

      if (this.senderType === 'user' && this.agentInfo.thumbnail)
        return this.agentInfo.thumbnail;

      return this.currentAccountInfo?.avatar_url;
    },
    shouldShowAvatar() {
      if (this.isAnEmail) return false;
      if (!this.isOutgoing && !this.isOutOfOffice) return false;
      if (this.isPending) return false;

      const currentMessage = this.data;
      const nextMessage = this.nextMessage;

      // If it's the last message in the entire list
      if (!nextMessage) return true;

      // if the next message is not an outgoing message
      if (nextMessage.message_type !== MESSAGE_TYPE.OUTGOING) return true;

      const diffInMinutes = this.differenceInMinutes(
        currentMessage.created_at,
        nextMessage.created_at
      );

      // Show avatar if the gap to the next message is more than or equal to 1 minute
      return diffInMinutes >= 1;
    },
  },
  methods: {
    differenceInMinutes(timestamp1, timestamp2) {
      const date1 = new Date(timestamp1 * 1000);
      const date2 = new Date(timestamp2 * 1000);

      return (date2.getTime() - date1.getTime()) / (1000 * 60);
    },
  },
};
