<template>
  <woot-modal :show.sync="showPopup" :show-close="false" :medium-width="true">
    <div class="flex-row-justify-center">
      <div class="feature-toggle">
        <img :src="'/brand-assets/logo.svg'" class="logo" />
        <ul>
          <li
            v-for="item in contentToShow"
            :key="item.ID"
            :class="
              item.FEATURE === contentToShow[currentIndex].FEATURE &&
                activeItemClassname
            "
            @click="navigateToFeature(item.FEATURE)"
          >
            {{ item.FEATURE }}
          </li>
        </ul>
      </div>
      <div class="feature-preview flex-space-between-col">
        <div class="content-container flex-space-between-col">
          <div class="media-container">
            <img
              v-if="currentIndex === 0"
              :src="'/brand-assets/onboarding.svg'"
            />
            <video
              v-else
              :key="source"
              preload="true"
              autoPlay
              muted
              loop
              width="724"
              height="377"
            >
              <source :src="source" type="video/mp4" />
            </video>
          </div>
          <h2 v-if="currentIndex === 0" class="header-title">
            Let's make LimeChat work for {{ companyName }}
          </h2>
          <p class="typography">
            {{ content }}
          </p>
        </div>
        <div class="button-container">
          <template>
            <button
              v-if="currentIndex !== 0"
              class="button hollow"
              style="width: 12rem"
              @click="prevSlide"
            >
              BACK
            </button>
          </template>
          <template v-if="currentIndex === contentToShow.length - 1">
            <woot-button
              id="intercom_widget_open"
              class="button-width"
              @click="closeModal"
            >
              FINISH
            </woot-button>
          </template>
          <template v-else>
            <woot-button
              class="button-width"
              :class="nextSlideButtonClassname"
              @click="nextSlide"
            >
              <span>
                {{ currentIndex === 0 ? `LET'S EXPLORE` : 'NEXT' }}
              </span>
              <span class="icon-rotate">
                <icons
                  v-if="currentIndex === 0"
                  name="arrow"
                  color="white"
                  size="semimedium"
                />
              </span>
            </woot-button>
          </template>
        </div>
      </div>
    </div>
  </woot-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  mixins: [googleAnalyticsMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      currentIndex: 0,
      showPopup: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    content() {
      return this.contentToShow[this.currentIndex].CONTENT;
    },
    contentToShow() {
      let nonShopifyUser = this.currentUser.source !== 'Shopify';
      if (nonShopifyUser) {
        return this.$t('ONBOARDING_MODAL_NON_SHOPIFY');
      }
      return this.$t('ONBOARDING_MODAL_SHOPIFY');
    },
    source() {
      return this.contentToShow[this.currentIndex].SRC;
    },
    companyName() {
      const currAccountId = this.currentUser.account_id;
      let currentAccount = null;
      let companyName = null;

      if (this.currentUser.accounts && this.currentUser.accounts.length !== 0) {
        currentAccount = this.currentUser.accounts.filter(
          acc => acc.id === currAccountId
        );
        companyName = currentAccount[0].name;
      }

      return companyName;
    },
    activeItemClassname() {
      return 'active-item';
    },
    nextSlideButtonClassname() {
      if (this.currentIndex === 0) {
        return 'explore-button';
      }
      return 'next-slide-button';
    },
  },

  mounted() {
    if (localStorage.getItem('firstLaunch')) {
      if (this.currentUser.source === 'Shopify') {
        this.mixPanelShopifyOnBoardingModalInitiated();
      } else {
        this.mixPanelNormalOnBoardingModalInitiated();
      }
    }
    this.showPopup = true;
  },
  methods: {
    hideAddPopup() {
      this.showPopup = false;
    },
    prevSlide() {
      this.logOnBoardModalEvents(
        this.currentUser.source,
        'Back: ' + this.contentToShow[this.currentIndex].FEATURE
      );
      this.currentIndex -= 1;
    },
    nextSlide() {
      this.logOnBoardModalEvents(
        this.currentUser.source,
        'Next: ' + this.contentToShow[this.currentIndex].FEATURE
      );
      this.currentIndex += 1;
    },
    navigateToFeature(feature) {
      const updateIndex = this.contentToShow.findIndex(
        d => d.FEATURE === feature
      );
      this.logOnBoardModalEvents(
        this.currentUser.source,
        'Navigate: ' + feature
      );
      this.currentIndex = updateIndex;
    },
    closeModal() {
      if (this.currentUser.source === 'Shopify') {
        this.mixPanelShopifyOnBoardingModalFinished();
      } else {
        this.mixPanelNormalOnBoardingModalFinished();
      }

      this.logOnBoardModalEvents(this.currentUser.source, 'Finish Modal');
      localStorage.removeItem('firstLaunch');
      this.$emit('closeModal');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
.flex-row-justify-center {
  height: 100%;
}
.feature-toggle {
  background: $neutral-grey-300;
  padding: $space-large;
  width: 38rem;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  .logo {
    margin-left: $space-small;
    margin-bottom: $space-large;
    max-width: 20rem;
  }

  ul {
    list-style-type: none;

    li {
      cursor: pointer;
      display: block;
      font-size: $font-size-medium;
      color: $text-light;
      height: $space-jumbo;
    }

    .active-item {
      color: $pg-1-600;
      font-size: $font-size-jumbo;
      @media screen and (max-width: 1200px) {
        font-size: $font-size-large;
      }
    }

    li:hover {
      color: $pg-1-600;
    }
  }
}
.feature-preview {
  width: 70%;
  text-align: center;
  padding: $space-medium $space-larger;
  background: $chat-background;
  overflow-y: hidden;

  .content-container {
    margin: $space-one;
    .header-title {
      margin-bottom: $space-medium;
      color: $text-dark;
      font-size: $font-size-mega;
      line-height: $space-larger;
      opacity: 0.8;
    }

    .media-container {
      img {
        max-width: 90%;
        height: auto;
        margin: $space-four auto;
      }
      video {
        width: 100%;
        border-radius: $border-radius * 2;
        border: 4px solid $neutral-grey-400;
        object-fit: cover;
        margin: $space-larger auto;
      }
    }
    .typography {
      height: $space-jumbo * 2;
      color: $text-dark;
      opacity: 0.8;
      font-size: $font-size-medium;
      line-height: 3.6rem;
      padding: 0 $space-jumbo $space-normal;
      @media screen and (max-width: 1024px) {
        padding: 0 $space-normal $space-normal;
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    column-gap: $space-normal;
    margin-bottom: $space-normal;

    .explore-button {
      width: 20rem;
      padding: auto $space-medium;
      display: flex;
      justify-content: space-evenly;
    }
    .next-slide-button {
      margin-bottom: $zero;
      width: 12rem;
    }
  }
}
.icon-rotate {
  margin-top: $space-smaller;
  transform: translate(-45deg);
}
.button-width {
  margin-bottom: $zero;
  width: $space-two * 6;
}
</style>
