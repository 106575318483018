<template>
  <woot-delete-modal-new
    :show.sync="show"
    :on-close="onClose"
    :on-confirm="onConfirm"
    :title="$t('SLA.GO_BACK.TITLE')"
    :message="$t('SLA.GO_BACK.DESC')"
    show-close
    :confirm-text="$t('SLA.GO_BACK.CONFIRM')"
    :reject-text="$t('SLA.GO_BACK.CANCEL')"
    :custom-style="{
      'max-width': '35.2rem',
      height: 'auto',
    }"
  />
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onConfirm() {
      this.$emit('confirm');
    },
  },
};
</script>
