<template>
  <component :is="renderProductComponent" />
</template>
<script>
import { mapGetters } from 'vuex';

import ProductPanelNonDeepShopify from './nonDeepShopify/ProductPanel';
import ProductPanelDeepShopify from './deepShopify/ProductPanel';

export default {
  components: { ProductPanelNonDeepShopify, ProductPanelDeepShopify },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      isDeepShopifyEnabled: 'accounts/getDeepShopifyEnabledFlag',
    }),
    renderProductComponent() {
      if (!this.isDeepShopifyEnabled) return 'ProductPanelNonDeepShopify';
      return 'ProductPanelDeepShopify';
    },
  },
};
</script>
