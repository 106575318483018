<template>
  <transition-group name="toast-fade" tag="div" class="ui-snackbar-container">
    <snackbar
      v-for="snackMessage in snackMessages"
      :key="snackMessage.key"
      :message="snackMessage.message"
      :variant="snackMessage.variant"
    />
  </transition-group>
</template>

<script>
import Snackbar from './Snackbar';

export default {
  components: {
    Snackbar,
  },
  props: {
    duration: {
      type: Number,
      default: 2500,
    },
  },

  data() {
    return {
      snackMessages: [],
    };
  },

  mounted() {
    bus.$on('newToastMessage', message => {
      this.snackMessages.push({
        key: new Date().getTime(),
        message: message[0],
        variant: message[1] ? message[1] : 'info',
      });
      window.setTimeout(
        () => {
          this.snackMessages.splice(0, 1);
        },
        message[2] ? message[2] : this.duration
      );
    });
  },
};
</script>
