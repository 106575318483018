<template>
  <div class="assignment-panel custom-scroll">
    <div class="assignment-panel--header">
      <div v-if="isAutoMode" class="flex-row flex-align gap--medium">
        <assignment-logic-radio
          :label="
            $t('TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.TYPES.LOAD_BALANCED.TITLE')
          "
          :info-text="
            $t(
              'TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.TYPES.LOAD_BALANCED.INFO_TEXT'
            )
          "
          :is-active="isLoadBalanceActive"
          @toggle="toggleAssignmentLogic(ASSIGNMENT_LOGIC.LOAD_BALANCED)"
        />
        <assignment-logic-radio
          :label="
            $t('TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.TYPES.ROUND_ROBIN.TITLE')
          "
          :info-text="
            $t('TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.TYPES.ROUND_ROBIN.INFO_TEXT')
          "
          :is-active="isRoundRobinActive"
          @toggle="toggleAssignmentLogic(ASSIGNMENT_LOGIC.ROUND_ROBIN)"
        />
      </div>
      <div v-else class="flex-row flex-align gap--small">
        <span
          class="title-h4 text-dark"
          v-text="$t('TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.TYPES.MANUAL.TITLE')"
        />
        <span
          v-tooltip="
            $t('TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.TYPES.MANUAL.INFO_TEXT')
          "
        >
          <icons name="info" size="small" color="darkgrey" />
        </span>
      </div>
    </div>
    <div class="inboxes-container">
      <reference-box
        v-if="isAutoMode && noInboxes"
        class="warn-banner"
        :content="$t('TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.INFO_TEXT')"
        icon-name="info"
        icon-size="normal"
        icon-color="darkestgrey"
        view-box="0 -3 24 26"
      />
      <template v-if="!noInboxes">
        <div
          class="title-h5_m text-light mg-bottom--slab"
          v-text="$t('TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.ACTIVE_INBOXES')"
        />
        <transition-group tag="div" class="row gap--slab" name="modal-fade">
          <inbox-card
            v-for="inbox in inboxes"
            :key="inbox.id"
            :inbox="inbox"
            :mode="mode"
            @click="$emit('update', $event)"
          />
        </transition-group>
      </template>
      <div
        v-else
        class="no-inboxes body-b2 text-light"
        v-text="$t('TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.NO_INBOXES')"
      />
    </div>
  </div>
</template>
<script>
import InboxCard from './InboxCard';
import AssignmentLogicRadio from './AssignmentLogicRadio';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';

import { ASSIGNMENT_MODES, ASSIGNMENT_LOGIC } from '../utils/constants';

export default {
  components: { InboxCard, AssignmentLogicRadio, ReferenceBox },
  props: {
    mode: { type: String, default: 'manual' },
    currentAssignmentLogic: {
      type: String,
      default: ASSIGNMENT_MODES.LOAD_BALANCED,
    },
    inboxes: { type: Array, default: () => [] },
  },
  data() {
    return {
      isLoadBalanceActive: true,
      isRoundRobinActive: false,
      ASSIGNMENT_LOGIC,
    };
  },
  computed: {
    isAutoMode() {
      return this.mode === ASSIGNMENT_MODES.AUTO;
    },
    noInboxes() {
      return !this.inboxes.length;
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.isLoadBalanceActive =
        this.currentAssignmentLogic === ASSIGNMENT_LOGIC.LOAD_BALANCED;
      this.isRoundRobinActive =
        this.currentAssignmentLogic === ASSIGNMENT_LOGIC.ROUND_ROBIN;
    },
    updateAssignmentLogic() {
      this.$emit(
        'updateAssigmentLogic',
        this.isRoundRobinActive
          ? ASSIGNMENT_LOGIC.ROUND_ROBIN
          : ASSIGNMENT_LOGIC.LOAD_BALANCED
      );
    },
    toggleAssignmentLogic(logicType) {
      switch (logicType) {
        case ASSIGNMENT_LOGIC.LOAD_BALANCED:
          this.isLoadBalanceActive = !this.isLoadBalanceActive;
          this.isRoundRobinActive = false;
          break;
        case ASSIGNMENT_LOGIC.ROUND_ROBIN:
          this.isRoundRobinActive = !this.isRoundRobinActive;
          this.isLoadBalanceActive = false;
          break;
        default:
          break;
      }
      this.updateAssignmentLogic();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

.assignment-panel {
  padding: 0 $space-normal $space-normal $space-normal;
  background-color: $neutral-grey-100;
  border: 1px solid $neutral-grey-200;
  border-radius: $border-radius;
  height: $space-four * 10;

  &--header {
    position: sticky;
    top: 0;
    background-color: $neutral-grey-100;
    padding-top: $space-normal;
    padding-bottom: $space-normal;
  }

  .inboxes-container {
    .warn-banner {
      max-width: $space-largest * 10;
      border-radius: $border-radius;
      background: $warn-yellow-200;

      ::v-deep .text-content {
        color: $text-dark;
      }
    }

    .no-inboxes {
      margin-top: $space-small * 10;
      text-align: center;
    }
  }
}
</style>
