<template>
  <Header
    class="mg-bottom--slab CUSTOM_FIELD_HEADER"
    :name="customFieldName"
    :description="customFieldDesc"
    :name-placeholder="$t('CUSTOM_TICKET_FIELDS.ADD.FORM.HEADER.NAME')"
    :description-placeholder="$t('CUSTOM_TICKET_FIELDS.ADD.FORM.HEADER.DESC')"
    :disabled="editView"
    :on-click="showDetailsModal"
    :on-back="() => $router.push({ name: 'settings_custom_ticket_fields' })"
  >
    <template v-slot:right-section>
      <div
        class="status-indicator flex-row flex-align gap--small"
        :class="{ enableStatus }"
      >
        <span class="title-h5_m text-dark mg-left--slab">
          {{ enableText }}
        </span>
        <custom-field-status-toggle
          :enabled="enableStatus"
          style="margin: 1.2rem 0"
          @toggleFieldStatus="$emit('toggleFieldStatus', $event)"
        />
      </div>
    </template>
  </Header>
</template>

<script>
import Header from 'dashboard/components/ui/Header';
import CustomFieldStatusToggle from '../components/CustomFieldStatusToggle';

export default {
  components: {
    Header,
    CustomFieldStatusToggle,
  },
  props: {
    customFieldName: {
      type: String,
      default: '',
    },
    customFieldDesc: {
      type: String,
      default: '',
    },
    showDetailsModal: {
      type: Function,
      default: () => null,
    },
    enableStatus: {
      type: Boolean,
      default: true,
    },
    editView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    enableText() {
      return this.enableStatus
        ? this.$t('CUSTOM_TICKET_FIELDS.ADD.FORM.ENABLE')
        : this.$t('CUSTOM_TICKET_FIELDS.ADD.FORM.DISABLE');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.status-indicator {
  background: $neutral-white;
  border: 1px solid $neutral-grey-300;
  border-radius: $border-radius-smaller;

  &.enableStatus {
    background: #e5f7cf;
  }
}
</style>
