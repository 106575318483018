const PREFIX = 'CUSTOM_FIELD';

export function validateCustomField(payload, isDropdownField) {
  if (!payload.name) return ['NO_NAME', false, 'AUTOMATION_NAME'];

  if (isDropdownField && !payload.options?.length)
    return ['NO_VALUES', false, `${PREFIX}_VALUES`];

  if (!payload.inbox_ids?.length)
    return ['NO_INBOXES', false, `${PREFIX}_INBOXES`];

  return ['', true, ''];
}
