<template>
  <div class="lime-card-1dp agent-info-wrapper flex-row gap--normal">
    <thumbnail
      :src="avatarSource"
      :username="name"
      bg-color="#6bac1b"
      color="#fff"
      size="6.4rem"
    />
    <div class="agent-meta flex-column flex-justify flex-align--start">
      <div class="flex-row flex-align gap--smaller">
        <span v-if="role" class="body-b3 text-light capitalize" v-text="role" />
        <div
          v-if="status"
          :class="
            `flex-row flex-align gap--smaller status-badge status-badge__${status}`
          "
        >
          <span class="status-dot" />
          <span class="capital_c3" v-text="status" />
        </div>
      </div>
      <span v-if="name" class="title-h5 capitalize" v-text="name" />
      <span v-if="email" class="body-b3 capitalize" v-text="email" />
    </div>
  </div>
</template>
<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail';
export default {
  components: { Thumbnail },
  props: {
    name: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    role: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    avatarSource: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.agent-info-wrapper {
  padding: $space-slab;
  position: absolute;
  bottom: $space-three * 10;
  right: $space-normal;
  width: 28rem;

  .status-badge {
    border-radius: $border-radius-smaller;
    padding: $zero $space-normal;

    .status-dot {
      width: $space-smaller;
      height: $space-smaller;
      border-radius: 50%;
    }

    &__online {
      color: $pg-1;
      background-color: $pg-light-1;

      .status-dot {
        background-color: $pg-1;
      }
    }

    &__offline {
      color: $neutral-grey-600;
      background-color: $neutral-grey-200;

      .status-dot {
        background-color: $neutral-grey-600;
      }
    }

    &__utility,
    &__meals,
    &__oncall {
      color: $warn-yellow-500;
      background-color: $warn-yellow-200;

      .status-dot {
        background-color: $warn-yellow-500;
      }
    }
  }
}
</style>
