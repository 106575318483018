<template>
  <multiselect
    v-model="values"
    track-by="id"
    :label="labelKey"
    placeholder="Select"
    multiple
    selected-label
    deselect-label=""
    :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
    :max-height="160"
    :options="dropdownValues"
    allow-empty
  />
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    dropdownValues: {
      type: Array,
      default: () => [],
    },
    labelKey: {
      type: String,
      default: 'name',
    },
  },
  computed: {
    values: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
