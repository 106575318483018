import * as types from '../../mutation-types';
import actions from './actions';

const zeroStateMeta = {
  total_entries: 0,
  total_pages: 0,
  current_page: 1,
};

const state = {
  metrics: {
    ordersCount: 0,
    ordersEditted: 0,
    ordersCancelledCount: 0,
    ordersRefundedCount: 0,
  },
  topOrderedProductsMeta: { ...zeroStateMeta },
  topOrderedProductsList: [],
  topCancelledProductsMeta: { ...zeroStateMeta },
  topCancelledProductsList: [],
  periodicOrdersCount: [],
  codPrepaidSplit: [],
  ordersMeta: { ...zeroStateMeta },
  orders: [],
  statusesList: [],
  uiFlags: {
    fetchingMetrics: true,
    fetchingTopOrderedProducts: true,
    fetchingTopCancelledProducts: true,
    fetchingPeriodicOrdersCount: true,
    fetchingCodPrepaidSplit: true,
    fetchingOrders: true,
  },
};

export const getters = {
  getUiFlags({ uiFlags }) {
    return uiFlags;
  },
  getMetrics({ metrics }) {
    return metrics;
  },
  getPeriodicOrdersCount({ periodicOrdersCount }) {
    return periodicOrdersCount;
  },
  getCodPrepaidSplit({ codPrepaidSplit }) {
    return codPrepaidSplit;
  },
  getTopOrderedProductsMeta({ topOrderedProductsMeta }) {
    return topOrderedProductsMeta;
  },
  getTopOrderedProductsList({ topOrderedProductsList }) {
    return topOrderedProductsList;
  },
  getTopCancelledProductsMeta({ topCancelledProductsMeta }) {
    return topCancelledProductsMeta;
  },
  getTopCancelledProductsList({ topCancelledProductsList }) {
    return topCancelledProductsList;
  },
  getOrdersMeta({ ordersMeta }) {
    return ordersMeta;
  },
  getOrders({ orders }) {
    return orders;
  },
  getOrderStatusesList({ statusesList }) {
    return statusesList;
  },
};

export const mutations = {
  [types.default.SET_DEEP_SHOPIFY_ANALYTICS_UI_FLAGS](_state, flag) {
    _state.uiFlags = { ..._state.uiFlags, ...flag };
  },
  [types.default.SET_DEEP_SHOPIFY_ANALYTICS_METRICS](_state, data) {
    const {
      orders_count,
      orders_edited_events_count,
      orders_cancelled_count,
      orders_refunded_count,
    } = data;

    _state.metrics = {
      ..._state.metrics,
      ordersCount: orders_count,
      ordersEditted: orders_edited_events_count,
      ordersCancelledCount: orders_cancelled_count,
      ordersRefundedCount: orders_refunded_count,
    };
  },
  [types.default.SET_DEEP_SHOPIFY_ANALYTICS_TOP_ORDERED_PRODUCTS](
    _state,
    responseData
  ) {
    const {
      top_ordered_products: { meta, data },
    } = responseData;

    _state.topOrderedProductsMeta = meta;
    _state.topOrderedProductsList = data;
  },
  [types.default.SET_DEEP_SHOPIFY_ANALYTICS_TOP_CANCELLED_PRODUCTS](
    _state,
    responseData
  ) {
    const {
      top_cancelled_products: { meta, data },
    } = responseData;

    _state.topCancelledProductsMeta = meta;
    _state.topCancelledProductsList = data;
  },
  [types.default.SET_DEEP_SHOPIFY_ANALYTICS_PERIODIC_ORDER_COUNT](
    _state,
    data
  ) {
    _state.periodicOrdersCount = data.orders_periodic_count;
  },
  [types.default.SET_DEEP_SHOPIFY_ANALYTICS_COD_PREPAID_SPLIT](_state, data) {
    _state.codPrepaidSplit = data.orders_cod_split;
  },
  [types.default.SET_DEEP_SHOPIFY_ORDERS](_state, responseData) {
    const {
      all_orders: { meta, data },
    } = responseData;

    _state.ordersMeta = meta;
    _state.orders = data;
  },
  [types.default.SET_DEEP_SHOPIFY_ANALYTICS_ORDER_STATUSES](_state, results) {
    _state.statusesList = results;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
