import {
  fromUnixTime,
  formatDistanceToNow,
  format,
  differenceInDays,
} from 'date-fns';

export default {
  methods: {
    messageStamp(time, timeFormat = 'h:mm a') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, timeFormat);
    },
    dynamicTime(time) {
      const unixTime = fromUnixTime(time);
      return formatDistanceToNow(unixTime, { addSuffix: true });
    },
    utcToLocal(time = '00:00') {
      if (time === null) {
        time = '00:00';
      }
      let t = new Date();
      let h = time.split(':')[0];
      let m = time.split(':')[1];
      t.setUTCHours(h, m);
      return (
        ('0' + t.getHours()).slice(-2) + ':' + ('0' + t.getMinutes()).slice(-2)
      );
    },
    localToUtc(time = '00:00') {
      if (time === null) {
        time = '00:00';
      }
      let t = new Date();
      let h = time.split(':')[0];
      let m = time.split(':')[1];
      t.setHours(h, m);
      return (
        ('0' + t.getUTCHours()).slice(-2) +
        ':' +
        ('0' + t.getUTCMinutes()).slice(-2)
      );
    },
    workTimeUtc() {
      let wt = this.displayWorkTime;
      // eslint-disable-next-line no-restricted-syntax
      for (let [key] of Object.entries(wt)) {
        wt[key].start_time = this.localToUtc(wt[key].start_time);
        wt[key].end_time = this.localToUtc(wt[key].end_time);
      }
      return wt;
    },
    workTimeLocal(wt) {
      // eslint-disable-next-line no-restricted-syntax
      for (let [key] of Object.entries(wt)) {
        wt[key].start_time = this.utcToLocal(wt[key].start_time);
        wt[key].end_time = this.utcToLocal(wt[key].end_time);
      }
      return wt;
    },
    formatDate(date = '') {
      var d = new Date(date);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    },
    secondsToDuration(sec) {
      let days = parseInt(sec / (24 * 60 * 60), 10) || 0;
      sec %= 24 * 60 * 60;
      let hours = parseInt(sec / (60 * 60), 10) || 0;
      sec %= 60 * 60;
      let minutes = parseInt(sec / 60, 10) || 0;
      sec %= 60;
      let seconds = sec;
      return { days, hours, minutes, seconds };
    },
    formattedDuration({ days, hours, minutes, seconds }) {
      let day =
        days > 0
          ? days + ' <span class="title-h5 text-light">days</span> '
          : '';
      let hrs =
        hours > 0
          ? hours + ' <span class="title-h5 text-light">hrs</span> '
          : '';
      let min =
        minutes > 0
          ? minutes + ' <span class="title-h5 text-light">min</span> '
          : '';
      let sec =
        seconds > 0
          ? seconds + ' <span class="title-h5 text-light">sec</span> '
          : '';
      return day + hrs + min + sec !== '' ? day + hrs + min + sec : '0 min';
    },
    humanReadableTime(time) {
      let timeStamp =
        new Date(time).getTime() > 0
          ? new Date(time).toLocaleDateString([], {
              hour: '2-digit',
              minute: '2-digit',
            })
          : '-';

      return timeStamp;
    },
    dateToWords(date) {
      let formattedDate = date.replace(/-/g, '/');
      let months = [
        'Jan',
        'Feb',
        'March',
        'Apr',
        'May',
        'Jun',
        'July',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec',
      ];
      let dateArray = formattedDate.split('/');
      let month = dateArray[1];
      let day = dateArray[2];

      return day === undefined ? date : day + ` ${months[month - 1]}`;
    },
    timeToAMPM(time) {
      let timeStamp = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (timeStamp.length > 1) {
        timeStamp = timeStamp.slice(1);
        timeStamp[5] = +timeStamp[0] < 12 ? 'AM' : 'PM';
        timeStamp[0] = +timeStamp[0] % 12 || 12;
        return timeStamp.join('').replace(':00:00', '');
      }
      return '';
    },
    timeRangeToAMPMRange(range) {
      let formattedRange = range.split('-').map(hour => {
        let nHour = hour.trim();
        let suffix = nHour >= 12 ? 'PM' : 'AM';
        var hours = (nHour % 12 || 12) + suffix;
        return hours;
      });
      return formattedRange.join('-');
    },
    differenceInDays(start, end) {
      return differenceInDays(start, end);
    },
  },
};
