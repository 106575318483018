import axios from 'axios';

import { S3_BUCKET_URL, PLACEHOLDER_MESSAGE } from '../constants/messages';

export default {
  data() {
    return {
      contentFromS3: '',
      quotedContentFromS3: '',
    };
  },
  methods: {
    fetchContentFromS3({ content, quotedContent }) {
      const promises = [];
      const hasS3LinkToFetchContent = content.startsWith(S3_BUCKET_URL);
      const hasS3LinkToFetchQuotedContent = quotedContent.startsWith(
        S3_BUCKET_URL
      );

      if (hasS3LinkToFetchContent) promises.push(this.fetchContent(content));

      if (hasS3LinkToFetchQuotedContent)
        promises.push(this.fetchQuotedContent(quotedContent));
    },
    fetchContent(s3Url) {
      this.contentFromS3 = PLACEHOLDER_MESSAGE;

      axios
        .get(s3Url)
        .then(response => {
          this.contentFromS3 = response.data;
        })
        .catch(() => {
          this.contentFromS3 = s3Url;
          this.showAlert(
            'There was an error fetching some of the emails',
            'error'
          );
        });
    },
    fetchQuotedContent(s3Url) {
      axios.get(s3Url).then(response => {
        this.quotedContentFromS3 = response.data;
      });
    },
  },
};
