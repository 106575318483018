<template>
  <section>
    <label v-if="title" class="views-section-title" v-text="title" />
    <component
      :is="hasAccordion ? 'accordian-card' : 'div'"
      :key="isAccordionOpen"
      :name="accordionTitle"
      :always-open="isAccordionOpen"
    >
      <div v-if="hasViews" class="custom-views-container">
        <Item
          v-for="view in views"
          :key="view.id"
          :data="view"
          :is-default="isDefault(view)"
          :is-active="isActive(view)"
          :controls="controls"
          @onEdit="onEditView"
          @onDelete="onDeleteView"
          @onMakeCurrent="onMakeCurrent"
          @onMakeDefault="onMakeDefault"
        />
      </div>
      <div v-else class="views-zero-state" v-text="zeroStateMessage" />
    </component>
  </section>
</template>

<script>
import Item from './Item';
import AccordianCard from 'dashboard/components/AccordianCard';

export default {
  components: { Item, AccordianCard },
  props: {
    title: {
      type: String,
      default: '',
    },
    accordionTitle: {
      type: String,
      default: '',
    },
    views: {
      type: Array,
      required: true,
    },
    isDefault: {
      type: Function,
      default: () => false,
    },
    isActive: {
      type: Function,
      default: () => false,
    },
    hasAccordion: {
      type: Boolean,
      default: false,
    },
    isAccordionOpen: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasViews() {
      return this.views.length > 0 && this.views[0];
    },
    zeroStateMessage() {
      const key = this.hasAccordion ? 'NO_VIEWS_AVAILABLE' : 'NO_VIEW_SET';
      return this.$t(`CONVERSATION.CONVERSATION_VIEWS.ZERO_STATES.${key}`);
    },
  },
  methods: {
    onEditView(view) {
      this.$emit('onEdit', view);
    },
    onDeleteView(view) {
      this.$emit('onDelete', view);
    },
    onMakeCurrent(view) {
      this.$emit('onMakeCurrent', view);
    },
    onMakeDefault(view) {
      this.$emit('onMakeDefault', view);
    },
  },
};
</script>
