export default {
  data() {
    return { permissions: {} };
  },
  created() {
    this.getAccountDetails();
  },
  methods: {
    getAccountDetails() {
      this.$store.dispatch('accounts/get').then(response => {
        const {
          shopify_orders_create: canCreate,
          shopify_orders_cancel: canCancel,
          shopify_orders_refund: canRefund,
          shopify_orders_edit: canEdit,
          shopify_orders_payments: canPrepay,
          shopify_orders_cod: canCod,
          shopify_orders_with_phone: phoneMandatory,
          shopify_orders_with_email: emailMandatory,
        } = response.features;

        this.permissions = {
          canCreate,
          canCancel,
          canRefund,
          canEdit,
          canPrepay,
          canCod,
          phoneMandatory,
          emailMandatory,
        };
      });
    },
  },
};
