import { render, staticRenderFns } from "./OptOut.vue?vue&type=template&id=ed72d922&scoped=true&"
import script from "./OptOut.vue?vue&type=script&lang=js&"
export * from "./OptOut.vue?vue&type=script&lang=js&"
import style0 from "./OptOut.vue?vue&type=style&index=0&id=ed72d922&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed72d922",
  null
  
)

export default component.exports