import { formatDateWithToday } from 'dashboard/helper/time';
import { METRICS_OPTIONS } from '../../../../routes/dashboard/settings/sla/utils/constants';

export function calculateTimeRemaining(dueTime) {
  if (!dueTime) return null;

  const dueDate = new Date(dueTime).getTime();
  const currentTime = Date.now();
  const timeRemaining = Math.floor((dueDate - currentTime) / 1000);

  return timeRemaining > 0 ? timeRemaining : 0;
}

export function getSlaBreachStatus(chat) {
  const metrics = METRICS_OPTIONS.map(metric => ({
    ...metric,
    dueTime: chat[metric.dueTimeKey],
  }));

  const now = new Date();

  // Check if all dueTime values are null
  const allMetricsNull = metrics.every(metric => metric.dueTime === null);
  if (allMetricsNull) return null;

  // Check if any metric is breached (dueTime in the past)
  const breachedMetric = metrics.find(
    metric => metric.dueTime && new Date(metric.dueTime) <= now
  );

  // If a breached metric is found, return the breach status
  if (breachedMetric) {
    return `⚠️ SLA Breached: ${breachedMetric.name}`;
  }

  // Find the first metric that is due in the future
  const nextDueMetric = metrics.find(
    metric => metric.dueTime && new Date(metric.dueTime) > now
  );

  if (nextDueMetric) {
    const dueTime = new Date(nextDueMetric.dueTime);
    return `SLA due by ${formatDateWithToday(dueTime)}`;
  }

  // Default return if no valid metrics are found
  return null;
}
