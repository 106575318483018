<template>
  <woot-modal-new
    :show.sync="show"
    :on-close="onClose"
    :disable-btn="{
      0: shouldDisableBtn,
      1: $v.body.$invalid,
    }"
    @submit="createTemplate"
  >
    <woot-modal-header-new :header-title="$t('TEMPLATE.ADD.TITLE')" />
    <template v-slot:paginated-components>
      <div class="template-form">
        <div class="settings-form custom-scroll">
          <woot-input
            v-model.trim="shortCode"
            class="medium-12 columns content-margin"
            required
            auto-focus
            :class="{ error: $v.shortCode.$error }"
            :has-error="$v.shortCode.$error"
            :error="$t('TEMPLATE.ADD.FORM.SHORT_CODE.ERROR')"
            :label="$t('TEMPLATE.ADD.FORM.SHORT_CODE.LABEL')"
            :help-text="$t('TEMPLATE.CODE_EXMPL')"
            :placeholder="$t('TEMPLATE.ADD.FORM.SHORT_CODE.PLACEHOLDER')"
            @blur="$v.shortCode.$touch"
          />
          <div
            class="flex-row flex-align gap--two content-margin"
            :class="
              !isGupshupEnterpriseInbox || isMediaTemplate
                ? 'flex-row'
                : 'flex-column'
            "
          >
            <div class="full-width">
              <div class="flex-row flex-align">
                <label class="formlabel" :class="{ error: $v.type.$error }">
                  <span
                    class="title-h5 text-dark"
                    v-text="$t('TEMPLATE.ADD.FORM.TYPE.LABEL')"
                  />
                </label>
                <span
                  v-tooltip="$t('TEMPLATE.ADD.FORM.TYPE.INFO')"
                  class="mg-bottom--smaller mg-left--small"
                >
                  <icons
                    name="warning"
                    color="grey evenodd"
                    size="semimedium"
                    :show-title="false"
                  />
                </span>
              </div>
              <woot-single-selector
                size="large"
                :option-list="typeList"
                :default-option="selectedType"
                :custom-style="{ top: '4.8rem', left: 'auto', width: '100%' }"
                @click="changeType"
              />
            </div>
            <div style="position: relative" class="full-width">
              <div class="flex-row flex-align gap--smaller">
                <span title="required" class="dot-circle mg-bottom--small" />
                <label class="formlabel" :class="{ error: !inboxId }">
                  <span
                    class="title-h5 text-dark"
                    v-text="$t('TEMPLATE.ADD.FORM.INBOX.LABEL')"
                  />
                </label>
                <span
                  v-tooltip="$t('TEMPLATE.ADD.FORM.INBOX.INFO')"
                  class="mg-bottom--smaller mg-left--small"
                >
                  <icons
                    name="warning"
                    color="grey evenodd"
                    size="semimedium"
                    :show-title="false"
                  />
                </span>
              </div>
              <woot-single-selector
                size="large"
                :option-list="inboxList"
                :default-option="selectedInboxName"
                :custom-style="{
                  top: '4.8rem',
                  left: 'auto',
                  width: '100%',
                  maxHeight: '20rem',
                }"
                @click="changeInbox"
              />
              <span
                v-if="$v.inboxId.$invalid"
                style="padding-left: 0"
                class="desc-text capital_c3 text-red"
                v-text="$t('TEMPLATE.ADD.FORM.INBOX.ERROR')"
              />
            </div>
          </div>
          <woot-input
            v-if="isMediaTemplate"
            v-model.trim="mediaUrl"
            class="medium-12 columns content-margin"
            :class="{ error: $v.mediaUrl.$error }"
            :has-error="$v.mediaUrl.$error"
            :error="$t('TEMPLATE.ADD.FORM.MEDIA.ERROR')"
            :label="$t('TEMPLATE.ADD.FORM.MEDIA.LABEL')"
            :help-text="$t('TEMPLATE.ADD.FORM.MEDIA.DESCRIPTION')"
            :placeholder="$t('TEMPLATE.ADD.FORM.MEDIA.PLACEHOLDER')"
            @blur="$v.mediaUrl.$touch"
          />
          <woot-input
            v-if="!isGupshupEnterpriseInbox"
            v-model.trim="body"
            rows="6"
            required
            class="medium-12 columns content-margin"
            :class="{ error: $v.body.$error }"
            :has-error="$v.body.$error"
            :error="$t('TEMPLATE.ADD.FORM.BODY.ERROR')"
            :label="$t('TEMPLATE.ADD.FORM.BODY.LABEL')"
            :placeholder="$t('TEMPLATE.ADD.FORM.BODY.PLACEHOLDER')"
            :help-text="$t('TEMPLATE.ADD.FORM.BODY.INFO')"
            @blur="$v.body.$touch"
          />
        </div>
      </div>
      <div v-if="isGupshupEnterpriseInbox" class="template-form">
        <div class="settings-form custom-scroll">
          <woot-input
            v-model.trim="header"
            class="medium-12 columns content-margin"
            :label="$t('TEMPLATE.ADD.FORM.HEADER.LABEL')"
            :help-text="$t('TEMPLATE.ADD.FORM.HEADER.DESCRIPTION')"
            :placeholder="$t('TEMPLATE.ADD.FORM.HEADER.PLACEHOLDER')"
          />
          <woot-input
            v-model.trim="body"
            rows="6"
            required
            class="medium-12 columns content-margin"
            :class="{ error: $v.body.$error }"
            :has-error="$v.body.$error"
            :error="$t('TEMPLATE.ADD.FORM.BODY.ERROR')"
            :label="$t('TEMPLATE.ADD.FORM.BODY.LABEL')"
            :placeholder="$t('TEMPLATE.ADD.FORM.BODY.PLACEHOLDER')"
            :help-text="$t('TEMPLATE.ADD.FORM.BODY.INFO')"
            @blur="$v.body.$touch"
          />
          <woot-input
            v-model.trim="footer"
            class="medium-12 columns"
            :label="$t('TEMPLATE.ADD.FORM.FOOTER.LABEL')"
            :help-text="$t('TEMPLATE.ADD.FORM.FOOTER.DESCRIPTION')"
            :placeholder="$t('TEMPLATE.ADD.FORM.FOOTER.PLACEHOLDER')"
          />
        </div>
      </div>
    </template>
  </woot-modal-new>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';

import { mustBeCDNUrlorVariable } from 'dashboard/helper/files.js';
import * as types from 'shared/constants/googleEventType';
import DOMPurify from 'dompurify';

import alertMixin from 'shared/mixins/alertMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';

export default {
  mixins: [alertMixin, googleAnalyticsMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      shortCode: '',
      body: '',
      mediaUrl: '',
      header: '',
      footer: '',
      type: 'text',
      show: true,
      inboxId: null,
      addTemplate: {
        showLoading: false,
        message: '',
      },
    };
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    mediaUrl: {
      mustBeCDNUrlorVariable,
    },
    body: {
      required,
      validate: value => {
        const sanitizedValue = DOMPurify.sanitize(value || '');
        return sanitizedValue.length > 0 && sanitizedValue.length <= 2000;
      },
    },
    inboxId: {
      required,
    },
    type: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
    }),
    isGupshupEnterpriseInbox() {
      return this.selectedInboxType === 'Channel::GupshupEnterprise';
    },
    shouldDisableBtn() {
      let commonValidation =
        this.$v.shortCode.$invalid ||
        (this.isMediaTemplate && this.$v.mediaUrl.$invalid) ||
        this.$v.inboxId.$invalid ||
        this.addTemplate.showLoading;

      if (!this.isGupshupEnterpriseInbox)
        return this.$v.body.$invalid || commonValidation;

      return commonValidation;
    },
    inboxList() {
      let r = this.inboxes.reduce((ibList, ib) => {
        ibList.push({
          id: ib.id,
          name: ib.name,
          type: ib.channel_type,
          channel_id: ib.channel_id,
        });

        return ibList;
      }, []);
      return r;
    },
    selectedInboxName() {
      let selectedIndex = this.inboxList.findIndex(i => i.id === this.inboxId);

      return selectedIndex > -1
        ? this.inboxList[selectedIndex].name
        : 'Select an Inbox';
    },
    selectedInboxType() {
      let selectedIndex = this.inboxList.findIndex(i => i.id === this.inboxId);

      return selectedIndex > -1 ? this.inboxList[selectedIndex].type : '';
    },
    typeList() {
      return [
        { id: 0, name: 'text' },
        { id: 1, name: 'image' },
        { id: 2, name: 'video' },
        { id: 3, name: 'document' },
      ];
    },
    selectedType() {
      let selectedIndex = this.typeList.findIndex(i => i.name === this.type);

      return selectedIndex > -1
        ? this.typeList[selectedIndex].name
        : 'Select a Type';
    },
    isMediaTemplate() {
      return ['image', 'video', 'document'].includes(this.selectedType);
    },
  },
  methods: {
    resetForm() {
      this.shortCode = '';
      this.body = '';
      this.header = '';
      this.footer = '';
      this.$v.shortCode.$reset();
      this.$v.body.$reset();
      this.$v.inboxId.$reset();
    },
    changeInbox(val) {
      this.googleAnalyticsEvent(
        types.default.TEMPLATE_CHANGE_INBOX,
        types.default.TEMPLATE_ADD_SETTINGS,
        val.id
      );
      this.inboxId = val.id;
    },
    changeType(val) {
      this.googleAnalyticsEvent(
        types.default.TEMPLATE_CHANGE_TYPE,
        types.default.TEMPLATE_ADD_SETTINGS,
        val.name
      );
      this.type = val.name;
    },
    createTemplate() {
      this.googleAnalyticsEvent(
        types.default.TEMPLATE_ADD_AGENT,
        types.default.TEMPLATE_ADD_SETTINGS,
        types.default.TEMPLATE_ADD_SETTINGS
      );
      this.addTemplate.showLoading = true;

      this.body = DOMPurify.sanitize(this.body || '');

      this.$store
        .dispatch('createTemplate', {
          short_code: this.shortCode,
          body: this.body,
          media_url: this.mediaUrl,
          template_buttons: null,
          header: this.header,
          footer: this.footer,
          inbox_id: this.inboxId,
          template_type: this.type,
        })
        .then(() => {
          this.addTemplate.showLoading = false;
          this.showAlert(
            this.$t('TEMPLATE.ADD.API.SUCCESS_MESSAGE'),
            'success'
          );
          this.resetForm();
          this.onClose();
        })
        .catch(err => {
          this.addTemplate.showLoading = false;
          if (err) {
            this.showAlert(err, 'error');
          } else {
            this.showAlert(this.$t('TEMPLATE.ADD.API.ERROR_MESSAGE'), 'error');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.template-form {
  padding: $space-two $space-jumbo;

  .settings-form {
    height: $space-large * 10;
    padding: $zero $space-two;

    .dot-circle {
      background-color: $warn-red-400;
      border-radius: 50%;
      display: inline-block;
      height: $space-smaller;
      width: $space-smaller;
    }

    .desc-text {
      padding-left: $zero;
      position: absolute;
    }
  }
}

.content-margin {
  margin-bottom: $space-medium;
}
</style>
