/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';

class BroadcastApi extends ApiClient {
  constructor() {
    super('integrations', { accountScoped: true });
  }

  send({
    phone_numbers,
    content,
    status,
    template_id,
    template_parameters,
    customAttributes,
    is_fake = false,
    name,
    scheduled_at,
  }) {
    const formData = new FormData();
    formData.append('template_id', template_id);
    formData.append('template_attributes', JSON.stringify(template_parameters));
    formData.append('phone_numbers', phone_numbers);
    formData.append('custom_attributes', JSON.stringify(customAttributes));
    formData.append('content', content);
    formData.append('status', status);
    formData.append('is_fake', is_fake);
    formData.append('name', name);
    if (scheduled_at) formData.append('scheduled_at', scheduled_at);

    return axios({
      method: 'post',
      url: `${this.baseUrl()}/broadcast`,
      data: formData,
    });
  }

  status({ page, q, inbox_id }) {
    return axios.get(`${this.baseUrl()}/broadcast`, {
      params: { page, q, inbox_id },
    });
  }

  sentCount(id) {
    return axios.get(`${this.baseUrl()}/broadcast_sent_count?id=${id}`);
  }

  download({ broadcast_id, is_direct_brd, email }) {
    if (is_direct_brd) {
      return axios.get(`${this.baseUrl()}/broadcast/${broadcast_id}`);
    }
    return axios.get(`${this.baseUrl()}/broadcast/${broadcast_id}`, {
      params: { email },
      responseType: 'arraybuffer',
    });
  }

  cancelScheduledBroadcast(broadcastId) {
    return axios.patch(`${this.baseUrl()}/cancel_broadcast`, {
      id: broadcastId,
    });
  }
}

export default new BroadcastApi();
