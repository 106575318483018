<template>
  <div class="view-box columns">
    <settings-header
      icon="megaphone"
      :header-title="$t('BROADCAST.HEADER')"
      :show-back-button="false"
    />
    <deprecation-page
      v-if="displayDeprecationPage"
      @on-close="onHideDeprecationPage"
    >
      <reference-box
        class="warn-banner"
        :heading="$t('BROADCAST.DEPRECATION.BANNER.HEADING')"
        :content="$t('BROADCAST.DEPRECATION.BANNER.CONTENT')"
        icon-name="info"
        icon-color="red"
        view-box="0 0 24 24"
      />
    </deprecation-page>
    <div v-else class="settings-box custom-scroll flex-row">
      <woot-base-button
        front-icon="heavyPlus"
        :icon-view-box="'0 0 20 20'"
        class="settings-button"
        :disabled="
          (inPendingPaymentForNonShopify && !isEnterprise) ||
            addBillingRestrictions(accountDetails)
        "
        @click="goToCreateBroadcast"
      >
        {{ $t('BROADCAST.CREATE.LABEL') }}
      </woot-base-button>
      <div class="small-12">
        <div class="flex-space-between">
          <div class="flex-row-center">
            <search-box
              :placeholder="$t('BROADCAST.SEARCH.PLACEHOLDER')"
              :value="search"
              custom-class="reports-search"
              :on-search="searchReports"
              @setSearch="setSearch"
            />
            <div class="mg-left">
              <woot-single-selector
                variant="secondary"
                size="medium"
                front-icon="inbox"
                :custom-style="{ width: 'auto' }"
                :default-option="inboxName ? inboxName : 'All Inboxes'"
                :option-list="inboxes"
                :button-style="{
                  'border-radius': '4px',
                  color: '#3C492C',
                  border: '1px solid #8c8c8c',
                }"
                :show-selected-text="false"
                @click="changeInboxId"
              />
            </div>
          </div>
        </div>
        <div
          class="table-container lime-card-1dp custom-scroll mg-top"
          style="padding: 0; margin:1.6rem 1.6rem 0.1rem 0.1rem"
        >
          <table class="woot-table">
            <thead v-if="!isGupshup">
              <th
                v-for="thHeader in $t('BROADCAST.DOWNLOAD_TAB.TABLE_HEADER')"
                :key="thHeader"
                :class="{
                  'text-align': ['Reports', 'Status'].includes(thHeader),
                }"
              >
                {{ thHeader }}
              </th>
            </thead>
            <!-- gupshup related code -->
            <thead v-else>
              <th>{{ $t('BROADCAST.DOWNLOAD_TAB.TABLE_HEADER')[0] }}</th>
              <th>{{ $t('BROADCAST.DOWNLOAD_TAB.TABLE_HEADER')[1] }}</th>
              <th>
                <div class="flex-column">
                  <span>Gupshup</span>
                  <div style="color:#1B83AC">
                    <span style="color:#6BAC1B; text-transform:capitalize">
                      Sent
                    </span>
                    ,
                    <span style="color:#FF7E61;text-transform:capitalize">
                      Failed
                    </span>
                  </div>
                </div>
              </th>
              <th>{{ $t('BROADCAST.DOWNLOAD_TAB.TABLE_HEADER')[2] }}</th>
              <th class="text-align">
                {{ $t('BROADCAST.DOWNLOAD_TAB.TABLE_HEADER')[3] }}
              </th>
            </thead>
            <tbody v-if="broadcastList.length">
              <tr
                v-for="status in broadcastList"
                :key="status.id"
                height="76px"
                style="border-bottom:none; position: relative"
              >
                <td class="body-b2 text-dark">
                  {{ status.id }}
                </td>
                <td>
                  <div>
                    <span
                      class="title-h5_m text-dark text-truncate"
                      v-text="
                        status.name !== 'null'
                          ? status.name ||
                            $t('BROADCAST.DOWNLOAD_TAB.TABLE_HEADER')[1]
                          : $t('BROADCAST.DOWNLOAD_TAB.TABLE_HEADER')[1]
                      "
                    />
                    <span class="body-b3 text-light">
                      |{{ ' ' + status.total }} message scheduled
                    </span>
                    <span class="body-b3 text-light">
                      |{{ ' ' + status.date }}
                    </span>
                  </div>
                  <div
                    style="max-width: 40rem"
                    class="body-b3 text-light line-clamp-1"
                    v-text="status.content"
                  />
                </td>
                <!-- gupshup related code -->
                <td v-if="isGupshup" class="body-b2 text-dark">
                  <span v-if="status.gupshupSent" style="display: flex;">
                    <span style="color:#6BAC1B">
                      {{ status.gupshupSent }},
                    </span>
                    <span
                      class="mg-left--smaller"
                      style="color:#FF7E61
"
                    >
                      {{ status.gupshupFailed }}
                    </span>
                    <a
                      class="mg-top--smaller mg-left--micro"
                      @click="viewBroadcastCount(status.id)"
                    >
                      <icons name="refreshRound" color="green" size="normal" />
                    </a>
                  </span>
                  <woot-base-button
                    v-else
                    tag="span"
                    variant="tertiary"
                    class="view-button"
                    @click="viewBroadcastCount(status.id)"
                  >
                    {{ $t('BROADCAST.STATUS.VIEW') }}
                  </woot-base-button>
                </td>
                <td v-if="isBeforeScheduledTimeStamp(status.scheduled_at)">
                  <div class="flex-row flex-align flex-justify gap--larger">
                    <span
                      v-if="!status.is_cancelled"
                      v-tooltip="
                        'Scheduled @ ' + formatDate(status.scheduled_at)
                      "
                      class="mg-right--smaller"
                    >
                      <icons
                        name="history"
                        size="medium"
                        color="warning"
                        :show-title="false"
                      />
                    </span>
                    <span
                      v-else
                      class="title-h5 text-light"
                      v-text="$t('BROADCAST.STATUS.NOT_APPLICABLE')"
                    />
                  </div>
                </td>
                <td v-if="!isBeforeScheduledTimeStamp(status.scheduled_at)">
                  <div class="flex-row flex-justify">
                    <woot-base-button
                      v-tooltip.left="'Share report on email'"
                      tag="span"
                      variant="tertiary"
                      class="view-button"
                      @click="
                        downloadBroadcast(status.id, status.direct_broadcast)
                      "
                    >
                      <icons
                        name="email"
                        color="green"
                        size="medium"
                        :show-title="false"
                      />
                    </woot-base-button>
                  </div>
                </td>
                <td class="body-b2 text-dark text-align">
                  <span
                    v-if="status.is_cancelled"
                    class="text-red cancelled-status title-h6"
                    v-text="$t('BROADCAST.STATUS.CANCELLED')"
                  />
                  <woot-base-button
                    v-else
                    tag="span"
                    variant="tertiary"
                    class="view-button"
                    :loading="status.loading"
                    @click="viewBroadcastCount(status.id)"
                  >
                    {{ $t('BROADCAST.STATUS.VIEW') }}
                  </woot-base-button>
                </td>
                <div
                  v-if="
                    (status.sentCount || status.sentCount === 0) &&
                      selectedBroadcastId === status.id &&
                      showModal &&
                      !status.loading
                  "
                  v-on-clickaway="hideStats"
                  class="lime-card-1dp flex-column-center stat-card"
                >
                  <div
                    v-if="status.failedState"
                    class="mg-bottom--medium flex-column-center"
                  >
                    <icons name="error" color="error" size="mediumlarge" />
                    <span
                      class="body-b2 text-light"
                      v-text="$t('BROADCAST.STATUS.FAIL')"
                    />
                  </div>
                  <div
                    v-else-if="isBeforeScheduledTimeStamp(status.scheduled_at)"
                  >
                    <div>
                      <span
                        class="body-b2 text-light"
                        v-text="$t('BROADCAST.STATUS.SCHEDULED')"
                      />
                      <div
                        class="title-h5 text-dark"
                        v-text="timeStamp(status.scheduled_at)"
                      />
                    </div>
                    <div class="cancel-btn">
                      <woot-base-button
                        variant="secondary-danger"
                        size="small"
                        class="mg-auto"
                        @click="onCancelBroadcast(status.id)"
                      >
                        {{ $t('BROADCAST.STATUS.CANCEL_BROADCAST') }}
                      </woot-base-button>
                    </div>
                  </div>
                  <div v-else>
                    <progress-bar :series="selectedBroadcastProgress" />

                    <span
                      class="body-b2 text-light mg-left"
                      style="margin-top: -1.5rem"
                      v-text="$t('BROADCAST.STATUS.COMPLETE')"
                    />
                  </div>
                  <template
                    v-if="!isBeforeScheduledTimeStamp(status.scheduled_at)"
                  >
                    <span
                      class="text-light body-b2 mg-top mg-bottom--small flex-row flex-justify--start full-width"
                    >
                      LimeChat status
                    </span>
                    <div class="flex-space-between full-width">
                      <div class="flex-column-center">
                        <span class="title-h2 text-dark">
                          {{ status.sentCount }}
                        </span>
                        <span
                          v-if="status.total < ROW_CAPPED_NUMBER"
                          class="body-b2 text-light"
                        >
                          Sent
                        </span>
                        <span v-else class="body-b2 text-light">Success</span>
                      </div>
                      <div class="flex-column-center">
                        <span class="title-h2 text-dark">
                          {{ status.failedCount }}
                        </span>
                        <span class="body-b2 text-light">Failed</span>
                      </div>
                    </div>

                    <span
                      v-if="status.total < ROW_CAPPED_NUMBER"
                      class="text-light body-b2 mg-top mg-bottom--small flex-row flex-justify--start full-width"
                    >
                      WhatsApp BSP status
                    </span>
                    <div
                      v-if="status.total < ROW_CAPPED_NUMBER"
                      class="flex-space-between full-width"
                    >
                      <div class="flex-column-center">
                        <span class="title-h2 text-dark">
                          {{ status.gupshupSent }}
                        </span>
                        <span
                          class="body-b2 text-light"
                          v-text="$t('BROADCAST.STATUS.SENT')"
                        />
                      </div>
                      <div class="flex-column-center">
                        <span class="title-h2 text-dark">
                          {{ status.gupshupFailed }}
                        </span>
                        <span
                          class="body-b2 text-light"
                          v-text="$t('BROADCAST.STATUS.FAIL')"
                        />
                      </div>
                    </div>
                  </template>
                </div>
              </tr>
            </tbody>
          </table>

          <table-footer
            v-if="broadcastList.length"
            :on-page-change="onPageChange"
            :current-page="Number(meta.currentPage)"
            :total-count="meta.count"
            :page-size="8"
          />

          <div
            v-if="!broadcastList.length && !isRefreshing"
            class="no-data flex-column-center"
          >
            <div>
              <img
                :src="require('dashboard/assets/images/noData.svg')"
                alt="No Data"
                height="100px"
                width="100px"
              />
            </div>
            <p class="no-items-error-message">
              {{ $t('BROADCAST.DOWNLOAD_TAB.404') }}
            </p>
          </div>
          <woot-loading-state
            v-if="isRefreshing"
            :message="$t('BROADCAST.DOWNLOAD_TAB.LOADING')"
          />
        </div>

        <woot-modal :show.sync="isDownloading" :on-close="hidePopup">
          <div class="download-note">
            <img
              src="https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
            />
            <div
              v-html="
                $t('ANALYTICS.DOWNLOAD_REPORT.EMAIL', { email: userEmail })
              "
            />
          </div>
        </woot-modal>
      </div>
    </div>
    <woot-modal :show.sync="downloadError" :on-close="hidePopup">
      <div class="download-note">
        <div>{{ $t('ANALYTICS.DOWNLOAD_REPORT.REPORT_FAIL') }}</div>
      </div>
    </woot-modal>
    <!-- cancel confirmation -->
    <woot-delete-modal-new
      :show.sync="showCancelPopup"
      :on-close="() => (showCancelPopup = false)"
      :on-confirm="cancelScheduledBroadcast"
      show-close
      title="Cancel Broadcast"
      :message="$t('BROADCAST.CANCEL.CONFIRMATION_TEXT') + selectedBroadcastId"
      confirm-text="Continue"
      reject-text="Back"
      :custom-style="{ 'max-width': '35.2rem', height: 'auto' }"
    />
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';

import ProgressBar from 'dashboard/components/widgets/chart/ProgressBar';
import SettingsHeader from '../settings/SettingsHeader';
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import DeprecationPage from './components/DeprecationPage';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';

import downloadMixin from 'dashboard/mixins/download';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import timeMixin from 'dashboard/mixins/time';
import alertMixin from 'shared/mixins/alertMixin';
import shopifyMixin from 'dashboard/mixins/shopify';
import billingMixin from 'dashboard/mixins/billing';

import { ROW_CAPPED_NUMBER } from './constants';
import { getUserEmail } from 'dashboard/helper/cookieHelper';
import * as types from 'shared/constants/googleEventType';

export default {
  components: {
    TableFooter,
    SettingsHeader,
    ProgressBar,
    SearchBox,
    DeprecationPage,
    ReferenceBox,
  },
  mixins: [
    downloadMixin,
    googleAnalyticsMixin,
    timeMixin,
    shopifyMixin,
    alertMixin,
    billingMixin,
    clickaway,
  ],
  data() {
    return {
      showCancelPopup: false,
      isRefreshing: false,
      isDownloading: false,
      downloadError: false,
      search: '',
      inboxId: null,
      inboxName: null,
      isGupshup: false,
      isViewing: false,
      showDeprecationPage: true,
      selectedBroadcastId: null,
      selectedBroadcastProgress: [],
      showModal: false,
      timer: null,
      meta: {
        count: 0,
        prevPage: null,
        nextPage: null,
        currentPage: 1,
      },
      ROW_CAPPED_NUMBER,
    };
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
      progress: 'getBroadcastProgress',
      accountDetails: 'accounts/getAccountDetails',
      umsEnabledFlag: 'inboxes/getUmsEnabledForAnyOneInbox',
      broadcastList: 'getBroadcastList',
    }),
    displayDeprecationPage() {
      return this.umsEnabledFlag && this.showDeprecationPage;
    },
    pageParameter() {
      const selectedPage = Number(this.$route.query?.page);
      return !Number.isNaN(selectedPage) && selectedPage >= 1
        ? selectedPage
        : 1;
    },
    userEmail() {
      return getUserEmail();
    },
    lastThreeBroadcastIds() {
      return this.broadcastList.map(b => b.id).slice(-3);
    },
  },
  mounted() {
    this.onPageChange(this.pageParameter);
  },
  methods: {
    onHideDeprecationPage() {
      this.showDeprecationPage = false;
    },
    setSearch(value) {
      this.search = value;
    },
    isBeforeScheduledTimeStamp(time) {
      if (time) return new Date(time).valueOf() > new Date().valueOf();
      return false;
    },
    changeInboxId(val) {
      this.inboxId = val.id;
      this.inboxName = val.name;
      this.isGupshup = val.channel_type === 'Channel::Gupshup';
      this.fetchStatus();
    },
    goToCreateBroadcast() {
      this.$router.push(
        `/app/accounts/${this.$route.params.accountId}/broadcast/create`
      );
    },
    onPageChange(page) {
      window.history.pushState({}, null, `${this.$route.path}?&page=${page}`);
      this.meta.currentPage = page;
      this.fetchStatus();
    },
    scrollToBottom() {
      const el = document.querySelector('.table-container');

      el.scrollTo({
        top: el.scrollHeight,
        behavior: 'smooth',
      });
    },
    viewBroadcastCount(id) {
      this.isViewing = true;
      this.selectedBroadcastId = id;
      let selectedBroadcast = this.broadcastList.filter(el => el.id === id);
      selectedBroadcast[0].loading = true;

      this.$store.dispatch('broadcastSentCount', id).then(res => {
        selectedBroadcast[0].loading = false;

        let numberOfHoursFromCreated =
          Math.abs(
            new Date(selectedBroadcast[0].created_at).getTime() -
              new Date().getTime()
          ) / 3600000;

        selectedBroadcast[0].sentCount =
          res?.sent + res?.delivered + res?.read + res?.failed || 0;

        selectedBroadcast[0].failedCount =
          numberOfHoursFromCreated > 6
            ? selectedBroadcast[0].total - selectedBroadcast[0].sentCount
            : 0;

        this.selectedBroadcastProgress = selectedBroadcast[0].sentCount
          ? [
              (
                ((selectedBroadcast[0].sentCount +
                  selectedBroadcast[0].failedCount) /
                  selectedBroadcast[0]?.total) *
                100
              ).toFixed(0),
            ]
          : [0];

        this.timer = setInterval(() => {
          if (this.progress[id] && this.selectedBroadcastId === id) {
            this.selectedBroadcastProgress = [this.progress[id]];
          }
        }, 2000);

        selectedBroadcast[0].gupshupSent =
          res?.sent + res?.delivered + res?.read;

        selectedBroadcast[0].gupshupFailed = res?.failed;

        const beforeScheduledTime = this.isBeforeScheduledTimeStamp(
          selectedBroadcast[0].scheduled_at
        );

        if (
          !beforeScheduledTime &&
          this.selectedBroadcastProgress[0] === 0 &&
          +numberOfHoursFromCreated.toFixed(0) > 6
        )
          selectedBroadcast[0].failedState = true;

        selectedBroadcast[0].showProgress = true;

        this.showModal = true;

        const oneOfLastThree = this.lastThreeBroadcastIds.includes(id);

        if (oneOfLastThree)
          this.$nextTick(() => {
            this.scrollToBottom();
          });
      });
    },
    onCancelBroadcast(broadcastId) {
      this.selectedBroadcastId = broadcastId;
      this.showCancelPopup = true;
    },
    cancelScheduledBroadcast() {
      this.$store
        .dispatch('cancelScheduledBroadcast', this.selectedBroadcastId)
        .then(() => {
          this.showCancelPopup = false;
          this.hideStats();
          this.showAlert(
            this.$t('BROADCAST.CANCEL.SUCCESS_MESSAGE'),
            'success'
          );
        })
        .catch(() => {
          this.showAlert(this.$t('BROADCAST.CANCEL.ERROR_MESSAGE'), 'error');
        });
    },
    hideStats() {
      clearInterval(this.timer);
      this.showModal = false;
      this.selectedBroadcastId = null;
      this.selectedBroadcastProgress = [];
    },
    formatDate(date) {
      return this.humanReadableTime(date);
    },
    timeStamp(d) {
      const date = new Date(d);

      const OPTIONS = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };

      const formattedDate = new Intl.DateTimeFormat('en-US', OPTIONS).format(
        date
      );

      return `${formattedDate}`;
    },
    searchReports() {
      this.fetchStatus();
    },
    async fetchStatus() {
      this.isRefreshing = true;
      await this.$store
        .dispatch('broadcastStatus', {
          page: this.meta.currentPage,
          q: this.search ? this.search : null,
          inbox_id: this.inboxId,
        })
        .then(data => {
          this.meta = data.meta;
        });
      this.isRefreshing = false;
    },
    async downloadBroadcast(id, is_direct_brd) {
      this.googleAnalyticsEvent(
        types.default.BROADCAST_DOWNLOAD_BROADCAST,
        types.default.BROADCAST_REPORTS,
        types.default.BROADCAST_REPORTS
      );
      this.isDownloading = true;
      await this.$store
        .dispatch('broadcastDownload', {
          broadcast_id: id,
          email: this.userEmail,
          is_direct_brd: is_direct_brd,
        })
        .then(data => {
          if (is_direct_brd) {
            // From mixin
            this.openLink(data.data);
            this.isDownloading = false;
          }
        })
        .catch(() => {
          this.downloading = false;
          this.downloadError = true;
        });
    },
    hidePopup() {
      this.isDownloading = false;
      this.downloadError = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.warn-banner {
  max-width: $space-largest * 10;
  border: 1px solid $warn-red-400;
}

.settings-box {
  height: calc(100% - 1.6rem);
  padding-top: $space-largest;
  padding-right: $space-normal;
}
.small-12 {
  display: flex;
  flex-direction: column;
}

.woot-table {
  margin-top: -1px;

  > thead {
    position: sticky;
    top: $zero;
    z-index: 2;

    th {
      padding: $space-normal $zero $space-small $zero;
    }
    > th:first-child {
      padding-left: $space-medium;
      width: 10%;
    }
  }

  > tbody {
    > tr {
      cursor: pointer;

      &:hover {
        background: $pg-light-1;
      }

      > td {
        &:first-child {
          padding-left: $space-medium;
        }
        &:nth-child(2) {
          padding: $space-normal $zero;
        }
      }
    }
  }
}

.table-container {
  height: 100%;
  width: 100%;

  table.woot-table tr td {
    word-break: break-word;
  }
}

.no-items-error-message {
  margin-top: $space-normal;
  text-align: center;
}

.reports-search {
  margin-bottom: $zero;
  background: none;
  border: 1px solid $neutral-grey-600;
  border-radius: $border-radius-smaller;
  min-width: 31rem;
  color: $text-dark;
  height: 3.8rem !important;
}

.stat-card {
  padding: $space-slab $space-normal;
  position: absolute;
  right: $space-one;
  width: $space-medium * 10;
  z-index: 1;

  .cancel-btn {
    padding-top: $space-slab;
  }
}

.cancelled-status {
  cursor: default;
  text-transform: uppercase;
  word-break: keep-all;
}

.view-button {
  display: inline-block;
  margin: $zero auto;
  text-transform: capitalize;
}

.no-data {
  margin-top: $space-large;
  min-height: 18rem;
  text-align: center;
  justify-content: center;
}
</style>
