<template>
  <div
    class="create-ticket-form-with-preview flex-row flex-justify--between gap--normal"
  >
    <form class="create-ticket-form custom-scroll" @submit.prevent>
      <add-email-section
        class="CREATE_TICKET_CONTACTS"
        @selectedContactsTo="selectedContactsTo = $event"
        @selectedContactsCC="selectedContactsCC = $event"
        @selectedContactsBCC="selectedContactsBCC = $event"
      />
      <add-subject-body-section
        @subjectInput="subject = $event"
        @messageInput="message = $event"
        @fileInput="files = $event"
        @toggleEmailSignature="hasEmailSignature = $event"
      />
      <assign-agent-or-team-section @select="onAgentOrTeamSelection" />
      <assign-tags-section
        tag-type="Conversation"
        @selectedLabels="selectedConvLabels = $event"
      />
      <assign-tags-section
        tag-type="Contact"
        @selectedLabels="selectedContactLabels = $event"
      />
      <div class="submit-button">
        <woot-base-button
          front-icon="rocket"
          :loading="uiFlags.isCreatingConversation"
          @click="onSubmit"
        >
          {{ $t('CREATE_TICKET.FORM.SUBMIT.TITLE') }}
        </woot-base-button>
      </div>
    </form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import AddEmailSection from './AddEmailSection';
import AddSubjectBodySection from './AddSubjectBodySection';
import AssignAgentOrTeamSection from '../shared/AssignAgentOrTeamSection';
import AssignTagsSection from '../shared/AssignTagsSection';

import broadcastMixin from 'dashboard/mixins/broadcast';

export default {
  components: {
    AddEmailSection,
    AddSubjectBodySection,
    AssignAgentOrTeamSection,
    AssignTagsSection,
  },
  mixins: [broadcastMixin],
  props: {
    inboxId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      files: [],
      hasEmailSignature: false,
      message: '',
      selectedContactsTo: [],
      selectedContactsCC: [],
      selectedContactsBCC: [],
      selectedConvLabels: [],
      selectedContactLabels: [],
      selectedAgent: null,
      selectedTeam: null,
      subject: '',
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'getConversationUiFlags' }),
  },
  methods: {
    onAgentOrTeamSelection({ id, type }) {
      if (type === 'agent') this.selectedAgent = id;
      if (type === 'team') {
        // remove 'team' from the id before assigning to state
        const teamId = id.replace(/^team:/, '');
        this.selectedTeam = +teamId;
      }
    },
    preparePayload() {
      const preparedContacts = this.selectedContactsTo.map(
        ({ type, name, email }) => ({
          type,
          name,
          email_address: email,
        })
      );
      const bcc_emails =
        this.selectedContactsBCC.map(({ email }) => email).toString() || '';
      const cc_emails =
        this.selectedContactsCC.map(({ email }) => email).toString() || '';
      return {
        agent_id: this.selectedAgent,
        team_id: this.selectedTeam,
        contacts: preparedContacts,
        bcc_emails: bcc_emails,
        cc_emails: cc_emails,
        subject: this.subject,
        content: this.message,
        labels: this.selectedConvLabels.map(({ title }) => title),
        contact_labels: this.selectedContactLabels.map(({ title }) => title),
        attachments: this.files.map(({ file }) => file),
        has_email_signature: this.hasEmailSignature ? 1 : 0,
      };
    },
    onSubmit() {
      const payload = this.preparePayload();
      this.$emit('submit', payload);
    },
  },
};
</script>
