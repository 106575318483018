<template>
  <div class="data-table">
    <span
      v-if="title"
      class="text-dark title-h5 mg-bottom--normal"
      v-text="title"
    />
    <div v-if="canShowFeatures" class="features flex-row">
      <slot v-if="!selectedRows.length" name="table-features" />
      <div
        v-else
        key="bulk-features"
        class="bulk-actions-container flex-row flex-align gap--normal"
      >
        <woot-base-button variant="tertiary" @click="selectAllRows">
          <label style="cursor: pointer" class="container-checkbox">
            <input
              v-model="selectAllChecked"
              type="checkbox"
              :checked="selectAllChecked"
              @click="selectAllRows"
            />
            <span class="grey-checkmark checkmark" />
          </label>
          Select all
        </woot-base-button>
        <woot-base-button
          variant="secondary-danger"
          front-icon="delete"
          @click="deleteRow(selectedRows)"
        >
          Delete
        </woot-base-button>
      </div>
    </div>
    <base-table
      v-model="selectedRows"
      :data="tableData"
      :columns="columns"
      :loading="loading"
      :actions="actions"
      :enable-animations="enableAnimations"
      :class="_class"
      @selectRow="onRowSelection"
    >
      <template v-if="actions.length" v-slot:actions="{ row }">
        <div class="flex-row flex-justify--end">
          <woot-single-selector
            v-if="collapseActions"
            variant="tertiary"
            :custom-style="{
              borderRadius: '0.8rem',
              boxShadow: 'none',
              left: 'auto',
              right: '0.8rem',
              width: 'auto',
            }"
            icon-size-prop="semimedium"
            icon="threeDot"
            :show-selected-text="false"
            :show-option-name="false"
            :option-list="getActionsList(row)"
            @click="action => onActionSelected(action, row)"
          />
          <div v-else class="flex-row gap--large actions-container">
            <woot-base-button
              v-for="action in getActionsList(row)"
              :key="action.id"
              tag="span"
              :variant="
                action.name === 'delete' ? 'tertiary-danger' : 'tertiary'
              "
              @click="onActionSelected(action, row)"
            >
              <icons
                :name="action.icon"
                color="textlightgrey"
                size="semimedium"
                :title="action.name"
              />
            </woot-base-button>
          </div>
        </div>
      </template>

      <template v-if="hasExtraActionsSlot" v-slot:extra-actions="{ row }">
        <slot name="actions" :row="{ ...row }" />
      </template>

      <template v-if="hasLoadingSlot" v-slot:loading-state>
        <slot name="loading-state" />
      </template>

      <template v-if="hasZeroStateSlot" v-slot:zero-state>
        <slot name="zero-state" />
      </template>
    </base-table>
  </div>
</template>
<script>
import BaseTable from './BaseTable';

export default {
  components: { BaseTable },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    _class: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    actions: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    collapseActions: {
      type: Boolean,
      default: false,
    },
    enableAnimations: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedRows: [],
      selectAllChecked: false,
    };
  },
  computed: {
    hasExtraActionsSlot() {
      return !!this.$scopedSlots.actions;
    },
    hasLoadingSlot() {
      return !!this.$slots['loading-state'];
    },
    hasZeroStateSlot() {
      return !!this.$slots['zero-state'];
    },
    canShowFeatures() {
      return this.$slots['table-features']?.length;
    },
  },
  inject: {
    canSelectRow: {
      default: () =>
        // eslint-disable-next-line func-names
        function() {
          return true;
        },
    },
    getConditionalActionsList: { default: null },
  },
  methods: {
    getActionsList({ row }) {
      if (!this.getConditionalActionsList) return this.actions;

      const filteredActionsList = this.getConditionalActionsList(
        this.actions,
        row
      );
      return filteredActionsList;
    },
    toggleSelectAll() {
      this.selectAllChecked =
        this.selectedRows.length === this.tableData.length - 1;
    },
    onActionSelected(action, data) {
      if (action.name === 'select') {
        this.addToSelectedRows(data.index);
        return;
      }
      if (action.name === 'delete') {
        this.deleteRow([data.index]);
        return;
      }
      this.$emit(action.name, data.row);
    },
    onRowSelection({ row, index, allowMultiSelect }) {
      if (!this.canSelectRow(row) && allowMultiSelect) return;

      if (!allowMultiSelect) {
        this.$emit('select', row, index);
        return;
      }
      this.addToSelectedRows(index);
    },
    addToSelectedRows(rowIndex) {
      if (this.selectedRows.includes(rowIndex)) {
        this.selectedRows.splice(this.selectedRows.indexOf(rowIndex), 1);
        this.toggleSelectAll();
        return;
      }
      this.selectedRows.push(rowIndex);

      this.toggleSelectAll();
    },
    selectAllRows() {
      this.selectAllChecked = !this.selectAllChecked;

      if (this.selectAllChecked)
        this.tableData.map((row, index) => {
          if (!this.selectedRows.includes(index) && this.canSelectRow(row))
            this.selectedRows.push(index);
          return null;
        });
      else this.clearSelections();
    },
    deleteRow(selectedRows) {
      this.$emit('delete', selectedRows);
    },
    clearSelections() {
      this.selectedRows = [];
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.data-table {
  height: 100%;

  .features {
    gap: $space-normal;
    padding-bottom: $space-slab;
    z-index: 1;

    .bulk-actions-container {
      .container-checkbox {
        margin: $zero $space-two 1.8rem $zero;
        padding-left: $zero;
      }

      .select-all-btn {
        color: $text-dark;
      }

      .delete-btn {
        height: 4.4rem;
        width: $space-slab * 10;
      }
    }
  }
}
</style>
