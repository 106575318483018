<template>
  <div :class="{ 'email-body': isEmail }">
    <video-view v-if="isVideo" :url="videoUrl" />
    <youtube-view v-else-if="isYoutube" :url="isYoutube" />
    <span v-else class="message-text__wrap">
      <div
        class="message-span"
        :class="{
          'message-activity': [2, 7].includes(data.message_type),
          'message-email body-b2': isEmail,
          'message-deleted': isDeleted,
        }"
        v-html="emailSignature ? message + emailSignature : message"
      />
    </span>
  </div>
</template>

<script>
import Video from 'shared/helpers/VideoHelper';
import VideoView from 'shared/components/VideoView';
import YoutubeView from 'shared/components/YoutubeView';

export default {
  components: {
    VideoView,
    YoutubeView,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
    isEmail: {
      type: Boolean,
      default: true,
    },
    emailSignature: {
      type: [String, Boolean],
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isVideo() {
      var video = new Video();
      return video.checkVideo(this.data.content);
    },
    isYoutube() {
      var video = new Video();
      return video.checkYoutube(this.data.content);
    },
    videoUrl() {
      return this.data.content;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.message-span ::v-deep p::after {
  content: '';
  padding-right: $space-jumbo;
  display: inline-block;
}

.message-email {
  overflow: auto;
}

.message-email ::v-deep p {
  margin-bottom: 0;

  &::after {
    display: none;
  }
}

.message-activity ::v-deep p::after {
  content: none;
}

.message-deleted ::v-deep p {
  color: $neutral-grey-600;
}
</style>
