<template>
  <transition name="modal-fade">
    <div
      v-show="show"
      class="modal-mask"
      :style="modalPosition"
      @click="onBackDropClick"
    >
      <div :class="modalContainerClassName" :style="customStyle" @click.stop>
        <div class="positioned-container flex-row flex-align">
          <div v-if="numberOfCmpts > 0">
            <div
              v-if="showProgress && numberOfCmpts - 1 !== 0"
              class="progress--container flex-row flex-align"
            >
              <span
                class="progress--text title-h5 text-dark"
                v-text="progressText"
              />
              <div class="progress--bar">
                <div
                  :style="{ width: calculateWidth }"
                  class="progress--bar--filled"
                />
              </div>
            </div>
          </div>
          <woot-base-button
            v-if="showClose"
            class="close-icon"
            tag="span"
            variant="tertiary-danger"
            @click="onClose"
          >
            <icons
              name="closeRound"
              color="red"
              size="normal"
              :show-title="false"
            />
          </woot-base-button>
        </div>
        <slot />
        <node-renderer
          v-for="(node, index) of filteredPaginatedComponents"
          v-show="index === currIndex"
          :key="node.key || index"
          :value="node"
          tag="div"
        />
        <div v-if="numberOfCmpts" class="pagination-controls">
          <woot-base-button
            v-if="!isFirstIndex"
            class="modal-button"
            variant="secondary"
            @click="decrementIndex"
          >
            GO BACK
          </woot-base-button>
          <woot-base-button
            v-if="!isLastIndex"
            class="modal-button"
            variant="primary"
            :disabled="disableBtn && disableBtn[currIndex]"
            @click="incrementIndex"
          >
            NEXT
          </woot-base-button>
          <woot-base-button
            v-else
            class="modal-button"
            variant="primary"
            :loading="loadingBtn"
            :disabled="disableBtn && disableBtn[currIndex]"
            @click="$emit('submit')"
          >
            SUBMIT
          </woot-base-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  components: {
    NodeRenderer: {
      props: {
        value: { type: [Object, Array], required: true },
        tag: { type: String, default: 'div' },
      },
      render(h) {
        let nodes = this.value;
        if (!this.value.tag) return null;
        if (!Array.isArray(nodes)) nodes = [nodes];

        return h(this.tag, { attrs: { class: '' } }, nodes);
      },
    },
  },
  props: {
    customStyle: {
      type: Object,
      default: () => {},
    },
    modalPosition: {
      type: Object,
      default: () => {},
    },
    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },
    disableBtn: {
      type: Object,
      default: null,
    },
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    show: Boolean,
    onClose: {
      type: Function,
      default: () => {},
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showProgress: {
      type: Boolean,
      default: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    mediumWidth: {
      type: Boolean,
      default: false,
    },
    smallWidth: {
      type: Boolean,
      default: false,
    },
    navigateTo: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currIndex: 0,
    };
  },
  computed: {
    calculateWidth() {
      return ((this.currIndex + 1) / this.numberOfCmpts) * 100 + '%';
    },
    progressText() {
      return `Step ${this.currIndex + 1}/${this.numberOfCmpts}`;
    },
    modalContainerClassName() {
      let className = 'modal-container';
      if (this.fullWidth) {
        return `${className} modal-container--full-width`;
      }
      if (this.smallWidth) {
        return `${className} modal-container--small-width`;
      }
      if (this.mediumWidth) {
        return `${className} modal-container--medium-width`;
      }
      return className;
    },
    filteredPaginatedComponents() {
      return this.$slots['paginated-components']?.filter(cmpt => cmpt.tag);
    },
    numberOfCmpts() {
      return this.filteredPaginatedComponents?.length;
    },
    isFirstIndex() {
      const { currIndex } = this;
      return currIndex === 0;
    },
    isLastIndex() {
      const { currIndex, numberOfCmpts } = this;
      return currIndex === numberOfCmpts - 1;
    },
  },
  mounted() {
    setTimeout(() => {
      this.currIndex = this.navigateTo;
    }, 300);
    document.addEventListener('keydown', e => {
      if (this.show && e.code === 'Escape') this.onClose();
    });
  },
  methods: {
    incrementIndex() {
      if (this.currIndex + 1 < this.filteredPaginatedComponents.length)
        this.currIndex += 1;
    },
    decrementIndex() {
      if (this.currIndex !== 0) this.currIndex -= 1;
    },
    close() {
      this.onClose();
    },
    onBackDropClick() {
      if (this.closeOnBackdropClick) {
        this.onClose();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.modal-container {
  border-radius: $border-radius-smaller;
  height: 53.6rem;
  width: 76.8rem;
  overflow: hidden;

  .pagination-controls {
    position: absolute;
    bottom: 0;
    padding: $space-two $space-four $zero;
    margin-bottom: 1.6rem;
  }

  &--full-width {
    height: 100%;
    width: 97%;
  }

  &--small-width {
    width: 50rem;
  }

  &--medium-width {
    width: 120rem;
    height: 80%;
  }

  .positioned-container {
    gap: $space-larger;
    position: absolute;
    right: $space-three * 10;
    top: 2.7rem;

    .progress {
      &--container {
        gap: $space-one;
      }

      &--bar {
        background: $neutral-grey-400;
        border-radius: $border-radius;
        height: $space-one;
        width: $space-slab * 10;

        &--filled {
          background: $secondary-blue;
          border-radius: $border-radius;
          height: $space-one;
          transition: width 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
        }
      }
    }

    .close-icon {
      cursor: pointer;
    }
  }
}
</style>
