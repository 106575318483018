<template>
  <woot-modal
    :show.sync="showModal"
    :on-close="
      this.$t('RELEASE.NEW_FEATURES').length === 1 ? hideModal : () => {}
    "
    :show-close="this.$t('RELEASE.NEW_FEATURES').length === 1"
    :small-width="true"
  >
    <div class="feature-intro-modal">
      <p class="modal-heading">
        What's new in LimeChat Helpdesk?
      </p>
      <div class="media-container">
        <img
          v-if="this.$t('RELEASE.NEW_FEATURES')[currentIndex].image"
          :src="this.$t('RELEASE.NEW_FEATURES')[currentIndex].image"
        />
        <video
          v-else-if="$t('RELEASE.NEW_FEATURES')[currentIndex].video"
          :key="$t('RELEASE.NEW_FEATURES')[currentIndex].video"
          preload="true"
          autoPlay
          muted
          loop
        >
          <source
            :src="$t('RELEASE.NEW_FEATURES')[currentIndex].video"
            type="video/mp4"
          />
        </video>
      </div>
      <div class="feature-container">
        <p class="feature-heading">
          {{ this.$t('RELEASE.NEW_FEATURES')[currentIndex].title }}
        </p>
        <div
          v-html="this.$t('RELEASE.NEW_FEATURES')[currentIndex].description"
        />
      </div>
      <div
        v-if="this.$t('RELEASE.NEW_FEATURES').length > 1"
        class="modal-navigation flex-space-between"
      >
        <div class="button-container flex-space-between">
          <woot-secondary-button
            v-if="currentIndex !== 0"
            size="small"
            name="BACK"
            @click="prevSlide"
          />
          <woot-primary-button
            v-if="currentIndex === this.$t('RELEASE.NEW_FEATURES').length - 1"
            size="small"
            :custom-class="'primary-button'"
            name="GOT IT!"
            @click="hideModal"
          >
          </woot-primary-button>
          <woot-primary-button
            v-else
            size="small"
            :custom-class="'primary-button'"
            name="NEXT"
            @click="nextSlide"
          />
        </div>
      </div>
    </div>
  </woot-modal>
</template>
<script>
export default {
  data() {
    return {
      showModal: true,
      currentIndex: 0,
    };
  },
  methods: {
    prevSlide() {
      this.currentIndex -= 1;
    },
    nextSlide() {
      this.currentIndex += 1;
    },
    hideModal() {
      if (
        this.$route.path.includes(
          `/app/accounts/${this.$route.params.accountId}/dashboard`
        ) &&
        this.$tours.sectionIntroduction
      )
        this.$tours.sectionIntroduction.start();

      this.$emit('closeModal');
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.feature-intro-modal {
  padding: $space-medium $space-normal $space-normal;
  .modal-heading {
    text-align: center;
    margin-bottom: $space-normal;
    color: $secondary-blue;
    font-weight: $font-weight-black;
    font-size: $font-size-big;
  }
  .media-container {
    img {
      display: block;
      margin: $zero auto;
    }
    video {
      display: block;
      margin: $zero auto;
      width: 90%;
      height: 3.4rem * 10;
      border-radius: $border-radius;
      border: $space-smaller solid $neutral-grey-400;
      object-fit: cover;
    }
  }
  .feature-container {
    padding: $space-medium $space-large $space-two;
    .feature-heading {
      font-weight: $font-weight-black;
      font-size: $font-size-medium;
      line-height: $space-medium;
      margin-bottom: $space-small;
    }
  }
  .modal-navigation {
    justify-content: flex-end;
    .feature-count {
      font-size: $font-size-small;
    }
    .primary-button {
      margin-left: $space-small;
    }
  }
}
</style>
