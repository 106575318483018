<template>
  <woot-single-selector
    variant="tertiary"
    size="large"
    :custom-button="true"
    :top-position="{ top: undefined }"
    :custom-style="{
      width: '100%',
      left: '0.01rem',
      bottom: '0.4rem',
      maxHeight: '10rem',
      borderRadius: '0.4rem',
      top: null,
    }"
    :hide-on-select="false"
    :option-list="standardVariables"
    is-dropdown-shown
    @click="onSelectOption"
  >
    <slot />
  </woot-single-selector>
</template>
<script>
import { MESSAGE_VARIABLES } from 'shared/constants/messages';

export default {
  props: {
    searchKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      initialValue: {
        id: 'conversation.id',
        name: 'Conversation Id',
      },
    };
  },
  computed: {
    standardVariables() {
      return MESSAGE_VARIABLES.filter(variable => {
        return (
          variable.label.includes(this.searchKey) ||
          variable.key.includes(this.searchKey)
        );
      }).map(variable => ({
        id: variable.key,
        name: variable.label,
      }));
    },
  },
  methods: {
    onSelectOption(item) {
      this.$emit('onSelectVariable', item.id);
    },
  },
};
</script>
