<template>
  <div class="content-box settings-box">
    <div class="settings-container gap--normal">
      <div class="custom-scroll lime-card-1dp">
        <page-header
          :header-title="integrationFields.TITLE"
          class="page-header"
        >
          <template v-slot:icon>
            <div class="crm-img-container">
              <div class="centered-image">
                <img
                  :src="
                    '/dashboard/images/integrations/' +
                      integrationFields.THUMBNAIL
                  "
                />
              </div>
            </div>
          </template>
          <reference-box
            :to="
              $t('INTEGRATION_SETTINGS.GITBOOK_URL') + integrationFields.ACTION
            "
            :content="
              'Know more about ' + integrationFields.TITLE + ' Integration'
            "
            type="document"
          />
        </page-header>
        <div class="crm-integration flex-column">
          <woot-loading-state
            v-if="isLoading"
            message="Fetching integration details"
          />
          <div :class="isLoading ? 'disable-container' : ''">
            <div class="crm-container">
              <div class="crm-body-container">
                <form
                  class="flex-column-center"
                  @submit.prevent="logisticsConnect"
                >
                  <page-header
                    v-if="integrationType === 'logistics'"
                    class="crm-input"
                    :header-title="'Is Integration Active'"
                    size="small"
                  >
                    <woot-switch
                      id="active_switch"
                      v-model="model[formLength]"
                      @click="activationToggler"
                    />
                  </page-header>
                  <woot-input
                    v-for="(data, index) in integrationFields.FORM"
                    :key="index"
                    v-model.trim="model[index]"
                    :label="data.LABEL"
                    :placeholder="data.PLACEHOLDER"
                    :has-error="
                      data.REQUIRED !== 'false'
                        ? $v.model.$each[index].$error
                        : false
                    "
                    error="Required"
                    class="crm-input"
                    @input="$v.model.$each[index].$touch"
                  />
                  <slot name="more-inputs" />
                  <woot-button
                    v-if="showDefaultButton"
                    type="submit"
                    class="button nice crm-button"
                    :disabled="disableButton"
                  >
                    {{ $t('INTEGRATION_SETTINGS.SAVE.BUTTON_TEXT') }}
                  </woot-button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import { required } from 'vuelidate/lib/validators';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';
import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';

export default {
  components: {
    PageHeader,
    ReferenceBox,
  },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  props: {
    integrationFields: {
      type: Object,
      default: () => {},
    },
    formLength: {
      type: Number,
      default: 0,
    },
    showDefaultButton: {
      type: Boolean,
      default: true,
    },
    integrationType: {
      type: String,
      default: 'logistics',
    },
  },
  data() {
    const model = new Array(this.formLength).fill('');
    model.push(false);
    return {
      model,
      isLoading: false,
      isActive: false,
    };
  },
  validations: {
    model: {
      $each: {
        required,
      },
    },
  },
  computed: {
    integrationName() {
      return this.integrationFields.ID;
    },
    disableButton() {
      return this.integrationFields.FORM.some((data, index) => {
        return data.REQUIRED !== 'false'
          ? this.$v.model.$each[index].$invalid
          : false;
      });
    },
    fetchConnectionUrl() {
      return this.$t(
        'INTEGRATION_SETTINGS.' +
          this.integrationType.toUpperCase() +
          '.ACTIONS.FETCH'
      );
    },
    createConnectionUrl() {
      return this.$t(
        'INTEGRATION_SETTINGS.' +
          this.integrationType.toUpperCase() +
          '.ACTIONS.CREATE'
      );
    },
  },
  methods: {
    activationToggler() {
      this.model[this.formLength] = !this.model[this.formLength];
    },
    logisticsConnect() {
      this.$emit('catchInput', this.model);
    },
    createConnection(data) {
      this.isLoading = true;
      this.$store
        .dispatch(this.createConnectionUrl, {
          integration_name: this.integrationName,
          integration_data: data,
        })
        .then(response => {
          if (response.data) {
            let response_data = response.data.integration_details;
            this.isActive = response_data?.is_active ?? true;
            if (!this.isActive) {
              this.showAlert(
                this.$t('INTEGRATION_SETTINGS.STATES.CONNECTED_AND_NOT_ACTIVE'),
                'info'
              );
            } else {
              let notification;
              if (this.integrationType === 'logistics') {
                notification = this.$t(
                  'INTEGRATION_SETTINGS.STATES.CONNECTED_AND_ACTIVE'
                );
              } else {
                notification = this.$t('INTEGRATION_SETTINGS.STATES.CONNECTED');
              }

              this.showAlert(notification, 'success');
            }

            if (
              this.integrationName ===
              this.$t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.ZOHO.ID')
            ) {
              this.account_id = this.$store.getters.getCurrentAccountId;
              const url = this.$t(
                'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.ZOHO.URL',
                {
                  account_id: this.account_id,
                  client_id: this.model[0],
                  current_window: window.location.href,
                }
              );
              window.open(url, '_blank', 'noopener,noreferrer');
            }
          }
          this.mix_panel_connection_successful_settings_integrations(
            this.integrationName
          );
        })
        .catch(() => {
          this.showAlert(this.$t('INTEGRATION_SETTINGS.STATES.ERROR'), 'error');
        });
      this.isLoading = false;
    },
    getConnectionDetails() {
      this.isLoading = true;
      this.$store
        .dispatch(this.fetchConnectionUrl, {
          integration_name: this.integrationName,
        })
        .then(response => {
          if (response.data) {
            let response_data = response.data.integration_details;
            this.isActive = response_data?.is_active ?? true;
            this.setData(response_data);
            if (!this.isActive) {
              this.showAlert(
                this.$t('INTEGRATION_SETTINGS.STATES.CONNECTED_AND_NOT_ACTIVE'),
                'info'
              );
            } else {
              let notification;
              if (this.integrationType === 'logistics') {
                notification = this.$t(
                  'INTEGRATION_SETTINGS.STATES.CONNECTED_AND_ACTIVE'
                );
              } else {
                notification = this.$t('INTEGRATION_SETTINGS.STATES.CONNECTED');
              }

              this.showAlert(notification, 'success');
            }
          } else {
            this.showAlert(
              this.$t('INTEGRATION_SETTINGS.STATES.NOT_CONNECTED'),
              'error'
            );
          }
        })
        .catch(() => {
          this.showAlert(this.$t('INTEGRATION_SETTINGS.STATES.ERROR'), 'error');
        });
      this.isLoading = false;
    },
    setData(data) {
      this.model = Object.values(data);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.crm-integration {
  justify-content: center;
}

.disable-container {
  pointer-events: none;
  opacity: 0.3;
}

.settings-box {
  padding-top: $space-largest;
  padding-left: 0.1rem;
  overflow: hidden;

  .settings-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    .lime-card-1dp {
      flex-grow: 1;
      padding: $zero;
      padding-bottom: $space-two;
    }
  }
}

.crm-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.crm-body-container {
  width: 80%;
}

.crm-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.centered-image {
  height: 32px;
  width: 32px;
}

.heading {
  text-align: center;
  font-size: $font-size-medium;
  margin: $space-normal $zero;
  font-weight: $font-weight-medium;
}

.steps {
  text-align: center;
  font-size: $font-size-small;
  margin: $space-micro $space-larger;
}

.question {
  color: $neutral-grey-600;
  margin: $space-two $space-larger;
}

.limechat-store {
  color: $pg-1-500;
  margin: $space-two $space-larger;
  display: flex;
  justify-content: center;
}

.text-margin {
  margin: $space-micro;
}

.crm-button {
  margin-top: $space-normal;
  margin-bottom: $space-one;

  padding: $space-slab $space-large;
}

.crm-input {
  margin-bottom: $space-one;
  width: 300px;
}

.page-header {
  margin-bottom: $space-two;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 300px;
}
</style>
