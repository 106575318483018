import { render, staticRenderFns } from "./LabelDetails.vue?vue&type=template&id=24eafa98&scoped=true&"
import script from "./LabelDetails.vue?vue&type=script&lang=js&"
export * from "./LabelDetails.vue?vue&type=script&lang=js&"
import style0 from "./LabelDetails.vue?vue&type=style&index=0&id=24eafa98&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24eafa98",
  null
  
)

export default component.exports