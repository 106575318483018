<template>
  <div class="small-12 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.WHATSAPP_API.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.WHATSAPP_API.DESC')"
    />
    <div v-if="showWebhookDetails" class="settings-sub-menu-content--larger">
      <div class="profile--settings--row mg-bottom">
        <woot-modal-header
          :header-title="$t('INBOX_MGMT.ADD.WHATSAPP_API.WEBHOOK_DETAILS')"
          :header-content="$t('INBOX_MGMT.ADD.WHATSAPP_API.WEBHOOK_URL')"
        />
        <div class="columns medium-12">
          <woot-code :script="webhookUrl" />
        </div>
      </div>
      <div class="profile--settings--row mg-bottom">
        <woot-modal-header
          :header-content="$t('INBOX_MGMT.ADD.WHATSAPP_API.WEBHOOK_TOKEN')"
        />
        <div class="columns medium-12">
          <woot-code :script="webhookToken" />
        </div>
      </div>
      <div class="mg-top flex-row flex-justify--end">
        <woot-primary-button
          :name="$t('INBOX_MGMT.ADD.WHATSAPP_API.NEXT_STEP')"
          @click="redirectToAddAgents"
        />
      </div>
    </div>
    <form v-else @submit.prevent="createChannel">
      <div class="settings-sub-menu-content--larger mg-bottom--jumbo">
        <div
          class="flex-row flex-justify--between content-margin full-width gap--two"
        >
          <woot-input
            v-model.trim="inboxName"
            required
            auto-focus
            :error="$t('INBOX_MGMT.ADD.WHATSAPP_API.INBOX_NAME.ERROR')"
            :label="$t('INBOX_MGMT.ADD.WHATSAPP_API.INBOX_NAME.LABEL')"
            :placeholder="
              $t('INBOX_MGMT.ADD.WHATSAPP_API.INBOX_NAME.PLACEHOLDER')
            "
            :class="{ error: $v.inboxName.$error }"
            :has-error="$v.inboxName.$error"
            full-width
            @blur="$v.inboxName.$touch"
          />
          <woot-input
            v-model.trim="phoneNumber"
            required
            full-width
            :error="$t('INBOX_MGMT.ADD.WHATSAPP_API.PHONE_NUMBER.ERROR')"
            :label="$t('INBOX_MGMT.ADD.WHATSAPP_API.PHONE_NUMBER.LABEL')"
            :placeholder="
              $t('INBOX_MGMT.ADD.WHATSAPP_API.PHONE_NUMBER.PLACEHOLDER')
            "
            :class="{ error: $v.phoneNumber.$error }"
            :has-error="$v.phoneNumber.$error"
            @blur="$v.phoneNumber.$touch"
          />
        </div>

        <woot-input
          v-model.trim="systemUserAdminAccessToken"
          required
          class="medium-12 columns content-margin"
          :error="
            systemUserAdminAccessToken === ''
              ? $t('INBOX_MGMT.COMMON_ERROR_MESSAGE')
              : $t('INBOX_MGMT.ADD.WHATSAPP_API.AUTH_TOKEN.ERROR')
          "
          :label="$t('INBOX_MGMT.ADD.WHATSAPP_API.AUTH_TOKEN.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.WHATSAPP_API.AUTH_TOKEN.PLACEHOLDER')
          "
          :class="{ error: $v.systemUserAdminAccessToken.$error }"
          :has-error="$v.systemUserAdminAccessToken.$error"
          @blur="$v.systemUserAdminAccessToken.$touch"
        />
        <div
          class="flex-row flex-justify--between content-margin full-width gap--two"
        >
          <woot-input
            v-model.trim="accountName"
            required
            full-width
            :error="$t('INBOX_MGMT.ADD.WHATSAPP_API.ACCOUNT_NAME.ERROR')"
            :label="$t('INBOX_MGMT.ADD.WHATSAPP_API.ACCOUNT_NAME.LABEL')"
            :placeholder="
              $t('INBOX_MGMT.ADD.WHATSAPP_API.ACCOUNT_NAME.PLACEHOLDER')
            "
            :class="{ error: $v.accountName.$error }"
            :has-error="$v.accountName.$error"
            @blur="$v.accountName.$touch"
          />
          <woot-input
            v-model.trim="accountId"
            required
            full-width
            :error="$t('INBOX_MGMT.ADD.WHATSAPP_API.ACCOUNT_ID.ERROR')"
            :label="$t('INBOX_MGMT.ADD.WHATSAPP_API.ACCOUNT_ID.LABEL')"
            :placeholder="
              $t('INBOX_MGMT.ADD.WHATSAPP_API.ACCOUNT_ID.PLACEHOLDER')
            "
            :class="{ error: $v.accountId.$error }"
            :has-error="$v.accountId.$error"
            @blur="$v.accountId.$touch"
          />
        </div>
        <woot-input
          v-model.trim="phoneNumberId"
          required
          :error="$t('INBOX_MGMT.ADD.WHATSAPP_API.PHONE_NUMBER_ID.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.WHATSAPP_API.PHONE_NUMBER_ID.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.WHATSAPP_API.PHONE_NUMBER_ID.PLACEHOLDER')
          "
          :class="{ error: $v.phoneNumberId.$error }"
          :has-error="$v.phoneNumberId.$error"
          @blur="$v.phoneNumberId.$touch"
        />
        <woot-input
          v-model.trim="catalogId"
          :error="$t('INBOX_MGMT.ADD.WHATSAPP_API.CATALOG_ID.ERROR')"
          class="medium-12 columns content-margin"
          :label="$t('INBOX_MGMT.ADD.WHATSAPP_API.CATALOG_ID.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.WHATSAPP_API.CATALOG_ID.PLACEHOLDER')
          "
          :class="{ error: $v.catalogId.$error }"
          :has-error="$v.catalogId.$error"
          @blur="$v.catalogId.$touch"
        />
      </div>
      <div class="medium-12 columns settings-sub-menu-bottom-nav">
        <woot-base-button
          variant="secondary"
          @click.prevent="() => $router.go(-1)"
        >
          Back
        </woot-base-button>
        <woot-base-button :loading="uiFlags.isCreating" type="submit">
          Next
        </woot-base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import { required } from 'vuelidate/lib/validators';
import validatePhoneNumber from '../validations';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      inboxName: '',
      systemUserAdminAccessToken: '',
      phoneNumber: '',
      accountName: '',
      accountId: '',
      phoneNumberId: '',
      catalogId: '',
      webhookUrl: '',
      webhookToken: '',
      showWebhookDetails: false,
      id: null,
      channelType: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    inboxName: { required, ...validatePhoneNumber.notNumeric },
    systemUserAdminAccessToken: { required },
    phoneNumber: { required, ...validatePhoneNumber.phone },
    accountName: { required, ...validatePhoneNumber.notNumeric },
    accountId: { required, ...validatePhoneNumber.postiveNumber },
    phoneNumberId: { required, ...validatePhoneNumber.postiveNumber },
    catalogId: { ...validatePhoneNumber.postiveNumber },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('AGENT_MGMT.FORM_ERROR'), 'error');
        return;
      }
      try {
        const whatsappApiChannel = await this.$store.dispatch(
          'inboxes/createWhatsappAPIChannel',
          {
            name: this.inboxName,
            auth_token: this.systemUserAdminAccessToken,
            phone_number: this.phoneNumber,
            wa_business_account_name: this.accountName,
            wa_business_account_id: this.accountId,
            wa_phone_number_id: this.phoneNumberId,
            catalog_id: this.catalogId,
          }
        );
        this.id = whatsappApiChannel.id;
        this.channelType = whatsappApiChannel.channel_type;
        if (whatsappApiChannel?.channel?.webhook_url) {
          this.webhookUrl = whatsappApiChannel?.channel?.webhook_url;
          this.webhookToken = whatsappApiChannel?.channel?.webhook_token;
          this.showWebhookDetails = true;
          this.mix_panel_clicked_create_inbox_successful(
            'WhatsApp',
            this.channelType
          );
        } else {
          this.showAlert(
            this.$t('INBOX_MGMT.ADD.WHATSAPP_API.API.WEBHOOK_ERROR_MESSAGE'),
            'error'
          );
        }
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.WHATSAPP_API.API.ERROR_MESSAGE'),
          'error'
        );
      }
    },
    redirectToAddAgents() {
      const {
        id: inbox_id,
        channelType: channel_type,
        inboxName: channel_name,
      } = this;

      router.replace({
        name: 'settings_inboxes_add_agents',
        params: {
          page: 'new',
          inbox_id,
        },
        query: { channel_type, channel_name },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.form-label {
  padding: $zero $space-small $space-one;
  color: $neutral-grey-800;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: 22px;
}

.content-margin {
  margin-bottom: 2.4rem;
}

.medium-12 {
  .button {
    float: right;
  }
}
</style>
