import axios from 'axios';

const waWidgetServerAPIEndpoint = 'https://app-whatsapp-widget.limechat.ai/api';

const axiosInstance = axios.create({
  baseURL: waWidgetServerAPIEndpoint,
});

export const setAccessToken = accessToken => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export default axiosInstance;
