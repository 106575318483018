<template>
  <modal :show.sync="show" :on-close="onClose" :close-on-backdrop-click="false">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('INTEGRATION_SETTINGS.WEBHOOK.ADD.TITLE')"
        :header-content="$t('INTEGRATION_SETTINGS.WEBHOOK.ADD.DESC')"
      />
      <form class="row settings-form" @submit.prevent="addWebhook()">
        <woot-input
          v-model.trim="endPoint"
          :class="{ error: $v.endPoint.$error }"
          class="medium-12 columns content-margin"
          :label="$t('INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.END_POINT.LABEL')"
          :help-text="
            $t('INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.END_POINT.ERROR')
          "
          :placeholder="
            $t('INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.END_POINT.PLACEHOLDER')
          "
          @input="$v.endPoint.$touch"
        />

        <div class="medium-12 content-margin">
          <label class="form-label">
            {{ $t('INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.INBOXES.LABEL') }}
          </label>
          <div
            v-if="selectedInboxes.length"
            class="medium-12 agent-container mg-bottom--small"
          >
            <woot-chips
              v-for="(ibx, index) in selectedInboxes"
              :key="index"
              :title="ibx.name"
              variant="primary-small"
              :show-cancel-icon="true"
              @click="onRemoveInbox(index)"
            />
          </div>
          <woot-single-selector
            size="large"
            :hide-on-select="false"
            :option-list="inactiveInboxes"
            :custom-style="{ width: '100%' }"
            :default-option="
              $t('INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.INBOXES.PLACEHOLDER')
            "
            @click="onSelectInbox"
          />

          <span v-if="$v.selectedInboxes.$error" class="desc-text capital_c3">
            {{ $t('INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.INBOXES.ERROR') }}
          </span>
        </div>

        <div class="medium-12 content-margin">
          <label class="form-label">
            {{
              $t('INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.SUBSCRIPTIONS.LABEL')
            }}
          </label>
          <div
            v-if="selectedSubscriptions.length"
            class="medium-12 agent-container mg-bottom--small"
          >
            <woot-chips
              v-for="(sub, index) in selectedSubscriptions"
              :key="index"
              :title="sub"
              variant="primary-small"
              :show-cancel-icon="true"
              @click="onRemoveSub(index)"
            />
          </div>
          <woot-single-selector
            size="large"
            :hide-on-select="false"
            :option-list="inactiveSubscriptions"
            :custom-style="{ width: '100%' }"
            :default-option="
              $t(
                'INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.SUBSCRIPTIONS.PLACEHOLDER'
              )
            "
            @click="onSubSelect"
          />

          <span
            v-if="$v.selectedSubscriptions.$error"
            class="desc-text capital_c3"
          >
            {{
              $t('INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.SUBSCRIPTIONS.ERROR')
            }}
          </span>
        </div>

        <div v-if="showMessageType" class="medium-12 content-margin">
          <label class="form-label">
            {{
              $t('INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.MESSAGE_TYPES.LABEL')
            }}
          </label>
          <div
            v-if="selectedMessageTypes.length"
            class="medium-12 agent-container mg-bottom--small"
          >
            <woot-chips
              v-for="(sub, index) in selectedMessageTypes"
              :key="index"
              :title="sub"
              variant="primary-small"
              :show-cancel-icon="true"
              @click="onRemoveMessageType(index)"
            />
          </div>
          <woot-single-selector
            size="large"
            :hide-on-select="false"
            :option-list="inactiveMessageTypes"
            :custom-style="{ width: '100%' }"
            :default-option="
              $t(
                'INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.MESSAGE_TYPES.PLACEHOLDER'
              )
            "
            @click="onMessageTypeSelect"
          />
        </div>

        <div class="modal-footer">
          <button class="button clear red" @click.prevent="onClose">
            {{ $t('INTEGRATION_SETTINGS.WEBHOOK.ADD.CANCEL') }}
          </button>
          <woot-primary-button
            :disabled="
              $v.endPoint.$invalid ||
                addWebHook.showLoading ||
                $v.selectedSubscriptions.$invalid ||
                $v.selectedInboxes.$invalid
            "
            :name="$t('INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.SUBMIT')"
            :loading="addWebHook.showLoading"
          />
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, url, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { messageTypes } from 'shared/helpers/MessageTypeHelper';
import { SUBSCRIPTIONS } from './contstant';
import alertMixin from 'shared/mixins/alertMixin';
import Modal from 'dashboard/components/Modal';

const notEmpty = value => value.length > 0;

export default {
  components: {
    Modal,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      endPoint: '',
      selectedSubscriptions: [],
      selectedMessageTypes: [],
      selectedInboxes: [],
      addWebHook: {
        showAlert: false,
        showLoading: false,
        message: '',
      },
      show: true,
    };
  },
  validations: {
    endPoint: {
      required,
      minLength: minLength(7),
      url,
    },
    selectedSubscriptions: {
      notEmpty,
    },
    selectedInboxes: {
      notEmpty,
    },
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
    }),
    showMessageType() {
      return (
        this.selectedSubscriptions.length &&
        (this.selectedSubscriptions.includes('message_created') ||
          this.selectedSubscriptions.includes('message_updated'))
      );
    },
    inactiveSubscriptions() {
      let inactiveSubs = SUBSCRIPTIONS.filter(
        sub => !this.selectedSubscriptions.includes(sub)
      );

      return inactiveSubs.map((ele, index) => {
        return {
          id: index,
          name: ele,
        };
      });
    },
    inactiveMessageTypes() {
      let inactiveSubs = messageTypes.filter(
        sub => !this.selectedMessageTypes.includes(sub)
      );

      return inactiveSubs.map((ele, index) => {
        return {
          id: index,
          name: ele,
        };
      });
    },
    inactiveInboxes() {
      let selectedInboxIds = this.selectedInboxes.map(ibx => ibx.id);
      let inactiveInboxes = this.inboxes.filter(
        ibx => !selectedInboxIds.includes(ibx.id)
      );

      return inactiveInboxes.map((ele, index) => {
        return {
          id: index,
          name: ele.name,
          value: ele.id,
        };
      });
    },
  },
  methods: {
    onSubSelect(val) {
      this.selectedSubscriptions.push(val.name);
    },
    onRemoveSub(index) {
      this.selectedSubscriptions.splice(index, 1);
    },
    onMessageTypeSelect(val) {
      this.selectedMessageTypes.push(val.name);
    },
    onRemoveMessageType(index) {
      this.selectedMessageTypes.splice(index, 1);
    },
    onSelectInbox(val) {
      this.selectedInboxes.push(val);
    },
    onRemoveInbox(index) {
      this.selectedInboxes.splice(index, 1);
    },
    resetForm() {
      this.endPoint = '';
      this.selectedMessageTypes = [];
      this.selectedSubscriptions = [];
      this.selectedInboxes = [];
      this.$v.endPoint.$reset();
    },
    async addWebhook() {
      this.addWebHook.showLoading = true;

      try {
        await this.$store.dispatch('webhooks/create', {
          webhook: {
            url: this.endPoint,
            subscriptions: this.selectedSubscriptions,
            message_types: this.selectedMessageTypes,
            inbox_ids: this.selectedInboxes.map(ibx => ibx.value),
          },
        });
        this.addWebHook.showLoading = false;
        this.addWebHook.message = this.$t(
          'INTEGRATION_SETTINGS.WEBHOOK.ADD.API.SUCCESS_MESSAGE'
        );
        this.showAlert(this.addWebHook.message, 'success');
        this.resetForm();
        this.onClose();
      } catch (error) {
        this.addWebHook.showLoading = false;
        this.addWebHook.message =
          error.response.data.message ||
          this.$t('INTEGRATION_SETTINGS.WEBHOOK.ADD.API.ERROR_MESSAGE');
        this.showAlert(this.addWebHook.message, 'error');
      }
    },
  },
};
</script>
