/* global axios */
import ApiClient from 'dashboard/api/ApiClient';

class IntelligenceApi extends ApiClient {
  constructor() {
    super('intelligence');
  }

  improveTextWithGpt(payload) {
    return axios.get(`${this.url}/improve_tone`, {
      params: payload,
    });
  }
}

export default new IntelligenceApi();
