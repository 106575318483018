import AuthWrapper from './AuthWrapper';
import ErrorPage from '../ErrorPage';
import InstallShopifyApp from './InstallShopifyApp';

import { frontendURL } from '../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('auth'),
      name: 'auth',
      component: AuthWrapper,
      children: [
        {
          path: 'install-shopify-app',
          name: 'install_shopify',
          component: InstallShopifyApp,
        },
        {
          path: 'error',
          name: 'auth_error',
          component: ErrorPage,
        },
      ],
    },
  ],
};
