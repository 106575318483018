<template>
  <div class="footer-section flex-row flex-justify--between">
    <woot-base-button @click="$emit('submitFieldForm')">
      {{ editView ? 'SAVE' : 'CREATE' }}
    </woot-base-button>
    <woot-base-button
      variant="secondary-danger"
      @click="$router.push({ name: 'settings_custom_ticket_fields' })"
    >
      DISCARD
    </woot-base-button>
  </div>
</template>

<script>
export default {
  props: {
    editView: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
