<template>
  <woot-modal
    class="onboarding-modal"
    :show.sync="showPopup"
    :show-close="!isWelcomeScreen"
    :on-close="onClose"
  >
    <header :style="headerHeight" class="modal-header">
      <img
        v-if="isWelcomeScreen"
        :src="require('assets/images/onboarding_welcome.svg')"
        class="image"
        alt="welcome-illustration"
      />
      <img
        v-else-if="isConfigurationErrorScreen"
        :src="require('assets/images/configuration_error.svg')"
        class="image"
        alt="welcome-illustration"
      />
      <img
        v-else
        :src="require('assets/images/three_sixty_integration_visual.svg')"
        class="image"
        alt="welcome-illustration"
      />
    </header>
    <main class="modal-content">
      <component :is="currentComponent" :inbox="inbox" />
    </main>
  </woot-modal>
</template>
<script>
import { mapGetters } from 'vuex';

import WelcomeScreen from './WelcomeScreen';
import EducateUser from './EducateUser';
import Create360Inbox from './Create360Inbox';
import ThreeSixtyConnectButton from './ThreeSixtyConnectButton';
import SetupStatus from './SetupStatus';
import WabaIntegrationComplete from './WabaIntegrationComplete';
import ConfigurationError from './ConfigurationError';
import FinishSetup from './FinishSetup';

import { ONBOARDING_SCREENS } from 'dashboard/constants';

export default {
  components: {
    WelcomeScreen,
    EducateUser,
    Create360Inbox,
    ThreeSixtyConnectButton,
    SetupStatus,
    WabaIntegrationComplete,
    ConfigurationError,
    FinishSetup,
  },
  props: {
    screen: {
      type: String,
      default: '',
    },
    inbox: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      showPopup: true,
      currentScreen: '',
    };
  },
  computed: {
    ...mapGetters({
      inProgressThreeSixtyInboxes: 'inboxes/getInProgressThreeSixtyInboxes',
    }),
    currentComputedScreen() {
      return this.currentScreen || this.screen;
    },
    isWelcomeScreen() {
      return this.currentComputedScreen === ONBOARDING_SCREENS.WELCOME.key;
    },
    isConfigurationErrorScreen() {
      return (
        this.currentComputedScreen ===
        ONBOARDING_SCREENS.CONFIGURATION_ERROR.key
      );
    },
    headerHeight() {
      if (
        this.currentComputedScreen === ONBOARDING_SCREENS.WA_FORM.key ||
        this.currentComputedScreen === ONBOARDING_SCREENS.EDUCATE_USER.key
      )
        return { height: '25vh' };
      return { height: '52vh' };
    },
    currentComponent() {
      return ONBOARDING_SCREENS[this.currentComputedScreen].component;
    },
  },
  provide() {
    return { navigateTo: this.navigateTo, onCloseModal: this.onClose };
  },
  methods: {
    onClose() {
      if (this.isWelcomeScreen) return;
      this.$emit('closeModal');
    },
    navigateTo(screen) {
      this.currentScreen = screen;
      this.$emit('updateCurrentScreen', screen);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.onboarding-modal {
  backdrop-filter: blur(5px);
}

.modal-header {
  background: $neutral-grey-500;
  max-height: 42.6rem;

  .image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.modal-content {
  padding: $space-large $space-larger;

  ::v-deep .highlighted {
    color: $pg-1;
  }
}
</style>
