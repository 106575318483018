<template>
  <base-integration
    ref="baseIntegration"
    :integration-fields="integrationFields"
    :form-length="formLength"
    integration-type="crm"
    @catchInput="integrationConnect"
  />
</template>

<script>
import BaseIntegration from './BaseIntegration';
export default {
  components: {
    BaseIntegration,
  },
  computed: {
    integrationFields() {
      return this.$t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.KAPTURE');
    },
    formLength() {
      return this.integrationFields.FORM.length;
    },
  },
  mounted() {
    this.$refs.baseIntegration.getConnectionDetails();
  },
  methods: {
    integrationConnect(model) {
      const data = {
        domain: model[0],
        pull_api_key: model[1],
        push_api_key: model[2],
        get_ticket_api_key: model[3],
        update_ticket_api_key: model[4],
      };
      this.$refs.baseIntegration.createConnection(data);
    },
  },
};
</script>
