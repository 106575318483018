var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('woot-single-selector',{attrs:{"size":"medium","front-icon":_vm.iconName,"custom-style":{
    left: 0,
    top: 0,
    width: '18rem',
  },"button-style":{
    color: '#808975',
    padding: '0.4rem 0.6rem',
  },"show-selected-text":false,"default-option":_vm.selectedFilterComputed,"option-list":_vm.filterOptions,"highlight-selected":""},on:{"click":_vm.onSelectType}})}
var staticRenderFns = []

export { render, staticRenderFns }