export const debounce = (func, wait) => {
  let timeout;
  // eslint-disable-next-line func-names
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};

export const retryUntilSuccess = (fn, retryCount = 5, interval = 100) => {
  const attempt = count => {
    fn();
    if (count > 0) {
      setTimeout(() => {
        attempt(count - 1);
      }, interval);
    }
  };
  attempt(retryCount);
};
