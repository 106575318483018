<template>
  <div class="scroll--panel custom-scroll">
    <span v-if="search" class="body-b3 text-light">
      {{ uiFlags.fetchingList ? 'Searching' : 'Search' }} by
      {{ defaultSelectedParam.name }}
    </span>
    <search-box
      :placeholder="'Search by ' + defaultSelectedParam.name"
      :value="search"
      :variant="true"
      :on-search="searchOrders"
      @setSearch="setSearch"
    >
      <woot-single-selector
        variant="tertiary"
        size="large"
        :default-option="defaultSelectedParam.name"
        :custom-button="true"
        :option-list="searchParams"
        :custom-style="{ left: 'calc(100% - 13.6rem)' }"
        :top-position="{ top: '3.3rem' }"
        @click="setSearchParam"
      >
        <div class="dropdown-button">
          <span class="flex-row">
            <icons name="chevronDown" size="medium" color="green" />
          </span>
        </div>
      </woot-single-selector>
    </search-box>
    <div v-if="!uiFlags.fetchingList && !records.length" class="no-order">
      <img
        :src="require('dashboard/assets/images/noOrder.svg')"
        alt="No Order"
      />
      <p class="no-items-error-message">
        No orders found.
      </p>
    </div>
    <woot-loading-state
      v-if="uiFlags.fetchingList"
      :message="'Fetching orders...'"
    />
    <div v-if="!uiFlags.fetchingList && records.length">
      <accordian-card
        v-for="(order, index) in records"
        :key="order.id"
        :name="`Order ${order.order_id}`"
        color="blue"
        :always-open="index === 0"
      >
        <template v-slot:close>
          <div class="close-button-container">
            <span
              v-if="order.fulfillment_status && order.fulfillment_status.name"
            >
              Fulfilled
            </span>
            <span v-if="order.financial_status === 'paid'">
              Paid
            </span>
          </div>
        </template>
        <table>
          <tbody>
            <tr v-if="order.last_name || order.first_nam">
              <td>Invoice Name :</td>
              <td>{{ `${order.first_name} ${order.last_name}` }}</td>
            </tr>
            <tr v-if="order.purchase_datetime">
              <td>Placed On :</td>
              <td>{{ formatDate(order.purchase_datetime) }}</td>
            </tr>
            <tr v-if="order.shipping_address">
              <td>
                Delivery Address :
              </td>
              <td>{{ getShippingAddress(order) }}</td>
            </tr>
            <tr v-if="order.shipping_address">
              <td>Pincode :</td>
              <td>{{ order.shipping_address.zip }}</td>
            </tr>
          </tbody>
        </table>
        <order-panel-item
          :order="order.items"
          :shipping-charges="order.shipping_charges"
          :amount="order.amount"
          :sub-total="order.subtotal_price"
        />
        <div
          v-if="
            order.fulfillment_status && order.fulfillment_status.tracking_info
          "
          class="tracking-container"
        >
          <span style="margin-bottom: 0.8rem">Tracking:</span>
          <span
            v-html="
              order.fulfillment_status.tracking_info[0].url
                ? formatMessage(order.fulfillment_status.tracking_info[0].url)
                : '-'
            "
          />
        </div>
        <div class="form-button">
          <woot-secondary-button
            size="small"
            :name="$t('PRODUCTS_MGMT.SHARE_ORDER')"
            :full-width="true"
            :custom-style="{ width: '60%', 'justify-content': 'center' }"
            @click="openSharePopup(order)"
          />
        </div>
      </accordian-card>
    </div>
    <p
      v-if="!uiFlags.fetchingList && records.length && hasNextPage"
      class="clear button load-more-conversations"
      @click="loadMoreOrders"
      v-text="'Load More Orders'"
    />
    <p
      v-if="!uiFlags.fetchingList && records.length && !hasNextPage"
      class="text-center text-muted end-of-list-text"
      v-text="'All Orders Loaded'"
    />
    <woot-delete-modal
      :show.sync="showShareModal"
      :on-close="closeSharePopup"
      :on-confirm="shareOrder"
      :title="'Share Order'"
      :message="shareMessage"
      :confirm-text="'Share'"
      :reject-text="'Cancel'"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccordianCard from 'dashboard/components/AccordianCard';
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import OrderPanelItem from './OrderPanelItem';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import * as types from 'shared/constants/googleEventType';

export default {
  components: {
    AccordianCard,
    SearchBox,
    OrderPanelItem,
  },
  mixins: [messageFormatterMixin, googleAnalyticsMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      search: '',
      showShareModal: false,
      selectedOrder: {},
      selectedParam: null,
    };
  },
  computed: {
    ...mapGetters({
      records: 'getOrders',
      meta: 'getOrdersMeta',
      uiFlags: 'getOrdersUIFlags',
      currentChat: 'getSelectedChat',
    }),
    hasNextPage() {
      return this.meta.hasNext;
    },
    shareMessage() {
      return `Share Order "${this.selectedOrder.order_id}" with ${this.currentChat.meta?.sender?.name}`;
    },
    defaultSelectedParam() {
      return this.selectedParam || this.searchParams[0];
    },
    searchParams() {
      return [
        { id: 'phone', name: 'Phone' },
        { id: 'email', name: 'Email' },
        { id: 'order_id', name: 'Order id' },
      ];
    },
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
  },
  watch: {
    'contact.id'(n, o) {
      if (n !== o) this.searchOrders();
    },
    currentChat(newChat, oldChat) {
      if (newChat.id === oldChat.id) return;

      this.$store.dispatch('resetOrders');
    },
  },
  created() {
    this.searchOrders();
  },
  methods: {
    setSearch(value) {
      const refetchAllproducts = !!this.search && value === '';
      if (refetchAllproducts)
        this.$store.dispatch('getOrders', {
          contactId: this.contact.id,
          cursor: this.meta.cursor,
        });

      this.search = value;
    },
    searchOrders() {
      if (this.search) {
        this.mix_panel_searched_ticket_order();
        this.googleAnalyticsEvent(
          types.default.ORDERS_SEARCH,
          types.default.ORDERS_PANEL,
          this.search
        );

        this.$store.dispatch('getOrders', {
          search: true,
          contactId: this.contact.id,
          query: this.search,
          type: this.defaultSelectedParam.id,
          cursor: this.meta.cursor,
        });
      } else {
        if (!this.contact.id) return;
        this.$store.dispatch('getOrders', {
          contactId: this.contact.id,
          cursor: this.meta.cursor,
        });
      }
    },
    setSearchParam(val) {
      this.mix_panel_selected_ticket_order_filter(val.name);
      this.selectedParam = val;
    },
    loadMoreOrders() {
      if (this.search) {
        this.$store.dispatch('getOrders', {
          append: true,
          search: true,
          contactId: this.contact.id,
          query: this.search,
          type: this.defaultSelectedParam.id,
          cursor: this.meta.cursor,
        });
      } else {
        this.$store.dispatch('getOrders', {
          append: true,
          contactId: this.contact.id,
          cursor: this.meta.cursor,
        });
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    getShippingAddress(order) {
      if (order.shipping_address) {
        return `${order.shipping_address?.address1} ${
          order.shipping_address.address2 ? order.shipping_address.address2 : ''
        }, ${order.shipping_address?.city}, ${
          order.shipping_address.province ? order.shipping_address.province : ''
        }`;
      }
      return '';
    },
    orderItemsMessagePayload(order) {
      let message = ``;
      order.items.forEach((item, index) => {
        message += `\n${index + 1}. ${item.name} (Price: ${
          item.price
        }, Quantity: ${item.quantity})`;
      });
      return message;
    },
    invoiceName(order) {
      return order.first_name
        ? `*Invoice Name:* ${this.selectedOrder.first_name} ${this.selectedOrder.last_name}`
        : '';
    },
    async shareOrder() {
      try {
        let desc = '';
        if (
          this.selectedOrder.product_link !== null &&
          this.selectedOrder.fulfillment_status &&
          Object.keys(this.selectedOrder.fulfillment_status).length !== 0 &&
          Object.getPrototypeOf(this.selectedOrder.fulfillment_status) !==
            null &&
          this.selectedOrder.fulfillment_status.tracking_info[0].url
        ) {
          desc += `Tracking URL:\n${this.selectedOrder.fulfillment_status.tracking_info[0].url}`;
        }

        let orderId = '';
        if (this.selectedOrder.order_id) {
          orderId = `*Order ID*: ${this.selectedOrder.order_id}`;
        }
        const messagePayload = {
          conversationId: this.currentChat.id,
          message: `*Order Details*
          \n${orderId}
          \n\n${this.invoiceName(this.selectedOrder)}
          \n*Placed On:* ${this.formatDate(
            this.selectedOrder.purchase_datetime
          )}  \n*Delivery Address:* ${this.getShippingAddress(
            this.selectedOrder
          )}  \n*Pin Code:* ${
            this.selectedOrder.shipping_address.zip
          }  \n*Amount:* ${this.selectedOrder.amount}  \n\n${desc}
          \n\n*Items:* ${this.orderItemsMessagePayload(this.selectedOrder)}`,
        };

        await this.$store.dispatch('sendMessage', messagePayload);
        bus.$emit('scrollToMessage');
      } catch (error) {
        // handle error
      }
      this.showShareModal = false;
    },
    closeSharePopup() {
      this.showShareModal = false;
    },
    openSharePopup(order) {
      this.selectedOrder = order;
      this.showShareModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.order-date {
  color: $neutral-grey-800;
  font-size: $font-size-mini;
  line-height: 2;

  .order-date-text {
    padding-left: $space-smaller;
    color: $neutral-grey-600;
  }
}
.close-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: $space-normal;

  span {
    background: $pg-light-1;
    border-radius: $border-radius;
    padding: $space-smaller $space-small;
    font-size: $font-size-small;
    line-height: 2.2rem;
    color: $neutral-grey-800;

    &:first-child {
      margin-right: $space-small;
    }
  }
}
table {
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
  margin-top: $space-normal;
  margin-bottom: $space-normal;
}
table tr {
  border-bottom: none;
}
table td {
  padding: $zero;
  padding-bottom: $space-smaller;
  font-size: $font-size-small;
  line-height: 2.2rem;
  color: $neutral-grey-800;
  vertical-align: baseline;

  &:first-child {
    color: $neutral-grey-600;
    white-space: nowrap;
    padding-right: $space-smaller;
  }
}
.tracking-container {
  display: flex;
  flex-direction: column;
  font-size: $font-size-small;
  line-height: 2.2rem;
  color: $neutral-grey-600;
}
.tracking-container ::v-deep a {
  word-break: break-all;
  color: $secondary-blue;

  &:hover {
    text-decoration: underline;
  }
}
.form-button {
  margin-top: $space-medium;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-order {
  margin-top: 10rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 12rem;
    margin-bottom: $space-large;
  }

  p {
    color: $secondary-blue;
    font-size: $font-size-default;
    line-height: $space-medium;
  }
}
.load-more-conversations {
  border: 0;
  color: $pg-1-500;
  font-size: $font-size-small;
  margin: 0;
  padding: $space-normal;
  width: 100%;
}
.end-of-list-text {
  padding: $space-normal;
}
.loading-state {
  padding: $zero;
}

.agent-dropdown-button {
  display: flex;
  align-items: center;
  width: calc(100% - 3.2rem);
  padding: $space-small;
  border: 1px solid $neutral-grey-600;
  border-radius: $border-radius-smaller;

  .dropdown-option {
    width: 100%;
    font-size: $font-size-small;
    font-weight: $font-weight-black;
    line-height: $height-h5;
    color: $text-dark;
    text-transform: uppercase;
    margin-left: $space-small;
  }
}
</style>
