<template>
  <div v-if="inbox.id">
    <div :style="{ background: shadeColor }" class="inbox-viewer">
      <div
        v-if="inbox.id"
        class="flex-row flex-justify mg-top--small mg-auto gap--slab"
      >
        <span v-if="isWhatsappChannel">
          <img
            width="48"
            :src="require('dashboard/assets/images/channels/whatsapp.svg')"
          />
        </span>
        <span v-if="isAKnowlarityInbox">
          <img
            width="48"
            :src="
              require('dashboard/assets/images/channels/voice_channels.svg')
            "
          />
        </span>
        <span v-if="isWhatsappChannel || isAKnowlarityInbox">
          <icons name="link" color="green" size="medium" :show-title="false" />
        </span>
        <span>
          <img width="48" :src="imageSrc(channelImageIdentifier)" />
        </span>
      </div>
      <span
        v-if="inbox.name"
        class="inbox-name text-dark line-clamp-2 title-h3"
        v-text="inbox.name"
      />
    </div>
    <div class="search-container flex-row flex-justify--between gap--slab">
      <search-box
        placeholder="Search for Collaborator/Status"
        custom-class="search-box"
        :value="search"
        @setSearch="setSearch"
      />
      <woot-base-button
        tag="span"
        variant="secondary"
        :disabled="inPendingPaymentForNonShopify && !isEnterprise"
        @click="() => (showAgentPopup = true)"
      >
        <icons
          name="plus"
          color="green"
          size="medium"
          view="-2 -2 24 24"
          :show-title="false"
        />
      </woot-base-button>
    </div>
    <div
      class="flex-column"
      :style="{
        'flex-direction': isAddCollaboratorsPanelOpen
          ? 'column-reverse'
          : 'column',
      }"
    >
      <div class="tables-container custom-scroll">
        <collaborators-table
          :search="search"
          :members="computedMembers"
          :inbox="inbox"
          :is-loading="isLoading || uiFlags.isFetching"
          :custom-table-title="
            isAFacebookInbox && !isAddCollaboratorsPanelOpen
              ? 'Added for DMs'
              : ''
          "
          :categorise-by="
            isAFacebookInbox && !isAddCollaboratorsPanelOpen
              ? 'inbox_type'
              : 'role'
          "
          :action="!isAddCollaboratorsPanelOpen ? 'remove' : 'add'"
        />
        <collaborators-table
          v-if="isAFacebookInbox && !isAddCollaboratorsPanelOpen"
          :search="search"
          :members="computedMembersForComments"
          :inbox="inbox"
          :is-loading="isLoading || uiFlags.isFetching"
          custom-table-title="Added for Comments"
          action="remove"
          categorise-by="inbox_type"
        />
      </div>
      <div
        v-if="isAFacebookInbox && isAddCollaboratorsPanelOpen"
        class="member-type-switch flex-row flex-justify--between gap--small full-width"
      >
        <woot-base-button
          :variant="showCommentCollaborators ? 'primary' : 'secondary'"
          size="small"
          full-width
          @click="showCommentCollaborators = true"
        >
          Comments
        </woot-base-button>
        <woot-base-button
          :variant="showCommentCollaborators ? 'secondary' : 'primary'"
          size="small"
          full-width
          @click="showCommentCollaborators = false"
        >
          Direct Messages
        </woot-base-button>
      </div>
      <woot-base-button
        v-if="showAddCollaboratorsPanel"
        class="custom-button flex-row flex-justify--between"
        :class="{ 'position-absolute': !isAddCollaboratorsPanelOpen }"
        full-width
        :back-icon="isAddCollaboratorsPanelOpen ? 'chevronDown' : 'chevronTop'"
        size="large"
        @click="toggleAddCollaborators"
      >
        Add Collaborators
      </woot-base-button>
    </div>
    <add-edit-agent
      v-if="showAgentPopup"
      :show="showAgentPopup"
      :agent-data="{}"
      :inbox-list="populateInboxData"
      @onClose="() => (showAgentPopup = false)"
    />
  </div>
  <educational-onboarding v-else :show-case-data="showCaseData" />
</template>
<script>
import { mapGetters } from 'vuex';

import CollaboratorsTable from './CollaboratorsTable';
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import AddEditAgent from 'dashboard/routes/dashboard/settings/agents/AddEditAgent';
import EducationalOnboarding from 'dashboard/components/ui/settings/EducationalOnboarding';
import inboxMixin from 'shared/mixins/inboxMixin';
import inboxBannerMixin from 'shared/mixins/inboxBannerMixin';
import alertMixin from 'shared/mixins/alertMixin';
import billingMixin from 'dashboard/mixins/billing';

import { SHOWCASE_INBOX_DATA } from 'dashboard/routes/dashboard/settings/inbox/constants';

export default {
  components: {
    SearchBox,
    AddEditAgent,
    CollaboratorsTable,
    EducationalOnboarding,
  },
  mixins: [alertMixin, billingMixin, inboxBannerMixin, inboxMixin],
  props: {
    inbox: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currIndex: 0,
      search: '',
      showCaseData: SHOWCASE_INBOX_DATA,
      inboxMembers: [],
      inboxMembersForComments: [],
      tableColumns: [
        {
          prop: 'avatar',
          component: 'AgentAvatar',
          width: '10%',
        },
        {
          prop: 'name',
        },
        {
          prop: 'availability_status',
          component: 'InboxMemberAction',
        },
      ],
      isLoading: false,
      showAgentPopup: false,
      isAddCollaboratorsPanelOpen: false,
      showAddCollaboratorsPanel: false,
      showCommentCollaborators: false,
    };
  },
  provide() {
    return { updateInboxMembers: this.updateInboxMembers };
  },
  computed: {
    ...mapGetters({
      records: 'agents/getAgents',
      uiFlags: 'agents/getUIFlags',
    }),
    showCaseDatum() {
      return this.showcaseData[this.currIndex];
    },
    computedMembers() {
      if (!this.isAddCollaboratorsPanelOpen) return this.inboxMembers;

      let selectedInboxMembers = this.showCommentCollaborators
        ? this.inboxMembersForComments
        : this.inboxMembers;

      return this.records.filter(agent => {
        return selectedInboxMembers.every(member => {
          return member.id !== agent.id;
        });
      });
    },
    computedMembersForComments() {
      return this.inboxMembersForComments;
    },
    populateInboxData() {
      const { inbox: data } = this;

      if (!data.id) return [];

      return [
        { id: data.id, name: data.name, channel_type: data.channel_type },
      ];
    },
  },
  watch: {
    inbox(n) {
      this.showCommentCollaborators = false;
      this.inboxMembers = [];
      this.inboxMembersForComments = [];

      if (Object.keys(n).length) {
        this.showAddCollaboratorsPanel = true;
        this.fetchAttachedAgents();
      } else this.showAddCollaboratorsPanel = false;
    },
  },
  methods: {
    setSearch(value) {
      this.search = value;
    },
    tableTitle(category) {
      let status = '';
      if (this.isAddCollaboratorsPanelOpen) status = 'available';
      else status = 'added';

      return category + 's ' + status;
    },
    async updateInboxMembers({ member, action, inboxType }) {
      let updatedInboxMembers = [];

      if (this.isAddCollaboratorsPanelOpen)
        inboxType = this.showCommentCollaborators ? 'comment' : 'message';

      updatedInboxMembers =
        inboxType === 'comment'
          ? [...this.inboxMembersForComments]
          : [...this.inboxMembers];

      if (action === 'ADD')
        updatedInboxMembers.push({ ...member, inbox_type: inboxType });

      if (action === 'REMOVE')
        updatedInboxMembers = updatedInboxMembers.filter(
          m => m.id !== member.id
        );

      try {
        await this.$store.dispatch('inboxMembers/create', {
          inboxId: this.inbox.id,
          agentList: updatedInboxMembers.map(el => el.id),
          inboxType,
        });

        if (inboxType === 'comment')
          this.inboxMembersForComments = updatedInboxMembers;
        else this.inboxMembers = updatedInboxMembers;

        let role = member.role;
        role = role.charAt(0).toUpperCase() + role.slice(1);

        this.showAlert(
          action === 'REMOVE'
            ? `${role} removed from inbox`
            : `${role} added to inbox`,
          'success'
        );
      } catch (error) {
        this.showAlert('Some error occurred', 'error');
      }
    },
    async fetchAttachedAgents() {
      this.isLoading = true;

      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.inbox.id,
        });

        this.isLoading = false;

        let {
          data: { payload: inboxMembers },
        } = response;

        this.inboxMembers = inboxMembers.filter(
          item => item.inbox_type === 'message'
        );

        this.inboxMembersForComments = inboxMembers.filter(
          item => item.inbox_type === 'comment'
        );
      } catch (error) {
        this.isLoading = false;
        this.showAlert('Error fetching inbox collaborators', 'error');
      }
    },
    onAddAgent() {
      this.fetchAttachedAgents();
    },
    toggleAddCollaborators() {
      this.isAddCollaboratorsPanelOpen = !this.isAddCollaboratorsPanelOpen;
      if (this.isAddCollaboratorsPanelOpen) this.$store.dispatch('agents/get');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.reference-box {
  bottom: $space-two;
  position: absolute;
  width: 100%;
}

.profile-viewer {
  background: $facebook-blue;
  border-radius: $border-radius-smaller;
  height: $space-two * 10;
  transition: background-color 0.3s ease-out;
}

.inbox-viewer {
  background-color: $neutral-grey-500;
  height: 15.5rem;
  padding: $space-two $space-slab $space-two $space-two;
  position: relative;
  transition: all 0.8s ease;

  .inbox-name {
    position: absolute;
    bottom: $space-normal;
    left: $space-normal;
  }
}

.tables-container {
  border-top: 1px solid $neutral-grey-200;
  max-height: calc(100vh - 38rem);
  overflow: overlay;
  overflow-x: hidden;
}

.member-type-switch {
  padding: $space-small;
}

.search-container {
  margin: $space-normal $space-small;

  .search-box {
    flex-direction: row-reverse;
    margin-bottom: $zero;
    width: 82.6%;
  }
}

.custom-button {
  border-radius: $zero;
  font-size: $font-size-small;
  font-weight: $font-weight-black;
  height: 4.4rem;
  line-height: $height-h5;
  text-transform: capitalize;
}

.position-absolute {
  bottom: $zero;
  position: absolute;
}
</style>
