var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-box settings-box"},[_c('div',{staticClass:"settings-container gap--normal"},[_c('div',{staticClass:"custom-scroll lime-card-1dp"},[_c('page-header',{staticClass:"page-header",attrs:{"header-title":_vm.$t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.TITLE')},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('div',{staticClass:"crm-img-container"},[_c('div',{staticClass:"centered-image"},[_c('img',{attrs:{"src":'/dashboard/images/integrations/' +
                    _vm.$t(
                      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.THUMBNAIL'
                    )}})])])]},proxy:true}])},[_vm._v(" "),_c('reference-box',{attrs:{"to":_vm.$t('INTEGRATION_SETTINGS.GITBOOK_URL') + 'crm/freshdesk',"content":'Know more about ' +
              _vm.$t('INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.TITLE') +
              ' Integration',"type":"document"}})],1),_vm._v(" "),_c('div',{staticClass:"crm-integration flex-column"},[(_vm.isLoading)?_c('woot-loading-state',{attrs:{"message":"Fetching crm details"}}):_vm._e(),_vm._v(" "),_c('div',{class:_vm.isLoading ? 'disable-container' : ''},[_c('div',{staticClass:"crm-container"},[_c('div',{staticClass:"crm-body-container"},[_c('form',{staticClass:"flex-column-center",on:{"submit":function($event){$event.preventDefault();return _vm.crmConnect()}}},[_c('woot-input',{staticClass:"crm-input",class:{ error: _vm.$v.domain.$error },attrs:{"label":_vm.$t(
                      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.DOMAIN_LABEL'
                    ),"placeholder":_vm.$t(
                      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.DOMAIN_PLACEHOLDER'
                    )},on:{"input":_vm.$v.domain.$touch},model:{value:(_vm.domain),callback:function ($$v) {_vm.domain=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"domain"}}),_vm._v(" "),_c('woot-input',{staticClass:"crm-input",class:{ error: _vm.$v.widgetId.$error },attrs:{"label":_vm.$t(
                      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.WIDGET_ID_LABEL'
                    ),"placeholder":_vm.$t(
                      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.WIDGET_ID_PLACEHOLDER'
                    )},on:{"input":_vm.$v.widgetId.$touch},model:{value:(_vm.widgetId),callback:function ($$v) {_vm.widgetId=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"widgetId"}}),_vm._v(" "),_c('woot-input',{staticClass:"crm-input",attrs:{"label":_vm.$t(
                      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.API_KEY_LABEL'
                    ),"placeholder":_vm.$t(
                      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.API_KEY_PLACEHOLDER'
                    )},model:{value:(_vm.apiKey),callback:function ($$v) {_vm.apiKey=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"apiKey"}}),_vm._v(" "),_c('woot-input',{staticClass:"crm-input",attrs:{"label":_vm.$t(
                      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.PASSWORD_LABEL'
                    ),"placeholder":_vm.$t(
                      'INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.FORM.PASSWORD_PLACEHOLDER'
                    )},model:{value:(_vm.password),callback:function ($$v) {_vm.password=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"password"}}),_vm._v(" "),_c('woot-button',{staticClass:"button nice crm-button",attrs:{"type":"submit","disabled":_vm.$v.domain.$invalid ||
                      _vm.$v.widgetId.$invalid ||
                      _vm.$v.apiKey.$invalid ||
                      _vm.$v.password.$invalid}},[_vm._v("\n                  "+_vm._s(_vm.isLimekitCrmConnected
                      ? 'Update'
                      : _vm.$t('INTEGRATION_SETTINGS.CRM.CONNECT.BUTTON_TEXT'))+"\n                ")]),_vm._v(" "),_c('a',{staticClass:"steps limechat-store",attrs:{"href":"https://developers.freshdesk.com/widget-api/#introduction","target":"_blank"}},[_c('icons',{attrs:{"name":"rocketFilled","size":"medium","color":"green"}}),_vm._v(" "),_c('span',{staticClass:"text-margin"},[_vm._v("\n                    How to find out Freshdesk Widget ID?\n                  ")])],1)],1)])])])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }