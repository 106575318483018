<template>
  <modal
    :show.sync="show"
    :on-close="onClose"
    :show-close="true"
    :small-modal="true"
  >
    <div class="column content-box">
      <woot-modal-header :header-title="title" :header-content="description" />
      <div class="flex-column-center">
        <img :src="require('dashboard/assets/images/sent.gif')" alt="sent" />
        <span
          v-if="resentEmail"
          class="success-text"
          v-html="`<b>✓</b> ${$t('REGISTER.RESEND.SUCCESS_MESSAGE')}`"
        />
        <div class="resend-container">
          <span>{{ $t('REGISTER.RESEND.DESCRIPTION') }}</span>
          <button class="button clear" @click.prevent="resendConfirmation">
            {{ $t('REGISTER.RESEND.NAME') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from 'dashboard/components/Modal';

export default {
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    email: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      resentEmail: false,
    };
  },
  methods: {
    resendConfirmation() {
      this.resentEmail = true;
      this.$emit('resend');
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '~dashboard/assets/scss/variables';

.desc-text {
  margin-bottom: $space-normal;
}
img {
  width: 8rem;
  height: 8rem;
  margin-bottom: $space-normal;
}
.success-text {
  font-size: $font-size-small;
  line-height: 2.2rem;
  color: $secondary-blue;
  margin-bottom: $space-slab;
}
.resend-container {
  font-size: $font-size-small;
  color: $neutral-grey-800;
  display: flex;
  line-height: 2.2rem;
  margin-bottom: $space-medium;

  button {
    padding: $zero;
    margin-left: $space-smaller;
    margin-bottom: $zero;
  }
}
</style>
