import * as types from 'dashboard/store/mutation-types';

export default {
  [types.default.SET_SLA_UI_FLAGS]: ($state, newFlags) => {
    $state.uiFlags = { ...$state.uiFlags, ...newFlags };
  },
  [types.default.ADD_SLA]: ($state, sla) => {
    $state.slas.push(sla);
  },
  [types.default.SET_SLAS]: ($state, slas) => {
    $state.slas = slas;
  },
  [types.default.UPDATE_SLA]: ($state, updatedSLA) => {
    const index = $state.slas.findIndex(sla => sla.id === updatedSLA.id);
    if (index !== -1) {
      $state.slas.splice(index, 1, updatedSLA);
    }
  },
  [types.default.REMOVE_SLA]: ($state, id) => {
    $state.slas = $state.slas.filter(sla => sla.id !== id);
  },
};
