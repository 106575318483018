<template>
  <div class="row empty-state">
    <span class="title-h1 title text-dark mg-auto" v-text="title" />
    <p class="body-b2 text-light" v-text="message" />
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: '' },
    message: { type: String, default: '' },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.empty-state {
  text-align: center;

  p {
    margin: 0 auto;
    padding: $space-two $space-jumbo;
  }
}
</style>
