<template>
  <woot-base-button
    size="small"
    tag="span"
    :loading="isUpdating"
    style="margin-left: auto;"
    preserve-case
    @click.stop="onMarkAsRead"
  >
    {{ $t('NOTIFICATIONS_PAGE.MARK_NOTIFICATION_READ') }}
  </woot-base-button>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { isUpdating: false };
  },
  methods: {
    onMarkAsRead() {
      this.isUpdating = true;
      const { id, primary_actor_id } = this.notification;

      this.$store
        .dispatch('notifications/markAsRead', {
          notificationId: id,
          primaryActorId: primary_actor_id,
        })
        .then(() => {
          this.isUpdating = false;
        });
    },
  },
};
</script>
