/* global axios */
import Cookies from 'js-cookie';
import endPoints from './endPoints';
import { clearDataOnLogout } from '../store/utils/api';
import {
  clearShopifyAppDetailsFromLocalStorage,
  removeFromLocalStorage,
} from '../helper/localStorage';
import { getInstance } from '../auth0';
import { accountIdFromPathname, frontendURL } from '../helper/URLHelper';

const DEFAULT_LOGOUT_OPTIONS = {
  withAlert: false,
  alertTimeout: 100,
  alertMessage: 'Session expired. Please log in again.',
  withRedirect: true,
};

const showError = (error, duration) => {
  bus.$emit('newToastMessage', [error, 'error', duration]);
};

export default {
  logout(logoutOptions = {}) {
    const options = { ...DEFAULT_LOGOUT_OPTIONS, ...logoutOptions };
    const { withAlert, alertTimeout, alertMessage, withRedirect } = options;

    if (withAlert) showError(alertMessage, alertTimeout);

    const auth0 = getInstance();
    if (withRedirect && window.location.pathname.includes('/app/accounts/'))
      localStorage.setItem('redirectPath', window.location.pathname);

    setTimeout(() => {
      removeFromLocalStorage('draftMessages');
      clearShopifyAppDetailsFromLocalStorage();
      auth0.logout({
        logoutParams: {
          returnTo: `${window.location.origin}${frontendURL('login')}`,
        },
      });
      clearDataOnLogout();
    }, alertTimeout);
  },

  getPubSubToken() {
    const userData = Cookies.getJSON('user');

    if (!userData) {
      return null;
    }

    return userData.pubsub_token;
  },

  getAuthenticatedUser(currentPath) {
    const auth0 = getInstance();
    const auth0User = auth0.user || {};
    const user = this.getCurrentUser() || {};

    if (!auth0.isAuthenticated) {
      return { authenticated: false, hasAccounts: false, user: null };
    }

    const { account_id, email } = user;
    const { email: auth0Email } = auth0User;
    if (!email || !auth0Email || email !== auth0Email) {
      return { authenticated: true, hasAccounts: false, user: null };
    }

    if (!account_id || !user.accounts || !user.accounts.length) {
      return { authenticated: true, hasAccounts: false, user: null };
    }

    const accountId = accountIdFromPathname(currentPath) || account_id;
    const currentAccount = user.accounts.find(
      account => account.id === accountId
    );

    return {
      authenticated: true,
      hasAccounts: true,
      user: { ...user, role: currentAccount?.role || user.role },
    };
  },

  getCurrentUser() {
    const userData = Cookies.getJSON('user');

    if (!userData) {
      return null;
    }

    const userAccounts = JSON.parse(
      localStorage.getItem('userAccounts') || '[]'
    );

    return { ...userData, accounts: userAccounts };
  },

  profileUpdate(profileAttributes) {
    const formData = new FormData();
    Object.keys(profileAttributes).forEach(key => {
      const value = profileAttributes[key];
      if (value) {
        formData.append(`profile[${key}]`, value);
      }
    });

    return axios.put(endPoints('profile').url, formData);
  },

  resendConfirmation() {
    return axios.get(`${endPoints('profile').url}/resend_confirmation`);
  },

  updateUISettings({ uiSettings }) {
    return axios.put(endPoints('profile').url, {
      profile: { ui_settings: uiSettings },
    });
  },

  updateAvailability({ availability, account_id }) {
    return axios.put(endPoints('profile').url, {
      profile: { availability },
      account_id: account_id,
    });
  },

  markConversationViewAsDefault({ default_view_id, default_view_type }) {
    return axios.put(`${endPoints('profile').url}/mark_view_default`, {
      default_view_id,
      default_view_type,
    });
  },
};
