import * as types from '../../mutation-types';
import KnowlarityApi from '../../../api/knowlarity/knowlarity';

const actions = {
  getKnowlarityAgents: async ({ commit }, data) => {
    try {
      const response = await KnowlarityApi.getKnowlarityAgents(data);
      commit(types.default.SET_KNOWLARITY_AGENTS, response.data);
    } catch (error) {
      // handle error
      throw new Error(error);
    }
  },
  initiateKnowlarityOutboundCall: async ({ commit }, data) => {
    commit(types.default.SET_CALL_INITIATION_UI_FLAG, { initiatingCall: true });
    try {
      await KnowlarityApi.initiateKnowlarityOutboundCall(data);
      commit(types.default.SET_CALL_INFORMATION, data);
      commit(types.default.SET_CALL_INITIATION_UI_FLAG, {
        initiatingCall: false,
      });
    } catch (error) {
      // handle error
      commit(types.default.SET_CALL_INITIATION_UI_FLAG, {
        initiatingCall: false,
      });
      commit(types.default.CALL_FAILURE_UI_FLAG, {
        callFailure: true,
      });
      setTimeout(() => {
        commit(types.default.CALL_FAILURE_UI_FLAG, {
          callFailure: false,
        });
      }, 3000);
      throw new Error(error);
    }
  },
};

export default actions;
