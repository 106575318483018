<template>
  <div
    class="column padding-container settings-box custom-scroll"
    style="padding-top: 0px"
  >
    <div class="filter-pane flex-space-between">
      <div class="flex-container medium-flex-dir-row">
        <div class="mr-right">
          <report-date-range-selector
            :default-selection="0"
            @date-range-change="onDateRangeChange"
          />
        </div>
        <div class="mr-right">
          <woot-single-selector
            variant="secondary"
            size="medium"
            :option-list="timingList"
            :default-option="selectedTiming"
            :show-selected-text="false"
            :custom-style="{ width: 'auto' }"
            @click="onTimingChange"
          />
        </div>
        <div class="mr-right">
          <woot-single-selector
            variant="secondary"
            size="medium"
            :option-list="conversationTypeList"
            :default-option="selectedConversationType"
            :show-selected-text="false"
            @click="onConversationTypeChange"
          />
        </div>
        <div class="mr-right">
          <inbox-filter
            value="All Inboxes"
            :option-list="csatInboxes"
            :exclude-inbox-types="excludedInboxTypes"
            :toggle-filter="changeInboxId"
          />
        </div>
      </div>
    </div>
    <div class="column content-box settings-box">
      <div class="settings-container columns custom-scroll">
        <csat-metrics />
        <csat-table
          :page-index="pageIndex"
          :show-inbox-column="selectedInboxes.length === 0"
          @page-change="onPageNumberChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import CsatMetrics from '../../components/CsatMetrics';
import CsatTable from '../../components/CsatTable';
import ReportDateRangeSelector from '../../components/DateRangeSelector';
import InboxFilter from 'dashboard/components/widgets/conversation/filter/InboxFilter';

import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import timeMixin from 'dashboard/mixins/time';

import * as types from 'shared/constants/googleEventType';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

export default {
  name: 'CsatResponses',
  components: {
    CsatMetrics,
    CsatTable,
    ReportDateRangeSelector,
    InboxFilter,
  },
  mixins: [timeMixin, googleAnalyticsMixin],
  data() {
    return {
      pageIndex: 1,
      from: 0,
      to: 0,
      timing: 0,
      conversationType: 0,
      selectedInboxes: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUserId: 'getCurrentUserID',
      inboxes: 'inboxes/getInboxes',
    }),
    csatInboxes() {
      return this.inboxes.filter(inbox => inbox.csat_survey_enabled === true);
    },
    excludedInboxTypes() {
      return [INBOX_TYPES.KNOWLARITY];
    },
    timingList() {
      return this.$t('ANALYTICS.FILTER_SELECTION.WORKING_HOURS').map(
        (item, index) => {
          return {
            id: index,
            name: item,
          };
        }
      );
    },
    selectedTiming() {
      return this.timingList[this.timing].name;
    },
    conversationTypeList() {
      return this.$t('ANALYTICS.FILTER_SELECTION.CONVERSATION_TYPE').map(
        (item, index) => {
          return {
            id: index,
            name: item,
          };
        }
      );
    },
    selectedConversationType() {
      return this.conversationTypeList[this.conversationType].name;
    },
  },
  methods: {
    getAllData() {
      this.googleAnalyticsEvent(
        types.default.CSAT_ANALYTICS_APPLY_OR_MOUNT,
        types.default.CSAT_ANALYTICS,
        types.default.CSAT_ANALYTICS
      );
      this.$store.dispatch('csat/getMetrics', {
        startDate: this.formatDate(this.from * 1000).replaceAll('-', '/'),
        endDate: this.formatDate(this.to * 1000).replaceAll('-', '/'),
        ticketType: this.convertParamValueToString(
          'ticketType',
          this.conversationType
        ),
        workingHours: this.convertParamValueToString(
          'workingHours',
          this.timing
        ),
        inboxes: this.selectedInboxes,
        agent_id: this.currentUserId,
      });
      this.getResponses();
    },
    changeInboxId(val, inboxes, id) {
      this.selectedInboxes = id;
      this.getAllData();
    },
    getResponses() {
      this.$store.dispatch('csat/get', {
        startDate: this.formatDate(this.from * 1000).replaceAll('-', '/'),
        endDate: this.formatDate(this.to * 1000).replaceAll('-', '/'),
        ticketType: this.convertParamValueToString(
          'ticketType',
          this.conversationType
        ),
        workingHours: this.convertParamValueToString(
          'workingHours',
          this.timing
        ),
        inboxes: this.selectedInboxes,
        agent_id: this.currentUserId,
      });
    },
    onPageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getResponses();
    },
    onTimingChange(timing) {
      this.googleAnalyticsEvent(
        types.default.CSAT_ON_TIMING_CHANGE,
        types.default.CSAT_ANALYTICS,
        timing.id
      );
      this.timing = timing.id;
      this.getAllData();
    },
    onConversationTypeChange(type) {
      this.googleAnalyticsEvent(
        types.default.CSAT_ON_CONVERSATION_TYPE_CHANGE,
        types.default.CSAT_ANALYTICS,
        type.id
      );
      this.conversationType = type.id;
      this.getAllData();
    },
    onDateRangeChange({ from, to }) {
      this.googleAnalyticsEvent(
        types.default.CSAT_ANALYTICS_ONDATERANGECHANGE,
        from,
        to
      );
      this.from = from;
      this.to = to;
      this.getAllData();
    },
    convertParamValueToString(param, value) {
      const enums = {
        workingHours: ['all', 'inside', 'outside'],
        ticketType: ['all', 'non_bot', 'bot', 'auto_resolved'],
        // the payload for Agent tickets(in HD UI) is non_bot for backend compatibility, backend only accepts the above ticketTypes
      };
      return enums[param][value];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-box {
  padding-left: $zero;
  padding-top: $space-six;
}

.filter-pane {
  position: -webkit-sticky;
  position: sticky;
  background-color: #faf9f5;
  top: $zero;
  z-index: 1000;
  padding: $space-six $zero $space-normal;
}

.selector-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  align-items: center;
  color: $neutral-grey-800;
  font-size: $font-size-small;
  line-height: 22px;
  background: $neutral-white;
  border: 1px solid $pg-1-500;
  cursor: pointer;
  border-radius: $border-radius;
  padding: $space-slab;
  margin-left: $space-normal;
}

.filter-container {
  margin-top: $space-slab;
  margin-left: $space-smaller;
  align-items: center;
  justify-content: flex-start;
}
.mr-right {
  margin-right: $space-normal;
}
</style>
