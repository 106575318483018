<template>
  <div>
    <!-- Push Notifications Section -->
    <div
      v-if="vapidPublicKey && !isBrowserSafari"
      class="profile--settings--row row push-row"
    >
      <woot-modal-header
        :header-title="
          $t('PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.TITLE')
        "
        :header-content="
          $t('PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.NOTE')
        "
      />
      <div class="columns small-9">
        <p v-if="hasEnabledPushPermissions">
          {{
            $t(
              'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.HAS_ENABLED_PUSH'
            )
          }}
        </p>
        <div v-else>
          <woot-primary-button
            :name="
              $t(
                'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.REQUEST_PUSH'
              )
            "
            @click="onRequestPermissions"
          />
          <p class="notification_blocked">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.NOTIFICATIONS_BLOCKED_RESOLUTION_HEADING'
              )
            }}
          </p>
          <p>
            {{
              $t(
                'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.NOTIFICATIONS_BLOCKED_RESOLUTION_TITLE'
              )
            }}
          </p>
          <p>
            {{
              $t(
                'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.NOTIFICATIONS_BLOCKED_RESOLUTION_SUBTITLE'
              )
            }}
          </p>
        </div>
        <div class="columns medium-12 form-box">
          <div
            v-for="pushFlag in allPushFlags"
            :key="pushFlag.value"
            class="checkbox-container"
          >
            <label class="container-checkbox">
              <input
                v-model="selectedPushFlags"
                class="notification--checkbox"
                type="checkbox"
                :value="pushFlag.value"
                @input="handlePushInput"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-label" :for="pushFlag.value">
              {{ pushFlag.label }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  hasPushPermissions,
  requestPushPermissions,
  verifyServiceWorkerExistence,
} from 'dashboard/helper/pushHelper';

import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  mixins: [alertMixin, configMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      selectedPushFlags: [],
      hasEnabledPushPermissions: false,
      allPushFlags: [
        {
          label: this.$t(
            'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_CREATION'
          ),
          value: 'push_conversation_creation',
        },
        {
          label: this.$t(
            'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_ASSIGNMENT'
          ),
          value: 'push_conversation_assignment',
        },
        {
          label: this.$t(
            'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.ASSIGNED_CONVERSATION_NEW_MESSAGE'
          ),
          value: 'push_assigned_conversation_new_message',
        },
        {
          label: this.$t(
            'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_AUTO_RESOLVED'
          ),
          value: 'push_conversation_resolved',
        },
        {
          label: this.$t(
            'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_WAITING_OR_FOLLOWUP_TO_OPENED'
          ),
          value: 'push_conversation_opened',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      pushFlags: 'userNotificationSettings/getSelectedPushFlags',
    }),
    isBrowserSafari() {
      return window.browserConfig?.is_safari === 'true';
    },
  },
  watch: {
    pushFlags(value) {
      this.selectedPushFlags = value;
    },
  },
  mounted() {
    if (hasPushPermissions()) {
      this.getPushSubscription();
    }
    this.$store.dispatch('userNotificationSettings/get');
  },
  methods: {
    onRegistrationSuccess() {
      this.hasEnabledPushPermissions = true;
    },
    onRequestPermissions() {
      requestPushPermissions({
        onSuccess: this.onRegistrationSuccess,
      });
      this.mix_panel_clicked_enable_push_notification();
    },
    getPushSubscription() {
      verifyServiceWorkerExistence(registration =>
        registration.pushManager
          .getSubscription()
          .then(subscription => {
            this.hasEnabledPushPermissions = !!subscription;
          })
          .catch(() => {
            // Ignore error
          })
      );
    },
    async updateNotificationSettings() {
      try {
        await this.$store.dispatch('userNotificationSettings/update', {
          selectedPushFlags: this.selectedPushFlags,
        });
        this.showAlert(
          this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'),
          'success'
        );
      } catch (error) {
        this.showAlert(
          this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_ERROR'),
          'error'
        );
      }
    },
    handlePushInput(e) {
      this.selectedPushFlags = this.toggleInput(
        this.selectedPushFlags,
        e.target.value
      );
      this.updateNotificationSettings();
    },
    toggleInput(selected, current) {
      if (selected.includes(current)) {
        return selected.filter(flag => flag !== current);
      }
      return [...selected, current];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

.notification--checkbox {
  font-size: $font-size-big;
}

.notification_blocked {
  margin-top: $space-normal;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: $space-normal;

  input {
    margin: 0;
  }

  .checkbox-label {
    font-size: $font-size-small;
    line-height: 2.2rem;
    color: $neutral-grey-800;
    margin-top: $space-six;
  }

  .checkmark {
    cursor: pointer;
  }
}
</style>
