export const APP_BASE_URL = '';

export const MESSAGE_TYPE = {
  INCOMING: 0,
  OUTGOING: 1,
  ACTIVITY: 2,
  TEMPLATE: 3,
  GPT: 14,
};

export const WOOT_PREFIX = 'chatwoot-widget:';

export const CONTENT_TYPE = {
  INPUT_EMAIL: 'input_email',
  CARDS: 'cards',
  INPUT_SELECT: 'input_select',
  FORM_INPUT: 'form_input',
  FORM: 'form',
  ARTICLE: 'article',
  TRACK_ORDER: 'track_order',
  ORDER_DETAIL: 'order_detail',
  STICKER: 'sticker',
  INPUT_CSAT: 'input_csat',
};

export const ENABLED_FEATURES = {
  HIDE_BRANDING: 'hide_branding',
  RESTART_CONVERSATION: 'restart_conversation',
  EMOJI_PICKER: 'emoji_picker',
  ATTACHMENTS: 'attachments',
};
