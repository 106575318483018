<template>
  <button
    class="primary-button flex-space-between"
    :disabled="disabled"
    :class="[size, customClass, `button-${size}`]"
    :name="name"
    :style="[customStyle, fullWidthClass]"
    @click="$emit('click')"
  >
    <span v-if="frontIcon" style="margin-right: 0.8rem">
      <icons :name="frontIcon" color="white" :view="iconViewBox" :size="icon" />
    </span>
    <span>{{ name }}</span>
    <span v-if="backIcon" style="margin-left: 0.8rem">
      <icons :name="backIcon" color="white" :view="iconViewBox" :size="icon" />
    </span>
    <span v-if="loading" class="spinner-margin">
      <spinner />
    </span>
  </button>
</template>

<script>
import Spinner from 'shared/components/Spinner';
export default {
  components: {
    Spinner,
  },
  props: {
    name: {
      type: String,
      default: 'submit',
    },
    iconViewBox: {
      type: String,
      default: '0 0 24 24',
    },
    size: {
      type: String,
      default: 'medium',
    },
    frontIcon: {
      type: String,
      default: '',
    },
    backIcon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: Object,
      default: undefined,
    },
    customClass: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    fullWidthClass() {
      if (this.fullWidth)
        return {
          width: '100%',
          'text-align': 'center',
          'justify-content': 'center',
        };
      return '';
    },
    icon() {
      if (this.iconSize) return this.iconSize;
      if (this.size === 'large') return 'medium';
      return 'semimedium';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.primary-button {
  border-radius: $border-radius-smaller;
  background: $pg-1-500;
  cursor: pointer;
  white-space: nowrap;
  color: $color-white;
  font-style: normal;
  justify-content: center;
  min-width: 8rem;

  &:active {
    background: $pg-1-700;
  }

  &:hover {
    background: $pg-1-600;
  }
  &:disabled {
    background: $neutral-grey-600;
    pointer-events: none;
  }
}

.small {
  padding: $space-smaller $space-small;
}

.medium {
  padding: $space-small;
}

.large {
  padding: $space-small $space-slab;
}

.spinner-margin {
  margin: $zero $zero $space-micro $space-smaller;
}
</style>
