<template>
  <div class="row">
    <div
      v-for="(color, index) in colorArray"
      :key="index"
      class="columns small-1 picker--container"
      @click.prevent="updateColor(color)"
    >
      <div class="colorpicker--element" :class="{ selected: color === value }">
        <span class="colorpicker" :style="`background-color: ${color}`" />
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [clickaway],
  props: {
    value: {
      type: String,
      default: '',
    },
    colorArray: {
      type: Array,
      default: () => {
        return [
          '#5B8FF9',
          '#77DFB6',
          '#798AAA',
          '#F6BD16',
          '#E65D3C',
          '#61C3EA',
          '#FF9D4D',
          '#47D2D0',
          '#FF66A5',
          '#6BAC1B',
        ];
      },
    },
  },
  methods: {
    updateColor(color) {
      this.$emit('input', color);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.colorpicker--element {
  display: flex;
  width: 3.6rem;
  align-items: center;
  justify-content: center;
  border-radius: $space-smaller;
  cursor: pointer;
  transition: transform 0.2s;
  border: 1px solid transparent;
  padding: $space-micro;

  .colorpicker {
    border-radius: $space-smaller;
    width: $space-large;
    height: $space-large;
  }

  &:hover {
    transform: scale(1.2);
  }
}

.selected {
  border-color: $pg-1-500;
}
</style>
