import authAPI from 'dashboard/api/auth';

export const getSelectedChatConversation = ({
  allConversations,
  selectedChatId,
}) =>
  allConversations.filter(conversation => conversation.id === selectedChatId);

// getters
const getters = {
  getConversationUiFlags({ uiFlags }) {
    return uiFlags;
  },
  getAllConversations: ({ allConversations }) => allConversations,
  getConversationFilters: ({ conversationFilters }) => conversationFilters,
  getSelectedChat: ({ selectedChatId, allConversations }) => {
    const selectedChat = allConversations.find(
      conversation => conversation.id === selectedChatId
    );
    return selectedChat || {};
  },
  getPendingMessages: ({ pendingMessages }) => {
    return pendingMessages;
  },
  getMineChats(_state) {
    const currentUserID = authAPI.getCurrentUser().id;
    return _state.allTabConversations.filter(chat =>
      !chat.meta.assignee
        ? false
        : chat.status === _state.chatStatusFilter &&
          chat.meta.assignee.id === currentUserID
    );
  },
  getUnAssignedChats(_state) {
    return _state.allTabConversations.filter(
      chat => !chat.meta.assignee && chat.status === _state.chatStatusFilter
    );
  },
  getAllStatusChats(_state) {
    return _state.allTabConversations.filter(
      chat => chat.status === _state.chatStatusFilter
    );
  },
  getChatListLoadingStatus: ({ listLoadingStatus }) => listLoadingStatus,
  getAllMessagesLoaded(_state) {
    const [chat] = getSelectedChatConversation(_state);
    return !chat || chat.allMessagesLoaded === undefined
      ? false
      : chat.allMessagesLoaded;
  },
  getUnreadCount(_state) {
    try {
      const [chat] = getSelectedChatConversation(_state);
      if (!chat) return [].length;
      let x = chat.messages.filter(
        chatMessage =>
          chatMessage.created_at * 1000 > chat.agent_last_seen_at * 1000 &&
          chatMessage.message_type === 0 &&
          chatMessage.private !== true
      ).length;
      return x;
    } catch (e) {
      // statements
      return 0;
    }
  },
  getChatStatusFilter: ({ chatStatusFilter }) => chatStatusFilter,
  getSelectedInbox: ({ currentInbox }) => currentInbox,
};

export default getters;
