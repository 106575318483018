import Vue from 'vue';
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import AutomationRulesAPI from '../../api/automationRules/automationRules';
import AutomationTemplatesAPI from '../../api/automationRules/automationTemplates';

export const state = {
  records: [],
  templates: [],
  uiFlags: {
    isFetching: false,
    isFetchingTemplates: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export const getters = {
  getAutomations(_state) {
    return _state.records;
  },
  getAutomationTemplates(_state) {
    return _state.templates;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  get: async function getAutomations({ state: _state, commit }) {
    if (_state.records?.length) return;

    commit(types.SET_AUTOMATION_UI_FLAG, { isFetching: true });
    try {
      const response = await AutomationRulesAPI.get();
      commit(types.SET_AUTOMATIONS, response.data.payload);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_AUTOMATION_UI_FLAG, { isFetching: false });
    }
  },
  getTemplates: async function getAutomationTemplates({
    state: _state,
    commit,
  }) {
    if (_state.templates?.length) return;

    commit(types.SET_AUTOMATION_UI_FLAG, { isFetchingTemplates: true });
    try {
      const {
        data: { automation_rule_templates: templates },
      } = await AutomationTemplatesAPI.get();
      commit(types.SET_AUTOMATION_TEMPLATES, templates);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_AUTOMATION_UI_FLAG, { isFetchingTemplates: false });
    }
  },
  create: async function createAutomation({ commit }, automationObj) {
    commit(types.SET_AUTOMATION_UI_FLAG, { isCreating: true });
    try {
      const response = await AutomationRulesAPI.create(automationObj);
      commit(types.ADD_AUTOMATION, response.data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_AUTOMATION_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_AUTOMATION_UI_FLAG, { isUpdating: true });
    try {
      const response = await AutomationRulesAPI.update(id, updateObj);
      commit(types.EDIT_AUTOMATION, response.data.payload);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_AUTOMATION_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_AUTOMATION_UI_FLAG, { isDeleting: true });
    try {
      await AutomationRulesAPI.delete(id);
      commit(types.DELETE_AUTOMATION, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_AUTOMATION_UI_FLAG, { isDeleting: false });
    }
  },
  clone: async ({ commit }, id) => {
    commit(types.SET_AUTOMATION_UI_FLAG, { isCloning: true });
    try {
      const {
        data: { payload },
      } = await AutomationRulesAPI.clone(id);
      commit(types.ADD_AUTOMATION, payload);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_AUTOMATION_UI_FLAG, { isCloning: false });
    }
  },
  uploadAttachment: async (_, file) => {
    try {
      const { data } = await AutomationRulesAPI.attachment(file);
      return data.blob_id;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.SET_AUTOMATION_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.ADD_AUTOMATION]: MutationHelpers.create,
  [types.SET_AUTOMATIONS]: MutationHelpers.set,
  [types.SET_AUTOMATION_TEMPLATES](_state, data) {
    Vue.set(_state, 'templates', data);
  },
  [types.EDIT_AUTOMATION]: MutationHelpers.update,
  [types.DELETE_AUTOMATION]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
