/* global axios */
import ApiClient from '../ApiClient';

class ExotelApi extends ApiClient {
  constructor() {
    super('integrations', { accountScoped: true });
  }

  initiateExotelOutboundCall({
    inboxId,
    agentNumber,
    customerNumber,
    virtualNumber,
  }) {
    return axios.post(`${this.baseUrl()}/voice/outbound_call`, {
      inbox_id: inboxId,
      agent_number: agentNumber,
      customer_number: customerNumber,
      virtual_number: virtualNumber,
    });
  }

  getExotelAgents({ inboxId }) {
    return axios.get(
      `${this.baseUrl()}/voice/fetch_voice_agents?inbox_id=${inboxId}`
    );
  }
}

export default new ExotelApi();
