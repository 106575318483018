<template>
  <div class="employee-details-container">
    <form class="settings-form custom-scroll">
      <!-- fields for concurrency -->
      <div class="content-margin">
        <label class="formlabel flex-row flex-space-between">
          <div class="flex-row">
            <span
              class="title-h5 text-dark"
              v-text="$t('GENERAL_SETTINGS.FORM.CONCURRENCY.ENABLED')"
            />
            <span
              v-tooltip="$t('GENERAL_SETTINGS.FORM.CONCURRENCY.INFO_TEXT')"
              class="desc-text capital_c3"
            >
              <icons
                name="warning"
                color="grey evenodd"
                size="semimedium"
                :show-title="false"
              />
            </span>
          </div>
          <woot-switch
            :id="Math.random()"
            :value="concurrencyEnabledVal"
            @click="changeConcurrency"
          />
        </label>
        <transition name="slide-up">
          <woot-input
            v-model.trim="concurrencyLimitVal"
            :class="{ error: $v.concurrencyLimitVal.$error }"
            class="medium-12 columns"
            type="Number"
            :has-error="$v.concurrencyLimitVal.$error"
            :error="$t('GENERAL_SETTINGS.FORM.CONCURRENCY.ERROR_MESSAGE')"
            :label="$t('GENERAL_SETTINGS.FORM.CONCURRENCY.LIMIT')"
            :placeholder="$t('GENERAL_SETTINGS.FORM.CONCURRENCY.LIMIT')"
            :disabled="!concurrencyEnabledVal"
            @input="$emit('update:concurrencyLimit', concurrencyLimitVal)"
            @blur="$v.concurrencyLimitVal.$touch"
          />
        </transition>
      </div>
      <!-- Email signature -->
      <div style="position: relative" class="medium-12 content-margin">
        <div class="form-label body-b2 flex-row flex-justify--between">
          <label class="formlabel flex-row flex-align">
            <span
              class="title-h5 text-dark"
              v-text="$t('AGENT_MGMT.EMAIL_SIGNATURE.LABEL')"
            />
            <span
              v-tooltip="$t('AGENT_MGMT.EMAIL_SIGNATURE.DESCRIPTION')"
              class="mg-top--six mg-left--small"
            >
              <icons
                name="warning"
                color="grey evenodd"
                size="semimedium"
                :show-title="false"
              />
            </span>
          </label>
          <woot-tab-switch
            :active-state="markdownModeVal"
            :state-text="$t('AGENT_MGMT.EMAIL_SIGNATURE.MODE.TEXT')"
            :compliment-state-text="$t('AGENT_MGMT.EMAIL_SIGNATURE.MODE.HTML')"
            @click="toggleEditor"
          />
        </div>
        <text-editor
          v-if="markdownModeVal"
          v-model="emailSignMdVal"
          :for-email="false"
          :height="11.6"
          is-menu-bar-required
          placeholder="Start your signature here..."
          @input="$emit('update:emailSignMd', emailSignMdVal)"
        />
        <woot-input
          v-else
          v-model="emailSignHtmlVal"
          rows="6"
          type="text"
          :placeholder="'Paste html code here...'"
          @input="$emit('update:emailSignHtml', emailSignHtmlVal)"
        />
        <transition name="slide-up">
          <div
            v-if="emailSignHtmlVal && !markdownModeVal"
            class="signature-container"
            v-html="emailSignHtmlVal"
          />
        </transition>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validNonZeroNumber } from '../validations';

import TextEditor from 'shared/components/TextEditor';

import mixPanelMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: {
    TextEditor,
  },
  mixins: [mixPanelMixin],
  props: {
    agentId: { type: [Number, String], default: '' },
    emailSignHtml: { type: String, default: '' },
    emailSignMd: { type: String, default: '' },
    markdownMode: { type: Boolean, default: true },
    concurrencyEnabled: { type: Boolean, default: false },
    concurrencyLimit: { type: [Number, String], default: 5 },
  },
  data() {
    return {
      emailSignHtmlVal: this.emailSignHtml,
      emailSignMdVal: this.emailSignMd,
      markdownModeVal: this.markdownMode,
      concurrencyEnabledVal: this.concurrencyEnabled,
      concurrencyLimitVal: this.concurrencyLimit,
    };
  },
  computed: {
    shouldDisableBtn() {
      return this.concurrencyEnabledVal && this.$v.concurrencyLimitVal.$invalid;
    },
  },
  watch: {
    shouldDisableBtn(val) {
      this.$emit('formValidity', val);
    },
  },
  validations: {
    concurrencyLimitVal: {
      required,
      validNonZeroNumber,
    },
  },
  mounted() {
    this.$emit('formValidity', this.shouldDisableBtn);
  },
  methods: {
    toggleEditor() {
      this.markdownModeVal = !this.markdownModeVal;

      this.$emit('update:markdownMode', this.markdownModeVal);
    },
    changeConcurrency() {
      this.concurrencyEnabledVal = !this.concurrencyEnabledVal;

      const action = this.agentId ? 'Agent Edit' : 'Agent Add';
      this.mix_panel_toggled_agent_ticket_concurrency(
        action,
        this.concurrencyEnabledVal
      );

      this.$emit('update:concurrencyEnabled', this.concurrencyEnabledVal);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.employee-details-container {
  padding: $space-two $space-two;
}

.settings-form {
  padding: $zero $space-two;

  .settings-title {
    margin-bottom: $space-large;
  }
}

.content-margin {
  margin-bottom: $space-medium;
}

.form-label {
  color: $neutral-grey-800;
  padding-bottom: $space-one;
}

.signature-container {
  border: 1px solid $neutral-grey-500;
  border-radius: $border-radius;
  margin-top: $space-normal;
  padding: $space-smaller;
}
</style>
