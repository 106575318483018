<template>
  <div class="columns notification--page lime-card-1dp custom-scroll">
    <div class="notification--content medium-12">
      <List
        :notifications="records"
        :is-loading="uiFlags.isFetching"
        :on-click-notification="openConversation"
      />
      <table-footer
        :on-page-change="onPageChange"
        :current-page="Number(meta.currentPage)"
        :total-count="meta.count"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import List from './components/List';
import TableFooter from 'dashboard/components/widgets/TableFooter';

export default {
  components: {
    List,
    TableFooter,
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      meta: 'notifications/getMeta',
      records: 'notifications/getNotifications',
      uiFlags: 'notifications/getUIFlags',
    }),
  },
  mounted() {
    this.$store.dispatch('notifications/get', { page: 1 });
  },
  methods: {
    onPageChange(page) {
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
      this.$store.dispatch('notifications/get', { page });
    },
    openConversation(notification) {
      const {
        id: notificationId,
        primary_actor_id: primaryActorId,
        primary_actor: { id: conversationId },
      } = notification;

      this.$store.dispatch('notifications/markAsRead', {
        notificationId,
        primaryActorId,
      });

      this.$router.push(
        `/app/accounts/${this.accountId}/conversations/${conversationId}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
.notification--page {
  width: calc(100% - 3.2rem);
  margin: $space-largest $zero $space-normal $space-normal;
  padding: $zero;
}

.notification--content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
