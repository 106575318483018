<template>
  <div
    v-if="inboxData.id"
    class="inbox-card"
    @click="onClickMoveButton(inboxData.id)"
  >
    <div class="flex-row flex-align gap--smaller mg-bottom--smaller">
      <icons
        :name="inboxData.iconName"
        :color="`${inboxData.iconColor} evenodd`"
        :show-title="false"
        size="semimedium"
      />
      <span
        :style="{ color: inboxData.color }"
        class="title-h6 line-clamp-1"
        v-text="inboxData.inboxType"
      />
    </div>
    <span class="body-b2 text-dark line-clamp-1" v-text="inboxData.name" />
    <transition name="slide-fade">
      <div class="move-button flex-row flex-align flex-justify mg-top--smaller">
        <icons v-if="isManual" name="chevronLeft" color="green" size="medium" />
        <span class="title-h6 text-lime-green" v-text="buttonText" />
        <icons v-if="isAuto" name="chevronRight" color="green" size="medium" />
      </div>
    </transition>
  </div>
</template>
<script>
import inboxMixin from 'dashboard/mixins/inbox';
import { INBOX_NAMES } from 'shared/mixins/inboxMixin';

import { ASSIGNMENT_MODES } from '../utils/constants';
import { mixpanelEventWithProps } from 'dashboard/helper/mixpanel';

export default {
  mixins: [inboxMixin],
  props: {
    mode: { type: String, default: ASSIGNMENT_MODES.MANUAL },
    inbox: { type: Object, default: () => {} },
  },
  computed: {
    isAuto() {
      return this.mode === ASSIGNMENT_MODES.AUTO;
    },
    isManual() {
      return this.mode === ASSIGNMENT_MODES.MANUAL;
    },
    buttonText() {
      return { manual: 'Move to Automatic', auto: 'Move to Manual' }[this.mode];
    },
    inboxData() {
      const { inbox } = this;
      if (!inbox?.id) return {};

      const {
        name: iconName,
        hex: color,
        color: iconColor,
      } = this.getInboxClassByType(inbox.channel_type);

      return {
        id: inbox.id,
        name: inbox.name,
        inboxType: INBOX_NAMES[inbox.channel_type].name,
        iconName,
        iconColor,
        color,
      };
    },
  },
  methods: {
    captureMixpanelEvents() {
      const event = this.isAuto
        ? 'clicked_move_to_manual_assignment'
        : 'clicked_move_to_automatic_assignment';

      mixpanelEventWithProps(event, {
        inboxId: this.inbox.id,
      });
    },
    onClickMoveButton(id) {
      this.captureMixpanelEvents();

      this.$emit('click', {
        id,
        enable_auto_assignment: !this.isAuto,
        allow_offline_assignment: !this.isAuto
          ? false
          : this.inbox.allow_offline_assignment,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

.inbox-card {
  cursor: pointer;
  padding: $space-slab;
  border: 1px solid $neutral-grey-400;
  background: $color-white;
  border-radius: $border-radius;
  width: 18rem;
  height: 9rem;
  transition: 0.2s all ease-out;

  &:hover {
    box-shadow: $zero $zero $zero $space-micro / 2 $pg-1;

    .move-button {
      opacity: 1;
      visibility: visible;
    }
  }

  .move-button {
    transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
