<template>
  <div class="custom-fields column content-box settings-box no-padding">
    <div class="settings-button flex-row flex-align gap--normal">
      <reference-box
        :to="documentation.customFields.resource"
        content="Know More"
        type="document"
      />
      <reference-box
        :to="documentation.customFields.demoVideo"
        content="Video Demo"
        type="video"
      />
      <woot-base-button
        size="medium"
        front-icon="plus"
        icon-view-box="0 0 20 20"
        @click="openAddPopup"
      >
        {{ $t('CUSTOM_TICKET_FIELDS.HEADER_BTN_TXT') }}
      </woot-base-button>
    </div>

    <div class="settings-container">
      <div class="column settings-main-menu-new">
        <index-header
          @setSearch="setSearch"
          @selectedInbox="selectedInbox = $event"
          @selectedType="selectedType = $event"
        />
        <List
          :table-columns="tableColumns"
          :custom-fields-list="filteredCustomFieldsList"
          :loading="isLoading"
          :search="search"
          @viewCustomField="viewCustomField"
          @openAddPopup="openAddPopup"
        />
      </div>
      <settings-side-card>
        <educational-onboarding
          v-if="!selectedCustomField"
          :show-case-data="SHOWCASE_CUSTOM_FIELD_DATA"
        />
        <rhs-panel v-else :custom-field="selectedCustomField" />
      </settings-side-card>

      <transition name="modal-fade">
        <create-custom-field-modal
          v-if="showCreateModal"
          @handleCreate="onHandleCreate"
          @onClose="showCreateModal = false"
        />
      </transition>

      <transition name="modal-fade">
        <custom-field-details-modal
          v-if="showDetailsModal"
          opened
          :name="selectedCustomFieldDetails.name"
          :description="selectedCustomFieldDetails.description"
          @onSubmit="onSubmitDetailsModal"
          @onClose="showDetailsModal = false"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SettingsSideCard from '../SettingSideCard';
import IndexHeader from './headers/IndexHeader';
import List from './components/List';
import EducationalOnboarding from 'dashboard/components/ui/settings/EducationalOnboarding';
import RhsPanel from './components/RhsPanel';
import CreateCustomFieldModal from './components/CreateCustomFieldModal';
import CustomFieldDetailsModal from './components/CustomFieldDetailsModal';

import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';

import documentation from 'dashboard/helper/data/documentationData.json';

import {
  SHOWCASE_CUSTOM_FIELD_DATA,
  CUSTOM_FIELD_TABLE_COLUMNS,
} from './utils/constants';

export default {
  name: 'CustomTicketFieldsHome',
  components: {
    ReferenceBox,
    IndexHeader,
    SettingsSideCard,
    EducationalOnboarding,
    RhsPanel,
    List,
    CreateCustomFieldModal,
    CustomFieldDetailsModal,
  },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      documentation,
      search: '',
      showCreateModal: false,
      showDetailsModal: false,
      tableColumns: CUSTOM_FIELD_TABLE_COLUMNS,
      SHOWCASE_CUSTOM_FIELD_DATA,
      selectedCustomFieldDetails: { name: '', description: '' },
      selectedCustomFieldType: null,
      selectedCustomFieldId: null,
      selectedInbox: null,
      selectedType: null,
    };
  },
  computed: {
    ...mapGetters({
      customFieldsList: 'customTicketFields/getCustomTicketFields',
      uiFlags: 'customTicketFields/getUIFlags',
    }),
    isLoading() {
      const { isFetching } = this.uiFlags;
      return isFetching;
    },
    filteredCustomFieldsList() {
      let list = this.customFieldsList;

      if (this.selectedInbox?.id) {
        list = list.filter(({ inboxes }) => {
          const inbox_ids = inboxes.map(inbox => inbox.id);
          return inbox_ids.includes(this.selectedInbox.id);
        });
      }

      if (this.selectedType?.id) {
        list = list.filter(item => {
          return item.field_type === this.selectedType.id;
        });
      }

      return list.filter(item => {
        return (
          item.name?.toLowerCase().search(this.search.toLowerCase()) > -1 ||
          item.description?.toLowerCase().search(this.search.toLowerCase()) > -1
        );
      });
    },
    selectedCustomField() {
      return this.customFieldsList.find(
        item => item.id === this.selectedCustomFieldId
      );
    },
  },
  created() {
    this.$store.dispatch('customTicketFields/get');
  },
  methods: {
    viewCustomField(customField) {
      this.selectedCustomFieldId = customField.id;
    },
    setSearch(value) {
      this.search = value;
    },
    openAddPopup() {
      this.showCreateModal = true;
    },
    hideAddPopup() {
      this.showCreateModal = false;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    onHandleCreate(selectedCustomFieldTemplate) {
      this.showDetailsModal = true;
      this.selectedCustomFieldType = selectedCustomFieldTemplate?.id || null;
    },
    updateDetails(data) {
      this.selectedCustomFieldDetails = {
        ...this.selectedCustomFieldDetails,
        ...data,
      };
    },
    onSubmitDetailsModal(data) {
      this.updateDetails(data);
      this.showDetailsModal = false;
      const { name, description } = this.selectedCustomFieldDetails;
      this.selectedCustomFieldDetails = { name: '', description: '' };
      this.$router.push({
        name: 'settings_create_custom_field',
        params: {
          customFieldType: this.selectedCustomFieldType,
          customFieldName: name,
          customFieldDesc: description,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-box {
  padding-top: $space-largest;
  overflow: hidden;

  .settings-container {
    display: flex;
    height: 100%;
    flex-direction: row;

    .settings-side-card {
      padding: $zero;
    }
  }
}
</style>
