var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter flex-column gap--small"},_vm._l((_vm.filterList),function(group,groupIndex){return _c('div',{key:groupIndex},[(group.GROUP_NAME)?_c('div',{staticClass:"title-h6_m text-light mg-bottom--smaller",domProps:{"textContent":_vm._s(group.GROUP_NAME)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"filters-container flex-row flex-wrap flex-align--start gap--small"},[_vm._l((group.FILTERS),function(item){return [(_vm.isFilterVisible(item))?_c(_vm.getComponent(item),{key:item.key,ref:_vm.getFilterRef(item),refInFor:true,tag:"component",class:_vm.getCustomClass(item),attrs:{"item":item,"size":'small',"button-style":_vm.getButtonStyle(item),"custom-style":_vm.getCustomStyle(item),"option-list":_vm.getOptionList(item),"default-option":_vm.getDefaultOption(item),"front-icon":_vm.getFrontIcon(item),"selected-agent-id":_vm.appliedFilters.selectedAgent,"selected-team-id":_vm.appliedFilters.teamId,"filter-title":item.static_title,"default-selection":_vm.defaultRangeSelection,"active-status":_vm.activeStatus,"selected-option":_vm.identifier,"highlight-selected":_vm.isHighlightSelected(item),"static-title":item.static_title,"show-selected-text":false,"custom-button":item.customButton,"toggle-filter":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.getFilterToggler(item, args);
}},on:{"click":function (value) { return _vm.handleClick(item, value); },"date-range-change":_vm.onDateRangeChange,"labelSelected":_vm.handleLabelClick,"selected-option":_vm.handleSelectedOption,"dropdown-opened":_vm.handleDropdownOpened,"statusFilterChange":_vm.updateStatusType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c(_vm.getCollapsedComponent(item),{tag:"component",attrs:{"data":data}})]}}],null,true)}):_vm._e()]})],2)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }