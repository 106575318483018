<template>
  <div>
    <div class="text-align mg-bottom--large">
      <span
        class="body-b2 text-dark"
        v-html="
          $t('ONBOARDING.WHATSAPP.THREE_SIXTY_DIALOG.INFO_TEXT', {
            phoneNumber: inbox.phone_number,
          })
        "
      />
      <span
        v-tooltip="$t('ONBOARDING.WHATSAPP.THREE_SIXTY_DIALOG.INFO_TOOLTIP')"
        class="body-b2 text-light"
        v-html="'<b><em>required information.</em></b>'"
      />
    </div>
    <connect-button
      class="mg-auto"
      env="prod"
      :label="$t('ONBOARDING.WHATSAPP.THREE_SIXTY_DIALOG.LOGIN.BUTTON')"
      :query-parameters="{
        redirect_url: setRedirectUrl(),
        state: inbox.phone_number,
      }"
    />
  </div>
</template>
<script>
import ConnectButton from 'dashboard/routes/dashboard/settings/inbox/components/360Dialog/ConnectButton';

export default {
  components: { ConnectButton },
  props: {
    inbox: {
      type: Object,
      default: () => null,
    },
  },
  inject: ['navigateTo'],
  methods: {
    setRedirectUrl() {
      const redirectUrl = window.location.origin + this.$route.path;

      return redirectUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.content-margin {
  margin-bottom: $space-medium;
}

ol {
  > li {
    list-style-type: disc;
    margin-top: $space-smaller;
  }
}

.reference-box {
  max-width: 100%;
}

.navigation-button {
  width: $space-slab * 10;
}
</style>
