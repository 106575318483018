import { mapGetters } from 'vuex';
import { CALL_PROVIDERS } from 'dashboard/constants';

export default {
  computed: {
    ...mapGetters({
      knowlarityInboxes: 'inboxes/getKnowlarityInboxes',
      exotelInboxes: 'inboxes/getExotelInboxes',
      currentUser: 'getCurrentUser',
    }),
    isKnowlarityFeatureAvailable() {
      return this.knowlarityInboxes.find(inbox =>
        this.currentUser.call_function.includes(inbox.id)
      );
    },
    isExotelFeatureAvailable() {
      return this.exotelInboxes.find(inbox =>
        this.currentUser.call_function.includes(inbox.id)
      );
    },
    hasVoiceCallInboxes() {
      return this.knowlarityInboxes.length || this.exotelInboxes.length;
    },
    isCallFeatureAvailable() {
      return this.isKnowlarityFeatureAvailable || this.isExotelFeatureAvailable;
    },
  },
  methods: {
    createCallInstance({
      agentNumber,
      agentRole,
      agentName,
      customerNumber = '',
      customerName = null,
      chat = null,
      activeInbox = null,
    }) {
      bus.$emit('initiateCallInstance', {
        agentNumber,
        agentRole,
        agentName,
        customerNumber,
        customerName,
        chat,
        activeInbox,
      });
    },
    getUserAccessibleInboxes(inboxes, provider) {
      return inboxes
        .filter(({ id }) => this.currentUser.call_function.includes(id))
        .map(inbox => {
          switch (provider) {
            case CALL_PROVIDERS.KNOWLARITY:
              return {
                id: inbox.channel_id,
                name: `${inbox.name} (${inbox.sr_number})`,
                callerId: inbox.caller_id,
                knowlarityNumber: inbox.sr_number,
                provider,
              };
            case CALL_PROVIDERS.EXOTEL:
              return {
                id: inbox.id,
                name: `${inbox.name}`,
                virtual_numbers: inbox.virtual_numbers,
                provider,
              };
            default:
              return {
                id: inbox.channel_id,
                name: `${inbox.name}`,
                provider,
              };
          }
        });
    },
  },
};
