<template>
  <div>
    <div class="multiselect-wrap--small">
      <multiselect
        v-model="selectedTeams"
        track-by="id"
        label="name"
        style="max-width: 32rem"
        :placeholder="$t('AUTOMATION.ACTION.TEAM_DROPDOWN_PLACEHOLDER')"
        :multiple="true"
        selected-label
        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
        deselect-label=""
        :max-height="160"
        :options="teamsList"
        allow-empty
        @input="updateValue"
      />
      <text-editor
        v-model="message"
        class="action-message"
        is-menu-bar-required
        is-format-mode
        :min-height="15"
        :placeholder="$t('AUTOMATION.ACTION.TEAM_MESSAGE_INPUT_PLACEHOLDER')"
        @input="updateValue"
      />
    </div>
  </div>
</template>

<script>
import TextEditor from 'shared/components/TextEditor';

export default {
  components: { TextEditor },
  // The value types are dynamic, hence prop validation removed to work with our action schema
  // eslint-disable-next-line vue/require-prop-types
  props: ['teams', 'value'],
  data() {
    return {
      selectedTeams: [],
      message: '',
    };
  },
  computed: {
    teamsList() {
      // to remove the first option which is nullity option
      return this.teams.slice(1);
    },
  },
  mounted() {
    if (this.value?.team_ids) {
      this.selectedTeams = this.teamsList.filter(team =>
        this.value.team_ids.includes(team.id)
      );
    }
    this.message = this.value.message;
  },
  methods: {
    updateValue() {
      this.$emit('input', {
        team_ids: this.selectedTeams.map(team => team.id),
        message: this.message,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.multiselect {
  margin: $space-smaller $zero;
  min-height: $zero;
}

textarea {
  margin-bottom: $zero;
}
</style>
