<template>
  <div class="app-switcher" @click="show">
    <icons name="menu" color="grey" size="medium" />
    <div
      v-if="showAppSwitcher"
      v-on-clickaway="hide"
      class="app-switch-container"
    >
      <div class="app-switch-container__app">
        <a
          v-for="app in apps"
          :key="app.id"
          :href="targetUrl(app)"
          target="_blank"
          rel="noopener noreferrer"
          @click="hide"
        >
          <div class="flex-column-center padding-slab">
            <img :src="getLogoForApp(app)" :alt="app.name" />
            <div class="title-h5 text-dark mg-top--slab">
              {{ app.name }}
            </div>
          </div>
        </a>
      </div>
      <div class="body-b3 text-light branding">
        {{ $t('SIDEBAR_ITEMS.PRODUCT_BY') }}
        <img
          :src="'/brand-assets/logo_thumbnail.svg'"
          style="height: 12px"
          class="mg-left--small mg-right--micro"
        />
        {{ $t('SIDEBAR_ITEMS.LIMECHAT') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import crmLogo from 'dashboard/assets/images/crm.svg';
import marketingLogo from 'dashboard/assets/images/marketing.svg';
import automationLogo from 'dashboard/assets/images/automation.svg';
import { LC_APPS } from 'dashboard/constants';

export default {
  mixins: [clickaway],
  data() {
    return {
      showAppSwitcher: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    apps() {
      return LC_APPS;
    },
  },
  methods: {
    getLogoForApp(app) {
      switch (app.id) {
        case 'crm':
          return crmLogo;
        case 'marketing':
          return marketingLogo;
        case 'automation':
          return automationLogo;
        default:
          return '';
      }
    },
    show() {
      this.showAppSwitcher = true;
    },
    hide() {
      this.showAppSwitcher = false;
    },
    targetUrl(app) {
      if (app.id === 'crm') {
        return '/';
      }

      return `${app.url}?hd_account_id=${this.accountId}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'dashboard/assets/scss/_variables.scss';
@import 'dashboard/assets/scss/_mixins.scss';

.app-switcher {
  display: flex;
  cursor: pointer;
  position: relative;
}

.app-switch-container {
  @include elegant-card;
  @include border-light;
  box-shadow: 0 0 0 100000px $backdrop;
  background: $color-white;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  top: $space-large;
  right: $zero;
  width: 31.6rem;
  z-index: 1000;
  transition: all 1s;

  &__app {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: $space-small;
    width: 100%;

    a {
      width: 10rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: var(--space-smaller);

      img {
        width: 5.6rem;
        height: 5.6rem;
      }

      &:hover {
        background: rgba(205, 240, 162, 0.5);
      }
    }
  }

  .branding {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $space-slab;
    background: $neutral-grey-200;
    border-top: 1px solid $neutral-grey-400;
  }
}
</style>
