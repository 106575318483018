<template>
  <woot-single-selector
    id="status-filter"
    :size="size"
    :variant="variant"
    class="status-dropdown-btn"
    :button-style="{
      color: '#8c8c8c',
      ...buttonStyle,
    }"
    :custom-style="{
      left: 0,
      width: 'auto',
      'z-index': 400,
    }"
    :default-option="activeStatus"
    :option-list="getChatFilterList"
    :show-all="true"
    highlight-selected
    @click="onTabChange"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';

import isAdmin from 'dashboard/mixins/isAdmin';
import alertMixin from 'shared/mixins/alertMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';

import wootConstants from 'dashboard/constants';
import * as types from 'shared/constants/googleEventType';

export default {
  mixins: [isAdmin, alertMixin, clickaway, googleAnalyticsMixin],
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    variant: {
      type: String,
      default: 'secondary',
    },
    buttonStyle: {
      type: Object,
      default: () => {},
    },
    activeStatus: {
      type: String,
      default: wootConstants.STATUS_TYPE.OPEN,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'accounts/getUIFlags',
    }),
    getChatFilterList() {
      const hideBotTicketsFilter = this.uiFlags.isBotTicketHidden;

      return this.$t('CHAT_LIST.CHAT_STATUS_ITEMS').reduce((acc, item) => {
        // if bot tickets filter has to hidden
        if (!this.isAdmin && hideBotTicketsFilter && item.VALUE === 'bot')
          return acc;

        acc.push({
          id: item.NAME,
          name: item.VALUE,
        });
        return acc;
      }, []);
    },
  },
  methods: {
    onTabChange(val) {
      this.$emit('statusFilterChange', val.name);
      this.googleAnalyticsEvent(
        types.default.CHANGE_STATUS_FILTER,
        types.default.DASHBOARD_MID_PANEL,
        val.name
      );
    },
  },
};
</script>
