<template>
  <div class="pos-relative">
    <span class="table-title">{{ title }}</span>
    <span class="tooltip-position">
      <icons name="info" size="medium" color="grey" />
    </span>
    <div class="title-h3 text-light">
      {{ subtitle }}
    </div>
    <div :id="id" ref="funnel_chart" class="chart"></div>
  </div>
</template>
<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);
export default {
  name: 'FunnelChart',
  props: {
    id: {
      type: String,
      default: '',
    },
    chartColors: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chart: '',
    };
  },
  watch: {
    data(newVal, oldVal) {
      if (newVal !== oldVal) {
        // eslint-disable-next-line no-undef
        chart.data = newVal;
        this.renderChart();
      }
    },
  },
  mounted() {
    this.renderChart();
  },

  methods: {
    renderChart() {
      let chart = am4core.create(this.id, am4charts.SlicedChart);
      let colorSet = new am4core.ColorSet();
      // eslint-disable-next-line new-cap
      colorSet = this.chartColors.map(color => new am4core.color(color));

      chart.data = this.data;
      chart.legend = new am4charts.Legend();
      chart.legend.position = 'left';
      chart.legend.labels.template.text = '[{color}]{name}';
      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 16;
      markerTemplate.height = 16;

      chart.legend.labels.template.fontSize = '14px';

      let series = chart.series.push(new am4charts.FunnelSeries());
      series.dataFields.value = 'value';
      series.dataFields.category = 'name';
      series.labels.template.text = '{value}';
      series.topWidth = am4core.percent(100);
      series.bottomWidth = am4core.percent(40);

      chart.tooltip.getFillFromObject = false;
      chart.tooltip.background.fill = am4core.color('#fff');
      chart.tooltip.label.fill = am4core.color('#fff');
      chart.tooltip.label.fontSize = 20;
      chart.tooltip.label.textAlign = 'middle';

      series.colors.list = colorSet;
      series.bottomRatio = 1;
      series.labels.template.fill = am4core.color('#fff');
      series.labels.template.fontSize = 13;
      series.sliceLinks.template.height = 0;
      series.sliceLinks.template.fillOpacity = 0;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.chart {
  width: 90%;
  height: 220px;
  margin: 12px;
}
.table-title {
  font-size: $font-size-medium;
  line-height: 2.6rem;
  color: $neutral-grey-800;
  font-weight: $font-weight-medium;
  margin: $space-slab $zero;
}

.tooltip-position {
  position: absolute;
  top: $zero;
  right: $zero;
}

.pos-relative {
  position: relative;
}
</style>
