<template>
  <div id="chart" class="pos-relative">
    <span class="table-title">{{ title }}</span>
    <span class="tooltip-position">
      <div v-if="tooltipContent" v-tooltip="tooltipContent">
        <icons name="info" size="medium" color="grey" />
      </div>
    </span>
    <apexchart
      type="pie"
      :width="width"
      :height="height + Math.floor(Math.random() * 2) + 1"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  components: {
    apexchart: VueApexCharts,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    colors: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: '350',
    },
    series: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number,
      default: 0,
    },
    legendPosition: {
      type: String,
      default: '',
    },
    legendOffsetX: {
      type: Number,
      default: 0,
    },
    legendOffsetY: {
      type: Number,
      default: 0,
    },
    markerOffsetX: {
      type: Number,
      default: 0,
    },
    markerOffsetY: {
      type: Number,
      default: 0,
    },
    chartOffsetX: {
      type: Number,
      default: 0,
    },
    chartOffsetY: {
      type: Number,
      default: 0,
    },
    tooltipContent: {
      type: String,
      default: '',
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          id: 'chart',
          offsetY: this.chartOffsetY,
          offsetX: this.chartOffsetX,
        },
        tooltip: {
          fillSeriesColor: false,
          style: {
            fontFamily: 'Lato',
          },
        },
        colors: this.colors,
        legend: {
          position: this.legendPosition,
          verticalAlign: 'center',
          fontSize: '12px',
          fontFamily: 'Lato',
          fontWeight: 400,
          offsetX: this.legendOffsetX,
          offsetY: this.legendOffsetY,
          formatter: (seriesName, opts) => {
            if (opts.w.config.series[opts.seriesIndex] === 0)
              return seriesName + ' 0%';
            return seriesName;
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            radius: 20,
            customHTML: undefined,
            onClick: undefined,
            offsetX: this.markerOffsetX,
            offsetY: this.markerOffsetY,
          },
          labels: {
            colors: '#8c8c8c',
            useSeriesColors: false,
            formatter: (val, opts) => {
              if (val === 0) return '0%';
              return opts.w.config.series[opts.seriesIndex];
            },
          },
          onItemHover: {
            highlightDataSeries: false,
          },
          itemMargin: {
            vertical: 10,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: val => {
            if (val === 0) return '0%';
            return val?.toFixed(1) + '%';
          },
          style: {
            fontSize: '14px',
            fontFamily: 'Lato',
          },
        },
        labels: this.labels,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.table-title {
  font-size: $font-size-medium;
  line-height: 2.6rem;
  color: $neutral-grey-800;
  font-weight: $font-weight-medium;
  margin: $space-slab;
}

.tooltip-position {
  position: absolute;
  top: $zero;
  right: $zero;
}
.pos-relative {
  position: relative;
}
</style>
