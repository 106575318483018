<template>
  <div class="content-margin filters-wrap">
    <label class="formlabel" :class="{ error }">
      <span
        class="title-h5 text-dark"
        v-text="$t('AUTOMATION.ADD.FORM.EVENT.LABEL')"
      />
    </label>
    <woot-single-selector
      class="AUTOMATION_EVENT_NAME"
      :default-option="dropdownTitle"
      :option-list="events"
      :show-selected-text="false"
      :custom-style="{ left: 0, width: '100%' }"
      :dropdown-container-style="{ height: '3.6rem', width: '22rem' }"
      @click="selectEvent"
    />
    <span v-if="error" class="message">
      {{ $t('AUTOMATION.ADD.FORM.EVENT.ERROR') }}
    </span>
  </div>
</template>

<script>
import { convertToTitleCase } from 'dashboard/helper/stringUtils';

export default {
  props: {
    selectedEvent: {
      type: String,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dropdownTitle() {
      return convertToTitleCase(this.selectedEvent) || 'Select an event';
    },
  },
  methods: {
    selectEvent(event) {
      this.$emit('select', event);
    },
  },
};
</script>
