<template>
  <div class="small-12">
    <div class="flex-space-between" style="align-items:flex-start">
      <div>
        <div class="title-h4 text-dark">
          Download Template and fill informaton
        </div>
        <div
          class="mg-top--small subtitle-s2 text-light"
          style="max-width:38rem"
        >
          {{ $t('BROADCAST.BROADCAST_TAB.UPLOAD_CSV.SELECTED_CSV_CONTENT') }}
        </div>
        <div class="mg-top mg-bottom--smaller button-small text-light">
          Selected Template
        </div>
        <div
          ref="selectUserContainer"
          class="body-b2 text-dark  custom-scroll"
          style="max-width:43rem; max-height: 8rem"
          v-html="highlightedBrackets(selectedTemplate.body, true)"
        />
      </div>
      <div class="csv-preview">
        <div class="flex-row mg-bottom" style="justify-content:flex-end">
          <woot-primary-button
            name="Download CSV template"
            size="small"
            :icon-view-box="'0 0 20 20'"
            front-icon="excel"
            @click="downloadCSVTemplate"
          />
        </div>
        <csv-preview
          :headers-only="true"
          :params-count="paramsCount"
          :needs-media-header="needsMediaHeader"
        />
      </div>
    </div>
    <div
      class="flex-space-between  mg-top--large"
      style="align-items:flex-start"
    >
      <div>
        <div class="title-h4 text-dark">
          Upload the edited template csv
        </div>
        <div class="mg-top--slab body-b3 text-light" style="max-width:40rem">
          Upload the final CSV here. You can drag and drop or upload it from the
          files.<br />💡 Messages will not be sent to opted-out customers<br />💡Accepted
          Phone No Format : [PHONE_NO] without '+' sign or country code<br />💡Accepted
          Country Format : [COUNTRY_CODE] without '+' sign<br />💡Broadcast
          Messaging now has international number support. CountryCode if left
          empty will default to '91'
        </div>
      </div>
      <div class="csv-preview">
        <drop-zone
          accept-format=".xlsx, .csv"
          :height="180"
          img-icon
          :show-attachment-button="true"
          @fileData="onCSVUpload"
        />
        <div v-if="csvLoading" class="flex-row mg-top--small">
          <div class="flex-row-center">
            <woot-spinner color="lime-green" />
            <span
              class="title-h4 text-dark button-small"
              style="color : #6BAC1B"
            >
              uploading please wait
            </span>
          </div>
        </div>
        <div v-else class="flex-space-between mg-top--small">
          <div v-if="selectedFileName" class="flex-row-center">
            <img src="~/assets/images/excel.svg" class="mg-right--small" />
            <span class="title-h4 text-dark"> {{ selectedFileName }}</span>
          </div>
          <div v-if="isUploaded">
            <div
              v-if="
                !fileUploadFailed &&
                  csvErrorMessages.length === 0 &&
                  csvSoftErrorMessages.length === 0
              "
              class="flex-row title-h4_m"
              style="color:#6BAC1B"
            >
              <span class="mg-right--small">
                <icons name="tickRound" size="medium" color="green" />
              </span>
              {{ $t('BROADCAST.BROADCAST_TAB.UPLOAD_CSV.STATUS.SUCCESS') }}
            </div>
            <div
              v-else
              class="flex-row title-h4_m"
              style="color:#FF7E61; cursor: pointer"
              @click="showModal = true"
            >
              <span class="mg-right--small">
                <icons name="error" color="red" size="semimedium" />
              </span>
              {{ 'Error in file' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-row flex-justify--end mg-top--large">
      <woot-primary-button
        name="previous"
        :custom-style="buttonStyle"
        custom-class="mg-right"
        size="medium"
        @click="goToPrevious"
      />
      <woot-primary-button
        name="next"
        :custom-style="buttonStyle"
        size="medium"
        :disabled="disableNext"
        @click="goToNext"
      />
    </div>

    <woot-modal
      v-if="showUploadModal"
      :show.sync="showModal"
      :on-close="hideUploadModal"
    >
      <div class="column content-box">
        <div class="column page-top-bar modal-header">
          <span class="page-sub-title title-h2" style="color : #FF7E61">
            {{ 'Errors found in CSV' }}
          </span>
        </div>
        <div class="row settings-form">
          <div class="button-small text-light mg-bottom">
            {{ $t('BROADCAST.BROADCAST_TAB.UPLOAD_CSV.SELECTED_TEMPLATE') }}
          </div>
          <div class="small-12">
            <div
              v-if="selectedTemplate !== null"
              class="flex-row-justify-center template-prev"
            >
              <span class="template-prev--sc title-h5 text-dark">
                {{
                  `(${selectedTemplate.display_id}) ${selectedTemplate.short_code}`
                }}
              </span>
              <div
                class="body-b2 text-dark  custom-scroll"
                style="max-height: 10rem"
                v-html="highlightedBrackets(selectedTemplate.body, true)"
              />
            </div>
            <hr />
            <div style="width:100%">
              <div class="title-h5_m text-light mg-bottom">
                {{ $t('BROADCAST.BROADCAST_TAB.UPLOAD_CSV.UPLOADED_CSV') }}
              </div>
              <div class="flex-space-between" style="gap:4.8rem">
                <div v-if="!fileUploadFailed" class="flex-row-center">
                  <img
                    src="~/assets/images/excel.svg"
                    class="mg-right--small"
                  />
                  <span class="title-h4 text-dark">
                    {{ selectedFileName }}
                  </span>
                </div>
                <div
                  v-else
                  class="flex-row-center"
                  style="min-width: 30%; gap:0.4rem"
                >
                  <icons name="error" size="normal" color="warning" />
                  <span class="title-h5 text-dark">
                    {{ $t('BROADCAST.BROADCAST_TAB.UPLOAD_CSV.UPLOAD_FAILED') }}
                  </span>
                </div>
                <div>
                  <label class="primary-button button-medium flex-row-center">
                    <span class="mg-right--small">
                      <icons
                        name="refreshRound"
                        view="0 0 20 20"
                        color="white"
                        size="semimedium"
                      />
                    </span>
                    {{ 'Re-upload ' }}
                    <file-upload
                      ref="upload"
                      accept=".xlsx, .csv"
                      @input-file="onCSVUpload"
                    >
                    </file-upload>
                  </label>
                </div>
              </div>
            </div>
            <div
              v-if="
                csvErrorMessages.length > 0 || csvSoftErrorMessages.length > 0
              "
            >
              <hr />
              <div class="title-h5_m text-light mg-bottom">
                {{ $t('BROADCAST.BROADCAST_TAB.UPLOAD_CSV.ERRORS_FOUND') }}
              </div>
              <ul
                v-if="csvErrorMessages.length"
                style="list-style-type:disc"
                class="mg-left"
              >
                <li
                  v-for="(error, index) in csvErrorMessages"
                  :key="index"
                  class="title-h4_m"
                  style="color:#FF7E61"
                >
                  {{ error.content }}
                </li>
              </ul>
              <ul
                v-if="csvSoftErrorMessages.length"
                style="list-style-type:disc"
                class="mg-left"
              >
                <li
                  v-for="(error, index) in csvSoftErrorMessages"
                  :key="index"
                  class="title-h4_m"
                  style="color:#F6BD16"
                >
                  <span
                    v-if="error.type === 'phone' || error.type === 'opt_out'"
                    class="flex-space-between"
                  >
                    <span>{{ error.content }}</span>
                    <woot-tertiary-button
                      size="small"
                      name="see list"
                      :custom-style="{ color: '#1B83AC' }"
                      @click="downloadErrorCsv(error.type)"
                    />
                  </span>
                  <span v-else>
                    {{ error.content }}
                  </span>
                </li>
              </ul>
            </div>
            <div class="flex-row mg-top flex-align--start">
              <img
                src="~/assets/images/light-bulb.svg"
                class="mg-right--small"
              />
              <div>
                <div class="body-b3">
                  {{
                    $t('BROADCAST.BROADCAST_TAB.UPLOAD_CSV.INTERNATIONAL_INFO')
                  }}
                </div>
                <div class="body-b3">
                  {{
                    $t(
                      'BROADCAST.BROADCAST_TAB.UPLOAD_CSV.BROADCAST_LIMIT_INFO'
                    )
                  }}
                </div>
              </div>
            </div>

            <div
              class="modal-footer mg-top--medium"
              style="justify-content: flex-end; padding:0"
            >
              <div class="flex-row-justify-center">
                <woot-tertiary-button
                  :name="$t('BROADCAST.BROADCAST_TAB.UPLOAD_CSV.CANCEL')"
                  :custom-style="{ color: '#FF422E', 'margin-right': '0.8rem' }"
                  @click="hideUploadModal"
                />
                <woot-primary-button
                  :name="
                    $t('BROADCAST.BROADCAST_TAB.UPLOAD_CSV.BUTTON.SUCCESS')
                  "
                  :disabled="csvErrorMessages.length > 0 && !fileUploadFailed"
                  @click="continueCSV"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </woot-modal>
    <woot-delete-modal
      :show.sync="showConfirmationModal"
      :on-close="closeModal"
      :on-confirm="proceedWithErrors"
      :message="
        $t('BROADCAST.BROADCAST_TAB.UPLOAD_CSV.CONFIRMATION_MODAL_CONTENT', {
          defectiveNumberLength: defectiveNumberLength,
          contact: defectiveNumberLength > 1 ? 'contacts' : 'contact',
          this: defectiveNumberLength > 1 ? 'these' : 'this',
        })
      "
      :confirm-text="$t('BROADCAST.BROADCAST_TAB.UPLOAD_CSV.CONTINUE')"
      :reject-text="$t('BROADCAST.BROADCAST_TAB.UPLOAD_CSV.BACK')"
    />
  </div>
</template>

<script>
import broadcastMixin from 'dashboard/mixins/broadcast';
import downloadMixin from 'shared/mixins/downloadMixin.js';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import CsvPreview from '../BroadcastCsvPreview.vue';
import DropZone from 'dashboard/components/ui/DropZone.vue';
import FileUpload from 'vue-upload-component';
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import { url as isValidURL } from 'vuelidate/lib/validators';

export default {
  components: {
    CsvPreview,
    DropZone,
    FileUpload,
  },
  mixins: [broadcastMixin, downloadMixin, googleAnalyticsMixin],
  props: {
    selectedTemplate: {
      type: Object,
      default: () => {},
    },
    needsMediaHeader: {
      type: Boolean,
      default: false,
    },
    paramsCount: {
      type: Number,
      default: 0,
    },
    optOutListProp: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      csvLoading: false,
      phoneNumberList: [],
      phoneNumbersCount: null,
      csvErrorMessages: [],
      csvSoftErrorMessages: [],
      files: [],
      selectedFileName: '',
      selectedFiles: null,
      fileUploadFailed: false,
      showConfirmationModal: false,
      defectiveNumberLength: null,
      defectiveNumberList: [],
      optedOutNumberList: [],
      templateParameters: [],
      isUploaded: false,
      csvHeaderError: false,
      firstRow: null,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      records: 'contacts/getContacts',
    }),
    buttonStyle() {
      return { padding: '0.8rem 1.2rem', width: '12rem' };
    },
    disableNext() {
      return (
        !this.isUploaded ||
        this.csvErrorMessages.length > 0 ||
        this.fileUploadFailed
      );
    },
    showUploadModal() {
      if (
        !this.fileUploadFailed &&
        (this.csvErrorMessages.length > 0 ||
          this.csvSoftErrorMessages.length > 0)
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showModal = true;
        return true;
      }
      return false;
    },
    csvHeader() {
      let csv = 'CountryCode,Phone';
      if (this.needsMediaHeader) {
        csv += ',MediaURL';
      }
      for (let i = 0; i < this.paramsCount; i += 1) {
        csv += `,Input${i + 1}`;
      }

      return csv;
    },
  },
  methods: {
    async downloadCSVTemplate() {
      this.processCsv(this.csvHeader, 'broadcastTemplate.csv');
    },

    onCSVUpload(files) {
      this.selectedFiles = files?.files ? files?.files : files;
      this.csvErrorMessages = [];
      this.csvSoftErrorMessages = [];
      this.defectiveNumberList = [];
      this.fileUploadFailed = false;
      this.defectiveNumberLength = null;
      this.csvLoading = true;
      this.templateParameters = [];
      this.phoneNumberList = [];
      this.isUploaded = true;
      let selectedFile = files[0]?.file || files?.file;
      this.selectedFileName = selectedFile?.name || files?.file?.name;
      let reader = new FileReader();
      if (selectedFile) {
        reader.readAsBinaryString(selectedFile);
      }
      reader.onload = async event => {
        let data = event.target.result;
        let workbook = XLSX.read(data, {
          type: 'binary',
        });
        let sheet = workbook.SheetNames[0];
        let csv_text = XLSX.utils.sheet_to_csv(workbook.Sheets[sheet]);
        // getting the names of all columns present in the worksheet
        // if the data is converted to row_object, a column which has all empty fields will never appear as a key in the object, so converting it to csv first
        let headersPresentList = csv_text
          .split('\n')[0]
          .split('\r')[0]
          .split(',');
        let XL_row_object = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheet]
        );

        let headersWantedList = this.csvHeader.split(',');
        let allValuesMatch = true;

        // Check if all values in headersWantedList are in headersPresentList
        let notMatch = [];

        headersWantedList.forEach(header => {
          if (!headersPresentList.includes(header)) {
            allValuesMatch = false;
            notMatch.push(header);
          }
        });

        let missingList = {};
        headersWantedList.forEach(header => {
          missingList[header] = [];
        });

        let invalidUrlsList = [];

        let numberList = [];
        // numberList will have all non zero numbers and it will be passed to a function which will classify all removed numbers into optout numbers or invalid numbers
        let broadcastLimit = 300000;
        let maxShow = 3;
        // maxShow is the maximum numbers of phone contacts that will be shown during errors,
        // for example, if there are 10 invalid urls, then the first 3 contacts will be shown and 7 more errors will be notified through the message

        if (XL_row_object.length > broadcastLimit) {
          this.csvErrorMessages.push({
            type: 'broadcast_limit_overflow',
            content: this.$t(
              'BROADCAST.BROADCAST_TAB.UPLOAD_CSV.SIZE_TOO_LARGE'
            ),
          });
          this.csvLoading = false;
        }

        if (!XL_row_object.length) {
          this.csvErrorMessages.push({
            type: 'empty_csv',
            content: 'Empty CSV submitted!',
          });
          this.csvLoading = false;
        }
        this.csvHeaderError = !allValuesMatch;
        if (this.csvHeaderError) {
          let notFound = 'Please ensure the presence of column(s) : ';

          notFound += notMatch.join(', ');

          this.csvErrorMessages.push({
            type: 'csv_header',
            content:
              'Please make sure all CSV headers are according to the correct format. Note that headers are case-sensitive and space-sensitive. Remove extra whitespaces if any.',
          });

          this.csvErrorMessages.push({
            type: 'csv_header',
            content: notFound,
          });
          this.csvLoading = false;
          this.csvHeaderError = false;
        }

        let seen = new Set();
        XL_row_object = XL_row_object.filter(el => {
          if (el.Phone) {
            let phone = el.Phone.toString();
            let code = el.CountryCode ? el.CountryCode.toString() : '91';

            let phtemp = code + phone;
            phtemp = phtemp.replaceAll(',', '');
            numberList.push(phtemp);
            return seen.has(phone) ? false : seen.add(phone);
          }
          return false;
        });
        // the above code will keep only the details attached with the first occurence of a number and discard all other details attached with other occurences

        let allOptOutSet = new Set(
          Array.isArray(this.optOutListProp) ? this.optOutListProp : []
        );
        XL_row_object = XL_row_object.filter(el => {
          let phone = el.Phone;
          let code = el.CountryCode ? el.CountryCode : 91;
          return this.verifyPhoneNumber(code, phone, allOptOutSet);
        });

        // verifyphoneNumber function handles conversion of phone and code to string internally
        // the above code will only keep numbers which have been verified by verifyPhoneNumber functions and discard all other details of the numbers which were found to be incorrect

        if (this.csvLoading) {
          XL_row_object.forEach(el => {
            let params = Array(headersWantedList.length - 2).fill(' ');
            // subtracting 2 because the first two are fixed columns of countryCode and Phone

            let phone = el.Phone.toString();

            headersWantedList.slice(2).forEach((header, i) => {
              let element =
                el[header] === 0 ? el[header].toString() : el[header];
              // The above statement might need further check

              if (element) {
                // Encoded the param from utf-8 to utf-16le
                params[i] = this.encodeUTF8ToUTF16(el[header]);
                if (header === 'MediaURL' && !isValidURL(element)) {
                  invalidUrlsList.push(phone);
                  // isvalidurl just checks for the format(please see the corresponding function)
                  // it does not check for the validity of the url
                }
              } else {
                missingList[header].push(phone);
              }
            });
            this.templateParameters.push(params);
          });
        }
        this.firstRow = XL_row_object[0];

        headersWantedList.slice(2).forEach(header => {
          let missingCount = missingList[header].length;
          if (missingCount) {
            let content = header + ' missing for : ';

            let show = missingCount > maxShow ? maxShow : missingCount;

            content += missingList[header].slice(0, show).join(', ');

            if (missingCount > maxShow) {
              let number = missingCount - maxShow;
              number = number.toString();
              content += ' and ' + number + ' more contacts';
            }
            this.csvSoftErrorMessages.push({
              type: 'column',
              content,
            });
          }
        });

        let invalidUrlsCount = invalidUrlsList.length;
        if (invalidUrlsCount) {
          let missingCount = invalidUrlsCount;
          let content = 'Found invalid URLs for : ';

          let show = missingCount > maxShow ? maxShow : missingCount;

          content += invalidUrlsList.slice(0, show).join(', ');

          if (missingCount > maxShow) {
            let number = missingCount - maxShow;
            number = number.toString();
            content += ' and ' + number + ' more contacts';
          }
          this.csvSoftErrorMessages.push({
            type: 'invalid_url',
            content,
          });
        }

        XL_row_object.forEach(el => {
          let phoneString = el.Phone.toString();
          let code = el.CountryCode ? el.CountryCode.toString() : '91';
          phoneString = phoneString.replace(/\+/g, '');
          phoneString = phoneString.replace(/-/g, '');
          phoneString = phoneString.replace(/'/g, '');
          phoneString = phoneString.replace(/ /g, '');
          phoneString = code + phoneString;
          phoneString = phoneString.replaceAll(',', '');
          this.phoneNumberList.push(phoneString);
        });

        // this might create some issue, since variable "code" is not being checked for regex replacement
        // Also, the use of replace at some places and replaceAll at other places should be verified
        // Why not just keep integers and remove all in regex?

        this.phoneNumbersCount = numberList.length;

        let {
          defectiveNumberList,
          errorState,
          errorMessages,
          optedOutNumbers,
        } = this.verifyPhoneNumbers(numberList.join(','), false, allOptOutSet);

        this.defectiveNumberList = defectiveNumberList;
        this.optedOutNumberList = optedOutNumbers;

        // TODO: Remove this false condition and make it work for checking publicly accessible urls
        // Why we did this? :- ValidateResourceUrl api was throwing CORS error for many urls.

        // if (this.selectedTemplate.template_type !== 'text') {
        //   let urls = [XL_row_object[0]?.Input1] || [];
        //   if (!(await this.validateResourceURLs(urls))) {
        //     this.csvSoftErrorMessages.push({
        //       type: 'media_url',
        //       content:
        //         'Please make sure all media URLs are valid and publicly accessible',
        //     });
        //   }
        // }

        if (
          (errorState && errorMessages.length > 0) ||
          this.optedOutNumberList.length > 0
        ) {
          this.defectiveNumberLength = errorMessages.length;

          if (errorMessages.length) {
            this.csvSoftErrorMessages.push({
              type: 'phone',
              content: `${errorMessages.length} ${
                errorMessages.length > 1 ? 'contacts' : 'contact'
              } found invalid.`,
            });
          }
          if (this.optedOutNumberList.length > 0) {
            this.csvSoftErrorMessages.push({
              type: 'opt_out',
              content: `${this.optedOutNumberList.length} opted out ${
                this.optedOutNumberList.length > 1 ? 'customers' : 'customer'
              } found. We won't send broadcast message to  ${
                this.optedOutNumberList.length > 1 ? 'these' : 'this'
              }  ${
                this.optedOutNumberList.length > 1 ? 'customers' : 'customer'
              } `,
            });
          }
        }
        this.csvLoading = false;
      };
    },

    downloadErrorCsv(type) {
      let defectiveNumberHeader = 'Sr. no, Incorrect Contacts \n';
      let optedOutHeader = 'Sr. no, Opted Out Contacts\n';
      let defectiveNumberCsv = '';
      let optedOutCsv = '';
      this.defectiveNumberList.forEach((number, index) => {
        defectiveNumberCsv += index + 1 + ',' + number + '\n';
      });
      this.optedOutNumberList.forEach((number, index) => {
        optedOutCsv += index + 1 + ',' + number + '\n';
      });
      this.processCsv(
        type === 'phone'
          ? defectiveNumberHeader + defectiveNumberCsv
          : optedOutHeader + optedOutCsv,
        type === 'phone'
          ? 'incorrect-number-list.csv'
          : 'opted-out-number-list.csv'
      );
    },
    hideUploadModal() {
      this.showModal = false;
    },
    continueCSV() {
      if (
        this.csvSoftErrorMessages.length > 0 &&
        this.defectiveNumberLength > 0
      ) {
        this.showConfirmationModal = true;
        return;
      }
      this.proceedWithErrors();
    },
    proceedWithErrors() {
      this.showConfirmationModal = false;
      this.$emit(
        'next',
        this.templateParameters,
        this.phoneNumberList,
        this.selectedFiles,
        this.phoneNumbersCount,
        this.firstRow
      );
    },

    closeModal() {
      this.showConfirmationModal = false;
    },
    goToNext() {
      if (
        this.csvSoftErrorMessages.length > 0 &&
        this.defectiveNumberLength > 0
      ) {
        this.showConfirmationModal = true;
        return;
      }
      this.$emit(
        'next',
        this.templateParameters,
        this.phoneNumberList,
        this.selectedFiles,
        this.phoneNumbersCount,
        this.firstRow
      );
    },
    goToPrevious() {
      this.$emit('prev');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';
.csv-preview {
  min-width: 52rem;
}

.primary-button {
  border-radius: $border-radius-smaller;
  background: $pg-1-500;
  cursor: pointer;
  white-space: nowrap;
  color: $color-white;
  font-style: normal;
  justify-content: center;
  min-width: 8rem;
  padding: $space-smaller $space-small;
}
.form-box {
  position: absolute;
  top: $space-small;
  right: $zero;
}
.modal-header {
  @include elevation-1dp;
}

.custom-scroll {
  overflow: auto;

  &::-webkit-scrollbar {
    height: 0.1em;
    transition: all 0.5s;
    width: 0.1em;
    z-index: 10;
  }

  & ::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $neutral-grey-400;
    border-radius: 0.1em;
  }
}

.phone-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $space-small;
}
.message-display-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: $space-small;

  .finalize-title {
    font-size: $font-size-default;
    color: $color-gray;
    margin: $space-one $space-smaller;
  }

  .selected-message {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .selected-message-content {
      background: $color-border-light;
      border-radius: $space-small;
      padding: 1.4rem;
      margin: $space-smaller $space-one;

      span {
        font-size: $font-size-small;
        margin-top: $space-smaller;
      }
    }
  }

  .test-message {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .test-message-content {
      display: flex;
      justify-content: space-between;

      .phone-number-input {
        margin: 0;
        height: $space-slab * 3;
        width: 100%;
        padding-left: $space-large;
        padding: $space-small $space-two;
      }

      button {
        margin-left: $space-one;
      }
    }
  }
}
.fileupload {
  display: flex;
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius;
  color: $pg-1-500;
  font-size: $font-size-mini;
  font-weight: $font-weight-normal;
  line-height: 1.6;
  padding: $zero;
  background: transparent;
}
.broadcast-footer {
  display: flex;
  width: 100%;
}
.flex-row-justify-center {
  font-size: $font-size-small;
  align-items: center;
}
.selector-status {
  height: unset;
  margin-bottom: $zero;
}
.content-margin {
  margin-bottom: $space-medium;

  button {
    width: auto;
    margin-right: $space-slab;
    padding: $space-smaller $space-small;
  }
}
.settings-form {
  padding: $space-medium;
}
.status-text {
  font-size: $font-size-small;
  line-height: 2.2rem;
  margin-left: $space-smaller;
  color: $pg-1-500;

  &--color {
    color: $warn-red-500;
  }

  &--hide {
    visibility: hidden;
  }
}

.template-prev {
  margin-bottom: $space-medium;

  align-items: flex-start;
  justify-content: flex-start;

  &--sc {
    min-width: 20%;
    margin-right: $space-medium;
  }
}

.canned {
  max-height: $space-two * 10;

  strong {
    &:first-child {
      text-align: center;
      min-width: 8%;
      max-width: 8%;
    }
  }
}
.selected-message ::v-deep .brackets--highlight {
  font-weight: $font-weight-bold;
  color: $warn-yellow-500;
}

.broadcast-size-warning {
  color: $warn-yellow-500;
  border: 1px solid $warn-yellow-500;
  padding: $space-smaller;
  margin-top: $space-medium;
  text-align: center;
  border-radius: $space-three;
}

.broadcast-soft-error {
  color: $warn-red-500;
}
.next-button {
  position: absolute;
  bottom: $space-slab;
  right: 7rem;
}
</style>
