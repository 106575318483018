<template>
  <section class="assign-agent-section title-h5_m text-dark">
    <div class="label-container">
      <span class="title-h5_m text-dark" v-text="titleText" />
      <conversation-labels
        v-if="isTagTypeConv"
        show-add-button
        class="label-input"
        placeholder="No tags added yet"
        :selected-labels="selectedLabelsCopy"
        @updateLabels="updateLabels"
      />
      <contact-labels
        v-if="isTagTypeContact"
        show-add-button
        class="label-input"
        placeholder="No tags added yet"
        :contact-labels="selectedLabelsCopy"
        @updateLabels="updateLabels"
      />
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';

import ConversationLabels from 'dashboard/routes/dashboard/conversation/labels/LabelBox';
import ContactLabels from 'dashboard/routes/dashboard/contacts/labels/LabelBox';

import { TYPES } from 'dashboard/routes/dashboard/settings/labels/constants';

export default {
  components: { ConversationLabels, ContactLabels },
  props: {
    tagType: {
      type: String,
      default: TYPES.conversation,
    },
  },
  data() {
    return {
      selectedLabels: [],
    };
  },
  computed: {
    ...mapGetters({
      allContactLabels: 'labels/getContactLabels',
      allConvLabels: 'labels/getLabels',
    }),
    activeLabelList() {
      return this.isTagTypeConv ? this.allConvLabels : this.allContactLabels;
    },
    titleText() {
      return this.isTagTypeConv
        ? this.$t('CREATE_TICKET.FORM.SECTIONS.ASSIGN_CONV_TAGS.HEADING')
        : this.$t('CREATE_TICKET.FORM.SECTIONS.ASSIGN_CONTACT_TAGS.HEADING');
    },
    isTagTypeConv() {
      return this.tagType === TYPES.conversation;
    },
    isTagTypeContact() {
      return this.tagType === TYPES.contact;
    },
    selectedLabelsCopy() {
      return [...this.selectedLabels];
    },
  },
  methods: {
    findLabelById(labels, id) {
      let foundLabel = labels.find(label => label.id === id);

      // If not found, recursively search in child labels
      if (!foundLabel) {
        labels.some(label => {
          if (label.labels && label.labels.length) {
            const foundInChild = this.findLabelById(label.labels, id);
            if (foundInChild) {
              foundLabel = foundInChild;
              return true; // Stop searching further if the label is found
            }
          }
          return false;
        });
      }

      return foundLabel;
    },
    includeParent(parentId, selectedLabelIdSet) {
      if (!parentId) return;

      const parentLabel = this.findLabelById(this.activeLabelList, parentId);

      // If the parent is not included already, include it. Otherwise, discard.
      if (parentLabel) {
        if (!selectedLabelIdSet.has(parentLabel.id)) {
          this.selectedLabels.push(parentLabel);
        }
        this.includeParent(parentLabel.parent_id, selectedLabelIdSet);
      }
    },
    updateLabels(label, action) {
      if (action === 'add') {
        this.selectedLabels.push(label);
        if (label.parent_id) {
          this.includeParent(
            label.parent_id,
            new Set(this.selectedLabels.map(({ id }) => id))
          );
        }
      } else if (action === 'remove') {
        this.selectedLabels = this.selectedLabels.filter(
          ({ id }) => id !== label.id
        );
      }
      this.$emit('selectedLabels', this.selectedLabels);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.label-container {
  position: relative;
  max-width: $space-larger * 10;

  .label-input {
    margin-top: $space-small;
    padding: $space-smaller $space-small;
    border-radius: $space-smaller;
    background: $neutral-grey-100;
    border: 1px solid $neutral-grey-400;
    max-width: $space-medium * 10;
  }

  ::v-deep .add-btn {
    top: $zero;
    right: $space-mega * 3 - $space-one;
  }
}
</style>
