<template>
  <div v-if="data.senderMessage" class="reply-message">
    <span class="reply-message--name text-truncate">
      {{ `Replying to ${data.senderName}` }}
    </span>
    <div>
      <p v-if="data.type === 'text'" class="reply-message--content">
        {{ data.senderMessage }}
      </p>
      <div v-else-if="data.type === 'image'" class="reply-message--image">
        <img :src="data.senderMessage" />
      </div>
      <div v-else class="reply-message--docs">
        <div v-if="!fileType" class="file message-text__wrap">
          <div class="icon-wrap">
            <i class="ion-document-text"></i>
          </div>
          <h5 class="text-block-title">
            {{ decodeURI(fileName) }}
          </h5>
        </div>
        <video-view v-else :url="data.senderMessage" class="file" />
      </div>
    </div>
    <button
      v-if="enableCancel && data.enableCancel"
      type="button"
      class="reply-message--button"
      @click="onCancel"
    >
      <icons name="cancel" color="stroke-red" size="semimedium" />
    </button>
  </div>
</template>

<script>
import VideoView from 'shared/components/VideoView';

export default {
  components: {
    VideoView,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
        return {
          senderName: '',
          senderMessage: '',
          enableCancel: true,
        };
      },
    },
    enableCancel: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    fileName() {
      const filename = this.data.senderMessage.substring(
        this.data.senderMessage.lastIndexOf('/') + 1
      );
      return filename;
    },
    fileType() {
      const theFileType = this.data.senderMessage.substring(
        this.data.senderMessage.lastIndexOf('.') + 1
      );
      if (theFileType === 'mp4') {
        return true;
      }
      return false;
    },
  },
  methods: {
    onCancel() {
      this.$emit('close', false);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.reply-message {
  padding-left: $space-small;
  margin: $space-smaller $zero $space-smaller $space-slab;
  max-width: 95%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-left: 2px solid $secondary-blue;

  .reply-message--name {
    display: flex;
    text-align: start;
    color: $secondary-blue;
    font-size: $font-size-mini;
    font-weight: $font-weight-black;
    line-height: 2;
  }

  .reply-message--content {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: start;
    color: $neutral-grey-700;
    font-size: $font-size-mini;
    word-break: break-word;
    margin: 0;
  }

  .reply-message--image {
    border-radius: $border-radius;
    overflow: hidden;
    max-height: 15rem;
    max-width: 100%;
  }

  .reply-message--docs {
    max-width: 95%;
    display: flex;
  }

  .reply-message--button {
    position: absolute;
    top: $space-slab;
    right: $space-slab;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }
}

.file {
  display: flex;
  flex-direction: row;

  .icon-wrap {
    font-size: $font-size-giga;
    color: $secondary-blue;
    line-height: 1;
    margin-right: $space-small;
  }

  .text-block-title {
    margin: 0;
    color: $secondary-blue;
    word-break: break-word;
  }
}
</style>
