<template>
  <woot-modal-new
    :show.sync="show"
    :on-close="onClose"
    :disable-btn="{
      0: !buttons.buttons,
      1: editTemplate.showLoading,
    }"
    :navigate-to="selectedTemplate.navigateTo"
    @submit="editTemplateResponse"
  >
    <woot-modal-header-new :header-title="$t('TEMPLATE.EDIT.TITLE')" />
    <template v-slot:paginated-components>
      <div class="template-form">
        <div class="settings-form custom-scroll">
          <div
            class="settings-title title-h3 text-dark"
            v-text="'Template Details'"
          />
          <page-header
            :header-title="$t('TEMPLATE.EDIT.FORM.SHORT_CODE.LABEL')"
            size="small"
            style="padding-top: 0"
          >
            <div
              style="line-break: anywhere"
              class="body-b3 text-light"
              v-text="shortCode"
            />
          </page-header>
          <div
            class="flex-row flex-align gap--two"
            :class="{ 'content-margin': !isMediaTemplate }"
          >
            <page-header
              :header-title="$t('TEMPLATE.EDIT.FORM.TYPE.LABEL')"
              size="small"
            >
              <div
                class="body-b3 text-light capitalize"
                v-text="selectedType"
              />
            </page-header>
            <span style="color: #f0f0f0" class="page-title">|</span>
            <page-header
              :header-title="$t('TEMPLATE.EDIT.FORM.INBOX.LABEL')"
              size="small"
            >
              <div
                class="body-b3 text-light capitalize"
                v-text="selectedInbox"
              />
            </page-header>
          </div>
          <page-header
            v-if="isMediaTemplate"
            :header-title="$t('TEMPLATE.ADD.FORM.MEDIA.LABEL')"
            class="content-margin"
            size="small"
          >
            <div class="body-b3 text-light capitalize" v-text="mediaUrl" />
          </page-header>
          <woot-input
            v-if="header"
            v-model.trim="header"
            class="medium-12 columns content-margin"
            :label="$t('TEMPLATE.EDIT.FORM.HEADER.LABEL')"
            :help-text="$t('TEMPLATE.EDIT.FORM.HEADER.DESCRIPTION')"
            :placeholder="$t('TEMPLATE.EDIT.FORM.HEADER.PLACEHOLDER')"
            readonly
          />
          <woot-input
            v-model.trim="body"
            rows="6"
            type="text"
            class="medium-12 columns content-margin"
            :label="$t('TEMPLATE.EDIT.FORM.BODY.LABEL')"
            :placeholder="$t('TEMPLATE.EDIT.FORM.BODY.PLACEHOLDER')"
            readonly
          />
          <woot-input
            v-if="footer"
            v-model.trim="footer"
            class="medium-12 columns content-margin"
            :label="$t('TEMPLATE.EDIT.FORM.FOOTER.LABEL')"
            :help-text="$t('TEMPLATE.EDIT.FORM.FOOTER.DESCRIPTION')"
            :placeholder="$t('TEMPLATE.EDIT.FORM.FOOTER.PLACEHOLDER')"
            readonly
          />
        </div>
      </div>
      <form
        v-if="buttons && buttons.buttons"
        class="template-form"
        @submit.prevent="editTemplateResponse"
      >
        <div class="flex-column settings-form custom-scroll">
          <div
            class="settings-title title-h3 text-dark"
            v-text="'Edit Button Payload'"
          />
          <div
            v-for="(button, inx) in buttons.buttons"
            :key="inx"
            class="flex-row flex-align gap--two flex-justify--between gap--small mg-bottom"
          >
            <woot-base-button
              v-tooltip.bottom="button.url"
              style="flex-shrink: 0"
              variant="secondary"
              icon-size="semimedium"
              icon-color="grey"
              :front-icon="buttons.type === 'call_to_action' ? 'reply' : ''"
              @click.prevent="() => {}"
            >
              {{ button.title }}
            </woot-base-button>
            <woot-input
              v-model.trim="button.payload"
              full-width
              :placeholder="$t('TEMPLATE.EDIT.FORM.BUTTON.PAYLOAD.PLACEHOLDER')"
            />
          </div>
        </div>
      </form>
    </template>
  </woot-modal-new>
</template>

<script>
/* eslint no-console: 0 */
import { mapGetters } from 'vuex';
import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  props: {
    selectedTemplate: {
      type: Object,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      editTemplate: {
        showLoading: false,
        message: '',
      },
      shortCode: this.selectedTemplate.short_code,
      type: this.selectedTemplate.template_type,
      body: this.selectedTemplate.body,
      mediaUrl: this.selectedTemplate.media_url,
      buttons: this.selectedTemplate.buttons,
      header: this.selectedTemplate.header,
      footer: this.selectedTemplate.footer,
      inboxId: this.selectedTemplate.inbox_id,
    };
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
    }),
    inboxList() {
      let r = this.inboxes.reduce((ibList, ib) => {
        ibList.push({
          id: ib.id,
          name: ib.name,
          type: ib.channel_type,
          channel_id: ib.channel_id,
        });
        return ibList;
      }, []);
      return r;
    },
    selectedInbox() {
      let selectedIndex = this.inboxList.findIndex(i => i.id === this.inboxId);

      return selectedIndex > -1 ? this.inboxList[selectedIndex].name : 'N/A';
    },
    typeList() {
      return [
        { id: 0, name: 'text' },
        { id: 1, name: 'image' },
        { id: 2, name: 'video' },
        { id: 3, name: 'document' },
      ];
    },
    selectedType() {
      let selectedIndex = this.typeList.findIndex(i => i.name === this.type);

      return selectedIndex > -1 ? this.typeList[selectedIndex].name : 'N/A';
    },
    isMediaTemplate() {
      return ['image', 'video', 'document'].includes(this.selectedType);
    },
  },
  methods: {
    editTemplateResponse() {
      this.editTemplate.showLoading = true;
      this.$store
        .dispatch('updateTemplate', {
          id: this.selectedTemplate.id,
          template_buttons: this.buttons,
        })
        .then(() => {
          // Reset Form, Show success message
          this.editTemplate.showLoading = false;
          this.editTemplate.message = this.$t(
            'TEMPLATE.EDIT.API.SUCCESS_MESSAGE'
          );
          this.showAlert(this.editTemplate.message, 'success');
          this.onClose();
        })
        .catch(err => {
          this.editTemplate.showLoading = false;

          if (err) {
            this.editTemplate.message = err;
            this.showAlert(this.editTemplate.message, 'error');
          } else {
            this.editTemplate.message = this.$t(
              'TEMPLATE.EDIT.API.ERROR_MESSAGE'
            );
            this.showAlert(this.editTemplate.message, 'error');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '~dashboard/assets/scss/variables';

.modal-content-wrapper {
  overflow: overlay;
}

::v-deep .page-header-container--small {
  padding-bottom: $space-slab;
}

.settings-form {
  height: $space-large * 10;
  padding: $zero $space-two;
}

.settings-title {
  display: block;
  margin-bottom: $space-two;
}

.template-form {
  padding: $space-two $space-jumbo;
}

.content-margin {
  margin-bottom: $space-medium;
}
</style>
