<template>
  <woot-single-selector
    variant="secondary"
    size="small"
    :default-option="
      $t(`SLA.CREATE.SECTIONS.TIME_DURATION.${value.toString()}`)
    "
    :option-list="options"
    :custom-style="{ left: 0, width: '20rem' }"
    :show-selected-text="false"
    @click="onClick"
  />
</template>
<script>
import { TIME_DURATION_OPTIONS } from '../../../../../utils/constants';

export default {
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    includeImmediateOption: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    options() {
      return this.includeImmediateOption
        ? TIME_DURATION_OPTIONS
        : TIME_DURATION_OPTIONS.filter(option => option.value !== 0);
    },
  },
  methods: {
    onClick(value) {
      this.$emit('click', value);
    },
  },
};
</script>
