import parse from 'date-fns/parse';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import timeZoneData from './timezones.json';

export const defaultTimeSlot = [
  {
    day: 0,
    working_hour_slots: [
      {
        to: '',
        from: '',
        valid: false,
      },
    ],
  },
  {
    day: 1,
    working_hour_slots: [
      {
        to: '',
        from: '',
        valid: false,
      },
    ],
  },
  {
    day: 2,
    working_hour_slots: [
      {
        to: '',
        from: '',
        valid: false,
      },
    ],
  },
  {
    day: 3,
    working_hour_slots: [
      {
        to: '',
        from: '',
        valid: false,
      },
    ],
  },
  {
    day: 4,
    working_hour_slots: [
      {
        to: '',
        from: '',
        valid: false,
      },
    ],
  },
  {
    day: 5,
    working_hour_slots: [
      {
        to: '',
        from: '',
        valid: false,
      },
    ],
  },
  {
    day: 6,
    working_hour_slots: [
      {
        to: '',
        from: '',
        valid: false,
      },
    ],
  },
];

export const slotList = [
  { id: 24, name: '30 minutes', value: 0.5 },
  { id: 1, name: '1 hour', value: 1 },
  { id: 2, name: '1 hour 30 minutes', value: 1.5 },
  { id: 3, name: '2 hour', value: 2 },
  { id: 4, name: '2 hour 30 minutes', value: 2.5 },
  { id: 5, name: '3 hour', value: 3 },
  { id: 6, name: '3 hour 30 minutes', value: 3.5 },
  { id: 7, name: '4 hour', value: 4 },
  { id: 8, name: '4 hour 30 minutes', value: 4.5 },
  { id: 9, name: '5 hour', value: 5 },
  { id: 10, name: '5 hour 30 minutes', value: 5.5 },
  { id: 11, name: '6 hour', value: 6 },
  { id: 12, name: '6 hour 30 minutes', value: 6.5 },
  { id: 13, name: '7 hour', value: 7 },
  { id: 14, name: '7 hour 30 minutes', value: 7.5 },
  { id: 15, name: '8 hour', value: 8 },
  { id: 16, name: '8 hour 30 minutes', value: 8.5 },
  { id: 17, name: '9 hour', value: 9 },
  { id: 18, name: '9 hour 30 minutes', value: 9.5 },
  { id: 19, name: '10 hour', value: 10 },
  { id: 20, name: '10 hour 30 minutes', value: 10.5 },
  { id: 21, name: '11 hour', value: 11 },
  { id: 22, name: '11 hour 30 minutes', value: 11.5 },
  { id: 23, name: '12 hour', value: 12 },
  { id: 25, name: '12 hour 30 minutes', value: 12.5 },
  { id: 26, name: '13 hour', value: 13 },
  { id: 27, name: '13 hour 30 minutes', value: 13.5 },
  { id: 28, name: '14 hour', value: 14 },
  { id: 29, name: '14 hour 30 minutes', value: 14.5 },
  { id: 30, name: '15 hour', value: 15 },
  { id: 31, name: '15 hour 30 minutes', value: 15.5 },
  { id: 32, name: '16 hour', value: 16 },
  { id: 33, name: '16 hour 30 minutes', value: 16.5 },
  { id: 34, name: '17 hour', value: 17 },
  { id: 35, name: '17 hour 30 minutes', value: 17.5 },
  { id: 36, name: '18 hour', value: 18 },
  { id: 37, name: '18 hour 30 minutes', value: 18.5 },
  { id: 38, name: '19 hour', value: 19 },
  { id: 39, name: '19 hour 30 minutes', value: 19.5 },
  { id: 40, name: '20 hour', value: 20 },
  { id: 41, name: '20 hour 30 minutes', value: 20.5 },
  { id: 42, name: '21 hour', value: 21 },
  { id: 43, name: '21 hour 30 minutes', value: 21.5 },
  { id: 44, name: '22 hour', value: 22 },
  { id: 45, name: '22 hour 30 minutes', value: 22.5 },
  { id: 46, name: '23 hour', value: 23 },
  { id: 47, name: '23 hour 30 minutes', value: 23.5 },
  { id: 48, name: '24 hour', value: 24 },
];

export const generateTimeSlots = (step = 15) => {
  /* 
    Generates a list of time strings from 12:00 AM to next 24 hours. Each new string
     will be generated by adding `step` minutes to the previous one.
    The list is generated by starting with a random day and adding step minutes till end of the same day.
  */
  const date = new Date(1970, 1, 1);
  const slots = [];
  while (date.getDate() === 1) {
    slots.push(
      date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
    );
    date.setMinutes(date.getMinutes() + step);
  }
  return slots;
};

export const getTime = (hour, minute) => {
  const merdian = hour > 11 ? 'PM' : 'AM';
  const modHour = hour > 12 ? hour % 12 : hour || 12;
  const parsedHour = modHour < 10 ? `0${modHour}` : modHour;
  const parsedMinute = minute < 10 ? `0${minute}` : minute;
  return `${parsedHour}:${parsedMinute} ${merdian}`;
};

export const timeSlotParse = timeSlots => {
  return timeSlots
    ? timeSlots.map(slot => {
        const {
          day_of_week: day_of_week,
          working_hour_slots: working_hour_slots,
        } = slot;

        let closed_all_day = false;

        if (slot.working_hour_slots.length === 0) {
          closed_all_day = true;
        }

        let new_slots = [];
        let sequence = 0;

        if (!closed_all_day) {
          working_hour_slots.forEach(item => {
            let from = '';
            let to = '';
            let slotObj = {
              open_hour: item.open_hour,
              open_minutes: item.open_minutes,
              close_hour: item.close_hour,
              close_minutes: item.close_minutes,
            };

            from = getTime(slotObj.open_hour, slotObj.open_minutes);
            to = getTime(slotObj.close_hour, slotObj.close_minutes);
            sequence += 1;

            new_slots.push({
              from,
              to,
              sequence,
            });
          });
        }

        return {
          day: day_of_week,
          working_hour_slots: new_slots,
          closed_all_day,
        };
      })
    : [];
};

export const timeSlotTransform = timeSlots => {
  return timeSlots.map(slot => {
    const closed = slot.working_hour_slots[0]
      ? !(slot.working_hour_slots[0].from && slot.working_hour_slots[0].to)
      : false;

    const isDayClosed = closed || slot.closed_all_day;

    let fromDate = '';
    let toDate = '';
    let daySlots = [];

    if (!isDayClosed) {
      slot.working_hour_slots.forEach(singleSlot => {
        let slotObj = {
          open_hour: '',
          open_minutes: '',
          close_hour: '',
          close_minutes: '',
        };

        fromDate = parse(singleSlot.from, 'hh:mm a', new Date());
        toDate = parse(singleSlot.to, 'hh:mm a', new Date());
        slotObj.open_hour = getHours(fromDate);
        slotObj.open_minutes = getMinutes(fromDate);
        slotObj.close_hour = getHours(toDate);
        slotObj.close_minutes = getMinutes(toDate);

        daySlots.push(slotObj);
      });
    }

    return {
      day_of_week: slot.day,
      closed_all_day: isDayClosed,
      working_hour_slots: daySlots,
    };
  });
};

export const timeZoneOptions = () => {
  return Object.keys(timeZoneData).map(key => ({
    label: key,
    value: timeZoneData[key],
  }));
};
