<template>
  <div id="app" class="app-wrapper app-root">
    <network-notification />
    <div v-if="loading" class="full-height full-width">
      <LoadingState message="Getting this ready..." />
    </div>

    <template v-else-if="signupOnboarding">
      <SignupOnboarding :user="user" />
    </template>

    <template v-else>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
      <snackbar-box />
      <template v-if="hasVoiceCallInboxes">
        <calling-popup :inbox-list="voiceInboxList" />
        <calling-notification />
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { accountIdFromPathname } from './helper/URLHelper';
import LogRocket from 'logrocket';

import auth from './api/auth';
import SnackbarBox from './components/SnackbarContainer';
import LoadingState from './components/ui/LoadingState';
import SignupOnboarding from './routes/auth/SignupOnboarding';
import CallingPopup from 'dashboard/components/ui/calling/CallingPopup';
import CallingNotification from 'dashboard/components/ui/calling/CallingNotification';
import { handleLoginError } from './store/utils/api';
import { initializeDelightedSurvey } from './loadWidgets';
import NetworkNotification from 'dashboard/components/NetworkNotification.vue';
import callMixin from 'shared/mixins/callMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import { CALL_PROVIDERS } from 'dashboard/constants';

export default {
  name: 'App',
  components: {
    SnackbarBox,
    LoadingState,
    SignupOnboarding,
    CallingPopup,
    CallingNotification,
    NetworkNotification,
  },
  mixins: [callMixin, inboxMixin],
  data() {
    return {
      user: null,
      isLoading: false,
      signupOnboarding: false,
      authCheckInterval: null,
      isLogRocketInitialized: false,
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentUser: 'getCurrentUser',
      knowlarityInboxes: 'inboxes/getKnowlarityInboxes',
      exotelInboxes: 'inboxes/getExotelInboxes',
    }),
    loading() {
      return this.$auth.loading || this.isLoading;
    },
    voiceInboxList() {
      const knowlarityMapped = this.getUserAccessibleInboxes(
        this.knowlarityInboxes,
        CALL_PROVIDERS.KNOWLARITY
      );
      const exotelMapped = this.getUserAccessibleInboxes(
        this.exotelInboxes,
        CALL_PROVIDERS.EXOTEL
      );

      return [...knowlarityMapped, ...exotelMapped];
    },
  },
  watch: {
    '$auth.loading': async function handleAuthenticationChange(loading) {
      if (!loading) {
        await this.setupAuthenticatedApp();
      }
    },
    '$route.name': function handleRouteChange() {
      this.handleRoute();
    },
  },
  created() {
    if (process.env.LOGROCKET_APP_ID) {
      LogRocket.init(process.env.LOGROCKET_APP_ID);
      this.isLogRocketInitialized = true;
    }

    if (!this.$auth.loading) {
      this.setupAuthenticatedApp();
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.authCheckInterval = setInterval(this.silentlyAuthenticate, 180 * 1000); // 180 seconds
    this.handleRoute();
  },
  beforeDestroy() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    );
    clearInterval(this.authCheckInterval);
  },
  methods: {
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },

    handleRoute() {
      if (this.$route.params.accountId) {
        this.$store.dispatch(
          'setCurrentAccountId',
          this.$route.params.accountId
        );
      }
    },

    async setupAuthenticatedApp() {
      if (['error', 'install_shopify'].includes(this.$route.name)) return;

      if (!this.$auth.isAuthenticated) {
        window.location.href = '/';
        return;
      }

      this.isLoading = true;

      try {
        const user = await this.$store.dispatch('fetchCurrentUser');
        this.user = user;
        if (!user.account_id) {
          handleLoginError();
          return;
        }

        const isFirstLogin = this.$auth.getNamespaceAttribute('isFirstLogin');
        const accounts = user.accounts || [];
        const account = accounts.find(acc => acc.id === user.account_id);
        if (isFirstLogin && account && account.features?.signup_onboarding) {
          this.signupOnboarding = true;
        } else {
          await this.$store.dispatch('login', user);
          this.setLocale(window.chatwootConfig.selectedLocale);
          await this.initializeAccount();
        }
      } catch (error) {
        this.$store.dispatch('logout');
      } finally {
        this.isLoading = false;
        this.onInitializeDelightedSurvey();
      }
    },

    onInitializeDelightedSurvey() {
      const { pathname } = window.location;
      const accountId = accountIdFromPathname(pathname);
      initializeDelightedSurvey(
        accountId,
        this.currentUser.email,
        this.currentUser.name
      );
    },

    async initializeAccount() {
      const { pathname } = window.location;
      const accountId = accountIdFromPathname(pathname);

      if (accountId) {
        const response = await this.$store.dispatch('accounts/get');
        const { locale } = this.getAccount(accountId);
        this.setLocale(locale);

        if (this.isLogRocketInitialized && response.features.log_rocket) {
          LogRocket.identify(this.user.id, {
            name: this.user.name,
            email: this.user.email,
          });
        }

        // set ui visibility flags
        this.$store.dispatch('accounts/setUiVisibility', response);
      }
    },

    handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        this.silentlyAuthenticate();
      }
    },

    async silentlyAuthenticate() {
      try {
        await this.$auth?.getTokenSilently();
      } catch (e) {
        auth.logout({ withAlert: true, alertTimeout: 3000 });
      }
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/app';
@import 'vue2-timepicker/dist/VueTimepicker.css';
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
