<template>
  <div class="back-button" @click.capture="goBack">
    <span v-if="title">{{ $t('GENERAL_SETTINGS.BACK') }}</span>
    <icons name="chevronLeft" color="green" size="large" title="Go Back" />
  </div>
</template>
<script>
import router from '../../routes/index';

export default {
  props: {
    backUrl: {
      type: [String, Object],
      default: '',
    },
    title: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goBack() {
      if (this.backUrl !== '') {
        router.push(this.backUrl);
      } else {
        router.go(-1);
      }
    },
  },
};
</script>
