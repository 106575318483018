<template>
  <div
    class="test-mode-button"
    :class="{ active: isActive }"
    @click="$emit('onClick')"
  >
    Test Mode
  </div>
</template>
<script>
export default {
  props: {
    isActive: { type: Boolean, default: false },
  },
};
</script>
