<template>
  <div>
    <loading-state message="Taking you to Login..." />
  </div>
</template>

<script>
import LoadingState from '../components/ui/LoadingState';

export default {
  components: { LoadingState },
  mounted() {
    this.$auth.loginWithRedirect({
      appState: { targetUrl: window.location.pathname },
    });
  },
};
</script>
