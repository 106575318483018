<template>
  <div>
    <div class="text-align mg-bottom--larger">
      <div
        class="title-h1 text-dark mg-bottom"
        v-html="
          $t('ONBOARDING.WHATSAPP.WABA_INTEGRATION_COMPLETE.ERROR_HEADING')
        "
      />
      <div
        class="body-b2 text-light"
        v-html="
          $t('ONBOARDING.WHATSAPP.WABA_INTEGRATION_COMPLETE.ERROR_SUB_HEADING')
        "
      />
    </div>
    <div class="flex-row flex-justify--end">
      <connect-button
        class="navigation-button"
        :label="$t('ONBOARDING.WHATSAPP.WABA_INTEGRATION_COMPLETE.TRY_AGAIN')"
        :query-parameters="{
          redirect_url: setRedirectUrl(),
          state: inbox.phone_number,
        }"
      />
    </div>
  </div>
</template>
<script>
import ConnectButton from 'dashboard/routes/dashboard/settings/inbox/components/360Dialog/ConnectButton';

export default {
  components: { ConnectButton },
  props: {
    inbox: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    setRedirectUrl() {
      const redirectUrl = window.location.origin + this.$route.path;

      return redirectUrl;
    },
  },
};
</script>
<style lang="scss" scoped></style>
