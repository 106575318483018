<template>
  <div
    class="column page-top-bar"
    :style="{ backgroundImage: 'url(' + headerBg + ')' }"
  >
    <span v-if="headerTitle" class="title-h5 text-light" v-text="headerTitle" />
    <p
      v-if="headerContent"
      class="small-12 column header-content title-h5_m text-dark"
      v-html="headerContent"
    />
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    headerContent: {
      type: String,
      default: '',
    },
    headerBg: {
      type: String,
      default: '/brand-assets/modal-header-banner.svg',
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.page-top-bar {
  background-size: cover;
  max-height: $space-mega;
  min-height: $space-normal * 5;
  padding: $space-large $space-four;
  width: 100%;

  .header-content {
    color: $text-light;
  }
}
</style>
