<template>
  <div
    class="contact-entry"
    :class="{ disabled: !isOptIn }"
    @click="handleClick"
  >
    <span class="title-h5 text-dark text-truncate medium-6">
      {{ inboxType === 'email' ? email : contactPhone }}
      <span v-if="!isOptIn" class="body-b3 text-red">
        (Opt-out)
      </span>
    </span>
    <span class="body-b3 text-light text-truncate" v-text="contactName" />
  </div>
</template>

<script>
export default {
  props: {
    contactName: {
      type: String,
      required: true,
    },
    contactId: {
      type: [String, Number],
      required: true,
    },
    contactPhone: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    isOptIn: {
      type: Boolean,
      required: true,
    },
    inboxType: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleClick() {
      if (!this.isOptIn) return;

      this.$emit('onSelectContact', {
        name: this.contactName,
        contact_id: this.contactId,
        phone_number: this.contactPhone,
        email: this.email,
        type: 'existing',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.contact-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $space-small;
  transition: all 0.2s ease-out;
  border-radius: $border-radius-smaller;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: $neutral-grey-200;
  }
}
</style>
