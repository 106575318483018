/* eslint-disable no-restricted-syntax */
export default function validateSLA(sla) {
  // Check if name is provided
  if (!sla.name || sla.name.trim() === '') {
    return ['NAME_REQUIRED', false, 'sla-header'];
  }

  // Check if conditions object is empty
  if (!sla.conditions || Object.keys(sla.conditions).length === 0) {
    return ['CONDITIONS_REQUIRED', false, 'sla-conditions__add-filter'];
  }

  function validateConditions(conditions) {
    for (const [key, condition] of Object.entries(conditions)) {
      if (key === 'select') {
        return [
          'VALID_TRIGGER_REQUIRED',
          false,
          'sla-conditions__trigger_selector__' + key,
        ];
      }

      if (!condition.values || condition.values.length === 0) {
        return [
          `TRIGGER_VALUES_REQUIRED_${key.toUpperCase()}`,
          false,
          'sla-conditions',
          `.multi-select-filter__${key} .multiselect__tags`,
        ];
      }
    }
    return null; // No errors found
  }

  function validateReminders(reminders) {
    if (!reminders || Object.keys(reminders).length === 0) {
      return null; // No reminders to validate
    }

    for (const [key, reminder] of Object.entries(reminders)) {
      if (key === 'select') {
        return [
          'VALID_METRIC_REQUIRED',
          false,
          'sla-reminders__metric_selector__' + key,
        ];
      }

      if (!reminder.data || reminder.data.length === 0) {
        return [
          `REMINDER_DATA_REQUIRED_${key.toUpperCase()}`,
          false,
          'sla-reminders',
          `.multi-select-filter__${key} .multiselect__tags`,
        ];
      }
    }
    return null; // No errors found
  }

  function validateEscalations(escalations) {
    if (!escalations || Object.keys(escalations).length === 0) {
      return null; // No escalations to validate
    }

    for (const [key, escalation] of Object.entries(escalations)) {
      if (key === 'select') {
        return [
          'VALID_METRIC_REQUIRED',
          false,
          'sla-escalations__metric_selector__' + key,
        ];
      }

      const invalidAction = escalation.action.find(
        action => !action.data || action.data.length === 0
      );
      if (invalidAction) {
        const selectedActionIndex = escalation.action.indexOf(invalidAction);
        return [
          `ESCALATION_DATA_REQUIRED_${key.toUpperCase()}`,
          false,
          'escalation-action__' + key + '__' + selectedActionIndex,
          '.multiselect__tags',
        ];
      }
    }
    return null; // No errors found
  }

  const invalidCondition = validateConditions(sla.conditions);
  if (invalidCondition) {
    return invalidCondition;
  }

  const invalidReminder = validateReminders(sla.reminders);
  if (invalidReminder) {
    return invalidReminder;
  }

  const invalidEscalation = validateEscalations(sla.escalations);
  if (invalidEscalation) {
    return invalidEscalation;
  }

  // If all validations pass
  return ['', true, ''];
}
