<template>
  <div>
    <video-view
      v-if="isMp4(url) || attachmentType === 'video'"
      :url="url"
      class="file"
    />
    <audio v-else-if="attachmentType === 'audio'" controls>
      <source :src="url" type="audio/ogg" />
      <source :src="url" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
    <div v-else class="file message-text__wrap" @click="openLink">
      <div class="icon-wrap">
        <i class="ion-document-text"></i>
      </div>
      <div>
        <h5 class="text-block-title">
          {{ fileName(url) }}
        </h5>
        <a
          class="download clear button small"
          rel="noreferrer noopener nofollow"
          target="_blank"
          :href="url"
        >
          {{ $t('CONVERSATION.DOWNLOAD') }}
        </a>
      </div>
    </div>
    <span
      v-if="hasAttachedContent"
      class="message-span"
      :class="{
        'message-deleted': isDeleted,
      }"
      v-html="message"
    />
  </div>
</template>

<script>
import VideoView from 'shared/components/VideoView';
import fileMixin from 'shared/mixins/fileMixin';

export default {
  components: {
    VideoView,
  },
  mixins: [fileMixin],
  props: {
    url: {
      type: String,
      required: true,
    },
    hasAttachedContent: {
      type: Boolean,
      default: false,
    },
    attachmentType: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openLink() {
      const win = window.open(this.url, '_blank', 'noopener');
      win.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.file {
  display: flex;
  flex-direction: row;
  cursor: pointer;

  .icon-wrap {
    font-size: $font-size-giga;
    color: $secondary-blue;
    line-height: 1;
    margin-right: $space-small;
  }

  .text-block-title {
    margin: 0;
    color: $secondary-blue;
    word-break: break-word;
  }

  .button {
    padding: 0;
    margin: 0;
    color: $pg-1-700;
  }

  .time {
    min-width: $space-larger;
  }
}

.message-span ::v-deep p {
  margin: $space-normal 0 0;
}

.message-span {
  :after {
    content: '';
    padding-right: $space-jumbo;
    display: inline-block;
  }
}

.message-deleted ::v-deep p {
  color: $neutral-grey-600;
}

audio {
  height: 3.6rem;

  &::-webkit-media-controls-panel {
    background-color: #fff;
  }
}
</style>
