import { mapGetters } from 'vuex';
import router from 'dashboard/routes';

import billingMixin from 'dashboard/mixins/billing';

export default {
  mixins: [billingMixin],
  data() {
    return {
      enabledFeatures: {},
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    account() {
      return this.$store.getters['accounts/getAccount'](this.accountId);
    },
  },
  mounted() {
    this.initializeEnabledFeatures();
  },
  methods: {
    async initializeEnabledFeatures() {
      await this.$store.dispatch('accounts/get', this.accountId);

      let restrictedChannels = {};
      this.channelList.map(
        // eslint-disable-next-line no-return-assign
        channel => (restrictedChannels[channel.key] = false)
      );

      if (
        this.subscriptionForShopify?.status === 'active' &&
        this.subscriptionForShopify?.plan?.name.includes('Starter')
      )
        this.enabledFeatures = restrictedChannels;
      else this.enabledFeatures = this.account.features;
    },
    initChannelAuth(channel) {
      const params = {
        page: 'new',
        sub_page: channel,
      };
      router.push({ name: 'settings_inboxes_page_channel', params });
    },
  },
};
