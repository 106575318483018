<template>
  <woot-modal-new :show.sync="show" :on-close="onClose">
    <woot-modal-header-new
      :header-title="$t('CONTACTS_PAGE.DOWNLOAD.MODAL.TITLE')"
    />
    <div class="settings-form">
      <div
        class="title-h3 text-dark mg-bottom--large"
        v-text="$t('CONTACTS_PAGE.DOWNLOAD.MODAL.HEADING')"
      />
      <label class="formlabel flex-row">
        <span
          class="title-h5 text-dark"
          v-text="$t('CONTACTS_PAGE.DOWNLOAD.DATE_RANGE.TITLE')"
        />
        <span
          v-tooltip="$t('CONTACTS_PAGE.DOWNLOAD.DATE_RANGE.INFO')"
          class="desc-text capital_c3"
        >
          <icons
            name="warning"
            color="grey evenodd"
            size="semimedium"
            :show-title="false"
          />
        </span>
      </label>
      <date-range-picker
        show-range
        class="secondary-dropdown content-margin"
        variant="full-width"
        :value="customDateRange"
        :disable-after-next-number-of-days="90"
        :confirm-text="$t('ANALYTICS.CUSTOM_DATE_RANGE.CONFIRM')"
        :placeholder="$t('ANALYTICS.CUSTOM_DATE_RANGE.PLACEHOLDER')"
        @change="onChangeDate"
      />
      <label style="margin-bottom: 0.4rem" class="formlabel flex-row">
        <span
          class="title-h5 text-dark"
          v-text="$t('CONTACTS_PAGE.DOWNLOAD.INBOX.TITLE')"
        />
        <span
          v-tooltip="$t('CONTACTS_PAGE.DOWNLOAD.INBOX.INFO')"
          class="desc-text capital_c3"
        >
          <icons
            name="warning"
            color="grey evenodd"
            size="semimedium"
            :show-title="false"
          />
        </span>
      </label>
      <woot-single-selector
        size="large"
        :option-list="inactiveInbox"
        :default-option="defaultOption"
        :custom-style="{
          top: '4.8rem',
          left: 'auto',
          height: '18rem',
          width: '100%',
        }"
        :dropdown-container-style="{ height: '4.4rem' }"
        @click="addInbox"
      />
      <div v-if="inboxList.length" class="medium-12 inbox-chips-container">
        <woot-chips
          v-for="(inbox, index) in inboxList"
          :key="inbox.id"
          :title="inbox.name"
          variant="secondary-small"
          show-cancel-icon
          :show-tooltip="false"
          :custom-style="{
            'border-color': getInboxMeta(inbox).hex,
            color: getInboxMeta(inbox).hex,
          }"
          :front-icon="getInboxMeta(inbox).name"
          :front-icon-color="getInboxMeta(inbox).color"
          @click="onRemoveInbox(index)"
        />
      </div>
      <div class="pagination-controls">
        <woot-base-button variant="tertiary-danger" @click.prevent="onClose">
          {{ $t('CONTACTS_PAGE.DOWNLOAD.MODAL.CANCEL_BUTTON') }}
        </woot-base-button>
        <woot-base-button
          variant="primary"
          :loading="isLoading"
          @click="sendContactsOverEmail"
        >
          {{ $t('CONTACTS_PAGE.DOWNLOAD.MODAL.SUBMIT_BUTTON') }}
        </woot-base-button>
      </div>
    </div>
    <woot-modal :show="showConfirmationModal" :show-close="false" small-modal>
      <div class="flex-colum-center" style="padding:3.6rem">
        <span>
          <lottie
            :options="defaultAnimOptions"
            :height="200"
            :width="200"
            @animCreated="handleAnimation"
          />
        </span>
        <div class="title-h3 text-align text-light mg-top">
          {{ responseMessage }}
        </div>
      </div>
    </woot-modal>
  </woot-modal-new>
</template>
<script>
import Lottie from 'vue-lottie';
import { mapGetters } from 'vuex';

import DateRangePicker from 'dashboard/components/ui/DatePicker';

import inboxMixin from 'dashboard/mixins/inbox';
import alertMixin from 'shared/mixins/alertMixin';
import downloadMixin from 'shared/mixins/downloadMixin';
import timeMixin from 'dashboard/mixins/time';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import animationData from 'dashboard/assets/lottie/emailSent.json';

export default {
  components: { Lottie, DateRangePicker },
  mixins: [
    inboxMixin,
    alertMixin,
    downloadMixin,
    timeMixin,
    mixPanelAnalyticsMixin,
  ],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      showConfirmationModal: false,
      isLoading: false,
      responseMessage: '',
      defaultOption: 'All Inboxes',
      inboxList: [],
      customDateRange: [new Date(), new Date()],
      defaultAnimOptions: { animationData, loop: false },
    };
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
    }),
    inactiveInbox() {
      let selectedIds = this.inboxList.map(item => {
        return item.id;
      });

      return [
        { id: -1, name: 'All Inboxes' },
        ...this.inboxes.filter(item => !selectedIds.includes(item.id)),
      ];
    },
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      this.anim.setSpeed(0.4);
    },
    onChangeDate(value) {
      this.mix_panel_clicked_contacts_download_select_date_range();
      this.customDateRange = value;
    },
    sendContactsOverEmail() {
      let [startTime, endTime] = this.customDateRange;
      const inboxIds = this.inboxList.map(ibx => ibx.id);

      startTime = this.formatDate(startTime);
      endTime = this.formatDate(endTime);

      this.isLoading = true;
      this.$store
        .dispatch('contacts/sendContactsOverEmail', {
          start_time: startTime,
          end_time: endTime,
          inbox_ids: inboxIds,
        })
        .then(response => {
          this.isLoading = false;
          this.responseMessage = response.message;

          this.showConfirmationModal = true;
          this.mix_panel_clicked_contacts_download_send_to_email();
          setTimeout(() => {
            this.onClose();
          }, 5000);
        })
        .catch(error => {
          this.isLoading = false;
          this.showAlert(
            error.data.error || this.$t('CONTACTS_PAGE.DOWNLOAD.ERROR'),
            'info'
          );
        });
    },
    getInboxMeta(inbox) {
      return this.getInboxClassByType(
        inbox?.channel_type,
        inbox?.phone_number,
        '',
        inbox?.instagram_add_on
      );
    },
    addInbox(val) {
      this.mix_panel_clicked_inbox_filter_menu_item(
        this.$t('CONTACTS_PAGE.DOWNLOAD.SCREEN_NAME'),
        val.channel_type
      );
      if (val.name === 'All Inboxes') {
        this.defaultOption = 'All Inboxes';
        this.inboxList = [];
        return;
      }
      this.defaultOption = this.$t('CONTACTS_PAGE.DOWNLOAD.INBOX.PLACEHOLDER');
      this.inboxList.push(val);
    },
    onRemoveInbox(index) {
      this.inboxList.splice(index, 1);
      if (!this.inboxList.length) this.defaultOption = 'All Inboxes';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-form {
  padding: $space-two $space-jumbo + $space-two $zero;
}

.secondary-dropdown {
  border-radius: $border-radius-smaller;
  height: $space-four;
}

.content-margin {
  margin-bottom: $space-medium;
}

.inbox-chips-container {
  margin-top: $space-small;
  height: $space-two * 10;
}

.pagination-controls {
  bottom: $space-three * 10;
  position: absolute;
  right: $space-four * 2 + $space-micro;
}
</style>
