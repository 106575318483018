var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-table"},[(_vm.title)?_c('span',{staticClass:"text-dark title-h5 mg-bottom--normal",domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),(_vm.canShowFeatures)?_c('div',{staticClass:"features flex-row"},[(!_vm.selectedRows.length)?_vm._t("table-features"):_c('div',{key:"bulk-features",staticClass:"bulk-actions-container flex-row flex-align gap--normal"},[_c('woot-base-button',{attrs:{"variant":"tertiary"},on:{"click":_vm.selectAllRows}},[_c('label',{staticClass:"container-checkbox",staticStyle:{"cursor":"pointer"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectAllChecked),expression:"selectAllChecked"}],attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectAllChecked,"checked":Array.isArray(_vm.selectAllChecked)?_vm._i(_vm.selectAllChecked,null)>-1:(_vm.selectAllChecked)},on:{"click":_vm.selectAllRows,"change":function($event){var $$a=_vm.selectAllChecked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectAllChecked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectAllChecked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectAllChecked=$$c}}}}),_vm._v(" "),_c('span',{staticClass:"grey-checkmark checkmark"})]),_vm._v("\n        Select all\n      ")]),_vm._v(" "),_c('woot-base-button',{attrs:{"variant":"secondary-danger","front-icon":"delete"},on:{"click":function($event){return _vm.deleteRow(_vm.selectedRows)}}},[_vm._v("\n        Delete\n      ")])],1)],2):_vm._e(),_vm._v(" "),_c('base-table',{class:_vm._class,attrs:{"data":_vm.tableData,"columns":_vm.columns,"loading":_vm.loading,"actions":_vm.actions,"enable-animations":_vm.enableAnimations},on:{"selectRow":_vm.onRowSelection},scopedSlots:_vm._u([(_vm.actions.length)?{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-row flex-justify--end"},[(_vm.collapseActions)?_c('woot-single-selector',{attrs:{"variant":"tertiary","custom-style":{
            borderRadius: '0.8rem',
            boxShadow: 'none',
            left: 'auto',
            right: '0.8rem',
            width: 'auto',
          },"icon-size-prop":"semimedium","icon":"threeDot","show-selected-text":false,"show-option-name":false,"option-list":_vm.getActionsList(row)},on:{"click":function (action) { return _vm.onActionSelected(action, row); }}}):_c('div',{staticClass:"flex-row gap--large actions-container"},_vm._l((_vm.getActionsList(row)),function(action){return _c('woot-base-button',{key:action.id,attrs:{"tag":"span","variant":action.name === 'delete' ? 'tertiary-danger' : 'tertiary'},on:{"click":function($event){return _vm.onActionSelected(action, row)}}},[_c('icons',{attrs:{"name":action.icon,"color":"textlightgrey","size":"semimedium","title":action.name}})],1)}),1)],1)]}}:null,(_vm.hasExtraActionsSlot)?{key:"extra-actions",fn:function(ref){
          var row = ref.row;
return [_vm._t("actions",null,{"row":Object.assign({}, row)})]}}:null,(_vm.hasLoadingSlot)?{key:"loading-state",fn:function(){return [_vm._t("loading-state")]},proxy:true}:null,(_vm.hasZeroStateSlot)?{key:"zero-state",fn:function(){return [_vm._t("zero-state")]},proxy:true}:null],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }