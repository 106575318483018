<template>
  <base-ticket-form :parent-form-fields="{ userAction, selectedCrm }">
    <woot-input
      v-model="action"
      label="Action"
      type="text"
      class="content-margin"
      placeholder="Action"
    />
  </base-ticket-form>
</template>
<script>
import BaseTicketForm from './shared/BaseTicketForm';
import { AVAILABLE_CRMS } from 'dashboard/routes/dashboard/conversation/overview/constants';

export default {
  components: { BaseTicketForm },
  data() {
    return {
      action: '',
    };
  },
  computed: {
    selectedCrm() {
      return AVAILABLE_CRMS.ONEDIRECT;
    },
  },
};
</script>
