import * as types from 'shared/constants/googleEventType';

export default {
  methods: {
    googleAnalyticsEvent(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
        value: 1,
      });
    },
    logAvailibilityChange(availability) {
      this.googleAnalyticsEvent(
        types.default.AGENT_AVAILABILITY,
        types.default.AVAILABILITY_CHANGE,
        availability
      );
    },
    logFilterChange(selectedFilters) {
      this.googleAnalyticsEvent(
        types.default.CONVERSATION_FILTER_SET,
        types.default.DASHBOARD_MID_PANEL,
        selectedFilters
      );
    },
    logTagFilterChange(selectedFilters) {
      this.googleAnalyticsEvent(
        types.default.TAG_FILTER_SET,
        types.default.DASHBOARD_MID_PANEL,
        selectedFilters
      );
    },
    logAgentFilterChange(selectedFilters) {
      this.googleAnalyticsEvent(
        types.default.AGENT_FILTER_SET,
        types.default.DASHBOARD_MID_PANEL,
        selectedFilters
      );
    },
    logCannedResponseSent(message) {
      this.googleAnalyticsEvent(
        types.default.CANNED_RESPONSE_SENT,
        types.default.CONVERSATION_PANEL,
        message
      );
    },
    logConversationCardClick(path) {
      this.googleAnalyticsEvent(
        types.default.CONVERSATION_OPENED,
        types.default.CHAT_LIST_OR_PREVIOUS_OR_CONTACT_INFO,
        path
      );
    },
    logConversationStatusChange(status) {
      this.googleAnalyticsEvent(
        types.default.AGENT_CHANGE_CONV_STATUS,
        types.default.CONVERSATION_HEADER,
        status
      );
    },
    logQuickResolve(status) {
      this.googleAnalyticsEvent(
        types.default.QUICK_RESOLVE_CONV_STATUS,
        types.default.CONVERSATION_HEADER,
        status
      );
    },
    logAssigneeChange(assignee_id) {
      this.googleAnalyticsEvent(
        types.default.AGENT_ASSIGN_CONV,
        types.default.CONVERSATION_HEADER,
        assignee_id
      );
    },
    logTeamChange(team_id) {
      this.googleAnalyticsEvent(
        types.default.TEAM_ASSIGN_CONV,
        types.default.CONVERSATION_HEADER,
        team_id
      );
    },
    logConversationStarred() {
      this.googleAnalyticsEvent(
        types.default.STAR_CONVERSATION,
        types.default.CONVERSATION_HEADER,
        types.default.STAR_CONVERSATION
      );
    },
    logOnBoardModalEvents(store_type, event_detail) {
      var GACompatibleEventName =
        (store_type !== 'Shopify' ? 'NS_' : 'S_') +
        event_detail.replace(/[^A-Z0-9]+/gi, '_');
      // Adding an identifier for Non Shopify or Shopify
      // Removing spaces and other special characters and replacing them with allowed underscore
      this.googleAnalyticsEvent(
        GACompatibleEventName.toUpperCase(),
        types.default.ONBOARD_MODAL,
        GACompatibleEventName.toUpperCase()
      );
    },
  },
};
