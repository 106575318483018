export const isEnter = e => {
  return e.keyCode === 13;
};

export const isEscape = e => {
  return e.keyCode === 27;
};

export const hasPressedShift = e => {
  return e.shiftKey;
};

export const hasPressedAlt = e => {
  return e.altKey;
};

export const hasPressedR = e => {
  return e.keyCode === 82;
};

export const hasPressedW = e => {
  return e.keyCode === 87;
};

export const hasPressedF = e => {
  return e.keyCode === 70;
};

export const hasPressedS = e => {
  return e.keyCode === 83;
};

export const hasPressedRightArrow = e => {
  return e.keyCode === 39;
};

export const hasPressedLeftArrow = e => {
  return e.keyCode === 37;
};

export const hasPressedControl = e => {
  return e.keyCode === 17;
};
