import inboxImage from 'assets/images/inboxes_intro.svg';
import channelsImage from 'assets/images/channels_intro.svg';
import collaboratorsImage from 'assets/images/collaborators_intro.svg';
import collaboratorsImageAsset from 'assets/images/collaborators.svg';

export const SHOWCASE_INBOX_DATA = [
  {
    name: 'inboxes',
    key: 'INBOXES',
    image: inboxImage,
    contentPath: 'INBOX_MGMT',
  },
  {
    name: 'channels',
    key: 'CHANNELS',
    image: channelsImage,
    contentPath: 'INBOX_MGMT',
  },
  {
    name: 'collaborators',
    key: 'COLLABORATORS',
    image: collaboratorsImage,
    contentPath: 'INBOX_MGMT',
    asset: collaboratorsImageAsset,
  },
];

export const META_APP_SCOPES =
  'pages_manage_metadata,pages_messaging,pages_read_user_content,pages_manage_engagement,pages_read_engagement,instagram_manage_messages,instagram_basic,instagram_manage_comments,business_management';
