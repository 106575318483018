<template>
  <div class="column settings-box custom-scroll">
    <woot-modal :show.sync="downloading" :on-close="hidePopup">
      <div class="download-note">
        <img
          src="https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
        />
        <div
          v-html="$t('ANALYTICS.DOWNLOAD_REPORT.EMAIL', { email: userEmail })"
        />
      </div>
    </woot-modal>
    <woot-modal :show.sync="downloadError" :on-close="hidePopup">
      <div class="download-note">
        <div>{{ $t('ANALYTICS.DOWNLOAD_REPORT.REPORT_FAIL') }}</div>
      </div>
    </woot-modal>
    <div class="settings-container padding-container columns custom-scroll">
      <span class="section-title">
        Live Agent Availability
      </span>

      <div class="metrics-card-container">
        <metrics-card
          v-for="(item, index) in metricsCount"
          :key="index"
          :title="item.title"
          :sub-title="item.sub"
          :color="item.color"
        />
      </div>
      <div>
        <span class="section-title">Filters</span>
        <div class="flex-space-between container-margin">
          <date-range-selector
            :default-selection="0"
            @date-range-change="onDateRangeChange"
          />
          <woot-primary-button
            front-icon="email"
            name="Attendance Report"
            :loading="uiFlag"
            @click="download()"
          />
        </div>

        <transition name="slide-up">
          <logs-table
            v-if="selectedUserId > 0"
            :header-list="$t('ANALYTICS.ATTENDANCE_LOGS_HEADER')"
            :agent-name="selectedAgentName"
            @select-summary="closeAgentLogs"
          />
          <summary-table
            v-else
            :header-list="$t('ANALYTICS.ATTENDANCE_SUMMARY_HEADER')"
            @select-agent="getAgentLogs"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MetricsCard from '../components/MetricsCard';
import SummaryTable from '../components/AttendaceSummaryTable';
import LogsTable from '../components/AttendanceLogsTable';
import DateRangeSelector from '../components/DateRangeSelector';

import alertMixin from 'shared/mixins/alertMixin';
import timeMixin from 'dashboard/mixins/time';
import downloadMixin from 'dashboard/mixins/download';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import { getUserEmail } from 'dashboard/helper/cookieHelper';
import * as types from 'shared/constants/googleEventType';

export default {
  components: {
    MetricsCard,
    SummaryTable,
    LogsTable,
    DateRangeSelector,
  },
  mixins: [
    alertMixin,
    timeMixin,
    downloadMixin,
    googleAnalyticsMixin,
    mixPanelAnalyticsMixin,
  ],
  data() {
    return {
      dateRange: '1',
      startDate: 0,
      endDate: 0,
      selectedUserId: 0, // 0 will show all agents
      downloading: false,
      downloadError: false,
    };
  },
  computed: {
    ...mapGetters({
      attendanceMetrics: 'getAttendanceMetrics',
      uiFlag: 'getAttendanceUIFlag',
      attendanceLogs: 'getAttendanceLogs',
    }),
    dateList() {
      return this.$t('DOWNLOAD.DATE_RANGE').map(item => {
        return {
          key: item.KEY,
          name: item.NAME,
        };
      });
    },
    selectedAgentName() {
      let selectedIndex = this.attendanceMetrics.findIndex(
        item => item.id === this.selectedUserId
      );

      return selectedIndex > 0
        ? this.attendanceMetrics[selectedIndex].name
        : '';
    },
    metricsCount() {
      return [
        {
          title: this.attendanceMetrics.length,
          sub: 'Total Agents',
          color: '#1B83AC',
        },
        {
          title: this.attendanceMetrics.filter(
            item => item.current_availability === 'online'
          ).length,
          sub: 'Agents Online',
          color: '#6BAC1B',
        },
        {
          title: this.attendanceMetrics.filter(
            item => !['online', 'offline'].includes(item.current_availability)
          ).length,
          sub: 'Agents Busy',
          color: '#FFB200',
        },
        {
          title: this.attendanceMetrics.filter(
            item => item.current_availability === 'offline'
          ).length,
          sub: 'Agents Offline',
          color: '#8C8C8C',
        },
      ];
    },
    userEmail() {
      return getUserEmail();
    },
  },
  mounted() {
    this.getMetrics();
  },
  methods: {
    async getMetrics() {
      await this.$store.dispatch('getAttendanceMetrics', {
        startDate: this.startDate,
        endDate: this.endDate,
        inCSV: false,
      });
    },
    onDateRangeChange({ from, to, date_range_name, isFiredOnMount }) {
      this.startDate = this.formatDate(from * 1000).replaceAll('-', '/');
      this.endDate = this.formatDate(to * 1000).replaceAll('-', '/');
      this.googleAnalyticsEvent(
        types.default.AGENT_ATTENDANCE_FILTER_DATE_RANGE,
        this.startDate,
        this.endDate
      );
      this.getMetrics();
      if (!isFiredOnMount) {
        this.mix_panel_clicked_date_filter_menu_item(
          'agent_attendance',
          date_range_name
        );
      }
    },
    getAgentLogs(userId) {
      this.$store
        .dispatch('getAttendanceLogs', {
          startDate: this.startDate,
          endDate: this.endDate,
          inCSV: false,
          userId: userId,
        })
        .then(() => {
          this.selectedUserId = userId;
        });
    },
    closeAgentLogs() {
      this.selectedUserId = 0;
    },
    async download() {
      this.mix_panel_clicked_report_menu_item(
        'agent_attendance',
        'attendance_report'
      );
      this.downloading = true;
      this.googleAnalyticsEvent(
        types.default.DOWNLOAD_AGENT_ATTENDANCE,
        types.default.DOWNLOAD_AGENT_ATTENDANCE,
        types.default.DOWNLOAD_AGENT_ATTENDANCE
      );
      await this.$store
        .dispatch('getAttendanceDownload', {
          startDate: this.startDate,
          endDate: this.endDate,
          userId: this.selectedUserId,
          email: this.userEmail,
        })
        .then(() => {})
        .catch(() => {
          this.downloading = false;
          this.downloadError = true;
        });
    },
    hidePopup() {
      this.downloading = false;
      this.downloadError = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.metrics-card-container {
  display: flex;
  flex-direction: row;
  margin: $space-micro $space-micro $space-normal;
}

.bottom-container {
  display: flex;
  flex-direction: column;
  margin: $zero $space-micro $space-normal;
}

.cancel-button {
  padding: $space-small;
  margin-bottom: $space-normal;
  cursor: pointer;
}

.section-title {
  font-size: $font-size-medium;
  line-height: $space-medium;
  color: $neutral-grey-600;
  margin: $space-slab;
  margin-left: $space-smaller;
}

.button {
  margin-bottom: auto;
}
.container-margin {
  margin: $space-one $space-smaller $space-medium $space-smaller;
}
</style>
