import { isValidPhoneNumber } from 'libphonenumber-js';

export const validName = value => {
  let pattern = /^[A-Za-z ]+$/;
  return pattern.test(value);
};

export const validNonZeroNumber = value => {
  let pattern = /^\d*[1-9]\d*$/;
  return pattern.test(value);
};

export const checkValidPhoneNumber = value => {
  if (!value) {
    return true;
  }
  return isValidPhoneNumber('+' + value);
};
