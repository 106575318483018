<template>
  <div class="column content-box settings-box">
    <div class="settings-button flex-row-justify-center" style="top: 2.4rem">
      <woot-primary-button
        :name="$t('PRODUCTS_MGMT.HEADER_BUTTON.PREV')"
        front-icon="chevronLeft"
        :disabled="itemIndex === 0"
        :custom-style="{ 'margin-right': '1.2rem' }"
        @click="prevCard()"
      />

      <woot-primary-button
        :name="$t('PRODUCTS_MGMT.HEADER_BUTTON.NEXT')"
        back-icon="chevronRight"
        :disabled="itemIndex === records.length - 1"
        @click="nextCard()"
      />
    </div>
    <div
      v-if="!uiFlags.fetchingProducts && item !== {}"
      class="settings-container custom-scroll"
    >
      <div
        class="small-12 item-wrap"
        :class="{ 'item-height': item.description !== null }"
      >
        <div class="card__media">
          <img :src="item.Image" alt="Card image" />
        </div>
        <div class="card__content">
          <div class="card__header">
            {{ item.name }}
          </div>
          <footer class="card__meta flex-space-between" role="contentinfo">
            <div class="card__price">
              <span style="margin-right: 0.8rem">{{
                $t('PRODUCTS_MGMT.PRICE')
              }}</span>
              <span
                v-if="item.sale_price < item.price"
                v-html="`${accountCurrency} ${item.sale_price}`"
              >
              </span>
              <span
                :class="{
                  'sale-price': item.sale_price < item.price,
                }"
                v-html="`${accountCurrency} ${item.price}`"
              >
              </span>
              <span
                v-if="item.sale_price < item.price"
                class="price__discount flex-row-justify-center"
              >
                <icons name="discount" color="primary" size="semimedium" />
                <span>{{
                  `Buy this at ${Math.floor(
                    100 - (100 * item.sale_price) / item.price
                  )}% OFF discount`
                }}</span>
              </span>
            </div>
          </footer>
          <span
            v-if="
              item.inventory < 1 &&
                !['not_managed', 'instock'].includes(item.inventory_management)
            "
            class="out-of-stock title-h6"
            v-text="$t('PRODUCTS_MGMT.OUT_OF_STOCK')"
          />
          <p
            v-if="item.description !== null"
            class="card__excerpt custom-scroll"
            v-html="item.description"
          ></p>
        </div>
      </div>

      <div class="attributes-container">
        <div class="attribute-header">
          {{ $t('PRODUCTS_MGMT.ATTRIBUTES.TITLE') }}
        </div>
        <div class="flex-space-between">
          <woot-input
            v-model.trim="newAttributes"
            class="columns content-margin"
            :label="$t('PRODUCTS_MGMT.ATTRIBUTES.LABEL')"
            :placeholder="$t('PRODUCTS_MGMT.ATTRIBUTES.PLACEHOLDER')"
            :help-text="$t('PRODUCTS_MGMT.ATTRIBUTES.DESC')"
          />
          <woot-primary-button
            :name="$t('PRODUCTS_MGMT.ATTRIBUTES.BUTTON')"
            :disabled="newAttributes.length === 0"
            :custom-style="{
              'margin-top': '3.5rem',
              width: '10rem',
              display: 'flex',
              'justify-content': 'center',
            }"
            @click="addAttributes(newAttributes)"
          />
        </div>
        <span class="attribute-box columns">
          <woot-chips
            v-for="(attribute, index) in attributes"
            :key="index"
            :title="attribute"
            variant="primary-small"
            :show-icon="true"
            @click="onRemoveAttribute(index)"
          />
          <p v-if="attributes.length === 0" class="no-items-error-message">
            {{ $t('PRODUCTS_MGMT.ATTRIBUTES.404') }}
          </p>
        </span>
      </div>

      <p
        v-if="!uiFlags.fetchingProducts && item === {}"
        class="no-items-error-message"
      >
        {{ $t('PRODUCTS_MGMT.LIST.404') }}
      </p>
      <woot-loading-state
        v-if="uiFlags.fetchingProducts"
        :message="$t('PRODUCTS_MGMT.LIST.LOADING')"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import router from 'dashboard/routes';
import alertMixin from 'shared/mixins/alertMixin';
import accountMixin from '../../../../../mixins/account';
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  mixins: [alertMixin, accountMixin],
  props: {
    productId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      newAttributes: '',
    };
  },
  computed: {
    ...mapGetters({
      records: 'getProducts',
      uiFlags: 'getProductsUIFlags',
      accountId: 'getCurrentAccountId',
    }),
    itemIndex() {
      return this.records.findIndex(item => item.id === Number(this.productId));
    },
    item() {
      return this.itemIndex > -1 ? this.records[this.itemIndex] : {};
    },
    attributes() {
      return this.itemIndex > -1 ? this.records[this.itemIndex].attributes : [];
    },
    currentPage() {
      const page = this.$route.query?.page;
      return page || 1;
    },
    accountCurrency() {
      return this.$store.getters['accounts/getAccountCurrency'](this.accountId);
    },
  },
  mounted() {
    this.$store.dispatch('getProducts', { page: this.currentPage });
  },
  methods: {
    nextCard() {
      let id = this.records[this.itemIndex + 1].id;
      router.push({
        path: frontendURL(
          `accounts/${this.accountId}/settings/products/${id}?page=${this.currentPage}`
        ),
      });
    },
    prevCard() {
      let id = this.records[this.itemIndex - 1].id;
      router.push({
        path: frontendURL(
          `accounts/${this.accountId}/settings/products/${id}?page=${this.currentPage}`
        ),
      });
    },
    addAttributes(val) {
      let newAttributesArray = val.split(',');
      let newList = [...this.attributes, ...newAttributesArray];
      this.records[this.itemIndex].attributes = newList;
      this.newAttributes = '';
      this.updateAttributes();
    },
    onRemoveAttribute(index) {
      this.records[this.itemIndex].attributes.splice(index, 1);
      this.updateAttributes();
    },
    updateAttributes() {
      try {
        this.$store
          .dispatch('updateProduct', {
            attributes: this.attributes,
            id: this.item.id,
          })
          .then(data => {
            if (data) {
              this.showAlert(
                this.$t('PRODUCTS_MGMT.SUCCESS_MESSAGE'),
                'success'
              );
            } else {
              this.showAlert(this.$t('PRODUCTS_MGMT.FAILURE_MESSAGE'), 'error');
            }
          });
      } catch {
        this.showAlert(this.$t('PRODUCTS_MGMT.FAILURE_MESSAGE'), 'error');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.settings-box {
  padding-top: $space-largest;
  padding-left: 0.1rem;
  overflow: hidden;

  .settings-container {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
}

.item-wrap {
  @include elevation-1dp;
  display: flex;
  flex-direction: row;
  background: $neutral-white;
  padding: $space-slab;
  margin: $space-micro;
  margin-bottom: $space-normal;
  max-height: 40%;

  .card__media {
    @include elevation-1dp;
    border-radius: $border-radius;
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: $border-radius;
      padding: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-left: $space-medium;

    .card__excerpt {
      position: relative;
      font-size: $font-size-small;
      line-height: 22px;
      color: $neutral-grey-600;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      height: 100%;
      text-align: start;
      word-break: break-word;
      margin-bottom: $space-normal;

      &::after {
        content: '-';
        opacity: 0;
      }
    }

    .card__meta {
      border-bottom: 0;

      .card__price {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $neutral-grey-800;
        margin-bottom: $space-slab;
        font-size: $font-size-medium;
        line-height: 2.6rem;

        .sale-price {
          margin-left: $space-small;
          color: $neutral-grey-600;
          font-size: $font-size-small;
          text-decoration: line-through;
          text-decoration-color: $warn-red-500;
        }
      }

      .price__discount {
        margin-left: $space-medium;
        align-items: center;
        color: $secondary-blue;
        font-size: $font-size-default;
        line-height: $space-medium;
      }
    }

    .out-of-stock {
      color: $warn-red-400;
      text-transform: uppercase;
    }
  }
}

.attributes-container {
  @include elevation-1dp;
  background: $neutral-white;
  padding: $space-slab;
  margin: $space-micro;
  margin-bottom: $space-medium;
  display: flex;
  flex-direction: column;

  .attribute-header {
    font-size: $font-size-medium;
    line-height: 2.6rem;
    color: $neutral-grey-600;
    padding-bottom: $space-normal;
    margin-bottom: $space-normal;
    border-bottom: 1px solid $neutral-grey-400;
    display: flex;
  }

  button {
    margin-left: $space-slab;
  }
}

.card__header {
  font-size: $font-size-big;
  line-height: $space-large;
  color: $neutral-grey-800;
  margin-bottom: $space-normal;
  display: flex;
}

.item-height {
  max-height: 60%;
}

.card__content::v-deep a {
  color: $pg-1-500;
}

.card__content::v-deep strong {
  color: $neutral-grey-800;
  font-size: $font-size-medium;
}

.card__content::v-deep img {
  width: 100%;
  margin-bottom: $space-small;
}

.no-items-error-message {
  text-align: center;
}

.label-container {
  flex: 0 1 auto;
  flex-wrap: wrap;
  display: flex;
  max-width: 100%;
}

.button {
  min-width: 100px;
  padding: $space-small;
}

.button ::v-deep svg {
  margin-right: 0;
}
</style>
