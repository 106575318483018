<template>
  <raise-ticket
    v-if="showRaiseTicketSection"
    class="raise-ticket-section"
    @closeRaiseTicket="closeRaiseTicket"
  />
  <accordian-card
    v-else
    :name="$t('CONTACT_PANEL.CRM.TITLE')"
    icon="ticket"
    always-open
    has-add-button
    @addButtonMethod="handleAddButton"
  >
    <show-tickets v-if="canShowTickets" :key="rerenderTickets" />
  </accordian-card>
</template>
<script>
import { mapGetters } from 'vuex';

import AccordianCard from 'dashboard/components/AccordianCard';
import RaiseTicket from './create/RaiseTicket';
import ShowTickets from './list/ShowTickets';

import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import getCurrentChatContact from 'shared/mixins/getCurrentChatContact';

export default {
  components: { AccordianCard, RaiseTicket, ShowTickets },
  mixins: [getCurrentChatContact, mixPanelAnalyticsMixin],
  data() {
    return {
      showRaiseTicketSection: false,
    };
  },
  computed: {
    ...mapGetters({
      enabledCrms: 'crm/getEnabledCrms',
      currentChat: 'getSelectedChat',
    }),
    canShowTickets() {
      return this.enabledCrms.length !== 0 && this.contact?.id;
    },
    email() {
      return this.contact?.email || '';
    },
    phone() {
      return this.contact?.phone_number || '';
    },
    rerenderTickets() {
      return this.email + this.phone;
    },
  },
  created() {
    this.$store.dispatch('crm/fetchEnabledCrms');
  },
  provide() {
    return { closeRaiseTicketSection: this.closeRaiseTicket };
  },
  methods: {
    handleAddButton() {
      this.mix_panel_clicked_add_crm_ticket();
      this.showRaiseTicketSection = true;
      this.$emit('raiseTicketSectionOpened');
    },
    closeRaiseTicket() {
      this.showRaiseTicketSection = false;
      this.$emit('raiseTicketSectionClosed');
    },
  },
};
</script>
