/* global axios */
import ApiClient from '../ApiClient';

class ThreeSixtyDialog extends ApiClient {
  constructor() {
    super('channels/three_sixty_dialog_channels', { accountScoped: true });
  }

  configure({ three_sixty_channel_id, three_sixty_client_id, phone_number }) {
    return axios.patch(`${this.url}/configure_integration`, {
      three_sixty_channel_id,
      three_sixty_client_id,
      phone_number,
    });
  }

  setRedirectUrl(redirectUrl) {
    return axios.post(`${this.url}/configure_redirect_url`, {
      partner_redirect_url: redirectUrl,
    });
  }

  reauthorize(phone_number) {
    return axios.post(`${this.url}/regenerate_three_sixty_api_token`, {
      phone_number,
    });
  }

  fetchPartnerId() {
    return axios.get(`${this.url}/fetch_partner_id`);
  }
}

export default new ThreeSixtyDialog();
