<template>
  <woot-modal-new :show="true" :on-close="() => $emit('onClose')">
    <woot-modal-header-new header-title="Import Canned Responses" />
    <div class="modal-content-wrapper flex-column flex-justify--between">
      <div class="flex-column gap--normal">
        <div
          :style="{
            backgroundImage: `url(${require('assets/images/import_canned.svg')})`,
          }"
          class="illustration-container"
        />
        <span
          class="body-b2 text-light"
          v-text="
            'Please upload the Canned responses in the given format. If you don’t have a template ready, feel free to download here and fill & upload accordingly'
          "
        />
      </div>
      <div class="flex-row flex-justify--between">
        <woot-base-button
          to="/example_canned.csv"
          variant="secondary"
          @click.native="InlineButtonClickHandler"
        >
          Download Example
        </woot-base-button>
        <woot-base-button class="custom-import-btn" :loading="isUploading">
          <file-upload
            :size="4096 * 4096"
            accept=".csv"
            @input-file="onFileUpload"
          >
            Upload Csv
          </file-upload>
        </woot-base-button>
      </div>
    </div>
  </woot-modal-new>
</template>
<script>
import FileUpload from 'vue-upload-component';
import ImportDataApi from 'dashboard/api/importData';
import alertMixin from 'shared/mixins/alertMixin';

import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: { FileUpload },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      isUploading: false,
    };
  },
  methods: {
    async onFileUpload(file) {
      this.mix_panel_clicked_settings_import_upload_csv_canned_response();
      if (!file) {
        return;
      }
      this.isUploading = true;
      try {
        // {"status":"processed","meta":{"total":22,"success":0,"failed":22}}
        const {
          data: {
            meta: { total, success, failed },
          },
        } = await ImportDataApi.create({
          file: file.file,
          type: 'canned',
        });
        this.$store.dispatch('getCannedResponse');
        this.isUploading = false;
        this.$emit('onClose');

        this.showAlert(
          `Bulk upload completed. Total: ${total}, Success: ${success}, Failed: ${failed}`,
          'info',
          3000
        );
      } catch (error) {
        const message = error?.response?.data?.message || '';
        this.showAlert(
          `Failed to initiate bulk upload. ${message}`,
          'error',
          3000
        );
      } finally {
        this.isUploading = false;
      }
    },
    InlineButtonClickHandler() {
      this.mix_panel_clicked_settings_import_download_example_canned_response();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.modal-content-wrapper {
  height: 80%;

  .illustration-container {
    height: 21.6rem;
    max-width: 62.4rem;
    background: $neutral-grey-300;
    background-size: contain;
    border-radius: $border-radius-smaller;
  }

  .custom-import-btn {
    position: relative;
    width: $space-normal * 10;

    ::v-deep .file-uploads {
      position: absolute;
      width: 100%;

      label {
        cursor: pointer;
      }
    }
  }
}
</style>
