<template>
  <div class="animation--container">
    <div class="animation text-align--center">
      <lottie :options="defaultOptions" :height="+height" :width="+width" />
      <span
        v-if="message"
        class="body-b1 text-light animation-text"
        v-text="message"
      />
    </div>
  </div>
</template>

<script>
import Lottie from 'vue-lottie';
import animationData from 'dashboard/assets/lottie/limeLoader.json';

export default {
  components: {
    Lottie,
  },
  props: {
    message: { type: String, default: 'Please wait...' },
    height: { type: [String, Number], default: '120' },
    width: { type: [String, Number], default: '120' },
  },
  data() {
    return {
      defaultOptions: { animationData },
    };
  },
};
</script>

<style lang="scss" scoped>
.animation--container {
  height: 100vh;
  position: relative;
  width: 100%;

  .animation {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    .animation-text {
      display: block;
      margin-top: var(--space-smaller);
    }
  }
}
</style>
