<template>
  <woot-modal-new
    :show.sync="show"
    :show-close="false"
    :custom-style="customStyle"
    :on-close="onClose"
  >
    <div class="delete-modal-header flex-row flex-justify--between">
      <span class="title-h2 text-warn" v-text="title" />
      <woot-base-button
        v-if="showClose"
        class="close-icon"
        tag="span"
        variant="tertiary-danger"
        @click="onClose"
      >
        <icons
          name="closeRound"
          color="red"
          size="normal"
          :show-title="false"
        />
      </woot-base-button>
    </div>
    <div class="modal-content">
      <span class="body-b1 text-dark" v-html="message" />
    </div>
    <div class="modal-footer delete-item flex-row flex-align--end gap--small">
      <woot-base-button size="small" variant="tertiary" @click="onClose">
        {{ rejectText }}
      </woot-base-button>
      <woot-base-button
        v-if="confirmText"
        variant="secondary-danger"
        size="small"
        :front-icon="showDeleteIcon ? 'delete' : ''"
        @click="onConfirm"
      >
        {{ confirmText }}
      </woot-base-button>
    </div>
  </woot-modal-new>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: 'delete',
    },
    rejectText: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
    customStyle: {
      type: Object,
      default: () => {},
    },
    showDeleteIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.delete-modal-header {
  background: $neutral-grey-300;
  padding: $space-medium $space-two;

  .close-icon {
    cursor: pointer;
  }

  .text-warn {
    color: $warn-red-500;
  }
}

.modal-content {
  padding: $space-normal $space-medium;
}

.modal-footer {
  padding: $space-normal $space-medium;
  margin-top: $space-six;
}

.delete-button {
  border: 1px solid $warn-red-400;
  width: $space-slab * 10;

  ::v-deep.btn-name {
    color: $warn-red-400;
  }

  &:hover {
    border: 1px solid $warn-red-400;
  }
}
</style>
