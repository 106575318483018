import SettingsContent from '../Wrapper';
import AssignmentSettings from './index';
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/ticket-assignment'),
      roles: ['administrator'],
      component: SettingsContent,
      props: {
        headerTitle: 'SIDEBAR.TICKET_ASSIGNMENT',
        icon: 'ticket',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'ticket_assignment',
          component: AssignmentSettings,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
