/* eslint arrow-body-style: ["error", "always"] */

const endPoints = {
  profile: {
    url: '/api/v1/profile',
  },

  me: {
    url: 'api/v1/conversations.json',
    params: { type: 0, page: 1 },
  },

  getInbox: {
    url: 'api/v1/conversations.json',
    params: { inbox_id: null },
  },

  fetchFacebookPages: {
    url(accountId) {
      return `api/v1/accounts/${accountId}/callbacks/facebook_pages.json`;
    },
    params: { omniauth_token: '', social_platform: '' },
  },
};

export default page => {
  return endPoints[page];
};
