<template>
  <ul class="tab--panel flex-row-justify-center">
    <li
      v-for="item in tabItems"
      :key="item.key"
      :style="tabItems.length === 1 && 'justify-content: center'"
      class="tab--item"
      @click.prevent="onClick(item.key)"
    >
      <span v-if="item.icon" class="tab--icon">
        <icons
          :name="item.icon"
          :color="isActive(item.key) ? 'active' : 'inactive'"
          size="medium"
        />
      </span>
      <div
        class="item--text title-h5"
        :class="[{ active: isActive(item.key) }, item.key]"
      >
        <span :class="{ 'active--text': isActive(item.key) }">{{
          item.name
        }}</span>
        <span
          v-if="item.label"
          class="item--label body-b3"
          :class="{ 'active--count': isActive(item.key) }"
        >
          {{ getItemCount(item.label) }}
        </span>
      </div>
    </li>
  </ul>
</template>

<script>
import TWEEN from 'tween.js';
export default {
  props: {
    tabItems: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      animatedNumber: 0,
    };
  },
  computed: {
    activeTabIndex() {
      return this.tabItems.findIndex(item => item.key === this.active);
    },
  },
  watch: {
    count(newValue, oldValue) {
      let animationFrame;
      const animate = time => {
        TWEEN.update(time);
        animationFrame = window.requestAnimationFrame(animate);
      };
      const tweeningNumber = { value: oldValue };
      new TWEEN.Tween(tweeningNumber)
        .easing(TWEEN.Easing.Quadratic.Out)
        .to({ value: newValue }, 0)
        .onUpdate(() => {
          this.animatedNumber = tweeningNumber.value.toFixed(0);
        })
        .onComplete(() => {
          window.cancelAnimationFrame(animationFrame);
        })
        .start();
      animationFrame = window.requestAnimationFrame(animate);
    },
  },
  methods: {
    isActive(e) {
      return e === this.active;
    },
    getItemCount(label) {
      return this.animatedNumber || label;
    },
    onClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.tab--panel {
  margin: $space-two $space-normal;

  &:hover {
    cursor: pointer;
  }

  > li {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    .item--text {
      color: $neutral-grey-600;
      padding: $space-smaller;
      display: flex;
      flex-direction: row;
      align-items: center;
      transition: font 0.3s ease;
      width: fit-content;
      text-align: center;
      .item--label {
        margin-left: $space-smaller;
      }
    }

    .active--text {
      font-size: $font-size-medium;
      font-weight: $font-weight-black;
      line-height: $height-h3;
      color: $pg-1-500;
    }

    .active--count {
      font-size: $font-size-mini;
      font-weight: $font-weight-black;
      line-height: $height-h6;
      color: $pg-1-500;
      margin-top: $space-micro;
    }

    .tab--icon {
      @include flex-center;
      margin-right: $space-small;
    }
  }
  li:first-child {
    justify-content: left;
    margin-right: 2.5rem;
  }
  li:last-child {
    justify-content: right;
    margin-left: 2.5rem;
  }

  .active {
    border-bottom: 3px solid $color-woot;
  }
}
</style>
