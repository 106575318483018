<template>
  <div class="add-contact-wrapper">
    <div class="add-email-wrapper flex-column gap--smaller">
      <span class="title-h6 text-dark" style="padding: 0 8px" v-text="title" />
      <div class="search-contact" style="margin-bottom: 0;">
        <search-box
          placeholder="Search for contact email IDs here"
          custom-class="search-box"
          size="medium"
          input-type="email"
          :value="searchQuery"
          :on-search="onSearchSubmit"
          :on-enter="onAddNewContact"
          @setSearch="onInputSearch"
        />
        <woot-base-button
          variant="secondary"
          size="small"
          :disabled="!searchQuery"
          @click="onAddNewContact"
        >
          ADD
        </woot-base-button>
      </div>
      <transition name="slide-up">
        <show-contact-results-panel
          v-if="showSearchResults"
          :is-loading="isFetchingContacts"
          inbox-type="email"
          :contacts="filteredContacts"
          :on-select-contact="onAddContact"
          :on-add-new-contact="onAddNewContact"
          :can-load-more-results="canLoadMoreResults"
          :close-panel="() => (showSearchResults = false)"
          @onPageChange="onPageChange"
        />
      </transition>
      <show-selected-emails
        :contact-type="emailContactType"
        :contacts="selectedContacts"
        @updateContacts="onUpdateContacts"
      />
    </div>
  </div>
</template>
<script>
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import ShowSelectedEmails from './ShowSelectedEmails';
import ShowContactResultsPanel from 'dashboard/components/widgets/addContact/showContactResults/ShowContactResultsPanel';

import createTicketMixin from 'dashboard/mixins/createTicketMixin';
import { getNameFromEmail } from '../../utils/helper';
import { EMAIL_TYPES } from '../../utils/constants';

export default {
  components: { ShowContactResultsPanel, SearchBox, ShowSelectedEmails },
  mixins: [createTicketMixin],
  props: {
    type: {
      type: String,
      default: 'To',
      validator: val => ['To', 'CC', 'BCC'].includes(val),
    },
  },
  computed: {
    title() {
      return `${this.type}:`;
    },
    emailContactType() {
      return EMAIL_TYPES[this.type];
    },
  },
  methods: {
    onAddNewContact() {
      if (!this.searchQuery.trim()) return null;
      const newContact = {
        name: getNameFromEmail(this.searchQuery),
        email: this.searchQuery,
        type: 'new',
      };

      return this.onAddContact(newContact);
    },
    onSearchSubmit(page = 1, append = false) {
      if (!this.searchQuery) {
        this.clearSearchResults();
      }
      this.clearTimer();
      this.currentPage = page;
      this.showSearchResults = true;
      this.isFetchingContacts = true;
      this.canLoadMoreResults = true;
      this.$store
        .dispatch('contacts/getSearchedContacts', {
          search: this.searchQuery,
          searchBy: 'email',
          page: this.currentPage,
          isSearching: true,
        })
        .then(({ results }) => {
          this.isFetchingContacts = false;

          if (results.length === 0 || results.length < 10)
            this.canLoadMoreResults = false;

          this.fetchedContacts = append
            ? [...this.fetchedContacts, ...results]
            : results;
        })
        .catch(() => {
          this.isFetchingContacts = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.add-email-wrapper {
  max-width: $space-mega * 5;
  padding: $space-small $space-slab;
  margin-bottom: $space-small;
  border-radius: $space-smaller;
  border: 1px solid $neutral-grey-300;
  background: $neutral-grey-100;
}
</style>
