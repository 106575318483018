<template>
  <div class="automation-form">
    <automation-header
      :automation="automation"
      :mode="mode"
      :on-show-details-modal="() => (showDetailsModal = true)"
      :on-update-automation-detail="onUpdateAutomationDetail"
      :on-close="onClose"
    />
    <automation-details-modal
      :opened="showDetailsModal"
      :name="automation.name"
      :description="automation.description"
      @updateDetail="onUpdateAutomationDetail"
      @onClose="onCloseDetailsModal"
      @onSubmit="onCloseDetailsModal"
    />
    <div class="automation-form-wrapper">
      <div
        v-if="!isLoadingData && automation"
        style="position: relative"
        class="full-height"
      >
        <div class="sections">
          <event-selector
            :selected-event="automation.event_name"
            :events="eventsList"
            @select="onSelectEvent"
          />
          <conditions-section
            v-if="automation.event_name"
            :conditions="automation.conditions"
            :event-name="automation.event_name"
            :mode="mode"
            @appendCondition="appendNewCondition"
            @resetFilter="resetFilter"
            @removeFilter="removeFilter"
          />
          <actions-section
            v-if="automation.event_name"
            :actions="automation.actions"
            :action-types="automationActionTypes"
            :files="automation.files"
            @appendAction="appendNewAction"
            @resetAction="resetAction"
            @removeAction="removeAction"
          />
        </div>
        <div class="form-footer flex-row flex-justify--between full-width">
          <woot-base-button
            size="small"
            class="modal-button"
            :loading="isSubmitting"
            @click="submitAutomation"
          >
            {{ submitButtonText }}
          </woot-base-button>
          <woot-base-button
            size="small"
            class="modal-button"
            variant="secondary-danger"
            @click.prevent="onClose"
          >
            {{ $t('AUTOMATION.EDIT.CANCEL_BUTTON_TEXT') }}
          </woot-base-button>
        </div>
      </div>
      <loading-state v-else-if="isLoadingData" style="height: 100%" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import AutomationHeader from './header';
import LoadingState from 'dashboard/components/ui/LoadingState';
import ConditionsSection from './conditions/Section';
import ActionsSection from './actions/Section';
import EventSelector from './EventSelector';
import AutomationDetailsModal from '../shared/AutomationDetailsModal';

import alertMixin from 'shared/mixins/alertMixin';
import accountMixin from 'dashboard/mixins/account';
import automationMethodsMixin from '../../utils/mixins/methodsMixin';

import {
  AUTOMATION_RULE_EVENTS,
  AUTOMATION_ACTION_TYPES,
  AUTOMATIONS,
  DEFAULT_AUTOMATION_PAYLOAD,
  MODES,
} from '../../utils/constants';
import { getDefaultAutomation } from '../../utils/automationHelper';

export default {
  components: {
    AutomationHeader,
    LoadingState,
    ConditionsSection,
    ActionsSection,
    EventSelector,
    AutomationDetailsModal,
  },
  mixins: [alertMixin, accountMixin, automationMethodsMixin],
  props: {
    selectedResponse: {
      type: Object,
      default: () => ({ ...DEFAULT_AUTOMATION_PAYLOAD }),
    },
    mode: {
      type: String,
      default: MODES.CREATE,
    },
  },
  data() {
    return {
      automation: { ...DEFAULT_AUTOMATION_PAYLOAD },
      automationTypes: JSON.parse(JSON.stringify(AUTOMATIONS)),
      automationRuleEvents: AUTOMATION_RULE_EVENTS,
      automationActionTypes: AUTOMATION_ACTION_TYPES,
      isLoadingData: false,
      showDetailsModal: false,
      MODES,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      uiFlags: 'automations/getUIFlags',
    }),
    isSubmitting() {
      return this.mode === MODES.EDIT
        ? this.uiFlags.isUpdating
        : this.uiFlags.isCreating;
    },
    submitButtonText() {
      return this.mode !== MODES.EDIT
        ? this.$t('AUTOMATION.ADD.SUBMIT')
        : this.$t('AUTOMATION.EDIT.SUBMIT');
    },
    eventsList() {
      return this.automationRuleEvents.map(event => ({
        id: event.key,
        name: event.value,
        value: event.key,
      }));
    },
    automationDetails() {
      const { name, description } = this.$router.history.current?.params || {};
      return { name, description };
    },
  },
  mounted() {
    this.getData();
    this.populateAutomationDetails();
  },
  methods: {
    getData() {
      this.isLoadingData = true;
      axios
        .all([
          this.$store.dispatch('inboxes/get'),
          this.$store.dispatch('agents/get'),
          this.$store.dispatch('labels/getLabelsWithoutGroups'),
        ])
        .then(() => {
          this.isLoadingData = false;
          this.setAutomationByMode();
        });
    },
    populateAutomationDetails() {
      this.automation.name = this.automationDetails.name;
      this.automation.description = this.automationDetails.description;
    },
    setAutomationByMode() {
      switch (this.mode) {
        case MODES.EDIT:
          this.formatAutomation(this.selectedResponse);
          break;

        case MODES.CREATE_WITH_TEMPLATE: {
          const { name, description, ...rest } = this.selectedResponse;
          this.formatAutomation({ ...this.automation, ...rest });
          break;
        }

        case MODES.CREATE:
          this.formatAutomation(this.automation);
          break;

        default:
          this.resetAutomationPayload(this.automation);
      }
    },
    onUpdateAutomationDetail(data) {
      this.automation = { ...this.automation, ...data };
    },
    onSelectEvent({ value: newEventName }) {
      if (this.automation.event_name !== newEventName) {
        this.resetAutomationPayload({
          ...this.automation,
          event_name: newEventName,
        });
      }
    },
    resetAutomationPayload(oldAutomation) {
      this.automation = getDefaultAutomation(oldAutomation);
    },
    onClose() {
      this.$router.push(this.addAccountScoping('settings/automation/list'));
    },
    onCloseDetailsModal() {
      this.showDetailsModal = false;
    },
  },
};
</script>
