import types from '../../mutation-types';
import CustomTicketFieldsAPI from '../../../api/customTicketFields/customTicketFields';
import ConversationApi from '../../../api/inbox/conversation';

const actions = {
  get: async ({ commit, state }) => {
    if (state.records.length) return;
    commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isFetching: true });
    try {
      const { data } = await CustomTicketFieldsAPI.get();
      commit(types.SET_CUSTOM_TICKET_FIELDS_LIST, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isFetching: false });
    }
  },

  getFieldWithId: async ({ commit }, { id }) => {
    commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isFetching: true });
    try {
      const { data } = await CustomTicketFieldsAPI.show(id);
      commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isFetching: false });
      return data;
    } catch (error) {
      commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },

  create: async ({ commit }, payload) => {
    commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: true });
    try {
      const { data } = await CustomTicketFieldsAPI.create(payload);
      commit(types.ADD_CUSTOM_TICKET_FIELD, data);
    } catch (error) {
      throw new Error(error.response?.data?.error[0] || error);
    } finally {
      commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: false });
    }
  },

  delete: async ({ commit }, { id }) => {
    commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: true });
    try {
      await CustomTicketFieldsAPI.delete(id).then(() => {
        commit(types.REMOVE_CUSTOM_TICKET_FIELD, id);
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: false });
    }
  },

  edit: async ({ commit }, { id, ...payload }) => {
    commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: true });
    try {
      const { data } = await CustomTicketFieldsAPI.update(id, payload);
      commit(types.SET_CUSTOM_TICKET_FIELD_BY_ID, data);
      return data;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: false });
    }
  },

  toggle: async ({ commit }, { id, status }) => {
    commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: true });
    try {
      await CustomTicketFieldsAPI.toggle_status(id, status);
      commit(types.TOGGLE_CUSTOM_TICKET_FIELD_STATUS, { id, status });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: false });
    }
  },

  addInboxes: async ({ commit }, { id, inboxIds }) => {
    commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: true });
    try {
      await CustomTicketFieldsAPI.add_inboxes(id, inboxIds);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: false });
    }
  },

  removeInboxes: async ({ commit }, { id, inboxIds }) => {
    commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: true });
    try {
      await CustomTicketFieldsAPI.remove_inboxes(id, inboxIds);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: false });
    }
  },

  addOption: async ({ commit }, { id, option }) => {
    commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: true });
    try {
      await CustomTicketFieldsAPI.add_option(id, option);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: false });
    }
  },

  removeOption: async ({ commit }, { id, optionId }) => {
    commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: true });
    try {
      await CustomTicketFieldsAPI.remove_option(id, optionId);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CUSTOM_TICKET_FIELDS_UI_FLAG, { isUpdating: false });
    }
  },

  getCustomFieldValues: async (_, { conversationId }) => {
    try {
      const {
        data: { payload },
      } = await ConversationApi.getCustomFieldValues(conversationId);
      return payload;
    } catch (error) {
      throw new Error(error);
    }
  },

  updateCustomFieldValues: async (_, { conversationId, customFieldValues }) => {
    try {
      await ConversationApi.updateCustomFieldValues(
        conversationId,
        customFieldValues
      );
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default actions;
