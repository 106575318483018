<template>
  <woot-switch :id="data.name" :value="data.active" @click="toggleSLA" />
</template>
<script>
export default {
  props: {
    data: { type: Object, default: () => {} },
  },
  methods: {
    toggleSLA() {
      this.$emit('toggle', { id: this.data.id, active: !this.data.active });
    },
  },
};
</script>
