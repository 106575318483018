import templateIntroImage from 'assets/images/templates_intro.svg';

export const SHOWCASE_TEMPLATES_DATA = [
  {
    name: 'templates',
    key: 'TEMPLATES',
    image: templateIntroImage,
    contentPath: 'TEMPLATE',
  },
];
