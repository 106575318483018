<template>
  <div class="keyword-selector">
    <woot-input
      v-model="keyword"
      :size="size"
      :label="inputLabel"
      :placeholder="inputPlaceholder"
      :required="required"
      :type="type"
      :has-error="!!errorMessage"
      :error="errorMessage"
      :help-text="helpText"
      v-bind="$attrs"
      v-on="$listeners"
      @keyup.enter.native="addKeyword"
      @input="clearError"
    />
    <div class="mg-top--smaller">
      <woot-chips
        v-for="(chip, index) in keywords"
        :key="index"
        :title="chip"
        :custom-style="{
          color: 'text-black-800',
        }"
        variant="secondary-small"
        :show-cancel-icon="true"
        :show-tooltip="false"
        @click="removeKeyword(index)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'KeywordInput',
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    keywords: {
      type: Array,
      default: () => [],
    },
    inputLabel: {
      type: String,
      default: '',
    },
    inputPlaceholder: {
      type: String,
      default: 'Type a keyword and press enter',
    },
    required: {
      type: Boolean,
      default: false,
    },
    validationPattern: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    helpText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      keyword: '',
      errorMessage: '',
    };
  },
  methods: {
    addKeyword() {
      const trimmedKeyword = this.keyword.trim();

      if (!trimmedKeyword) return;

      if (this.keywords.includes(trimmedKeyword)) {
        this.errorMessage = 'Keyword already added';
        return;
      }

      if (
        this.validationPattern &&
        !new RegExp(this.validationPattern).test(trimmedKeyword)
      ) {
        this.errorMessage = 'Invalid input provided';
        return;
      }

      const updatedKeywords = [...this.keywords, trimmedKeyword];
      this.keyword = '';
      this.errorMessage = '';
      this.$emit('updateKeywords', updatedKeywords);
    },
    removeKeyword(index) {
      const updatedKeywords = [...this.keywords];
      updatedKeywords.splice(index, 1);
      this.$emit('updateKeywords', updatedKeywords);
    },
    clearError() {
      this.errorMessage = '';
    },
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  margin-top: 5px;
}
</style>
