<template>
  <div class="zero-state-container">
    <div>
      <h2
        class="title-h2 text-light mg-bottom--medium"
        v-text="$t('TICKET_ASSIGNMENT.NO_INBOXES.TITLE')"
      />
      <a
        :href="ADD_INBOX_DEMO_VIDEO_URL"
        target="_blank"
        rel="noopener noreferrer"
      >
        <video
          preload
          autoplay
          muted
          loop
          height="360"
          class="video-container"
          onloadstart="playbackRate = 3"
        >
          <source :src="ADD_INBOX_DEMO_VIDEO_URL" type="video/mp4" />
        </video>
      </a>
      <router-link :to="newInboxURL()">
        <woot-base-button class="mg-auto mg-top--medium">
          {{ $t('CONVERSATION.ADD_INBOX') }}
        </woot-base-button>
      </router-link>
    </div>
  </div>
</template>
<script>
import accountMixin from 'dashboard/mixins/account';
import { ADD_INBOX_DEMO_VIDEO_URL } from '../utils/constants';

export default {
  mixins: [accountMixin],
  data() {
    return {
      ADD_INBOX_DEMO_VIDEO_URL,
    };
  },
  methods: {
    newInboxURL() {
      return this.addAccountScoping('settings/inboxes/new');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.zero-state-container {
  display: grid;
  height: 100%;

  div {
    place-self: center;
  }

  .video-container {
    border-radius: $border-radius;
    border: 1px solid $neutral-grey-400;
  }
}
</style>
