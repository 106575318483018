<template>
  <div class="full-width">
    <Header />
    <div v-if="computedInbox" class="create-ticket-wrapper">
      <create-ticket-header :inbox="computedInbox" />
      <create-wa-ticket-form
        v-if="computedInbox.isWhatsAppInbox"
        :inbox-id="computedInbox && computedInbox.id"
        @submit="onSubmit"
      />
      <create-email-ticket-form
        v-if="computedInbox.isEmailInbox"
        :inbox-id="computedInbox && computedInbox.id"
        @submit="onSubmit"
      />
      <success-modal
        :show="showSuccessModal"
        :contacts="selectedContacts"
        :inbox-type="
          computedInbox && computedInbox.isWhatsAppInbox ? 'whatsapp' : 'email'
        "
        @closeModal="onCloseModal"
      />
    </div>
    <create-ticket-zero-state v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Header from 'dashboard/components/layout/Header';
import CreateTicketHeader from './components/CreateTicketHeader';
import CreateWaTicketForm from './components/form/createWATicketForm';
import CreateEmailTicketForm from './components/form/createEmailTicketForm';
import CreateTicketZeroState from './components/CreateTicketZeroState';
import SuccessModal from './components/SuccessModal';

import alertMixin from 'shared/mixins/alertMixin';
import { validatePayload } from './components/form/utils/validations';
import showError from 'dashboard/helper/showError';
import { mixpanelEventWithProps } from 'dashboard/helper/mixpanel';
import { isAWhatsAppChannel, INBOX_TYPES } from 'shared/mixins/inboxMixin';

const ERROR_PREFIX = 'CREATE_TICKET';

export default {
  components: {
    Header,
    CreateTicketHeader,
    CreateWaTicketForm,
    CreateEmailTicketForm,
    CreateTicketZeroState,
    SuccessModal,
  },
  mixins: [alertMixin],
  data() {
    return { showSuccessModal: false, selectedContacts: [] };
  },
  computed: {
    ...mapGetters({
      getInbox: 'inboxes/getInbox',
      accountId: 'getCurrentAccountId',
    }),
    computedInbox() {
      const { inbox_id = undefined } = this.$route.params;
      if (!inbox_id) return null;
      let selectedInbox = this.getInbox(inbox_id);
      return {
        id: selectedInbox.id,
        name: selectedInbox.name,
        type: selectedInbox.channel_type,
        isWhatsAppInbox: isAWhatsAppChannel(selectedInbox.channel_type),
        isEmailInbox: selectedInbox.channel_type === INBOX_TYPES.EMAIL,
      };
    },
  },
  methods: {
    isFormValid(payload) {
      const [errorMessage, isValid, errorneousFieldClassName] = validatePayload(
        payload
      );

      showError({
        message: this.$t(`CREATE_TICKET.FORM.ERRORS.${errorMessage}`),
        isValid,
        errorneousFieldClassName: `${ERROR_PREFIX}_${errorneousFieldClassName}`,
      });
      return isValid;
    },
    onSubmit(payload) {
      payload = {
        ...payload,
        inbox_id: this.computedInbox?.id,
        is_whatsapp_inbox: this.computedInbox?.isWhatsAppInbox,
        is_email_inbox: this.computedInbox?.isEmailInbox,
      };
      const isValid = this.isFormValid(payload);

      mixpanelEventWithProps('clicked_create_ticket_send_message', {
        isFormValid: isValid,
      });

      let requiredPayload;

      if (payload.attachments && payload.attachments.length !== 0) {
        requiredPayload = new FormData();

        requiredPayload.append('inbox_id', payload.inbox_id);
        if (payload.agent_id)
          requiredPayload.append('agent_id', payload.agent_id);
        if (payload.team_id) requiredPayload.append('team_id', payload.team_id);
        if (payload.contacts) {
          payload.contacts.forEach(contact => {
            requiredPayload.append('contacts[][type]', contact.type);
            requiredPayload.append(
              'contacts[][email_address]',
              contact.email_address
            );
            requiredPayload.append('contacts[][name]', contact.name);
          });
        }
        if (payload.subject) requiredPayload.append('subject', payload.subject);
        if (payload.content) requiredPayload.append('content', payload.content);
        if (payload.bcc_emails)
          requiredPayload.append('bcc_emails', payload.bcc_emails);
        if (payload.cc_emails)
          requiredPayload.append('cc_emails', payload.cc_emails);
        requiredPayload.append(
          'has_email_signature',
          payload.has_email_signature
        );
        payload.attachments.forEach(file => {
          requiredPayload.append('attachments[]', file);
        });
        if (payload.labels)
          payload.labels.forEach(label => {
            requiredPayload.append('labels[]', label);
          });
        if (payload.contact_labels && payload.contact_labels.length)
          payload.contact_labels.forEach(label => {
            requiredPayload.append('contact_labels[]', label);
          });
      } else {
        const {
          has_media,
          has_dynamic_button,
          ...deconstructedPayload
        } = payload;
        requiredPayload = deconstructedPayload;
      }

      if (isValid) {
        this.$store
          .dispatch('createConversation', requiredPayload)
          .then(() => {
            this.selectedContacts = payload.contacts;
            this.showSuccessModal = true;
          })
          .catch(() => {
            this.showAlert(this.$t('CREATE_TICKET.FORM.SUBMIT.ERROR'), 'error');
          });
      }
    },
    onCloseModal() {
      this.showSuccessModal = false;
      this.$router.push(`/app/accounts/${this.accountId}/dashboard`);
    },
  },
};
</script>
