<template>
  <div class="shopify-integration">
    <woot-loading-state v-if="isLoading" message="Fetching shopify details" />
    <div :class="isLoading ? 'disable-container' : ''">
      <div v-if="connectionStatus">
        <connected :domain="domain" @get-shopify="getShopify" />
      </div>

      <div v-else>
        <connect @shopify-connect="shopifyConnect" />
      </div>
    </div>
  </div>
</template>

<script>
import Connect from './Connect';
import Connected from './Connected';
import alertMixin from 'shared/mixins/alertMixin';
export default {
  components: {
    Connect,
    Connected,
  },
  mixins: [alertMixin],
  data() {
    return {
      connectionStatus: null,
      domain: '',
      isLoading: false,
    };
  },
  mounted() {
    this.getShopify();
  },
  methods: {
    shopifyConnect(domain) {
      this.$store
        .dispatch('updateShopifyDomain', {
          domain: domain,
        })
        .then(response => {
          if (response) {
            this.showAlert('Shopify Connected', 'success');
            this.connectionStatus = true;
          } else {
            this.showAlert('Not a valid domain', 'warning');
            this.connectionStatus = false;
          }
        })
        .catch(() => {
          this.showAlert('Not a valid domain', 'warning');
          this.connectionStatus = false;
        });
      this.getShopify();
    },

    getShopify() {
      this.isLoading = true;
      this.$store.dispatch('goToShopifyStore').then(response => {
        this.isLoading = false;
        this.connectionStatus = response.status;
        this.domain = response.shopify;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shopify-integration {
  display: flex;
  justify-content: center;
  height: 100vh !important;
  flex-direction: column;
}

.disable-container {
  pointer-events: none;
  opacity: 0.3;
}
</style>
