<template>
  <div class="code--container">
    <div v-if="isWhatsapp">
      <div class="code--container--header flex-space-between">
        <span class="body-b2">
          {{ $t('COMPONENTS.CODE.WIDGET_CODE_TITLE') }}
          {{ $t('COMPONENTS.CODE.WHATSAPP_TITLE_TEXT') }}
        </span>
        <woot-base-button size="small" @click="onCopy">
          {{ $t('COMPONENTS.CODE.WHATSAPP_BUTTON_TEXT') }}
        </woot-base-button>
      </div>
      <highlightjs
        class="whatsapp-widget-pane"
        :language="lang"
        :code="script"
      />
    </div>
    <div v-else-if="isLimechatWidget">
      <div class="code--container--header flex-space-between">
        <span class="body-b2">
          {{ $t('COMPONENTS.CODE.WIDGET_CODE_TITLE') }}
          {{ $t('COMPONENTS.CODE.LIMECHAT_TITLE_TEXT') }}
        </span>
        <woot-base-button size="small" @click="onCopy">
          {{ $t('COMPONENTS.CODE.WHATSAPP_BUTTON_TEXT') }}
        </woot-base-button>
      </div>
      <highlightjs
        class="whatsapp-widget-pane"
        :language="lang"
        :code="script"
      />
    </div>
    <div v-else>
      <woot-base-button class="button--copy-code" size="small" @click="onCopy">
        {{ $t('COMPONENTS.CODE.BUTTON_TEXT') }}
      </woot-base-button>
      <highlightjs :language="lang" :code="script" />
    </div>
  </div>
</template>

<script>
import 'highlight.js/styles/default.css';
import copy from 'copy-text-to-clipboard';
import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  props: {
    script: {
      type: String,
      default: '',
    },
    lang: {
      type: String,
      default: 'javascript',
    },
    isWhatsapp: {
      type: Boolean,
      default: false,
    },
    isLimechatWidget: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCopy() {
      if (this.isLimechatWidget || this.isWhatsapp) {
        if (this.isLimechatWidget) {
          this.mixPanelWebWidgetScriptCopied();
        } else {
          this.mixPanelWAWidgetScriptCopied();
        }
      }

      copy(this.script);
      this.showAlert(this.$t('COMPONENTS.CODE.COPY_SUCCESSFUL'), 'info');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.code--container {
  position: relative;
  text-align: left;
  background-color: $pg-light-1;
  color: $pg-1-600;
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius-smaller;

  ::v-deep .hljs {
    background-color: transparent;
    font-size: $font-size-mini;
    font-weight: $font-weight-normal;
    line-height: $height-h7;
  }

  &--header {
    padding: $space-small;
    width: 99.2%;
    font-weight: $font-weight-medium;
  }

  .button--copy-code {
    margin-top: $space-small;
    position: absolute;
    right: $space-small;
  }

  .whatsapp-widget-pane {
    min-height: $space-normal * 10;
    height: 26vh;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
