<template>
  <Header
    class="mg-bottom--slab"
    :name="automation.name"
    :description="automation.description"
    :name-placeholder="$t('AUTOMATION.ADD.FORM.NAME.PLACEHOLDER')"
    :description-placeholder="$t('AUTOMATION.ADD.FORM.DESC.LONG_PLACEHOLDER')"
    :on-click="onShowDetailsModal"
    :on-back="onClose"
  >
    <template v-slot:right-section>
      <div
        v-if="isEditMode && automation.id"
        class="flex-row gap--small flex-align--end"
      >
        <test-automation
          :automation-test-data="automationTestData"
          @updateAutomationTestData="onUpdateAutomationDetail"
        />
        <toggle-automation-rule
          class="automation-toggler--secondary"
          :data="automation"
          show-status-text
          @onToggle="({ active }) => onUpdateAutomationDetail({ active })"
        />
      </div>
    </template>
  </Header>
</template>

<script>
import Header from 'dashboard/components/ui/Header';
import TestAutomation from '../testAutomation';
import ToggleAutomationRule from '../../shared/ToggleAutomationRule';
import { MODES } from '../../../utils/constants';

export default {
  components: {
    Header,
    TestAutomation,
    ToggleAutomationRule,
  },
  props: {
    automation: {
      type: Object,
      default: () => null,
    },
    mode: {
      type: String,
      default: MODES.CREATE,
    },
    onUpdateAutomationDetail: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      default: () => null,
    },
    onShowDetailsModal: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    automationTestData() {
      const { is_test, test_numbers } = this.automation;
      return { is_test, test_numbers };
    },
    isEditMode() {
      return this.mode === MODES.EDIT;
    },
  },
};
</script>
