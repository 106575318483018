<template>
  <div
    :class="['view-item', { active: isActive, default: isDefault }]"
    @click="makeCurrent"
  >
    <div class="flex-row flex-align gap--small">
      <icons
        v-if="isDefault"
        name="flagFilled"
        color="orange"
        size="semimedium"
      />
      <span class="view-name" v-text="data.name" />
    </div>
    <div v-if="controls.length !== 0" class="view-item-control-panel">
      <woot-base-button
        v-if="controls.includes('makeDefault') && !isDefault"
        tag="span"
        size="small"
        variant="tertiary"
        @click.stop="makeDefault"
      >
        <icons
          name="flag"
          color="grey"
          size="semimedium"
          title="make default"
        />
      </woot-base-button>
      <woot-base-button
        v-if="controls.includes('edit')"
        tag="span"
        size="small"
        variant="tertiary"
        @click.stop="edit"
      >
        <icons name="editRound" color="grey" size="semimedium" title="edit" />
      </woot-base-button>
      <woot-base-button
        v-if="controls.includes('clone')"
        tag="span"
        size="small"
        variant="tertiary"
        @click.stop="edit"
      >
        <icons
          name="copy"
          color="grey"
          size="medium"
          title="edit"
          view="-2 -3 22 22"
        />
      </woot-base-button>
      <woot-base-button
        v-if="controls.includes('delete') && !isDefault"
        tag="span"
        size="small"
        variant="tertiary-danger"
        @click.stop="deleteItem"
      >
        <icons name="bin" color="grey" size="semimedium" title="delete" />
      </woot-base-button>
    </div>
  </div>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    data: {
      type: Object,
      default: () => ({
        id: 0,
        name: '',
      }),
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    makeCurrent() {
      this.$emit('onMakeCurrent', this.data);
    },
    makeDefault() {
      this.$emit('onMakeDefault', this.data);
    },
    edit() {
      this.$emit('onEdit', this.data);
    },
    deleteItem() {
      this.$emit('onDelete', this.data);
    },
  },
};
</script>
