<template>
  <div v-if="enabledCrms.length !== 0" class="ticket-form-container">
    <div class="content-margin">
      <woot-single-selector
        :default-option="selectedCrm"
        :option-list="crmTypeList"
        :custom-style="{
          left: 0,
          top: 0,
          width: '20rem',
        }"
        @click="onSelectCrmType"
      />
    </div>
    <component :is="currentCRMComponent" :key="selectedCrm" />
  </div>
  <span v-else class="body-b3 text-light" v-text="'No CRMs available'" />
</template>

<script>
import { mapGetters } from 'vuex';

import FreshdeskTicketForm from './FreshdeskTicketForm';
import ZohoTicketForm from './ZohoTicketForm';
import KaptureTicketForm from './KaptureTicketForm';
import OnedirectTicketForm from './OnedirectTicketForm';
import ZendeskTicketForm from './ZendeskTicketForm';
import OdooTicketForm from './OdooTicketForm';
import JiraTicketForm from './JiraTicketForm';
import LeadSquaredTicketForm from './LeadSquaredTicketForm';

import { AVAILABLE_CRMS } from 'dashboard/routes/dashboard/conversation/overview/constants';

export default {
  components: {
    FreshdeskTicketForm,
    ZohoTicketForm,
    KaptureTicketForm,
    OnedirectTicketForm,
    ZendeskTicketForm,
    OdooTicketForm,
    JiraTicketForm,
    LeadSquaredTicketForm,
  },
  computed: {
    ...mapGetters({
      enabledCrms: 'crm/getEnabledCrms',
      selectedCrm: 'crm/getSelectedCrm',
    }),
    crmTypeList() {
      return this.enabledCrms?.map((crm, idx) => ({
        id: idx,
        name: crm,
      }));
    },
    currentCRMComponent() {
      const componentMap = {
        [AVAILABLE_CRMS.FRESHDESK]: 'FreshdeskTicketForm',
        [AVAILABLE_CRMS.ZOHO]: 'ZohoTicketForm',
        [AVAILABLE_CRMS.KAPTURE]: 'KaptureTicketForm',
        [AVAILABLE_CRMS.ONEDIRECT]: 'OnedirectTicketForm',
        [AVAILABLE_CRMS.ZENDESK]: 'ZendeskTicketForm',
        [AVAILABLE_CRMS.ODOO]: 'OdooTicketForm',
        [AVAILABLE_CRMS.JIRA]: 'JiraTicketForm',
        [AVAILABLE_CRMS.LEADSQUARED]: 'LeadSquaredTicketForm',
      };

      return componentMap[this.selectedCrm] || null;
    },
  },
  methods: {
    onSelectCrmType({ name: value }) {
      this.setSelectedCrm(value);
    },
    setSelectedCrm(name) {
      this.$store.dispatch('crm/setSelectedCrm', name);
    },
  },
};
</script>
