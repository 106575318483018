<template>
  <woot-modal
    class="progress-tracker-modal"
    show-close
    :show.sync="showPopup"
    :on-close="() => $emit('closeModal')"
  >
    <header class="modal-header">
      <img
        :src="require('assets/images/progress_tracker.svg')"
        class="image"
        alt="welcome-illustration"
      />
    </header>
    <main class="modal-content">
      <h1
        class="title-h1 content-margin"
        v-text="$t('SIDEBAR_ITEMS.PROGRESS_TRACKER_MODAL.TITLE')"
      />
      <reference-box
        class="reference-box content-margin"
        :content="$t('SIDEBAR_ITEMS.PROGRESS_TRACKER_MODAL.SUB_TITLE')"
      />
      <div class="row flex-row gap--slab mg-bottom--large">
        <div
          v-for="task in tasksList"
          :key="task.key"
          class="task-container flex-row flex-justify--between flex-align"
          :class="{ active: task.active, 'not-active': task.active === false }"
          @click="navigateTo(task)"
        >
          <div
            class="flex-row flex-justify flex-align"
            :class="task.active ? 'gap--slab' : 'gap--two'"
          >
            <span>
              <icons
                v-if="task.active"
                :name="task.active ? 'tickSingle' : 'error'"
                :color="task.active ? 'green' : 'danger'"
                size="mediumlarge"
                view="-2 -3 24 24"
                :show-title="false"
              />
              <icons
                v-else-if="task.active === false"
                name="error"
                color="danger"
                size="medium"
                :show-title="false"
              />
              <icons
                v-else
                name="info"
                color="warning"
                size="semimedium"
                :show-title="false"
              />
            </span>
            <span class="title-h4 text-dark" v-text="task.label" />
          </div>
          <div>
            <span
              class="body-b3 text-light task-status"
              v-text="taskStatus(task.active)"
            />
          </div>
        </div>
      </div>
      <div>
        <woot-base-button
          size="small"
          variant="tertiary-danger"
          class="mg-auto"
          @click="() => $emit('closeModal')"
        >
          {{ $t('SIDEBAR_ITEMS.PROGRESS_TRACKER_MODAL.CLOSE') }}
        </woot-base-button>
      </div>
    </main>
  </woot-modal>
</template>
<script>
import { mapGetters } from 'vuex';

import ReferenceBox from 'dashboard/components/ui/ReferenceBox';

import { frontendURL } from 'dashboard/helper/URLHelper.js';

export default {
  components: {
    ReferenceBox,
  },
  data() {
    return {
      showPopup: true,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      inboxes: 'inboxes/getInboxes',
      agents: 'agents/getAgents',
      templates: 'getTemplates',
      broadcastList: 'getBroadcastList',
    }),
    tasksList() {
      return [
        {
          key: 'inboxes',
          label: 'Add an Inbox',
          active: !!this.inboxes.length,
        },
        {
          key: 'agents',
          label: 'Add an Agent',
          active: this.agents.length > 1,
        },
        {
          key: 'template',
          label: 'Add a Template',
          active: !!this.templates.length,
        },
        {
          key: 'broadcast',
          label: 'Send a Broadcast',
          active: !!this.broadcastList.length,
        },
        {
          key: 'general',
          label: 'Change Account Settings',
        },
      ];
    },
  },
  methods: {
    navigateTo(task) {
      if (task.active) return;

      let path = '';
      if (task.key === 'broadcast')
        path = `accounts/${this.accountId}/${task.key}`;
      else path = `accounts/${this.accountId}/settings/${task.key}`;

      this.$emit('closeModal');

      setTimeout(() => {
        this.$router.push({ path: frontendURL(path) });
      }, 500);
    },
    taskStatus(active) {
      if (active === undefined) {
        return this.$t('SIDEBAR_ITEMS.PROGRESS_TRACKER_MODAL.STATUS.VISIT');
      }
      if (active) {
        return this.$t('SIDEBAR_ITEMS.PROGRESS_TRACKER_MODAL.STATUS.DONE');
      }
      return this.$t('SIDEBAR_ITEMS.PROGRESS_TRACKER_MODAL.STATUS.INCOMPLETE');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.progress-tracker-modal {
  ::v-deep .modal-container {
    width: 90rem;
  }

  .task-container {
    background-color: $chat-background;
    border: 1px solid $neutral-grey-400;
    border-radius: $border-radius-smaller;
    cursor: pointer;
    height: $space-jumbo;
    min-width: $space-large * 10;
    padding: $space-two $space-medium;
    width: $space-four * 10;
    transition: background-color 0.3s ease-out;

    &:hover {
      background-color: $neutral-grey-300;
    }

    &.active {
      background-color: $pg-light-1;
      border: 1px solid $pg-1-400;
      cursor: default;

      &:hover {
        background-color: $pg-light-1;
      }
    }

    &.not-active {
      background-color: $warn-red-100;
      cursor: pointer;

      &:hover {
        background: $warn-red-200;
      }
    }

    .task-status {
      background: $chat-background;
      border: 1px solid $neutral-grey-400;
      border-radius: $border-radius-smaller;
      padding: $space-smaller $space-small;
    }
  }
}

.modal-header {
  background: $neutral-grey-500;
  height: $space-two * 10;

  .image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.modal-content {
  padding: $space-large $space-four - $space-smaller;

  .reference-box {
    border-left: 4px solid $pg-1;
    max-width: 100%;

    ::v-deep .text-content {
      color: $text-dark;
    }
  }
}

.content-margin {
  margin-bottom: $space-large;
}
</style>
