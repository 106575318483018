<template>
  <div class="collaborators">
    <div
      v-for="[category, membersInCategory] in Object.entries(groupedMembers)"
      :key="category"
      class="collaborators-table"
    >
      <div class="flex-row flex-align flex-justify--between mg-right--small">
        <div class="flex-row flex-align gap--smaller">
          <span
            class="title-h5 text-dark capitalize"
            v-text="tableTitle(category)"
          />
        </div>
        <metric-capsule v-if="membersInCategory.length">
          {{ membersInCategory.length }}
        </metric-capsule>
      </div>
      <data-table
        :columns="tableColumns"
        :table-data="membersInCategory"
        :loading="isLoading"
        :enable-animations="false"
      >
        <template v-slot:loading-state>
          <skeleton-loader />
        </template>
      </data-table>
    </div>
  </div>
</template>
<script>
import DataTable from 'dashboard/components/widgets/table/DataTable';

export default {
  components: {
    DataTable,
    SkeletonLoader: {
      render(h) {
        return h('div', {
          attrs: { class: 'skeleton-animation skeleton-table-row' },
        });
      },
    },
    metricCapsule: {
      props: {
        customClass: {
          type: String,
          default: '',
        },
      },
      render(h) {
        return h(
          'span',
          {
            attrs: {
              class: `flex-row flex-align flex-justify metric-capsule ${this.customClass}`,
            },
          },
          this.$slots.default
        );
      },
    },
  },
  props: {
    action: {
      type: String,
      default: 'add',
    },
    categoriseBy: {
      type: String,
      default: 'role',
    },
    search: {
      type: String,
      default: '',
    },
    customTableTitle: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    members: {
      type: Array,
      default: () => [],
    },
    inbox: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableColumns: [
        {
          prop: 'avatar',
          component: 'AgentAvatar',
          width: '10%',
        },
        {
          prop: 'name',
          component: 'AgentNameWithStatus',
        },
        {
          prop: 'availability_status',
          component: 'InboxMemberAction',
        },
      ],
    };
  },
  computed: {
    groupedMembers() {
      let groupedMembers = this.arrangeMembers(this.members);
      let updatedGroupedMembers = {};

      Object.entries(groupedMembers).forEach(([key, value]) => {
        value.forEach((val, index) => {
          if (
            (this.search.trim() &&
              val.name.toLowerCase().search(this.search.toLowerCase()) > -1) ||
            val.availability_status
              .toLowerCase()
              .search(this.search.toLowerCase()) > -1
          ) {
            const memberData = {
              ...val,
              index,
              current_channel: this.inbox.channel_type,
              selected_inbox_id: this.inbox.id,
              action: this.action,
              showRole: this.categoriseBy === 'inbox_type',
            };

            let keyName = key;

            if (updatedGroupedMembers[keyName])
              updatedGroupedMembers[keyName].push(memberData);
            else updatedGroupedMembers[keyName] = [memberData];
          }
        });
      });

      // zero state when search result not found
      if (this.search.trim() && !Object.keys(updatedGroupedMembers).length)
        updatedGroupedMembers = { '': [] };

      return (
        (Object.keys(updatedGroupedMembers).length && updatedGroupedMembers) ||
        groupedMembers
      );
    },
  },
  methods: {
    tableTitle(category) {
      if (this.customTableTitle) return this.customTableTitle;

      if (!category) return null;

      let status = '';
      let appendText = '';
      if (this.action === 'remove') status = 'added';
      if (this.action === 'add') status = 'available';
      return category + 's ' + status + appendText;
    },
    arrangeMembers(inboxMembers) {
      let groupedMembers = {};

      if (this.categoriseBy === 'inbox_type') groupedMembers = { agent: [] };
      else
        groupedMembers = {
          agent: [],
          administrator: [],
          supervisor: [],
        };

      Object.entries(
        this.categorisedOptions(inboxMembers, this.categoriseBy)
      ).forEach(([key, value]) => {
        groupedMembers[key] = value;
      });

      return groupedMembers;
    },
    categorisedOptions(data, prop) {
      let optionsObj = data.reduce((options, option) => {
        options[option[prop]] = options[option[prop]] || [];
        options[option[prop]].push(option);
        return options;
      }, Object.create(null));

      return optionsObj;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.collaborators {
  background: $neutral-grey-200;
  padding: $space-small;

  .collaborators-table {
    margin-bottom: $space-small;

    ::v-deep .base-table-container .woot-table tr td {
      padding: $space-slab;
    }
  }

  .float-right {
    float: right;
  }
}

.skeleton-table-row {
  border-radius: $zero;
  height: $space-slab * 6;
  margin-top: $space-small;
}
</style>
