/* eslint no-param-reassign: 0 */

import getUuid from 'widget/helpers/uuid';
import { MESSAGE_STATUS, MESSAGE_TYPE } from 'shared/constants/messages';

export default () => {
  if (!Array.prototype.last) {
    Object.assign(Array.prototype, {
      last() {
        return this[this.length - 1];
      },
    });
  }
};

export const currentEnv = () => {
  const currentUrl = window.location.href;
  if (currentUrl.includes('stgapp')) {
    return 'staging';
  }

  return process.env.NODE_ENV;
};

export const isObjectEqual = (obj1, obj2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export const getChangedValues = (prev, updated) => {
  // Recursive helper function to add valid keys to the new object
  function addKeys(newObj, obj1, obj2) {
    Object.keys(obj2).forEach(key => {
      if (
        obj2[key] &&
        typeof obj2[key] === 'object' &&
        typeof obj1[key] === 'object' &&
        obj1[key] !== null
      ) {
        newObj[key] = {};
        addKeys(newObj[key], obj1[key], obj2[key]);
      } else if (obj2[key] !== null && obj1[key]) {
        newObj[key] = obj1[key];
      }
    });
  }

  const newObj = { id: prev.id };

  addKeys(newObj, prev, updated);
  return newObj;
};

export const updateNestedObject = (obj, path, value) => {
  // Split the path into an array using dot notation for deep access
  const keys = path.split('.');

  // Traverse all keys except the last one
  let current = obj;
  for (let i = 0; i < keys.length - 1; i += 1) {
    const key = keys[i];

    if (!current[key]) {
      current[key] = {};
    }

    // Move to the next nested object
    current = current[key];
  }

  // Set the value at the final key
  current[keys[keys.length - 1]] = value;

  return obj;
};

export const getTypingUsersText = (users = []) => {
  const count = users.length;
  if (count === 1) {
    const [user] = users;
    return `${user.name} is typing`;
  }

  if (count === 2) {
    const [first, second] = users;
    return `${first.name} and ${second.name} are typing`;
  }

  const [user] = users;
  const rest = users.length - 1;
  return `${user.name} and ${rest} others are typing`;
};

export const createPendingMessage = data => {
  const timestamp = Math.floor(new Date().getTime() / 1000);
  const tempMessageId = getUuid();
  const { message, files, isTemplate } = data;

  const pendingMessage = {
    ...data,
    content: message || null,
    id: tempMessageId,
    echo_id: tempMessageId,
    status: MESSAGE_STATUS.PROGRESS,
    created_at: timestamp,
    message_type: isTemplate ? MESSAGE_TYPE.TEMPLATE : MESSAGE_TYPE.OUTGOING,
    conversation_id: data.conversationId,
    attachments: files || [],
  };

  return pendingMessage;
};
