import store from './store';
import Vue from 'vue';

function initializeFreshworksWidget() {
  /* eslint-disable */
  !(function() {
    if (typeof window.FreshworksWidget !== 'function') {
      var n = function() {
        n.q.push(arguments);
      };
      (n.q = []), (window.FreshworksWidget = n);
    }
  })();
  FreshworksWidget('hide', 'launcher');
}

function loadFreshdeskWidget() {
  store.dispatch('crm/getFreshdeskWidgetId').then(data => {
    let widgetId = data?.widget_id;
    let widgetSourceUrl = data?.widget_source_url;
    let alternativeWidgetSourceUrl = 'https://ind-widget.freshworks.com/widgets/';

    if (widgetId) {
      window.fwSettings = {
        widget_id: widgetId,
      };

      let widgetScript = document.createElement('script');
      widgetScript.setAttribute('src', `${widgetSourceUrl}${widgetId}.js`);
      widgetScript.async = true;
          widgetScript.defer = true;

      widgetScript.onload = () => {
        initializeFreshworksWidget();
      };

      widgetScript.onerror = () => {
        let alternativeWidgetScript = document.createElement('script');
        alternativeWidgetScript.setAttribute('src', `${alternativeWidgetSourceUrl}${widgetId}.js`);
        alternativeWidgetScript.async = true;
        alternativeWidgetScript.defer = true;
        alternativeWidgetScript.onload = () => {
          initializeFreshworksWidget();
        };
        document.head.appendChild(alternativeWidgetScript);
      };

      Vue.nextTick(() => {
        document.head.appendChild(widgetScript);
      });
    }
  })
}

function initializeSupportWidget() {
  const BASE_URL = 'https://app.limechat.ai';
  const script = document.createElement('script');
  script.src =
    'https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/packs/js/LC_sdk/v1/sdk.js';
  script.defer = true;
  script.onload = () => {
    window.chatwootSDK.run({
      websiteToken: '6znhr9nFaScdDFVk3aN5wvFt',
      baseUrl: BASE_URL,
    });
  };
  document.body.appendChild(script);
}

async function initializeDelightedSurvey(accountId, userEmail, userName) {
  !(function(e, t, r, n) {
    if (!e[n]) {
      for (
        var a = (e[n] = []),
          i = [
            'survey',
            'reset',
            'config',
            'init',
            'set',
            'get',
            'event',
            'identify',
            'track',
            'page',
            'screen',
            'group',
            'alias',
          ],
          s = 0;
        s < i.length;
        s++
      ) {
        var c = i[s];
        a[c] =
          a[c] ||
          (function(e) {
            return function() {
              var t = Array.prototype.slice.call(arguments);
              a.push([e, t]);
            };
          })(c);
      }
      a.SNIPPET_VERSION = '1.0.1';
      var o = t.createElement('script');
      (o.type = 'text/javascript'),
        (o.async = true),
        (o.src =
          'https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/' +
          r +
          '/' +
          n +
          '.js');
      var u = t.getElementsByTagName('script')[0];
      u.parentNode.insertBefore(o, u);
    }
  })(window, document, '8TdksucYbTtZJ0jD', 'delighted');

  if (window.delighted && userEmail && userName) {
    window.delighted.survey({
      email: userEmail,
      name: userName,
      properties: {
        account_id: accountId,
      },
    });
  }
}

export {
  initializeFreshworksWidget,
  loadFreshdeskWidget,
  initializeSupportWidget,
  initializeDelightedSurvey,
};
