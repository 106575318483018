import { CUSTOM_FIELD_TYPES, CUSTOM_FIELD_TEMPLATES } from './constants';

export function isDropdown(fieldType) {
  return (
    fieldType === CUSTOM_FIELD_TYPES.SINGLE_SELECT ||
    fieldType === CUSTOM_FIELD_TYPES.MULTI_SELECT
  );
}

export function cleanEmptyStrings(array) {
  // Array should be of the format [{ value: string }, ...]
  return array.filter(({ value: string }) => string !== '');
}

export function reOrder(options) {
  let allIds = [...options.map(option => option.id)];
  // Sort the ids in ascending order
  allIds.sort((a, b) => {
    if (a === null) return 1;
    if (b === null) return -1;
    return a - b;
  });

  return options.map((option, index) => {
    return {
      id: allIds[index],
      value: option.value,
    };
  });
}

export const getPayload = initialPayload => {
  const { options, ...payload } = initialPayload;

  const DEFAULT_PAYLOAD = {
    name: null,
    description: null,
    field_type: 'short_text',
    inbox_ids: [],
    mandatory: false,
    enabled: true,
    ...payload,
  };

  if (isDropdown(payload.field_type)) {
    return {
      ...DEFAULT_PAYLOAD,
      options: options?.length ? options : [{ id: null, value: '' }],
    };
  }
  return {
    ...DEFAULT_PAYLOAD,
  };
};

export function getCustomFieldNameByType(fieldType) {
  return CUSTOM_FIELD_TEMPLATES.find(template => template.id === fieldType)
    ?.name;
}
