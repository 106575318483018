import SettingsContent from '../Wrapper';
import TemplateHome from './Index';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/template'),
      component: SettingsContent,
      props: {
        headerTitle: 'TEMPLATE.HEADER',
        icon: 'template',
      },
      children: [
        {
          path: '',
          name: 'template_wrapper',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_template',
          roles: ['administrator', 'agent', 'supervisor'],
          component: TemplateHome,
        },
      ],
    },
  ],
};
