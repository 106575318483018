<template>
  <CreateFromTemplate
    :can-create-new="false"
    :templates="CUSTOM_FIELD_TEMPLATES"
    :modal-title="$t('CUSTOM_TICKET_FIELDS.CREATE.CREATE_MODAL.TITLE')"
    :loading="uiFlags.isFetchingTemplates"
    opened
    :handle-create="data => $emit('handleCreate', data)"
    @onClose="$emit('onClose')"
  />
</template>
<script>
import { mapGetters } from 'vuex';

import CreateFromTemplate from 'components/ui/modals/CreateFromTemplate';
import { CUSTOM_FIELD_TEMPLATES } from '../utils/constants';

export default {
  components: { CreateFromTemplate },
  data() {
    return {
      CUSTOM_FIELD_TEMPLATES,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'customTicketFields/getUIFlags',
    }),
  },
};
</script>
