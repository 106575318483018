var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small-12"},[_c('div',{staticClass:"flex-space-between"},[_c('search-box',{attrs:{"placeholder":_vm.$t('BROADCAST.BROADCAST_TAB.SEARCH_TXT'),"value":_vm.search,"variant":true,"custom-class":"custom-search","icon-size":"mediumlarge"},on:{"setSearch":_vm.setSearch}}),_vm._v(" "),_c('div',{staticClass:"filter-button",class:{ 'filter-button-active': _vm.isImgFilterActive },on:{"click":_vm.toggleImgFilter}},[_vm._v("\n      "+_vm._s(_vm.$t('BROADCAST.BROADCAST_TAB.IMG_FILTER'))+"\n      "),_c('span',[_c('icons',{attrs:{"name":"filterBtn","color":_vm.isImgFilterActive ? 'white' : 'green',"size":"semimedium","view":"0 0 20 20"}})],1)])],1),_vm._v(" "),_c('div',{staticClass:"custom-scroll",style:(_vm.selectedTemplateId !== null && _vm.toggleTemplate
        ? { 'max-height': '24rem' }
        : { 'max-height': '35rem' })},[(_vm.templateList.length > 0)?_c('table',{staticClass:"woot-table"},[_c('thead',_vm._l((_vm.$t(
            'BROADCAST.BROADCAST_TAB.SELECT_TEMPLATE.TABLE_HEADER'
          )),function(thHeader){return _c('th',{key:thHeader},[_vm._v("\n          "+_vm._s(thHeader)+"\n        ")])}),0),_vm._v(" "),(!_vm.uiFlags.fetchingTemplateList && _vm.templateList.length)?_c('tbody',_vm._l((_vm.templateList),function(item,index){return _c('tr',{key:index,class:{
            'selected-template':
              _vm.selectedTemplateId === item.id && _vm.toggleTemplate,
          },on:{"click":function($event){return _vm.selectTemplate(item.id)}}},[_c('td',{staticClass:"flex-row-center",staticStyle:{"height":"40px"}},[_c('span',{staticClass:"mg-left--small mg-right--small check-icon"},[_c('icons',{attrs:{"name":"checked","color":_vm.selectedTemplateId === item.id && _vm.toggleTemplate
                    ? 'green'
                    : 'lightestgrey',"size":"medium","view":"0 0 24 24"}})],1),_vm._v(" "),_c('span',[_c('span',{staticClass:"title-h5_m text-dark mg-right--small"},[_vm._v("\n                "+_vm._s(item.display_id)+"\n              ")]),_vm._v(" "),_c('span',{staticClass:"body-b2 text-light",domProps:{"innerHTML":_vm._s(_vm.prepareContent(item.short_code))}})])]),_vm._v(" "),_c('td',{staticStyle:{"width":"50%"}},[_c('div',{staticClass:"flex-row-center"},[(
                  item.template_type == 'image' && item.media_url !== '{}'
                )?_c('div',{staticClass:"mg-right--small template-img"},[_c('img',{attrs:{"src":item.media_url,"height":"24px","width":"24px"}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-truncate body-b2 text-light",staticStyle:{"max-width":"90%"},domProps:{"innerHTML":_vm._s(_vm.highlightedBrackets(_vm.prepareContent(item.body), false))}})])]),_vm._v(" "),_c('td',[_c('span',{staticClass:"body-b2 text-dark"},[_vm._v("\n              Inbox - "+_vm._s(item.inbox_id)+"\n            ")])])])}),0):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.uiFlags.fetchingTemplateList && !_vm.templateList.length)?_c('div',{staticClass:"body-b2 text-light flex-row-center flex-justify"},[_vm._v("\n      No Templates Found\n    ")]):_vm._e(),_vm._v(" "),(_vm.uiFlags.fetchingTemplateList)?_c('woot-loading-state',{attrs:{"message":_vm.$t('TEMPLATE.LOADING')}}):_vm._e()],1),_vm._v(" "),_c('hr'),_vm._v(" "),(_vm.selectedTemplateId !== null && _vm.toggleTemplate)?_c('div',{staticClass:"selected-template-box custom-scroll"},[_c('div',{staticClass:"mg-top mg-bottom  button-small text-light"},[_vm._v("\n      Selected Template\n    ")]),_vm._v(" "),_c('div',{staticClass:"body-b2 text-dark mg-left--micro",domProps:{"innerHTML":_vm._s(_vm.highlightedBrackets(_vm.selectedTemplate.body, true))}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-row flex-justify--end mg-top--large"},[_c('woot-primary-button',{attrs:{"name":"next","size":"medium","custom-style":{ width: '12rem' },"disabled":_vm.selectedTemplateId === null || !_vm.toggleTemplate},on:{"click":_vm.goToNext}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }