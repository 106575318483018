<template>
  <iframe width="250" :src="url"> </iframe>
</template>

<script>
export default {
  name: 'YoutubeView',
  props: {
    url: { type: String, default: '' },
  },
};
</script>
<style lang="scss" scoped>
iframe {
  border: none;
}
</style>
