export const BUS_EVENTS = {
  SET_REFERRER_HOST: 'SET_REFERRER_HOST',
  SET_CONVERSATION_LABELS: 'SET_CONVERSATION_LABELS',
  SET_TWEET_REPLY: 'SET_TWEET_REPLY',
  SCROLL_TO_MESSAGE: 'scrollToMessage',
  SLA_BREACHED: 'sla_breached',

  // websocket
  WEBSOCKET_DISCONNECT: 'WEBSOCKET_DISCONNECT',
  WEBSOCKET_RECONNECT_COMPLETED: 'WEBSOCKET_RECONNECT_COMPLETED',
  WEBSOCKET_RECONNECT: 'WEBSOCKET_RECONNECT',
};
