<template>
  <div v-html="safeMarkdown" />
</template>

<script>
import marked from 'marked';
import DOMPurify from 'dompurify';

export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  computed: {
    safeMarkdown() {
      const dirtyHtml = marked(this.content);
      return DOMPurify.sanitize(dirtyHtml);
    },
  },
};
</script>
