export default {
  /* ==== Chat List ==== */
  // action
  LOAD_MORE_CONVERSATIONS: 'LOAD_MORE_CONVERSATIONS',
  CONVERSATION_TAB_SWITCH: 'CONVERSATION_TAB_SWITCH',

  // category
  DASHBOARD_MID_PANEL: 'DASHBOARD_MID_PANEL',
  /* ==== Chat List ==== */

  /* ==== Availabity Status ==== */
  // action
  AGENT_AVAILABILITY: 'AGENT_AVAILABILITY',

  // category
  AVAILABILITY_CHANGE: 'AVAILABILITY_CHANGE',
  /* ==== Availabity Status ==== */

  /* ==== Canned Response ==== */
  // action
  CANNED_RESPONSE_SENT: 'CANNED_RESPONSE_SENT',

  // category
  CONVERSATION_PANEL: 'CONVERSATION_PANEL',
  /* ==== Canned Response ==== */

  /* ==== Conversation Card ==== */
  // action
  CONVERSATION_OPENED: 'CONVERSATION_OPENED',

  // category
  CHAT_LIST_OR_PREVIOUS_OR_CONTACT_INFO:
    'CHAT_LIST_OR_PREVIOUS_OR_CONTACT_INFO',
  /* ==== Conversation Card ==== */

  /* ==== Conversation Header ==== */
  // action
  AGENT_ASSIGN_CONV: 'AGENT_ASSIGN_CONV',
  AGENT_CHANGE_CONV_STATUS: 'AGENT_CHANGE_CONV_STATUS',
  STAR_CONVERSATION: 'STAR_CONVERSATION',
  QUICK_RESOLVE_CONV_STATUS: 'QUICK_RESOLVE_CONV_STATUS',

  // action
  TEAM_ASSIGN_CONV: 'TEAM_ASSIGN_CONV',

  // category
  CONVERSATION_HEADER: 'CONVERSATION_HEADER',
  /* ==== Conversation Header ==== */

  /* ==== Conversation Status ==== */
  // action
  CHANGE_STATUS_FILTER: 'CHANGE_STATUS_FILTER',
  /* ==== Conversation Status ==== */

  /* ==== More Actions ==== */
  // action
  MORE_ACTIONS_MUTE: 'MORE_ACTIONS_MUTE',
  MORE_ACTIONS_UNMUTE: 'MORE_ACTIONS_UNMUTE',
  MORE_ACTIONS_SEND_TRANSCRIPT: 'MORE_ACTIONS_SEND_TRANSCRIPT',
  /* ==== More Actions ==== */

  /* ==== Status Filter ==== */
  // action
  CONVERSATION_FILTER_SET: 'CONVERSATION_FILTER_SET',
  /* ==== Status Filter ==== */

  /* ==== Tags Filter ==== */
  // action
  TAG_FILTER_SET: 'TAG_FILTER_SET',
  /* ==== Tags Filter ==== */

  /* ==== Agents Filter ==== */
  // action
  AGENT_FILTER_SET: 'AGENT_FILTER_SET',
  /* ==== Agents Filter ==== */

  /* ==== Agent Analytics ==== */
  // action
  CHANGE_REPORT_TYPE: 'CHANGE_REPORT_TYPE',
  APPLY_BUTTON: 'APPLY_BUTTON',
  DOWNLOAD_REPORT_PERFORMANCE_ANALYTICS:
    'DOWNLOAD_REPORT_PERFORMANCE_ANALYTICS',
  INFORMATION_TOOLTIP: 'INFORMATION_TOOLTIP',

  // category
  AGENT_ANALYTICS: 'AGENT_ANALYTICS',
  /* ==== Agent Analytics ==== */

  /* ==== Agent Attendance ==== */
  // action
  AGENT_ATTENDANCE_FILTER_DATE_RANGE: 'AGENT_ATTENDANCE_FILTER_DATE_RANGE',
  DOWNLOAD_AGENT_ATTENDANCE: 'DOWNLOAD_AGENT_ATTENDANCE',
  /* ==== Agent Attendance ==== */

  /* ==== CSAT Responses ==== */
  // action
  CSAT_ANALYTICS_APPLY_OR_MOUNT: 'AGENT_ATTENDANCE_FILTER_DATE_RANGE',
  CSAT_ON_TIMING_CHANGE: 'CSAT_ON_TIMING_CHANGE',
  CSAT_ON_CONVERSATION_TYPE_CHANGE: 'CSAT_ON_CONVERSATION_TYPE_CHANGE',
  CSAT_ANALYTICS_ONDATERANGECHANGE: 'CSAT_ANALYTICS_ONDATERANGECHANGE',
  CSAT_DOWNLOAD: 'CSAT_DOWNLOAD',

  // category
  CSAT_ANALYTICS: 'CSAT_ANALYTICS',
  /* ==== CSAT Responses ==== */

  /* ==== Sales Analytics ==== */
  // category
  SALES_ANALYTICS: 'SALES_ANALYTICS',
  /* ==== Sales Analytics ==== */

  /* ==== AttendaceSummaryTable ==== */
  // action
  AGENT_ATTENDANCE_SORT: 'AGENT_ATTENDANCE_SORT',
  /* ==== AttendaceSummaryTable ==== */

  /* ==== Broadcast ==== */
  // action
  BROADCAST_CHANGE_INBOX: 'BROADCAST_CHANGE_INBOX',
  BROADCAST_SELECT_TEMPLATE: 'BROADCAST_SELECT_TEMPLATE',
  BROADCAST_SWITCH_MESSAGE_TYPE: 'BROADCAST_SWITCH_MESSAGE_TYPE',
  BROADCAST_TOGGLE_STATUS: 'BROADCAST_TOGGLE_STATUS',
  SHOW_BROADCAST_UPLOAD_MODAL: 'SHOW_BROADCAST_UPLOAD_MODAL',
  HIDE_BROADCAST_UPLOAD_MODAL: 'HIDE_BROADCAST_UPLOAD_MODAL',
  SEND_BROADCAST_INITIATED: 'SEND_BROADCAST_INITIATED',
  BROADCAST_SENT: 'BROADCAST_SENT',

  // category
  BROADCAST: 'BROADCAST',
  /* ==== Broadcast ==== */

  /* ==== Broadcast Reports ==== */
  // action
  BROADCAST_DOWNLOAD_BROADCAST: 'BROADCAST_DOWNLOAD_BROADCAST',

  // category
  BROADCAST_REPORTS: 'BROADCAST_REPORTS',
  /* ==== Broadcast Reports ==== */

  /* ==== Contacts View ==== */
  // action
  CONTACTS_SEARCH: 'CONTACTS_SEARCH',

  // category
  CONTACTS_EVENT: 'CONTACTS_EVENT',
  /* ==== Contacts View ==== */

  /* ==== Contacts Conversations ==== */
  // action
  CONTACT_CONVERSATION_OPENED: 'CONTACT_CONVERSATION_OPENED',

  // category
  CONTACT_INFO_OR_PREVIOUS_CONVO: 'CONTACT_INFO_OR_PREVIOUS_CONVO',
  /* ==== Contacts Conversations ==== */

  /* ==== Conversation View ==== */
  // action
  CONVERSATION_SIDEBAR_TOGGLE: 'CONVERSATION_SIDEBAR_TOGGLE',
  /* ==== Conversation View ==== */

  /* ==== Edit Contact ==== */
  // action
  CONTACT_EDITED: 'CONTACT_EDITED',
  /* ==== Edit Contact ==== */

  /* ==== Raise Ticket ==== */
  // action
  RAISE_TICKET: 'RAISE_TICKET',
  RAISE_TICKET_CRM_TYPE: 'RAISE_TICKET_CRM_TYPE',

  // category
  RIGHT_SIDE_PANEL_EVENT: 'RIGHT_SIDE_PANEL_EVENT',
  RAISE_TICKET_FRESHWORKS: 'RAISE_TICKET_FRESHWORKS',
  /* ==== Raise Ticket ==== */

  /* ==== ==== Label Box ==== ==== */
  // action
  ADD_LABEL: 'ADD_LABEL',
  REMOVE_LABEL: 'REMOVE_LABEL',

  // category
  CONVERSATION_SIDEBAR: 'CONVERSATION_SIDEBAR',
  /* ==== ==== Label Box ==== ==== */

  /* ==== Order Panel ==== */
  // action
  ORDERS_SEARCH: 'ORDERS_SEARCH',

  // category
  ORDERS_PANEL: 'ORDERS_PANEL',
  /* ==== Order Panel ==== */

  /* ==== Products Panel ==== */
  // action
  PRODUCTS_SEARCH: 'PRODUCTS_SEARCH',

  // category
  PRODUCTS_PANEL: 'PRODUCTS_PANEL',
  /* ==== Products Panel ==== */

  /* ==== Search (message) ==== */
  // action
  MESSAGE_SEARCH: 'MESSAGE_SEARCH',
  /* ==== Search (message) ==== */

  /* ==== Account Settings ==== */
  // action
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',

  // category
  ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS',
  /* ==== Account Settings ==== */

  /* ==== Add Agent ==== */
  // action
  ADD_AGENT: 'ADD_AGENT',

  // category
  AGENT_SETTINGS: 'AGENT_SETTINGS',
  /* ==== Add Agent ==== */

  /* ==== Edit Agent ==== */
  // action
  AGENTS_EDIT_REMOVE_INBOX: 'AGENTS_EDIT_REMOVE_INBOX',
  EDIT_AGENT: 'EDIT_AGENT',
  CHANGE_AGENT_TYPE: 'CHANGE_AGENT_TYPE',
  AGENTS_SETTING_ADD_INBOX: 'AGENTS_SETTING_ADD_INBOX',
  AGENT_RESET_PASSWORD: 'AGENT_RESET_PASSWORD',
  /* ==== Edit Agent ==== */

  /* ==== Agent Settings ==== */
  // action
  AGENTS_OPEN_POP_UP: 'AGENTS_OPEN_POP_UP',
  AGENTS_HIDE_POP_UP: 'AGENTS_HIDE_POP_UP',
  AGENTS_OPEN_ADD_POP_UP: 'AGENTS_OPEN_ADD_POP_UP',
  AGENTS_HIDE_ADD_POP_UP: 'AGENTS_HIDE_ADD_POP_UP',
  AGENTS_OPEN_EDIT_POP_UP: 'AGENTS_OPEN_EDIT_POP_UP',
  AGENTS_HIDE_EDIT_POP_UP: 'AGENTS_HIDE_EDIT_POP_UP',
  DELETE_AGENT: 'DELETE_AGENT',
  /* ==== Agent Settings ==== */

  /* ==== Automation Settings ==== */
  // action
  AUTOMATIONS_OPEN_ADD_POP_UP: 'AUTOMATIONS_OPEN_ADD_POP_UP',
  AUTOMATION_SETTINGS: 'AUTOMATION_SETTINGS',
  AUTOMATIONS_SETTINGS_SEARCH: 'AUTOMATIONS_SETTINGS_SEARCH',
  AUTOMATIONS_HIDE_ADD_POP_UP: 'AUTOMATIONS_HIDE_ADD_POP_UP',
  /* ==== Agent Settings ==== */

  /* ==== Add Canned ==== */
  // action
  ADD_CANNED_RESPONSE: 'ADD_CANNED_RESPONSE',

  // category
  CANNED_RESPONSE_SETTINGS: 'CANNED_RESPONSE_SETTINGS',
  /* ==== Add Canned ==== */

  /* ==== Canned Response Settings ==== */
  // action
  SEARCH_CANNED_RESPONSE: 'SEARCH_CANNED_RESPONSE',
  OPEN_ADD_POP_UP_CANNED_RESPONSE: 'OPEN_ADD_POP_UP_CANNED_RESPONSE',
  HIDE_ADD_POP_UP_CANNED_RESPONSE: 'HIDE_ADD_POP_UP_CANNED_RESPONSE',
  OPEN_EDIT_POP_UP_CANNED_RESPONSE: 'OPEN_EDIT_POP_UP_CANNED_RESPONSE',
  HIDE_EDIT_POP_UP_CANNED_RESPONSE: 'HIDE_EDIT_POP_UP_CANNED_RESPONSE',
  OPEN_DELETE_POP_UP_CANNED_RESPONSE: 'OPEN_DELETE_POP_UP_CANNED_RESPONSE',
  HIDE_DELETE_POP_UP_CANNED_RESPONSE: 'HIDE_DELETE_POP_UP_CANNED_RESPONSE',
  DELETE_CANNED_RESPONSE: 'DELETE_CANNED_RESPONSE',
  DOWNLOAD_CANNED_RESPONSE: 'DOWNLOAD_CANNED_RESPONSE',
  IMPORT_CANNED_RESPONSE: 'IMPORT_CANNED_RESPONSE',
  /* ==== Canned Response Settings ==== */

  /* ==== Inbox Settings (index) ==== */
  // action
  INBOX_SEARCH: 'INBOX_SEARCH',
  INBOX_OPEN_SETTINGS: 'INBOX_OPEN_SETTINGS',
  INBOX_CLOSE_SETTINGS: 'INBOX_CLOSE_SETTINGS',
  INBOX_DELETE: 'INBOX_DELETE',
  OPEN_DELETE_INBOX: 'OPEN_DELETE_INBOX',
  CLOSE_DELETE_INBOX: 'CLOSE_DELETE_INBOX',

  // category
  INBOX_SETTINGS: 'INBOX_SETTINGS',
  /* ==== Inbox Settings (index) ==== */

  /* ==== Settings Inbox  ==== */
  // action
  INBOX_ON_AGENT_SELECT: 'INBOX_ON_AGENT_SELECT',
  INBOX_ON_REMOVE_AGENT: 'INBOX_ON_REMOVE_AGENT',
  INBOX_ON_COMMENT_SELECT: 'INBOX_ON_COMMENT_SELECT',
  INBOX_ON_REMOVE_COMMENT: 'INBOX_ON_REMOVE_COMMENT',
  INBOX_CHANGE_REPLY_TIME: 'INBOX_CHANGE_REPLY_TIME',
  INBOX_CHANGE_ENABLE_EMAIL: 'INBOX_CHANGE_ENABLE_EMAIL',
  INBOX_CHANGE_AGENT_OFFLINE_STATUS: 'INBOX_CHANGE_AGENT_OFFLINE_STATUS',
  INBOX_CHANGE_WIDGET_ENABLE: 'INBOX_CHANGE_WIDGET_ENABLE',
  INBOX_CHANGE_GREETING: 'INBOX_CHANGE_GREETING',
  INBOX_CHANGE_WAIT_TIME: 'INBOX_CHANGE_WAIT_TIME',
  INBOX_CHANGE_AUTO_ASSIGNMENT: 'INBOX_CHANGE_AUTO_ASSIGNMENT',
  INBOX_REBOOT_CHAT_API: 'INBOX_REBOOT_CHAT_API',
  INBOX_UPDATE_AGENTS: 'INBOX_UPDATE_AGENTS',

  // category
  SETTINGS_INBOX: 'SETTINGS_INBOX',
  /* ==== Settings Inbox ==== */

  /* ==== Weekly Availability   ==== */
  // action
  UPDATE_INBOX_FROM_WEEKLY_AVAIL: 'UPDATE_INBOX_FROM_WEEKLY_AVAIL',
  /* ==== Weekly Availability   ==== */

  /* ==== Pre Chat Settings   ==== */
  // action
  UPDATE_PRE_CHAT_FORM: 'UPDATE_PRE_CHAT_FORM',
  /* ==== Pre Chat Settings   ==== */

  /* ==== Add Label   ==== */
  // action
  ADD_LABEL_FROM_SETTINGS: 'ADD_LABEL_FROM_SETTINGS',

  // category
  LABEL_SETTINGS: 'LABEL_SETTINGS',
  /* ==== Add Label   ==== */

  /* ==== Label Settings (index)  ==== */
  // action
  LABEL_OPEN_ADD_POPUP: 'LABEL_OPEN_ADD_POPUP',
  LABEL_HIDE_ADD_POPUP: 'LABEL_HIDE_ADD_POPUP',
  LABEL_OPEN_EDIT_POPUP: 'LABEL_OPEN_EDIT_POPUP',
  LABEL_HIDE_EDIT_POPUP: 'LABEL_HIDE_EDIT_POPUP',
  LABEL_OPEN_DELETE_POPUP: 'LABEL_OPEN_DELETE_POPUP',
  LABEL_CLOSE_DELETE_POPUP: 'LABEL_CLOSE_DELETE_POPUP',
  LABEL_DELETE: 'LABEL_DELETE',
  LABEL_DOWNLOAD: 'LABEL_DOWNLOAD',
  LABEL_IMPORT: 'LABEL_IMPORT',
  /* ==== Label Settings (index)  ==== */

  /* ==== Products settings (index)  ==== */
  // action
  PRODUCT_SEARCH: 'PRODUCT_SEARCH',

  // category
  PRODUCT_SETTINGS: 'PRODUCT_SETTINGS',
  /* ==== Products settings (index)  ==== */

  /* ==== Store notification settings (notification list) ==== */
  // action
  STORE_NOTIFICATION_SEARCH: 'STORE_NOTIFICATION_SEARCH',

  // category
  STORE_NOTIFICATION_SETTINGS: 'STORE_NOTIFICATION_SETTINGS',
  /* ==== Store notification settings  (notification list)   ==== */

  /* ==== Add Template  ==== */
  // action
  TEMPLATE_CHANGE_INBOX: 'TEMPLATE_CHANGE_INBOX',
  TEMPLATE_CHANGE_TYPE: 'TEMPLATE_CHANGE_TYPE',
  TEMPLATE_ADD_AGENT: 'TEMPLATE_ADD_AGENT',

  // category
  TEMPLATE_ADD_SETTINGS: 'TEMPLATE_ADD_SETTINGS',
  /* ==== Add Template  ==== */

  /* ==== Template Settings (index)  ==== */
  // action
  TEMPLATE_SEARCH: 'TEMPLATE_SEARCH',
  TEMPLATE_OPEN_ADD_POPUP: 'TEMPLATE_OPEN_ADD_POPUP',
  TEMPLATE_HIDE_ADD_POPUP: 'TEMPLATE_HIDE_ADD_POPUP',
  TEMPLATE_OPEN_EDIT_POPUP: 'TEMPLATE_OPEN_EDIT_POPUP',
  TEMPLATE_HIDE_EDIT_POPUP: 'TEMPLATE_HIDE_EDIT_POPUP',
  TEMPLATE_OPEN_DELETE_POPUP: 'TEMPLATE_OPEN_DELETE_POPUP',
  TEMPLATE_CLOSE_DELETE_POPUP: 'TEMPLATE_CLOSE_DELETE_POPUP',
  TEMPLATE_DELETE: 'TEMPLATE_DELETE',
  TEMPLATE_DOWNLOAD: 'TEMPLATE_DOWNLOAD',
  TEMPLATE_IMPORT: 'TEMPLATE_IMPORT',

  // category
  TEMPLATE_SETTINGS: 'TEMPLATE_SETTINGS',
  /* ==== Template Settings (index)  ==== */

  /* ==== Onboard Modal  ==== */
  // category
  ONBOARD_MODAL: 'ONBOARD_MODAL',
  /* ==== Onboard Modal  ==== */

  /* ==== Navbar ==== */
  // action
  NAVBAR_APP_STORE: 'NAVBAR_APP_STORE',
  NAVBAR_GOOGLE_PLAY: 'NAVBAR_GOOGLE_PLAY',

  // category
  NAVBAR_SETTINGS: 'BOTTOM_NAV_OPTIONS',
  /* ==== Template Settings (index)  ==== */
};
