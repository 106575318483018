export default {
  methods: {
    processCsv(data, fileName) {
      var blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName);
      } else {
        let link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          let url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    addImagePreviewUrl(newFile, oldFile) {
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        if (/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          newFile.url = '';
          let URL = window.URL || window.webkitURL;
          if (URL && URL.createObjectURL) {
            newFile.url = URL.createObjectURL(newFile.file);
          }
        }
      }
    },
  },
};
