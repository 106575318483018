<template>
  <header class="header">
    <div class="table-actions-wrap">
      <div class="left-aligned-wrap">
        <h1 class="page-title">
          {{ $t('CONTACTS_PAGE.HEADER') }}
        </h1>
      </div>
      <div class="flex-row">
        <div class="download-controls flex-row flex-align gap--normal">
          <woot-base-button
            tag="span"
            front-icon="download"
            @click="handleDownloadButtonClick"
          >
            {{ $t('CONTACTS_PAGE.DOWNLOAD.TITLE') }}
          </woot-base-button>
          <tags-filter
            value="All Tags"
            default-key="title"
            resource-type="Contact"
            :show-option-tooltip="false"
            :toggle-filter="onChangeLabelIds"
          />
        </div>
        <div class="right-aligned-wrap flex-space-between">
          <div class="search-wrap">
            <search-box
              :placeholder="'Search by ' + defaultSelectedParam.name"
              :value="searchQuery"
              :variant="true"
              custom-class="contact-search"
              :on-search="onSearchSubmit"
              @setSearch="onInputSearch"
            >
              <woot-single-selector
                variant="tertiary"
                size="large"
                :default-option="defaultSelectedParam.name"
                :custom-button="true"
                :option-list="searchParams"
                :custom-style="{ left: 'calc(100% - 13.6rem)' }"
                :top-position="{ top: '3.3rem' }"
                @click="setSearchParam"
              >
                <div class="dropdown-button">
                  <span class="flex-row">
                    <icons name="chevronDown" size="medium" color="green" />
                  </span>
                </div>
              </woot-single-selector>
            </search-box>
          </div>
          <div v-if="isClosed" class="toggle-container">
            <button class="toggle-button" @click="onClose">
              <icons
                name="chevronFilled"
                size="medium"
                color="green"
                view="0 0 24 24"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <transition name="modal-fade">
      <download-contacts-modal
        v-if="showDownloadModal"
        :on-close="() => (showDownloadModal = false)"
      />
    </transition>
  </header>
</template>

<script>
import TagsFilter from 'dashboard/components/widgets/conversation/filter/TagsFilter';
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import DownloadContactsModal from './DownloadContactsModal';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: { SearchBox, DownloadContactsModal, TagsFilter },
  mixins: [mixPanelAnalyticsMixin],
  props: {
    searchQuery: {
      type: String,
      default: '',
    },
    searchParam: {
      type: String,
      default: 'phone_number',
    },
    onInputSearch: {
      type: Function,
      default: () => {},
    },
    onSearchSubmit: {
      type: Function,
      default: () => {},
    },
    setSearchParam: {
      type: Function,
      default: () => {},
    },
    onChangeLabelIds: {
      type: Function,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    isClosed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showDownloadModal: false,
    };
  },
  computed: {
    searchParams() {
      return [
        { name: 'Phone', value: 'phone_number' },
        { name: 'Email', value: 'email' },
        { name: 'Name', value: 'name' },
        { name: 'Instagram Username', value: 'instagram_username' },
      ];
    },
    defaultSelectedParam() {
      return this.searchParams.find(param => param.value === this.searchParam);
    },
  },
  methods: {
    handleDownloadButtonClick() {
      this.mix_panel_clicked_contacts_download_data();
      this.showDownloadModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.header {
  padding: 0 $space-medium;
}

.page-title {
  margin: 0;
}

.download-controls {
  border-right: 1px solid $neutral-grey-300;
  padding-right: $space-normal;
  margin-right: $space-normal;

  .action--button-new {
    height: 3.8rem !important;
    align-items: center;
  }
}

.table-actions-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: $space-slab $zero;
}

.search-wrap {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: $space-small;

  .contact-search {
    background: $neutral-grey-100;
    height: $space-four !important;
    margin-bottom: $zero;
    min-width: $space-normal * 10;
    width: 100%;
  }
}
</style>
