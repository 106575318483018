import { mapGetters } from 'vuex';
import { getOptions } from '../utils/form';

export default {
  computed: {
    ...mapGetters({
      agentRecords: 'agents/getAgents',
      inboxes: 'inboxes/getInboxes',
      labels: 'labels/getLabelsWithoutGroups',
      teams: 'teams/getTeams',
    }),
    agents() {
      return [
        { name: 'Current Assignee', id: 'Current_Assignee' },
        ...this.agentRecords,
      ];
    },
  },
  methods: {
    getDropdownValues(type) {
      const { agents, inboxes, teams, labels } = this;

      return getOptions({
        agents,
        inboxes,
        teams,
        type,
        labels,
      });
    },
  },
};
