<template>
  <div class="action-group">
    <span style="white-space: nowrap">
      Send {{ escalationLevel }} Level Escalation to
    </span>
    <multi-select-trigger-values
      class="multi-select-filter"
      :value="selectedValues"
      :dropdown-values="dropdownValues"
      @input="updateValues"
    />
    <span>in</span>
    <time-duration-selector :value="action.after" @click="updateTimeDuration" />
    <woot-base-button
      tag="span"
      variant="tertiary-danger"
      @click="deleteAction"
    >
      <icons name="bin" color="red" size="semimedium" :show-title="false" />
    </woot-base-button>
  </div>
</template>

<script>
import MultiSelectTriggerValues from './selectors/MultiSelector';
import TimeDurationSelector from './selectors/TimeDurationSelector';

export default {
  components: {
    MultiSelectTriggerValues,
    TimeDurationSelector,
  },
  props: {
    action: {
      type: Object,
      default: () => ({}),
    },
    metricKey: {
      type: String,
      default: '',
    },
    actionIndex: {
      type: Number,
      default: 0,
    },
    dropdownValues: {
      type: Array,
      default: () => [],
    },
    escalationLevel: {
      type: String,
      default: '',
    },
    selectedValues: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    updateValues(values) {
      this.$emit('update', {
        metricKey: this.metricKey,
        actionIndex: this.actionIndex,
        update: { data: values.map(value => value.id) },
      });
    },
    updateTimeDuration({ value: selectedTimeDuration }) {
      this.$emit('update', {
        metricKey: this.metricKey,
        actionIndex: this.actionIndex,
        update: { after: selectedTimeDuration },
      });
    },
    deleteAction() {
      this.$emit('delete', {
        metricKey: this.metricKey,
        actionIndex: this.actionIndex,
      });
    },
  },
};
</script>
