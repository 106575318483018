<template>
  <div class="form-section-header flex-row flex-align--start gap--normal">
    <div class="section-icon-wrapper">
      <icons :name="icon" size="semimedium" color="green" />
    </div>
    <div class="flex-column gap--smaller">
      <span class="title-h5 text-dark" v-text="heading" />
      <span class="body-b3 text-light" v-text="subHeading" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    heading: { type: String, default: '' },
    subHeading: { type: String, default: '' },
    icon: { type: String, default: '' },
  },
};
</script>
