/* global axios */

import ApiClient from '../ApiClient';

class UploadApi extends ApiClient {
  constructor() {
    super('integrations', { accountScoped: true });
  }

  uploadImage({ file }) {
    const formData = new FormData();
    formData.append('attachments[]', file, file.name);
    return axios({
      method: 'post',
      url: `${this.baseUrl()}/helpers/upload`,
      data: formData,
    });
  }
}

export default new UploadApi();
