<template>
  <woot-single-selector
    size="small"
    front-icon="agent"
    :custom-style="computedCustomStyle"
    :button-style="buttonStyle"
    :show-selected-text="false"
    :default-option="selectedAgentObject"
    :option-list="modifiedAgentList"
    :highlight-selected="highlightAgentName"
    @click="onSelectAgent"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';

export default {
  mixins: [googleAnalyticsMixin],
  props: {
    selectedAgentId: {
      type: [String, Number],
      default: '',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    toggleFilter: {
      type: Function,
      default: () => {},
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
    }),
    computedCustomStyle() {
      return {
        left: 0,
        top: 0,
        width: '18rem',
        ...this.customStyle,
      };
    },
    buttonStyle() {
      return {
        color: '#808975',
        padding: '0.4rem 0.6rem',
      };
    },
    selectedAgentObject() {
      return (
        this.modifiedAgentList.find(
          agent => agent.id === this.selectedAgentId
        ) || 'All Agents'
      );
    },
    modifiedAgentList() {
      return [{ id: null, name: 'All Agents' }, ...this.agentList];
    },
    highlightAgentName() {
      const agentName = this.selectedAgentObject.name;
      return !!agentName && agentName !== 'All Agents';
    },
  },
  methods: {
    onSelectAgent(val) {
      this.logAgentFilterChange(val.name);
      this.toggleFilter(this.item.key, true, val.id);
    },
  },
};
</script>
