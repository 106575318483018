<template>
  <top-banner>
    <div class="shake flex-row flex-align flex-justify full-width">
      <div class="subtitle-s2">
        We've noticed one or more of your inboxes are currently inactive due to
        authentication issues. Click
        <b> reauthenticate now </b> to ensure uninterrupted service.
      </div>
      <woot-base-button
        size="small"
        class="mg-left"
        @click="navigateToInboxPage"
      >
        Reauthenticate Now
      </woot-base-button>
    </div>
  </top-banner>
</template>
<script>
import TopBanner from 'dashboard/components/ui/TopBanner';

import { frontendURL } from 'dashboard/helper/URLHelper.js';
import { mapGetters } from 'vuex';

export default {
  components: { TopBanner },
  computed: { ...mapGetters({ accountId: 'getCurrentAccountId' }) },
  methods: {
    navigateToInboxPage() {
      const path = `accounts/${this.accountId}/settings/inboxes`;
      this.$router.push({ path: frontendURL(path) });
    },
  },
};
</script>
