<template>
  <div class="ticket-assignment-settings">
    <div class="assignment-panels">
      <assignment-panel
        :mode="ASSIGNMENT_MODES.AUTO"
        :inboxes="autoAssignmentInboxes"
        :current-assignment-logic="selectedAssignmentLogic"
        @update="onUpdate"
        @updateAssigmentLogic="onUpdateAssignmentLogic"
      />
      <assignment-panel
        :mode="ASSIGNMENT_MODES.MANUAL"
        :inboxes="manualAssignmentInboxes"
        @update="onUpdate"
      />
    </div>
    <offline-assignment-selector
      class="mg-top--medium"
      :inboxes="autoAssignmentInboxes"
      :selected-inboxes="autoInboxesWithOfflineAssignmentEnabled"
      @update="onUpdate"
    />
    <woot-base-button
      class="settings-button"
      :loading="isUpdatingSettings"
      @click="onSubmit"
    >
      {{ $t('TICKET_ASSIGNMENT.SAVE.BUTTON_TITLE') }}
    </woot-base-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AssignmentPanel from './AssignmentPanel';
import OfflineAssignmentSelector from './OfflineAssignmentSelector';

import alertMixin from 'shared/mixins/alertMixin';

import { ASSIGNMENT_MODES } from '../utils/constants';

export default {
  components: {
    AssignmentPanel,
    OfflineAssignmentSelector,
  },
  mixins: [alertMixin],
  data() {
    return {
      selectedAssignmentLogic: null,
      previousAssignmentLogic: null,
      isUpdatingSettings: false,
      updatedInboxes: [],
      ASSIGNMENT_MODES,
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
    }),
    autoAssignmentInboxes() {
      return this.updatedInboxes.filter(inbox => inbox.enable_auto_assignment);
    },
    manualAssignmentInboxes() {
      return this.updatedInboxes.filter(inbox => !inbox.enable_auto_assignment);
    },
    autoInboxesWithOfflineAssignmentEnabled() {
      return this.autoAssignmentInboxes.filter(
        inbox => inbox.allow_offline_assignment
      );
    },
    inboxesWithoutKnowlarity() {
      const knowlarityIds = new Set(
        this.$store.getters['inboxes/getKnowlarityInboxes'].map(
          inbox => inbox.id
        )
      );
      return this.$store.getters['inboxes/getInboxes'].filter(
        inbox => !knowlarityIds.has(inbox.id)
      );
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.updatedInboxes = this.inboxesWithoutKnowlarity;

      this.selectedAssignmentLogic = this.getAccount(
        this.currentAccountId
      ).assignment_logic;
      this.previousAssignmentLogic = this.selectedAssignmentLogic;
    },
    onUpdate(updatedInbox) {
      const index = this.updatedInboxes.findIndex(
        inbox => inbox.id === updatedInbox.id
      );
      if (index !== -1) {
        this.$set(this.updatedInboxes, index, {
          ...this.updatedInboxes[index],
          ...updatedInbox,
        });
      }
    },
    onUpdateAssignmentLogic(assignment_logic) {
      this.selectedAssignmentLogic = assignment_logic;
    },
    dispatchAssignmentLogicUpdate() {
      this.previousAssignmentLogic = this.selectedAssignmentLogic;

      const formData = new FormData();
      formData.append('assignment_logic', this.selectedAssignmentLogic);
      return this.$store.dispatch('accounts/update', formData);
    },
    dispatchBulkUpdateAutoAssignment() {
      return this.$store.dispatch(
        'inboxes/bulkUpdateAutoAssignment',
        this.updatedInboxes.map(inbox => ({
          id: inbox.id,
          allow_offline_assignment: inbox.allow_offline_assignment,
          enable_auto_assignment: inbox.enable_auto_assignment,
        }))
      );
    },
    async onSubmit() {
      this.isUpdatingSettings = true;
      try {
        if (this.selectedAssignmentLogic !== this.previousAssignmentLogic) {
          await this.dispatchAssignmentLogicUpdate();
        }

        await this.dispatchBulkUpdateAutoAssignment();
        this.showAlert(this.$t('TICKET_ASSIGNMENT.SAVE.SUCCESS'), 'success');
      } catch (error) {
        this.showAlert(this.$t('TICKET_ASSIGNMENT.SAVE.ERROR'), 'error');
      } finally {
        this.isUpdatingSettings = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

.ticket-assignment-settings {
  margin: $space-two;

  .assignment-panels {
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    gap: $space-slab;
  }
}
</style>
