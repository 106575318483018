<template>
  <div>
    <page-header
      :header-title="$t('BOT_CSAT.TITLE')"
      :info-text="$t('BOT_CSAT.DESCRIPTION')"
    >
      <woot-switch
        v-if="!uiFlags.isFetchingBotConfigs"
        :id="Math.random()"
        :value="value.enable_bot_csat"
        @click="() => updateConfig('enable_bot_csat', !value.enable_bot_csat)"
      />
      <woot-spinner
        v-else
        size=""
        color="lime-green"
        class="margin-right--medium"
      />
    </page-header>
    <transition name="modal-fade">
      <div
        v-if="value.enable_bot_csat"
        class="settings-sub-menu-content--larger"
      >
        <bot-flow-checkboxes
          :flows-object="value.csat_flow_trigger"
          @updateConfig="newVal => updateConfig('csat_flow_trigger', newVal)"
        />
        <bot-rating-radios
          :rating="value.csat_point_system"
          @updateConfig="newVal => updateConfig('csat_point_system', newVal)"
        />
        <bot-csat-time-delay
          :title="$t('BOT_CSAT.CONTROLS.TIME_DELAY.TITLE')"
          :description="$t('BOT_CSAT.CONTROLS.RATING_SCALE.DESCRIPTION')"
          :time="value.bot_csat_timer"
          :error="
            $v.value.bot_csat_timer.$error
              ? $t('BOT_CSAT.CONTROLS.TIME_DELAY.ERRORS.INVALID_TIME')
              : ''
          "
          @updateConfig="
            newVal => {
              $v.value.bot_csat_timer.$touch();
              updateConfig('bot_csat_timer', newVal);
            }
          "
        />
        <bot-csat-time-delay
          :title="$t('BOT_CSAT.CONTROLS.REMINDER_TIME.TITLE')"
          :description="$t('BOT_CSAT.CONTROLS.REMINDER_TIME.DESCRIPTION')"
          :time="value.csat_reminder_timer"
          :error="
            $v.value.csat_reminder_timer.$error
              ? $t('BOT_CSAT.CONTROLS.REMINDER_TIME.ERRORS.INVALID_TIME')
              : ''
          "
          @updateConfig="
            newVal => {
              $v.value.csat_reminder_timer.$touch();
              updateConfig('csat_reminder_timer', newVal);
            }
          "
        />
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { minValue, maxValue } from 'vuelidate/lib/validators';

import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';
import BotFlowCheckboxes from './BotFlowCheckboxes';
import BotRatingRadios from './BotRatingRadios';
import BotCsatTimeDelay from './BotCsatTimeDelay';

import {
  CSAT_TIMER_MAX_VALUE,
  CSAT_REMINDER_TIMER_MIN_VALUE,
} from '../utils/constants';

export default {
  components: {
    PageHeader,
    BotFlowCheckboxes,
    BotRatingRadios,
    BotCsatTimeDelay,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({ uiFlags: 'accounts/getUIFlags' }),
  },
  validations() {
    if (Object.keys(this.value)) {
      return {
        value: {
          bot_csat_timer: { maxValue: maxValue(CSAT_TIMER_MAX_VALUE - 1) },
          csat_reminder_timer: {
            minValue: minValue(CSAT_REMINDER_TIMER_MIN_VALUE),
            maxValue: maxValue(CSAT_TIMER_MAX_VALUE - 1),
          },
        },
      };
    }
    return {};
  },
  methods: {
    updateConfig(key, newValue) {
      const updatedConfig = {
        ...this.value,
        [key]: newValue,
      };
      this.$emit('input', updatedConfig);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.csat-flow-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: $space-normal;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .csat-flow .label-text {
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}
</style>
