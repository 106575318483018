<template>
  <div>
    <div
      class="file-wrapper"
      :class="{ hoverable: !canDelete }"
      @click="onOpenImg"
    >
      <div
        :style="canDelete && 'cursor:default'"
        :title="attachmentName || fileName(attachmentUrl)"
        class="file flex-row flex-align"
        @click="openLink"
      >
        <div class="icon-wrap">
          <icons :name="iconName" size="medium" color="darkestgrey" />
        </div>
        <div>
          <span class="text-block-title title-h6_m text-dark">
            {{
              truncateFilename(attachmentName || fileName(attachmentUrl), 10)
            }}
          </span>
        </div>
      </div>
      <span
        v-if="canDelete"
        class="cancel--preview"
        @click.prevent="$emit('clear', id)"
      >
        <icons
          name="cancel"
          color="stroke-grey"
          size="semimedium"
          :custom-style="{
            strokeWidth: '2',
          }"
          title="remove"
        />
      </span>
      <span
        v-if="attachmentSize !== 0"
        class="file-size-text body-b3 text-light"
      >
        {{ formatFileSize(attachmentSize) }}
      </span>
    </div>
    <woot-modal :full-width="true" :show.sync="showModal" :on-close="onClose">
      <img :src="attachmentUrl" style="max-height: 100%" />
    </woot-modal>
  </div>
</template>
<script>
import fileMixin from 'shared/mixins/fileMixin';
import { formatBytes } from 'dashboard/helper/files';

export default {
  mixins: [fileMixin],
  props: {
    id: {
      type: String,
      default: '',
    },
    attachmentUrl: {
      type: String,
      default: '',
    },
    attachmentName: {
      type: String,
      default: '',
    },
    attachmentType: {
      type: String,
      default: '',
    },
    attachmentSize: {
      type: Number,
      default: 0,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    showImgModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    iconName() {
      const mapTypeToIcon = {
        pdf: 'filePdf',
        jpeg: 'image',
        jpg: 'image',
        png: 'image',
        svg: 'image',
        webp: 'image',
        mp3: 'audio',
        html: 'browser',
        csv: 'fileCsv',
        mp4: 'video',
        mov: 'video',
      };
      return (
        mapTypeToIcon[
          this.fileType(this.attachmentName || this.attachmentUrl)
        ] || 'download'
      );
    },
  },
  methods: {
    formatFileSize(size) {
      return formatBytes(size, 0);
    },
    onOpenImg() {
      if (this.attachmentType === 'image' && this.showImgModal)
        this.showModal = true;
    },
    onClose() {
      this.showModal = false;
    },
    openLink() {
      if (this.canDelete) return;
      const fileTypes = {
        jpeg: true,
        jpg: true,
        png: true,
        mp4: true,
        svg: true,
        webp: true,
        gif: true,
      };
      if (fileTypes[this.fileType(this.attachmentUrl)]) {
        this.$emit('openMediaSlider', this.id);
        return;
      }
      window.open(this.attachmentUrl, '_blank', 'noopener');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.file-wrapper {
  position: relative;
  transition: all 0.3s ease-in-out;

  .file {
    padding: $space-micro $space-small;
    margin-bottom: $space-smaller;
    max-width: $space-two * 10;
    height: $space-larger;
    border: 1px solid $pg-1;
    border-radius: $border-radius;
    cursor: pointer;

    .icon-wrap {
      font-size: $font-size-giga;
      margin-right: $space-small;
      position: sticky;
      top: $space-normal;
    }

    .text-block-title {
      margin: 0;
      word-break: break-word;
    }
  }
  .file-size-text {
    margin-left: $space-slab;
  }
}

.hoverable:hover {
  transform: translateY(-$space-smaller);
}

.cancel--preview {
  background: $neutral-white;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: -$space-small;
  top: -$space-small;
  z-index: 100;
}

.cancel--preview ::v-deep svg {
  margin-right: 0;
}
</style>
