<template>
  <div class="contacts-page row">
    <div class="left-wrap lime-card-1dp" :class="wrapClass">
      <contacts-header
        :search-query="searchQuery"
        :on-search-submit="onSearchSubmit"
        this-selected-contact-id=""
        :on-input-search="onInputSearch"
        :on-close="closeContactInfoPanel"
        :is-closed="showContactInfoPanelPane"
        :search-param="searchParam"
        :set-search-param="setSearchParam"
        :on-change-label-ids="onChangeLabelIds"
      />
      <contacts-table
        :contacts="activeRecords"
        :show-search-empty-state="showEmptySearchResult"
        :is-loading="uiFlags.isFetching"
        :on-click-contact="openContactInfoPanel"
        :active-contact-id="activeContactId"
      />
      <div class="full-width flex-row flex-align flex-justify--end navigation">
        <div class="flex-row flex-align navigation-controls">
          <woot-primary-button
            name="previous"
            :custom-style="{ width: '12rem' }"
            :disabled="uiFlags.isFetching || currentPage === 1"
            @click="prevPage"
          />
          <woot-primary-button
            name="next"
            :custom-style="{ width: '12rem' }"
            :disabled="uiFlags.isFetching || activeRecords.length < 15"
            @click="nextPage"
          />
        </div>
      </div>
    </div>
    <contact-info-panel
      v-if="showContactViewPane"
      :key="`contact-info-panel-${selectedContact.id}`"
      :contact="selectedContact"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContactsHeader from './Header';
import ContactsTable from './ContactsTable';
import ContactInfoPanel from './ContactInfoPanel';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import * as types from 'shared/constants/googleEventType';

export default {
  components: {
    ContactsHeader,
    ContactsTable,
    ContactInfoPanel,
  },
  mixins: [googleAnalyticsMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      searchQuery: '',
      searchParam: 'phone_number',
      showEditModal: false,
      showContactInfoPanelPane: false,
      selectedContact: null,
      currentPage: 1,
      selectedLabelIds: [],
      hasSearched: false,
    };
  },
  computed: {
    ...mapGetters({
      records: 'contacts/getContacts',
      searchedContacts: 'contacts/getSearchedContacts',
      uiFlags: 'contacts/getUIFlags',
      meta: 'contacts/getMeta',
    }),
    firstIndex() {
      return 15 * (this.currentPage - 1) + 1;
    },
    lastIndex() {
      const index = Math.min(this.meta.count, 15 * this.currentPage);
      return index;
    },
    showEmptySearchResult() {
      const hasEmptyResults =
        !!this.searchQuery && this.activeRecords.length === 0;
      return hasEmptyResults;
    },
    activeContactId() {
      return this.selectedContact ? this.selectedContact.id : null;
    },
    showContactViewPane() {
      return !!this.selectedContact;
    },
    wrapClass() {
      return this.showContactViewPane ? 'medium-9' : 'medium-12';
    },
    pageParameter() {
      const selectedPageNumber = Number(this.$route.query.page);
      return Number.isFinite(selectedPageNumber) && selectedPageNumber >= 1
        ? selectedPageNumber
        : 1;
    },
    activeRecords() {
      return this.hasSearched ? this.searchedContacts : this.records;
    },
  },
  created() {
    this.fetchContactsBasedOnQuery();
  },
  methods: {
    fetchContactsBasedOnQuery(page = this.currentPage) {
      const action = this.searchQuery.trim()
        ? 'contacts/search'
        : 'contacts/getList';
      const params = { page, labelIds: this.selectedLabelIds };
      if (this.searchQuery) {
        params.search = this.searchQuery;
        params.searchBy = this.searchParam;
        this.hasSearched = true;
      }
      this.$store.dispatch(action, params);
    },
    onInputSearch(value) {
      if (!value.trim()) {
        // If the search query is empty, fetch the contact list
        this.searchQuery = ''; // Clear the search query
        this.fetchContactsBasedOnQuery();
        this.hasSearched = false;
      } else {
        this.searchQuery = value;
      }
    },
    setSearchParam(val) {
      this.searchParam = val.value;
    },
    onChangeLabelIds(_, __, ids) {
      this.selectedLabelIds = ids;
      this.fetchContactsBasedOnQuery();
    },
    onSearchSubmit() {
      if (this.searchQuery.trim()) {
        this.googleAnalyticsEvent(
          types.default.CONTACTS_SEARCH,
          types.default.CONTACTS_EVENT,
          this.searchQuery
        );
        this.fetchContactsBasedOnQuery(1);
      }
    },
    prevPage() {
      this.mix_panel_clicked_contacts_previous();
      this.currentPage -= 1;
      this.fetchContactsBasedOnQuery();
    },
    nextPage() {
      this.mix_panel_clicked_contacts_next();
      this.currentPage += 1;
      this.fetchContactsBasedOnQuery();
    },
    openContactInfoPanel(contactId) {
      this.mix_panel_clicked_contact_card();
      this.selectedContact = this.activeRecords.find(
        item => item.id === contactId
      );
      this.showContactInfoPanelPane = true;
    },
    closeContactInfoPanel() {
      this.selectedContact = null;
      this.showContactInfoPanelPane = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';
.contacts-page {
  width: 100%;
  padding: $space-normal;
}
.left-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $space-slab $zero;
}

.navigation {
  padding: $zero $space-three * 10;

  .navigation-controls {
    gap: $space-slab;
  }
}
</style>
