export const filterMappings = {
  inboxId: 'inbox_id',
  status: 'status',
  starred: 'starred',
  oldest: 'oldest',
  assigneeType: 'assignee_type',
  page: 'page',
  labels: 'label_ids',
  assigned_by: 'assigned_by',
  resolved_by: 'resolved_by',
  closed_by: 'closed_by',
  selectedAgent: 'selectedAgent',
  unread: 'unread',
  isNew: 'is_new',
  alert: 'alert',
  teamId: 'team_id',
  identifier: 'identifier',
  statusTimeRange: 'status_time_range',
  sla_breached: 'sla_breached',
};

export const reverseFilterMappings = Object.fromEntries(
  Object.entries(filterMappings).map(([key, value]) => [value, key])
);

export function transformFilters(filters, mappings) {
  return Object.keys(filters).reduce((transformedFilters, key) => {
    if (mappings[key] !== undefined && filters[key] !== undefined) {
      transformedFilters[mappings[key]] = filters[key];
    }
    return transformedFilters;
  }, {});
}

export function transformFiltersToBackend(filters) {
  return transformFilters(filters, filterMappings);
}

export function transformFiltersToFrontend(filters) {
  return transformFilters(filters, reverseFilterMappings);
}
