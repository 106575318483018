<template>
  <loading-state v-if="initialisingData" style="height: 100%" />
  <Form v-else :data="sla" @submit="onSubmit" />
</template>
<script>
import { mapGetters } from 'vuex';

import Form from '../components/create/Form';
import LoadingState from 'dashboard/components/ui/LoadingState';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    Form,
    LoadingState,
  },
  mixins: [alertMixin],
  data() {
    return {
      sla: { name: '', description: '' },
      initialisingData: true,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'sla/getUIFlags' }),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      const { sla_id } = this.$route.params;
      this.$store.dispatch('sla/showSLA', sla_id).then(response => {
        this.sla = response.data;
        this.initialisingData = false;
      });
    },
    onSubmit(payload) {
      this.$store
        .dispatch('sla/editSLA', { id: this.sla.id, ...payload })
        .then(() => {
          this.showAlert(this.$t('SLA.SAVE.API.SUCCESS_MESSAGE'), 'success');
        })
        .catch(error => {
          const errorMessage = error.response?.data?.[0]
            ? error.response.data[0]
            : this.$t('SLA.SAVE.API.ERROR_MESSAGE');

          this.showAlert(
            errorMessage,
            'error',
            errorMessage.length >= 50 ? 5000 : 2500
          );
        });
    },
  },
};
</script>
