<template>
  <woot-switch
    :id="id"
    :value="enabled"
    style="margin: -1rem;"
    @click="toggleFieldStatus"
  />
</template>

<script>
export default {
  props: {
    id: {
      type: [Number, String],
      default: Math.random(),
    },
    enabled: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    toggleFieldStatus() {
      this.$emit('toggleFieldStatus', !this.enabled);
    },
  },
};
</script>
