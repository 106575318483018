// eslint-disable-next-line spaced-comment
/// <reference path="./typeDefs.js" />

/**
 * @typedef {import('./types').State} State
 * @typedef {import('./types').Record} Record
 */

const getters = {
  getCustomTicketFields($state) {
    return Object.values($state.records).sort((n1, n2) => n2.id - n1.id);
  },
  getFieldWithId: $state => id => {
    return $state.records.find(field => field.id === id);
  },

  /**
   * Filters the records in the state to find fields associated with a specific inbox.
   *
   * @param {State} $state - The state object containing records.
   * @param {number} inboxId - The ID of the inbox to filter fields by.
   * @returns {Record[]} An array of records that are associated with the specified inbox.
   */
  getFieldsOfInbox: $state => inboxId => {
    const filteredFields = $state.records.filter(field => {
      if (Array.isArray(field.inboxes)) {
        return field.inboxes.some(inbox => inbox.id === inboxId);
      }
      return false;
    });

    return filteredFields;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export default getters;
