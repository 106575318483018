const getters = {
  getCurrentView: state => state.currentView,
  getDefaultView: state => state.defaultView,
  getAllViews: state => {
    return [...state.curatedViews, ...state.customViews];
  },
  getCustomViews: state => state.customViews,
  getCuratedViews: state => state.curatedViews,
  getUiFlags: state => state.uiFlags,
  getCurrentViewFilters: ({ currentViewFilters }) => currentViewFilters,
};

export default getters;
