/* global axios */

import ApiClient from './ApiClient';

class ImportDataApi extends ApiClient {
  constructor() {
    super('import_data', { accountScoped: true });
  }

  create({ file, type }) {
    const formData = new FormData();
    formData.append('file[]', file, file.name);
    formData.append('type', type);

    return axios({ method: 'post', url: this.url, data: formData });
  }
}

export default new ImportDataApi();
