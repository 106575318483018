<template>
  <woot-modal
    :show.sync="opened"
    :custom-style="{ width: '500px' }"
    :on-close="onClose"
  >
    <div class="modal-body">
      <div class="flex-row flex-align gap--small">
        <span
          class="title-h3 text-blue"
          v-text="$t('AUTOMATION.FORM.TEST_MODE.TITLE')"
        />
        <woot-switch
          id="toggle-test-mode"
          :value="isTestModeActive"
          @click="toggleTestMode(!isTestModeActive)"
        />
      </div>
      <div
        class="body-b2 text-light mg-bottom--slab"
        v-text="$t('AUTOMATION.FORM.TEST_MODE.DESC')"
      />
      <div class="test-with-phone-numbers">
        <add-contact
          :selected-contacts="selectedContacts"
          @updateContacts="onUpdatePhoneNumbers"
        >
          <template v-slot:selected-contacts-display-panel>
            <div class="flex-row flex-wrap">
              <woot-chips
                v-for="({ phone_number }, idx) in selectedContacts"
                :key="idx"
                show-cancel-icon
                :show-tooltip="false"
                variant="primary-small"
                :title="phone_number"
                @click="removeContact($event)"
              />
            </div>
          </template>
        </add-contact>
      </div>
    </div>
  </woot-modal>
</template>
<script>
import AddContact from 'dashboard/components/widgets/addContact';

export default {
  components: { AddContact },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    isTestModeActive: {
      type: Boolean,
      default: false,
    },
    phoneNumbers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedContacts() {
      return this.phoneNumbers.map(phone => ({ phone_number: phone }));
    },
  },
  methods: {
    toggleTestMode(isActive) {
      this.$emit('onUpdate', { is_test: isActive });
    },
    removeContact(phoneToRemove) {
      const updatedPhoneNumbers = [
        ...this.selectedContacts.filter(
          contact => contact.phone_number !== phoneToRemove
        ),
      ];
      this.onUpdatePhoneNumbers(updatedPhoneNumbers);
    },
    onUpdatePhoneNumbers(contacts) {
      const updatedPhoneNumbers = contacts.map(contact => contact.phone_number);

      this.emitUpdatedPhoneNumbers(updatedPhoneNumbers);
    },
    emitUpdatedPhoneNumbers(updatedPhoneNumbers) {
      this.$emit('onUpdate', { test_numbers: updatedPhoneNumbers });
    },
    onClose() {
      if (this.phoneNumbers.length === 0) this.toggleTestMode(false);
      this.$emit('onClose');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.modal-body {
  padding: $space-normal $space-medium;
}
</style>
