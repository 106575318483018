<template>
  <div class="orders--container">
    <!-- Apply Discount modal -->
    <woot-modal
      :show.sync="showDiscountModal"
      :on-close="() => (showDiscountModal = false)"
    >
      <woot-modal-header :header-title="'ADD DISCOUNT'" />
      <template v-if="!readOnly">
        <div
          v-if="!shopifyBetaEnabledFlag || (shopifyBetaEnabledFlag && !isAgent)"
          class="content-box flex-row flex-align flex-justify--center content-margin"
        >
          <label
            for="numericalDiscount"
            class="radio-container"
            style="cursor:pointer"
          >
            <span class="body-b2">
              Discount value
            </span>
            <input
              id="numericalDiscount"
              v-model="discountType"
              type="radio"
              name="discountype"
              value="FIXED_AMOUNT"
            />
            <span class="radio-circle" />
          </label>
          <label
            for="percentageDiscount"
            class="radio-container"
            style="cursor:pointer"
          >
            <span class="body-b2">Discount percentage</span>
            <input
              id="percentageDiscount"
              v-model="discountType"
              type="radio"
              name="discountype"
              value="PERCENTAGE"
            />
            <span class="radio-circle" />
          </label>
        </div>
      </template>
      <div class="content-box">
        <template v-if="!readOnly">
          <div class="flex-row flex-align flex-justify--between">
            <woot-input
              v-model="discount"
              type="number"
              :label="
                `Discount value (${accountCurrency})${
                  isDiscountPercentage ? ' (%)' : ''
                }`
              "
              class="discount-field"
              :class="{ error: $v.discount.$error }"
              :has-error="$v.discount.$error"
              :error="customDiscountErrMsg"
              :disabled="shopifyBetaEnabledFlag && isAgent"
              @keyup.enter.native="applyDiscount"
              @input="onInput"
            />
          </div>
          <div v-if="shopifyBetaEnabledFlag" class="mg-top--small">
            <woot-chips
              v-for="val in quickDiscountValues"
              :key="val.name"
              :title="val.name"
              :show-tooltip="false"
              :custom-style="{ cursor: 'pointer', borderRadius: '4px' }"
              variant="secondary-small"
              :bg-color="val.value === discount ? '#6bac1b' : ''"
              @click.native="onClickDiscountValue(val)"
            />
          </div>
        </template>
        <div
          style="gap: 0.8rem"
          class="flex-row flex-justify--end discount-modal-btn-container"
        >
          <woot-secondary-button
            name="Cancel"
            @click="() => (showDiscountModal = false)"
          />
          <woot-primary-button
            name="Apply"
            :disabled="$v.discount.$error"
            @click="applyDiscount"
          />
        </div>
      </div>
    </woot-modal>

    <!-- Apply Shipping Charge modal -->
    <woot-modal
      :show.sync="showShippingChargeModal"
      :on-close="() => (showShippingChargeModal = false)"
    >
      <woot-modal-header :header-title="'ADD SHIPPING CHARGE'" />

      <div class="content-box">
        <div class="flex-row flex-align flex-justify--center content-margin">
          <label
            v-if="accountShippingRate.name"
            for="applicableShipping"
            class="radio-container"
            style="cursor:pointer"
          >
            <span class="body-b2">
              {{
                `${accountShippingRate.name} - ${accountCurrency}${accountShippingRate.price}`
              }}
            </span>
            <input
              id="applicableShipping"
              v-model="shippingSelection"
              type="radio"
              name="refund-action"
              :value="0"
              :checked="shippingSelection === 0"
            />
            <span class="radio-circle" />
          </label>
          <label
            for="freeShipping"
            class="radio-container"
            style="cursor:pointer"
          >
            <span class="body-b2">{{ `Free Shipping` }}</span>
            <input
              id="freeShipping"
              v-model="shippingSelection"
              type="radio"
              name="refund-action"
              :value="1"
              :checked="shippingSelection === 1"
            />
            <span class="radio-circle" />
          </label>
          <label
            v-if="
              !shopifyBetaEnabledFlag || (shopifyBetaEnabledFlag && !isAgent)
            "
            for="customShipping"
            class="radio-container"
            style="cursor:pointer"
          >
            <span class="body-b2">{{ `Custom Shipping` }}</span>
            <input
              id="customShipping"
              v-model="shippingSelection"
              type="radio"
              name="refund-action"
              :value="2"
              :checked="shippingSelection === 2"
            />
            <span class="radio-circle" />
          </label>
        </div>
      </div>
      <div class="content-box">
        <template v-if="!readOnly && shippingSelection === 2">
          <div class="flex-row flex-align flex-justify--between">
            <woot-input
              v-model="shippingCharge"
              type="number"
              :label="`Shipping Charge (${accountCurrency})`"
              class="discount-field"
              @keyup.enter.native="applyShippingCharge"
              @input="onInput"
            />
          </div>
          <div v-if="shopifyBetaEnabledFlag" class="mg-top--small">
            <woot-chips
              v-for="val in quickShippingValues"
              :key="val.name"
              :title="val.name"
              :show-tooltip="false"
              :custom-style="{ cursor: 'pointer', borderRadius: '4px' }"
              :bg-color="val.value === shippingCharge ? '#6bac1b' : ''"
              variant="secondary-small"
              @click.native="onClickShippingValue(val)"
            />
          </div>
        </template>
        <div
          style="gap: 0.8rem"
          class="flex-row flex-justify--end discount-modal-btn-container"
        >
          <woot-secondary-button
            name="Cancel"
            @click="() => (showShippingChargeModal = false)"
          />
          <woot-primary-button name="Apply" @click="applyShippingCharge" />
        </div>
      </div>
    </woot-modal>
    <transition-group name="menu-list" tag="div" class="menu vertical">
      <div v-for="order in orders" :key="order.id">
        <order
          v-if="order.fulfillable_quantity !== 0"
          :order-data="order"
          :read-only="readOnly"
        />
      </div>
    </transition-group>
    <div
      v-if="readOnly || hasAddress"
      class="orders--calculation"
      :class="{ 'orders--disable': creatingDraftOrder }"
    >
      <div class="flex-row flex-justify--between content-margin">
        <span class="body-b3 text-light">Item(s) total</span>
        <span class="body-b3 text-dark">
          {{ accountCurrency + cartTotal }}
        </span>
      </div>
      <div class="flex-column content-margin">
        <div
          v-if="!readOnly && hasAddress && orderMode !== 'edit'"
          class="flex-row flex-align flex-justify--between"
        >
          <span
            class="body-b3 text-light discount-label"
            @click="onShowShippingChargesModal"
          >
            Add Shipping Charge
          </span>
          <span class="body-b3 text-dark">
            {{ accountCurrency + shippingChargeAmt }}
          </span>
        </div>
        <div
          v-else-if="readOnly || orderMode === 'edit'"
          class="flex-row flex-align flex-justify--between"
        >
          <span class="body-b3 text-light">
            Shipping Charge
          </span>
          <span class="body-b3 text-dark">
            {{ accountCurrency + shippingChargeAmt }}
          </span>
        </div>
      </div>
      <div class="flex-column content-margin">
        <div
          v-if="!readOnly && hasAddress && orderMode !== 'edit'"
          class="flex-row flex-align flex-justify--between"
        >
          <span
            class="body-b3 text-light discount-label"
            @click="onShowDiscountModal"
          >
            Apply Discount
          </span>
          <span class="body-b3 text-dark">
            -{{ accountCurrency + discountAmt }}
          </span>
        </div>
        <div
          v-else-if="readOnly || orderMode === 'edit'"
          class="flex-row flex-align flex-justify--between"
        >
          <span class="body-b3 text-light">
            Discount
          </span>
          <span class="body-b3 text-dark">
            -{{ accountCurrency + discountAmt }}
          </span>
        </div>
      </div>
      <div class="flex-row flex-justify--between content-margin">
        <span class="body-b3 text-light">Tax</span>
        <span class="body-b3 text-dark">
          {{ accountCurrency + totalTax }}
        </span>
      </div>
      <div class="flex-row flex-align flex-justify--between">
        <span class="title-h5_m text-dark">Total Price</span>
        <span class="title-h5_m text-dark">
          {{ accountCurrency + totalPrice || 0.0 }}
        </span>
      </div>
      <div
        v-if="
          !orderDetail.is_cod &&
            orderDetail.user_owe_amount &&
            orderDetail.user_owe_amount !== '0.00'
        "
        class="flex-row flex-align flex-justify--between"
      >
        <span class="title-h5_m text-dark">Balance (customer owes you)</span>
        <span class="title-h5_m text-red">{{
          accountCurrency +
            (orderDetail.is_cod ? totalPrice : orderDetail.user_owe_amount)
        }}</span>
      </div>
      <div
        v-if="
          orderDetail.user_refund_amount &&
            orderDetail.user_refund_amount !== '0.00' &&
            orderDetail.user_owe_amount === '0.00' &&
            !orderDetail.is_cod
        "
        class="flex-row flex-align flex-justify--between"
      >
        <span class="title-h5_m text-dark">Balance (you owe customer)</span>
        <span class="title-h5_m text-green">{{
          accountCurrency + orderDetail.user_refund_amount
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import validations from '../shared/validations';

import alertMixin from 'shared/mixins/alertMixin';
import isAdmin from 'dashboard/mixins/isAgent';

import Order from './Order';

const DISCOUNT_TYPE = { numerical: 'FIXED_AMOUNT', percentage: 'PERCENTAGE' };

export default {
  components: { Order },
  mixins: [alertMixin, isAdmin],
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    cartData: {
      type: Object,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    orderData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      discount: 0,
      appliedDiscount: 0,
      shippingCharge: 0,
      appliedShippingCharge: 0,
      showDiscountModal: false,
      shippingSelection: 0,
      showShippingChargeModal: false,
      discountType: DISCOUNT_TYPE.numerical,
      quickDiscountValues: [
        { name: '5%', value: 5 },
        { name: '10%', value: 10 },
      ],
    };
  },
  validations: () => {
    const { shippingCharge, discount } = validations;
    return {
      shippingCharge,
      discount: {
        ...discount,
        checkValidDiscountValue(value) {
          if (this.isDiscountValue) return value <= +this.cartTotal;
          if (this.isDiscountPercentage) return value <= 100;
          return false;
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      orderDetails: 'getActiveShopifyOrder',
      orderMode: 'getCurrentOrderMode',
      draftOrderData: 'getDraftOrderMeta',
      creatingDraftOrder: 'getDraftOrderUIFlag',
      cartDetailsInCreateMode: 'getCartMeta',
      selectedAddress: 'getSelectedAddress',
      customerAddresses: 'getCustomerAddresses',
      cartDetailsInEditMode: 'getCartMetaInEditMode',
      productsInCartInCreateMode: 'getProductsInCart',
      productsInCartInEditMode: 'getProductsInCartInEditMode',
      accountShippingRate: 'getAccountShippingRate',
      shopifyBetaEnabledFlag: 'getDeepShopifyBetaEnabledFlag',
    }),
    quickShippingValues() {
      return [
        { name: this.accountCurrency + '50', value: 50 },
        { name: this.accountCurrency + '90', value: 90 },
      ];
    },
    isDiscountValue() {
      return this.discountType === DISCOUNT_TYPE.numerical;
    },
    isDiscountPercentage() {
      return this.discountType === DISCOUNT_TYPE.percentage;
    },
    customDiscountErrMsg() {
      if (!this.$v.discount.checkValidDiscountValue) {
        if (this.isDiscountValue)
          return `Discount should not exceed cart value (${this
            .accountCurrency + this.cartTotal})`;

        if (this.isDiscountPercentage)
          return 'Discount percentage cannot exceed 100%';
      }

      return 'Add a positive number';
    },
    cartDetails() {
      if (this.orderMode === 'edit') return this.cartDetailsInEditMode;
      return this.cartDetailsInCreateMode;
    },
    currentCartId() {
      return this.cartDetails.id;
    },
    productsInCart() {
      if (this.orderMode === 'edit') return this.productsInCartInEditMode;
      return this.productsInCartInCreateMode;
    },
    hasAddress() {
      return this.customerAddresses && this.customerAddresses.length !== 0;
    },
    discountAmt() {
      return (
        this.appliedDiscount ||
        this.orderDetail.appliedDiscount ||
        this.orderDetail.applied_discount ||
        0
      );
    },
    shippingChargeAmt() {
      return (
        this.appliedShippingCharge ||
        this.orderDetail.appliedShippingCharge ||
        this.orderDetail.shipping_charges ||
        this.orderDetail.shipping_price ||
        0
      );
    },
    totalTax() {
      const total =
        +this.orderDetail.current_total_tax || +this.orderDetail.total_tax;
      return total < 0 ? 0 : total;
    },
    totalPrice() {
      const total =
        +this.orderDetail?.amount || +this.orderDetail?.total_price || 0;
      return total < 0 ? 0 : total;
    },
    accountCurrency() {
      return this.$store.getters['accounts/getAccountCurrency'](this.accountId);
    },
    orderDetail() {
      if (this.orderMode === 'edit') {
        return this.orderDetails;
      }
      if (this.readOnly) {
        return this.orderData;
      }
      return this.draftOrderData;
    },
    cartTotal() {
      return (
        this.orderDetail.cart?.cart_total ||
        this.orderDetail?.current_subtotal_price ||
        0
      );
    },
  },
  methods: {
    onClickDiscountValue(val) {
      this.discountType = DISCOUNT_TYPE.percentage;
      this.discount = val.value;
    },
    onClickShippingValue(val) {
      this.shippingCharge = val.value;
    },
    onShowDiscountModal() {
      this.discount = this.discountAmt;
      this.discountType = DISCOUNT_TYPE.numerical;
      this.showDiscountModal = true;
    },
    onShowShippingChargesModal() {
      // Fetch Shipping Charge Rates
      this.$store
        .dispatch('fetchShopifyShippingRates', {
          orderValue: this.cartTotal,
        })
        .then(() => {
          this.shippingCharge = this.shippingChargeAmt;
          this.showShippingChargeModal = true;
        });
    },
    onInput() {
      this.$v.$touch();
      this.$emit(
        'error',
        this.$v.shippingCharge.$error || this.$v.discount.$error
      );
    },
    applyDiscount() {
      let assigneeId = null;
      try {
        assigneeId = this.currentChat?.meta?.assignee?.id;
        if (!assigneeId) {
          assigneeId = null;
        }
      } catch {
        assigneeId = null;
      }

      try {
        this.$store
          .dispatch('updateDraftOrder', {
            draftOrderId: this.draftOrderData.id,
            editRequest: 'edit_discount',
            discountType: this.discountType,
            appliedDiscount: this.discount || 0,
            shippingAddress: this.selectedAddress.shipping,
            agentId: assigneeId,
            conversationId: this.currentChat?.id,
          })
          .then(() => {
            this.appliedDiscount = this.orderDetails.appliedDiscount;
            this.showDiscountModal = false;
            this.showAlert('Discount applied successfully', 'success');
          });
      } catch (error) {
        // handle error
        this.discount = 0;
        this.showAlert('Cannot apply discount', 'error');
        this.showDiscountModal = false;
      }
    },

    applyShippingCharge() {
      // Checking type of shipping selected
      switch (this.shippingSelection) {
        case 0: {
          this.shippingCharge = this.accountShippingRate.price;
          break;
        }
        case 1: {
          this.shippingCharge = 0;
          break;
        }
        case 2: {
          break;
        }
        default:
          break;
      }

      if (this.shippingCharge === this.shippingChargeAmt) {
        this.showAlert('Shipping Charge already added', 'warning');
        return;
      }

      let assigneeId = null;
      try {
        assigneeId = this.currentChat?.meta?.assignee?.id;
        if (!assigneeId) {
          assigneeId = null;
        }
      } catch {
        assigneeId = null;
      }

      try {
        this.$store
          .dispatch('updateDraftOrder', {
            draftOrderId: this.draftOrderData.id,
            editRequest: 'edit_shipping_charges',
            appliedShippingCharge: this.shippingCharge || 0,
            shippingAddress: this.selectedAddress.shipping,
            agentId: assigneeId,
            conversationId: this.currentChat?.id,
          })
          .then(() => {
            this.appliedShippingCharge = this.shippingCharge;
            this.showShippingChargeModal = false;
            this.showAlert('Shipping Charge added successfully', 'success');
          });
      } catch (error) {
        // handle error
        this.shippingCharge = 0;
        this.showAlert('Cannot add shipping charge', 'error');
        this.showShippingChargeModal = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.content-margin {
  margin-bottom: $space-smaller;
}

.content-box {
  padding: $space-slab $space-medium !important;
}

.discount-modal-btn-container {
  padding-top: $space-large;
}

.discount-field {
  width: 100%;
}

.orders {
  &--container {
    background: $pg-light-20;
    border-radius: $border-radius;
    margin-bottom: $space-slab;
    padding: $space-slab;
  }

  &--disable {
    pointer-events: none;
    animation: fade 1s linear 0.5s infinite;
  }

  &--calculation {
    border-top: 1px dashed $neutral-grey-400;
    padding: $space-slab 0 0;

    .capital_c4 {
      text-align: right;
      line-height: 0.8rem;
    }

    .discount-label {
      color: $secondary-blue;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
