<template>
  <div class="columns content-box settings-box">
    <div class="settings-container column custom-scroll lime-card-1dp mr-right">
      <div class="small-12 columns">
        <page-header
          :header-title="$t('DEEP_SHOPIFY.TITLE')"
          :header-content="$t('DEEP_SHOPIFY.SUB_TITLE')"
        >
          <template v-slot:icon>
            <div class="crm-image-container">
              <div class="centered-image">
                <img :src="'/dashboard/images/integrations/shopify.svg'" />
              </div>
            </div>
          </template>
          <reference-box
            :to="documentation.shopify"
            content="Know more about Shopify Integration"
            type="document"
          />
        </page-header>
        <div
          class="shopify-settings settings-sub-menu-content--larger flex-column"
        >
          <div
            v-for="control in Object.keys(controls)"
            :key="controls[control].id"
          >
            <page-header
              :header-title="
                $t(`DEEP_SHOPIFY.CONTROLS.${controls[control].name}.TITLE`)
              "
              :header-content="
                $t(`DEEP_SHOPIFY.CONTROLS.${controls[control].name}.DESC`)
              "
              :show-border="!getOtherSettings(control)"
              size="small"
            >
              <woot-switch
                :id="controls[control].id"
                v-model="controls[control].value"
                @click="toggleSelectedControl(control)"
              />
            </page-header>
            <template
              v-if="getOtherSettings(control) && controls[control].value"
            >
              <span class="text-light body-b2 other-settings-title">
                {{
                  $t(
                    `DEEP_SHOPIFY.CONTROLS.${controls[control].name}.OTHER_SETTINGS.DESC`
                  )
                }}
              </span>
              <div class="other-settings flex-row">
                <div
                  v-for="subControl in Object.keys(getOtherSettings(control))"
                  :key="getOtherSettings(control)[subControl].id"
                  style="cursor:pointer"
                  class="flex-row flex-align--start"
                >
                  <label
                    class="container-checkbox"
                    :class="{
                      disabled:
                        !canAccessPrepaid && subControl === 'prepaidEnabled',
                    }"
                  >
                    <span class="body-b2 label-text">
                      {{
                        $t(
                          `DEEP_SHOPIFY.CONTROLS.${
                            controls[control].name
                          }.OTHER_SETTINGS.${
                            getOtherSettings(control)[subControl].name
                          }`
                        )
                      }}
                    </span>
                    <input
                      v-model="selectedSubControls"
                      type="checkbox"
                      :value="subControl"
                      @input="e => handleSubControl(e, control)"
                    />
                    <span class="checkmark" />
                  </label>
                  <span
                    v-if="!canAccessPrepaid && subControl === 'prepaidEnabled'"
                    v-tooltip="
                      'Enable Razorpay Integration to use this feature.'
                    "
                    class="warning-icon"
                  >
                    <icons
                      name="error"
                      color="warning"
                      size="normal"
                      :show-title="false"
                    />
                  </span>
                </div>
              </div>
              <hr />
            </template>
          </div>
          <span
            class="title-h5 text-dark mg-top--medium mg-bottom--small"
            v-text="'Mandatory fields for order creation or updation'"
          />
          <woot-single-selector
            :default-option="selectedOptionName"
            :option-list="actionsList"
            :show-selected-text="false"
            @click="onActionSelected"
          />
          <woot-base-button
            name="Save"
            class="settings-button"
            @click="updateControls"
          >
            {{ $t('GENERAL_SETTINGS.SUBMIT') }}
          </woot-base-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';

import * as types from 'shared/constants/googleEventType';
import documentation from 'dashboard/helper/data/documentationData.json';
import { MANDATORY_FIELDS } from './constants';

import alertMixin from 'shared/mixins/alertMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';

export default {
  components: {
    PageHeader,
    ReferenceBox,
  },
  mixins: [alertMixin, googleAnalyticsMixin],
  data() {
    return {
      controls: {
        createOrderEnable: {
          id: 1,
          name: 'CREATE',
          value: false,
          otherSettings: {
            prepaidEnabled: { id: 1.1, name: 'PREPAID', value: false },
            codEnabled: { id: 1.2, name: 'COD', value: false },
          },
        },
        cancelOrderEnable: { id: 2, name: 'CANCEL', value: false },
        refundOrderEnable: { id: 3, name: 'REFUND', value: false },
        editOrderEnable: { id: 4, name: 'EDIT', value: false },
      },
      isDeepShopify: false,
      canAccessPrepaid: false,
      documentation,
      actionsList: [
        {
          id: 1,
          name: this.$t(
            'GENERAL_SETTINGS.FORM.MANDATORY_FIELDS.' + MANDATORY_FIELDS.PHONE
          ),
          key: MANDATORY_FIELDS.PHONE,
          value: 'shopify_orders_with_phone',
        },
        {
          id: 2,
          name: this.$t(
            'GENERAL_SETTINGS.FORM.MANDATORY_FIELDS.' + MANDATORY_FIELDS.EMAIL
          ),
          key: MANDATORY_FIELDS.EMAIL,
          value: 'shopify_orders_with_email',
        },
        {
          id: 3,
          name: this.$t(
            'GENERAL_SETTINGS.FORM.MANDATORY_FIELDS.' +
              MANDATORY_FIELDS.PHONE_AND_EMAIL
          ),
          key: MANDATORY_FIELDS.PHONE_AND_EMAIL,
          value: 'both',
        },
      ],
      selectedOption: {
        id: 1,
        name: 'Phone number is mandatory',
        key: 'PHONE',
        value: 'shopify_orders_with_phone',
      },
    };
  },
  computed: {
    selectedOptionName() {
      return this.selectedOption.name;
    },
    selectedSubControls: {
      get() {
        const {
          createOrderEnable: { otherSettings },
        } = this.controls;
        const selectedControls = [];
        Object.keys(otherSettings).forEach(setting => {
          if (otherSettings[setting].value) selectedControls.push(setting);
        });
        return selectedControls;
      },
      set(newVal) {
        return newVal;
      },
    },
  },
  created() {
    this.populateControls();
  },
  methods: {
    onActionSelected(option) {
      this.selectedOption = option;
    },
    populateControls() {
      this.$store.dispatch('accounts/get').then(response => {
        const {
          shopify_orders_create: canCreate,
          shopify_orders_cancel: canCancel,
          shopify_orders_refund: canRefund,
          shopify_orders_edit: canEdit,
          shopify_orders_payments: canPrepay,
          shopify_orders_cod: canCod,
          deep_shopify: isDeepShopifyEnabled,
        } = response.features;

        this.controls.createOrderEnable.value = canCreate;
        if (canCreate) this.fetchPaymentStatus();
        this.controls.cancelOrderEnable.value = canCancel;
        this.controls.refundOrderEnable.value = canRefund;
        this.controls.editOrderEnable.value = canEdit;
        this.controls.createOrderEnable.otherSettings.prepaidEnabled.value = canPrepay;
        this.controls.createOrderEnable.otherSettings.codEnabled.value = canCod;
        this.isDeepShopify = isDeepShopifyEnabled;

        this.setMandatoryOptions(response);
      });
    },
    setMandatoryOptions(response) {
      const {
        shopify_orders_with_phone: phoneMandatory,
        shopify_orders_with_email: emailMandatory,
      } = response.features;

      if (phoneMandatory && emailMandatory) {
        this.selectedOption = this.actionsList.find(
          option => option.key === 'PHONE_AND_EMAIL'
        );
      } else if (phoneMandatory) {
        this.selectedOption = this.actionsList.find(
          option => option.key === 'PHONE'
        );
      } else if (emailMandatory) {
        this.selectedOption = this.actionsList.find(
          option => option.key === 'EMAIL'
        );
      } else {
        this.selectedOption = this.actionsList[0];
      }
    },
    updateControls() {
      this.googleAnalyticsEvent(
        types.default.UPDATE_ACCOUNT,
        types.default.ACCOUNT_SETTINGS,
        types.default.ACCOUNT_SETTINGS
      );

      let updatePayload = {
        features: {
          deep_shopify: this.isDeepShopify,
          shopify_orders_create: this.controls.createOrderEnable.value,
          shopify_orders_cancel: this.controls.cancelOrderEnable.value,
          shopify_orders_refund: this.controls.refundOrderEnable.value,
          shopify_orders_edit: this.controls.editOrderEnable.value,
          shopify_orders_payments: this.controls.createOrderEnable.otherSettings
            .prepaidEnabled.value,
          shopify_orders_cod: this.controls.createOrderEnable.otherSettings
            .codEnabled.value,
        },
      };

      switch (this.selectedOption.key) {
        case 'PHONE':
          updatePayload.features.shopify_orders_with_phone = true;
          updatePayload.features.shopify_orders_with_email = false;
          break;

        case 'EMAIL':
          updatePayload.features.shopify_orders_with_phone = false;
          updatePayload.features.shopify_orders_with_email = true;
          break;

        case 'PHONE_AND_EMAIL':
          updatePayload.features.shopify_orders_with_phone = true;
          updatePayload.features.shopify_orders_with_email = true;
          break;

        default:
          updatePayload.features.shopify_orders_with_phone = false;
          updatePayload.features.shopify_orders_with_email = false;
      }

      this.$store
        .dispatch('accounts/update', updatePayload)
        .then(() =>
          this.showAlert(this.$t('DEEP_SHOPIFY.SAVE.SUCCESS'), 'success')
        )
        .catch(() =>
          this.showAlert(this.$t('GENERAL_SETTINGS.SAVE.ERROR'), 'error')
        );
    },
    getOtherSettings(control) {
      return this.controls[control].otherSettings;
    },
    arePaymentModesDisabled(control) {
      return (
        control === 'createOrderEnable' &&
        !this.getOtherSettings(control).prepaidEnabled.value &&
        !this.getOtherSettings(control).codEnabled.value
      );
    },
    toggleSelectedControl(control) {
      this.controls[control].value = !this.controls[control].value;

      if (
        this.controls[control].name === 'CREATE' &&
        this.controls[control].value
      )
        this.fetchPaymentStatus();

      // enable cod by default when createOrder enabled
      if (this.arePaymentModesDisabled(control))
        this.getOtherSettings(control).codEnabled.value = true;
    },
    handleSubControl({ target: { value: subControl } }, control) {
      this.getOtherSettings(control)[subControl].value = !this.getOtherSettings(
        control
      )[subControl].value;

      // check if prepaid and cod both are disabled then disable create mode
      if (this.arePaymentModesDisabled(control))
        this.toggleSelectedControl(control);
    },
    fetchPaymentStatus() {
      this.$store
        .dispatch('checkPaymentEnabled')
        .then(response => {
          this.canAccessPrepaid = response.status;
          if (!response.status) {
            this.controls.createOrderEnable.otherSettings.prepaidEnabled.value = false;
          }
        })
        .catch(() => {
          this.controls.createOrderEnable.otherSettings.prepaidEnabled.value = false;
          this.showAlert(this.$t('DEEP_SHOPIFY.PREPAY_STATUS.ERROR'), 'error');
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/widgets/buttons';

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.settings-box {
  padding-top: $space-largest;
  padding-left: 0.1rem;
  overflow: hidden;

  .lime-card-1dp {
    padding: $zero;
  }

  .settings-container {
    height: 100%;
    margin: $zero;

    .settings-sub-menu-content--larger {
      padding-top: $zero;
    }

    .shopify-settings {
      max-width: 72rem;

      .other-settings {
        gap: $space-large;

        .checkmark {
          cursor: pointer;
        }

        .warning-icon {
          margin: 2.4px $zero $zero $space-six;
        }
      }

      .other-settings-title {
        margin: $space-small $zero;
      }
    }
  }
}
.crm-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  .centered-image {
    height: 32px;
    width: 32px;
  }
}

hr {
  border-bottom: 1px solid $neutral-grey-300;
  margin: $space-two $zero $zero;
}
</style>
