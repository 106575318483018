<template>
  <page-header
    :header-title="$t('BOT_CSAT.CONTROLS.FLOWS.TITLE')"
    size="small"
    custom-class="grid-1-2-ratio"
  >
    <div class="csat-flow-grid">
      <label
        v-for="flow in Object.keys(flowsObject)"
        :key="flow"
        style="cursor: pointer"
        class="container-checkbox csat-flow"
      >
        <span
          class="title-h5_m text-dark label-text capitalize"
          v-text="formatFlowLabel(flow)"
        />
        <input
          type="checkbox"
          :value="flow"
          :checked="flowsObject[flow]"
          @input="
            $emit('updateConfig', {
              ...flowsObject,
              [flow]: !flowsObject[flow],
            })
          "
        />
        <span class="grey-checkmark checkmark" />
      </label>
    </div>
  </page-header>
</template>
<script>
import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';

export default {
  components: { PageHeader },
  props: {
    flowsObject: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    formatFlowLabel(flowName) {
      // Strip the prefix
      const prefix = 'CSAT_FLOW_TRIGGER_';
      let strippedLabel = flowName.startsWith(prefix)
        ? flowName.substring(prefix.length)
        : flowName;

      const words = strippedLabel.split('_').map(word => word.toLowerCase());

      const convertedLabel = words.join(' ');

      return convertedLabel;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.csat-flow-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: $space-normal;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .csat-flow .label-text {
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}
</style>
