/* eslint no-plusplus: 0 */
/* eslint-env browser */
import Avatar from './widgets/Avatar.vue';
import AvatarUploader from './widgets/forms/AvatarUploader.vue';
import Bar from './widgets/chart/BarChart';
import MultiBar from './widgets/chart/MultiBarChart';
import Line from './widgets/chart/LineChart.js';
import MultiLine from './widgets/chart/MultiLineChart';
import Heatmap from './widgets/chart/Heatmap';
import Button from './widgets/Button';
import ActionButton from './buttons/ActionButton';
import BaseButton from './buttons/BaseButton.vue';
import PrimaryButton from './buttons/PrimaryButton.vue';
import SecondaryButton from './buttons/SecondaryButton.vue';
import TertiaryButton from './buttons/TertiaryButton.vue';
import DropdownButton from './dropdowns/DropdownButton.vue';
import SingleSelector from './dropdowns/SingleSelector.vue';
import MultiSelector from './dropdowns/MultiSelector.vue';
import Code from './Code';
import Banner from './Banner';
import ColorPicker from './widgets/ColorPicker';
import DeleteModal from './widgets/modal/DeleteModal.vue';
import DeleteModalNew from './DeleteModalNew';
import Input from './widgets/forms/Input.vue';
import RadioButton from './widgets/forms/RadioButton.vue';
import Checkbox from './widgets/forms/Checkbox.vue';
import Label from './widgets/Label.vue';
import Chips from './widgets/Chips.vue';
import Badge from './widgets/Badge.vue';
import LoadingState from './widgets/LoadingState';
import Modal from './Modal';
import ModalNew from './ModalNew';
import ModalHeader from './ModalHeader';
import ModalHeaderNew from './ModalHeaderNew';
import Spinner from 'shared/components/Spinner';
import SubmitButton from './buttons/FormSubmitButton';
import Tabs from './ui/Tabs/Tabs';
import TabsItem from './ui/Tabs/TabsItem';
import FolderTabs from './ui/FolderTabs';
import TabSwitch from './ui/TabSwitch';
import Thumbnail from './widgets/Thumbnail.vue';
import TooltipIndicator from './TooltipIndicator.vue';
import HorizontalBar from './widgets/chart/HorizontalBarChart';
import Table from './widgets/Table';

const WootUIKit = {
  Avatar,
  AvatarUploader,
  MultiBar,
  Bar,
  MultiLine,
  Line,
  Heatmap,
  Button,
  ActionButton,
  BaseButton,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  DropdownButton,
  SingleSelector,
  MultiSelector,
  Code,
  Banner,
  ColorPicker,
  DeleteModal,
  DeleteModalNew,
  Input,
  RadioButton,
  Checkbox,
  LoadingState,
  Label,
  Chips,
  Badge,
  Modal,
  ModalNew,
  ModalHeader,
  ModalHeaderNew,
  HorizontalBar,
  Spinner,
  SubmitButton,
  Tabs,
  TabsItem,
  FolderTabs,
  TooltipIndicator,
  TabSwitch,
  Thumbnail,
  Table,
  install(Vue) {
    const keys = Object.keys(this);
    keys.pop(); // remove 'install' from keys
    let i = keys.length;
    while (i--) {
      Vue.component(`woot${keys[i]}`, this[keys[i]]);
    }
  },
};

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(WootUIKit);
}

export default WootUIKit;
