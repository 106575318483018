<template>
  <woot-single-selector
    ref="singleSelector"
    icon="sortSleek"
    :variant="variant"
    :option-list="filters"
    :show-selected-text="false"
    :default-option="getTitle"
    :button-style="{
      color: '#808975',
      ...buttonStyle,
    }"
    :custom-style="{
      width: 'auto',
      right: '0.1rem',
    }"
    @dropdown-opened="handleDropdownOpened"
    @click="handleSelectedOption"
  />
</template>

<script>
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  mixins: [googleAnalyticsMixin, mixPanelAnalyticsMixin],
  props: {
    variant: {
      type: String,
      default: 'secondary',
    },
    buttonStyle: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedFilter: { id: 1, name: 'Newest', value: '' },
      filters: [
        { id: 1, name: 'Newest', value: '' },
        { id: 2, name: 'Oldest', value: 'oldest' },
      ],
    };
  },
  computed: {
    getTitle() {
      return (
        "<b style='color: #6bac1b'>Sorted By: </b>" + this.selectedFilter.name
      );
    },
  },
  methods: {
    handleDropdownOpened() {
      this.mix_panel_clicked_ticket_sort();
    },
    handleSelectedOption(selectedOption) {
      this.mix_panel_sorted_tickets(selectedOption.value);
      this.selectedFilter = selectedOption;
      const filterName = 'sort';
      this.$emit('applyFilter', filterName, selectedOption.value);
      this.logFilterChange(selectedOption.value);
    },
  },
};
</script>
