<template>
  <modal
    :show.sync="show"
    :show-close="false"
    :custom-style="{ 'max-width': '50rem' }"
  >
    <woot-modal-header :header-title="title" :header-content="message" />
    <div class="modal-footer delete-item">
      <woot-tertiary-button
        :name="rejectText"
        size="small"
        :custom-style="{ color: '#FF422E' }"
        @click="onClose"
      />

      <woot-primary-button
        v-if="confirmText"
        front-icon="tickRound"
        size="small"
        :name="confirmText"
        @click="onConfirm"
      />
    </div>
  </modal>
</template>

<script>
import Modal from '../../Modal';

export default {
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    rejectText: {
      type: String,
      default: '',
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
