import FeedbackApi from 'dashboard/api/captureFeedback';
import { FEATURES_FOR_FEEDBACK, SOURCE_TYPES } from 'dashboard/constants';

async function submitFeedback(payload) {
  try {
    const { data } = await FeedbackApi.create(payload);
    return data;
  } catch (error) {
    return error;
  }
}

function generateFeedbackAction(featureName, sourceType) {
  return async (
    _,
    { account_id, source_id, feedback_message, feature_likability }
  ) => {
    const payload = {
      account_id,
      source_id,
      feedback_message,
      feature_likability,
      feature_name: featureName,
      source_type: sourceType,
    };

    const response = await submitFeedback(payload);
    return response;
  };
}

function getFeedbacks(sourceType) {
  return async (_, { account_id, source_id }) => {
    const params = {
      account_id,
      source_id,
      source_type: sourceType,
    };
    try {
      const response = await FeedbackApi.fetch(params);
      const in_app_feedbacks = response?.data?.data?.in_app_feedbacks || [];
      return in_app_feedbacks;
    } catch (error) {
      return error;
    }
  };
}

const actions = {
  getConversationFeedbacks: getFeedbacks(SOURCE_TYPES.CONVERSATION),
  submitFeedbackForAISummary: generateFeedbackAction(
    FEATURES_FOR_FEEDBACK.AI_SUMMARY,
    SOURCE_TYPES.CONVERSATION
  ),
  submitFeedbackForAccount: generateFeedbackAction(null, SOURCE_TYPES.ACCOUNT),
  submitFeedbackForUser: generateFeedbackAction(null, SOURCE_TYPES.USER),
};

export default actions;
