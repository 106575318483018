<template>
  <section class="conditions-section">
    <div class="columns filters-wrap">
      <label class="formlabel">
        <span
          class="title-h5 text-dark"
          v-text="$t('AUTOMATION.ADD.FORM.CONDITIONS.LABEL')"
        />
      </label>
      <condition-input
        v-for="(condition, idx) in conditions"
        :key="idx"
        v-model="conditions[idx]"
        :index="idx"
        :filter-attributes="getAttributes(eventName)"
        :input-type="getInputType(conditions[idx].attribute_key)"
        :label-key="getLabelKey(conditions[idx].attribute_key)"
        :operators="getOperators(conditions[idx].attribute_key)"
        :dropdown-values="
          getConditionDropdownValues(conditions[idx].attribute_key)
        "
        :show-query-operator="idx !== conditions.length - 1"
        :show-user-input="!!conditions[idx].attribute_key"
        @resetFilter="resetFilter(idx, conditions[idx])"
        @removeFilter="removeFilter(idx)"
      />
      <div v-if="!!lastCondition.attribute_key" class="filter-actions">
        <woot-base-button
          tag="span"
          size="small"
          variant="secondary"
          @click="appendCondition"
        >
          <icons
            name="plus"
            color="green"
            size="semimedium"
            :show-title="false"
            view="0 0 20 20"
          />
        </woot-base-button>
      </div>
    </div>
  </section>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import { MODES } from '../../../utils/constants';

import ConditionInput from './Input';

export default {
  components: {
    ConditionInput,
  },
  props: {
    conditions: {
      type: Array,
      default: () => [],
    },
    eventName: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: MODES.CREATE,
    },
  },
  validations() {
    return {
      conditions: {
        required,
        $each: {
          values: {
            required: requiredIf(prop => {
              return !(
                prop.filter_operator === 'is_present' ||
                prop.filter_operator === 'is_not_present'
              );
            }),
          },
        },
      },
    };
  },
  computed: {
    lastCondition() {
      const conditionsCpy = [...this.conditions];
      const lastIndex = conditionsCpy.length - 1;
      return conditionsCpy[lastIndex];
    },
  },
  methods: {
    appendCondition() {
      this.$emit('appendCondition');
    },
    resetFilter(index, condition) {
      this.$emit('resetFilter', index, condition);
    },
    removeFilter(index) {
      this.$emit('removeFilter', index);
    },
    getAttributes(key) {
      return this.$parent.getAttributes(key);
    },
    getInputType(key) {
      return this.$parent.getInputType(key);
    },
    getLabelKey(key) {
      return this.$parent.getLabelKey(key);
    },
    getOperators(key) {
      return this.$parent.getOperators(key);
    },
    getConditionDropdownValues(type) {
      return this.$parent.getConditionDropdownValues(type);
    },
  },
};
</script>
