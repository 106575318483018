<template>
  <div class="gap--normal" :class="computedClass">
    <div class="flex-row gap--two">
      <slot name="icon" />
      <div>
        <div class="flex-column">
          <div
            class="page-subtitle text-dark flex-row gap--slab"
            :class="{ 'mg-bottom--small': headerContent.trim() }"
          >
            {{ headerTitle }}
            <span v-if="infoText" v-tooltip="infoText">
              <icons
                name="info"
                color="green"
                size="normal"
                :show-title="false"
              />
            </span>
          </div>
          <span
            class="page-subtitle small-12 column body-b3 text-light"
            v-html="headerContent"
          />
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    showBorder: {
      type: Boolean,
      default: true,
    },
    headerTitle: {
      type: String,
      default: '',
    },
    headerContent: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'large',
    },
    customClass: {
      type: String,
      default: '',
    },
    infoText: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedClass() {
      let baseClass = 'page-header-container flex-align';

      if (this.showBorder) baseClass += ' border-bottom';

      return `${baseClass} page-header-container--${this.size} ${this.customClass}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/_typography';

.page-header-container {
  display: grid;
  grid-template-columns: 1fr auto;

  padding: $space-medium;
  padding-bottom: $space-slab;

  &--small {
    @extend .title-h5;
    padding: $space-medium $zero;
  }

  &--medium {
    @extend .title-h4;
    padding-top: $space-normal;
  }

  &--large {
    @extend .title-h1;
  }
}

.page-subtitle {
  max-width: $space-six * 100;
}

.border-bottom {
  border-bottom: 1px solid $neutral-grey-300;
}
</style>
