<template>
  <div class="last-updated-container">
    <div v-if="isLoading" class="loading skeleton-animation" />
    <div v-else class="title-h5_m text-light last-updated-text">
      {{ formattedLastUpdated }}
    </div>
  </div>
</template>

<script>
import { humanReadableDate } from 'dashboard/helper/time';
import AnalyticsApi from 'dashboard/api/limekit/analytics';

export default {
  name: 'LastUpdatedTime',
  props: {
    scope: {
      type: String,
      required: true,
      validator: value => ['tickets', 'agents'].indexOf(value) !== -1,
    },
  },
  data() {
    return {
      lastUpdatedAt: '',
      isLoading: true,
    };
  },
  computed: {
    formattedLastUpdated() {
      if (this.isLoading) return '';
      if (
        !this.lastUpdatedAt ||
        this.lastUpdatedAt === 0 ||
        this.lastUpdatedAt.getFullYear() === 1970
      )
        return 'Last updated: N/A';

      const readableTime = humanReadableDate(this.lastUpdatedAt);

      return `Last updated: ${readableTime}`;
    },
  },
  mounted() {
    this.fetchLastUpdatedTime();
  },
  methods: {
    async fetchLastUpdatedTime() {
      this.isLoading = true;
      try {
        const response = await AnalyticsApi.getLastUpdatedAt(this.scope);
        this.lastUpdatedAt = new Date(response.data.last_updated_at[0]);
      } catch (error) {
        // handle error
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.last-updated-container {
  position: relative;
  top: $space-medium;
  width: 100%;
}

.loading {
  border-radius: $border-radius-smaller;
  height: $height-h5;
  width: $space-two * 10;
}
</style>
