<template>
  <div class="columns conversation-wrap">
    <messages-view v-if="currentChat.id" />
    <empty-state v-else />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import EmptyState from './EmptyState';
import MessagesView from './MessagesView';

export default {
  components: {
    EmptyState,
    MessagesView,
  },

  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
  },
};
</script>
