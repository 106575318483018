<template>
  <div class="settings-box shopify-container">
    <div class="shopify-body-container">
      <div class="shopify-image shopify-image-margin">
        <img :src="'/dashboard/images/integrations/shopify.svg'" />
      </div>
      <p class="heading">
        {{ $t('INTEGRATION_SETTINGS.SHOPIFY.TITLE') }}
      </p>

      <p class="steps">
        {{ $t('INTEGRATION_SETTINGS.SHOPIFY.SUB_TEXT_ONE') }}
      </p>

      <p class="steps">
        {{ $t('INTEGRATION_SETTINGS.SHOPIFY.SUB_TEXT_TWO') }}
      </p>

      <form
        class="shopify-form"
        @submit.prevent="$emit('shopify-connect', shopifyDomain)"
      >
        <woot-input
          v-model.trim="shopifyDomain"
          :class="{ error: $v.shopifyDomain.$error }"
          class="shopify-input"
          :placeholder="'Shopify Domain Name'"
          @input="$v.shopifyDomain.$touch"
        />
        <woot-button
          type="submit"
          class="button nice shopify-button"
          :disabled="$v.shopifyDomain.$invalid"
        >
          {{ $t('INTEGRATION_SETTINGS.CONNECT.BUTTON_TEXT') }}
        </woot-button>
      </form>
      <p class="steps question">
        {{ $t('INTEGRATION_SETTINGS.SHOPIFY.NO_CODE_TXT') }}
      </p>
      <a
        href="https://apps.shopify.com/limechat-shop"
        target="_blank"
        class="steps limechat-store"
      >
        <icons name="rocketFilled" size="medium" color="green" />
        <span class="text-margin">{{
          $t('INTEGRATION_SETTINGS.SHOPIFY.LIMECHAT_APP')
        }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [alertMixin],
  data() {
    return {
      shopifyDomain: '',
    };
  },
  validations: {
    shopifyDomain: {
      required,
    },
  },
  methods: {
    shopifyConnect() {
      this.$store
        .dispatch('updateShopifyCode', {
          dashboard_token: this.shopifyCode,
        })
        .then(response => {
          if (response) this.showAlert('Shopify Connected', 'success');
          else this.showAlert('Not a valid token', 'error');
        })
        .catch(() => {
          this.showAlert('Not a valid token', 'error');
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.shopify-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.shopify-body-container {
  width: 80%;
  padding: 10%;
}

.shopify-image {
  height: 80px;
  width: 68px;
}

.shopify-image-margin {
  margin: $zero auto;
}

.heading {
  text-align: center;
  font-size: $font-size-medium;
  margin: $space-normal $zero;
  font-weight: $font-weight-medium;
}

.steps {
  text-align: center;
  font-size: $font-size-small;
  margin: $space-micro $space-larger;
}

.question {
  color: $neutral-grey-600;
  margin: $space-two $space-larger;
}

.limechat-store {
  color: $pg-1-500;
  margin: $space-two $space-larger;
  display: flex;
  justify-content: center;
}

.text-margin {
  margin: $space-micro;
}

.shopify-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shopify-button {
  padding: $space-slab $space-larger;
}

.shopify-input {
  margin-top: $space-two;
  margin-bottom: $space-two;
  width: 300px;
}
</style>
