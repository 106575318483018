<template>
  <div
    class="column padding-container settings-box custom-scroll"
    style="padding-top: 0px"
  >
    <div class="filter-pane">
      <div class="flex-space-between" style="padding-top: 0.6rem">
        <div class="flex-row-justify-center analytics-filters">
          <date-range-selector
            v-if="currentUserId"
            :default-selection="2"
            @date-range-change="onDateRangeChange"
          />
          <inbox-filter
            value="All Inboxes"
            :allow-multi-select="true"
            :toggle-filter="changeInboxId"
            :exclude-inbox-types="excludedInboxTypes"
            @selected-option="logSelectedInbox"
          />
          <tags-filter
            value="All Tags"
            default-key="title"
            :toggle-filter="changeLabelId"
            @selected-option="logSelectedTag"
          />
          <woot-single-selector
            variant="secondary"
            size="medium"
            :disabled="false"
            :option-list="optionList"
            :default-option="selectedWorkingHour"
            :show-selected-text="false"
            :custom-style="{ width: 'auto' }"
            @click="changeWorkingHourType"
          />
        </div>
      </div>
    </div>
    <div class="column settings-box custom-scroll" style="padding-top: 0px">
      <div class="settings-container columns custom-scroll">
        <div class="metrics-container mg-bottom">
          <performance-conv-states
            v-if="!convStateUIFlag"
            :key="JSON.stringify({ ...agentConvStates, startDate, endDate })"
            :selected-date-range="{ startDate, endDate }"
            :agent-conv-states="agentConvStates"
          />
          <metrics-card-skeleton v-else style="height: 100%" />
          <div class="w-100">
            <div v-if="metricCardUIFlag" class="metrics-card-container">
              <metrics-card-skeleton
                v-for="(item, index) in metricsCount"
                :key="index"
              />
            </div>
            <div v-else class="metrics-card-container">
              <metrics-card
                v-for="(item, index) in metricsCount"
                :key="index"
                :title="item.title"
                :sub-title="item.sub"
                :color="item.color"
                :actual="item.actual"
              >
                <div v-if="item.extra" class="title-h5_m average-metric">
                  <span class="text-light">Avg: </span>
                  <span class="text-dark" v-html="item.extra" />
                </div>
              </metrics-card>
            </div>
          </div>
        </div>
        <div v-if="periodicChartUIFlag">
          <line-chart-skeleton />
        </div>
        <div v-else class="medium-12 bottom-container">
          <div class="lime-card-1dp medium-12">
            <div style="position: relative">
              <span class="table-title">Periodic Agent Performance</span>
              <line-chart
                :series="chartSeries"
                :categories="chartCategories"
                :colors="chartColors"
                :toolbar-enable="false"
                label-append="Min"
              />
            </div>
          </div>
        </div>

        <div v-if="hourlyUIFlag">
          <line-chart-skeleton />
          <div class="flex-space-between">
            <div style="width: 49%">
              <line-chart-skeleton />
            </div>
            <div style="width: 49%">
              <line-chart-skeleton />
            </div>
          </div>
        </div>
        <div v-else class="lime-card-1dp medium-12 bottom-container">
          <div class="flex-space-between" style="height: 40px">
            <span class="table-title">Hourly Performance</span>
          </div>
          <column-chart
            :series="columnChartSeries"
            :categories="columnChartCategories"
            :colors="columnChartColors"
          />
          <div class="chart-card mg-bottom--medium">
            <div class="flex-space-between">
              <span class="table-title smaller-font">
                First Response Time
              </span>
              <div
                v-tooltip="
                  'First Response time indicates the time taken by the agent to respond to a chat'
                "
              >
                <icons name="info" size="medium" color="grey" />
              </div>
            </div>
            <line-chart
              class="chartOverflowVisible"
              :series="firstResponseTimeSeries"
              :categories="firstResponseTimeCategories"
              :colors="firstResponseChartColors"
              :toolbar-enable="false"
              :show-tooltip="true"
              :width="510"
              label-append="Min"
            />
          </div>
          <div class="chart-card">
            <div class="flex-space-between">
              <span class="table-title smaller-font">Unresolved Tickets</span>

              <div
                v-tooltip="
                  'Tickets which are unresolved in that time for the selected agent'
                "
              >
                <icons name="info" size="medium" color="grey" />
              </div>
            </div>
            <line-chart
              class="chartOverflowVisible"
              :series="pendingChatSeries"
              :categories="pendingChatCategories"
              :colors="pendingChatChartColors"
              :toolbar-enable="false"
              :show-tooltip="true"
              :width="510"
              label-append="Min"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import LineChartSkeleton from 'dashboard/components/LineChartSkeleton';
import MetricsCardSkeleton from 'dashboard/routes/dashboard/analytics/components/MetricsCardSkeleton';
import MetricsCard from 'dashboard/routes/dashboard/analytics/components/MetricsCard';
import LineChart from 'dashboard/components/widgets/chart/LineChart';
import ColumnChart from 'dashboard/components/widgets/chart/ColumnChart';
import DateRangeSelector from 'dashboard/routes/dashboard/analytics/components/DateRangeSelector';
import PerformanceConvStates from '../components/PerformanceConvStates';
import InboxFilter from 'dashboard/components/widgets/conversation/filter/InboxFilter';
import TagsFilter from 'dashboard/components/widgets/conversation/filter/TagsFilter';

import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import alertMixin from 'shared/mixins/alertMixin';
import timeMixin from 'dashboard/mixins/time';

import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

export default {
  components: {
    MetricsCard,
    LineChart,
    ColumnChart,
    DateRangeSelector,
    LineChartSkeleton,
    MetricsCardSkeleton,
    PerformanceConvStates,
    InboxFilter,
    TagsFilter,
  },
  mixins: [alertMixin, timeMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      dateRange: '1',
      startDate: this.formatDate(new Date().getTime()).replaceAll('-', '/'),
      endDate: this.formatDate(new Date().getTime()).replaceAll('-', '/'),
      agentPerformanceMetrics: null,
      averageAgentPerformanceMetrics: null,
      agentLineGraphData: null,
      agentChartsData: null,
      agentConvStates: {},
      messageBlockChartData: null,
      working_hour_type: [
        {
          NAME: 'All Time             ',
          KEY: 'all',
        },
        {
          NAME: 'Outside Working Hours',
          KEY: 'outside',
        },
        {
          NAME: 'Inside Working Hours',
          KEY: 'inside',
        },
      ],
      selectedInboxes: [],
      selectedLabels: [],
      selectedWorkingHourType: 'all',
    };
  },
  computed: {
    ...mapGetters({
      currentUserId: 'getCurrentUserID',
      inboxes: 'inboxes/getInboxes',
      accountLabels: 'labels/getLabelsWithoutGroups',
      metricCardUIFlag: 'getPerformanceMetricUIFlag',
      periodicChartUIFlag: 'getPeriodicPerformanceUIFlag',
      hourlyUIFlag: 'getHourlyPerformanceUIFlag',
      convStateUIFlag: 'getAgentConversationStateUIFlag',
    }),
    headerList() {
      return this.$t('ANALYTICS.ATTENDANCE_HEADER');
    },
    excludedInboxTypes() {
      return [INBOX_TYPES.KNOWLARITY];
    },
    optionList() {
      return this.working_hour_type.map(item => {
        return {
          key: item.KEY,
          name: item.NAME,
        };
      });
    },
    selectedWorkingHour() {
      let selectedIndex = this.optionList.findIndex(
        a => a.key === this.selectedWorkingHourType
      );
      return selectedIndex > 0
        ? this.optionList[selectedIndex].name
        : this.optionList[0].name;
    },
    metricsCount() {
      const createMetric = (key, sub) => {
        const value = this.agentPerformanceMetrics?.[key];
        const averageValue = this.averageAgentPerformanceMetrics?.[key];
        return {
          title: value ? this.formatMetricWithUnits(value) : 0,
          sub,
          actual: this.$t(
            `ANALYTICS.TOOLTIP.AGENT_PERFORMANCE.${key.toUpperCase()}`
          ),
          extra: this.formatMetricWithUnits(averageValue) || 0,
        };
      };

      return [
        createMetric('frt', 'FRT'),
        createMetric('resolution_time', 'Res. Time'),
        createMetric('wait_time', 'Wait Time'),
        {
          title: this.messageBlockChartData || 0,
          sub: 'Message block Sent',
          actual: this.$t('ANALYTICS.TOOLTIP.AGENT_PERFORMANCE.MESSAGE_BLOCK'),
        },
      ];
    },
    chartSeries() {
      return this.agentLineGraphData?.yaxis;
    },
    chartCategories() {
      if (this.startDate === this.endDate) {
        if (this.agentLineGraphData?.xaxis) {
          return this.agentLineGraphData?.xaxis.map(x =>
            this.timeToAMPM(x.substr(x.length - 8))
          );
        }
        return this.agentLineGraphData?.xaxis?.map(date =>
          this.dateToWords(date)
        );
      }
      return this.agentLineGraphData?.xaxis?.map(date =>
        this.dateToWords(date)
      );
    },
    chartColors() {
      return ['#6BAC1B', '#1B83AC', '#9270CA', '#5D7092'];
    },
    columnChartSeries() {
      let formattedSeries = [
        {
          name: 'Assigned Ticket',
          data: this.agentChartsData?.assigned_resolved_conv_data?.yaxis.filter(
            item => item.name === 'Assigned Conversation'
          )[0].data,
        },
        {
          name: 'Resolved Ticket',
          data: this.agentChartsData?.assigned_resolved_conv_data?.yaxis.filter(
            item => item.name === 'Resolved Conversation'
          )[0].data,
        },
      ];

      return formattedSeries || [];
    },
    columnChartColors() {
      return ['#6BAC1B', '#1B83AC'];
    },
    columnChartCategories() {
      return this.agentChartsData?.assigned_resolved_conv_data?.xaxis.map(
        time => this.timeRangeToAMPMRange(time)
      );
    },
    firstResponseTimeSeries() {
      return [
        {
          name: 'First Response Time',
          data: this.agentChartsData?.frt?.yaxis.map(yaxis => yaxis.toFixed(2)),
        },
      ];
    },
    firstResponseTimeCategories() {
      if (this.agentChartsData?.frt?.xaxis)
        return this.agentChartsData?.frt?.xaxis.map(range =>
          this.timeRangeToAMPMRange(range)
        );
      return [];
    },
    firstResponseChartColors() {
      return ['#6BAC1B'];
    },
    pendingChatSeries() {
      return [
        {
          name: 'Unresolved Tickets',
          data: this.agentChartsData?.pending_chats?.yaxis,
        },
      ];
    },
    pendingChatCategories() {
      if (this.agentChartsData?.pending_chats?.xaxis)
        return this.agentChartsData?.pending_chats?.xaxis.map(range =>
          this.timeRangeToAMPMRange(range)
        );
      return [];
    },
    pendingChatChartColors() {
      return ['#1B83AC'];
    },
  },
  created() {
    this.getMetrics();
  },
  methods: {
    formatMetricWithUnits(metric) {
      return this.formattedDuration(
        this.secondsToDuration(parseInt(metric * 60, 10))
      );
    },
    changeLabelId(_, __, id) {
      this.selectedLabels = id;
      this.getAllData();
    },
    onDateRangeChange({ from, to, date_range_name, isFiredOnMount }) {
      this.startDate = this.formatDate(from * 1000).replaceAll('-', '/');
      this.endDate = this.formatDate(to * 1000).replaceAll('-', '/');
      this.getAllData();
      if (!isFiredOnMount) {
        this.mix_panel_clicked_date_filter_menu_item(
          'agent_performance',
          date_range_name
        );
      }
    },
    changeWorkingHourType(val) {
      this.selectedWorkingHourType = val.key;
      this.getAllData();
      this.mix_panel_clicked_working_hours_menu_item(
        'agent_performance',
        val.key
      );
    },
    nFormatter(num) {
      if (num > 9999 && num < 1_000_000) {
        return (num / 1000).toFixed(1) + 'K';
      }
      if (num > 1000000) {
        return (num / 1000000).toFixed(0) + 'M';
      }
      if (num < 9999) {
        return Math.round(num * 10) / 10;
      }
      return Math.round(num * 10) / 10;
    },
    changeInboxId(_, __, id) {
      this.selectedInboxes = id;
      this.getAllData();
    },
    logSelectedInbox(selectedOptionValue) {
      const filteredInbox = this.inboxes.find(
        inbox => inbox.name === selectedOptionValue
      );

      this.mix_panel_clicked_inbox_filter_menu_item(
        'agent_performance',
        filteredInbox.channel_type
      );
    },
    logSelectedTag(selectedOptionValue) {
      const filteredInbox = this.accountLabels.find(
        tag => tag.title === selectedOptionValue
      );

      this.mix_panel_clicked_tag_filter_menu_item(
        'agent_performance',
        filteredInbox.label_type
      );
    },
    getAllData() {
      this.getMetrics();
      this.getGraphData();
      this.getHourlyData();
      this.getConvStates();
      this.getAgentMessageBlock();
    },
    async getMetrics() {
      await this.$store
        .dispatch('getAgentPerformanceStats', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          agentId: this.currentUserId,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          if (!this.currentUserId) this.averageAgentPerformanceMetrics = data;
          this.agentPerformanceMetrics = data;

          this.agentConvStates.averageHandlingTime = this.agentPerformanceMetrics.average_handling_time;
        });
    },
    async getGraphData() {
      this.$store
        .dispatch('getPeriodicAgentsPerformance', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          agentId: this.currentUserId,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          this.agentLineGraphData = data;
        });
    },
    async getHourlyData() {
      this.$store
        .dispatch('getAgentHourlyPerformance', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          agentId: this.currentUserId,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          this.agentChartsData = data;
        });
    },
    async getAgentMessageBlock() {
      this.$store
        .dispatch('getAgentPerformanceMessageBlocks', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          agentId: this.currentUserId,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          this.messageBlockChartData = data.agent_turns[0];
        });
    },
    async getConvStates() {
      this.$store
        .dispatch('getAgentWiseConvStats', {
          startDate: this.startDate,
          endDate: this.endDate,
          inboxes: this.selectedInboxes,
          labels: this.selectedLabels,
          agentId: this.currentUserId,
          workingHours: this.selectedWorkingHourType,
        })
        .then(data => {
          if (data.length > 0) {
            [data] = data;
            this.agentConvStates = data;
            this.agentConvStates.averageHandledTickets =
              data.avg_tickets_handled;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.chart-card {
  border: 1px solid $neutral-grey-400;
  border-radius: $border-radius;
  padding: $space-one $space-medium;
}

.filter-pane {
  position: -webkit-sticky;
  position: sticky;
  background-color: #faf9f5;
  top: $zero;
  z-index: 1000;
  padding: $zero $zero $space-normal;
}

.metrics-container {
  padding: 0 $space-micro;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: $space-normal;
}

.metrics-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $space-normal;
  min-height: $space-large * 10;

  .metrics-card {
    margin-right: $zero;
  }

  .average-metric {
    position: absolute;
    right: $space-medium;
    bottom: $space-normal;
    background: $color-white;
  }
}

.bottom-container {
  display: flex;
  flex-direction: column;
  margin: $zero $space-micro $space-normal;
}

.analytics-filters {
  justify-content: flex-start !important;
  max-width: 90%;
  flex-wrap: wrap;
  gap: $space-slab;
}

.table-title {
  font-size: $font-size-medium;
  line-height: 2.6rem;
  color: $neutral-grey-800;
  font-weight: $font-weight-medium;
  margin: $space-slab $zero;
}

.tooltip-position {
  position: absolute;
  top: $zero;
  right: $zero;
}

.smaller-font {
  font-size: $font-size-small;
}

.chartOverflowVisible::v-deep .apexcharts-svg {
  overflow: visible;
}
</style>
