<template>
  <woot-single-selector
    size="small"
    front-icon="people"
    :custom-style="computedCustomStyle"
    :button-style="buttonStyle"
    :show-selected-text="false"
    :default-option="selectedTeamComputed"
    :option-list="modifiedTeamList"
    :highlight-selected="highlightTeamName"
    @click="onSelectTeam"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    selectedTeamId: {
      type: [String, Number],
      default: '',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    toggleFilter: {
      type: Function,
      default: () => {},
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedTeam: { id: 0, name: 'All Teams' },
    };
  },
  computed: {
    ...mapGetters({
      teamList: 'teams/getTeams',
    }),
    computedCustomStyle() {
      return {
        left: 0,
        top: 0,
        width: '18rem',
        ...this.customStyle,
      };
    },
    buttonStyle() {
      return {
        color: '#808975',
        padding: '0.4rem 0.6rem',
      };
    },
    selectedTeamComputed() {
      return (
        this.modifiedTeamList.find(team => team.id === this.selectedTeamId) ||
        'All Teams'
      );
    },
    modifiedTeamList() {
      return [{ id: 0, name: 'All Teams' }, ...this.teamList];
    },
    highlightTeamName() {
      const teamName = this.selectedTeamComputed.name;
      return !!teamName && teamName !== 'All Teams';
    },
  },
  methods: {
    onSelectTeam(val) {
      this.selectedTeam = val;

      if (this.selectedTeam.id === 0) {
        this.toggleFilter(this.item.key, false, null);
      } else {
        this.toggleFilter(this.item.key, true, val.id);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.labels-container {
  .filter--button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: $space-smaller $space-six;
    border: 1px solid $neutral-grey-500;
    border-radius: $border-radius-smaller;
    cursor: pointer;
    white-space: nowrap;

    &:focus {
      box-shadow: none;
      outline: none;
    }

    .filter--text {
      color: $text-light;
      font-size: $font-size-small;
      line-height: 20px;
      padding: $zero $space-micro;
      user-select: none;
    }
  }

  .button--active {
    border: 1px solid $pg-1-500;

    .filter--text {
      color: $pg-1-500;
    }
  }

  .label-dropdown {
    @include shadow-medium;
    border-radius: $border-radius * 2;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid $neutral-grey-400;
    background: $neutral-white;
    width: calc(100% + 2.4rem);
    padding: $zero;
    min-width: 9rem;
    max-width: 15rem;
    max-height: 30rem;
    position: absolute;
    width: auto;
    overflow: auto;
    margin: $zero;
    z-index: 100;

    &::-webkit-scrollbar {
      transition: all 0.5s;
      width: 0.4em;
      height: 0.1em;
      z-index: 10;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfbfbf;
      border-radius: 0.1em;
    }

    .action--button {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      white-space: nowrap;
      padding: $space-small $space-slab;
      background-color: transparent;
      border-color: transparent;
      border: $zero;

      .label-text {
        font-size: $font-size-mini;
        text-transform: capitalize;
        color: $text-dark;
        line-height: 22px;
      }

      .label-color {
        border-radius: $space-smaller;
        height: $space-normal;
        margin-right: $space-smaller;
        min-width: $space-normal;
        width: $space-normal;
      }

      &:hover {
        background: $neutral-grey-300;
      }
    }

    .is-selected {
      background: $pg-light-50;
      color: $color-white;
      pointer-events: none;
    }
  }
}
</style>
