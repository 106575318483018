<template>
  <div class="column content-box settings-box shopify-container">
    <div class="shopify-body-container">
      <div class="image-container">
        <div class="shopify-image">
          <img :src="'/dashboard/images/integrations/shopify.svg'" />
        </div>
        <div>
          <icons name="add" size="large" color="#434343" />
        </div>
        <div>
          <img
            class="shopify-image"
            :src="'/brand-assets/logo_thumbnail.svg'"
          />
        </div>
      </div>
      <p class="connected-success-text connected-text">
        {{ $t('INTEGRATION_SETTINGS.SHOPIFY.CONNECT_SUCCESS') }}
      </p>
      <p class="connected-text">
        {{ $t('INTEGRATION_SETTINGS.SHOPIFY.STORE_NAME') }}
      </p>
      <a :href="formattedUrl" target="_blank">
        <woot-button
          type="submit"
          class="button nice hollow go-to-shopify-button"
        >
          <icons name="rocketFilled" size="medium" color="green" />
          <span>
            {{ $t('INTEGRATION_SETTINGS.SHOPIFY.GO_TO_STORE_TXT') }}
          </span>
        </woot-button>
      </a>
      <div class="remove-connection shopify-form" @click="onClick">
        {{ $t('INTEGRATION_SETTINGS.SHOPIFY.REMOVE_CON') }}
      </div>

      <woot-modal :show.sync="show" :on-close="onClose">
        <div class="delete-modal">
          <p class="confirm-text modal-typography">
            {{ $t('INTEGRATION_SETTINGS.SHOPIFY.CONFIRM') }}
          </p>
          <p class="modal-typography">
            {{ $t('INTEGRATION_SETTINGS.SHOPIFY.CONFIRM_TXT') }}
          </p>
          <div style="display: flex; justify-content: flex-end">
            <woot-button
              class="button hollow link"
              style="width: auto; font-size: 13px"
              @click="onClose"
            >
              <span class="modal-typography">
                {{ $t('INTEGRATION_SETTINGS.SHOPIFY.KEEP_INTGRETION_TXT') }}
              </span>
            </woot-button>

            <woot-button
              class="button hollow red cancel"
              style="
                width: auto;
                color: #ff7e61;
                border: 1px solid #ff7e61;
                font-size: 13px;
              "
              @click="shopifyDelete(domain)"
            >
              <span class="modal-typography">
                {{ $t('INTEGRATION_SETTINGS.SHOPIFY.REMOVE_INTGRETION_TXT') }}
              </span>
            </woot-button>
          </div>
        </div>
      </woot-modal>
    </div>
  </div>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    domain: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      connectionStatus: false,
    };
  },
  computed: {
    formattedUrl() {
      if (this.domain) {
        if (
          this.domain.indexOf('http://') === 0 ||
          this.domain.indexOf('https://') === 0
        )
          return this.domain;
        return 'https://' + this.domain;
      }
      return '';
    },
  },
  mounted() {
    this.$store.dispatch('goToShopifyStore').then(response => {
      this.connectionStatus = response.status;
      this.domain = response.shopify;
    });
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    },
    shopifyDelete() {
      this.$store
        .dispatch('deleteShopifyIntegration', {
          domain: this.domain,
        })
        .then(response => {
          if (response) this.showAlert('Integration Deleted', 'info');
          else this.showAlert('Could not Delete the integration', 'error');
        })
        .catch(() => {
          this.showAlert('Could not Delete the integration', 'error');
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.shopify-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.shopify-image {
  height: 80px;
  width: 68px;
}

.union-image {
  height: 55px;
  width: 55px;
}

.connected-text {
  text-align: center;
  font-size: $font-size-big;
  font-weight: $font-weight-normal;
}
.connected-success-text {
  color: $pg-1-500;
  margin: $space-large $zero;
  font-size: $font-size-big;
}

.image-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: $zero $space-two;
}

.shopify-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.go-to-shopify-button {
  width: auto;
  margin: $space-large auto;
  display: flex;
  justify-content: center;
  font-size: $font-size-small;
}

.remove-connection {
  color: $r-500;
  font-size: $font-size-small;
  cursor: pointer;
}

.delete-modal {
  padding: $space-slab $space-medium;
}

.confirm-text {
  color: $secondary-blue;
}

.modal-typography {
  font-size: $font-size-small;
}
</style>
