<template>
  <div class="column padding-container settings-box custom-scroll">
    <div class="filter-pane">
      <date-range-selector
        :default-selection="2"
        @date-range-change="onDateRangeChange"
      />
    </div>
    <div v-if="fetchingSalesData" class="metrics-card-container">
      <metrics-card-skeleton
        v-for="(item, index) in metricsCount"
        :key="index"
      />
    </div>
    <div v-else class="metrics-card-container">
      <metrics-card
        v-for="(item, index) in metricsCount"
        :key="index"
        :title="item.title"
        :sub-title="item.sub"
        :actual="item.actual"
      />
    </div>
    <line-chart-skeleton v-if="fetchingSalesData" chart-height="16rem" />
    <div v-else class="lime-card-1dp medium-12">
      <div class="flex-space-between">
        <div />
        <div class="flex-row flex-align flex-justify--between">
          <woot-single-selector
            :option-list="options"
            :default-option="selectedChart.name"
            :custom-style="{ 'z-index': '10000' }"
            :show-selected-text="false"
            front-icon="filterHollow"
            @click="changeChart"
          />
        </div>
      </div>
      <line-chart
        :series="selectedChartData"
        :categories="xAxis"
        :colors="SALES_LINE_CHART_COLOR"
        :show-marker="false"
        :toggle-data="false"
        :highlight-data="false"
      />
    </div>
    <pie-chart-skeleton v-if="fetchingSalesData" chart-height="20rem" />
    <div
      v-else
      class="lime-card-1dp mg-top mg-bottom"
      style="width: 50%; height: 40rem"
    >
      <pie-chart
        title="Influenced Revenue Breakdown"
        :series="influencedSalesBreakdownValues"
        :colors="INFLUENCED_SALES_PIE_CHART_COLORS"
        :labels="influencedSalesBreakdownLabels"
        :height="350"
        width="100%"
        legend-position="bottom"
        :legend-offset-x="0"
        :legend-offset-y="0"
        :marker-offset-x="0"
        :market-offset-y="0"
        :chart-offset-x="-10"
        :chart-offset-y="10"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import MetricsCard from '../components/MetricsCard';
import LineChart from 'dashboard/components/widgets/chart/LineChart';
import PieChart from 'dashboard/components/widgets/chart/PieChart';
import MetricsCardSkeleton from 'dashboard/routes/dashboard/analytics/components/MetricsCardSkeleton';
import LineChartSkeleton from 'dashboard/components/LineChartSkeleton';
import PieChartSkeleton from 'dashboard/routes/dashboard/analytics/components/PieChartSkeleton';
import DateRangeSelector from 'dashboard/routes/dashboard/analytics/components/DateRangeSelector';

import timeMixin from 'dashboard/mixins/time';
import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import {
  SALES_SCOPES,
  INFLUENCED_SALES_PIE_CHART_COLORS,
  SALES_LINE_CHART_COLOR,
} from '../constants';

export default {
  components: {
    MetricsCard,
    LineChart,
    PieChart,
    MetricsCardSkeleton,
    LineChartSkeleton,
    PieChartSkeleton,
    DateRangeSelector,
  },
  mixins: [timeMixin, alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      startDate: this.formatDate(new Date().getTime()).replaceAll('-', '/'),
      endDate: this.formatDate(new Date().getTime()).replaceAll('-', '/'),
      INFLUENCED_SALES_PIE_CHART_COLORS,
      SALES_LINE_CHART_COLOR,
      options: [
        {
          key: 'bot_sales',
          name: this.$t('ANALYTICS.SALES_ANALYTICS.CHART.BOT_SALES'),
        },
        {
          key: 'outbound_sales',
          name: this.$t('ANALYTICS.SALES_ANALYTICS.CHART.OUTBOUND_SALES'),
        },
        {
          key: 'influenced_sales',
          name: this.$t('ANALYTICS.SALES_ANALYTICS.CHART.INFLUENCED_SALES'),
        },
      ],
      selectedChart: {
        key: 'influenced_sales',
        name: this.$t('ANALYTICS.SALES_ANALYTICS.CHART.INFLUENCED_SALES'),
      },
      dates: {},
      sales: {
        bot_sales: {},
        outbound_sales: {},
        influenced_sales: {},
      },
      revenue: {
        total_bot_revenue: 0,
        total_outbound_revenue: 0,
        total_influenced_revenue: 0,
      },
      influencedByType: {
        agent: 0,
        bot: 0,
        outbound: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      fetchingSalesData: 'getSalesUIFlag',
    }),
    selectedChartData() {
      return [
        this.shapeData(
          this.sales[this.selectedChart.key],
          this.selectedChart.name
        ),
      ];
    },
    xAxis() {
      return Object.values(this.dates).map(d => this.dateToWords(d));
    },
    influencedSalesBreakdownLabels() {
      return Object.keys(this.influencedByType);
    },
    influencedSalesBreakdownValues() {
      return Object.values(this.influencedByType);
    },
    metricsCount() {
      const {
        total_bot_revenue,
        total_outbound_revenue,
        total_influenced_revenue,
      } = this.revenue;

      return [
        {
          title: this.getMetricTitle(total_bot_revenue),
          sub: this.$t('ANALYTICS.SALES_ANALYTICS.METRICS.TOTAL_BOT_REVENUE'),
        },
        {
          title: this.getMetricTitle(total_outbound_revenue),
          sub: this.$t(
            'ANALYTICS.SALES_ANALYTICS.METRICS.TOTAL_OUTBOUND_REVENUE'
          ),
        },
        {
          title: this.getMetricTitle(total_influenced_revenue),
          sub: this.$t(
            'ANALYTICS.SALES_ANALYTICS.METRICS.TOTAL_INFLUENCED_REVENUE.TITLE'
          ),
          actual: this.$t(
            'ANALYTICS.SALES_ANALYTICS.METRICS.TOTAL_INFLUENCED_REVENUE.TOOLTIP'
          ),
        },
      ];
    },
  },
  methods: {
    getMetricTitle(value, defaultText = 'N/A') {
      return value !== null && value !== undefined ? value : defaultText;
    },
    shapeData(obj, name) {
      return {
        data: Object.values(obj),
        name,
      };
    },
    onDateRangeChange({ from, to, date_range_name, isFiredOnMount }) {
      this.startDate = this.formatDate(from * 1000).replaceAll('-', '/');
      this.endDate = this.formatDate(to * 1000).replaceAll('-', '/');

      this.fetchSalesData();
      if (!isFiredOnMount) {
        this.mix_panel_clicked_date_filter_menu_item(
          'Sales Insights',
          date_range_name
        );
      }
    },
    changeChart(val) {
      this.mix_panel_clicked_sales_insight_chart_type_sales_analytics(val.name);
      this.selectedChart = val;
    },
    async fetchSalesData() {
      let hasError = false;

      await Promise.all(
        Object.keys(SALES_SCOPES).map(async scopeKey => {
          const scope = SALES_SCOPES[scopeKey];

          return this.$store
            .dispatch('getSalesByScope', {
              scope,
              startDate: this.startDate,
              endDate: this.endDate,
            })
            .then(response => this.updateSalesData(scope, response))
            .catch(() => {
              if (!hasError) {
                this.showAlert(
                  this.$t('ANALYTICS.SALES_ANALYTICS.API.RESPONSE.ERROR'),
                  'error'
                );
                hasError = true;
              }
            });
        })
      );
    },
    updateSalesData(scope, data) {
      const {
        date,
        total_outbound_revenue,
        outbound_sales,
        bot_sales,
        total_bot_revenue,
        influenced_sales,
        total_influenced_revenue,
        source,
      } = data;

      this.dates = date;

      const dataMapping = {
        [SALES_SCOPES.OUTBOUND]: () => {
          this.sales.outbound_sales = outbound_sales;
          this.revenue.total_outbound_revenue = total_outbound_revenue;
        },
        [SALES_SCOPES.BOT]: () => {
          this.sales.bot_sales = bot_sales;
          this.revenue.total_bot_revenue = total_bot_revenue;
        },
        [SALES_SCOPES.INFLUENCED]: () => {
          this.sales.influenced_sales = influenced_sales;
          this.revenue.total_influenced_revenue = total_influenced_revenue;
          this.influencedByType = source;
        },
      };

      if (dataMapping[scope]) dataMapping[scope]();
    },
  },
};
</script>
