<template>
  <div
    v-tooltip.bottom="
      showTooltip && (description || title.replaceAll('_', ' '))
    "
    class="label"
    :class="cssClass"
    :style="[
      bgColor
        ? { background: bgColor, color: textColor, 'border-color': bgColor }
        : { color: textColor },
      ...customStyle,
    ]"
    :title="description"
  >
    <icons
      v-if="frontIcon"
      :name="frontIcon"
      :size="iconSize"
      :color="
        frontIconColor ? frontIconColor : bgColor ? 'white' : 'darkestgrey'
      "
    />
    <span v-if="!href" class="label--box">
      {{ title }}
    </span>
    <a v-else :href="href" :style="{ color: textColor }">{{ title }}</a>
    <icons
      v-if="showCancelIcon"
      :name="icon"
      :size="iconSize"
      :custom-style="{
        fill: 'none',
        stroke: textColor,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        cursor: 'pointer',
      }"
      @click="onClick"
    />
  </div>
</template>
<script>
import { getContrastingTextColor } from 'shared/helpers/ColorHelper';

export default {
  props: {
    variant: {
      type: String,
      required: true,
      default: 'primary-small',
    },
    title: {
      type: String,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    description: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: '',
    },
    showCancelIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'cancel',
    },
    frontIcon: {
      type: String,
      required: false,
      default: '',
    },
    frontIconColor: {
      type: String,
      default: '',
    },
    customStyle: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    textColor() {
      if (this.bgColor) return getContrastingTextColor(this.bgColor);
      return '#434343';
    },
    iconSize() {
      if (this.variant === 'primary-big' || this.variant === 'secondary-big')
        return 'medium';
      return 'semimedium';
    },
    cssClass() {
      if (this.variant === 'primary-big') {
        return 'primary-label big body-b1';
      }
      if (this.variant === 'secondary-big') {
        return 'secondary-label big body-b1';
      }
      if (this.variant === 'secondary-small') {
        return 'secondary-label small body-b3';
      }
      if (this.variant === 'primary-small') {
        return 'primary-label small body-b3';
      }
      return 'primary-label small body-b3';
    },
  },
  methods: {
    onClick() {
      this.$emit('click', this.title);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.label {
  display: inline-flex;
  align-items: center;
  border-radius: $space-two;
  font-style: normal;
  margin: $space-micro;
  transition: all 0.1s ease-in-out;

  &--box {
    margin: auto 0.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-word;
  }
}

.primary-label {
  border: 1px solid $pg-1-200;
  background: $pg-1-100;
}

.secondary-label {
  border: 1px solid $pg-1;
  background: transparent;
}

.big {
  padding: $space-six $space-small;
}

.small {
  padding: $space-three $space-small;
}
</style>
