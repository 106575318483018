<template>
  <woot-multi-selector
    ref="multiSelector"
    default-key="title"
    variant="secondary"
    show-search
    highlight-selected
    :size="size"
    :custom-button="customButton"
    :static-title="staticTitle"
    :default-option="value"
    :front-icon="frontIcon"
    :option-list="filteredLabels"
    :collapse-selected="collapseSelected"
    :custom-style="customStyle"
    :show-selected-text="showSelectedText"
    :show-option-tooltip="showOptionTooltip"
    :button-style="buttonStyle"
    :toggle-filter="toggleFilter"
    :search-placeholder="$t('LABEL_MGMT.SEARCH_TXT')"
    @selected-option="propagateSelect"
  >
    <template v-slot:default="{ data }">
      <slot :data="data" />
    </template>
  </woot-multi-selector>
</template>

<script>
import { mapGetters } from 'vuex';

import labelsMixin from 'shared/mixins/labelsMixin';

export default {
  mixins: [labelsMixin],
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    value: {
      type: String,
      default: '',
    },
    collapseSelected: {
      type: Boolean,
      default: true,
    },
    customButton: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: Object,
      default: () => ({
        left: 0,
        width: '24rem',
        'overflow-x': 'hidden',
      }),
    },
    showSelectedText: {
      type: Boolean,
      default: false,
    },
    toggleFilter: {
      type: Function,
      default: () => {},
    },
    excludeInboxTypes: {
      type: Array,
      default: () => [],
    },
    resourceType: {
      type: String,
      default: 'Conversation',
    },
    frontIcon: {
      type: String,
      default: '',
    },
    buttonStyle: {
      type: Object,
      default: undefined,
    },
    staticTitle: {
      type: String,
      default: '',
    },
    showOptionTooltip: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      conversationLabels: 'labels/getLabelsWithoutGroups',
      contactLabels: 'labels/getContactLabelsWithoutGroups',
    }),
    activeLabelsList() {
      return this.resourceType === 'Conversation'
        ? this.conversationLabels
        : this.contactLabels;
    },
    filteredLabels() {
      return this.sortLabels(
        this.activeLabelsList.map(label => {
          const colorhex = this.labelColor(label);
          return {
            textcolor: colorhex,
            id: label.id,
            label_type: label.label_type,
            title: label.title,
            icon: 'hash',
            color: this.getLabelColor(colorhex),
          };
        })
      );
    },
  },
  methods: {
    sortLabels(labelsArray) {
      return labelsArray.sort((a, b) => {
        if (a.label_type > b.label_type) return 1;
        if (a.label_type < b.label_type) return -1;
        return a.title > b.title ? 1 : -1;
      });
    },
    getLabelColor(colorhex) {
      if (colorhex.toUpperCase() === '#1B83AC') return 'secondaryblue';
      if (colorhex.toUpperCase() === '#9270CA') return 'purple';
      if (colorhex.toUpperCase() === '#F6BD16') return 'warningyellow';
      return 'text-dark';
    },
    propagateSelect(defaultKey) {
      let selectedLabel = this.activeLabelsList.find(
        label => label.title === defaultKey
      );
      this.$emit('label-selected', selectedLabel);
    },
  },
};
</script>
