<template>
  <component
    :is="inputComponent"
    :value="value"
    :error="error"
    :label="label"
    :data-type="dataType"
    :choices="options"
    :validator="validator"
    @update-value="updateValue"
  >
  </component>
</template>

<script>
import DynamicInput from './DynamicInput';
import ArrayInput from './ArrayInput';

import { DATA_TYPES } from './utils/constants';

export default {
  components: {
    DynamicInput,
    ArrayInput,
  },
  props: {
    dataType: { type: String, required: true },
    label: { type: String, required: true },
    value: {
      type: [String, Number, Array, Object, Boolean, Date],
      required: true,
    },
    options: {
      type: [Array, String],
      default: () => [],
    },
    updateValue: { type: Function, required: true },
    validator: {
      type: Object,
      default: () => ({}),
    },
    error: { type: String, default: 'Field is required' },
  },
  computed: {
    inputComponent() {
      switch (this.dataType) {
        case DATA_TYPES.NUM:
        case DATA_TYPES.ENUM:
        case DATA_TYPES.MULTI_ENUM:
        case DATA_TYPES.TEXT:
        case DATA_TYPES.LARGE_TEXT:
        case DATA_TYPES.DATE:
        case DATA_TYPES.TIME:
        case DATA_TYPES.DATETIME:
        case DATA_TYPES.TIMEFRAME:
          return 'DynamicInput';
        case DATA_TYPES.ARRAY:
        case DATA_TYPES.ARRAY_NUM:
        case DATA_TYPES.ARRAY_ENUM:
        case DATA_TYPES.ARRAY_TEXT:
        case DATA_TYPES.ARRAY_DATE:
        case DATA_TYPES.ARRAY_DATETIME:
        case DATA_TYPES.ARRAY_TIME:
          return 'ArrayInput';
        default:
          return null;
      }
    },
  },
};
</script>
