/* global axios */
import ApiClient from './ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  getLabels(conversationID) {
    return axios.get(`${this.url}/${conversationID}/labels`);
  }

  updateLabels(conversationID, labelId, action) {
    return axios.post(`${this.url}/${conversationID}/labels`, {
      label_id: labelId,
      action_to_perform: action,
    });
  }
}

export default new ConversationApi();
