<template>
  <table-skeleton v-if="uiFlags.fetchingOrders" class="orders-table-skeleton" />
  <woot-table
    v-else
    class="deep-shopify-orders-table lime-card-1dp"
    :title="$t('ANALYTICS.SHOPIFY_ANALYTICS.TABLES.ORDERS_TABLE.TITLE')"
    :header-list="tableHeader"
    :table-data="formattedTableData"
  >
    <template v-slot:filters>
      <woot-single-selector
        variant="secondary"
        class="small-2 mg-bottom--slab mg-top--slab"
        :custom-style="{ width: 'auto' }"
        :default-option="selectedStatusTitle"
        :option-list="statusOptions"
        :show-selected-text="false"
        @click="applyStatus"
      />
    </template>
    <template v-slot:bottom>
      <div class="table-pagination">
        <ve-pagination
          :total="ordersMeta.total_entries"
          :page-index="ordersMeta.current_page"
          :page-size="5"
          :paging-count="2"
          :layout="['total', 'prev', 'pager', 'next']"
          @on-page-number-change="onPageNumberChange"
        />
      </div>
    </template>
  </woot-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { VePagination } from 'vue-easytable';

import TableSkeleton from '../components/TableSkeleton';

import { frontendURL, conversationUrl } from 'dashboard/helper/URLHelper';

export default {
  components: { VePagination, TableSkeleton },
  props: {
    appliedFilters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return { selectedStatusId: 0 };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      uiFlags: 'deepShopifyAnalytics/getUiFlags',
      ordersMeta: 'deepShopifyAnalytics/getOrdersMeta',
      orders: 'deepShopifyAnalytics/getOrders',
      statusesList: 'deepShopifyAnalytics/getOrderStatusesList',
      agents: 'agents/getAgents',
    }),
    statusOptions() {
      return [
        { id: 0, name: 'All Statuses' },
        ...this.statusesList.map(
          ({ id, title: name, status: value, color_code: color }) => {
            return {
              id,
              name,
              value,
              color,
            };
          }
        ),
      ];
    },
    selectedStatusTitle() {
      if (this.selectedStatusId === 0) return 'All Statuses';

      return this.findStatusById(this.selectedStatusId).title;
    },
    tableHeader() {
      return [
        {
          id: 'product_names',
          name: this.$t(
            'ANALYTICS.SHOPIFY_ANALYTICS.TABLES.ORDERS_TABLE.HEADER.PRODUCT_NAME'
          ),
        },
        {
          id: 'order_id',
          name: this.$t(
            'ANALYTICS.SHOPIFY_ANALYTICS.TABLES.ORDERS_TABLE.HEADER.ORDER_ID'
          ),
        },
        {
          id: 'total_price',
          name: this.$t(
            'ANALYTICS.SHOPIFY_ANALYTICS.TABLES.ORDERS_TABLE.HEADER.ORDER_VALUE'
          ),
        },
        {
          id: 'conversation_id',
          name: this.$t(
            'ANALYTICS.SHOPIFY_ANALYTICS.TABLES.ORDERS_TABLE.HEADER.TICKET'
          ),
        },
        {
          id: 'agent_id',
          name: this.$t(
            'ANALYTICS.SHOPIFY_ANALYTICS.TABLES.ORDERS_TABLE.HEADER.AGENT'
          ),
        },
        {
          id: 'shopify_order_status_str',
          name: this.$t(
            'ANALYTICS.SHOPIFY_ANALYTICS.TABLES.ORDERS_TABLE.HEADER.STATUS'
          ),
        },
      ];
    },
    formattedTableData() {
      return this.orders.map(order => ({
        product_names: this.formatProductNames(order.product_names),
        order_id: this.getOrderLink(order),
        total_price: this.formatPrice(order),
        conversation_id: this.getConversationLink(order.conversation_id),
        agent_id: this.getAgentName(+order.agent_id),
        shopify_order_status_str: this.formatStatus(
          order.shopify_order_status_id
        ),
      }));
    },
  },
  methods: {
    findStatusById(id) {
      return this.statusesList.find(status => status.id === id);
    },
    formatStatus(statusId) {
      if (!statusId) return '---';

      const { title, color_code } = this.findStatusById(statusId);
      return `
      <div 
      style='color:${color_code};border-color:${color_code};max-width: 12rem' 
      class='status-badge text-align body-b3'>
      ${title}
      </div>
      `;
    },
    formatProductNames(names) {
      const commaSeparatedNames = names.join(', ');
      return `<div style='max-width:40rem' class='line-clamp-3' title='${commaSeparatedNames}'>${commaSeparatedNames}</div>`;
    },
    formatPrice(order) {
      const { currency, total_price: price } = order;
      return price ? `${currency || ''} ${price}` : '---';
    },
    getAgentName(id) {
      if (!id) return '---';
      const foundAgent = this.agents.find(agent => agent.id === id);

      return `<span class='subtitle-s2 text-dark'>${foundAgent?.name}</span>`;
    },
    getOrderLink(order) {
      return `<a href="${order.shopify_order_url}" target="_blank" rel="noopener noreferrer">${order.order_id}</a>`;
    },
    getConversationLink(id) {
      if (!id) return '---';

      const conversationLink =
        window.location.origin +
        frontendURL(
          conversationUrl({
            accountId: this.accountId,
            id: id,
          })
        );

      return `<a href="${conversationLink}" target="_blank" rel="noopener noreferrer">#${id}</a>`;
    },
    applyStatus({ id }) {
      this.selectedStatusId = id;
      this.$emit('status-change', id);
      const params = {
        ...this.appliedFilters,
        status: id === 0 ? undefined : id,
      };
      this.$store.dispatch('deepShopifyAnalytics/fetchOrders', params);
    },
    onPageNumberChange(pageIndex) {
      const params = { ...this.appliedFilters, page: pageIndex };

      this.$store.dispatch('deepShopifyAnalytics/fetchOrders', params);
    },
  },
};
</script>
<style lang="scss">
@import '~dashboard/assets/scss/variables';

.deep-shopify-orders-table {
  .status-badge {
    border: 1px solid;
    border-radius: $space-two;
    padding: $space-smaller $space-small;
  }
}

.table-pagination {
  margin-top: $space-one;
}
</style>
