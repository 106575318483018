<template>
  <div>
    <div class="pos-relative">
      <span class="table-title">{{ title }}</span>
      <span class="tooltip-position">
        <icons name="info" size="medium" color="grey" />
      </span>
    </div>
    <div id="chart">
      <apexchart
        type="heatmap"
        width="100%"
        :height="height"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: 'auto',
    },
  },

  data() {
    return {
      chartOptions: {
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 25,
                  color: '#E5F7CF',
                },
                {
                  from: 25,
                  to: 75,
                  color: '#B6EA76',
                },
                {
                  from: 76,
                  to: 120,
                  color: '#86D822',
                },
                {
                  from: 120,
                  to: 2000,
                  color: '#6BAC1B',
                },
              ],
            },
          },
        },
        chart: {
          height: 350,
          type: 'heatmap',
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          fillSeriesColor: false,
          style: {
            fontFamily: 'Lato',
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#6BAC1B'],
        yaxis: {
          labels: {
            show: true,
            hideOverlappingLabels: true,
            trim: false,
            minWidth: 40,
            style: {
              fontFamily: 'Lato',
              fontSize: '12px',
              colors: ['#8C8C8C'],
            },
          },
        },
        xaxis: {
          labels: {
            style: {
              fontSize: '12px',
              colors: '#8C8C8C',
            },
          },
          axisTicks: {
            show: false,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.table-title {
  font-size: $font-size-medium;
  line-height: 2.6rem;
  color: $neutral-grey-800;
  font-weight: $font-weight-medium;
  margin: $space-medium $zero;
}

.tooltip-position {
  position: absolute;
  top: $zero;
  right: $zero;
}
.pos-relative {
  position: relative;
}
</style>
