<template>
  <div class="draggable-values-section">
    <label class="formlabel">
      <span class="title-h5 text-dark"> Values </span>
    </label>
    <draggable :list="list" @end="$emit('updateValues', list)">
      <div
        v-for="(_, index) in list"
        :key="index"
        class="flex-row flex-justify--start"
      >
        <span style="cursor: pointer" class="mg-right--small">
          <icons name="drag" color="lightgrey-fill-only" size="mediumlarge" />
        </span>

        <woot-input
          v-model="list[index].value"
          size="small"
          class="name-input"
          :disabled="disabled && !!list[index].id"
          @blur="$emit('updateLabelName', $event, index)"
        />

        <woot-base-button
          size="small"
          tag="span"
          variant="tertiary"
          style="margin-bottom: -0.4rem"
          @click="$emit('addField', index)"
        >
          <span>
            <icons name="plus" color="textlightgrey" size="medium" />
          </span>
        </woot-base-button>

        <woot-base-button
          size="small"
          tag="span"
          variant="tertiary-danger"
          @click="$emit('removeField', index)"
        >
          <span style="cursor: pointer">
            <icons name="delete" color="textlightgrey" size="medium" />
          </span>
        </woot-base-button>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragOptions: {
        animation: 200,
        disabled: false,
        ghostClass: 'moving-card',
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.draggable-values-section {
  padding: $space-normal;
  width: 5 * $space-mega;
  background-color: $neutral-grey-100;
  border-radius: $border-radius;
  border: 1px solid $neutral-grey-300;

  .name-input {
    width: 31.6rem;
    margin-right: $space-one;
    margin-top: $space-smaller;
    margin-bottom: $space-smaller;
  }
}

.moving-card {
  opacity: 0.5;
  background: $neutral-grey-200;
}
</style>
