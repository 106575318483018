<template>
  <form class="form-container" @submit.prevent="createThreeSixtyInbox">
    <div
      class="title-h1 text-dark mg-bottom--medium"
      v-text="$t('ONBOARDING.WHATSAPP.FORM.TITLE')"
    />
    <woot-input
      v-model.trim="inboxName"
      required
      auto-focus
      full-width
      class="mg-bottom--slab"
      :class="{ error: $v.inboxName.$error }"
      :has-error="$v.inboxName.$error"
      :error="
        $t(
          'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.INBOX_NAME.ERROR'
        )
      "
      :label="
        $t(
          'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.INBOX_NAME.LABEL'
        )
      "
      :placeholder="
        $t(
          'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.INBOX_NAME.PLACEHOLDER'
        )
      "
      @blur="$v.inboxName.$touch"
    />
    <woot-input
      v-model.trim="phoneNumber"
      required
      full-width
      class="content-margin"
      :class="{ error: $v.phoneNumber.$error }"
      :has-error="$v.phoneNumber.$error"
      :error="
        $t(
          'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.PHONE_NUMBER.ERROR'
        )
      "
      :label="
        $t(
          'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.PHONE_NUMBER.LABEL'
        )
      "
      :placeholder="
        $t(
          'INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.PHONE_NUMBER.PLACEHOLDER'
        )
      "
      @blur="$v.phoneNumber.$touch"
    />
    <div class="flex-row flex-justify--between">
      <woot-base-button
        variant="secondary"
        class="navigation-button"
        @click.prevent="navigateTo(ONBOARDING_SCREENS.EDUCATE_USER.key)"
      >
        {{ $t('ONBOARDING.NAVIGATION.PREVIOUS') }}
      </woot-base-button>
      <woot-base-button type="submit" class="navigation-button">
        {{ $t('ONBOARDING.NAVIGATION.NEXT') }}
      </woot-base-button>
    </div>
  </form>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { isValidPhoneNumber } from 'libphonenumber-js';

import alertMixin from 'shared/mixins/alertMixin';

import { ONBOARDING_SCREENS } from 'dashboard/constants';

export default {
  mixins: [alertMixin],
  data() {
    return { inboxName: '', phoneNumber: '', ONBOARDING_SCREENS };
  },
  inject: ['navigateTo'],
  validations: {
    inboxName: { required },
    phoneNumber: {
      checkValidPhoneNumber(value) {
        return isValidPhoneNumber('+' + value);
      },
      required,
    },
  },
  methods: {
    createThreeSixtyInbox() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(
          this.$t('ONBOARDING.WHATSAPP.FORM.VALIDATION_ERROR'),
          'error'
        );
        return;
      }

      const payload = {
        name: this.inboxName,
        phone_number: this.phoneNumber,
      };

      this.$store
        .dispatch('inboxes/createThreeSixtyDialogChannel', payload)
        .then(() => {
          this.navigateTo(ONBOARDING_SCREENS.THREE_SIXTY_CONNECT.key);
        })
        .catch(error => {
          this.showAlert(
            error.response.data.error ||
              this.$t('INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.API.ERROR_MESSAGE'),
            'error',
            5000
          );
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.form-container {
  padding: $zero;
}

.content-margin {
  margin-bottom: $space-slab * 10;
}

.navigation-button {
  width: $space-slab * 10;
}
</style>
