<template>
  <div class="view-box-new columns">
    <settings-header
      icon="graph"
      :header-title="$t('ANALYTICS.HEADER.ANALYTICS')"
      :show-back-button="false"
    />

    <last-updated-time
      :key="computedScope"
      :scope="computedScope"
      class="flex-row flex-justify--end pd-right"
    />

    <div class="tabs-pane">
      <woot-tabs :index="selectedTabIndex" @change="onTabChange">
        <woot-tabs-item v-for="tab in tabs" :key="tab.key" :name="tab.name" />
      </woot-tabs>
    </div>
    <bot-overview v-if="selectedTabIndex == 0" />
    <user-behavior v-if="selectedTabIndex == 1" />
  </div>
</template>

<script>
import SettingsHeader from '../settings/SettingsHeader';
import BotOverview from './tabs/BotOverview';
import UserBehavior from './tabs/UserBehaviour';
import LastUpdatedTime from './components/LastUpdatedTime';

import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: {
    SettingsHeader,
    BotOverview,
    UserBehavior,
    LastUpdatedTime,
  },
  mixins: [mixPanelAnalyticsMixin],
  data() {
    return {
      selectedTabIndex: 0,
      tabs: [
        {
          key: 'bot_overview',
          name: this.$t('ANALYTICS.HEADER.BOT_OVERVIEW'),
        },
        {
          key: 'user_behavior',
          name: this.$t('ANALYTICS.HEADER.USER_BEHAVIOR'),
        },
      ],
    };
  },
  computed: {
    computedScope() {
      const scopeMap = {
        0: 'bot_performance',
        1: 'user_behaviour',
      };
      return scopeMap[this.selectedTabIndex];
    },
    tabParameter() {
      const selectedTab = Number(this.$route.query?.tab);
      return !Number.isNaN(selectedTab) && selectedTab >= 0 ? selectedTab : 0;
    },
  },
  mounted() {
    this.onTabChange(this.tabParameter, 'mounted');
  },
  methods: {
    onTabChange(selectedTabIndex, source) {
      if (source !== 'mounted') {
        switch (selectedTabIndex) {
          case 0:
            this.mix_panel_clicked_bot_overview_tab(
              this.$t('ANALYTICS.HEADER.BOT_OVERVIEW')
            );
            break;
          case 1:
            this.mix_panel_clicked_bot_overview_tab(
              this.$t('ANALYTICS.HEADER.USER_BEHAVIOR')
            );
            break;
          case 2:
            this.mix_panel_clicked_bot_overview_tab(
              this.$t('ANALYTICS.HEADER.SALES_ANALYTICS')
            );
            break;
          default:
            this.mix_panel_clicked_bot_overview_tab(
              this.$t('ANALYTICS.HEADER.BOT_OVERVIEW')
            );
            break;
        }
      }

      window.history.pushState(
        {},
        null,
        `${this.$route.path}?tab=${selectedTabIndex}`
      );
      this.selectedTabIndex = selectedTabIndex;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tabs-pane {
  position: -webkit-sticky;
  position: sticky;
  background-color: $chat-background;
  top: $zero;
  z-index: 1000;
  margin-top: 7rem;
  padding-bottom: $space-six;
}
</style>
