import { mapGetters } from 'vuex';
import timeMixin from 'dashboard/mixins/time';

export default {
  mixins: [timeMixin],

  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      accountId: 'getCurrentAccountId',
    }),
    currentAccount() {
      return this.getAccount(this.accountId);
    },
    hasSubscription() {
      return !!this.currentAccount?.subscription?.length;
    },
    subscription() {
      return this.hasSubscription ? this.currentAccount.subscription : null;
    },
    subscriptionForShopify() {
      // for shopify only one object will be there in the subscription array
      return this.hasSubscription && this.isShopifyBilling
        ? this.subscription[0]
        : null;
    },
    platform() {
      return this.hasSubscription ? this.subscription[0].platform : '';
    },
    isShopifyBilling() {
      return this.platform === 'shopify';
    },
    isNonShopifyBilling() {
      return this.platform === 'non_shopify';
    },
    isPaymentPending() {
      return this.currentAccount?.payment_pending;
    },
    inGracePeriod() {
      return this.currentAccount?.on_grace_period;
    },
    gracePeriodLastDate() {
      return this.currentAccount?.grace_period_last_date || '';
    },
    gracePeriodDaysLeft() {
      let daysLeft =
        this.differenceInDays(new Date(this.gracePeriodLastDate), new Date()) +
        1;
      return daysLeft;
    },
    isEnterprise() {
      return this.currentAccount?.is_enterprise;
    },
    inGracePeriodForNonShopify() {
      return (
        this.isNonShopifyBilling && this.hasSubscription && this.inGracePeriod
      );
    },
    inPendingPaymentForNonShopify() {
      return (
        this.isNonShopifyBilling &&
        this.hasSubscription &&
        this.isPaymentPending
      );
    },
  },
};
