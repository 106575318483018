<template>
  <section>
    <section-header translation-key="TRIGGER" />
    <aside class="sla-conditions">
      <div
        v-for="triggerKey in triggers"
        :key="triggerKey"
        class="filter"
        :class="{
          'mg-bottom--medium':
            triggers.indexOf(triggerKey) !== triggers.length - 1,
        }"
      >
        <span>if</span>
        <trigger-selector
          :class="'sla-conditions__trigger_selector__' + triggerKey"
          :value="triggerKey"
          :triggers="triggers"
          @click="onSelectTrigger($event, triggerKey)"
        />
        <template v-if="triggerKey !== 'select'">
          <span>is</span>
          <multi-select-trigger-values
            :key="triggerKey"
            class="multi-select-filter"
            :class="'multi-select-filter__' + triggerKey"
            :value="getValues(triggerKey)"
            :dropdown-values="getOptionsList(triggerKey)"
            :label-key="getLabelKey(triggerKey)"
            @input="handleValues($event, triggerKey)"
          />
        </template>
        <woot-base-button
          tag="span"
          variant="tertiary-danger"
          @click="deleteCondition(triggerKey)"
        >
          <icons name="bin" color="red" size="semimedium" :show-title="false" />
        </woot-base-button>
      </div>
      <!-- add filter button -->
      <div v-if="triggers.length !== 3" class="mg-top">
        <add-filter-button
          class="sla-conditions__add-filter"
          :label="$t('SLA.CREATE.SECTIONS.TRIGGER.ADD_FILTER')"
          @click="onAddFilter"
        />
      </div>
    </aside>
  </section>
</template>
<script>
import SectionHeader from './components/shared/Header';
import TriggerSelector from './components/selectors/TriggerSelector';
import AddFilterButton from './components/shared/AddFilterButton';
import MultiSelectTriggerValues from './components/selectors/MultiSelector';

import { TRIGGER_OPTIONS, TRIGGERS } from '../../../utils/constants';
import { onAddKey, onSelectKey } from '../../../utils/form';

import formMixin from '../../../mixins/formMixin';

export default {
  components: {
    SectionHeader,
    TriggerSelector,
    AddFilterButton,
    MultiSelectTriggerValues,
  },
  mixins: [formMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    triggers() {
      return this.data?.conditions ? Object.keys(this.data?.conditions) : {};
    },
  },
  methods: {
    getLabelKey(triggerKey) {
      return triggerKey === TRIGGERS.LABEL ? 'title' : 'name';
    },
    getValues(triggerKey) {
      return this.getDropdownValues(triggerKey).filter(item =>
        this.data.conditions[triggerKey].values.includes(item.id)
      );
    },
    getOptionsList(triggerKey) {
      return this.getDropdownValues(triggerKey);
    },
    handleValues(values, triggerKey) {
      this.$emit('update', {
        ...this.data,
        conditions: {
          ...this.data.conditions,
          [triggerKey]: { values: values.map(item => item.id) },
        },
      });
    },
    onSelectTrigger(selectedTrigger, currentTriggerKey) {
      const newPayload = onSelectKey(
        this.data,
        selectedTrigger.value,
        currentTriggerKey,
        'conditions'
      );

      // Emit the updated SLA data
      if (newPayload) this.$emit('update', newPayload);
    },
    getLastTriggerOption() {
      let lastOption = null;
      if (this.triggers.length === 2) {
        lastOption = TRIGGER_OPTIONS.find(
          trigger => !this.triggers.includes(trigger.value)
        );
      }
      return lastOption;
    },
    onAddFilter() {
      const newCondition = onAddKey(this.data, 'conditions');

      if (newCondition) this.$emit('update', newCondition);

      const lastTriggerOption = this.getLastTriggerOption();
      if (lastTriggerOption) {
        this.onSelectTrigger(lastTriggerOption, '');
      }
    },
    deleteCondition(triggerKey) {
      const updatedConditions = { ...this.data.conditions };
      delete updatedConditions[triggerKey];

      this.$emit('update', {
        ...this.data,
        conditions: updatedConditions,
      });
    },
  },
};
</script>
